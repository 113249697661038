import React ,{useEffect, useState}from 'react'
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import 'react-phone-input-2/lib/style.css'
import A12Y_CONST from '../common/a12yConst'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import PopUpComponent from '../components/PopUpComponent'

function  DependentProfileStep2Type (props) {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const  [selectedRoleTyperOption, setSelectedRoleTypeOption] = useState(null)

    const {handleSubmit ,formState:{ isSubmitting}} = useForm({
        mode:"all"
    });

    useEffect(()=>{
        setLoading(props.loading);
        setError(props.error)
    },[props.loading,props.error])


    const onSubmit = async (user) => {
        let Obj = {user_role_type:selectedRoleTyperOption}
        props.onSubmit(3,Obj)
    }
    const onCancel = async()=>{
        props.onCancel(1)
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.onFinish()
    }
    const roleTypeOptions = [
        // { value: 'Choose Gender', label: 'Choose Gender', isdisabled: true},
        {key:1, value: A12Y_CONST.LABEL.GUARDIAN_CONSERVATOR, label: A12Y_CONST.LABEL.GUARDIAN_CONSERVATOR},
        {key:2, value: A12Y_CONST.LABEL.AGENT_UNDER_POWER_OF_ATTORNEY, label: A12Y_CONST.LABEL.AGENT_UNDER_POWER_OF_ATTORNEY},
        {key:3, value: A12Y_CONST.LABEL.REPRESENTATIVE_PAYEE, label: A12Y_CONST.LABEL.REPRESENTATIVE_PAYEE},
        {key:4, value: A12Y_CONST.LABEL.NONE_OF_THE_ABOVE, label: A12Y_CONST.LABEL.NONE_OF_THE_ABOVE},

    ]
    const onValueChange = async (event) => {
        setSelectedRoleTypeOption(parseInt(event.target.value))

    }
    useEffect(  () => {
        if(props.roleType !== '' && props.roleType !== undefined){
            setSelectedRoleTypeOption(props.roleType)
        }
    },[props.roleType])

    useEffect(() => {
        if(props.roleType === '' || props.roleType === null) {
            setSelectedRoleTypeOption(1)
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div  className="fullWidth">
                <div className="dependentSubTitle marginTop30 marginBottom5">
                    {A12Y_CONST.LABEL.WHICH_ROLE_BEST}
                </div>

                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>
                            :
                            null
                    )
                }
                <form onSubmit={handleSubmit(onSubmit)} className='paddingTop30 newFormUI dependentStep1' autoComplete="off">
                        <div className="fullWidth">
                            <ul className='roleTypeList'>
                                {
                                    roleTypeOptions.map((item, i) => (
                                     <li key={i}>
                                         <label>
                                            <input type="radio"
                                                   checked={ selectedRoleTyperOption !== null && selectedRoleTyperOption === item.key }
                                                   onChange={onValueChange}
                                                   tabIndex={4}
                                                   value={item.key } />
                                            <span> { item.label }</span>
                                        </label>
                                     </li>
                                    ))
                                }
                            </ul>
                      </div>
                    <div className="fullWidth textAlignRight signUpFlowButtonRow marginBottom30 displayFlexCol" >
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_STEP2BACK} className={!isSubmitting ? "backButton" :'backButton'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.BACK}/></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            {loading ?
                                <Loading />
                                :
                                null
                            }
                            <input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_STEP2CONTINUE} className={loading ? "saveButtonDisable buttonlaerUp buttonHeight56" :'saveButton buttonHeight56 buttonlaerUp'} disabled={loading}  type="submit" value={A12Y_CONST.BUTTON_TEXT.DEPENDENT_COUNTINUE }/>
                        </div>
                    </div>
                </div>
                </form>
                </div>
            <PopUpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={A12Y_CONST.LABEL.ANY_UNSAVED_INFORMATION}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.DELETE}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.CANCEL}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
                isImageRemove={props.isImageRemove}
            />

        </div>

    )
}

export default  DependentProfileStep2Type;