import React,{Component} from 'react'
import EmailLogin from '../components/LoginEmailVerifiedComponent'
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions'
import A12Y_CONST from '../common/a12yConst'

class LoginEmailVerifiedScreen extends Component {

    constructor(props){
        super(props)
        this.state={
            loading:false,
            errorMessage:'',
            SignUpSuccess:false,
            title:A12Y_CONST.MESSAGE.EMAIL_LOGIN_TITLE,
            message:A12Y_CONST.MESSAGE.EMAIL_LOGIN_MESSAGE,
            type:'Email',
            error:false
        }
    }

    componentDidMount = async()=>{
        if(this.props?.location?.state?.title && this.props?.location?.state?.message && this.props?.location?.state?.type){
            if(this.props?.location?.state?.title){
                this.setState({title:this.props?.location?.state?.title})
            }if(this.props?.location?.state?.message){
                this.setState({message:this.props?.location?.state?.message})
            }if(this.props?.location?.state?.type){
                this.setState({type:this.props?.location?.state?.type})
            }
        }else {
            if(window.location.pathname === "/passwordUpdated"){
                this.setState({
                    'title' : A12Y_CONST.MESSAGE.PASSWORD_UPDATED_LOGIN_TITLE, 'message' : A12Y_CONST.MESSAGE.PASSWORD_UPDATED_LOGIN_MESSAGE, 'type': 'Password'
                })
            }
        }
    }

    handleSubmit = async(loginData)=> {
        this.setState({loading:true})
        let usrName = loginData.username ? loginData.username :'';
        let password = loginData.password ? loginData.password :'';
        let auth_code = loginData.auth_code ? loginData.auth_code :'';
        await this.props.login(usrName,password, auth_code)
        this.setState({loading:false})
        if(this.props.isLoggedIn){
            await this.props.trialReminder(true);
            this.setState({error:false})
            // this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW);
            this.props.history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW);
        }else {
            this.setState({errorMessage:this.props.errMsg,error:true})
        }
    }

    render() {
        return (
            <div>
                <EmailLogin
                    type={this.state.type}
                    title={this.state.title}
                    message={this.state.message}
                    loading={this.state.loading}
                    onSubmit={this.handleSubmit}
                    errorMessage={this.state.errorMessage}
                    error={this.state.error}
                />
            </div>

        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
});

const mapDispatchToProps = dispatch => ({
    login : (username,password,authcode) => dispatch(userDetailActions.login(username,password,authcode)),
    trialReminder : (status) => dispatch(userDetailActions.trialReminder(status)),
});
export default connect(mapStateToProps,mapDispatchToProps) (LoginEmailVerifiedScreen);