import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import A12Y_CONST from '../common/a12yConst'
import {isValidPhoneNumber}from 'libphonenumber-js';
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import EditChangesRemovePopup from '../components/EditChangesRemovePopup'
import { useHistory } from 'react-router-dom'

const MyAccountPersonalInformationComponent = props => {
    const history = useHistory();
    const [firstName, setFirstName] = useState('');
   /* const languageQueryParam =  "";
    const libraries = "places";
    const googleMapsScriptUrl = `${CONFIG.GOOGLE_PLACES_BASE_URL}?key=${CONFIG.GOOGLE_PLACES_KEY}&libraries=${libraries}${languageQueryParam}`;*/
    const [lastName, setLastName] = useState('');
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [countryCodeEditable, setCountryCodeEditable] = useState(false); // eslint-disable-line
    const [selectedAdd, setSelectedAdd] = useState({
        "address_line1" : "",
        "address_line2" : "",
        "city" : "",
        "state" : "",
        "zip" : "",
        "country_code" : "USA"

    });
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [selectedPhoneType1, setSelectedPhoneType1] = useState({value: 'Mobile',label: 'Mobile'});
    const [selectedPhoneType2, setSelectedPhoneType2] = useState({});
    const [countryState, setCountryState]  = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumber_2, setPhoneNumber_2] = useState('');
    const [phoneNumber_Error_Message, setPhoneNumber_Error_Message] = useState('');
    const [phoneNumber_2ErrorMessage, setPhoneNumber_2ErrorMessage] = useState('');
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [phoneNumberError, setphoneNumberError] = useState(false);
    const [phoneNumber2Error, setphoneNumber2Error] = useState(false);
    const [showAddressDropDown, setShowAddressDropDown] = useState(false);
    const [phoneType1LabelActive, setPhoneType1LabelActive] = useState(false);
    const [phoneType2LabelActive, setPhoneType2LabelActive] = useState(false);
    const [isPhone2DropdownSelect, setIsPhone2DropdownSelect] = useState(false);
    const [isValueChanged, setIsValueChanged] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    /* eslint-disable no-unused-vars */
    const [state, setState] = useState({});
    /* eslint-disable no-unused-vars */
    const [dropDownIndex, setDropDownIndex] = useState(null);
    const [placeServiceList, setPlaceServiceList] = useState({});
    /*const handleLoadScript = useCallback(
        () => loadGoogleMapScript(CONFIG.GOOGLE_PLACES_BASE_URL, googleMapsScriptUrl),
        [CONFIG.GOOGLE_PLACES_BASE_URL, googleMapsScriptUrl]
    );*/
    const {register,handleSubmit, setError  ,formState:{ errors, isDirty, isValid, isSubmitting},setValue,getValues,clearErrors} = useForm({
        mode:"all"
    });
    /*const { ref, autocompleteRef } = usePlacesWidget({
        apiKey:CONFIG.GOOGLE_PLACES_KEY,
        onPlaceSelected: (place) => {
            console.log(place);
        },
        options: {
            componentRestrictions: { country: "us" },
        },
    });*/
    /*const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: CONFIG.GOOGLE_PLACES_KEY,
        options: {
            types:["address", "geocode"],
            //fields:["address_components"],
            componentRestrictions: { country: "us" },
        },
    });*/
    // const [value, setValue] = useState("");
    /*useEffect(() => {
        // fetch place details for the first element in placePredictions array
        console.log("placePredictions", placePredictions)
        if (placePredictions.length){
            placePredictions.map((place,key) => {placesService?.getDetails(
                {
                    placeId: place.place_id,
                },
                (placeDetails) =>{
                    setPlacePredictionList(oldArray => [...oldArray, placeDetails]);
                }
            );});
        }
    }, [placePredictions]);*/

    useEffect(()=>{
        setErrors(props.error)
        setLoading(props.loading);
    },[props.error, props.loading])


    useEffect(()=>{
        setShowAddressDropDown(false)
    },[props.isClickedOutside])

    useEffect(()=>{
        if(isSubmitting){
            const isValid =  validatePhoneNumber(phoneNumber)
            if(!isValid){
                setphoneNumberError(true)
            }else {
                setphoneNumberError(false)
            }
        }
    },[isSubmitting]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(()=>{
        assingDefaultValue();
    },[props.userInfo]) // eslint-disable-line react-hooks/exhaustive-deps


    const assingDefaultValue = async () => {
        setphoneNumberError(false);
        setphoneNumber2Error(false);
        setPhoneNumber_2ErrorMessage('');
        clearErrors("firstName")
        clearErrors("lastName")
        if(props.userInfo){
            setValue("firstName",props.userInfo.first_name)
            setFirstName(props.userInfo.first_name)
            setLastName(props.userInfo.last_name)
            setValue("lastName",props.userInfo.last_name)
            if(props.userInfo.address && Object.keys(props.userInfo.address).length > 0) {
                setValue("address1", props.userInfo.address.address_line1)
                setValue("zipcode", props.userInfo.address.zip)
                setValue("city", props.userInfo.address.city)
                setValue("address2", props.userInfo.address.address_line2)
                setAddress1(props.userInfo.address.address_line1)
                setAddress2(props.userInfo.address.address_line2)
                setZip(props.userInfo.address.zip)
                setCity(props.userInfo.address.city)
                let stateObj = countryStateList.filter(data =>data.label === props.userInfo.address.state);
                if(stateObj.length > 0) {
                    setCountryState(stateObj[0])
                    setState(stateObj[0])
                }else {
                    setCountryState({})
                    setState({})
                }
            }else {
                setValue("address1", '')
                setValue("zipcode", '')
                setValue("city", '')
                setValue("address2", '')
                setCountryState({})
                setAddress1('')
                setAddress2('')
                setZip('')
                setCity('')
                setState({})
            }
            if(props.userInfo.phones && props.userInfo.phones.length > 0) {
                props.userInfo.phones.forEach((phone,index)=>{
                    if(index === 0){
                        if(phone.type === 'mobile'){
                            setSelectedPhoneType1({value:'Mobile' , label:'Mobile'})
                        }else if(phone.type === 'office'){
                            setSelectedPhoneType1({value:'Office' , label:'Office'})
                        }else if(phone.type === 'home'){
                            setSelectedPhoneType1({value:'Home' , label:'Home'})
                        }else if(phone.type === 'other'){
                            setSelectedPhoneType1({value:'Other' , label:'Other'})
                        }
                        if(phone.number.length > 0){
                            setPhoneNumber(phone.number)
                            setPhoneType1LabelActive(true)
                        }else {
                            setPhoneNumber('')
                        }
                    }else {
                        if(phone.type === 'mobile'){
                            setSelectedPhoneType2({value:'Mobile' , label:'Mobile'})
                        }else if(phone.type === 'office'){
                            setSelectedPhoneType2({value:'Office' , label:'Office'})
                        }else if(phone.type === 'home'){
                            setSelectedPhoneType2({value:'Home' , label:'Home'})
                        }else if(phone.type === 'other'){
                            setSelectedPhoneType2({value:'Other' , label:'Other'})
                        }
                        if(phone.number.length > 0){
                            setPhoneNumber_2(phone.number)
                            setPhoneType2LabelActive(true)
                        }else {
                            setPhoneNumber_2('')
                            //handlePhoneField_2('')
                        }
                    }
                })
            }else {
                setSelectedPhoneType1({value:'Mobile' , label:'Mobile'})
                setSelectedPhoneType2({})
                setPhoneNumber('')
                setPhoneNumber_2('')
                //handlePhoneField_2('')
            }
        }
        setIsEditMode(true)
    }

    const getPlaceFromService = async (obj)=>{
        //if (!isBrowser) return;
        const request = {
            input: obj.input,
            componentRestrictions: {country : 'us'},
            fields: ["name", "geometry", "formatted_address", "place_id"],
        };
        if(window.google){

        let service = new window.google.maps.places.AutocompleteService(document.createElement("div"));
        service.getPlacePredictions(request,(results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                let placesPred = [];
                setPlaceServiceList(results)
                /*for (let i = 0; i < results.length; i++) {
                    console.log(results[i])
                    placesPred.push(results[i])
                    setPlaceServiceList(placesPred)
                    console.log("placeServiceList", placeServiceList)
                    //createMarker(results[i]);
                }*/

                //map.setCenter(results[0].geometry.location);
            }
        });
        }
        //handleLoadScript().then(() => buildService())
    }



    const phoneTypeList = [
        {
            value: 'select',
            label: 'Select',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#0096C7"></path>
            </svg>
        },
        {
            value: 'mobile',
            label: 'Mobile',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#2D6CB2"></path>
            </svg>
        },
        {
            value: 'office',
            label: 'Office',
            icon:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'home',
            label: 'Home',
            icon: <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'other',
            label: 'Other',
            icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#71767A" style={{strokeWidth:2}} ></path>
            </svg>,
            iconActive: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#0297C8" style={{strokeWidth:2}}></path>
            </svg>
        }
    ];

    // const countryStateList = A12Y_STATE_LIST.map(item => item.abbreviation)
    const countryStateList = [
            {
                "name": "Alabama",
                "label": "AL"
            },
            {
                "name": "Alaska",
                "label": "AK"
            },
            {
                "name": "American Samoa",
                "label": "AS"
            },
            {
                "name": "Arizona",
                "label": "AZ"
            },
            {
                "name": "Arkansas",
                "label": "AR"
            },
            {
                "name": "California",
                "label": "CA"
            },
            {
                "name": "Colorado",
                "label": "CO"
            },
            {
                "name": "Connecticut",
                "label": "CT"
            },
            {
                "name": "Delaware",
                "label": "DE"
            },
            {
                "name": "District Of Columbia",
                "label": "DC"
            },
            {
                "name": "Federated States Of Micronesia",
                "label": "FM"
            },
            {
                "name": "Florida",
                "label": "FL"
            },
            {
                "name": "Georgia",
                "label": "GA"
            },
            {
                "name": "Guam",
                "label": "GU"
            },
            {
                "name": "Hawaii",
                "label": "HI"
            },
            {
                "name": "Idaho",
                "label": "ID"
            },
            {
                "name": "Illinois",
                "label": "IL"
            },
            {
                "name": "Indiana",
                "label": "IN"
            },
            {
                "name": "Iowa",
                "label": "IA"
            },
            {
                "name": "Kansas",
                "label": "KS"
            },
            {
                "name": "Kentucky",
                "label": "KY"
            },
            {
                "name": "Louisiana",
                "label": "LA"
            },
            {
                "name": "Maine",
                "label": "ME"
            },
            {
                "name": "Marshall Islands",
                "label": "MH"
            },
            {
                "name": "Maryland",
                "label": "MD"
            },
            {
                "name": "Massachusetts",
                "label": "MA"
            },
            {
                "name": "Michigan",
                "label": "MI"
            },
            {
                "name": "Minnesota",
                "label": "MN"
            },
            {
                "name": "Mississippi",
                "label": "MS"
            },
            {
                "name": "Missouri",
                "label": "MO"
            },
            {
                "name": "Montana",
                "label": "MT"
            },
            {
                "name": "Nebraska",
                "label": "NE"
            },
            {
                "name": "Nevada",
                "label": "NV"
            },
            {
                "name": "New Hampshire",
                "label": "NH"
            },
            {
                "name": "New Jersey",
                "label": "NJ"
            },
            {
                "name": "New Mexico",
                "label": "NM"
            },
            {
                "name": "New York",
                "label": "NY"
            },
            {
                "name": "North Carolina",
                "label": "NC"
            },
            {
                "name": "North Dakota",
                "label": "ND"
            },
            {
                "name": "Northern Mariana Islands",
                "label": "MP"
            },
            {
                "name": "Ohio",
                "label": "OH"
            },
            {
                "name": "Oklahoma",
                "label": "OK"
            },
            {
                "name": "Oregon",
                "label": "OR"
            },
            {
                "name": "Palau",
                "label": "PW"
            },
            {
                "name": "Pennsylvania",
                "label": "PA"
            },
            {
                "name": "Puerto Rico",
                "label": "PR"
            },
            {
                "name": "Rhode Island",
                "label": "RI"
            },
            {
                "name": "South Carolina",
                "label": "SC"
            },
            {
                "name": "South Dakota",
                "label": "SD"
            },
            {
                "name": "Tennessee",
                "label": "TN"
            },
            {
                "name": "Texas",
                "label": "TX"
            },
            {
                "name": "Utah",
                "label": "UT"
            },
            {
                "name": "Vermont",
                "label": "VT"
            },
            {
                "name": "Virgin Islands",
                "label": "VI"
            },
            {
                "name": "Virginia",
                "label": "VA"
            },
            {
                "name": "Washington",
                "label": "WA"
            },
            {
                "name": "West Virginia",
                "label": "WV"
            },
            {
                "name": "Wisconsin",
                "label": "WI"
            },
            {
                "name": "Wyoming",
                "label": "WY"
            }
        ]

    ;


    const onSubmit = async (user) => {
        let IsPhone2DropdownSelected = false;
        setPhoneNumber_2ErrorMessage('')
        //let phoneList = [{type:selectedPhoneType1.text.toLowerCase(),number:phoneNumber},{type:selectedPhoneType2.text.toLowerCase(),number:phoneNumber_2}]
        let phoneList = [{type:selectedPhoneType1.label?selectedPhoneType1.label.toLowerCase():'',number:(phoneNumber === '1' || phoneNumber === '') ? '' : phoneNumber},{type:selectedPhoneType2.label && selectedPhoneType2.label !== 'Select'?selectedPhoneType2.label.toLowerCase():'',number:(phoneNumber_2 === '1' || phoneNumber_2 === '') ? '' : phoneNumber_2}]
        let addObj = selectedAdd
        addObj.address_line1 = getValues('address1')
        addObj.address_line2 = getValues('address2')
        addObj.city = getValues('city')
        addObj.state = countryState ? countryState.label : ''
        addObj.zip = getValues('zipcode')
        setSelectedAdd(addObj)
        let obj = {
            "userId" : props.userInfo,
            "phone" : JSON.stringify(phoneList),
            "first_name" : getValues('firstName'),
            "last_name" : getValues('lastName'),
            "address" : selectedAdd
        }
        // const isValid =  validatePhoneNumber(phoneNumber)
        // if(!isValid){
        //     setphoneNumberError(true)
        // }else {
        //     setphoneNumberError(false)
        // }
        // const isValidPhone2 =  validatePhoneNumber(phoneNumber_2)
        // if(phoneNumber_2.length > 1 && !isValidPhone2){
        //     setphoneNumber2Error(true)
        // }else {
        //     setphoneNumber2Error(false)
        // }
       // await onBlurEventHandlerPhone();
        const isValid =  validatePhoneNumber(phoneNumber)
        if(!isValid){
            setphoneNumberError(true)
            return;
        }else {
            setphoneNumberError(false)
        }
        await onBlurEventHandlerPhone2();
        if(phoneNumber_2.length > 1 && phoneNumber_2 !== "1" &&  Object.keys(selectedPhoneType2).length === 0 ){
            setIsPhone2DropdownSelect(true)
            IsPhone2DropdownSelected = true
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            return;
        }else{
            if(Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label !== 'Select' && (phoneNumber_2 === "" || phoneNumber_2 === "1")){
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone2DropdownSelected = false
        }
        if(!phoneNumberError && !phoneNumber2Error && !IsPhone2DropdownSelected){
            setIsBtnDisabled(true)
            await props.onSubmit(obj)
           // setIsBtnDisabled(false)
        }
    };

    const handleChangeFirstName = (value) => {
        if(value.trim().length === 0){
            setValue("firstName",'')
            setFirstName('');
        }
        else{
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setValue("firstName",value)
                setFirstName(value);
            }
        }
    }

    const handleAddress1 = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setValue("address1",value)
            setAddress1(value);
        }
    }

    const handleAddress2 = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setValue("address2",value)
            setAddress2(value);
        }
    }

    const handleCity = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setValue("city",value)
            setCity(value);
        }
    }

    const handleZip = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setValue("zipcode",value)
            setZip(value);
        }
    }

    const handleState = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setValue("state",value)
            setState(value);
        }
    }

    const handleChangeLastName = (value) => {
        if(value.trim().length === 0){
            setValue("lastName",'')
            setLastName('');
        }
        else{
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setValue("lastName",value)
                setLastName(value);
            }
        }
    }

    const validatePhoneNumber = (e) => {
        const validNumber = '+'+e.toString()
        //    console.log('validNumber',validNumber, typeof validNumber)
        const phoneNumber = isValidPhoneNumber(validNumber)
        //    console.log('phoneNumber')
        return phoneNumber
    }

    const renderItem = async (item) =>{
        //console.log("placeDeta", placeDeta)
        return (
            <div className="row" key={item.place_id}>
                <div className="col-md-1">
                    {item.description}
                </div>
            </div>
        )
        //return();
    }
    useEffect(() => {
         if(Object.keys(props.userInfo).length > 0 && props.userInfo.phones && props.userInfo.phones.length > 0 && props.dependent && Object.keys(props.dependent).length > 0 && props.userSubscription?.subscription_status === A12Y_CONST.TEXT.ACTIVE_UPPER){
             history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW)
        }
    })
    const selectAdd = (add) =>{
        if(add){
            setShowAddressDropDown(false)
            const request = {
                placeId: add.place_id,
                fields: ["name", "formatted_address", "place_id", "geometry", "address_components"],
            };
            const service = new window.google.maps.places.PlacesService(document.createElement("div"));

            service.getDetails(request, (place, status) => {
                setValue("address1", '')
                place.address_components.forEach((item)=>{
                    if(item.types.length > 0){
                        item.types.forEach((type)=>{
                            if(type === "street_number"){
                                setValue("address1",item.short_name)
                            }
                            if(type === "route"){
                                let add = getValues("address1")
                                if(add.trim() === ""){
                                    setValue("address1",item.short_name)
                                }else {
                                    setValue("address1",add+" "+item.short_name)
                                }
                            }
                            if(type === "postal_code"){
                                setValue("zipcode",item.short_name)
                                setZip(item.short_name)
                            }
                            if(type === "locality"){
                                setValue("city",item.short_name)
                                setCity(item.short_name)
                            }
                            /*if(type === "neighborhood"){
                                setValue("address2",item.short_name)
                            }
                            if(type === "sublocality_level_1"){
                                let add = getValues("address2")
                                if(add.trim() === ""){
                                    setValue("address2",item.short_name)
                                }else {
                                    setValue("address2",add+" "+item.short_name)
                                }

                            }*/
                            if(type === "administrative_area_level_1"){
                                let stateObj = countryStateList.filter(data =>data.label === item.short_name)
                                setCountryState(stateObj[0])
                                //setValue("address2",item.short_name)
                            }
                        })
                    }
                })
            });



            let address1 = getValues("address1")
            if(address1.trim() === ""){
                setValue("address1",address1.formatted_address)
            }

        }
        let addObj = {
            "address_line1" : getValues('address1'),
            "address_line2" : getValues('address2'),
            "city" : getValues('city'),
            "state" : countryState ? countryState.label : '',
            "zip" : getValues('zipcode'),
            "country_code" : "USA"

        }
        setSelectedAdd(addObj)
        setDropDownIndex(null)
    }


    const checkValidation = (isvalid) => {
    }
    const handleChangePhoneType1 = async e => {
        if(e.label === 'Select'){

        }else {
            setIsValueChanged(true)
            await  setSelectedPhoneType1(e);
        }
    }
    const handleChangePhoneType2 = e => {
        if(e.label === 'Select'){
            setPhoneNumber_2("1")
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone2();
        }
        setIsValueChanged(true)
        setSelectedPhoneType2(e);
    }
    const handleChangeCountryState = e => {
        setIsValueChanged(true)
        setCountryState({
            name:e.name,
            label:e.label,
        });
    }
    const handlePhoneField = e => {
        if(e.length > 1){
            setPhoneType1LabelActive(true)
        }else{
            setPhoneType1LabelActive(false);
        }
       /* const isValid =  validatePhoneNumber(e)
        console.log('valid', isValid)
        if(!isValid){
            setphoneNumberError(true)
        }else {
            setphoneNumberError(false)
        }*/
        setPhoneNumber(e)
    }
    const onBlurEventHandlerPhone = () => {
  
        if(phoneNumber.length > 1){
            const isValid =  validatePhoneNumber(phoneNumber)
            if(!isValid){
                setphoneNumberError(true)
                setPhoneNumber_Error_Message(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
            }else {
                setphoneNumberError(false)
            }
        }
    }

    const handlePhoneField_2 = e => {
        if(e.length > 1){
            setPhoneType2LabelActive(true)
            setCountryCodeEditable(true);
        }else{
            setCountryCodeEditable(false);
            setPhoneType2LabelActive(false);
        }
        setPhoneNumber_2(e)
    }
    const onBlurEventHandlerPhone2 = () => {
        const isValid =  validatePhoneNumber(phoneNumber_2)
        if(phoneNumber_2.length > 1 && !isValid){
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }
    }
    const onFocusEventHandler = () => {
        if(showAddressDropDown){
            setShowAddressDropDown(false)
        }
    }
    const stateOptions = countryStateList && countryStateList.map(state =>{
        return {label: state.label, value: state.label}
    })

    const phoneTypeOptions = phoneTypeList && phoneTypeList.map(phoneTypeList =>{
        return {label:phoneTypeList.label, value: phoneTypeList.label}
    })

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }


    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        props.onCancel()
        await setCountryCodeEditable(true)
       await assingDefaultValue()
        setIsPopUpVisible(false);
        setIsValueChanged(false)
    }

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }else {
            props.onCancel()
        }
    }

    useEffect(()=>{
        let userInfo = props.userInfo;
        setIsDataModified(false)
        props.isDataChanged(false)
        setIsBtnDisabled(true)
        if(isEditMode){
            if(props.onFlow){
                if(firstName !=="" && lastName !== "" && phoneNumber !== "" && phoneNumber.length > 1){
                    setIsBtnDisabled(false)
                }else {
                    setIsBtnDisabled(true)
                }
            }
            if(userInfo.first_name !== firstName || userInfo.last_name !== lastName || (Object.keys(userInfo).length > 0 && userInfo.phones !== null && userInfo.phones.length > 0 && phoneNumber_2 ==="1" && userInfo.phones[1].number !== '' )||(userInfo.phones === null && phoneNumber !== "" && phoneNumber !== '1') || (userInfo.phones && userInfo.phones.length > 0 && (userInfo.phones[0].type !== selectedPhoneType1.value.toLowerCase() || userInfo.phones[0].number !== phoneNumber || ((Object.keys(selectedPhoneType2).length > 0 && (userInfo.phones[1].type !== selectedPhoneType2?.value?.toLowerCase())) || (userInfo.phones[1].number !== phoneNumber_2 && phoneNumber_2 !=="1")))) || (userInfo.address && Object.keys(userInfo.address).length > 0 && (userInfo.address.address_line1 !== address1 || userInfo.address.address_line2 !== address2 || userInfo.address.city !== city || userInfo.address.zip !== zip || (countryState && Object.keys(countryState).length > 0 && userInfo.address.state !== countryState.label) ))){
                if(!isSubmitting && firstName !=="" && lastName !== "" && phoneNumber !== "" && phoneNumber.length > 1){
                    setIsBtnDisabled(false)
                }
                setIsDataModified(true)
                props.isDataChanged(true)
            }
        }
    },[firstName,getValues("lastName"),getValues('address1'),lastName,address1,address2,city,zip,phoneNumber,phoneNumber_2,selectedPhoneType1,selectedPhoneType2,countryState,isValueChanged,isEditMode]) // eslint-disable-line react-hooks/exhaustive-deps


    const onMouseMoveEvent = async ()=>{
        if(dropDownIndex !== null){
            setDropDownIndex(null)
        }
    }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter' && dropDownIndex !== null){
            e.preventDefault();
        }
    };

    return (
        <div className='myAccountPersonalInfo marginTop5' onClick={()=>setShowAddressDropDown(false)}>
            {error ?
                <div className='maxWidth600 marginNone marginLeftRightAuto'>
                    <ErrorMessageComponent errorMessage={props.errorMessage}/>
                </div>
                :
                (props.message ?
                    <SuccessMessageComponent successMessage={props.message}/>

                        :
                        null
                )

            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className="fullWidth">
                {props.onFlow ?
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}
                          className='marginTop12 paddingTop55 newFormUI myAccountFormFiled formMyPersonalInfo marginTop32_600'
                          autoComplete="off">
                        <div className='personalInfoNameRow fullWidth'>
                            <div className='fullWidth firstLastNameSpace'>
                                <div className='width50 formCol paddingRight16 myProfile_personal_FirstName600'>
                                    <div className='fullWidth'><label>{A12Y_CONST.LABEL.YOUR_FIRST_NAME}<span
                                        className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label></div>
                                    <input
                                        {...register('firstName', {
                                            required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_REQUIRED, pattern: {
                                                value: /^.{1,}$/,
                                                //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                                message: A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_INVALID
                                            }
                                        })}
                                        placeholder='First Name'
                                        className={errors.firstName ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                        type='text'
                                        id='userFirstName'
                                        onChange={(e) => handleChangeFirstName(e.target.value)}
                                        value={firstName}
                                        maxLength="100"
                                        autoComplete="none"
                                        // onFocus={onFocusEventHandler}
                                        onFocus={() => {
                                            setError("firstName", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'> {errors.firstName &&
                                    <p className='errorMessage'>{errors.firstName.message}</p>}</div>
                                </div>
                                <div className='width50 paddingleft16 formCol responsive6000LeftRight'>
                                    <div className='fullWidth'><label>{A12Y_CONST.LABEL.YOUR_LAST_NAME}<span
                                        className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label></div>
                                    <input
                                        {...register('lastName', {
                                            required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_REQUIRED, pattern: {
                                                value: /^.{1,}$/,
                                                //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                                message: A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_INVALID
                                            }
                                        })}
                                        placeholder='Last Name'
                                        className={errors.lastName ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                        type='text'
                                        id='userLastName'
                                        maxLength="100"
                                        onChange={(e) => handleChangeLastName(e.target.value)}
                                        value={lastName}
                                        autoComplete="none"
                                        // onFocus={onFocusEventHandler}
                                        onFocus={() => {
                                            setError("lastName", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'>{errors.lastName &&
                                    <p className='errorMessage'>{errors.lastName.message}</p>}</div>
                                </div>
                            </div>
                        </div>
                        <div className='personalInfoNameRow newPhoneList fullWidth firstLastNameSpace'>
                            <div
                                className='maxWidth420 fullWidth  paddingRight16 formCol selectBoxParend myProfilePhoneCol1_600'>
                                <div
                                    className={(selectedPhoneType1 && Object.keys(selectedPhoneType1).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol  positionAbsoute positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                    <div className='fullWidth'><label>{A12Y_CONST.LABEL.PHONE1}<span
                                        className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label></div>
                                    <Select
                                        selectdisplayprops={selectedPhoneType1}
                                        defaultValue={phoneTypeOptions[0]}
                                        className='phoneTypeLabel'
                                        classNamePrefix={phoneType1LabelActive ? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                        placeholder="Select"
                                        value={selectedPhoneType1}
                                        options={phoneTypeOptions.filter(item=>item.label !== 'Select')}
                                        onChange={handleChangePhoneType1}
                                        // getOptionLabel={e => (
                                        //     <div className='phoneListRow'>
                                        //     <div className='phoneListIcon'>
                                        //         {selectedPhoneType1.value === e.value ? e.iconActive : e.icon}
                                        //     </div>
                                        //     <span className={selectedPhoneType1.value === e.value ? phoneType1LabelActive? 'phoneListText selectColor phone1LabelActive' :'phoneListText selectColor'  : 'phoneListText'}>{e.text}</span>
                                        //     </div>
                                        // )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />

                                </div>
                                <div className='marginTop27 myProfilePhoneNumber600'>
                                    <PhoneInput
                                        country='us'
                                        countryCodeEditable={false}
                                        containerClass={phoneType1LabelActive ? phoneNumberError ? 'errorInputBorderRedInput phoneNumber_Active' : 'phoneNumber_Active' : phoneNumberError ? 'errorInputBorderRedInput' : null}
                                        value={phoneNumber}
                                        // onFocus={onFocusEventHandler}
                                        onFocus={() => setphoneNumberError(false)}
                                        onBlur={onBlurEventHandlerPhone}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true
                                        }}
                                        onChange={phone => {
                                            handlePhoneField(phone)
                                        }}
                                        placeholder='+1'
                                        // isValid={(value, country) => {
                                        //     if (value.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) {
                                        //         return 'Invalid value: '+value+', '+country.name;
                                        //     } else if (value.match(/1234/)) {
                                        //         return 'Invalid value: '+value+', '+country.name;
                                        //     } else {
                                        //         return true;
                                        //     }
                                        // }}
                                    />
                                </div>
                                <div className='errorRelativeRow'> {phoneNumberError ?
                                    <p className='errorMessage'>{phoneNumber_Error_Message}</p> : null}</div>
                            </div>
                            {/*<div className='width48 marginTop768_10 formCol floatRight paddingleft16 selectBoxParend myProfilePhoneCol2_600'>*/}
                            {/*    <div className={(selectedPhoneType2 && Object.keys(selectedPhoneType2).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol firstLastNameSpace positionAbsoute positionTop maxWidth138px phoneDropDownResponsiveCol'}>*/}
                            {/*        <Select*/}
                            {/*           // selectdisplayprops={selectedPhoneType2}*/}
                            {/*            className='phoneTypeLabel'*/}
                            {/*            classNamePrefix={phoneType2LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}*/}
                            {/*            placeholder="Select"*/}
                            {/*            value={selectedPhoneType2.label ? selectedPhoneType2 : ""}*/}
                            {/*            options={phoneTypeOptions}*/}
                            {/*            onChange={handleChangePhoneType2}*/}
                            {/*            // getOptionLabel={e => (*/}
                            {/*            //     <div className='phoneListRow'>*/}
                            {/*            //         <div className='phoneListIcon'>*/}
                            {/*            //             {selectedPhoneType2.value === e.value ? e.iconActive : e.icon}*/}
                            {/*            //         </div>*/}
                            {/*            //         <span className={selectedPhoneType2.value === e.value ? phoneType2LabelActive? 'phoneListText selectColor phone1LabelActive' :'phoneListText selectColor'  : 'phoneListText'}>{e.text}</span>*/}
                            {/*            //     </div>*/}
                            {/*            // )}*/}
                            {/*            theme={(theme) => ({*/}
                            {/*                ...theme,*/}
                            {/*                borderRadius: 0,*/}
                            {/*                colors: {*/}
                            {/*                    ...theme.colors,*/}
                            {/*                    text: 'red',*/}
                            {/*                    primary: '#0096C708',*/}
                            {/*                },*/}
                            {/*            })}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*  */}
                            {/*    <div className="marginTop25 myProfilePhoneNumber600">*/}
                            {/*        <PhoneInput*/}
                            {/*            country='us'*/}
                            {/*            countryCodeEditable={false}*/}
                            {/*           // countryCodeEditable={countryCodeEditable}*/}
                            {/*            // containerClass={phoneType2LabelActive ? 'phoneNumber_Active': null}*/}
                            {/*            containerClass={phoneType2LabelActive ? phoneNumber2Error? 'errorInputBorderRedInput phoneNumber_Active':'phoneNumber_Active' : phoneNumber2Error? 'errorInputBorderRedInput': null}*/}
                            {/*            // placeholder='Select'*/}
                            {/*            value={phoneNumber_2}*/}
                            {/*            // onFocus={onFocusEventHandler}*/}
                            {/*            onFocus={() => setphoneNumber2Error(false)}*/}
                            {/*            onBlur={onBlurEventHandlerPhone2}*/}
                            {/*            placeholder='+1'*/}
                            {/*            inputProps={{*/}
                            {/*                name: 'phone1',*/}
                            {/*                required: false,*/}
                            {/*                autoFocus: false*/}
                            {/*            }}*/}
                            {/*            onChange={phone => {handlePhoneField_2(phone)}}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*    <div className='errorRelativeRow'> {phoneNumber2Error || isPhone2DropdownSelect? <p className='errorMessage'>{phoneNumber_2ErrorMessage}</p> : null }</div>*/}
                            {/*</div>*/}

                        </div>
                        <div className='firstLastNameSpace fullWidth marginTop768_20 arressFirstRow myProfileAddres1'>
                            <div className='fullWidth paddingRight16 width50 '>
                                <div className='fullWidth'><label>{A12Y_CONST.LABEL.ADDRESS_1}</label></div>
                                <input
                                    {...register('address1',)}
                                    placeholder='Street Address'
                                    className='inputColum24size'
                                    type='text'
                                    onKeyUp={(evt) => {
                                        //setPlacePredictionList([])
                                        //setPlaceServiceList([])
                                        if (evt.target.value === " ") {
                                            setValue("address1", evt.target.value.trim())
                                            setAddress1(evt.target.value.trim());
                                        } else {
                                            setValue("address1", evt.target.value)
                                            setAddress1(evt.target.value);
                                        }
                                        if (evt.target.value.length > 2) {
                                            if (evt.key === "ArrowDown" || evt.key === "ArrowUp" || evt.key === 'Enter') {
                                                if (dropDownIndex === null) {
                                                    setDropDownIndex(0)
                                                } else {
                                                    setDropDownIndex(evt.key === "ArrowUp" && dropDownIndex !== 0 ? dropDownIndex - 1 : evt.key === "ArrowDown" && placeServiceList.length - 1 > dropDownIndex ? dropDownIndex + 1 : dropDownIndex)
                                                    if (evt.key === 'Enter' && dropDownIndex !== null && placeServiceList.length > 0) {
                                                        selectAdd(placeServiceList[dropDownIndex])
                                                    }
                                                }

                                            } else {
                                                setShowAddressDropDown(true)
                                                //getPlacePredictions({ input: evt.target.value });
                                                getPlaceFromService({input: evt.target.value});
                                            }

                                        }
                                    }}
                                    // loading={isPlacePredictionsLoading}
                                    id='address1'
                                    autoComplete="new-no-address1"
                                    //onBlur={()=>setShowAddressDropDown(false)}
                                />
                                {/*<Autocomplete
                            className='inputColum24size'
                            apiKey={CONFIG.GOOGLE_PLACES_KEY}
                            options={{componentRestrictions:["us"], fields:["address_component", "adr_address", "formatted_address", "name", "geometry.location"], types:[]}}
                            onPlaceSelected={(place) => {
                                console.log(place);
                            }}
                        />*/}
                                {/*<div onClick={()=>{selectAdd(null)}} >Hello</div>)*/}
                                {showAddressDropDown && placeServiceList.length > 0 ?
                                    <div className='addressLin1DropDown'>
                                        <div className="addressLin1DropDownInner">
                                            {placeServiceList && placeServiceList.map((item, key) =>
                                                (item && item.description ?
                                                        <div onMouseMove={onMouseMoveEvent}
                                                             className={dropDownIndex === key ? 'addressListDropDownHighlight' : dropDownIndex === null ? 'addressListDropDown addressHover' : 'addressListDropDown'}
                                                             onClick={() => {
                                                                 selectAdd(item)
                                                             }} key={key}>{item.description}</div>
                                                        :
                                                        null
                                                )
                                            )}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {/*{placePredictions.map((item) => <div>{item.description}</div>)}*/}

                            </div>
                            <div className='fullWidth paddingleft16 width50 myProfileAddres2'>
                                <div className='fullWidth'><label>{A12Y_CONST.LABEL.ADDRESS_2}</label></div>
                                <input
                                    {...register('address2',)}
                                    placeholder='Apt., Suite, Unit, Building'
                                    className='inputColum24size'
                                    type='text'
                                    id='address2'
                                    onFocus={onFocusEventHandler}
                                    onChange={(e) => handleAddress2(e.target.value)}
                                    value={address2}
                                    autoComplete="new-no-address2"
                                />
                            </div>
                        </div>

                        <div className='personalInfoNameRow fullWidth marginTop768_20 myProfileAddres1'>

                            <div className=' fullWidth firstLastNameSpace positionRelative'>
                                <div className='fullWidth marginBottom10 cityCountryState'>
                                    <div className='width32 city'>
                                        <div className='fullWidth'><label
                                            className='addressLable positionTop768_25'>{A12Y_CONST.LABEL.CITY}</label>
                                        </div>
                                        <input
                                            {...register('city',)}
                                            placeholder='City'
                                            className='inputColum24size'
                                            type='text'
                                            id='city'
                                            onFocus={onFocusEventHandler}
                                            onChange={(e) => handleCity(e.target.value)}
                                            value={city}
                                            autoComplete="new-no-city"
                                        />
                                    </div>
                                    <div className='width32 dependentResidencCol marginLeftRight2Percent countryState'>
                                        <div
                                            className={(countryState && Object.keys(countryState).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol floatLeft positionRelative    countryState'}>
                                            <div className='fullWidth'><label
                                                className='labelCol stateLabel'>{A12Y_CONST.LABEL.STATE}</label></div>
                                            <Select
                                                // selectdisplayprops={countryState}
                                                // defaultValue={countryStateList[0]}
                                                className='phoneTypeLabel'
                                                classNamePrefix={'dependentResidence'}
                                                placeholder="State"
                                                value={countryState.label ? countryState : ""}
                                                options={stateOptions}
                                                onChange={handleChangeCountryState}
                                                // getOptionLabel={e => (
                                                //     <div className='phoneListRow'>
                                                //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                                //     </div>
                                                // )}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: 'red',
                                                        primary: '#0096C708',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className='personalInfoNameRow width32 marginTop768_20 myProfileAddres1'>
                                        <div className='fullWidth '><label
                                            className='addressLable positionTop768_25'>{A12Y_CONST.LABEL.ZIP}</label>
                                        </div>
                                        <div className='fullWidth'>
                                            <div className='fullWidth myProfileZipCode'>
                                                <input
                                                    {...register('zipcode',)}
                                                    placeholder='00000'
                                                    className='inputColum24size'
                                                    type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                                    id='zipcode'
                                                    onChange={(e) => handleZip(e.target.value)}
                                                    value={zip}
                                                    onWheel={e => e.target.blur()}
                                                    onFocus={onFocusEventHandler}
                                                    autoComplete="new-pincode"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className=' fullWidth firstLastNameSpace positionRelative'>*/}
                        {/*    <div className='width50'>*/}
                        {/*        <div className='fullWidth'> <label className='addressLable positionTop768_25'>{A12Y_CONST.LABEL.COUNTY}</label></div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className='fullWidth marginTop35'>*/}
                        {/*<input id='signInButton' className={isSubmitting ? "buttonDisable" :'button'} disabled={isSubmitting}  type="button" onClick={handleSubmit(onSubmit)} value='Save'/>*/}
                        {/*</div>*/}
                        <div className="fullWidth textAlignRight signUpFlowButtonRow marginBottom30 displayFlexCol ">
                            {/*<div className='widthAuto'><input className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>*/}
                            <div className='widthAuto marginLeft20'>
                                <div className='loaderSpinner'>
                                    {loading ?
                                        <Loading/>
                                        :
                                        null
                                    }
                                    <input id='AdvocateProfile'
                                           className={isBtnDisabled ? "saveButtonDisable buttonHeight56" : 'saveButton buttonHeight56'}
                                           disabled={isBtnDisabled} type="submit"
                                           value={A12Y_CONST.BUTTON_TEXT.CONTINUE}/>
                                </div>
                            </div>
                        </div>
                    </form>
                :
                    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)} className='marginTop12 myAccountFormFiled formMyPersonalInfo' autoComplete="off">
                        <div className="fullWidth">
                            <h3 className='paddingLeft35 myAccountTitle marginTop50'>{A12Y_CONST.TEXT.PERSONAL}</h3>
                        </div>
                        <div className='personalInfoNameRow fullWidth'>
                            <label className='nameLable'>{A12Y_CONST.LABEL.GAURDIAN_PROFILE_PERSONAL_INFO_NAME}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className='fullWidth firstLastNameSpace'>
                                <div className='width50 paddingRight16 myProfile_personal_FirstName600'>
                                    <input
                                        {...register('firstName', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_REQUIRED, pattern:{
                                                value:/^.{1,}$/,
                                                //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                                message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_INVALID
                                            }})}
                                        placeholder='First Name'
                                        className={errors.firstName ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                        type='text'
                                        id='userFirstName'
                                        onChange={(e) => handleChangeFirstName(e.target.value)}
                                        value={firstName}
                                        maxLength="100"
                                        autoComplete="none"
                                        // onFocus={onFocusEventHandler}
                                        onFocus={()=>{
                                            setError("firstName", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'> {errors.firstName && <p className='errorMessage'>{errors.firstName.message}</p>}</div>
                                </div>
                                <div className='width50 paddingleft16 responsive6000LeftRight'>
                                    <input
                                        {...register('lastName', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_REQUIRED , pattern:{
                                                value:/^.{1,}$/,
                                                //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                                message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_INVALID
                                            }})}
                                        placeholder='Last Name'
                                        className={errors.lastName ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                        type='text'
                                        id='userLastName'
                                        maxLength="100"
                                        onChange={(e) => handleChangeLastName(e.target.value)}
                                        value={lastName}
                                        autoComplete="none"
                                        // onFocus={onFocusEventHandler}
                                        onFocus={()=>{
                                            setError("lastName", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'>{errors.lastName && <p className='errorMessage'>{errors.lastName.message}</p>}</div>
                                </div>
                            </div>
                        </div>
                        <div className='personalInfoNameRow fullWidth' >
                            <label className='myProfle_phone_label600'>{A12Y_CONST.LABEL.GAURDIAN_PROFILE_PERSONAL_INFO_PHONE}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className='width50 newPhoneList paddingRight16 selectBoxParend myProfilePhoneCol1_600'>
                                <div className={(selectedPhoneType1 && Object.keys(selectedPhoneType1).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol firstLastNameSpace positionAbsoute positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                    <Select
                                        selectdisplayprops={selectedPhoneType1}
                                        defaultValue={phoneTypeOptions[0]}
                                        className='phoneTypeLabel'
                                        classNamePrefix={phoneType1LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                        placeholder="Select"
                                        value={selectedPhoneType1}
                                        options={phoneTypeOptions.filter(item=>item.label !== 'Select')}
                                        onChange={handleChangePhoneType1}
                                        // getOptionLabel={e => (
                                        //     <div className='phoneListRow'>
                                        //     <div className='phoneListIcon'>
                                        //         {selectedPhoneType1.value === e.value ? e.iconActive : e.icon}
                                        //     </div>
                                        //     <span className={selectedPhoneType1.value === e.value ? phoneType1LabelActive? 'phoneListText selectColor phone1LabelActive' :'phoneListText selectColor'  : 'phoneListText'}>{e.text}</span>
                                        //     </div>
                                        // )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />

                                </div>
                                <div className='marginTop25 myProfilePhoneNumber600'>
                                    <PhoneInput
                                        country='us'
                                        countryCodeEditable={false}
                                        containerClass={phoneType1LabelActive ? phoneNumberError? 'errorInputBorderRedInput phoneNumber_Active':'phoneNumber_Active' : phoneNumberError? 'errorInputBorderRedInput': null}
                                        value={phoneNumber}
                                        // onFocus={onFocusEventHandler}
                                        onFocus={() => setphoneNumberError(false)}
                                        onBlur={onBlurEventHandlerPhone}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true
                                        }}
                                        onChange={phone => {handlePhoneField(phone)}}
                                        placeholder='+1'
                                        // isValid={(value, country) => {
                                        //     if (value.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) {
                                        //         return 'Invalid value: '+value+', '+country.name;
                                        //     } else if (value.match(/1234/)) {
                                        //         return 'Invalid value: '+value+', '+country.name;
                                        //     } else {
                                        //         return true;
                                        //     }
                                        // }}
                                    />
                                </div>
                                <div className='errorRelativeRow'> {phoneNumberError ? <p className='errorMessage'>{phoneNumber_Error_Message}</p> : null }</div>
                            </div>
                            <div className='width48 marginTop768_10 floatRight paddingleft16 selectBoxParend myProfilePhoneCol2_600'>
                                <div className={(selectedPhoneType2 && Object.keys(selectedPhoneType2).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol firstLastNameSpace positionAbsoute positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                    <Select
                                        // selectdisplayprops={selectedPhoneType2}
                                        className='phoneTypeLabel'
                                        classNamePrefix={phoneType2LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                        placeholder="Select"
                                        value={selectedPhoneType2.label ? selectedPhoneType2 : ""}
                                        options={phoneTypeOptions}
                                        onChange={handleChangePhoneType2}
                                        // getOptionLabel={e => (
                                        //     <div className='phoneListRow'>
                                        //         <div className='phoneListIcon'>
                                        //             {selectedPhoneType2.value === e.value ? e.iconActive : e.icon}
                                        //         </div>
                                        //         <span className={selectedPhoneType2.value === e.value ? phoneType2LabelActive? 'phoneListText selectColor phone1LabelActive' :'phoneListText selectColor'  : 'phoneListText'}>{e.text}</span>
                                        //     </div>
                                        // )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />
                                </div>
                                {/*<input*/}
                                {/*{...register('phoneNumber2',)}*/}
                                {/*placeholder='(000) 000-0000'*/}
                                {/*className='inputColum marginTop35'*/}
                                {/*type='number'*/}
                                {/*id='phoneNumber'*/}
                                {/*/>*/}
                                <div className="marginTop25 myProfilePhoneNumber600">
                                    <PhoneInput
                                        country='us'
                                        countryCodeEditable={false}
                                        disabled={Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label === 'Select' ? true : false}
                                        // countryCodeEditable={countryCodeEditable}
                                        // containerClass={phoneType2LabelActive ? 'phoneNumber_Active': null}
                                        containerClass={phoneType2LabelActive ? phoneNumber2Error? 'errorInputBorderRedInput phoneNumber_Active':phoneNumber_2 === "1" ? 'phone_number_placeholder_color null' :'phoneNumber_Active' : phoneNumber2Error? 'errorInputBorderRedInput': null}
                                        // placeholder='Select'
                                        value={phoneNumber_2}
                                        // onFocus={onFocusEventHandler}
                                        onFocus={() => {
                                            setphoneNumber2Error(false)
                                            setIsPhone2DropdownSelect(false)
                                        }}
                                        onBlur={onBlurEventHandlerPhone2}
                                        placeholder='+1'
                                        inputProps={{
                                            name: 'phone1',
                                            required: false,
                                            autoFocus: false
                                        }}
                                        onChange={phone => {handlePhoneField_2(phone)}}
                                    />
                                </div>
                                <div className='errorRelativeRow'> {phoneNumber2Error || isPhone2DropdownSelect? <p className='errorMessage'>{phoneNumber_2ErrorMessage}</p> : null }</div>
                            </div>

                        </div>
                        <div className='personalInfoNameRow fullWidth marginTop30 marginTop768_20 arressFirstRow myProfileAddres1'>
                            <label className='addressLable positionTop768_25'>{A12Y_CONST.LABEL.GAURDIAN_PROFILE_PERSONAL_INFO_ADDRESS}</label>
                            <div className='fullWidth firstLastNameSpace'>
                                <input
                                    {...register('address1',)}
                                    placeholder='Street Address'
                                    className='inputColum24size'
                                    type='text'
                                    onKeyUp={(evt) => {
                                        //setPlacePredictionList([])
                                        //setPlaceServiceList([])
                                        if(evt.target.value === " "){
                                            setValue("address1",evt.target.value.trim())
                                            setAddress1(evt.target.value.trim());
                                        }else{
                                            setValue("address1",evt.target.value)
                                            setAddress1(evt.target.value);
                                        }
                                        if(evt.target.value.length > 2){
                                            if(evt.key === "ArrowDown" || evt.key === "ArrowUp" || evt.key === 'Enter'){
                                                if(dropDownIndex === null){
                                                    setDropDownIndex(0)
                                                }else {
                                                    setDropDownIndex(evt.key === "ArrowUp" && dropDownIndex !== 0 ? dropDownIndex -1 : evt.key === "ArrowDown" && placeServiceList.length-1 > dropDownIndex ? dropDownIndex +1 : dropDownIndex )
                                                    if(evt.key === 'Enter' && dropDownIndex !== null && placeServiceList.length > 0){
                                                        selectAdd(placeServiceList[dropDownIndex])
                                                    }
                                                }

                                            }else {
                                                setShowAddressDropDown(true)
                                                //getPlacePredictions({ input: evt.target.value });
                                                getPlaceFromService({ input: evt.target.value });
                                            }

                                        }
                                    }}
                                    // loading={isPlacePredictionsLoading}
                                    id='address1'
                                    autoComplete="new-no-address1"
                                    //onBlur={()=>setShowAddressDropDown(false)}
                                />
                                {/*<Autocomplete
                            className='inputColum24size'
                            apiKey={CONFIG.GOOGLE_PLACES_KEY}
                            options={{componentRestrictions:["us"], fields:["address_component", "adr_address", "formatted_address", "name", "geometry.location"], types:[]}}
                            onPlaceSelected={(place) => {
                                console.log(place);
                            }}
                        />*/}
                                {/*<div onClick={()=>{selectAdd(null)}} >Hello</div>)*/}
                                {showAddressDropDown && placeServiceList.length > 0?
                                    <div className='addressLin1DropDown'>
                                        <div className="addressLin1DropDownInner">
                                            {placeServiceList && placeServiceList.map((item,key) =>
                                                (item && item.description ?
                                                        <div onMouseMove={onMouseMoveEvent} className={dropDownIndex === key ? 'addressListDropDownHighlight' : dropDownIndex === null ? 'addressListDropDown addressHover' : 'addressListDropDown' } onClick={()=>{selectAdd(item)}} key={key}>{item.description}</div>
                                                        :
                                                        null
                                                )
                                            )}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {/*{placePredictions.map((item) => <div>{item.description}</div>)}*/}

                            </div>
                        </div>
                        <div className='personalInfoNameRow fullWidth marginTop768_20 myProfileAddres1 lblUnit'>
                            <label className='addressLable positionTop768_25'>{A12Y_CONST.LABEL.UNIT}</label>
                            <div className='fullWidth firstLastNameSpace myProfileAddres2'>
                                <input
                                    {...register('address2',)}
                                    placeholder='Apartment, Suite, Unit, Building'
                                    className='inputColum24size'
                                    type='text'
                                    id='address2'
                                    onFocus={onFocusEventHandler}
                                    onChange={(e) => handleAddress2(e.target.value)}
                                    value={address2}
                                    autoComplete="new-no-address2"
                                />
                            </div>
                        </div>
                        <div className='personalInfoNameRow fullWidth marginTop768_20 myProfileAddres1'>
                            <label className='addressLable positionTop768_25'>{A12Y_CONST.LABEL.CITY}</label>
                            <div className=' fullWidth firstLastNameSpace positionRelative cityWrap'>
                                <div className='fullWidth marginBottom10 cityCountryState'>
                                    <div className='width40 paddingRight35 city'>
                                        <input
                                            {...register('city',)}
                                            placeholder='City'
                                            className='inputColum24size'
                                            type='text'
                                            id='city'
                                            onFocus={onFocusEventHandler}
                                            onChange={(e) => handleCity(e.target.value)}
                                            value={city}
                                            autoComplete="new-no-city"
                                        />
                                    </div>
                                    <div className='width60 dependentResidencCol paddingLeft35 marginTop10 countryState'>
                                        <div className={(countryState && Object.keys(countryState).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol floatLeft positionRelative paddingLeft35 addressRow countryState'}>
                                            <label className='labelCol institutionStateLabel'>{A12Y_CONST.LABEL.STATE}</label>
                                            <Select
                                                // selectdisplayprops={countryState}
                                                // defaultValue={countryStateList[0]}
                                                className='phoneTypeLabel'
                                                classNamePrefix={'dependentResidence'}
                                                placeholder="State"
                                                value={countryState.label ? countryState : ""}
                                                options={stateOptions}
                                                onChange={handleChangeCountryState}
                                                // getOptionLabel={e => (
                                                //     <div className='phoneListRow'>
                                                //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                                //     </div>
                                                // )}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: 'red',
                                                        primary: '#0096C708',
                                                    },
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='personalInfoNameRow fullWidth marginTop768_20 myProfileAddres1'>
                            <label className='addressLable positionTop768_25 lblZip'>{A12Y_CONST.LABEL.ZIP}</label>
                            <div className='fullWidth firstLastNameSpace zipWrap'>
                                <div className='width37 width50Percent768 myProfileZipCode'>
                                    <input
                                        {...register('zipcode',)}
                                        placeholder='00000'
                                        className='inputColum24size'
                                        type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                        id='zipcode'
                                        onChange={(e) => handleZip(e.target.value)}
                                        value={zip}
                                        onWheel={e => e.target.blur()}
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-pincode"
                                    />
                                </div>
                            </div>
                        </div>

                        {/*<div className='fullWidth marginTop35'>*/}
                        {/*<input id='signInButton' className={isSubmitting ? "buttonDisable" :'button'} disabled={isSubmitting}  type="button" onClick={handleSubmit(onSubmit)} value='Save'/>*/}
                        {/*</div>*/}
                        <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol positionAbsouteOnly paddingRight70 bottom40 right" >
                            <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.MY_ACCOUNT_PERSONAL_INFO_CANCEL} className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>
                            <div className='widthAuto marginButton buttonRightMargin'>
                                <div className='loaderSpinner'>
                                    {loading ?
                                        <Loading />
                                        :
                                        null
                                    }
                                    <input id={A12Y_CONST.BUTTON_ID.MY_ACCOUNT_PERSONAL_INFO_SAVE} className={isBtnDisabled ? "saveButtonDisable" :'saveButton'} disabled={isBtnDisabled}  type="submit" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE}/>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </div>

            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>

    );
};

export default MyAccountPersonalInformationComponent;
