import React, { useState, useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import AssetTransferFormComponent from '../components/AssetTransferFormComponent'
import "react-datepicker/dist/react-datepicker.css";

function TransferPopUpComponent(props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
        setError(props.error)
        setLoading(props.loading);

    },[props.popUpStatus, props.error, props.loading])

    const handleSave = async (data,fileInfo,fileBase64Info,deletedFileInfo)=>{
        props.onSubmit(data,fileInfo,fileBase64Info,deletedFileInfo)
    }

    const onCancel = async ()=>{
        props.hidePopUp()
    }

    const dataChangeHandler = async (status)=>{
        props.isDataChanged(status)
    }

    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop'>
                        <div className='popContainer transfers popMaxWidth df aic'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className='popUpInner df'>
                                    {/*{error ?*/}
                                    {/*    <div className='maxWidth600 marginLeftRightAuto'>*/}
                                    {/*        <ErrorMessageComponent errorMessage={props.errorMessage}/>*/}
                                    {/*    </div>*/}
                                    {/*    :*/}
                                    {/*    (props.message ?*/}
                                    {/*            <SuccessMessageComponent successMessage={props.message}/>*/}

                                    {/*            :*/}
                                    {/*            null*/}
                                    {/*    )*/}

                                    {/*}*/}
                                    <AssetTransferFormComponent
                                        currentAsset={props.currentAsset}
                                        onSubmit={handleSave}
                                        hidePopUp={onCancel}
                                        loading={loading}
                                        BtnTxt={A12Y_CONST.BUTTON_TEXT.TRANSFER}
                                        dependent={props.dependent}
                                        editMode={false}
                                        error={error}
                                        errorMessage={props.errorMessage}
                                        message={props.message}
                                        isDataChanged={dataChangeHandler}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
        
    )
}

export default TransferPopUpComponent
