import React from 'react'
import A12Y_CONST from "../common/a12yConst";
function LoaderFullScreenComponent () {
    return (
        <div className="fullScreenLoader">
            <span className='loaderCenterCol'>
                <div className='fullWidth justifyCenter marginBottom10' style={{height:15}}>
                    <div className="stage">
                    <div className="dot-pulse"></div>
                  </div>
                </div>
                <span className='fontSize15'>{A12Y_CONST.TEXT.LOADING}</span>
            </span>
        </div>
    )
}

export default  LoaderFullScreenComponent;