import React, { useState, useEffect, useRef } from "react";
import A12Y_CONST from "../common/a12yConst";
import PopUpDocumentComponent from './PopUpDocumentComponent'
import PopupDocumentActionResponsive from './PopupDocumentActionResponsive'
import ErrorMessageComponent from "./ErrorMessageComponent";
import pdf from "../assets/images/pdf.png";
import jpg_icon from "../assets/images/jpg_icon.svg";
import doc_icon from "../assets/images/doc.svg";
import ai_icon from "../assets/images/AI.svg";
import jpg from "../assets/images/jpg.png";
import moment from 'moment';
import Dropzone from 'react-dropzone'
import {v4 as uuidv4} from "uuid";
import PopUpComponent from "./PopUpComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import expenseEmpty from "../assets/images/expenseEmpty.svg";
import expenseFIll from "../assets/images/expenseFIll.svg";
import financialEmpty from "../assets/images/financialEmpty.svg";
import financialFill from "../assets/images/financialFill.svg";
import incomeEmpty from "../assets/images/incomeEmpty.svg";
import incomeFIll from "../assets/images/incomeFIll.svg";
import legalEmpty from "../assets/images/legalEmpty.svg";
import legalFill from "../assets/images/legalFill.svg";
import medicalEmpty from "../assets/images/medicalEmpty.svg";
import medicalFIll from "../assets/images/medicalFIll.svg";
import trainingEmpty from "../assets/images/trainingEmpty.svg";
import trainingFill from "../assets/images/trainingFill.svg";
import blankFill from "../assets/images/blankFill.svg";
import blankEmpty from "../assets/images/blankEmpty.svg";
import advocord_admin from "../assets/images/advocord_admin.svg";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function DocumentComponent(props) {
    const [error, setErrors] = useState(false);
    /* eslint-disable no-unused-vars */
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    /* eslint-disable no-unused-vars */
    const [loading, setLoading] = useState(false);
    const [inputList, setInputList] = useState([
        {folderName: "Legal",type:'folder',file_type:null, isSelected:false},
        {folderName: "Financial",type:'folder',file_type:null, isSelected:false},
        {folderName: "Medical",type:'folder',file_type:null, isSelected:false},
        {folderName: "Traning",type:'folder',file_type:null, isSelected:false},
    ]);
    const lastRef = useRef(null);
    const dropDownRef = useRef(null);
    const [elementAdded, setElementAdded] = useState(false)
    const [selectedFile, setSelectedFile] = useState({});
    const [actionButton, setActionButton] = useState({
        addFile:false,
        uploadFile:false,
        downloadFile:false,
        deleteFile:false,
        popUpStatus:false,
    })
    const [popUpType, setPopUpType] = useState('')
    const [isPopUp, setIsPopUp] = useState(false)
    const [isPopUp2, setIsPopUp2] = useState(false)
    const [isPopUpVisible, setIsPopUpVisible] = useState(false)
    const [isChild, setIsChild] = useState(false)
    const [activeChild, setActiveChild] = useState({})

    const [documentList, setDocumentList] = useState([])
    const [documentChildList, setDocumentChildList] = useState([])
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const [updatedFolder, setUpdatedFolder] = useState({})
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [documentTopRowVisible, setDocumentTopRowVisible] = useState(false)
    const [isRenameMoveTo, setIsRenameMoveTo] = useState(true)
    const [parentMoveFolder, setParentMoveFolder] = useState({})
    const [selectedMoveFile, setSelectedMoveFile] = useState({})
    const [dropDownRefRight,setDropDownRefRight]  = useState('')
    const [dropDownRightAlign, setDropDownRightAlign] = useState(false);
    const [dropDownBottomAlign, setDropDownBottomAlign] = useState(false);
    const [isDrage, setIsDrage] = useState(false);
    const[isResponsive_moveFile,setIsResponsive_moveFile] = useState(false)
    const [isSorted, setIsSorted] = useState(false);
    const [documentSortClass, setDocumentSortClass] = useState('');


    useEffect(() => {
        if(!isDrage){
        let elm = document.getElementById('documentListRow');
        elm.addEventListener('dragover', dragOver);
        /*elm.addEventListener('dragenter', dragEnter);
        elm.addEventListener('dragleave', dragLeave);*/

        function dragOver(e) {
            e.preventDefault()
            this.className = props?.dependent?.access?.can_update ? 'documentListRow fullWi, isDragedth animationall df fcol hovered' : 'documentListRow fullWi, isDragedth animationall df fcol'
            setIsDrage(true);
        }

        // function dragEnter(e) {
        //     e.preventDefault()
        //     this.className = 'documentListRow fullWidth animationall df fcol hovered'
        //     setIsDrage(true);
        // }

        // function dragLeave() {
        //     // this.className = 'documentListRow fullWidth animationall df fcol'
        //     setIsDrage(false);
        // }
        }
    },[isDrage])

    useEffect(()=>{
        if(windowDimensions.width > 768) {
            if (isRenameMoveTo) {
                setSelectedMoveFile({})
                props.outSideClickHandler();
            } else {
                setIsRenameMoveTo(true)
            }
        }
    },[props.isOutSideClick]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleInputChange = (e, index, item) => {
        let obj = {
            id : item.id,
            display_name : e.target.value
        }
        setUpdatedFolder(obj)
        const { name, value } = e.target;
        if(!isChild) {
            setDocumentList(Object.values({...documentList, [index]: {...documentList[index], display_name: value}}))
        }else {
            setDocumentChildList(Object.values({...documentChildList, [index]: {...documentChildList[index], display_name: value}}))
        }
    };
    const handleonBlur =  () => {
        if(Object.keys(updatedFolder).length > 0){
            props.updateFileNFolder(updatedFolder)
            setUpdatedFolder({})
        }
    }
    useEffect(() => {
        setLoaderFullScreenVisible(true)
        setDocumentList(props.documentList)
        setLoaderFullScreenVisible(false)
    }, [props.documentList])

    useEffect(() => {
        setLoaderFullScreenVisible(true)
        if(isChild) {
            props.documentList.forEach(item =>{
                if(item.id === activeChild.id){
                    setDocumentChildList(item.childs)
                }
            })
        }
        setLoaderFullScreenVisible(false)
    }, [props.documentList]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lastRef.current){
            lastRef.current.focus();
        }
    }, [inputList]);

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
        setLoaderFullScreenVisible(props.loaderFullScreen)
    },[props.loading,props.error, props.loaderFullScreen])

    const elementSelect = async (item, i, e) => {
        e.stopPropagation();
        setSelectedMoveFile({})
        if(windowDimensions.width < 768 && item.is_folder){
            goToChildElement(item, i)
        }else {
            if(selectedFile.id && (selectedFile.id === item.id || item.sys_created === true)){
            /*if(selectedFile.id === item.id || item.sys_created === true){*/
                setSelectedFile({})
            }else {
               await setSelectedFile(item)
            }
        }

    }

    const showDocumentActionPopUp = async  (e,item, i) => {
        e.stopPropagation();
        if(selectedFile.id && (selectedFile.id === item.id || item.sys_created === true)){
            await setSelectedFile({})
            await setSelectedMoveFile({})
        }else {
            setIsPopUpVisible(true)
            await setSelectedFile(item)
            await setSelectedMoveFile(item)
        }
    }
    const addFile_Folder_popUP = () => {
        setSelectedFile({})
        setIsPopUp(true);
        setPopUpType(A12Y_CONST.LABEL.NEW_FOLDER)
    }
    const hidePopUp = () => {
        setIsPopUp(false);
    }
    const hidePopUp2 =  () => {
        setIsPopUp2(false)
    }
    const createNewFolder = (name) => {
        handleCreateFolder(name)
    }
    const goToChildElement = async (item, i) => {
        setSelectedFile({})
        await setIsChild(true)
        await setActiveChild(item)
        await setInputList([])
        await setDocumentChildList(item.childs)
    }
    useEffect( ()=>{
       const childElement = async ()=>{
           if(props.isCommingFromDependentView){
               let index = docList.findIndex(item => item.display_name === "Legal")
               await goToChildElement(docList[index],index)
           }
       }
       childElement()
    },[props.isCommingFromDependentView]) // eslint-disable-line react-hooks/exhaustive-deps



    const parentBoxActive = async () => {
        setSelectedFile({})
        setIsChild(false);
        setActiveChild('')
        await setDocumentList(props.documentList)
    }

    const handleCreateFolder = async (name) => {
        //setInputList([...inputList, { folderName: name, type:'folder', file_type:null}]);
        setElementAdded (true)
        setIsPopUp(false);
        await  setActionButton({...actionButton, addFile: false})
        let data = {
            "s3_key" : "s3-key",
            "display_name" : name,
            "file_content_type" : null,
            "is_folder" : true,
            "metadata" : {},
            "parent_id" : null
        }
        props.createNFolderOnly(data)

    };

    const onDrop = async (files) => {
        if(!activeChild?.metadata?.invoice_folder) {
            const result = files.filter(file => !file.path.includes('/'))
            if (result.length > 0) {
                await setActionButton({...actionButton, uploadFile: true})
                let uploadFiles = []
                uploadFiles = [...uploadFiles, ...result]
                imageInfo(uploadFiles)
            }
        }

        // uploadFiles.map((item) => {
        //     let obj = {
        //         folderName: item.name.split(".").shift(),
        //         type:'file',
        //         file_type:item.type.split("/").pop(),
        //         isSelected:false,
        //         uploadDate:moment(item.lastModifiedDate).format("MM/DD/YYYY HH:mm")
        //     }
        //     //setInputList([obj])
        //     setInputList( arr => [...arr, obj]);
        // })
        setIsDrage(false);
    }

    const fileUpload = async (event) => {
        await setActionButton({...actionButton, uploadFile: true})
        let uploadList = inputList
        let uploadFiles = []
        uploadFiles = [...uploadFiles, ...event.target.files]
        document.getElementById('fileUploadModal').value = "";
        //  var fileFolderList = await uploadFiles.map((item) => {
        //     let obj = {
        //         folderName: item.name.split(".").shift(),
        //         type:'file',
        //         file_type:item.type.split("/").pop(),
        //         isSelected:false,
        //         uploadDate:moment(item.lastModifiedDate).format("MM/DD/YYYY HH:mm")
        //     }
        //      //setInputList([obj])
        //      setInputList( arr => [...arr, obj]);
        // })
        imageInfo(uploadFiles)
        await setDocumentTopRowVisible(false)

    };

    const imageInfo = async (e) => {
        let data = {
            "s3_key" : "",
            "display_name" : '',
            "file_content_type" : null,
            "is_folder" : false,
            "metadata" : {},
            "parent_id" : isChild ? activeChild.id : null
        }
        let fileList = [];
        let base64List = [];
        for (var i = 0; i < e.length; i++) {
            if(!e[i].s3_key){
                let name = e[i].name
                let size = e[i].size
                let file = e[i];
                let reader = new FileReader();
                const uuid = uuidv4();
                let s3key = `${props.dependent.id}/document/${uuid}`
                reader.readAsDataURL(file);
                reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                        })
                );
                // extension = path.substring(fileName.lastIndexOf('.')+1);
                fileList.push({
                    s3_key: s3key,
                    file_id: uuid,
                    'contentType': e[i].type,
                    'fileMetadata': {
                        "about_dependent": "some more info"
                    },
                    'name' : name,
                    'size' : size
                });
            }
        }
        await  setFileInfo(fileList);
        await  setFileBase64Info(base64List)
        createFileNFolder(data, fileList, base64List)
        await setActionButton({...actionButton, uploadFile: false})
    }

    const createFileNFolder = (data, fileInfo, base64List) => {
        props.handleCreateFileFolder (data, fileInfo, base64List)
    }

    const downoadFile = () => {
        // console.log('selectedFile.display_name', activeChild.metadata)
        setIsPopUpVisible(false)
        let data = {
            name:selectedFile.display_name,
            s3_key:activeChild?.metadata?.invoice_folder ? props.dependent.id+'/invoices/'+selectedFile.s3_key : props.dependent.id+'/document/'+selectedFile.s3_key,
            size:'',
            upload_date:'',
            contentType:selectedFile.file_content_type
        }
        props.onDownload(data, selectedFile.display_name)
        setSelectedFile({})
    }
    const deletePopup = () => {
        if(!selectedFile.is_folder){
            setPopUpType(A12Y_CONST.TEXT.DOCUMENT_TYPE)
            setIsPopUp2(true)
        }else{
            if(selectedFile.childs.length > 0){
                setIsPopUp(true)
                setPopUpType(A12Y_CONST.LABEL.DOCUMENT_UNABLE_TO_REMOVE)
            }else{
                setPopUpType(A12Y_CONST.TEXT.DOCUMENT_TYPE)
                setIsPopUp2(true)

            }
        }
    }
    const deleteFileOrFolder = () => {
        let data = selectedFile
        props.deleteFileFolder(data)
        setSelectedFile({})
        setIsPopUp2(false)
        setIsPopUp(false)
        // let data = selectedFile
        // if(!selectedFile.is_folder){
        //     props.deleteFileFolder(data)
        //     setSelectedFile({})
        // }else{
        //     if(selectedFile.childs.length > 0){

        //      setIsPopUp(true)
        //         setPopUpType(A12Y_CONST.LABEL.DOCUMENT_UNABLE_TO_REMOVE)
        //     }else{
        //         props.deleteFileFolder(data)
        //         setSelectedFile({})
        //     }
        // }
    }
    const handleDeleteButton = async () => {
        setLoaderFullScreenVisible(true)
        deleteFileOrFolder()
        setLoaderFullScreenVisible(false)
    }

    let docList = isChild ?  documentChildList : documentList
    // console.log('input------', inputList)
    // console.log('isChild------', isChild, docList.length)

    useEffect( ()=>{
        const childElement = async ()=>{
            if(props.isCommingFromDependentInvoiceView){
                let index = docList.findIndex(item => item.display_name === "Advocord Admin")
                if(index !== -1){
                    await goToChildElement(docList[index],index)
                }
            }
        }
        childElement()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.isCommingFromDependentInvoiceView])

    const popUpclose = async () => {
        await setIsPopUpVisible(false)
    }

    const itemDelete = async () => {
        await setIsPopUpVisible(false)
        deletePopup();
    }
    const handleResponsiveRename = async (event) => {
        setSelectedMoveFile({})
        await setIsPopUpVisible(false)
        await setIsPopUp(true)
        setPopUpType(A12Y_CONST.TEXT.RENAME)
    }

    const responsiveMoveitem = async () =>{
        await  setIsPopUpVisible(false)
        await setIsResponsive_moveFile(true)
    }

    const itemRename = async (obj) => {
        await setIsPopUp(false)
        props.updateFileNFolder(obj)
    }

    const elementRightClick = async (e, item, i) => {
        if(windowDimensions.width > 768 && props.dependent?.access && props.dependent.access.can_update){
            e.preventDefault()
            setSelectedFile({})
            setParentMoveFolder({})
            setIsRenameMoveTo(true)
            if((selectedFile.id && selectedFile.id === item.id) || (item.sys_created === true)){
                setSelectedMoveFile({})
            }else {
                setSelectedMoveFile(item)
            }

            let refPosWidth = e.pageX + 320
            let refPosHeight = e.pageY + 320

            if(refPosWidth > windowDimensions.width){
                setDropDownRightAlign(true)
            }else{
                setDropDownRightAlign(false)
            }

            if(refPosHeight > windowDimensions.height){
                setDropDownBottomAlign(true)
            }else{
                setDropDownBottomAlign(false)
            }

        }
    }

    const moveTO = async (event) => {
        event.stopPropagation();
        await setIsRenameMoveTo(false)
    }
    const moveToFolder = async (event, item) => {
        event.stopPropagation();
        await setParentMoveFolder(item)
    }
    const moveToRootFolder = async (event) => {
        event.stopPropagation();
        await setParentMoveFolder(null)
    }
    const handleMoveButton = async (event) => {
        event.stopPropagation();
        if(parentMoveFolder === null || Object.keys(parentMoveFolder).length > 0){
            props.handleMoveItem (selectedMoveFile, parentMoveFolder)
            await setSelectedMoveFile({})
            await setParentMoveFolder({})
            await setIsResponsive_moveFile(false);

        }
    }
    const documentListCancelButton = () => {
        setSelectedMoveFile({})
        setIsRenameMoveTo(true)
        setIsResponsive_moveFile(false)
    }
    const rightClickmenueHid =() => {
        // if(Object.keys(selectedMoveFile).length > 0){
        //     setSelectedMoveFile({})
        // }
    }
    const div = document.querySelector('div')
    div.addEventListener('dragstart', (e) => {
        e.preventDefault()
    })

    const sortBy = () => {
        let sortedArray = [];
        const tempArray = [...props.documentList];
        if (isSorted) {
            sortedArray = tempArray.sort((a, b) => {
                if (a['display_name'] < b['display_name']) {
                    return -1;
                }
                if (a['display_name'] > b['display_name']) {
                    return 1;
                }
                return 0;
            });
            setIsSorted(false)
            setDocumentSortClass('active asc')
        } else {
            sortedArray = tempArray.sort((b, a) => {
                if (a['display_name'] < b['display_name']) {
                    return -1;
                }
                if (a['display_name'] > b['display_name']) {
                    return 1;
                }
                return 0;
            });
            setIsSorted(true)
            setDocumentSortClass('active dec')

        }
        setDocumentList(sortedArray)
    }

    const doubleClickEvent = async (file)=>{
        let data = {
            name:file.display_name,
            // s3_key:props.dependent.id+'/document/'+file.s3_key,
            s3_key:activeChild?.metadata?.invoice_folder ? props.dependent.id+'/invoices/'+file.s3_key : props.dependent.id+'/document/'+file.s3_key,
            size:'',
            upload_date:'',
            contentType:file.file_content_type
        }
       props.onDownload(data, file.display_name)
    }

    const goToDocumentLandingPage = async ()=>{
        parentBoxActive()
        setDocumentChildList([])
        props.goToDocumentLandingPage()
    }

    return (
        <div onClick={rightClickmenueHid} className="titleRow docTitleRow margignTop768_13 margignBottom768_10">
            {/*{ loaderFullScreenVisible ?*/}
            {/*    <LoaderFullScreen/>*/}
            {/*    :*/}
            {/*    null*/}
            {/*}*/}
            <div>
                <div className='documentFixedHeader'>
                <h2 className="titleWithSidebar documentTitle marginBottom30">
                    <span className='documentTitle positionAbsouteOnly'>
                        {isChild ?
                            <span  id={A12Y_CONST.BUTTON_ID.DOCUMENT_BACK} className={isChild ? "cursorPointer" : ''} onClick={parentBoxActive}>
                             <svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                             </svg>
                         </span>
                            :
                            null
                        }
                        {A12Y_CONST.TEXT.DOCUMENTS}
                        {/*<span className={isChild ? "cursorPointer documentbreadcrum" : 'documentbreadcrum'} onClick={parentBoxActive}>*/}
                        {/*    {isChild ? props.dependent.first_name + ' ' +props.dependent.last_name : ''}*/}
                        {/*</span>*/}
                    </span>
                    <span className='dependentNameTitle displayBlock texAlingCener'>
                    <span className="txtEllipsis documentTitleName vam">
                        {/*{isChild ? activeChild.display_name : props.dependent.first_name + ' ' +props.dependent.last_name  }*/}
                    </span>
                </span>
                    {props.dependent?.access && props.dependent.access.can_update ?
                        <div className='addIconDocument'><button onClick={() => setDocumentTopRowVisible(!documentTopRowVisible)} className="btn-primary saveButton plusIcon"><span>+</span></button></div>
                        :
                        null
                    }
                    <span id={A12Y_CONST.TEXT.LEARN_MORE+A12Y_CONST.LABEL.DOCUMENT} onClick={()=>{goToDocumentLandingPage()}} className="titleRightContent2 positionAbsoute">{A12Y_CONST.LABEL.LEARN_MORE}</span>
                </h2>
                {documentTopRowVisible?<div onClick={() => setDocumentTopRowVisible(false)} className="overLayLayer"></div>:''}
                <div className={documentTopRowVisible? "fullWidth documentTopRow documentTopRowShow": 'fullWidth documentTopRow'}>
                    <ul onClick={() => setDocumentTopRowVisible(false)}>
                        { props.dependent?.access && props.dependent.access.can_update === true ?
                            <>
                                {isChild && windowDimensions.width < 768 ?
                                    <div></div>
                                    :
                                    <li id={A12Y_CONST.BUTTON_ID.DOCUMENT_ADD} onClick={addFile_Folder_popUP} className={isChild ? 'clickDisable pTopBottom15 documentADD': 'pTopBottom15 documentADD'}>
                            <span>
                            {isChild || windowDimensions.width < 768 ?
                                <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4810_29809)">
                                        <path d="M15.5 24C11.514 24 7.51879 24 3.53282 24C1.40573 24 0 22.615 0 20.5285C0 14.8428 0 9.14806 0 3.46241C0 1.39408 1.41498 0.00911162 3.51432 0C6.24254 0 8.98001 0.00911162 11.7082 0C12.6978 0 13.5671 0.28246 14.27 0.984055C14.4087 1.12073 14.5567 1.09339 14.7139 1.09339C19.0051 1.09339 23.2962 1.09339 27.5782 1.09339C29.1966 1.09339 30.4913 2.06834 30.889 3.56264C30.963 3.86333 30.9908 4.16401 30.9908 4.4738C30.9908 9.85877 30.9908 15.2437 30.9908 20.6287C30.9908 22.5786 29.548 24 27.5689 24C23.5459 24.0091 19.523 24 15.5 24ZM15.5 21.8223C19.5137 21.8223 23.5274 21.8223 27.5412 21.8223C28.3643 21.8223 28.7897 21.3941 28.7897 20.574C28.7897 16.3098 28.7897 12.0547 28.7897 7.79043C28.7897 6.96128 28.355 6.54214 27.5042 6.54214C24.0361 6.54214 20.5588 6.53303 17.0907 6.54214C15.5462 6.55125 14.455 5.87699 13.7798 4.51936C13.5209 3.99089 13.2434 3.47153 12.9937 2.93394C12.744 2.40547 12.3371 2.16856 11.736 2.16856C8.98926 2.17768 6.25179 2.17768 3.50507 2.17768C2.63574 2.18679 2.21957 2.59681 2.21957 3.46241C2.21957 9.15718 2.21957 14.8428 2.21957 20.5376C2.21957 21.4123 2.63574 21.8223 3.53282 21.8223C7.51879 21.8223 11.5048 21.8223 15.5 21.8223ZM15.6665 3.28018C16.0086 4.18223 16.2861 4.37358 17.2017 4.37358C20.4848 4.37358 23.7679 4.37358 27.051 4.37358C27.6244 4.37358 28.207 4.36446 28.7619 4.56492C28.8452 3.79954 28.3643 3.28929 27.5874 3.28929C23.6939 3.28929 19.8004 3.28929 15.8977 3.28929C15.8237 3.28018 15.7497 3.28018 15.6665 3.28018Z" fill="#71767A" fillOpacity="0.99"/>
                                        <path d="M16.6098 16.5102C16.6098 16.8291 16.619 17.1481 16.6098 17.467C16.5913 18.0774 16.1104 18.5421 15.5092 18.5512C14.9081 18.5512 14.4179 18.0774 14.4087 17.4761C14.3994 16.8291 14.3994 16.1822 14.4087 15.5353C14.4179 15.3166 14.344 15.2711 14.1405 15.2802C13.5486 15.2984 12.966 15.2893 12.3741 15.2893C11.588 15.2893 11.0793 14.8519 11.0793 14.1868C11.0886 13.5307 11.588 13.1025 12.3556 13.1025C12.9475 13.1025 13.5301 13.0934 14.122 13.1116C14.3347 13.1207 14.4179 13.0752 14.4087 12.8383C14.3902 12.2278 14.3994 11.6082 14.3994 10.9977C14.3994 10.3143 14.8804 9.82231 15.5185 9.83142C16.1474 9.84053 16.6098 10.3235 16.6098 10.9977C16.6098 11.6264 16.619 12.246 16.6098 12.8747C16.6098 13.0752 16.6653 13.1207 16.8595 13.1116C17.4976 13.1025 18.1265 13.1025 18.7646 13.1116C19.4397 13.1116 19.9299 13.5763 19.9391 14.1959C19.9484 14.8246 19.449 15.2893 18.7461 15.2984C18.1265 15.2984 17.4976 15.3075 16.878 15.2984C16.6745 15.2984 16.5913 15.3348 16.6098 15.5535C16.6283 15.8542 16.6098 16.1822 16.6098 16.5102Z" fill="#71767A" fillOpacity="0.99"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4810_29809">
                                            <rect width="31" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                :
                                <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4810_29809)">
                                        <path d="M15.5 24C11.514 24 7.51879 24 3.53282 24C1.40573 24 0 22.615 0 20.5285C0 14.8428 0 9.14806 0 3.46241C0 1.39408 1.41498 0.00911162 3.51432 0C6.24254 0 8.98001 0.00911162 11.7082 0C12.6978 0 13.5671 0.28246 14.27 0.984055C14.4087 1.12073 14.5567 1.09339 14.7139 1.09339C19.0051 1.09339 23.2962 1.09339 27.5782 1.09339C29.1966 1.09339 30.4913 2.06834 30.889 3.56264C30.963 3.86333 30.9908 4.16401 30.9908 4.4738C30.9908 9.85877 30.9908 15.2437 30.9908 20.6287C30.9908 22.5786 29.548 24 27.5689 24C23.5459 24.0091 19.523 24 15.5 24ZM15.5 21.8223C19.5137 21.8223 23.5274 21.8223 27.5412 21.8223C28.3643 21.8223 28.7897 21.3941 28.7897 20.574C28.7897 16.3098 28.7897 12.0547 28.7897 7.79043C28.7897 6.96128 28.355 6.54214 27.5042 6.54214C24.0361 6.54214 20.5588 6.53303 17.0907 6.54214C15.5462 6.55125 14.455 5.87699 13.7798 4.51936C13.5209 3.99089 13.2434 3.47153 12.9937 2.93394C12.744 2.40547 12.3371 2.16856 11.736 2.16856C8.98926 2.17768 6.25179 2.17768 3.50507 2.17768C2.63574 2.18679 2.21957 2.59681 2.21957 3.46241C2.21957 9.15718 2.21957 14.8428 2.21957 20.5376C2.21957 21.4123 2.63574 21.8223 3.53282 21.8223C7.51879 21.8223 11.5048 21.8223 15.5 21.8223ZM15.6665 3.28018C16.0086 4.18223 16.2861 4.37358 17.2017 4.37358C20.4848 4.37358 23.7679 4.37358 27.051 4.37358C27.6244 4.37358 28.207 4.36446 28.7619 4.56492C28.8452 3.79954 28.3643 3.28929 27.5874 3.28929C23.6939 3.28929 19.8004 3.28929 15.8977 3.28929C15.8237 3.28018 15.7497 3.28018 15.6665 3.28018Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                        <path d="M16.6098 16.5102C16.6098 16.8291 16.619 17.1481 16.6098 17.467C16.5913 18.0774 16.1104 18.5421 15.5092 18.5512C14.9081 18.5512 14.4179 18.0774 14.4087 17.4761C14.3994 16.8291 14.3994 16.1822 14.4087 15.5353C14.4179 15.3166 14.344 15.2711 14.1405 15.2802C13.5486 15.2984 12.966 15.2893 12.3741 15.2893C11.588 15.2893 11.0793 14.8519 11.0793 14.1868C11.0886 13.5307 11.588 13.1025 12.3556 13.1025C12.9475 13.1025 13.5301 13.0934 14.122 13.1116C14.3347 13.1207 14.4179 13.0752 14.4087 12.8383C14.3902 12.2278 14.3994 11.6082 14.3994 10.9977C14.3994 10.3143 14.8804 9.82231 15.5185 9.83142C16.1474 9.84053 16.6098 10.3235 16.6098 10.9977C16.6098 11.6264 16.619 12.246 16.6098 12.8747C16.6098 13.0752 16.6653 13.1207 16.8595 13.1116C17.4976 13.1025 18.1265 13.1025 18.7646 13.1116C19.4397 13.1116 19.9299 13.5763 19.9391 14.1959C19.9484 14.8246 19.449 15.2893 18.7461 15.2984C18.1265 15.2984 17.4976 15.3075 16.878 15.2984C16.6745 15.2984 16.5913 15.3348 16.6098 15.5535C16.6283 15.8542 16.6098 16.1822 16.6098 16.5102Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4810_29809">
                                            <rect width="31" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            }

                            </span>
                                    </li>
                                }
                                {!activeChild?.metadata?.invoice_folder ?
                                    <li  id={A12Y_CONST.BUTTON_ID.DOCUMENT_UPLOAD} className='pTopBottom15 documentUpload'>
                                        <label>
                                            <input type="file" id="fileUploadModal" multiple name="file" onChange={fileUpload} />
                                            <span>
                               {windowDimensions.width < 768 ?
                                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                       <path
                                           d="M10.8 21.6C8.1492 21.6 6 19.4508 6 16.8C6 16.7616 5.99997 16.7208 6.00117 16.6776C6.01437 16.1196 5.6412 15.6264 5.1 15.4872C3.5472 15.0876 2.4 13.6764 2.4 12C2.4 10.0116 4.0116 8.4 6 8.4H7.2C7.8624 8.4 8.4 7.8624 8.4 7.2C8.4 6.5376 7.8624 6 7.2 6H6C2.6868 6 0 8.6868 0 12C0 14.4756 1.49759 16.5984 3.63479 17.5152C3.99479 21.156 7.0644 24 10.8 24C13.4688 24 15.7956 22.5492 17.0388 20.3964C20.9052 20.2704 24 17.0964 24 13.2C24 9.2232 20.7768 6 16.8 6C16.1376 6 15.6 6.5376 15.6 7.2C15.6 7.8624 16.1376 8.4 16.8 8.4C19.4508 8.4 21.6 10.5492 21.6 13.2C21.6 15.8508 19.4508 18 16.8 18C16.6668 18 16.536 17.9952 16.4064 17.9844C15.894 17.9424 15.4116 18.2316 15.2076 18.7032C14.4696 20.4096 12.7728 21.6 10.8 21.6ZM12 14.4C12.6624 14.4 13.2 13.8624 13.2 13.2V4.0968L13.5516 4.4484C14.0196 4.9176 14.7804 4.9176 15.2484 4.4484C15.7176 3.9804 15.7176 3.2196 15.2484 2.7516L12.8484 0.351599C12.624 0.125998 12.318 0 12 0C11.682 0 11.376 0.125998 11.1516 0.351599L8.75164 2.7516C8.28244 3.2196 8.28244 3.9804 8.75164 4.4484C9.21964 4.9176 9.98036 4.9176 10.4484 4.4484L10.8 4.0968V13.2C10.8 13.8624 11.3376 14.4 12 14.4Z"
                                           fill="#2D6CB2"/>
                                   </svg>
                                   :
                                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                       <path
                                           d="M10.8 21.6C8.1492 21.6 6 19.4508 6 16.8C6 16.7616 5.99997 16.7208 6.00117 16.6776C6.01437 16.1196 5.6412 15.6264 5.1 15.4872C3.5472 15.0876 2.4 13.6764 2.4 12C2.4 10.0116 4.0116 8.4 6 8.4H7.2C7.8624 8.4 8.4 7.8624 8.4 7.2C8.4 6.5376 7.8624 6 7.2 6H6C2.6868 6 0 8.6868 0 12C0 14.4756 1.49759 16.5984 3.63479 17.5152C3.99479 21.156 7.0644 24 10.8 24C13.4688 24 15.7956 22.5492 17.0388 20.3964C20.9052 20.2704 24 17.0964 24 13.2C24 9.2232 20.7768 6 16.8 6C16.1376 6 15.6 6.5376 15.6 7.2C15.6 7.8624 16.1376 8.4 16.8 8.4C19.4508 8.4 21.6 10.5492 21.6 13.2C21.6 15.8508 19.4508 18 16.8 18C16.6668 18 16.536 17.9952 16.4064 17.9844C15.894 17.9424 15.4116 18.2316 15.2076 18.7032C14.4696 20.4096 12.7728 21.6 10.8 21.6ZM12 14.4C12.6624 14.4 13.2 13.8624 13.2 13.2V4.0968L13.5516 4.4484C14.0196 4.9176 14.7804 4.9176 15.2484 4.4484C15.7176 3.9804 15.7176 3.2196 15.2484 2.7516L12.8484 0.351599C12.624 0.125998 12.318 0 12 0C11.682 0 11.376 0.125998 11.1516 0.351599L8.75164 2.7516C8.28244 3.2196 8.28244 3.9804 8.75164 4.4484C9.21964 4.9176 9.98036 4.9176 10.4484 4.4484L10.8 4.0968V13.2C10.8 13.8624 11.3376 14.4 12 14.4Z"
                                           fill="#2D6CB2"/>
                                   </svg>
                               }
                                </span>
                                        </label>
                                    </li>
                                    :
                                    <li  className='pTopBottom15 uploadDisable'>
                                        <svg className='uploadDisable' width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.8 21.6C8.1492 21.6 6 19.4508 6 16.8C6 16.7616 5.99997 16.7208 6.00117 16.6776C6.01437 16.1196 5.6412 15.6264 5.1 15.4872C3.5472 15.0876 2.4 13.6764 2.4 12C2.4 10.0116 4.0116 8.4 6 8.4H7.2C7.8624 8.4 8.4 7.8624 8.4 7.2C8.4 6.5376 7.8624 6 7.2 6H6C2.6868 6 0 8.6868 0 12C0 14.4756 1.49759 16.5984 3.63479 17.5152C3.99479 21.156 7.0644 24 10.8 24C13.4688 24 15.7956 22.5492 17.0388 20.3964C20.9052 20.2704 24 17.0964 24 13.2C24 9.2232 20.7768 6 16.8 6C16.1376 6 15.6 6.5376 15.6 7.2C15.6 7.8624 16.1376 8.4 16.8 8.4C19.4508 8.4 21.6 10.5492 21.6 13.2C21.6 15.8508 19.4508 18 16.8 18C16.6668 18 16.536 17.9952 16.4064 17.9844C15.894 17.9424 15.4116 18.2316 15.2076 18.7032C14.4696 20.4096 12.7728 21.6 10.8 21.6ZM12 14.4C12.6624 14.4 13.2 13.8624 13.2 13.2V4.0968L13.5516 4.4484C14.0196 4.9176 14.7804 4.9176 15.2484 4.4484C15.7176 3.9804 15.7176 3.2196 15.2484 2.7516L12.8484 0.351599C12.624 0.125998 12.318 0 12 0C11.682 0 11.376 0.125998 11.1516 0.351599L8.75164 2.7516C8.28244 3.2196 8.28244 3.9804 8.75164 4.4484C9.21964 4.9176 9.98036 4.9176 10.4484 4.4484L10.8 4.0968V13.2C10.8 13.8624 11.3376 14.4 12 14.4Z"
                                                fill="#71767A"/>
                                        </svg>

                                    </li>
                                }


                            </>
                            :
                            null
                        }
                        <li  id={A12Y_CONST.BUTTON_ID.DOCUMENT_DOWNLOAD} className={selectedFile.id && !selectedFile.is_folder? 'pTopBottom15 documentDownload': 'pTopBottom15 clickDisable documentDownload'} onClick={()=>downoadFile()}>
                            <span>
                                {selectedFile.id && !selectedFile.is_folder ?
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8 2.4C8.1492 2.4 6 4.5492 6 7.2C6 7.2384 5.99997 7.27921 6.00117 7.32241C6.01437 7.88041 5.6412 8.37359 5.1 8.51279C3.5472 8.91239 2.4 10.3236 2.4 12C2.4 13.9884 4.0116 15.6 6 15.6H7.2C7.8624 15.6 8.4 16.1376 8.4 16.8C8.4 17.4624 7.8624 18 7.2 18H6C2.6868 18 0 15.3132 0 12C0 9.5244 1.49759 7.40161 3.63479 6.48481C3.99479 2.84401 7.0644 0 10.8 0C13.4688 0 15.7956 1.45081 17.0388 3.60361C20.9052 3.72961 24 6.9036 24 10.8C24 14.7768 20.7768 18 16.8 18C16.1376 18 15.6 17.4624 15.6 16.8C15.6 16.1376 16.1376 15.6 16.8 15.6C19.4508 15.6 21.6 13.4508 21.6 10.8C21.6 8.1492 19.4508 6 16.8 6C16.6668 6 16.536 6.0048 16.4064 6.0156C15.894 6.0576 15.4116 5.7684 15.2076 5.2968C14.4696 3.5904 12.7728 2.4 10.8 2.4ZM12 9.6C12.6624 9.6 13.2 10.1376 13.2 10.8V19.9032L13.5516 19.5516C14.0196 19.0824 14.7804 19.0824 15.2484 19.5516C15.7176 20.0196 15.7176 20.7804 15.2484 21.2484L12.8484 23.6484C12.624 23.874 12.318 24 12 24C11.682 24 11.376 23.874 11.1516 23.6484L8.75164 21.2484C8.28244 20.7804 8.28244 20.0196 8.75164 19.5516C9.21964 19.0824 9.98036 19.0824 10.4484 19.5516L10.8 19.9032V10.8C10.8 10.1376 11.3376 9.6 12 9.6Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                    </svg>
                                    :
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8 2.4C8.1492 2.4 6 4.5492 6 7.2C6 7.2384 5.99997 7.27921 6.00117 7.32241C6.01437 7.88041 5.6412 8.37359 5.1 8.51279C3.5472 8.91239 2.4 10.3236 2.4 12C2.4 13.9884 4.0116 15.6 6 15.6H7.2C7.8624 15.6 8.4 16.1376 8.4 16.8C8.4 17.4624 7.8624 18 7.2 18H6C2.6868 18 0 15.3132 0 12C0 9.5244 1.49759 7.40161 3.63479 6.48481C3.99479 2.84401 7.0644 0 10.8 0C13.4688 0 15.7956 1.45081 17.0388 3.60361C20.9052 3.72961 24 6.9036 24 10.8C24 14.7768 20.7768 18 16.8 18C16.1376 18 15.6 17.4624 15.6 16.8C15.6 16.1376 16.1376 15.6 16.8 15.6C19.4508 15.6 21.6 13.4508 21.6 10.8C21.6 8.1492 19.4508 6 16.8 6C16.6668 6 16.536 6.0048 16.4064 6.0156C15.894 6.0576 15.4116 5.7684 15.2076 5.2968C14.4696 3.5904 12.7728 2.4 10.8 2.4ZM12 9.6C12.6624 9.6 13.2 10.1376 13.2 10.8V19.9032L13.5516 19.5516C14.0196 19.0824 14.7804 19.0824 15.2484 19.5516C15.7176 20.0196 15.7176 20.7804 15.2484 21.2484L12.8484 23.6484C12.624 23.874 12.318 24 12 24C11.682 24 11.376 23.874 11.1516 23.6484L8.75164 21.2484C8.28244 20.7804 8.28244 20.0196 8.75164 19.5516C9.21964 19.0824 9.98036 19.0824 10.4484 19.5516L10.8 19.9032V10.8C10.8 10.1376 11.3376 9.6 12 9.6Z" fill="#71767A" fillOpacity="0.99"/>
                                    </svg>
                                }
                            </span>
                        </li>
                        { props.dependent?.access && props.dependent.access.can_update === true ?
                            <li  id={A12Y_CONST.BUTTON_ID.DOCUMENT_DELETE} className={selectedFile.id && !selectedFile.sys_created ? 'pTopBottom15 documentDelete': 'pTopBottom15 clickDisable documentDelete'} onClick={deletePopup}>
                            <span>
                                {/*{selectedFile.id ?*/}
                                {selectedFile.id && !selectedFile.sys_created?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 2C5 0.8954 5.89543 0 7 0H13C14.1046 0 15 0.8954 15 2V4H16.9897C16.9959 3.9999 17.0021 3.9999 17.0083 4H19C19.5523 4 20 4.4477 20 5C20 5.5523 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.189 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.189 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.5523 0 5C0 4.4477 0.44772 4 1 4H2.99174C2.99795 3.9999 3.00414 3.9999 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#2D6CB2"/>
                                    </svg>
                                    :
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 2C5 0.8954 5.89543 0 7 0H13C14.1046 0 15 0.8954 15 2V4H16.9897C16.9959 3.9999 17.0021 3.9999 17.0083 4H19C19.5523 4 20 4.4477 20 5C20 5.5523 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.189 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.189 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.5523 0 5C0 4.4477 0.44772 4 1 4H2.99174C2.99795 3.9999 3.00414 3.9999 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#71767A"/>
                                    </svg>
                                }
                            </span>
                            </li>
                            :
                            null
                        }
                    </ul>
                </div>
                </div>
                <div className="paddingBottom10 marginTop140 documentContent overViewLabel fullWidth positionRelative">
                    {   error ?
                        <div className={windowDimensions.width > 768 ? 'maxWidth600 marginLeftRightAuto marginTop10 paddingBottom10' : 'maxWidth600 marginLeftRightAuto'}>
                            <ErrorMessageComponent errorMessage={props.errorMessage}/>
                        </div>
                        :
                        (props.message ?
                                <SuccessMessageComponent successMessage={props.message}/>

                                :
                                null
                        )

                    }
                    {/*<div id='documentListRow' className=" documentListRow fullWidth marginTop25 ">*/}
                    <div id='documentListRow' className={`documentListRow documentTopMargin20 fullWidth animationall df fcol ${isDrage?'hovered':''} ${windowDimensions.width <= 600 && isResponsive_moveFile?'hideDocRowList': null}`}>
                        <div className='fullWidth positionRelative zIndex20'>
                            {
                                !props.loading && docList && docList.length > 0 ? docList.map((item, i) => {
                                        return (
                                            // item.type == 'file' ?
                                            !item.is_folder  ?
                                                <div
                                                    key={i}
                                                    className={item.id === selectedFile.id ? 'documentListCol imgCol selectedFileFolder' : 'documentListCol imgCol'}
                                                    onClick={(e) => {
                                                        elementSelect(item, i, e)
                                                    }}
                                                    onDoubleClick={()=>doubleClickEvent(item)}
                                                    onContextMenu={(e) => {elementRightClick (e, item, i)}}
                                                >
                                                    <div className="folderImgIcon">
                                                        {item.display_name.split(".").pop().toLowerCase() === 'pdf' ? <img  src={pdf} alt='logo' />: item.display_name.split(".").pop().toLowerCase() === 'jpg' || item.display_name.split(".").pop().toLowerCase() === 'jpeg'? <img src={jpg_icon} alt='logo' /> : item.display_name.split(".").pop().toLowerCase() === 'doc' || item.display_name.split(".").pop().toLowerCase() === 'docx'? <img src={doc_icon} alt='logo' /> : item.display_name.split(".").pop().toLowerCase() === 'ai'? <img src={ai_icon} alt='logo' /> : <img src={jpg} alt='logo' />}
                                                    </div>
                                                    <div>
                                                        <div className='inputfolderName'>
                                                    <textarea
                                                        readOnly
                                                        name={"folderName"+i}
                                                        value={item.display_name || ''}
                                                        onChange={e => handleInputChange(e, i, item)}
                                                        onBlur={handleonBlur}
                                                    />
                                                            <p>{item.display_name}</p>
                                                            {/* <p className="mElipsisWrap df ai-fe"><span className="multiElipsis">{item.display_name.split('.').slice(0, -1)}</span><span>.{item.display_name.split('.').pop()}</span></p> */}
                                                        </div>
                                                        <div className="dateTime">
                                                            {moment(item.created_date).format("MM/DD/YYYY. hh:mmA")}
                                                        </div>
                                                    </div>
                                                    {props.dependent?.access && props.dependent.access.can_update ?
                                                        <div onClick={(e) => {showDocumentActionPopUp(e,item,i)}} className='docNavIcon'>
                                                            <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 4C9.105 4 10 3.1046 10 2C10 0.8954 9.105 0 8 0C6.895 0 6 0.8954 6 2C6 3.1046 6.895 4 8 4Z" fill="#71767A"/>
                                                                <path d="M2 4C3.105 4 4 3.1046 4 2C4 0.8954 3.105 0 2 0C0.895 0 0 0.8954 0 2C0 3.1046 0.895 4 2 4Z" fill="#71767A"/>
                                                                <path d="M14 4C15.105 4 16 3.1046 16 2C16 0.8954 15.105 0 14 0C12.895 0 12 0.8954 12 2C12 3.1046 12.895 4 14 4Z" fill="#71767A"/>
                                                            </svg>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {item.id === selectedMoveFile.id ?
                                                        <div id='documentItemDropDown' ref={dropDownRef} className={`documentItemDropDown positionAbsouteOnly docTop zIndex2 ${dropDownRightAlign ? 'rightAlign': null} ${dropDownBottomAlign ? 'bottomAlign': null}`}>
                                                            {
                                                                isRenameMoveTo?
                                                                <div className='reName_move'>
                                                              <ul>
                                                                    <li id={A12Y_CONST.BUTTON_ID.DOCUMENT_RENAME} onClick={handleResponsiveRename} className='cursorPointer'>
                                                                                    <span>
                                                                                        <svg width="17" height="17" viewBox="0 0 17 17"
                                                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M11.8936 0.262046C12.2425 -0.0873488 12.8098 -0.0873488 13.1587 0.262046L16.7376 3.841C17.0875 4.19039 17.0875 4.75694 16.7376 5.10634L5.1062 16.7379C4.93888 16.9057 4.71073 17 4.47363 17H0.894725C0.400568 17 0 16.5994 0 16.1053V12.5263C0 12.289 0.0943041 12.0614 0.262065 11.8936L9.2094 2.94644L11.8936 0.262046ZM9.84198 4.84427L1.78945 12.8969V15.2105H4.10321L12.1557 7.15788L9.84198 4.84427ZM13.4209 5.89254L14.8399 4.47367L12.5262 2.16005L11.1071 3.57893L13.4209 5.89254Z"
                                                                                                fill="#565C65"/>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className='label'>{A12Y_CONST.LABEL.RENAME}</span>
                                                                    </li>
                                                                    <li id={A12Y_CONST.BUTTON_ID.DOCUMENT_MOVE_TO} className='cursorPointer' onClick={moveTO}>
                                                                                    <span>
                                                                                        <svg width="16" height="18" viewBox="0 0 16 18"
                                                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M10.6667 1.8H1.77778V16.2H14.2222V5.4H10.6667V1.8ZM0 0.8928C0 0.3996 0.397333 0 0.887999 0H11.5555L16 4.5V17.0937C16.0008 17.2119 15.9786 17.3291 15.9347 17.4386C15.8908 17.5481 15.826 17.6478 15.744 17.7319C15.6621 17.8161 15.5645 17.8831 15.457 17.9291C15.3495 17.9751 15.234 17.9992 15.1173 18H0.882665C0.649224 17.9984 0.425793 17.9038 0.260638 17.7367C0.0954825 17.5697 0.00186191 17.3436 0 17.1072V0.8928ZM7.99999 8.1V5.4L11.5555 9L7.99999 12.6V9.9H4.44444V8.1H7.99999Z"
                                                                                            fill="#565C65"/>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className='label'>{A12Y_CONST.LABEL.MOVE_TO}</span>
                                                                    </li>
                                                            </ul>
                                                                </div>
                                                                :
                                                                <div className="listOfFolder fullWidth">
                                                                <div className="boxListTitle" id={A12Y_CONST.BUTTON_ID.DOCUMENT_PRIVATE_FILE}>Private File</div>
                                                                <div className="docList marginBottom10 marginTop10 fullWidth">
                                                                    {isChild ?
                                                                    <div onClick={(e) => {moveToRootFolder(e)}} className={`fullWidth paddingleft16 fullWidth docFolderNamIconRow df aic cursorPointer ${parentMoveFolder === null ? 'selectedParentFolder' : ''}`}>
                                                                         <span
                                                                             className='fodlerIcon paddingRight16'>
                                                                                         <svg width="39" height="30"
                                                                                              viewBox="0 0 39 30"
                                                                                              fill="none"
                                                                                              xmlns="http://www.w3.org/2000/svg">
                                                                                             <g clipPath="url(#clip0_8486_55670)">
                                                                                                 <path opacity="0.99"
                                                                                                       d="M3.10971 29.9362H35.0153C36.7256 29.9362 38.125 28.5016 38.125 26.7481V5.32412C38.125 3.57067 36.7256 2.13603 35.0153 2.13603H19.3113C18.5649 2.13603 17.8808 1.72157 17.5076 1.05207C17.1656 0.414453 16.4814 0 15.7351 0H3.10971C1.39937 0 0 1.43464 0 3.1881V26.7481C0 28.5335 1.39937 29.9362 3.10971 29.9362Z"
                                                                                                       fill="#214C7B"/>
                                                                                                 <path opacity="0.05"
                                                                                                       d="M4.72689 3.22021H32.5899C34.3002 3.22021 35.6996 4.65486 35.6996 6.40831V25.2819C35.6996 27.0353 34.3002 28.4699 32.5899 28.4699H4.72689C3.01656 28.4699 1.61719 27.0353 1.61719 25.2819V6.40831C1.61719 4.65486 3.01656 3.22021 4.72689 3.22021Z"
                                                                                                       fill="black"/>
                                                                                                 <path
                                                                                                     d="M5.09994 3.69824H32.9629C34.6733 3.69824 36.0726 5.13289 36.0726 6.88634V25.7599C36.0726 27.5133 34.6733 28.948 32.9629 28.948H5.09994C3.3896 28.948 1.99023 27.5133 1.99023 25.7599V6.88634C1.99023 5.13289 3.3896 3.69824 5.09994 3.69824Z"
                                                                                                     fill="white"/>
                                                                                                 <path opacity="0.06"
                                                                                                       d="M35.0153 29.6813H3.10971C1.39937 29.6813 0 28.2466 0 26.4932V10.9034C0 9.14992 1.39937 7.71528 3.10971 7.71528H18.6271C19.4979 7.71528 20.3064 7.1733 20.6795 6.34439C21.0216 5.51549 21.8301 4.97351 22.732 4.97351H35.0464C36.7567 4.97351 38.1561 6.40815 38.1561 8.16161V26.4613C38.125 28.2466 36.7567 29.6813 35.0153 29.6813Z"
                                                                                                       fill="black"/>
                                                                                                 <path opacity="0.99"
                                                                                                       d="M35.0153 29.9361H3.10971C1.39937 29.9361 0 28.5015 0 26.748V11.1901C0 9.43666 1.39937 8.00202 3.10971 8.00202H18.6271C19.4979 8.00202 20.3064 7.46004 20.6795 6.63114C21.0216 5.80223 21.8301 5.26025 22.732 5.26025H35.0464C36.7567 5.26025 38.1561 6.6949 38.1561 8.44835V26.748C38.125 28.5334 36.7567 29.9361 35.0153 29.9361Z"
                                                                                                       fill="#2D6CB2"/>
                                                                                             </g>
                                                                                             <defs>
                                                                                                 <clipPath
                                                                                                     id="clip0_8486_55670">
                                                                                                     <rect
                                                                                                         width="38.125"
                                                                                                         height="30"
                                                                                                         fill="white"/>
                                                                                                 </clipPath>
                                                                                             </defs>
                                                                                         </svg>
                                                                                     </span>
                                                                         <span
                                                                             className='docFolderName paddingLeft10'>
                                                                                <span className='textcapitalize'>{props.dependent.first_name} </span> <span className='textcapitalize'>{props.dependent.last_name}</span>
                                                                         </span>
                                                                    </div>
                                                                        :
                                                                    null
                                                                    }
                                                                {
                                                                    documentList && documentList.length > 0 ? documentList.map((item, i) => {
                                                                            return (
                                                                                activeChild?.id !== item.id && item.is_folder && !item?.metadata?.invoice_folder?
                                                                                    <div key={i} onDoubleClick={(e)=>{e.stopPropagation()}} onClick={(event) => {moveToFolder(event, item)}} className={`fullWidth docFolderNamIconRow df aic cursorPointer ${item.id === parentMoveFolder?.id ? 'selectedParentFolder' : ''}`}>
                                                                                     <span
                                                                                         className='fodlerIcon paddingRight16'>
                                                                                         <svg width="39" height="30"
                                                                                              viewBox="0 0 39 30"
                                                                                              fill="none"
                                                                                              xmlns="http://www.w3.org/2000/svg">
                                                                                             <g clipPath="url(#clip0_8486_55670)">
                                                                                                 <path opacity="0.99"
                                                                                                       d="M3.10971 29.9362H35.0153C36.7256 29.9362 38.125 28.5016 38.125 26.7481V5.32412C38.125 3.57067 36.7256 2.13603 35.0153 2.13603H19.3113C18.5649 2.13603 17.8808 1.72157 17.5076 1.05207C17.1656 0.414453 16.4814 0 15.7351 0H3.10971C1.39937 0 0 1.43464 0 3.1881V26.7481C0 28.5335 1.39937 29.9362 3.10971 29.9362Z"
                                                                                                       fill="#214C7B"/>
                                                                                                 <path opacity="0.05"
                                                                                                       d="M4.72689 3.22021H32.5899C34.3002 3.22021 35.6996 4.65486 35.6996 6.40831V25.2819C35.6996 27.0353 34.3002 28.4699 32.5899 28.4699H4.72689C3.01656 28.4699 1.61719 27.0353 1.61719 25.2819V6.40831C1.61719 4.65486 3.01656 3.22021 4.72689 3.22021Z"
                                                                                                       fill="black"/>
                                                                                                 <path
                                                                                                     d="M5.09994 3.69824H32.9629C34.6733 3.69824 36.0726 5.13289 36.0726 6.88634V25.7599C36.0726 27.5133 34.6733 28.948 32.9629 28.948H5.09994C3.3896 28.948 1.99023 27.5133 1.99023 25.7599V6.88634C1.99023 5.13289 3.3896 3.69824 5.09994 3.69824Z"
                                                                                                     fill="white"/>
                                                                                                 <path opacity="0.06"
                                                                                                       d="M35.0153 29.6813H3.10971C1.39937 29.6813 0 28.2466 0 26.4932V10.9034C0 9.14992 1.39937 7.71528 3.10971 7.71528H18.6271C19.4979 7.71528 20.3064 7.1733 20.6795 6.34439C21.0216 5.51549 21.8301 4.97351 22.732 4.97351H35.0464C36.7567 4.97351 38.1561 6.40815 38.1561 8.16161V26.4613C38.125 28.2466 36.7567 29.6813 35.0153 29.6813Z"
                                                                                                       fill="black"/>
                                                                                                 <path opacity="0.99"
                                                                                                       d="M35.0153 29.9361H3.10971C1.39937 29.9361 0 28.5015 0 26.748V11.1901C0 9.43666 1.39937 8.00202 3.10971 8.00202H18.6271C19.4979 8.00202 20.3064 7.46004 20.6795 6.63114C21.0216 5.80223 21.8301 5.26025 22.732 5.26025H35.0464C36.7567 5.26025 38.1561 6.6949 38.1561 8.44835V26.748C38.125 28.5334 36.7567 29.9361 35.0153 29.9361Z"
                                                                                                       fill="#2D6CB2"/>
                                                                                             </g>
                                                                                             <defs>
                                                                                                 <clipPath
                                                                                                     id="clip0_8486_55670">
                                                                                                     <rect
                                                                                                         width="38.125"
                                                                                                         height="30"
                                                                                                         fill="white"/>
                                                                                                 </clipPath>
                                                                                             </defs>
                                                                                         </svg>
                                                                                     </span>
                                                                                        <span
                                                                                            className='docFolderName paddingLeft10'>
                                                                                         {item.display_name}
                                                                                       </span>
                                                                                    </div>
                                                                                    :
                                                                                null
                                                                            );
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                                </div>
                                                                <div className="documentListbuttonRow fullWidth">
                                                                <div id={A12Y_CONST.BUTTON_ID.DOCUMENT_CANCEL} className="buttonColCancel borderRightDocument width50 texAlingCener cursorPointer" onClick={documentListCancelButton}>Cancel</div>
                                                                <div id={A12Y_CONST.BUTTON_ID.DOCUMENT_MOVE} className="buttonColMove width50 texAlingCener cursorPointer" onDoubleClick={(e)=>{e.stopPropagation()}} onClick={(e)=>{handleMoveButton(e)}}>Move</div>
                                                                </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                </div>
                                                :
                                                <div
                                                    key={i}
                                                    className={item.id === selectedFile.id ? 'documentListCol imgCol selectedFileFolder' : 'documentListCol imgCol'}
                                                    onClick={(e) => {
                                                        elementSelect(item, i, e)
                                                    }}
                                                    onContextMenu={(e) => {elementRightClick (e, item, i)}}
                                                    onDoubleClick={() => {
                                                        goToChildElement(item,i)
                                                    }}
                                                >
                                                    <div className="folderImgIcon">
                                                        {item.display_name === 'Legal'? item.childs.length > 0 ?
                                                            <img src={legalFill} alt='icon'/>
                                                            :
                                                            <img src={legalEmpty} alt='icon'/>
                                                            :
                                                            item.metadata.invoice_folder ? item.childs.length > 0 ?
                                                                <img src={advocord_admin} alt='icon'/>
                                                                :
                                                                <img src={advocord_admin} alt='icon'/>
                                                                :
                                                                item.display_name === 'Financial'? item.childs.length > 0 ?
                                                                        <img src={financialFill} alt='icon'/>
                                                                        :
                                                                        <img src={financialEmpty} alt='icon'/>
                                                                    :
                                                                item.display_name === 'Medical'? item.childs.length > 0 ?
                                                                    <img src={medicalFIll} alt='icon'/>
                                                                    :
                                                                    <img src={medicalEmpty} alt='icon'/>
                                                                    :
                                                                    item.display_name === 'Training' ? item.childs.length > 0 ?
                                                                        <img src={trainingFill} alt='icon'/>
                                                                        :
                                                                        <img src={trainingEmpty} alt='icon'/>
                                                                        :
                                                                        item.display_name === 'Expense' || item.display_name === 'expense' ? item.childs.length > 0 ?
                                                                            <img src={expenseFIll} alt='icon'/>
                                                                            :
                                                                            <img src={expenseEmpty} alt='icon'/>
                                                                            :
                                                                            item.display_name === 'income' || item.display_name === 'Income'  ? item.childs.length > 0 ?
                                                                                <img src={incomeFIll} alt='icon'/>
                                                                                :
                                                                                <img src={incomeEmpty} alt='icon'/>
                                                                                :
                                                                                item.childs.length > 0 ?<img src={blankFill} alt='icon'/>
                                                                                    :
                                                                                    <img src={blankEmpty} alt='icon'/>

                                                        }
                                                    </div>
                                                    <div className='inputfolderName'>
                                                        <textarea
                                                            readOnly
                                                            name={"folderName"+i}
                                                            // placeholder="Enter First Name"
                                                            value={item.display_name || ''}
                                                            onChange={e => handleInputChange(e, i, item)}
                                                            onBlur={handleonBlur}
                                                            // readOnly={item.sys_created ? true : false}
                                                        />
                                                        <p>{item.display_name}</p>
                                                    </div>
                                                    {item.sys_created === false && props.dependent?.access && props.dependent.access.can_update  ?
                                                        <div onClick={(e) => {showDocumentActionPopUp(e,item,i)}} className='docNavIcon'>
                                                            <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 4C9.105 4 10 3.1046 10 2C10 0.8954 9.105 0 8 0C6.895 0 6 0.8954 6 2C6 3.1046 6.895 4 8 4Z" fill="#71767A"/>
                                                                <path d="M2 4C3.105 4 4 3.1046 4 2C4 0.8954 3.105 0 2 0C0.895 0 0 0.8954 0 2C0 3.1046 0.895 4 2 4Z" fill="#71767A"/>
                                                                <path d="M14 4C15.105 4 16 3.1046 16 2C16 0.8954 15.105 0 14 0C12.895 0 12 0.8954 12 2C12 3.1046 12.895 4 14 4Z" fill="#71767A"/>
                                                            </svg>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {item.id === selectedMoveFile.id ?
                                                        <div className={`documentItemDropDown positionAbsouteOnly docTop zIndex2 ${dropDownRightAlign ? 'rightAlign': null} ${dropDownBottomAlign ? 'bottomAlign': null}`}>
                                                            {
                                                                isRenameMoveTo?
                                                                    <div className='reName_move'>
                                                                        <ul>
                                                                            <li onClick={handleResponsiveRename} className='cursorPointer'>
                                                                                    <span>
                                                                                        <svg width="17" height="17" viewBox="0 0 17 17"
                                                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M11.8936 0.262046C12.2425 -0.0873488 12.8098 -0.0873488 13.1587 0.262046L16.7376 3.841C17.0875 4.19039 17.0875 4.75694 16.7376 5.10634L5.1062 16.7379C4.93888 16.9057 4.71073 17 4.47363 17H0.894725C0.400568 17 0 16.5994 0 16.1053V12.5263C0 12.289 0.0943041 12.0614 0.262065 11.8936L9.2094 2.94644L11.8936 0.262046ZM9.84198 4.84427L1.78945 12.8969V15.2105H4.10321L12.1557 7.15788L9.84198 4.84427ZM13.4209 5.89254L14.8399 4.47367L12.5262 2.16005L11.1071 3.57893L13.4209 5.89254Z"
                                                                                                fill="#565C65"/>
                                                                                        </svg>
                                                                                    </span>
                                                                                <span className='label'>{A12Y_CONST.LABEL.RENAME}</span>
                                                                            </li>
                                                                          </ul>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>

                                        );
                                    }) :
                                    (props.loading ?
                                            <div className='noData df aic jcc fcol marginTop50'>
                                                <div className="positionRelative">
                                                    <div className="dependentLoader">
                                                        <div className='fullWidth justifyCenter marginBottom10'>
                                                            <div className="stage">
                                                                <div className="dot-pulse"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='marginTop20'>
                                                        <span>Loading Documents...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    )
                            }
                        </div>
                        <div id='dropZoneWrapDocument' className='fullWidth dropzoneCol positionAbsoute height100 showDesktophideMobile'>
                            { props.dependent?.access && props.dependent.access.can_update === true &&
                            (<Dropzone onDrop={onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className="df fcol fullWidth ">
                                        {/*<input {...getInputProps()} accept={".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf"} />*/}
                                        <div {...getRootProps({ className: "documentDragDrop" })}>
                                        </div>

                                    </section>
                                )}
                            </Dropzone>)
                            }
                        </div>

                        <div name={`logo`}>
                            {(fieldprops) => (
                                <div>
                                    <label>Logo</label>
                                    <Dropzone
                                        onDrop={(files, e) => {
                                            props.change(`logo`, files);
                                            props.blur(`logo`);
                                        }}
                                    />
                                    <pre>{JSON.stringify(fieldprops, 0, 2)}</pre>
                                </div>
                            )}
                        </div>
                    </div>

                    {windowDimensions.width <= 600 && isResponsive_moveFile?
                        <>
                        <div className={`listOfFolder fullWidth responsiveMoveFolderList`} style={{height:windowDimensions.height-150}}>
                            {props.loading ?
                                <div className='noData df aic jcc fcol marginTop50'>
                                    <div className="positionRelative">
                                        <div className="dependentLoader">
                                            <div className='fullWidth justifyCenter marginBottom10'>
                                                <div className="stage">
                                                    <div className="dot-pulse"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='marginTop20'>
                                            <span>Loading Documents...</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="docList marginBottom10 marginTop10 fullWidth">
                                    {isChild ?
                                        <div onClick={(e) => {
                                            moveToRootFolder(e)
                                        }}
                                             className={`fullWidth paddingleft16 fullWidth docFolderNamIconRow df aic cursorPointer ${parentMoveFolder === null ? 'selectedParentFolder' : ''}`}>
                                                                         <span
                                                                             className='fodlerIcon paddingRight16'>
                                                                                         <svg width="39" height="30"
                                                                                              viewBox="0 0 39 30"
                                                                                              fill="none"
                                                                                              xmlns="http://www.w3.org/2000/svg">
                                                                                             <g clipPath="url(#clip0_8486_55670)">
                                                                                                 <path opacity="0.99"
                                                                                                       d="M3.10971 29.9362H35.0153C36.7256 29.9362 38.125 28.5016 38.125 26.7481V5.32412C38.125 3.57067 36.7256 2.13603 35.0153 2.13603H19.3113C18.5649 2.13603 17.8808 1.72157 17.5076 1.05207C17.1656 0.414453 16.4814 0 15.7351 0H3.10971C1.39937 0 0 1.43464 0 3.1881V26.7481C0 28.5335 1.39937 29.9362 3.10971 29.9362Z"
                                                                                                       fill="#214C7B"/>
                                                                                                 <path opacity="0.05"
                                                                                                       d="M4.72689 3.22021H32.5899C34.3002 3.22021 35.6996 4.65486 35.6996 6.40831V25.2819C35.6996 27.0353 34.3002 28.4699 32.5899 28.4699H4.72689C3.01656 28.4699 1.61719 27.0353 1.61719 25.2819V6.40831C1.61719 4.65486 3.01656 3.22021 4.72689 3.22021Z"
                                                                                                       fill="black"/>
                                                                                                 <path
                                                                                                     d="M5.09994 3.69824H32.9629C34.6733 3.69824 36.0726 5.13289 36.0726 6.88634V25.7599C36.0726 27.5133 34.6733 28.948 32.9629 28.948H5.09994C3.3896 28.948 1.99023 27.5133 1.99023 25.7599V6.88634C1.99023 5.13289 3.3896 3.69824 5.09994 3.69824Z"
                                                                                                     fill="white"/>
                                                                                                 <path opacity="0.06"
                                                                                                       d="M35.0153 29.6813H3.10971C1.39937 29.6813 0 28.2466 0 26.4932V10.9034C0 9.14992 1.39937 7.71528 3.10971 7.71528H18.6271C19.4979 7.71528 20.3064 7.1733 20.6795 6.34439C21.0216 5.51549 21.8301 4.97351 22.732 4.97351H35.0464C36.7567 4.97351 38.1561 6.40815 38.1561 8.16161V26.4613C38.125 28.2466 36.7567 29.6813 35.0153 29.6813Z"
                                                                                                       fill="black"/>
                                                                                                 <path opacity="0.99"
                                                                                                       d="M35.0153 29.9361H3.10971C1.39937 29.9361 0 28.5015 0 26.748V11.1901C0 9.43666 1.39937 8.00202 3.10971 8.00202H18.6271C19.4979 8.00202 20.3064 7.46004 20.6795 6.63114C21.0216 5.80223 21.8301 5.26025 22.732 5.26025H35.0464C36.7567 5.26025 38.1561 6.6949 38.1561 8.44835V26.748C38.125 28.5334 36.7567 29.9361 35.0153 29.9361Z"
                                                                                                       fill="#2D6CB2"/>
                                                                                             </g>
                                                                                             <defs>
                                                                                                 <clipPath
                                                                                                     id="clip0_8486_55670">
                                                                                                     <rect
                                                                                                         width="38.125"
                                                                                                         height="30"
                                                                                                         fill="white"/>
                                                                                                 </clipPath>
                                                                                             </defs>
                                                                                         </svg>
                                                                                     </span>
                                            <span
                                                className='docFolderName paddingLeft10'>
                                                                                <span
                                                                                    className='textcapitalize'>{props.dependent.first_name} </span> <span
                                                className='textcapitalize'>{props.dependent.last_name}</span>
                                                                         </span>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        documentList && documentList.length > 0 ? documentList.map((item, i) => {
                                                return (
                                                    activeChild?.id !== item.id && item.is_folder && !item?.metadata?.invoice_folder?
                                                        <div key={i} onClick={(event) => {
                                                            moveToFolder(event, item)
                                                        }}
                                                             className={`fullWidth docFolderNamIconRow df aic cursorPointer ${item.id === parentMoveFolder?.id ? 'selectedParentFolder' : ''}`}>
                                                                                     <span
                                                                                         className='fodlerIcon paddingRight16'>
                                                                                         <svg width="39" height="30"
                                                                                              viewBox="0 0 39 30"
                                                                                              fill="none"
                                                                                              xmlns="http://www.w3.org/2000/svg">
                                                                                             <g clipPath="url(#clip0_8486_55670)">
                                                                                                 <path opacity="0.99"
                                                                                                       d="M3.10971 29.9362H35.0153C36.7256 29.9362 38.125 28.5016 38.125 26.7481V5.32412C38.125 3.57067 36.7256 2.13603 35.0153 2.13603H19.3113C18.5649 2.13603 17.8808 1.72157 17.5076 1.05207C17.1656 0.414453 16.4814 0 15.7351 0H3.10971C1.39937 0 0 1.43464 0 3.1881V26.7481C0 28.5335 1.39937 29.9362 3.10971 29.9362Z"
                                                                                                       fill="#214C7B"/>
                                                                                                 <path opacity="0.05"
                                                                                                       d="M4.72689 3.22021H32.5899C34.3002 3.22021 35.6996 4.65486 35.6996 6.40831V25.2819C35.6996 27.0353 34.3002 28.4699 32.5899 28.4699H4.72689C3.01656 28.4699 1.61719 27.0353 1.61719 25.2819V6.40831C1.61719 4.65486 3.01656 3.22021 4.72689 3.22021Z"
                                                                                                       fill="black"/>
                                                                                                 <path
                                                                                                     d="M5.09994 3.69824H32.9629C34.6733 3.69824 36.0726 5.13289 36.0726 6.88634V25.7599C36.0726 27.5133 34.6733 28.948 32.9629 28.948H5.09994C3.3896 28.948 1.99023 27.5133 1.99023 25.7599V6.88634C1.99023 5.13289 3.3896 3.69824 5.09994 3.69824Z"
                                                                                                     fill="white"/>
                                                                                                 <path opacity="0.06"
                                                                                                       d="M35.0153 29.6813H3.10971C1.39937 29.6813 0 28.2466 0 26.4932V10.9034C0 9.14992 1.39937 7.71528 3.10971 7.71528H18.6271C19.4979 7.71528 20.3064 7.1733 20.6795 6.34439C21.0216 5.51549 21.8301 4.97351 22.732 4.97351H35.0464C36.7567 4.97351 38.1561 6.40815 38.1561 8.16161V26.4613C38.125 28.2466 36.7567 29.6813 35.0153 29.6813Z"
                                                                                                       fill="black"/>
                                                                                                 <path opacity="0.99"
                                                                                                       d="M35.0153 29.9361H3.10971C1.39937 29.9361 0 28.5015 0 26.748V11.1901C0 9.43666 1.39937 8.00202 3.10971 8.00202H18.6271C19.4979 8.00202 20.3064 7.46004 20.6795 6.63114C21.0216 5.80223 21.8301 5.26025 22.732 5.26025H35.0464C36.7567 5.26025 38.1561 6.6949 38.1561 8.44835V26.748C38.125 28.5334 36.7567 29.9361 35.0153 29.9361Z"
                                                                                                       fill="#2D6CB2"/>
                                                                                             </g>
                                                                                             <defs>
                                                                                                 <clipPath
                                                                                                     id="clip0_8486_55670">
                                                                                                     <rect
                                                                                                         width="38.125"
                                                                                                         height="30"
                                                                                                         fill="white"/>
                                                                                                 </clipPath>
                                                                                             </defs>
                                                                                         </svg>
                                                                                     </span>
                                                            <span className='docFolderName paddingLeft10'>
                                                             {item.display_name}
                                                             </span>
                                                        </div>
                                                        :
                                                        null
                                                );
                                            })
                                            :
                                            null
                                    }
                                </div>
                            }

                        </div>
                        <div className="documentListbuttonRow fullWidth">
                            <div className="buttonColCancel borderRightDocument width50 texAlingCener cursorPointer" onClick={documentListCancelButton}>Cancel</div>
                            <div className="buttonColMove width50 texAlingCener cursorPointer" onDoubleClick={(e)=>{e.stopPropagation()}} onClick={(e)=>{handleMoveButton(e)}}>Move</div>
                        </div>
                        </>
                        :
                        null
                    }

                    <div className="documentBottomRow border_top fullWidth df js-btw aic">
                        <div className="bottomLeftPart widthAuto">
                            <ul className='fullWidth '>
                                {!isChild ?
                                    null
                                    // <li>
                                    //     <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    //         <g clipPath="url(#clip0_4810_31054)">
                                    //             <path opacity="0.99" d="M2.69168 25.9447H30.3083C31.7887 25.9447 33 24.7014 33 23.1817V4.61424C33 3.09458 31.7887 1.85122 30.3083 1.85122H16.7153C16.0693 1.85122 15.4772 1.49203 15.1542 0.911796C14.8581 0.359192 14.2659 0 13.6199 0H2.69168C1.21126 0 0 1.24336 0 2.76302V23.1817C0 24.729 1.21126 25.9447 2.69168 25.9447Z" fill="#00769D"/>
                                    //             <path opacity="0.05" d="M4.09134 2.79077H28.2088C29.6892 2.79077 30.9005 4.03413 30.9005 5.55379V21.9109C30.9005 23.4305 29.6892 24.6739 28.2088 24.6739H4.09134C2.61091 24.6739 1.39966 23.4305 1.39966 21.9109V5.55379C1.39966 4.03413 2.61091 2.79077 4.09134 2.79077Z" fill="black"/>
                                    //             <path d="M4.41434 3.20508H28.5318C30.0122 3.20508 31.2235 4.44844 31.2235 5.9681V22.3252C31.2235 23.8448 30.0122 25.0882 28.5318 25.0882H4.41434C2.93391 25.0882 1.72266 23.8448 1.72266 22.3252V5.9681C1.72266 4.44844 2.93391 3.20508 4.41434 3.20508Z" fill="white"/>
                                    //             <path opacity="0.06" d="M30.3083 25.7237H2.69168C1.21126 25.7237 0 24.4803 0 22.9607V9.44952C0 7.92986 1.21126 6.6865 2.69168 6.6865H16.1232C16.8768 6.6865 17.5767 6.21679 17.8997 5.4984C18.1958 4.78002 18.8956 4.3103 19.6762 4.3103H30.3352C31.8157 4.3103 33.0269 5.55366 33.0269 7.07332V22.933C33 24.4803 31.8157 25.7237 30.3083 25.7237Z" fill="black"/>
                                    //             <path opacity="0.99" d="M30.3083 25.9448H2.69168C1.21126 25.9448 0 24.7015 0 23.1818V9.6983C0 8.17864 1.21126 6.93528 2.69168 6.93528H16.1232C16.8768 6.93528 17.5767 6.46556 17.8997 5.74718C18.1958 5.02879 18.8956 4.55908 19.6762 4.55908H30.3352C31.8157 4.55908 33.0269 5.80244 33.0269 7.3221V23.1818C33 24.7291 31.8157 25.9448 30.3083 25.9448Z" fill="#0396C7"/>
                                    //             <path opacity="0.99" fillRule="evenodd" clipRule="evenodd" d="M13.2969 26.0001H30.2814C31.7618 26.0001 32.9731 24.7567 32.9731 23.2371V14.6165C27.4282 20.6398 19.8107 24.1489 13.2969 26.0001Z" fill="#058CB9"/>
                                    //         </g>
                                    //         <defs>
                                    //             <clipPath id="clip0_4810_31054">
                                    //                 <rect width="33" height="26" fill="white"/>
                                    //             </clipPath>
                                    //         </defs>
                                    //     </svg>
                                    //     <span>{A12Y_CONST.TEXT.DOCUMENTS}</span>
                                    // </li>
                                    :
                                    <>
                                        <li  id={<A12Y_CONST className="BUTTON_ID DOCUMENT_FOOTER_MY_DEPENDENT"></A12Y_CONST>} className='cursorPointer folderBottomIcon' onClick={parentBoxActive}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="123" height="94" viewBox="0 0 123 94" fill="none">
                                                <path d="M9.99976 93.9437H112.648C118.171 93.9437 122.648 89.4665 122.648 83.9437V16.7485C122.648 11.2257 118.171 6.74851 112.648 6.74851H62.1456C59.7584 6.74851 57.5578 5.45779 56.3927 3.37425C55.2276 1.29072 53.027 0 50.6398 0H9.99976C4.47691 0 -0.000244141 4.47716 -0.000244141 10V83.9437C-0.000244141 89.4665 4.47691 93.9437 9.99976 93.9437Z" fill="#214C7B"/>
                                                <rect x="5.21875" y="10.1482" width="109.601" height="79.1562" rx="10" fill="black" fillOpacity="0.05"/>
                                                <rect x="6.37891" y="11.5979" width="109.601" height="79.1562" rx="10" fill="white"/>
                                                <path d="M112.649 93.9436H10C4.47715 93.9436 0 89.4665 0 83.9436V35.0646C0 29.5418 4.47716 25.0646 10 25.0646H59.9229C62.7601 25.0646 65.33 23.3908 66.477 20.7959C67.6241 18.2009 70.194 16.5271 73.0311 16.5271H112.649C118.172 16.5271 122.649 21.0043 122.649 26.5271V83.9436C122.649 89.4665 118.172 93.9436 112.649 93.9436Z" fill="#2D6CB2"/>
                                            </svg>
                                            <span>{props.dependent.first_name + ' ' +props.dependent.last_name}</span>
                                        </li>
                                        <li>
                                            <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 6L0 12V0L6 6Z" fill="#A9AEB1"/>
                                            </svg>
                                        </li>
                                        <li className='folderBottomIcon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="123" height="94" viewBox="0 0 123 94" fill="none">
                                                <path d="M9.99976 93.9437H112.648C118.171 93.9437 122.648 89.4665 122.648 83.9437V16.7485C122.648 11.2257 118.171 6.74851 112.648 6.74851H62.1456C59.7584 6.74851 57.5578 5.45779 56.3927 3.37425C55.2276 1.29072 53.027 0 50.6398 0H9.99976C4.47691 0 -0.000244141 4.47716 -0.000244141 10V83.9437C-0.000244141 89.4665 4.47691 93.9437 9.99976 93.9437Z" fill="#214C7B"/>
                                                <rect x="5.21875" y="10.1482" width="109.601" height="79.1562" rx="10" fill="black" fillOpacity="0.05"/>
                                                <rect x="6.37891" y="11.5979" width="109.601" height="79.1562" rx="10" fill="white"/>
                                                <path d="M112.649 93.9436H10C4.47715 93.9436 0 89.4665 0 83.9436V35.0646C0 29.5418 4.47716 25.0646 10 25.0646H59.9229C62.7601 25.0646 65.33 23.3908 66.477 20.7959C67.6241 18.2009 70.194 16.5271 73.0311 16.5271H112.649C118.172 16.5271 122.649 21.0043 122.649 26.5271V83.9436C122.649 89.4665 118.172 93.9436 112.649 93.9436Z" fill="#2D6CB2"/>
                                            </svg>
                                            <span>{activeChild?.display_name}</span>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                        <div className="bottomRightPart widthAuto">
                            {/*{isChild?activeChild?.childs?.length : documentList.length} items*/}
                            {isChild?documentChildList.length : documentList.length} items

                            {/*, 18 GB available*/}
                        </div>
                    </div>
                </div>
            </div>

            <PopUpDocumentComponent
                popUpStatus={isPopUp}
                popUpType={popUpType}
                hidePopUp = {hidePopUp}
                createNewFolder = {createNewFolder}
                selectedItem = {selectedFile}
                itemRename ={itemRename}
            />

            <PopUpComponent
                popUpStatus={isPopUp2}
                title={selectedFile.is_folder ? A12Y_CONST.TEXT.DELETE_THIS_FOLDER : A12Y_CONST.TEXT.DELETE_THIS_FILE}
                message={A12Y_CONST.LABEL.DELETE_WILL_PERMANENETLY}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.DELETE}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.CANCEL}
                hidePopUp={hidePopUp2}
                handleDeleteButton={handleDeleteButton}
                popUpType={popUpType}
            />
            <PopupDocumentActionResponsive
                popUpStatus={isPopUpVisible}
                selectedItem = {selectedMoveFile}
                popUpclose = {popUpclose}
                itemDelete = {itemDelete}
                downloadFile={downoadFile}
                handleResponsiveRename={handleResponsiveRename}
                responsiveMoveitem={responsiveMoveitem}
            />
        </div>

    );
}

export default DocumentComponent;


