import React,{Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import InviteUserComponent from '../components/InviteUserComponent'
import {secureClient} from "../config/axiosClient";
import * as userDetailActions from "../redux/actions/userDetailActions";
class InviteUserScreen extends Component {

    constructor(props){
        super(props)
        this.state={
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            isClickedOutside:false,
            successPopUp: false,
        }
    }

    componentDidMount(){
    }

    submitHandler = async(data)=>{
        try {
            this.dataChangeHandler(false)
            if(this.props.isCreatingAdvocateTeam){
                this.setState({error:false})
                this.setState({loading:true})
                //let idNum = Number(this.props.dependent.id)
                data.dependent_id = this.props.dependent.id;
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.USER_INVITE, data);
                this.setState({loading:false})
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.setState({successPopUp:true});
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                }
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                //let idNum = Number(this.props.dependent.id)
                data.dependent_id = this.props.dependent.id;
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.USER_RE_INVITE, data);
                this.setState({loading:false})
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.setState({successPopUp:true});
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.INVITE_USER,e)
        }
    }

    onCancel = () =>{
        this.setState({sucessPopUp:false})
        this.props.onCancel()
    }
    onDonSuccessButton = () =>{
        this.setState({sucessPopUp:false})
        this.props.onDonSuccessButton();
    }

    reloadAddress = () =>{
        this.props.reloadData()
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    render(){
        return(
            <div className="titleRow margignTop768_13 margignBottom768_10">
                <h2 className="titleWithSidebar marginBottom30 inviteUserTitle">
                {/*<span className='marginRight20'>*/}
                {/*    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*        <path d="M8.59961 13.3798C9.1336 12.8609 9.14481 12.0063 8.6246 11.4737L4.24361 6.97622L8.74104 2.59519C9.27503 2.07636 9.28623 1.22176 8.76603 0.6891C8.2472 0.155095 7.39251 0.143887 6.85985 0.664102L1.39685 5.98567C0.86286 6.50451 0.851653 7.35911 1.37185 7.89177L6.69343 13.3548C7.21226 13.8888 8.06695 13.9 8.59961 13.3798Z" fill="#565C65"/>*/}
                {/*    </svg>*/}
                {/*</span>*/}
                {/*<span className='titleBackButton'>Back</span>*/}
                {A12Y_CONST.TEXT.INVITE_USER}</h2>
                <div className='dependentDefaultMainC0l fullWidth'>
                <InviteUserComponent
                    onSubmit={this.submitHandler}
                    successPop={this.state.successPopUp}
                    onCancel={this.onCancel}
                    onDonSuccessButton={this.onDonSuccessButton}
                    loading={this.state.loading}
                    error={this.state.error}
                    errorMessage={this.state.errorMessage}
                    advocateTeamUpdate={this.props.advocateTeamUpdate}
                    isDataChanged={this.dataChangeHandler}
                />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    errMsg : state.dependentReducer.errMsg,
});

const mapDispatchToProps = dispatch => ({
    createAddress : (advocate_id,dependent_id,data) => dispatch(dependentActions.createAddress(advocate_id,dependent_id,data)),
    updateAddress : (advocate_id,dependent_id,address_id,data) => dispatch(dependentActions.updateAddress(advocate_id,dependent_id,address_id,data)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
});

export default connect(mapStateToProps, mapDispatchToProps) (InviteUserScreen);
