import React, { Component } from 'react'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from '../common/a12yConst'
import DocumentComponent from '../components/DocumentComponent'
import {secureClient} from "../config/axiosClient";
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import axios from "axios";
import {downloadFile} from "../util/imageDownload";
import WelcomeComponent from "../components/WelcomeComponent";
import {commingFromDependentInvoiceView, isCommingFromDependentInvoiceView} from "../redux/actions/dependentActions";

export class DocumentScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading:false,
            isDownloadActive: false,
            error:false,
            message:'',
            errorMessage:'',
            isIncomeAvailable: false,
            loaderFullScreenVisible : false,
            IsDOcument:false,
            documentList:[],
            isCommingFromDependentView:false,
            isCommingFromDependentInvoiceView:false,
            btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_DOCUMENTS,
            isOutSideClick:false
        }
    }

    componentDidMount = async () => {
        let state = { ...this.props.location.state };
        if(state?.ladingPageVisible){
            await this.goToDocumentLandingPageHandler()
        }
        if(state?.ladingPageVisible){
            state.ladingPageVisible = false;
        }
        await this.getDocument()
    }

    goToDocumentScreen = async () => {
        this.setState({IsDOcument:true})
        await this.props.checkDocumentScreen(true)
    }
    handleCreateFolder =  async (data) => {
        this.setState({loading:true})
        let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS + '/' + this.props.dependent.id + '/' + A12Y_CONST.API_ENDPOINT.DOCUMENTS, data);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
           // this.setState({loading: false})
            this.setState({loaderFullScreen:false})
            await this.getDocument()
        } else {
            this.setState({error: true, errorMessage: response.data.sr?.a12yErr?.message})
            this.setState({loading: false})
            this.setState({loaderFullScreen:false})
        }
        await this.hideMessage();
    }
    handleCreateFileFolder = async  (data, fileInfo, fileBase64Info) => {
        this.setState({loading:true,error:false})
        let obj = {}
        obj = {'files' : fileInfo}
        let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let signed_urls = Object.keys(response.data.sr.signed_urls)
            let i = 0;
            for await (const item of signed_urls) {
                let uploadUrl = response.data.sr.signed_urls[item];
                let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                let fileResp = await fetch(filterData[0].base64);
                let Blobdata = await fileResp.blob();

                await axios.put(uploadUrl,Blobdata , {headers:{
                        "Content-Type" : fileInfo[i].contentType
                    }})
                // if(uploadResBody.statusText === 'OK'){
                //     attached_file.push({
                //         "s3_key" : fileInfo[i].s3_key,
                //         "name" : fileInfo[i].name,
                //         "size" : fileInfo[i].size,
                //         "upload_date" : uploadDate,
                //     })
                //     data.document_ref = {"attached_files":attached_file}
                // }
                i++;
            }
            this.createFile(data, fileInfo)

        }else {
        }
        await this.hideMessage();
    }

    createFile = async (data, fileInfo) => {
        for (let i = 0; i < fileInfo.length; i++){
            let obj = {
                "s3_key" : fileInfo[i].file_id,
                "display_name" : fileInfo[i].name,
                "file_content_type" : fileInfo[i].contentType,
                "is_folder" : false,
                "metadata" : {
                    "size" : fileInfo[i].size,
                    "upload_date" : new Date()
                },
                "parent_id" : data.parent_id ? data.parent_id : null
            }
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS + '/' + this.props.dependent.id + '/' + A12Y_CONST.API_ENDPOINT.DOCUMENTS, obj);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
               /*await this.getDocument();*/
               // this.setState({loading: false})
                this.setState({loaderFullScreen:false})
            } else {
                this.setState({error: true, errorMessage: response.data.sr?.a12yErr?.message})
                this.setState({loading: false})
                this.setState({loaderFullScreen:false})
            }
            obj={}
        }
        await this.getDocument();
        await this.hideMessage();
    }


    getDocument = async () => {
        this.setState({loading:true})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCUMENTS);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            await  this.setState({documentList:response.data.sr.documents},()=>{
                this.setState({isCommingFromDependentView:this.props.isCommingFromDependentView ? true : false,loading:false, isCommingFromDependentInvoiceView:this.props.isCommingFromDependentInvoiceView})
            });
            if(this.props.isCommingFromDependentView){
               await this.props.isCommingFromDependentViewLegal(false)
            }

            if(this.props.isCommingFromDependentInvoiceView){
                await this.props.commingFromDependentInvoiceView(false)
            }

            this.setState({loaderFullScreen:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loading:false})
        }
        this.setState({loading:false})
        await this.hideMessage();
    }

    onDownloadHandler = async(item, newName)=>{
        this.setState({
            isDownloadActive: true
        })
        // this.setState({loaderFullScreen:true})
        await downloadFile(item, newName).then(() => {
            this.setState({
                isDownloadActive: false
            })
        });
        // this.setState({loaderFullScreen:false})
        await this.hideMessage();
    }
    deleteFileFolder = async (data) => {
        this.setState({loading:true})
        const response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCUMENTS+'/'+data.id);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
           await this.getDocument()
          //  this.setState({loading:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loading:false})
        }
        await this.hideMessage();
    }
    updateFileNFolder = async  (data) => {
        let obj = {
            display_name : data.display_name
        }
        this.setState({loaderFullScreen:true})
        const response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCUMENTS+'/'+data.id, obj);
        this.setState({loaderFullScreen:true})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
           await this.getDocument()
            this.setState({loaderFullScreen:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loaderFullScreen:false})
        }
        await this.hideMessage();
    }

    hideMessage = async ()=>{
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    handleMoveItem = async (document, parentMoveFolder) => {
        let obj = {
            "parent_id" : parentMoveFolder === null ? null : parentMoveFolder.id,
        }
        this.setState({loaderFullScreen:true})
        const response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCUMENTS+'/'+document.id+'/'+A12Y_CONST.API_ENDPOINT.MOVE, obj);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            await this.getDocument()
            this.setState({loaderFullScreen:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loaderFullScreen:false})
        }
    }

    goToDocumentLandingPageHandler = async ()=>{
        this.props.checkDocumentScreen(true)
    }

    landingPageBtnEvent = async ()=>{
        this.setState({isCommingFromDependentView:false},async ()=>{
           await this.props.checkDocumentScreen(false)
        })
    }

    outSideClick = async ()=>{
        this.setState({isOutSideClick:true})
    }

    render() {
        return (
                    <div className="fullWidth">
                        {this.state.loaderFullScreenVisible ?
                                <div className="myProfileAccount animationall mainRightSidebare">
                                    <div className='rightPanelWidhtSidebar'>
                                        <div className="rightPanelWidhtSidebarContainer fullWidth">
                                              <LoaderFullScreen/>
                                        </div>
                                    </div>
                                </div>
                            :
                            (!this.props.isDocumentChecked ?
                                    <div className="myProfileAccount animationall mainRightSidebare" onClick={()=>{this.outSideClick()}}>
                                        <div className='rightPanelWidhtSidebar'>
                                            <div className="rightPanelWidhtSidebarContainer fullWidth">
                                                <DocumentComponent
                                                dependent={this.props.dependent}
                                                handleCreateFileFolder = {this.handleCreateFileFolder}
                                                createNFolderOnly = {this.handleCreateFolder}
                                                documentList={this.state.documentList}
                                                loaderFullScreen={this.state.loaderFullScreen}
                                                onDownload={this.onDownloadHandler}
                                                deleteFileFolder={this.deleteFileFolder}
                                                updateFileNFolder = {this.updateFileNFolder}
                                                handleMoveItem = {this.handleMoveItem}
                                                error={this.state.error}
                                                errorMessage={this.state.errorMessage}
                                                isCommingFromDependentView={this.state.isCommingFromDependentView}
                                                isCommingFromDependentInvoiceView={this.state.isCommingFromDependentInvoiceView}
                                                loading={this.state.loading}
                                                goToDocumentLandingPage={this.goToDocumentLandingPageHandler}
                                                isOutSideClick={this.state.isOutSideClick}
                                                outSideClickHandler={()=>{this.setState({isOutSideClick:false})}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare welcomeScreen">
                                        <div className='rightPanelWidhtSidebar bgWhite fullWidth marginTop12'>
                                            <div className="rightPanelWidhtSidebarContainer welcomeBg fullWidth marginNone">
                                                <WelcomeComponent
                                                    screenName={A12Y_CONST.LABEL.DOCUMENTS}
                                                    title={A12Y_CONST.TEXT.DOCUMENTS_DETAILS_AT}
                                                    description1={A12Y_CONST.TEXT.DOCUMENT_DESCRIPTIONLIST_1}
                                                    description2={A12Y_CONST.TEXT.DOCUMENT_DESCRIPTIONLIST_2}
                                                    dependent={this.props.dependent}
                                                    onCreate={this.landingPageBtnEvent}
                                                    welcomeScreenType={A12Y_CONST.LABEL.DOCUMENTS}
                                                    isOverview={this.state.isOverView}
                                                    buttonTitle={this.state.btnTitle}
                                                />
                                            </div>
                                        </div>
                                    </div>
                            )
                        }
                        {
                                    this.state.isDownloadActive && <div className="myProfileAccount animationall mainRightSidebare">
                                        <div className='rightPanelWidhtSidebar'>
                                            <div className="rightPanelWidhtSidebarContainer fullWidth">
                                                <LoaderFullScreen/>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>

        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
    dependent : state.dependentReducer.dependent,
    isDocumentChecked : state.dependentReducer.isDocumentChecked,
    isCommingFromDependentView : state.dependentReducer.isCommingFromDependentView,
    isCommingFromDependentInvoiceView : state.dependentReducer.isCommingFromDependentInvoiceView,
});

const mapDispatchToProps = dispatch => ({
    checkDocumentScreen : (documentStatus) => dispatch(dependentActions.checkDocumentScreen(documentStatus)),
    commingFromDependentInvoiceView : (status) => dispatch(dependentActions.commingFromDependentInvoiceView(status)),
    isCommingFromDependentViewLegal : (status) => dispatch(dependentActions.isCommingFromDependentViewLegal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps) (DocumentScreen)