export async function browserType() {
    let browser_name = null;
    if(navigator.userAgent.indexOf("Chrome") !== -1)
    {
        browser_name = 'chrome';
    }
    else if(navigator.userAgent.indexOf("Safari") !== -1)
    {
        browser_name = 'safari';
    }
    else if(navigator.userAgent.indexOf("Firefox") !== -1)
    {
        browser_name = 'firefox';
    }
    return browser_name;
}