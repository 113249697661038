import React, {useEffect, useState} from 'react'
import A12Y_CONST from "../common/a12yConst";
import {deleteNote, getNotes, postNotes, updateNote} from "../util/NotesCommonApi";
import LoaderFullScreen from "./LoaderFullScreenComponent";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import PopUpIncomeExpComponent from "./PopUpIncomeExpComponent";


function NotesComponent(props) {
    const [note, setNote] = useState('');
    const [noteList, setNoteList] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const [editMote, setEditMote] = useState(false)
    const [isPopUp, setIsPopUp] = useState(false)
    const [seletedNote, setSeletedNote] = useState({})
    const [deleteItem, setDeleteItem] = useState({})

    useEffect(() =>{
        getAllNotes()
    },[])

    const handleNoteChange = (e) => {
        if(e.target.value === " "){
            e.preventDefault();
        }else {
            setNote(e.target.value);
        }
    }

    useEffect(() => {
        setNoteList(noteList)
    },[noteList])

    const getAllNotes = async () => {
        await setLoading(true);
        let response = await getNotes(props.dependent.id, props.selectedItem.id, props.noteType)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            // this.setState({noteList:response.data.sr.notes,noteLoader:false})
            console.log('list', response.data.sr.notes)
            await setNoteList(response.data.sr.notes)
            await setLoading(false);
        }else {
            //  this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false,noteLoader:false})
            setErrorMessage(response.data.sr?.a12yErr?.message)
            await setLoading(false);
        }
    }
    const handleSubmitNote = async () => {
        await setLoading(true);
        let obj = {
            "note" : note,
            "note_type" : props.noteType,
            "ref_id" : props.selectedItem.id
        }
        if(editMote){
            try {
                let response = await updateNote(props.dependent.id, seletedNote.id, obj)
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    await setSeletedNote({})
                    await setNote('')
                    await getAllNotes(props.selectedItem.id)
                    await setLoading(false);
                    await setEditMote(false)

                }else {
                    setErrorMessage(response.data.sr?.a12yErr?.message)
                    await setLoading(false);
                    await setEditMote(false)
                    // await setNote('')
                    // await setSeletedNote({})
                    console.log('check', response.data.sr?.a12yErr?.message)
                }
            }catch (e) {
                setErrorMessage(A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG)
                    await setSeletedNote({})
                    await setNote('')
                    await setLoading(false);
                    await setEditMote(false)


            }

        }else {
            try {
                let response = await postNotes(props.dependent.id, obj)
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    await setNote('')
                    await getAllNotes(props.selectedItem.id)
                    await setLoading(false);
                } else {
                    setErrorMessage(response.data.sr?.a12yErr?.message)
                    await setLoading(false);
                }
            } catch (e) {
                setErrorMessage(A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG)
                await setLoading(false);
            }
        }

    }
    const cancelSubmitNote = async () => {
        setNote('')
        setSeletedNote({})
    }


    const editNote = async (item)  => {
       await setEditMote(true);
       await setSeletedNote(item)
       await setNote(item.note)

    }

    const showitemDeletePop = async (item) => {
        await setDeleteItem(item)
        setIsPopUp(true)

    }
    const deleteNoteHandle = async (item) => {
        await setLoading(true);
        let response = await deleteNote(props.dependent.id, item.id)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            // this.setState({noteList:response.data.sr.notes,noteLoader:false})
            console.log('list', response.data.sr.notes)
            await setNoteList(response.data.sr.notes)
            await getAllNotes(props.selectedItem.id)
            await setLoading(false);
        }else {
            //  this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false,noteLoader:false})
            setErrorMessage(response.data.sr?.a12yErr?.message)
            await setLoading(false);
        }
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }

    const handleDeleteButton = () => {
        setIsPopUp(false)
        deleteNoteHandle(deleteItem)
    }
    return (
        <div className="fullWidth viewNoteBox">

            <>
                {loading ?
                    <LoaderFullScreen />
                    :
                    null
                }
                {!seletedNote.id && props?.dependent?.access?.can_update?
                <div className="fullWidth marginBottom15">
                    <div className="fullWidth positionRelative addNoteCol">
                        <textarea
                            rows="2"
                            onChange={handleNoteChange}
                            value={note}
                            maxLength='4000'
                            className="fullWidth listNote"
                            placeholder={A12Y_CONST.LABEL.TYPE_SOMETHING_NOTE}
                        ></textarea>
                        {note.length > 0 ?
                            <div  className='fullWidth marginTop10'>
                                <span id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.BUTTON_TEXT.ADD} className='notesSaveButton' onClick={handleSubmitNote}>{A12Y_CONST.BUTTON_TEXT.ADD}</span>
                                <span id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.BUTTON_TEXT.CANCEL} className='notesCancelButton' onClick={cancelSubmitNote}>{A12Y_CONST.BUTTON_TEXT.CANCEL}</span>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                null
                }
                 <div className="fullWidth">
                        {
                            noteList?.length > 0 && noteList.map((noteItem, index) => {
                                return (
                                    <div  className='fullWidth listBgRow' key={index} >
                                        { noteItem.id === seletedNote.id ?
                                            <div className="fullWidth marginBottom15">
                                                <div className="fullWidth positionRelative addNoteCol">
                                                    <textarea
                                                        rows="2"
                                                        onChange={handleNoteChange}
                                                        value={note}
                                                        maxLength='4000'
                                                        className="fullWidth listNote"
                                                        placeholder={A12Y_CONST.LABEL.TYPE_SOMETHING_NOTE}
                                                    ></textarea>
                                                    {note.length > 0 ?
                                                        <div  className='fullWidth marginTop10'>
                                                            <span id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.BUTTON_TEXT.UPDATE} className='notesSaveButton' onClick={handleSubmitNote}>{A12Y_CONST.BUTTON_TEXT.UPDATE}</span>
                                                            <span id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.BUTTON_TEXT.CANCEL} className='notesCancelButton' onClick={cancelSubmitNote}>{A12Y_CONST.BUTTON_TEXT.CANCEL}</span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className='listnoteMainCol positionRelative'>
                                                <div className='listNoteDateTime fullWidth positionRelative'><span className='noteCreated'>{noteItem.editor.first_name}</span><span className='noteItemCreatedDate'>{noteItem.created_date}</span>
                                                    {props?.dependent?.access?.can_update ?
                                                    <div className='noteEditDeleteCol'>
                                                        <span id={A12Y_CONST.TEXT.EDIT+noteItem.id} onClick={() => {editNote(noteItem)}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                                                <path d="M17.293 4.29287C17.683 3.90237 18.317 3.90237 18.707 4.29287L22.707 8.29287C23.098 8.68337 23.098 9.31657 22.707 9.70707L9.707 22.7071C9.52 22.8946 9.265 23 9 23H5C4.4477 23 4 22.5523 4 22V18C4 17.7348 4.1054 17.4804 4.2929 17.2929L14.293 7.29307L17.293 4.29287ZM15 9.41417L6 18.4142V21H8.586L17.586 12L15 9.41417ZM19 10.5858L20.586 8.99997L18 6.41417L16.414 7.99997L19 10.5858Z" fill="#71767A"/>
                                                            </svg>
                                                        </span>
                                                        <span id={A12Y_CONST.TEXT.DELETE+noteItem.id} onClick={() => {showitemDeletePop(noteItem)}}>
                                                           <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                            <path d="M9 6C9 4.8954 9.89543 4 11 4H17C18.1046 4 19 4.8954 19 6V8H20.9897C20.9959 7.9999 21.0021 7.9999 21.0083 8H23C23.5523 8 24 8.4477 24 9C24 9.5523 23.5523 10 23 10H21.9311L21.0638 22.1425C20.989 23.189 20.1182 24 19.0689 24H8.93112C7.88184 24 7.01096 23.189 6.9362 22.1425L6.06888 10H5C4.44772 10 4 9.5523 4 9C4 8.4477 4.44772 8 5 8H6.99174C6.99795 7.9999 7.00414 7.9999 7.01032 8H9V6ZM11 8H17V6H11V8ZM8.07398 10L8.93112 22H19.0689L19.926 10H8.07398ZM12 12C12.5523 12 13 12.4477 13 13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13C11 12.4477 11.4477 12 12 12ZM16 12C16.5523 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V13C15 12.4477 15.4477 12 16 12Z" fill="#71767A"/>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                        :
                                                    null
                                                    }
                                                    </div>
                                                <div className='listNoteTitle'>{noteItem.note}</div>

                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
            </>

            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
}

export default NotesComponent
