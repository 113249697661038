import React,{Component} from 'react'
import DependentProfileStep1 from './DependentProfileStep1'
import DependentProfileStep3 from './DependentProfileStep3'
import DependentProfileStep4 from './DependentProfileStep4'
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions'
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from "../common/a12yConst";
import {secureClient} from "../config/axiosClient";
import axios from "axios";
import DependentProfileStep2Type from "./DependentProfileStep2Type";
import {uploadImage} from "../util/imageUpload";

class DependentProfileAll extends Component{

    constructor(props){
        super(props)
        this.state = {
            currentScreen :this.props.screen !== null ?  this.props.screen : 1,
            displayName:this.props.dependent ? this.props.dependent.nick_name: '',
            advocate_id:this.props.userState.userInfo.id,
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            dependent:this.props.dependent,
            isImageRemove:false,
            fileUploadingData:{},
            showProgressBar:false,
            fileEditMode:true,
            fileLoader:false,
            roleType:0,
            uploadedFiles:{
                document_ref: {attached_files:[]}
            },
            docList:[],
            removedDocFile:[],
        }
    }

    componentDidMount = async ()=> {
       // await this.props.saveDependentDocs([])
        if(this.props.dependent?.id){
            await this.getDependentDocument()
        }
        let temp = this.state.uploadedFiles;
        temp.document_ref.attached_files = this.props.dependentDocs.length > 0 ? this.props.dependentDocs : []
        await this.setState({uploadedFiles:temp,docList:this.props.dependentDocs})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.dependentDocs.length !== this.state.docList.length){
            this.setState({docList:this.props.dependentDocs})
        }
    }


    submitHandler = (screen,data) =>{
        this.setState({currentScreen:screen,})
    }

    createDependent = async(screen,data)=>{
      this.setState({loading:true,isError:false})
        data.advocate_id =this.state.advocate_id
        await this.props.createDependent(this.state.advocate_id,data)
         this.setState({loading:false})
        if(this.props.isError){
            this.setState({isError:true, errorMessage:this.props.errMsg})
        }else {
            if(Object.keys(this.props.dependentProfileObj).length > 0){
                this.setState({loading:true})
                let profile = this.props.dependentProfileObj
                let dependent = this.props.dependent
                await uploadImage(profile.croppedImageUrl1, profile.advocate_id,dependent.id,profile.cropedUrl,dependent)
                await this.props.dependentProfileUpdate({});
                this.setState({loading:false})
            }
            this.setState({message:this.props.successMessage})
            this.setState({dependent:this.props.dependent,displayName:data.nick_name})
            this.setState({message:'',currentScreen:screen,})
            if((Object.keys(this.props.dependent).length > 0 && this.props.activityTypeList.length === 0) || this.props.activityTypeList[0] === undefined){
                await this.props.getActivityTypeList(this.props.dependent.id)
            }
        }

    }

    step1OnSubmit = async(screen,dependent,type) =>{
        if(type === 'post'){
           await this.createDependent(screen,dependent)
        }else {
          let obj =  {
                first_name : dependent.first_name,
                last_name : dependent.last_name,
                nick_name : dependent.nick_name,
                gender : dependent.gender,
                dob : dependent.dob ? dependent.dob : null ,
              advocate_id : this.state.advocate_id
            }
            await this.updateStep1DependentData(screen,obj)
        }
    }

    onProfilUploadHandling = async (croppedImageUrl1,advocate_id,dependent_id,cropedUrl,dependent)=>{
        let obj = {
            croppedImageUrl1 : croppedImageUrl1,
            advocate_id : advocate_id,
            dependent_id : dependent_id,
            cropedUrl : cropedUrl
        }
        if(Object.keys(this.props.dependent).length > 0){
            await uploadImage(croppedImageUrl1,advocate_id,dependent_id,cropedUrl,dependent)
        }else {
            await this.props.dependentProfileUpdate(obj);
        }
    }

    updateStep1DependentData = async(screen,dependent)=>{
        if(this.state.dependent){
            this.setState({loading:true,isError:false,displayName:dependent.nick_name})
            await this.updateDependent(screen,dependent,this.state.dependent.id)
        }
    }

    updateDependent = async(screen, dependent, dependentId)=>{
        await this.props.updateDependent(dependent,dependentId)
       // this.setState({loading:false})
        if(this.props.isError){
            this.setState({isError:true, errorMessage:this.props.errMsg,loading:false})
        }else {
            this.setState({message:this.props.successMessage})
            if(screen === 4){
                this.props.tabSelect()
            }else{
            this.setState({message:'',currentScreen:screen,loading:false})
            }
        }
    }

    updateStep3DependentData = async(screen,data,fileInfo,fileBase64Info,deleteFiles)=>{
        if(this.state.dependent){
            this.setState({removedDocFile:[]})
            this.setState({loading:true,isError:false})
            await this.fileUploading(fileInfo,fileBase64Info,deleteFiles)
            let obj = {
                first_name : this.props.dependent.first_name,
                last_name : this.props.dependent.last_name,
                case_file_num : data.caseFileNumber,
                appointment_date : data.appointment_date !== "" ? data.appointment_date : null ,
                advocate_id : this.state.advocate_id
            }
            await this.updateDependent(screen,obj, this.state.dependent.id)
        }
    }

    fileUploading = async (fileInfo, fileBase64Info,deleteFiles)=>{
        await this.setState({fileUploadingData: {}})
        if(deleteFiles.length > 0){
            await this.deleteDependetFile(deleteFiles)
        }
        try {
            if(fileInfo.length > 0 && fileBase64Info.length > 0){
                if(this.props.legalDocId && this.props.legalDocId !== ''){
                    this.setState({fileLoader:true,fileEditMode:false})
                    let temp = (this.props.dependentDocs && this.props.dependentDocs.length > 0) || this.props.dependentDocs !== undefined ? this.props.dependentDocs : []
                    let files = [];
                    files = [...temp, ...fileInfo]
                    await this.props.saveDependentDocs(files)
                    let obj = {}
                    obj = {'files' : fileInfo}
                    let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                    if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                        let signed_urls = Object.keys(response.data.sr.signed_urls)
                        let i = 0;
                        for await (const item of signed_urls) {
                            let uploadUrl = response.data.sr.signed_urls[item];
                            let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line no-loop-func
                            let fileResp = await fetch(filterData[0].base64);
                            let Blobdata = await fileResp.blob();
                            await this.setState({showProgressBar:true})
                             await axios.put(uploadUrl,Blobdata , {headers:{
                                    "Content-Type" : fileInfo[i].contentType
                                },onUploadProgress: async data=>{ // eslint-disable-line
                                    let index = this.state.docList.findIndex(x => x.s3_key === fileInfo[i].s3_key)
                                    let temp={data:data,index:index} // eslint-disable-line
                                    await this.setState({fileUploadingData:temp})
                                }})
                            await this.setState({showProgressBar:false})
                            i++;
                        }

                        for (let i = 0; i < fileInfo.length; i++){
                            let obj = {
                                "s3_key" : fileInfo[i].file_id,
                                "display_name" : fileInfo[i].name,
                                "file_content_type" : fileInfo[i].contentType,
                                "is_folder" : false,
                                "metadata" : {
                                    "size" : fileInfo[i].size,
                                    "upload_date" : new Date()
                                },
                                "parent_id" : this.props.legalDocId
                            }
                            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS + '/' + this.props.dependent.id + '/' + A12Y_CONST.API_ENDPOINT.DOCUMENTS, obj);
                            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                                let temp = this.props.dependentDocs;
                                let index = this.props.dependentDocs.findIndex(x => x.s3_key === fileInfo[i].s3_key);
                                temp[index].id = response.data.sr.doc.id
                                await this.props.saveDependentDocs(temp)
                                //this.getDocument();
                                this.setState({fileLoader: false})
                                this.setState({loaderFullScreen:false})
                            } else {
                                this.setState({error: true, errorMessage: response.data.sr?.a12yErr?.message})
                                this.setState({fileLoader: false})
                                this.setState({loaderFullScreen:false})
                            }
                            obj={}
                        }

                        this.setState({fileLoader:false})
                    }else {
                        this.setState({fileLoader:false})
                    }
                }
            }
        }catch (e) {
            this.setState({error: true, errorMessage: A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,fileLoader: false,loaderFullScreen:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DEPENDENT_UPLOAD_DOCS,e)
        }


    }

    deleteDependetFile = async (file) =>{
        try {
            for  (let i=0; i< file.length; i++){
              //  let  data = this.props.dependentDocs.find(item => item.file_id === obj.file_id)
                if(file[i].id){
                    this.setState({fileLoader:true})
                    const response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCUMENTS+'/'+file[i].id);
                    if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                        let filteredData = this.props.dependentDocs.filter(item => item.file_id !== file[i].file_id)
                        await this.props.saveDependentDocs(filteredData)
                        // this.getDocument()
                        this.setState({fileLoader:false})
                    }else {
                        this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                        this.setState({fileLoader:false})
                    }
                    this.setState({fileLoader:false})
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,fileLoader:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DEPENDENT_DELETE_DOCS,e)
        }

    }

    updateStep4DependentData = async(screen,data)=>{
        if(this.state.dependent){
            await  this.setState({loading:true,isError:false})
            let obj = {
                first_name : this.props.dependent.first_name,
                last_name : this.props.dependent.last_name,
                email : data.email,
                advocate_id : this.state.advocate_id,
                phone : data.phone
            }
          //  dependent.phone = data.phone;
            await this.updateDependent(screen,obj,this.state.dependent.id)
            if(this.props.isError){
              // this.props.onCompleteDependent()
            }else {
                this.onFinishHandler();
            }
        }
    }

    cancelHandler = async (screen) =>{
        if(screen === 0){
            this.onFinishHandler()
            this.setState({currentScreen:screen})
            this.props.yourProfileTabActive()
            if(Object.keys(this.props.dependentProfileObj).length > 0){
                await  await this.props.dependentProfileUpdate({});
            }

        }else {
            this.setState({currentScreen:screen})
        }

        if(this.state.removedDocFile.length > 0){
            let arr = this.props.dependentDocs
            let removedFile = this.state.removedDocFile;
            for await (const file of removedFile){
                arr.splice(file.index, 0, file.data);
            }
            await this.props.saveDependentDocs(arr)
            this.setState({removedDocFile:[]})
        }
    }
    handleRemoveImg = async () => {
        try{
            await this.props.dependentProfileUpdate({});
            let url = A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DELETE_PROFILE_PIC
            let res = await secureClient.delete(url)
            if (!res.data.error && res.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let dependent = this.props.dependent
                dependent.profile_pic_url = ''
                this.props.updateDependent(dependent, dependent.id)
                this.setState({isImageRemove:true})
            }else {

            }
        }catch (e) {
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DELETE_PROFILE_PIC,e)
        }

    }
    onFinishHandler = async ()=>{
        await this.props.saveDependentDocs([])
        this.props.onFinish();
    }

    overlay  = (status) => {
        this.props.overlay(status);
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    removeFileIndexHandler = async(index) =>{
        if(index !== undefined && index !== null && this.props.dependentDocs.length > 0){
            let temp = this.state.removedDocFile;
            let docList = this.state.docList;
            let isDataExist = await docList[index]
            if(isDataExist){
                temp.push({data:isDataExist, index:index})
                this.setState({removedDocFile:temp})
                docList.splice(index, 1);
                 await this.setState({docList:docList})
            }
        }
    }
    updateStep2DependentData = async (screen, data) => {
        await this.setState({loaderFullScreenVisible:false, loading:true})
        let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ROLE_TYPE, data);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            // await this.setState({roleType:data, currentScreen:screen,loading:false})
            await this.props.selectUserRoleType(data.user_role_type)
            if(this.props.userRoleType === A12Y_CONST.TEXT.OTHER){
                this.props.tabSelect()
            }else{
                await this.setState({roleType:data, currentScreen:screen,loading:false})
            }
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
    }

    getDependentDocument = async () => {
        //this.setState({loading:true})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCUMENTS);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let docs = response.data.sr.documents;
           let legalDocList = docs.find(item => item.id === this.props.legalDocId)
            let docList = []
            legalDocList.childs.forEach((item,i)=>{
                let obj = {
                    contentType : item.file_content_type,
                    fileMetadata:{},
                    file_id:item.s3_key,
                    id:item.id,
                    name:item.display_name,
                    s3_key:this.props.dependent.id+'/document/'+item.s3_key,
                    size:item.metadata.size
                }
                docList.push(obj);
            })
            await this.props.saveDependentDocs(docList)
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loading:false})
        }
        this.setState({loading:false})
    }

    render(){
        const {fileUploadingData,showProgressBar,fileEditMode} = this.state;
        return(
                <div className='fullWidth'>
                    {
                        this.state.currentScreen === 1 ?
                            <div className="fullWidth">
                                 <div className='dependentDefaultMainC0l fullWidth'>
                                    <DependentProfileStep1
                                        displayName={''}
                                        onSubmit={this.step1OnSubmit}
                                        onProfilUpload={this.onProfilUploadHandling}
                                        step={1}
                                        loading={this.state.loading}
                                        message={this.state.message}
                                        error={this.state.error}
                                        errorMessage={this.state.errorMessage}
                                        onCancel={this.cancelHandler}
                                        dependent={this.props.dependent}
                                        isDataChanged={this.dataChangeHandler}
                                        handleRemoveImg={this.handleRemoveImg}
                                        isImageRemove={this.state.isImageRemove}
                                        overlay = {this.overlay}
                                        profileObj={this.props.dependentProfileObj}
                                    />
                                </div>
                                        </div>
                            :
                            this.state.currentScreen === 2 ?
                            <div className="titleRow margignTop768_13 margignBottom768_10 dependentWizard">
                                <div className='dependentDefaultMainC0l fullWidth'>
                                        <DependentProfileStep2Type
                                        step={2}
                                        loading={this.state.loading}
                                        message={this.state.message}
                                        error={this.state.error}
                                        errorMessage={this.state.errorMessage}
                                        onCancel={this.cancelHandler}
                                        dependent={this.props.dependent}
                                        onFinish={this.onFinishHandler}
                                        handleRemoveImg={this.handleRemoveImg}
                                        isImageRemove={this.state.isImageRemove}
                                        overlay = {this.overlay}
                                        onSubmit={this.updateStep2DependentData}
                                        roleType={this.props.userRoleType}
                                       />
                                    </div>
                                </div>
                                :
                                this.state.currentScreen === 3 ?
                                    <div className="fullWidth">
                                        <div className='dependentDefaultMainC0l fullWidth'>
                                            <DependentProfileStep3
                                                displayName={this.state.displayName}
                                                onSubmit={this.updateStep3DependentData}
                                                step={3}
                                                loading={this.state.loading}
                                                message={this.state.message}
                                                error={this.state.error}
                                                errorMessage={this.state.errorMessage}
                                                onCancel={this.cancelHandler}
                                                dependent={this.props.dependent}
                                                onFinish={this.onFinishHandler}
                                                roleType={this.props.userRoleType}
                                                // uploadFiles={this.fileUploading}
                                                fileUploadingData={fileUploadingData}
                                                showProgressBar={showProgressBar}
                                                uploadedFiles={{document_ref: {attached_files:this.props.dependentDocs}}}
                                                fileEditMode={fileEditMode}
                                                fileLoader={this.state.fileLoader}
                                                isDataChanged={this.dataChangeHandler}
                                                removeFileIndex={this.removeFileIndexHandler}
                                            />
                                        </div>
                                    </div>
                                    :
                                    this.state.currentScreen === 4 ?
                                        <div className='fullWidth'>
                                            <h2 className="titleWithSidebar marginBottom30"><span>{A12Y_CONST.LABEL.DEPENDENT_LABEL}</span></h2>
                                            <div className='dependentDefaultMainC0l fullWidth'>
                                                <DependentProfileStep4
                                                    displayName={this.state.displayName}
                                                    onSubmit={this.updateStep4DependentData}
                                                    step={4}
                                                    loading={this.state.loading}
                                                    message={this.state.message}
                                                    error={this.state.error}
                                                    errorMessage={this.state.errorMessage}
                                                    onCancel={this.cancelHandler}
                                                    dependent={this.props.dependent}
                                                    onFinish={this.onFinishHandler}
                                                    isDataChanged={this.dataChangeHandler}
                                                />
                                            </div>
                                        </div>
                                            :
                                        null
                    }
            </div>
        )
    }
}
const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    dependentList : state.dependentReducer.dependentList,
    successMessage : state.dependentReducer.successMessage,
    dependent : state.dependentReducer.dependent,
    dependentDocs : state.dependentReducer.dependentDocs,
    legalDocId : state.dependentReducer.legalDocId,
    activityTypeList: state.dependentReducer.activityTypeList,
    dependentProfileObj: state.dependentReducer.dependentProfileObj,
    userRoleType: state.userDetailReducer.userRoleType,
});

const mapDispatchToProps = dispatch => ({
    createDependent : (advocate_id,data) => dispatch(dependentActions.createDependent(advocate_id,data)),
    updateDependent : (dependent,dependentId) => dispatch(dependentActions.updateDependent(dependent,dependentId)),
    saveDependentDocs : (docs) => dispatch(dependentActions.saveDependentDocs(docs)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    getActivityTypeList : (dependentId) => dispatch(dependentActions.getActivityTypeList(dependentId)),
    selectUserRoleType : (type) => dispatch(userDetailActions.selectUserRoleType(type)),
    dependentProfileUpdate : (profileObj) => dispatch(dependentActions.dependentProfileUpdate(profileObj)),
});


export default connect(mapStateToProps, mapDispatchToProps) (DependentProfileAll);