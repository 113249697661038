import A12Y_CONST from '../../common/a12yConst'
let initialState = {
    errMsg:'',
    isUpdated:false,
    isError:false,
    successMessage:'',
    pathWithoutLogin:'',
    dependentList:[],
    dependent:{},
    addressList:[],
    currentExpense:{},
    currentDebt:{},
    currentIncome:{},
    currentActivity:{},
    IsExpenseCreateFromActivityLog:false,
    isDocumentChecked:false,
    isComingFromActivityView:false,
    activityTypeList:[],
    expenseTypeList:[],
    incomeTypeList:[],
    debtTypeList:[],
    dependentDocs:[],
    legalDocId:'',
    isCommingFromDependentView:false,
    isCommingFromDependentInvoiceView:false,
    isCommingFromActivityList:'',
    income_present:false,
    expense_present:false,
    activity_present:false,
    asset_present:false,
    userId:null,
    screen:null,
    assetTypeList:[],
    assetList:[],
    asset:{},
    currentAsset:{},
    doctorSpecialistTypeList:[],
    currentDoctor:{},
    currentMedication:{},
    medication_data:{},
    doctorList:[],
    medicine_present:false,
    doctor_present:false,
    isMyAccountClicked:false,
    dependentProfileObj:{},
    assetEditScreenKey:'',
    tabName:null,
    previousCurrentMedication:{}
}

function dependentReducer(state = initialState, action) {
    switch (action.type) {
        case A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_START:
            return { ...state, dependentList: [], errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_SUCCESS:
            return { ...state, dependentList: action.dependents, successMessage:action.message, dependent:action.dependent}

        case A12Y_CONST.DISPATCH_ACTION.SWITCH_DEPENDENT:
            return { ...state,dependent:action.dependent}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_LIST:
            return { ...state,dependentList:action.dependents}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_USERS_PREFERENCES:
            return { ...state, dependent:action.dependent}

        case A12Y_CONST.DISPATCH_ACTION.TAB_NAME:
            return { ...state, tabName:action.tabName}

        case A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true, dependent:{}}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_DEPENDENT_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_DEPENDENT_SUCCESS:
            return { ...state, successMessage:action.message, dependent:action.dependent}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_DEPENDENT_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_SUCCESS:
            return { ...state, successMessage:action.message, dependent:action.dependent}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_SUCCESS:
            return { ...state, successMessage:action.message, addressList: action.addressList}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_ADDRESSES_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_ADDRESSES_SUCCESS:
            return { ...state, successMessage:action.message, addressList:action.addressList}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_ADDRESSES_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.GET_ADDRESSES_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.GET_ADDRESSES_SUCCESS:
            return { ...state, successMessage:action.message, addressList:action.addressList}

        case A12Y_CONST.DISPATCH_ACTION.GET_ADDRESSES_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_PROFILE_PIC:
            return { ...state, errMsg: action.errMsg, isError: true, dependent:action.dependent}
        case A12Y_CONST.DISPATCH_ACTION.CLEAN_DEPENDENT:
            return { ...state, errMsg: action.errMsg,
                isUpdated:false,
                isError:false,
                successMessage:'',
                dependentList:[],
                dependent:{},
                addressList:[],
                currentExpense:{},
                currentDebt:{},
                currentIncome:{},
                currentActivity:{},
                IsExpenseCreateFromActivityLog:false,
                isDocumentChecked:false,
                isComingFromActivityView:false,
                activityTypeList:[],
                expenseTypeList:[],
                incomeTypeList:[],
                debtTypeList:[],
                dependentDocs:[],
                legalDocId:'',
                isCommingFromDependentView:false,
                isCommingFromDependentInvoiceView:false,
                isCommingFromActivityList:'',
                income_present:false,
                expense_present:false,
                activity_present:false,
                asset_present:false,
                assetTypeList:[],
                assetList:[],
                asset:{},
                currentAsset:{},
                doctorSpecialistTypeList:[],
                currentDoctor:{},
                currentMedication:{},
                medication_data:{},
                doctorList:[],
                medicine_present:false,
                doctor_present:false,
                isMyAccountClicked:false,
                dependentProfileObj:{},
                assetEditScreenKey:'',
                tabName:null,
                previousCurrentMedication:{},

            }

        case A12Y_CONST.DISPATCH_ACTION.CURRENT_EXPENSE_DATA:
            return { ...state, currentExpense: action.expense }
            
        case A12Y_CONST.DISPATCH_ACTION.CURRENT_INCOME_DATA:
            return { ...state, currentIncome: action.income }

        case A12Y_CONST.DISPATCH_ACTION.CURRENT_ACTIVITY_DATA:
            return { ...state, currentActivity: action.activity }
            
        case A12Y_CONST.DISPATCH_ACTION.CURRENT_ASSET_DATA:
            return { ...state, currentAsset: action.asset }
        
        case A12Y_CONST.DISPATCH_ACTION.CURRENT_DEBT_DATA:
            return { ...state, currentDebt: action.debt }
                
        case A12Y_CONST.DISPATCH_ACTION.CURRENT_DOCTOR_DATA:
            return { ...state, currentDoctor: action.doctor }

        case A12Y_CONST.DISPATCH_ACTION.CURRENT_MEDICATION_DATA:
            return { ...state, currentMedication: action.medication }

        case A12Y_CONST.DISPATCH_ACTION.MEDICATION_DATA:
            return { ...state, medication_data: action.medication_data }

        case A12Y_CONST.DISPATCH_ACTION.CREATE_EXPENSE_USING_ACTIVITY_LOG:
            return { ...state, IsExpenseCreateFromActivityLog: action.status }

        case A12Y_CONST.DISPATCH_ACTION.VIEW_EXPENSE_USING_ACTIVITY_VIEW:
            return { ...state, isComingFromActivityView: action.status }

        case A12Y_CONST.DISPATCH_ACTION.DOCUMENT_SCREEN_CHECKED:
            return { ...state, isDocumentChecked: action.payload }

        case A12Y_CONST.DISPATCH_ACTION.ACTIVITY_TYPE_LIST_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.ACTIVITY_TYPE_LIST_SUCCESS:
            return { ...state, successMessage:action.message, activityTypeList:action.activityList}

        case A12Y_CONST.DISPATCH_ACTION.ACTIVITY_TYPE_LIST_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.EXPENSE_TYPE_LIST_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.EXPENSE_TYPE_LIST_SUCCESS:
            return { ...state, successMessage:action.message, expenseTypeList:action.expenseList}

        case A12Y_CONST.DISPATCH_ACTION.EXPENSE_TYPE_LIST_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}
        
        case A12Y_CONST.DISPATCH_ACTION.DEBT_TYPE_LIST_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.DEBT_TYPE_LIST_SUCCESS:
            return { ...state, successMessage:action.message, debtTypeList:action.debtList}

        case A12Y_CONST.DISPATCH_ACTION.DEBT_TYPE_LIST_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.INCOME_TYPE_LIST_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.INCOME_TYPE_LIST_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.INCOME_TYPE_LIST_SUCCESS:
            return { ...state, successMessage:action.message, incomeTypeList:action.incomeList}

        case A12Y_CONST.DISPATCH_ACTION.DEPENDENT_DOCS:
            return { ...state, dependentDocs:action.docs}

        case A12Y_CONST.DISPATCH_ACTION.MY_ACCOUNT_CLICKED:
            return { ...state, isMyAccountClicked:action.status}

        case A12Y_CONST.DISPATCH_ACTION.LEGAL_DOC_ID:
            return { ...state, legalDocId:action.doc_id}

        case A12Y_CONST.DISPATCH_ACTION.IS_COMMING_FROM_DEPENDENT_VIEW:
            return { ...state, isCommingFromDependentView:action.status}

        case A12Y_CONST.DISPATCH_ACTION.IS_COMMING_FROM_DEPENDENT_INVOICE_VIEW:
            return { ...state, isCommingFromDependentInvoiceView:action.status}

        case A12Y_CONST.DISPATCH_ACTION.IS_COMMING_FROM_ACTIVITY_LIST:
            return { ...state, isCommingFromActivityList:action.status}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_META_DATA:
            return { ...state, income_present:action.income_present, expense_present:action.expense_present, activity_present:action.activity_present, asset_present:action.asset_present,}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_MEDICINE_PRESENT_META_DATA:
            return { ...state, medicine_present:action.medicine_present}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_DOCTOR_PRESENT_META_DATA:
            return { ...state, doctor_present:action.doctor_present}

        case A12Y_CONST.DISPATCH_ACTION.USER_SESSION_OUT_USER_ID:
            return { ...state, userId:action.userId}

        case A12Y_CONST.DISPATCH_ACTION.USER_SESSION_OUT_SCREEN:
            return { ...state,  screen: action.screen}

        case A12Y_CONST.DISPATCH_ACTION.ASSET_TYPE_LIST_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.ASSET_TYPE_LIST_SUCCESS:
            return { ...state, successMessage:action.message, assetTypeList:action.assetList}

        case A12Y_CONST.DISPATCH_ACTION.ASSET_TYPE_LIST_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.ASSET_OBJ:
            return { ...state, asset: action.data}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_ASSET_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_ASSET_SUCCESS:
            return { ...state, asset: action.asset, successMessage:action.message, }

        case A12Y_CONST.DISPATCH_ACTION.CREATE_ASSET_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.DOCTOR_SPECIALIST_LIST_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.DOCTOR_SPECIALIST_SUCCESS:
            return { ...state, successMessage:action.message, doctorSpecialistTypeList:action.doctorSpecialty}

        case A12Y_CONST.DISPATCH_ACTION.DOCTOR_SPECIALIST_TYPE_LIST_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_START:
            return { ...state, errMsg: '', isError: false, successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_SUCCESS:
            return { ...state, doctorList: action.doctorList, successMessage:action.message, }

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_DOCTORS_LIST:
            return { ...state, doctorList: action.doctorList}

        case A12Y_CONST.DISPATCH_ACTION.DEPENDENT_PROFILE:
            return { ...state, dependentProfileObj: action.dependentProfileObj}

        case A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_ERROR:
            return { ...state, errMsg: action.errMsg, isError: true,}

        case A12Y_CONST.DISPATCH_ACTION.ASSET_EDIT_SCREEN_KEY:
            return { ...state, assetEditScreenKey : action.key}

        case A12Y_CONST.DISPATCH_ACTION.PATH_BEFORE_LOGIN:
            return { ...state, pathWithoutLogin : action.data}

        case A12Y_CONST.DISPATCH_ACTION.PREVEIOUS_MEDICATION_DATA:
            return { ...state, previousCurrentMedication : action.data}

        default:
            return state
    }
}

export default dependentReducer;