import A12Y_CONST from '../common/a12yConst'
import axios from "axios";
import {secureClient} from "../config/axiosClient";

export async function downloadFile(obj, newName){
    try{
        let s3Key = obj?.s3_key ? obj.s3_key : obj.dependent_id+'/'+obj.type+'/'+obj.id+'/'+obj.file_id
        let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_S3_SIGN_URL_GET,{
            fileKey:s3Key
        });
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let url = response.data.sr.url
            const uriArr = obj.name.split('.');
            let fileType = uriArr[uriArr.length - 1]
            let fileName = newName !== undefined ? newName : obj.name;
            let checkIsMobileDevice = detectMob()
            if(checkIsMobileDevice || !obj?.contentType?.includes("/") || (fileType !== A12Y_CONST.MIMES_TYPES.JPEG && fileType !== A12Y_CONST.MIMES_TYPES.JPG && fileType !== A12Y_CONST.MIMES_TYPES.SVG && fileType !== A12Y_CONST.MIMES_TYPES.PNG && fileType !== A12Y_CONST.MIMES_TYPES.PDF)){
                axios.get(url,{
                    headers: {
                        "Content-Type": "application/octet-stream"
                    },
                    responseType: "blob"
                }).then((response)=>{
                    const a = document.createElement("a");
                    const url = window.URL.createObjectURL(new Blob([response.data],{ type: fileType }));
                    if(navigator.userAgent.search("Firefox") > -1 && fileName.includes('.pdf')){
                        window.open(url, '_blank');
                    }else {
                        a.href = url;
                        a.download = fileName;
                        a.click();
                    }
                }).catch((err)=>{
                    console.error("error", err);
                })
            }else {
               window.open(url,fileName)
            }
        }else {
            console.error("error");
        }
    }catch (err){
        console.error("error", err);
    }
}

export function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}
