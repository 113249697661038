import React, { Component } from 'react'
import AddAssets from '../components/AddAssetFormComponent'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'



class AddAssetScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            loaderFullScreenVisible : false,
            isClickedOutside:false,
        }
    }

    selectedAssetType = async (e) => {
        let obj = {
            asset_type_id : e.id,
        }
       await this.props.saveCurrentAsset(obj)
    }

    render() {
        return (
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare" onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddAssets
                        assetTypeList={this.props.assetTypeList}
                        selectedAssetType = {this.selectedAssetType}
                        asset={this.props.asset}
                        dependent={this.props.dependent}
                        isClickedOutside={this.state.isClickedOutside}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    assetTypeList: state.dependentReducer.assetTypeList,
    asset: state.dependentReducer.asset,
});

const mapDispatchToProps = dispatch => ({
    saveCurrentAsset : (data) => dispatch(dependentActions.saveCurrentAsset(data)),
});

export default connect(mapStateToProps, mapDispatchToProps) (AddAssetScreen);


