import A12Y_CONST from '../common/a12yConst'

export async function routePathRedirect(key){
    let routesNameRedirect = new Map();
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT,A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DEPENDENT_BILLING,A12Y_CONST.ROUTE.DEPENDENT_BILLING)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DASHBOARD,A12Y_CONST.ROUTE.DASHBOARD)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DEPENDENT,A12Y_CONST.ROUTE.DEPENDENT)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DEPENDENT_VIEW,A12Y_CONST.ROUTE.DEPENDENT_VIEW)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EXPENSES,A12Y_CONST.ROUTE.EXPENSES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_EXPENSE,A12Y_CONST.ROUTE.EXPENSES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EXPENSE_VIEW,A12Y_CONST.ROUTE.EXPENSES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.INCOMES,A12Y_CONST.ROUTE.INCOMES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.INCOME_VIEW,A12Y_CONST.ROUTE.INCOMES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_INCOME,A12Y_CONST.ROUTE.INCOMES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DOCUMENTS,A12Y_CONST.ROUTE.DOCUMENTS)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ACTIVITIES,A12Y_CONST.ROUTE.ACTIVITIES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ACTIVITY_VIEW,A12Y_CONST.ROUTE.ACTIVITIES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_ACTIVITY,A12Y_CONST.ROUTE.ACTIVITIES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.INVENTORY_VIEW,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_ADDRESS,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.INVENTORYVIEW,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.INVENTORY,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_ASSET,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EDIT_ASSET,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_INSTITUTION,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_CONTACT,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_BENEFICIARY,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_CO_OWNER,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_LOAN,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.MEDICAL,A12Y_CONST.ROUTE.MEDICAL)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_MEDICATION,A12Y_CONST.ROUTE.MEDICAL)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_DOCTOR,A12Y_CONST.ROUTE.MEDICAL)
        routesNameRedirect.set(A12Y_CONST.ROUTE.RELEASE_NOTES,A12Y_CONST.ROUTE.RELEASE_NOTES)
        routesNameRedirect.set(A12Y_CONST.ROUTE.ADD_DEBT,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DEBT_VIEW,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EDIT_DEBT,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EDIT_CONTACT,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EDIT_BENEFICIARY,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EDIT_CO_OWNER,A12Y_CONST.ROUTE.INVENTORY)
        routesNameRedirect.set(A12Y_CONST.ROUTE.MEDICATION_VIEW,A12Y_CONST.ROUTE.MEDICAL)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DOCTOR_VIEW,A12Y_CONST.ROUTE.MEDICAL)
        routesNameRedirect.set(A12Y_CONST.ROUTE.EDIT_DOCTOR,A12Y_CONST.ROUTE.MEDICAL)
        routesNameRedirect.set(A12Y_CONST.ROUTE.DASHBOARD_VIEW,A12Y_CONST.ROUTE.DASHBOARD_VIEW)
 return routesNameRedirect.get(key);

}