import React, { Component } from 'react'
import MedicationViewComponent from '../components/MedicationViewComponent'
import  * as dependentActions from '../redux/actions/dependentActions'
import * as userDetailActions from "../redux/actions/userDetailActions";
import { connect } from 'react-redux';
import A12Y_CONST from "../common/a12yConst";
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import {handlePreviousMedication} from "../redux/actions/dependentActions";

export class MedicationViewScreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            isDownloadActive: false,
            error:false,
            message:'',
            errorMessage:'',

        }
    }

    componentDidMount = async () => {
        await this.props.dataChanged(false)
    }

    onEditHandler = async()=>{
        let previousMedication = Object.assign({}, this.props.currentMedication);
        await this.props.handlePreviousMedication(previousMedication)

        let medication = this.props.currentMedication;
        medication.isEditMode = true;
        await this.props.currentMedicationData(medication);
        await this.props.medicationData(medication);
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_MEDICATION,
            state: {fromMedicalView:true }
        });
    }

    onDelete = async()=>{

    }

    onCancel = async()=>{

    }

    onDownloadHandler = async(item, newName)=>{

    }

    render() {
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        {this.state.loading ?
                            <LoaderFullScreen/>
                            :
                            <MedicationViewComponent
                                dependent={this.props.dependent}
                                message={this.state.message}
                                error={this.state.error}
                                errorMessage={this.state.errorMessage}
                                onEdit={this.onEditHandler}
                                onDelete={this.onDelete}
                                onCancel={this.onCancel}
                                onDownload={this.onDownloadHandler}
                                currentMedication={this.props.currentMedication}
                            />
                        }
                        {
                            this.state.isDownloadActive && <LoaderFullScreen/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    currentIncome : state.dependentReducer.currentIncome,
    currentMedication : state.dependentReducer.currentMedication,
    previousCurrentMedication : state.dependentReducer.previousCurrentMedication,

});
const mapDispatchToProps = dispatch => ({
    currentMedicationData: (medication) => dispatch(dependentActions.currentMedicationData(medication)),
    medicationData: (medication) => dispatch(dependentActions.medicationData(medication)),
    handlePreviousMedication: (medication) => dispatch(dependentActions.handlePreviousMedication(medication)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});

export default connect(mapStateToProps,mapDispatchToProps) (MedicationViewScreen);
