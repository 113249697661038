import React, { useState, useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Loading from '../components/LoadingComponent';
import Select from 'react-select';
import stateList from '../common/stateList.json'
import countyList from '../common/countyList.json'
import countyList_1 from '../common/countyList_1.json'
import {isValidPhoneNumber}from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import EditChangesRemovePopup from "./EditChangesRemovePopup";


function AddContactComponent(props) {
    const [loading, setLoading] = useState(false);
	const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const history = useHistory();
    
	const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMeesage, setNameErrorMeesage] = useState('');
	
	const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMeesage, setEmailErrorMeesage] = useState('');

	const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [countryState, setCountryState]  = useState({});
    const [county, setCounty]  = useState({});
    const [showAddressDropDown, setShowAddressDropDown] = useState(false);
    const [line2, setLine2] = useState('');
    const [line1, setLine1] = useState('');

	const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumber_2, setPhoneNumber_2] = useState('');
    const [phoneNumber_1ErrorMessage, setPhoneNumber_1ErrorMessage] = useState('');
    const [phoneNumber_2ErrorMessage, setPhoneNumber_2ErrorMessage] = useState('');
    const [phoneNumberError, setphoneNumberError] = useState(false);
    const [phoneNumber2Error, setphoneNumber2Error] = useState(false);
    const [phoneType1LabelActive, setPhoneType1LabelActive] = useState(false);
    const [phoneType2LabelActive, setPhoneType2LabelActive] = useState(false);
    const [isPhone2DropdownSelect, setIsPhone2DropdownSelect] = useState(false);   
    const [selectedPhoneType1, setSelectedPhoneType1] = useState({});
    const [selectedPhoneType2, setSelectedPhoneType2] = useState({});
    const [isEditMode, setisEditMode] = useState(false);
    const [dropDownIndex, setDropDownIndex] = useState(null);
    const [placeServiceList, setPlaceServiceList] = useState({});
	const [fax, setFax] = useState('');
    const [faxError, setFaxError] = useState(false);
    const [faxErrorMeesage, setFaxErrorMeesage] = useState('');

    const { register, handleSubmit, getValues, setFocus,setValue,setError, formState: { errors, isSubmitting } } = useForm({ mode: "onBlur" });


    useEffect(() => {
        setFocus("name");
    }, [setFocus])

    const getPlaceFromService = async (obj)=>{
        //if (!isBrowser) return;
        const request = {
            input: obj.input,
            componentRestrictions: {country : 'us'},
            fields: ["name", "geometry", "formatted_address", "place_id"],
        };
        if(window.google){

            let service = new window.google.maps.places.AutocompleteService(document.createElement("div"));
            service.getPlacePredictions(request,(results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                    setPlaceServiceList(results)
                }
            });
        }
        //handleLoadScript().then(() => buildService())
    }

    useEffect(()=>{
        setShowAddressDropDown(false)
    },[props.isClickedOutside])

    const countryStateList = stateList;
    const stateOptions = countryStateList && countryStateList.map(state =>{
        return {label: state.label, value: state.label}
    })
	
	const countyOptions = countyList[countryState.label] && countyList[countryState.label].map(state =>{
        return {label: state.label, value: state.label}
    })

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
    },[props.loading,props.error])

    useEffect( ()=>{
       const assignDefaultValue = async ()=>{
           if(props.editMode && Object.keys(props.contactDetails).length > 0){
               let contact = props.contactDetails;
               setName(contact.contact_name)
               setFax(contact.fax);
               setEmail(contact.email)
               if(contact.address && Object.keys(contact.address).length > 0){
                   setValue("line1",contact.address.line1)
                   setLine2(contact.address.line2);
                   setLine1(contact.address.line1);
                   setCity(contact.address.city);
                   setZip(contact.address.zip)
                   if(contact.address?.state){
                       let stateObj = await countryStateList.find(item=>item.label === contact.address.state)
                       setCountryState(stateObj)
                   }if(contact.address?.country && countyList_1.length > 0){
                       let countyObj = await countyList_1.find(item=>item.label === contact.address.country)
                       if(countyObj){
                           setCounty(countyObj)
                       }
                   }
               }
               if(contact?.phones && contact.phones.length > 0){
                   contact.phones.forEach((phone,index)=>{
                       if(index === 0){
                           if(phone.type === 'mobile'){
                               setSelectedPhoneType1({value:'Mobile' , label:'Mobile'})
                           }else if(phone.type === 'office'){
                               setSelectedPhoneType1({value:'Office' , label:'Office'})
                           }else if(phone.type === 'home'){
                               setSelectedPhoneType1({value:'Home' , label:'Home'})
                           }else if(phone.type === 'other'){
                               setSelectedPhoneType1({value:'Other' , label:'Other'})
                           }
                           if(phone.number.length > 0){
                               setPhoneNumber(phone.number)
                               setPhoneType1LabelActive(true)
                           }else {
                               setPhoneNumber('')
                           }
                       }else {
                           if(phone.type === 'mobile'){
                               setSelectedPhoneType2({value:'Mobile' , label:'Mobile'})
                           }else if(phone.type === 'office'){
                               setSelectedPhoneType2({value:'Office' , label:'Office'})
                           }else if(phone.type === 'home'){
                               setSelectedPhoneType2({value:'Home' , label:'Home'})
                           }else if(phone.type === 'other'){
                               setSelectedPhoneType2({value:'Other' , label:'Other'})
                           }
                           if(phone.number.length > 0){
                               setPhoneNumber_2(phone.number)
                               setPhoneType2LabelActive(true)
                           }else {
                               setPhoneNumber_2('')
                           }
                       }
                   })
               }
               setisEditMode(true)
           }
       }
        assignDefaultValue()

    },[props.editMode,props.contactDetails]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkIsFormDataChanged = async ()=>{
           let contact = props.contactDetails;
           setIsDataModified(false)
           setIsBtnDisabled(true)
           props.isDataChanged(false);
           if(isEditMode && Object.keys(props.contactDetails).length > 0){
               /*  setIsBtnDisabled(true)*/
               if((Object.keys(county).length > 0 && county.label !== contact.address.country) || line1 !== contact.address.line1 || line2 !== contact.address.line2 || city !== contact.address.city || zip !== contact.address.zip || (Object.keys(countryState).length > 0 && countryState.label !== contact.address.state) || name !== contact.contact_name || email !== contact.email || (fax !=="1" && fax !== contact.fax) || (contact.fax !== "" && fax !== contact.fax) || (contact.phones !== null && Object.keys(selectedPhoneType1).length > 0 && contact.phones[0].type !== selectedPhoneType1?.value?.toLowerCase()) || (contact.phones !== null &&  ((Object.keys(selectedPhoneType2).length > 0 && contact.phones[1].type !== selectedPhoneType2?.value?.toLowerCase()) || (contact.phones[1].number !== phoneNumber_2 && phoneNumber_2 !== "" && phoneNumber_2 !=="1"))) || (contact.phones === null && (Object.keys(selectedPhoneType1).length > 0 || Object.keys(selectedPhoneType2).length > 0 || (phoneNumber !== "" && phoneNumber !== '1') || (phoneNumber_2 !== "" && phoneNumber_2 !=="1"))) || (contact.phones !== null && contact.phones && contact.phones.length > 0 &&(((contact.phones[0].type !== '' && contact.phones[0].number !== '' && contact.phones[0].type !== selectedPhoneType1?.value?.toLowerCase()) || (contact.phones[0].number !== phoneNumber)) || ( contact.phones[1].type !== '' && contact.phones[1].number !== '' && ((Object.keys(selectedPhoneType2).length > 0 && (contact.phones[1].type !== selectedPhoneType2?.value?.toLowerCase())) || contact.phones[1].number !== phoneNumber_2))))){
                   if(name !== "" && line1 !== undefined && line1.length > 2){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true);
               }
           }else {
               if(!props.editMode){
                   if(Object.keys(county).length > 0 || line1 !== '' || line2 !== '' || city !== '' || zip !== '' || Object.keys(countryState).length > 0  || name !== '' || email !== '' || fax !== '' || (phoneNumber !== "" && phoneNumber !=="1") || (phoneNumber_2 !== '' && phoneNumber_2 !== '1') || Object.keys(selectedPhoneType2).length > 0 || Object.keys(selectedPhoneType1).length > 0 ){
                       if(name !== "" && line1 !== undefined && line1.length > 2){
                           setIsBtnDisabled(false)
                       }
                       setIsDataModified(true)
                       props.isDataChanged(true);
                   }
               }
           }
       }

        checkIsFormDataChanged()

    }, [isEditMode, phoneNumber, phoneNumber_2, selectedPhoneType2, selectedPhoneType1, name, email, fax, line1, line2, city, zip, county, countryState]) // eslint-disable-line react-hooks/exhaustive-deps

    /*useEffect(async ()=>{
        if(props.editMode && Object.keys(props.contactDetails).length > 0){
            let contact = props.contactDetails;
            if(contact.address?.country && Object.keys(countryState).length > 0 && countyOptions.length > 0){
                let countyObj = await countyOptions.find(item=>item.label === contact.address.country)
                if(countyObj){
                    setCounty(countyObj)
                }
            }
        }
    },[props.editMode,countryState])*/

	const onSubmit = async (formData)=>{
        let IsPhone1DropdownSelected = false;
        let IsPhone2DropdownSelected = false;
        let phoneError = false
        let isNameValidate = await validateName();
        let isNumberPresent = false
        let isFaxValid = false
        if(line1 === '' || getValues('line1') === ''){
            setError("line1", {
                type: "manual",
                message: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED,
            });
            return
        }
        let isEmailValidate = await validateEmail();
        if(fax !=="" && fax !=="1"){
            isFaxValid = await validateFax(fax)
        }
        let phoneList = [{type:selectedPhoneType1.label && selectedPhoneType1.label !== 'Select' ?selectedPhoneType1?.label?.toLowerCase():'',number:(phoneNumber === '1' || phoneNumber === '') ? '' : phoneNumber},{type:selectedPhoneType2.label && selectedPhoneType2.label !== 'Select' ?selectedPhoneType2?.label?.toLowerCase():'',number:(phoneNumber_2 === '1' || phoneNumber_2 === '') ? '' : phoneNumber_2}]
        /*if(Object.keys(selectedPhoneType1).length > 0 && phoneNumber){
           await setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
            onBlurEventHandlerPhone()
        }else if(Object.keys(selectedPhoneType1).length === 0 && phoneNumber){
            onBlurEventHandlerPhone()
            if(!phoneNumberError){
           await  setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                return;
            }
        }
        if(Object.keys(selectedPhoneType2).length > 0 && phoneNumber_2){
           await setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            onBlurEventHandlerPhone()
        }else if(Object.keys(selectedPhoneType2).length === 0 && phoneNumber_2){
            onBlurEventHandlerPhone()
            if(!phoneNumber2Error){
           await setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                return;
            }
        }*/
        await onBlurEventHandlerPhone();
        if(phoneNumber.length > 1 && phoneNumber !== "1" &&  Object.keys(selectedPhoneType1).length === 0 ){
            phoneError = true
            setphoneNumberError(true)
            setIsPhone2DropdownSelect(true)
            IsPhone1DropdownSelected = true
            setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label !== 'Select' && (phoneNumber === "" || phoneNumber === "1")){
                phoneError = true
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone1DropdownSelected = false
        }

        await onBlurEventHandlerPhone2();
        if(phoneNumber_2.length > 1 && phoneNumber_2 !== "1" &&  Object.keys(selectedPhoneType2).length === 0 ){
            phoneError = true
            setIsPhone2DropdownSelect(true)
            IsPhone2DropdownSelected = true
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label !== 'Select' && (phoneNumber_2 === "" || phoneNumber_2 === "1")){
                phoneError = true
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone2DropdownSelected = false
        }
        if(phoneNumberError || phoneNumber2Error || IsPhone2DropdownSelected ||IsPhone1DropdownSelected ||  phoneError){
            return
        }
        if(!phoneNumberError && !phoneNumber2Error){
            phoneList.forEach((item,key)=>{
                if(item.number){
                    isNumberPresent = true
                }
            })
        }
        let obj = {
            "contact_name" : name,
            "email" : email,
            "phones" : isNumberPresent ? JSON.stringify(phoneList) : null,
            "fax" : (fax === "1" || fax === "" ) ? "" : fax,
            address : {
                "line1" : line1,
                "line2" : line2,
                "city" : city,
                "state" : Object.keys(countryState).length > 0 ? countryState.label : '',
                "zip" : zip,
                "country_code" : "USA",
                "country" : Object.keys(county).length > 0 ? county.label : ''
            }
        }

        if(!isNameValidate && !isEmailValidate && !phoneNumberError && !phoneNumber2Error && !isFaxValid) {
            if (props.editMode) {
                props.onUpdate(obj);
            } else {
                props.onSubmit(obj);
            }
        }
    }

	// useEffect(()=>{
    //     setIsBtnDisabled(true)
    //     if(getValues('line1') !== '' && name !== ''){
    //         setIsBtnDisabled(false)
    //     }
    // },[getValues('line1'),name])

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
                history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
        }
    }

	const goBack = async()=>{
		history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
    }

	const handleNameChange = (e) => {
        setName(e.target.value.trimStart())
        setValue('name', e.target.value.trimStart())
    }
    const validateName = (e) => {
        if(name === '' && name.length === 0) {
            setNameError(true)
            setNameErrorMeesage(A12Y_CONST.ERROR_MESSAGE.NAME_REQUIRED);
            return true;
        }else{
            setNameError(false)
            setNameErrorMeesage('');
            return false;
        }
    }

	const handleFaxChange = (value) => {
        setFax(value.trimStart())
        setValue('fax', value.trimStart())
    }
    const validateFax = (e) => {
        if(fax !== '' && fax !=="1" && fax.length >= 0) {
            if(validatePhoneNumber(fax)){
                setFaxError(false)
                setFaxErrorMeesage('');
                return faxError
            }else{
                setFaxError(true)
                setFaxErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_FAX);
                return true
            }
        }
    }

	const handleEmailChange = (e) => {
        setEmail(e.target.value.trimStart())
        setValue('email', e.target.value.trimStart())
    }
    const validateEmail = (e) =>{
        if(email.length > 0) {
            if(!/\S+@\S+\.\S+/.test(email)){
                setEmailError(true)
                setEmailErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_EMAIL);
                return true
            }
            else{
                setEmailError(false)
                setEmailErrorMeesage('');
                return false;
            }
        }else{
            setEmailError(false)
            setEmailErrorMeesage('');
            return false;
        }
    }

	const handelZip = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setZip(value)
        }
    }

    const handelCity = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setCity(value)
        }
    }

    const handleChangeCountryState = e => {
        setCountryState(e);
        setCounty({});
    }

    const handelLine2 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine2(value)
        }
    }
    const handelLine1 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine1(value)
            setValue("line1", value)
        }
    }

	const handleChangeCounty = e => {
        setCounty(e);
    }

	const phoneTypeList = [
        {
            value: 'select',
            label: 'Select',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#0096C7"></path>
            </svg>
        },
        {
            value: 'mobile',
            label: 'Mobile',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#2D6CB2"></path>
            </svg>
        },
        {
            value: 'office',
            label: 'Office',
            icon:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'home',
            label: 'Home',
            icon: <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'other',
            label: 'Other',
            icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#71767A" style={{strokeWidth:2}} ></path>
            </svg>,
            iconActive: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#0297C8" style={{strokeWidth:2}}></path>
            </svg>
        }
    ];

    const phoneTypeOptions = phoneTypeList && phoneTypeList.map(phoneTypeList =>{
        return {label:phoneTypeList.label, value: phoneTypeList.label}
    })

	const validatePhoneNumber = (e) => {
        const validNumber = '+'+e.toString()
        //    console.log('validNumber',validNumber, typeof validNumber)
        const phoneNumber = isValidPhoneNumber(validNumber)
        //    console.log('phoneNumber')
        return phoneNumber
    }

    const handlePhoneField_2 = e => {
        if(e.length > 1){
            setPhoneType2LabelActive(true)
        }else{
            setPhoneType2LabelActive(false);
        }
        setPhoneNumber_2(e)
    }
    const onBlurEventHandlerPhone2 = () => {
        const isValid =  validatePhoneNumber(phoneNumber_2)
        if(phoneNumber_2.length > 1 && !isValid){
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }
    }
	const handleChangePhoneType1 = async e => {
        if(e.label === 'Select'){
            setPhoneNumber("1")
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
        }else {
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone();
        }
        await  setSelectedPhoneType1(e);
    }
    const handleChangePhoneType2 = e => {
        if(e.label === 'Select'){
            setPhoneNumber_2("1")
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone2();
        }
        setSelectedPhoneType2(e);
    }
	const handlePhoneField = e => {
        if(e.length > 1){
            setPhoneType1LabelActive(true)
        }else{
            setPhoneType1LabelActive(false);
        }
        setPhoneNumber(e)
    }
    const onBlurEventHandlerPhone = () => {
        if(phoneNumber.length > 1){
            const isValid =  validatePhoneNumber(phoneNumber)
            if(!isValid){
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
            }else {
                setphoneNumberError(false)
                setPhoneNumber_1ErrorMessage('')
            }
        }
    }

    const selectAdd = (add) =>{
        if(add){
            setShowAddressDropDown(false)
            const request = {
                placeId: add.place_id,
                fields: ["name", "formatted_address", "place_id", "geometry", "address_components"],
            };
            const service = new window.google.maps.places.PlacesService(document.createElement("div"));

            service.getDetails(request, (place, status) => {
                setLine1('');
                setValue("line1", '')
                place.address_components.forEach((item)=>{
                    if(item.types.length > 0){
                        item.types.forEach((type)=>{
                            if(type === "street_number"){
                                setValue("line1",item.short_name)
                                setLine1(item.short_name)
                            }
                            if(type === "route"){
                                let add = getValues("line1")
                                if(add.trim() === ""){
                                    setValue("line1",item.short_name)
                                    setLine1(item.short_name)
                                }else {
                                    setValue("line1",add+" "+item.short_name)
                                    setLine1(add+" "+item.short_name)
                                }
                            }
                            if(type === "postal_code"){
                                setValue("zipcode",item.short_name)
                                setZip(item.short_name)
                            }
                            if(type === "locality"){
                                setValue("city",item.short_name)
                                setCity(item.short_name)
                            }
                            /*if(type === "neighborhood"){
                                setValue("address2",item.short_name)
                            }
                            if(type === "sublocality_level_1"){
                                let add = getValues("address2")
                                if(add.trim() === ""){
                                    setValue("address2",item.short_name)
                                }else {
                                    setValue("address2",add+" "+item.short_name)
                                }

                            }*/
                            if(type === "administrative_area_level_1"){
                                let stateObj = countryStateList.filter(data =>data.label === item.short_name)
                                setCountryState(stateObj[0])
                                //setValue("address2",item.short_name)
                            }
                            if (type === "administrative_area_level_2") {
                                if(countyList_1.length > 0){
                                    let countyObj = countyList_1.filter(data => data.label === item.short_name)
                                    if (countyObj && countyObj.length > 0) {
                                        setCounty(countyObj[0])
                                    }
                                }
                            }
                        })
                    }
                })
            });

            let address1 = getValues("line1")
            if(address1.trim() === ""){
                setValue("line1",address1.formatted_address)
                setLine1(address1.formatted_address)
            }

        }
        /*let addObj = {
            "address_line1" : getValues('address1'),
            "address_line2" : getValues('address2'),
            "city" : getValues('city'),
            "state" : countryState ? countryState.label : '',
            "zip" : getValues('zipcode'),
            "country_code" : "USA"

        }
        setSelectedAdd(addObj)*/
        setDropDownIndex(null)
    }
    const onFocusEventHandler = () => {
        if(showAddressDropDown){
            setShowAddressDropDown(false)
        }
    }

    const outSideClick = async ()=>{
        onFocusEventHandler()
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
    }

    const onMouseMoveEvent = async ()=>{
        if(dropDownIndex !== null){
            setDropDownIndex(null)
        }
    }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter' && dropDownIndex !== null){
            e.preventDefault();
        }
    }


    return (
    <div className="titleRow margignTop768_13 margignBottom768_10 addActivitiesWrap" onClick={outSideClick}>
        {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
		<h2 className="titleWithSidebar noExpenseTitle marginBottom30">
			<span className="cursorPointer" onClick={goBack}><svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
			</svg>
			<span>{A12Y_CONST.LABEL.ADD_ASSET_LABEL}</span></span>
		</h2>
		<div className="dependentBorderBox dependentDefauldMinHeight paddingBottom70 activityViewMain positionRelative">
			<div className="df fcol marginBottom30 height100">
				<h4 className="dependentSubTitle">{A12Y_CONST.LABEL.CONTACT_INFORMATION}</h4>
                <div className="fullWidth">
                    {error ?
                        <div className='maxWidth600 marginLeftRightAuto'>
                            <ErrorMessageComponent errorMessage={props.errorMessage}/>
                        </div>
                        :
                        (props.message ?
                                <SuccessMessageComponent successMessage={props.message}/>
                                :
                                null
                        )
                    }
                </div>
				<form autoComplete="off" onKeyDown={(e) => checkKeyDown(e)} className='marginTop60 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
					<div className="formRow fullWidth marginBottom30">
						<label>{A12Y_CONST.LABEL.NAME}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
						<div className="fullWidth">
							<input 
								{...register("name")}
								placeholder="Contact Name" 
								type='text'
								id="nsme"
                                tabIndex={1}
								onChange={handleNameChange}
								value={name}
								onBlur={validateName}
								onFocus={()=>setNameErrorMeesage(false)}
                                maxLength="100"
								className={`fullWidth inputColum24size ${nameError && 'errorInputBorderRed'}`}
							/>
							<div className='errorRelativeRow'>{nameError? <p className='errorMessage'>{nameErrorMeesage}</p>: null}</div>
						</div>
					</div>
					<div className="formRow fullWidth marginBottom30">
                        <label>{A12Y_CONST.LABEL.CAPITAL_LOCATION}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth address1'>
                                    <input
                                        {...register('line1', { required: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED })}
                                        placeholder='Street Address'
                                        className={errors.line1 && line1?.length === 0 ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                        type='text'
                                        maxLength='255'
                                        tabIndex={2}
                                        onChange={handelLine1}
                                        onKeyUp={(evt) => {
                                            if(evt.target.value === " "){
                                                setValue('line1',evt.target.value.trim())
                                                setLine1(evt.target.value.trim())
                                            }
                                            if(evt.target.value.length > 2 ){
                                                if(evt.key === "ArrowDown" || evt.key === "ArrowUp" || evt.key === 'Enter'){
                                                    if(dropDownIndex === null){
                                                        setDropDownIndex(0)
                                                    }else {
                                                        setDropDownIndex(evt.key === "ArrowUp" && dropDownIndex !== 0 ? dropDownIndex -1 : evt.key === "ArrowDown" && placeServiceList.length-1 > dropDownIndex ? dropDownIndex +1 : dropDownIndex )
                                                        if(evt.key === 'Enter' && dropDownIndex !== null && placeServiceList.length > 0){
                                                            selectAdd(placeServiceList[dropDownIndex])
                                                        }
                                                    }

                                                }else {
                                                    setLine1(evt.target.value)
                                                    setShowAddressDropDown(true)
                                                    //getPlacePredictions({ input: evt.target.value });
                                                    getPlaceFromService({ input: evt.target.value });
                                                }
                                            }
                                        }}
                                        // loading={isPlacePredictionsLoading}
                                        id='line1'
                                        autoComplete="new-no-line1"
                                        onBlur={()=>{
                                            if(line1.length === 0){
                                                setError("line1", {
                                                    type: "manual",
                                                    message: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED,
                                                });
                                            }
                                        }}
                                        onFocus={()=>{
                                            setError("line1", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />

                                    {/*<div onClick={()=>{selectAdd(null)}} >Hello</div>)*/}
                                    {showAddressDropDown && placeServiceList.length > 0?
                                        <div className='addressLin1DropDown'>
                                            <div className="addressLin1DropDownInner">
                                                {placeServiceList && placeServiceList.map((item,key) =>
                                                    (item && item.description ?
                                                            <div onMouseMove={onMouseMoveEvent} className={dropDownIndex === key ? 'addressListDropDownHighlight' : dropDownIndex === null ? 'addressListDropDown addressHover' : 'addressListDropDown' } onClick={()=>{selectAdd(item)}} key={key}>{item.description}</div>
                                                            :
                                                            null
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {/*{placePredictions.map((item) => <div>{item.description}</div>)}*/}
                                    <div className='errorRelativeRow'> {errors.line1 && <p className='errorMessage'>{errors.line1.message}</p>}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom30">
                        <label>{A12Y_CONST.LABEL.UNIT}</label>
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth address2'>
                                    <input
                                        {...register('line2',)}
                                        placeholder='Apartment, Suite, Unit, Building'
                                        className='inputColum24size '
                                        maxLength='255'
                                        type='text'
                                        tabIndex={3}
                                        id='line2'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-no-line2"
                                        onChange={handelLine2}
                                        value={line2}
                                    />
                                </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom20">
                        <label>{A12Y_CONST.LABEL.CITY}</label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth marginBottom10 cityCountryState'>
                                <div className='width40 paddingRight35 city'>
                                    <input
                                        {...register('city',)}
                                        placeholder='City'
                                        maxLength='100'
                                        tabIndex={4}
                                        className='inputColum24size'
                                        type='text'
                                        id='city'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-no-city"
                                        onChange={handelCity}
                                        value={city}
                                    />
                                </div>
                                <div className='width60 dependentResidencCol paddingLeft35 marginTop10 countryState'>
                                    <div className={(countryState && Object.keys(countryState).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol floatLeft positionRelative paddingLeft35 addressRow countryState'}>
                                        <label className='labelCol institutionStateLabel'>{A12Y_CONST.LABEL.STATE}</label>
                                    <Select
                                        // selectdisplayprops={countryState}
                                        // defaultValue={countryStateList[0]}
                                        // className='phoneTypeLabel'
                                        classNamePrefix={'dependentResidence'}
                                        placeholder="Select"
                                        maxLength='100'
                                        tabIndex={5}
                                        value={countryState.label ? countryState : ""}
                                        options={stateOptions}
                                        onChange={handleChangeCountryState}
                                        // getOptionLabel={e => (
                                        //     <div className='phoneListRow'>
                                        //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                        //     </div>
                                        // )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom30">
                        <label>{A12Y_CONST.LABEL.ZIP}</label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth'>
                                <div className='width40 paddingRight35 zip'>
                                    <input
                                        {...register('zip',)}
                                        placeholder='00000'
                                        className='inputColum24size'
                                        maxLength='100'
                                        type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                        onWheel={e => e.target.blur()}
                                        id='zip'
                                        tabIndex={6}
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-pincode"
                                        onChange={handelZip}
                                        value={zip}
                                    />
                                </div>
                                <div className='width60 dependentResidencCol paddingLeft35 marginTop10 countryState'>
                                    <div className={(county && Object.keys(county).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol floatLeft positionRelative paddingLeft35 addressRow countryState'}>
                                        <label className='labelCol institutionStateLabel'>{A12Y_CONST.LABEL.COUNTY}</label>
                                    <Select
                                        // selectdisplayprops={countryState}
                                        // defaultValue={countryStateList[0]}
                                        classNamePrefix={'dependentResidence'}
                                        placeholder="Select"
                                        value={county.label ? county : ""}
                                        options={countyOptions}
                                        tabIndex={7}
                                        onChange={handleChangeCounty}
                                        // getOptionLabel={e => (
                                        //     <div className='phoneListRow'>
                                        //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                        //     </div>
                                        // )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>




					<div className='dependentStepFormRow fullWidth positionRelative'>
                            <label className='labelCol phoneEditProfile'>{A12Y_CONST.LABEL.DEPENDENT_PHONE}</label>
                            <div className='fullWidth marginBottom30'>
                                <div className='width50 paddingRight35 selectBoxParend myProfilePhoneCol1_600'>
                                    <div className={(selectedPhoneType1 && Object.keys(selectedPhoneType1).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsouteTop4 positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                        <Select
                                            className='phoneTypeLabel'
                                            classNamePrefix={phoneType1LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                            placeholder="Select"
                                            value={selectedPhoneType1.label ? selectedPhoneType1 : ""}
                                            options={phoneTypeOptions}
                                            onChange={handleChangePhoneType1}
                                            tabIndex={8}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: 'red',
                                                    primary: '#0096C708',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <PhoneInput
                                            country='us'
                                            countryCodeEditable={false}
                                            disabled={Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label === 'Select' ? true : false}
                                            // containerClass={phoneType1LabelActive ? 'phoneNumber_Active': null}
                                            containerClass={phoneType1LabelActive ? phoneNumberError? 'errorInputBorderRedInput phoneNumber_Active':phoneNumber === "1" ? 'phone_number_placeholder_color null' :'phoneNumber_Active' : phoneNumberError? 'errorInputBorderRedInput': null}
                                            value={phoneNumber}
                                            tabIndex={9}
                                            onBlur={onBlurEventHandlerPhone}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                            }}
                                            onChange={phone => {handlePhoneField(phone)}}
                                            onFocus={() => setphoneNumberError(false)}
                                        />
                                    </div>
                                    <div className='errorRelativeRow'> {phoneNumberError ? <p className='errorMessage'>{phoneNumber_1ErrorMessage}</p> : null }</div>
                                </div>
                                <div className='width48WithPhone marginTop768_10 floatRight paddingleft16 selectBoxParend myProfilePhoneCol2_600'>
                                    <div className={(selectedPhoneType2 && Object.keys(selectedPhoneType2).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsouteTop4 positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                        <Select
                                            className='phoneTypeLabel'
                                            classNamePrefix={phoneType2LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                            placeholder="Select"
                                            value={selectedPhoneType2.label ? selectedPhoneType2 : ""}
                                            options={phoneTypeOptions}
                                            onChange={handleChangePhoneType2}
                                            tabIndex={10}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: 'red',
                                                    primary: '#0096C708',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <PhoneInput
                                            country='us'
                                            countryCodeEditable={false}
                                            disabled={Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label === 'Select' ? true : false}
                                            // containerClass={phoneType2LabelActive ? 'phoneNumber_Active': null}
                                            containerClass={phoneType2LabelActive ? phoneNumber2Error? 'errorInputBorderRedInput phoneNumber_Active': phoneNumber_2 === "1" ? 'phone_number_placeholder_color null' :'phoneNumber_Active' : phoneNumber2Error? 'errorInputBorderRedInput': null}
                                            placeholder='Select'
                                            value={phoneNumber_2}
                                            onBlur={onBlurEventHandlerPhone2}
                                            tabIndex={11}
                                            inputProps={{
                                                name: 'phone1',
                                                required: false,
                                                autoFocus: false
                                            }}
                                            onChange={phone => {handlePhoneField_2(phone)}}
                                            onFocus={() => {
                                                setphoneNumber2Error(false)
                                                setIsPhone2DropdownSelect(false)
                                            }}
                                        />
                                    </div>
                                    <div className='errorRelativeRow'> {phoneNumber2Error || isPhone2DropdownSelect? <p className='errorMessage'>{phoneNumber_2ErrorMessage}</p> : null }</div>
                                </div>

                            </div>
                    </div>
					<div className="formRow fullWidth">
						<label className="lblExpType7">{A12Y_CONST.LABEL.FAX}</label>
						<div className='width50 marginBottom30 paddingRight35 faxInput'>
							<PhoneInput
                                country='us'
                                countryCodeEditable={false}
                                containerClass={`${faxError? 'errorInputBorderRedInput phoneNumber_Active': 'form-phoneNumber_Active'} ${fax.toString().length > 1 ? ' hasValue phoneNumber_Active' : null}`}
                                {...register("fax")}
                                placeholder="+(000) 000-000"
                                value={fax}
                                tabIndex={12}
                                onBlur={validateFax}
                                inputProps={{
                                    name: 'fax',
                                    required: false,
                                    autoFocus: false
                                }}
                                onChange={fx => handleFaxChange(fx)}
                                onFocus={() => setFaxError(false)}
                            />

							<div className='errorRelativeRow'>{faxError? <p className='errorMessage'>{faxErrorMeesage}</p>: null}</div>
						</div>
					</div>
					<div className="formRow fullWidth marginBottom30">
						<label className="lblExpType7">{A12Y_CONST.LABEL.Email}</label>
						<div className="fullWidth">
							<input 
								{...register("email")}
								placeholder="jane.doe@example.com" 
								type='text'
								id="nsme"
                                tabIndex={13}
								onChange={handleEmailChange}
								value={email}
								onBlur={validateEmail}
								onFocus={()=>setEmailErrorMeesage(false)}
                                autoComplete="none"
								className={`fullWidth inputColum24size ${emailError && 'errorInputBorderRed'}`}
							/>
							<div className='errorRelativeRow'>{emailError? <p className='errorMessage'>{emailErrorMeesage}</p>: null}</div>
						</div>
					</div>
					

					{/*------------------ Button Actions ---------------------*/}
					<div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom" >
						<div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.ADD_CONTACT_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
						<div className='widthAuto marginLeft20'>
							<div className='loaderSpinner'>
								{loading ?
									<Loading />
									:
									null
								}
								<input id={A12Y_CONST.BUTTON_ID.ADD_CONTACT_SAVE} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={A12Y_CONST.BUTTON_TEXT.SAVE} />
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
        <EditChangesRemovePopup
            popUpStatus={isPopUpVisible}
            hidePopUp={handleHidePopup}
            resetAllFields={handleResetAllFields}
        />
	</div>
    );
}

export default AddContactComponent;
