import React,{useEffect, useState} from 'react'
import A12Y_CONST from '../common/a12yConst'
import Loading from './LoadingComponent'
import { useForm } from 'react-hook-form';
import exclamationImg from '../assets/images/exclamationErrorIcon.svg'
import { withRouter } from "react-router";
import { Check } from '@material-ui/icons';
import EditChangesRemovePopup from '../components/EditChangesRemovePopup'
//use type as 'email' for email verification or use 'password' for forget password

function  CodeVerification(props) {
    const [loading, setLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState(false);
    const [error, setErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const {register, setFocus,setError ,handleSubmit,formState:{ errors},setValue} = useForm({mode:"all"});
    const [password, setPassword] = useState('');
    const [passwordConditionBox, setPasswordConditionBox] = useState(true);
   // const [passwordErrorHide, setaPsswordErrorHide] = useState(false);
   // const [passwordSuccessMessage, setPasswordSuccessMessage] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [passwordCondition, setPasswordCondition] = useState([])

    const handleChangePassword = (event) => {
        if(event.target.value === " "){
            return
        }else{
            let count = 0;
            if(event.target.value.length > 0){
               // setPasswordConditionBox(true)
            }
            else{
               // setPasswordConditionBox(false)
            }
            let lowercaseCharacter = /[a-z]/g;
            if(event.target.value.match(lowercaseCharacter)) {
                validatePassword('lowercaseCharacter', true)
                count++
            } else {
                validatePassword('lowercaseCharacter', false)
            }
            let uppercaseCharacter = /[A-Z]/g;
            if(event.target.value.match(uppercaseCharacter)) {
                validatePassword('uppercaseCharacter', true)
                count++
            } else {
                validatePassword('uppercaseCharacter', false)
            }
            let numberOnly = /[0-9]/g;
            if(event.target.value.match(numberOnly)) {
                validatePassword('numberOnly', true)
                count++
            } else {
                validatePassword('numberOnly', false)
            }
            //eslint-disable-next-line
            let specialCharacter = /[ `!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/? ~]/;
            if(event.target.value.match(specialCharacter)) {
                validatePassword('specialCharacter', true)
                count++
            } else {
                validatePassword('specialCharacter', false)
            }
            if(event.target.value.length >= 8) {
                validatePassword('minimumCharacters', true)
                count++
            } else {
                validatePassword('minimumCharacters', false)
            }
            if(count === passwordCondition.length) {
               // setPasswordSuccessMessage(true);
                //setaPsswordErrorHide(false)
                setIsBtnDisabled(false)
            }else{
               // setPasswordSuccessMessage(false);
                //setaPsswordErrorHide(true)
               // setIsBtnDisabled(true)
            }
            setPassword(event.target.value);
            setValue('password', event.target.value);
        }
    };

    const validatePassword = (type, status) => {
        passwordCondition.forEach((item, index) => {
            if(item.type === type){
                item.status = status
            }
        })

    }
    const handeBlankSpace = (e) => {
        if(e.key === " "){
            e.preventDefault();
        }
    }

    useEffect(() => {
        setLoading(props.loading)
        setErrors(props.error)
        setErrorMessage(props.errorMessage)
        if(props.error){
            setIsBtnDisabled(false)
        }
    }, [props.loading,props.error,props.errorMessage]);

    useEffect(() => {
        setFocus("emailCodeVerification");
    }, [setFocus])


    useEffect(() => {
        setPasswordCondition([{
            type:'lowercaseCharacter',
            name: 'One lowercase character',
            status: false
        },
            {
                type:'specialCharacter',
                name: 'One special character',
                status: false
            },
            {
                type:'uppercaseCharacter',
                name: 'One uppercase character',
                status: false
            },
            {
                type:'minimumCharacters',
                name: '8 characters minimum',
                status: false
            },
            {
                type:'numberOnly',
                name: 'Should be one number',
                status: false
            }])
        setPasswordConditionBox(true)
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userName = params.get('cog_uname');
        const code = params.get('vcode');
        if(userName && code){
            setValue('emailCodeVerification',code)
            setVerificationCode(code)
            setLoading(true)
            onSubmit({emailCodeVerification:code})
        }
        if(props.type === 'password' ){
            setIsBtnDisabled(false)
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
                window.history.pushState(null, document.title,  window.location.href);
            });
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    
    const onSubmit = (code) => {
        setIsBtnDisabled(true);
        props.onSubmit(code);
    }
    const onResend = (code) => {
        setVerificationCode('');
        props.onResend();
    }
    const handleVerificationCode = (event) => {
        if(event.target.value.length <= 6){
            setIsBtnDisabled(false)
            setVerificationCode(event.target.value);
            setValue("emailCodeVerification",event.target.value)
        }
    }
    
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }


    const handleResetAllFields = async () => {
        setIsPopUpVisible(false);
        props.history.push(A12Y_CONST.ROUTE.LOGIN);
    }

    const onCancel = async()=>{
        setIsPopUpVisible(true);
    }

    return (
        <div className="fullWidth">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className='fullWidth signColCenter texAlingCener'>
                <div className="emailVerificationIcon">
                    {props.type === 'email' ?
                    // <svg width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //     <path d="M20.6393 27.4264V31.7525C18.7714 31.0901 16.7715 30.8832 14.8087 31.1729C12.8479 31.442 10.978 32.1872 9.35992 33.3257C7.74386 34.4641 6.42292 35.9959 5.51273 37.7553C4.60254 39.5147 4.12786 41.4811 4.12786 43.4683H0C0 40.943 0.575857 38.4383 1.68419 36.1821C2.79458 33.9052 4.40651 31.9181 6.3982 30.3657C8.38989 28.8339 10.7097 27.7576 13.1782 27.2401C15.6467 26.7433 18.1997 26.8054 20.6393 27.4264ZM16.5114 24.839C9.66951 24.839 4.12786 19.2916 4.12786 12.4195C4.12786 5.54738 9.66951 0 16.5114 0C23.3534 0 28.895 5.54738 28.895 12.4195C28.895 19.2916 23.3534 24.839 16.5114 24.839ZM16.5114 20.6992C21.0727 20.6992 24.7671 16.994 24.7671 12.4195C24.7671 7.84498 21.0727 4.13983 16.5114 4.13983C11.9501 4.13983 8.25571 7.84498 8.25571 12.4195C8.25571 16.994 11.9501 20.6992 16.5114 20.6992ZM28.4678 39.1421L35.7638 31.8353L38.6842 34.7539L28.4678 45L21.1697 37.6932L24.0902 34.7539L28.4657 39.1421H28.4678Z" fill="#2D6CB2"></path>
                    // </svg>
                        null
                        :
                    null
                    }
                    {props.type === 'password' ?
                        <svg width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.0955 6.5C38.7237 8.3148 38.7237 10.1852 39.0955 12H6.96232L27.8964 30.3205L41.8959 17.8905C43.0771 19.3398 44.5522 20.547 46.2297 21.4242L27.9269 37.6795L5.54545 18.094V50.5H49.9091V22.725C51.7389 23.0937 53.6248 23.0937 55.4545 22.725V53.25C55.4545 53.9793 55.1624 54.6788 54.6424 55.1945C54.1224 55.7103 53.4172 56 52.6818 56H2.77273C2.03735 56 1.3321 55.7103 0.812113 55.1945C0.292126 54.6788 0 53.9793 0 53.25V9.25C0 8.52065 0.292126 7.82118 0.812113 7.30546C1.3321 6.78973 2.03735 6.5 2.77273 6.5H39.0955Z" fill="#112E53"/>
                            <circle cx="52" cy="9" r="8" fill="#D86941"/>
                        </svg>
                        :
                    null
                    }
                </div>
                <h2 className="title1 texAlingCener">
                    {props.title}
                </h2>


                <div className="emailVerificationMessage">
                    {props.message}
                    {/*{props.type === 'password'? <span className='displayBlock'>{props.email}</span>:null}*/}
                </div>
                <div className='fullWidth marginBottom20'></div>
                {error &&
               <div className="texAlingLeft">
                    <div className="positionRelative">
                        <div className="errorMessageWithBackground positionAbsoute">
                            <div className="exclamationErrorIcon"><img alt={''} src={exclamationImg}/></div>
                            <div className='errorTextContent'>{errorMessage}</div>
                        </div>
                    </div>
               </div>
                }
                <div className="inputColFieldMarginNone fullWidth marginTop35 onlyNumber">
                    <form autoComplete="off" className='marginTop20 signUpFlow fullWidth' onSubmit={handleSubmit(onSubmit)}>
                        <div className={'fullWidth positionRelative marginBottom20'}>
                            <input
                                {...register('emailCodeVerification', { required: A12Y_CONST.ERROR_MESSAGE.EMAIL_VERIFICATION_REQUIRED,
                                    pattern:{
                                        value:/^[0-9]{6,6}$/,
                                       // message:A12Y_CONST.ERROR_MESSAGE.EMAIL_VERIFICATION_INVALID
                                    }
                                })}
                                id='userPassword'
                                placeholder='Enter Code'
                                className={error || errors.emailCodeVerification ? 'codeInput errorInputAllBorderRed': 'codeInput'}
                                type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                onWheel={e => e.target.blur()}
                                value={verificationCode}
                                onFocus={()=>{
                                    setError("emailCodeVerification", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                                onChange={(e) => handleVerificationCode(e)}
                            />
                            <div className='errorRelativeRow marginTop10'>{errors.emailCodeVerification && <p className='errorMessage fullWidth texAlingCener'>{errors.emailCodeVerification.message}</p>}</div>
                        </div>
                        {props.type === "password" ?
                           <div>
                               <label className='fullWidth texAlingLeft marginTop10'>{A12Y_CONST.TEXT.ENTER_NEW_PASSWORD}</label>
                               <div className={'fullWidth positionRelative marginBottom20'}>
                                   <input
                                       {...register('password', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_PASSWORD_REQUIRED,
                                           pattern:{
                                               value:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$/,
                                               message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_PASSWORD_INVALID
                                           }
                                       })}
                                       id='userPassword'
                                       placeholder='Password'
                                       className={errors.password ? 'inputColum errorInputBorderRed PaddingPasswordIcon': 'inputColum PaddingPasswordIcon'}
                                       onChange={e =>handleChangePassword(e)}
                                       type={passwordShown ? "text" : "password"}
                                       value={password}
                                       onKeyDown={e => handeBlankSpace(e)}
                                       onFocus={()=>{
                                           setError("password", {
                                               type: "manual",
                                               message: "",
                                           });
                                       }}
                                   />
                                   <div id={A12Y_CONST.BUTTON_ID.PASSWORD_SHOW_HIDE} className="passwordTextShow" onClick={togglePasswordVisiblity}>
                                       {
                                           passwordShown ?
                                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                                   <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#2D6CB2"/>
                                                   <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#2D6CB2"/>
                                               </svg>
                                               :
                                               <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#2D6CB2"></path>
                                                   <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#2D6CB2"></path>
                                               </svg>
                                       }

                                   </div>
                                   <div className='errorRelativeRow'> {errors.password && <p className='errorMessage'>{errors.password.message}</p>}</div>
                                   {passwordConditionBox ?
                                       <div className="passwordMatchBox fullWidth">
                                           <ul>
                                               {
                                                   passwordCondition.map((item, index) => {
                                                       return(
                                                           <li key={index} className='passowrdListOfCondition'>
                                                               <span className='rightIocn'><Check className={item.status ? 'checkIconActive':'checkIcon'}/></span>
                                                               <span className='passowrdListOfConditionText'>{item.name}</span>
                                                           </li>
                                                       )
                                                   })
                                               }
                                           </ul>
                                       </div>
                                       :
                                       null
                                   }
                               </div>

                           </div>
                           :
                            null
                        }




                        <div className='fullWidth marginTop15'>
                            <div className='marginTop45 loaderSpinner8radius'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                            <input id={props.buttonTypeId} className={isBtnDisabled || loading ? "buttonDisable textTransferUppercase" :'button textTransferUppercase'} disabled={isBtnDisabled || loading}  type="submit"  value={props.type === 'email'? A12Y_CONST.BUTTON_TEXT.CONTINUE_CAPS: !isBtnDisabled ? A12Y_CONST.BUTTON_TEXT.DONE : A12Y_CONST.BUTTON_TEXT.FORGOT_CREATE_PASSWORD_UPDATING_PASSWORD}/>
                        </div>
                        </div>
                    </form>
                </div>
                <div id={props.type === "password"?A12Y_CONST.BUTTON_ID.RESET_VERIFICATION_LINK_PASSWORD_VERIFICATION:A12Y_CONST.BUTTON_ID.RESET_VERIFICATION_LINK_EMAIL_VERIFICATION} className={`dontReceiveCodeText fullWidth ${props.type === 'email'? 'paddingTop10' : null}`}>
                    {A12Y_CONST.LABEL.EMAIL_VERIFICATION_DONT_RECEIVE} <span onClick={onResend}>{props.type === 'email' ? A12Y_CONST.LABEL.EMAIL_VERIFICATION_RESEND_CODE:A12Y_CONST.LABEL.FORGOT_PASSWORD_VERIFICATION_CODE_RESEND_CODE}</span>
                </div>
                {props.type !== 'email' ? <div className='cancelButton fullWidth marginTop10'><span id={A12Y_CONST.BUTTON_ID.CANCEL_BUTTON_PASSWORD_VERIFICATION} onClick={onCancel}>Cancel</span></div> : null}
            </div>
            <EditChangesRemovePopup
                title={A12Y_CONST.MESSAGE.FORGOT_PASSWORD_POPUP_TITLE}
                message={A12Y_CONST.MESSAGE.FORGOT_PASSWORD_POPUP_MESSAGE}
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )

}


export  default  withRouter (CodeVerification);