import React,{Component} from 'react'
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions'
import SignUpFormComponent from '../components/SignUpFormComponent'
import SignUpFormAbonelawComponent from '../components/SignUpFormAbonelawComponent'
import A12Y_CONST from '../common/a12yConst'
import {client} from "../config/axiosClient";
import PopUpSignUpComponent from '../components/PopUpSignUpComponent'
import SignUpFormSampleComponent from '../components/SignUpFormSampleComponent';

class SignUpSampleScreen extends Component {
    constructor(props){
        super(props)
        this.state = {
            error:false,
            loading:false,
            userInvitationDetail : {},
            invitation_code:'',
            loaderFullScreen:false,
            isPopUp:false,
            popUpType:'',
            PopUpMessage:'',
            urlStore:'',
            loadingFullScreen:false,
            userEmail:''

        }
    }

    componentDidMount = async () => {
        let state = { ...this.props.location.state };
        if(state?.email){
            await this.setState({userEmail:state.email})
        }
        this.getInvitation_code();
        await this.urlGet();
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let googleCode = params.get('code');
        if(googleCode){
            this.setState({loadingFullScreen:true})
            let data = {'auth_code':googleCode}
            this.handleSubmit(data)
        }
    }

    urlGet = async () => {
        const response = await client.get(A12Y_CONST.API_ENDPOINT.LOGIN_URL);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let docs = response.data.sr.google_login_url;
            await this.setState({urlStore:docs})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loading:false})
        }
    }

    getInvitation_code = async () => {
        const search = window.location.search;
        if(search.indexOf("invitation_code") > -1){
            await this.setState({loaderFullScreen:true})
            let invitation_code =  search.split("=");
            await this.setState({invitation_code:invitation_code[1]})
            await this.props.setInviteCode(invitation_code[1])
            let response = await client.post(A12Y_CONST.API_ENDPOINT.INVITATION_DETAILS, {
                "invitation_code" : invitation_code[1]
            });
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let userData = response.data.sr.invitation;
                let obj = {
                    userFirst_Name:userData.invitee_first_name,
                    userLast_Name:userData.invitee_last_name,
                    userEmail:userData.invitee_email,
                    userByName:userData.invited_by_name,
                    invited_for:userData.invited_for
                }
               await this.setState({userInvitationDetail:obj})

            }else {
                // setError(true);
                // setErrorMessage(response.data.sr?.a12yErr?.message)
                if(response.data.sr?.a12yErr?.a12y_code === A12Y_CONST.ERROR_API_CODE.REMOVED_ACCESS){
                    this.setState({isPopUp:true, popUpType:A12Y_CONST.TEXT.REMOVED_ACCESS, PopUpMessage:response.data.sr?.a12yErr?.more_info})
                }
                if(response.data.sr?.a12yErr?.a12y_code === A12Y_CONST.ERROR_API_CODE.TRY_AGAIN){
                    this.setState({isPopUp:true, popUpType:A12Y_CONST.TEXT.TRY_AGAIN, PopUpMessage:response.data.sr?.a12yErr?.more_info})
                }
                if(response.data.sr?.a12yErr?.a12y_code === A12Y_CONST.ERROR_API_CODE.ALREADYSIGNEDUP){
                    this.props.history.push({
                        pathname : A12Y_CONST.ROUTE.LOGIN,
                        state : {
                            'message_already_SignedUp' : response.data.sr?.a12yErr?.message,
                        }
                    })
                }

            }
            await this.setState({loaderFullScreen:false})
        }
    }

    handelSubmit = async(data) =>{
        this.setState({error:false})
        this.setState({loading:true})
        if(this.state.invitation_code){
            data.invitation_uuid = this.state.invitation_code
        }
        await this.props.signUp(data)
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true})
        }else {
            this.setState({error:false})
            if(this.props.userState.usercognitoResponse.cognitoResponse.UserConfirmed){
                this.props.history.push(A12Y_CONST.ROUTE.EMAIL_LOGIN);
            }else{
                this.props.history.push(A12Y_CONST.ROUTE.SIGN_UP_EMAIL_VARIFICATION);
            }
        }
        /*if(this.props.userState.userInfo.username){
            this.setState({error:false})
            if(this.props.userState.usercognitoResponse.cognitoResponse.UserConfirmed){
            this.props.history.push(A12Y_CONST.ROUTE.EMAIL_LOGIN);
            }else{
            this.props.history.push(A12Y_CONST.ROUTE.SIGN_UP_EMAIL_VARIFICATION);
            }
        }else {
            this.setState({error:true})
        }*/

    }
    hidePopUp_Redirect = () => {
        this.setState({isPopUp:false})
    }
    render(){
        return(
            <div>
                <SignUpFormSampleComponent
                    loading={this.state.loading}
                    onSubmit={this.handelSubmit}
                    error={this.state.error}
                    errorMessage={this.props.errMsg}
                    inviteUserInfo={this.state.userInvitationDetail}
                    loaderFullScreen={this.state.loaderFullScreen}
                    url={this.state.urlStore}
                    userEmail={this.state.userEmail}
                    signUpText={this.props.signUpText}
                />

                <PopUpSignUpComponent
                    popUpStatus={this.state.isPopUp}
                    popUpType={this.state.popUpType}
                    hidePopUp = {this.hidePopUp_Redirect}
                    PopUpMessage={this.state.PopUpMessage}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    inviteCode : state.userDetailReducer.inviteCode,
    errMsg : state.userDetailReducer.errMsg,
    isError : state.userDetailReducer.isError,
    signUpText : state.userDetailReducer.signUpText,
});

const mapDispatchToProps = dispatch => ({
    signUp : (userData) => dispatch(userDetailActions.signUp(userData)),
    setInviteCode : (code) => dispatch(userDetailActions.setInviteCode(code)),
});

export default connect(mapStateToProps,mapDispatchToProps) (SignUpSampleScreen);