import React, {useEffect, useRef, useState} from 'react';
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import { connect } from 'react-redux';
import A12Y_CONST from '../common/a12yConst'
import { withRouter } from "react-router";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function MedicationPhotoUploadComponent (props) {
    const [imageLoaded, setImageLoaded]=React.useState(false);
    /* eslint-disable no-unused-vars */
    const [error, setError] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [cropImageData, setCropImageData] = useState({})
    /* eslint-disable no-unused-vars */
    const [loading, setLoading] = useState(false);
    const [src, setSrc] = useState("");
    const [crop, setCrop] = useState({
        unit: 'px', width: 144, height: 144, aspect: 8 / 8
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const [imageRef, setImageRef] = useState("");
    const [name, setName] = useState("");
    const [isOverlay, setIsOverLay] = useState(false);
    const [isDone, setIsDone] = useState(true);
    const [uploadIcon, setUploadIcon] = useState(true);
    const [imageOptionVisible, setImageOptionVisible] = useState(false)
    const {formState:{isSubmitting}} = useForm({mode:"onBlur"});
    const refPhotoAction = useRef(null);
    const [doneButtonDisable, setDoneButtonDislabe] = useState(false)
    const onSubmit = async (data) => {
        setIsBtnDisabled(true);
        await props.onSubmit(data)
        await setIsBtnDisabled(false)
    };

    useEffect( () => {
        setLoading(props.loading)
        setError(props.errorMessage)
        // if(props.errorMessage){
        //     setIsBtnDisabled(false);
        // }
        if(props.isImageRemove){
            setCroppedImageUrl('')
        }
    }, [props.loading,props.errorMessage, props.isImageRemove])

    useEffect( () => {
        if(props.medication !== null && Object.keys(props.medication).length > 0 ){
            if(props.medication.medicine_pic_url && !props.medication.hasOwnProperty('medication_pic')){
                setUploadIcon(false)
                setIsDone(true)
                setCroppedImageUrl(props.medication.medicine_pic_url)
            }
            if(props.medication.hasOwnProperty('medication_pic')){
                setUploadIcon(false)
                setIsDone(true)
                setCroppedImageUrl(props.medication.medication_pic.base64)
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    },[])

    const onSelectFile = (e) => {
        setImageLoaded(false)
        if (e.target.files && e.target.files.length > 0) {
            props.isPopupOpen(true)
            setName(e.target.files[0].name)
            setUploadIcon(false)
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrc(reader.result)
                setIsDone(false)
                props.overlay(true);
                setIsOverLay(true)
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    const onImageLoaded = (image) => {
        // setCrop({ x: 150, y: 100, width: 144, height: 144, aspect: 8/8})
        setCrop({
            //unit: '%',
            width: 144,
            height: 144,
            x: image.width/2-72,
            y: image.height/2-72,
            aspect: 8/8})
        setImageRef(image);

        return false;
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setCrop(crop);
    };
    const cropingDone = async(Crop, percentCrop) => {
        setLoading(true);
        setImageOptionVisible(false)
        // await makeClientCrop(crop);
        let croppedImageUrl1 = null;
        let cropedUrl = null;
        if (imageRef && crop.width && crop.height) {
            croppedImageUrl1 = await getCroppedImg(
                imageRef,
                crop,
                name
            );
            if(croppedImageUrl1!= null){
                setCropImageData(croppedImageUrl1)
                const reader = new FileReader();
                await reader.readAsDataURL(croppedImageUrl1);
                reader.addEventListener('loadend', async () =>{
                        cropedUrl = reader.result;
                        //const resp = await uploadImage(croppedImageUrl1,props.dependent.advocate_id,props.dependent.id,cropedUrl,props.dependent)
                    let obj ={
                        result : croppedImageUrl1,
                        base64 : cropedUrl
                    }
                        const resp = props.uploadingMedicationPic(obj)
                        setIsDone(true);
                        setLoading(false);
                        props.overlay(false);
                        setIsOverLay(false)
                        props.isPopupOpen(false)
                        setCroppedImageUrl(reader.result)
                        resp == null && setError(true)
                    }
                );
                //  setIsDone(true);
                //  setLoading(false);
                //  props.overlay(false);
                //  setIsOverLay(false)
                //  props.isPopupOpen(false)
            }
        }
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });


    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl1 = await getCroppedImg(
                imageRef,
                crop,
                name
            );
            setCropImageData(croppedImageUrl1)
            const reader = new FileReader();
            reader.readAsDataURL(croppedImageUrl1);
            reader.addEventListener('loadend', () =>
                setCroppedImageUrl(reader.result )
            );
        }
        if(crop.width === 0){
            setDoneButtonDislabe(true)
        }else{
            setDoneButtonDislabe(false)
        }
    }

    const getCroppedImg = async (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    //window.URL.revokeObjectURL(fileUrl);
                    //setFileUrl(window.URL.createObjectURL(blob));
                    resolve(blob);
                },
                'image/jpeg',
                1
            );
        });
    }
    const onCancel = async()=>{
        props.overlay(false);
        setIsOverLay(false)
        props.isPopupOpen(false)
        if(props.medication === null || (props.medication !== null && props.medication.medicine_pic_url === null)){
            if(Object.keys(props.medicationPicObj).length  > 0){
                setSrc("")
                setIsDone(true)
                setCroppedImageUrl(props.medicationPicObj.base64)
            }else {
                setUploadIcon(true)
                setSrc("")
            }
        }else {
            setSrc("")
            setIsDone(true)
            setCroppedImageUrl(props.medicationPicObj.base64)
        }
        setImageOptionVisible(false)
        setDoneButtonDislabe(false)

        if(props.medication?.medicine_pic_url){
            setCroppedImageUrl(props.medication.medicine_pic_url)
        }
    }
    const showImageOptions = () => {
        setImageOptionVisible(!imageOptionVisible);
        props.overlay(true);
        setIsOverLay(true);
    }
    const handleUploadNow = () => {
        setUploadIcon(true);
        setImageOptionVisible(false);
        setCroppedImageUrl('')
        props.overlay(false);
        setIsOverLay(false)
    }
    const handleRemove = () => {
        setUploadIcon(true);
        setImageOptionVisible(false);
        props.removeImageCol();
        setCroppedImageUrl('');
        props.overlay(false);
        setIsOverLay(false)
    }

    const outerSideClick = ()=>{
        if(imageOptionVisible){
            setImageOptionVisible(false)
        }
    }

    // Hide Opened Element on clicking outside
    const handleClickOutside = (event) => {
        if (refPhotoAction.current && !refPhotoAction.current.contains(event.target)) {
            setImageOptionVisible(false)
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <div className='DependentStep2FormCol alignCenterVH'>
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            {uploadIcon ?
                <div className="broseRow">
                    <label className="custom-file-upload">
                        <input id="fileBrowse" type="file" accept="image/*"  onChange={onSelectFile}/>
                        <svg width="108" height="107" viewBox="0 0 108 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.357605" y="0.476196" width="107.285" height="106.048" rx="8" fill="#91B7E2"/>
                            <path d="M67.4062 98.1386H40.5901C39.2109 98.1386 37.8882 97.6075 36.913 96.6622C35.9377 95.7168 35.3899 94.4347 35.3899 93.0978V89.7372H72.5718V93.0978C72.5718 94.4289 72.0287 95.706 71.0609 96.6504C70.0932 97.5947 68.7794 98.1297 67.4062 98.1386V98.1386Z" fill="#7DA5CD"/>
                            <path d="M44.951 6H62.2853C64.0906 6.00444 65.8207 6.7016 67.0973 7.93904C68.3738 9.17648 69.093 10.8535 69.0976 12.6035V13.5277H38.1386V12.6035C38.1432 10.8535 38.8624 9.17648 40.139 7.93904C41.4156 6.7016 43.1457 6.00444 44.951 6V6Z" fill="#E1EAF4"/>
                            <path d="M72.0696 35.2335C70.3389 34.5388 68.7543 33.5412 67.3947 32.2903C65.8191 30.6084 66.0788 26 66.0788 26H40.9038C40.9038 26 41.1635 30.6084 39.6052 32.2903C38.2456 33.5412 36.6611 34.5388 34.9304 35.2335C33.7596 35.7454 32.7658 36.5748 32.0686 37.622C31.3713 38.6692 31.0003 39.8898 31 41.1369V89.5247C31.0046 91.2407 31.7083 92.8852 32.9575 94.0985C34.2066 95.3119 35.8995 95.9956 37.666 96H69.3339C70.2093 96 71.0761 95.8325 71.8849 95.5071C72.6937 95.1817 73.4285 94.7047 74.0475 94.1034C74.6665 93.5022 75.1575 92.7883 75.4925 92.0027C75.8275 91.2171 76 90.3751 76 89.5247V41.1369C76.004 39.8889 75.6347 38.6665 74.9369 37.6185C74.2392 36.5705 73.243 35.7419 72.0696 35.2335Z" fill="#E1EAF4"/>
                            <path d="M64.9027 20.6185H41.8135C40.7605 20.6185 39.9068 21.4461 39.9068 22.4668V24.8361C39.9068 25.8569 40.7605 26.6844 41.8135 26.6844H64.9027C65.9558 26.6844 66.8095 25.8569 66.8095 24.8361V22.4668C66.8095 21.4461 65.9558 20.6185 64.9027 20.6185Z" fill="#B8C9DD"/>
                            <path d="M66.6015 9.79749H40.4788C39.1098 9.79749 38 10.8733 38 12.2003V21.0218C38 22.3488 39.1098 23.4246 40.4788 23.4246H66.6015C67.9705 23.4246 69.0803 22.3488 69.0803 21.0218V12.2003C69.0803 10.8733 67.9705 9.79749 66.6015 9.79749Z" fill="#CCDBEC"/>
                            <path d="M40.1841 22.0804C39.974 22.0761 39.7738 21.9933 39.6252 21.8493C39.4766 21.7052 39.3911 21.5111 39.3867 21.3074V12.0995C39.3867 11.8945 39.4707 11.6979 39.6203 11.5529C39.7698 11.408 39.9727 11.3265 40.1841 11.3265C40.3956 11.3265 40.5984 11.408 40.7479 11.5529C40.8975 11.6979 40.9815 11.8945 40.9815 12.0995V21.3074C40.9771 21.5111 40.8916 21.7052 40.743 21.8493C40.5944 21.9933 40.3942 22.0761 40.1841 22.0804V22.0804Z" fill="#B3C6DC"/>
                            <path d="M43.4776 22.0805C43.2675 22.0762 43.0673 21.9934 42.9187 21.8493C42.7701 21.7053 42.6846 21.5112 42.6802 21.3075V12.0996C42.6802 11.8946 42.7642 11.698 42.9138 11.553C43.0633 11.408 43.2661 11.3266 43.4776 11.3266C43.583 11.3243 43.6877 11.3428 43.7855 11.3808C43.8834 11.4188 43.9722 11.4757 44.0467 11.5479C44.1212 11.6201 44.1799 11.7062 44.2191 11.8011C44.2584 11.8959 44.2773 11.9974 44.275 12.0996V21.3075C44.275 21.5125 44.191 21.7091 44.0414 21.8541C43.8919 21.999 43.6891 22.0805 43.4776 22.0805V22.0805Z" fill="#B3C6DC"/>
                            <path d="M46.7885 22.0804C46.577 22.0804 46.3741 21.999 46.2246 21.854C46.0751 21.7091 45.9911 21.5125 45.9911 21.3075V12.0995C45.9742 11.9896 45.982 11.8774 46.0139 11.7707C46.0458 11.664 46.1011 11.5652 46.1759 11.4811C46.2508 11.3969 46.3435 11.3294 46.4477 11.2831C46.5519 11.2369 46.6652 11.2129 46.7798 11.2129C46.8944 11.2129 47.0077 11.2369 47.1119 11.2831C47.2161 11.3294 47.3088 11.3969 47.3836 11.4811C47.4585 11.5652 47.5138 11.664 47.5457 11.7707C47.5776 11.8774 47.5853 11.9896 47.5685 12.0995V21.3075C47.5685 21.5096 47.4869 21.7037 47.3411 21.8482C47.1953 21.9926 46.9969 22.076 46.7885 22.0804V22.0804Z" fill="#B3C6DC"/>
                            <path d="M50.082 22.0805C49.8705 22.0805 49.6676 21.999 49.5181 21.8541C49.3686 21.7091 49.2846 21.5125 49.2846 21.3075V12.0996C49.2822 11.9974 49.3012 11.8959 49.3405 11.8011C49.3797 11.7062 49.4383 11.6201 49.5129 11.5479C49.5874 11.4757 49.6762 11.4188 49.774 11.3808C49.8718 11.3428 49.9766 11.3243 50.082 11.3266C50.2934 11.3266 50.4962 11.408 50.6458 11.553C50.7953 11.698 50.8793 11.8946 50.8793 12.0996V21.3075C50.8749 21.5112 50.7895 21.7053 50.6409 21.8493C50.4923 21.9934 50.292 22.0762 50.082 22.0805V22.0805Z" fill="#B3C6DC"/>
                            <path d="M53.3755 22.0804C53.1654 22.0761 52.9651 21.9933 52.8165 21.8493C52.6679 21.7052 52.5825 21.5111 52.5781 21.3074V12.0995C52.5781 11.8945 52.6621 11.6979 52.8116 11.5529C52.9612 11.408 53.164 11.3265 53.3755 11.3265C53.5869 11.3265 53.7897 11.408 53.9393 11.5529C54.0888 11.6979 54.1728 11.8945 54.1728 12.0995V21.3074C54.1684 21.5111 54.083 21.7052 53.9344 21.8493C53.7858 21.9933 53.5855 22.0761 53.3755 22.0804V22.0804Z" fill="#B3C6DC"/>
                            <path d="M56.6689 22.0805C56.4589 22.0762 56.2586 21.9934 56.11 21.8493C55.9614 21.7053 55.876 21.5112 55.8716 21.3075V12.0996C55.8716 11.8946 55.9556 11.698 56.1051 11.553C56.2547 11.408 56.4575 11.3266 56.6689 11.3266C56.7743 11.3243 56.8791 11.3428 56.9769 11.3808C57.0747 11.4188 57.1635 11.4757 57.238 11.5479C57.3126 11.6201 57.3712 11.7062 57.4104 11.8011C57.4497 11.8959 57.4687 11.9974 57.4663 12.0996V21.3075C57.4663 21.5125 57.3823 21.7091 57.2328 21.8541C57.0833 21.999 56.8804 22.0805 56.6689 22.0805V22.0805Z" fill="#B3C6DC"/>
                            <path d="M59.9625 22.0804C59.754 22.076 59.5556 21.9926 59.4098 21.8482C59.264 21.7037 59.1824 21.5096 59.1824 21.3075V12.0995C59.1656 11.9896 59.1733 11.8774 59.2052 11.7707C59.2371 11.664 59.2924 11.5652 59.3673 11.4811C59.4421 11.3969 59.5348 11.3294 59.639 11.2831C59.7432 11.2369 59.8565 11.2129 59.9711 11.2129C60.0857 11.2129 60.199 11.2369 60.3032 11.2831C60.4074 11.3294 60.5001 11.3969 60.575 11.4811C60.6498 11.5652 60.7051 11.664 60.737 11.7707C60.7689 11.8774 60.7767 11.9896 60.7599 12.0995V21.3075C60.7599 21.5125 60.6758 21.7091 60.5263 21.854C60.3768 21.999 60.1739 22.0804 59.9625 22.0804V22.0804Z" fill="#B3C6DC"/>
                            <path d="M63.2733 22.0805C63.0618 22.0805 62.859 21.999 62.7095 21.8541C62.56 21.7091 62.4759 21.5125 62.4759 21.3075V12.0996C62.4736 11.9974 62.4926 11.8959 62.5318 11.8011C62.571 11.7062 62.6297 11.6201 62.7042 11.5479C62.7787 11.4757 62.8676 11.4188 62.9654 11.3808C63.0632 11.3428 63.1679 11.3243 63.2733 11.3266C63.4848 11.3266 63.6876 11.408 63.8372 11.553C63.9867 11.698 64.0707 11.8946 64.0707 12.0996V21.3075C64.0663 21.5112 63.9808 21.7053 63.8322 21.8493C63.6836 21.9934 63.4834 22.0762 63.2733 22.0805V22.0805Z" fill="#B3C6DC"/>
                            <path d="M66.5668 22.0804C66.3567 22.0761 66.1565 21.9933 66.0079 21.8493C65.8593 21.7052 65.7739 21.5111 65.7694 21.3074V12.0995C65.7694 11.8945 65.8535 11.6979 66.003 11.5529C66.1525 11.408 66.3553 11.3265 66.5668 11.3265C66.7783 11.3265 66.9811 11.408 67.1307 11.5529C67.2802 11.6979 67.3642 11.8945 67.3642 12.0995V21.3074C67.3598 21.5111 67.2743 21.7052 67.1257 21.8493C66.9772 21.9933 66.7769 22.0761 66.5668 22.0804V22.0804Z" fill="#B3C6DC"/>
                            <path d="M76 46H31V88H76V46Z" fill="#CCDBEC"/>
                            <path d="M76 79H31V81H76V79Z" fill="white"/>
                            <path d="M52.0021 64.9529H49.5805L47.2066 59.5281H44.0032V64.9529H41.6865V51.5896H47.2829C48.338 51.5896 49.2055 51.7498 49.8856 52.0702C50.5721 52.3844 51.0805 52.8311 51.411 53.4102C51.7415 53.9832 51.9068 54.6547 51.9068 55.4249C51.9068 56.1395 51.786 56.7402 51.5445 57.227C51.303 57.7075 50.9884 58.1018 50.6007 58.4099C50.2193 58.7118 49.8093 58.9582 49.3708 59.1492L52.0021 64.9529ZM46.7585 57.7445C47.6356 57.7445 48.3189 57.5473 48.8083 57.153C49.3041 56.7587 49.5519 56.2104 49.5519 55.508C49.5519 54.818 49.3231 54.2851 48.8655 53.9092C48.4079 53.5334 47.8041 53.3455 47.0541 53.3455H44.0032V57.7445H46.7585Z" fill="white"/>
                            <path d="M66.5772 64.9529H63.698L60.3039 59.8146L56.9671 64.9529H54.4502L59.0741 58.1511L54.6027 51.5896H57.4629L60.5232 56.2658L63.5645 51.5896H66.0624L61.7435 57.8831L66.5772 64.9529Z" fill="white"/>
                            <rect x="36.5513" y="68.3135" width="32.935" height="3.36057" rx="1" fill="white"/>
                            <rect x="36.5513" y="73.3544" width="22.5345" height="1.68029" rx="0.840145" fill="white"/>
                        </svg>
                    </label>

                </div>
                :
                null
            }
            <div>
                <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} />
                </div>
                {!isDone && src ? (
                    <div className={ isOverlay ? 'imageCropBoxMain' :'imageCropBoxMain'}>
                        <div className='imgCropBox'>
                            <div className='imgCropBoxInner'>
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    zoom={1}
                                    scale={1}
                                    circularCrop={false}
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                                <div className="textAlignRight displayFlexCol imageCropButtonRow marginTop50" >
                                    <div className='widthAuto'><input  id={A12Y_CONST.BUTTON_ID.CANCEL_BUTTON} className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>
                                    <div className='widthAuto marginLeft20'>
                                        <div className='loaderSpinner'>
                                            {loading ?
                                                <Loading />
                                                :
                                                null
                                            }
                                            <input id={A12Y_CONST.BUTTON_TEXT.DONE+A12Y_CONST.BUTTON_ID.BUTTON} type="button" value={"Done"} className={loading || doneButtonDisable ?'doneButtonDisable':'doneButton'} disabled={doneButtonDisable}  onClick={cropingDone} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ):<div></div>}
                {isDone && croppedImageUrl && (
                    <div className='dependentAfterUploadImgCol'>
                        <div className='dependentImgageCol overflowHidden' onClick={showImageOptions}>
                            <label className='custom-file-uploadImage'>
                                {!imageLoaded && (
                                    <div className="picLoader">
                                        <div className='fullWidth justifyCenter marginBottom10' style={{height:15}}>
                                            <div className="stage">
                                                <div className="dot-pulse"></div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <img
                                    id={A12Y_CONST.BUTTON_ID.IMAGE_UPLOAD+A12Y_CONST.LABEL.MEDICATION}
                                    onLoad={()=>{setImageLoaded(true)}}
                                    alt="Crop" src={croppedImageUrl} />
                            </label>
                        </div>
                        <div className='positionRelative'>
                            {imageOptionVisible ?
                                <div className="positionAbsoute width300Center" ref={refPhotoAction}>
                                    <div className='imageOptionList'>
                                        <ul>
                                            <li>
                                                <label onChange={onSelectFile}>
                                                    <input id="fileBrowse" accept="image/*"  type="file" onChange={onSelectFile}/>{A12Y_CONST.TEXT.UPLOAD_PHOTO}
                                                </label>
                                            </li>
                                            <li id={A12Y_CONST.BUTTON_ID.IMAGE_REMOVE_BUTTON} onClick={handleRemove} >{A12Y_CONST.LABEL.REMOVE_PHOTO}</li>
                                        </ul>
                                    </div>
                                </div>
                                :
                                null}
                            <div id={A12Y_CONST.BUTTON_ID.IMAGE_UPLOAD_BUTTON} className='avtarTextCol'><span>{A12Y_CONST.LABEL.PHOTO_UPLOADED}</span></div>
                        </div>

                    </div>
                )}
            </div>

        </div>

    );
}


export default withRouter (connect(null,null) (MedicationPhotoUploadComponent));
