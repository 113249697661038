import React, { Component } from 'react'
import ExpenseList from '../components/ExpenseListComponent'
import { connect } from 'react-redux';
import {secureClient} from "../config/axiosClient";
import  * as userDetailActions from '../redux/actions/userDetailActions'
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from '../common/a12yConst'
import moment from 'moment';
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import WelcomeComponent from "../components/WelcomeComponent";
import {ddmmyyyyFormat} from "../util/date_format";

class ExpenseScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentScreen :0,
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            isExpensesAvailable: false,
            expenseList : [],
            loaderFullScreenVisible : false,
            btnTitle: this.props.dependent?.metadata?.expense_present && this.props.expense_present === false ? A12Y_CONST.BUTTON_TEXT.BACK_TO_EXPENSE : A12Y_CONST.BUTTON_TEXT.ADD_AN_EXPENSE
        }
    }

    componentDidMount = async() => {
        let state = { ...this.props.location.state };
        if(state?.ladingPageVisible){
            // await this.goToExpenseLandingPageHandler()
            if(this.props?.dependent?.metadata?.expense_present){
                await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_EXPENSE})
            }else{
                await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.ADD_AN_EXPENSE})
            }
            this.props.updateMetaData(this.props.income_present,false,this.props.activity_present,this.props.asset_present)
        }
        if(state?.ladingPageVisible){
            state.ladingPageVisible = false;
        }
        await this.props.expenseViewingUsingActivityView(false);
        await this.props.currentExpenseData({})
        await this.props.updateSessionTimeout(false)
        this.setState({loading:true})
        await this.props.dataChanged(false)
        if(this.props.expenseTypeList.length === 0 || this.props.expenseTypeList[0] === undefined){
            await this.getExpenseTyp()
        }
       // await this.getMetaData();
        if(this.props.expense_present){
            let types =[]
            if(this.props.filterData['expense'].type.length > 0){
                types = this.props.filterData['expense'].type;
            }
            await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date,types)
        }else {
            this.setState({loaderFullScreenVisible:false,loading:false})
        }
    }

    getExpenseTyp = async () => {
        await this.props.getExpenseTypeList();
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,loaderFullScreen: false})
        }else {
            this.setState({message:this.props.successMessage,loaderFullScreen: false})
        }
    }

    getMetaData = async() =>{
        let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENT+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.METADATA);
        this.setState({loaderFullScreenVisible:false})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({isExpensesAvailable:response.data.sr.expense_present})
            if(response.data.sr.expense_present){
                let types =[]
                if(this.props.filterData['expense'].isModify){
                    types = this.props.filterData['expense'].type;
                }
                await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date,types)
            }else {
                this.setState({loaderFullScreenVisible:false})
            }
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }

    onSearchHandler = async(date1,date2,typeList)=>{
        let startDate = await ddmmyyyyFormat(date1);
        let endDate = await ddmmyyyyFormat(date2);
        this.setState({loading:true,errorMessage:'',error:false})
        let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.EXPENSE_BY_DATE_RANGE,{"start_date": startDate,"end_date": endDate,"expense_type_ids":typeList});
       // this.setState({loading:false})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
             this.setState({expenseList:response.data.sr.expenses})

           /* var isTypeListChange = false;
            if(JSON.stringify(typeList)===JSON.stringify(this.props.dependent.reporting_period.expense_type_ids)){
                // console.log('if',typeList, this.props.dependent.reporting_period.expense_type_ids);
                isTypeListChange = false;
            }
            else{
                // console.log('else',typeList, this.props.dependent.reporting_period.expense_type_ids);
                isTypeListChange = true;
            }*/
            /*  if((Object.keys(this.props.dependent).length > 0 && this.props.dependent.reporting_period.start_date !== startDate) || this.props.dependent.reporting_period.end_date !== endDate || isTypeListChange){
                // await this.props.setUserPreferences(startDate,endDate,this.props.dependent,this.props.dependentList)

            }*/

            await this.props.setUserPreferences(startDate,endDate,this.props.dependent,this.props.dependentList,this.props.dependent?.reporting_period?.activity_type_ids,this.props.dependent?.reporting_period?.activity_tags,typeList,this.props.dependent?.reporting_period?.income_type_ids)
            this.setState({loaderFullScreenVisible:false,loading:false})

        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }

    onDeleteHandler = async(expense)=>{
        this.setState({loading:true,errorMessage:'',error:false})
        let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.EXPENSES+"/"+expense.id);
       // this.setState({loading:false})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let dataList = await this.state.expenseList.filter(item => item.id !== expense.id)
            this.setState({expenseList:dataList,loading:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }

    hideMessage = async ()=>{
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    dateFormat = async(date)=>{
      return  await moment(date).format("MM/DD/YYYY")
    }

    onEditHandler = async(expense) =>{
        await this.props.currentExpenseData(expense)
        this.props.history.push(A12Y_CONST.ROUTE.ADD_EXPENSE);
    }

    onCreateHandler = async() =>{
        await this.props.expenseCreatingUsingActivityLog(false);
        await this.props.currentExpenseData({})
        this.props.history.push(A12Y_CONST.ROUTE.ADD_EXPENSE);
    }

    onViewHandler = async(expense) =>{
        await this.props.currentExpenseData(expense)
        this.props.history.push(A12Y_CONST.ROUTE.EXPENSE_VIEW);
    }

    goToExpenseLandingPageHandler = async ()=>{
        this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_EXPENSE})
        this.props.updateMetaData(this.props.income_present,!this.props.expense_present,this.props.activity_present,this.props.asset_present)
    }

    landingPageBtnEvent = async ()=>{
        if(this.props.dependent?.metadata?.expense_present){
            this.props.updateMetaData(this.props.income_present,!this.props.expense_present,this.props.activity_present,this.props.asset_present)
            // await this.onSearchHandler(this.props.dependent?.reporting_period?.start_date,this.props.dependent?.reporting_period?.end_date)
            let types =[]
            if(this.props.filterData['expense'].type.length > 0){
                types = this.props.filterData['expense'].type;
            }
            await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date,types)
        }else {
            await this.props.expenseCreatingUsingActivityLog(false);
            await this.props.currentExpenseData({})
            this.props.history.push(A12Y_CONST.ROUTE.ADD_EXPENSE);
        }
    }

    setfilter = async (date1, date2,typeList,modify) => {
        console.log('date1, date2,typeList,tagList,modify', date1, date2,typeList,modify)
        var filter = this.props.filterData
        filter['expense'].type = typeList
        filter['expense'].isModify = modify
        await this.props.setSeletedFilter(filter)
        this.onSearchHandler(date1, date2,typeList,modify)
        await console.log('this.props.filterData',this.props.filterData)
    }
    render() {
        const {expenseList} = this.state
        return (
            <div className="fullWidth">
                        {this.state.loaderFullScreenVisible ?
                            <div className="myProfileAccount animationall lightBackgroundBlueColor expenseListScreenMain mainRightSidebare">
                                <div className='rightPanelWidhtSidebar'>
                                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                                          <LoaderFullScreen/>
                                    </div>
                                </div>
                            </div>
                            :
                            (this.props.expense_present ?
                            <div className="myProfileAccount animationall lightBackgroundBlueColor expenseListScreenMain mainRightSidebare">
                                <div className='rightPanelWidhtSidebar'>
                                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                                        <ExpenseList
                                            expenseList={expenseList}
                                            message={this.state.message}
                                            error={this.state.error}
                                            errorMessage={this.state.errorMessage}
                                            onSearch={this.onSearchHandler}
                                            onDelete={this.onDeleteHandler}
                                            onEdit={this.onEditHandler}
                                            userInfo={this.props.userState.userInfo}
                                            onCreate={this.onCreateHandler}
                                            loading={this.state.loading}
                                            dependent={this.props.dependent}
                                            onView={this.onViewHandler}
                                            expenseTypeList={this.props.expenseTypeList}
                                            goToExpenseLandingPage={this.goToExpenseLandingPageHandler}
                                            filterSelectedTypeListExpense={this.props.filterSelectedTypeListExpense}
                                            updateSelectedFilterTypeList={this.updateSelectedFilterTypeList}
                                            setfilter={this.setfilter}
                                            filterData={this.props.filterData}
                                        />
                                    </div>
                                </div>
                            </div>
                                :
                                // <NoExpenses
                                //     dependent={this.props.dependent}
                                //     onCreate={this.onCreateHandler}
                                // />
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare welcomeScreen">
                                        <div className='rightPanelWidhtSidebar bgWhite fullWidth marginTop12'>
                                            <div className="rightPanelWidhtSidebarContainer welcomeBg fullWidth marginNone">
                                                <WelcomeComponent
                                                    screenName={A12Y_CONST.LABEL.EXPENSES_SMALL }
                                                    title={`${A12Y_CONST.TEXT.STAY_ON_TOP_OF} ${this.props.dependent.nick_name}'s ${A12Y_CONST.TEXT.EXPENSES_DOT}`}
                                                    description1={A12Y_CONST.TEXT.JUST_AS_WITH_INCOME}
                                                    description2={A12Y_CONST.TEXT.USE_THIS_SECTION_TO}
                                                    descriptionTitle={A12Y_CONST.TEXT.ADD_YOUR_FIRST_EXPENSE}
                                                    descriptionList_1={A12Y_CONST.TEXT.QUICKLY_RECORD_AND_CLASSIFY}
                                                    descriptionList_2={A12Y_CONST.TEXT.REPORT_ON_TOTAL_VALUE}
                                                    descriptionList_3={A12Y_CONST.TEXT.COMPARE_EXPENSES_FROM_YEAR}
                                                    dependent={this.props.dependent}
                                                    onCreate={this.landingPageBtnEvent}
                                                    welcomeScreenType={A12Y_CONST.LABEL.EXPENSES_SMALL}
                                                    buttonTitle={this.state.btnTitle}
                                                />
                                            </div>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
        )
    }
}


const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    dependent : state.dependentReducer.dependent,
    expenseTypeList: state.dependentReducer.expenseTypeList,
    expense_present: state.dependentReducer.expense_present,
    asset_present: state.dependentReducer.asset_present,
    activity_present: state.dependentReducer.activity_present,
    income_present: state.dependentReducer.income_present,
    userState : state.userDetailReducer.userState,
    dependentList : state.dependentReducer.dependentList,
    filterData : state.userDetailReducer.filterData,
});

const mapDispatchToProps = dispatch => ({
    currentExpenseData : (expense) => dispatch(dependentActions.currentExpenseData(expense)),
    expenseCreatingUsingActivityLog: (status) => dispatch(dependentActions.expenseCreatingUsingActivityLog(status)),
    setUserPreferences : (startDate,endDate,dependent,dependentList,activeTypeList,tagList,expenseTypeList,incomeTypeList) => dispatch(dependentActions.setUserPreferences(startDate,endDate,dependent,dependentList,activeTypeList,tagList,expenseTypeList,incomeTypeList)),
    getExpenseTypeList: () => dispatch(dependentActions.getExpenseTypeList()),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    updateSessionTimeout : (status) => dispatch(userDetailActions.updateSessionTimeout(status)),
    expenseViewingUsingActivityView : (status) => dispatch(dependentActions.expenseViewingUsingActivityView(status)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    setSeletedFilter : (data) => dispatch(userDetailActions.setSeletedFilter(data)),
});


export default connect(mapStateToProps, mapDispatchToProps) (ExpenseScreen);
