import {secureClient} from "../config/axiosClient";
import A12Y_CONST from '../common/a12yConst'

export async function setupAccessControl(dependentId, assetId, data) {
    let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+dependentId+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+assetId, data);
    return response;
}

export async function saveInstitutionDetails(dependentId, assetId, data) {
    let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+dependentId+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+assetId+'/'+A12Y_CONST.API_ENDPOINT.INSTITUTES, data);
    return response;
}

export async function saveLoan(dependentId, assetId, data) {
    let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+dependentId+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+assetId+'/'+A12Y_CONST.API_ENDPOINT.LOANS, data);
    return response;
}

export async function saveAddressDetails(dependentId, assetId, data) {
    let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+dependentId+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+assetId+A12Y_CONST.API_ENDPOINT.ADDRESSES, data);
    return response;
}

export async function decryptAccountNo (dependentId, id) {
    let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+dependentId+'/'+A12Y_CONST.API_ENDPOINT.DECRYPT+'/'+id);
    return response;
}