import React,{Component} from 'react'

class ReleaseNotes extends Component{

    render() {
        return(
            <div className="fullWidth">
                <h1>Release Notes</h1>
            </div>
        )
    }

}

export default ReleaseNotes;