import React, {useEffect, useRef, useState} from 'react'
import A12Y_CONST from "../common/a12yConst";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PopUpIncomeExpComponent from './PopUpIncomeExpComponent'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import viewIcon from "../assets/images/viewIcon.svg";
import {ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {readOnlyMobile} from '../util/inputKeyboardHide';
import {downloadBase64File} from "../util/Common";
import {secureClient} from "../config/axiosClient";
import LoaderFullScreen from "./LoaderFullScreenComponent";

import moment from "moment/moment";
import Multiselect from 'multiselect-react-dropdown';
// import {Tooltip} from "antd";

function ActivityListComponent(props) {
	const [activityList, setActivityList] = useState([])
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [isPopUp, setIsPopUp] = useState(false);
	const [loading, setLoading] = useState(false);
	const [activityData, setActivityData] = useState({});
	const [isSorted, setIsSorted] = useState(false);
	const [dateSortClass, setDateSortClass] = useState('');
	const [typeSortClass, setTypeSortClass] = useState('');
	const [tagSortClass, setTagSortClass] = useState('');
	const [error, setErrors] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [message, setMessage] = useState('');
	//const [printObj, setPrintObj] = useState({});
	const [totalHours, setTotalHours] = useState('');
	const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
	const refCalendarAction = useRef(null);
	const refFilterAction = useRef(null);
	const startDatesRef = useRef(null);
	const endDatesRef = useRef(null);

	const [isResponsiveFilter, setisResponsiveFilter] = useState(false);
	const [isResponsiveCalendar, setisResponsiveCalendar] = useState(false);
	const [selectedTagFilter, setSelectedTagFilter] = useState([]);
	const [selectedTypeFilter, setSelectedTypeFilter] = useState([]);
	const [typeFilter, setTypeFilter] = useState([]);
	const [tagList, setTagList] = useState(
[
		{name: 'Red', isChecked: false, tag:1},
		{name: 'Blue', isChecked: false, tag:2},
		{name: 'Yellow', isChecked: false, tag:3},
		{name: 'Green', isChecked: false, tag:4},
		{name: 'NoTag', isChecked: false, tag:0}
		]
	)
	const [isFilter, setIsFilter] = useState(false);
	const [isFilterUpdated, setIsFilterUpdated] = useState(false);


	const tagCheck = props?.filterData['activity']?.tag.length > 0 ? props?.filterData['activity'].tag :''
	const typeCheck = props?.filterData['activity']?.type.length > 0 ? props?.filterData['activity'].type:''

	useEffect(() => {
		// clear filter button
		const checkFilter = async () => {
			/*let start_Date = await moment(startDate).format("MM/DD/YYYY")
			let end_Date = await moment(endDate).format("MM/DD/YYYY")*/
			if(typeCheck.length > 0 || tagCheck.length > 0 || (await props.dependent?.reporting_period?.start_date !== await props.dependent?.default_reporting_period?.start_date) || (await props.dependent?.reporting_period?.end_date !== props.dependent?.default_reporting_period?.end_date)){
				await setIsFilter(true);
			}else{
				await setIsFilter(false);
			}
		};
		checkFilter(); // run it, run it

	},[isFilter, startDate, endDate,props?.filterData,props.dependent?.reporting_period?.start_date,props.dependent?.reporting_period?.end_date, typeCheck, tagCheck, props.dependent?.default_reporting_period?.end_date, props.dependent?.default_reporting_period?.start_date])

	const tagCheckUpdate = props?.filterData['activity']?.tag
	const typeCheckUpdate = props?.filterData['activity']?.type
	useEffect(() => {

		// function compareArrays(arr1, arr2) {
		//
		// 	// compare arrays
		//
		// }
		//
		// const array1 = [1, 3, 5, 8];
		// const array2 = [8, 1, 3, 5,];
		// compareArrays(array1, array2);
		const typeFilterResult = JSON.stringify(selectedTypeFilter.sort()) === JSON.stringify(props?.filterData['activity'].type.sort())
		const tagFilterResult = JSON.stringify(selectedTagFilter.sort()) === JSON.stringify(props?.filterData['activity'].tag.sort())
		let isTypeFilter= false;
		let isTagFilter=false;
		if(typeFilterResult) {
			isTypeFilter = true
		}
		else {
			isTypeFilter = false
		}
		if(tagFilterResult) {
			isTagFilter = true
		}
		else {
			isTagFilter = false
		}
		const updateFilter = async () => {
			let start_Date = await moment(startDate).format("MM/DD/YYYY")
			let end_Date = await moment(endDate).format("MM/DD/YYYY")

			if ((!isTypeFilter) || (!isTagFilter) || (await props.dependent?.reporting_period?.start_date !== start_Date) || (await props.dependent?.reporting_period?.end_date !== end_Date)) {
			await setIsFilterUpdated(true)
			} else {
			await setIsFilterUpdated(false)
			}
		}
		updateFilter(); // run it, run it
	},[isFilterUpdated,  startDate, endDate, tagList,selectedTypeFilter,selectedTagFilter,props?.filterData, typeCheckUpdate, tagCheckUpdate, props.dependent?.reporting_period?.start_date,props.dependent?.reporting_period?.end_date])

	useEffect(  () => {
		 setActivityList(props.activityList)
		//setActivityList(activityListDummy)

	}, [props.activityList])

	useEffect( () => {
		if (props.dependent) {
			setStartDate(new Date(props.dependent.reporting_period.start_date));
			setEndDate(new Date(props.dependent.reporting_period.end_date))
		}
	}, [props.dependent])

	const filterActivity = async(date1, date2, typeList, tagList) => {
		// props.onSearch(date1, date2, typeList, tagList)
		props.setfilter(date1, date2,typeList, tagList, true)
		setisResponsiveCalendar(false)
	}

	 const clearFilter = async () => {
		 tagList.map((tag) => (
			 tag.isChecked = false
		 ));
		 await setTypeFilter([]);
		 await setTagList(tagList);
		 // await props.updateSelectedFilterTypeList([])
		 props.setfilter(props.dependent?.default_reporting_period?.start_date, props.dependent?.default_reporting_period?.end_date,[], [], false);
		 await setIsFilter(false);
		 await setIsFilterUpdated(false);
		 await setisResponsiveCalendar(false);
		 // setStartDate(props.dependent?.default_reporting_period?.start_date)
		 	setStartDate(new Date(props.dependent?.default_reporting_period?.start_date));
		 	setEndDate(new Date(props.dependent?.default_reporting_period?.end_date));
		 // setEndDate(props.dependent?.default_reporting_period?.end_date)
	}
	const filterPopUpClose = () => {
		let tags = tagList
		tags?.forEach((item) => {
			if(props?.filterData['activity'].tag.length > 0 ){
				props?.filterData['activity'].tag.forEach((data) => {
					if (data === item.tag) {
						item.isChecked = true;
					}else{
						item.isChecked = false;
					}
				})
				}else{
					tagList.map((tag) => (
						tag.isChecked = false
					))
				}
		})
		setTagList(tags);

		setStartDate(new Date(props.dependent.reporting_period.start_date));
		setEndDate(new Date(props.dependent.reporting_period.end_date))

		let typeFilterCol = []
		props?.filterData['activity'].type?.length > 0 && props?.activityTypeList?.forEach((item) => {
			props?.filterData['activity'].type.forEach((data) => {
				if (data === item.id) {
					typeFilterCol.push(item)
				}
			})
		})
		setTypeFilter(typeFilterCol);

		setisResponsiveCalendar(false)
	}

	useEffect(() => {
		const filterDataAssignOnLoad = async () => {
			if ((props?.filterData['activity']?.type.length > 0 || props?.filterData['activity']?.tag.length > 0) && tagList?.length > 0) {
				let tags = tagList
				tags?.forEach((item) => {
					props?.filterData['activity'].tag.forEach((data) => {
						if (data === item.tag) {
							item.isChecked = true;
						}
					})
				})
				setTagList(tags);

				let typeFilterCol = []
				props?.filterData['activity'].type?.length > 0 && props?.activityTypeList?.forEach((item) => {
					props?.filterData['activity'].type.forEach((data) => {
						if (data === item.id) {
							typeFilterCol.push(item)
						}
					})
				})
				setTypeFilter(typeFilterCol);
				//filterActivity(startDate, endDate, props?.filterData['activity'].type, props?.filterData['activity'].tag)
			}
		}
		filterDataAssignOnLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	function editActivity(activity) {
		props.onEdit(activity);
	}
	function deleteActivity(activity) {
		setActivityData(activity)
		setIsPopUp(true)
	}

	useEffect(() => {
		setLoading(props.loading)
		setErrors(props.error)
		setErrorMessage(props.errorMessage)
		setMessage(props.message)
	}, [props.loading,props.error,props.message,props.errorMessage])

	function addActivity(params) {
		props.onCreate();
	}

	function sortBy(sortType) {
		let sortedArray = [];
		const tempArray = [...props.activityList];

		setDateSortClass('')
		setTagSortClass('')
		setTypeSortClass('')
		
		if (sortType === 'date_of_activity') {
			if (isSorted) {
				sortedArray = tempArray.sort((a, b) => (
					new Date(b['date_of_activity']) - new Date(a['date_of_activity'])
				));
				setDateSortClass('active dec')
				setIsSorted(false)
			} else {
				sortedArray = tempArray.sort((a, b) => (
					new Date(a['date_of_activity']) - new Date(b['date_of_activity'])
				));
				setIsSorted(true)
				setDateSortClass('active asc')
			}
		}
		else {
			if (isSorted) {
				sortedArray = tempArray.sort((a, b) => {
					if (a[sortType] < b[sortType]) {
						return -1;
					}
					if (a[sortType] > b[sortType]) {
						return 1;
					}
					return 0;
				});
				setIsSorted(false)
				sortType === 'tag' ? setTagSortClass('active asc') : setTypeSortClass('active asc')
			} else {
				sortedArray = tempArray.sort((b, a) => {
					if (a[sortType] < b[sortType]) {
						return -1;
					}
					if (a[sortType] > b[sortType]) {
						return 1;
					}
					return 0;
				});
				setIsSorted(true)
				sortType === 'tag' ?  setTagSortClass('active dec') : setTypeSortClass('active dec')
			}
		}
		setActivityList(sortedArray)
		setisResponsiveFilter(false)
	}

	const hidePopUp = () => {
		setIsPopUp(false)
	}
	const handleDeleteButton = () => {
		setIsPopUp(false)
		props.onDelete(activityData)
	}

	const setStartingDate = (date) => {
		setStartDate(date)
		if (date !== null && date.getTime() > endDate.getTime()){
			setEndDate(date)
		}
	}

	const setEndingDate = (date) => {
		// if (date.getTime() > new Date().getTime() || startDate.getTime() > date.getTime()) {
		// 	setEndDate(new Date())
		// } else {
		// 	setEndDate(date)
		// }
		if(new Date(startDate) < new Date(date)) {
			setEndDate(date)
		}
	}

	function viewDetails(activity) {
		props.onView(activity)
	}

	useEffect(   () => {
		async function printObj() {
			let total = 0;
			if (activityList.length > 0) {
				activityList.forEach(activity => {
					const duration = activity.activity_duration.split(':')
					total += parseInt(duration[0] * 60) + parseInt(duration[1])
				});
			}
			let minutes = total % 60;
			if(minutes === 0 || minutes < 10){
				minutes = '0'+minutes;
			}
			let hours = parseInt(total/60) > 9 ? parseInt(total/60) : '0'+parseInt(total/60)
			let totalTime = hours+':'+ minutes  + ' HOURS'
			await setTotalHours(totalTime)

			/*await setPrintObj({
				title: A12Y_CONST.LABEL.ACTIVITY_LABEL,
				total: totalHours,
				startDate: await ddmmyyyyFormat(startDate),
				endDate: await ddmmyyyyFormat(endDate),
				dependentName: props.dependent.first_name + ' ' + props.dependent.last_name,
				advocateName: props.userInfo.first_name + ' ' + props.userInfo.last_name,
				totalBg: 'lblTotalActivity',
				totalColor: 'totalActivity'
			})*/
		}
		printObj();

	}, [activityList, totalHours, startDate, endDate, props.dependent.first_name, props.dependent.last_name,props.userInfo.first_name,props.userInfo.last_name])
	
	const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = (dateFromKeyboard(val)).toString().replace(/ /g, '')
        }
    }

	const showFilter = () => {
		setisResponsiveFilter(!isResponsiveFilter)
	}
	const showCalendar = async () => {
		let tags = tagList
		tags?.forEach((item) => {
			props?.filterData['activity'].tag.forEach((data) => {
				if (data === item.tag) {
					item.isChecked = true;
				}
			})
		})
		setTagList(tags);

		await setSelectedTypeFilter(props?.filterData['activity'].type)
		await setSelectedTagFilter(props?.filterData['activity'].tag)
		setisResponsiveCalendar(!isResponsiveCalendar)
		let arr =[startDatesRef.current, endDatesRef.current]
		readOnlyMobile(arr);
	}
	const hideCalendar_Filter = async (event) => {
		// if(isResponsiveFilter || isResponsiveCalendar){
		// 	setisResponsiveFilter(false)
		// }
		if (refCalendarAction.current && !refCalendarAction.current.contains(event.target)) {
			await filterPopUpClose();
			setisResponsiveCalendar(false)
		}
		if (refFilterAction.current && !refFilterAction.current.contains(event.target)) {
			setisResponsiveFilter(false)
		}
	}
	useEffect(() => {
		document.addEventListener('click', hideCalendar_Filter, true);
		return () => {
			document.removeEventListener('click', hideCalendar_Filter, true);
		};
	});

	var count = 0;
	const printFromBackEnd = async () => {
		const typeFilterResult = props?.filterData['activity'].type
		const tagFilterResult = props?.filterData['activity'].tag
		let res;
		try {
			setErrors(false)
			setErrorMessage('')
			await setLoaderFullScreenVisible(true);
			let stDate = await ddmmyyyyFormat(startDate);
			let	edDate = await ddmmyyyyFormat(endDate);
			let response = await secureClient.post('pdf_report', {"dependent_id" : props.dependent.id, "report_type" : "activity","start_date": stDate,"end_date": edDate, "activity_type_ids":typeFilterResult, "tags":tagFilterResult});
			if(response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK){
				downloadBase64File(response.data.sr.pdf_base64, response.data.file_name)
				await setLoaderFullScreenVisible(false);
			}else{
				 	setErrors(true)
					await setLoaderFullScreenVisible(false);
				// count++
				// if(count <= 3){
				// 	setTimeout(() => {
				// 		console.log('else 200', count)
				// 		printFromBackEnd()
				// 	}, 2000);
				//
				// }else{
				// 	setErrors(true)
				// 	await setLoaderFullScreenVisible(false);
				// 	count = 0;
				// }
			}

		}catch (e) {
			count++
			if(count <= 3){
				printFromBackEnd()
			}else{
				setErrors(true)
				setErrorMessage(A12Y_CONST.ERROR_MESSAGE.NETWORK_ERROR_TRY_AGAIN)
				await setLoaderFullScreenVisible(false);
				count = 0;
			}

		}
	}



	const goToActivityLandingPage = ()=>{
		props.goToActivityLandingPage();
	}



	const onValueChange = async (item, index) => {
		var listactiveTag = [];
		const newData = [...tagList];
		newData[index].isChecked = !item.isChecked;
		await setTagList(newData);
		//setSelectedTagFilter
		tagList.forEach((tag) => {
			if(tag.isChecked === true){
				listactiveTag.push(tag.tag)
			}
		})
	await setSelectedTagFilter(listactiveTag)
	}
	const onSelect = (selectedList, selectedItem) => {
		selectedTypeList(selectedList)
	}
	const onRemove = (selectedList, removedItem) => {
		selectedTypeList(selectedList)
	}

	const selectedTypeList = async (selectedList) => {
		var listactiveType = [];
		selectedList.forEach((type) => {
			listactiveType.push(type.id)
		})
		setSelectedTypeFilter(listactiveType)
		await setTypeFilter(selectedList);
	}

	return (
		<div className='activityMainList'>
			{loaderFullScreenVisible ?
				<LoaderFullScreen />
				:
				null
			}
			{loading ?
				<div className="transparentLayer"></div>
				:
				null
			}
			<h2 className="titleWithSidebar activityTitleMargin overViewLabel responsiveListTitle marginBottom30 activityListError">
				{error ?
					<div className='maxWidth600 marginLeftRightAuto'>
						<ErrorMessageComponent errorMessage={errorMessage} />
					</div>
					:
					(message ?
							<SuccessMessageComponent successMessage={message} />

							:
							null
					)

				}
			<div className='fullWidth600Col activityLog'>
					<span>{A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL}</span>
					<span id={A12Y_CONST.BUTTON_ID.LEARN_MORE+A12Y_CONST.LABEL.ACTIVITY_LABEL} onClick={()=>{goToActivityLandingPage()}} className="titleRightContent2 positionAbsoute">{A12Y_CONST.LABEL.LEARN_MORE}</span>
				</div>
			</h2>
			<div className="padding20 fullWidth responsiveListTableTop responsiveTalbe activityBoxCol">
				{loading ?
					null
					:
					<div className="tableTop tableTopMain df aic">
						<div className="tableTopLeft df aic">
							<span className={'icoWrap ' + (isResponsiveFilter && 'thisActive')}>
								<span onClick={showFilter}
								  className={'a12-icon filterIcon sortIcon notToPrint marginRight10 hideDesktopShowMobile '}>
									<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.6001 1H18.4001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M1.6001 6H13.6001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
										<path d="M1.6001 11H8.8001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
									</svg>
								</span>
								<i></i>
							</span>
							<span id={A12Y_CONST.LABEL.FILTERS+A12Y_CONST.LABEL.ACTIVITY_LABEL} className={'icoWrap filterTextIconColoum ' + (isResponsiveCalendar && 'thisActive')}>
								<span onClick={showCalendar} className={'a12-icon dateIcon'} id='dateIcon'>
									{
										window.innerWidth > 768 ?
											<>
											{
												isFilter?
													<svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
														<path d="M9.00684 3.02734L17.0068 2.99987" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<path d="M1.00684 3.05493L5.00681 3.04119" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<circle cx="7.00688" cy="3.03444" r="2" transform="rotate(89.8032 7.00688 3.03444)" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<path d="M14.0308 10.0103L17.0307 9.99995" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<path d="M1.03076 10.0549L9.03071 10.0275" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<circle cx="12.0308" cy="10.0171" r="2" transform="rotate(89.8032 12.0308 10.0171)" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<path d="M1.05469 17.0549L3.05468 17.0481" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<path d="M9.05469 17.0273L17.0546 16.9999" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
														<circle cx="6.05473" cy="17.0379" r="2" transform="rotate(89.8032 6.05473 17.0379)" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
													</svg>
													:
													<svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
														<path d="M9.00684 3.02734L17.0068 2.99987" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<path d="M1.00684 3.05493L5.00681 3.04119" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<circle cx="7.00688" cy="3.03444" r="2" transform="rotate(89.8032 7.00688 3.03444)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<path d="M14.0308 10.0103L17.0307 9.99995" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<path d="M1.03076 10.0549L9.03071 10.0275" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<circle cx="12.0308" cy="10.0171" r="2" transform="rotate(89.8032 12.0308 10.0171)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<path d="M1.05469 17.0549L3.05468 17.0481" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<path d="M9.05469 17.0273L17.0546 16.9999" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
														<circle cx="6.05473" cy="17.0379" r="2" transform="rotate(89.8032 6.05473 17.0379)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
													</svg>
											}
											</>
										:
											<svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
												<path d="M9.00684 3.02734L17.0068 2.99987" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<path d="M1.00684 3.05493L5.00681 3.04119" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<circle cx="7.00688" cy="3.03444" r="2" transform="rotate(89.8032 7.00688 3.03444)" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<path d="M14.0308 10.0103L17.0307 9.99995" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<path d="M1.03076 10.0549L9.03071 10.0275" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<circle cx="12.0308" cy="10.0171" r="2" transform="rotate(89.8032 12.0308 10.0171)" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<path d="M1.05469 17.0549L3.05468 17.0481" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<path d="M9.05469 17.0273L17.0546 16.9999" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
												<circle cx="6.05473" cy="17.0379" r="2" transform="rotate(89.8032 6.05473 17.0379)" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
											</svg>
									}
									<span className={`filterText ${isFilter?'filterTextActive':''}`}>{A12Y_CONST.LABEL.FILTER}</span>
								</span>
								<i></i>
							</span>
							{/*{isFilter && window.innerWidth > 768 ?*/}
							{/*<span onClick={clearFilter} className={`clearTextFilterColTopRow marginLeft10 ${isFilter?'activeClearButton':''}`}>{A12Y_CONST.LABEL.CLEAR}</span>*/}
							{/*	:*/}
							{/*	null*/}
							{/*}*/}
							<span className={'icoWrap calendarDateCol ' + (isResponsiveCalendar && 'thisActive')}>
								<span className={'a12-icon dateIcon startEndDateReadOnly'}>
									{/*<span className='rangeText'>{A12Y_CONST.LABEL.RANGE}</span>*/}
									<div><span>{A12Y_CONST.LABEL.FROM_COLON}</span> {moment(startDate).format("MMM DD,YYYY")} <span>{A12Y_CONST.LABEL.TO_COLON}</span> {moment(endDate).format("MMM DD,YYYY")} </div>
								</span>
								<i></i>
							</span>
							<span>
								<span onClick={() => printFromBackEnd()} className={`a12-icon printIcon notToPrint marginLeft10 hideDesktopShowMobile ${activityList.length === 0 ? 'clickDisable':''}`}>
									<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
										<path d="M8.13504 9.42846C7.81509 9.12375 7.30871 9.13611 7.004 9.45605C6.69929 9.77599 6.71164 10.2824 7.03159 10.5871L8.13504 9.42846ZM13 15.1665L12.4483 15.7458L13 16.2713L13.5517 15.7458L13 15.1665ZM18.9684 10.5871C19.2883 10.2824 19.3007 9.77599 18.996 9.45605C18.6912 9.13611 18.1849 9.12375 17.8649 9.42846L18.9684 10.5871ZM13.8 4.33317C13.8 3.89134 13.4418 3.53317 13 3.53317C12.5582 3.53317 12.2 3.89134 12.2 4.33317L13.8 4.33317ZM7.03159 10.5871L12.4483 15.7458L13.5517 14.5872L8.13504 9.42846L7.03159 10.5871ZM13.5517 15.7458L18.9684 10.5871L17.8649 9.42846L12.4483 14.5872L13.5517 15.7458ZM13.8 15.1665L13.8 4.33317L12.2 4.33317L12.2 15.1665L13.8 15.1665Z" fill="white"/>
										<path d="M5.41669 17.3335L5.41669 18.4168C5.41669 19.6134 6.38674 20.5835 7.58335 20.5835L18.4167 20.5835C19.6133 20.5835 20.5834 19.6134 20.5834 18.4168V17.3335" stroke="white" strokeWidth="1.6" strokeLinecap="round"/>
									</svg>
								</span>
							</span>
							{isResponsiveCalendar || isResponsiveFilter ?
							<div className="orerlayFilter"></div>
								:
								null
							}
							<div ref={refCalendarAction} className={`dateWrap df aic ${isResponsiveCalendar ? 'calendarDisplayBlock' : null}`}>
								<h3 className='filterByDate'>{A12Y_CONST.LABEL.FILTERS} <span id={A12Y_CONST.BUTTON_ID.CROSS+A12Y_CONST.LABEL.FILTERS} onClick={() => filterPopUpClose()}  className={`clearTextInFilterCol`}><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M6.5 5.05908L11.5558 0L13 1.44117L7.94422 6.5L13 11.5588L11.5558 13L6.5 7.94092L1.44422 13L0 11.5588L5.05578 6.5L0 1.44117L1.44422 0L6.5 5.05908Z" fill="#71767A"/>
</svg></span></h3>
								<div className="fullWidth dateStartEnd">
									<div className="dateStart">
										<div className="txtTo">{A12Y_CONST.LABEL.FROM}</div>
										<DatePicker
											name='startDate'
											ref={startDatesRef}
											selected={startDate}
											onChange={setStartingDate}
											onChangeRaw={(event) => handleChangeRaw(event, 'startDate')}
											onBlur={()=>{
												if(startDate === null){
													setStartDate(new Date(props.dependent.reporting_period.start_date));
												}
											}}
											// onCalendarClose={handleStatDateChange}
											showMonthDropdown
											// onFocus={e => readOnlyOnMobile(e.target)}
											showYearDropdown
											dropdownMode="select"
											onMonthChange={setStartingDate}
											onYearChange={setStartingDate}
											// setYearDate{(e) = setStartingDate(e)}
										/>
									</div>
									<div className="dateEnd">
										<div className="txtTo">{A12Y_CONST.TEXT.to}</div>
										<DatePicker
											name='endDate'
											ref={endDatesRef}
											selected={endDate}
											minDate={startDate}
											onChange={setEndingDate}
											onChangeRaw={(event) => handleChangeRaw(event, 'endDate')}
											onBlur={()=>{
												if (endDate === null){
													setEndDate(new Date(props.dependent.reporting_period.end_date))
												}
											}}
											// onCalendarClose={handleEndDateChange}
											showMonthDropdown
											showYearDropdown
											// onFocus={e => readOnlyOnMobile(e.target)}
											dropdownMode="select"
											onMonthChange={setEndingDate}
											onYearChange={setEndingDate}
										/>
									</div>
								</div>
								<div className='fullWidth typeTitleFilter'>
									<div className="txtTo fullWidth">
										{A12Y_CONST.LABEL.TYPE}
									</div>
									<div className="fullWidth typeListFilter padding20">
										<Multiselect
											options={props?.activityTypeList} // Options to display in the dropdown
											selectedValues={typeFilter} // Preselected value to persist in dropdown
											onSelect={onSelect} // Function will trigger on select event
											onRemove={onRemove} // Function will trigger on remove event
											displayValue="title" // Property name to display in the dropdown options
											showCheckbox
											avoidHighlightFirstOption={false}
											value={props.activityTypeList}
										/>

									</div>
								</div>
								<div className="tagFilterColum marginTop20 marginBottom15 fullWidth">
									<div className="txtTo tagTitleFilter fullWidth">
										{A12Y_CONST.LABEL.TAG}
									</div>
									{tagList.map((obj, index) => (
										tagList.length-1 !== index ?
											<label key={index}>
												<input
													title={obj.name}
													className={obj.isChecked?'checked':''}
													checked={obj.isChecked || false}
													onChange={(val) => onValueChange(obj, index)}
													key={obj.name}
													type='checkbox'
												/>
											</label>
										:
										/*<Tooltip color={'#112e53'} className={'overlayFilterToolTip'} placement="right" style={{borderRadius:'4px',}} title={<><span className='cursorPointer' style={{fontSize: '14px', color:'white', lineHeight:'21px', padding: '5px', fontWeight: 400}}>{A12Y_CONST.LABEL.NO_TAG_WAS_ADDED}</span></>}>
										<label key={index}
											   onMouseEnter={() => document.getElementsByTagName('body')[0].classList.add("onTagHover")}
											   onMouseLeave={() => document.getElementsByTagName('body')[0].classList.remove("onTagHover")}
										>
											<input
												id='noTagCol'
												title={obj.name}
												className={obj.isChecked?'checked':''}
												checked={obj.isChecked || false}
												onChange={(val) => onValueChange(obj, index)}
												key={obj.name}
												type='checkbox'
											/>
											<span className='addressTooltip'>{A12Y_CONST.LABEL.NO_TAG_WAS_ADDED}</span>
										</label>
										</Tooltip>*/
											<label key={index}
												   className='positionRelative'
												// onMouseEnter={() => document.getElementsByTagName('body')[0].classList.add("onTagHover")}
												// onMouseLeave={() => document.getElementsByTagName('body')[0].classList.remove("onTagHover")}
											>
												<input
													id='noTagCol'
													title={obj.name}
													className={obj.isChecked?'checked':''}
													checked={obj.isChecked || false}
													onChange={(val) => onValueChange(obj, index)}
													key={obj.name}
													type='checkbox'
												/>
												<span className='tagTooltip'>{A12Y_CONST.LABEL.NO_TAG_WAS_ADDED}</span>
											</label>

									))}
								</div>
								<div className='marginLeft10 filterButtonRow fullWidth dateColButonRow'>
									<button id={A12Y_CONST.LABEL.FILTERS+A12Y_CONST.LABEL.CLEAR+A12Y_CONST.LABEL.ACTIVITY_LABEL} className={`btn-outline btn-md saveButton filterCancelButton ${isFilter?'buttonActive':''}`} onClick={clearFilter} >{A12Y_CONST.LABEL.CLEAR}</button>
									<button id={A12Y_CONST.LABEL.FILTERS+A12Y_CONST.LABEL.APPLY+A12Y_CONST.LABEL.ACTIVITY_LABEL} className={`btn-outline btn-md saveButton filterButton ${isFilterUpdated ? 'applybuttonActive':''}`} onClick={() => filterActivity(startDate, endDate, selectedTypeFilter, selectedTagFilter)}>{A12Y_CONST.BUTTON_TEXT.APPLY_SMALL}</button>
								</div>
							</div>
							{isResponsiveFilter &&
							<div ref={refFilterAction}
								 className={`filterDorpDownMain hideDesktopShowMobile ${isResponsiveFilter ? 'displayBlock' : null}`}>
								<h3>{A12Y_CONST.LABEL.SORT_BY}</h3>
								<ul>
									<li><span onClick={() => sortBy('date_of_activity')}
											  className={`${dateSortClass} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.DATE}
										<i className="arrow down"></i></span></li>
									<li><span onClick={() => sortBy('activity_type')}
											  className={`${typeSortClass} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.TYPE}
										<i className="arrow down"></i></span></li>
									<li><span onClick={() => sortBy('tag')}
											  className={`${tagSortClass} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.TAG}
										<i className="arrow down"></i></span></li>
								</ul>
							</div>
							}
						</div>
						<div className="tableTopRight df mla">
							<span
								className="lblTotal marginRight20 df aic jcc">{A12Y_CONST.TEXT.TOTAL}{A12Y_CONST.TEXT.COLON}
								<span className="marginLeft10 totalActivity">{totalHours}</span></span>
							<button id={A12Y_CONST.BUTTON_ID.DOWNLOAD_ACTIVITY} disabled={activityList.length === 0 ? true:false} className="btn-outline saveButton marginRight20 printButton displayNone600"
									onClick={() => printFromBackEnd()}>{A12Y_CONST.TEXT.DOWNLOAD}</button>
							{props.dependent?.access && props.dependent.access.can_update === true ?
								<button id={A12Y_CONST.BUTTON_ID.ADD_ACTIVITY} className="btn-primary saveButton plusIcon" onClick={addActivity}>
									<span>{A12Y_CONST.TEXT.PLUS_SYMBOL}</span> <span
									className='displayNone600'>{A12Y_CONST.TEXT.ACTIVITY}</span></button>
								:
								null
							}
						</div>
					</div>
				}
				<div className="tableBody responsiveTalbe activityTableTitle marginTop20" id='Printable'>
					{
						loading ?
							<div className='noData df aic jcc fcol'>
								<div className="positionRelative">
									<div className="dependentLoader">
										<div className='fullWidth justifyCenter marginBottom10'>
											<div className="stage">
												<div className="dot-pulse"></div>
											</div>
										</div>
									</div>
									<div className='marginTop20'>
										<span>{A12Y_CONST.TEXT.LOADING_ACTIVITY}</span>
									</div>
								</div>
							</div>
							:
							(
								activityList.length === 0 ?
									<div className='noData df aic jcc fcol'>
										<strong>{A12Y_CONST.TEXT.NO_DATA_FOUND}</strong>
										<p>{A12Y_CONST.TEXT.NO_DATA_FOUND_MESSAGE}</p>
									</div>
									:
									<table cellSpacing="0" cellPadding="0" border="0"  className={`fullWidth ${activityList.length === 0 ? 'notToPrint' : ''}`} id="TableContent">
										<thead className='mt20'>
										<tr>
											<th className='tagCol'><span onClick={() => sortBy('tag')} className={`${tagSortClass} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.TAG} <i className="arrow down"></i></span></th>
											<th className='dateCol'><span onClick={() => sortBy('date_of_activity')} className={`${dateSortClass} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.DATE} <i className="arrow down"></i></span></th>
											<th className='typeCol'><span onClick={() => sortBy('activity_type')} className={`${typeSortClass} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.TYPE.toUpperCase()} <i className="arrow down"></i></span></th>
											<th className='noteCole tal'>{A12Y_CONST.LABEL.DESCRIPTION}</th>
											<th className='durCol tar-print'>{A12Y_CONST.LABEL.DURATION}</th>
											<th className='actCol notToPrint'></th>
										</tr>
										</thead>
										<tbody>
										{activityList.map((item, i) => {
											return (
												<tr key={i}>
													<td><span onClick={() => viewDetails(item)} className="df aic cursorPointer height22">
											{/* <i className={`tag tag-${item.tag}`}><span></span></i> */}
														{
															item.tag === 1 ?
																<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<g filter="url(#filter0_d_8727_59801)"><circle cx="12" cy="12" r="11" fill="#D96941"/></g><defs><filter id="filter0_d_8727_59801" x="0" y="0.000289917" width="24" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feMorphology radius="14" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_8727_59801"/><feOffset dx="8" dy="24"/><feGaussianBlur stdDeviation="26.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8727_59801"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8727_59801" result="shape"/></filter></defs>
																</svg>
																: item.tag === 2 ?
																<svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M13 0.000289917L25.1244 21.7503H0.875645L13 0.000289917Z" fill="#2D6CB2" fillOpacity="0.99"/>
																</svg>
																: item.tag === 3 ?
																	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<rect y="11.0003" width="15.5568" height="15.5568" transform="rotate(-45 0 11.0003)" fill="#F2A950"/>
																	</svg>
																	: item.tag === 4 ?
																		<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M12 0.000289917L14.6942 8.29209H23.4127L16.3593 13.4167L19.0534 21.7085L12 16.5839L4.94658 21.7085L7.64074 13.4167L0.587322 8.29209H9.30583L12 0.000289917Z" fill="#99D98C"/>
																		</svg>
																		: null
														}
											</span>
													</td>
													<td><span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer"> {item.date_of_activity ? item.date_of_activity : null}</span></td>
													<td>
														<div className='tagDescripton_600'>
															<span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer noWrap"> {item.activity_type === A12Y_CONST.LABEL.ACTIVITY_ON_BEHALF_OF_DEPENDENT ? A12Y_CONST.LABEL.ON_BEHALF_OF : A12Y_CONST.LABEL.WITH_DEPENDENT }</span>
															<span onClick={() => viewDetails(item)} className="hideDesktopShowMobile notToPrint tagIcon paddingLeft1">
											{/* <i className={`tag tag-${item.tag}`}><span></span></i> */}
																{
																	item.tag === 1 ?
																		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<circle cx="11" cy="11" r="11" fill="#D96941"/>
																		</svg>
																		: item.tag === 2 ?
																		<svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M13 0.000289917L25.1244 21.7503H0.875645L13 0.000289917Z" fill="#2D6CB2" fillOpacity="0.99"/>
																		</svg>
																		: item.tag === 3 ?
																			<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<rect y="11.0003" width="15.5568" height="15.5568" transform="rotate(-45 0 11.0003)" fill="#F2A950"/>
																			</svg>
																			: item.tag === 4 ?
																				<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path d="M12 0.000289917L14.6942 8.29209H23.4127L16.3593 13.4167L19.0534 21.7085L12 16.5839L4.94658 21.7085L7.64074 13.4167L0.587322 8.29209H9.30583L12 0.000289917Z" fill="#99D98C"/>
																				</svg>
																				: null
																}
											</span>
														</div>
													</td>
													<td className='ellipsis'><span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer"> {item.descriptions}</span></td>
													<td className="blackText tar-print"><span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer nowrap tar-print"> {item.activity_duration}</span></td>
													<td className="tac tdAction textAlignRight notToPrint">
														{props.dependent?.access && props.dependent.access.can_update === true
															?
															<div className='activityButonAction'>
													<span id={A12Y_CONST.LABEL.ACTIVITY_LABEL+A12Y_CONST.TEXT.EDIT+item.id} className="marginRight10" onClick={() => editActivity(item)}>
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M13.293 0.292875C13.683 -0.097625 14.317 -0.097625 14.707 0.292875L18.707 4.29287C19.098 4.68337 19.098 5.31657 18.707 5.70707L5.707 18.7071C5.52 18.8946 5.265 19 5 19H1C0.4477 19 0 18.5523 0 18V14C0 13.7348 0.1054 13.4804 0.2929 13.2929L10.293 3.29307L13.293 0.292875ZM11 5.41417L2 14.4142V17H4.586L13.586 7.99997L11 5.41417ZM15 6.58577L16.586 4.99997L14 2.41417L12.414 3.99997L15 6.58577Z" fill="#2D6CB2" />
														</svg>
													</span>
																<span id={A12Y_CONST.LABEL.ACTIVITY_LABEL+A12Y_CONST.TEXT.DELETE+item.id} className="marginLeft10 deleteIcon" onClick={() => deleteActivity(item)}>
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M5 2C5 0.8954 5.89543 0 7 0H13C14.1046 0 15 0.8954 15 2V4H16.9897C16.9959 3.9999 17.0021 3.9999 17.0083 4H19C19.5523 4 20 4.4477 20 5C20 5.5523 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.189 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.189 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.5523 0 5C0 4.4477 0.44772 4 1 4H2.99174C2.99795 3.9999 3.00414 3.9999 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#D96941" />
														</svg>
													</span>

																<span onClick={() => viewDetails(item)}  className="marginLeft10 ViewIcon">
													<img src={viewIcon} alt='View Icon'/>
												</span>
															</div>
															:
															null
														}
													</td>
												</tr>
											)
										})}
										</tbody>
										<tfoot><tr><td><div className="footer-space">&nbsp;</div></td></tr></tfoot>
									</table>

							)
					}
				</div>
			</div>
			<PopUpIncomeExpComponent
				popUpStatus={isPopUp}
				title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
				message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
				buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
				buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
				hidePopUp={hidePopUp}
				handleDeleteButton={handleDeleteButton}
			/>
		</div>
	)
}

export default ActivityListComponent
