import React,{useEffect} from 'react';
import HeaderColumn from './HeaderColumnComponent'
import A12Y_CONST from "../common/a12yConst";
import {titleRoutePath} from "../util/titleRouteNamePath";
const LayoutWithoutLogin =  (props) => {

    useEffect( ()=>{
        const routingPath = async ()=>{
            let path = window.location.pathname;
            let title = await titleRoutePath(path)
            document.title =A12Y_CONST.TEXT.ADVOCORD+title;
        }
        routingPath()
    },[window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <HeaderColumn/>
            {props.children}
        </div>
    )
}


export default LayoutWithoutLogin