import React, { useState, useEffect}from 'react';
import A12Y_CONST from "../common/a12yConst";
import parsePhoneNumber from "libphonenumber-js";

function  PopUpWelcomeAdvocord (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [buttonId, setButtonId] = useState('');
    const [mobileNumberFormat, setMobileNumberFormat] = useState('');
    const [alternateNumberFormat, setAlternateNumberFormat] = useState('');

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const hidePopUp = () => {
      props.hidePopUp()
    }
//    const closePopUP = () => {
//      props.closePopUP()
//    }

    useEffect(()=>{
        if(props.popUpType === A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD){
            setTitle(A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD)
            props?.dependent?.access?.can_update ? setMessage(A12Y_CONST.LABEL.YOU_HAVE_30_DAYS) :setMessage(A12Y_CONST.LABEL.YOU_HAVE_BEEN_GIVEN_VIEW)
            props?.dependent?.access?.can_update? setButtonText(A12Y_CONST.BUTTON_TEXT.CONTINUE): setButtonText(A12Y_CONST.BUTTON_TEXT.GET_STARTED)
            setButtonId(A12Y_CONST.BUTTON_ID.FREE_TRIAL)
        }
        if(props.popUpType === A12Y_CONST.LABEL.TRIAL_HAS_EXPIRED || props.popUpType === A12Y_CONST.LABEL.PURCHASED_PLAN_EXPIRED){
            props.popUpType === A12Y_CONST.LABEL.TRIAL_HAS_EXPIRED ? setTitle(A12Y_CONST.LABEL.YOUR_30_DAY_TRIAL) : setTitle(A12Y_CONST.LABEL.YOUR_PLAN_EXPIRED)
            props.dependent?.access && props.dependent.access.can_update !== true ? setMessage(`${A12Y_CONST.TEXT.PLEASE_CONTACT + ' ' + props.dependent.advocate.first_name + ' ' + props.dependent.advocate.last_name} ${A12Y_CONST.TEXT.TO_ACTIVE_ACCOUNT}`): setMessage(A12Y_CONST.LABEL.ALL_YOUR_ACCOUNTS_DETAILS);
            setButtonText(A12Y_CONST.BUTTON_TEXT.SUBSCRIBE)
            props.popUpType === A12Y_CONST.LABEL.TRIAL_HAS_EXPIRED ? setButtonId(A12Y_CONST.BUTTON_ID.Trial_Expired) : setButtonId(A12Y_CONST.BUTTON_ID.Trial_Expired)
        }

    },[title,message, props.popUpType, props?.dependent?.advocate?.first_name, props?.dependent?.advocate?.last_name, props.dependent.access, props.userInfo.first_name, props.userInfo.last_name])

    useEffect (  () => {
        // console.log('props.dependent.phones[0]', props.userInfo.phones[0])
        // console.log('props.userInfo.phones[0].number', props.userInfo.phones[0].number)
        // if(props?.userInfo?.address) delete props.userInfo.address['country_code']
        if(props.dependent?.advocate?.phones !== null && props.dependent?.advocate?.phones?.length > 0 && props.dependent?.advocate?.phones[0].type !== "" && props.dependent?.advocate?.phones[0].number.length > 1 ){
            const mobileNum = parsePhoneNumber('+'+props.dependent?.advocate?.phones[0].number);
            const mobileNumberFormating = '+1 ' + mobileNum.formatNational()
            setMobileNumberFormat(mobileNumberFormating)
        }
        if(props.dependent?.advocate?.phones !== null && props.dependent?.advocate?.phones?.length > 0 && props.dependent?.advocate?.phones[1].type !== "" && props.dependent?.advocate?.phones[1].number.length > 1 ){
            const alternateNum = parsePhoneNumber('+'+props.dependent?.advocate?.phones[1].number);
            const altNumberFormating = '+1 ' + alternateNum.formatNational()
            setAlternateNumberFormat(altNumberFormating)
            // console.log('alter numbe=========', alternateNumberFormat)
        }


    },[props.userInfo, props.dependent?.advocate?.phones])

    // const handleDeleteButton = () => {
    // //    props.handleDeleteButton()
    // }
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className={`popTop ${props.popUpType === A12Y_CONST.LABEL.TRIAL_HAS_EXPIRED || props.popUpType === A12Y_CONST.LABEL.PURCHASED_PLAN_EXPIRED ?'popUpWidthTrialEnd':''}`}>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                   {/* {props.popUpType === A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD && props?.dependent?.access?.can_update?
                                    <div className="fullWidth positionRelative textAlignRight">
                                        <svg className='cursorPointer closePopUpIcon' width="17" height="17" onClick={closePopUP} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.5 6.61572L15.1114 0L17 1.88461L10.3886 8.5L17 15.1154L15.1114 17L8.5 10.3843L1.88859 17L0 15.1154L6.61141 8.5L0 1.88461L1.88859 0L8.5 6.61572Z" fill="#71767A"/>
                                        </svg>
                                    </div>
                                        :
                                        null
                                    }*/}
                                    <div className="cancelIcon fullWidth marginTopBottom20">
                                        {props.popUpType === A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD ?
                                            <svg width="92" height="92" viewBox="0 0 92 92" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M25 46.7111L39.25 60L63 34"
                                                      stroke="url(#paint0_linear_9983_64252)" strokeWidth="5"
                                                      strokeLinecap="round"/>
                                                <path d="M25 46.7111L39.25 60L63 34"
                                                      stroke="url(#paint1_linear_9983_64252)" strokeOpacity="0.2"
                                                      strokeWidth="5" strokeLinecap="round"/>
                                                <circle cx="46" cy="46" r="38.5" stroke="url(#paint2_linear_9983_64252)"
                                                        strokeWidth="5"/>
                                                <path d="M25 46.7111L39.25 60L63 34"
                                                      stroke="url(#paint3_linear_9983_64252)" strokeOpacity="0.18"
                                                      strokeWidth="5" strokeLinecap="round"/>
                                                <path d="M25 46.7111L39.25 60L63 34"
                                                      stroke="url(#paint4_linear_9983_64252)" strokeOpacity="0.2"
                                                      strokeWidth="5" strokeLinecap="round"/>
                                                <circle cx="46" cy="46" r="43.5" stroke="url(#paint5_linear_9983_64252)"
                                                        strokeOpacity="0.18" strokeWidth="5"/>
                                                <defs>
                                                    <linearGradient id="paint0_linear_9983_64252" x1="25" y1="34"
                                                                    x2="51.099" y2="68.8999"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_9983_64252" x1="30.4522"
                                                                    y1="37.5455" x2="48.8091" y2="66.2274"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_9983_64252" x1="5" y1="5"
                                                                    x2="90.474" y2="83.203"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_9983_64252" x1="25" y1="34"
                                                                    x2="51.099" y2="68.8999"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint4_linear_9983_64252" x1="30.4522"
                                                                    y1="37.5455" x2="48.8091" y2="66.2274"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint5_linear_9983_64252" x1="5" y1="5"
                                                                    x2="90.474" y2="83.203"
                                                                    gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82"
                                                 viewBox="0 0 82 82" fill="none">
                                                <rect x="37.1328" y="19.3396" width="7.73585" height="31.717"
                                                      fill="#E85555"/>
                                                <circle cx="40.9999" cy="60.3395" r="4.64151" fill="#E85555"/>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M41 82C63.6437 82 82 63.6437 82 41C82 18.3563 63.6437 0 41 0C18.3563 0 0 18.3563 0 41C0 63.6437 18.3563 82 41 82ZM40.9996 75.0378C59.7982 75.0378 75.0374 59.7986 75.0374 41.0001C75.0374 22.2016 59.7982 6.96235 40.9996 6.96235C22.2011 6.96235 6.96191 22.2016 6.96191 41.0001C6.96191 59.7986 22.2011 75.0378 40.9996 75.0378Z"
                                                      fill="#E85555"/>
                                            </svg>
                                        }
                                    </div>
                                    <div className={`areYouText areYouDmFont ${props.popUpType === A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION?'fontWeight400':null}`}>
                                        {title}
                                    </div>
                                        <div className={'useInfo marginTop10 maxWidth340'}>
                                            {message}
                                        </div>
                                    {
                                        props.dependent?.access && props.dependent.access.can_update !== true && props.popUpType !== A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD?
                                   <>
                                    <div className={'useInfo paddingTop5'}>
                                        {props.dependent?.advocate?.phones !== null && props.dependent?.advocate?.phones.length > 0 && props.dependent?.advocate?.phones.map((phone,key)=>(
                                            phone.type !== "" && phone.number !== ''?
                                                <div key={key} className="fullWidth">
                                                    {/*<span className="geneInfo_label">{phone.type !== null && phone.type === 'office'? A12Y_CONST.LABEL.OFFICE : phone.type === 'home' ? A12Y_CONST.LABEL.HOME : phone.type === 'mobile' ? A12Y_CONST.LABEL.MOBILE : A12Y_CONST.LABEL.OTHER }</span>*/}
                                                    <span className="texAlingCener fontSize16 geneInfo_value">{key === 0 ? mobileNumberFormat : alternateNumberFormat}</span>
                                                    {/*<span className="geneInfo_value">{props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[0].type !="" && props.dependent.phone[0].number.length > 1?  mobileNumberFormat : '-'}</span>*/}
                                                </div>
                                                : null
                                        ))}
                                    </div>
                                   <div className={'paddingTop5 fullWidth texAlingCener paddingTop5'}>
                                       <span className="geneInfo_value fontSize16">{props.dependent?.advocate?.email? props.dependent?.advocate?.email:'' }</span>
                                    </div>
                                    </>
                                        :
                                    null
                                    }

                                    <div className="fullWidth marginTop45 marginBottom20 textAlignRight">
                                        {((props.dependent?.access && props.dependent.access.can_update) || props.popUpType === A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD) ?
                                        <div id={buttonId} className=" popUpNoButton" onClick={hidePopUp}>{buttonText}</div>
                                            :
                                            null
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpWelcomeAdvocord;