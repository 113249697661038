import React, {useEffect, useRef, useState} from 'react';
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import { connect } from 'react-redux';
import A12Y_CONST from '../common/a12yConst'
import { withRouter } from "react-router";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function SignUpUploadImageComponent (props) {
    const [imageLoaded, setImageLoaded]=React.useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    /* eslint-disable no-unused-vars */
    const [error, setError] = useState(false);
    const [sameUserLoggedIn, setSameUserLoogedIn] = useState(false);
    /* eslint-disable no-unused-vars */
    const [loading, setLoading] = useState(false);
    const [src, setSrc] = useState("");
    const [crop, setCrop] = useState({
        unit: 'px', width: 144, height: 144, aspect: 8 / 8
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const [doneButtonDisable, setDoneButtonDislabe] = useState(false)
    const [imageRef, setImageRef] = useState("");
    const [name, setName] = useState("");
    const [isOverlay, setIsOverLay] = useState(false);
    const [isDone, setIsDone] = useState(true);
    const [uploadIcon, setUploadIcon] = useState(true);
    const [imageOptionVisible, setImageOptionVisible] = useState(false)
    const {formState:{isSubmitting}} = useForm({mode:"onBlur"});
    const refPhotoAction = useRef(null);


    useEffect( () => {
        setLoading(props.loading)
        setError(props.errorMessage)
        // if(props.errorMessage){
        //     setIsBtnDisabled(false);
        // }
    }, [props.loading,props.errorMessage])

    useEffect( () => {
        if(props.dependent.profile_pic_url){
            setUploadIcon(false)
            setIsDone(true)
            setCroppedImageUrl(props.dependent.profile_pic_url)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    },[])

    const togglePasswordVisibility = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const newUserLogin = () => {
        setSameUserLoogedIn(false);
    }
    const goToSignUpScreen = () => {
        props.history.push(A12Y_CONST.ROUTE.SIGNUP);
    }
    const onSelectFile = (e) => {
        setImageLoaded(false)
        if (e.target.files && e.target.files.length > 0) {
            props.isPopupOpen(true)
            setName(e.target.files[0].name)
            setUploadIcon(false)
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrc(reader.result)
                setIsDone(false)
                props.overlay(true);
                setIsOverLay(true)
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    const onImageLoaded = (image) => {
        // setCrop({ x: 150, y: 100, width: 144, height: 144, aspect: 8/8})
        setCrop({
            //unit: '%',
            width: 144,
            height: 144,
            x: image.width/2-72,
            y: image.height/2-72,
            aspect: 8/8})
        setImageRef(image);

        return false;
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setCrop(crop);
    };
    const cropingDone = async(Crop, percentCrop) => {
        setLoading(true);
        setImageOptionVisible(false)
        // await makeClientCrop(crop);
        let croppedImageUrl1 = null;
        let cropedUrl = null;
        if (imageRef && crop.width && crop.height) {
            croppedImageUrl1 = await getCroppedImg(
                imageRef,
                crop,
                name
            );
            if(croppedImageUrl1!= null){
                const reader = new FileReader();
                await reader.readAsDataURL(croppedImageUrl1);
                reader.addEventListener('loadend', async () =>{
                        cropedUrl = reader.result;
                       await props.onImageUpload(croppedImageUrl1,props.dependent.advocate_id,props.dependent.id,cropedUrl,props.dependent)
                       // const resp = await uploadImage(croppedImageUrl1,props.dependent.advocate_id,props.dependent.id,cropedUrl,props.dependent)
                        setIsDone(true);
                        setLoading(false);
                        props.overlay(false);
                        setIsOverLay(false)
                        props.isPopupOpen(false)
                        setCroppedImageUrl(reader.result)
                       // resp == null && setError(true)
                    }
                );
                //  setIsDone(true);
                //  setLoading(false);
                //  props.overlay(false);
                //  setIsOverLay(false)
                //  props.isPopupOpen(false)
            }
        }
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });


    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl1 = await getCroppedImg(
                imageRef,
                crop,
                name
            );
            const reader = new FileReader();
            reader.readAsDataURL(croppedImageUrl1);
            reader.addEventListener('loadend', () =>
                setCroppedImageUrl(reader.result )
            );
        }
        if(crop.width === 0){
            setDoneButtonDislabe(true)
        }else{
            setDoneButtonDislabe(false)
        }
    }

    const getCroppedImg = async (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        return;
                    }
                    blob.name = fileName;
                    //window.URL.revokeObjectURL(fileUrl);
                    //setFileUrl(window.URL.createObjectURL(blob));
                    resolve(blob);
                },
                'image/jpeg',
                1
            );
        });
    }
    const onCancel = async()=>{
        props.overlay(false);
        setIsOverLay(false)
        props.isPopupOpen(false)
        if(props.dependent.profile_pic_url === null || props.dependent.profile_pic_url === undefined){
            if(Object.keys(props.profileObj).length > 0 && (Object.keys(props.dependent).length === 0 || (Object.keys(props.dependent).length > 0 && props.dependent.profile_pic_url === undefined))){
                setSrc("")
                setIsDone(true)
                setCroppedImageUrl(props.profileObj.cropedUrl)
            }else {
                setUploadIcon(true)
                setSrc("")
            }
        }else {
            setSrc("")
            setIsDone(true)
            setCroppedImageUrl(props.dependent.profile_pic_url)
        }
        setDoneButtonDislabe(false)
        setImageOptionVisible(false)
    }
    const showImageOptions = () => {
        setImageOptionVisible(!imageOptionVisible);
        props.overlay(true);
        setIsOverLay(true);
    }
    const handleUploadNow = () => {
        setUploadIcon(true);
        setImageOptionVisible(false);
        setCroppedImageUrl('')
        props.overlay(false);
        setIsOverLay(false)
    }
    const handleRemove = () => {
        setUploadIcon(true);
        setImageOptionVisible(false);
        props.removeImageCol();
        setCroppedImageUrl('');
        props.overlay(false);
        setIsOverLay(false)
    }

    const outerSideClick = ()=>{
        if(imageOptionVisible){
            setImageOptionVisible(false)
        }
    }

    // Hide Opened Element on clicking outside
    const handleClickOutside = (event) => {
        if (refPhotoAction.current && !refPhotoAction.current.contains(event.target)) {
            setImageOptionVisible(false)
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <div className='imageUploadMain alignCenterVH'>
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            {uploadIcon ?
                <div className="signUpImageUploadCol">
                    <label className="SignUp-custom-file-upload">
                        <input id="fileBrowse" type="file" accept="image/*"  onChange={onSelectFile}/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144" fill="none">
                            <circle cx="72" cy="72" r="72" fill="#E2EDF8"/>
                            <circle cx="71.9999" cy="72.0008" r="63.4286" fill="#A1C4E7"/>
                            <path d="M100.694 100.305C101.284 100.182 101.633 99.569 101.405 99.0115C99.4978 94.3457 95.7962 90.2359 90.7842 87.2447C85.3952 84.0285 78.7923 82.2852 71.9996 82.2852C65.2069 82.2852 58.604 84.0285 53.2149 87.2447C48.2029 90.2359 44.5014 94.3457 42.5937 99.0115C42.3657 99.5689 42.7154 100.182 43.305 100.305L63.8387 104.584C69.2214 105.706 74.7777 105.706 80.1604 104.584L100.694 100.305Z" fill="#F1F8FF"/>
                            <ellipse cx="71.9998" cy="58.2854" rx="17.1429" ry="17.1429" fill="#F1F8FF"/>
                        </svg>
                        <span className='imgPlusIconUpload'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <circle cx="10" cy="10" r="10" fill="black" fillOpacity="0.48"/>
                                <path d="M10 5C10.345 5 10.625 5.27981 10.625 5.625V9.375H14.375C14.72 9.375 15 9.65481 15 10C15 10.3452 14.72 10.625 14.375 10.625H10.625V14.375C10.625 14.7202 10.345 15 10 15C9.655 15 9.375 14.7202 9.375 14.375V10.625H5.625C5.28 10.625 5 10.3452 5 10C5 9.65481 5.28 9.375 5.625 9.375H9.375V5.625C9.375 5.27981 9.655 5 10 5Z" fill="white"/>
                                </svg>
                            </span>
                    </label>
                    <div className='avtarTextCol'>
                        {/*<span>{A12Y_CONST.LABEL.CLLICK_ON_AVATOR_TO_ADD}</span>*/}
                        <span>{A12Y_CONST.LABEL.UPLOAD_A_PHOTO_OF}</span>
                    </div>
                </div>
                :
                null
            }
            <div className='fullWidth'>
                <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} />
                </div>
                {!isDone && src ? (
                    <div className={ isOverlay ? 'imageCropBoxMain' :'imageCropBoxMain'}>
                        <div className='imgCropBox'>
                            <div className='imgCropBoxInner'>
                                <h2 className='popUpImageTitle'>{A12Y_CONST.LABEL.DEPENDENT_PROFILE_PHOTO}</h2>
                                <div className='popUpBoxCol'>
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        zoom={1}
                                        scale={1}
                                        circularCrop={true}
                                        onImageLoaded={onImageLoaded}
                                        onComplete={onCropComplete}
                                        onChange={onCropChange}
                                    />
                                </div>
                                <div className="textAlignRight displayFlexCol imageCropButtonRow marginTop50" >
                                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.SIGNUP_LOADING_IMAGE_CANCEL} className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>
                                    <div className='widthAuto marginLeft20'>
                                        <div className='loaderSpinner'>
                                            {loading ?
                                                <Loading />
                                                :
                                                null
                                            }
                                            <input type="button" value={A12Y_CONST.BUTTON_TEXT.SAVE} className={loading || doneButtonDisable ? 'doneButtonDisable':'doneButton'} disabled={doneButtonDisable}  onClick={cropingDone} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ):<div></div>}
                {isDone && croppedImageUrl && (
                    <div className='profileImageBox'>
                        <div className='profileImageCol overflowHidden' onClick={showImageOptions}>
                            <label className='SignUp-custom-file-uploadEditComponent'>
                                {!imageLoaded && (
                                    <div className="picLoader">
                                        <div className='fullWidth justifyCenter marginBottom10' style={{height:15}}>
                                            <div className="stage">
                                                <div className="dot-pulse"></div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <img
                                        onLoad={()=>{setImageLoaded(true)}}
                                        alt="Crop" src={croppedImageUrl} />
                                </div>
                            </label>
                        </div>
                        <div className="dependentText">
                            {/*<div>{A12Y_CONST.LABEL.UPLOAD_A_PHOTO_OF}</div>*/}
                            {/*<div onClick={handleRemove} className='removeText' >{A12Y_CONST.LABEL.REMOVE}</div>*/}
                        </div>
                        <div className='positionRelative'>
                            {imageOptionVisible ?
                                <div className="positionAbsoute width268Center" ref={refPhotoAction}>
                                    <div className='imageOptionList'>
                                        <ul>
                                            <li>
                                                <label onChange={onSelectFile}>
                                                    <input id="fileBrowse" accept="image/*"  type="file" onChange={onSelectFile}/>{A12Y_CONST.TEXT.UPLOAD_PHOTO}
                                                </label>
                                            </li>
                                            <li onClick={handleRemove} >{A12Y_CONST.LABEL.REMOVE_PHOTO}</li>
                                        </ul>
                                    </div>
                                </div>
                                :
                                null}
                            {/* <div className='avtarTextCol'><span>{A12Y_CONST.LABEL.PHOTO_HAS_UPDATED}</span></div> */}
                        </div>

                    </div>
                )}
            </div>

        </div>

    );
}


export default withRouter (connect(null,null) (SignUpUploadImageComponent));
