import React, { Component } from 'react';
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import {secureClient} from "../config/axiosClient";
import  * as userDetailActions from '../redux/actions/userDetailActions'
import AddCoOwnerComponent from '../components/AddCoOwnerComponent';

class CoOwnerScreen extends Component {
    constructor(props){
        super(props)

        this.state = {
			loading: false,
			error:false,
			message:'',
			errorMessage:'',
			loaderFullScreenVisible : false,
			isClickedOutside:false,
			isAssetPopUp:false,
            successMessage:'',
            isComplete:false,
            editMode:false,
            coOwnerDetails:{}
        }
    }

    componentDidMount = async () => {
        this.setState({editMode:false,coOwnerDetails:{}})
        if(this.props.location && this.props.location?.state && this.props.location.state?.co_owner){
            this.setState({editMode:true,coOwnerDetails:this.props.location.state.co_owner})
        }
    }

    onSubmitHandler = async (obj)=>{
        if(Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.id){
            this.setState({loading:true})
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+this.props.currentAsset.id+'/'+A12Y_CONST.API_ENDPOINT.OWNERS, obj);

            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
                await this.props.dataChanged(false)
                this.setState({loading:false,message:'Owners added successfully'})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,institutesLoader:false})
            }
        }
    }

    onUpdateHandler = async (obj)=>{
        if(Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.id){
            this.setState({loading:true})
            let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+this.props.currentAsset.id+'/'+A12Y_CONST.API_ENDPOINT.OWNERS+'/'+ this.state.coOwnerDetails.id,obj);
            
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
                await this.props.dataChanged(false)
                this.setState({loading:false,message:'Owners updated successfully'})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,institutesLoader:false})
            }
        }
    }
    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }
    render() {
        const {loading,error,errorMessage,editMode,coOwnerDetails} = this.state
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                    {
                        <AddCoOwnerComponent 
                            loading={loading}
                            error={error}
                            errorMessage={errorMessage}
                            isDataChanged={this.dataChangeHandler}
                            successMessage={this.props.successMessage}
                            loaderFullScreen={this.state.loaderFullScreen}
                            asset = {this.props.asset}
                            currentAsset= {this.props.currentAsset}
                            isComplete={this.state.isComplete}
                            userInfo = {this.props.userState.userInfo}
                            onSubmit={this.onSubmitHandler}
                            onUpdate={this.onUpdateHandler}
                            selectedAssestType={this.props.selectedAssestType}
                            dependent={this.props.dependent}
                            editMode={editMode}
                            coOwnerDetails={coOwnerDetails}
                        /> 
                    }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    assetTypeList: state.dependentReducer.assetTypeList,
    asset : state.dependentReducer.asset,
    asset_present: state.dependentReducer.asset_present,
    userState : state.userDetailReducer.userState,
    currentAsset:state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});

export default connect(mapStateToProps, mapDispatchToProps) (CoOwnerScreen);
