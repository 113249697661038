import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Loading from '../components/LoadingComponent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {currencyFormatting} from "../util/CurrencyFormater";
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import UploadFiles from "./ImageUploadComponent";
import {v4 as uuidv4} from "uuid";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';

function BurialAccountComponentStep1(props) {
    const { register, handleSubmit, setFocus, control,setValue,setError, formState: {errors, isSubmitting } } = useForm({ mode: "all" });
    const history = useHistory();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [error, setErrors] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMeesage, setNameErrorMeesage] = useState('');
    const [account, setAccount] = useState('');
    const [accountError, setAccountError] = useState(false);
    const [accountErrorMeesage, setAccountErrorMeesage] = useState('');
    const [passwordShown, setPasswordShown] = useState(true);

    const [dateAcquired, setDateAcquired] = useState(null);
    const [dateAcquiredError, setDateAcquiredError] = useState(false);
    const [dateAcquiredErrorMeesage, setDateAcquiredErrorMeesage] = useState('');

    const [assetValue, setAssetValue] = useState('');
    const [assetValueError, setAssetValueError] = useState(false);
    const [assetValueErrorMeesage, setAssetValueErrorMeesage] = useState('');

    const [asOfDate, setAsOfDate] = useState(new Date());
    const [asOfDateError, setAsOfDateError] = useState(false);
    const [asOfDateErrorMeesage, setAsOfDateErrorMeesage] = useState('');

   // const [hideNote, setHideNote] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [note, setNote] = useState('');
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const dateAcquiredRef = useRef(null);
    const asOfDateRef = useRef(null);
    const [linkToWebsite, setLinkToWebsite] = useState('');

    const onSubmit = async (formData)=>{
        formData.dateAcquired = dateAcquired
        let isNameValid = await validateName();
        let isAssetValueValid = await validateAssetValue();
        formData.asOfDate = asOfDate
        let obj =  {
            "asset_type_id" : props.editMode ? props.currentAsset.asset_type_id : props.asset.asset_type_id,
            "asset_name" : name,
            "acquisition_date" : dateAcquired != null ? await ddmmyyyyFormat(new Date(dateAcquired)) : null,
            "asset_value" : assetValue && assetValue.includes(',') ? assetValue.replaceAll(',','') : assetValue ,
            "as_of_date" : await ddmmyyyyFormat(new Date(asOfDate)),
            "status" : "1",
            "account_number" : account,
            "note" : note,
            "web_url" : linkToWebsite,
        }

        let isDatesValid = !asOfDateError && !dateAcquiredError
        // let isValidAccount = validateAccount()

        if(isDatesValid && !isAssetValueValid && !isNameValid){
            setIsSubmit(true)
            setLoading(true);
            if(props.editMode){
                obj.id = props.currentAsset.id;
                props.onUpdate(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }else {
                // props.onSubmit(obj)
                obj.id = assetId;
                props.onSubmit(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }
        }
    }

    useEffect(  () => {
       const createUuid = async ()=>{
           if(props.currentAsset === undefined || (props.currentAsset && Object.keys(props.currentAsset).length === 0)){
               const uuid = await uuidv4();
               await setAssetId(uuid);
           }
           setAccountError(false)
           setPasswordShown(true)
       }

        createUuid();

    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const assignFormDefaultValue = async ()=>{
           setIsEditMode(false)
           if(props.editMode && Object.keys(props.currentAsset).length > 0){
               let asset = props.currentAsset;
               setAssetId(asset.id)
               let assetVal = asset.asset_value || asset?.asset_value === 0 ? await currencyFormatting(asset.asset_value) : ''
               setName(asset.asset_name)
               setAssetValue(assetVal)
               setAsOfDate(new Date(asset.as_of_date))
               setDateAcquired(asset.acquisition_date != null ? new Date(asset.acquisition_date) : null)
               setNote(asset.note)
               setAccount(asset.burial.account_number)
               setLinkToWebsite(asset?.web_url)
               setIsEditMode(true)
           }
       }

       assignFormDefaultValue()
    },[props.editMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
       const checkFormDataIsChanged = async ()=>{
           setIsBtnDisabled(true)
           setIsDataModified(false)
           props.isDataChanged(false)
           if(isEditMode && Object.keys(props.currentAsset).length  > 0){
               /* setIsBtnDisabled(true)*/
               let asset = props.currentAsset;
               let asOfDate1 = await ddmmyyyyFormat(new Date(asOfDate))
               let dateAcquired1 = dateAcquired != null ? await ddmmyyyyFormat(new Date(dateAcquired)) : null
               if(name !== asset.asset_name || account !== asset.burial.account_number || linkToWebsite !== asset.web_url || assetValue !== await currencyFormatting(asset.asset_value) || asOfDate1 !== asset.as_of_date || note !== asset.note || dateAcquired1 !== asset.acquisition_date || fileInfo.length > 0 || deletedFileInfo.length > 0){
                   if(name !== "" && assetValue !== "" && asOfDate !== null){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }else {
               if(!props.editMode){
                   let today = await ddmmyyyyFormat(new Date())
                   if(name !== '' || account !== "" || assetValue !== '' || linkToWebsite !== '' ||await ddmmyyyyFormat( new Date(asOfDate)) !== today || note !== '' || dateAcquired !== null || fileInfo.length > 0 || deletedFileInfo.length > 0){
                       if(name !== "" && assetValue !== "" && asOfDate !== null ){
                           setIsBtnDisabled(false)
                       }
                       setIsDataModified(true)
                       props.isDataChanged(true)
                   }
               }
           }
       }
       checkFormDataIsChanged()

    }, [isEditMode, name, account, assetValue,  asOfDate, note, linkToWebsite, dateAcquired,fileInfo, deletedFileInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFocus("name");
    }, [setFocus])

    /*const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };*/

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            if(props.editMode){
                props.onCancel()
            }else {
                history.push(A12Y_CONST.ROUTE.INVENTORY);
            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        if(props.editMode){
            props.onCancel()
        }else {
            history.push(A12Y_CONST.ROUTE.INVENTORY);
        }
    }

    const handleNameChange = (e) => {
        setName(e.target.value.trimStart())
        setValue('name', e.target.value.trimStart())
    }
    const validateName = (e) => {
        if(name === '' && name.length === 0) {
            setNameError(true)
            setNameErrorMeesage(A12Y_CONST.ERROR_MESSAGE.NAME_REQUIRED);
            return true;
        }else{
            setNameError(false)
            setNameErrorMeesage('');
            return false;
        }
    }

    const handleAssetValueChange = async (e) => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setAssetValue(amt)
            setValue('assetValue',amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setAssetValue(temp)
                setValue('assetValue', temp)
            }
        }
    }

    const validateAssetValue = () => {
        if(assetValue === '' && assetValue.length === 0) {
            setAssetValueError(true)
            setAssetValueErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_ASSET_VALUE);
            return true;
        }else{
            setAssetValueError(false)
            setAssetValueErrorMeesage('');
            return false;
        }
    }


    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
    },[props.loading,props.error,])


    const handleNoteChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setNote(e.target.value);
            setValue('note',e.target.value)
        }
    }

    /*const validateAsOfDate = () => {
        // debugger
        if(asOfDate === '' && asOfDate.length === 0) {
            setAsOfDateError(true)
            setAsOfDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_AS_OF_DATE);
            return true;
        }else{
            setAsOfDateError(false)
            setAsOfDateErrorMeesage('');
            return false;
        }
    }*/

    /*const handleNoteHideChange = (e) => {
        setHideNote(e.target.checked);
        setValue('noteHide', e.target.checked)
    }*/

    const filesDetail_FromChild = (e,deletedFiles) => {
        if(assetId){
            let fileList = [];
            let base64List = [];
            for (var i = 0; i < e.length; i++) {
                if(!e[i].file_id){
                    let name = e[i].displayName
                    let size = e[i].size
                    let file = e[i];
                    let fileName = e[i].name;
                    let reader = new FileReader();
                    const uuid = uuidv4();
                    let s3key = `${props.dependent.id}/asset/${assetId}/${uuid}`
                    reader.readAsDataURL(file);
                    reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                    );
                    fileList.push({
                        s3_key: s3key,
                        file_id:uuid,
                        asset_id:assetId,
                        'contentType': e[i].type,
                        'fileMetadata': {
                            "about_dependent": "some more info"
                        },
                        'name' : name,
                        'size' : size,
                        'fileName': fileName
                    });
                }
            }
            setFileInfo(fileList);
            setFileBase64Info(base64List)
            setDeletedFileInfo(deletedFiles)
        }
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleAsOfDateChange = () => {
        if(asOfDate != null){
            let isValidObj = validateDate(asOfDate, 100, 0)
            setAsOfDateError(isValidObj.isErr)
            setAsOfDateErrorMeesage(isValidObj.errMsg)
        }
        else{
            setAsOfDateError(true)
            setAsOfDateErrorMeesage('Date is required.')
        }
    }
    const handleDateAcquiredChange = () => {
        if(dateAcquired != null){
            let isValidObj = validateDate(dateAcquired, 100, 0)
            setDateAcquiredError(isValidObj.isErr)
            setDateAcquiredErrorMeesage(isValidObj.errMsg)
        }
    }

    const handleAccountChange = (e) => {
        let acc = e.target.value.trim();
        setAccount(acc)
        setValue('account', acc)
    }

    // const validateAccount = (e) => {
    //     if(account.length > 0 && account.length != 9){
    //         setAccountError(true)
    //         setAccountErrorMeesage(A12Y_CONST.ERROR_MESSAGE.INVALID_ACCOUNT_NUMBER);
    //         return true;
    //     }else{
    //         setAccountError(false)
    //         setAccountErrorMeesage('');
    //         return false;
    //     }
    // }

    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }

    return (
        <div className="df fcol marginBottom20 height100">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h4 className="dependentSubTitle marginBottom10"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'>{props.apostrophe ? A12Y_CONST.LABEL.APOSTROPHE_S : ''} {props.title}</span></h4>
            <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
            </div>
            <form autoComplete="off" className='marginTop30 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                <div className="formRow fullWidth formRowGapSM">
                    <label className="lblExpType">{A12Y_CONST.LABEL.NAME}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className="fullWidth">
                        <input
                            {...register("name")}
                            value={name}
                            id="name"
                            maxLength='255'
                            onBlur={validateName}
                            onChange={handleNameChange}
                            onFocus={()=>setNameErrorMeesage(false)}
                            className={`fullWidth inputColum24size ${nameError && 'errorInputBorderRed'}`}
                            placeholder="Name of Asset"
                        />
                        <div className='errorRelativeRow'>{nameError? <p className='errorMessage'>{nameErrorMeesage}</p>: null}</div>
                    </div>
                </div>
                <div className="formRow fullWidth formRowGapSM payRow">
                    <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.ASSET_VALUE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <span className={ assetValue.trim().length === 0? "currency" : "currency currencyColorChnage" }>$</span>
                            <input
                                {...register("assetValue")}
                                value={assetValue}
                                id="assetValue"
                                type='text' min='0' inputMode='decimal'
                                onWheel={e => e.target.blur()}
                                maxLength='50'
                                onBlur={validateAssetValue}
                                onChange={handleAssetValueChange}
                                onFocus={()=>setAssetValueErrorMeesage(false)}
                                className={`fullWidth inputColum24size txtAmount ${assetValueError && 'errorInputBorderRed'}`}
                                placeholder="0,000.00"
                            />
                            <div className='errorRelativeRow'>{assetValueError? <p className='errorMessage'>{assetValueErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="width50 paddingleft16 positionRelative">
                        <label>{A12Y_CONST.LABEL.AS_OF_DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <Controller
                                name="asOfDate"
                                control={control}
                                inputmode='none'
                                render={({ field }) =>
                                    <DatePicker
                                        {...field}
                                        ref={asOfDateRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={asOfDate}
                                        onChange={(date) => setAsOfDate(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'asOfDate')}
                                        onBlur={handleAsOfDateChange}
                                        onCalendarClose={handleAsOfDateChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(event) => hideOnBlur(event, asOfDateRef)}
                                        onFocus={(e)=> {setAsOfDateError(false); readOnlyOnMobile(e.target)}}
                                        className={!asOfDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>{asOfDateError? <p className='errorMessage'>{asOfDateErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                </div>

                <div className="formRow fullWidth formRowGapSM payRow">
                    <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.DATE_OPENED}</label>
                        <div className="fullWidth amountWrap">
                            <Controller
                                name="dateAcquired"
                                control={control}
                                inputmode='none'
                                render={({ field }) =>
                                    <DatePicker
                                        {...field}
                                        id={A12Y_CONST.BUTTON_ID.AS_OF_DATE}
                                        ref={dateAcquiredRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={dateAcquired}
                                        onChange={date => setDateAcquired(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'dateAcquired')}
                                        onBlur={handleDateAcquiredChange}
                                        onCalendarClose={handleDateAcquiredChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(event) => hideOnBlur(event, dateAcquiredRef)}
                                        onFocus={(e)=> {setDateAcquiredError(false); readOnlyOnMobile(e.target)}}
                                        className={!dateAcquiredError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                        // minDate={subYears(new Date(), 100)}
                                        // maxDate={addYears(new Date(), 0)}
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>
                                {dateAcquiredError? <p className='errorMessage'>{dateAcquiredErrorMeesage}</p>: null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="formRow fullWidth formRowGapSM payRow">
                    <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.LINK_URL}</label>
                        <div className='fullWidth doctorRowMarginB_600'>
                            <input
                                {...register('linkToWebsite',{
                                    pattern:{
                                        //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                        value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                        message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                    }
                                })}
                                placeholder='www.example.com'
                                className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                type='text'
                                id='displayName'
                                autoComplete="none"
                                onChange={(e) => handleLinkToWebsite(e.target.value)}
                                value={linkToWebsite}
                                onFocus={()=>{
                                    setError("linkToWebsite", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                        </div>
                    </div>
                </div>
                {!props.editMode ?
                    <div className="formRow fullWidth formRowGapSM df fcol">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className="fullWidth">
                            <textarea
                                {...register("note")}
                                rows="3"
                                onChange={handleNoteChange}
                                maxLength='4000'
                                value={note}
                                className="fullWidth inputColum24size txtNote"
                                placeholder="Capture details about this burial account."
                            ></textarea>

                            {/*<div className="fullWidth hideNote positionRelative">*/}
                            {/*    <label className="lblExpType">{A12Y_CONST.LABEL.HIDE_NOTE_FROM_VIEWER}</label>*/}
                            {/*    <div className="fullWidth marginTop5 df aic">*/}
                            {/*        <Controller*/}
                            {/*            name="noteHide"*/}
                            {/*            control={control}*/}
                            {/*            render={({ field }) =>*/}
                            {/*                <Switch*/}
                            {/*                    checked={hideNote}*/}
                            {/*                    onChange={handleNoteHideChange}*/}
                            {/*                    color="primary"*/}
                            {/*                />*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*        {hideNote && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}*/}
                            {/*        {!hideNote &&  <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="txtCapture">Capture a note about this transaction.</div>*/}
                    </div>
                    :
                    null
                }
                <div className="formRow fullWidth formRowGapSM">
                    <label className="lblExpType">{A12Y_CONST.LABEL.ACCOUNT} <span>{A12Y_CONST.TEXT.HASH_SYMBOL}</span></label>
                    <div className="fullWidth">
                        <input
                            {...register("account")}
                            value={account}
                            id="account"
                            maxLength='17'
                            //type={passwordShown ? "text" : "password"}
                            type="text"
                            // onBlur={validateAccount}
                            onChange={handleAccountChange}
                            onFocus={()=>setAccountErrorMeesage(false)}
                            className={!passwordShown ?`fullWidth inputColum24size accountSecureTxt ${accountError && 'errorInputBorderRed'}`: `fullWidth inputColum24size ${accountError && 'errorInputBorderRed'}`}
                            placeholder="Bank Account Number"
                        />
                        {/*<div className="passwordTextShow" onClick={togglePasswordVisiblity}>
                            {
                                passwordShown ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                        <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#A9AEB1"/>
                                        <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#A9AEB1"/>
                                    </svg>
                                    :
                                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#A9AEB1"></path>
                                        <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#A9AEB1"></path>
                                    </svg>
                            }

                        </div>*/}
                        <div className='errorRelativeRow'>{accountError? <p className='errorMessage'>{accountErrorMeesage}</p>: null}</div>
                    </div>
                </div>

                <div className="formRow fullWidth marginBottom30 df fcol">
                    <label>{A12Y_CONST.LABEL.ATTACHMENT}</label>
                    <div className="fullWidth">
                        <div className="formRow fullWidth formRowGap uploadWrap">
                            <div className="lblUpload">
                                <UploadFiles
                                    fileDetailSendToParent={filesDetail_FromChild}
                                    fileUploadingData={props.fileUploadingData}
                                    isSubmit={isSubmit}
                                    editMode={props.editMode}
                                    obj={props.currentAsset}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*------------------ Button Actions ---------------------*/}
                <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom">
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.BURIAL_ACCOUNT_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            { loading ? <Loading /> : null }
                            <input id={A12Y_CONST.BUTTON_ID.BURIAL_ACCOUNT+props.BtnTxt} className={isBtnDisabled || loading? "saveButtonDisable" : 'saveButton'} disabled={isBtnDisabled || loading} type="submit" value={props.BtnTxt} />
                        </div>
                    </div>
                </div>
            </form>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default BurialAccountComponentStep1;