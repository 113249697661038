import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Loading from '../components/LoadingComponent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import Select from "react-select";
import {currencyFormatting} from "../util/CurrencyFormater";
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';

function InsuranceStep3(props) {
    const [error, setErrors] = useState(false);
    const { register, handleSubmit, setFocus, control,setValue, formState: { isSubmitting } } = useForm({ mode: "onBlur" });
    const history = useHistory();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);

    const [loanAmount, setLoanAmount] = useState('');
    const [loanAmountError, setLoanAmountError] = useState(false);
    const [loanAmountErrorMeesage, setLoanAmountErrorMeesage] = useState('');

    const [asOfDate, setAsOfDate] = useState(new Date());
    const [asOfDateError, setAsOfDateError] = useState(false);
    const [asOfDateErrorMeesage, setAsOfDateErrorMeesage] = useState('');

    const [loanDate, setLoanDate] = useState(new Date());
    const [loanDateError, setLoanDateError] = useState(false);
    const [loanDateErrorMessage, setLoanDateErrorMessage] = useState('');
    const [currentBalance, setCurrentBalance] = useState('');
    const [currentBalanceError, setCurrentBalanceError] = useState(false);
    const [currentBalanceErrorMeesage, setCurrentBalanceErrorMeesage] = useState('');
    const [rate, setRate] = useState('');
    const [rateError, setRateError] = useState(false);
    const [rateErrorMeesage, setRateErrorMeesage] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('InterestOnly');
    const [loanPayment, setLoanPayment] = useState('');
    const [loanPaymentError, setLoanPaymentError] = useState(false);
    const [loanPaymentErrorMeesage, setLoanPaymentErrorMeesage] = useState('');

    const [frequency, setFrequency] = useState({label:'Monthly', value:'Monthly'});
    const [isEditMode, setIsEditMode] = useState(false);


    const [frequencyOptionsList, setfrequencyOptionsList] = useState([
        {label:'Yearly', value:'Yearly', key:1},
        {label:'Monthly', value:'Monthly', key:12},
        {label:'Quarterly', value:'Quarterly' , key:4}
    ]);
    const loanDateRef = useRef(null);
    const asOfDateRef = useRef(null);
    useEffect(  () => {
        const assignFrequencyList = async ()=>{
            let frequencyList = await frequencyOptionsList.map(item => ({ label: item.label, value: item.label, key:item.key }));
            setfrequencyOptionsList(frequencyList)
        }
        assignFrequencyList()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFocus("loanAmount");
    }, [setFocus])

    const validateRateAmount = (amount) =>{
        let amt = amount.trim();
        let reg = new RegExp('^[0-9 .]*$');
        if(reg.test(amt)){
            if(amt.toString().includes('.')){
                let index = amt.indexOf('.');
                let result = amt.substring(index+1, amt.length);
                if(result.length <= 2){
                    return amt
                }
            }else {
                return amt
            }
        }
    }



    useEffect( ()=>{
        const assignFormDefaultValue = async ()=>{
            setIsEditMode(false)
            if(props.editMode && Object.keys(props.currentAsset).length > 0 && props.currentAsset?.loan && Object.keys(props.currentAsset.loan).length > 0){
                let loan  = props.currentAsset.loan
                let loanAmount = loan.loan_amount || loan.loan_amount === 0 ? await currencyFormatting(loan.loan_amount) : '';
                let current_balance = loan.current_balance || loan.current_balance === 0 ? await currencyFormatting(loan.current_balance) : '';
                let loan_payment_amount = loan.loan_payment_amount || loan.loan_payment_amount === 0 ? await currencyFormatting(loan.loan_payment_amount) : '';
                if(loan.repayment_cycle){
                    let frequencyVal = await frequencyOptionsList.find(item=> item.key === loan.repayment_cycle)
                    if(frequencyVal !== null && frequencyVal !== undefined){
                        setFrequency(frequencyVal)
                    }
                }
                setLoanAmount(loanAmount)
                setCurrentBalance(current_balance)
                setLoanDate(new Date(loan.date_of_loan))
                setAsOfDate(new Date(loan.as_of_date))
                setPaymentTerms(loan.repayment_term)
                setRate(loan.rate_of_interest)
                setValue('rate',loan.rate_of_interest)
                setLoanPayment(loan_payment_amount)
                setIsEditMode(true)
            }
        }
        assignFormDefaultValue();
    },[props.editMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkFormDataIsChanged = async ()=>{
           let loan  = props.currentAsset?.loan ? props.currentAsset.loan : null
           setIsDataModified(false)
           setIsBtnDisabled(true)
           props.isDataChanged(false)
           if(isEditMode && Object.keys(loan).length > 0){
               /* setIsBtnDisabled(true)*/
               let LoanDate = await ddmmyyyyFormat(new Date(loanDate))
               let AsOfDate = await ddmmyyyyFormat(new Date(asOfDate))
               if(loanAmount !== await currencyFormatting(loan.loan_amount) || LoanDate  !== loan.date_of_loan || AsOfDate  !== loan.as_of_date || currentBalance !== await currencyFormatting(loan.current_balance) || rate.toString() !== loan.rate_of_interest.toString() || paymentTerms !== loan.repayment_term || (Object.keys(frequency).length > 0 && frequency.key !== loan.repayment_cycle) || loanPayment !== await currencyFormatting(loan.loan_payment_amount)){
                   if(loanAmount !== "" && loanDate !== null && asOfDate !== null && currentBalance !== "" && rate !== "" && loanPayment !== ""){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }

           }else {
               if(!props.editMode){
                   let today = await ddmmyyyyFormat(new Date())
                   if(loanAmount !== '' || await ddmmyyyyFormat(new Date(loanDate)) !== today || await ddmmyyyyFormat(new Date(asOfDate)) !== today || currentBalance !== '' || rate !== '' || loanPayment !== ''){
                       if(loanAmount !== "" && loanDate !== null && asOfDate !== null && currentBalance !== "" && rate !== "" && loanPayment !== ""){
                           setIsBtnDisabled(false)
                       }
                       setIsDataModified(true)
                       props.isDataChanged(true)
                   }
               }
           }
       }
       checkFormDataIsChanged()
    },[isEditMode,loanAmount, loanDate, asOfDate, currentBalance, rate, paymentTerms, frequency, loanPayment]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (formData)=>{
        /*console.log('insuranceData: ' , formData);*/
        let isLoanAmountValid = await validateLoanAmount()
        let isCurrentBalanceValid = await validateCurrentBalance()
        let isRateValid = await validateRate()
        let isLoanPaymentValid = await validateLoanPayment()
        let repayment_cycle = null;
        if(Object.keys(frequency).length > 0){
            repayment_cycle =  frequency.value === 'Yearly' ? '1': frequency.value === 'Quarterly' ? '4' :'12'
        }
        let obj = {
            loan_amount : loanAmount && loanAmount.includes(',') ? loanAmount.replaceAll(',','') : loanAmount ,
            date_of_loan : await ddmmyyyyFormat(new Date(loanDate)),
            current_balance :currentBalance && currentBalance.includes(',') ? currentBalance.replaceAll(',','') : currentBalance,
            rate_of_interest :  rate,
            repayment_term :  paymentTerms,
            as_of_date : await ddmmyyyyFormat(new Date(asOfDate)),
            repayment_cycle :  repayment_cycle,
            loan_payment_amount :loanPayment && loanPayment.includes(',') ? loanPayment.replaceAll(',','') : loanPayment ,
            frequency:frequency
        }

        // If date error is there
        if(!loanDateError && !isLoanAmountValid && !isCurrentBalanceValid && !isRateValid && !rateError && !isLoanPaymentValid && !asOfDateError){
            props.isDataChanged(false)
            if(props.editMode){
                obj.id = props?.currentAsset?.loan.id
                props.onUpdate(obj)
            }
            else{
                props.onSubmit(obj)
            }
        }
    }

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            props.onCancel()
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    /*const validateAsOfDate = () => {
        // debugger
        if(asOfDate === '' && asOfDate.length === 0) {
            setAsOfDateError(true)
            setAsOfDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_AS_OF_DATE);
            return true;
        }else{
            setAsOfDateError(false)
            setAsOfDateErrorMeesage('');
            return false;
        }
    }*/

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        if(props.editMode || props.createFromViewScreen){
            props.onCancel()
        }else {
            history.push(A12Y_CONST.ROUTE.INVENTORY);
        }
    }

    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
    }, [props.error, props.loading])

    const handleLoanAmountChange = async (e) => {
        let amt = e.target.value.trim();
        if(amt === ''){
            await setLoanAmount(amt)
        }else{
            let temp = await currencyFormatting(amt);
            if(temp !== undefined){
                await setLoanAmount(temp);
            }
        }
    }

    const validateLoanAmount = () => {
        if(loanAmount === '' && loanAmount.length === 0) {
            setLoanAmountError(true)
            setLoanAmountErrorMeesage(A12Y_CONST.ERROR_MESSAGE.LOAN_AMOUNT_IS_REQUIRED);
            return true;
        }else{
            setLoanAmountError(false)
            setLoanAmountErrorMeesage('');
            return false;
        }
    }

    // const handleCurrentBalance = (e) => {
    //     let amt = e.target.value
    //     if(validateAmount(amt)){
    //         setValue('currentBalance', e.target.value)
    //         setCurrentBalance(e.target.value)
    //     }
    // }

    const handleCurrentBalance = async (e) => {
        let amt = e.target.value.trim();
        if(amt === ''){
            await setCurrentBalance(amt)
            setValue('currentBalance', amt)
        }else{
            let temp = await currencyFormatting(amt);
            if(temp !== undefined){
                await setCurrentBalance(temp);
                setValue('currentBalance', temp)
            }
        }
    }

    const validateCurrentBalance = () => {
        if(currentBalance === '' && currentBalance.length === 0) {
            setCurrentBalanceError(true)
            setCurrentBalanceErrorMeesage(A12Y_CONST.ERROR_MESSAGE.CURRENT_BALANCE_IS_REQUIRED);
            return true;
        }else{
            let cAmt = currentBalance && currentBalance.includes(',') ? currentBalance.replaceAll(',','') : currentBalance;
            let lAmt = loanAmount && loanAmount.includes(',') ? loanAmount.replaceAll(',','') : loanAmount;

            if(parseFloat(cAmt) > parseFloat(lAmt)){
                setCurrentBalanceError(true)
                setCurrentBalanceErrorMeesage(A12Y_CONST.ERROR_MESSAGE.CURRENT_BALANCE_IS_GREATER_THAN_LOAN_AMOUNT);
                return true;
            }

            setCurrentBalanceError(false)
            setCurrentBalanceErrorMeesage('');
            return false;
        }
    }

    // const handleLoanPaymentChange = (e) => {
    //     console.log('handleCashValueChange');
    //     setLoanPayment(e.target.value)
    //     setValue('loanPayment', e.target.value)
    // }

    const handleLoanPaymentChange = async (e) => {
        let amt = e.target.value.trim();
        if(amt === ''){
            await setLoanPayment(amt)
        }else{
            let temp = await currencyFormatting(amt);
            if(temp !== undefined){
                await setLoanPayment(temp);
            }
        }
    }

    const validateLoanPayment = () => {
        if(loanPayment === '' && loanPayment.length === 0) {
            setLoanPaymentError(true)
            setLoanPaymentErrorMeesage(A12Y_CONST.ERROR_MESSAGE.LOAN_PAYMENT_IS_REQUIRED);
            return true;
        }else{
            setLoanPaymentError(false)
            setLoanPaymentErrorMeesage('');
            return false;
        }
    }

    const validatePrecent = (amount) =>{
        let amt = amount.trim();
        let reg = /^(\d{0,2}(\.\d{0,2})?|100(\.00?)?)$/;
        
        return reg.test(amt)
    }

    const handleRateChange = async (e) => {
        let rate = e.target.value
        let temp = await validateRateAmount(rate);
        if(temp !== undefined && temp !== null){
            if(validatePrecent(temp)){
                setValue('rate', temp)
                setRate(temp)
            }
        }
    }
    const validateRate = () => {
        if(rate === '' && rate.length === 0) {
            setRateError(true)
            setRateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.RATE_IS_REQUIRED);
            return true;
        }else if(parseFloat(rate) === 0){
            setRateError(true)
            setRateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.RATE_MUST_BE_GREATER_THAN_ZERO);
        }
        else{
            setRateError(false)
            setRateErrorMeesage('');
            return false;
        }
    }

    // const validateLoanDate = () => {
    //     if(loanDate === '') {
    //         setLoanDateError(true)
    //         setLoanDateErrorMeesage('Loan date balance is required.');
    //         return true;
    //     }else{
    //         setLoanDateError(false)
    //         setLoanDateErrorMeesage('');
    //         return false;
    //     }
    // }

    const handleFrequencyChange = (e) => {
        setFrequency(e)
        setValue('frequency', e)
    }

    const handlePaymentTerms = (e) => {
        setPaymentTerms(e.target.value)
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleDateChange = () => {
        if(loanDate != null){
            let isValidObj = validateDate(loanDate, 100, 0)
            setLoanDateError(isValidObj.isErr)
            setLoanDateErrorMessage(isValidObj.errMsg)
        }else{
            setLoanDateError(true)
            setLoanDateErrorMessage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    const handleAsOfDateChange = () => {
        if(asOfDate != null){
            let isValidObj = validateDate(asOfDate, 100, 0)
            setAsOfDateError(isValidObj.isErr)
            setAsOfDateErrorMeesage(isValidObj.errMsg)
        }
        else{
            setAsOfDateError(true)
            setAsOfDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }

    return (
        <div className="df fcol marginBottom20 height100 insurance insStep3 loandAgainstPolicyResponsive">

            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h4 className="dependentSubTitle marginBottom10">Loan Against Policy</h4>
            <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
            </div>

            <form autoComplete="off" className='marginTop30 fullWidth addAssetForm' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                <div className="formRow fullWidth formRowGap payRow">
                    <div className="width50 paddingleft16 paddingRight1 positionRelative">
                        <label>{A12Y_CONST.LABEL.LOAN_AMOUNT}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <span className={loanAmount === '' ? "currency" : "currency currencyColorChnage" }>{A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                            <input
                                {...register("loanAmount")}
                                value={loanAmount}
                                id="loanAmount"
                                type='text' min='0' inputMode='decimal'
                                onWheel={e => e.target.blur()}
                                maxLength='50'
                                onBlur={validateLoanAmount}
                                onChange={handleLoanAmountChange}
                                onFocus={()=>setLoanAmountErrorMeesage(false)}
                                className={`fullWidth txtAmount inputColum24size ${loanAmountError && 'errorInputBorderRed'}`}
                                placeholder="0,000.00"
                            />
                            <div className='errorRelativeRow'>{loanAmountError? <p className='errorMessage'>{loanAmountErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="width50 paddingRight16 positionRelative">
                        <label className='textAlignRight'>{A12Y_CONST.LABEL.DATE_OF_LOAN}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <Controller
                                name="loanDate"
                                control={control}
                                inputmode='none'
                                render={({ field }) =>
                                    <DatePicker
                                        {...field}
                                        ref={loanDateRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={loanDate}
                                        onChange={date => setLoanDate(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'loanDate')}
                                        onBlur={handleDateChange}
                                        onCalendarClose={handleDateChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        onKeyDown={(event) => hideOnBlur(event, loanDateRef)}
                                        onFocus={(e)=> {setLoanDateError(false); readOnlyOnMobile(e.target)}}
                                        dropdownMode="select"
                                        className={!loanDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>
                                {loanDateError? <p className='errorMessage'>{loanDateErrorMessage}</p>: null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="formRow fullWidth formRowGap payRow">
                    <div className="width50 positionRelative paddingRight1">
                        <label>{A12Y_CONST.LABEL.CURRENT_BALANCE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <span className={currentBalance === '' ? "currency" : "currency currencyColorChnage" }>{A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                            <input
                                {...register("currentBalance")}
                                value={currentBalance}
                                id="currentBalance"
                                type='text' min='0' inputMode='decimal'
                                onWheel={e => e.target.blur()}
                                maxLength='50'
                                onBlur={validateCurrentBalance}
                                onChange={handleCurrentBalance}
                                onFocus={()=>setCurrentBalanceErrorMeesage(false)}
                                className={`fullWidth txtAmount inputColum24size ${currentBalanceError && 'errorInputBorderRed'}`}
                                placeholder="0,000.00"
                            />
                            <div className='errorRelativeRow'>{currentBalanceError? <p className='errorMessage'>{currentBalanceErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="width50 paddingleft16 positionRelative">
                        <label className='textAlignRight'>{A12Y_CONST.LABEL.AS_OF_DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <Controller
                                name="asOfDate"
                                control={control}
                                inputmode='none'
                                render={({ field }) =>
                                    <DatePicker
                                        {...field}
                                        ref={asOfDateRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={asOfDate}
                                        onChange={date => setAsOfDate(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'asOfDate')}
                                        onBlur={handleAsOfDateChange}
                                        onCalendarClose={handleAsOfDateChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(event) => hideOnBlur(event, asOfDateRef)}
                                        onFocus={(e)=> {setAsOfDateError(false); readOnlyOnMobile(e.target)}}
                                        className={!asOfDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>{asOfDateError? <p className='errorMessage'>{asOfDateErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                </div>
                <div className="formRow fullWidth formRowGap payRow">
                    <div className="width50 paddingleft16 positionRelative paddingRight1">
                        <label>{A12Y_CONST.LABEL.RATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <input
                                {...register("rate")}
                                value={rate}
                                id="rate"
                                type='text' min='0' inputMode='decimal'
                                onWheel={e => e.target.blur()}
                                maxLength='50'
                                onBlur={validateRate}
                                onChange={handleRateChange}
                                onFocus={()=>setRateError(false)}
                                className={`fullWidth inputColum24size percentOwned ${rateError && 'errorInputBorderRed'}`}
                                placeholder="00.00"
                            />
                            <label className={`lblPercent ${rate > 0 && 'activeVal'} `}>%</label>
                            <div className='errorRelativeRow'>{rateError? <p className='errorMessage'>{rateErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                </div>
                <div className="formRow fullWidth formRowGap">
                    <label>{A12Y_CONST.LABEL.PAYMENT_TERMS}</label>
                    <div className="fullWidth radioAsset paymentTemrs">
                        <label className='static marginRight30'>
                            <input type="radio"
                                   {...register("paymentTerms")}
                                   onChange={handlePaymentTerms}
                                   name='paymentTerms'
                                   checked={paymentTerms === 'InterestOnly'}
                                   value='InterestOnly' />
                            <span className='paddingLeft1 vam marginTop5'>{A12Y_CONST.LABEL.INTEREST_ONLY}</span>
                        </label>
                        <label className='static'>
                            <input type="radio"
                                   {...register("paymentTerms")}
                                   onChange={handlePaymentTerms}
                                   name='paymentTerms'
                                   checked={paymentTerms === 'PrincipalInterest'}
                                   value='PrincipalInterest' />
                            <span className='paddingLeft1 vam marginTop5'> {A12Y_CONST.LABEL.PRINCIPAL} &amp; {A12Y_CONST.LABEL.INTEREST}</span>
                        </label>
                    </div>
                </div>
                <div className="formRow fullWidth formRowGap payRow">
                    <div className="width50 paddingleft16 positionRelative paddingRight1 dependentResidencCol marginTop5">
                        <label className='lblExpType'>{A12Y_CONST.LABEL.FREQUENCY}</label>
                        {/*<div className="fullWidth amountWrap">*/}
                        {/*    <select className='fullWidth inputColum24size' {...register("frequency")} value={frequency} onChange={handleFrequencyChange}>*/}
                        {/*        <option value='Monthly' selected>Monthly</option>*/}
                        {/*        <option value='Yearly'>Yearly</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        <Controller
                            name="frequency"
                            value={frequency}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={frequencyOptionsList}
                                    value={frequencyOptionsList.find(c => c.label === frequency.label)}
                                    onChange={handleFrequencyChange}
                                    classNamePrefix={'dependentResidence'}
                                    selectdisplayprops={frequency}
                                />
                            )}
                            control={control}
                            className="fullwidth inputColum24size"
                            placeholder="Select"
                        />
                    </div>
                    <div className="width50 paddingleft16 positionRelative">
                        <label className='textAlignRight'>{A12Y_CONST.LABEL.LOAN_PAYMENT}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <span className={loanPayment === '' ? "currency" : "currency currencyColorChnage" }>{A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                            <input
                                {...register("loanPayment")}
                                value={loanPayment}
                                id="loanPayment"
                                type='text' min='0' inputMode='decimal'
                                onWheel={e => e.target.blur()}
                                maxLength='50'
                                onBlur={validateLoanPayment}
                                onChange={handleLoanPaymentChange}
                                onFocus={()=>setLoanPaymentErrorMeesage(false)}
                                className={`fullWidth txtAmount inputColum24size ${loanPaymentError && 'errorInputBorderRed'}`}
                                placeholder="0,000.00"
                            />
                            <div className='errorRelativeRow'>{loanPaymentError? <p className='errorMessage'>{loanPaymentErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                </div>

                {/*------------------ Button Actions ---------------------*/}
                <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom">
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.INSURANCE_STEP3_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            { loading ? <Loading /> : null }
                            <input id={A12Y_CONST.BUTTON_ID.INSURANCE_STEP3+props.BtnTxt} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={props.submitBtnText} />
                        </div>
                    </div>
                </div>
            </form>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default InsuranceStep3
