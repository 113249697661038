import React from 'react'
import {Route} from 'react-router-dom';


const LayoutRoute = (props) => {
    const {layout:Layout, component:Component, ...rest} = props;
    return (
        <Route
            {...rest}
            render = {(matchingProps) => {
                return ( <Layout props={props}>
                        <Component {...matchingProps} />
                    </Layout>
                );
            }}
        />
    );
};

export  default  LayoutRoute