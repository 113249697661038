import React, { useState, useEffect}from 'react';
import A12Y_CONST from "../common/a12yConst";


function  PopUpIncomeExpComponent (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
   /* /!* eslint-disable no-unused-vars *!/
    const [message, setMessage] = useState('');
    /!* eslint-disable no-unused-vars *!/*/

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const hidePopUp = () => {
        props.hidePopUp()
    }

    useEffect(()=>{
        if(props.title){
            setTitle(props.title)
        }
        if(props.message){
           // setMessage(props.message)
        }
    },[props.title,props.message])


    const handleDeleteButton = () => {
        props.handleDeleteButton()
    }
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop deleteElement'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className="cancelIcon fullWidth marginTopBottom20">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 12.8V3.2C16 2.336 16.3361 1.53613 16.9377 0.928125C17.5361 0.352125 18.352 0 19.2 0H44.8C45.648 0 46.4639 0.352125 47.0623 0.928125C47.6639 1.53613 48 2.336 48 3.2V12.8H64V19.2H57.6V60.8C57.6 61.664 57.2639 62.4639 56.6623 63.0719C56.0639 63.6479 55.248 64 54.4 64H9.6C8.752 64 7.9361 63.6479 7.3377 63.0719C6.7361 62.4639 6.4 61.664 6.4 60.8V19.2H0V12.8H16ZM36.5248 38.4L42.1824 32.7359L37.6576 28.2242L32 33.8883L26.3424 28.2242L21.8176 32.7359L27.4752 38.4L21.8176 44.0641L26.3424 48.5758L32 42.9117L37.6576 48.5758L42.1824 44.0641L36.5248 38.4ZM22.4 6.4V12.8H41.6V6.4H22.4Z" fill="#D83933"/>
                                        </svg>
                                    </div>
                                    <div className="areYouText df justifyCenter aic fullWidth columBox">
                                       <span id={A12Y_CONST.BUTTON_ID.DELETE_POPUP_TITLE} className={props.popUpTypeExpense && props.popUpTypeExpense === A12Y_CONST.LABEL.EXPENSE ? 'displayInline' :'maxWidth392  maxWidth368 displayInline'}> {title} </span>
                                        {props.popUpTypeExpense && props.popUpTypeExpense === A12Y_CONST.LABEL.EXPENSE ?
                                            <div id={A12Y_CONST.BUTTON_ID.DELETE_POPUP_DESCRIPTION} className='fullWidth useInfo texAlingCener'>
                                                Deleting this expense unlinks it from your Activity and removes it
                                                completely from the system
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="fullWidth marginTop45 marginBottom20 texAlingCener buttonRow_600ChildCenter">
                                        <div id={A12Y_CONST.BUTTON_ID.DELETE_POPUP_YES} className=" popUpYesButton" onClick={handleDeleteButton}>{props.buttonDeleteTxt}</div>
                                        <div id={A12Y_CONST.BUTTON_ID.DELETE_POPUP_NO} className="popUpNoButton marginLeftRight2percent" onClick={hidePopUp} >{props.buttonCancelTxt}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpIncomeExpComponent;