import React from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ children, location: { pathname } }) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]); // pathname is url which is changed.

    return children;
}

export default withRouter(ScrollToTop);