import React, { useState, useEffect}from 'react';
import A12Y_CONST from "../common/a12yConst";


function  PopUpMedicationComponent (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const handleNoButton = () => {
        props.handleNoButton()
    }


    const handleYesButton = () => {
        props.handleYesButton()
    }
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className="cancelIcon fullWidth marginTopBottom20">
                                        <svg width="58" height="50" viewBox="0 0 58 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M31.3094 1.99999L56.7121 46C56.9461 46.4054 57.0693 46.8652 57.0693 47.3333C57.0693 47.8014 56.9461 48.2612 56.7121 48.6666C56.478 49.072 56.1414 49.4086 55.736 49.6427C55.3307 49.8768 54.8708 50 54.4027 50H3.59739C3.12929 50 2.66945 49.8768 2.26408 49.6427C1.8587 49.4086 1.52208 49.072 1.28803 48.6666C1.05399 48.2612 0.930783 47.8014 0.930786 47.3333C0.930789 46.8652 1.05401 46.4054 1.28805 46L26.6907 1.99999C26.9248 1.59464 27.2614 1.25804 27.6668 1.02401C28.0721 0.789983 28.532 0.666779 29.0001 0.666779C29.4681 0.666779 29.928 0.789983 30.3333 1.02401C30.7387 1.25804 31.0753 1.59464 31.3094 1.99999ZM26.3334 36.6667V42H31.6667V36.6667H26.3334ZM26.3334 18V31.3333H31.6667V18H26.3334Z" fill="#FFBE2E"/>
                                        </svg>
                                    </div>
                                    <div className="areYouText areYouDmFont marginBottom10">
                                        <div>{A12Y_CONST.TEXT.ARE_YOU_SURE_TEXT}</div>
                                    </div>
                                    <div className='useInfo popUpInviteUserMessage marginTop10'>
                                        <div>{A12Y_CONST.LABEL.ONCE_SET_INACTIVE}</div>
                                    </div>
                                    <div className="fullWidth marginTop45 marginBottom20 buttonRow_600ChildCenter texAlingCener">
                                        <div className="popUpYesButton marginLeftRight2percent" onClick={handleYesButton}>{A12Y_CONST.LABEL.YES}</div>
                                        <div className="popUpNoButton" onClick={handleNoButton}>{A12Y_CONST.LABEL.NO}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpMedicationComponent;