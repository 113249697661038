import React ,{useEffect, useState, useRef}from 'react'
import {Controller, useForm} from 'react-hook-form';
import Loading from './LoadingComponent'
import 'react-phone-input-2/lib/style.css'
import A12Y_CONST from '../common/a12yConst'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import PopUpComponent from '../components/PopUpComponent'
import DatePicker from "react-datepicker";
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import UploadFiles from "./ImageUploadComponent";
import {v4 as uuidv4} from "uuid";
import { hideOnBlur, readOnlyMobile} from "../util/inputKeyboardHide";

function  DependentProfileStep3 (props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [appointmentError, setAppointmentError] = useState(false);
    const [appointmentErrorMeesage, setAppointmentErrorMeesage] = useState('');
    const [isPopUp, setIsPopUp] = useState(false);
    const [anyChanges, setAnychanges] = useState(false);
   // const [editMode, setEditMode] = useState(false);
    const [caseFile,setCaseFile] = useState('');
    const [isCancelButton, setIsCancelButton] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState('');
    const [popUpButtonText1, setPopUpButtonText1] = useState('');
    const [popUpButtonText2, setPopUpButtonText2] = useState('');
    const [popUpType, setpopUpType] = useState('');
    const [fileInfo, setFileInfo] = useState([]);
    const [index, setIndex] = useState(null);
    const [title, setTitle] = useState('');
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deleteFileInfo, setDeleteFileInfo] = useState([]);
    const {register,handleSubmit,  control ,formState:{ errors, isSubmitting},setValue} = useForm({
        mode:"all"
    });
    const appointmentDateRef = useRef(null);
    
    const onSubmit = async (user) => {
        let data = user
        if(appointmentDate){
            data.appointment_date = await ddmmyyyyFormat(appointmentDate);
        }else {
            data.appointment_date = null;
        }
        if(appointmentError) {
            return
        }
        props.onSubmit(4,data,fileInfo,fileBase64Info,deleteFileInfo)
    }
    const onCancel = async()=>{
        if(anyChanges){
            setIsCancelButton(true)
            setPopUpMessage(A12Y_CONST.LABEL.ANY_EDITS_YOU)
            setPopUpButtonText1(A12Y_CONST.BUTTON_TEXT.YES)
            setPopUpButtonText2(A12Y_CONST.BUTTON_TEXT.NO)
            setpopUpType('Cancel')
            setIsPopUp(true)

        }else{
            setIsCancelButton(false)
            props.onCancel(2)
        }

    }

    // useEffect(() => {
    //     setFocus("caseFileNumber");
    // }, [setFocus])

    useEffect(()=>{
        setLoading(props.loading);
        setError(props.error)
    },[props.loading,props.error])

    useEffect( ()=>{
        if(Object.keys(props.dependent).length > 0 && (props.dependent.case_file_num || props.dependent.appointment_date)){
            setDefaultValue();
        }else {
           // setAppointmentDate(new Date())
        }
    },[props.dependent]) // eslint-disable-line react-hooks/exhaustive-deps


    const setDefaultValue = async()=>{
        setValue("caseFileNumber",props.dependent.case_file_num)
        if(props.dependent.appointment_date !== null){
            let date = await new Date(props.dependent.appointment_date)
            setAppointmentDate(date)
        }
        //setEditMode(true)
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        if(isCancelButton){
            props.onCancel(2)
        }else{
        setIsPopUp(false)
        props.onFinish()
        }
    }
    const handleCaseFileNumber = (e) => {
        let value = e.target.value;
        if(value === ' ') {
            e.preventDefault();
        }else{
            setCaseFile(value.trim());
        }
    }

    useEffect(()=>{
        fileInfo.splice(index, 1);
        props.removeFileIndex(index)
    },[index]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect( ()=>{
        const checkIsFormDataIsChanged = async ()=>{
            let dependent = props.dependent
            setAnychanges(false)
            props.isDataChanged(false)
            if((Object.keys(props.dependent).length > 0  && ((dependent.appointment_date !== null && props.dependent.case_file_num !== "") ||( props.dependent.appointment_date !== null && props.dependent.appointment_date !=="")))){
                let apponitDate = await ddmmyyyyFormat(new Date(appointmentDate))
                if((appointmentDate !== null && dependent.appointment_date !== apponitDate)  || (caseFile !== "" && dependent.case_file_num !== caseFile)){
                    setAnychanges(true)
                    props.isDataChanged(true)
                }
            }else {
                if(appointmentDate !== null || caseFile !== ""){
                    setAnychanges(true)
                    props.isDataChanged(true)
                }
            }
        }
        checkIsFormDataIsChanged()
    },[caseFile,appointmentDate,fileInfo,deleteFileInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
        setAnychanges(false)
        props.isDataChanged(false)
        if(fileInfo.length > 0 || deleteFileInfo.length > 0){
            setAnychanges(true)
            props.isDataChanged(true)
        }

    },[fileInfo,deleteFileInfo]) // eslint-disable-line react-hooks/exhaustive-deps



    const filesDetail_FromChild = async (e,deleteFiles,index) => {
        await setIndex(index)
        await setDeleteFileInfo(deleteFiles)
        let fileList = [];
        let base64List = [];
        for (var i = 0; i < e.length; i++) {
            if(!e[i].s3_key && !e[i].file_id){
                let name = e[i].name
                let size = e[i].size
                let file = e[i];
                let reader = new FileReader();
                const uuid = uuidv4();
                let s3key = `${props.dependent.id}/document/${uuid}`
                reader.readAsDataURL(file);
                reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                        })
                );
                fileList.push({
                    s3_key: s3key,
                    file_id: uuid,
                    'contentType': e[i].type,
                    'fileMetadata': {
                        "about_dependent": "some more info"
                    },
                    'name' : name,
                    'size' : size,
                });
            }
        }
        await setFileInfo(fileList);
        await setFileBase64Info(base64List);
       // props.uploadFiles(fileList,base64List,deleteFiles)
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleDateChange = () => {
        if(appointmentDate != null){
            let isValidObj = validateDate(appointmentDate, 100, 100)
            setAppointmentError(isValidObj.isErr)
            setAppointmentErrorMeesage(isValidObj.errMsg)
        }else{
            setAppointmentError(false)
            setAppointmentErrorMeesage('')
        }
    }

    useEffect( () => {
        let arr =[appointmentDateRef.current]
        readOnlyMobile(arr)
    },[])

    useEffect(  () => {
        const assignTitle = async ()=>{
            if(props.roleType === A12Y_CONST.TEXT.GUARDIAN) {
                await setTitle(A12Y_CONST.LABEL.ADD_THE_LEGAL_DETAILS)
            }else if(props.roleType === A12Y_CONST.TEXT.AGENT){
                await setTitle(A12Y_CONST.LABEL.ADD_YOUR_POWER_OF_ATTORNEY_INFORMATION)
            }else if(props.roleType === A12Y_CONST.TEXT.REPRESENTATIVE){
                await setTitle(A12Y_CONST.LABEL.ADD_YOUR_REPRESENTATIVE_PAYEE_INFORMATION)
            }
        }
        assignTitle()
    },[props.roleType, title])
    return (
        <div className="fullWidth">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            {/*{props.fileLoader ?*/}
            {/*<LoaderFullScreen/>*/}
            {/*:*/}
            {/*    null*/}
            {/*}*/}
            <div className="dependentSubTitle marginTop30 marginBottom5">
                {title}
            </div>
            <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
            <form onSubmit={handleSubmit(onSubmit)} className='paddingTop30 newFormUI' autoComplete="off">
            <div className="fullWidth paddingBottom70">
                <div className='fullWidth marginBottom20 formCol positionRelative'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.DATE_OF_APPOINTMENT}</label>
                    <div className='fullWidth'>
                        <Controller
                            name="appointment_date"
                            control={control}
                            inputmode='none'
                            // rules={{ required: true }}
                            render={({ field }) =>
                                <DatePicker
                                    {...field}
                                    dateFormat="MM / dd / yyyy"
                                    ref={appointmentDateRef}
                                    tabIndex={2}
                                    selected={appointmentDate}
                                    onChange={date => setAppointmentDate(date)}
                                    onChangeRaw={(event) => handleChangeRaw(event, 'appointment_date')}
                                    onBlur={handleDateChange}
                                    onCalendarClose={handleDateChange}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(event) => hideOnBlur(event, appointmentDateRef)}
                                    onFocus={(e)=> {setAppointmentError(false);}}
                                    maxLength='10'
                                    className={appointmentError ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    placeholderText="MM / DD / YYYY"
                                />
                            }
                        />
                        {/*<input
                            {...register('dateOfAdvocateAppointmnet')}
                            placeholder='MM/DD/YYYY'
                            className={appointmentError ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                            type='text'
                            id='dob'
                            onChange={handleAppointment}
                            value={appointmentDate}
                            onBlur={validateAppointment}
                            onFocus={()=>{setAppointmentError(false)}}
                        />*/}
                        <div className='errorRelativeRow'>
                            {appointmentError && <p className='errorMessage'>{appointmentErrorMeesage}</p>}
                        </div>
                    </div>
                </div>
                {props.roleType !== A12Y_CONST.TEXT.AGENT?
                    <div className='fullWidth marginBottom20 formCol '>
                    <label className='labelCol'>{props.roleType === A12Y_CONST.TEXT.REPRESENTATIVE ? A12Y_CONST.LABEL.FILE_NUMBER :A12Y_CONST.LABEL.DEPENDENT_CASE_FILE_NUMBER}</label>
                    <div className='fullWidth'>
                        <input
                            {...register('caseFileNumber')}
                            // placeholder={moment().format('YYYYMMDD')+'-01'}
                            placeholder="&nbsp;"
                            tabIndex={1}
                            className={'inputColum24size'}
                            type='text'
                            id='displayName'
                            onChange={(e) => handleCaseFileNumber(e)}
                        />
                        <div className='errorRelativeRow'> {errors.caseFileNumber && <p className='errorMessage'>{errors.caseFileNumber.message}</p>}</div>
                    </div>
                </div>
                :
                null
                }
                <div className="fullWidth marginBottom20 formCol">
                    <label className='labelCol'>{props.roleType === A12Y_CONST.TEXT.GUARDIAN ? A12Y_CONST.LABEL.COURT_ORDERS : props.roleType === A12Y_CONST.TEXT.AGENT ? A12Y_CONST.LABEL.POWER_OF_ATTORNEY_DOCUMENTS : A12Y_CONST.LABEL.RELATED_DOCUMENTS }</label>
                    <UploadFiles
                        fileDetailSendToParent={filesDetail_FromChild}
                        fileUploadingData={props.fileUploadingData}
                        isSubmit={props.showProgressBar}
                        editMode={props.fileEditMode}
                        obj={props.uploadedFiles}
                        isDoctype={true}
                    />
                </div>
                <div className="fullWidth textAlignRight signUpFlowButtonRow marginBottom30 displayFlexCol">
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_STEP3BACK} className={!isSubmitting ? "backButton" :'backButton'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.DEPENDENT_BACK}/></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            {loading ?
                                <Loading />
                                :
                                null
                            }
                            <input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_STEP3CONTINUE} className={loading ? "saveButtonDisable buttonHeight56 buttonlaerUp" :'saveButton buttonHeight56 buttonlaerUp'} disabled={loading}  type="submit" value={A12Y_CONST.BUTTON_TEXT.DEPENDENT_COUNTINUE}/>
                        </div>
                    </div>
                </div>

            </div>
            </form>
            </div>
            <PopUpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={popUpMessage}
                buttonDeleteTxt={popUpButtonText1}
                buttonCancelTxt={popUpButtonText2}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
                popUpType={popUpType}
            />
        </div>
    )
}

export default  DependentProfileStep3;