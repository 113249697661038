import React, { useState, useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import PopUpAssetComponent from './PopUpAssetComponent'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import LoaderFullScreen from "./LoaderFullScreenComponent";


function AssetAccessControlComponent(props) {
    const [error, setErrors] = useState(false);
    const [popUpType, setPopUpType] = useState('');
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);

    useEffect(()=>{
        setPopUpType('')
    },[])

    const handleYes = () => {
        props.accessToAsset(true)

    }
    const handleNo = () => {
        props.accessToAsset(false)
    }
    const assetPopUpHide = () => {
        props.assetPopUpHide()
    }

    useEffect(()=>{
        setErrors(props.error)
        setLoaderFullScreenVisible(props.loading)
    },[props.loading,props.error])

    return (
        <div className="df fcol marginBottom20 height100">
            {loaderFullScreenVisible ?
                <LoaderFullScreen />
                :
                null
            }
            <h4 className="dependentSubTitle"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'>{A12Y_CONST.LABEL.APOSTROPHE_S} {props.title} </span></h4>
            <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
                <div className='df fcol marginBottom20 marginTop20 height100 width100Percent'>
                    <h3 className='title24 marginTop30'>{A12Y_CONST.LABEL.HAVE_ACCESS_TO_THIS_ASSET}</h3>

                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol positionAbsouteBottom right65_bottom30">
                        <div className='widthAuto'>
                            <input id={A12Y_CONST.BUTTON_ID.ASSET_ACCESS_CONTROL_NO} className='cancelButton1' onClick={handleNo} type="button" value={A12Y_CONST.BUTTON_TEXT.NO} />
                        </div>
                        <div className='widthAuto marginLeft20'>
                            <input id={A12Y_CONST.BUTTON_ID.ASSET_ACCESS_CONTROL_YES} className='saveButton' onClick={handleYes} type="button" value={A12Y_CONST.BUTTON_TEXT.YES} />
                        </div>

                    </div>
                </div>

            </div>


            <PopUpAssetComponent
                popUpStatus={props.isPopUpVisible}
                popUpType={popUpType}
                assetPopUpHide={assetPopUpHide}
                currentAsset= {props.asset}
                dependent={props.dependent}
            />
        </div>
    )
}

export default AssetAccessControlComponent
