import React, { Component } from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import {secureClient} from "../config/axiosClient";
import axios from "axios";
import * as userDetailActions from "../redux/actions/userDetailActions";
import AddMedicationComponent from "../components/AddMedicationComponent";

class AddMedicationScreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : true,
            incomeTypeList: [],
            loaderFullScreen:false,
            fileUploadingData:{},
            overylayResponsive:false,
            fromMedicalViewScreen:false,
        }
    }

    componentDidMount= async ()=> {
        let state = { ...this.props.location.state };
        if(state?.fromMedicalView || state?.fromAddDoctorToMedication){
           await this.setState({fromMedicalViewScreen:true})
        }
        if(this.props.currentMedication !== null && Object.keys(this.props.currentMedication).length > 0 && this.props.currentMedication.hasOwnProperty('isComingFromMedication')){
            let obj = this.props.currentMedication
            delete obj.isComingFromMedication
            await this.props.currentMedicationData(obj);
        }
    }

    medicationPicUploading = async (medication,data) =>{
        try {
            let s3Key = this.props.dependent.id+"/medication_pic/"+data.id+"/"+medication.result.name
            let s3PreSignedUrlRes = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_S3_SIGN_URL_PUT, {
                "fileKey":s3Key,
                "contentType": medication.result.type,
                "type":"put",
                ACL: 'public-read'
            })
            if (!s3PreSignedUrlRes.data.error && s3PreSignedUrlRes.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let uploadUrl = s3PreSignedUrlRes.data.sr.url
                let fileResp = await fetch(medication.base64);
                let data = await fileResp.blob();
                let uploadResBody = await axios.put(uploadUrl, data, {
                    headers: {
                        'x-amz-acl': 'public-read',
                        'Content-Type':medication.result.type
                    }
                })
                // if (uploadResBody.statusText === A12Y_CONST.API_RESPONSE_STATUS.CAP_OK) {
                if(uploadResBody.status === 200){
                    let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_S3_SIGN_URL_GET,{
                        fileKey:s3Key
                    });
                    if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                        let url = response.data.sr.url
                        return url;
                    }else {
                        return "";
                    }
                }
            }

        }catch (e) {
            console.error('error....',e)
        }
    }

    fileUploading = async (data,fileInfo,fileBase64Info,deletedFileInfo,editMode,medicationPic)=>{
        this.setState({error:false,loading:true})
        let attached_file = [];
        let reqBody = {
            ref_type : "medication",
            ref_id : data.id,
            attachments : []
        }
        try{
            if(Object.keys(medicationPic).length > 0){
                let picUrl =   await this.medicationPicUploading(medicationPic,data);
                if(picUrl.length > 0){
                    data.medicine_pic_url = picUrl
                }
            }
             if(editMode && this.props.currentMedication && Object.keys(this.props.currentMedication)?.length > 0  && this.props.currentMedication.attachments?.length > 0){
                 let attached_files_list = this.props.currentMedication.attachments
                 let arr =[];
                 if(deletedFileInfo.length > 0){
                     for await (const item of deletedFileInfo) {
                         attached_files_list = attached_files_list.filter(item_1 => item_1.file !== item)
                         arr = attached_files_list
                     }
                     attached_file = arr;
                     reqBody.attachments = attached_files_list
                 }else {
                     attached_file = attached_files_list
                     reqBody.attachments = attached_files_list
                 }

             }
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                if(deletedFileInfo.length > 0) {
                    await this.savingMedicationFile(reqBody)
                }
                if(editMode){
                     await this.updateMedication(data)
                }else {
                    await this.createMedication(data)
                }
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    //let progressIndex = data.document_ref && Object.keys(data.document_ref).length > 0 ? data.document_ref.attached_files.length > 0  ? data.document_ref.attached_files.length : 0 : 0;
                    let deletedFile = deletedFileInfo.length > 0 ? deletedFileInfo.length : 0
                    let progressIndex = editMode && Object.keys(this.props.currentMedication).length > 0 && this.props.currentMedication.hasOwnProperty('attachments') ? this.props.currentMedication.attachments.length - deletedFile : 0
                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();

                        // console.log('signed_urls ===',uploadUrl)
                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                                "Content-Type" : fileInfo[i].contentType
                            },onUploadProgress:async data => { // eslint-disable-line
                                //Set the progress value to show the progress bar
                                // setProgress(Math.round((100 * data.loaded) / data.total))
                                let temp={data:data,index:progressIndex}
                                await this.setState({fileUploadingData:temp})
                                // console.log('loading Event....',data.loaded,this.state.fileUploadingData,progressIndex)
                            }})
                        // console.log('uploadResBody ',uploadResBody)
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                /* ref_type : "asset",
                                 ref_id :fileInfo[i].asset_id,*/
                                file : fileInfo[i].file_id,
                                file_name : fileInfo[i].name,
                                file_extension : fileInfo[i].contentType,
                                file_size : fileInfo[i].size
                            })
                            // data.document_ref = {"attached_files":attached_file}
                            reqBody.attachments = attached_file
                        }
                        i++;
                        progressIndex++
                    }
                    await this.savingMedicationFile(reqBody)
                    if(editMode){
                        await this.updateMedication(data)
                    }else {
                        await this.createMedication(data)
                    }
                }else {
                    this.setState({error:true,loading:false,transferPopupLoader:false})
                }
            }

        }catch (e){
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false,transferPopupLoader:false})
            console.error('error ......',e)
        }

    }

    savingMedicationFile = async (data)=>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ATTACHMENTS,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({loading:false})
                //this.redirectToInventoryView()
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,transferPopupLoader:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false,transferPopupLoader:false})
        }
    }

    updateMedication = async(data) =>{
        if(this.props.currentMedication && Object.keys(this.props.currentMedication).length !== 0){
            this.setState({loading:true})
            let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.MEDICINES+"/"+this.props.currentMedication.id,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.props.currentMedicationData(response.data.sr.medicine);
                if(this.state.fromMedicalViewScreen){
                    this.props.history.push(A12Y_CONST.ROUTE.MEDICATION_VIEW);
                }else{
                    this.goToMedicalScreen()
                }
                this.setState({loading:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }
        }
    }

    createMedication = async(data) =>{
        let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.MEDICINES, data);

        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            if(!this.props.medicine_present){
                let dependent = this.props.dependent
                dependent.metadata.medicine_present = true
                this.props.updateDependentProps(dependent)
               await this.props.updateMedicineMetaData(!this.props.medicine_present)
            }
            await this.props.currentMedicationData(null);
            this.goToMedicalScreen()
            this.setState({loading:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loading:false})
        }
    }

    inActiveMedication = async ()=>{
        this.setState({loaderFullScreen:true})
        let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.MEDICINES+"/"+this.props.currentMedication.id+"/"+A12Y_CONST.API_ENDPOINT.DEACTIVATE,{});
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            await this.props.currentMedicationData(response.data.sr.medicine);
            if(this.state.fromMedicalViewScreen){
                this.props.history.push(A12Y_CONST.ROUTE.MEDICATION_VIEW);}
            else {
                this.props.history.push({
                    pathname: A12Y_CONST.ROUTE.MEDICAL,
                    state: {status: true }
                });
            }
            this.setState({loading:false, loaderFullScreen:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loading:false, loaderFullScreen:false})
        }
    }

    goToMedicalScreen = async ()=>{
        if(Object.keys(this.props.previousCurrentMedication).length > 0){
           await this.props.currentMedicationData(this.props.previousCurrentMedication)
        }
        if(this.state.fromMedicalViewScreen){
            this.props.history.push(A12Y_CONST.ROUTE.MEDICATION_VIEW);}
        else {
            this.props.history.push({
                pathname: A12Y_CONST.ROUTE.MEDICAL,
                state: {status: true }
            });
        }
    }

    onBackHandler = ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.INCOMES);
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }
    overlay = (status) => {
        this.setState({overylayResponsive : status})
    }
    goToCreateDoctorScreen = async (data) => {
        data.isComingFromMedication = true
        await this.props.currentMedicationData(data);
        await this.props.currentDoctorData({})
        if(this.state.fromMedicalViewScreen){
            this.props.history.push({
                pathname: A12Y_CONST.ROUTE.ADD_DOCTOR,
                state: {fromViewScreen:true }
            });
        }else{
            this.props.history.push(A12Y_CONST.ROUTE.ADD_DOCTOR);
        }

    }

    render(props) {
        const {fileUploadingData} = this.state;
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddMedicationComponent
                            overlay = {this.overlay}
                            loading={this.state.loading}
                            message={this.state.message}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            incomeTypeList={this.props.incomeTypeList}
                            onSubmit={this.fileUploading}
                            loaderFullScreen={this.state.loaderFullScreen}
                            dependent={this.props.dependent}
                            incomeObj={this.props.currentIncome}
                            onUpdate={this.fileUploading}
                            fileUploadingData={fileUploadingData}
                            onCancel={this.goToMedicalScreen}
                            isDataChanged={this.dataChangeHandler}
                            isSessionTimeout={this.props.isSessionTimeout}
                            goToCreateDoctorScreen={this.goToCreateDoctorScreen}
                            doctorList={this.props.doctorList}
                            currentMedication={this.props.currentMedication}
                            inActiveMedication={this.inActiveMedication}
                            medication_data={this.props.medication_data}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    currentIncome : state.dependentReducer.currentIncome,
    incomeTypeList : state.dependentReducer.incomeTypeList,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    asset_present: state.dependentReducer.asset_present,
    doctorList: state.dependentReducer.doctorList,
    isSessionTimeout: state.userDetailReducer.isSessionTimeout,
    currentMedication : state.dependentReducer.currentMedication,
    medicine_present : state.dependentReducer.medicine_present,
    medication_data : state.dependentReducer.medication_data,
    previousCurrentMedication : state.dependentReducer.previousCurrentMedication,
});

const mapDispatchToProps = dispatch => ({
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    currentMedicationData: (medication) => dispatch(dependentActions.currentMedicationData(medication)),
    updateMedicineMetaData: (medicine_present) => dispatch(dependentActions.updateMedicineMetaData(medicine_present)),
    currentDoctorData: (doctor) => dispatch(dependentActions.currentDoctorData(doctor)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});

export default connect(mapStateToProps, mapDispatchToProps) (AddMedicationScreen);
