import A12Y_CONST from "../common/a12yConst";

export async function currencyFormatting(currency) {
    if(currency || currency === 0){
        let reg = new RegExp('^[0-9 . ,]*$');
        if(typeof currency !== 'string'){
            currency = currency.toString();
        }
        if(reg.test(currency)){
                if(currency.toString().includes('.')){
                    let index = currency.indexOf('.');
                    let result = currency.substring(index+1, currency.length);
                    if(result.toString().includes('.')){
                        return
                    }
                    if(result.length <= 2){
                        return currency
                    }
                }else {
                    if(currency.includes(',')){
                        currency = currency.replaceAll(',','')
                    }
                    return  new Intl.NumberFormat(A12Y_CONST.TEXT.CURRENCY_FORMAT).format(currency)
                }
        }
    }
}

export async function totalAmountFormatter(totalAmount) {
    return  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount)
}