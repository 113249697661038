import React, {useEffect, useState} from 'react'
import A12Y_CONST from "../common/a12yConst";
import PopUpWelcomeAdvocord from "./PopUpWelcomeAdvocord";
import PopUpUserSurvey from "./PopUpUserSurvey";
// import PopUpComponent from "./PopUpComponent";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function DashboardComponent (props) {
    const [screenList, setScreenList] = useState([])
    /* eslint-disable no-unused-vars */
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    /* eslint-disable no-unused-vars */
    const [isPopUp, setIsPopUp] = useState(false)
    const [isSurveyPopUp, setIsSurveyPopUp] = useState(false)
    const [popUpType, setPopUpType] = useState('')
    const [isReminderBox, setIsReminderBox] = useState(false)

    useEffect(() => {
        if(props.userInfo.last_login_time === null) {
            setIsPopUp(props.welcomePopUpStatus)
        }
    },[props.welcomePopUpStatus, props.userInfo.last_login_time])

    useEffect(() => {
        async function popUpStatus() {
            if(props.userInfo.last_login_time === null){
                await setPopUpType(A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD)
            }
            if(props?.userSubscriptionAllDetails?.is_trial_expired || props?.dependent?.subscirption?.is_trial_expired){
                await setPopUpType(A12Y_CONST.LABEL.TRIAL_HAS_EXPIRED)
                await setIsPopUp(true);
            }
            if(((props.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED && !props?.userSubscriptionAllDetails?.is_trial_expired) || props.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT) || (props.dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED && !props?.dependent?.subscirption?.is_trial_expired) || props.dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT){
                await setPopUpType(A12Y_CONST.LABEL.PURCHASED_PLAN_EXPIRED)
                await setIsPopUp(true)
            }

        }
        popUpStatus()

    }, [props.userInfo,props.dependent, props?.userSubscriptionAllDetails?.is_trial_expired, props.userInfo.last_login_time, props.userSubscriptionAllDetails?.subscription_details?.subscription_status])

    useEffect(() => {
        async function ReminderPopUpstatus() {
            if(props.userInfo.last_login_time === null){
              await  setIsReminderBox(props.isTrialReminder)
            }
        }
        ReminderPopUpstatus()
    },[props.isTrialReminder, props.userInfo.last_login_time])

    useEffect(() => {
        setScreenList([
            {
                'icon':<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    <path d="M38.1384 12.4564H11.9523V18.0928H38.1384V12.4564Z" fill="#F2A950"/>\n' +
                    <path d="M38.1384 23.715H11.9523V29.3513H38.1384V23.715Z" fill="#F2A950"/>\n' +
                    <path d="M24.7225 34.9736H11.9523V40.61H24.7225V34.9736Z" fill="#F2A950"/>\n' +
                    <path fillRule="evenodd" clipRule="evenodd" d="M44.3513 56.3636H5.73941V5.63636H44.3513V28.8723L50.0907 23.2359V0H0V62H50.0907V46.81L44.3513 52.4464V56.3636Z" fill="#F2A950"/>\n' +
                    <path fillRule="evenodd" clipRule="evenodd" d="M37.4353 47.4441L42.6008 46.345L62 27.3082L57.9394 23.3204L38.5545 42.3573L37.4353 47.4441Z" fill="#F2A950"/>\n' +
                </svg>,
                'title':A12Y_CONST.LABEL.ACTIVITY_LOG,
                'description':A12Y_CONST.LABEL.ACTIVITY_DES_DASHBOARD,
                'ViewLog':A12Y_CONST.LABEL.VIEW+' '+A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL,
                'addScreen':A12Y_CONST.LABEL.ADD+' '+A12Y_CONST.LABEL.ACTIVITY_LABEL,
                'viewURL':A12Y_CONST.ROUTE.ACTIVITIES,
                'addURL':A12Y_CONST.ROUTE.ADD_ACTIVITY,
                'IdLearnMore':A12Y_CONST.TEXT.ACTIVITY+A12Y_CONST.TEXT.LEARN_MORE,
                'IdViewButton':A12Y_CONST.TEXT.ACTIVITY+A12Y_CONST.TEXT.VIEW_SECTION,
                'IdADDButton':A12Y_CONST.TEXT.ACTIVITY+A12Y_CONST.TEXT.ADD
            },
            {
                'icon':<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_11322_70687)">
                        <path d="M38 0C30.4843 0 23.1374 2.22866 16.8883 6.40416C10.6393 10.5796 5.76872 16.5144 2.8926 23.458C0.0164649 30.4016 -0.736062 38.0422 0.730176 45.4134C2.19641 52.7847 5.81556 59.5557 11.13 64.8701C16.4444 70.1845 23.2153 73.8036 30.5866 75.2699C37.9579 76.7361 45.5984 75.9836 52.542 73.1074C59.4856 70.2313 65.4204 65.3607 69.5959 59.1117C73.7714 52.8626 76 45.5157 76 38C76 27.9218 71.9965 18.2563 64.8701 11.1299C57.7437 4.00356 48.0782 0 38 0V0ZM38 69.0909C31.8508 69.0909 25.8397 67.2675 20.7268 63.8512C15.614 60.4348 11.629 55.5791 9.27576 49.898C6.92256 44.2169 6.30686 37.9655 7.50651 31.9345C8.70616 25.9034 11.6673 20.3636 16.0154 16.0154C20.3636 11.6673 25.9034 8.70614 31.9345 7.50649C37.9655 6.30685 44.2169 6.92255 49.898 9.27575C55.5791 11.6289 60.4349 15.6139 63.8512 20.7268C67.2675 25.8397 69.0909 31.8508 69.0909 38C69.0909 42.0829 68.2867 46.1259 66.7243 49.898C65.1618 53.6701 62.8717 57.0975 59.9846 59.9846C57.0976 62.8717 53.6701 65.1618 49.898 66.7243C46.1259 68.2867 42.0829 69.0909 38 69.0909Z" fill="#F2A950"/>
                        <path d="M39.6059 34.5453H36.3932C35.871 34.5453 35.3701 34.3379 35.0009 33.9686C34.6316 33.5993 34.4241 33.0985 34.4241 32.5762C34.4241 32.054 34.6316 31.5532 35.0009 31.1839C35.3701 30.8146 35.871 30.6072 36.3932 30.6072H46.3941V23.6981H41.4541V19.3799H34.545V23.8708C32.3752 24.3043 30.4455 25.5325 29.1339 27.3146C27.8224 29.0967 27.2236 31.3044 27.4549 33.505C27.6862 35.7056 28.7309 37.7405 30.3843 39.211C32.0377 40.6815 34.1807 41.4815 36.3932 41.4544H39.6059C40.1282 41.4544 40.629 41.6619 40.9983 42.0312C41.3676 42.4004 41.575 42.9013 41.575 43.4235C41.575 43.9458 41.3676 44.4466 40.9983 44.8159C40.629 45.1852 40.1282 45.3926 39.6059 45.3926H29.605V52.3017H34.545V56.6372H41.4541V52.0772C43.5703 51.5931 45.4357 50.3504 46.6978 48.5842C47.9599 46.818 48.5311 44.6506 48.3035 42.4918C48.076 40.3329 47.0653 38.3323 45.4627 36.8681C43.8601 35.4038 41.7765 34.5775 39.6059 34.5453Z" fill="#F2A950"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_11322_70687">
                            <rect width="76" height="76" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>,
                'title':A12Y_CONST.LABEL.EXPENSE,
                'description':A12Y_CONST.LABEL.EXPENSES_DES_DASHBOARD,
                'ViewLog':A12Y_CONST.LABEL.VIEW+' '+A12Y_CONST.LABEL.EXPENSE_small,
                'addScreen':A12Y_CONST.LABEL.ADD+' '+A12Y_CONST.LABEL.EXPENSE,
                'viewURL':A12Y_CONST.ROUTE.EXPENSES,
                'addURL':A12Y_CONST.ROUTE.ADD_EXPENSE,
                'IdLearnMore':A12Y_CONST.LABEL.EXPENSE_LABEL+A12Y_CONST.TEXT.LEARN_MORE,
                'IdViewButton':A12Y_CONST.LABEL.EXPENSE_LABEL+A12Y_CONST.TEXT.VIEW_SECTION,
                'IdADDButton':A12Y_CONST.LABEL.EXPENSE_LABEL+A12Y_CONST.TEXT.ADD
            },
            {
                'icon':<svg width="58" height="75" viewBox="0 0 58 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.8614 42.4265H24.6598C25.1147 42.4265 25.551 42.6141 25.8726 42.9479C26.1943 43.2817 26.375 43.7345 26.375 44.2066C26.375 44.6788 26.1943 45.1316 25.8726 45.4654C25.551 45.7992 25.1147 45.9868 24.6598 45.9868H15.9485V52.2329H20.2515V56.2148H26.2697V52.0611C28.1428 51.6523 29.8034 50.5371 30.9301 48.9311C32.0568 47.3251 32.5696 45.3425 32.3693 43.3668C32.169 41.3911 31.2699 39.5627 29.8458 38.2352C28.4217 36.9078 26.5739 36.1756 24.6598 36.1804H21.8614C21.4065 36.1804 20.9702 35.9929 20.6485 35.659C20.3269 35.3252 20.1462 34.8724 20.1462 34.4003C20.1462 33.9282 20.3269 33.4754 20.6485 33.1415C20.9702 32.8077 21.4065 32.6201 21.8614 32.6201H30.5726V26.374H26.2697V22.5015H20.2515V26.5458C18.3783 26.9546 16.7178 28.0698 15.5911 29.6758C14.4644 31.2818 13.9516 33.2644 14.1518 35.2401C14.3521 37.2158 15.2513 39.0443 16.6753 40.3717C18.0994 41.6992 19.9472 42.4313 21.8614 42.4265Z" fill="#F2A950"/>
                    <path d="M11.4796 0V11.9769H0V74.9532L9.76446 68.8944L16.5499 73.1262L23.3053 68.9413L30.0908 73.1262L36.8311 68.9413L46.5204 74.9532V59.5097L48.2355 58.4478L58 64.5066V0H11.4796ZM40.5022 64.0225L36.7559 61.6958L30.0908 65.8495L23.3354 61.6646L16.5499 65.8495L9.76446 61.6646L6.01816 64.0225V18.223H40.5022V64.0225ZM51.9818 53.529L48.2355 51.2024L46.5204 52.2642V11.9769H17.4978V6.2461H51.9818V53.529Z" fill="#F2A950"/>
                </svg>,
                'title':A12Y_CONST.LABEL.INCOME_LABEL,
                'description':A12Y_CONST.LABEL.INCOME_DES_DASHBOARD,
                'ViewLog':A12Y_CONST.LABEL.VIEW+' '+A12Y_CONST.LABEL.INCOME_LABEL,
                'addScreen':A12Y_CONST.LABEL.ADD+' '+A12Y_CONST.LABEL.INCOME_LABEL,
                'viewURL':A12Y_CONST.ROUTE.INCOMES,
                'addURL':A12Y_CONST.ROUTE.ADD_INCOME,
                'IdLearnMore':A12Y_CONST.LABEL.INCOME_LABEL+A12Y_CONST.TEXT.LEARN_MORE,
                'IdViewButton':A12Y_CONST.LABEL.INCOME_LABEL+A12Y_CONST.TEXT.VIEW_SECTION,
                'IdADDButton':A12Y_CONST.LABEL.INCOME_LABEL+A12Y_CONST.TEXT.ADD
            },
            {
                'icon':<svg width="64" height="53" viewBox="0 0 64 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M42.7113 11.4694L32.729 0H0V44.453C0.00393337 46.7186 0.917407 48.8903 2.5403 50.4924C4.1632 52.0944 6.36318 52.9961 8.6583 53H63.9702V11.4694H42.7113ZM58.0195 47.1258H8.6583C7.9402 47.1258 7.25152 46.8442 6.74375 46.343C6.23598 45.8417 5.95072 45.1619 5.95072 44.453V5.8742H29.9768L34.8563 11.4694H13.166V17.3436H58.0195V47.1258Z" fill="#F2A950"/>
                    <path d="M26.4063 35.4067H13.166V41.2809H26.4063V35.4067Z" fill="#F2A950"/>
                </svg>,
                'title':A12Y_CONST.LABEL.DOCUMENTS,
                'description':A12Y_CONST.LABEL.DOCUMENTS_DES_DASHBOARD,
                'ViewLog':A12Y_CONST.LABEL.VIEW+' '+A12Y_CONST.LABEL.DOCUMENTS,
                'addScreen':A12Y_CONST.LABEL.UPLOAD+' '+A12Y_CONST.LABEL.DOCUMENT,
                'viewURL':A12Y_CONST.ROUTE.DOCUMENTS,
                'addURL':A12Y_CONST.ROUTE.DOCUMENTS,
                'IdLearnMore':A12Y_CONST.LABEL.DOCUMENT+A12Y_CONST.TEXT.LEARN_MORE,
                'IdViewButton':A12Y_CONST.LABEL.DOCUMENT+A12Y_CONST.TEXT.VIEW_SECTION,
                'IdADDButton':A12Y_CONST.LABEL.DOCUMENT+A12Y_CONST.TEXT.ADD
            },
            {
                'icon':<svg width="53" height="66" viewBox="0 0 53 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.2222 0V6.6H50.0556C51.6809 6.6 53 8.085 53 9.9V62.7C53 64.515 51.6809 66 50.0556 66H2.94444C1.31911 66 0 64.515 0 62.7V9.9C0 8.085 1.31911 6.6 2.94444 6.6H11.7778V0H41.2222ZM11.7778 13.2H5.88889V59.4H47.1111V13.2H41.2222V19.8H11.7778V13.2ZM29.4444 29.7V36.3H35.3333V42.9H29.4416L29.4444 49.5H23.5556L23.5527 42.9H17.6667V36.3H23.5556V29.7H29.4444ZM35.3333 6.6H17.6667V13.2H35.3333V6.6Z" fill="#F2A950"/>
                </svg>,
                'title':A12Y_CONST.LABEL.MEDICAL,
                'description':A12Y_CONST.LABEL.MEDICAL_DES_DASHBOARD,
                'ViewLog':A12Y_CONST.LABEL.VIEW+' '+A12Y_CONST.LABEL.MEDICATION,
                'addScreen':A12Y_CONST.LABEL.ADD+' '+A12Y_CONST.LABEL.MEDICATION,
                'viewURL':A12Y_CONST.ROUTE.MEDICAL,
                'addURL':A12Y_CONST.ROUTE.ADD_MEDICATION,
                'IdLearnMore':A12Y_CONST.LABEL.MEDICATION+A12Y_CONST.TEXT.LEARN_MORE,
                'IdViewButton':A12Y_CONST.LABEL.MEDICATION+A12Y_CONST.TEXT.VIEW_SECTION,
                'IdADDButton':A12Y_CONST.LABEL.MEDICATION+A12Y_CONST.TEXT.ADD
            },
            {
                'icon':<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M50.3119 44.3864V0H0V53.7991C0.00381045 55.973 0.888738 58.0567 2.46092 59.5939C4.0331 61.1311 6.16433 61.9963 8.38773 62H53.6123C55.8307 61.9888 57.9545 61.1203 59.5205 59.584C61.0864 58.0476 61.9674 55.9681 61.9712 53.7991V44.3864H50.3119ZM44.5472 56.3636H8.38773C7.69207 56.3636 7.02491 56.0934 6.53301 55.6125C6.04111 55.1315 5.76476 54.4792 5.76476 53.7991V5.63636H44.5472V56.3636ZM56.2064 53.7991C56.2065 54.4744 55.9341 55.1225 55.4484 55.6026C54.9627 56.0827 54.3029 56.3562 53.6123 56.3636H50.3119V50.0227H56.2064V53.7991Z" fill="#F2A950"/>
                    <path d="M38.3068 12.4564H12.0051V18.0928H38.3068V12.4564Z" fill="#F2A950"/>
                    <path d="M38.3068 23.715H12.0051V29.3513H38.3068V23.715Z" fill="#F2A950"/>
                    <path d="M24.8317 34.9736H12.0051V40.61H24.8317V34.9736Z" fill="#F2A950"/>
                </svg>,
                'title':A12Y_CONST.LABEL.INVENTORY,
                'description':A12Y_CONST.LABEL.INVENTORY_DES_DASHBOARD,
                'ViewLog':A12Y_CONST.LABEL.VIEW+' '+A12Y_CONST.LABEL.INVENTORY,
                'addScreen':A12Y_CONST.LABEL.ADD+' '+A12Y_CONST.LABEL.ASSET,
                'viewURL':A12Y_CONST.ROUTE.INVENTORY,
                'addURL':A12Y_CONST.ROUTE.ADD_ASSET,
                'IdLearnMore':A12Y_CONST.LABEL.INVENTORY+A12Y_CONST.TEXT.LEARN_MORE,
                'IdViewButton':A12Y_CONST.LABEL.INVENTORY+A12Y_CONST.TEXT.VIEW_SECTION,
                'IdADDButton':A12Y_CONST.LABEL.INVENTORY+A12Y_CONST.TEXT.ADD
            },
        ])
    },[])

    const viewScreen = (item) => {
      props.goToScreen(item)
    }

    const landingScreen = (item) => {
      props.goToLandingScreen(item)
    }

    const AddScreen = (item) => {
      props.goToAddScreen(item)
    }

    const hidetrialCountDown = () => {
        props.trialReminderBoxHide(false)

    }
    const hidePopUp = async () => {
        if(popUpType === A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD){
            props.welcomePopUpClose(false)
            if(props?.dependent?.access?.can_update){
                setIsSurveyPopUp(true)
              await  props.surveyPopUpHandle(true)
            }
        }
        if(popUpType === A12Y_CONST.LABEL.TRIAL_HAS_EXPIRED || popUpType === A12Y_CONST.LABEL.PURCHASED_PLAN_EXPIRED){
            props.welcomePopUpClose(false)
            billingScreen()
        }
    }

    const closePopUP = () => {
        props.welcomePopUpClose(false)
    }

    const billingScreen = () => {
        props.goToBillingScreen()
    }

    const hideSurveyPopUp = () => {
        setIsSurveyPopUp(false)
        props.surveyPopUpHandle(false)
    }

    const surveySectionVisible = (status) => {
          props.surveySectionVisible(status)
    }

    const skipSurveyPopUp = () => {
        setIsSurveyPopUp(false)
        props.surveyPopUpHandle(false)
    }
    const handleSurvey = (data) => {
        setIsSurveyPopUp(false)
        props.surveyPopUpHandle(false)
        props.handleSurvey(data)
    }
    return (
        <div className="positionRelative zIndex2">
            {!props?.userSubscriptionAllDetails?.is_trial_expired && props?.userSubscriptionAllDetails?.trial_period && props.isTrialReminder?
                <>
                <div className="freeTrialCountdown fullWidth">
                    <span>{A12Y_CONST.LABEL.REMINDER} {props?.userSubscriptionAllDetails?.remaining_days} {A12Y_CONST.LABEL.DAYS_LEFT}
                        {props?.dependent?.access?.can_update ? <span className='textDecoration cursorPointer paddingLeft1' onClick={() => {billingScreen()}} id={A12Y_CONST.BUTTON_ID.SUBSCRIBETEXTID}>{A12Y_CONST.LABEL.SUBSCRIBE}</span>:null}</span>
                    <span id={A12Y_CONST.BUTTON_ID.SUBSCRIBECLOSEID} className='cursorPointer' onClick={() => {hidetrialCountDown()}}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path
                            d="M6.36401 4.9502L11.314 0L12.728 1.41016L7.77802 6.36011L12.728 11.3101L11.314 12.7202L6.36401 7.77002L1.414 12.7202L0 11.3101L4.95001 6.36011L0 1.41016L1.414 0L6.36401 4.9502Z"
                            fill="white"/>
                        </svg>
                    </span>
                </div>
                </>
                :
                null
            }

            <div className="fullWith">
                {/*{windowDimensions.width > 768 ?*/}
                {/*    <h2 className="titleWithSidebarDashboard marginTop30 marginBottom80 fullWidth">*/}
                {/*        <span>{props.dependent && props.dependent.nick_name + '' + A12Y_CONST.LABEL.APOSTROPHE_S }</span><span> {A12Y_CONST.LABEL.Dashboard}</span></h2>*/}
                {/* :*/}
                {/*    <h2 className="titleWithSidebarDashboard marginTop30 marginBottom80 fullWidth">*/}
                {/*        <span>{props.dependent && A12Y_CONST.LABEL.HELLO+' '+props.dependent.first_name},</span></h2>*/}
                {/*}*/}
                <h2 className="titleWithSidebarDashboard marginTop30 marginBottom80 fullWidth">
                    <span>{props.dependent && props.dependent.nick_name + '' + A12Y_CONST.LABEL.APOSTROPHE_S }</span><span> {A12Y_CONST.LABEL.Dashboard}</span></h2>
            </div>
            <div className="fullWith quikLinkTitle">
                <h2 className="titleWithSidebar marginBottom30"><span>{A12Y_CONST.LABEL.QUICK_LINKS}</span></h2>
            </div>
            <div className="fullWidth">
            {
                screenList && screenList.length > 0 && screenList.map((item, i) => {
                    return (
                         <div key={i} className="dashboardColumMain">
                            <div className="dashboardColum">
                                <div className="dashboardColum_L">
                                    {item.icon}
                                </div>
                                <div className="dashboardColum_R">
                                    <div className="dashboardColum_R_T">
                                        <h3>{item.title}</h3>
                                        <p>{item.description} <span id={item.IdLearnMore} onClick={() => {landingScreen(item)}}>{A12Y_CONST.LABEL.LEARN_MORE}</span></p>
                                        <span id={item.IdViewButton} className="viewLog" onClick={() => {viewScreen(item)}}>{item.ViewLog}
                                <span className='arrowIcon'>
                                    <svg width="34" height="8" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M33.3536 4.35356C33.5488 4.15829 33.5488 3.84171 33.3536 3.64645L30.1716 0.464469C29.9763 0.269207 29.6597 0.269207 29.4645 0.464469C29.2692 0.659731 29.2692 0.976313 29.4645 1.17158L32.2929 4L29.4645 6.82843C29.2692 7.02369 29.2692 7.34027 29.4645 7.53554C29.6597 7.7308 29.9763 7.7308 30.1716 7.53554L33.3536 4.35356ZM-4.37114e-08 4.5L33 4.5L33 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="#F69E38"/>
                                    </svg>
                                </span>
                            </span>
                                    </div>
                                    {
                                    props.dependent?.access && props.dependent.access.can_update === true ?
                                    (item.title === A12Y_CONST.LABEL.INVENTORY && windowDimensions.width < 768) ?
                                        null
                                        :
                                        <div id={item.IdADDButton} onClick={() => {AddScreen(item)}} className="dashboardColum_R_B fullWidth">
                                        <span className="dashboardPlusIcon">
                                            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M25.25 12.5C25.25 19.1136 19.7236 24.5 12.875 24.5C6.02644 24.5 0.5 19.1136 0.5 12.5C0.5 5.88641 6.02644 0.5 12.875 0.5C19.7236 0.5 25.25 5.88641 25.25 12.5Z" stroke="#2D6CB2"/>
                                                <line x1="13.0505" y1="7.14307" x2="13.0505" y2="17.8574" stroke="#2D6CB2"/>
                                                <line x1="7.35724" y1="12.3154" x2="18.393" y2="12.3154" stroke="#2D6CB2"/>
                                            </svg>
                                         </span>
                                            <span className="cardBottomText">{item.addScreen}</span>
                                        </div>
                                        :
                                        null
                                    }
                                     </div>
                            </div>
                         </div>
                     )
                })
            }
            </div>
            <PopUpWelcomeAdvocord
                popUpStatus={isPopUp}
                //popUpStatus={true}
                dependent={props.dependent}
                userInfo={props.userInfo}
                hidePopUp={hidePopUp}
                closePopUP={closePopUP}
                // handleDeleteButton={handleDeleteButton}
                popUpType={popUpType}

            />

            <PopUpUserSurvey
                popUpStatus={props.IsSurveyRolePopUp}
                //popUpStatus={true}
                dependent={props.dependent}
                IsSurvey_PopUp={props.IsSurvey_PopUp}
                surveySectionVisible={surveySectionVisible}
                hideSurveyPopUp={hideSurveyPopUp}
                skipSurveyPopUp={skipSurveyPopUp}
                handleSurvey={handleSurvey}
                // handleDeleteButton={handleDeleteButton}
                popUpType={popUpType}

            />


        </div>
    )
}

export default  DashboardComponent;