import React, { Component } from 'react'
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions'
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from '../common/a12yConst'
import IncomeList from '../components/IncomeListComponent'
import moment from 'moment';
import {secureClient} from "../config/axiosClient";
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import WelcomeComponent from "../components/WelcomeComponent";
import {ddmmyyyyFormat} from "../util/date_format";


export class IncomeScreen extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            isIncomeAvailable: false,
            loaderFullScreenVisible : false,
            incomeList:[],
            btnTitle: this.props.dependent?.metadata?.income_present && this.props.income_present === false ? A12Y_CONST.BUTTON_TEXT.BACK_TO_INCOME : A12Y_CONST.BUTTON_TEXT.ADD_AN_INCOME
        }
    }

    componentDidMount = async() => {
        let state = { ...this.props.location.state };
        if(state?.ladingPageVisible){
            //await this.goIncomeLandingPageHanlder()
            if(this.props?.dependent?.metadata?.income_present){
                this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_INCOME})
            }else{
                this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.ADD_AN_INCOME})
            }
            this.props.updateMetaData(false,this.props.expense_present,this.props.activity_present,this.props.asset_present)
        }
        if(state?.ladingPageVisible){
            state.ladingPageVisible = false;
        }
        await this.props.currentIncomeData({})
        await this.props.updateSessionTimeout(false)
        this.setState({loading:true})
        await this.props.dataChanged(false)
        if(this.props.incomeTypeList.length === 0 || this.props.incomeTypeList[0] === undefined){
            await this.getIncomeTyp();
        }
       // await this.getMetaData()
        if(this.props.income_present){
            let types = []
            if(this.props.filterData['income'].type.length > 0){
                types = this.props.filterData['income'].type;
            }
            await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date,types)
        }else {
            this.setState({loaderFullScreenVisible:false,loading:false})
        }
    }

    getIncomeTyp = async () => {
        await this.props.getIncomeTypeList();
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,loaderFullScreen: false})
        }else {
            this.setState({message:this.props.successMessage,loaderFullScreen: false})
        }
    }

    getMetaData = async() =>{
        let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENT+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.METADATA);
        this.setState({loaderFullScreenVisible:false})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({isIncomeAvailable:response.data.sr.income_present})
            if(response.data.sr.income_present){
                let types = []
                if(this.props.filterData['income'].isModify){
                    types = this.props.filterData['income'].type;
                }
                await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date, types)
            }else {
                this.setState({loaderFullScreenVisible:false})
            }
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }

    onSearchHandler = async(date1,date2, typeList)=>{
        let startDate = await ddmmyyyyFormat(date1);
        let endDate = await ddmmyyyyFormat(date2);
        let selectedTypeList = typeList;
        this.setState({loading:true,errorMessage:'',error:false})
        let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.INCOME_BY_DATE_RANGE,{"start_date": startDate,"end_date": endDate,"income_type_ids":selectedTypeList});
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({incomeList:response.data.sr.incomes})
            // if((Object.keys(this.props.dependent).length > 0 && this.props.dependent.reporting_period.start_date !== startDate) || this.props.dependent.reporting_period.end_date !== endDate){
            //
            // }
            await this.props.setUserPreferences(startDate,endDate,this.props.dependent,this.props.dependentList,this.props.dependent.reporting_period.activity_type_ids,this.props.dependent.reporting_period.activity_tags,this.props.dependent.reporting_period.expense_type_ids,typeList)
            this.setState({loaderFullScreenVisible:false,loading:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }

    onDeleteHandler = async(income)=>{
        this.setState({loading:true,errorMessage:'',error:false})
        let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.INCOME+income.id);
        //this.setState({loading:false})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let dataList = await this.state.incomeList.filter(item => item.id !== income.id)
            this.setState({incomeList:dataList,loading:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }

    hideMessage = async ()=>{
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    onEditHandler = async(income)=>{
        await this.props.currentIncomeData(income)
        // this.props.history.push(A12Y_CONST.ROUTE.ADD_INCOME);
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_INCOME,
            state: {fromIncomeList:true }
        });
    }

    dateFormat = async(date)=>{
        return  await moment(date).format("MM/DD/YYYY")
    }

    onCreateHandler = async()=>{
        await this.props.currentIncomeData({})
        // this.props.history.push(A12Y_CONST.ROUTE.ADD_INCOME);
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_INCOME,
            state: {fromIncomeList:true }
        });
    }

    onViewHandler = async(income)=>{
        await this.props.currentIncomeData(income)
        this.props.history.push(A12Y_CONST.ROUTE.INCOME_VIEW);
    }

    goIncomeLandingPageHanlder = async ()=>{
        this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_INCOME})
        this.props.updateMetaData(!this.props.income_present,this.props.expense_present,this.props.activity_present,this.props.asset_present)
    }

    landingPageBtnEvent = async ()=>{
        if(this.props.dependent?.metadata?.income_present){
            this.props.updateMetaData(!this.props.income_present,this.props.expense_present,this.props.activity_present,this.props.asset_present)
            // await this.onSearchHandler(this.props.dependent?.reporting_period?.start_date,this.props.dependent?.reporting_period?.end_date)
            let types = []
            if(this.props.filterData['income'].type.length > 0){
                types = this.props.filterData['income'].type;
            }
            await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date,types)
        }else {
            await this.props.currentIncomeData({})
            this.props.history.push(A12Y_CONST.ROUTE.ADD_INCOME);
        }
    }
    updateSelectedFilterTypeList = async (data) => {
        await this.props.selectedFilterTypeListIncome(data)
    }

    setfilter = async (date1, date2,typeList,modify) => {
        var filter = this.props.filterData
        filter['income'].type = typeList
        filter['income'].isModify = modify
        await this.props.setSeletedFilter(filter)
        this.onSearchHandler(date1, date2,typeList,modify)
    }

    render() {
        return (
            <div className="fullWidth">
                        {this.state.loaderFullScreenVisible ?
                            <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare">
                                <div className='rightPanelWidhtSidebar'>
                                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                                        <LoaderFullScreen/>
                                    </div>
                                </div>
                            </div>
                            :
                            (this.props.income_present ?
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare">
                                        <div className='rightPanelWidhtSidebar'>
                                            <div className="rightPanelWidhtSidebarContainer fullWidth">
                                                <IncomeList
                                                    onSearch={this.onSearchHandler}
                                                    message={this.state.message}
                                                    error={this.state.error}
                                                    errorMessage={this.state.errorMessage}
                                                    incomeTypeList={this.props.incomeTypeList}
                                                    onDelete={this.onDeleteHandler}
                                                    onEdit={this.onEditHandler}
                                                    onCreate={this.onCreateHandler}
                                                    loading={this.state.loading}
                                                    dependent={this.props.dependent}
                                                    userInfo={this.props.userState.userInfo}
                                                    incomeList={this.state.incomeList}
                                                    onView={this.onViewHandler}
                                                    goIncomeLandingPage={this.goIncomeLandingPageHanlder}
                                                    setfilter={this.setfilter}
                                                    filterData={this.props.filterData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    // <NoIncome
                                    //     dependent={this.props.dependent}
                                    //     onCreate={this.onCreateHandler}
                                    // />
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare welcomeScreen">
                                        <div className='rightPanelWidhtSidebar bgWhite fullWidth marginTop12'>
                                            <div className="rightPanelWidhtSidebarContainer welcomeBg fullWidth marginNone">
                                                <WelcomeComponent
                                                    screenName={A12Y_CONST.TEXT.INCOME_STREAMS}
                                                    title={`${A12Y_CONST.TEXT.STAY_ON_TOP_OF}  ${this.props.dependent.nick_name}'s ${A12Y_CONST.TEXT.INCOME_DOT}`}
                                                    description1={A12Y_CONST.TEXT.LOGGING_AND_TRAKING_YOUR}
                                                    description2={A12Y_CONST.TEXT.IN_THIS_SECTION}
                                                    descriptionTitle={A12Y_CONST.TEXT.ADD_AN_INCOME_TO_EXPLORE}
                                                    descriptionList_1={A12Y_CONST.TEXT.QUICKLY_RECORD_AND_CLASSIFY_TYPES_OF_INCOMES}
                                                    descriptionList_2={A12Y_CONST.TEXT.REPORT_ON_TOTAL_VALUE_OF_ANNUAL_INCOME}
                                                    descriptionList_3={A12Y_CONST.TEXT.COMPARE_INCOME_FROM_YEAR}
                                                    dependent={this.props.dependent}
                                                    onCreate={this.landingPageBtnEvent}
                                                    welcomeScreenType={A12Y_CONST.TEXT.INCOME_STREAMS}
                                                    buttonTitle={this.state.btnTitle}
                                                />
                                            </div>
                                        </div>
                                    </div>
                            )
                        }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
    dependent : state.dependentReducer.dependent,
    incomeTypeList : state.dependentReducer.incomeTypeList,
    income_present: state.dependentReducer.income_present,
    expense_present: state.dependentReducer.expense_present,
    asset_present: state.dependentReducer.asset_present,
    activity_present: state.dependentReducer.activity_present,
    dependentList: state.dependentReducer.dependentList,
    filterData : state.userDetailReducer.filterData,
});

const mapDispatchToProps = dispatch => ({
    currentIncomeData : (income) => dispatch(dependentActions.currentIncomeData(income)),
    setUserPreferences : (startDate,endDate,dependent,dependentList,activeTypeList,tagList,expenseTypeList,incomeTypeList) => dispatch(dependentActions.setUserPreferences(startDate,endDate,dependent,dependentList,activeTypeList,tagList,expenseTypeList,incomeTypeList)),
    getIncomeTypeList : () => dispatch(dependentActions.getIncomeTypeList()),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    updateSessionTimeout : (status) => dispatch(userDetailActions.updateSessionTimeout(status)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    setSeletedFilter : (data) => dispatch(userDetailActions.setSeletedFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps) (IncomeScreen)