import React, { useState, useEffect}from 'react';
import A12Y_CONST from "../common/a12yConst";


function  PopUpLoggedOut (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const hidePopUp = () => {
        props.hidePopUp()
    }

    useEffect(()=>{
        setTitle(A12Y_CONST.LABEL.YOU_HAVE_BEEN_LOGGED_OUT);
        setMessage(A12Y_CONST.LABEL.TO_KEEP_YOUR_SENSITIVE);
    },[title,message, props.popUpType,])

    // useEffect(() => {
    //     console.log('window.location.pathname', window.location.pathname)
    //     props.currentSreenName(window.location.pathname)
    // },[])
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className={`popTop`}>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className="cancelIcon fullWidth marginTopBottom20">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82"
                                             viewBox="0 0 82 82" fill="none">
                                            <rect x="37.1328" y="19.3396" width="7.73585" height="31.717"
                                                  fill="#E85555"/>
                                            <circle cx="40.9999" cy="60.3395" r="4.64151" fill="#E85555"/>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M41 82C63.6437 82 82 63.6437 82 41C82 18.3563 63.6437 0 41 0C18.3563 0 0 18.3563 0 41C0 63.6437 18.3563 82 41 82ZM40.9996 75.0378C59.7982 75.0378 75.0374 59.7986 75.0374 41.0001C75.0374 22.2016 59.7982 6.96235 40.9996 6.96235C22.2011 6.96235 6.96191 22.2016 6.96191 41.0001C6.96191 59.7986 22.2011 75.0378 40.9996 75.0378Z"
                                                  fill="#E85555"/>
                                        </svg>

                                    </div>
                                    <div className={`areYouText areYouDmFont`}>
                                        {title}
                                    </div>
                                    <div className={'useInfo maxWidth480 marginTop10'}>
                                        {message}
                                    </div>
                                    <div className="fullWidth marginTop45 marginBottom20 textAlignRight">
                                        <div id={A12Y_CONST.BUTTON_ID.LOGIN_BUTTON} className=" popUpNoButton" onClick={hidePopUp}>{A12Y_CONST.BUTTON_TEXT.FORGOT_PASSWORD_UPDATE_LOGIN}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpLoggedOut;