import React,{Component} from 'react'
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions'
import SignUpEmailComponent from '../components/SignUpEmailComponent'
import A12Y_CONST from '../common/a12yConst'
import {client} from "../config/axiosClient";
import PopUpSignUpComponent from '../components/PopUpSignUpComponent'

class SiginUpWithEmailScreen extends Component {
    constructor(props){
        super(props)
        this.state = {
            error:false,
            loading:false,
            userInvitationDetail : {},
            invitation_code:'',
            loaderFullScreen:false,
            isPopUp:false,
            popUpType:'',
            PopUpMessage:'',
            urlStore:'',
            loadingFullScreen:false
        }

    }

    componentDidMount = async () => {

    }

     urlGet = async () => {
            const response = await client.get(A12Y_CONST.API_ENDPOINT.LOGIN_URL);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let docs = response.data.sr.google_login_url;
                await this.setState({urlStore:docs})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }

    }



     handelSubmit = async(data) =>{
              let response = await client.post(A12Y_CONST.API_ENDPOINT.TRACK_USER, {"email" : data.email});
                  if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                      this.props.history.push({
                      pathname: A12Y_CONST.ROUTE.SIGNUP,
                      state: { email: data.username}
                      })
                  }else {
                     await this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})

                  }

      }
    hidePopUp_Redirect = () => {
        this.setState({isPopUp:false})
    }
    render(){
        return(
            <div>
                <SignUpEmailComponent
                                   loading={this.state.loading}
                                   onSubmit={this.handelSubmit}
                                   error={this.state.error}
                                   errorMessage={this.props.errMsg}
                                   inviteUserInfo={this.state.userInvitationDetail}
                                   loaderFullScreen={this.state.loaderFullScreen}
                                    url={this.state.urlStore}
                               />

                               <PopUpSignUpComponent
                                   popUpStatus={this.state.isPopUp}
                                   popUpType={this.state.popUpType}
                                   hidePopUp = {this.hidePopUp_Redirect}
                                   PopUpMessage={this.state.PopUpMessage}
                               />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.userDetailReducer.errMsg,
    isError : state.userDetailReducer.isError,
});

const mapDispatchToProps = dispatch => ({
    signUp : (userData) => dispatch(userDetailActions.signUp(userData)),
});

export default connect(mapStateToProps,mapDispatchToProps) (SiginUpWithEmailScreen);