import React, { useState,useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useHistory } from "react-router-dom";
import PopUpIncomeExpComponent from "./PopUpIncomeExpComponent";
import parsePhoneNumber from "libphonenumber-js";


function CoOwnerDetailComponent(props) {

    const [coOwnerList, setCoOwnerList] = useState([]);
    const [isPopUp, setIsPopUp] = useState(false);
    const [coOwnerDelete, setCoOwnerDelete] = useState({})

    const history = useHistory();
    const [canUpdate, setCanUpdate] = useState(false);
    const [showEditOrAddIcon, setShowEditOrAddIcon] = useState(false);
    const [selectCoOwner, setSelectCoOwner] = useState({});


    useEffect(()=>{
        setShowEditOrAddIcon(props.showEditOrAddIcon)
    },[props.showEditOrAddIcon])


    const collapse = (elment) => {
        setCoOwnerList([])
        let elm = document.getElementById(elment);
        if(elm !== null){
            elm.parentElement.classList.toggle('opened')
            elm.classList.toggle('heightAuto')
            
        }
        /*if(!props.currentAsset.coowners){*/
            props.getCoOwnerDetails()
        /*}*/
    }

    useEffect(()=>{
        // debugger
        if(Object.keys(props.currentAsset).length > 0 && props.currentAsset.coowners && props.currentAsset.coowners.length > 0){
            setCoOwnerList(props.currentAsset.coowners)
        }else{
            if(!props.currentAsset.coowners){
                setCoOwnerList([])
            }
        }
        setCanUpdate(props?.dependent?.access?.can_update)
    },[props.currentAsset,props.currentAsset.coowners, props.dependent.access])

    const addCoOwner = async () => {
        history.push(A12Y_CONST.ROUTE.ADD_CO_OWNER)
    }

    const CoOwnerUpdate = async (item) => {
        history.push({
            pathname: A12Y_CONST.ROUTE.EDIT_CO_OWNER,
            state: {co_owner : item,
            },
        });
    }
    const deleteCoOwner =  async  (item) => {
        setCoOwnerDelete(item)
        setIsPopUp(true)
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.coOwnerDelete(coOwnerDelete)
    }

    const openCoOwner = (coOwner) => {
        if(coOwner.id === selectCoOwner.id){
            setSelectCoOwner({})
        }else{
            setSelectCoOwner(coOwner)
        }

    }
    const phoneNumberFormate = (number) => {
        let numberFormating ;
        const mobileNum = parsePhoneNumber('+'+number);
        const mobileNumberFormating = '+1 ' + mobileNum.formatNational()
        // setMobileNumberFormat(mobileNumberFormating)
        numberFormating = mobileNumberFormating
        return numberFormating;
    }
    return(
        <div className="inventoryBox fullWidth borderBottom">
            <div onClick={() => collapse('CoOwner')} className="inventoryScreenTitle fullWidth positionRelative  inventory_Col_padding">
                {A12Y_CONST.LABEL.CO_OWNERS}
                {
                    !props.coOwnerLoader && canUpdate && showEditOrAddIcon &&
                    <span id={A12Y_CONST.TEXT.ADD_CO_OWNER_ASSET} className='cursorPointer dn' onClick={addCoOwner}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0C8.552 0 9 0.4477 9 1V7H15C15.552 7 16 7.4477 16 8C16 8.5523 15.552 9 15 9H9V15C9 15.5523 8.552 16 8 16C7.448 16 7 15.5523 7 15V9H1C0.448 9 0 8.5523 0 8C0 7.4477 0.448 7 1 7H7V1C7 0.4477 7.448 0 8 0Z" fill="#2F6DB3"/>
                        </svg>
                    </span>
                }
                
                <div className="btnAddEditWrap df fcol jcc aic">
                    <span className='collape marginBottom20'>
                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.703768 0.684193C1.63973 -0.228064 3.16111 -0.228064 4.09707 0.684193L12 8.36722L19.9029 0.684193C20.8389 -0.228064 22.3603 -0.228064 23.2962 0.684193C24.2346 1.59412 24.2346 3.07333 23.2962 3.98326L13.6966 13.3158C12.7607 14.2281 11.2393 14.2281 10.3033 13.3158L0.703768 3.98326C-0.23459 3.07333 -0.23459 1.59412 0.703768 0.684193Z" fill="#565C65"/>
                        </svg>
                    </span>
                    
                </div>
            </div>
            <div id='CoOwner' className='fullWidth texAlingCener CoOwnerMainBox height0 '>

                {
                    coOwnerList.length > 0 ?
                        (
                            <>
                            {window.outerWidth > 768 ?
                                <div className='personList padding20 zIndex1 positionRelative'>
                                    {coOwnerList && coOwnerList.length > 0 && coOwnerList.map((owner,key)=>(

                                        <div key={key} className='person df fcol positionRelative'>
                                            <div className='personInfo df fullWidth'>
                                    <span className='positionAbsouteOnly paddingleft16'>
                                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.043 0C24.875 0 32.043 7.168 32.043 16C32.043 24.832 24.875 32 16.043 32C7.21097 32 0.0429688 24.832 0.0429688 16C0.0429688 7.168 7.21097 0 16.043 0ZM6.47979 21.4719C8.77765 24.8999 12.2026 27.1029 16.043 27.1969V17.6023C12.4901 17.6651 9.08136 19.0344 6.47979 21.4719ZM16.043 14.4C17.3166 14.4 18.5374 13.8882 19.4366 12.9922C20.3374 12.0962 20.843 10.88 20.843 9.6C20.843 8.32 20.3374 7.10381 19.4366 6.20781C18.5374 5.31181 17.3166 4.8 16.043 4.8C14.7694 4.8 13.5486 5.31181 12.6494 6.20781C11.7486 7.10381 11.243 8.32 11.243 9.6C11.243 10.88 11.7486 12.0962 12.6494 12.9922C13.5486 13.8882 14.7694 14.4 16.043 14.4ZM17.9454 21.2799C17.8298 21.7511 17.8298 22.2489 17.9454 22.7201L17.043 23.2266L17.9527 24.7734L18.8551 24.2578C19.2171 24.5956 19.6547 24.8445 20.1333 24.9779V26H21.9527V24.9779C22.4312 24.8445 22.8688 24.5956 23.2309 24.2578L24.1333 24.7734L25.043 23.2266L24.1406 22.7201C24.2561 22.2489 24.2561 21.7511 24.1406 21.2799L25.043 20.7734L24.1333 19.2266L23.2309 19.7422C22.8733 19.4133 22.4367 19.1555 21.9527 19.0221V18H20.1333V19.0221C19.6547 19.1555 19.2171 19.4044 18.8551 19.7422L17.9527 19.2266L17.043 20.7734L17.9454 21.2799ZM22.0082 22.9423C21.7516 23.1912 21.405 23.3333 21.043 23.3333C20.6809 23.3333 20.3343 23.1912 20.0778 22.9423C19.8222 22.6934 19.6784 22.3556 19.6784 22C19.6784 21.6444 19.8222 21.3066 20.0778 21.0577C20.3343 20.8088 20.6809 20.6667 21.043 20.6667C21.405 20.6667 21.7516 20.8088 22.0082 21.0577C22.2638 21.3066 22.4075 21.6444 22.4075 22C22.4075 22.3556 22.2638 22.6934 22.0082 22.9423Z" fill="#89B1D9"/>
                                        </svg>
                                    </span>
                                                <div className='pName texAlingLeft text24'>
                                                    <div>{owner.owner_name}</div>
                                                </div>
                                                <a className='pEmail texAlingLeft text24' href={`mailto:${owner.email}`}>{owner.email}</a>
                                                <div className='pAction mla df aic'>
                                                    {/*<a href='tel:1234567891'>*/}
                                                    {owner.phones[0].number !== '' || owner.phones[1].number !== ''?
                                                        <a href={"tel:" + owner.phones[0].number}>
                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.67963 1.58353L3.55605 0.707107C3.94658 0.316583 4.57974 0.316582 4.97026 0.707106L7.76658 3.50342C8.1571 3.89394 8.1571 4.52711 7.76658 4.91763L5.84261 6.8416C5.528 7.15621 5.45 7.63685 5.64898 8.03481C6.79925 10.3353 8.66465 12.2007 10.9652 13.351C11.3632 13.55 11.8438 13.472 12.1584 13.1574L14.0824 11.2334C14.4729 10.8429 15.1061 10.8429 15.4966 11.2334L18.2929 14.0297C18.6834 14.4203 18.6834 15.0534 18.2929 15.4439L17.4165 16.3204C15.3051 18.4317 11.9625 18.6693 9.57382 16.8777L7.94436 15.6556C6.20083 14.348 4.65201 12.7992 3.34436 11.0556L2.12226 9.42618C0.330722 7.03745 0.568273 3.69488 2.67963 1.58353Z" fill="#2D6CB2"/>
                                                            </svg>
                                                        </a>
                                                        :
                                                        null
                                                    }

                                                    {canUpdate && showEditOrAddIcon &&
                                                     <span id={A12Y_CONST.TEXT.EDIT_CO_OWNER_ASSET+key} onClick={() => {CoOwnerUpdate(owner)}} className='actDel cursorPointer marginLeft20'>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.9924 0.30829C14.403 -0.102763 15.0703 -0.102763 15.4809 0.30829L19.6913 4.51882C20.1029 4.92987 20.1029 5.5964 19.6913 6.00745L6.00729 19.6917C5.81045 19.8891 5.54203 20 5.26309 20H1.05262C0.471257 20 0 19.5287 0 18.9474V14.7368C0 14.4577 0.110946 14.1899 0.308312 13.9925L10.8346 3.4664L13.9924 0.30829ZM11.5788 5.69914L2.10524 15.1728V17.8947H4.8273L14.3009 8.42104L11.5788 5.69914ZM15.7893 6.9324L17.4587 5.26314L14.7366 2.54124L13.0672 4.21051L15.7893 6.9324Z" fill="#2D6CB2"/>
                                                        </svg>
                                                    </span>}
                                                    { canUpdate && showEditOrAddIcon &&
                                                    <span  id={A12Y_CONST.TEXT.DELETE_CO_OWNER_ASSET+key} onClick={() => {deleteCoOwner(owner)}} className='actDel cursorPointer marginLeft20'>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 2C5 0.8954 5.89543 0 7 0H13C14.1046 0 15 0.8954 15 2V4H16.9897C16.9959 3.9999 17.0021 3.9999 17.0083 4H19C19.5523 4 20 4.4477 20 5C20 5.5523 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.189 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.189 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.5523 0 5C0 4.4477 0.44772 4 1 4H2.99174C2.99795 3.9999 3.00414 3.9999 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#D96941"/>
                                                </svg>
                                            </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className='pAddress texAlingLeft marginTop5'>
                                                {owner.percentage_ownership}% Owner Since {(new Date(owner.date_of_ownership)).getFullYear()}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='personList padding20 zIndex1 positionRelative'>
                                    {coOwnerList && coOwnerList.length > 0 && coOwnerList.map((owner,key)=>(
                                        <div className='personMain'  onClick={() => {openCoOwner(owner)}} key={key} >
                                          <div key={key} className='person df fcol positionRelative'>
                                            <div className='personInfo df fullWidth'>
                                              <span className='positionAbsouteOnly paddingleft16'>
                                                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.043 0C24.875 0 32.043 7.168 32.043 16C32.043 24.832 24.875 32 16.043 32C7.21097 32 0.0429688 24.832 0.0429688 16C0.0429688 7.168 7.21097 0 16.043 0ZM6.47979 21.4719C8.77765 24.8999 12.2026 27.1029 16.043 27.1969V17.6023C12.4901 17.6651 9.08136 19.0344 6.47979 21.4719ZM16.043 14.4C17.3166 14.4 18.5374 13.8882 19.4366 12.9922C20.3374 12.0962 20.843 10.88 20.843 9.6C20.843 8.32 20.3374 7.10381 19.4366 6.20781C18.5374 5.31181 17.3166 4.8 16.043 4.8C14.7694 4.8 13.5486 5.31181 12.6494 6.20781C11.7486 7.10381 11.243 8.32 11.243 9.6C11.243 10.88 11.7486 12.0962 12.6494 12.9922C13.5486 13.8882 14.7694 14.4 16.043 14.4ZM17.9454 21.2799C17.8298 21.7511 17.8298 22.2489 17.9454 22.7201L17.043 23.2266L17.9527 24.7734L18.8551 24.2578C19.2171 24.5956 19.6547 24.8445 20.1333 24.9779V26H21.9527V24.9779C22.4312 24.8445 22.8688 24.5956 23.2309 24.2578L24.1333 24.7734L25.043 23.2266L24.1406 22.7201C24.2561 22.2489 24.2561 21.7511 24.1406 21.2799L25.043 20.7734L24.1333 19.2266L23.2309 19.7422C22.8733 19.4133 22.4367 19.1555 21.9527 19.0221V18H20.1333V19.0221C19.6547 19.1555 19.2171 19.4044 18.8551 19.7422L17.9527 19.2266L17.043 20.7734L17.9454 21.2799ZM22.0082 22.9423C21.7516 23.1912 21.405 23.3333 21.043 23.3333C20.6809 23.3333 20.3343 23.1912 20.0778 22.9423C19.8222 22.6934 19.6784 22.3556 19.6784 22C19.6784 21.6444 19.8222 21.3066 20.0778 21.0577C20.3343 20.8088 20.6809 20.6667 21.043 20.6667C21.405 20.6667 21.7516 20.8088 22.0082 21.0577C22.2638 21.3066 22.4075 21.6444 22.4075 22C22.4075 22.3556 22.2638 22.6934 22.0082 22.9423Z" fill="#89B1D9"/>
                                                    </svg>
                                               </span>
                                              <div className='pName texAlingLeft text24'>
                                                    <div>{owner.owner_name}</div>
                                                    <div className='pAddress texAlingLeft marginTop5'>
                                                        {owner.percentage_ownership}% Owner Since {(new Date(owner.date_of_ownership)).getFullYear()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            {
                                                owner.id === selectCoOwner.id ?
                                                    <div className=' df fcol positionRelative peronOtherInfo'>
                                                        {owner.email ?
                                                        <div className='hideDesktopShowMobile mobileEmail'>
                                                            <a className='pEmail texAlingLeft text24' href={`mailto:${owner.email}`}>
                                                                     <span className='contactIcon'>
                                                                         <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M0.6 0H11.4C11.5591 0 11.7117 0.0585316 11.8243 0.162718C11.9368 0.266905 12 0.408213 12 0.555556V9.44444C12 9.59179 11.9368 9.7331 11.8243 9.83728C11.7117 9.94147 11.5591 10 11.4 10H0.6C0.44087 10 0.288258 9.94147 0.175736 9.83728C0.0632141 9.7331 0 9.59179 0 9.44444V0.555556C0 0.408213 0.0632141 0.266905 0.175736 0.162718C0.288258 0.0585316 0.44087 0 0.6 0ZM6.036 4.82389L2.1888 1.79889L1.4118 2.64556L6.0438 6.28722L10.5924 2.64278L9.8076 1.80222L6.0366 4.82389H6.036Z" fill="#71767A"/>
                                                                            </svg>
                                                                     </span>
                                                                {owner.email}
                                                            </a>
                                                        </div>
                                                            :
                                                            null
                                                        }
                                                        {owner.phones !== null && (owner.phones[0].number !== '' || owner.phones[1].number !== '') ?
                                                            <div className="phoneNumberMobile">
                                                                <a href={"tel:" + owner.phones[0].number}>
                                                                 <span className='contactIcon'>
                                                                     <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.7597 8.07553L12.4061 9.72192C12.7341 10.0499 12.7341 10.5817 12.4061 10.9097C10.6329 12.6829 7.82564 12.8824 5.81946 11.3778L5.70251 11.29C4.18922 10.1551 2.84493 8.81078 1.70996 7.29749L1.62224 7.18054C0.117611 5.17436 0.317118 2.36709 2.09034 0.593866C2.41833 0.265883 2.95009 0.265883 3.27808 0.593867L4.92447 2.24026C5.315 2.63079 5.315 3.26395 4.92447 3.65447L3.78983 4.78912C3.5696 5.00935 3.515 5.34579 3.65429 5.62436C4.45948 7.23474 5.76526 8.54052 7.37564 9.34571C7.65421 9.485 7.99065 9.4304 8.21088 9.21017L9.34552 8.07553C9.73605 7.685 10.3692 7.685 10.7597 8.07553Z" fill="#71767A"/>
                                                                      </svg>
                                                                 </span>
                                                                    {owner.phones[0].number !== ''? phoneNumberFormate(owner.phones[0].number): phoneNumberFormate(owner.phones[1].number)}
                                                                </a>
                                                            </div>
                                                            :
                                                            null}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))}
                                </div>
                            }
                            </>
                        )

                        :
                        (
                        props.coOwnerLoader ?
                            <div  className='positionRelative bgColor df aic jcc fcol height50  fullWidth zIndex2 '>
                                    <div className="dependentLoader">
                                        <div className='fullWidth justifyCenter marginBottom10'>
                                            <div className="stage">
                                                <div className="dot-pulse"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='marginTop20 fullWidth'>{A12Y_CONST.TEXT.LOADING_CO_OWNERS}</div>
                            </div>
                        :
                        <div className='fullWidth texAlingCener noDataInventory'>
                            <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.1601 48.7239C30.1601 50.8298 30.7618 52.9358 31.9652 54.7408H0V48.047C0 36.9908 8.95025 28.0405 20.0064 28.0405H21.285C28.355 28.0405 34.9737 31.8011 38.5838 37.8933C33.6198 39.1719 30.1601 43.6847 30.1601 48.7239Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                <path d="M20.6827 27.0764C28.1597 27.0764 34.2209 21.0151 34.2209 13.5382C34.2209 6.06125 28.1597 0 20.6827 0C13.2058 0 7.14453 6.06125 7.14453 13.5382C7.14453 21.0151 13.2058 27.0764 20.6827 27.0764Z" fill="#2D6CB2"/>
                                <path d="M51.6562 48.0404C51.6562 54.6667 46.3049 60 39.6562 60C33.0076 60 27.6562 54.6667 27.6562 48.0404C27.6562 42.5455 31.386 37.7778 36.7373 36.404C37.7103 36.1616 38.6833 36 39.7373 36C46.2238 36.0808 51.6562 41.4141 51.6562 48.0404Z" fill="#F7F9FA"/>
                                <path d="M33.0304 49.6201C32.7704 48.5601 32.7704 47.4399 33.0304 46.3799L31 45.2402L33.0469 41.7598L35.0772 42.9199C35.8919 42.1599 36.8765 41.5998 37.9531 41.2998V39H42.0469V41.2998C43.1358 41.5998 44.1183 42.1799 44.9228 42.9199L46.9531 41.7598L49 45.2402L46.9696 46.3799C47.2296 47.4399 47.2296 48.5601 46.9696 49.6201L49 50.7598L46.9531 54.2402L44.9228 53.0801C44.1081 53.8401 43.1235 54.4002 42.0469 54.7002V57H37.9531V54.7002C36.8765 54.4002 35.8919 53.8401 35.0772 53.0801L33.0469 54.2402L31 50.7598L33.0304 49.6201ZM40 51C40.8147 51 41.5945 50.6801 42.1717 50.1201C42.7468 49.5601 43.0703 48.8 43.0703 48C43.0703 47.2 42.7468 46.4399 42.1717 45.8799C41.5945 45.3199 40.8147 45 40 45C39.1853 45 38.4055 45.3199 37.8283 45.8799C37.2532 46.4399 36.9297 47.2 36.9297 48C36.9297 48.8 37.2532 49.5601 37.8283 50.1201C38.4055 50.6801 39.1853 51 40 51Z" fill="#2D6CB2"/>
                            </svg>

                            <div className="noAddressText fullWidth marginTop5">{A12Y_CONST.TEXT.NO_CO_OWNERS}</div>
                            {'transfer' in props.currentAsset || (props.dependent?.access && props.dependent.access.can_update === false)? '' : <div className="noAddressDescription marginTop5 fullWidth">{A12Y_CONST.TEXT.ADD_CONTACT_INSTITUTION_MESSAGE}</div>}
                        </div>
                    )
                }
            </div>
            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
}

export default CoOwnerDetailComponent