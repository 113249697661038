import React,{useState,useEffect} from 'react'
import A12Y_CONST from "../common/a12yConst";
import pdf from '../assets/images/pdf.png'
import jpg from '../assets/images/jpg.png'
import jpg_icon from '../assets/images/jpg_icon.svg'
import moment from 'moment';
import PopUpIncomeExpComponent from '../components/PopUpIncomeExpComponent'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import LoaderFullScreen from "./LoaderFullScreenComponent";
import NotesComponent from "./NotesComponent";


function ExpenseViewComponent(props) {
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState('');

    const [isCommentTabVisible, setIsCommentTabVisible] = useState(true);

    function goBack() {
        props.onGoBack();
        //history.goBack();
    }

    useEffect(()=>{
        setErrors(props.error)
        setLoading(props.loading);
    },[props.error, props.loading])

    function deleteExpense() {
        setIsPopUp(true)
        // history.push("/expenses");
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.onDelete()
    }

    function editExpense() {
        props.onEdit();
        //history.push("/addExpense");
    }
    /*function cancel() {
        props.onCancel();
        // history.push("/addExpense");
    }*/

    function bytesForHuman(bytes) {
        let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

        let i = 0

        for (i; bytes > 1024; i++) {
            bytes /= 1024;
        }

        return parseFloat(bytes).toFixed(1) + ' ' + units[i]
    }

    function imgType(imgName){
        const imgExt = imgName.split('.')[1]
        let imgSrc = ''

        switch (imgExt) {
            case 'jpeg':
                imgSrc = jpg_icon
                break;

            case 'jpg':
                imgSrc = jpg_icon
                break;

            case 'pdf':
                imgSrc = pdf
                break;

            default:
                imgSrc = jpg
                break;
        }

        return imgSrc
    }

    function downloadFile(item, newName) {
        props.onDownload(item, newName)
    }

    useEffect(() => {
        if(props?.expenseObj?.web_url){
            let link = (props?.expenseObj?.web_url.indexOf('://') === -1) ? 'http://' + props.expenseObj.web_url : props.expenseObj.web_url;
            setWebsiteUrl(link)
        }else{
            setWebsiteUrl('')
        }
    },[props?.expenseObj, props?.expenseObj?.web_url])


    // Note Tab code
    const tabCheckBox = (status) => {
        setIsCommentTabVisible(status)
    }
    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 ViewExpenseMain addExpensesWrap">
            {loading ?
                <LoaderFullScreen />
                :
                null
            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h2 className="titleWithSidebar noExpenseTitle marginBottom30 addExpenseTitleError">

                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage} />
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message} />
                            :
                            null
                    )

                }
                <span id={A12Y_CONST.BUTTON_ID.EXPENSE_VIEW_BACK} className="cursorPointer" onClick={goBack}><svg className='marginRight10'     width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span>{props.isComingFromActivityView ? props.dependent.nick_name+"'s Activity" : A12Y_CONST.LABEL.EXPENSE_LABEL}</span></span>
                {props.dependent?.access && props.dependent.access.can_update === true ?
                    <div className="actionButton hideDesktopShowMobile">
                        <button className="btn-primary mobileEditViewButton saveButton viewEditButton" onClick={editExpense}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z"
                                    fill="#2D6CB2"/>
                            </svg>
                            <span className="paddingLeft1">{A12Y_CONST.LABEL.DEPENDENT_EDIT}</span>
                        </button>
                    </div>
                    :
                    null
                }
            </h2>
            <div className="dependentBorderBox dependentDefauldMinHeight pad_0_5">
                <div className="df aic marginBottom20">
                    <h4 className="dependentSubTitle"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'>'s {props.isComingFromActivityView ? 'Linked':''} Expense</span></h4>

                    <div className="mla desktopCol">
                        {props.dependent?.access && props.dependent.access.can_update === true ?
                            <>
                                <button id={A12Y_CONST.BUTTON_ID.EDIT_EXPENSE} className="btn-primary saveButton marginRight20 viewEditButton" onClick={editExpense}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z"
                                            fill="#2D6CB2"/>
                                    </svg>
                                    <span className="paddingLeft1">{A12Y_CONST.LABEL.DEPENDENT_EDIT}</span>
                                </button>
                                < button id={A12Y_CONST.BUTTON_ID.DELETE_EXPENSE} className = "cancelButton1 viewDeleteButton" onClick={deleteExpense}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D96941" />
                                    </svg>
                                    <span className="paddingLeft1">{A12Y_CONST.TEXT.DELETE}</span>
                                </button>
                            </>
                            :
                            null
                        }
                        {/*<button className="cancelButton1 " onClick={cancel}>*/}
                        {/*    /!*<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*        <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#8D9297" />*/}
                        {/*    </svg>*!/*/}
                        {/*    <span className="paddingLeft1">{A12Y_CONST.BUTTON_TEXT.CANCEL}</span>*/}
                        {/*</button>*/}
                    </div>


                </div>
                <div className="expenseDetails invBox">
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.EXPENSE_TYPE}</label>
                        <div>{props.expenseObj.expense_type}</div>
                    </div>
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.AMOUNT}</label>
                        <div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.expenseObj.amount)}</div>

                    </div>
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.PAID_TO}</label>
                        <div className='breakWords'>{props.expenseObj.paid_to}</div>
                    </div>
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.DATE}</label>
                        <div>{moment(new Date(props.expenseObj.date_of_expense)).format("MM/DD/YYYY")}</div>
                    </div>
                    <div className="df repeatResponsiveCol">
                        <div className="width50 df">
                            <label>{A12Y_CONST.LABEL.REPEAT}</label>
                            <div>{props.expenseObj.repeat_monthly? A12Y_CONST.LABEL.YES : A12Y_CONST.LABEL.NO}</div>
                        </div>
                        {props?.expenseObj?.repeat_monthly ?
                            <div className="width50 df">
                                <label>{A12Y_CONST.LABEL.END_REPEAT_BY}</label>
                                <div>{moment(new Date(props.expenseObj.end_repeat_by)).format("MM/DD/YYYY")}</div>
                            </div>
                            :
                            null
                        }
                    </div>
                    {websiteUrl ?
                        <div className="df">
                            <a id={A12Y_CONST.TEXT.LINK_URL} rel="noreferrer" className='linkToWebsite df alignCenterVH' target="_blank" href={websiteUrl}>
                                <svg className='marginRight10' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <path d="M9.16667 18.3333C4.10392 18.3333 0 14.2294 0 9.16667C0 4.10392 4.10392 0 9.16667 0C14.2294 0 18.3333 4.10392 18.3333 9.16667C18.3333 14.2294 14.2294 18.3333 9.16667 18.3333ZM7.0675 16.1947C6.16313 14.2765 5.63922 12.201 5.52475 10.0833H1.89017C2.06854 11.494 2.65276 12.8225 3.57185 13.9074C4.49095 14.9923 5.70535 15.7869 7.0675 16.1947ZM7.36083 10.0833C7.49925 12.3191 8.13817 14.4192 9.16667 16.2727C10.223 14.3702 10.84 12.2554 10.9725 10.0833H7.36083ZM16.4432 10.0833H12.8086C12.6941 12.201 12.1702 14.2765 11.2658 16.1947C12.628 15.7869 13.8424 14.9923 14.7615 13.9074C15.6806 12.8225 16.2648 11.494 16.4432 10.0833ZM1.89017 8.25H5.52475C5.63922 6.1323 6.16313 4.05688 7.0675 2.13858C5.70535 2.54639 4.49095 3.34102 3.57185 4.42594C2.65276 5.51085 2.06854 6.83934 1.89017 8.25ZM7.36175 8.25H10.9716C10.8394 6.07805 10.2226 3.96324 9.16667 2.06067C8.11037 3.96316 7.49334 6.07798 7.36083 8.25H7.36175ZM11.2658 2.13858C12.1702 4.05688 12.6941 6.1323 12.8086 8.25H16.4432C16.2648 6.83934 15.6806 5.51085 14.7615 4.42594C13.8424 3.34102 12.628 2.54639 11.2658 2.13858Z" fill="#2F6DB3"/>
                                </svg>
                                {A12Y_CONST.TEXT.LINK_TO_WEBSITE}</a>
                        </div>
                        :
                        null
                    }
                   {/* <div className="df">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className='breakWords noteText ayScrollBar'>{
                            props?.expenseObj?.note?.length > 0 && props?.expenseObj?.note?.split('\n').map((n, i)=> {
                                return <div key={i}>{n}</div>
                            })}
                        </div>
                    </div>*/}
                    <div className="df commentSetionViewScreen">
                        <label>{A12Y_CONST.TEXT.OTHER_SURVEY}
                            <span className='showLabel'>{A12Y_CONST.TEXT.SHOW}</span>
                        </label>
                        {/*  <div className='breakWords noteText ayScrollBar'>{
                            props?.incomeObj?.note?.length > 0 && props?.incomeObj?.note?.split('\n').map((n, i)=> {
                            return <div key={i}>{n}</div>
                        })}</div>*/}
                        <div className='fullWidth'>
                            <div className='fullWidth marginBottom20 marginTop35 commentMarginResponsive'>
                                <div id={A12Y_CONST.LABEL.ATTACHMENTS_SMALL+A12Y_CONST.LABEL.EXPENSE_LABEL+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(true)}} className={`commentExpenseTab ${isCommentTabVisible?'activecommentExpenseTab':''}`}>{A12Y_CONST.LABEL.ATTACHMENTS_SMALL}</div>
                                <div id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.LABEL.EXPENSE_LABEL+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(false)}}  className={`commentExpenseTab  ${isCommentTabVisible ? '' : 'activecommentExpenseTab'}`}>{A12Y_CONST.LABEL.NOTES}</div>
                            </div>
                            <div className="fullWidth tabContentBox">
                                {isCommentTabVisible?
                                    <>
                                        {props?.expenseObj?.document_ref?.attached_files?.length > 0?
                                            <div className='fullWidth'>
                                                <div className="fileList attachedImgList fullWidth p0">
                                                    {props.expenseObj && Object.keys(props.expenseObj).length > 0 && Object.keys(props.expenseObj.document_ref).length > 0 && props.expenseObj.document_ref.attached_files.length > 0 && props.expenseObj.document_ref.attached_files.map((item,key)=>(
                                                        <div className='fileInner' key={key}>
                                                            <div className="file df aic" onClick={()=>downloadFile(item, `${moment(new Date(props.expenseObj.date_of_expense)).format("MM/DD/YYYY")}_${props.expenseObj.expense_type}_${item.name}`)}>
                                                                <div className="fileType cursorPointer">
                                                                    <img width="44" height="50" src={imgType(item.name)} alt='logo' />
                                                                </div>
                                                                <div className="fileInfo cursorPointer">
                                                                    <h5>{item.name}</h5>
                                                                    <div className="df">
                                                                        {/*<span className="paddingRight16">{item?.upload_date ? dateFormatting(item.upload_date) : null}</span>
                                                <span className="paddingRight16 fileDot"><svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3.5" r="3" fill="#565C65" /></svg></span>*/}
                                                                        <span>{bytesForHuman(item.size)}</span>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="fileAction df aic">
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 2.76328C6 1.43776 7.07452 0.363281 8.4 0.363281H15.6C16.9255 0.363281 18 1.43776 18 2.76328V5.16328H20.3876C20.3951 5.16316 20.4025 5.16316 20.41 5.16328H22.8C23.4628 5.16328 24 5.70052 24 6.36328C24 7.02604 23.4628 7.56328 22.8 7.56328H21.5173L20.4766 22.1343C20.3868 23.3901 19.3418 24.3633 18.0827 24.3633H5.91734C4.65821 24.3633 3.61315 23.3901 3.52344 22.1343L2.48266 7.56328H1.2C0.537264 7.56328 0 7.02604 0 6.36328C0 5.70052 0.537264 5.16328 1.2 5.16328H3.59009C3.59754 5.16316 3.60497 5.16316 3.61238 5.16328H6V2.76328ZM8.4 5.16328H15.6V2.76328H8.4V5.16328ZM4.88878 7.56328L5.91734 21.9633H18.0827L19.1112 7.56328H4.88878ZM9.6 9.96328C10.2628 9.96328 10.8 10.5005 10.8 11.1633V18.3633C10.8 19.026 10.2628 19.5633 9.6 19.5633C8.93726 19.5633 8.4 19.026 8.4 18.3633V11.1633C8.4 10.5005 8.93726 9.96328 9.6 9.96328ZM14.4 9.96328C15.0628 9.96328 15.6 10.5005 15.6 11.1633V18.3633C15.6 19.026 15.0628 19.5633 14.4 19.5633C13.7372 19.5633 13.2 19.026 13.2 18.3633V11.1633C13.2 10.5005 13.7372 9.96328 14.4 9.96328Z" fill="#F2938C" />
                                            </svg>
                                        </div>*/}
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {/*<div className="file df">
                                <div className="fileType">
                                    <img width="44" height="50" src={icoPdf} alt='logo' />
                                </div>
                                <div className="fileInfo">
                                    <h5>Abc name.pdf</h5>
                                    <div className="df">
                                        <span className="paddingRight16">16 sep, 2021 at 11:05</span>
                                        <span className="paddingRight16 fileDot"><svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3.5" r="3" fill="#565C65" /></svg></span>
                                        <span>4.8 Mb</span>
                                    </div>
                                </div>
                                <div className="fileAction df aic">
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 2.76328C6 1.43776 7.07452 0.363281 8.4 0.363281H15.6C16.9255 0.363281 18 1.43776 18 2.76328V5.16328H20.3876C20.3951 5.16316 20.4025 5.16316 20.41 5.16328H22.8C23.4628 5.16328 24 5.70052 24 6.36328C24 7.02604 23.4628 7.56328 22.8 7.56328H21.5173L20.4766 22.1343C20.3868 23.3901 19.3418 24.3633 18.0827 24.3633H5.91734C4.65821 24.3633 3.61315 23.3901 3.52344 22.1343L2.48266 7.56328H1.2C0.537264 7.56328 0 7.02604 0 6.36328C0 5.70052 0.537264 5.16328 1.2 5.16328H3.59009C3.59754 5.16316 3.60497 5.16316 3.61238 5.16328H6V2.76328ZM8.4 5.16328H15.6V2.76328H8.4V5.16328ZM4.88878 7.56328L5.91734 21.9633H18.0827L19.1112 7.56328H4.88878ZM9.6 9.96328C10.2628 9.96328 10.8 10.5005 10.8 11.1633V18.3633C10.8 19.026 10.2628 19.5633 9.6 19.5633C8.93726 19.5633 8.4 19.026 8.4 18.3633V11.1633C8.4 10.5005 8.93726 9.96328 9.6 9.96328ZM14.4 9.96328C15.0628 9.96328 15.6 10.5005 15.6 11.1633V18.3633C15.6 19.026 15.0628 19.5633 14.4 19.5633C13.7372 19.5633 13.2 19.026 13.2 18.3633V11.1633C13.2 10.5005 13.7372 9.96328 14.4 9.96328Z" fill="#F2938C" />
                                    </svg>
                                </div>
                            </div>
                            <div className="file df">
                                <div className="fileType">
                                    <img width="44" height="50" src={icoJpg} alt='logo' />
                                </div>
                                <div className="fileInfo">
                                    <h5>Abc name.pdf</h5>
                                    <div className="df">
                                        <span className="paddingRight16">16 sep, 2021 at 11:05</span>
                                        <span className="paddingRight16 fileDot"><svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3.5" r="3" fill="#565C65" /></svg></span>
                                        <span>4.8 Mb</span>
                                    </div>
                                </div>
                                <div className="fileAction df aic">
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 2.76328C6 1.43776 7.07452 0.363281 8.4 0.363281H15.6C16.9255 0.363281 18 1.43776 18 2.76328V5.16328H20.3876C20.3951 5.16316 20.4025 5.16316 20.41 5.16328H22.8C23.4628 5.16328 24 5.70052 24 6.36328C24 7.02604 23.4628 7.56328 22.8 7.56328H21.5173L20.4766 22.1343C20.3868 23.3901 19.3418 24.3633 18.0827 24.3633H5.91734C4.65821 24.3633 3.61315 23.3901 3.52344 22.1343L2.48266 7.56328H1.2C0.537264 7.56328 0 7.02604 0 6.36328C0 5.70052 0.537264 5.16328 1.2 5.16328H3.59009C3.59754 5.16316 3.60497 5.16316 3.61238 5.16328H6V2.76328ZM8.4 5.16328H15.6V2.76328H8.4V5.16328ZM4.88878 7.56328L5.91734 21.9633H18.0827L19.1112 7.56328H4.88878ZM9.6 9.96328C10.2628 9.96328 10.8 10.5005 10.8 11.1633V18.3633C10.8 19.026 10.2628 19.5633 9.6 19.5633C8.93726 19.5633 8.4 19.026 8.4 18.3633V11.1633C8.4 10.5005 8.93726 9.96328 9.6 9.96328ZM14.4 9.96328C15.0628 9.96328 15.6 10.5005 15.6 11.1633V18.3633C15.6 19.026 15.0628 19.5633 14.4 19.5633C13.7372 19.5633 13.2 19.026 13.2 18.3633V11.1633C13.2 10.5005 13.7372 9.96328 14.4 9.96328Z" fill="#F2938C" />
                                    </svg>
                                </div>
                            </div>
                            <div className="file df">
                                <div className="fileType">
                                    <img width="44" height="50" src={icoJpg} alt='fileType' />
                                </div>
                                <div className="fileInfo">
                                    <h5>Abc name.pdf</h5>
                                    <div className="df">
                                        <span className="paddingRight16">16 sep, 2021 at 11:05</span>
                                        <span className="paddingRight16 fileDot"><svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3.5" r="3" fill="#565C65" /></svg></span>
                                        <span>4.8 Mb</span>
                                    </div>
                                </div>
                                <div className="fileAction df aic">
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 2.76328C6 1.43776 7.07452 0.363281 8.4 0.363281H15.6C16.9255 0.363281 18 1.43776 18 2.76328V5.16328H20.3876C20.3951 5.16316 20.4025 5.16316 20.41 5.16328H22.8C23.4628 5.16328 24 5.70052 24 6.36328C24 7.02604 23.4628 7.56328 22.8 7.56328H21.5173L20.4766 22.1343C20.3868 23.3901 19.3418 24.3633 18.0827 24.3633H5.91734C4.65821 24.3633 3.61315 23.3901 3.52344 22.1343L2.48266 7.56328H1.2C0.537264 7.56328 0 7.02604 0 6.36328C0 5.70052 0.537264 5.16328 1.2 5.16328H3.59009C3.59754 5.16316 3.60497 5.16316 3.61238 5.16328H6V2.76328ZM8.4 5.16328H15.6V2.76328H8.4V5.16328ZM4.88878 7.56328L5.91734 21.9633H18.0827L19.1112 7.56328H4.88878ZM9.6 9.96328C10.2628 9.96328 10.8 10.5005 10.8 11.1633V18.3633C10.8 19.026 10.2628 19.5633 9.6 19.5633C8.93726 19.5633 8.4 19.026 8.4 18.3633V11.1633C8.4 10.5005 8.93726 9.96328 9.6 9.96328ZM14.4 9.96328C15.0628 9.96328 15.6 10.5005 15.6 11.1633V18.3633C15.6 19.026 15.0628 19.5633 14.4 19.5633C13.7372 19.5633 13.2 19.026 13.2 18.3633V11.1633C13.2 10.5005 13.7372 9.96328 14.4 9.96328Z" fill="#F2938C" />
                                    </svg>
                                </div>
                            </div>*/}
                                                </div>

                                            </div>
                                            :
                                            <div className='noData inventoryNote df aic jcc fcol'>
                                                <strong>{A12Y_CONST.TEXT.NO_DATA_FOUND}</strong>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className='fullWidth'>
                                        <NotesComponent
                                            noteType={A12Y_CONST.LABEL.NOTE_EXPENSE_TYPE}
                                            selectedItem={props?.expenseObj}
                                            dependent={props.dependent}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="df">
                        {/*<label className="df">{A12Y_CONST.LABEL.EXPENSE_ATTACHMENT}</label>*/}
                        {props.dependent?.access && props.dependent.access.can_update === true ?
                            <div className="actionButton hideDesktopShowMobile marginTop20 mobileDeleteViewButton">
                                < button className = "cancelButton1 viewDeleteButton" onClick={deleteExpense}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D96941" />
                                    </svg>
                                    <span className="paddingLeft1">{A12Y_CONST.LABEL.DELETE_EXPENSE}</span>
                                </button>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            {/*<button className="cancelButton1 marginTop10 marginRight10 floatRight mobileCol" onClick={cancel}>*/}
            {/*    /!*<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                    <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#8D9297" />*/}
            {/*                </svg>*!/*/}
            {/*    <span className="paddingLeft1">Cancel</span>*/}
            {/*</button>*/}

            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
                popUpTypeExpense={props.isComingFromActivityView ?A12Y_CONST.LABEL.EXPENSE:''}
            />
        </div>
    )
}

export default ExpenseViewComponent
