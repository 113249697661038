import React, { Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import * as dependentActions from "../redux/actions/dependentActions";
import AddressComponent from "../components/AddressComponent";
import {saveAddressDetails} from "../util/AssetCommonApi";
import * as userDetailActions from "../redux/actions/userDetailActions";




class AddAddressScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
        }
    }


    onSubmitHandler = async (obj)=>{
        try {
            this.setState({ loading: true })
            if(Object.keys(this.props.currentAsset).length> 0){
                let response = await saveAddressDetails(this.props.dependent.id, this.props.currentAsset.id, obj)
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.setState({ loading: false })
                    this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
                } else {
                    this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false  })
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.ADD_ADDRESS,e)
        }
    }

    onCancelHandler = ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    render() {
        const {loading,error,errorMessage,loaderFullScreen} = this.state
        return (
            <div className='myProfileAccount animationall mainRightSidebare' onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddressComponent
                            title={A12Y_CONST.LABEL.LOCATION}
                            titlePrefix ={''}
                            loading={loading}
                            error={error}
                            errorMessage={errorMessage}
                            loaderFullScreen={loaderFullScreen}
                            onSubmit={this.onSubmitHandler}
                            onCancel={this.onCancelHandler}
                            dependent={this.props.dependent}
                            BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                            isDataChanged={this.dataChangeHandler}
                            isClickedOutside={this.state.isClickedOutside}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    currentAsset : state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
});


export default connect(mapStateToProps, mapDispatchToProps) (AddAddressScreen);

