import React ,{useEffect, useState}from 'react'
import A12Y_CONST from '../common/a12yConst'
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import 'react-phone-input-2/lib/style.css'
import PopUpInviteUserComponent from '../components/PopUpInviteUserComponent'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'


function InviteUserComponent(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const {register,handleSubmit,setError, setFocus ,formState:{ errors, isSubmitting},setValue,getValues} = useForm({mode:"all"});
    const [email, setEmail] = useState('');
    const [isPopUp, setIsPopUp] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [popUpStatus, setPopUpStatus] = useState('')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');


    useEffect(()=>{
        if(props.advocateTeamUpdate){
            setEditData()
        }
    },[props.advocateTeamUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
        if(props.loading){
            setIsBtnDisabled(true)
        }
    },[props.loading,props.error])

    useEffect(() => {
        if(props.successPop){
            successPop()
        }
    },[props.successPop])

    const handleEmail = (value) => {
        if(value.length === 1 && value === " "){
            setEmail(value.trim());
        }else{
            setEmail(value.trim());
        }
    }

    useEffect(() => {
        setFocus("first_name");
    }, [setFocus])

    const onCancel = ()=>{
        if(isDataModified){
            setPopUpStatus(A12Y_CONST.TEXT.CANCEL_INVITE)
            setIsPopUp(true)
        }else {
            props.onCancel();
        }

    }

    const onSubmit = ()=>{
        let obj={};
        if(props.advocateTeamUpdate){
        obj = {
            "invitee_first_name" : getValues('first_name'),
            "invitee_last_name" : getValues('last_name'),
            "invitee_email" : getValues('email'),
            "invitation_id" : props.advocateTeamUpdate.id,
        }
        }else{
        obj = {
            "invitee_first_name" : getValues('first_name'),
            "invitee_last_name" : getValues('last_name'),
            "invitee_email" : getValues('email')
            }
        }
        props.onSubmit(obj)
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const hidePopUp_Redirect = () => {
        setIsPopUp(false)
        props.onDonSuccessButton()
    }
    const handleDeleteButton = (popUptype) => {
        props.isDataChanged(false)
        setIsPopUp(false)
        props.onCancel()
    }
    const successPop = () => {
        setPopUpStatus(A12Y_CONST.TEXT.SUCCESSFULLY_INVITE)
        setIsPopUp(true)
    }
    const handleChangeFirstName = (value) => {
        if(value.trim().length === 0){
            setFirstName('');
        }else{
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setFirstName(value);
            }
        }
    }
    const handleChangeLastName = (value) => {
        if(value.trim().length === 0){
            setLastName('');
        }else{
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setLastName(value);
            }
        }
    }

    const setEditData = async () => {
        setValue('first_name',props.advocateTeamUpdate.invitee_first_name)
        setValue('last_name',props.advocateTeamUpdate.invitee_last_name)
        setValue('email',props.advocateTeamUpdate.invitee_email)
        setFirstName(props.advocateTeamUpdate.invitee_first_name);
        setLastName(props.advocateTeamUpdate.invitee_last_name);
        setEmail(props.advocateTeamUpdate.invitee_email);
        setEditMode(true)
    }

    useEffect( ()=>{
        const checkFormDataIsChanged = async ()=>{
            setIsBtnDisabled(true)
            let inviteData = props.advocateTeamUpdate;
            if(editMode && Object.keys(inviteData).length > 0){
                props.isDataChanged(false)
                setIsDataModified(false)
                if(inviteData.invitee_first_name !== firstName || inviteData.invitee_last_name !== lastName || inviteData.invitee_email !== email){
                    if((firstName!== undefined && firstName !== "") && (lastName !==undefined && lastName !=="") && (email !== undefined && email !== "")){
                        if(await emailValidate(email)){
                            setIsBtnDisabled(false)
                        }
                    }
                    props.isDataChanged(true)
                    setIsDataModified(true)
                }else {
                    if((firstName!== undefined && firstName !== "") && (lastName !==undefined && lastName !=="") && (email !== undefined && email !== "")){
                        if(await emailValidate(email)){
                            setIsBtnDisabled(false)
                        }
                    }
                }
            }else {
                props.isDataChanged(false)
                setIsDataModified(false)
                if((firstName!== undefined && firstName !== "") || (lastName !==undefined && lastName !=="") || (email !== undefined && email !== "")){
                    if((firstName!== undefined && firstName !== "") && (lastName !==undefined && lastName !=="") && (email !== undefined && email !== "")){
                        if(await emailValidate(email)){
                            setIsBtnDisabled(false)
                        }
                    }
                    props.isDataChanged(true)
                    setIsDataModified(true)
                }
            }
        }
        checkFormDataIsChanged()
    },[firstName,lastName,email,editMode]) // eslint-disable-line react-hooks/exhaustive-deps

    const emailValidate = async (email)=>{
        //eslint-disable-next-line
        let regex = /^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return regex.test(email)
    }


    return(
        <div className="dependentBorderBox">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className="fullWidth DependentStep1FormCol paddingBottom100 positionRelative">
            <div className="dependentSubTitle marginBottom10">
                {A12Y_CONST.TEXT.INVITE_VIEW_ONLY}
            </div>

            {error ?
                <div className='maxWidth600 marginLeftRightAuto'>
                    <ErrorMessageComponent errorMessage={props.errorMessage}/>
                </div>
                :
                (props.message ?
                        <SuccessMessageComponent successMessage={props.message}/>

                        :
                        null
                )

            }
            <form onSubmit={handleSubmit(onSubmit)} className='inviteUser marginTop30 responsiveMarginTop50fullWidth' autoComplete="off">
                    <div className='dependentInviteuserFormRow fullWidth dependentRowSpace positionRelative'>
                        <label className='labelCol'>{A12Y_CONST.LABEL.GAURDIAN_PROFILE_PERSONAL_INFO_NAME}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className='fullWidth'>
                            <div className='width50 paddingRight16 myProfile_personal_FirstName600'>
                                <input
                                    {...register('first_name', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_REQUIRED, pattern:{
                                        value:/^.{1,}$/,
                                        //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_INVALID
                                    }})}
                                    placeholder='First Name'
                                    className={errors.first_name ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    type='text'
                                    id='userFirstName'
                                    onChange={(e) => handleChangeFirstName(e.target.value)}
                                    value={firstName}
                                    maxLength="100"
                                    // onBlur={onFirstNameBlur}
                                    onFocus={()=>{
                                        setError("first_name", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />
                                <div className='errorRelativeRow'> {errors.first_name && <p className='errorMessage'>{errors.first_name.message}</p>}</div>
                            </div>
                            <div className='width50 paddingleft16 responsive6000LeftRight'>
                                <input
                                    {...register('last_name', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_REQUIRED , pattern:{
                                        value:/^.{1,}$/,
                                        //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_INVALID
                                    }})}
                                    placeholder='Last Name'
                                    className={errors.last_name ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    type='text'
                                    maxLength="100"
                                    id='userLastName'
                                    onChange={(e) => handleChangeLastName(e.target.value)}
                                    value={lastName}
                                    onFocus={()=>{
                                        setError("last_name", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />
                                <div className='errorRelativeRow'>{errors.last_name && <p className='errorMessage'>{errors.last_name.message}</p>}</div>
                            </div>
                        </div>
                    </div>
                    <div className='dependentInviteuserFormRow fullWidth dependentRowSpace positionRelative'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.DEPENDENT_EMAIL}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className='fullWidth'>
                        <input
                            {...register('email', { required: A12Y_CONST.ERROR_MESSAGE.EMAIL_REQUIRED , pattern:{
                                    //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                    //eslint-disable-next-line
                                    value:/^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_USERNAME_INVALID
                                }
                            })}
                            placeholder='name@example.com'
                            className={errors.email? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                            type='text'
                            maxLength='255'
                            id='displayName'
                            onChange={(e) => handleEmail(e.target.value)}
                            value={email}
                            onFocus={()=>{
                                setError("email", {
                                    type: "manual",
                                    message: "",
                                });
                            }}
                        />
                        <div className='errorRelativeRow'> {errors.email && <p className='errorMessage'>{errors.email.message}</p>}</div>
                    </div>
                        <div className='infoBoxInviteUser marginBottom10 marginTop30'>
                                <span className='infoIconInviteUser'>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 12C15.5817 12 12 15.582 12 20C12 24.418 15.5817 28 20 28C24.4183 28 28 24.418 28 20C28 15.582 24.4183 12 20 12ZM10 20C10 14.477 14.4771 10 20 10C25.5228 10 30 14.477 30 20C30 25.523 25.5228 30 20 30C14.4771 30 10 25.523 10 20Z" fill="#71767A"/>
                                        <path d="M20 18C20.5523 18 21 18.448 21 19V25C21 25.552 20.5523 26 20 26C19.4477 26 19 25.552 19 25V19C19 18.448 19.4477 18 20 18Z" fill="#71767A"/>
                                        <path d="M21.5 15.5C21.5 16.328 20.8284 17 20 17C19.1716 17 18.5 16.328 18.5 15.5C18.5 14.672 19.1716 14 20 14C20.8284 14 21.5 14.672 21.5 15.5Z" fill="#71767A"/>
                                    </svg>
                                </span>
                            <span className='infoTextInviteUser'>{A12Y_CONST.TEXT.INVITE_USER_MESSAGE}</span>
                        </div>
                </div>
                    <div className="fullWidth textAlignRight positionAbsouteBottom displayFlexCol" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.INVITE_USER_CANCEL} className={!isSubmitting ? "cancelButton1 buttonlaerUp" :'cancelButton1 buttonlaerUp'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL}/></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={props.advocateTeamUpdate.id ? A12Y_CONST.BUTTON_ID.INVITE_USER_RESEND : A12Y_CONST.BUTTON_ID.INVITE_USER_SEND} className={loading || isBtnDisabled ? "saveButtonDisable buttonlaerUp" :'saveButton buttonlaerUp'} disabled={loading || isBtnDisabled}  type="submit" value={props.advocateTeamUpdate.id ? A12Y_CONST.BUTTON_TEXT.RESEND_INVITE: A12Y_CONST.BUTTON_TEXT.SEND_INVITE}/>
                            </div>
                        </div>
                    </div>

            </form>
            </div>
            <PopUpInviteUserComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                userEmail={email}
                userFirstName={firstName}
                //popUpType={A12Y_CONST.TEXT.SUCCESSFULLY_INVITE}
                //popUpType={A12Y_CONST.TEXT.CANCEL_INVITE}
                //popUpType={A12Y_CONST.TEXT.DELETE_INVITE}
                //popUpType={A12Y_CONST.TEXT.RESEND_INVITE}
                popUpType={popUpStatus}
                hidePopUp_Redirect = {hidePopUp_Redirect}
                handleDeleteButton ={handleDeleteButton}
            />
        </div>
    )
}

export default InviteUserComponent;