import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import exclamationImg from '../assets/images/exclamationErrorIcon.svg'
import Loading from './LoadingComponent'
import { connect } from 'react-redux';
import A12Y_CONST from '../common/a12yConst'
import { withRouter } from "react-router";

function LoginForm (props) {
    const [passwordShown, setPasswordShown] = useState(false);
    const [error, setErrors] = useState(false);
    /* eslint-disable no-unused-vars */
    const [sameUserLoggedIn, setSameUserLoogedIn] = useState(false);
    /* eslint-disable no-unused-vars */
    const [loading, setLoading] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [isUserNameError, setIsUserNameError] = useState(false)
    const {register,handleSubmit, setFocus, setError,formState:{ errors},getValues} = useForm({mode:"all"});

    const onSubmit = async (data) => {
        setIsBtnDisabled(true);
        await props.onSubmit(data)
        await setIsBtnDisabled(false)
    };

    useEffect( () => {
        async function userEmailBlur() {
            if(props.urlAuthCode){
                document.getElementById('userEmail').blur();
                // document.activeElement.blur();
            }else{
                setTimeout(() => setFocus('username'), 0)
                setFocus("username");
            }
        }
        userEmailBlur()
    }, [setFocus, props.urlAuthCode])

    useEffect( () => {
        setLoading(props.loder)
        setErrors(props.errorMessage)
        // if(props.errorMessage){
        //     setIsBtnDisabled(false);
        // }
    }, [props.loder,props.errorMessage])


    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    },[])

    const togglePasswordVisibility = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const goToSignUpScreen = () => {
        props.history.push(A12Y_CONST.ROUTE.SIGNUP);
    }
    const goToForgotPasswordEmailScreen = () => {
        // let email = getValues("username")
        // if(email.includes("+")){
        let email = getValues("username") !== undefined && getValues("username") !== null ? getValues("username") : '';
        if(email && email?.length > 0 && email.includes("+")){
            email =  email.replace("+","%2B")
        }
        props.history.push({
            pathname : A12Y_CONST.ROUTE.FORGOT_PASSWORD_EMAIL,
            search: '?uname='+email,
        })
        // props.history.push(A12Y_CONST.ROUTE.FORGOT_PASSWORD_EMAIL);
    }
    // const emailInput = useCallback((inputElement) => {
    //     if (inputElement) {
    //         inputElement.focus();
    //     }
    // }, []);

    const handleGoogleLogin = async () => {
        await props.signInUrlCheck()
        window.location.href=props.url;
    }
    return (
        <div>
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className="fullWidth">
                <div className={sameUserLoggedIn ? 'signColCenter signInformPaddingTop' :'signColCenter fullWidth'}>
                    <h3 className='title marginBottom5'>{A12Y_CONST.LABEL.LOGIN}</h3>
                    {error &&
                    <div className="positionRelative fullWidth">
                        <div className="errorMessageWithBackground positionAbsoute">
                            <div className="exclamationErrorIcon"><img alt={''} src={exclamationImg}/></div>
                            <div className='errorTextContent'>{props.message}</div>
                        </div>
                    </div>
                    }
                    <form className='marginTop50 signUpFlow fullWidth'>
                        <div className='fullWidth marginBottomInputCol'>
                            <label>{A12Y_CONST.LABEL.EMAIL}</label>
                            <input
                                {...register('username', { required: A12Y_CONST.ERROR_MESSAGE.EMAIL_REQUIRED, pattern:{
                                        //    value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{0,61}$/,
                                        //eslint-disable-next-line
                                        value:/^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNIN_USERNAME_INVALID
                                    }})}
                                placeholder='Email'
                                className={errors.username && getValues('username').length > 0  ? 'inputColum errorInputBorderRed': 'inputColum'}
                                type='text'
                                id='userEmail'
                                // ref={emailInput}
                                onBlur={() => {
                                    if(getValues('username') === ''){
                                        setError("username", {
                                            type: "manual",
                                            message: '',
                                        });
                                    }
                                    setIsUserNameError(true)}
                                }
                                onFocus={()=>{
                                    setIsUserNameError(false)
                                    /*setError("username", {
                                        type: "manual",
                                        message: "",
                                    });*/
                                }}
                            />
                            <div className='errorRelativeRow'>{errors.username && isUserNameError && <p className='errorMessage'>{errors.username.message}</p>}</div>
                        </div>
                        {/* register an input */}
                        <div className={'fullWidth positionRelative'}>
                            <label>{A12Y_CONST.LABEL.PASSWORD}</label>
                            <input
                                {...register('password')}
                                id='userPassword'
                                placeholder='Password'
                                className={errors.password ? 'inputColum PaddingPasswordIcon errorInputBorderRed': 'PaddingPasswordIcon inputColum'}
                                type={passwordShown ? "text" : "password"}
                                onFocus={()=>{
                                    setError("password", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div id={A12Y_CONST.BUTTON_ID.PASSWORD_SHOW_HIDE} className="passwordTextShow" onClick={togglePasswordVisibility}>
                                {
                                    passwordShown ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                            <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#2D6CB2"/>
                                            <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#2D6CB2"/>
                                        </svg>
                                        :
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#2D6CB2"></path>
                                            <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#2D6CB2"></path>
                                        </svg>
                                }

                            </div>
                            <div className='errorRelativeRow'>{errors.password && <p className='errorMessage'>{errors.password.message}</p>}</div>
                        </div>
                        <div className="forgotPasswordLink textAlignRight marginBottom40 fullWidth">
                            <span id={A12Y_CONST.BUTTON_ID.FORGOT_PASSWORD_SIGNIN} className='forgotPasswordLinkText' onClick={goToForgotPasswordEmailScreen}>{A12Y_CONST.LABEL.SIGNIN_FORGOT_PASSWORD}</span>
                        </div>


                        <div className='fullWidth texAlingCener'>
                            <div className='marginTop25 loaderSpinner8radius'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.EMAILLOGINBUTTON} className={isBtnDisabled ? "buttonDisable" :'button'} disabled={isBtnDisabled}  type="button" onClick={handleSubmit(onSubmit)} value={!loading ? A12Y_CONST.BUTTON_TEXT.SIGNIN_SINGIN : A12Y_CONST.BUTTON_TEXT.SIGNIN_SIGNING} />
                            </div>
                        </div>
                    </form>
                    <div className='joinNowText fullWidth marginBottom20'>
                        {A12Y_CONST.LABEL.SINGIN_DONT_HAVE_AN} <span id={A12Y_CONST.BUTTON_ID.JOINNOWBUTTON} onClick={goToSignUpScreen}>{A12Y_CONST.LABEL.SINGIN_JOIN_NOW}</span>
                    </div>
                    <div className="fullWidth paddingleft16 paddingRight16">
                        <div className="fullWidth orButton positionRelative texAlingCener">
                            <span>Or</span>
                        </div>
                    </div>
                    <div onClick={handleGoogleLogin} id={A12Y_CONST.BUTTON_ID.GOOGLELOGINBUTTON} className="fullWidth paddingleft16 paddingRight16 marginTop30">
                        <div className="fullWidth googleLoginBUtton">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.6902 13.2434C25.6902 12.1781 25.6037 11.4008 25.4167 10.5947H13.2531V15.4025H20.3928C20.2489 16.5974 19.4716 18.3967 17.7442 19.6058L17.72 19.7668L21.5659 22.7462L21.8324 22.7727C24.2794 20.5127 25.6902 17.1875 25.6902 13.2434" fill="#4285F4"/>
                                <path d="M13.253 25.9106C16.7509 25.9106 19.6874 24.759 21.8323 22.7726L17.7442 19.6056C16.6502 20.3686 15.1819 20.9012 13.253 20.9012C9.82709 20.9012 6.91936 18.6412 5.88283 15.5176L5.7309 15.5305L1.73186 18.6254L1.67957 18.7708C3.80998 23.0028 8.18602 25.9106 13.253 25.9106Z" fill="#34A853"/>
                                <path d="M5.88284 15.5175C5.60934 14.7114 5.45106 13.8477 5.45106 12.9552C5.45106 12.0627 5.60934 11.1991 5.86845 10.393L5.8612 10.2213L1.81205 7.07666L1.67957 7.13968C0.801523 8.89587 0.297699 10.868 0.297699 12.9552C0.297699 15.0425 0.801523 17.0145 1.67957 18.7707L5.88284 15.5175" fill="#FBBC05"/>
                                <path d="M13.253 5.00937C15.6857 5.00937 17.3267 6.06018 18.2624 6.93833L21.9187 3.36839C19.6732 1.28115 16.7509 0 13.253 0C8.18602 0 3.80998 2.90773 1.67957 7.13978L5.86844 10.3931C6.91936 7.26938 9.82709 5.00937 13.253 5.00937" fill="#EB4335"/>
                            </svg>
                            <span>{A12Y_CONST.BUTTON_TEXT.LOGIN_WITH_GOOGLE}</span>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    );
}


export default withRouter (connect(null,null) (LoginForm));
