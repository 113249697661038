import React, {useEffect, useRef, useState} from 'react';
import HeaderColumn from './HeaderColumnComponent'
import A12Y_CONST from "../common/a12yConst";
import * as userDetailActions from "../redux/actions/userDetailActions";
import { useHistory } from 'react-router-dom';
import {connect} from "react-redux";
import {titleRoutePath} from "../util/titleRouteNamePath";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const NewLayoutWithoutLogin =  (props) => {
    const signUpboxRef = useRef(null)
    const [isBoxHeightHeigher, setiIsBoxHeightHeigher] = useState(false);
    /* eslint-disable no-unused-vars */
    const [signUpBoxHeight, setSignUpBoxHeight] = useState(0);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [isUrlInviteCode, setIsUrlInviteCode] = useState(false)
    /* eslint-disable no-unused-vars */
    const history = useHistory();
    // useEffect( async () => {
    //     if(props?.props?.title && props.props.title) {
    //        await setTitle(props.props.title)
    //       }else{
    //        await setTitle(A12Y_CONST.LABEL.TIILE_RELATING_TO_WHY_THIS)
    //     }
    //     if(props?.props?.description && props.props.description) {
    //         setDescription(props.props.description)
    //     }else{
    //         setDescription('Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec sed odio dui. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor. ')
    //     }
    // },[title, description])

    useEffect( (props)=>{
        const routingPath = async ()=>{
            let path = window.location.pathname;
            let title = await titleRoutePath(path)
            document.title =A12Y_CONST.TEXT.ADVOCORD+title;
        }
        routingPath()
    },[window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

 /*   useEffect( async () => {
        let screenHeight = windowDimensions.height-88;
        let boxHeight = signUpboxRef.current.clientHeight + 20
        console.log('signUpboxRef.current.clientHeight', signUpboxRef.current.clientHeight)
        await setSignUpBoxHeight(signUpboxRef.current.clientHeight + 110)
        if(windowDimensions.width >= 951) {
            if (boxHeight > screenHeight) {
                setiIsBoxHeightHeigher(true);
            } else {
                setiIsBoxHeightHeigher(false);
            }
        }
        console.log('screenHeight: ', screenHeight, boxHeight)
    },[windowDimensions.height])*/

    useEffect( () => {
        const handleResize = async () => {
            //console.log('signUpboxRef.current.clientHeight', signUpboxRef)
            if(signUpboxRef !== undefined && signUpboxRef !== null && signUpboxRef?.current?.clientHeight){
                let boxHeight = signUpboxRef.current.clientHeight && signUpboxRef?.current?.clientHeight  + 20
                let windowHeight = window.innerHeight-180
                await setSignUpBoxHeight(signUpboxRef.current.clientHeight + 110)
                // if(windowDimensions.width >= 951) {
                    if (boxHeight > windowHeight) {
                        setiIsBoxHeightHeigher(true);
                    } else {
                        setiIsBoxHeightHeigher(false);
                    }
                // }
            }
        }
        handleResize()
    })

    useEffect( () => {
    // let pathName = (props.props.location.pathname)
        const assignSignUpText = async ()=>{
            let obj = {title:A12Y_CONST.LABEL.TIILE_RELATING_TO_WHY_THIS,description:A12Y_CONST.LABEL.VIVAMUS_SAGITTIS_LACUS,otherDes:''}

            const search = window.location.search;
            const params = new URLSearchParams(search);
            // let FreeTrialCode = params.get('utm_source');
            let inviteCode = params.get('invitation_code');
            //utm_source=advocord
            if(inviteCode){
                setIsUrlInviteCode(true);
            }else{
                setIsUrlInviteCode(false);
            }
            if((A12Y_CONST.ROUTE.SIGNUP === props.props.location.pathname || A12Y_CONST.ROUTE.TRUSTWORTHY === props.props.location.pathname || A12Y_CONST.ROUTE.ABONELAW === props.props.location.pathname || A12Y_CONST.ROUTE.SPONSOREXAMPLE === props.props.location.pathname.replace(/\/$/, "")) && !isUrlInviteCode){
                 obj = {title:A12Y_CONST.LABEL.ITS_FREE_ACCOUNT,description:A12Y_CONST.LABEL.WE_BUILT_ADVOCORD_FOR_ADVOCATES,otherDes:A12Y_CONST.LABEL.GET_STARTED_WITH_YOUR}
            }else {
            if(A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW === props.props.location.pathname){
                if(Object.keys(props?.dependent).length > 0 && !props?.dependent?.access.can_update){
                    obj = {title:A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD+'.',description:A12Y_CONST.LABEL.YOU_HAVE_BEEN_INVITED,otherDes:A12Y_CONST.LABEL.TO_GET_STARTED}
                }else{
                    obj = {title:A12Y_CONST.LABEL.LET_GET_SOME,description:A12Y_CONST.LABEL.WE_NEED_YOUR_FULL,otherDes:''}
                }
            }
            if(props.props.location.pathname === '/' || A12Y_CONST.ROUTE.LOGIN === props.props.location.pathname ||  A12Y_CONST.ROUTE.SIGNUP === props.props.location.pathname || A12Y_CONST.ROUTE.TRUSTWORTHY === props.props.location.pathname || A12Y_CONST.ROUTE.ABONELAW === props.props.location.pathname || A12Y_CONST.ROUTE.SPONSOREXAMPLE === props.props.location.pathname || A12Y_CONST.ROUTE.SIGNUP_EMAIL === props.props.location.pathname || A12Y_CONST.ROUTE.FORGOT_PASSWORD_EMAIL === props.props.location.pathname || A12Y_CONST.ROUTE.EMAIL_LOGIN === props.props.location.pathname || A12Y_CONST.ROUTE.PASSWORD_UPDATED === props.props.location.pathname){
                obj = {title:A12Y_CONST.LABEL.RECORD_MANAGE,description:A12Y_CONST.LABEL.ADVOCORD_IS_AS_LIFE_MANAGEMENT,otherDes:''}
            }
            if(A12Y_CONST.ROUTE.SIGN_UP_EMAIL_VARIFICATION === props.props.location.pathname || A12Y_CONST.ROUTE.FORGOT_PASSWORD_VARIFICATION_CODE === props.props.location.pathname){
                obj = {title:A12Y_CONST.LABEL.HELP_US_VERIFY,description:A12Y_CONST.LABEL.SECURITY_IS_A_CORNERSTONE,otherDes:''}
            }
            }
            await props.signUpTextChange(obj)

        }
        assignSignUpText()

    },[props.props.location.pathname,isUrlInviteCode]) // eslint-disable-line react-hooks/exhaustive-deps

//    console.log('props------', props)
    const goToSignInScreen = () => {
        history.push(A12Y_CONST.ROUTE.LOGIN);
    }
    const goToSignUpScreen = () => {
        history.push(A12Y_CONST.ROUTE.SIGNUP);
    }
    return (
        <div className='bgCol'>
            <HeaderColumn signUpflow={props.props.location.pathname === '/'?true:false} />
            <div className="top topRelativeCol">
                <div className="container">
                    <div className="master">
                        <div className="loginMain">
                            <div className={`imgLeftSignCol ${props?.props?.type && props.props.type === A12Y_CONST.LABEL.MY_ACCOUNT ? 'myAccountLeftCol' : ''} ${props.props.location.pathname !== '/' ? 'singnUpDisplay_600':''}`}>
                               <div className={'leftTextTitle'}>
                                   <h2 className='leftTitle'>{props?.signUpText?.title}</h2>
                                   <p className='leftDescriptions marginTop20'>{props?.signUpText?.description}</p>
                                   {
                                       props?.signUpText?.title === A12Y_CONST.LABEL.ITS_FREE_ACCOUNT ?
                                           <>
                                               <ul className='freeAccountListMEssage'>
                                                   <li>{A12Y_CONST.LABEL.LEGAL_COMPLIANCE}</li>
                                                   <li>{A12Y_CONST.LABEL.FINANCIAL_AFFAIRS}</li>
                                                   <li>{A12Y_CONST.LABEL.MEDICAL_INFORMATION}</li>
                                               </ul>
                                           </>
                                           :
                                           null
                                   }
                                   {props?.signUpText?.otherDes && props?.signUpText?.otherDes ?<p className='leftDescriptions marginTop20'>{props?.signUpText?.otherDes}</p> : null}

                               </div>
                                {windowDimensions.width <= 768?
                                    <div className="signUpLoginButtonRow hideDesktopShowMobile fullWidth">
                                        <span className='loginSignButton marginRight20' onClick={goToSignInScreen}>{A12Y_CONST.LABEL.LOG_IN}</span>
                                        <span className='loginSignButton' onClick={goToSignUpScreen}>{A12Y_CONST.LABEL.SIGNUP}</span>
                                    </div>
                                    :
                                    null
                                }

                            </div>
                            <div className={`imgRightSignCol onBoardingFlow ${props?.props?.type && props.props.type === A12Y_CONST.LABEL.MY_ACCOUNT ? 'myAccountRightCol' : ''} ${props.props.location.pathname === '/' ? 'singnUpDisplay_600':''}`}>
                                <div className="signInFormBox">
                                <div className='myAccountTabFullWidth' ref={signUpboxRef}>
                                   {props.children}
                                </div>
                                <div className={`${props?.props?.type && props.props.type === A12Y_CONST.LABEL.MY_ACCOUNT ?'rightalignBottomText':''} ${isBoxHeightHeigher?'positionNspacing':'' } allRightReserveText`}>
                                    <span className='allRightReserveCol'> {A12Y_CONST.MESSAGE.Advocord}</span>  <a target='blank' href={A12Y_CONST.TEXT.PRIVACY_POLICY_URL} className='signBottomLink'>{A12Y_CONST.LABEL.SINGUP_PRIVACY_POLICY}</a> <span>{A12Y_CONST.LABEL.AND}</span> <a target='blank' href={A12Y_CONST.TEXT.TERMS_OF_SERVICE_URL} className='signBottomLink marginRightNone'>{A12Y_CONST.LABEL.TERMS_OF_SERVICE}</a>.
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    signUpText : state.userDetailReducer.signUpText,
    dependent : state.dependentReducer.dependent,
});

const mapDispatchToProps = dispatch => ({
    signUpTextChange : (data) => dispatch(userDetailActions.signUpTextChange(data)),
});


export default connect(mapStateToProps, mapDispatchToProps) (NewLayoutWithoutLogin);