import React, { Component } from 'react';
import A12Y_CONST from "../common/a12yConst";
import * as dependentActions from "../redux/actions/dependentActions";
import {connect} from "react-redux";
import InsuranceStep3 from '../components/InsuranceStep3';
import {saveLoan} from "../util/AssetCommonApi";
import * as userDetailActions from "../redux/actions/userDetailActions";


class AddLoanAgainstPolicyScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
        }
    }

    onSubmitHandler = async (obj)=>{
        this.setState({ loading: true })
        if(Object.keys(this.props.currentAsset).length> 0){
            let response = await saveLoan(this.props.dependent.id, this.props.currentAsset.id, obj)
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({ loading: false })
                this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
            } else {
                this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false  })
            }
        }
    }

    onCancelHandler = ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

  render() {
        return (
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <InsuranceStep3
                            loading={this.state.loading}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            onSubmit={this.onSubmitHandler}
                            onCancel={this.onCancelHandler}
                            dependent={this.props.dependent}
                            currentAsset={this.props.currentAsset}
                            submitBtnText={A12Y_CONST.BUTTON_TEXT.SAVE}
                            editMode={false}
                            createFromViewScreen={true}
                            isDataChanged={this.dataChangeHandler}
                        />
                    </div>
                </div>
            </div>
        );
  }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependent : state.dependentReducer.dependent,
    currentAsset : state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});


export default connect(mapStateToProps, mapDispatchToProps) (AddLoanAgainstPolicyScreen);
