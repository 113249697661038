import React, { useState, useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import {useLocation } from "react-router-dom";
import Loading from '../components/LoadingComponent';
import countyList from '../common/countyList.json'
import countyList_1 from '../common/countyList_1.json'
import stateList from '../common/stateList.json'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import EditChangesRemovePopup from "./EditChangesRemovePopup";


function AddressComponent(props) {
    const [loading, setLoading] = useState(false);
    const [error, setErrors] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const { register, handleSubmit, getValues, setValue, setFocus, setError, formState: { errors, isSubmitting } } = useForm({ mode: "onBlur" });
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [county, setCounty]  = useState({});
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [countryState, setCountryState]  = useState({});
    const [showAddressDropDown, setShowAddressDropDown] = useState(false);
    const [line2, setLine2] = useState('');
    const [line1, setLine1] = useState('');
    const [placeServiceList, setPlaceServiceList] = useState({});
    const location = useLocation();
    const [isEditMode, setIsEditMode] = useState(false)
    const [isDataModified, setIsDataModified] = useState(false);
    const [dropDownIndex, setDropDownIndex] = useState(null);


    const countyOptions = countyList[countryState.label] && countyList[countryState.label].map(state =>{
        return {label: state.label, value: state.label}
    })

    const countryStateList = stateList;
    const stateOptions = countryStateList && countryStateList.map(state =>{
        return {label: state.label, value: state.label}
    })

    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
    }, [props.error, props.loading, props.loaderFullScreen])

    const onSubmit = async (formData)=>{
        if(line1 === '' || getValues('line1') === ''){
            setError("line1", {
                type: "manual",
                message: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED,
            });
            return
        }
        let obj = {
            line1 : line1,
            line2 : line2,
            city : city,
            state : Object.keys(countryState).length > 0 ? countryState.label : '',
            zip : zip,
            country : Object.keys(county).length > 0 ? county.label : ''
        }
        if(formData.line1 !== ''){
            props.isDataChanged(false)
            if(props.editMode){
                obj.id = props.currentAsset.address.id
                props.onUpdate(obj)
            }else {
                props.onSubmit(obj)
            }
        }
    }

    useEffect( ()=>{
       const assignFormDefaultValue = async ()=>{
           if(props.editMode && props.currentAsset.hasOwnProperty('address')){
               let asset = props.currentAsset
               setValue('line1',asset.address.line1)
               setLine1(asset.address.line1)
               setLine2(asset.address.line2)
               setCity(asset.address.city)
               setZip(asset.address.zip)
               if(asset.address.state){
                   let selectedState = countryStateList.find(state => state.label === asset.address.state)
                   setCountryState(selectedState)
               }if(asset.address?.country && countyList_1.length > 0){
                   let countyObj = await countyList_1.find(item=>item.label === asset.address.country)
                   if(countyObj){
                       setCounty(countyObj)
                   }
               }
               setIsEditMode(true)
           }
       }

       assignFormDefaultValue();

    },[props.editMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
        const checkFormDataIsChanged = async ()=>{
            setIsBtnDisabled(true)
            setIsDataModified(false)
            props.isDataChanged(false)
            if(isEditMode && props.currentAsset.hasOwnProperty('address')){
                let asset = props.currentAsset
                /* setIsBtnDisabled(true)*/
                // let line1 = await getValues('line1')
                if((Object.keys(county).length > 0 && county.label !== asset.address.country) || line1 !== asset.address.line1 || line2 !== asset.address.line2 || city !== asset.address.city || zip !== asset.address.zip || (Object.keys(countryState).length > 0 && countryState.label !== asset.address.state)){
                    if(line1 !== undefined && line1 !== "" && line1?.length > 2){
                        setIsBtnDisabled(false)
                    }
                    setIsDataModified(true)
                    props.isDataChanged(true)
                }
            }else {
                if(!props.editMode){
                    if(getValues('line1') !== '' || line1 !== '' || line2 !== '' || city !== '' || zip !== '' || Object.keys(countryState).length > 0){
                        if((Object.keys(county).length > 0  )|| (line1 !== undefined && line1 !== "" && line1?.length > 2)){
                            setIsBtnDisabled(false)
                        }
                        setIsDataModified(true)
                        props.isDataChanged(true)
                    }
                }
            }
        }

        checkFormDataIsChanged()

    },[line1,getValues('line1'),line1, line2, city, zip, countryState, isEditMode,county]) // eslint-disable-line react-hooks/exhaustive-deps

    /*const onCancel = async()=>{
        props.onCancel()
    }*/
    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            props.onCancel()
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        props.onCancel()
    }

    const getPlaceFromService = async (obj)=>{
        //if (!isBrowser) return;
        const request = {
            input: obj.input,
            componentRestrictions: {country : 'us'},
            fields: ["name", "geometry", "formatted_address", "place_id"],
        };
        if(window.google){

            let service = new window.google.maps.places.AutocompleteService(document.createElement("div"));
            service.getPlacePredictions(request,(results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                    setPlaceServiceList(results)
                }
            });
        }
    }


    const handelZip = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setZip(value)
        }
    }

    const handelCity = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setCity(value)
        }
    }

    const handleChangeCountryState = e => {
        setCountryState(e);
        setCounty({})
    }

    const handelLine2 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine2(value)
        }
    }

    const handelLine1 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine1(value)
            setValue("line1", value)
        }
    }

    const selectAdd = (add) =>{
        if(add){
            setShowAddressDropDown(false)
            const request = {
                placeId: add.place_id,
                fields: ["name", "formatted_address", "place_id", "geometry", "address_components"],
            };
            const service = new window.google.maps.places.PlacesService(document.createElement("div"));

            service.getDetails(request, (place, status) => {
                setLine1('');
                setValue("line1", '')
                place.address_components.forEach((item) => {
                    if (item.types.length > 0) {
                        item.types.forEach((type) => {
                            if (type === "street_number") {
                                setValue("line1", item.short_name)
                                setLine1(item.short_name)
                            }
                            if (type === "route") {
                                let add = getValues("line1")
                                if (add.trim() === "") {
                                    setValue("line1", item.short_name)
                                    setLine1(item.short_name)
                                } else {
                                    setValue("line1", add + " " + item.short_name)
                                    setLine1( add + " " + item.short_name)
                                }
                            }
                            if (type === "postal_code") {
                                setValue("zip", item.short_name)
                                setZip(item.short_name)
                            }
                            if (type === "locality") {
                                setValue("city", item.short_name)
                                setCity(item.short_name)
                            }
                            /*if(type === "neighborhood"){
                                setValue("address2",item.short_name)
                            }
                            if(type === "sublocality_level_1"){
                                let add = getValues("address2")
                                if(add.trim() === ""){
                                    setValue("address2",item.short_name)
                                }else {
                                    setValue("address2",add+" "+item.short_name)
                                }

                            }*/
                            if (type === "administrative_area_level_1") {
                                let stateObj = countryStateList.filter(data => data.label === item.short_name)
                                setCountryState(stateObj[0])
                                //setValue("address2",item.short_name)
                            }
                            if (type === "administrative_area_level_2") {
                                if(countyList_1.length > 0){
                                    let countyObj = countyList_1.filter(data => data.label === item.short_name)
                                    if (countyObj && countyObj.length > 0) {
                                        setCounty(countyObj[0])
                                    }
                                }
                            }
                        })
                    }
                })
            });

            let address1 = getValues("line1")
            if(address1.trim() === ""){
                setValue("line1",address1.formatted_address)
                setLine1(address1.formatted_address)
            }

        }
        /*let addObj = {
            "address_line1" : getValues('address1'),
            "address_line2" : getValues('address2'),
            "city" : getValues('city'),
            "state" : countryState ? countryState.label : '',
            "zip" : getValues('zipcode'),
            "country_code" : "USA"

        }
        setSelectedAdd(addObj)*/
        setDropDownIndex(null)
    }

    useEffect(()=>{
        setShowAddressDropDown(false)
    },[props.isClickedOutside])

    const onFocusEventHandler = () => {
        if(showAddressDropDown){
            setShowAddressDropDown(false)
        }
    }


    /*useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length){
            placePredictions.map((place,key) => {placesService?.getDetails(
                {
                    placeId: place.place_id,
                },
                (placeDetails) =>{
                    setPlacePredictionList(oldArray => [...oldArray, placeDetails]);
                }
            );});
        }
    }, [placePredictions]);*/
    useEffect(() => {
        setFocus("line1");
    }, [setFocus])

    const onMouseMoveEvent = async ()=>{
        if(dropDownIndex !== null){
            setDropDownIndex(null)
        }
    }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter' && dropDownIndex !== null){
            e.preventDefault();
        }
    };

    const handleChangeCounty = e => {
        setCounty(e);
    }

    return (
        <div className='height100'>
           {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className="df fcol marginBottom20 height100">
                <h4 className="dependentSubTitle marginBottom10"><span className='vam'>{props.titlePrefix} </span> <span className='txtEllipsis vam'>{props.editMode ? '' : location.pathname !== A12Y_CONST.ROUTE.ADD_ADDRESS ? props.dependent.nick_name : ''}</span><span className='vam'>{props.editMode ? '' : location.pathname !== A12Y_CONST.ROUTE.ADD_ADDRESS ? A12Y_CONST.LABEL.APOSTROPHE_S : ''} {props.title}{props.editMode || location.pathname === A12Y_CONST.ROUTE.ADD_ADDRESS ?'':'?'}</span> </h4>
                <div className="fullWidth">
                    {error ?
                        <div className='maxWidth600 marginLeftRightAuto'>
                            <ErrorMessageComponent errorMessage={props.errorMessage}/>
                        </div>
                        :
                        (props.message ?
                                <SuccessMessageComponent successMessage={props.message}/>

                                :
                                null
                        )

                    }
                </div>
                <form autoComplete="off" onKeyDown={(e) => checkKeyDown(e)} className='marginTop30 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    {/*<div className="formRow fullWidth formRowGap formRowMarginBottom10">*/}
                    {/*    <label className="lblExpType">INSTITUTION<span className="astrciBlue">*</span></label>*/}
                    {/*    <div className="fullWidth">*/}
                    {/*        <div className=' fullWidth positionRelative'>*/}
                    {/*            <div className="fullWidth fullWidth marginBottom30 marginTop10">*/}
                    {/*                <input*/}
                    {/*                    {...register("institutionName",{required:true})}*/}
                    {/*                    value={institutionName}*/}
                    {/*                    id="institutionName"*/}
                    {/*                    maxLength='255'*/}
                    {/*                    onBlur={validateInstitutionName}*/}
                    {/*                    onChange={handleInstitutionName}*/}
                    {/*                    onFocus={()=>setInstitutionNameError(false)}*/}
                    {/*                    className="fullWidth inputColum24size"*/}
                    {/*                    placeholder="Institution Name"*/}
                    {/*                />*/}
                    {/*                <div className='errorRelativeRow'>{institutionNameError? <p className='errorMessage'>{institutionNameErrorMeesage}</p>: null}</div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="formRow marginTop30 fullWidth">
                        <label>{A12Y_CONST.LABEL.CAPITAL_LOCATION}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth marginBottom20 address1'>
                                    <input
                                        {...register('line1', { required: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED })}
                                        placeholder='Street Address'
                                        className={errors.line1 && line1?.length === 0 ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                        type='text'
                                        maxLength='255'
                                        onChange={handelLine1}
                                        onKeyUp={(evt) => {
                                            //setPlacePredictionList([])
                                            if(evt.target.value === " "){
                                                setValue('line1',evt.target.value.trim())
                                                setLine1(evt.target.value.trim())
                                            }
                                            if(evt.target.value.length > 2 ){
                                                if(evt.key === "ArrowDown" || evt.key === "ArrowUp" || evt.key === 'Enter'){
                                                    if(dropDownIndex === null){
                                                        setDropDownIndex(0)
                                                    }else {
                                                        setDropDownIndex(evt.key === "ArrowUp" && dropDownIndex !== 0 ? dropDownIndex -1 : evt.key === "ArrowDown" && placeServiceList.length-1 > dropDownIndex ? dropDownIndex +1 : dropDownIndex )
                                                        if(evt.key === 'Enter' && dropDownIndex !== null && placeServiceList.length > 0){
                                                            selectAdd(placeServiceList[dropDownIndex])
                                                        }
                                                    }

                                                }else {
                                                    setShowAddressDropDown(true)
                                                    setLine1(evt.target.value.trim())
                                                    //getPlacePredictions({ input: evt.target.value });
                                                    getPlaceFromService({ input: evt.target.value });
                                                }
                                            }
                                        }}
                                        // loading={isPlacePredictionsLoading}
                                        id='line1'
                                        autoComplete="new-no-line1"
                                        onBlur={()=>{
                                            if(line1?.length === 0){
                                                setError("line1", {
                                                    type: "manual",
                                                    message: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED,
                                                });
                                            }
                                        }}
                                        onFocus={()=>{
                                            setError("line1", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />

                                    {/*<div onClick={()=>{selectAdd(null)}} >Hello</div>)*/}
                                    {showAddressDropDown && placeServiceList.length > 0?
                                        <div className='addressLin1DropDown'>
                                            <div className="addressLin1DropDownInner">
                                                {placeServiceList && placeServiceList.map((item,key) =>
                                                    (item && item.description ?
                                                            <div onMouseMove={onMouseMoveEvent} className={dropDownIndex === key ? 'addressListDropDownHighlight' : dropDownIndex === null ? 'addressListDropDown addressHover' : 'addressListDropDown' } onClick={()=>{selectAdd(item)}} key={key}>{item.description}</div>
                                                            :
                                                            null
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {/*{placePredictions.map((item) => <div>{item.description}</div>)}*/}
                                    <div className='errorRelativeRow'> {errors.line1 && <p className='errorMessage'>{errors.line1.message}</p>}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow marginTop20 fullWidth">
                        <label>{A12Y_CONST.LABEL.UNIT}</label>
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth marginBottom30  address2'>
                                    <input
                                        {...register('line2',)}
                                        placeholder='Apartment, Suite, Unit, Building'
                                        className='inputColum24size '
                                        maxLength='255'
                                        type='text'
                                        id='line2'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-no-line2"
                                        onChange={handelLine2}
                                        value={line2}
                                    />
                                </div>
                            </div>
                    </div>
                    <div className="formRow marginTop20 fullWidth">
                        <label>{A12Y_CONST.LABEL.CITY}</label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth marginBottom10 cityCountryState'>
                                <div className='width40 paddingRight35 marginBottom30  city'>
                                    <input
                                        {...register('city',)}
                                        placeholder='City'
                                        maxLength='100'
                                        className='inputColum24size'
                                        type='text'
                                        id='city'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-no-city"
                                        onChange={handelCity}
                                        value={city}
                                    />
                                </div>
                                <div className='width60 dependentResidencCol paddingLeft35 \ countryState'>
                                    <div className={(countryState && Object.keys(countryState).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol floatLeft marginTop10 positionRelative paddingLeft35 addressRow countryState'}>
                                        <label className='labelCol institutionStateLabel'>{A12Y_CONST.LABEL.STATE}</label>
                                    <Select
                                        // selectdisplayprops={countryState}
                                        // defaultValue={countryStateList[0]}
                                        // className='phoneTypeLabel'
                                        classNamePrefix={'dependentResidence'}
                                        placeholder="Select"
                                        maxLength='100'
                                        value={countryState.label ? countryState : ""}
                                        options={stateOptions}
                                        onChange={handleChangeCountryState}
                                        // getOptionLabel={e => (
                                        //     <div className='phoneListRow'>
                                        //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                        //     </div>
                                        // )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow marginTop20 fullWidth">
                        <label>{A12Y_CONST.LABEL.ZIP}</label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth'>
                                <div className='width40 paddingRight35 zip'>
                                    <input
                                        {...register('zip',)}
                                        placeholder='00000'
                                        className='inputColum24size'
                                        maxLength='100'
                                        type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                        onWheel={e => e.target.blur()}
                                        id='zip'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-pincode"
                                        onChange={handelZip}
                                        value={zip}
                                    />
                                </div>
                                <div className='width60 dependentResidencCol paddingLeft35 marginTop10 countryState'>
                                    <div className={(county && Object.keys(county).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol floatLeft positionRelative paddingLeft35 addressRow countryState'}>
                                        <label className='labelCol institutionStateLabel'>{A12Y_CONST.LABEL.COUNTY}</label>
                                        <Select
                                            // selectdisplayprops={countryState}
                                            // defaultValue={countryStateList[0]}
                                            classNamePrefix={'dependentResidence'}
                                            placeholder="Select"
                                            value={county.label ? county : ""}
                                            options={countyOptions}
                                            tabIndex={7}
                                            onChange={handleChangeCounty}
                                            // getOptionLabel={e => (
                                            //     <div className='phoneListRow'>
                                            //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                            //     </div>
                                            // )}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: 'red',
                                                    primary: '#0096C708',
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/*------------------ Button Actions ---------------------*/}
                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.ADDRESS_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.ADDRESS+props.BtnTxt} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={props.BtnTxt} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default AddressComponent
