import React, { Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import * as dependentActions from "../redux/actions/dependentActions";
import InstitutionAddressComponent from "../components/InstitutionAddressComponent";
import {saveInstitutionDetails} from "../util/AssetCommonApi";
import * as userDetailActions from "../redux/actions/userDetailActions";




class InventoryViewScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            assetTitle:'',
            assetPreFixTitle:'',
            isClickedOutside:false,
            locationTitle:A12Y_CONST.LABEL.LOCATION
        }
    }

    componentDidMount = async () => {
        await this.currentAssetType()
    }

    // currentAssetType = async () => {
    // if(this.props.currentAsset.asset_type === 'Life Insurance'){
    //    await this.setState({assetTitle:A12Y_CONST.TEXT.LIFE_INSURANCE_PROVIDE, assetPreFixTitle:A12Y_CONST.TEXT.WHO_IS})
    //
    // }
    // if(this.props.currentAsset.asset_type === 'Bank Account'){
    //     await this.setState({assetTitle:A12Y_CONST.TEXT.BANK_INFORMATION, assetPreFixTitle:A12Y_CONST.TEXT.WHAT_IS})
    //  }
    //     if(this.props.currentAsset.asset_type === 'Business Interest'){
    //         await this.setState({assetTitle:A12Y_CONST.LABEL.BUSINESS_DETAILS, assetPreFixTitle:''})
    //     }
    //     if(this.props.currentAsset.asset_type === 'Burial Account'){
    //         await this.setState({assetTitle:A12Y_CONST.LABEL.BURIAL_PROVIDER, assetPreFixTitle:''})
    //     }
    // }

    currentAssetType = async () => {
        if(this.props.currentAsset.asset_type === 'Life Insurance'){
            await this.setState({assetTitle:A12Y_CONST.TEXT.LIFE_INSURANCE_PROVIDE, locationTitle: A12Y_CONST.TEXT.LIFE_INSURANCE_PROVIDE, assetPreFixTitle:A12Y_CONST.TEXT.WHO_IS})

        }
        if(this.props.currentAsset.asset_type === 'Bank Account'){
            await this.setState({assetTitle:A12Y_CONST.TEXT.BANK_INFORMATION, assetPreFixTitle:A12Y_CONST.TEXT.WHAT_IS, locationTitle: A12Y_CONST.TEXT.BANK_INFORMATION})
        }
        if(this.props.currentAsset.asset_type === 'Business Interest'){
            await this.setState({assetTitle:A12Y_CONST.LABEL.BUSINESS_DETAILS, assetPreFixTitle:'', locationTitle: A12Y_CONST.LABEL.BUSINESS_DETAILS})
        }
        if(this.props.currentAsset.asset_type === 'Retirement Account'){
            await this.setState({assetTitle:A12Y_CONST.LABEL.RETIREMENT_ACCOUNT, assetPreFixTitle:'', locationTitle: A12Y_CONST.LABEL.LOCATION})
        }
        if(this.props.currentAsset.asset_type === 'Burial Account'){
            await this.setState({assetTitle:A12Y_CONST.LABEL.BURIAL_PROVIDER, assetPreFixTitle:'', locationTitle: A12Y_CONST.LABEL.BURIAL_PROVIDER})
        }
    }

    onSubmitHandler = async (obj)=>{
        try {
            this.setState({ loading: true })
            if(Object.keys(this.props.currentAsset).length> 0){
                let response = await saveInstitutionDetails(this.props.dependent.id, this.props.currentAsset.id, obj)
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.setState({ loading: false })
                    this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
                } else {
                    this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false  })
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.ADD_INSTITUTION,e)
        }

    }

    onCancelHandler = ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    render() {
        const {loading,error,errorMessage,loaderFullScreen} = this.state
        return (
            <div className='myProfileAccount animationall mainRightSidebare' onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <InstitutionAddressComponent
                            title={this.state.locationTitle}
                            titlePrefix={this.state.assetPreFixTitle}
                            loading={loading}
                            error={error}
                            isClickedOutside={this.state.isClickedOutside}
                            errorMessage={errorMessage}
                            loaderFullScreen={loaderFullScreen}
                            onSubmit={this.onSubmitHandler}
                            onCancel={this.onCancelHandler}
                            dependent={this.props.dependent}
                            fromViewScreen={true}
                            isDataChanged={this.dataChangeHandler}
                            BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    currentAsset : state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
});


export default connect(mapStateToProps, mapDispatchToProps) (InventoryViewScreen);

