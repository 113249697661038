import './App.css';
import React, {Component} from 'react'
import SiginUpScreen from './screens/SiginUpScreen'
import SiginUpAbonelawScreen from './screens/SignUpAbonelawScreen'
import SiginUpWithEmailScreen from './screens/SiginUpWithEmailScreen'
import SingUpEmailVarification from './screens/SignUpEmailVerificationScreen'
import SecureScreen from './screens/SecureScreen'
import SiginScreen from './screens/SiginScreen'
import CodeScreen from './screens/CodeScreen'
import { withRouter } from 'react-router';
import {Switch, Redirect} from "react-router-dom";
import ProtectedRoute from './components/ProtectedRouteComponent';
import ForgotPasswordEmailScreen from './screens/ForgotPasswordEmailScreen';
import ForgotPasswordVerificationCode from './screens/ForgotPasswordVerificationCode';
import ForgotCreatePasswordScreen from './screens/ForgotCreatePasswordScreen';
import ForgotPasswordUpdatedScreen from './screens/ForgotPasswordUpdatedScreen';
import LoginEmailVerifiedScreen from './screens/LoginEmailVerifiedScreen';
import './assets/css/style.css'
import { connect } from 'react-redux';
import  * as userDetailActions from './redux/actions/userDetailActions'
import CONFIG from './config/apiConfig'
import A12Y_CONST from './common/a12yConst'
import Logtout from './screens/LogoutScreen';
import Dependent from './screens/DependentProfile';
import DependentView from './screens/DependentProfileVIew';
import Expenses from './screens/ExpensesScreen';
import AddExpense from './screens/AddExpenseScreen';
import ExpenseView from './screens/ExpenseViewScreen';
import Incomes from './screens/IncomeScreen';
import IncomeView from './screens/IncomeViewScreen';
import AddIncome from './screens/AddIncomeScreen';
import InventoryView from './screens/InventoryViewScreen';
import LayoutRoute from './components/LayoutRoute'
import LayoutWithLogin from './components/LayoutWithLogin'
import NewLayoutWithoutLogin from './components/NewLayoutWithoutLogin'
import LayoutWithoutLogin from './components/LayoutWithoutLogin'
import Documents from './screens/DocumentScreen';
import Help from './screens/Help'
import Activities from './screens/ActivityScreen';
import ActivityView from './screens/ActivityViewScreen';
import AddActivity from './screens/AddActivityScreen';
import ScrollToTop from './components/ScrollToTop'
import Inventory from './screens/InventoryScreen'
import AddAsset from './screens/AddAssetScreen';
import AssetEditScreen from './screens/AssetEditScreen';
import AddCoOwner from './screens/CoOwnerScreen';
import AddContact from './screens/AddContactScreen';
import AddBeneficiary from "./screens/AddBeneficiaryScreen";
import AddInstitutionScreen from "./screens/AddInstitutionScreen";
import AddLoanAgainstPolicy from "./screens/AddLoanAgainstPolicyScreen";
import AddAddressScreen from "./screens/AddAddressScreen";
import AddDoctorScreen from "./screens/AddDoctorScreen"
import MedicalScreen from "./screens/MedicalScreen";
import DoctorViewScreen from "./screens/DoctorViewScreen";
import AddMedicationScreen from "./screens/AddMedicationScreen"
import MedicationViewScreen from "./screens/MedicationViewScreen"
import AddDebtScreen from "./screens/AddDebtLiabilityScreen"
import DebtViewScreen from "./screens/DebtLiabilityViewScreen"
import MyAccountScreen from "./screens/MyAccountScreen"
import NewMyAccountDependentScreen from "./screens/NewMyAccountDependentScreen"
import {LiveChatWidget} from "@livechat/widget-react";
import ReleaseNotes from "./screens/ReleaseNotes"
import DashboardScreen from "./screens/DashboardScreen"
import TagManager from 'react-gtm-module'
import * as dependentActions from "./redux/actions/dependentActions";
import {secureClient} from "./config/axiosClient";
import {LogOut} from "./util/LogOut";
import PopUpLoggedOut from "./components/PopUpLoggedOut";
import {hadlePathBeforeLogin} from "./redux/actions/dependentActions";
import SignUpFormSampleComponent from './components/SignUpFormSampleComponent';
import SignUpSampleScreen from './screens/SignUpSampleScreen';



class App extends Component {
    constructor(props){
        super(props);

        this.state ={
            isLoggedIn:false,
            sidebarVisible:false,
            shouldReload:false,
            isPopUp:false
        }
        this.intervalId = null
        this.routeName = [
            A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT,
            A12Y_CONST.ROUTE.DEPENDENT,
            A12Y_CONST.ROUTE.DEPENDENT_BILLING,
            A12Y_CONST.ROUTE.DEPENDENT_VIEW,
            A12Y_CONST.ROUTE.EXPENSES,
            A12Y_CONST.ROUTE.ADD_EXPENSE,
            A12Y_CONST.ROUTE.EXPENSE_VIEW,
            A12Y_CONST.ROUTE.INCOMES,
            A12Y_CONST.ROUTE.INCOME_VIEW,
            A12Y_CONST.ROUTE.ADD_INCOME,
            A12Y_CONST.ROUTE.DOCUMENTS,
            A12Y_CONST.ROUTE.ACTIVITIES,
            A12Y_CONST.ROUTE.ACTIVITY_VIEW,
            A12Y_CONST.ROUTE.ADD_ACTIVITY,
            A12Y_CONST.ROUTE.HOME,
            A12Y_CONST.ROUTE.FORGOT_PASSWORD_EMAIL,
            A12Y_CONST.ROUTE.FORGOT_PASSWORD_VARIFICATION_CODE,
            A12Y_CONST.ROUTE.FORGOT_CREATE_PASSWORD,
            A12Y_CONST.ROUTE.FORGOT_PASSWORD_UPDATED,
            A12Y_CONST.ROUTE.EMAIL_LOGIN,
            A12Y_CONST.ROUTE.PASSWORD_UPDATED,
            A12Y_CONST.ROUTE.ADD_ACTIVITY,
            A12Y_CONST.ROUTE.INVENTORY_VIEW,
            A12Y_CONST.ROUTE.INVENTORY,
            A12Y_CONST.ROUTE.ADD_ASSET,
            A12Y_CONST.ROUTE.EDIT_ASSET,
            A12Y_CONST.ROUTE.ADD_INSTITUTION,
            A12Y_CONST.ROUTE.ADD_CONTACT,
            A12Y_CONST.ROUTE.ADD_BENEFICIARY,
            A12Y_CONST.ROUTE.ADD_CO_OWNER,
            A12Y_CONST.ROUTE.ADD_LOAN,
            A12Y_CONST.ROUTE.MEDICAL,
            A12Y_CONST.ROUTE.ADD_MEDICATION,
            A12Y_CONST.ROUTE.ADD_DOCTOR,
            A12Y_CONST.ROUTE.DASHBOARD_VIEW,

        ]
        this.allowedScreenAfterLogin = [
          /*  A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT,
            A12Y_CONST.ROUTE.DEPENDENT,
            A12Y_CONST.ROUTE.DEPENDENT_VIEW,
            A12Y_CONST.ROUTE.EXPENSES,
            A12Y_CONST.ROUTE.ADD_EXPENSE,
            A12Y_CONST.ROUTE.EXPENSE_VIEW,
            A12Y_CONST.ROUTE.INCOMES,
            A12Y_CONST.ROUTE.INCOME_VIEW,
            A12Y_CONST.ROUTE.ADD_INCOME,
            A12Y_CONST.ROUTE.DOCUMENTS,
            A12Y_CONST.ROUTE.ACTIVITIES,
            A12Y_CONST.ROUTE.ACTIVITY_VIEW,
            A12Y_CONST.ROUTE.ADD_ACTIVITY,
            A12Y_CONST.ROUTE.LOGOUT,
            A12Y_CONST.ROUTE.ADD_ACTIVITY,
            A12Y_CONST.ROUTE.INVENTORY_VIEW,
            A12Y_CONST.ROUTE.INVENTORY,
            A12Y_CONST.ROUTE.ADD_ASSET,
            A12Y_CONST.ROUTE.EDIT_ASSET,
            A12Y_CONST.ROUTE.ADD_INSTITUTION,
            A12Y_CONST.ROUTE.ADD_CONTACT,
            A12Y_CONST.ROUTE.ADD_BENEFICIARY,
            A12Y_CONST.ROUTE.ADD_CO_OWNER,
            A12Y_CONST.ROUTE.ADD_LOAN,
            A12Y_CONST.ROUTE.MEDICAL,
            A12Y_CONST.ROUTE.ADD_MEDICATION,
            A12Y_CONST.ROUTE.ADD_DOCTOR,
            A12Y_CONST.ROUTE.DASHBOARD_VIEW,*/
            A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT,
            A12Y_CONST.ROUTE.DEPENDENT,
            A12Y_CONST.ROUTE.DEPENDENT_BILLING,
            A12Y_CONST.ROUTE.DEPENDENT_VIEW,
            A12Y_CONST.ROUTE.EXPENSES,
            A12Y_CONST.ROUTE.ADD_EXPENSE,
            A12Y_CONST.ROUTE.EXPENSE_VIEW,
            A12Y_CONST.ROUTE.INCOMES,
            A12Y_CONST.ROUTE.INCOME_VIEW,
            A12Y_CONST.ROUTE.ADD_INCOME,
            A12Y_CONST.ROUTE.DOCUMENTS,
            A12Y_CONST.ROUTE.ACTIVITIES,
            A12Y_CONST.ROUTE.ACTIVITY_VIEW,
            A12Y_CONST.ROUTE.ADD_ACTIVITY,
            A12Y_CONST.ROUTE.LOGOUT,
            A12Y_CONST.ROUTE.INVENTORY_VIEW,
            A12Y_CONST.ROUTE.INVENTORY,
            A12Y_CONST.ROUTE.ADD_ASSET,
            A12Y_CONST.ROUTE.EDIT_ASSET,
            A12Y_CONST.ROUTE.ADD_INSTITUTION,
            A12Y_CONST.ROUTE.ADD_CONTACT,
            A12Y_CONST.ROUTE.ADD_BENEFICIARY,
            A12Y_CONST.ROUTE.ADD_CO_OWNER,
            A12Y_CONST.ROUTE.ADD_LOAN,
            A12Y_CONST.ROUTE.MEDICAL,
            A12Y_CONST.ROUTE.ADD_MEDICATION,
            A12Y_CONST.ROUTE.ADD_DOCTOR,
            A12Y_CONST.ROUTE.DASHBOARD_VIEW,
            A12Y_CONST.ROUTE.EDIT_CONTACT,
            A12Y_CONST.ROUTE.EDIT_BENEFICIARY,
            A12Y_CONST.ROUTE.EDIT_CO_OWNER,
            A12Y_CONST.ROUTE.ADD_ADDRESS,
            A12Y_CONST.ROUTE.ADD_DEBT,
            A12Y_CONST.ROUTE.EDIT_DEBT,
            A12Y_CONST.ROUTE.DEBT_VIEW,
            A12Y_CONST.ROUTE.EDIT_DOCTOR,
            A12Y_CONST.ROUTE.DOCTOR_VIEW,
            A12Y_CONST.ROUTE.MEDICATION_VIEW
        ]
        this.notAllowedAfterLOgin = [A12Y_CONST.ROUTE.HOME,
            A12Y_CONST.ROUTE.LOGIN,
            A12Y_CONST.ROUTE.SIGNUP,
            A12Y_CONST.ROUTE.ABONELAW,
            A12Y_CONST.ROUTE.SPONSOREXAMPLE,
            A12Y_CONST.ROUTE.TRUSTWORTHY,
            A12Y_CONST.ROUTE.CODE,
            A12Y_CONST.ROUTE.SIGN_UP_EMAIL_VARIFICATION,
            A12Y_CONST.ROUTE.FORGOT_PASSWORD_EMAIL,
            A12Y_CONST.ROUTE.FORGOT_PASSWORD_VARIFICATION_CODE,
            A12Y_CONST.ROUTE.FORGOT_CREATE_PASSWORD,
            A12Y_CONST.ROUTE.FORGOT_PASSWORD_UPDATED,
            A12Y_CONST.ROUTE.EMAIL_LOGIN,
            A12Y_CONST.ROUTE.PASSWORD_UPDATED,
            A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT,
            A12Y_CONST.ROUTE.DEPENDENT,
            A12Y_CONST.ROUTE.DEPENDENT_BILLING,
            A12Y_CONST.ROUTE.DEPENDENT_VIEW,
            A12Y_CONST.ROUTE.EXPENSES,
            A12Y_CONST.ROUTE.ADD_EXPENSE,
            A12Y_CONST.ROUTE.EXPENSE_VIEW,
            A12Y_CONST.ROUTE.INCOMES,
            A12Y_CONST.ROUTE.INCOME_VIEW,
            A12Y_CONST.ROUTE.ADD_INCOME,
            A12Y_CONST.ROUTE.DOCUMENTS,
            A12Y_CONST.ROUTE.ACTIVITIES,
            A12Y_CONST.ROUTE.ACTIVITY_VIEW,
            A12Y_CONST.ROUTE.ADD_ACTIVITY,
            A12Y_CONST.ROUTE.LOGOUT,
            A12Y_CONST.ROUTE.ADD_ACTIVITY,
            A12Y_CONST.ROUTE.INVENTORY_VIEW,
            A12Y_CONST.ROUTE.INVENTORY,
            A12Y_CONST.ROUTE.ADD_ASSET,
            A12Y_CONST.ROUTE.EDIT_ASSET,
            A12Y_CONST.ROUTE.ADD_INSTITUTION,
            A12Y_CONST.ROUTE.ADD_CONTACT,
            A12Y_CONST.ROUTE.ADD_BENEFICIARY,
            A12Y_CONST.ROUTE.ADD_CO_OWNER,
            A12Y_CONST.ROUTE.ADD_LOAN,
            A12Y_CONST.ROUTE.MEDICAL,
            A12Y_CONST.ROUTE.ADD_MEDICATION,
            A12Y_CONST.ROUTE.ADD_DOCTOR,
            A12Y_CONST.ROUTE.DASHBOARD_VIEW,
        ]

        window.document.title = A12Y_CONST.TEXT.TITLE_HOME
        let script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id="+ CONFIG.GOOGLE_TRACKING_ID);
        //document.getElementsByTagName("head")[0].appendChild(script);
        let scriptPlaces = document.createElement("script");
        scriptPlaces.setAttribute("type", "text/javascript");
        scriptPlaces.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key="+ CONFIG.GOOGLE_PLACES_KEY + "&libraries=places");
        document.getElementsByTagName("head")[0].appendChild(scriptPlaces);
        window.dataLayer = window.dataLayer || [];
        let loadGtag = () => {
            window["gtag"] = function gtag() {
               // window.dataLayer.push(arguments);
            }
            window.gtag('js', new Date());

            window.gtag('config', CONFIG.GOOGLE_TRACKING_ID);
        }
        if(window.dataLayer) {
            loadGtag();
        } else {
            alert('ga not loaded.');
        }

        const tagManagerArgs = {
            gtmId: CONFIG.GTM_ID
        }
        TagManager.initialize(tagManagerArgs)

    }

    componentWillMount = async () => {
        const splitLocation = window.location.href.split("/");
        let path = '/'+splitLocation[splitLocation.length-1]

        if(path.includes(A12Y_CONST.ROUTE.LOGIN)){
            path = A12Y_CONST.ROUTE.LOGIN
        }else {
            path = '/'+splitLocation[splitLocation.length-1]

        }
        if(!this.props.isLoggedIn && path !== A12Y_CONST.ROUTE.LOGIN){
            await this.props.hadlePathBeforeLogin(path)
        }
    }


    switchDependentUsingUrl = async ()=>{
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const dependent_id = params.get('dependent_id');
        if(dependent_id !== null){
            localStorage.setItem(A12Y_CONST.TEXT.DEPENDENT_VIEW_ID,dependent_id)
            let checkDependentIsPresent = this.props.dependentList.filter(dependent => dependent.id === dependent_id)
            if(checkDependentIsPresent.length > 0){
                this.props.switchDependent(checkDependentIsPresent[0])
            }else {
               try {
                   secureClient.get(A12Y_CONST.API_ENDPOINT.ALL_DEPENDENTS).then(response=>{
                       if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                           let dependentList = response?.data?.sr?.dependents ? response.data.sr.dependents : []
                           if(dependentList.length !== 0 && dependentList.length !== this.props.dependentList.length){
                               this.props.updateDependentList(response?.data?.sr?.dependents)
                               let checkDependentIsPresent = dependentList.filter(dependent => dependent.id === dependent_id)
                               this.props.switchDependent(checkDependentIsPresent[0])
                           }
                       }
                   }).catch(e=>{
                       console.error('error',e)
                   })
               }catch (e) {
                   console.error('error',e)
                }
               }
        }
    }

    checkIsLogin = ()=>{
        this.switchDependentUsingUrl();
        //let userInfo = this.props.userState.userInfo
        // let userSubscription = this.props.userSubscription
        let userSubscription = this.props?.dependent?.access?.can_update ?  this.props?.userSubscriptionAllDetails?.subscription_details :  this.props?.dependent?.subscirption?.subscription_details
        //console.log('userSubscription---', userSubscription)
        // console.log('depndent---', this.props.dependent)
        return(
            (
                window.location.hash === A12Y_CONST.LABEL.HASH_BILLING && this.props.isLoggedIn && this?.props?.dependent?.access?.can_update && (userSubscription?.subscription_status !== A12Y_CONST.TEXT.EXPIRED || userSubscription?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT || !this.props.userSubscriptionAllDetails?.is_trial_expired )?
                <Redirect to={A12Y_CONST.ROUTE.DEPENDENT_BILLING}/>
                :
                    this.props.isLoggedIn && /*this?.props?.dependent?.access?.can_update &&*/ (userSubscription?.subscription_status === A12Y_CONST.TEXT.EXPIRED || userSubscription?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT || this.props.userSubscriptionAllDetails?.is_trial_expired ) &&  this.routeName.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase()) === -1  /*this.routeName.indexOf(window.location.pathname) === -1*/ ?
                        <Redirect to={A12Y_CONST.ROUTE.DASHBOARD_VIEW}/>
                        :
                        (this.props.isLoggedIn  && this.notAllowedAfterLOgin.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase()) !== -1/* this.notAllowedAfterLOgin.indexOf(window.location.pathname) !== -1*/ && (this.routeName.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase()) !== -1 || this.routeName.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase()) === -1) /*this.routeName.indexOf(window.location.pathname) !== -1*/ ?
                            /* <Redirect to={userInfo.phones && userInfo.phones.length > 0 && Object.keys(this.props.dependent).length === 0 ? A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW : this.props.dependent && Object.keys(this.props.dependent).length && userSubscription !== undefined && userSubscription !== null > 0 && userSubscription.subscription_status !== A12Y_CONST.TEXT.EXPIRED ? A12Y_CONST.ROUTE.ACTIVITIES : this.props.dependent && Object.keys(this.props.dependent).length && userSubscription !== undefined && userSubscription !== null ? A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT  : A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW } />*/
                            /*<Redirect to={this.props.isLoggedIn && ((userInfo.phones!== null && (userInfo.phones.length > 0 || userInfo.phones === null)) || userInfo.phones === null) && ((Object.keys(this.props.dependent).length > 0 && (this.props.dependent.access.can_update) && (userSubscription === undefined || userSubscription === null)) || Object.keys(this.props.dependent).length === 0) ?  A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW : this.props.isLoggedIn && userInfo.phones === null && ((Object.keys(this.props.dependent).length > 0 && !this.props.dependent.access.can_update && (userSubscription === undefined || userSubscription === null))) ? A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW : this.allowedScreenAfterLogin.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase()) !== -1 ? this.allowedScreenAfterLogin[this.allowedScreenAfterLogin.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase())] : A12Y_CONST.ROUTE.DASHBOARD_VIEW} />*/
                            <Redirect to={this.props.isLoggedIn && ((Object.keys(this.props.dependent).length > 0 && /*(this.props?.dependent?.access?.can_update) &&*/ (userSubscription?.subscription_status === A12Y_CONST.TEXT.EXPIRED || userSubscription?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT || this.props?.userSubscriptionAllDetails?.is_trial_expired )))  ?  A12Y_CONST.ROUTE.DASHBOARD_VIEW : this.props.isLoggedIn && ((Object.keys(this.props.dependent).length > 0 && !this.props?.dependent?.access?.can_update && (userSubscription === undefined || userSubscription === null))) ? A12Y_CONST.ROUTE.DASHBOARD_VIEW : this.allowedScreenAfterLogin.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase()) !== -1 ? this.allowedScreenAfterLogin[this.allowedScreenAfterLogin.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase())] : A12Y_CONST.ROUTE.DASHBOARD_VIEW} />
                            :
                            null)
            )
        )
    }


    componentDidMount() {
        window.addEventListener("focus", this.onFocus);
        window.addEventListener("blur", this.onBlur);
        window.addEventListener("mousemove", this.onMousemove);
        this.time_start = new Date();
        this.logoutHandle = setTimeout(this.logoutNow, A12Y_CONST.LOGOUT_TIME)
        let url = window.location.href;
        // if(url.includes(A12Y_CONST.ROUTE.TRUSTWORTHY)){
        //     this.props.history.push(A12Y_CONST.ROUTE.SIGNUP);
        // }
    }

    /*componentWillUnmount() {
        window.removeEventListener("focus", this.onBlur)
    }*/

    onMousemove = (e) => {
        clearTimeout(this.logoutHandle);
        this.logoutHandle = setTimeout(this.logoutNow, A12Y_CONST.LOGOUT_TIME)
    }

    logoutNow = async () => {
        if(this.allowedScreenAfterLogin.findIndex(path => path.toLowerCase() === window.location.pathname.toLowerCase()) !== -1 ){
            let screenName = window.location.pathname
            if(this.props.screen !== screenName ){
                await this.props.userSessionBackupScreenDetails(screenName)
            }
            await this.props.setLogoutPopStatus(true)
        }
    }

    onFocus = () => {
        if(this.intervalId !== null){
            clearInterval(this.intervalId)
            if(this.state.shouldReload){
                this.setState({shouldReload:false})
                this.refreshPage();
            }
        }
    }
    onBlur = () => {
        if(!this.state.shouldReload){
            this.startTimer(A12Y_CONST.REFRESH_TIMEOUT)
        }
    }

    refreshPage = () => {
        this.logoutNow();
        window.location.reload(true);
    }

     startTimer =(duration)=> {
        var timer = duration, minutes, seconds;
        let temp = this
      this.intervalId = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
          if(minutes.toString() === '00' && seconds.toString() === '00'){
              clearInterval(temp.intervalId)
              temp.setState({shouldReload:true})
             // window.location.reload(true);
          }
            //display.textContent = minutes + ":" + seconds;

            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    }

    hidePopUp = async () => {
        await this.setState({isPopUp:false})
        let logoutResponse = LogOut()
        if(!logoutResponse){
            this.props.history.push(A12Y_CONST.ROUTE.HOME);
        }
    }
    // currentSreenName = async (screenName) => {
    //     // let path = screenName.replace("\"", "")
    //     if(this.props.screen === null && this.props.screen !== screenName ){
    //       //  await this.props.userSessionBackupScreenDetails(screenName)
    //     }
    // }

    render(){
        return(
            <div>
                {this.checkIsLogin() }
                <LiveChatWidget
                    license={CONFIG.LIVE_CHAT_LICENSE}
                />
                <ScrollToTop>
                    <Switch>
                        <LayoutRoute exact path={A12Y_CONST.ROUTE.HOME} component={SiginScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.LOGIN} component={SiginScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.SIGNUP} component={SiginUpScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.TRUSTWORTHY} component={SiginUpScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.ABONELAW} component={SiginUpAbonelawScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.SPONSOREXAMPLE} component={SignUpSampleScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.SIGNUP_EMAIL} component={SiginUpWithEmailScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.CODE} component={CodeScreen} layout={LayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.SIGN_UP_EMAIL_VARIFICATION} component={SingUpEmailVarification} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.FORGOT_PASSWORD_EMAIL} component={ForgotPasswordEmailScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.FORGOT_PASSWORD_VARIFICATION_CODE} component={ForgotPasswordVerificationCode} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.FORGOT_CREATE_PASSWORD} component={ForgotCreatePasswordScreen} layout={LayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.FORGOT_PASSWORD_UPDATED} component={ForgotPasswordUpdatedScreen} layout={LayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.EMAIL_LOGIN} component={LoginEmailVerifiedScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute path={A12Y_CONST.ROUTE.PASSWORD_UPDATED} component={LoginEmailVerifiedScreen} layout={NewLayoutWithoutLogin}/>
                        <LayoutRoute type={A12Y_CONST.LABEL.MY_ACCOUNT} path={A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW} component={NewMyAccountDependentScreen} layout={NewLayoutWithoutLogin} />
                        <LayoutRoute path={A12Y_CONST.ROUTE.RELEASE_NOTES} component={ReleaseNotes} layout={LayoutWithoutLogin}/>
                        {/*<ProtectedRoute path={A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT} component={AdvocateProfileMyAccountScreen} layout={LayoutWithLogin}/>*/}
                        <ProtectedRoute history={this.props.history}  path={A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT} component={MyAccountScreen} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DASHBOARD} component={SecureScreen} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.LOGOUT} component={Logtout} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DEPENDENT} component={Dependent} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DEPENDENT_BILLING} component={MyAccountScreen} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DEPENDENT_VIEW} component={DependentView} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EXPENSES} component={Expenses} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_EXPENSE} component={AddExpense} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EXPENSE_VIEW} component={ExpenseView} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.INCOMES} component={Incomes} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.INCOME_VIEW} component={IncomeView} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_INCOME} component={AddIncome} layout={LayoutWithLogin}/>
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DOCUMENTS} component={Documents} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ACTIVITIES} component={Activities} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ACTIVITY_VIEW} component={ActivityView} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_ACTIVITY} component={AddActivity} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.INVENTORYVIEW} component={InventoryView} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.HELP} component={Help} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.INVENTORY} component={Inventory} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_ASSET} component={AddAsset} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EDIT_ASSET} component={AssetEditScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_CO_OWNER} component={AddCoOwner} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EDIT_CO_OWNER} component={AddCoOwner} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_CONTACT} component={AddContact} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EDIT_CONTACT} component={AddContact} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_BENEFICIARY} component={AddBeneficiary} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EDIT_BENEFICIARY} component={AddBeneficiary} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_INSTITUTION} component={AddInstitutionScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_LOAN} component={AddLoanAgainstPolicy} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_ADDRESS} component={AddAddressScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.MEDICAL} component={MedicalScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_DOCTOR} component={AddDoctorScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EDIT_DOCTOR} component={AddDoctorScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_MEDICATION} component={AddMedicationScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.MEDICATION_VIEW} component={MedicationViewScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DOCTOR_VIEW} component={DoctorViewScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.ADD_DEBT} component={AddDebtScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.EDIT_DEBT} component={AddDebtScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DEBT_VIEW} component={DebtViewScreen} layout={LayoutWithLogin} />
                        <ProtectedRoute path={A12Y_CONST.ROUTE.DASHBOARD_VIEW} component={DashboardScreen} layout={LayoutWithLogin} />

                        {/*<ProtectedRoute path={A12Y_CONST.ROUTE.MY_ACCOUNT} component={MyAccountViewScreen} layout={LayoutWithLogin} />*/}
                        {/*<ProtectedRoute exact path={A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT} component={MyAccountScreen} layout={LayoutWithLogin} />*/}
                    </Switch>
                </ScrollToTop>

                <PopUpLoggedOut
                    popUpStatus={this.props.isLogOutPopUP}
                    hidePopUp={this.hidePopUp}
                    // currentSreenName={(name) => {this.currentSreenName(name)}}
                />
             </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    isDataChanged : state.userDetailReducer.isDataChanged,
    userState : state.userDetailReducer.userState,
    dependent : state.dependentReducer.dependent,
    currentExpense : state.dependentReducer.currentExpense,
    userSubscription : state.userDetailReducer.userSubscription,
    userRoleType : state.userDetailReducer.userRoleType,
    dependentList : state.dependentReducer.dependentList,
    emailDependentInvitation : state.dependentReducer.emailDependentInvitation,
    isLogOutPopUP : state.userDetailReducer.isLogOutPopUP,
    screen : state.dependentReducer.screen,
    userSubscriptionAllDetails : state.userDetailReducer.userSubscriptionAllDetails,
    pathWithoutLogin : state.dependentReducer.pathWithoutLogin,

});

const mapDispatchToProps = dispatch => ({
    signUp : (userData) => dispatch(userDetailActions.signUp(userData)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    signUpTextChange : (data) => dispatch(userDetailActions.signUpTextChange(data)),
    switchDependent : (dependent) => dispatch(dependentActions.switchDependent(dependent)),
    updateDependentList : (dependents) => dispatch(dependentActions.updateDependentList(dependents)),
    setLogoutPopStatus : (status) => dispatch(userDetailActions.setLogoutPopStatus(status)),
    userSessionBackupScreenDetails : (screen) => dispatch(dependentActions.userSessionBackupScreenDetails(screen)),
    hadlePathBeforeLogin : (path) => dispatch(dependentActions.hadlePathBeforeLogin(path)),
});

export default withRouter (connect(mapStateToProps,mapDispatchToProps) (App));
