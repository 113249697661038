import React, {useEffect, useState, useRef} from 'react'
import A12Y_CONST from "../common/a12yConst";
import "react-datepicker/dist/react-datepicker.css";
import Tab from "@material-ui/core/Tab";
import TabList from '@material-ui/lab/TabList';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import viewIcon from '../assets/images/viewIcon.svg'
import PopUpIncomeExpComponent from '../components/PopUpIncomeExpComponent'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import {downloadBase64File} from "../util/Common";
import {secureClient} from "../config/axiosClient";
import LoaderFullScreen from "./LoaderFullScreenComponent";
import Multiselect from "multiselect-react-dropdown";

function MedicalListComponent(props) {
    const [activeTab, setActiveTab] = useState('1');

    const [isPopUp, setIsPopUp] = useState(false);
    const [medicationList, setMedicationList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMedical, setLoadingMedical] = useState(false);
    const [loadingDoctor, setLoadingDoctor] = useState(false);
    const [error, setErrors] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [isSorted, setIsSorted] = useState(false);
   /* const [dateSortClass, setDateSortClass] = useState('');
    const [nameSortClass, setNameSortClass] = useState('');
    const [doctorNameSortClass, setDoctorNameSortClass] = useState('');*/
    const [sortConfig, setSortConfig] = useState({key:'', order:''});
	//const [printObj, setPrintObj] = useState({});
	const [isDoctortabActive, setIsDoctorTabActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const refFilterAction = useRef(null);
    const refCalendarAction = useRef(null);
    const [isResponsiveFilter, setisResponsiveFilter] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [isResponsiveCalendar, setisResponsiveCalendar] = useState(false);
    const [selectedTypeFilter, setSelectedTypeFilter] = useState([]);
    const [typeFilter, setTypeFilter] = useState([]);
    const [isFilterUpdated, setIsFilterUpdated] = useState(false);
    const [medicalStatusList, setMedicalStatusList] = useState([
        {title:'active',status:1},
        {title:'inActive',status:0}
    ]);


    useEffect(()=>{
        setLoading(props.loading)
        setLoadingMedical(props.loadingMedical)
        setLoadingDoctor(props.loadingDoctor)
        setErrors(props.error)
        setDoctorList(props.doctorList)
        setMedicationList(props.medicationList)
        setErrorMessage(props.errorMessage)
    },[props.medicationList, props.loading,props.error,props.doctorList,props.loadingMedical,props.errorMessage])


    const handleChange = (event, newValue) => {
        props.onTabChange(newValue === '1' ? true : false)
        setActiveTab(newValue);
    };
    const editDoctor = (e,item) => {
        props.onEditDoctor(item)
        e.stopPropagation()
    };

    const editMedication =(item)=>{
        props.onEditMedication(item)
    }

    useEffect( ()=>{
        if(props.tabType !== null){
            setActiveTab('2')
            props.onTabChange(false)
            setIsDoctorTabActive(true);
        }
    },[props.tabType]) // eslint-disable-line react-hooks/exhaustive-deps

    /*useEffect(  () => {
       setPrintObj({
           title: activeTab === '1' ? A12Y_CONST.LABEL.MEDICATIONS : A12Y_CONST.LABEL.DOCTOR,
           total: '',
           startDate: '',
           endDate: '',
           dependentName: props.dependent.first_name + ' ' + props.dependent.last_name,
           advocateName: props.userInfo.first_name + ' ' + props.userInfo.last_name,
           totalBg: '',
           totalColor: ''
       })

   }, [props.dependent.first_name, props.dependent.last_name, props.userInfo.first_name, props.userInfo.last_name, activeTab]) // eslint-disable-line react-hooks/exhaustive-deps*/

    const addDoctor = () =>{
        props.addDoctorScreen()
    }
    const addMedication = () => {
        props.addMedication()
    }
    function viewDetails(doctor) {
        props.onView(doctor)
    }
    function viewDetailsMedication(medication) {
        props.onViewMedication(medication)
    }

    const deleteDoctor = (e,item)=>{
        setSelectedData(item)
        setIsPopUp(true)
        e.stopPropagation()
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.onDeleteDoctor(selectedData)
    }

    function sortBy(objName, objToSort, sortType) {
        let sortedArray = [];
        const tempArray = [...objToSort];
        /*setDateSortClass('')
        setNameSortClass('')
        setDoctorNameSortClass('')*/
        if (sortType === 'start_date') {
            if (isSorted) {
                sortedArray = tempArray.sort((a, b) => (
                    new Date(a['start_date']) - new Date(b['start_date'])
                ));
                setIsSorted(false)
                setSortConfig({key: sortType, order: 'active asc'})
            } else {
                sortedArray = tempArray.sort((a, b) => (
                    new Date(b['start_date']) - new Date(a['start_date'])
                ));
                setIsSorted(true)
                setSortConfig({key: sortType, order: 'active dec'})
            }
        }else if(sortType === 'status'){
            let activeList = tempArray.filter(item => item.status === 1);
            let inActiveList = tempArray.filter(item => item.status === 0)
            if(activeList.length > 0){
                 activeList.sort((a, b) => a.medicine_name.localeCompare(b.medicine_name))
            }if(inActiveList.length > 0){
               inActiveList.sort((a, b) => a.medicine_name.localeCompare(b.medicine_name))
            }
            let updatedSortedList = []
            if(isSorted){
                updatedSortedList = [...inActiveList,...activeList]
                sortedArray = updatedSortedList
                setIsSorted(false)
                setSortConfig({key: sortType, order: 'active asc'})
            }else {
                updatedSortedList = [...activeList,...inActiveList]
                sortedArray = updatedSortedList
                setIsSorted(true)
                setSortConfig({key: sortType, order: 'active dec'})
            }
        }
        else {
            if (isSorted) {
                sortedArray = tempArray.sort((a, b) => {
                    if (a[sortType] < b[sortType]) {
                        return -1;
                    }
                    if (a[sortType] > b[sortType]) {
                        return 1;
                    }
                    return 0;
                });
                setIsSorted(false)
                setSortConfig({key: sortType, order: 'active asc'})

            } else {
                sortedArray = tempArray.sort((b, a) => {
                    if (a[sortType] < b[sortType]) {
                        return -1;
                    }
                    if (a[sortType] > b[sortType]) {
                        return 1;
                    }
                    return 0;
                });
                setIsSorted(true)
                setSortConfig({key: sortType, order: 'active dec'})
            }
        }
        if(objName === 'medicationList'){
            setMedicationList(sortedArray)
        }else if(objName === 'doctorList'){
            setDoctorList(sortedArray)
        }
        setisResponsiveFilter(false);
    }
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.order : '';
    };
    const getMedicationData = async () =>{
        setErrors(false)
        setErrorMessage('')
        setSortConfig({key:'', order:''})
        setisResponsiveFilter(false);
      await setIsDoctorTabActive(false);
    }
    const getDoctorData = async () =>{
        setErrors(false)
        setErrorMessage('')
        setSortConfig({key:'', order:''})
      await setIsDoctorTabActive(true);
    }

    const showFilter = () => {
        setisResponsiveFilter(!isResponsiveFilter);
    }

    const hideCalendar_Filter = async (event) => {
        if (refFilterAction.current && !refFilterAction.current.contains(event.target)) {
            setisResponsiveFilter(false);
        }
        if (refCalendarAction.current && !refCalendarAction.current.contains(event.target)) {
            setisResponsiveCalendar(false)
            await filterPopUpClose();
        }
    }

    useEffect(() => {
        document.addEventListener('click', hideCalendar_Filter, true);
        return () => {
            document.removeEventListener('click', hideCalendar_Filter, true);
        };
    });

    const goToMedicalOverviewScreen = ()=>{
        props.goToMedicalOverviewScreen(activeTab);
    }

    var count = 0;
    const printFromBackEnd = async () => {
       try {
           setErrors(false)
           setErrorMessage('')
           await setLoaderFullScreenVisible(true);
           let report_type = activeTab === '1' ? 'medicine' : 'doctor'
           let response = await secureClient.post('pdf_report', {"dependent_id" : props.dependent.id, "report_type" : report_type});
           if(response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK){
               downloadBase64File(response.data.sr.pdf_base64, response.data.file_name)
               await setLoaderFullScreenVisible(false);
           }else{
               setErrors(true)
               await setLoaderFullScreenVisible(false);
           }
       }catch (e) {
           count++
           if(count <= 3){
               printFromBackEnd()
           }else{
               setErrors(true)
               setErrorMessage(A12Y_CONST.ERROR_MESSAGE.NETWORK_ERROR_TRY_AGAIN)
               await setLoaderFullScreenVisible(false);
               count = 0;
           }
       }
    }

    const filterPopUpClose = async () => {
        setisResponsiveCalendar(false)
    }
    const clearFilter = async () => {
        await setIsFilter(false);
        await setisResponsiveCalendar(false);
    }
    function filterIncome() {
        setisResponsiveCalendar(false)
    }
    /*const showCalendar = async () => {
        setisResponsiveCalendar(!isResponsiveCalendar)
    }*/
    const onSelect = (selectedList, selectedItem) => {
        selectedTypeList(selectedList)
    }
    const onRemove = (selectedList, removedItem) => {
        selectedTypeList(selectedList)
    }

    const selectedTypeList = async (selectedList) => {
        var listactiveType = [];
        selectedList.map((type) => (
            listactiveType.push(type.id)
        ))
        setSelectedTypeFilter(listactiveType)
        await setTypeFilter(selectedList);
    }

//    console.log('medicationList',props.medicationList)
    return (
        <div className='medicalListMain'>
            {loaderFullScreenVisible ?
                <LoaderFullScreen />
                :
                null
            }
            {loading || props.loadingMedical || props.loadingDoctor ?
                <div className="transparentLayer maxHeight150px"></div>
                :
                null
            }
            <h2 className="titleWithSidebar medicalTitleMargin marginBottom30 positionRelative inventoryListError justifySpaceBetween">
                {error ?
                    <div className='maxWidth600 medicalPosTop marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={errorMessage} />
                    </div>
                    :
                    (props.message ?
                    <SuccessMessageComponent successMessage={props.message} />
                    :
                    null
                    )

                }
                <span>{A12Y_CONST.LABEL.MEDICAL}</span>
                <span id={A12Y_CONST.BUTTON_ID.LEARN_MORE+A12Y_CONST.LABEL.MEDICATION} onClick={()=>{goToMedicalOverviewScreen()}} className="titleRightContent positionAbsoute">{A12Y_CONST.LABEL.LEARN_MORE}</span>
            </h2>
            <div className='padding20 fullWidth responsiveListTableTop responsiveTalbe incomeBoxcol medicalBoxCol'>
                <div className="tableTop medicalTopRow fullWidth tableTopMain df aic">
                        <div className="fullWidth positionRelative df aic">
                            <span className={'icoWrap hideDesktopShowMobile marginRight10 ' + (isResponsiveFilter && 'thisActive')}>
                                    <span onClick={showFilter}
                                          className={'a12-icon filterIcon notToPrint'}>
                                        {
                                            window.innerWidth > 768 ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
                                                    <path d="M9.00684 3.02734L17.0068 2.99987" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M1.00684 3.05493L5.00681 3.04119" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <circle cx="7.00688" cy="3.03444" r="2" transform="rotate(89.8032 7.00688 3.03444)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M14.0308 10.0103L17.0307 9.99995" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M1.03076 10.0549L9.03071 10.0275" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <circle cx="12.0308" cy="10.0171" r="2" transform="rotate(89.8032 12.0308 10.0171)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M1.05469 17.0549L3.05468 17.0481" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M9.05469 17.0273L17.0546 16.9999" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                    <circle cx="6.05473" cy="17.0379" r="2" transform="rotate(89.8032 6.05473 17.0379)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                                :
                                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.6001 1H18.4001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                    <path d="M1.6001 6H13.6001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                    <path d="M1.6001 11H8.8001" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                </svg>
                                        }
                                    </span>
                                    <i></i>
                                </span>
                          {/*  <span className={'icoWrap filterTextIconColoum ' + (isResponsiveCalendar && 'thisActive')}>
                                    <span onClick={showCalendar} className={'a12-icon dateIcon'} id='dateIcon'>
                                        {
                                            window.innerWidth > 768 ?
                                                <>
                                                    {
                                                        isFilter?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
                                                                <path d="M9.00684 3.02734L17.0068 2.99987" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M1.00684 3.05493L5.00681 3.04119" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <circle cx="7.00688" cy="3.03444" r="2" transform="rotate(89.8032 7.00688 3.03444)" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M14.0308 10.0103L17.0307 9.99995" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M1.03076 10.0549L9.03071 10.0275" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <circle cx="12.0308" cy="10.0171" r="2" transform="rotate(89.8032 12.0308 10.0171)" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M1.05469 17.0549L3.05468 17.0481" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M9.05469 17.0273L17.0546 16.9999" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                                <circle cx="6.05473" cy="17.0379" r="2" transform="rotate(89.8032 6.05473 17.0379)" stroke="#D96941" strokeWidth="2" strokeLinecap="round"/>
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
                                                                <path d="M9.00684 3.02734L17.0068 2.99987" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M1.00684 3.05493L5.00681 3.04119" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <circle cx="7.00688" cy="3.03444" r="2" transform="rotate(89.8032 7.00688 3.03444)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M14.0308 10.0103L17.0307 9.99995" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M1.03076 10.0549L9.03071 10.0275" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <circle cx="12.0308" cy="10.0171" r="2" transform="rotate(89.8032 12.0308 10.0171)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M1.05469 17.0549L3.05468 17.0481" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <path d="M9.05469 17.0273L17.0546 16.9999" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                                <circle cx="6.05473" cy="17.0379" r="2" transform="rotate(89.8032 6.05473 17.0379)" stroke="#2D6CB2" strokeWidth="2" strokeLinecap="round"/>
                                                            </svg>
                                                    }
                                                </>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
                                                    <path d="M9.00684 3.02734L17.0068 2.99987" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M1.00684 3.05493L5.00681 3.04119" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <circle cx="7.00688" cy="3.03444" r="2" transform="rotate(89.8032 7.00688 3.03444)" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M14.0308 10.0103L17.0307 9.99995" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M1.03076 10.0549L9.03071 10.0275" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <circle cx="12.0308" cy="10.0171" r="2" transform="rotate(89.8032 12.0308 10.0171)" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M1.05469 17.0549L3.05468 17.0481" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <path d="M9.05469 17.0273L17.0546 16.9999" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                    <circle cx="6.05473" cy="17.0379" r="2" transform="rotate(89.8032 6.05473 17.0379)" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                        }
                                        <span className={`filterText ${isFilter?'filterTextActive':''}`}>{A12Y_CONST.LABEL.FILTER}</span>
                                    </span>
                                    <i></i>
                                </span>*/}
                            <span>
                                   <span onClick={() => printFromBackEnd()} className="a12-icon printIcon notToPrint marginLeft10 hideDesktopShowMobile">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                            <path d="M8.13504 9.42846C7.81509 9.12375 7.30871 9.13611 7.004 9.45605C6.69929 9.77599 6.71164 10.2824 7.03159 10.5871L8.13504 9.42846ZM13 15.1665L12.4483 15.7458L13 16.2713L13.5517 15.7458L13 15.1665ZM18.9684 10.5871C19.2883 10.2824 19.3007 9.77599 18.996 9.45605C18.6912 9.13611 18.1849 9.12375 17.8649 9.42846L18.9684 10.5871ZM13.8 4.33317C13.8 3.89134 13.4418 3.53317 13 3.53317C12.5582 3.53317 12.2 3.89134 12.2 4.33317L13.8 4.33317ZM7.03159 10.5871L12.4483 15.7458L13.5517 14.5872L8.13504 9.42846L7.03159 10.5871ZM13.5517 15.7458L18.9684 10.5871L17.8649 9.42846L12.4483 14.5872L13.5517 15.7458ZM13.8 15.1665L13.8 4.33317L12.2 4.33317L12.2 15.1665L13.8 15.1665Z" fill="white"/>
                                            <path d="M5.41669 17.3335L5.41669 18.4168C5.41669 19.6134 6.38674 20.5835 7.58335 20.5835L18.4167 20.5835C19.6133 20.5835 20.5834 19.6134 20.5834 18.4168V17.3335" stroke="white" strokeWidth="1.6" strokeLinecap="round"/>
                                        </svg>
                                   </span>
                                </span>
                            {isResponsiveCalendar || isResponsiveFilter ?
                                <div className="orerlayFilter"></div>
                                :
                                null
                            }
                            <div ref={refCalendarAction}  className={`dateWrap df aic ${isResponsiveCalendar?'calendarDisplayBlock':null}`}>
                                <h3 className='filterByDate'>{A12Y_CONST.LABEL.FILTERS} <span onClick={() => filterPopUpClose()}  className={`clearTextInFilterCol`}><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"> <path d="M6.5 5.05908L11.5558 0L13 1.44117L7.94422 6.5L13 11.5588L11.5558 13L6.5 7.94092L1.44422 13L0 11.5588L5.05578 6.5L0 1.44117L1.44422 0L6.5 5.05908Z" fill="#71767A"/></svg></span></h3>
                                <div className='fullWidth typeTitleFilter'>
                                    <div className="txtTo fullWidth">
                                        {A12Y_CONST.LABEL.TYPE}
                                    </div>
                                    <div className="fullWidth typeListFilter padding20">
                                        <Multiselect
                                            options={medicalStatusList} // Options to display in the dropdown
                                            selectedValues={typeFilter} // Preselected value to persist in dropdown
                                            onSelect={onSelect} // Function will trigger on select event
                                            onRemove={onRemove} // Function will trigger on remove event
                                            displayValue="title" // Property name to display in the dropdown options
                                            showCheckbox
                                            avoidHighlightFirstOption={false}
                                        />

                                    </div>
                                </div>
                                <div className='marginLeft10 filterButtonRow fullWidth dateColButonRow'>
                                    <button className={`btn-outline btn-md saveButton filterCancelButton ${isFilter?'buttonActive':''}`} onClick={clearFilter} >{A12Y_CONST.LABEL.CLEAR}</button>
                                    <button className={`btn-outline btn-md saveButton filterButton ${isFilterUpdated ? 'applybuttonActive':''}`} onClick={() => filterIncome()}>{A12Y_CONST.TEXT.APPLY}</button>
                                </div>
                            </div>
                            {isResponsiveFilter &&
                            <div ref={refFilterAction}
                                 className={`filterDorpDownMain hideDesktopShowMobile ${isResponsiveFilter ? 'displayBlock' : null}`}>
                                 <h3>{A12Y_CONST.LABEL.SORT_BY}</h3>

                                {
                                    isDoctortabActive ?
                                        <ul>
                                            <li><span onClick={() => sortBy('doctorList',doctorList,'speciality_id')}  className={`${getClassNamesFor('speciality_id')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.SPECIALTY.toUpperCase()}<i className="arrow down"></i></span></li>
                                            <li><span onClick={() => sortBy('doctorList',doctorList,'doctor_name')} className={`${getClassNamesFor('doctor_name')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.NAME.toUpperCase()}<i className="arrow down"></i></span></li>
                                            <li><span onClick={() => sortBy('doctorList',doctorList,'hospital_name')} className={`${getClassNamesFor('hospital_name')} ellipsis filterBy df aic cursorPointer`}><span className='ellipsisFilter'>{A12Y_CONST.LABEL.PRACTICE_OR_HOSPITAL_GROUP.toUpperCase()}</span><i className="arrow down"></i></span></li>
                                        </ul>
                                        :
                                        <ul>
                                            <li><span onClick={() => sortBy('medicationList',medicationList,'status')} className={`${getClassNamesFor('status')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.STATUS}<i className="arrow down"></i></span></li>
                                            <li><span onClick={() => sortBy('medicationList',medicationList,'medicine_name')} className={`${getClassNamesFor('medicine_name')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.NAME}<i className="arrow down"></i></span></li>
                                            <li><span onClick={() => sortBy('medicationList',medicationList, 'start_date')}   className={`${getClassNamesFor('start_date')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.START_DATE} <i className="arrow down"></i></span></li>
                                        </ul>
                                }
                            </div>
                            }
                        </div>
                    {activeTab === '1' ?
                        <div className='df marginBottom10'>
                            <button id={A12Y_CONST.BUTTON_ID.DOWNLOAD_MEDICAL} className="btn-outline  saveButton marginRight20 displayNone600 printButton" onClick={() => printFromBackEnd()}>{A12Y_CONST.TEXT.DOWNLOAD}</button>
                            {props.dependent?.access && props.dependent.access.can_update === true ?
                                <button id={A12Y_CONST.TEXT.ADD+A12Y_CONST.LABEL.MEDICATION} className="btn-primary df alignCenterVH saveButton plusIcon" onClick={addMedication}><span>+</span> <span
                                    className='displayNone600 marginLeft10'>{A12Y_CONST.LABEL.MEDICATION}</span></button>
                                :
                                null
                            }
                        </div>
                        :
                        <div className='df marginBottom10'>
                            <button id={A12Y_CONST.BUTTON_ID.DOWNLOAD_PROVIDER} className="btn-outline  saveButton marginRight20 displayNone600 printButton" onClick={() => printFromBackEnd()}>{A12Y_CONST.TEXT.DOWNLOAD}</button>
                            {props.dependent?.access && props.dependent.access.can_update === true ?
                                <button id={A12Y_CONST.TEXT.ADD+A12Y_CONST.LABEL.DOCTOR} className="btn-primary df alignCenterVH saveButton plusIcon" onClick={addDoctor}><span>+</span> <span
                                    className='displayNone600 marginLeft10'>{A12Y_CONST.LABEL.DOCTOR}</span></button>
                                :
                                null
                            }
                        </div>
                    }
                    </div>
            </div>
            <div className="tableBody responsiveTalbe medicalTableTitle medical">
                <div className='fullWidth'>
                    <TabContext className='fullWidth' value={activeTab}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab className='attachTxt' onClick={getMedicationData} label={A12Y_CONST.TAB_TEXT.MEDICATIONS} value="1" />
                            <Tab className='attachTxt' onClick={getDoctorData} label={A12Y_CONST.TAB_TEXT.DOCTOR} value="2" />
                        </TabList>
                        <TabPanel value="1">

                            {
                                props.loadingMedical ?
                                <div className='noData df aic jcc fcol'>
                                    <div className="positionRelative">
                                        <div className="dependentLoader">
                                            <div className='fullWidth justifyCenter marginBottom10'>
                                                <div className="stage">
                                                    <div className="dot-pulse"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='marginTop20'>
                                            <span>{A12Y_CONST.TEXT.LOADING_MEDICAL}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                 <div className="tableBody responsiveTalbe medicalTableTitle" id='Printable'>
                                                                {
                                                                    medicationList.length > 0 ?
                                                                        (<table cellSpacing="0" cellPadding="0" border="0" className="fullWidth assetTable" id="TableContent">
                                                                                <thead className='mt20'>
                                                                                <tr>
                                                                                    <th className='statusCol'><span onClick={() => sortBy('medicationList',medicationList,'status')} className={`${getClassNamesFor('status')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.STATUS} <i className="arrow down"></i></span></th>
                                                                                    <th className='nameCol width30Percent'><span onClick={() => sortBy('medicationList',medicationList,'medicine_name')} className={`${getClassNamesFor('medicine_name')} nameColSpan filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.NAME}<i className="arrow down"></i></span></th>
                                                                                    <th className='dateCol'><span onClick={() => sortBy('medicationList',medicationList, 'start_date')} className={`${getClassNamesFor('start_date')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.START_DATE}<i className="arrow down"></i></span></th>
                                                                                    <th className='nameCol width30Percent paddingLeft35'><span className={`filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.PRESCRIVING_DOCTOR}</span></th>
                                                                                    <th className='instructions notToPrint notToPrintMed'><span className={` filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.INSTITUTION.toUpperCase()}<i className="arrow down"></i></span></th>
                                                                                    <th className='notToPrint notToPrintMed'><span className={`filterBy df aic cursorPointer`}> <i className="arrow down"></i></span></th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {
                                                                                    medicationList.map((item, i) => {
                                                                                        return (
                                                                                            <tr onClick={() => viewDetailsMedication(item)} key={i}>
                                                                                                <td className='dateCol'><span onClick={() => viewDetailsMedication(item)} className="h100 df aic cursorPointer marginTop10 marginLeft1  0">
                                                                                                    {
                                                                                                        item.status === 1 ?
                                                                                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path d="M18 0.992004V19.008C17.998 19.271 17.893 19.522 17.707 19.707C17.521 19.893 17.27 19.998 17.007 20H0.992981C0.729981 20 0.477016 19.896 0.291016 19.71C0.105016 19.524 0 19.271 0 19.008V0.992004C0.002 0.729004 0.10703 0.47803 0.29303 0.29303C0.47903 0.10703 0.729981 0.002 0.992981 0H17.007C17.555 0 18 0.444004 18 0.992004ZM16 2H2V18H16V2ZM8.29303 11.121L12.536 6.87903L13.95 8.29303L8.29303 13.95L4.40302 10.06L5.81799 8.646L8.29303 11.121Z" fill="#00A91C"/>
                                                                                                            </svg>
                                                                                                            :
                                                                                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path d="M1 1.5H17V18.5H1V1.5Z" fill="white" stroke="#8A9197" strokeWidth="2"/>
                                                                                                                <rect x="5" y="9" width="8" height="2" fill="#8A9197"/>
                                                                                                            </svg>
                                                                                                    }

                                                                                                </span></td>
                                                                                                <td onClick={() => viewDetailsMedication(item)}>
                                                                                                    <div className='ellipsis medLongText'><span className="cursorPointer"> {item.medicine_name}</span></div>
                                                                                                    <div className='ellipsis medLongText marginTop15'><span className="cursorPointer medicationinstruction"> {item.uses_instruction}</span></div>
                                                                                                </td>
                                                                                                {/*<td><span onClick={() => viewDetailsMedication(item)} className="h100 df aic cursorPointer">{item.start_date ? moment(item.start_date).format("MM/DD/YYYY") : null}</span></td>*/}

                                                                                                <td><
                                                                                                    span onClick={() => viewDetailsMedication(item)} className="h100 df aic medicalDateCol_600 cursorPointer">{item.start_date ? item.start_date : null}</span>
                                                                                                    <span className="statusWithDate notToPrint notToPrintMed">
                                                                                                         {
                                                                                                             item.status === 1 ?
                                                                                                                 <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                     <path d="M18 0.992004V19.008C17.998 19.271 17.893 19.522 17.707 19.707C17.521 19.893 17.27 19.998 17.007 20H0.992981C0.729981 20 0.477016 19.896 0.291016 19.71C0.105016 19.524 0 19.271 0 19.008V0.992004C0.002 0.729004 0.10703 0.47803 0.29303 0.29303C0.47903 0.10703 0.729981 0.002 0.992981 0H17.007C17.555 0 18 0.444004 18 0.992004ZM16 2H2V18H16V2ZM8.29303 11.121L12.536 6.87903L13.95 8.29303L8.29303 13.95L4.40302 10.06L5.81799 8.646L8.29303 11.121Z" fill="#00A91C"/>
                                                                                                                 </svg>
                                                                                                                 :
                                                                                                                 <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                     <path d="M1 1.5H17V18.5H1V1.5Z" fill="white" stroke="#8A9197" strokeWidth="2"/>
                                                                                                                     <rect x="5" y="9" width="8" height="2" fill="#8A9197"/>
                                                                                                                 </svg>
                                                                                                         }
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td className='paddingLeft35'>
                                                                                                    {item.doctor_name}
                                                                                                </td>
                                                                                                <td className='instructions notToPrint'><span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer">{item.uses_instruction}</span></td>
                                                                                                <td className='notToPrint notToPrintMed'>
                                                                                                    {props.dependent?.access && props.dependent.access.can_update === true
                                                                                                        ?
                                                                                                    <div className='doctorListTabelButton textAlignRight'>

                                                                                                    <span id={A12Y_CONST.LABEL.MEDICATION+A12Y_CONST.TEXT.EDIT+item.id} className="marginRight10 marginLeft10 cursorPointer" onClick={() => editMedication(item)}>
                                																		<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                																			<path d="M13.293 0.292875C13.683 -0.097625 14.317 -0.097625 14.707 0.292875L18.707 4.29287C19.098 4.68337 19.098 5.31657 18.707 5.70707L5.707 18.7071C5.52 18.8946 5.265 19 5 19H1C0.4477 19 0 18.5523 0 18V14C0 13.7348 0.1054 13.4804 0.2929 13.2929L10.293 3.29307L13.293 0.292875ZM11 5.41417L2 14.4142V17H4.586L13.586 7.99997L11 5.41417ZM15 6.58577L16.586 4.99997L14 2.41417L12.414 3.99997L15 6.58577Z" fill="#2D6CB2" />
                                																		</svg>
                                																	</span>
                                                                                                    </div>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })

                                                                                }
                                                                                </tbody>
                                						                        <tfoot><tr><td><div className="footer-space">&nbsp;</div></td></tr></tfoot>
                                                                            </table>
                                                                        )
                                                                        :
                                                                        (
                                                                            !loadingMedical && medicationList.length === 0  &&
                                                                            <div className='noData df aic jcc fcol'>
                                                                                <strong>{A12Y_CONST.TEXT.NO_DATA_FOUND}</strong>
                                                                                <p>{A12Y_CONST.TEXT.NO_DATA_FOUND_MESSAGE}</p>
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                </>
                            }
                        </TabPanel>
                        <TabPanel value="2">

                            {
                                props.loadingDoctor ?
                                <div className='noData df aic jcc fcol'>
                                    <div className="positionRelative">
                                        <div className="dependentLoader">
                                            <div className='fullWidth justifyCenter marginBottom10'>
                                                <div className="stage">
                                                    <div className="dot-pulse"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='marginTop20'>
                                            <span>{A12Y_CONST.TEXT.LOADING_PROVIDER}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                 <div className="tableBody doctorTableTitle responsiveTalbe" id='Printable'>
                                                                {
                                                                    !loadingDoctor &&  doctorList.length > 0 ?
                                                                        (<table cellSpacing="0" cellPadding="0" border="0" className="fullWidth assetTable doctorListTable" id="TableContent">
                                                                                <thead className='mt20'>
                                                                                <tr>
                                                                                    <th className='sepcialtyCol'><span onClick={() => sortBy('doctorList',doctorList,'speciality_id')}  className={`${getClassNamesFor('speciality_id')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.SPECIALTY.toUpperCase()} <i className="arrow down"></i></span></th>
                                                                                    <th className='doctorNameTdWidth'><span onClick={() => sortBy('doctorList',doctorList,'doctor_name')} className={`${getClassNamesFor('doctor_name')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.NAME.toUpperCase()}<i className="arrow down"></i></span></th>
                                                                                    <th className='typeCol'><span onClick={() => sortBy('doctorList',doctorList,'hospital_name')} className={`${getClassNamesFor('hospital_name')} filterBy df aic cursorPointer`}>{A12Y_CONST.LABEL.PRACTICE_OR_HOSPITAL_GROUP.toUpperCase()}<i className="arrow down"></i></span></th>
                                                                                    <th className='notToPrint notToPrintMed'><span className={`filterBy df aic cursorPointer`}> <i className="arrow down"></i></span></th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                {
                                                                                    doctorList.map((item, i) => {
                                                                                        return (
                                                                                            <tr onClick={() => viewDetails(item)}  key={i}>
                                                                                                <td><span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer">{item.title}</span></td>
                                                                                                <td className='ellipsis'><span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer">{item.doctor_name}</span></td>
                                                                                                <td className='ellipsis'><span onClick={() => viewDetails(item)} className="h100 df aic cursorPointer">{item.hospital_name}</span></td>
                                                                                                <td className='notToPrint notToPrintMed'>
                                                                                                    {props.dependent?.access && props.dependent.access.can_update === true
                                                                                                        ?
                                                                                                        <div className='doctorListTabelButton textAlignRight'>
                                                                                                            {
                                                                                                                item?.email?.length > 0 &&
                                                                                                                <a href={`mailto:${item.email}`} className="marginRight10 " >
                                                                                                                    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <path d="M5 4H23C23.2652 4 23.5196 4.09365 23.7071 4.26035C23.8946 4.42705 24 4.65314 24 4.88889V19.1111C24 19.3469 23.8946 19.573 23.7071 19.7397C23.5196 19.9064 23.2652 20 23 20H5C4.73478 20 4.48043 19.9064 4.29289 19.7397C4.10536 19.573 4 19.3469 4 19.1111V4.88889C4 4.65314 4.10536 4.42705 4.29289 4.26035C4.48043 4.09365 4.73478 4 5 4ZM14.06 11.7182L7.648 6.87822L6.353 8.23289L14.073 14.0596L21.654 8.22844L20.346 6.88356L14.061 11.7182H14.06Z" fill="#2D6CB2"/>
                                                                                                                    </svg>
                                																	            </a>
                                                                                                            }
                                                                                                            {
                                                                                                                item.phones !== null &&
                                                                                                                <a href={"tel:" + item.phones[0].number} className="marginRight10 marginLeft10" >
                                                                                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <path d="M2.67963 1.58353L3.55605 0.707107C3.94658 0.316583 4.57974 0.316582 4.97026 0.707106L7.76658 3.50342C8.1571 3.89394 8.1571 4.52711 7.76658 4.91763L5.84261 6.8416C5.528 7.15621 5.45 7.63685 5.64898 8.03481C6.79925 10.3353 8.66465 12.2007 10.9652 13.351C11.3632 13.55 11.8438 13.472 12.1584 13.1574L14.0824 11.2334C14.4729 10.8429 15.1061 10.8429 15.4966 11.2334L18.2929 14.0297C18.6834 14.4203 18.6834 15.0534 18.2929 15.4439L17.4165 16.3204C15.3051 18.4317 11.9625 18.6693 9.57382 16.8777L7.94436 15.6556C6.20083 14.348 4.65201 12.7992 3.34436 11.0556L2.12226 9.42618C0.330722 7.03745 0.568273 3.69488 2.67963 1.58353Z" fill="#2D6CB2"/>
                                                                                                                    </svg>
                                                                                                                </a>
                                                                                                            }
                                																	<span id={A12Y_CONST.LABEL.DOCTOR+A12Y_CONST.TEXT.EDIT+item.id} className="marginRight10 marginLeft10 cursorPointer" onClick={(e) => editDoctor(e,item)}>
                                																		<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                																			<path d="M13.293 0.292875C13.683 -0.097625 14.317 -0.097625 14.707 0.292875L18.707 4.29287C19.098 4.68337 19.098 5.31657 18.707 5.70707L5.707 18.7071C5.52 18.8946 5.265 19 5 19H1C0.4477 19 0 18.5523 0 18V14C0 13.7348 0.1054 13.4804 0.2929 13.2929L10.293 3.29307L13.293 0.292875ZM11 5.41417L2 14.4142V17H4.586L13.586 7.99997L11 5.41417ZM15 6.58577L16.586 4.99997L14 2.41417L12.414 3.99997L15 6.58577Z" fill="#2D6CB2" />
                                																		</svg>
                                																	</span>
                                                                                                    <span id={A12Y_CONST.LABEL.DOCTOR+A12Y_CONST.TEXT.DELETE+item.id} className="marginLeft10 deleteIcon cursorPointer"  onClick={(e) => deleteDoctor(e,item)}>
                                																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                																			<path d="M5 2C5 0.8954 5.89543 0 7 0H13C14.1046 0 15 0.8954 15 2V4H16.9897C16.9959 3.9999 17.0021 3.9999 17.0083 4H19C19.5523 4 20 4.4477 20 5C20 5.5523 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.189 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.189 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.5523 0 5C0 4.4477 0.44772 4 1 4H2.99174C2.99795 3.9999 3.00414 3.9999 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#D96941"/>
                                																		</svg>
                                																	</span>
                                                                                                            <span className="marginLeft10 ViewIcon">
                                																		<img src={viewIcon} alt='View Icon'/>
                                																	</span>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })

                                                                                }
                                                                                </tbody>
                                						                        <tfoot><tr><td><div className="footer-space">&nbsp;</div></td></tr></tfoot>
                                                                        </table>
                                                                        )
                                                                        :
                                                                        (
                                                                            !loadingDoctor &&
                                                                            <div className='noData df aic jcc fcol'>
                                                                                <strong>{A12Y_CONST.TEXT.NO_DATA_FOUND}</strong>
                                                                                <p>{A12Y_CONST.TEXT.NO_DATA_FOUND_MESSAGE}</p>
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                </>
                            }
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
}

export default MedicalListComponent
