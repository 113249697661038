import {secureClient} from "../config/axiosClient";
import A12Y_CONST from '../common/a12yConst'
import axios from "axios";
import {store} from "../redux/store";
const {dispatch} = store;

export async function uploadImage(result,advocate_id,dependent_id,base64,oldDependent){
    try{
      /*  advocate_id = 75;
        dependent_id = 33;*/
        let s3PreSignedUrlRes = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_S3_SIGN_URL_PUT, {
            "fileKey":dependent_id+"/profile_pic/"+result.name,
            "contentType": result.type,
            "type":"put",
            ACL: 'public-read'
        })
        if (!s3PreSignedUrlRes.data.error && s3PreSignedUrlRes.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let uploadUrl = s3PreSignedUrlRes.data.sr.url
            let fileResp = await fetch(base64);
            let data = await fileResp.blob();
            /*let uploadResBody = await fetch(uploadUrl, {
                method: "PUT",
                body: data,
                headers:{'x-amz-acl': 'public-read','Content-Type': result.type}
            });*/

            let uploadResBody = await axios.put(uploadUrl,data,{headers:{'x-amz-acl': 'public-read','Content-Type': result.type}})
            // if(uploadResBody.statusText === A12Y_CONST.API_RESPONSE_STATUS.CAP_OK){
              if(uploadResBody.status === 200){
                let uploadResponse = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+dependent_id+A12Y_CONST.API_ENDPOINT.PROFILE_PC,{
                    "fileKey":dependent_id+"/profile_pic/"+result.name,
                })
                if (!uploadResponse.data.error && uploadResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    oldDependent.profile_pic_url = uploadResponse.data.sr.picUrl
                    dispatch({
                        type: A12Y_CONST.DISPATCH_ACTION.UPDATE_PROFILE_PIC,
                        dependent : oldDependent
                    });
                   // let dependent = store.getState().dependentReducer.dependent;
                }else {
                    console.error("profile pic failde to saved in db")
                }
            }else {
                console.error('uploading failed .......')
            }

        }else {
            console.error('uploading Error Response.......',s3PreSignedUrlRes)
        }

        //let fileData = result.uri

       /* //console.log("datatosend", JSON.stringify(imageData) + "   " + uploadUrl)

        let uploadUrl = s3PreSignedUrlRes.data.data.url;
        console.log("uploadUrl :: ", uploadUrl)
        let fileResp = await fetch(result.contentUri);
        let data = await fileResp.blob();
        console.log('data #### ', data);
        let uploadResBody = await fetch(uploadUrl, {
            method: "PUT",
            body: data,
        });
        console.log("uploadResBody :: ",uploadResBody.url)
        return uploadResBody*/
    }catch (e) {
        console.error("error upload image", e.response? e?.response?.data?.error?.message:e)
        return null;
    }
}