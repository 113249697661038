import React,{Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import CodeVerification from '../components/CodeVerificationComponent'
import {client} from "../config/axiosClient";
import { connect } from 'react-redux';

class ForgotPasswordVerificationCode extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading:false,
            title:A12Y_CONST.MESSAGE.FORGOT_PASSWORD_VERIFICATION_CODE_TITLE,
            messsage:A12Y_CONST.MESSAGE.FORGOT_PASSWORD_VERIFICATION_CODE_MESSAGE,
            error:false,
            errorMessage:'',
            email:''
        }

    }

    componentDidMount = ()=>{
        if(this.props?.location?.state?.username){
            this.setState({email:this.props?.location?.state?.username})
        }
    }

    handleSubmit = async(code)  => {
        this.setState({loading:true,error:false})
        let response = await client.post(A12Y_CONST.API_ENDPOINT.CONFIRM_PASSWORD, {
            "code" : code.emailCodeVerification,
            "username" : this.state.email,
            "password" : code.password
    });
        this.setState({loading:false})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.props.history.push({
                pathname : A12Y_CONST.ROUTE.PASSWORD_UPDATED,
                state : {
                    'title' : A12Y_CONST.MESSAGE.PASSWORD_UPDATED_LOGIN_TITLE,
                    'message' : A12Y_CONST.MESSAGE.PASSWORD_UPDATED_LOGIN_MESSAGE,
                    'type': 'Password'
                }
            })
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
        }
        /*this.props.history.push({
            pathname : A12Y_CONST.ROUTE.FORGOT_CREATE_PASSWORD,
            state : {
                'username' : this.state.email,
                'code' : code.emailCodeVerification
            }
        })*/
    }
    handleResend = async(data)  => {
        this.setState({loading:true})
        let response = await client.post(A12Y_CONST.API_ENDPOINT.FORGOT_PASSWORD, {
            "username" : this.state.email
        });
        this.setState({loading:false})
        if (response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({error:false})
            /*this.props.history.push({
                pathname : A12Y_CONST.ROUTE.LOGIN,
                state : {
                    'SignUpSuccess' : true
                }
            })*/
        } else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
        }
    }
    render() {
        return (
            <div>

                <CodeVerification
                    type='password'
                    title={this.state.title}
                    error={this.state.error}
                    message={this.state.messsage}
                    onSubmit = {this.handleSubmit}
                    onResend = {this.handleResend}
                    loading = {this.state.loading}
                    errorMessage={this.state.errorMessage}
                    email={this.state.email}
                    buttonTypeId={A12Y_CONST.BUTTON_ID.FORGOT_BUTTON}
                />
            </div>

        )
    }


}
const mapStateToProps = state => ({
    userState: state.userDetailReducer.userState,
});

export default connect(mapStateToProps,null) (ForgotPasswordVerificationCode);