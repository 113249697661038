import React, {useEffect, useRef, useState} from 'react';
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import { connect } from 'react-redux';
import A12Y_CONST from '../common/a12yConst'
import { withRouter } from "react-router";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function PhotoUpload (props) {
    const [imageLoaded, setImageLoaded]=React.useState(false);
   // const [error, setError] = useState(false);
   // const [sameUserLoggedIn, setSameUserLoogedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [src, setSrc] = useState("");
    const [crop, setCrop] = useState({
        unit: 'px', width: 144, height: 144, aspect: 8 / 8
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState("");
    const [doneButtonDisable, setDoneButtonDislabe] = useState(false)
    const [imageRef, setImageRef] = useState("");
    const [name, setName] = useState("");
    const [isOverlay, setIsOverLay] = useState(false);
    const [isDone, setIsDone] = useState(true);
    const [uploadIcon, setUploadIcon] = useState(true);
    const [imageOptionVisible, setImageOptionVisible] = useState(false)
   // const [cropImageData, setCropImageData] = useState({})
    const {formState:{isSubmitting}} = useForm({mode:"onBlur"});
    const refPhotoAction = useRef(null);

    useEffect( () => {
        setLoading(props.loading)
        //setError(props.errorMessage)
        // if(props.errorMessage){
        //     setIsBtnDisabled(false);
        // }
    }, [props.loading,props.errorMessage])

    useEffect( () => {
        if(props.dependent.profile_pic_url){
            setUploadIcon(false)
            setIsDone(true)
            setCroppedImageUrl(props.dependent.profile_pic_url)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    },[])

    const onSelectFile = (e) => {
        setImageLoaded(false)
        if (e.target.files && e.target.files.length > 0) {
            props.isPopupOpen(true)
            setName(e.target.files[0].name)
            setUploadIcon(false)
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrc(reader.result)
                setIsDone(false)
                props.overlay(true);
                setIsOverLay(true)
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    const onImageLoaded = (image) => {
        // setCrop({ x: 150, y: 100, width: 144, height: 144, aspect: 8/8})
        setCrop({
            //unit: '%',
            width: 144,
            height: 144,
            x: image.width/2-72,
            y: image.height/2-72,
            aspect: 8/8})
        setImageRef(image);

        return false;
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setCrop(crop);
    };
    const cropingDone = async(Crop, percentCrop) => {
        setLoading(true);
        setImageOptionVisible(false)
       // await makeClientCrop(crop);
        let croppedImageUrl1 = null;
        let cropedUrl = null;
        if (imageRef && crop.width && crop.height) {
             croppedImageUrl1 = await getCroppedImg(
                imageRef,
                crop,
                name
            );
             if(croppedImageUrl1!= null){
                 //setCropImageData(croppedImageUrl1)
                 const reader = new FileReader();
                 await reader.readAsDataURL(croppedImageUrl1);
                  reader.addEventListener('loadend', async () =>{
                      cropedUrl = reader.result;
                      //const resp = await uploadImage(croppedImageUrl1,props.dependent.advocate_id,props.dependent.id,cropedUrl,props.dependent)
                      await props.dependentProfileUpload(croppedImageUrl1,props.dependent.advocate_id,props.dependent.id,cropedUrl,props.dependent)
                      setIsDone(true);
                        setLoading(false);
                        props.overlay(false);
                        setIsOverLay(false)
                        props.isPopupOpen(false)
                        setCroppedImageUrl(reader.result)
                        //resp == null && setError(true)
                     }
                 );
                //  setIsDone(true);
                //  setLoading(false);
                //  props.overlay(false);
                //  setIsOverLay(false)
                //  props.isPopupOpen(false)
             }
        }
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });


    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl1 = await getCroppedImg(
                imageRef,
                crop,
                name
            );
           // setCropImageData(croppedImageUrl1)
            const reader = new FileReader();
            reader.readAsDataURL(croppedImageUrl1);
            reader.addEventListener('loadend', () =>
                setCroppedImageUrl(reader.result )
            );
        }
        if(crop.width === 0){
            setDoneButtonDislabe(true)
        }else{
            setDoneButtonDislabe (false)
        }
    }

    const getCroppedImg = async (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    //window.URL.revokeObjectURL(fileUrl);
                    //setFileUrl(window.URL.createObjectURL(blob));
                    resolve(blob);
                },
                'image/jpeg',
                1
            );
        });
    }
    const onCancel = async()=>{
        props.overlay(false);
        setIsOverLay(false)
        props.isPopupOpen(false)
        if(props.dependent.profile_pic_url === null || props.dependent.profile_pic_url === ""){
            setUploadIcon(true)
            setSrc("")
        }else {
            setSrc("")
            setIsDone(true)
            setCroppedImageUrl(props.dependent.profile_pic_url)
        }
        setImageOptionVisible(false)
        setDoneButtonDislabe (false)
        props.removeImageCol(false);
    }
    const showImageOptions = () => {
        setImageOptionVisible(!imageOptionVisible);
        props.overlay(true);
        setIsOverLay(true);
    }

    const handleRemove = () => {
        setUploadIcon(true);
        setImageOptionVisible(false);
        props.removeImageCol(true);
        setCroppedImageUrl('');
        props.overlay(false);
        setIsOverLay(false)
    }

    // Hide Opened Element on clicking outside
    const handleClickOutside = (event) => {
        if (refPhotoAction.current && !refPhotoAction.current.contains(event.target)) {
            setImageOptionVisible(false)
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <div className='DependentStep2FormCol alignCenterVH'>
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            {uploadIcon ?
                <div className="broseRow">
                    <label id={A12Y_CONST.BUTTON_ID.IMAGE_ADD_BUTTON} className="custom-file-upload">
                        <input id="fileBrowse" type="file" accept="image/*"  onChange={onSelectFile}/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144" fill="none">
                            <circle cx="72" cy="72" r="72" fill="#E2EDF8"/>
                            <circle cx="71.9999" cy="72.0008" r="63.4286" fill="#A1C4E7"/>
                            <path d="M100.694 100.305C101.284 100.182 101.633 99.569 101.405 99.0115C99.4978 94.3457 95.7962 90.2359 90.7842 87.2447C85.3952 84.0285 78.7923 82.2852 71.9996 82.2852C65.2069 82.2852 58.604 84.0285 53.2149 87.2447C48.2029 90.2359 44.5014 94.3457 42.5937 99.0115C42.3657 99.5689 42.7154 100.182 43.305 100.305L63.8387 104.584C69.2214 105.706 74.7777 105.706 80.1604 104.584L100.694 100.305Z" fill="#F1F8FF"/>
                            <ellipse cx="71.9998" cy="58.2854" rx="17.1429" ry="17.1429" fill="#F1F8FF"/>
                        </svg>
                    </label>
                    <div className='avtarTextCol'>
                        <span>{A12Y_CONST.MESSAGE.PROFILE_PIC_TEXT} {props.displayName}{A12Y_CONST.LABEL.DOT}</span>
                    </div>
                </div>
                :
                null
            }
            <div>
                <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} />
                </div>
                {!isDone && src ? (
                    <div className={ isOverlay ? 'imageCropBoxMain' :'imageCropBoxMain'}>
                        <div className='imgCropBox'>
                            <div className='imgCropBoxInner'>
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    zoom={1}
                                    scale={1}
                                    circularCrop={true}
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onCropChange}
                                />
                                <div className="textAlignRight displayFlexCol imageCropButtonRow marginTop50" >
                                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.PHOTO_UPLOAD_CANCEL} className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>
                                    <div className='widthAuto marginLeft20'>
                                        <div className='loaderSpinner'>
                                            {loading ?
                                                <Loading />
                                                :
                                                null
                                            }
                                            <input id={A12Y_CONST.BUTTON_TEXT.DONE} type="button" value={"Done"} className={loading || doneButtonDisable ?'doneButtonDisable':'doneButton'} disabled={doneButtonDisable} onClick={cropingDone} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ):<div></div>}
                {isDone && croppedImageUrl && (
                    <div className='dependentAfterUploadImgCol'>
                        <div className='dependentImgageCol ' onClick={showImageOptions}>
                            <label className='custom-file-uploadImage'>
                                {!imageLoaded && (
                                    <div className="picLoader">
                                        <div className='fullWidth justifyCenter marginBottom10' style={{height:15}}>
                                            <div className="stage">
                                                <div className="dot-pulse"></div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <img
                                    className={`${!imageLoaded?'displayNone':null}`}
                                    onLoad={()=>{setImageLoaded(true)}}
                                    alt="Crop" src={croppedImageUrl} />
                            </label>
                        </div>
                        <div className='positionRelative profileEditUploadAndRemove'>
                            {imageOptionVisible ?
                                <div className="positionAbsoute width300Center" ref={refPhotoAction}>
                                    <div className='imageOptionList'>
                                        <ul>
                                            <li>
                                                <label onChange={onSelectFile}>
                                                    <input id="fileBrowse" accept="image/*"  type="file" onChange={onSelectFile}/>{A12Y_CONST.TEXT.UPLOAD_PHOTO}
                                                </label>
                                            </li>
                                            <li id={A12Y_CONST.BUTTON_ID.IMAGE_REMOVE_BUTTON} onClick={handleRemove} >{A12Y_CONST.LABEL.REMOVE_PHOTO}</li>
                                        </ul>
                                    </div>
                                </div>
                                :
                                null}
                            <div id={A12Y_CONST.BUTTON_ID.IMAGE_UPLOAD_BUTTON} className='avtarTextCol'><span>{A12Y_CONST.LABEL.PHOTO_UPLOADED}</span></div>
                        </div>

                    </div>
                )}
            </div>

        </div>

    );
}


export default withRouter (connect(null,null) (PhotoUpload));
