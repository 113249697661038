import React ,{useEffect, useState, useRef}from 'react'
import {Controller, useForm} from 'react-hook-form';
import Loading from './LoadingComponent'
import 'react-phone-input-2/lib/style.css'
import A12Y_CONST from '../common/a12yConst'
import PopUpComponent from '../components/PopUpComponent'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import DatePicker from "react-datepicker";
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {hideOnBlur, readOnlyMobile} from "../util/inputKeyboardHide";
import SignUpUploadImageComponent from "./SignUpUploadImageComponent";

function  DependentProfileStep1 (props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dob, setDob] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
   // const [isButtonDisbale, setIsButtonDisbale] = useState(false);
    const [nickName, setNickName] = useState('');
    const [dobErrorMeesage, setDobErrorMeesage] = useState('');
    const [dobError, setDobError] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [isDataPresent, setIsDataPresent] = useState(false);
    const [editMode, setEditMode] = useState(null);
    const [isDataModified, setIsDataModified] = useState(false);
    const  [selectedGenderOption, setSelectedGenderOption] = useState('')
    const {register,handleSubmit,setError,control, formState:{ errors, isSubmitting},setValue} = useForm({
        mode:"all"
    });
    const dobRef = useRef(null);
    
    const genderOptions = [
       // { value: 'Choose Gender', label: 'Choose Gender', isdisabled: true},
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' },
        { value: 'X', label: 'X' }
    ]

    // useEffect(() => {
    //     setFocus("first_name");
    // }, [setFocus])

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
    },[props.loading,props.error])

    useEffect(()=>{
        const assignDefaultValue = async ()=>{
            if(props.dependent && Object.keys(props.dependent).length > 0 ){
                setDefaultValue()
                setIsDataPresent(true)
            }else {
                setEditMode(false);
            }
        }
        assignDefaultValue();
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const setDefaultValue = async()=>{
        setValue("first_name",props.dependent.first_name)
        setFirstName(props.dependent.first_name)
        setValue("last_name",props.dependent.last_name)
        setLastName(props.dependent.last_name)
       setValue("nick_name",props.dependent.nick_name)
        setNickName(props.dependent.nick_name)
        if(props.dependent.gender){
            setSelectedGenderOption(props.dependent.gender)
            //setGender({ value: props.dependent.gender, label: props.dependent.gender })
        }
        if(props.dependent.dob){
          let date = new Date(props.dependent.dob)
           // setValue("dob",date)
            setDob(date)
        }
        setEditMode(true);
    }

    useEffect( ()=>{
       const checkIsFormDataChanged = async ()=>{
           setIsBtnDisabled(true)
           let dependent = props.dependent
           setIsDataModified(false)
           let dob_date = await ddmmyyyyFormat(new Date(dob))
           props.isDataChanged(false)
           if(editMode && Object.keys(dependent).length > 0){
               if(firstName !=="" && lastName !==""){
                   setIsBtnDisabled(false)
               }
               if(dependent.first_name !== firstName || dependent.last_name !== lastName || dependent.nick_name !== nickName || (dependent.gender === null && Object.keys(selectedGenderOption).length > 0) || (dependent.gender !== null && dependent.gender !== selectedGenderOption) || (dependent.dob === null && dob !== null) || (dependent.dob !==null && dependent.dob !== dob_date) || Object.keys(props.profileObj).length > 0){
                   if(firstName !=="" && lastName !==""){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }else {
               if(editMode !== null && editMode !== true){
                   if(firstName !== "" || lastName !== "" || nickName !== "" || selectedGenderOption !== "" || dob !== null || Object.keys(props.profileObj).length > 0){
                       if(firstName !=="" && lastName !==""){
                           setIsBtnDisabled(false)
                       }
                       setIsDataModified(true)
                       props.isDataChanged(true)
                   }
               }
           }
       }
       checkIsFormDataChanged();
    },[firstName,lastName,nickName,dob,selectedGenderOption,props.profileObj]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (user) => {
        if(!user.nick_name){
            user.nick_name = user.first_name
        }
        let data = user
        if(dob){
            data.dob = await ddmmyyyyFormat(dob);
        }else {
            data.dob = null
        }
        user.gender = selectedGenderOption ? selectedGenderOption : null
        if(dobError){
            return
        }
        props.onSubmit(2,data, isDataPresent ? 'put' : 'post')
        props.isDataChanged(false)

    }
    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUp(true)
        }else {
            props.onCancel(0)
        }
        /*if(props.dependent && (getValues("first_name") !== props.dependent.first_name || getValues("last_name") !== props.dependent.last_name || getValues("nick_name") !== props.dependent.nick_name || selectedGenderOption !== props.dependent.gender || await moment(props.dependent.dob).format("MM / DD / YYYY")!==dob)){
            setIsPopUp(true)
        }*/
    }
    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        props.isDataChanged(false)
        setIsPopUp(false)
        props.onCancel(0)
    }
    const onValueChange = async (event) => {
        setSelectedGenderOption(event.target.value)
    }

    const onFirstNameBlur = ()=>{
        //let firstName = getValues("first_name")
        if(!firstName){
            setError("first_name", {
                type: "manual",
                message: A12Y_CONST.ERROR_MESSAGE.FIRST_NAME_REQUIRED,
            });
        }
        if(!nickName && firstName){
            setValue("nick_name",firstName)
            setNickName(firstName)
        }

    }

    /*const handeBlankSpace = (e) => {
        if(e.target.value.length < 1){
            if(e.key === " "){
                e.preventDefault();
            }
        }
    }*/

    const handeFirstName = (e) => {
        let value = e.target.value;
        if(value.trim().length === 0){
            setValue("first_name",'')
            setFirstName('');
        }else {
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setFirstName(value)
                setValue('first_name',value)
            }
        }
    }

    const handeLastName = (e) => {
        let value = e.target.value
        if(value.trim().length === 0){
            setLastName('')
            setValue("last_name",'')
        }else {
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setLastName(value)
                setValue("last_name",value)
            }            
        }
    }

    const handeNickName = (e) => {
        let value = e.target.value;
        if(value.trim().length === 0){
            setNickName('')
            setValue("nick_name",'')
        }else {
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setNickName(value)
                setValue("nick_name",value)
            }            
        }
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleDateChange = () => {
        if(dob != null){
            let isValidObj = validateDate(dob, 100, 0)
            setDobError(isValidObj.isErr)
            setDobErrorMeesage(isValidObj.errMsg)            
        }
        else{
            setDobError(false)
            setDobErrorMeesage('')
        }
    }


    const checkIsPopupOpen = (status)=>{
        //setIsButtonDisbale(status)
    }
    const handleRemoveImg = () => {
        props.handleRemoveImg();
    }
    const overlay = (status) => {
        props.overlay(status);
    }

    const onImageUploadHandling = async (croppedImageUrl1,advocate_id,dependent_id,cropedUrl,dependent)=>{
     await props.onProfilUpload(croppedImageUrl1,advocate_id,dependent_id,cropedUrl,dependent)
    }

    useEffect( () => {
        let arr =[dobRef.current]
        readOnlyMobile(arr)
    },[])


    return (
        <div className="fullWidth">
            <div className="dependentSubTitle marginTop30 marginBottom5 step2Title">
                {A12Y_CONST.LABEL.TELL_US_ABOUT_YOUR}
            </div>
            <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
                {loading ?
                    <div className="transparentLayer"></div>
                    :
                    null
                }
                <form onSubmit={handleSubmit(onSubmit)} className='marginTop40 newFormUI dependentStep1' autoComplete="off">
                    <div className='fullWidth signUpImgUploadBox marginBottom20'>
                        <div className='width50 formCol dependentimageUploadCol positionRelative zIndex2 paddingRight16'>
                            <SignUpUploadImageComponent overlay={overlay} profileObj={props.profileObj} onImageUpload={onImageUploadHandling} removeImageCol={handleRemoveImg} displayName={props.displayName} dependent={props.dependent} loading={props.loading} isPopupOpen={checkIsPopupOpen} />
                            {/*<span className="visibilityHidden">.</span>*/}
                        </div>
                        <div className='width50 formCol paddingleft16'>
                            <label className='labelCol label_gender'>{A12Y_CONST.LABEL.DEPENDENT_GENDER}</label>
                            <div className='fullWidth'>
                            <div className='fullWidth maxWidth338'>
                                <div className='dependentGenderCol maxWidth338'>
                                    {
                                        genderOptions.map((item, i) => (
                                            <label key={ i }>
                                                <input type="radio"
                                                       checked={ selectedGenderOption === item.value }
                                                       onChange={onValueChange}
                                                       tabIndex={4}
                                                       value={item.value } />
                                                <span> { item.label }</span>
                                            </label>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        </div>

                    </div>
                    <div className='fullWidth marginBottom20 positionRelative'>
                        <div className='fullWidth'>
                            <div className='width50 formCol paddingRight16 myProfile_personal_FirstName600'>
                                <div className="fullWidth"> <label className='labelCol'>{A12Y_CONST.LABEL.FIRST_NAME}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label></div>
                                <input
                                    {...register('first_name', { required: A12Y_CONST.ERROR_MESSAGE.FIRST_NAME_REQUIRED, pattern:{
                                        value:/^.{1,}$/,
                                        //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_INVALID
                                    }})}
                                    placeholder='First Name'
                                    className={firstName.length === 0 && errors.first_name? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    type='text'
                                    tabIndex={1}
                                    id='userFirstName'
                                    maxLength="100"
                                    onBlur={onFirstNameBlur}
                                    onChange={handeFirstName}
                                    value={firstName}
                                   // onKeyDown={e => handeBlankSpace(e)}
                                    onFocus={()=>{
                                        setError("first_name", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />
                                <div className='errorRelativeRow'> {errors.first_name && <p className='errorMessage'>{errors.first_name.message}</p>}</div>
                            </div>
                            <div className='width50 formCol paddingleft16 responsive6000LeftRight'>
                                <div className="fullWidth"> <label className='labelCol'>{A12Y_CONST.LABEL.LAST_NAME}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label></div>
                                <input
                                    {...register('last_name', { required: A12Y_CONST.ERROR_MESSAGE.LAST_NAME_REQUIRED , pattern:{
                                        value:/^.{1,}$/,
                                        //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_INVALID
                                    }})}
                                    placeholder='Last Name'
                                    className={errors.last_name ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    type='text'
                                    tabIndex={2}
                                    maxLength="100"
                                    onChange={handeLastName}
                                    value={lastName}
                                   // onKeyDown={e => handeBlankSpace(e)}
                                    id='userLastName'
                                    onFocus={()=>{
                                        setError("last_name", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />
                                <div className='errorRelativeRow'>{errors.last_name && <p className='errorMessage'>{errors.last_name.message}</p>}</div>
                            </div>
                        </div>
                    </div>
                    <div className='fullWidth marginBottom20 positionRelative'>
                        <div className='width50 paddingRight16'>
                            <label className='labelCol'>{A12Y_CONST.LABEL.DEPENDENT_DISPLAY_NAME}</label>
                            <div className='fullWidth'>
                                <input
                                    {...register('nick_name')}
                                    placeholder='&nbsp;'
                                    className={`inputColum24size ${nickName.length === 0 && 'noText'}`}
                                    type='text'
                                    tabIndex={3}
                                    onChange={handeNickName}
                                    value={nickName}
                                    //onKeyDown={e => handeBlankSpace(e)}
                                    id='displayName'
                                />
                                <div className='errorRelativeRow'> {errors.nick_name && <p className='errorMessage'>{errors.nick_name.message}</p>}</div>
                        </div>
                        </div>
                        <div className='width50 paddingleft16 dependentDatePicker'>
                            <label className='labelCol'>{A12Y_CONST.LABEL.DEPENDENT_DOB}</label>
                            <div className='fullWidth'>
                                <div className='fullWidth maxWidth338 positionRelative z-index1'>
                                    <Controller
                                        name="dob"
                                        control={control}
                                        inputmode='none'
                                        // rules={{ required: true }}
                                        render={({ field }) =>
                                            <DatePicker
                                                {...field}
                                                ref={dobRef}
                                                dateFormat="MM / dd / yyyy"
                                                selected={dob}
                                                onChange={date => setDob(date)}
                                                onChangeRaw={(event) => handleChangeRaw(event, 'dob')}
                                                onBlur={handleDateChange}
                                                onCalendarClose={handleDateChange}
                                                peekNextMonth
                                                tabIndex={5}
                                                showMonthDropdown
                                                showYearDropdown
                                                onKeyDown={(event) => hideOnBlur(event, dobRef)}
                                                onFocus={(e)=> {setDobError(false);}}
                                                dropdownMode="select"
                                                className={dobError ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                                placeholderText="MM / DD / YYYY"
                                            />
                                        }
                                    />
                                    {/*<input
                                    {...register('dob')}
                                    placeholder='MM/DD/YYYY'
                                    className={dobError ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    type='text'
                                    id='dob'
                                    onChange={handleDob}
                                    value={dob}
                                    onBlur={validateDOB}
                                    onFocus={()=>setDobError(false)}
                                    //  onKeyDown={e => handeBlankSpace(e)}
                                />*/}
                                    <div className='errorRelativeRow'>
                                        {dobError? <p className='errorMessage'>{dobErrorMeesage}</p>: null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="fullWidth textAlignRight signUpFlowButtonRow marginBottom30 displayFlexCol zindex0" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_STEP1_BANK} className={!isSubmitting ? "backButton" :'backButton'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.BACK}/></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id='DependentProfile' className={loading || isBtnDisabled ? "saveButtonDisable buttonlaerUp buttonHeight56" :'saveButton buttonHeight56 buttonlaerUp'} disabled={loading || isBtnDisabled }  type="submit" value={A12Y_CONST.BUTTON_TEXT.DEPENDENT_COUNTINUE }/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <PopUpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={A12Y_CONST.LABEL.YOU_ARE_ABOUT_TO}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.DELETE}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.CANCEL}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
                maxWidth='maxWidth'

            />
        </div>

            )
}

export default  DependentProfileStep1;