import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as dependentActions from "../redux/actions/dependentActions";
import A12Y_CONST from "../common/a12yConst";
import InstitutionAddressComponent from "../components/InstitutionAddressComponent";
import {saveInstitutionDetails, setupAccessControl} from "../util/AssetCommonApi";
import AssetAccessControlComponent from "../components/AssetAccessControlComponent";
import BurialAccountComponentStep1 from "../components/BurialAccountComponentStep1";
import * as userDetailActions from "../redux/actions/userDetailActions";
import {secureClient} from "../config/axiosClient";
import axios from "axios";


class BurialAccountScreen extends Component {
    constructor(props){
        super(props)

        this.state = {
            isStep1: true,
            isStep2: false,
            isStep3: false,
            isStep4: false,
            fileUploadingData:{},
            loading: false,
            error:false,
            message:'',
            errorMessage:'',
            loaderFullScreenVisible : false,
            isClickedOutside:false,
            isAssetPopUp:false,
            isPopUpVisible:false
        }
    }



    step1Completed = async (obj,fileInfo,fileBase64Info,deletedFileInfo) => {
        this.setState({ loading: true })
        let dependendId = this.props.dependent.id
        await this.props.createAsset(dependendId, obj)
        await this.props.currentAssetData(this.props.asset)
        await this.fileUploading(obj,fileInfo,fileBase64Info,deletedFileInfo,'assets')

        if(this.props.isError){
            this.setState({ error: true, errorMessage: this.props.errMsg, loading: false})
        }else {
            if(!this.props.asset_present){
                let dependent = this.props.dependent
                dependent.metadata.asset_present = true
                this.props.updateDependentProps(dependent)
                this.props.updateMetaData(this.props.income_present,this.props.expense_present,this.props.activity_present,!this.props.asset_present)
            }
            this.setState({isStep1: false, isStep2: true, error: false, errorMessage: '', loading: false})
        }

    }

    step2Completed = async (obj) => {
        this.setState({ loading: true })
        let response = await saveInstitutionDetails(this.props.dependent.id, this.props.asset.id, obj)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            await  this.setState({
                isStep1: false,
                isStep2: false,
                isStep3: true,
                loading: false
            })
        } else {
            this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message })
            this.setState({ loading: false })
        }

    }

    step3Completed = async (obj) => {
        this.setState({ loading: true })
        this.setState({
            isStep1: false,
            isStep3: false,
            isStep4: true
        })

    }

    assetPopUpHide = async ()=> {
        this.setState({isPopUpVisible:false})
        await this.props.currentAssetData(this.props.asset)
        this.props.history.push(A12Y_CONST.ROUTE.INVENTORYVIEW)
    }


    accessToAsset = async (status) => {
        this.setState({ loading: true,error:false,errorMessage:'' })
        let obj = {advocate_control:status,action:A12Y_CONST.TEXT.UPDATE_ADVOCATE_ACCESS };
        let response = await setupAccessControl(this.props.dependent.id, this.props.asset.id, obj)
        if(!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK){
            if(status === false){
                this.setState({isPopUpVisible:true, loading:false})
            }else{
                await  this.assetPopUpHide();
            }
        }else {
            this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message })
            this.setState({ loading: false })
        }
    }

    onCancelHandler = async ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.INVENTORY)
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    updateAssetData = async (obj,fileInfo,base64File,deleteFileInfo) =>{
        await this.fileUploading(obj,fileInfo,base64File,deleteFileInfo,'assets')
    }

    fileUploading = async (data,fileInfo,fileBase64Info,deletedFileInfo,type)=>{
        this.setState({error:false,loading:false})
        let attached_file = [];
        let isTransfer = type === 'assets' ? false : true;
        let reqBody = {
            ref_type : isTransfer ? "transfer" : "asset",
            ref_id : isTransfer  ? data.id : this.props.currentAsset.id,
            attachments : []
        }
        try{
            if(this.props.currentAsset && Object.keys(this.props.currentAsset).length > 0 && ((isTransfer && this.props.currentAsset?.transfer?.attachments.length > 0) || (!isTransfer && this.props.currentAsset?.attachments?.length > 0))){
                let attached_files_list = isTransfer ? this.props.currentAsset.transfer.attachments : this.props.currentAsset.attachments
                let arr =[];
                if(deletedFileInfo.length > 0){
                    for await (const item of deletedFileInfo) {
                        attached_files_list = attached_files_list.filter(item_1 => item_1.file !== item)
                        arr = attached_files_list
                    }
                    attached_file = arr;
                    reqBody.attachments = attached_files_list
                }else {
                    attached_file = attached_files_list
                    reqBody.attachments = attached_files_list
                }

            }
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                if(deletedFileInfo.length > 0) {
                    await this.savingAssetFile(reqBody)
                }
                // if(isTransfer){
                //     await this.updateTransfer(data)
                // }else {
                //     await this.updateAsset(data)
                // }
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    let progressIndex = 0
                    let deletedFile = deletedFileInfo.length > 0 ?  deletedFileInfo.length : 0
                    if(isTransfer && Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.hasOwnProperty('transfer')){
                        progressIndex =  this.props.currentAsset.transfer.attachments.length - deletedFile
                    }else {
                        if(Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.hasOwnProperty('attachments')){
                            progressIndex = this.props.currentAsset.attachments.length - deletedFile
                        }
                    }
                    //let progressIndex = data.document_ref && Object.keys(data.document_ref).length > 0 ? data.document_ref.attached_files.length > 0  ? data.document_ref.attached_files.length : 0 : 0;

                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();
                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                                "Content-Type" : fileInfo[i].contentType
                            },onUploadProgress:async data => { // eslint-disable-line
                                //Set the progress value to show the progress bar
                                // setProgress(Math.round((100 * data.loaded) / data.total))
                                let temp={data:data,index:progressIndex}
                                await this.setState({fileUploadingData:temp})
                            }})
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                /* ref_type : "asset",
                                 ref_id :fileInfo[i].asset_id,*/
                                file : fileInfo[i].file_id,
                                file_name : fileInfo[i].name,
                                file_extension : fileInfo[i].contentType,
                                file_size : fileInfo[i].size
                            })
                            // data.document_ref = {"attached_files":attached_file}
                            reqBody.attachments = attached_file
                        }
                        i++;
                        progressIndex++
                    }
                    /*let reqBody = {
                        ref_type : "asset",
                        ref_id : this.props.currentAsset.id,
                        attachments : attached_file
                    }*/
                    await this.savingAssetFile(reqBody)
                    // await this.updateAsset(data)
                }else {

                }
            }

        }catch (e){
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            console.error('error ......',e)
        }

    }

    savingAssetFile = async (data)=>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ATTACHMENTS,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                // this.setState({loading:false}) // this was commented just because save button is getting active before redirection
                //this.redirectToInventoryView()
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
        }
    }

    updateAsset = async (data,action)=>{
        data.action = 'update_asset';
        let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id,data);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            // let currentAsset = this.props.currentAsset
            // currentAsset.isDataPresent = false
            // await this.props.currentAssetData(currentAsset)
            // this.setState({ loading: false })
            // this.redirectToInventoryView()
        }else {
            this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false })
        }
    }

    render() {
        return (
            <div className='df fcol' onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                { this.state.isStep1 &&
                <BurialAccountComponentStep1
                    title={A12Y_CONST.LABEL.BURIAL_ACCOUNT}
                    loading={this.state.loading}
                    error={this.state.error}
                    errorMessage={this.state.errorMessage}
                    loaderFullScreen={this.state.loaderFullScreen}
                    asset = {this.props.asset}
                    onSubmit={this.step1Completed}
                    selectedAssestType={this.props.selectedAssestType}
                    dependent={this.props.dependent}
                    apostrophe={true}
                    BtnTxt={A12Y_CONST.BUTTON_TEXT.Next}
                    isDataChanged={this.dataChangeHandler}
                    fileUploadingData={this.state.fileUploadingData}
                />}
                { this.state.isStep2 &&
                <InstitutionAddressComponent
                    title={A12Y_CONST.LABEL.BURIAL_ACCOUNT_PROVIDER}
                    titlePrefix={A12Y_CONST.TEXT.WHO_IS}
                    loading={this.state.loading}
                    error={this.state.error}
                    errorMessage={this.state.errorMessage}
                    loaderFullScreen={this.state.loaderFullScreen}
                    onSubmit={this.step2Completed}
                    onCancel={this.onCancelHandler}
                    dependent={this.props.dependent}
                    isDataChanged={this.dataChangeHandler}
                    isClickedOutside={this.props.isClickedOutside}
                    BtnTxt={A12Y_CONST.BUTTON_TEXT.Next}
                />}
                { this.state.isStep3 &&
                <AssetAccessControlComponent
                    title={A12Y_CONST.LABEL.BURIAL_ACCOUNT}
                    loading={this.state.loading}
                    error={this.state.error}
                    errorMessage={this.state.errorMessage}
                    accessToAsset={this.accessToAsset}
                    assetPopUpHide={this.assetPopUpHide}
                    isPopUpVisible={this.state.isPopUpVisible}
                    dependent={this.props.dependent}
                    asset = {this.props.asset}
                />}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    assetTypeList: state.dependentReducer.assetTypeList,
    asset : state.dependentReducer.asset,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    asset_present: state.dependentReducer.asset_present,
    currentAsset: state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    createAsset : (dependentId,data) => dispatch(dependentActions.createAsset(dependentId, data)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});


export default connect(mapStateToProps, mapDispatchToProps) (BurialAccountScreen);
