import React, {Component} from 'react'
import * as dependentActions from "../redux/actions/dependentActions";
import {connect} from "react-redux";
import LifeInsurance from '../components/InsuranceStep1'
import A12Y_CONST from "../common/a12yConst";
import {secureClient} from "../config/axiosClient";
import axios from "axios";
import InstitutionAddressComponent from "../components/InstitutionAddressComponent";
import InsuranceStep3 from "../components/InsuranceStep3";
import BankAccountStep1 from "../components/BankAccountStep1";
import AddressComponent from "../components/AddressComponent";
import RealEstateStep1 from "../components/RealEstateStep1";
import AutomobileComponentStep1 from "../components/AutomobileComponentStep1";
import PersonalPropertyComponentStep1 from "../components/PersonalPropertyComponentStep1";
import BusinessInterestComponentStep1 from "../components/BusinessInterestComponentStep1";
import SafeDepositBoxComponentStep1 from "../components/SafeDepositBoxComponentStep1";
import InvestmentAccountComponentStep1 from "../components/InvestmentAccountComponentStep1";
import RetirementAccountComponentStep1 from "../components/RetirementAccountComponentStep1";
import BurialAccountComponentStep1 from "../components/BurialAccountComponentStep1";
import OtherComponentStep1 from "../components/OtherComponentStep1";
import * as userDetailActions from "../redux/actions/userDetailActions";
import AssetTransferFormComponent from "../components/AssetTransferFormComponent";

class AssetEditScreen extends Component{
    constructor(props) {
        super(props);
        this.state = {
            screen:'none',
            fileUploadingData:{},
            institutionTitle:'Institution',
            institutionPrefix:'',
            assetTitle:'',
            assetPreFixTitle:'',
            isClickedOutside:false,
            locationTitle:A12Y_CONST.LABEL.LOCATION
        }
    }

    componentDidMount = async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let screen = params.get('type');
        if(screen === null && this.props.assetEditScreenKey !== ''){
            this.props.history.replace({search: '?type='+this.props.assetEditScreenKey});
            screen = this.props.assetEditScreenKey
        }
        if(screen !== '' && screen !== null){
            this.setState({screen:screen})
        }
        if(this.props.currentAsset && Object.keys(this.props.currentAsset).length > 0){
            if(this.props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_LIFE_INSURANCE_KEY){
                this.setState({institutionTitle:A12Y_CONST.TEXT.INSTITUTION_LIFE_INSURANCE_TITLE})
            }
        }
        await this.currentAssetType()
    }

    currentAssetType = async () => {
        if(this.props.currentAsset.asset_type === 'Life Insurance'){
            await this.setState({assetTitle:A12Y_CONST.TEXT.LIFE_INSURANCE_PROVIDE, locationTitle: A12Y_CONST.TEXT.LIFE_INSURANCE_PROVIDE, assetPreFixTitle:A12Y_CONST.TEXT.WHO_IS})

        }
        if(this.props.currentAsset.asset_type === 'Bank Account'){
            await this.setState({assetTitle:A12Y_CONST.TEXT.BANK_INFORMATION, assetPreFixTitle:A12Y_CONST.TEXT.WHAT_IS, locationTitle: A12Y_CONST.TEXT.BANK_INFORMATION})
        }
        if(this.props.currentAsset.asset_type === 'Business Interest'){
            await this.setState({assetTitle:A12Y_CONST.LABEL.BUSINESS_DETAILS, assetPreFixTitle:'', locationTitle: A12Y_CONST.LABEL.BUSINESS_DETAILS})
        }
        if(this.props.currentAsset.asset_type === 'Retirement Account'){
            await this.setState({assetTitle:A12Y_CONST.LABEL.RETIREMENT_ACCOUNT, assetPreFixTitle:'', locationTitle: A12Y_CONST.LABEL.LOCATION})
        }
        if(this.props.currentAsset.asset_type === 'Burial Account'){
            await this.setState({assetTitle:A12Y_CONST.LABEL.BURIAL_PROVIDER, assetPreFixTitle:'', locationTitle: A12Y_CONST.LABEL.BURIAL_PROVIDER})
        }
    }


    updateAssetData = async (obj,fileInfo,base64File,deleteFileInfo) =>{
        await this.fileUploading(obj,fileInfo,base64File,deleteFileInfo,'assets')
    }

    fileUploading = async (data,fileInfo,fileBase64Info,deletedFileInfo,type)=>{
        this.setState({error:false,loading:false})
        let attached_file = [];
        let isTransfer = type === 'assets' ? false : true;
        let reqBody = {
            ref_type : isTransfer ? "transfer" : "asset",
            ref_id : isTransfer  ? data.id : this.props.currentAsset.id,
            attachments : []
        }
        try{
            if(this.props.currentAsset && Object.keys(this.props.currentAsset).length > 0 && ((isTransfer && this.props.currentAsset.transfer.attachments.length > 0) || (!isTransfer && this.props.currentAsset.attachments.length > 0))){
                let attached_files_list = isTransfer ? this.props.currentAsset.transfer.attachments : this.props.currentAsset.attachments
                let arr =[];
                if(deletedFileInfo.length > 0){
                    for await (const item of deletedFileInfo) {
                        attached_files_list = attached_files_list.filter(item_1 => item_1.file !== item)
                        arr = attached_files_list
                    }
                    attached_file = arr;
                    reqBody.attachments = attached_files_list
                }else {
                    attached_file = attached_files_list
                    reqBody.attachments = attached_files_list
                }

            }
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                if(deletedFileInfo.length > 0) {
                    await this.savingAssetFile(reqBody)
                }
            if(isTransfer){
                await this.updateTransfer(data)
            }else {
                await this.updateAsset(data)
            }
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    let progressIndex = 0
                    let deletedFile = deletedFileInfo.length > 0 ?  deletedFileInfo.length : 0
                    if(isTransfer && Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.hasOwnProperty('transfer')){
                        progressIndex =  this.props.currentAsset.transfer.attachments.length - deletedFile
                    }else {
                        if(Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.hasOwnProperty('attachments')){
                            progressIndex = this.props.currentAsset.attachments.length - deletedFile
                        }
                    }
                    //let progressIndex = data.document_ref && Object.keys(data.document_ref).length > 0 ? data.document_ref.attached_files.length > 0  ? data.document_ref.attached_files.length : 0 : 0;

                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();

                        // console.log('signed_urls ===',uploadUrl)
                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                                "Content-Type" : fileInfo[i].contentType
                            },onUploadProgress:async data => { // eslint-disable-line
                                //Set the progress value to show the progress bar
                                // setProgress(Math.round((100 * data.loaded) / data.total))
                                let temp={data:data,index:progressIndex}
                                await this.setState({fileUploadingData:temp})
                               // console.log('loading Event....',data.loaded,this.state.fileUploadingData,progressIndex)
                            }})
                        // console.log('uploadResBody ',uploadResBody)
                        if(uploadResBody.status === 200){
                            attached_file.push({
                               /* ref_type : "asset",
                                ref_id :fileInfo[i].asset_id,*/
                                file : fileInfo[i].file_id,
                                file_name : fileInfo[i].name,
                                file_extension : fileInfo[i].contentType,
                                file_size : fileInfo[i].size
                            })
                           // data.document_ref = {"attached_files":attached_file}
                            reqBody.attachments = attached_file
                        }
                        i++;
                        progressIndex++
                    }
                    /*let reqBody = {
                        ref_type : "asset",
                        ref_id : this.props.currentAsset.id,
                        attachments : attached_file
                    }*/
                    await this.savingAssetFile(reqBody)
                    if(isTransfer){
                        await this.updateTransfer(data)
                    }else {
                        await this.updateAsset(data)
                    }
                }else {
                }
            }

        }catch (e){
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            console.error('error ......',e)
        }

    }

    savingAssetFile = async (data)=>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ATTACHMENTS,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                // this.setState({loading:false}) // this was commented just because save button is getting active before redirection
                //this.redirectToInventoryView()
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
        }
    }


    onInstitutionAddressSubmitHandler = async (data) => {
        try {
            this.setState({ loading: true })
            let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.INSTITUTES+"/"+data.id,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({ loading: false })
                this.redirectToInventoryView()
            }else {
                this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false })
            }
        }catch (e) {
            console.error(e)
        }

    }

    updateAsset = async (data,action)=>{
        data.action = 'update_asset';
        let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id,data);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let currentAsset = this.props.currentAsset
            currentAsset.isDataPresent = false
            await this.props.currentAssetData(currentAsset)
           // await this.props.currentAssetData(response.data.sr.asset)
            this.setState({ loading: false })
            this.redirectToInventoryView()
        }else {
            this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false })
        }
    }

    redirectToInventoryView = () =>{
        this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
    }

    onCancelHandler = async ()=>{
        let currentAsset = this.props.currentAsset
        currentAsset.isDataPresent = false
        await this.props.currentAssetData(currentAsset)
        this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
    }

    onLoanAgainstSubmitHandler = async (data) =>{
        this.setState({ loading: true })
        data.action = 'update_loan';
        let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.LOANS+'/'+ data.id,data);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({ loading: false })
            this.redirectToInventoryView()
        }else {
            this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false })
        }
    }

    updateAddress = async (data) =>{
        this.setState({ loading: true })
        let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+this.props.currentAsset.id+A12Y_CONST.API_ENDPOINT.ADDRESSES+"/"+data.id, data);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({ loading: false })
            this.redirectToInventoryView()
        }else {
            this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message,loading: false })
        }
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    updateTransferHandler = async (obj,fileInfo,base64File,deleteFileInfo)=>{
        await this.fileUploading(obj,fileInfo,base64File,deleteFileInfo,'transfer')
    }

    updateTransfer = async (obj)=>{
        let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.TRANSFER+"/"+obj.id,obj);
        this.setState({transferPopupLoader:true,loading:true})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            // let dataList = await this.state.assetList.filter(item => item.id !== asset.id)
            let asset = {};
            if(this.props.currentAsset.hasOwnProperty('transfer')){
                let transfer = response.data.sr.transfer
                asset.transfer = transfer
            }
           // asset.isDataPresent = true
            await this.props.currentAssetData(asset)
            this.setState({transferPopupLoader:false,showTransferPopup:false,loading:false})
            this.redirectToInventoryView()
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,transferPopupLoader:false,loading:false})
        }
    }


    render() {
        const {screen,fileUploadingData,institutionPrefix} = this.state;
        return(
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare" onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        {screen === A12Y_CONST.TEXT.ASSET_LIFE_INSURANCE_KEY ?
                            <LifeInsurance
                                loading={this.state.loading}
                                error={this.state.error}
                                errorMessage={this.state.errorMessage}
                                loaderFullScreen={this.state.loaderFullScreen}
                                asset = {this.props.asset}
                                onUpdate={this.updateAssetData}
                                selectedAssestType={this.props.selectedAssestType}
                                dependent={this.props.dependent}
                                currentAsset={this.props.currentAsset}
                                editMode={true}
                                fileUploadingData ={fileUploadingData}
                                title={A12Y_CONST.LABEL.LIFE_INSURANCE}
                                apostrophe={true}
                                BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                isDataChanged={this.dataChangeHandler}
                                onCancel={this.onCancelHandler}
                            />
                            :
                            (screen === A12Y_CONST.TEXT.ASSET_INSTITUTION_KEY ?
                                    <InstitutionAddressComponent
                                        title={this.state.locationTitle}
                                        titlePrefix={institutionPrefix}
                                        loading={this.state.loading}
                                        error={this.state.error}
                                        errorMessage={this.state.errorMessage}
                                        onUpdate={this.onInstitutionAddressSubmitHandler}
                                        onCancel={this.onCancelHandler}
                                        dependent={this.props.dependent}
                                        currentAsset={this.props.currentAsset}
                                        isClickedOutside={this.state.isClickedOutside}
                                        editMode={true}
                                        BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                        isDataChanged={this.dataChangeHandler}
                                    />
                                    :
                                    screen === A12Y_CONST.TEXT.LOAN_AGAINST_KEY ?
                                        <InsuranceStep3
                                            loading={this.state.loading}
                                            error={this.state.error}
                                            errorMessage={this.state.errorMessage}
                                            onUpdate={this.onLoanAgainstSubmitHandler}
                                            onCancel={this.onCancelHandler}
                                            dependent={this.props.dependent}
                                            currentAsset={this.props.currentAsset}
                                            submitBtnText={A12Y_CONST.BUTTON_TEXT.SAVE}
                                            editMode={true}
                                            BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                            isDataChanged={this.dataChangeHandler}
                                        />
                                        :
                                        screen === A12Y_CONST.TEXT.ASSET_BANK_KEY ?
                                            <BankAccountStep1
                                                asset={this.props.asset}
                                                loading={this.state.loading}
                                                dependent={this.props.dependent}
                                                currentAsset={this.props.currentAsset}
                                                onCancel={this.onCancelHandler}
                                                onUpdate={this.updateAssetData}
                                                editMode={true}
                                                apostrophe={true}
                                                fileUploadingData ={fileUploadingData}
                                                BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                isDataChanged={this.dataChangeHandler}
                                             />
                                             :
                                            screen === A12Y_CONST.TEXT.ASSET_ADDRESS_KEY ?
                                                <AddressComponent
                                                    currentAsset={this.props.currentAsset}
                                                    title={A12Y_CONST.LABEL.LOCATION}
                                                    titlePrefix ={''}
                                                    loading={this.state.loading}
                                                    error={this.state.error}
                                                    errorMessage={this.state.errorMessage}
                                                    loaderFullScreen={this.state.loaderFullScreen}
                                                    onUpdate={this.updateAddress}
                                                    onCancel={this.onCancelHandler}
                                                    dependent={this.props.dependent}
                                                    editMode={true}
                                                    BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                    isDataChanged={this.dataChangeHandler}
                                                    isClickedOutside={this.state.isClickedOutside}
                                                />
                                                :
                                                screen === A12Y_CONST.TEXT.ASSET_REAL_ESTATE ?
                                                    <RealEstateStep1
                                                        title={A12Y_CONST.LABEL.REAL_ESTATE}
                                                        loading={this.state.loading}
                                                        error={this.state.error}
                                                        errorMessage={this.state.errorMessage}
                                                        currentAsset={this.props.currentAsset}
                                                        loaderFullScreen={this.state.loaderFullScreen}
                                                        onUpdate={this.updateAssetData}
                                                        dependent={this.props.dependent}
                                                        editMode={true}
                                                        apostrophe={true}
                                                        fileUploadingData ={fileUploadingData}
                                                        BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                        isDataChanged={this.dataChangeHandler}
                                                        onCancel={this.onCancelHandler}
                                                    />
                                                    :
                                                    screen === A12Y_CONST.TEXT.ASSET_AUTOMOBILE_KEY ?
                                                        <AutomobileComponentStep1
                                                            title={A12Y_CONST.LABEL.AUTOMOBILE}
                                                            loading={this.state.loading}
                                                            error={this.state.error}
                                                            errorMessage={this.state.errorMessage}
                                                            loaderFullScreen={this.state.loaderFullScreen}
                                                            editMode={true}
                                                            onUpdate={this.updateAssetData}
                                                            currentAsset={this.props.currentAsset}
                                                            fileUploadingData ={fileUploadingData}
                                                            BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                            dependent={this.props.dependent}
                                                            apostrophe={true}
                                                            isDataChanged={this.dataChangeHandler}
                                                            onCancel={this.onCancelHandler}
                                                        />
                                                        :
                                                            screen === A12Y_CONST.TEXT.ASSET_PERSONAL_PROPERTY_KEY ?
                                                                <PersonalPropertyComponentStep1
                                                                    title={A12Y_CONST.LABEL.PERSONAL_PROPERTY}
                                                                    loading={this.state.loading}
                                                                    error={this.state.error}
                                                                    errorMessage={this.state.errorMessage}
                                                                    loaderFullScreen={this.state.loaderFullScreen}
                                                                    currentAsset={this.props.currentAsset}
                                                                    onUpdate={this.updateAssetData}
                                                                    editMode={true}
                                                                    dependent={this.props.dependent}
                                                                    fileUploadingData ={fileUploadingData}
                                                                    BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                    apostrophe={false}
                                                                    isDataChanged={this.dataChangeHandler}
                                                                    onCancel={this.onCancelHandler}
                                                                />
                                                                :
                                                                screen === A12Y_CONST.TEXT.ASSET_BUSINESS_INTEREST_KEY ?
                                                                    <BusinessInterestComponentStep1
                                                                        title={A12Y_CONST.LABEL.BUSINESS_INTEREST}
                                                                        loading={this.state.loading}
                                                                        error={this.state.error}
                                                                        errorMessage={this.state.errorMessage}
                                                                        loaderFullScreen={this.state.loaderFullScreen}
                                                                        editMode={true}
                                                                        onUpdate={this.updateAssetData}
                                                                        currentAsset={this.props.currentAsset}
                                                                        fileUploadingData ={fileUploadingData}
                                                                        BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                        dependent={this.props.dependent}
                                                                        apostrophe={true}
                                                                        isDataChanged={this.dataChangeHandler}
                                                                        onCancel={this.onCancelHandler}
                                                                    />
                                                                    :
                                                                    screen === A12Y_CONST.TEXT.ASSET_SAFE_DEPOSIT_BOX_KEY ?
                                                                        <SafeDepositBoxComponentStep1
                                                                            title={A12Y_CONST.LABEL.SAFE_DEPOSIT_BOX}
                                                                            loading={this.state.loading}
                                                                            error={this.state.error}
                                                                            errorMessage={this.state.errorMessage}
                                                                            loaderFullScreen={this.state.loaderFullScreen}
                                                                            dependent={this.props.dependent}
                                                                            editMode={true}
                                                                            onUpdate={this.updateAssetData}
                                                                            currentAsset={this.props.currentAsset}
                                                                            fileUploadingData ={fileUploadingData}
                                                                            BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                            apostrophe={true}
                                                                            isDataChanged={this.dataChangeHandler}
                                                                            onCancel={this.onCancelHandler}
                                                                        />
                                                                        :
                                                                        screen === A12Y_CONST.TEXT.ASSET_INVESTMENT_ACCOUNT_KEY ?
                                                                            <InvestmentAccountComponentStep1
                                                                                title={A12Y_CONST.LABEL.INVESTMENT_ACCOUNT}
                                                                                loading={this.state.loading}
                                                                                error={this.state.error}
                                                                                errorMessage={this.state.errorMessage}
                                                                                loaderFullScreen={this.state.loaderFullScreen}
                                                                                dependent={this.props.dependent}
                                                                                editMode={true}
                                                                                onUpdate={this.updateAssetData}
                                                                                currentAsset={this.props.currentAsset}
                                                                                fileUploadingData ={fileUploadingData}
                                                                                BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                                apostrophe={true}
                                                                                isDataChanged={this.dataChangeHandler}
                                                                                onCancel={this.onCancelHandler}
                                                                            />
                                                                            :
                                                                            screen === A12Y_CONST.TEXT.ASSET_RETOREMENT_ACCOUNT_KEY ?
                                                                                <RetirementAccountComponentStep1
                                                                                    title={A12Y_CONST.LABEL.RETIREMENT_ACCOUNT}
                                                                                    loading={this.state.loading}
                                                                                    error={this.state.error}
                                                                                    errorMessage={this.state.errorMessage}
                                                                                    loaderFullScreen={this.state.loaderFullScreen}
                                                                                    dependent={this.props.dependent}
                                                                                    editMode={true}
                                                                                    onUpdate={this.updateAssetData}
                                                                                    currentAsset={this.props.currentAsset}
                                                                                    selectedAssestType={this.props.selectedAssestType}
                                                                                    BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                                    apostrophe={true}
                                                                                    isDataChanged={this.dataChangeHandler}
                                                                                    onCancel={this.onCancelHandler}
                                                                                />
                                                                                :
                                                                                screen === A12Y_CONST.TEXT.ASSET_BURIAL_ACCOUNT_KEY ?
                                                                                    <BurialAccountComponentStep1
                                                                                        title={A12Y_CONST.LABEL.BURIAL_ACCOUNT}
                                                                                        loading={this.state.loading}
                                                                                        error={this.state.error}
                                                                                        errorMessage={this.state.errorMessage}
                                                                                        loaderFullScreen={this.state.loaderFullScreen}
                                                                                        dependent={this.props.dependent}
                                                                                        editMode={true}
                                                                                        onUpdate={this.updateAssetData}
                                                                                        currentAsset={this.props.currentAsset}
                                                                                        selectedAssestType={this.props.selectedAssestType}
                                                                                        BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                                        apostrophe={true}
                                                                                        isDataChanged={this.dataChangeHandler}
                                                                                        onCancel={this.onCancelHandler}
                                                                                    />
                                                                                    :
                                                                                    screen === A12Y_CONST.TEXT.ASSET_OTHER_KEY ?
                                                                                        <OtherComponentStep1
                                                                                            loading={this.state.loading}
                                                                                            error={this.state.error}
                                                                                            errorMessage={this.state.errorMessage}
                                                                                            loaderFullScreen={this.state.loaderFullScreen}
                                                                                            dependent={this.props.dependent}
                                                                                            editMode={true}
                                                                                            onUpdate={this.updateAssetData}
                                                                                            currentAsset={this.props.currentAsset}
                                                                                            fileUploadingData ={fileUploadingData}
                                                                                            BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                                            apostrophe={true}
                                                                                            isDataChanged={this.dataChangeHandler}
                                                                                            onCancel={this.onCancelHandler}
                                                                                        />
                                                                                        :
                                                                                        screen === A12Y_CONST.TEXT.ASSET_TRANSFER_KEY ?
                                                                                            <AssetTransferFormComponent
                                                                                                loading={this.state.loading}
                                                                                                error={this.state.error}
                                                                                                errorMessage={this.state.errorMessage}
                                                                                                dependent={this.props.dependent}
                                                                                                editMode={true}
                                                                                                onUpdate={this.updateTransferHandler}
                                                                                                fileUploadingData ={fileUploadingData}
                                                                                                currentAsset={this.props.currentAsset}
                                                                                                BtnTxt={A12Y_CONST.BUTTON_TEXT.SAVE}
                                                                                                isDataChanged={this.dataChangeHandler}
                                                                                                hidePopUp={this.onCancelHandler}
                                                                                            />
                                                                                            :
                                                                                            null

                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    assetTypeList: state.dependentReducer.assetTypeList,
    asset: state.dependentReducer.asset,
    currentAsset: state.dependentReducer.currentAsset,
    assetEditScreenKey: state.dependentReducer.assetEditScreenKey,
});

const mapDispatchToProps = dispatch => ({
    saveCurrentAsset : (data) => dispatch(dependentActions.saveCurrentAsset(data)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
});

export default connect(mapStateToProps, mapDispatchToProps) (AssetEditScreen);