import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userDetailReducer from '../reducers/userDetailReducer'
import dependentReducer from '../reducers/dependentReducer'

const persistConfig = {
    key:'root',
    storage,
    whitelist:['userDetailReducer','dependentReducer']
}



const rootReducer = combineReducers({
    userDetailReducer : userDetailReducer,
    dependentReducer : dependentReducer
});

export default persistReducer(persistConfig,rootReducer);