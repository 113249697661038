import React from 'react'
import A12Y_CONST from "../common/a12yConst";


function InsuranceQuestionIfNotZero(props) {

    const handleYes = () => {
        props.loanOnPolicy(true)
    }
    const handleNo = () => {
        props.loanOnPolicy(false)
    }



    return (
        <div className="df fcol marginBottom20 height100">
            <h4 className="dependentSubTitle"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'>{A12Y_CONST.LABEL.APOSTROPHE_S} {A12Y_CONST.LABEL.LIFE_INSURANCE} </span></h4>

            <div className='df fcol marginBottom20 height100 width100Percent positionAbsouteOnly queWrap'>
                <h3 className='title24'>{A12Y_CONST.TEXT.DEPENDENT_TAKE_LOAN_FROM_POLICY_MESSAGE}</h3>

                <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol positionAbsouteBottom right65_bottom30">
                    <div className='widthAuto'>
                        <input id={A12Y_CONST.BUTTON_ID.INSURANCE_QUE_NO} className='cancelButton1' onClick={handleNo} type="button" value={A12Y_CONST.BUTTON_TEXT.NO} />
                    </div>
                    <div className='widthAuto marginLeft20'>
                        <input id={A12Y_CONST.BUTTON_ID.INSURANCE_QUE_YES} className={'saveButton'} onClick={handleYes} type="button" value={A12Y_CONST.BUTTON_TEXT.YES} />
                    </div>

                </div>
            </div>
        </div>
    )

}

export default InsuranceQuestionIfNotZero
