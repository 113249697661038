import React,{Component} from 'react'
import DependentProfileEditComponent from '../components/DependentProfileEditComponent'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import {secureClient} from "../config/axiosClient";
import * as userDetailActions from "../redux/actions/userDetailActions";
import {uploadImage} from "../util/imageUpload";

class DependentProfileEditScreen extends Component {

    constructor(props){
        super(props)
        this.state={
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
        }
    }

    componentDidMount(){
    }

    submitHandler = async(data,dependentProfileObj,shouldImageRemove)=>{
        this.setState({loading:true,isError:false})
        if(Object.keys(dependentProfileObj).length > 0){
            await uploadImage(dependentProfileObj.croppedImageUrl1,dependentProfileObj.advocate_id,dependentProfileObj.dependentId,dependentProfileObj.cropedUrl,dependentProfileObj.dependent)
        }
        if(shouldImageRemove){
            await this.handleRemoveImg()
        }
        await this.props.updateDependent(data,this.props.dependent.id)
        this.setState({loading:false})
        await this.dataChangeHandler(false)
        if(this.props.isError){
            this.setState({isError:true, errorMessage:this.props.errMsg})
        }else {
            this.setState({message:this.props.successMessage},()=>{
                setTimeout(()=>{
                    this.setState({message:''})
                    //this.props.showToast(this.props.successMessage);
                    this.onCancelHandler();
                },500)
            })
        }
    }

    onCancelHandler=()=>{
        this.props.onCancel()
    }
    handleRemoveImg = async () => {
        try {
            let url = A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DELETE_PROFILE_PIC
            let res = await secureClient.delete(url)
            if (!res.data.error && res.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let dependent = this.props.dependent
                dependent.profile_pic_url = ''
                this.props.updateDependentProps(dependent)
                this.setState({isImageRemove:true})
            }else {

            }
        }catch (e) {
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DELETE_PROFILE_PIC,e)
        }

    }
    overlay = (status) => {
        this.props.overlay(status)
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    render(){
        return(
            <div className="titleRow margignTop768_13 margignBottom768_10 dependentEditProfileMain">
                <h2 className="titleWithSidebar marginBottom30"><span>{A12Y_CONST.LABEL.DEPENDENT_PROFILE_EDIT_TITLE}</span></h2>
                <div className='dependentDefaultMainC0l fullWidth'>
                <DependentProfileEditComponent
                    dependent={this.props.dependent}
                    onSubmit={this.submitHandler}
                    loading={this.state.loading}
                    message={this.state.message}
                    error={this.state.error}
                    errorMessage={this.state.errorMessage}
                    onCancel={this.onCancelHandler}
                    handleRemoveImg={this.handleRemoveImg}
                    overlay = {this.overlay}
                    isDataChanged={this.dataChangeHandler}
                />
            </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    successMessage : state.dependentReducer.successMessage,
    isError : state.userDetailReducer.isError,
});

const mapDispatchToProps = dispatch => ({
    updateDependent : (dependent,dependentId) => dispatch(dependentActions.updateDependent(dependent,dependentId)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
});

export default connect(mapStateToProps, mapDispatchToProps) (DependentProfileEditScreen);
