export async function readOnlyOnMobile (el) {
    let width = window.outerWidth
    if(width < 990){
        // if (el !== null && Object.keys(el).length > 0 && el?.input !== null)
        // {
        //     el.readOnly = true;
        // }
        el.name === 'dob' || el.name === 'appointment_date' ? el.readOnly = true : el.input.readOnly = true
    }
}

export async function readOnlyMobile (arr){
    for(let i=0; i < arr.length; i++){
        readOnlyOnMobile(arr[i]);
    }
}

export async function hideOnBlur (e, elmRef) {
    if (e.keyCode === 9 || e.which === 9) {
        elmRef.current.setOpen(false)
    }
}