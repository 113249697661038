import moment from 'moment';

export async function todayDate() {
    let today = new Date();
    let date = await moment(today).format("MM / DD / YYYY")
    return date;
}

export async function ddmmyyyyFormat(_date) {
    if (typeof _date !== 'string') {
        let date = await moment(_date).format("MM/DD/YYYY")
        return date;
    } else {
        return _date;
    }
}

export function validateDate(date, minYear, maxYear) {
    let MDY = moment(date).format('MM/DD/YYYY')
    let today = moment(new Date());
    let selectedDate = moment(date)
    let minYr = moment(today).subtract(minYear, 'y')
    let maxYr = maxYear !== '' ? moment(today).add(maxYear, 'y') : null;
    let returnStr = {
        errMsg: "",
        isErr: false
    };

    if (MDY.toString().length > 9) {
        if (maxYr === null) {
            return !(selectedDate < minYr)
        }
        else {
            if (selectedDate < minYr) {
                returnStr = {
                    errMsg: "Date is too far in the past.",
                    isErr: true
                }

            } else if (selectedDate > maxYr) {
                returnStr = {
                    errMsg: "Can't add future date.",
                    isErr: true
                }
            }
            else if (selectedDate > minYr && selectedDate < maxYr) {
                returnStr = {
                    errMsg: "",
                    isErr: false
                }
            }

            return returnStr;
        }
    }
}

export function dateFromKeyboard(dtStr) {
    let input = dtStr;
    let output = []

    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split('/').map(function (v, index) {
        if (index === 2) {
            let yr = ''
            v.toString().trim().split('').forEach((y, i) => {
                if (i === 0) {
                    yr = yr + '' + y.replace(/[^1-2]/g, '')
                }
                else {
                    yr = yr + '' + y.replace(/\D/g, '')
                }
            })
            return yr.substring(0, 4)
        } else {
            return v.replace(/\D/g, '')
        }
    });

    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 31);

    if (values.length < 3) {
        output = values.map(function (v, i) {
            return v.length === 2 && i < 2 ? v + ' / ' : v;
        });

        return output.join('')

    } else {
        return values.join(' / ')
    }
}

const checkValue = (str, max) => {
    if (str.charAt(0) !== '0' || str === '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0))
            && num.toString().length === 1 ? '0' + num : num.toString();
    };
    return str;
};

export function amoutWithoutDecimal(amount) {
    var amt = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    if (window.outerWidth < 768) {
        amt = amt.split('.')[0];
    }
    return amt
}