import React, { Component } from 'react'
import DebtViewComponent from '../components/DebtLiabilityViewComponent'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from "../common/a12yConst";
import {secureClient} from "../config/axiosClient";
import {downloadFile} from "../util/imageDownload";
import LoaderFullScreen from '../components/LoaderFullScreenComponent'

class DebtLiabilityViewScreen extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            isDownloadActive: false,
            error:false,
            message:'',
            errorMessage:'',
            loading: false,
        }
    }



    onEditHandler = async()=>{
        let debt = this.props.currentDebt;
        debt.isComingFromView = true;
        await this.props.currentDebtData(debt);
        /*this.props.history.push(A12Y_CONST.ROUTE.EDIT_DEBT);*/
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.EDIT_DEBT,
            state: {fromDebtView:true }
        });
    }

    onDelete = async()=>{
        this.setState({loading: true})
        if(this.props.currentDebt && Object.keys(this.props.currentDebt).length > 0){
            this.setState({loading:true,errorMessage:'',error:false})
            let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.DEBTS+"/"+this.props.currentDebt.id);
            this.setState({loading:false})
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.props.currentDebtData({})
                this.props.history.push({
                    pathname : A12Y_CONST.ROUTE.INVENTORY,
                    state:{tab:A12Y_CONST.TEXT.DEBTS}
                });
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message, loading: false})
            }
        }
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    onCancel = async()=>{
        /*this.props.history.push(A12Y_CONST.ROUTE.DEBTS);
        await this.props.currentDebtData({})*/
      await this.onGoBackHandler();
    }

    onDownloadHandler = async(item, newName)=>{

        this.setState({
            isDownloadActive: true
        })
        await downloadFile(item, newName).then(() => {
            this.setState({
                isDownloadActive: false
            })
        })
    }

    onGoBackHandler = async () =>{
        this.props.history.push({
            pathname:A12Y_CONST.ROUTE.INVENTORY,
            state:{tab:A12Y_CONST.TEXT.DEBTS}
        })
        await this.props.currentDebtData({})
    }

    render() {
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
            <div className='rightPanelWidhtSidebar'>
                <div className="rightPanelWidhtSidebarContainer fullWidth">
                    <DebtViewComponent
                        loading={this.state.loading}
                        debtObj={this.props.currentDebt}
                        message={this.state.message}
                        error={this.state.error}
                        errorMessage={this.state.errorMessage}
                        dependent={this.props.dependent}
                        onEdit={this.onEditHandler}
                        onDelete={this.onDelete}
                        onCancel={this.onCancel}
                        onDownload={this.onDownloadHandler}
                        isComingFromActivityView={this.props.isComingFromActivityView}
                        onGoBack={this.onGoBackHandler}
                    />

                    {
                        this.state.isDownloadActive && <LoaderFullScreen />
                    }
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    currentDebt : state.dependentReducer.currentDebt
});
const mapDispatchToProps = dispatch => ({
    currentDebtData : (debt) => dispatch(dependentActions.currentDebtData(debt))
});

export default connect(mapStateToProps,mapDispatchToProps) (DebtLiabilityViewScreen)