import React, { useState, useEffect, useRef } from "react";
import folderIcon from '../assets/images/folderIcon.svg'
function HelpComponent() {
        const [inputList, setInputList] = useState([{ fileName: "Anand Chawla"}]);
        const lastRef = useRef(null);
        const [elementAdded, setElementAdded] = useState(false)
        // handle input change
        const handleInputChange = (e, index) => {
                const { name, value } = e.target;
                const list = [...inputList];
                list[index][name] = value;
                setInputList(list);
        };
    useEffect(() => {
        //if(inputList.length > 1){
            if (lastRef.current){
                lastRef.current.focus();
            }
        //}

    }, [inputList]);


        // // handle click event of the Remove button
        // const handleRemoveClick = index => {
        //         const list = [...inputList];
        //         list.splice(index, 1);
        //         setInputList(list);
        // };

        // handle click event of the Add button
        const handleAddClick = () => {
                setInputList([...inputList, { fileName: ""}]);
                setElementAdded (true)
        };
        const elementSelect = (i, item) => {
            inputList.map((info) => (
                {...info, isSelect:false}
                )
            )
        }
        return (
            <div className="App">
                    <div className="btn-box">
                            {<button onClick={handleAddClick}>Add</button>}
                    </div>
                    <div className="documentListRow fullWidth">
                    {inputList.map((item, i) => {
                            return (
                                <div className="documentListCol" onClick={() => {elementSelect(item, i)}}>
                                        <div className="folderImgIcon">
                                                <img src={folderIcon} alt='icon' />
                                        </div>
                                        <div className='inputFilename'>
                                        <input
                                            name="fileName"
                                            ref={i === inputList.length - 1 && elementAdded && item.fileName.length < 1 ? lastRef : undefined}
                                            // placeholder="Enter First Name"
                                            value={item.fileName}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                        </div>
                                </div>
                            );
                    })}
                    </div>
                    {/*<div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>*/}
            </div>
        );
}

export default HelpComponent;