import React from 'react'
import {LinearProgress, Box} from "@material-ui/core";
function Loading () {
        return (
            <div className="positionRelative zIndex2">
                <span className="positionAbsoute fullWidth">
                    <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
                </span>
            </div>
        )
}

export default  Loading;