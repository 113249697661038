import React,{useEffect, useState} from 'react'
import Loading from '../components/LoadingComponent'
import A12Y_CONST from '../common/a12yConst';
import { useForm } from 'react-hook-form';
import exclamationImg from '../assets/images/exclamationErrorIcon.svg';
import { Check } from '@material-ui/icons';
import { withRouter } from "react-router";
import {client} from "../config/axiosClient";

function  ForgotCreatePasswordScreen(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {register, handleSubmit, setValue, formState:{ errors},} = useForm({mode:"onBlur"});
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConditionBox, setPasswordConditionBox] = useState(false);
    const [passwordErrorHide, setaPsswordErrorHide] = useState(false);
    const [password, setPassword] = useState('');
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    /* eslint-disable no-unused-vars */
    const [passwordSuccessMessage, setPasswordSuccessMessage] = useState(false);
    const [passwordCondition, setPasswordCondition] = useState([
        {
            type:'lowercaseCharacter',
            name: 'One lowercase character',
            status: false
        },
        {
            type:'specialCharacter',
            name: 'One special character',
            status: false
        },
        {
            type:'uppercaseCharacter',
            name: 'One uppercase character',
            status: false
        },
        {
            type:'minimumCharacters',
            name: '8 characters minimum',
            status: false
        },
        {
            type:'numberOnly',
            name: 'Should be one number',
            status: false
        }
    ])
    /* eslint-disable no-unused-vars */
    useEffect(() => {
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', function (event){
                window.history.pushState(null, document.title,  window.location.href);
            });
    },[])
    // useEffect(()=>{
    //     if(!isDirty || !isValid || isSubmitting){
    //         setIsBtnDisabled(true)
    //     }else {
    //         setIsBtnDisabled(false)
    //     }
    // },[isDirty , isValid , isSubmitting])

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleChangePassword = (event) => {
        if(event.target.value === " "){
            return
        }else{
            let count = 0;
            if(event.target.value.length > 0){
                setPasswordConditionBox(true)
            }
            else{
                setPasswordConditionBox(false)
            }
            let lowercaseCharacter = /[a-z]/g;
            if(event.target.value.match(lowercaseCharacter)) {
                validatePassword('lowercaseCharacter', true)
                count++
            } else {
                validatePassword('lowercaseCharacter', false)
            }
            let uppercaseCharacter = /[A-Z]/g;
            if(event.target.value.match(uppercaseCharacter)) {
                validatePassword('uppercaseCharacter', true)
                count++
            } else {
                validatePassword('uppercaseCharacter', false)
            }
            let numberOnly = /[0-9]/g;
            if(event.target.value.match(numberOnly)) {
                validatePassword('numberOnly', true)
                count++
            } else {
                validatePassword('numberOnly', false)
            }
            //eslint-disable-next-line
            let specialCharacter = /[ `!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/? ~]/;
            if(event.target.value.match(specialCharacter)) {
                validatePassword('specialCharacter', true)
                count++
            } else {
                validatePassword('specialCharacter', false)
            }
            if(event.target.value.length >= 8) {
                validatePassword('minimumCharacters', true)
                count++
            } else {
                validatePassword('minimumCharacters', false)
            }
            if(count === passwordCondition.length) {
                setPasswordSuccessMessage(true);
                setaPsswordErrorHide(false)
                setIsBtnDisabled(false)
            }else{
                setPasswordSuccessMessage(false);
                setaPsswordErrorHide(true)
                setIsBtnDisabled(true)
            }
            setPassword(event.target.value);
            setValue('password', event.target.value);
        }
    };

    const validatePassword = (type, status) => {
        passwordCondition.forEach((item, index) => {
            if(item.type === type){
                item.status = status
            }
        })

    }
    const handeBlankSpace = (e) => {
        if(e.key === " "){
            e.preventDefault();
        }
    }
    const onSubmit = async(e) => {
        setError(false);
        setLoading(true)
        let response = await client.post(A12Y_CONST.API_ENDPOINT.CONFIRM_PASSWORD, {
            "code" : props?.location?.state?.code ?  props.location.state.code : '',
            "username" : props?.location?.state?.username ?  props.location.state.username : '',
            "password" : password
        });
        setLoading(false)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            props.history.push(A12Y_CONST.ROUTE.FORGOT_PASSWORD_UPDATED);
        }else {
            setError(true);
            setErrorMessage(response.data.sr?.a12yErr?.message)
        }
    }

    const goToSignInScreen = () => {
        props.history.push(A12Y_CONST.ROUTE.LOGIN);
    }
    return (
        <div className="top topRelativeCol mainChildBoxCenter">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className='emailVerificationCenterBox texAlingCener'>
                <div className="emailVerificationIcon">
                    <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40 18.6667H45.3333C46.0406 18.6667 46.7189 18.9476 47.219 19.4477C47.7191 19.9478 48 20.6261 48 21.3333V53.3333C48 54.0406 47.7191 54.7189 47.219 55.219C46.7189 55.7191 46.0406 56 45.3333 56H2.66667C1.95942 56 1.28115 55.7191 0.781048 55.219C0.280951 54.7189 0 54.0406 0 53.3333V21.3333C0 20.6261 0.280951 19.9478 0.781048 19.4477C1.28115 18.9476 1.95942 18.6667 2.66667 18.6667H8V16C8 11.7565 9.68571 7.68687 12.6863 4.68629C15.6869 1.68571 19.7565 0 24 0C28.2435 0 32.3131 1.68571 35.3137 4.68629C38.3143 7.68687 40 11.7565 40 16V18.6667ZM5.33333 24V50.6667H42.6667V24H5.33333ZM21.3333 34.6667H26.6667V40H21.3333V34.6667ZM10.6667 34.6667H16V40H10.6667V34.6667ZM32 34.6667H37.3333V40H32V34.6667ZM34.6667 18.6667V16C34.6667 13.171 33.5429 10.4579 31.5425 8.45753C29.5421 6.45714 26.829 5.33333 24 5.33333C21.171 5.33333 18.4579 6.45714 16.4575 8.45753C14.4571 10.4579 13.3333 13.171 13.3333 16V18.6667H34.6667Z" fill="#2D6CB2"></path>
                    </svg>

                </div>
                <h2 className="title1 texAlingCener">
                    {A12Y_CONST.MESSAGE.FORGOT_CREATE_PASSWORD_TITLE}
                </h2>
                <div className="marginBottom20 fullWidth">
                    <div className="emailVerificationMessage">
                        {A12Y_CONST.MESSAGE.FORGOT_CREATE_PASSWORD_MESSAGE}
                    </div>
                </div>
                {error &&
                <div className="positionRelative fullWidth">
                <div className="errorMessageWithBackground  positionAbsoute">
                    <div className="exclamationErrorIcon"><img alt="" src={exclamationImg}/></div>
                    <div className='errorTextContent'>{errorMessage}</div>
                </div>
                </div>
                }
                <div className="inputColField fullWidth onlyNumber marginTop80">
                    <form autoComplete="off" className='maxWidth438' onSubmit={handleSubmit(onSubmit)}>

                        <div className={'fullWidth positionRelative marginBottom20'}>
                            <div className={'fullWidth positionRelative'}>
                                <input
                                    {...register('password', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_PASSWORD_REQUIRED,
                                        pattern:{
                                            value:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$/,
                                            message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_PASSWORD_INVALID
                                        }
                                    })}
                                    id='userPassword'
                                    placeholder='Password'
                                    className={errors.password ? 'inputColum errorInputBorderRed PaddingPasswordIcon': 'inputColum PaddingPasswordIcon'}
                                    onChange={e =>handleChangePassword(e)}
                                    type={passwordShown ? "text" : "password"}
                                    value={password}
                                    onKeyDown={e => handeBlankSpace(e)}
                                />
                                <div className="passwordTextShow" onClick={togglePasswordVisiblity}>
                                    {
                                        passwordShown ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                                <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#A9AEB1"/>
                                                <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#A9AEB1"/>
                                            </svg>
                                            :
                                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#A9AEB1"></path>
                                                <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#A9AEB1"></path>
                                            </svg>
                                    }

                                </div>
                                {passwordErrorHide || !passwordConditionBox ? <div className='errorRelativeRow'> {errors.password && <p className='errorMessage'>{errors.password.message}</p>}</div> : null}
                            </div>
                            {passwordConditionBox ?
                                <div className="passwordMatchBox fullWidth">
                                    <ul>
                                        {
                                            passwordCondition.map((item, index) => {
                                                return(
                                                    <li key={index} className='passowrdListOfCondition'>
                                                        <span className='rightIocn'><Check className={item.status ? 'checkIconActive':'checkIcon'}/></span>
                                                        <span className='passowrdListOfConditionText'>{item.name}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                :
                                null
                            }

                        </div>

                        {loading ?
                            <Loading />
                            :
                            null
                        }
                        <div className='fullWidth'>
                            <input
                                id='signInButton'
                                type="submit"
                                disabled={isBtnDisabled}
                                //className={!isDirty || !isValid || isSubmitting ? "buttonDisable marginTop25" :'button marginTop25'}
                                className={isBtnDisabled ? "buttonDisable marginTop25" :'button marginTop25'}
                                //disabled={!isDirty || !isValid || isSubmitting}  type="button"
                                //onClick={handleSubmit(onSubmit)}
                                value={A12Y_CONST.BUTTON_TEXT.FORGOT_CREATE_PASSWORD_SEND}/>
                        </div>
                        <div className='cancelButton fullWidth marginTop10'><span onClick={goToSignInScreen}>Cancel</span></div>
                    </form>
                </div>

            </div>
        </div>
    )

}
const mapStateToProps = state => ({
    userState: state.userDetailReducer.userState,
});

export default withRouter (ForgotCreatePasswordScreen);