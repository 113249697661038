import React, { Component } from 'react'
import AddDoctor from '../components/AddDoctorComponent'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import {secureClient} from "../config/axiosClient";
import * as userDetailActions from "../redux/actions/userDetailActions";

class AddDoctorScreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : true,
            incomeTypeList: [],
            loaderFullScreen:false,
            editMode:false,
            doctor:{},
            isClickedOutside:false,
            fromViewScreenOnly:false
         }
    }


    componentDidMount = async () => {
        let state = { ...this.props.location.state };
        if(state?.fromViewScreen){
           await this.setState({fromViewScreenOnly:true})
        }
    }


    handleOnSubmit = async (data, fileInfo, fileBase64Info) => {

    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }
    createDoctor = async (obj) => {
        try {
            this.setState({loading:true})
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCTORS, obj);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.setState({doctor:response.data.sr.doctor})
                await this.props.getAllDoctorList(this.props.dependent.id);
                let dependent = this.props.dependent
                dependent.metadata.doctor_present = true
                await this.props.updateDependentProps(dependent)
                this.navigationHanlder()
                this.setState({loading:false})
                // await this.props.updateDoctorMetaData(!this.props.doctor_present)
                await this.props.updateDoctorMetaData(true);
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.CREATE_DOCTOR,e)
        }
    }

    updateDoctor = async (data)=>{
        try {
            this.setState({loading:true})
            let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DOCTORS+"/"+data.id,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let doctor = response.data.sr.doctor
                await this.props.getAllDoctorList(this.props.dependent.id);
                let isComingFromView = this.props.currentDoctor.hasOwnProperty('isComingFromView') ?  true : false
                if(isComingFromView){
                    doctor.isComingFromView = true
                }
                await this.props.currentDoctorData(doctor)
                this.navigationHanlder()
                this.setState({loading:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.UPDATE_DOCTOR,e)
        }
    }

    navigationHanlder = async ()=>{
        let path = null;
        if(this.props.currentMedication !== null &&  this.props.currentMedication !== undefined && Object.keys(this.props.currentMedication).length > 0 && this.props.currentMedication.hasOwnProperty('isComingFromMedication')){
           let medication = this.props.currentMedication
            medication.doctor = this.state.doctor;
           await this.props.currentMedicationData(medication);
            path = A12Y_CONST.ROUTE.ADD_MEDICATION
        }else if (this.props.currentDoctor !== null && Object.keys(this.props.currentDoctor).length > 0 && this.props.currentDoctor.hasOwnProperty('isComingFromView')){
            path = A12Y_CONST.ROUTE.DOCTOR_VIEW
        }else {
            path = A12Y_CONST.ROUTE.MEDICAL
        }
        if(path !== null){
            this.props.history.push({
                pathname : path,
                state : path === A12Y_CONST.ROUTE.MEDICAL ? {tab:A12Y_CONST.TEXT.DOCTOR} : path = A12Y_CONST.ROUTE.ADD_MEDICATION && this.state.fromViewScreenOnly ?  {fromAddDoctorToMedication:true } :null
            });
        }
    }

    onCancelHandler = async ()=>{
        await this.navigationHanlder();
    }

    render(props) {
        return (
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare" onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddDoctor
                            loading={this.state.loading}
                            message={this.state.message}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            loaderFullScreen={this.state.loaderFullScreen}
                            dependent={this.props.dependent}
                            isDataChanged={this.dataChangeHandler}
                            editMode={this.state.editMode}
                            doctorSpecialistTypeList={this.props.doctorSpecialistTypeList}
                            onSubmit={this.createDoctor}
                            doctor={this.props.currentDoctor}
                            onCancel={this.onCancelHandler}
                            onUpdate={this.updateDoctor}
                            isClickedOutside={this.state.isClickedOutside}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    isSessionTimeout: state.userDetailReducer.isSessionTimeout,
    doctorSpecialistTypeList: state.dependentReducer.doctorSpecialistTypeList,
    currentDoctor : state.dependentReducer.currentDoctor,
    currentMedication : state.dependentReducer.currentMedication,
    doctor_present : state.dependentReducer.doctor_present,
});

const mapDispatchToProps = dispatch => ({
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    getAllDoctorList: (dependentId) => dispatch(dependentActions.getAllDoctorList(dependentId)),
    currentMedicationData: (medication) => dispatch(dependentActions.currentMedicationData(medication)),
    currentDoctorData: (doctor) => dispatch(dependentActions.currentDoctorData(doctor)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    updateDoctorMetaData: (doctor_present) => dispatch(dependentActions.updateDoctorMetaData(doctor_present)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});

export default connect(mapStateToProps, mapDispatchToProps) (AddDoctorScreen);
