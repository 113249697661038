import {client,secureClient} from "../../config/axiosClient";
import A12Y_CONST from '../../common/a12yConst'

export const getAllDependent = (advocate_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_START})
        const dependentResponse = await secureClient.post(A12Y_CONST.API_ENDPOINT.GET_ADVOCATE_DEPENDENTS);
        if (!dependentResponse.data.error && dependentResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get all dependent', {
                'method': 'Google'
            });
            const dependentResInfo = dependentResponse.data;
            /*let dispatchData = {
                dependents : dependentResInfo.sr.dependents,
                message : dependentResInfo.sr.message,
            }*/

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_SUCCESS,
                dependents : dependentResInfo.sr.dependents,
                dependent : dependentResInfo.sr.dependents.length > 0 ? dependentResInfo.sr.dependents[0] : {},
                message : dependentResInfo.sr.message,
            });
        } else {
            const dependentResInfo = dependentResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_ERROR,
                errMsg:dependentResInfo.sr.a12yErr.message,
                code:dependentResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "getting dependent failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_ALL_DEPENDENT,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "getting Dependent Error.",
            fatal: true
        });
    }
}


export const createDependent = (advocate_id,data) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.CREATE_DEPENDENT_START})
        const dependentResponse = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS,data);
        if (!dependentResponse.data.error && dependentResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'dependent_created', {

            });
            const dependentResInfo = dependentResponse.data;
            if(dependentResInfo.sr.dependent.sys_docs.length > 0){
                let temp = dependentResInfo.sr.dependent.sys_docs
                temp = temp.find(item => item.name === 'Legal')
                dispatch({
                    type: A12Y_CONST.DISPATCH_ACTION.LEGAL_DOC_ID,
                    doc_id: temp.doc_id,
                });
            }
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.CREATE_DEPENDENT_SUCCESS,
                message : dependentResInfo.sr.message,
                dependent: dependentResInfo.sr.dependent,
            });
        } else {
            const dependentResInfo = dependentResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.CREATE_DEPENDENT_ERROR,
                errMsg:dependentResInfo.sr.a12yErr.message,
                code:dependentResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'dependent_creation_failed', {
                description: dependentResInfo.sr.a12yErr.message,
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.CREATE_DEPENDENT,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.CREATE_DEPENDENT_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "Unable to create dependent",
            fatal: true
        });
    }
}

export const  updateDependentProps = (dependent) => async (dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_SUCCESS, dependent: dependent})
}

export const  switchDependent = (dependent) => async (dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.SWITCH_DEPENDENT, dependent: dependent})
}

export const  currentTabName = (tabName) => async (dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.TAB_NAME, tabName: tabName})
}

export const  updateDependentList = (dependents) => async (dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_LIST, dependents: dependents})
}

export const  updateDoctorsList = (doctors) => async (dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DOCTORS_LIST, doctorList: doctors})
}

export const updateDependent = (dependent,dependentId) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_START})
        const dependentResponse = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId,dependent);
        if (!dependentResponse.data.error && dependentResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'dependent_updated', {

            });
            const dependentResInfo = dependentResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_SUCCESS,
                message : dependentResInfo.sr.message,
                dependent: dependentResInfo.sr.dependent,
            });
        } else {
            const dependentResInfo = dependentResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_ERROR,
                errMsg:dependentResInfo.sr.a12yErr.message,
                code:dependentResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'dependent_updated_failed', {
                description: dependentResInfo.sr.a12yErr.message,
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.UPDATE_DEPENDENT,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "Unable to update dependent",
            fatal: true
        });
    }
}

export const createAddress = (advocate_id,dependent_id,data) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_START})
        const dependentResponse = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependent_id+A12Y_CONST.API_ENDPOINT.ADDRESSES,data);
        if (dependentResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'create address', {
                'method': 'Google'
            });
            const dependentResInfo = dependentResponse.data;
            /*let dispatchData = {
                dependents : dependentResInfo.sr.dependents,
                message : dependentResInfo.sr.message,
            }*/
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_SUCCESS,
                message : dependentResInfo.sr.message,
                addressList: dependentResInfo.sr.dependentAddress,
            });
        } else {
            const dependentResInfo = dependentResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_ERROR,
                errMsg:dependentResInfo.sr.a12yErr.message,
                code:dependentResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "create address failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.CREATE_ADDRESS,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "create address Error.",
            fatal: true
        });
    }
}


export const updateAddress = (advocate_id,dependent_id,address_id,data) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.UPDATE_ADDRESSES_START})
        const dependentResponse = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependent_id+A12Y_CONST.API_ENDPOINT.ADDRESSES+"/"+address_id,data);
        if (!dependentResponse.data.error && dependentResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'update address', {
                'method': 'Google'
            });
            const dependentResInfo = dependentResponse.data;
            /*let dispatchData = {
                dependents : dependentResInfo.sr.dependents,
                message : dependentResInfo.sr.message,
            }*/

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_ADDRESSES_SUCCESS,
                message : dependentResInfo.sr.message,
                addressList: dependentResInfo.sr.dependentAddress,
            });
        } else {
            const dependentResInfo = dependentResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_ADDRESSES_ERROR,
                errMsg:dependentResInfo.sr.a12yErr.message,
                code:dependentResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "update address failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.UPDATE_ADDRESS,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.UPDATE_ADDRESSES_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "update address Error.",
            fatal: true
        });
    }
}


export const getAddress = (advocate_id,dependent_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.GET_ADDRESSES_START})
        const dependentResponse = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependent_id+A12Y_CONST.API_ENDPOINT.ADDRESSES);
        if (!dependentResponse.data.error && dependentResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get_address', {

            });
            const dependentResInfo = dependentResponse.data;

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.GET_ADDRESSES_SUCCESS,
                message : dependentResInfo.sr.message,
                addressList: dependentResInfo.sr.dependent_addresses,
            });
        } else {
            const dependentResInfo = dependentResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.CREATE_ADDRESSES_ERROR,
                errMsg:dependentResInfo.sr.a12yErr.message,
                code:dependentResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'get_address_failed', {
                description: "get address failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_ADDRESS,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.GET_ADDRESSES_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get address failed.",
            reason : error ? error.message : '',
            fatal: true
        });
    }
}

export const currentExpenseData = (expense) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CURRENT_EXPENSE_DATA, expense : expense})
}

export const currentIncomeData = (income) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CURRENT_INCOME_DATA, income : income})
}

export const currentActivityData = (activity) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CURRENT_ACTIVITY_DATA, activity : activity})
}
export const currentAssetData = (asset) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CURRENT_ASSET_DATA, asset : asset})
}
export const currentDebtData = (debt) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CURRENT_DEBT_DATA, debt : debt})
}

export const currentDoctorData = (doctor) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CURRENT_DOCTOR_DATA, doctor : doctor})
}

export const currentMedicationData = (medication) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CURRENT_MEDICATION_DATA, medication : medication})
}

export const medicationData = (medication) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.MEDICATION_DATA, medication_data : medication})
}
export const expenseCreatingUsingActivityLog = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CREATE_EXPENSE_USING_ACTIVITY_LOG, status : status})
}

export const expenseViewingUsingActivityView = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.VIEW_EXPENSE_USING_ACTIVITY_VIEW, status : status})
}

export const cleanDependent = () => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CLEAN_DEPENDENT, payload: {}})
}

export const checkDocumentScreen = (documentStatus) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.DOCUMENT_SCREEN_CHECKED, payload: documentStatus})
}

export const saveDependentDocs = (docs) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.DEPENDENT_DOCS, docs: docs})
}

export const myAccountClicked = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.MY_ACCOUNT_CLICKED, status: status})
}

export const getActivityTypeList = (dependentId) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.ACTIVITY_TYPE_LIST_START})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ACTIVITY+"/"+A12Y_CONST.API_ENDPOINT.ACTIVITY_TYPES+"?dependent_id="+dependentId)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get activityList', {
                'method': 'Google'
            });
            const activityTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.ACTIVITY_TYPE_LIST_SUCCESS,
                message : activityTypeResInfo.sr.message,
                activityList: activityTypeResInfo.sr.activity_types,
            });
        } else {
            const activityTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.ACTIVITY_TYPE_LIST_ERROR,
                errMsg:activityTypeResInfo.sr.a12yErr.message,
                code:activityTypeResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "get activityList failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_ACTIVITY_TYPE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.ACTIVITY_TYPE_LIST_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get activityList Error.",
            fatal: true
        });
    }
}

export const getExpenseTypeList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.EXPENSE_TYPE_LIST_START})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.EXPENSE_EXPENSE_TYPES);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get expenseList', {
                'method': 'Google'
            });
            const expenseTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.EXPENSE_TYPE_LIST_SUCCESS,
                message : expenseTypeResInfo.sr.message,
                expenseList: expenseTypeResInfo.sr.expense_types,
            });
        } else {
            const expenseTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.EXPENSE_TYPE_LIST_ERROR,
                errMsg:expenseTypeResInfo.sr.a12yErr.message,
                code:expenseTypeResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "get expenseList failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_EXPENSE_TYPE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.EXPENSE_TYPE_LIST_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get expenseList Error.",
            fatal: true
        });
    }
}

export const getDebtTypeList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.DEBT_TYPE_LIST_START})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.DEBT_DEBT_TYPES);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get debtTypeList', {
                'method': 'Google'
            });
            const debtTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.DEBT_TYPE_LIST_SUCCESS,
                message : debtTypeResInfo.sr.message,
                debtList: debtTypeResInfo.sr.debt_types,
            });
        } else {
            const debtTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.DEBT_TYPE_LIST_ERROR,
                errMsg:debtTypeResInfo.sr.a12yErr.message,
                code:debtTypeResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "get debt type List failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_DEBT_TYPE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.DEBT_TYPE_LIST_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get debt type List Error.",
            fatal: true
        });
    }
}   

export const getIncomeTypeList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.INCOME_TYPE_LIST_START})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.INCOME+A12Y_CONST.API_ENDPOINT.INCOME_TYPES);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get incomeList', {
                'method': 'Google'
            });
            const incomeTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.INCOME_TYPE_LIST_SUCCESS,
                message : incomeTypeResInfo.sr.message,
                incomeList: incomeTypeResInfo.sr.income_types,
            });
        } else {
            const incomeTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.INCOME_TYPE_LIST_ERROR,
                errMsg:incomeTypeResInfo.sr.a12yErr.message,
                code:incomeTypeResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "get incomeList failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_INCOME_TYPE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.INCOME_TYPE_LIST_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get incomeList Error.",
            fatal: true
        });
    }
}

export const setUserPreferences = (startDate,endDate,dependent,dependentList,activityTypeList,tagList,expenseTypeList,incomeTypeList) => async (dispatch, getState) => {
    try {
        let obj = {
            "dependent_id" : dependent.id,
            "pref_key" : "reporting_period",
            "pref_val" :  {
                "start_date" : startDate,
                "end_date" : endDate,
                "income_type_ids" : incomeTypeList?.length > 0 ? incomeTypeList : [],
                "expense_type_ids" : expenseTypeList?.length > 0 ? expenseTypeList:[],
                "activity_type_ids" : activityTypeList?.length > 0 ? activityTypeList :[],
                "activity_tags" : tagList?.length > 0 ? tagList :[],
            }
        }
        const response = await secureClient.post(A12Y_CONST.API_ENDPOINT.USERS_PREFERENCES,obj);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'usr_pref_rp_update_success', {

            });
            const userPrefResInfo = response.data;
            let preferences = userPrefResInfo.sr.preferences;
            let preferencesIndex = preferences.findIndex(item=>item.dependent_id === dependent.id)
            dependent.reporting_period.start_date = preferences[preferencesIndex].pref_val.start_date
            dependent.reporting_period.end_date = preferences[preferencesIndex].pref_val.end_date
            dependent.reporting_period.activity_tags = preferences[preferencesIndex].pref_val.activity_tags
            dependent.reporting_period.activity_type_ids = preferences[preferencesIndex].pref_val.activity_type_ids
            dependent.reporting_period.expense_type_ids = preferences[preferencesIndex].pref_val.expense_type_ids
            dependent.reporting_period.income_type_ids = preferences[preferencesIndex].pref_val.income_type_ids


            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USERS_PREFERENCES,
                //message : incomeTypeResInfo.sr.message,
                dependent: dependent,
            });
            if(dependentList.length > 0){
                let index = null;
                index = dependentList.findIndex(item=>item.id === dependent.id)
                if(index!== null){
                    dependentList[index].reporting_period.start_date = preferences[preferencesIndex].pref_val.start_date
                    dependentList[index].reporting_period.end_date = preferences[preferencesIndex].pref_val.end_date
                    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DEPENDENT_LIST, dependents: dependentList})
                }
            }
        } else {
            window.gtag('event', 'usr_pref_rp_update_failed', {
                description : response.sr.a12yErr.message
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.SET_USER_PREFRENCE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.ERROR_CODE.SET_USER_PREFRENCE,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "unable to update user reporting period",
            error_message : error ? error.message : '',
            fatal: true
        });
    }
}

export const isCommingFromDependentViewLegal = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_COMMING_FROM_DEPENDENT_VIEW, status: status})
}
export const commingFromDependentInvoiceView = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_COMMING_FROM_DEPENDENT_INVOICE_VIEW, status: status})
}

export const isCommingFromActivityList = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_COMMING_FROM_ACTIVITY_LIST, status: status})
}

export const updateMetaData = (income_present,expense_present,activity_present, asset_present) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_META_DATA, income_present: income_present, expense_present : expense_present, activity_present: activity_present, asset_present: asset_present})
}

export const updateMedicineMetaData = (medicine_present) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_MEDICINE_PRESENT_META_DATA, medicine_present:medicine_present})
}

export const updateDoctorMetaData = (doctor_present) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DOCTOR_PRESENT_META_DATA, doctor_present:doctor_present})
}

export const userSessionBackupScreenDetails = (screen) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.USER_SESSION_OUT_SCREEN, screen:screen})
}

export const userSessionBackupUserIdDetails = (userId) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.USER_SESSION_OUT_USER_ID, userId: userId})
}

export const dependentProfileUpdate = (profileObj) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.DEPENDENT_PROFILE, dependentProfileObj:profileObj})
}

export const assetKeyUpdate = (key) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.ASSET_EDIT_SCREEN_KEY, key:key})
}


export const getAssetTypeList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.ASSET_TYPE_LIST_START})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ASSETS+"/"+A12Y_CONST.API_ENDPOINT.ASSET_TYPES)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get assetList', {
                'method': 'Google'
            });
            const assetTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.ASSET_TYPE_LIST_SUCCESS,
                message : assetTypeResInfo.sr.message,
                assetList: assetTypeResInfo.sr.asset_types,
            });
        } else {
            const assetTypeResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.ASSET_TYPE_LIST_ERROR,
                errMsg:assetTypeResInfo.sr.a12yErr.message,
                code:assetTypeResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "get assetList failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_ASSET_TYPE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.ASSET_TYPE_LIST_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get assetList Error.",
            fatal: true
        });
    }
}


export const getSpecialtyList = (dependentId) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.DOCTOR_SPECIALIST_LIST_START})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId+"/"+A12Y_CONST.API_ENDPOINT.DOCTORS+"/"+A12Y_CONST.API_ENDPOINT.SPECIALITY)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get doctorSpecialty', {
                'method': 'Google'
            });
            const doctorSpecialtyResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.DOCTOR_SPECIALIST_SUCCESS,
                message : doctorSpecialtyResInfo.sr.message,
                doctorSpecialty: doctorSpecialtyResInfo.sr.speciality,
            });
        } else {
            const doctorSpecialtyResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.DOCTOR_SPECIALIST_TYPE_LIST_ERROR,
                errMsg:doctorSpecialtyResInfo.sr.a12yErr.message,
                code:doctorSpecialtyResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "get doctorSpecialtyList failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.GET_DOCOTRS_SPECIALIST_TYPE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.DOCTOR_SPECIALIST_TYPE_LIST_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get doctorSpecialtyList Error.",
            fatal: true
        });
    }
}

export const saveCurrentAsset = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.ASSET_OBJ, data: data})
}


export const createAsset = (dependentId,data) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.CREATE_ASSET_START})
        const response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId+"/"+A12Y_CONST.API_ENDPOINT.ASSETS,data);
        if (response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'create asset', {
                'method': 'Google'
            });
            const resInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.CREATE_ASSET_SUCCESS,
                message : resInfo.sr.message,
                asset: resInfo.sr.asset,
            });
        } else {
            const resInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.CREATE_ASSET_ERROR,
                errMsg:resInfo.sr.a12yErr.message,
                code:resInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "create asset failed.",
                fatal: false
            });
        }
    } catch (error) {
        await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.CREATE_ASSET,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.CREATE_ASSET_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "create asset Error.",
            fatal: true
        });
    }
}

export const getAllDoctorList = (dependentId) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_START})
        const response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId+"/"+A12Y_CONST.API_ENDPOINT.DOCTORS)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'get All Doctor list', {
                'method': 'Google'
            });
            const doctorResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_SUCCESS,
                message : doctorResInfo.sr.message,
                doctorList: doctorResInfo.sr.doctors,
            });
        } else {
            const doctorResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_ERROR,
                errMsg:doctorResInfo.sr.a12yErr.message,
                code:doctorResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "get activityList failed.",
                fatal: false
            });
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_ERROR,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.GET_ALL_DOCTORS_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "get All Doctor list Error.",
            fatal: true
        });
    }
}

export const hadlePathBeforeLogin = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.PATH_BEFORE_LOGIN, data : data})
}
export const handlePreviousMedication = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.PREVEIOUS_MEDICATION_DATA, data : data})
}
