import React,{Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import CodeVerification from '../components/CodeVerificationComponent'
import {client} from "../config/axiosClient";
import { connect } from 'react-redux';
class SignUpEmailVerificationScreen extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading:false,
            title:A12Y_CONST.MESSAGE.EMAIL_VERIFICATION_TITLE,
            messsage:A12Y_CONST.MESSAGE.EMAIL_VERIFICATION_MESSAGE,
            error:false,
            errorMessage:''
        }

    }

    handleSubmit = async(data)  => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userName = params.get('cog_uname');
        let isEmailLink = false;
        if(userName){
            isEmailLink = true
        }
        this.setState({loading:true})
        let response = await client.post(A12Y_CONST.API_ENDPOINT.SIGNUP_VERIFY_CODE, {
            "username" : isEmailLink ? userName :  this.props.userState.userInfo.username,
            "code" : data.emailCodeVerification
        });
        this.setState({loading:false})
        if (response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({error:false})
            window.gtag('event', A12Y_CONST.GOOGLE_ANALYTIC_EVENT.SIGNUP_CODE_VERIFIED, {

            });
            //window.gtag('event', 'conversion', { 'send_to': CONFIG.GOOGLE_TRACKING_ID2, 'transaction_id': ''});
            this.props.history.push({
                pathname : A12Y_CONST.ROUTE.EMAIL_LOGIN,
                state : {
                    'SignUpSuccess' : true,
                    'title' : A12Y_CONST.MESSAGE.EMAIL_LOGIN_TITLE,
                    'message' : A12Y_CONST.MESSAGE.EMAIL_LOGIN_MESSAGE,
                    'type': 'Email'
                }
            })
        } else {
            if(response.data.sr?.a12yErr?.a12y_code === A12Y_CONST.ERROR_API_CODE.ALREADY_USER_VERIFIED){
                this.setState({error:false})
                window.gtag('event', A12Y_CONST.GOOGLE_ANALYTIC_EVENT.SIGNUP_CODE_ALREADY_VERIFIED, {

                });
                this.props.history.push({
                    pathname : A12Y_CONST.ROUTE.EMAIL_LOGIN,
                    state : {
                        'SignUpSuccess' : true
                    }
                })
            }else {
                this.setState({error: true, errorMessage: response.data.sr?.a12yErr?.message})
                window.gtag('event', A12Y_CONST.GOOGLE_ANALYTIC_EVENT.SIGNUP_CODE_VERIFICATION_FAILED, {
                    description: 'wrong code',
                    unmae : this.props.userState.userInfo.username
                });
            }
            //this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
        }



    }
    handleResend = async(data)  => {
        this.setState({loading:true})
        let response = await client.post(A12Y_CONST.API_ENDPOINT.SIGNUP_RESEND_VERIFY_CODE, {
            "username" : this.props.userState.userInfo.username
        });
        this.setState({loading:false})
        if (response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({error:false})
            /*this.props.history.push({
                pathname : A12Y_CONST.ROUTE.LOGIN,
                state : {
                    'SignUpSuccess' : true
                }
            })*/
        } else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
        }
    }
    render() {
            return (
                <div>

                    <CodeVerification
                        type='email'
                        title={this.state.title}
                        error={this.state.error}
                        message={this.state.messsage}
                        onSubmit = {this.handleSubmit}
                        onResend = {this.handleResend}
                        loading = {this.state.loading}
                        errorMessage={this.state.errorMessage}
                        buttonTypeId={A12Y_CONST.BUTTON_ID.UNVERIFIED_LEAD}
                    />
                </div>

            )
        }


}
const mapStateToProps = state => ({
    userState: state.userDetailReducer.userState,
});

export default connect(mapStateToProps,null) (SignUpEmailVerificationScreen);
