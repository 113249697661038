import React,{useEffect,useState} from 'react'
import { useHistory } from "react-router-dom";
import A12Y_CONST from "../common/a12yConst";
import PopUpIncomeExpComponent from '../components/PopUpIncomeExpComponent'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import parsePhoneNumber from "libphonenumber-js";
import NotesComponent from "./NotesComponent";

function DoctorViewComponent(props) {
    const history = useHistory();
    const [isPopUp, setIsPopUp] = useState(false);
    const [error, setErrors] = useState(false);
    const [doctorInfo, setDoctorInfo] = useState({})
    const [mobileNumberFormat, setMobileNumberFormat] = useState('');
    const [alternateNumberFormat, setAlternateNumberFormat] = useState('');
    const [fax, setFax] = useState('');
    const [specialty, setSpecialty] = useState('')
    const [websiteUrl, setWebsiteUrl] = useState('');

    useEffect(() => {
        setErrors(props.error)
    }, [props.error])


    useEffect(() => {
        setDoctorInfo(props.currentDoctor)
    },[props.currentDoctor])

    function goBack() {
        history.push({
            pathname : A12Y_CONST.ROUTE.MEDICAL,
            state : {tab:A12Y_CONST.TEXT.DOCTOR}
        });
    }

    function deletingDoctor() {
        setIsPopUp(true)
    }
    function editDoctor() {
        props.onEdit();
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.onDeleteDoctor()
    }

    useEffect (  () => {
        if(props.currentDoctor !== null && props.currentDoctor?.phones?.length > 0 && props.currentDoctor.phones[0].type !== "" && props.currentDoctor.phones[0].number.length > 1 ){
            const mobileNum = parsePhoneNumber('+'+props.currentDoctor.phones[0].number);
            const mobileNumberFormating = '1 ' + mobileNum.formatNational()
            setMobileNumberFormat(mobileNumberFormating)
        }
        if(props.currentDoctor !== null && props.currentDoctor?.phones?.length > 0 && props.currentDoctor.phones[1].type !== "" && props.currentDoctor.phones[1].number.length > 1 ){
            const alternateNum = parsePhoneNumber('+'+props.currentDoctor.phones[1].number);
            const altNumberFormating = '1 ' + alternateNum.formatNational()
            setAlternateNumberFormat(altNumberFormating)
        }
        if(props.currentDoctor !== null && props.currentDoctor.fax){
            const faxNumber = parsePhoneNumber('+'+props.currentDoctor.fax);
            const faxNumberFormating = '1 ' + faxNumber.formatNational()
            setFax(faxNumberFormating)
        }

        props?.doctorSpecialistTypeList?.forEach(item => {
            if(item.id === doctorInfo?.speciality_id){
                setSpecialty(item.title)
            }
        })

    },[props.currentDoctor, doctorInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(doctorInfo?.web_url){
            let link = (doctorInfo?.web_url.indexOf('://') === -1) ? 'http://' + doctorInfo?.web_url : doctorInfo?.web_url;
            setWebsiteUrl(link)
        }else{
            setWebsiteUrl('')
        }
    },[doctorInfo?.web_url])

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 ViewDoctorMain addIncomesWrap">
            <h2 className="titleWithSidebar addIncomeTitle marginBottom30 doctorViewBottomMargin_600 addIncomeTitleError">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage} />
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message} />
                            :
                            null
                    )
                }
                <span id={A12Y_CONST.BUTTON_ID.PROVIDER_VIEW_BACK} className="cursorPointer marginRight10" onClick={goBack}>
                    <svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span>{A12Y_CONST.LABEL.DOCTOR}</span></span>

                {props.dependent?.access && props.dependent.access.can_update === true ?
                    <div className="actionButton hideDesktopShowMobile">
                        <button className="btn-primary mobileEditViewButton saveButton viewEditButton" onClick={editDoctor}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z"
                                    fill="#2D6CB2"/>
                            </svg>
                            <span className="paddingLeft1">{A12Y_CONST.LABEL.DEPENDENT_EDIT}</span>
                        </button>
                    </div>
                    :
                    null
                }
            </h2>
            <div className="dependentBorderBox paddingNone dependentDefauldMinHeight ">
             <div className='fullWidth padding3040 borderBottom'>
                <div className="df aic marginBottom20">
                    <h4 className="dependentSubTitle fontSize20"><span className='txtEllipsis vam'>{A12Y_CONST.LABEL.DOCTOR_OF} {props?.dependent?.first_name} {props?.dependent?.last_name}</span></h4>
                    <div className="mla desktopCol">
                        {props.dependent?.access && props.dependent.access.can_update === true ?
                            <>
                                <button id={A12Y_CONST.BUTTON_ID.EDIT_PROVIDER} className="btn-outline saveButton marginRight20" onClick={editDoctor}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                    </svg>
                                    <span className="paddingLeft1">{A12Y_CONST.TEXT.EDIT}</span>
                                </button>
                                <button id={A12Y_CONST.BUTTON_ID.DELETE_PROVIDER} className="cancelButton1 viewDeleteButton" onClick={deletingDoctor}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D96941" />
                                    </svg>
                                    <span className="paddingLeft1">{A12Y_CONST.BUTTON_TEXT.DELETE}</span>
                                </button>
                            </>
                            :
                            null
                        }
                    </div>
                  </div>
                <div className="docotorDetails invBox">
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.NAME}</label>
                        <div className='breakWords'>{doctorInfo?.doctor_name ? doctorInfo.doctor_name :''}</div>
                    </div>
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.SPECIALTY}</label>
                        <div className='breakWords'>{doctorInfo?.speciality_id ? specialty : ''}</div>
                    </div>
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.HOSPITAL_AFFILIATION}</label>
                        <div className='breakWords'>{doctorInfo?.hospital_name ? doctorInfo.hospital_name : ''}</div>
                    </div>
                     {websiteUrl ?
                        <div className="df">
                            <a id={A12Y_CONST.TEXT.LINK_URL} rel="noreferrer" className='linkToWebsite df alignCenterVH' target="_blank" href={websiteUrl}>
                                <svg className='marginRight10' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <path d="M9.16667 18.3333C4.10392 18.3333 0 14.2294 0 9.16667C0 4.10392 4.10392 0 9.16667 0C14.2294 0 18.3333 4.10392 18.3333 9.16667C18.3333 14.2294 14.2294 18.3333 9.16667 18.3333ZM7.0675 16.1947C6.16313 14.2765 5.63922 12.201 5.52475 10.0833H1.89017C2.06854 11.494 2.65276 12.8225 3.57185 13.9074C4.49095 14.9923 5.70535 15.7869 7.0675 16.1947ZM7.36083 10.0833C7.49925 12.3191 8.13817 14.4192 9.16667 16.2727C10.223 14.3702 10.84 12.2554 10.9725 10.0833H7.36083ZM16.4432 10.0833H12.8086C12.6941 12.201 12.1702 14.2765 11.2658 16.1947C12.628 15.7869 13.8424 14.9923 14.7615 13.9074C15.6806 12.8225 16.2648 11.494 16.4432 10.0833ZM1.89017 8.25H5.52475C5.63922 6.1323 6.16313 4.05688 7.0675 2.13858C5.70535 2.54639 4.49095 3.34102 3.57185 4.42594C2.65276 5.51085 2.06854 6.83934 1.89017 8.25ZM7.36175 8.25H10.9716C10.8394 6.07805 10.2226 3.96324 9.16667 2.06067C8.11037 3.96316 7.49334 6.07798 7.36083 8.25H7.36175ZM11.2658 2.13858C12.1702 4.05688 12.6941 6.1323 12.8086 8.25H16.4432C16.2648 6.83934 15.6806 5.51085 14.7615 4.42594C13.8424 3.34102 12.628 2.54639 11.2658 2.13858Z" fill="#2F6DB3"/>
                                </svg>
                                {A12Y_CONST.TEXT.LINK_TO_WEBSITE}</a>
                        </div>
                        :
                        null
                    }
                    <div className="df marginTop15">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                       {/* <div className='breakWords noteText ayScrollBar'>{
                            doctorInfo?.note?.length > 0 && doctorInfo?.note?.split('\n').map((n, i)=> {
                                return <div key={i}>{n}</div>
                            })}</div>*/}
                        <div className='fullWidth marginTop5'>
                            <NotesComponent
                                noteType={A12Y_CONST.LABEL.NOTE_DOCTOR_TYPE}
                                selectedItem={props?.currentDoctor}
                                dependent={props.dependent}
                            />
                        </div>
                    </div>
                </div>
             </div>
                <div className='fullWidth padding3040'>
                    <div className="df aic marginBottom20">
                        <h4 className="dependentSubTitle fontSize20"><span className='txtEllipsis vam'>{A12Y_CONST.LABEL.CONTACT_INFORMATION}</span></h4>
                    </div>
                    <div className="docotorDetails invBox">
                        <div className="df">
                            <label>{A12Y_CONST.LABEL.PHONE}</label>
                            {doctorInfo?.phones !== null && doctorInfo?.phones?.length > 0 && doctorInfo?.phones.map((phone,key)=>(
                                phone.type !== "" && phone.number !== ''?
                                <div key={key}><span className='phoneMarginRight'><span className='marginRight10'>{phone.type !== null && phone.type === 'office'? A12Y_CONST.LABEL.OFFICE_SMALL : phone.type === 'home' ? A12Y_CONST.LABEL.HOME_SMALL : phone.type === 'mobile' ? A12Y_CONST.LABEL.MOBILE_SMALL : A12Y_CONST.LABEL.OTHER_SMALL }:</span> <span className="geneInfo_value">{key === 0 ? '+'+mobileNumberFormat : '+'+alternateNumberFormat}</span></span></div>
                                 :
                                 null
                            ))}
                        </div>
                        <div className="df">
                            <label>{A12Y_CONST.LABEL.EMAIL}</label>
                            <div className='breakWords'>{doctorInfo?.email? doctorInfo.email: ''}</div>
                        </div>
                        {/*<div className="df">*/}
                        {/*    <label>{A12Y_CONST.LABEL.WEBSITE}</label>*/}
                        {/*    <div className='linkLightBlueColor'>{doctorInfo?.web_url? doctorInfo.web_url:''}</div>*/}
                        {/*</div>*/}
                        <div className="df">
                            <label>{A12Y_CONST.LABEL.ADDRESS}</label>
                            <div className='breakWords'>{doctorInfo?.address?.line1 && doctorInfo?.address?.line1 +','} {doctorInfo?.address?.line2 && doctorInfo?.address?.line2+','} {doctorInfo?.address?.city && doctorInfo?.address?.city+','} {doctorInfo?.address?.state && doctorInfo?.address?.state} {doctorInfo?.address?.zip}</div>
                        </div>
                        <div className="df">
                            <label>{A12Y_CONST.LABEL.FAX}</label>
                            <div>{doctorInfo?.fax ? '+'+fax : ''}</div>
                        </div>
                    </div>
             </div>
                {props.dependent?.access && props.dependent.access.can_update === true ?
                    <div className="actionButton hideDesktopShowMobile mobileDeleteViewButton fullWidth">
                        < button className = "cancelButton1 marginRight20 viewDeleteButton" onClick={deletingDoctor}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D96941" />
                            </svg>
                            <span className="paddingLeft1">{A12Y_CONST.LABEL.DELETE_PROVIDER}</span>
                        </button>
                    </div>
                    :
                    null
                }
            </div>
            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
}

export default DoctorViewComponent
