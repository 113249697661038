import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {store,persistor} from "./redux/store";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    //<React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </BrowserRouter>
        </Provider>
    //</React.StrictMode>
    ,
    document.getElementById('root')
);



/*import GA4React from "ga-4-react";
const ga4react = new GA4React("G-62HKG7X3P4");

(async _ => {
    await ga4react.initialize()
        .then(res => console.log("Analytics Success."))
        .catch(err => console.log("Analytics Failure."))
        .finally(() => {
            ReactDOM.render(
                <React.StrictMode>
                    <Provider store={store}>
                        <BrowserRouter>
                            <PersistGate persistor={persistor}>
                                <App />
                            </PersistGate>
                        </BrowserRouter>
                    </Provider>
                </React.StrictMode>,
                document.getElementById('root')
            );
        });
})();*/

//reportWebVitals();
