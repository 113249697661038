import React, { Component } from "react";
import Dropzone from "react-dropzone";
import pdf from '../assets/images/pdf.png'
import jpg from '../assets/images/jpg.png'
import jpg_icon from '../assets/images/jpg_icon.svg'
import UploadService from "../util/imageUploadDropZone";
import PopUpIncomeExpComponent from '../components/PopUpIncomeExpComponent'
import A12Y_CONST from "../common/a12yConst";

export default class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.upload = this.upload.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.removeThisFile = this.removeThisFile.bind(this);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
            isEditMode:true,
            deletedFiles:[],
            isPopUp:false,
            deleteSelectedItemNumber:0,
            acceptedTypes:this.props.accepts
        };
    }

    upload(idx, file) {
        let _progressInfos = [...this.state.progressInfos];

        UploadService.upload(file, (event) => {
            _progressInfos[idx].percentage = Math.round(
                (100 * event.loaded) / event.total
            );
            this.setState({
                _progressInfos,
            });
        })
            .then((response) => {
                this.setState((prev) => {
                    let nextMessage = [
                        ...prev.message,
                        "Uploaded the file successfully: " + file.name,
                    ];
                    return {
                        message: nextMessage,
                    };
                });
                return UploadService.getFiles();
            })
            .then((files) => {
                this.setState({
                    fileInfos: files.data,
                });
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [
                        ...prev.message,
                        "Could not upload the file: " + file.name,
                    ];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage,
                    };
                });
            });
    }

    uploadFiles() {
        /*const selectedFiles = this.state.selectedFiles;

        let _progressInfos = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }

        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i]);
                }
            }
        );*/

    }

    componentDidUpdate = async()=>{
        let allFiles = this.state.selectedFiles && this.state.selectedFiles.length>0?this.state.selectedFiles:[]
        let _progressInfos = [...this.state.progressInfos];
        let event = this.props.fileUploadingData
        if(event && Object.keys(event).length > 0){
            for (let i = 0; i < allFiles.length; i++) {
                if(event.index === i){
                    _progressInfos[i].percentage = Math.round(
                        (100 * event.data.loaded) / event.data.total
                    );
                }
            }
        }
        if(this.props.editMode && this.state.isEditMode){
            await this.assignSelectedFile();
        }
        let temp = this.props.obj
        if(this.props.isDoctype && this.state.selectedFiles === undefined && temp && Object.keys(temp).length > 0 && temp.document_ref && Object.keys(temp.document_ref).length > 0 && temp.document_ref.attached_files.length > 0){
            await this.assignSelectedFile();
        }
    }


    assignSelectedFile = async()=>{
        let temp = this.props.obj
        if(temp && Object.keys(temp).length > 0 && temp.document_ref && Object.keys(temp.document_ref).length > 0 && temp.document_ref.attached_files.length > 0){
            let allFiles = [];
            let _progressInfos = [];
            temp.document_ref.attached_files.forEach((item,index)=>{
                allFiles.push({fileName:item.name, displayName:item.name, file_id: item.file_id, size: item.size,upload_date:item.upload_date})
                _progressInfos.push({ percentage: 100, fileName: item.name, isDone:true, size: item.size, upload_date: item.upload_date});
            })
           await this.setState({isEditMode:false,selectedFiles:allFiles,progressInfos: _progressInfos})
        }
        if(temp && temp.hasOwnProperty('attachments') && Object.keys(temp).length > 0 && temp.attachments.length > 0){
            let allFiles = [];
            let _progressInfos = [];
            temp.attachments.forEach((item,index)=>{
                allFiles.push({fileName:item.file_name, displayName:item.file_name, file_id: item.file, size: item.file_size,upload_date:new Date(item.created_date)})
                _progressInfos.push({ percentage: 100, fileName: item.file_name, isDone:true, size: item.file_size, upload_date: new Date(item.created_date)});
            })
            await this.setState({isEditMode:false,selectedFiles:allFiles,progressInfos: _progressInfos})
        }

    }


    onDrop(files) {
        const result = files.filter(file => !file.path.includes('/'))
        if (result.length > 0) {
            let _progressInfos = this.state.progressInfos && this.state.progressInfos.length>0?this.state.progressInfos:[];
            let allFiles = this.state.selectedFiles && this.state.selectedFiles.length>0?this.state.selectedFiles:[]
            let num = allFiles.length+1
            if(allFiles.length > 0){
                let lastFile = allFiles[allFiles.length-1];
                let lastFileNum = lastFile.displayName.replace('Attachment','').split('.')[0]
                num = parseInt(lastFileNum)+1;
            }
            result.forEach((file, index) =>{
                //file.name = "Attachment"+index
                const uriArr = file.name.split('.');
                let fileType = uriArr[uriArr.length - 1]
                let isDoctype = this.props?.isDoctype ? true :false
                file.displayName = isDoctype ? file.name : "Attachment" + num + "." + fileType
                let fileName = file.displayName;
                _progressInfos.push({ percentage: 0, fileName: fileName, isDone:false , size: file.size, upload_date: new Date()});
                allFiles.push(file)
                num++;
            })
            this.setState(
                {
                    progressInfos: _progressInfos,
                    message: [],
                });
            this.setState({ selectedFiles: allFiles });
            this.props.fileDetailSendToParent(this.state.selectedFiles,this.state.deletedFiles)
            /*if(this.props.isDoctype){
                this.state.selectedFiles.map((item,index)=>{
                    if(!item.s3_key){
                        item.s3_key = 'test'
                    }
                })
            }*/
        }
    }

    removeThisFile = async (index) => {
        let isDoctype = this.props?.isDoctype ? true :false
        let temp = this.props.obj
        let file_id = this.state.selectedFiles[index].file_id;
        let deleteFiles = this.state.deletedFiles && this.state.deletedFiles.length>0?this.state.deletedFiles:[]
        if(temp && temp.document_ref && Object.keys(temp).length > 0 && temp.document_ref && Object.keys(temp.document_ref).length > 0 && temp.document_ref.attached_files.length > 0){
          let data =  temp.document_ref.attached_files.filter(item =>item.file_id === file_id)
            if(data && data.length > 0){
                if(isDoctype){
                    deleteFiles.push(data[0])
                }else {
                    deleteFiles.push(file_id)
                }
            }
        }
        if(temp && temp.hasOwnProperty('attachments') && Object.keys(temp).length > 0 && temp.attachments.length > 0){
            let data =  temp.attachments.filter(item =>item.file === file_id)
            if(data && data.length > 0){
                if(isDoctype){
                    deleteFiles.push(data[0])
                }else {
                    deleteFiles.push(file_id)
                }
            }
        }
        let updatedArray = await this.state.selectedFiles.filter((_, i) => i !== index)
        let updatedProgressInfo = await this.state.progressInfos.filter((_, i) => i !== index)
        
         this.setState({
            selectedFiles: updatedArray,
            progressInfos: updatedProgressInfo,
            message: [],
            deletedFiles:deleteFiles,
            isPopUp:false
        });
        if(this.props.isDoctype){
            this.props.fileDetailSendToParent(this.state.selectedFiles, this.state.deletedFiles , index)
        }else {
            this.props.fileDetailSendToParent(this.state.selectedFiles, this.state.deletedFiles )
        }
    }
    handleDeleteButton = () => {
        this.removeThisFile(this.state.deleteSelectedItemNumber)
    }

    showFileRemovPopUp = async (ind) => {
       await this.setState({deleteSelectedItemNumber:ind, isPopUp:true})

    }
        /*-----Upload UI modification-----*/

    componentDidMount(){
        // debugger
        // this.setState({
        //     acceptedTypes:this.props.accepts
        // })
        // let elm = document.getElementById('dropZoneWrap');
        // elm.addEventListener('dragover', dragOver);
        // elm.addEventListener('dragenter', dragEnter);
        // elm.addEventListener('dragleave', dragLeave);

        // function dragOver(e) {
        //     e.preventDefault()
        // }
        
        // function dragEnter(e) {
        //     e.preventDefault()
        //     this.className = 'dropZoneWrap animationall df fcol hovered'
        // }
        
        // function dragLeave() {
        //     this.className = 'dropZoneWrap animationall df fcol'
        // }
    }

     bytesForHuman(bytes) {
        let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

        let i = 0

        for (i; bytes > 1024; i++) {
            bytes /= 1024;
        }

         return parseFloat(bytes).toFixed(1) + ' ' + units[i]
    }

     dateFormatting(date) {
         let _date = new Date(date)
         let date1 = _date.getDate() < 10 ? '0'+_date.getDate() : _date.getDate();
         let month = _date.toLocaleString('en-us', { month: 'short' });
         let fullYear = _date.getFullYear();
         let hh = _date.getHours();
         let mm = _date.getMinutes()< 10 ? '0'+_date.getMinutes() : _date.getMinutes()
         let formatted_date = date1+' '+month+', '+fullYear+' at '+hh+':'+mm
        return formatted_date;
    }

        // componentDidUpdate(){
        //     if(this.selectedFiles && Array.isArray(this.selectedFiles) && this.selectedFiles.length == 0){
        //         document.getElementById('dropZoneWrap').className='dropZoneWrap animationall df fcol'
        //     }
        // }

    hidePopUp = () => {
            this.setState({isPopUp:false})
    }
    
    render() {
        const { selectedFiles, progressInfos, message, fileInfos, acceptedTypes} = this.state;
        return (
            <div>
                <div id="dropZoneWrap">
                    <Dropzone onDrop={this.onDrop} accept={acceptedTypes}>
                        {({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections }) => (
                            <>
                                <section className={`df fcol dropZoneWrap animationall ${isDragActive && 'dragAactive'} ${isDragReject && 'rejectMsg'}`}>
                                    <input {...getInputProps()} />
                                    {(
                                        <div {...getRootProps({ className: `dropzone df fcol aic jcc fullWidth` })}>
                                            <div className={`attachTxt`} >
                                                {isDragReject && <p>{acceptedTypes}</p>}
                                                
                                                {!isDragReject && (
                                                    <div className="df fcol aic">
                                                        <div className="df aic">
                                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M14.3 2.52632C17.1717 2.52632 19.5 4.78863 19.5 7.57895C19.5 7.61937 19.5 7.66233 19.4987 7.7078C19.4844 8.29517 19.8887 8.8143 20.475 8.96083C22.1572 9.38146 23.4 10.8669 23.4 12.6316C23.4 14.7246 21.6541 16.4211 19.5 16.4211H18.2C17.4824 16.4211 16.9 16.9869 16.9 17.6842C16.9 18.3815 17.4824 18.9474 18.2 18.9474H19.5C23.0893 18.9474 26 16.1192 26 12.6316C26 10.0257 24.3776 7.79117 22.0623 6.82612C21.6723 2.9937 18.3469 0 14.3 0C11.4088 0 8.8881 1.52717 7.5413 3.79328C3.3527 3.92591 9.53674e-07 7.26695 9.53674e-07 11.3684C9.53674e-07 15.5545 3.4918 18.9474 7.8 18.9474C8.5176 18.9474 9.1 18.3815 9.1 17.6842C9.1 16.9869 8.5176 16.4211 7.8 16.4211C4.9283 16.4211 2.6 14.1587 2.6 11.3684C2.6 8.5781 4.9283 6.31579 7.8 6.31579C7.9443 6.31579 8.086 6.32084 8.2264 6.33221C8.7815 6.37642 9.3041 6.072 9.5251 5.57558C10.3246 3.77937 12.1628 2.52632 14.3 2.52632ZM13.9191 9.21221C13.4121 8.71832 12.5879 8.71832 12.0809 9.21221L9.4809 11.7385C8.9726 12.2312 8.9726 13.032 9.4809 13.5246C9.9879 14.0185 10.8121 14.0185 11.3191 13.5246L11.7 13.1545V22.7368C11.7 23.4341 12.2824 24 13 24C13.7176 24 14.3 23.4341 14.3 22.7368V13.1545L14.6809 13.5246C15.1879 14.0185 16.0121 14.0185 16.5191 13.5246C17.0274 13.032 17.0274 12.2312 16.5191 11.7385L13.9191 9.21221Z" fill="#2D6CB2" fillOpacity="0.99" />
                                                            </svg>
                                                            <span className="marginLeft10">{A12Y_CONST.LABEL.UPLOAD}  {selectedFiles && Array.isArray(selectedFiles) && selectedFiles.length > 0 && 'more'} {this.props.screenFrom && this.props.screenFrom === A12Y_CONST.LABEL.MEDICATION ? A12Y_CONST.LABEL.COPY_OF_PRESCRIPTION : A12Y_CONST.LABEL.FILE} </span>
                                                        </div>
                                                        <span className="dropTxt">{A12Y_CONST.LABEL.DRAG_YOUR_FILE_OR_CLICK_HERE}</span>
                                                    </div>
                                                )}
                                            </div>
                                            {!isDragReject && <div className="dragTxt">{A12Y_CONST.LABEL.DROP_FILE_MESSAGE}</div>}
                                        </div>
                                    )}
                                </section>
                                {
                                    fileRejections.length > 0 && 
                                    <div className="rejected marginTop15">
                                        <h5 className="df fullWidth marginBottom10"><span className="uc">Rejected files:</span>  <b className="mla">{fileRejections[0].errors[0].message}</b></h5>
                                        {
                                            fileRejections.map((file, i) => {
                                                return (
                                                    <b key={i}>{i !== 0 ? ', ' : ''} {file.file.path}</b>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </>
                        )}
                    </Dropzone>
                </div>

                <section className="fcol fullWidth">
                    {
                        selectedFiles &&
                        Array.isArray(selectedFiles) &&
                        selectedFiles.length ? (
                            <div className="fileList attachedImgList fcol fullWidth">
                                {progressInfos &&
                                progressInfos.map((progressInfo, index) => (
                                    <div className="fileInner" key={index}>
                                        <div className="df fullWidth aic">
                                            <div className="fileType">
                                                {/*{progressInfo[index].fileName.split("/").pop()}*/}
                                                {progressInfo.fileName.split(".").pop().toLowerCase() === 'pdf' ? <img width="44" height="50" src={pdf} alt='logo' />: progressInfo.fileName.split(".").pop().toLowerCase() === 'jpg' || progressInfo.fileName.split(".").pop().toLowerCase() === 'jpeg'? <img width="44" height="50" src={jpg_icon} alt='logo' /> : <img width="44" height="50" src={jpg} alt='logo' />}
                                            </div>
                                            <div className='progressWrap'>
                                                <span className='uploadFileName'>{progressInfo.fileName}</span>
                                                {
                                                    progressInfo.isDone ?
                                                    (<div className="df fInfo">
                                                        {/*<span className="paddingRight16">{progressInfo?.upload_date ? this.dateFormatting(progressInfo.upload_date) : null}</span>
                                                        <span className="paddingRight16 fileDot"><svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3.5" r="3" fill="#565C65" /></svg></span>*/}
                                                        <span>{this.bytesForHuman(progressInfo.size)}</span>
                                                    </div>)
                                                    :
                                                    null
                                                }
                                                {
                                                    this.props.isSubmit && !progressInfo.isDone
                                                    ?
                                                    (<div className="progress fullWidth">
                                                        <span className="progressAmt">{progressInfo.percentage}%</span>
                                                        <div
                                                            className="progress-bar progress-bar-info"
                                                            role="progressbar"
                                                            aria-valuenow={progressInfo.percentage}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{ width: progressInfo.percentage + "%" }}
                                                        >
                                                        </div>
                                                    </div>)
                                                    :
                                                    null
                                                }
                                            </div>
                                            {

                                                <b id={A12Y_CONST.BUTTON_ID.DELETE_DOCUMENT+A12Y_CONST.BUTTON_ID.BUTTON+index} className={`mla marginTop10 removeFile cursorPointer ${this.props.isSubmit ? 'disabled' : ''}`} onClick={() => this.showFileRemovPopUp(index)}>
                                                    <svg width="20" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 2.76328C6 1.43776 7.07452 0.363281 8.4 0.363281H15.6C16.9255 0.363281 18 1.43776 18 2.76328V5.16328H20.3876C20.3951 5.16316 20.4025 5.16316 20.41 5.16328H22.8C23.4628 5.16328 24 5.70052 24 6.36328C24 7.02604 23.4628 7.56328 22.8 7.56328H21.5173L20.4766 22.1343C20.3868 23.3901 19.3418 24.3633 18.0827 24.3633H5.91734C4.65821 24.3633 3.61315 23.3901 3.52344 22.1343L2.48266 7.56328H1.2C0.537264 7.56328 0 7.02604 0 6.36328C0 5.70052 0.537264 5.16328 1.2 5.16328H3.59009C3.59754 5.16316 3.60497 5.16316 3.61238 5.16328H6V2.76328ZM8.4 5.16328H15.6V2.76328H8.4V5.16328ZM4.88878 7.56328L5.91734 21.9633H18.0827L19.1112 7.56328H4.88878ZM9.6 9.96328C10.2628 9.96328 10.8 10.5005 10.8 11.1633V18.3633C10.8 19.026 10.2628 19.5633 9.6 19.5633C8.93726 19.5633 8.4 19.026 8.4 18.3633V11.1633C8.4 10.5005 8.93726 9.96328 9.6 9.96328ZM14.4 9.96328C15.0628 9.96328 15.6 10.5005 15.6 11.1633V18.3633C15.6 19.026 15.0628 19.5633 14.4 19.5633C13.7372 19.5633 13.2 19.026 13.2 18.3633V11.1633C13.2 10.5005 13.7372 9.96328 14.4 9.96328Z" fill="#F2938C" />
                                                    </svg>
                                                </b>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : ''
                    }
                </section>

                {message.length > 0 && (
                    <div className="alert alert-danger marginTop10" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )}

                {fileInfos.length > 0 && (
                    <div className="card">
                        <div className="card-header">List of Files</div>
                        <ul className="list-group list-group-flush">
                            {fileInfos &&
                                fileInfos.map((file, index) => (
                                    <li className="list-group-item" key={index}>
                                        <a href={file.url}>{file.name}</a>
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}

                <PopUpIncomeExpComponent
                    popUpStatus={this.state.isPopUp}
                    title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ATTACHMENT}
                    message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                    buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                    buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                    hidePopUp={this.hidePopUp}
                    handleDeleteButton={this.handleDeleteButton}
                />
            </div>
        );

    }
}
