import React, { Component } from 'react'
import AddExpense from '../components/AddExpenseFormComponent'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import {secureClient} from "../config/axiosClient";
import axios from "axios";
import * as userDetailActions from "../redux/actions/userDetailActions";


class AddExpenseScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : true,
            expenseTypeList: [],
            loaderFullScreen:false,
            fileUploadingData:{},
            fromExpenseViewScreen:false
        }
    }

    componentDidMount = async() => {
        if(this.props.expenseTypeList.length === 0 || this.props.expenseTypeList[0] === undefined){
            await this.getExpenseTyp()
        }
        let state = { ...this.props.location.state };
        if(state?.fromExpenseView){
           await this.setState({fromExpenseViewScreen:true});
        }
    }
    getExpenseTyp = async () => {
        await this.props.getExpenseTypeList();
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,loaderFullScreen: false})
        }else {
            this.setState({message:this.props.successMessage,loaderFullScreen: false})
        }
    }



    handleOnSubmit = async (data, fileInfo, fileBase64Info) => {
        try{
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                await this.createExpense(data,null);
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    let attached_file = [];
                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();
                        let uploadDate = new Date();

                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                            "Content-Type" : fileInfo[i].contentType
                        },onUploadProgress:async data => { // eslint-disable-line
                            //Set the progress value to show the progress bar
                           // setProgress(Math.round((100 * data.loaded) / data.total))
                            let temp={data:data,index:i}
                           await this.setState({fileUploadingData:temp})
                        }})
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                file_id:fileInfo[i].file_id,
                                id:fileInfo[i].expense_id,
                                dependent_id:this.props.dependent.id,
                                type:'expense',
                                "name" : fileInfo[i].name,
                                "size" : fileInfo[i].size,
                                "upload_date" : uploadDate,
                                "contentType" : fileInfo[i].contentType
                            })
                            data.document_ref = {"attached_files":attached_file}
                        }
                        i++;
                    }
                    await this.createExpense(data,fileBase64Info);
                }else {
                }
            }

        }catch (e){
            console.error('error ......',e)
        }
    }

    onUpdateHandler = async(data, fileInfo, fileBase64Info,deletedFileInfo)=>{
        this.setState({error:false,loading:false})
        let attached_file = [];
        try{
            if(this.props.currentExpense && Object.keys(this.props.currentExpense).length > 0 && this.props.currentExpense.document_ref && Object.keys(this.props.currentExpense.document_ref).length > 0 && this.props.currentExpense.document_ref.attached_files.length > 0){
                let attached_files_list = this.props.currentExpense.document_ref.attached_files
                let arr =[];
                if(deletedFileInfo.length > 0){
                    for await (const item of deletedFileInfo) {
                        attached_files_list = attached_files_list.filter(item_1 => item_1.file_id !== item)
                            arr = attached_files_list
                    }
                    attached_file = arr;
                    data.document_ref = {"attached_files":attached_file}
                    data.s3_delete_keys = deletedFileInfo
                }else {
                    attached_file = attached_files_list
                    data.document_ref = {"attached_files":attached_file}
                }

            }
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                await this.updateExpense(data);
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    let progressIndex = data.document_ref && Object.keys(data.document_ref).length > 0 ? data.document_ref.attached_files.length > 0  ? data.document_ref.attached_files.length : 0 : 0;
                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();
                        let uploadDate = new Date();

                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                            "Content-Type" : fileInfo[i].contentType
                        },onUploadProgress:async data => { // eslint-disable-line
                            //Set the progress value to show the progress bar
                            // setProgress(Math.round((100 * data.loaded) / data.total))
                            let temp={data:data,index:progressIndex}
                            await this.setState({fileUploadingData:temp})
                        }})
                        // if(uploadResBody.statusText === 'OK'){
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                file_id:fileInfo[i].file_id,
                                id:fileInfo[i].expense_id,
                                dependent_id:this.props.dependent.id,
                                type:'expense',
                                "name" : fileInfo[i].name,
                                "size" : fileInfo[i].size,
                                "upload_date" : uploadDate,
                                "contentType" : fileInfo[i].contentType
                            })
                            data.document_ref = {"attached_files":attached_file}
                        }
                        i++;
                        progressIndex++
                    }
                    await this.updateExpense(data);
                }else {
                }
            }

        }catch (e){
            console.error('error ......',e)
        }

    }

    updateExpense = async(data)=>{
        try {
            if(this.props.currentExpense && Object.keys(this.props.currentExpense).length !== 0){
                let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.EXPENSES+"/"+this.props.currentExpense.id,data);
                this.setState({loading:false})
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    await this.props.currentExpenseData(response.data.sr.expense)

                    let activity = await JSON.parse(localStorage.getItem('activity'));
                    activity.expenses && activity.expenses.map((item,index) => {
                        if(item.id === response.data.sr.expense.id){
                            // item = response.data.sr.expense
                            activity.expenses[index] = response.data.sr.expense
                        }
                    })
                    await localStorage.setItem("activity", JSON.stringify(activity));

                    if(this.props.IsExpenseCreateFromActivityLog || (this.state.fromExpenseViewScreen && Object.keys(this.props.currentActivity).length > 0)){
                        let activityLogObj  = this.props.currentActivity
                        let expense = response.data.sr.expense
                        let index = activityLogObj.expenses.findIndex(item=>item.id === expense.id)
                        activityLogObj.expenses = activityLogObj.expenses.filter(item=>item.id !== expense.id)
                        activityLogObj.expenses.splice(index, 0, expense);
                        await this.props.currentActivityData(activityLogObj);
                    }else {
                        // this.props.history.push(A12Y_CONST.ROUTE.EXPENSES);
                        if(this.state.fromExpenseViewScreen){
                            this.props.history.push(A12Y_CONST.ROUTE.EXPENSE_VIEW);
                        }else{
                            this.props.history.push(A12Y_CONST.ROUTE.EXPENSES);
                        }
                    }
                    if(this.props.IsExpenseCreateFromActivityLog){
                        this.props.history.push(A12Y_CONST.ROUTE.ADD_ACTIVITY);
                        await this.props.expenseCreatingUsingActivityLog(false);
                    }
                    if(this.state.fromExpenseViewScreen){
                        this.props.history.push(A12Y_CONST.ROUTE.EXPENSE_VIEW);
                        await this.props.expenseCreatingUsingActivityLog(false);
                    }
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                    this.setState({loading:false})
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.UPDATE_EXPENSE,e)
        }

    }

    createExpense = async(data,attachment) =>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.EXPENSES, data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(!this.props.expense_present){
                    let dependent = this.props.dependent
                    dependent.metadata.expense_present = true
                    this.props.updateDependentProps(dependent)
                    this.props.updateMetaData(this.props.income_present,!this.props.expense_present,this.props.activity_present,this.props.asset_present)
                }
                if(this.props.IsExpenseCreateFromActivityLog || (this.state.fromExpenseViewScreen && Object.keys(this.props.currentActivity).length > 0)){
                    let activityLogObj  = this.props.currentActivity
                    let expense = response.data.sr.expense;
                    if(!activityLogObj.expense_ids) {
                        activityLogObj.expense_ids = [];
                    }
                    activityLogObj.expense_ids.push(expense.id)
                    activityLogObj.expenses.push(expense)
                    await this.props.currentActivityData(activityLogObj)
                }else {
                    this.props.history.push(A12Y_CONST.ROUTE.EXPENSES);
                }
                if(this.props.IsExpenseCreateFromActivityLog){
                    this.props.history.push(A12Y_CONST.ROUTE.ADD_ACTIVITY);
                    await this.props.expenseCreatingUsingActivityLog(false);
                }
                if(this.state.fromExpenseViewScreen){
                    this.props.history.push(A12Y_CONST.ROUTE.EXPENSE_VIEW);
                    await this.props.expenseCreatingUsingActivityLog(false);
                }
                this.setState({loading:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.CREATE_EXPENSE,e)
        }

    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    checkIsCommingFromActivityHandler = async ()=>{
        if(this.props.IsExpenseCreateFromActivityLog){
           await this.props.expenseCreatingUsingActivityLog(false)
        }
    }

    backToActivity = async () => {
        console.log('expnse')
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_ACTIVITY,
            state: {fromExpenseScrene:true }
        });
    }
    render(props) {
        const {fileUploadingData} = this.state;
        return (
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddExpense
                            // dependent={props.dependent}
                            loading={this.state.loading}
                            message={this.state.message}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            expenseTypeList={this.props.expenseTypeList}
                            onSubmit={this.handleOnSubmit}
                            loaderFullScreen={this.state.loaderFullScreen}
                            dependent={this.props.dependent}
                            expenseObj={this.props.currentExpense}
                            onUpdate={this.onUpdateHandler}
                            fileUploadingData={fileUploadingData}
                            fromActivity={this.props.IsExpenseCreateFromActivityLog}
                            currentActivity={this.props.currentActivity}
                            isDataChanged={this.dataChangeHandler}
                            checkIsCommingFromActivity={this.checkIsCommingFromActivityHandler}
                            isSessionTimeout={this.props.isSessionTimeout}
                            fromExpenseViewScreen={this.state.fromExpenseViewScreen}
                            IsExpenseCreateFromActivityLog={this.props.IsExpenseCreateFromActivityLog}
                            backToActivity={this.backToActivity}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    currentExpense : state.dependentReducer.currentExpense,
    IsExpenseCreateFromActivityLog : state.dependentReducer.IsExpenseCreateFromActivityLog,
    currentActivity: state.dependentReducer.currentActivity,
    expenseTypeList: state.dependentReducer.expenseTypeList,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    asset_present: state.dependentReducer.asset_present,
    isSessionTimeout: state.userDetailReducer.isSessionTimeout,
});

const mapDispatchToProps = dispatch => ({
    getAllDependent : (advocate_id) => dispatch(dependentActions.getAllDependent(advocate_id)),
    currentExpenseData : (expense) => dispatch(dependentActions.currentExpenseData(expense)),
    currentActivityData: (activity) => dispatch(dependentActions.currentActivityData(activity)),
    expenseCreatingUsingActivityLog: (status) => dispatch(dependentActions.expenseCreatingUsingActivityLog(status)),
    getExpenseTypeList: () => dispatch(dependentActions.getExpenseTypeList()),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});


export default connect(mapStateToProps, mapDispatchToProps) (AddExpenseScreen);
