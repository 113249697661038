import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Loading from '../components/LoadingComponent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoaderFullScreen from './LoaderFullScreenComponent'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import {currencyFormatting} from "../util/CurrencyFormater";
import UploadFiles from "./ImageUploadComponent";
import {v4 as uuidv4} from "uuid";
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';

function InsuranceStep1(props) {
    const [error, setErrors] = useState(false);
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const { register, handleSubmit, setFocus, getValues, setError,control,setValue, formState: {errors,isSubmitting } } = useForm({ mode: "all" });
    const history = useHistory();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [note, setNote] = useState('');
    const [policyName, setPolicyName] = useState('');
    const [policyNameError, setPolicyNameError] = useState(false);
    const [policyNameErrorMeesage, setPolicyNameErrorMeesage] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [policyNumberError, setPolicyNumberError] = useState(false);
    const [policyNumberErrorMeesage, setPolicyNumberErrorMeesage] = useState('');
    const [annualPremium, setAnnualPremium] = useState('');
    const [annualPremiumError, setAnnualPremiumError] = useState(false);
    const [annualPremiumErrorMeesage, setAnnualPremiumErrorMeesage] = useState('');
    const [policyType, setPolicyType] = useState('Term');
    const [termDate, setTermDate] = useState(new Date());
    const [termDateError, setTermDateError] = useState(false);
    const [termDateErrorMessage, setTermDateErrorMessage] = useState('');
    const [deathBenefits, setDeathBenefits] = useState('');
    const [deathBenefitsError, setDeathBenefitsError] = useState(false);
    const [deathBenefitsErrorMeesage, setDeathBenefitsErrorMeesage] = useState('');
    const [purchaseDate, setPurchaseDate] = useState(new Date());
    const [purchaseDateError, setPurchaseDateError] = useState(false);
    const [purchaseDateErrorMeesage, setPurchaseDateErrorMeesage] = useState('');
    const [cashValue, setCashValue] = useState('');
    const [cashValueError, setCashValueError] = useState(false);
    const [cashValueErrorMeesage, setCashValueErrorMeesage] = useState('');
    const [asOfDate, setAsOfDate] = useState(new Date());
    const [asOfDateError, setAsOfDateError] = useState(false);
    const [asOfDateErrorMeesage, setAsOfDateErrorMeesage] = useState('');
    //const [hideNote, setHideNote] = useState(false);
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const [isEditMode, setEditMode] = useState(false);
    const termDateRef = useRef(null);
    const purchaseDateRef = useRef(null);
    const asOfDateRef = useRef(null);
    const [linkToWebsite, setLinkToWebsite] = useState('');

    useEffect(() => {
        setFocus("policyName");
    }, [setFocus])

    const handlePolicyNameChange = (e) => {
        setPolicyName(e.target.value.trimStart())
    }
    const validatePolicyName = () => {
        if(policyName === '' && policyName.length === 0) {
            setPolicyNameError(true)
            setPolicyNameErrorMeesage(A12Y_CONST.ERROR_MESSAGE.POLICY_NAME_IS_REQUIRED);
            return true;
        }else{
            setPolicyNameError(false)
            setPolicyNameErrorMeesage('');
            return false;
        }
    }
    
    const handleAnnualPremiumChange = async (e) => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setAnnualPremium(amt);
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if(temp !== undefined){
                await setAnnualPremium(temp);
            }
        }
    }
    useEffect(  () => {
       const createUuid = async ()=>{
           if(props.currentAsset === undefined || (props.currentAsset && Object.keys(props.currentAsset).length === 0)){
               const uuid = await uuidv4();
               await setAssetId(uuid);
           }
       }
       createUuid()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setErrors(props.error)
         setLoading(props.loading);
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.error, props.loading, props.loaderFullScreen])


    const validateAnnualPremium = async () => {
        if(annualPremium === '' && annualPremium.length === 0) {
            setAnnualPremiumError(true)
            setAnnualPremiumErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_ANNUAL_PREMIUM);
            return true;
        }else{
            setAnnualPremiumError(false)
            setAnnualPremiumErrorMeesage('');
            return false;
        }
    }

    const handlePolicyNumberChange = async (e) => {
            await setPolicyNumber(e.target.value.trim())
    }
    const validatePolicyNumber = () => {
        if(policyNumber === '' && policyNumber.length === 0) {
            setPolicyNumberError(true)
            setPolicyNumberErrorMeesage(A12Y_CONST.ERROR_MESSAGE.POLICY_NUMBER_IS_REQUIRED);
            return true;
        }else{
            setPolicyNumberError(false)
            setPolicyNumberErrorMeesage('');
            return false;
        }
    }

    const handlePolicyTypeChange = (e) =>{
        setPolicyType(e.target.value)
    }

    useEffect( ()=>{
        const assignDefaultFormData = async ()=>{
            if(props.editMode){
                let obj = props.currentAsset
                // let temp = await currencyFormatting(amt);
                if(Object.keys(obj).length > 0){
                    setAssetId(obj.id)
                    let policyNumber = obj?.insurance && obj.insurance.policy_number ? obj.insurance.policy_number : ''
                    let policyType = obj?.insurance && obj.insurance.policy_type ? obj.insurance.policy_type : ''
                    let annualPremium = obj?.insurance && (obj.insurance.annual_premium || obj.insurance.annual_premium === 0) ? await currencyFormatting(obj.insurance.annual_premium) : ''
                    let deathBenefit = obj?.insurance && (obj.insurance.death_benefit_value || obj.insurance.death_benefit_value === 0) ? await currencyFormatting(obj.insurance.death_benefit_value) : ''
                    let assetVal = obj?.insurance && (obj.asset_value || obj.asset_value === 0) ? await currencyFormatting(obj.asset_value) : ''
                    let termDate = obj?.insurance && obj.insurance.term_date ? obj.insurance.term_date : ''
                    setPolicyName(obj.asset_name)
                    setPolicyNumber(policyNumber)
                    setPolicyType(policyType)
                    setAnnualPremium(annualPremium)
                    setDeathBenefits(deathBenefit)
                    setCashValue(assetVal)
                    setAsOfDate(new Date(obj.as_of_date))
                    setTermDate(new Date(termDate))
                    setPurchaseDate(new Date(obj.acquisition_date))
                    setNote(obj.note)
                    setLinkToWebsite(obj?.web_url)
                    setEditMode(true)
                }
            }
        }
        assignDefaultFormData()
    },[props.editMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkFormDataIsChanged = async ()=>{
           let asset = props.currentAsset
           setIsDataModified(false)
           setIsBtnDisabled(true)
           props.isDataChanged(false);
           if(isEditMode && Object.keys(asset).length > 0 && asset.hasOwnProperty('insurance')){
               setIsBtnDisabled(true)
               let annual_Premium = asset?.insurance && (asset.insurance.annual_premium || asset.insurance.annual_premium === 0) ? await currencyFormatting(asset.insurance.annual_premium) : ''
               let deathBenefit = asset?.insurance && (asset.insurance.death_benefit_value || asset.insurance.death_benefit_value === 0) ? await currencyFormatting(asset.insurance.death_benefit_value) : ''
             //  console.log('annualPremium !== annual_Premium || deathBenefits !== deathBenefit', annualPremium , annual_Premium , deathBenefits , deathBenefit)
               if(policyName !== asset.asset_name || policyNumber !== asset.insurance.policy_number || linkToWebsite !== asset.web_url || policyType !== asset.insurance.policy_type || await ddmmyyyyFormat(new Date(termDate)) !== asset.insurance.term_date || annualPremium !== annual_Premium || deathBenefits !== deathBenefit || cashValue !== await currencyFormatting(asset.asset_value) || await ddmmyyyyFormat(new Date(purchaseDate)) !== asset.acquisition_date || note !== asset.note || fileInfo.length > 0 || deletedFileInfo.length > 0 || await ddmmyyyyFormat(new Date(asOfDate)) !== asset.as_of_date){
                   if(policyName !== "" && policyNumber !== "" && termDate !== null && annualPremium !== "" && deathBenefits !== "" && cashValue !== "" && purchaseDate !== null && asOfDate !== null){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true);
               }
           }else {
               if(!props.editMode){
                   let today = await ddmmyyyyFormat(new Date())
                   if(policyName !== "" || policyNumber !== "" || linkToWebsite !== "" || policyType !== "Term" || await ddmmyyyyFormat(new Date(termDate)) !== today || annualPremium !== "" || deathBenefits !== "" || cashValue !== "" || await ddmmyyyyFormat(new Date(purchaseDate)) !== today || note !== "" || fileInfo.length > 0 || deletedFileInfo.length > 0 || await ddmmyyyyFormat(new Date(asOfDate)) !== today){
                       if(policyName !== "" && policyNumber !== "" && termDate !== null && annualPremium !== "" && deathBenefits !== "" && cashValue !== "" && purchaseDate !== null && asOfDate !== null){
                           setIsBtnDisabled(false)
                       }
                       setIsDataModified(true)
                       props.isDataChanged(true);
                   }
               }
           }
       }
        checkFormDataIsChanged();
    },[policyName, policyNumber, termDate, policyType, annualPremium,linkToWebsite, deathBenefits, cashValue, purchaseDate,isEditMode,note,fileInfo,deletedFileInfo,asOfDate]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (formData)=>{
       let isPolicyNameValid = await validatePolicyName()
        let isPolicyNumberValid = await validatePolicyNumber()
        let isAnnualPremiumValid =await validateAnnualPremium()
        let isDeathBenefitsValid =await validateDeathBenefits()
        let isCashValueValid =await validateCashValue()
        let obj = {
            asset_type_id:props.asset.asset_type_id,
            asset_name: policyName,
            policy_number: policyNumber,
            policy_type: policyType,
            annual_premium:annualPremium.length > 0 ? annualPremium.replaceAll(',','') : '',
            death_benefit_value:deathBenefits.length > 0 ? deathBenefits.replaceAll(',','') : '',
            term_date: await ddmmyyyyFormat(new Date(termDate)),
            acquisition_date: await ddmmyyyyFormat(new Date(purchaseDate)),
            asset_value:cashValue.length > 0 ? cashValue.replaceAll(',','') : '',
            as_of_date: await ddmmyyyyFormat(new Date(asOfDate)),
            note:note,
            //viewer_note_visibility:hideNote,
            status:'1',
            web_url : linkToWebsite,
        }

        let isDatesValid = !termDateError && !purchaseDateError && !asOfDateError

        if(!isPolicyNameValid && !isPolicyNumberValid && !isAnnualPremiumValid && !isDeathBenefitsValid && !isCashValueValid && isDatesValid){
           // console.log('insuranceData===obj: ' , obj);
            setIsSubmit(true)
            setLoading(true);
            props.isDataChanged(false)
            if(props.editMode){
                obj.id = props.currentAsset.insurance.id;
                obj.asset_type_id = props.currentAsset.asset_type_id
                props.onUpdate(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }else {
                obj.id = assetId;
                props.onSubmit(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }
        }
    }

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            if(props.editMode){
               props.onCancel()
            }else {
                history.push(A12Y_CONST.ROUTE.INVENTORY);
            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        if(props.editMode){
            props.onCancel()
        }else {
            history.push(A12Y_CONST.ROUTE.INVENTORY);
        }
    }

    const handleCashValueChange = async (e) => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setCashValue(amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setCashValue(temp)
            }
        }
    }
    const validateCashValue = () => {
        if(cashValue === '' && cashValue.length === 0) {
            setCashValueError(true)
            setCashValueErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_CASH_VALUE);
            return true;
        }else{
            setCashValueError(false)
            setCashValueErrorMeesage('');
            return false;
        }
    }

    const handleDeathBenefitsChange = async (e) => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setDeathBenefits(amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setDeathBenefits(temp)
            }
        }
    }
    const validateDeathBenefits = () => {
        if(deathBenefits === '' && deathBenefits.length === 0) {
            setDeathBenefitsError(true)
            setDeathBenefitsErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_DEATH_BENEFIT);
            return true;
        }else{
            setDeathBenefitsError(false)
            setDeathBenefitsErrorMeesage('');
            return false;
        }
    }
    
    const handleNoteChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setNote(e.target.value);
            setValue('note',e.target.value)
        }
    }

    /*const handleNoteHideChange = (e) => {
        setHideNote(e.target.checked);
        setValue('noteHide', e.target.checked)
    }*/

    const filesDetail_FromChild = (e,deletedFiles) => {
        if(assetId){
            let fileList = [];
            let base64List = [];
            for (var i = 0; i < e.length; i++) {
                if(!e[i].file_id){
                    let name = e[i].displayName
                    let size = e[i].size
                    let file = e[i];
                    let fileName = e[i].name;
                    let reader = new FileReader();
                    const uuid = uuidv4();
                    let s3key = `${props.dependent.id}/asset/${assetId}/${uuid}`
                    reader.readAsDataURL(file);
                    reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                    );
                    fileList.push({
                        s3_key: s3key,
                        file_id:uuid,
                        asset_id:assetId,
                        'contentType': e[i].type,
                        'fileMetadata': {
                            "about_dependent": "some more info"
                        },
                        'name' : name,
                        'size' : size,
                        'fileName': fileName
                    });
                }
            }
            setFileInfo(fileList);
            setFileBase64Info(base64List)
            setDeletedFileInfo(deletedFiles)
        }
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleAsOfDateChange = () => {
        if(asOfDate != null){
            let isValidObj = validateDate(asOfDate, 100, 0)
            setAsOfDateError(isValidObj.isErr)
            setAsOfDateErrorMeesage(isValidObj.errMsg)
        }
        else{
            setAsOfDateError(true)
            setAsOfDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    const handlePurchaseDateChange = () => {
        if(purchaseDate != null){
            let isValidObj = validateDate(purchaseDate, 100, 0)
            setPurchaseDateError(isValidObj.isErr)
            setPurchaseDateErrorMeesage(isValidObj.errMsg)
            
        }else{
            setPurchaseDateError(true)
            setPurchaseDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }

    const handleTermDateChange = () => {
        if(termDate != null){
            let isValidObj = validateDate(termDate, 100, '')
            setTermDateError(isValidObj.isErr)
            setTermDateErrorMessage(isValidObj.errMsg)
        }else{
            setTermDateError(true)
            setTermDateErrorMessage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }
    return (
            <div className="df fcol marginBottom20 inventoryResponsive height100">
                {loaderFullScreenVisible ?
                    <LoaderFullScreen />
                    :
                    null
                }
                {loading ?
                    <div className="transparentLayer"></div>
                    :
                    null
                }
                <h4 className="dependentSubTitle marginBottom10"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'> {props.apostrophe ? A12Y_CONST.LABEL.APOSTROPHE_S : ''} {props.title} </span></h4>
                <div className="fullWidth">
                    {error ?
                        <div className='maxWidth600 marginLeftRightAuto'>
                            <ErrorMessageComponent errorMessage={props.errorMessage}/>
                        </div>
                        :
                        (props.message ?
                                <SuccessMessageComponent successMessage={props.message}/>

                                :
                                null
                        )

                    }
                </div>
                <form autoComplete="off" className='marginTop60 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow fullWidth marginBottom30">
                        <label className="lblExpType">{A12Y_CONST.LABEL.NAME}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <input 
                                {...register("policyName")} 
                                value={policyName}
                                id="policyName"
                                maxLength='255'
                                onBlur={validatePolicyName}
                                onChange={handlePolicyNameChange}
                                onFocus={()=>setPolicyNameErrorMeesage(false)}
                                className={`fullWidth inputColum24size ${policyNameError && 'errorInputBorderRed'}`}
                                placeholder="Enter Policy Name" 
                            />
                            <div className='errorRelativeRow'>{policyNameError? <p className='errorMessage'>{policyNameErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom30">
                        <label className="lblExpType">{A12Y_CONST.LABEL.POLICY_NUMBER}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <input
                                {...register("policyNumber")}
                                type='text'
                                id="policyNumber"
                                maxLength="50"
                                onChange={handlePolicyNumberChange}
                                value={policyNumber}
                                onBlur={validatePolicyNumber}
                                onFocus={()=>setPolicyNumberErrorMeesage(false)}
                                className={`fullWidth inputColum24size ${policyNumberError && 'errorInputBorderRed'}`}
                                placeholder="&nbsp;"
                            />
                            <div className='errorRelativeRow'>{policyNumberError? <p className='errorMessage'>{policyNumberErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom30">
                        <label className="lblExpType marginTop5">{A12Y_CONST.LABEL.INSURANCE_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <div className="fullWidth insuranceTtyp600 marginTop10">
                                <label className='static lblInsType'>
                                    <input type="radio"
                                           onChange={handlePolicyTypeChange}
                                           name='policyType'
                                           checked={policyType === 'Term'}
                                           value='Term' />
                                    <span className='paddingLeft1'>{A12Y_CONST.LABEL.TERM}</span>
                                </label>
                                <label className='static lblInsType'>
                                    <input type="radio"
                                           onChange={handlePolicyTypeChange}
                                           name='policyType'
                                           checked={policyType === 'Whole'}
                                           value='Whole' />
                                    <span className='paddingLeft1'>{A12Y_CONST.LABEL.WHOLE}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom30 payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.ANNUAL_PREMIUM}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={getValues('annualPremium') !== '' || annualPremium.length < 1? "currency" : "currency currencyColorChnage" }>$</span>
                                <input
                                    {...register("annualPremium")}
                                    value={annualPremium}
                                    id="annualPremium"
                                    type='text' min='0' inputMode='decimal'
                                    onWheel={e => e.target.blur()}
                                    maxLength='50'
                                    onBlur={validateAnnualPremium}
                                    onChange={handleAnnualPremiumChange}
                                    onFocus={()=>setAnnualPremiumErrorMeesage(false)}
                                    className={`fullWidth txtAmount inputColum24size ${annualPremiumError && 'errorInputBorderRed'}`}
                                    placeholder="0,000.00"
                                />
                                <div className='errorRelativeRow'>{annualPremiumError? <p className='errorMessage'>{annualPremiumErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 positionRelative">
                            <label>{A12Y_CONST.LABEL.TERM_DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <Controller
                                    name="termDate"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) => 
                                        <DatePicker
                                            {...field}
                                            id={A12Y_CONST.BUTTON_ID.TERM_DATE}
                                            ref={termDateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={termDate}
                                            onChange={date => setTermDate(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'termDate')}
                                            onBlur={handleTermDateChange}
                                            onCalendarClose={handleTermDateChange}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, termDateRef)}
                                            onFocus={(e)=> {setTermDateError(false); readOnlyOnMobile(e.target)}}
                                            // minDate={subYears(new Date(), 100)}
                                            // maxDate={addYears(new Date(), 0)}
                                            className={!termDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>{termDateError? <p className='errorMessage'>{termDateErrorMessage}</p>: null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom30 payRow">
                        <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.DEATH_BENEFIT}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={getValues('deathBenefits') !== '' || deathBenefits.length < 1? "currency" : "currency currencyColorChnage" }>$</span>
                                <input 
                                    {...register("deathBenefits")} 
                                    value={deathBenefits}
                                    id="deathBenefits"
                                    type='text' min='0' inputMode='decimal'
                                    onWheel={e => e.target.blur()}
                                    maxLength='50'
                                    onBlur={validateDeathBenefits}
                                    onChange={handleDeathBenefitsChange}
                                    onFocus={()=>setDeathBenefitsError(false)}
                                    className={`fullWidth txtAmount inputColum24size ${deathBenefitsError && 'errorInputBorderRed'}`}
                                    placeholder="0,000.00" 
                                />
                                <div className='errorRelativeRow'>{deathBenefitsError? <p className='errorMessage'>{deathBenefitsErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 positionRelative">
                            <label>{A12Y_CONST.LABEL.PURCHASE_DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <Controller
                                    name="purchaseDate"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) => 
                                        <DatePicker
                                            {...field}
                                            id={A12Y_CONST.BUTTON_ID.PURCHASE_DATE}
                                            ref={purchaseDateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={purchaseDate}
                                            onChange={(date) => setPurchaseDate(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'purchaseDate')}
                                            onBlur={handlePurchaseDateChange}
                                            onCalendarClose={handlePurchaseDateChange}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, purchaseDateRef)}
                                            onFocus={(e)=> {setPurchaseDateError(false); readOnlyOnMobile(e.target)}}
                                            className={!purchaseDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>{purchaseDateError? <p className='errorMessage'>{purchaseDateErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom40 payRow">
                        <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.CASH_VALUE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={getValues('annualPremium') !== '' || cashValue.length < 1? "currency" : "currency currencyColorChnage" }>$</span>
                                <input 
                                    {...register("cashValue")} 
                                    value={cashValue}
                                    id="cashValue"
                                    type='text' min='0' inputMode='decimal'
                                    onWheel={e => e.target.blur()}
                                    maxLength='50'
                                    onBlur={validateCashValue}
                                    onChange={handleCashValueChange}
                                    onFocus={()=>setCashValueErrorMeesage(false)}
                                    className={`fullWidth txtAmount inputColum24size ${cashValueError && 'errorInputBorderRed'}`}
                                    placeholder="0,000.00" 
                                />
                                <div className='errorRelativeRow'>{cashValueError? <p className='errorMessage'>{cashValueErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 positionRelative">
                            <label>{A12Y_CONST.LABEL.AS_OF_DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <Controller
                                    name="asOfDate"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) => 
                                        <DatePicker
                                            {...field}
                                            id={A12Y_CONST.BUTTON_ID.AS_OF_DATE}
                                            ref={asOfDateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={asOfDate}
                                            onChange={(date) => setAsOfDate(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'asOfDate')}
                                            onBlur={handleAsOfDateChange}
                                            onCalendarClose={handleAsOfDateChange}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, asOfDateRef)}
                                            onFocus={(e)=> {setAsOfDateError(false); readOnlyOnMobile(e.target)}}
                                            // minDate={subYears(new Date(), 100)}
                                            // maxDate={addYears(new Date(), 0)}
                                            className={!asOfDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>{asOfDateError? <p className='errorMessage'>{asOfDateErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom40 payRow">
                        <div className="width50 paddingRight16 positionRelative">
                                <label>{A12Y_CONST.LABEL.LINK_URL}</label>
                                <div className='fullWidth doctorRowMarginB_600'>
                                    <input
                                        {...register('linkToWebsite',{
                                            pattern:{
                                                //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                                value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                                message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                            }
                                        })}
                                        placeholder='www.example.com'
                                        className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                        type='text'
                                        id='displayName'
                                        autoComplete="none"
                                        onChange={(e) => handleLinkToWebsite(e.target.value)}
                                        value={linkToWebsite}
                                        onFocus={()=>{
                                            setError("linkToWebsite", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                                </div>
                        </div>
                    </div>
                    {!props.editMode ?
                    <div className="formRow fullWidth marginBottom30 df fcol">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className="fullWidth">
                            <textarea
                                {...register("note")}
                                rows="3"
                                onChange={handleNoteChange}
                                maxLength='4000'
                                value={note}
                                className="fullWidth inputColum24size txtNote"
                                placeholder="Capture details about this life insurance policy."
                            ></textarea>

                            {/*<div className="fullWidth hideNote positionRelative">*/}
                            {/*    <label className="lblExpType">{A12Y_CONST.LABEL.HIDE_NOTE_FROM_VIEWER}</label>*/}
                            {/*    <div className="fullWidth marginTop5 df aic">*/}
                            {/*        <Controller*/}
                            {/*            name="noteHide"*/}
                            {/*            control={control}*/}
                            {/*            render={({ field }) =>*/}
                            {/*                <Switch*/}
                            {/*                    checked={hideNote}*/}
                            {/*                    onChange={handleNoteHideChange}*/}
                            {/*                    color="primary"*/}
                            {/*                />*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*        {hideNote && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}*/}
                            {/*        {!hideNote &&  <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                        :
                        null
                    }
                    <div className="formRow fullWidth marginBottom30 df fcol">
                        <label>{A12Y_CONST.LABEL.ATTACHMENT}</label>
                        <div className="fullWidth">
                            <div className="formRow fullWidth formRowGap uploadWrap">
                                <div className="lblUpload">
                                    <UploadFiles
                                        fileDetailSendToParent={filesDetail_FromChild}
                                        fileUploadingData={props.fileUploadingData}
                                        isSubmit={isSubmit}
                                        editMode={props.editMode}
                                        obj={props.currentAsset}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                {/*------------------ Button Actions ---------------------*/}
                <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom buttonRow600">
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.INSURANCE_STEP1_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            { loading ? <Loading /> : null }
                            <input id={A12Y_CONST.BUTTON_ID.INSURANCE_STEP1+props.BtnTxt} className={isBtnDisabled || loading ? "saveButtonDisable" : 'saveButton'} disabled={isBtnDisabled || loading} type="submit" value={props.BtnTxt} />
                        </div>
                    </div>
                </div>
            </form>
                <EditChangesRemovePopup
                    popUpStatus={isPopUpVisible}
                    hidePopUp={handleHidePopup}
                    resetAllFields={handleResetAllFields}
                />
        </div>
    )
}

export default InsuranceStep1
