import React, {useEffect, useState, useRef} from 'react';
import { useForm } from 'react-hook-form';
import exclamationImg from '../assets/images/exclamationErrorIcon.svg'
// import {Check } from '@material-ui/icons';
import Loading from './LoadingComponent'
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import A12Y_CONST from '../common/a12yConst'
import LoaderFullScreen from './LoaderFullScreenComponent'


function SignUpEmailComponent (props) {
    const signUpboxRef = useRef(null)
    const [userName, setUserName] = useState('');
    const [error, setErrors] = useState(false);
    const [isUserInvite, setIsUserInvite] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);

    // const [isBoxHeightHeigher, setiIsBoxHeightHeigher] = useState(false);
    // const [signUpBoxHeight, setSignUpBoxHeight] = useState(0);
    // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const {register,handleSubmit, setError, setValue ,formState:{ errors},} = useForm({mode:"all"});

    const onSubmit = async (user) => {
        let obj = {
            "email" : user.username,
        }
        await props.onSubmit(obj)

        // await setIsBtnDisabled(false)
    };

    // useEffect(  () => {
    //     async function dimensionCalculate() {
    //         let screenHeight = windowDimensions.height;
    //         let boxHeight = signUpboxRef.current.clientHeight + 110
    //         await setSignUpBoxHeight(signUpboxRef.current.clientHeight + 110)
    //         if(windowDimensions.width >= 951) {
    //             if (boxHeight > screenHeight) {
    //                 setiIsBoxHeightHeigher(true);
    //             } else {
    //                 setiIsBoxHeightHeigher(false);
    //             }
    //         }
    //     }
    //     dimensionCalculate()
    // })

    useEffect(()=>{
        if(userName){
            setValue('username',userName)
        }
    },[userName]) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect( () => {
        setLoading(props.loading)
        setErrors(props.error)
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.loading,props.error,props.loaderFullScreen])

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
        setLoaderFullScreenVisible(false)
    },[])
    useEffect(() => {
        if(props.inviteUserInfo && Object.keys(props.inviteUserInfo).length > 0){
            setIsUserInvite(true);
            setInviteUserInfo()
        }
        setLoaderFullScreenVisible(false)
    },[props.inviteUserInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    const setInviteUserInfo = () => {
        setValue("username",props.inviteUserInfo.userEmail)
        setUserName(props.inviteUserInfo.userEmail);
    }

    const goToSignInScreen = () => {
        props.history.push(A12Y_CONST.ROUTE.LOGIN);
    }

    // const handeBlankSpace = (e) => {
    //     if(e.key === " "){
    //         e.preventDefault();
    //     }
    // }

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const email = params.get('email');

        if(email){
            setValue("username",email)
            setUserName(email)
        }

    },[]) // eslint-disable-line react-hooks/exhaustive-deps
    const handleUserName = (value) => {
        let val = value.toLowerCase();
        if(val.length === 1 && val === " "){
            return
        }else{
            setUserName(val);
        }
    }
    const handleGoogleLogin = () => {
        window.location.href=props.url;
    }

    return (
        <div>
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            { loaderFullScreenVisible ?
                <LoaderFullScreen/>
                :
                null
            }

            <div ref={signUpboxRef} className={isUserInvite ? 'signColCenter signColCenterInviteUser fullWidth' : 'signColCenter paddingLeftPaddingRight35 fullWidth'}>
                <h3 className={isUserInvite? 'titleWelcomeToAccount marginNone texAlingCener' : 'marginNone titleWelcomeToAccount'}>{A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD}</h3>
                {error ?
                    <div className="positionRelative fullWidth">
                        <div className="errorMessageWithBackground positionAbsoute">
                            <div className="exclamationErrorIcon"><img alt='' src={exclamationImg}/></div>
                            <div className='errorTextContent'>{props.errorMessage}</div>
                        </div>
                    </div>
                    :
                    <> {isUserInvite ?
                        <div className="positionRelative fullWidth">
                            <div className="positionAbsoute">
                                <div className='infoBoxInviteUser'>
                                <span className='infoIconInviteUser'>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 12C15.5817 12 12 15.582 12 20C12 24.418 15.5817 28 20 28C24.4183 28 28 24.418 28 20C28 15.582 24.4183 12 20 12ZM10 20C10 14.477 14.4771 10 20 10C25.5228 10 30 14.477 30 20C30 25.523 25.5228 30 20 30C14.4771 30 10 25.523 10 20Z" fill="#71767A"/>
                                        <path d="M20 18C20.5523 18 21 18.448 21 19V25C21 25.552 20.5523 26 20 26C19.4477 26 19 25.552 19 25V19C19 18.448 19.4477 18 20 18Z" fill="#71767A"/>
                                        <path d="M21.5 15.5C21.5 16.328 20.8284 17 20 17C19.1716 17 18.5 16.328 18.5 15.5C18.5 14.672 19.1716 14 20 14C20.8284 14 21.5 14.672 21.5 15.5Z" fill="#71767A"/>
                                    </svg>
                                </span>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    </>
                }

                <form autoComplete="off" className={'marginTop30 fullWidth signUpFlow'}>
                   <div className='fullWidth marginBottomInputCol responsive6000LeftRight signUpPasswordResponsive'>
                        <label>{A12Y_CONST.LABEL.EMAIL}</label>
                        <input
                            {...register('username', { required: A12Y_CONST.ERROR_MESSAGE.EMAIL_REQUIRED , pattern:{
                                    //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                    //eslint-disable-next-line
                                    value:/^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_USERNAME_INVALID
                                }})}
                            placeholder='Email'
                            className={errors.username ? 'inputColum errorInputBorderRed': 'inputColum'}
                            type='text'
                            id='userEmail'
                            readOnly={Object.keys(props.inviteUserInfo).length > 0}
                            onChange={(e) => handleUserName(e.target.value)}
                            //autoCompleteType="none"
                            value={userName}
                            onFocus={()=>{
                                setError("username", {
                                    type: "manual",
                                    message: "",
                                });
                            }}
                        />
                        <div className='errorRelativeRow'>{errors.username && <p className='errorMessage'>{errors.username.message}</p>}</div>
                    </div>
                    <div className={isUserInvite?'fullWidth texAlingCener':'fullWidth texAlingCener'}>
                        <div className='loaderSpinner8radius'>
                            {loading ?
                                <Loading/>
                                :
                                null
                            }
                            <input id={A12Y_CONST.BUTTON_ID.EMAILSIGNUPBUTTON} className={loading ? "buttonDisable" :'button'} disabled={loading}  type="button" onClick={handleSubmit(onSubmit)} value={!loading ? A12Y_CONST.BUTTON_TEXT.SIGNUP_SIGNUP : A12Y_CONST.BUTTON_TEXT.SIGNUP_SIGNINGUP}/>
                        </div>
                    </div>
                    <div className="fullWidth paddingleft16 marginTop20 marginBottom20 paddingRight16">
                        <div className="fullWidth orButton positionRelative texAlingCener">
                            <span>Or</span>
                        </div>
                    </div>
                    <div onClick={handleGoogleLogin} id={A12Y_CONST.BUTTON_ID.GOOGLE_SIGNUP_BUTTON} className="fullWidth paddingleft16 paddingRight16 marginTop10">
                        <div className="fullWidth googleLoginBUtton">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.6902 13.2434C25.6902 12.1781 25.6037 11.4008 25.4167 10.5947H13.2531V15.4025H20.3928C20.2489 16.5974 19.4716 18.3967 17.7442 19.6058L17.72 19.7668L21.5659 22.7462L21.8324 22.7727C24.2794 20.5127 25.6902 17.1875 25.6902 13.2434" fill="#4285F4"/>
                                <path d="M13.253 25.9106C16.7509 25.9106 19.6874 24.759 21.8323 22.7726L17.7442 19.6056C16.6502 20.3686 15.1819 20.9012 13.253 20.9012C9.82709 20.9012 6.91936 18.6412 5.88283 15.5176L5.7309 15.5305L1.73186 18.6254L1.67957 18.7708C3.80998 23.0028 8.18602 25.9106 13.253 25.9106Z" fill="#34A853"/>
                                <path d="M5.88284 15.5175C5.60934 14.7114 5.45106 13.8477 5.45106 12.9552C5.45106 12.0627 5.60934 11.1991 5.86845 10.393L5.8612 10.2213L1.81205 7.07666L1.67957 7.13968C0.801523 8.89587 0.297699 10.868 0.297699 12.9552C0.297699 15.0425 0.801523 17.0145 1.67957 18.7707L5.88284 15.5175" fill="#FBBC05"/>
                                <path d="M13.253 5.00937C15.6857 5.00937 17.3267 6.06018 18.2624 6.93833L21.9187 3.36839C19.6732 1.28115 16.7509 0 13.253 0C8.18602 0 3.80998 2.90773 1.67957 7.13978L5.86844 10.3931C6.91936 7.26938 9.82709 5.00937 13.253 5.00937" fill="#EB4335"/>
                            </svg>
                            <span>{A12Y_CONST.BUTTON_TEXT.JOIN_VIA_GOOGLE}</span>
                        </div>
                    </div>
                </form>
                <div id={A12Y_CONST.BUTTON_ID.ALREADY_HAS_ACCOUNT} className='joinNowText fullWidth marginTop60'>
                    {A12Y_CONST.LABEL.SINGUP_ALREADY_HAVE} <span onClick={goToSignInScreen}>{A12Y_CONST.LABEL.SINGUP_SINGIN}</span>
                </div>
            </div>


        </div>

    );
}


export default withRouter(connect(null,null) (SignUpEmailComponent));
