import  React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Loading from './LoadingComponent';
import LoaderFullScreen from './LoaderFullScreenComponent'
import ErrorMessageComponent from './ErrorMessageComponent'
import SuccessMessageComponent from './SuccessMessageComponent'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import PopUpComponent from './PopUpComponent';
import {hideOnBlur, readOnlyMobile} from "../util/inputKeyboardHide";

function AddActivity(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activityType, setActivityType] = useState({});
    const [dateActivity, setDateActivity] = useState(new Date());
    const [tag, setTag] = useState(0);
    /*const [duration, setDuration] = useState({
        hours: 0,
        minutes: 0
    });*/
    const [durationText, setDurationText] = useState('00:00');
    const [note, setNote] = useState('');
    const [activityTypeOptionsList, setActivityTypeOptionsList] = useState([]);
    const [activityTypeError, setActivityTypeError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [dateErrorMeesage, setDateErrorMeesage] = useState('');
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [editMode, setEditMode] = useState(false);
    // const [isDurationVisible, setIsDurationVisible] = useState(false);
    const [expenseList, setExpenseList] = useState([]);
    const { register, handleSubmit, setError,setValue, control, formState: {errors, isSubmitting } } = useForm({ mode: "all" });
    const [deletedExpenseInfo, setDeletedExpenseInfo] = useState([]);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const dateRef = useRef(null);
    const [delExpense, setDelExpense] = useState({});
    const [deleteExpenseIndex, setDeleteExpenseIndex] = useState(null);
    const [isPopUp, setIsPopUp] = useState(false);
    const [popUpType, setPopUpType] = useState('');
    const [linkToWebsite, setLinkToWebsite] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionErrorMeesage, setDescriptionErrorMeesage] = useState('');

    function goBack() {
        props.onCancel(deletedExpenseInfo);
    }

    useEffect( () => {
        let activity = props.activityObj;
        async function assiginDefaultValue() {
            if (activity && Object.keys(activity).length > 0) {
                // setValue("duration",activity.duration)
                if (props.activityTypeList.length > 0) {
                    let list = await props.activityTypeList.filter(item => item.id === activity.activity_type_id)
                    if (list && list.length > 0) {
                        setActivityType(list[0])
                        // setValue("activityType",list[0])
                    }
                }
                // setDuration(activity.activity_duration)
                if (activity.activity_duration) {
                    let arr = activity.activity_duration.split(':')
                    setDurationText(arr[0] + ':' + arr[1])
                    setHours(arr[0])
                    setMinutes(arr[1])
                    // handleInitiaDuration({hours: arr[0], minutes: arr[1]})
                }
                setTag(activity.tag)
                setDescription(activity.descriptions)
                setLinkToWebsite(activity?.web_url)
                setNote(activity.note)
                if(activity.date_of_activity !== null){
                    setDateActivity( new Date(activity.date_of_activity))
                }else {
                    setDateActivity( null)
                }
                // if (activity.date_of_activity) {
                //     let date = await moment(activity.date_of_activity).format("MM / DD / YYYY")
                //     setDateActivity(date)
                // }
                if (activity.expenses && activity.expenses.length > 0) {
                    setExpenseList(activity.expenses)
                }
                await setEditMode(activity.id ? true : false)
            }
            else {
                //setDateActivity(new Date())
            }
        }

        assiginDefaultValue();

    }, [props.activityTypeList, props.activityObj])

    useEffect(()=>{
        if(dateActivity !== null){
            let today = new Date().getTime()
            let selectedDate = dateActivity.getTime()
            if(today < selectedDate){
                handleDateChange()
            }
        }
    },[dateActivity]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{

        const checkFormDataIsUpdated = async ()=>{
            if(editMode){
                setIsBtnDisabled(true)
                setIsDataModified(false)
                props.isDataChanged(false)
                let isDataModified = false;
                let activityObj = props.activityObj;
                let activity = await JSON.parse(localStorage.getItem('activity'));
                if(activity.tag !== tag || activity.activity_duration !== durationText || activity.descriptions !== description ||  activity.note !== note || activityObj.web_url !== linkToWebsite || await ddmmyyyyFormat(activity.date_of_activity) !==  await ddmmyyyyFormat(new Date(dateActivity))|| (activityType && Object.keys(activityType).length > 0 && activity.activity_type_id !== activityType.id) ){
                    if((Object.keys(activityType).length > 0 && dateActivity !== null && description !== "")){
                        setIsBtnDisabled(false)
                    }
                    props.isDataChanged(true)
                    setIsDataModified(true)
                }
                if((activity.expense_ids && activity.expense_ids.length > 0) || (activityObj.expense_ids && activityObj.expense_ids.length > 0)){
                    activityObj.expense_ids.forEach( (id,key)=>{
                        let temp =  activity?.expense_ids?.indexOf(id);
                        if(temp === -1){
                            isDataModified = true
                        }
                    })
                    deletedExpenseInfo.forEach( (item,key)=>{
                        let temp_1 = activity.expense_ids.indexOf(item.value.id)
                        if(temp_1 !== -1){
                            isDataModified = true
                        }
                    })
                    if(isDataModified){
                        props.isDataChanged(true)
                        setIsDataModified(true)
                        if(Object.keys(activityType).length > 0 && dateActivity !== null && description !== ""){
                            setIsBtnDisabled(false)
                        }
                    }
                }
            }else {
                props.isDataChanged(false)
                setIsBtnDisabled(true)
                let activityObj = props.activityObj
                let checkIsEditMode = activityObj?.id ? true : false
                if(!checkIsEditMode){
                    setIsBtnDisabled(true)
                    setIsDataModified(false)
                    let expDate = dateActivity !== null ? await ddmmyyyyFormat(dateActivity) : null
                    let today = await moment(new Date()).format("MM/DD/YYYY")
                    if(Object.keys(activityObj).length > 0){
                        if(activityObj.web_url !== linkToWebsite || activityObj.tag !== tag || activityObj.activity_duration !== durationText  || activityObj.note !==  note || activityObj.date_of_activity !== expDate || /*(activityType && Object.keys(activityType).length > 0 && activityObj.activity_type_id !== activityType.id)*/ (activityType && Object.keys(activityType).length > 0 )|| activityObj.expense_ids.length > 0 ){
                            if(Object.keys(activityType).length > 0 && dateActivity !== null && description !== ''){
                                setIsBtnDisabled(false)
                            }
                            props.isDataChanged(true)
                           await setIsDataModified(true)
                        }
                    }else {
                        if(tag !== 0 || (durationText !== '00:00' && durationText !== "") || description !== "" || note !==  "" || linkToWebsite !== '' || expDate !== today || (activityType && Object.keys(activityType).length > 0 && activityObj.activity_type_id !== activityType.id) ){
                            if(Object.keys(activityType).length > 0 && dateActivity !== null && description !== ""){
                                setIsBtnDisabled(false)
                            }
                            props.isDataChanged(true)
                            setIsDataModified(true)
                        }
                    }
                }
            }
        }

        checkFormDataIsUpdated();


    },[activityType,dateActivity,tag,durationText,note,deletedExpenseInfo, description, expenseList,editMode, linkToWebsite]) // eslint-disable-line react-hooks/exhaustive-deps

    function onCancel() {
        // history.goBack();
        if(isDataModified){
            setIsPopUpVisible(true);
        }else {
             props.onCancel(deletedExpenseInfo);
        }
    }

    const handleActivityTypeChange = async (e) => {
        // const evt = e.target.value;
       await setActivityType(e)
       await setActivityTypeError(false)
    }

    function validateActivityType(type) {
        if (type === 'blur') {
            if (activityType && Object.keys(activityType).length === 0) {
                setActivityTypeError(true)
            }
        } else {
            setActivityTypeError(false)
        }
    }

    function handleNoteChange(e) {
        if(e.target.value === " "){
            e.preventDefault();
        }else {
            setNote(e.target.value);
        }
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleDateChange = () => {
        if(dateActivity != null){
            let isValidObj = validateDate(dateActivity, 100, 0)

            setDateError(isValidObj.isErr)
            setDateErrorMeesage(isValidObj.errMsg)
        }else{
            setDateError(true)
            setDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    
    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.error, props.loading, props.loaderFullScreen])

    useEffect( () => {
        async function setActivityTypeList() {
            var activityTypeList = await props.activityTypeList.map(item => ({ label: item.title, value: item.title, id: item.id }));
            setActivityTypeOptionsList(activityTypeList)
        }
        setActivityTypeList();

    }, [props.activityTypeList])
    
    const validateHours = async () => {
        if (hours.length === 1) {
            await setHours('0' + hours)
        }
    }

    const handleHourChange = async (e) => {
        let hrs = e.target.value;
        let hr='';
        if(hrs === " " || hrs.length > 2 || hrs > 23){
            e.preventDefault();
        }else {
            if(hrs.split('')[0] > 2){
                hr = '0' + hrs
            }else{
                hr = hrs;
            }
            await setHours(hr)
        }
        
        if(hr.toString().length === 2){
            await document.getElementById('Minutes').focus()
        }
    }

    const validateMinutes = async () => {
        if (minutes.length === 1) {
            await setMinutes('0' + minutes)
        }
    }

    const handleMinuteChange = async (e) => {
        let mins = e.target.value
        if(mins === " " || mins.length > 2 || mins > 59){
            e.preventDefault();
        }else {
            setMinutes(mins);
            if(mins.split('')[0] > 5){
                await setMinutes('0' + mins)
            }else{
                await setMinutes(mins);
            }
        }
    }

    useEffect(() => {
        let hrs = hours !== '' ? hours : '00'
        let mins = minutes !== '' ? minutes : '00'
        let dur = hrs + ':' + mins
        setDurationText(dur);
    }, [hours, minutes, durationText])


    // useEffect(() => { }, [duration, durationText]);

    const onSubmit = async (activityData) => {
        // user.advocate_id = props.dependent.advocate_id
        let isValidate = true;
        if (activityType && Object.keys(activityType).length === 0) {
            await setActivityTypeError(true)
            isValidate = false;
        }
        // if (duration === '' && duration.length === 0) {
        //     setDurationError(true)
        //     setDurationErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_DURATION);
        //     isValidate = false;
        // }

        if (dateActivity < 1) {
            setDateError(true)
            // setDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
            isValidate = false;
        }else{
            activityData.dateActivity = await ddmmyyyyFormat(new Date(dateActivity))
        }
        // if (dateActivity.length > 0 && dateActivity.length < 14 || dateActivity === '') {
        //     setDateError(true)
        //     setDateErrorMeesage('Invalid Date');
        //     isValidate = false;
        // }
        // if (dateActivity && dateActivity.split("/").length === 3) {
        //     let dateActivitySplitList = dateActivity.split("/")
        //     let date = dateActivitySplitList[0].trim() + "/" + dateActivitySplitList[1].trim() + "/" + dateActivitySplitList[2].trim();
        //     activityData.dateActivity = date
        // } else {
        //     activityData.dateActivity = null
        // }
        let obj = {
            "activity_type_id": activityType && Object.keys(activityType).length > 0 ? activityType.id : '',
            "activity_duration": durationText !== '' ? durationText : '00:00',
            "tag": tag,
            "descriptions":description,
            "note": note,
            "web_url" : linkToWebsite,
            "expense_ids": props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expense_ids : [],
            "date_of_activity": activityData.dateActivity,
            "expenses": props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expenses : []
        }

        let isDatesValid = validateDate(dateActivity, 100, 0)
        if (isValidate && !isDatesValid.isErr) {
            if (editMode) {
                props.onUpdate(obj)
            } else {
                props.onSubmit(obj)
            }
        }

    }

    const onTagClicked = (value) => {
        setTag(value)
    }

    const editExpense = async (expense) => {
        let activity = props.activityObj;
        activity.activity_type_id = activityType && Object.keys(activityType).length > 0 ? activityType.id : ''
        activity.activity_duration = durationText !== '' ? durationText : '00:00'
        activity.tag = tag
        activity.descriptions = description
        activity.note = note
        activity.expense_ids = props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expense_ids : []
        activity.date_of_activity = dateActivity !== null ? await ddmmyyyyFormat(new Date(dateActivity)) : null
        activity.expenses = props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expenses : []
        activity.web_url = linkToWebsite
        props.editExpense(expense,activity)
    }

    const deleteExpense = (expense, index) => {
        setLoaderFullScreenVisible(true)
        let deletedExpenseList = deletedExpenseInfo.length > 0 ? deletedExpenseInfo : [];
        deletedExpenseList.push({ key: index, value: expense });
        setDeletedExpenseInfo(deletedExpenseList)
        let expenseObj = props.activityObj;
        expenseObj.expense_ids = expenseObj?.expense_ids?.filter(item => item !== expense.id)
        expenseObj.expenses = expenseObj.expenses.filter(item => item.id !== expense.id)
        let filteredExpenseList = expenseList.filter(item => item.id !== expense.id)
        setExpenseList(filteredExpenseList);
        props.deleteExpense(expenseObj)
        setLoaderFullScreenVisible(false)
    }

    const handleDeleteExpense = () => {
        deleteExpense(delExpense, deleteExpenseIndex);
        setIsPopUp(false);
    }
    const expeseRemovePopUpShow = async (expense, index) => {
        setPopUpType(A12Y_CONST.TEXT.REMOVE_EXPENSE)
        setIsPopUp(true)
       await setDelExpense(expense)
       await setDeleteExpenseIndex(index)
    }
    const addExpense = async () => {
        // let _date = null;
        // if (dateActivity && dateActivity.split("/").length === 3) {
        //     let dateActivitySplitList = dateActivity.split("/")
        //     let date = dateActivitySplitList[0].trim() + "/" + dateActivitySplitList[1].trim() + "/" + dateActivitySplitList[2].trim();
        //     _date = date
        // } else {
        //     _date = null
        // }

        let temp
        if (editMode) {
            let activity = props.activityObj;
            activity.activity_type_id = activityType && Object.keys(activityType).length > 0 ? activityType.id : ''
            activity.activity_duration = durationText !== '' ? durationText : '00:00'
            activity.tag = tag
            activity.descriptions = description
            activity.note = note
            activity.expense_ids = props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expense_ids : []
            activity.date_of_activity = dateActivity !== null ? await ddmmyyyyFormat(new Date(dateActivity)) : null
            activity.expenses = props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expenses : []
            activity.web_url = linkToWebsite
            temp = activity
        } else {
            let obj = {
                "activity_type_id": activityType && Object.keys(activityType).length > 0 ? activityType.id : '',
                "activity_duration": durationText !== '' ? durationText : '00:00',
                "tag": tag,
                "note": note,
                "descriptions":description,
                "expense_ids": props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expense_ids : [],
                "date_of_activity": dateActivity !== null ? await ddmmyyyyFormat(new Date(dateActivity)) : null,
                "expenses": props.activityObj && Object.keys(props.activityObj).length > 0 ? props.activityObj.expenses : [],
                "web_url" : linkToWebsite
            }
            temp = obj
        }
        
        //if (!dateError) {
            props.addExpense(temp);
        //}
    }

    // // Hide Duration on clicking outside
    // const handleClickOutside = (event) => {
    //     if (ref.current && !ref.current.contains(event.target)) {
    //         setIsDurationVisible(false)
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside, true);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside, true);
    //     };
    // });

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        props.onCancel(deletedExpenseInfo);
    }

   const hidePopUpRemoveExpese = ()  => {
        setIsPopUp(false)
   }

    function selectThisRadio(e){
        if(e.code === "NumpadEnter" || e.code === "Enter" || e.code === "Space"){
            e.target.click();
        }
    }
     useEffect( () => {
        let arr =[dateRef.current]
        readOnlyMobile(arr)
    },[])

    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value.trimStart())
        setValue('description', e.target.value.trimStart())

    }
    const valiDatedescription = (e) => {
        if(description === '' && description.length === 0) {
            setDescriptionError(true)
            setDescriptionErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DESCRIPTION_REQUIRED);
            return true;
        }else{
            setDescriptionError(false)
            setDescriptionErrorMeesage('');
            return false;
        }
    }


    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addActivityWrap">
            {loaderFullScreenVisible ?
                <LoaderFullScreen />
                :
                null
            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h2 className="titleWithSidebar noActivityMainTitle marginBottom30">
                <span id={A12Y_CONST.BUTTON_ID.ACTIVITY_ADD_BACK} className="cursorPointer" onClick={onCancel}>
                <svg width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                <span className="marginLeft10">{A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL}</span></span>
            </h2>
            <div className="dependentBorderBox activityBorderBox600 dependentDefauldMinHeight">
                <h3 className='marginBottom10'><span className='vam'>{editMode ? 'Update' : 'Add'}</span> <span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'>{A12Y_CONST.LABEL.APOSTROPHE_S} {A12Y_CONST.LABEL.ACTIVITY_LABEL}</span></h3>
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage} />
                    </div>
                    :
                    (props.message ?
                        <SuccessMessageComponent successMessage={props.message} />
                        :
                        null
                    )

                }
                <form autoComplete="off" className='marginTop40 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow fullWidth formRowGap">
                        <label className="lblExpType">{A12Y_CONST.LABEL.ACTIVITY_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className={`fullWidth dependentResidencCol ${activityTypeError && 'selectError'} ${activityType && Object.keys(activityType).length > 0 ? 'hasValue' : 'noValue'}`}>
                            <Controller
                                name="activityType"
                                value={activityType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={activityTypeOptionsList}
                                        value={activityTypeOptionsList.find(c => c.id === activityType.id)}
                                        onChange={handleActivityTypeChange}
                                        onFocus={() => { validateActivityType('focus') }}
                                        onBlur={() => { validateActivityType('blur') }}
                                        classNamePrefix={'dependentResidence'}
                                        tabIndex={1}
                                    />
                                )}
                                //onChange={handleActivityTypeChange}
                                control={control}
                                className={`fullwidth inputColum24size ${activityTypeError && 'errorInputBorderRed'}`}
                                placeholder="Select"
                            />
                            <div className='errorRelativeRow'> {activityTypeError && <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_ACTIVITY_TYPE}</p>}</div>
                        </div>
                    </div>
                    <div className="fullWidth">
                        <div className="formRow fullWidth formRowGap payRow">
                            <div className="fullWidth formRow paddingleft16 positionRelative">
                                <label className='labelCol emailStep4'>{A12Y_CONST.LABEL.DESCRIPTION}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div className="fullWidth">
                                    <input
                                        {...register("description")}
                                        placeholder={A12Y_CONST.LABEL.TYPE_DESCRIPTION}
                                        type='text'
                                        id="description"
                                        onChange={handleDescriptionChange}
                                        value={description}
                                        maxLength='250'
                                        tabIndex={2}
                                        onBlur={valiDatedescription}
                                        onFocus={()=>setDescriptionErrorMeesage(false)}
                                        className={`fullWidth txtName inputColum24size ${descriptionError && 'errorInputBorderRed'}`}
                                    />
                                    <div className='errorRelativeRow'>{descriptionError? <p className='errorMessage'>{descriptionErrorMeesage}</p>: null}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGap payRow ">
                        <div className="width50 paddingleft16  positionRelative activityDateMarginBottom20">
                            <label>{A12Y_CONST.LABEL.DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="paddingRight16">
                                <Controller
                                    name="dateActivity"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            ref={dateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={dateActivity}
                                            onChange={date => setDateActivity(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'dateActivity')}
                                            onBlur={handleDateChange}
                                            onCalendarClose={handleDateChange}
                                            showMonthDropdown
                                            showYearDropdown
                                            tabIndex={2}
                                            onKeyDown={(event) => hideOnBlur(event, dateRef)}
                                            dropdownMode="select"
                                            onFocus={(e)=> {setDateError(false)}}
                                            className={!dateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                            onMonthChange={setDateActivity}
                                            onYearChange={setDateActivity}
                                            minDate={moment().subtract(10, 'years')._d}
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {dateError ? <p className='errorMessage'>{dateErrorMeesage}</p> : null} 
                                </div>
                            </div>

                        </div>
                        <div className="width50 paddingleft16 positionRelative marginNone600">
                            <label>{A12Y_CONST.LABEL.DURATION}</label>
                            <div className="fullWidth positionRelative df aic">
                                <div className='hours'>
                                    <input
                                        {...register("hours")}
                                        value={hours}
                                        placeholder="HH"
                                        type="number"
                                        inputMode='number'
                                        min={0}
                                        max={23}
                                        id="Hours"
                                        maxLength="2"
                                        tabIndex={4}
                                        onChange={handleHourChange}
                                        onBlur={validateHours}
                                        className="fullWidth durationInput texAlingCener inputColum24size"
                                    />
                                </div>
                                <div className='colon'>&#58;</div>
                                <div className='minutes'>
                                    <input
                                        {...register("minutes")}
                                        value={minutes}
                                        placeholder="MM"
                                        type="number"
                                        min={0}
                                        tabIndex={5}
                                        max={59}
                                        inputMode='number'
                                        id="Minutes"
                                        maxLength="2"
                                        onChange={handleMinuteChange}
                                        onBlur={validateMinutes}
                                        className="fullWidth durationInput texAlingCener inputColum24size"
                                    />
                                </div>
                                {/* {
                                isDurationVisible &&
                                <div id='duration' className='durationWrap'>
                                    <DurationPicker
                                        onChange={onDurationChange}
                                        initialDuration={initialDuration}
                                        maxHours={24}
                                    />

                                    <div className='durAct df'>
                                        <div><button className='btn-cancel' onClick={hideDurationBox}>Cancel</button></div>
                                        <div><button className='btn-ok' onClick={setDurationValue}>Ok</button></div>
                                    </div>
                                </div>
                            } */}
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGap payRow">
                        <div className="width50 paddingleft16 positionRelative">
                            <label>{A12Y_CONST.LABEL.TAG}</label>
                            <div id='tagList' className="fullWidth tagList">
                                {/*<label className='tag cursorPointer tag-0'><input {...register("tag")} onClick={()=>onTagClicked(A12Y_CONST.LABEL.RED)} type="radio" value="0" /><span></span></label>*/}
                                <label className='tag cursorPointer tag-1 tagLabel600' onKeyDown={e => selectThisRadio(e)} tabIndex={3}><input tabIndex={-1} {...register("tag")} checked={tag === 1 ? true : false} onClick={() => onTagClicked(A12Y_CONST.LABEL.BLUE)} type="radio" value="1" /><span></span></label>
                                <label className='tag cursorPointer tag-2 tagLabel600' onKeyDown={e => selectThisRadio(e)} tabIndex={3}><input tabIndex={-1} {...register("tag")} checked={tag === 2 ? true : false} onClick={() => onTagClicked(A12Y_CONST.LABEL.GREEN)} type="radio" value="2" /><span></span></label>
                                <label className='tag cursorPointer tag-3 tagLabel600' onKeyDown={e => selectThisRadio(e)} tabIndex={3}><input tabIndex={-1} {...register("tag")} checked={tag === 3 ? true : false} onClick={() => onTagClicked(A12Y_CONST.LABEL.YELLOW)} type="radio" value="3" /><span></span></label>
                                <label className='tag cursorPointer tag-4 tagLabel600' onKeyDown={e => selectThisRadio(e)} tabIndex={3}><input tabIndex={-1} {...register("tag")} checked={tag === 4 ? true : false} onClick={() => onTagClicked(A12Y_CONST.LABEL.RED)} type="radio" value="4" /><span></span></label>
                                {/* <div className='errorRelativeRow'>{tagError ? <p className='errorMessage'>{tagErrorMeesage}</p> : null}</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="fullWidth">
                        <div className="formRow fullWidth formRowGap payRow">
                            <div className="width50 paddingleft16 positionRelative">
                                <label className='labelCol emailStep4'>{A12Y_CONST.LABEL.LINK_URL}</label>
                                <div className='fullWidth doctorRowMarginB_600'>
                                    <input
                                        {...register('linkToWebsite',{
                                            pattern:{
                                                //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                                value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                                message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                            }
                                        })}
                                        placeholder='www.example.com'
                                        className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                        type='text'
                                        id='displayName'
                                        autoComplete="none"
                                        onChange={(e) => handleLinkToWebsite(e.target.value)}
                                        value={linkToWebsite}
                                        onFocus={()=>{
                                            setError("linkToWebsite", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {editMode ?
                        null
                        :
                        <div className="formRow fullWidth formRowGap df fcol">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className="fullWidth">
                            <textarea
                                {...register("note")}
                                rows="3"
                                maxLength='4000'
                                onChange={handleNoteChange}
                                value={note}
                                tabIndex={6}
                                className="fullWidth inputColum24size txtNote"
                                placeholder="Capture details about this activity."
                            ></textarea>
                        </div>
                    </div>
                    }
                    <div className="formRow fullWidth">
                        <label className="lblActivity">{A12Y_CONST.LABEL.EXPENSES}</label>
                        <div className="lblUpload">
                            <button id={A12Y_CONST.LABEL.ADD+A12Y_CONST.LABEL.EXPENSE_LABEL} className='dropZoneWrap addExpWrap df aic jcc marginBottom15 fullWidth cursorPointer' tabIndex={7} onClick={addExpense}>
                                <span>{A12Y_CONST.TEXT.PLUS_SYMBOL} {A12Y_CONST.LABEL.ADD_ASSOCIATED_EXPENSE}</span>
                            </button>
                            <div className="expList df fcol fullWidth p0">
                                {expenseList.length > 0 && expenseList.map((expense, key) => (
                                    <div className="exp df" key={key}>
                                        <span className="fl expIco">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.0092 0.0582892C12.4268 0.0582892 12.7192 0.0499622 13.0116 0.0582892C13.5712 0.0666163 14.0056 0.499624 13.9972 1.04921C13.9972 1.56549 13.5462 2.00682 13.0032 1.9985C11.9173 1.99017 10.8397 2.00682 9.77053 2.23998C5.87795 3.07269 2.76222 6.3369 2.11068 10.2673C1.2503 15.4884 4.43286 20.3347 9.57006 21.6421C15.1583 23.0577 20.8886 19.3105 21.8158 13.6397C21.9996 12.5322 22.0079 11.4331 21.8158 10.3256C21.6822 9.54283 22.0915 8.97659 22.7764 8.96826C23.2776 8.95993 23.7036 9.30967 23.7955 9.80097C24.9649 16.2295 20.897 22.3082 14.4984 23.7155C8.05813 25.1311 1.5176 20.876 0.239565 14.4308C-1.06353 7.8441 3.07129 1.64043 9.67865 0.274793C10.4889 0.108252 11.3075 0.033308 12.0092 0.0582892Z" fill="#0466C8" />
                                                <path d="M12.0092 0.0582892C12.4268 0.0582892 12.7192 0.0499622 13.0116 0.0582892C13.5712 0.0666163 14.0056 0.499624 13.9972 1.04921C13.9972 1.56549 13.5462 2.00682 13.0032 1.9985C11.9173 1.99017 10.8397 2.00682 9.77053 2.23998C5.87795 3.07269 2.76222 6.3369 2.11068 10.2673C1.2503 15.4884 4.43286 20.3347 9.57006 21.6421C15.1583 23.0577 20.8886 19.3105 21.8158 13.6397C21.9996 12.5322 22.0079 11.4331 21.8158 10.3256C21.6822 9.54283 22.0915 8.97659 22.7764 8.96826C23.2776 8.95993 23.7036 9.30967 23.7955 9.80097C24.9649 16.2295 20.897 22.3082 14.4984 23.7155C8.05813 25.1311 1.5176 20.876 0.239565 14.4308C-1.06353 7.8441 3.07129 1.64043 9.67865 0.274793C10.4889 0.108252 11.3075 0.033308 12.0092 0.0582892Z" fill="black" fillOpacity="0.2" />
                                                <path d="M12.995 6.42849C12.995 7.05302 12.995 7.05302 13.588 7.21956C14.841 7.58595 15.7097 8.33539 15.9687 9.65939C16.0606 10.1091 15.952 10.5088 15.551 10.7835C15.2253 11.0084 14.8744 11.025 14.5236 10.8335C14.2145 10.667 14.0224 10.4088 14.0057 10.0674C13.9806 9.63441 13.7467 9.35962 13.3792 9.21806C12.4604 8.85167 11.5332 8.85999 10.6143 9.21806C10.2802 9.35129 10.0045 9.58445 10.0045 9.96749C10.0045 10.3505 10.2969 10.567 10.6227 10.7169C11.0654 10.9168 11.5415 10.9584 12.026 10.9584C12.7277 10.9584 13.4126 11.0583 14.0642 11.3331C15.0081 11.7328 15.668 12.4073 15.9186 13.4149C16.2109 14.5724 15.5844 15.7964 14.415 16.4043C14.0391 16.6042 13.6382 16.7457 13.2121 16.8123C13.0451 16.8373 12.9782 16.8956 12.9866 17.0705C13.0033 17.3619 12.995 17.6534 12.9866 17.9448C12.9699 18.5027 12.5355 18.9358 11.9926 18.9358C11.4496 18.9358 11.0069 18.5111 10.9902 17.9532C10.9818 17.6617 10.9818 17.3703 10.9902 17.0788C10.9985 16.9123 10.9401 16.8457 10.773 16.8207C9.91264 16.6958 9.21097 16.2794 8.64295 15.6299C8.25871 15.1886 8.04988 14.664 7.99141 14.0811C7.94129 13.4982 8.31718 13.0152 8.86849 12.9569C9.43651 12.8986 9.92099 13.2983 9.97111 13.8895C10.0045 14.2393 10.1799 14.4891 10.4807 14.6223C11.4663 15.072 12.4687 15.0637 13.4627 14.639C13.4794 14.6307 13.4961 14.6223 13.5212 14.6057C14.0809 14.2476 14.1059 13.7646 13.5713 13.3649C13.137 13.0402 12.6191 12.9569 12.0928 12.9486C11.3494 12.9403 10.6227 12.8653 9.92934 12.5739C8.98543 12.1742 8.31718 11.508 8.05823 10.5004C7.75752 9.34296 8.38401 8.12721 9.5618 7.51933C9.90428 7.34447 10.2635 7.2029 10.6477 7.14462C10.8983 7.10298 11.0236 7.02804 10.9902 6.73659C10.9568 6.47845 10.9818 6.22031 10.9818 5.96217C10.9985 5.39593 11.4246 4.97125 11.9759 4.97125C12.5188 4.96292 12.9615 5.39593 12.9866 5.95384C13.0033 6.12038 12.995 6.2786 12.995 6.42849Z" fill="#0466C8" />
                                                <path d="M12.995 6.42849C12.995 7.05302 12.995 7.05302 13.588 7.21956C14.841 7.58595 15.7097 8.33539 15.9687 9.65939C16.0606 10.1091 15.952 10.5088 15.551 10.7835C15.2253 11.0084 14.8744 11.025 14.5236 10.8335C14.2145 10.667 14.0224 10.4088 14.0057 10.0674C13.9806 9.63441 13.7467 9.35962 13.3792 9.21806C12.4604 8.85167 11.5332 8.85999 10.6143 9.21806C10.2802 9.35129 10.0045 9.58445 10.0045 9.96749C10.0045 10.3505 10.2969 10.567 10.6227 10.7169C11.0654 10.9168 11.5415 10.9584 12.026 10.9584C12.7277 10.9584 13.4126 11.0583 14.0642 11.3331C15.0081 11.7328 15.668 12.4073 15.9186 13.4149C16.2109 14.5724 15.5844 15.7964 14.415 16.4043C14.0391 16.6042 13.6382 16.7457 13.2121 16.8123C13.0451 16.8373 12.9782 16.8956 12.9866 17.0705C13.0033 17.3619 12.995 17.6534 12.9866 17.9448C12.9699 18.5027 12.5355 18.9358 11.9926 18.9358C11.4496 18.9358 11.0069 18.5111 10.9902 17.9532C10.9818 17.6617 10.9818 17.3703 10.9902 17.0788C10.9985 16.9123 10.9401 16.8457 10.773 16.8207C9.91264 16.6958 9.21097 16.2794 8.64295 15.6299C8.25871 15.1886 8.04988 14.664 7.99141 14.0811C7.94129 13.4982 8.31718 13.0152 8.86849 12.9569C9.43651 12.8986 9.92099 13.2983 9.97111 13.8895C10.0045 14.2393 10.1799 14.4891 10.4807 14.6223C11.4663 15.072 12.4687 15.0637 13.4627 14.639C13.4794 14.6307 13.4961 14.6223 13.5212 14.6057C14.0809 14.2476 14.1059 13.7646 13.5713 13.3649C13.137 13.0402 12.6191 12.9569 12.0928 12.9486C11.3494 12.9403 10.6227 12.8653 9.92934 12.5739C8.98543 12.1742 8.31718 11.508 8.05823 10.5004C7.75752 9.34296 8.38401 8.12721 9.5618 7.51933C9.90428 7.34447 10.2635 7.2029 10.6477 7.14462C10.8983 7.10298 11.0236 7.02804 10.9902 6.73659C10.9568 6.47845 10.9818 6.22031 10.9818 5.96217C10.9985 5.39593 11.4246 4.97125 11.9759 4.97125C12.5188 4.96292 12.9615 5.39593 12.9866 5.95384C13.0033 6.12038 12.995 6.2786 12.995 6.42849Z" fill="black" fillOpacity="0.2" />
                                                <path d="M19.519 1.9985C19.0428 1.9985 18.5667 1.9985 18.0989 1.9985C17.4474 1.9985 16.988 1.58214 16.9963 0.990922C17.0047 0.408027 17.4557 0 18.0906 0C19.3603 0 20.6299 0 21.8996 0C22.5762 0 22.9939 0.416354 22.9939 1.08252C22.9939 2.35656 22.9939 3.63893 22.9939 4.91297C22.9939 5.5375 22.5595 5.98717 21.9748 5.97884C21.4068 5.97051 20.9975 5.52085 20.9891 4.91297C20.9891 4.43 20.9891 3.94703 20.9891 3.39745C20.7135 3.67224 20.4796 3.9054 20.2457 4.13856C19.4187 4.96294 18.6001 5.78732 17.7732 6.60337C17.3054 7.06969 16.6956 7.09467 16.2779 6.67831C15.8603 6.26196 15.8853 5.65408 16.3531 5.18777C17.3388 4.20517 18.3245 3.22258 19.3185 2.23998C19.3853 2.17337 19.4855 2.1234 19.5691 2.06511C19.5607 2.04846 19.544 2.02348 19.519 1.9985Z" fill="#0466C8" />
                                                <path d="M19.519 1.9985C19.0428 1.9985 18.5667 1.9985 18.0989 1.9985C17.4474 1.9985 16.988 1.58214 16.9963 0.990922C17.0047 0.408027 17.4557 0 18.0906 0C19.3603 0 20.6299 0 21.8996 0C22.5762 0 22.9939 0.416354 22.9939 1.08252C22.9939 2.35656 22.9939 3.63893 22.9939 4.91297C22.9939 5.5375 22.5595 5.98717 21.9748 5.97884C21.4068 5.97051 20.9975 5.52085 20.9891 4.91297C20.9891 4.43 20.9891 3.94703 20.9891 3.39745C20.7135 3.67224 20.4796 3.9054 20.2457 4.13856C19.4187 4.96294 18.6001 5.78732 17.7732 6.60337C17.3054 7.06969 16.6956 7.09467 16.2779 6.67831C15.8603 6.26196 15.8853 5.65408 16.3531 5.18777C17.3388 4.20517 18.3245 3.22258 19.3185 2.23998C19.3853 2.17337 19.4855 2.1234 19.5691 2.06511C19.5607 2.04846 19.544 2.02348 19.519 1.9985Z" fill="black" fillOpacity="0.2" />
                                            </svg>
                                        </span>
                                        <div className='expMid'>
                                            <h5>{expense.expense_type}</h5>
                                            <div className='expInfo df aic'>
                                                {/* <span>{item?.upload_date ? dateFormatting(item.upload_date) : null} 12/11/2021</span> */}
                                                <span>{expense.date_of_expense}</span>
                                                {expense.paid_to !== '' && (<span className="dot"></span>)}
                                                <span className='txtEllipsis vam'>{expense.paid_to}</span>
                                                <span className="dot"></span>
                                                {/*<span>${expense.amount}</span>*/}
                                                <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(expense.amount)}</span>
                                            </div>
                                        </div>
                                        <div className='expAction mla df aic'>
                                            <span id={A12Y_CONST.TEXT.EDIT+A12Y_CONST.LABEL.EXPENSES_LABEL+expense.id} className='cursorPointer' onClick={() => { editExpense(expense) }}>
                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.293 0.292875C13.683 -0.097625 14.317 -0.097625 14.707 0.292875L18.707 4.29287C19.098 4.68337 19.098 5.31657 18.707 5.70707L5.707 18.7071C5.52 18.8946 5.265 19 5 19H1C0.4477 19 0 18.5523 0 18V14C0 13.7348 0.1054 13.4804 0.2929 13.2929L10.293 3.29307L13.293 0.292875ZM11 5.41417L2 14.4142V17H4.586L13.586 7.99997L11 5.41417ZM15 6.58577L16.586 4.99997L14 2.41417L12.414 3.99997L15 6.58577Z" fill="#71767A" />
                                                </svg>
                                            </span>
                                            <span id={A12Y_CONST.TEXT.DELETE+A12Y_CONST.LABEL.EXPENSES_LABEL+expense.id} className='marginLeft20 cursorPointer' onClick={() => { expeseRemovePopUpShow(expense, key) }}>
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 15.3429C0 15.4641 0 15.5852 0 15.7064C0.0519333 15.8145 0.0822258 15.94 0.160126 16.0222C0.506344 16.3943 0.800629 16.8399 1.32861 17C1.44979 17 1.57096 17 1.69214 17C2.06865 16.8615 2.31101 16.5587 2.58365 16.2861C4.51382 14.3608 6.43966 12.4312 8.3655 10.5015C8.46936 10.3977 8.5213 10.3934 8.62949 10.5015C10.6159 12.4961 12.6067 14.4819 14.5974 16.4765C14.8225 16.7015 15.0389 16.9308 15.3678 17C15.4803 17 15.5885 17 15.701 17C16.1424 16.8529 16.4064 16.4851 16.7137 16.1736C17.1162 15.7626 17.0902 15.2175 16.6618 14.7891C14.6104 12.734 12.5547 10.6832 10.4991 8.63245C10.3909 8.52429 10.3952 8.47237 10.4991 8.36853C12.5374 6.33938 14.5715 4.30157 16.6098 2.26377C16.6661 2.20752 16.718 2.1556 16.7656 2.09503C17.0296 1.77487 17.0859 1.35086 16.8522 1.01772C16.6141 0.675921 16.3199 0.373063 15.9736 0.143755C15.5885 -0.111511 15.147 -0.016327 14.7749 0.35143C12.7235 2.40222 10.6765 4.44867 8.62949 6.49946C8.52562 6.6033 8.47369 6.61195 8.3655 6.50379C6.32714 4.46165 4.28445 2.42385 2.24176 0.381716C2.18117 0.321144 2.12491 0.264899 2.06 0.21298C1.7484 -0.0379598 1.28966 -0.0855519 0.982393 0.169715C0.58857 0.498533 0.160126 0.810044 0 1.33788C0 1.45903 0 1.58017 0 1.70131C0.134159 2.06474 0.428444 2.30703 0.688108 2.57095C2.6226 4.50492 4.5571 6.44321 6.49592 8.37286C6.59978 8.47669 6.60411 8.52861 6.49592 8.63678C4.50949 10.614 2.53172 12.5999 0.545292 14.5815C0.320251 14.8064 0.0908813 15.0228 0 15.3429Z" fill="#71767A" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol" >
                        <div className='widthAuto'><input  id={A12Y_CONST.BUTTON_ID.CANCEL_ACTIVITY} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input tabIndex={8} id={A12Y_CONST.BUTTON_ID.SAVE_ACTIVITY} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={editMode ? A12Y_CONST.BUTTON_TEXT.UPDATE : A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />

            <PopUpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.REMOVE_EXPENSE}
                message={A12Y_CONST.TEXT.YOU_ARE_NOT_DELETING_THE_EXPENSE}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUpRemoveExpese}
                handleDeleteButton={handleDeleteExpense}
                popUpType={popUpType}
            />
        </div>
    )
}

export default AddActivity
