import React, { Component } from 'react'
import DoctorViewComponent from '../components/DoctorViewComponent'
import  * as dependentActions from '../redux/actions/dependentActions'
import { connect } from 'react-redux';
import A12Y_CONST from "../common/a12yConst";
import {secureClient} from "../config/axiosClient";
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import * as userDetailActions from "../redux/actions/userDetailActions";



export class DoctorViewScreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            isDownloadActive: false,
            error:false,
            message:'',
            errorMessage:'',
        }
    }

    componentDidMount = async () => {
        await this.props.dataChanged(false)
    }

    onEditHandler = async()=>{
        let doctor = this.props.currentDoctor;
        doctor.isComingFromView = true
        await this.props.currentDoctorData(doctor);
        this.props.history.push(A12Y_CONST.ROUTE.EDIT_DOCTOR);
    }

    onDelete = async()=>{

    }

    onCancel = async()=>{

    }

    onDownloadHandler = async(item, newName)=>{

    }

    deletingDoctor = async () =>{
        if(Object.keys(this.props.currentDoctor).length > 0){
            this.setState({loading:true})
            let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.DOCTORS+"/"+this.props.currentDoctor.id);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.props.history.push({
                    pathname : A12Y_CONST.ROUTE.MEDICAL,
                    state : {tab:A12Y_CONST.TEXT.DOCTOR}
                });
                await this.props.currentDoctorData({});
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false})
            }
            let temp = this;
            setTimeout(function(){ temp.setState({successMessage:null,error:false,errorMessage:''}) }, 3000);
        }
    }


    render() {
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        {this.state.loading ?
                            <LoaderFullScreen/>
                            :
                            <DoctorViewComponent
                                dependent={this.props.dependent}
                                message={this.state.message}
                                error={this.state.error}
                                errorMessage={this.state.errorMessage}
                                onEdit={this.onEditHandler}
                                onDelete={this.onDelete}
                                onCancel={this.onCancel}
                                onDownload={this.onDownloadHandler}
                                currentDoctor={this.props.currentDoctor}
                                doctorSpecialistTypeList={this.props.doctorSpecialistTypeList}
                                onDeleteDoctor={this.deletingDoctor}
                            />
                        }
                        {
                            this.state.isDownloadActive && <LoaderFullScreen/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    currentIncome : state.dependentReducer.currentIncome,
    currentDoctor : state.dependentReducer.currentDoctor,
    doctorSpecialistTypeList: state.dependentReducer.doctorSpecialistTypeList,
});
const mapDispatchToProps = dispatch => ({
    currentDoctorData: (doctor) => dispatch(dependentActions.currentDoctorData(doctor)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});

export default connect(mapStateToProps,mapDispatchToProps) (DoctorViewScreen);
