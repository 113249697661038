import React, { Component } from 'react'
import ExpenseViewComponent from '../components/ExpenseViewComponent'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from "../common/a12yConst";
import {secureClient} from "../config/axiosClient";
import {downloadFile} from "../util/imageDownload";
import LoaderFullScreen from '../components/LoaderFullScreenComponent'
import * as userDetailActions from "../redux/actions/userDetailActions";

export class ExpenseViewScreen extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             isDownloadActive: false,
            error:false,
            message:'',
            errorMessage:'',
            loading: false,
        }
    }

    componentDidMount = async () => {
        await this.props.dataChanged(false)
    }
    onEditHandler = async()=>{
        // this.props.history.push(A12Y_CONST.ROUTE.ADD_EXPENSE);
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_EXPENSE,
            state: {fromExpenseView:true }
        });
    }

    onDelete = async()=>{
        this.setState({loading: true})
        if(this.props.currentExpense && Object.keys(this.props.currentExpense).length > 0){
            this.setState({loading:true,errorMessage:'',error:false})
            let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.EXPENSES+"/"+this.props.currentExpense.id);
            this.setState({loading:false})
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.props.history.push(A12Y_CONST.ROUTE.EXPENSES);
                await this.props.currentExpenseData({})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message, loading: false})
            }
        }
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    onCancel = async()=>{
        /*this.props.history.push(A12Y_CONST.ROUTE.EXPENSES);
        await this.props.currentExpenseData({})*/
      await this.onGoBackHandler();
    }

    onDownloadHandler = async(item, newName)=>{

        this.setState({
            isDownloadActive: true
        })
        await downloadFile(item, newName).then(() => {
            this.setState({
                isDownloadActive: false
            })
        })
    }

    onGoBackHandler = async () =>{
        console.log('check')
        if(this.props.isComingFromActivityView){
            await this.props.expenseViewingUsingActivityView(false);
            this.props.history.push(A12Y_CONST.ROUTE.ACTIVITY_VIEW);
        }else{
            this.props.history.push(A12Y_CONST.ROUTE.EXPENSES)
        }
        // this.props.history.goBack();
        await this.props.currentExpenseData({})
    }


    render() {
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
            <div className='rightPanelWidhtSidebar'>
                <div className="rightPanelWidhtSidebarContainer fullWidth">
                    <ExpenseViewComponent
                        loading={this.state.loading}
                        expenseObj={this.props.currentExpense}
                        message={this.state.message}
                        error={this.state.error}
                        errorMessage={this.state.errorMessage}
                        dependent={this.props.dependent}
                        onEdit={this.onEditHandler}
                        onDelete={this.onDelete}
                        onCancel={this.onCancel}
                        onDownload={this.onDownloadHandler}
                        isComingFromActivityView={this.props.isComingFromActivityView}
                        onGoBack={this.onGoBackHandler}

                    />

                    {
                        this.state.isDownloadActive && <LoaderFullScreen />
                    }
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    currentExpense : state.dependentReducer.currentExpense,
    isComingFromActivityView : state.dependentReducer.isComingFromActivityView,
});
const mapDispatchToProps = dispatch => ({
    currentExpenseData : (expense) => dispatch(dependentActions.currentExpenseData(expense)),
    expenseViewingUsingActivityView : (status) => dispatch(dependentActions.expenseViewingUsingActivityView(status)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});

export default connect(mapStateToProps,mapDispatchToProps) (ExpenseViewScreen);
