import React,{useEffect, useState} from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import exclamationImg from '../assets/images/exclamationErrorIcon.svg';
import { withRouter } from "react-router";

function  ForgotPasswordUpdatedScreen(props) {
    /* eslint-disable no-unused-vars */
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    /* eslint-disable no-unused-vars */

    const goToLoginScreen = () =>{
        props.history.push(A12Y_CONST.ROUTE.LOGIN);
    }

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    },[])

    return (
        <div className="top topRelativeCol mainChildBoxCenter">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className='emailVerificationCenterBox texAlingCener'>
                <div className="emailVerificationIcon">
                    <svg width="58" height="57" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.6829 28.9423L24.7195 37.2074L39.7805 21.0367" stroke="url(#paint0_linear_3:19)" strokeWidth="3" strokeLinecap="round"></path>
                        <path d="M15.6829 28.9423L24.7195 37.2074L39.7805 21.0367" stroke="url(#paint1_linear_3:19)" strokeOpacity="0.2" strokeWidth="3" strokeLinecap="round"></path>
                        <path d="M54 28.5C54 42.0128 42.8255 53 29 53C15.1745 53 4 42.0128 4 28.5C4 14.9872 15.1745 4 29 4C42.8255 4 54 14.9872 54 28.5Z" stroke="url(#paint2_linear_3:19)" strokeWidth="2"></path>
                        <path d="M29 55.5C44.1602 55.5 56.5 43.439 56.5 28.5C56.5 13.561 44.1602 1.5 29 1.5C13.8398 1.5 1.5 13.561 1.5 28.5C1.5 43.439 13.8398 55.5 29 55.5Z" stroke="url(#paint3_linear_3:19)" strokeOpacity="0.18" strokeWidth="3"></path>
                        <defs>
                            <linearGradient id="paint0_linear_3:19" x1="15.6829" y1="21.0367" x2="31.8236" y2="43.0433" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#34A0A4"></stop>
                                <stop offset="1" stopColor="#85C894"></stop>
                            </linearGradient>
                            <linearGradient id="paint1_linear_3:19" x1="19.1404" y1="23.2418" x2="30.4632" y2="41.2802" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#34A0A4"></stop>
                                <stop offset="1" stopColor="#85C894"></stop>
                            </linearGradient>
                            <linearGradient id="paint2_linear_3:19" x1="3" y1="3" x2="56.2423" y2="52.6683" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#34A0A4"></stop>
                                <stop offset="1" stopColor="#85C894"></stop>
                            </linearGradient>
                            <linearGradient id="paint3_linear_3:19" x1="3" y1="3" x2="56.2423" y2="52.6683" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#34A0A4"></stop>
                                <stop offset="1" stopColor="#85C894"></stop>
                            </linearGradient>
                        </defs>
                    </svg>

                </div>
                <h2 className="title1 texAlingCener">
                    {A12Y_CONST.MESSAGE.FORGOT_PASSWORD_UPDATE_TITLE}
                </h2>
                {error &&
                <div className="errorMessageWithBackground marginTopBottom10">
                    <div className="exclamationErrorIcon"><img alt="" src={exclamationImg}/></div>
                    <div className='errorTextContent'>{errorMessage}</div>
                </div>
                }
                <div className="emailVerificationMessage">
                    {A12Y_CONST.MESSAGE.FORGOT_PASSWORD_UPDATE_MESSAGE}
                </div>
                <div className="inputColField onlyNumber marginTop45">
                    <div className='fullWidth'><span id='loginButton' className='button displayInlineBlock lineHeight57 marginBottom80 responsive600LineHeight37' onClick={goToLoginScreen} >{A12Y_CONST.BUTTON_TEXT.FORGOT_PASSWORD_UPDATE_LOGIN}</span></div>
                </div>

            </div>
        </div>
    )

}
const mapStateToProps = state => ({
    userState: state.userDetailReducer.userState,
});

export default withRouter (connect(mapStateToProps,null) (ForgotPasswordUpdatedScreen));