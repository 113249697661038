import React, { Component } from 'react'
import AddActivity from '../components/AddActivityFormComponent'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import * as dependentActions from '../redux/actions/dependentActions'
import { secureClient } from "../config/axiosClient";
import * as userDetailActions from "../redux/actions/userDetailActions";


class AddActivityScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: false,
            successMessage: '',
            error: false,
            activityTypeList: [],
            loaderFullScreenVisible: false,
            loaderFullScreen: false,
            fileUploadingData: {},
            editMode:false,
            pageReferrer:'',
            fromScreen:false
        }
    }

    componentDidMount() {
        let state = { ...this.props.location.state };
        if(state?.fromActivityList || state?.fromDashboardScreen){
            this.setState({fromScreen:true});
        }
    }

    async componentDidUpdate(){
        this.state.error && await this.hideMessage();
    }

    handleOnSubmit = async (data) => {
        this.setState({ error: false, loading: true })
        try {
            await this.createActivity(data)
        } catch (e) {
            console.error('error ......', e)
        }
    }

    onUpdateHandler = async (data) => {
        this.setState({ error: false, loading: true })
        try {
            await this.updateActivity(data)
        } catch (e) {
            console.error('error ......', e)
        }
    }

    updateActivity = async (data) => {
        try {
            if (this.props.currentActivity && Object.keys(this.props.currentActivity).length !== 0) {
                delete data.expenses
                let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS + "/" + this.props.dependent.id + "/" + A12Y_CONST.API_ENDPOINT.ACTIVITIES + "/" + this.props.currentActivity.id, data);
                this.setState({ loading: false })
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.props.currentActivityData(response.data.sr.activity)
                    if (this.props.isCommingFromActivityList === A12Y_CONST.ROUTE.ACTIVITY_VIEW){
                        this.props.history.push(A12Y_CONST.ROUTE.ACTIVITY_VIEW);
                    }else{
                    this.props.history.push(A12Y_CONST.ROUTE.ACTIVITIES);
                    }
                } else {
                    this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message })
                    this.setState({ loading: false })
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.UPDATE_ACTIVITY,e)
        }
    }

    createActivity = async (data) => {
        try {
            delete data.expenses
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS + '/' + this.props.dependent.id + '/' + A12Y_CONST.API_ENDPOINT.ACTIVITIES, data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.props.currentActivityData({})
                if(!this.props.activity_present){
                    let dependent = this.props.dependent
                    dependent.metadata.activity_present = true
                    this.props.updateDependentProps(dependent)
                    this.props.updateMetaData(this.props.income_present,this.props.expense_present,!this.props.activity_present,this.props.asset_present)
                }
                this.props.history.push(A12Y_CONST.ROUTE.ACTIVITIES);
                this.setState({ loading: false })
            } else {
                this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message })
                this.setState({ loading: false })
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.CREATE_ACTIVITY,e)
        }

    }

    addExpenseHandler = async (activity) =>{
        await this.props.expenseCreatingUsingActivityLog(true);
        await this.props.currentActivityData(activity)
        await this.props.currentExpenseData({})
        this.props.history.push({
            pathname : A12Y_CONST.ROUTE.ADD_EXPENSE
        })
    }

    editExpenseHandler = async (expense,activity) => {
        await this.props.expenseCreatingUsingActivityLog(true);
        await this.props.currentActivityData(activity);
        await this.props.currentExpenseData(expense)
        this.props.history.push({
            pathname : A12Y_CONST.ROUTE.ADD_EXPENSE
        })
    }

    deleteExpenseHandler = async (expense) => {
        await this.props.currentActivityData(expense);
    }

    onCancelHandler = async (expenseList) =>{
        let previousActvity = await JSON.parse(localStorage.getItem('activity'));
        if(previousActvity !== ''){
             await this.props.currentActivityData(previousActvity);
        }
       /* if(this.props.currentActivity && Object.keys(this.props.currentActivity).length > 0 && this.props.currentActivity.id && expenseList.length > 0 ) {
            let activity = this.props.currentActivity
             for(let i=0; i < expenseList.length; i++){
                 activity.expenses.splice(expenseList[i].key, 0, expenseList[i].value);
                 activity.expense_ids.splice(expenseList[i].key, 0, expenseList[i].value.id);
             }
            await this.props.currentActivityData(activity);
        }*/
        await this.props.updateSessionTimeout(false)
        if(this.props.isCommingFromActivityList === A12Y_CONST.ROUTE.ACTIVITIES || this.state.fromScreen){
            this.props.history.push(A12Y_CONST.ROUTE.ACTIVITIES);
        }else if (this.props.isCommingFromActivityList === A12Y_CONST.ROUTE.ACTIVITY_VIEW){
            this.props.history.push(A12Y_CONST.ROUTE.ACTIVITY_VIEW);
            // this.props.history.push(A12Y_CONST.ROUTE.ACTIVITIES);
        }else{
            //this.props.history.goBack();
            this.props.history.push(A12Y_CONST.ROUTE.ACTIVITIES);
        }
    }

    hideMessage = async ()=>{
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    render(props) {
        return (
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddActivity
                            // dependent={props.dependent}
                            loading={this.state.loading}
                            message={this.state.message}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            activityTypeList={this.props.activityTypeList}
                            onSubmit={this.handleOnSubmit}
                            loaderFullScreen={this.state.loaderFullScreen}
                            dependent={this.props.dependent}
                            activityObj={this.props.currentActivity}
                            onUpdate={this.onUpdateHandler}
                            addExpense={this.addExpenseHandler}
                            editExpense={this.editExpenseHandler}
                            deleteExpense={this.deleteExpenseHandler}
                            onCancel={this.onCancelHandler}
                            isDataChanged={this.dataChangeHandler}
                            fromScreen={this.state.fromScreen}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errMsg: state.dependentReducer.errMsg,
    isError: state.dependentReducer.isError,
    successMessage: state.dependentReducer.successMessage,
    dependent: state.dependentReducer.dependent,
    currentActivity: state.dependentReducer.currentActivity,
    activityTypeList: state.dependentReducer.activityTypeList,
    isCommingFromActivityList:state.dependentReducer.isCommingFromActivityList,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    asset_present: state.dependentReducer.asset_present,
});

const mapDispatchToProps = dispatch => ({
    currentActivityData: (activity) => dispatch(dependentActions.currentActivityData(activity)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    expenseCreatingUsingActivityLog: (status) => dispatch(dependentActions.expenseCreatingUsingActivityLog(status)),
    currentExpenseData : (expense) => dispatch(dependentActions.currentExpenseData(expense)),
    getActivityTypeList : (dependentId) => dispatch(dependentActions.getActivityTypeList(dependentId)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    updateSessionTimeout : (status) => dispatch(userDetailActions.updateSessionTimeout(status)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AddActivityScreen);
