import React,{Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import DependentAddressEditComponent from '../components/DependentAddressEditComponent'
import * as userDetailActions from "../redux/actions/userDetailActions";

class DependentAddressEditScreen extends Component {

    constructor(props){
        super(props)
        this.state={
            loading:false,
            showLayer:false,
            error:false,
            message:'',
            errorMessage:'',
            isClickedOutside:false
        }
    }


    submitHandler = async(data)=>{
        this.dataChangeHandler(false)
        this.setState({showLayer:true})
        if(this.props.dependent){
            if(this.props.isCreating){
                this.setState({loading:true, error:false, errorMessage:'', message:''})
                data.dependent_id = this.props.dependent.id
                await this.props.createAddress(this.props.dependent.advocate_id,this.props.dependent.id,data)
                this.setState({loading:false})
                if(this.props.isError){
                    this.setState({error:true,errorMessage:this.props.errMsg})
                }else {
                    this.setState({message:this.props.successMessage})
                    this.reloadAddress()
                }
            }else {
                this.setState({loading:true, error:false, errorMessage:'', message:''})
                data.id = this.props.address.id
                data.dependent_id = this.props.dependent.id
                await this.props.updateAddress(this.props.dependent.advocate_id,this.props.dependent.id,this.props.address.id,data)
                this.setState({loading:false})
                if(this.props.isError){
                    this.setState({error:true,errorMessage:this.props.errMsg})
                }else {
                    this.setState({message:this.props.successMessage},()=>{
                        setTimeout(()=>{
                            this.reloadAddress()
                        },500)
                    })

                }
            }
            this.setState({showLayer:false})
        }
    }

    onCancel = () =>{
        this.props.onCancel()
    }

    reloadAddress = () =>{
        this.props.reloadData()
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    render(){
        return(
            <div className="titleRow margignTop768_13 margignBottom768_10 dependentAddressEditMain" onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                <h2 className="titleWithSidebar marginBottom30">
                    <span>
                        {A12Y_CONST.LABEL.DEPENDENT_PROFILE_EDIT_TITLE}
                    </span>
                </h2>
                <div className='dependentDefaultMainC0l fullWidth'>
                    <DependentAddressEditComponent
                        onSubmit={this.submitHandler}
                        loading={this.state.loading}
                        showLayer={this.state.showLayer}
                        message={this.state.message}
                        error={this.state.error}
                        errorMessage={this.state.errorMessage}
                        onCancel={this.onCancel}
                        address={this.props.address}
                        isClickedOutside={this.state.isClickedOutside}
                        userInfo={this.props.userInfo}
                        isDataChanged={this.dataChangeHandler}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    errMsg : state.dependentReducer.errMsg,
    userInfo : state.userDetailReducer.userState.userInfo,
});

const mapDispatchToProps = dispatch => ({
    createAddress : (advocate_id,dependent_id,data) => dispatch(dependentActions.createAddress(advocate_id,dependent_id,data)),
    updateAddress : (advocate_id,dependent_id,address_id,data) => dispatch(dependentActions.updateAddress(advocate_id,dependent_id,address_id,data)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});

export default connect(mapStateToProps, mapDispatchToProps) (DependentAddressEditScreen);
