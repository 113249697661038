import React, { useState, useEffect}from 'react';
import A12Y_CONST from '../common/a12yConst'

function  PopupDocumentActionResponsive (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState(props.popUpStatus);

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const popUpClose = () => {
        props.popUpclose()
    }
    const itemDelete = () => {
        props.itemDelete()
    }
    const downoaditem = () => {
        props.downloadFile()
    }
    const handleResponsiveRename = () => {
        props.handleResponsiveRename()
    }
    const responsiveMoveitem = () => {
        props.responsiveMoveitem()
    }

    useEffect(()=> {
        if(props?.selectedItem?.display_name){
            setTitle(props?.selectedItem?.display_name)
        }
    },[props.selectedItem])


    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop documentPopUp'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg">
                                    <div className="popUpTitle">
                                        {title}

                                        <span className='popUpCloseIcon' onClick={popUpClose}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.29324 0.292876C0.683226 -0.0976254 1.3172 -0.0976254 1.70719 0.292876L7 5.58579L12.2928 0.292876C12.6828 -0.0976254 13.3168 -0.0976254 13.7068 0.292876C14.0977 0.683377 14.0977 1.31658 13.7068 1.70708L8.41395 7L13.7068 12.2929C14.0977 12.6834 14.0977 13.3166 13.7068 13.7071C13.3168 14.0976 12.6828 14.0976 12.2928 13.7071L7 8.41421L1.70719 13.7071C1.3172 14.0976 0.683226 14.0976 0.29324 13.7071C-0.0977465 13.3166 -0.0977465 12.6834 0.29324 12.2929L5.58605 7L0.29324 1.70708C-0.0977465 1.31658 -0.0977465 0.683377 0.29324 0.292876Z" fill="#71767A"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="documentPopUpActionList">
                                        <ul>
                                            {props.selectedItem.id && !props.selectedItem.sys_created ?
                                            <li onClick={handleResponsiveRename}>
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.8936 0.262046C12.2425 -0.0873488 12.8098 -0.0873488 13.1587 0.262046L16.7376 3.841C17.0875 4.19039 17.0875 4.75694 16.7376 5.10634L5.1062 16.7379C4.93888 16.9057 4.71073 17 4.47363 17H0.894725C0.400568 17 0 16.5994 0 16.1053V12.5263C0 12.289 0.0943041 12.0614 0.262065 11.8936L9.2094 2.94644L11.8936 0.262046ZM9.84198 4.84427L1.78945 12.8969V15.2105H4.10321L12.1557 7.15788L9.84198 4.84427ZM13.4209 5.89254L14.8399 4.47367L12.5262 2.16005L11.1071 3.57893L13.4209 5.89254Z" fill="#565C65"/>
                                                </svg>
                                                {A12Y_CONST.TEXT.RENAME}</li>
                                                :
                                                null
                                            }
                                            {/*<li>*/}
                                            {/*    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                            {/*        <path d="M10.6667 1.8H1.77778V16.2H14.2222V5.4H10.6667V1.8ZM0 0.8928C0 0.3996 0.397333 0 0.887999 0H11.5555L16 4.5V17.0937C16.0008 17.2119 15.9786 17.3291 15.9347 17.4386C15.8908 17.5481 15.826 17.6478 15.744 17.7319C15.6621 17.8161 15.5645 17.8831 15.457 17.9291C15.3495 17.9751 15.234 17.9992 15.1173 18H0.882665C0.649224 17.9984 0.425793 17.9038 0.260638 17.7367C0.0954825 17.5697 0.00186191 17.3436 0 17.1072V0.8928ZM7.99999 8.1V5.4L11.5555 9L7.99999 12.6V9.9H4.44444V8.1H7.99999Z" fill="#565C65"/>*/}
                                            {/*    </svg>*/}
                                            {/*    {A12Y_CONST.TEXT.Move_TO}</li>*/}

                                            {props.selectedItem.id && !props.selectedItem.sys_created ?
                                                <li onClick={itemDelete}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M4.5 1.8C4.5 0.80586 5.30589 0 6.3 0H11.7C12.6941 0 13.5 0.80586 13.5 1.8V3.6H15.2907C15.2963 3.59991 15.3019 3.59991 15.3075 3.6H17.1C17.5971 3.6 18 4.00293 18 4.5C18 4.99707 17.5971 5.4 17.1 5.4H16.138L15.3574 16.3283C15.2901 17.2701 14.5064 18 13.562 18H4.43801C3.49366 18 2.70986 17.2701 2.64258 16.3283L1.86199 5.4H0.9C0.402948 5.4 0 4.99707 0 4.5C0 4.00293 0.402948 3.6 0.9 3.6H2.69257C2.69816 3.59991 2.70373 3.59991 2.70929 3.6H4.5V1.8ZM6.3 3.6H11.7V1.8H6.3V3.6ZM3.66658 5.4L4.43801 16.2H13.562L14.3334 5.4H3.66658ZM7.2 7.2C7.69707 7.2 8.1 7.60293 8.1 8.1V13.5C8.1 13.9971 7.69707 14.4 7.2 14.4C6.70295 14.4 6.3 13.9971 6.3 13.5V8.1C6.3 7.60293 6.70295 7.2 7.2 7.2ZM10.8 7.2C11.2971 7.2 11.7 7.60293 11.7 8.1V13.5C11.7 13.9971 11.2971 14.4 10.8 14.4C10.3029 14.4 9.9 13.9971 9.9 13.5V8.1C9.9 7.60293 10.3029 7.2 10.8 7.2Z"
                                                            fill="#565C65"/>
                                                    </svg>
                                                    {A12Y_CONST.TEXT.DELETE}</li>
                                                :
                                                null
                                            }

                                            {!props.selectedItem.is_folder && (props.selectedItem.id && !props.selectedItem.sys_created)?
                                            /*{!props.selectedItem.is_folder?*/
                                                <li onClick={responsiveMoveitem}>
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.3726 1.77778H17.1C17.3387 1.77778 17.5676 1.87143 17.7364 2.03813C17.9052 2.20483 18 2.43092 18 2.66667V15.1111C18 15.3469 17.9052 15.573 17.7364 15.7397C17.5676 15.9064 17.3387 16 17.1 16H0.9C0.661305 16 0.432387 15.9064 0.263604 15.7397C0.0948211 15.573 0 15.3469 0 15.1111V0.888889C0 0.653141 0.0948211 0.427048 0.263604 0.260349C0.432387 0.0936505 0.661305 0 0.9 0H7.5726L9.3726 1.77778ZM1.8 1.77778V14.2222H16.2V3.55556H8.6274L6.8274 1.77778H1.8ZM9 8V5.33333L12.6 8.88889L9 12.4444V9.77778H5.4V8H9Z" fill="#565C65"/>
                                                    </svg>
                                                    {A12Y_CONST.TEXT.MOVE_TO}
                                                </li>
                                                :
                                                null
                                            }
                                            {props.selectedItem.is_folder ?
                                            null
                                                :
                                                <li onClick={downoaditem}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 2C6.791 2 5 3.791 5 6C5 6.032 4.99998 6.06601 5.00098 6.10201C5.01198 6.56701 4.701 6.97799 4.25 7.09399C2.956 7.42699 2 8.603 2 10C2 11.657 3.343 13 5 13H6C6.552 13 7 13.448 7 14C7 14.552 6.552 15 6 15H5C2.239 15 0 12.761 0 10C0 7.937 1.24799 6.16801 3.02899 5.40401C3.32899 2.37001 5.887 0 9 0C11.224 0 13.163 1.20901 14.199 3.00301C17.421 3.10801 20 5.753 20 9C20 12.314 17.314 15 14 15C13.448 15 13 14.552 13 14C13 13.448 13.448 13 14 13C16.209 13 18 11.209 18 9C18 6.791 16.209 5 14 5C13.889 5 13.78 5.004 13.672 5.013C13.245 5.048 12.843 4.807 12.673 4.414C12.058 2.992 10.644 2 9 2ZM10 8C10.552 8 11 8.448 11 9V16.586L11.293 16.293C11.683 15.902 12.317 15.902 12.707 16.293C13.098 16.683 13.098 17.317 12.707 17.707L10.707 19.707C10.52 19.895 10.265 20 10 20C9.735 20 9.48003 19.895 9.29303 19.707L7.29303 17.707C6.90203 17.317 6.90203 16.683 7.29303 16.293C7.68303 15.902 8.31697 15.902 8.70697 16.293L9 16.586V9C9 8.448 9.448 8 10 8Z" fill="#565C65"/>
                                                    </svg>
                                                    {A12Y_CONST.TEXT.DOWNLOAD}
                                                </li>
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopupDocumentActionResponsive;
