import React,{Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import MyAccountPersonalInformationComponent from '../components/MyAccountPersonalInformationComponent'
import MyAccountPasswordComponent from '../components/MyAccountPasswordComponent'
import { connect } from 'react-redux';
import {secureClient} from "../config/axiosClient";
import  * as userDetailActions from '../redux/actions/userDetailActions'
import  * as dependentActions from '../redux/actions/dependentActions'
import EditChangesRemovePopup from "../components/EditChangesRemovePopup";
import MyAccountViewComponent from "../components/MyAccountViewComponent";
import {Elements} from "@stripe/react-stripe-js";
import BillingComponent from "../components/BillingComponent";
import {loadStripe} from '@stripe/stripe-js';
import {userSubscriptionDetailUpdate} from "../redux/actions/userDetailActions";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

class MyAccountScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            SignUpSuccess:false,
            selectPersontalInfo: true,
            selectPassword: false,
            selectBilling: false,
            successMessage:'',
            error:false,
            resetFields:false,
            isClickedOutside:false,
            stripePromiseState:null,
            showPopup:false,
            invoiceData:{},
            isPopupVisible:false,
            tabSelecting:'',
            isMyAccountView:true,
            isPromoCodeApplied:false,
            isPromocodeVisible:false,
            planStatus:'',
        }
        window.document.title = A12Y_CONST.TEXT.TITLE_LOGIN
    }

    componentDidMount = async ()=> {
        const stripePromise = loadStripe(this?.props?.stripeConfig?.stripe_publishable_key);
        this.setState({stripePromiseState:stripePromise})
        /*let userInfo =await this.props.userState.userInfo*/
        let userSubscription = this.props.userSubscription
        if(this.props.userInfo.phones && this.props.userInfo.phones.length > 0 && (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status !== A12Y_CONST.TEXT.EXPIRED)){
            this.setState({isMyAccountView:true})
            await this.getInvoiceData()
        }else {
            if(this.props.userInfo.phones && this.props.userInfo.phones.length > 0 &&Object.keys(this.props.dependent).length > 0 && !this.props.dependent.access.can_update){
                this.setState({isMyAccountView:true})
            }
        }
      /*  if(userInfo.phones && userInfo.phones.length > 0 && ((Object.keys(this.props.dependent).length === 0) || (Object.keys(this.props.dependent).length > 0 && this.props.dependent.access.can_update)) && ((userSubscription === undefined) || (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status === A12Y_CONST.TEXT.EXPIRED))){
            await this.tabcheck(false,false,true)
        }*/

        if(userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status !== A12Y_CONST.TEXT.EXPIRED){
            this.setState({isMyAccountView:true})
            await this.getInvoiceData()
        }else {
            if(Object.keys(this.props.dependent).length > 0 && !this.props.dependent.access.can_update){
                this.setState({selectBilling:true})
            }
        }

      /*  if(((Object.keys(this.props.dependent).length === 0) || (Object.keys(this.props.dependent).length > 0 && this.props.dependent.access.can_update)) && ((userSubscription === undefined) || (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status === A12Y_CONST.TEXT.EXPIRED))){
            await this.tabcheck(false,false,true)
        }*/


        if(this.props.isBillingScreen || window.location.hash === A12Y_CONST.LABEL.HASH_BILLING){
             await this.setState({selectBilling:true, isMyAccountView:false,selectPersontalInfo:false})
            this.props.goToBillingScreen(false)

        }
    }

    // componentDidUpdate= async ()=> {
    //     if(this.props.isMyAccountClicked){
    //         let userSubscription = this.props.userSubscription
    //         if(this.props.userInfo.phones && this.props.userInfo.phones.length > 0 && (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status !== A12Y_CONST.TEXT.EXPIRED)){
    //             this.setState({isMyAccountView:true})
    //         }
    //         await this.props.myAccountClicked(false)
    //     }
    // }

    tabcheck = async (personInfo, password, billing) => {
        if(this.props.isDataChanged){
            let tab = personInfo ? 'personInfo' : password ? 'password' : 'billing'
            this.setState({isPopupVisible:true,tabSelecting:tab})
        }else {
            this.setState({isMyAccountView:false, selectPersontalInfo: personInfo, selectPassword: password, selectBilling: billing ,error:false})
            /*if(billing){
                await this.getInvoiceData()
            }*/
        }
    }

    handelSubmit = async(data)=>{
        this.setState({error:false})
        data.userId = this.props.userState.userInfo.id
        this.setState({loading:true})
        // let isPhonePresent = this.props.userState.userInfo?.phones?.length > 0 ? true : false;
        await this.props.updateUserProfile(data,this.props.userState.userInfo.email);
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg})
        }else {
            await this.dataChangeHandler(false);
            // let userInfo =await this.props.userState.userInfo
            // let userSubscription = this.props.userSubscription
            this.setState({successMessage:this.props.successMessage})
            // if(userInfo.phones && userInfo.phones.length > 0 && ((Object.keys(this.props.dependent).length === 0) || (Object.keys(this.props.dependent).length > 0 && this.props.dependent.access.can_update)) && ((userSubscription === undefined) || (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status === A12Y_CONST.TEXT.EXPIRED))){
            //     await this.tabcheck(false,false,true)
            // }else {
            //     if(userInfo.phones && userInfo.phones.length > 0 && Object.keys(this.props.dependent).length === 0){
            //         this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)
            //     }else {
            //         let userSubscription = this.props.userSubscription
            //         if(this.props.userInfo.phones && this.props.userInfo.phones.length > 0 && (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status !== A12Y_CONST.TEXT.EXPIRED)){
            //             this.setState({isMyAccountView:true, selectPersontalInfo: false, selectPassword: false, selectBilling: false ,error:false})
            //         }else {
            //             if(!isPhonePresent && (Object.keys(this.props.dependent).length > 0 && !this.props.dependent.access.can_update)){
            //                 this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)
            //             }else {
            //                 this.setState({isMyAccountView:true, selectPersontalInfo: false, selectPassword: false, selectBilling: false ,error:false})
            //             }
            //         }
            //     }
            // }
            this.setState({isMyAccountView:true, selectPersontalInfo: false, selectPassword: false, selectBilling: false ,error:false})
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }

    handelUpdatePasswordSubmit = async(data)=>{
        this.setState({error:false})
        let obj = {
            "current_password" : data.oldPassword,
            "updated_password" : data.password
        }
        this.setState({loading:true})
        await this.props.updatePassword(obj);
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
        }else {
            this.setState({successMessage:this.props.successMessage,resetFields:true,error:false})
            await this.dataChangeHandler(false)
            this.onCancelHandler();
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }

    updateBilling = async(data)=>{
        this.setState({error:false})
        let obj = {
            "payment_method" : data.payMethod,
            "email" : this.props.userState.userInfo.email,
            "user_id" : this.props.userState.userInfo.id,
            'dependent_id':this.props.dependent.id,
            "price_id":this.props?.subscriptionProduct[0]?.plans[0]?.id,
            // "stripe_customer_id":this.props?.dependent?.subscirption?.subscription_details?.external_system_customer_id
            "stripe_customer_id":this.props?.userSubscriptionAllDetails?.subscription_details?.external_system_customer_id
        }
        if(this.props?.userSubscriptionAllDetails?.payment_method?.default_payment_method_id){
            obj.default_payment_method_id = this.props?.userSubscriptionAllDetails?.payment_method?.default_payment_method_id
        }
        this.setState({loading:true})
        if(this.state.isPromoCodeApplied && Object.keys(this.state.promoCode).length > 0){
            obj.promotion_code = this.state.promoCode.id
        }
        if(this.props.userSubscription?.payment_method !== null && this.props.userSubscription?.payment_method !== undefined){
            obj.existing_payment_method_id = this.props.userSubscription?.payment_method.default_payment_method_id
        }
        await this.props.updatePaymentMethod(obj);
        // if(data.editMode) {
        //     obj.stripe_customer_id = this.props.paymentMethod.external_customer_id
        //     obj.existing_payment_method_id = this.props.paymentMethod.default_payment_method_id
        //     await this.props.updatePaymentMethod(obj);
        // }else{
        //     await this.props.createUserSubscription(obj);
        // }
        this.setState({loading:false,isPromoCodeApplied:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
        }else {
            let userSubscriptionAllDetails = this.props.userSubscriptionAllDetails
            userSubscriptionAllDetails.trial_period = false;
            userSubscriptionAllDetails.is_trial_expired = false;
            userSubscriptionAllDetails.subscription_details = this.props.userSubscription;
            userSubscriptionAllDetails.price_details = this.props.price_details;
            userSubscriptionAllDetails.payment_method = this.props.paymentMethod;
            userSubscriptionAllDetails.plan_name = this.props.planName;

            let dependent = this.props.dependent
            dependent.subscirption.trial_period = false;
            dependent.subscirption.is_trial_expired = false;
            dependent.subscirption.subscription_details = this.props.userSubscription;
            dependent.subscirption.payment_method = this.props.paymentMethod;
            dependent.subscirption.plan_name = this.props.planName;

            await this.props.updateDependentProps(dependent)
            await this.props.userSubscriptionDetailUpdate(userSubscriptionAllDetails)
            this.setState({successMessage:this.props.successMessage,resetFields:true,error:false,showPopup:true})
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }

    // handleCancelSubscription = async() =>{
    //     this.setState({error:false})
    //     let obj = {
    //         "stripe_customer_id" : this.props.paymentMethod.external_customer_id,
    //         "subscription_id" : this.props.userSubscription.subscription_id,
    //         "user_id" : this.props.userState.userInfo.id
    //     }
    //     this.setState({loading:true})
    //     await this.props.cancelUserSubscription(obj);
    //     this.setState({loading:false})
    //     if(this.props.isError){
    //         this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
    //     }else {
    //         this.setState({successMessage:this.props.successMessage,resetFields:true,error:false})
    //     }
    //     let temp = this;
    //     setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    // }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    onContinueHandel =()=>{
        this.setState({showPopup:false, isMyAccountView:true})
        this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT)
    }
    myAccountViewSection =()=>{
        this.setState({showPopup:false, isMyAccountView:true})
        this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT)
    }

    getInvoiceData = async () =>{
        if(this.props.userSubscription !== undefined && this.props.paymentMethod !== undefined){
            let response  = await secureClient.put(A12Y_CONST.API_ENDPOINT.GET_INVOICES,{
                "stripe_customer_id" : this.props.paymentMethod.external_customer_id,
                "subscription_id" : this.props.userSubscription.subscription_id
            })
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr.invoices.data.length > 0){
                    this.setState({invoiceData:response.data.sr.invoices.data[0]})
                }
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            }
        }
    }

    handleHidePopup = () => {
        this.setState({isPopupVisible:false})
    }

    handleResetAllFields = async ()=>{
        await this.props.dataChanged(false)
        if(this.state.tabSelecting !== ''){
            this.setState({ isPopupVisible:false, selectPersontalInfo: this.state.tabSelecting === 'personInfo' ? true : false, selectPassword: this.state.tabSelecting === 'password' ?  true : false, selectBilling: this.state.tabSelecting === 'billing' ? true : false ,error:false})
        }
    }
    onEditInfo = async (personInfo, password, billing) => {
        this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT)
        await this.tabcheck(personInfo, password, billing)
        this.setState({isMyAccountView:false, isPromocodeVisible:false})

    }

    billingScreenEdit = async (personInfo, password, billing, status) => {
        console.log('status', status)
        this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT_BILLING)
        await this.tabcheck(personInfo, password, billing)
        await this.setState({isMyAccountView:false, isPromocodeVisible:false, planStatus:status})
    }

    onlyBillingPromocodeEdit = async (personInfo, password, billing) => {
        await this.tabcheck(personInfo, password, billing)
        this.setState({isMyAccountView:false,isPromocodeVisible:true})
    }

    onBackHandler = async ()=>{
        this.setState({isPromoCodeApplied:false})
        let userSubscription = this.props.userSubscription
        if(this.props.userInfo.phones && this.props.userInfo.phones.length > 0 && (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status !== A12Y_CONST.TEXT.EXPIRED)){
            this.setState({isMyAccountView:true, selectPersontalInfo: false, selectPassword: false, selectBilling: false ,error:false})
        }else {
            this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT);
            await this.tabcheck(true,false,false)
            this.setState({isMyAccountView:true})
        }

    }

    onBackHandlerFomBilling = async () => {
        this.setState({isPromoCodeApplied:false})
//        let userSubscription = this.props.userSubscription
        if(this.props?.userSubscriptionAllDetails?.is_trial_expired || this.props?.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED || this.props?.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT){
            this.goToDashBoardScreen()
        }else {
            this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT);
            await this.tabcheck(true,false,false)
            this.setState({isMyAccountView:true})
        }
    }

    goToDashBoardScreen = () => {
        this.props.history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW);
    }

    onCancelHandler = ()=>{
        this.setState({isMyAccountView:true, selectPersontalInfo: false, selectPassword: false, selectBilling: false ,error:false})
    }

    // handleCancelSubscription = async() =>{
    //     this.setState({error:false})
    //     let obj = {
    //         "stripe_customer_id" : this.props.paymentMethod.external_customer_id,
    //         "subscription_id" : this.props.userSubscription.subscription_id,
    //         "user_id" : this.props.userState.userInfo.id
    //     }
    //     this.setState({loading:true})
    //     await this.props.cancelUserSubscription(obj);
    //     this.setState({loading:false})
    //     if(this.props.isError){
    //         this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
    //     }else {
    //         this.setState({successMessage:this.props.successMessage,resetFields:true,error:false})
    //     }
    //     let temp = this;
    //     setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    // }

    handleCancelSubscription = async() =>{

        this.setState({error:false})
        let obj = {
            "stripe_customer_id" : this.props?.userSubscriptionAllDetails?.payment_method?.external_customer_id,
            "subscription_id" : this.props?.userSubscriptionAllDetails?.subscription_details?.subscription_id,
            "user_id" : this.props?.userState.userInfo.id
        }
        this.setState({loading:true})
        await this.props.cancelUserSubscription(obj);
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
        }else {
            /*let dependent = this.props.dependent
            dependent.subscirption.subscription_details = this.props?.userSubscription;
            await this.props.updateDependentProps(dependent)*/

            let userSubscriptionAllDetails = this.props.userSubscriptionAllDetails
            userSubscriptionAllDetails.subscription_details = this.props?.userSubscription;
            await this.props.userSubscriptionDetailUpdate(userSubscriptionAllDetails)
            this.setState({successMessage:this.props.successMessage,resetFields:true,error:false,loading:false})
        }
        let temp = this;
        this.setState({loading:false})
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }

    handelApplyPromoCode = async (promoCode)=>{
        await this.setState({loaderFullScreen:true,promocodeMessage:'', promocodeError:false})
        try{
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.VALIDATE_PROMOCODE,{code:promoCode, product_id:this.props.subscriptionProduct[0].id});
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response?.data?.sr?.promotion_code?.data[0]?.active){
                    let obj = response?.data?.sr?.promotion_code?.data[0];
                    if(obj.coupon.amount_off !== null && Object.keys(this.props.subscriptionProduct[0].plans).length > 0){
                        let discountPrice = obj.coupon.amount_off/100
                        this.setState({discountAmt:this.props.subscriptionProduct[0].plans[0].plan_price - discountPrice, promocodeInAmount:true, promocodeMessage:A12Y_CONST.TEXT.OFFER_HAS_BEEN_APPLIED, couponAmount: discountPrice})
                    }else {
                        let discountPrice =  ((obj.coupon.percent_off/ 100) * this.props.subscriptionProduct[0].plans[0].plan_price).toFixed(2)
                        this.setState({discountAmt:this.props.subscriptionProduct[0].plans[0].plan_price - discountPrice, promocodeInAmount:false, promocodeMessage:A12Y_CONST.TEXT.OFFER_HAS_BEEN_APPLIED, couponAmount: obj.coupon.percent_off})
                    }
                    if(this.state.isPromocodeVisible){
                        let response_promoCode = await secureClient.post(A12Y_CONST.API_ENDPOINT.APPLY_PROMOCODE,{code:promoCode, product_id:this.props.subscriptionProduct[0].id});
                        let userSubscriptionAll = this.props.userSubscriptionAllDetails
                        userSubscriptionAll.price_details = response_promoCode.data.sr.subscription.price_details
                        await this.props.userSubscriptionDetailUpdate(userSubscriptionAll)

                    }
                    this.setState({isPromoCodeApplied:true,promoCode:obj,})
                }else {
                    this.setState({isPromoCodeApplied:false,promoCode:{}})
                }
                await this.setState({loaderFullScreen:false})
            }else {
                await  this.setState({isPromoCodeApplied:false,promoCode:{},promocodeError:true, promocodeMessage:response.data.sr.a12yErr.message, loaderFullScreen:false})
            }
        }catch (e) {
            console.error('error',e)
            await this.setState({loaderFullScreen:false})
        }
    }

    goToDocumnetInvoice = async () =>{
        await this.props.commingFromDependentInvoiceView(true)
    }


    render() {
        return (
           <div className="fullWidth">
               {this.state.isMyAccountView ?
                   <div className="myProfileAccount animationall lightBackgroundBlueColor expenseListScreenMain mainRightSidebare">
                       <div className='rightPanelWidhtSidebar'>
                           <div className="rightPanelWidhtSidebarContainer fullWidth">
                               <MyAccountViewComponent
                                   userInfo={this.props.userState.userInfo}
                                   loading={this.state.loading}
                                   dependent={this.props.dependent}
                                   userSubscription={this.props.userSubscriptionAllDetails}
                                   invoiceData={this.state.invoiceData}
                                   userPlan={this.props.userPlan}
                                   onEdit={this.onEditInfo}
                                   billingScreenEdit={this.billingScreenEdit}
                                   onlyBillingPromocodeEdit={this.onlyBillingPromocodeEdit}
                                   paymentMethod={this.props.paymentMethod}
                                   onCancel={this.handleCancelSubscription}
                                   goToDocumnetInvoice={this.goToDocumnetInvoice}
                               />
                           </div>
                       </div>
                   </div>
               :
                <div className=" myProfileAccount animationall mainRightSidebare">
                {/*<Drawer/>*/}
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer paddinBottom110 fullWidth" onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                        <div className='fullWidth myAccountMinHeight'>
                            <div className="titleRow margignTop768_13 margignBottom768_10">
                                <h2 className={`${!this.state.isPromocodeVisible?'titleWithSidebar df':'titleWithSidebar df paddingLeft35 titlePaddingNone'}`}>
                                       <span className="cursorPointer backArrow" onClick={this.onBackHandlerFomBilling}>
                                         <svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#112e53" />
                                         </svg>
                                       </span>
                                    <span>{A12Y_CONST.TEXT.TITLE_MY_ACCOUNT_EDIT}</span>
                                </h2>
                            </div>


                            <div className="tabBox fullWidth marginNone600">
                                {/*<div className="tabRow fullWidth">*/}
                                {/*    <ul className={Object.keys(this.props.dependent).length > 0 && !this.props.dependent.access.can_update ? 'twoChildLi' : ''}>*/}
                                {/*        <li onClick={() => this.tabcheck(true, false, false)} className={this.state.selectPersontalInfo?'active':null}> <span>{A12Y_CONST.TAB_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO}</span></li>*/}
                                {/*        {Object.keys(this.props.dependent).length >= 1 && !this.props.dependent.access.can_update ? null : <li onClick={() => this.tabcheck(false, false, true)} className={userInfo.phones && userInfo.phones.length > 0 && (Object.keys(this.props.dependent).length === 0 || (Object.keys(this.props.dependent).length > 0 && this.props.dependent.access.can_update)) ? this.state.selectBilling ? 'active': null : 'clickDisable'}><span>{A12Y_CONST.TAB_TEXT.GAURDIAN_PROFILE_BILLING}</span></li>}*/}
                                {/*        <li onClick={() => this.tabcheck(false, true, false)} className={this.state.selectPassword?'active': null}><span>{A12Y_CONST.TAB_TEXT.GAURDIAN_PROFILE_PASSWORD}</span></li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                {this.state.selectPersontalInfo ?
                                    <div className='tabContainer myAcHeight'>
                                        <MyAccountPersonalInformationComponent
                                            onSubmit={this.handelSubmit}
                                            userInfo={this.props.userState.userInfo}
                                            error={this.state.error}
                                            errorMessage={this.state.errorMessage}
                                            message={this.state.successMessage}
                                            loading={this.state.loading}
                                            isClickedOutside={this.state.isClickedOutside}
                                            isDataChanged={this.dataChangeHandler}
                                            onCancel={this.onBackHandler}
                                            onFlow={false}
                                        />
                                    </div>
                                    :
                                    null
                                }
                                {this.state.selectPassword ?
                                    <div className='tabContainer myAcHeight'>
                                        <MyAccountPasswordComponent
                                            onSubmit={this.handelUpdatePasswordSubmit}
                                            userInfo={this.props.userState.userInfo}
                                            error={this.state.error}
                                            errorMessage={this.state.errorMessage}
                                            message={this.state.successMessage}
                                            loading={this.state.loading}
                                            resetFields={this.state.resetFields}
                                            isDataChanged={this.dataChangeHandler}
                                            onCancel={this.onCancelHandler}
                                        />
                                    </div>
                                    :
                                    null
                                }
                                {this.state.selectBilling ?
                                    <div className='tabContainer myAcHeight fullWidth'>
                                        {/*<PhotoUpload/>*/}

                                        <Elements stripe={this.state.stripePromiseState} options={{appearance:{theme:"night", variables:{colorPrimary: '#0570de',
                                                    colorBackground: '#ffffff',
                                                    colorText: '#30313d',
                                                    colorDanger: '#df1b41',
                                                    fontSizeBase:'22px',
                                                    fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                                    spacingUnit: '2px',
                                                    borderRadius: '4px',}}}}>
                                            <BillingComponent
                                                onSubmit={this.updateBilling}
                                                loaderFullScreen={this.state.loaderFullScreen}
                                                isPromocodeVisible = {this.state.isPromocodeVisible}
                                                onCancel={this.handleCancelSubscription}
                                                // userSubscription={this.props.userSubscription}
                                                userSubscription={this.props?.userSubscription?.subscription_details}
                                                applyPromoCode={this.handelApplyPromoCode}
                                                userSubscriptionAllDetails={this.props?.userSubscriptionAllDetails}
                                                subscriptionProduct={this.props?.subscriptionProduct}
                                                userPlan={this.props.userPlan}
                                                subscription={this.props.subscription}
                                                paymentMethod={this.props.paymentMethod}
                                                loading={this.state.loading}
                                                error={this.state.error}
                                                errorMessage={this.state.errorMessage}
                                                message={this.state.successMessage}
                                                onContinue={this.onContinueHandel}
                                                myAccountViewSection={this.myAccountViewSection}
                                                showPopup={this.state.showPopup}
                                                invoiceData={this.state.invoiceData}
                                                isDataChanged={this.dataChangeHandler}
                                                onBack={this.onBackHandlerFomBilling}
                                                onFlow={false}
                                                goToDashBoardScreen={this.goToDashBoardScreen}
                                                isPromoCodeApplied={this.state.isPromoCodeApplied}
                                                discountAmt={this.state.discountAmt}
                                                promocodeMessage={this.state.promocodeMessage}
                                                promocodeError={this.state.promocodeError}
                                                promocodeInAmount={this.state.promocodeInAmount}
                                                couponAmount={this.state.couponAmount}
                                                resetPromocodeError={(status)=>{this.setState({promocodeError:false})}}
                                                planStatus={this.state.planStatus}

                                            />
                                        </Elements>

                                        {/*<img className='billingImage' src={billingImg} />*/}
                                    </div>
                                    :
                                    null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <EditChangesRemovePopup
                    popUpStatus={this.state.isPopupVisible}
                    hidePopUp={this.handleHidePopup}
                    resetAllFields={this.handleResetAllFields}
                />
            </div>
           }
           </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
    isError : state.userDetailReducer.isError,
    successMessage : state.userDetailReducer.successMessage,
    userInfo : state.userDetailReducer.userState.userInfo,
    isDataChanged : state.userDetailReducer.isDataChanged,
    dependent : state.dependentReducer.dependent,
    subscription : state.userDetailReducer.subscription,
    userSubscription : state.userDetailReducer.userSubscription,
    userSubscriptionAllDetails : state.userDetailReducer.userSubscriptionAllDetails,
    subscriptionProduct : state.userDetailReducer.subscriptionProduct,
    paymentMethod : state.userDetailReducer.paymentMethod,
    price_details : state.userDetailReducer.price_details,
    userPlan : state.userDetailReducer.userPlan,
    stripeConfig : state.userDetailReducer.stripeConfig,
    isMyAccountClicked : state.dependentReducer.isMyAccountClicked,
    isBillingScreen : state.userDetailReducer.isBillingScreen,
});

const mapDispatchToProps = dispatch => ({
    updateUserProfile : (data,email) => dispatch(userDetailActions.updateUserProfile(data,email)),
    updatePassword : (data) => dispatch(userDetailActions.updatePassword(data)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    createUserSubscription : (data) => dispatch(userDetailActions.createUserSubscription(data)),
    updatePaymentMethod : (data) => dispatch(userDetailActions.updatePaymentMethod(data)),
    cancelUserSubscription : (data) => dispatch(userDetailActions.cancelUserSubscription(data)),
    myAccountClicked : (status) => dispatch(dependentActions.myAccountClicked(status)),
    userSubscriptionDetailUpdate : (userSubscriptionAllDetails) => dispatch(userDetailActions.userSubscriptionDetailUpdate(userSubscriptionAllDetails)),
    goToBillingScreen : (status) => dispatch(userDetailActions.goToBillingScreen(status)),
    commingFromDependentInvoiceView : (status) => dispatch(dependentActions.commingFromDependentInvoiceView(status)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});


export default connect(mapStateToProps, mapDispatchToProps) (MyAccountScreen);

