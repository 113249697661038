import React, { useState, useEffect}from 'react';
import A12Y_CONST from '../common/a12yConst'

function  PopUpSignUpComponent (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    useEffect(()=>{
        if(props.popUpType){
            if (props.popUpType === A12Y_CONST.TEXT.REMOVED_ACCESS){
                setTitle(A12Y_CONST.TEXT.REMOVED_ACCESS)
                setMessage(props.PopUpMessage)
            }
            if (props.popUpType === A12Y_CONST.TEXT.TRY_AGAIN){
                setTitle(A12Y_CONST.TEXT.TRY_AGAIN)
                setMessage(props.PopUpMessage)
            }
        }

    },[props.title,props.message, props.popUpType,props.PopUpMessage]) // eslint-disable-line react-hooks/exhaustive-deps


    const hidePopUp = () => {
        props.hidePopUp()
    }
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className="cancelIcon fullWidth marginBottom30 marginTop30">
                                        <svg width="74" height="86" viewBox="0 0 74 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M39.9223 52.9015V61.2463C36.3093 59.9687 32.4408 59.5693 28.6442 60.1283C24.8516 60.6473 21.2346 62.0848 18.1047 64.2807C14.9787 66.4766 12.4239 69.4307 10.6633 72.8243C8.90275 76.218 7.98446 80.011 7.98446 83.8439H0C0 78.9729 1.11375 74.1418 3.25758 69.79C5.40539 65.3981 8.52331 61.565 12.3758 58.5706C16.2283 55.6161 20.7157 53.5399 25.4904 52.5418C30.2651 51.5836 35.2035 51.7037 39.9223 52.9015ZM31.9378 47.9108C18.7036 47.9108 7.98446 37.2107 7.98446 23.9554C7.98446 10.7001 18.7036 0 31.9378 0C45.1721 0 55.8912 10.7001 55.8912 23.9554C55.8912 37.2107 45.1721 47.9108 31.9378 47.9108ZM31.9378 39.9256C40.7607 39.9256 47.9067 32.779 47.9067 23.9554C47.9067 15.1318 40.7607 7.98513 31.9378 7.98513C23.115 7.98513 15.9689 15.1318 15.9689 23.9554C15.9689 32.779 23.115 39.9256 31.9378 39.9256ZM59.8834 66.237L68.3508 57.7323L74 63.4015L65.5285 71.8662L74 80.3309L68.3508 86L59.8834 77.4953L51.416 86L45.7669 80.3309L54.2384 71.8662L45.7669 63.4015L51.416 57.7323L59.8834 66.237Z" fill="#D83933"/>
                                        </svg>
                                    </div>
                                    <div className="areYouText paddingBottom10">
                                        {title}
                                    </div>
                                    <div className="popUpInviteUserMessage">
                                        {message}
                                    </div>
                                    <div className='fullWidth marginTop20 marginBottom40 texAlingCener'>
                                        <input id='signInButton' className={'button maxWidth138 borderRadius4 height44'} onClick={hidePopUp} type="button" value={A12Y_CONST.BUTTON_TEXT.OK}/>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpSignUpComponent;