import React,{Component} from 'react'
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions'
import A12Y_CONST from '../common/a12yConst'
import  * as dependentActions from '../redux/actions/dependentActions'
import DependentProfileViewComponent from '../components/DependentProfileViewComponent'
import DependentProfileEditScreen from '../screens/DependentProfileEditScreen'
import {secureClient} from "../config/axiosClient";
import DependentAddressEditScreen from '../screens/DependentAddressEditScreen'
import InviteUserScreen from '../screens/InviteUserScreen'
//eslint-disable-next-line
import newThemeStyle from "../assets/css/newThemeStyle.css"
import {Elements} from "@stripe/react-stripe-js";
import BillingComponent from "../components/BillingComponent";
//eslint-disable-next-line
import {loadStripe} from '@stripe/stripe-js';
// import MyAccountViewComponent from "../components/MyAccountViewComponent";
import { withRouter } from "react-router";
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
/*import {CommingFromDependentInvoiceView} from "../redux/actions/dependentActions";*/

class DependentProfileVIew extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            billingLoading:false,
            error:false,
            isEditMode:false,
            message:'',
            errorMessage:'',
            type:'general',
            isCreating:false,
            address:{},
            advocateTeamList:[],
            loaderFullScreen:false,
            isPromoCodeApplied:false,
            advocateTeamUpdate : {},
            isCreatingAdvocateTeam:false,
            addressLoader:false,
            advocateTeamLoader:false,
            prevDependentId:'',
            invoiceData:{},
            loadingFullScreen:false,
            stripePromiseState:null,
            promocodeError:false,
            hashBilling:false

        }
    }



    componentDidMount= async()=> {
        // const params = new URLSearchParams(this.props.location.search);
        // let screen = params.get('type');
        // this.state.isEditMode && this.state.type === 'billing'
        if(this.props.isBillingScreen || window.location.hash === A12Y_CONST.LABEL.HASH_BILLING){
           await this.setState({loadingFullScreen:true, isEditMode:false});
        }
        await this.loadInitialData();
        const stripePromise = loadStripe(this?.props?.stripeConfig?.stripe_publishable_key);
        await this.setState({stripePromiseState:stripePromise})
        if(this.props.isBillingScreen){
           await this.setState({isEditMode:true,type: 'billing', loadingFullScreen:false})
           this.props.goToBillingScreen(false)
        }

        // let userInfo =await this.props.userState.userInfo
        let userSubscription = this.props?.dependent?.subscirption?.subscription_details
        if(userSubscription !== undefined && userSubscription !== null && userSubscription?.subscription_status !== A12Y_CONST.TEXT.EXPIRED){
            await this.getInvoiceData()
        }

        if(window.location.hash === A12Y_CONST.LABEL.HASH_BILLING){
            await this.setState({type:'billing',isEditMode:true,loadingFullScreen:false,hashBilling:true})
        }
    }


    loadInitialData = async ()=>{
        this.setState({addressLoader:true,advocateTeamLoader:true,prevDependentId:this.props.dependent.id})
        if((this.props?.dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.ACTIVE_UPPER && !this.props?.dependent?.subscirption?.is_trial_expired ) || (!this.props?.dependent?.subscirption?.is_trial_expired && this.props?.dependent?.subscirption?.subscription_details?.subscription_status !== A12Y_CONST.TEXT.EXPIRED)){
            await this.getAllAddress();
        }

        this.setState({addressLoader:false,advocateTeamLoader:true})
        if((this.props?.dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.ACTIVE_UPPER && !this.props?.dependent?.subscirption?.is_trial_expired ) || (!this.props?.dependent?.subscirption?.is_trial_expired && this.props?.dependent?.subscirption?.subscription_details?.subscription_status !== A12Y_CONST.TEXT.EXPIRED)){
            await this.getAdvocateTeamInviteListe()
            }
        this.setState({advocateTeamLoader:false})
    }

    componentDidUpdate = async ()=> {
        if(this.state.prevDependentId !== this.props.dependent.id){
            await this.loadInitialData();
        }
    }

    getAllAddress = async()=>{
        if(this.props.dependent){
            await this.props.getAddress(this.props.dependent.advocate_id,this.props.dependent.id)
        }
    }

    editHandler = async() =>{
        this.setState({isEditMode:true,type:'general'})
    }

    onCancelHandler=()=>{
        this.setState({isEditMode:false})
    }

    onAddressEditHandler = async(address)=>{
        this.setState({isEditMode:true,type:'address',isCreating:false,address:address})
    }

    onAdvocateTeamResendHandler = async (advocateTeam) => {
        await this.setState({isEditMode:true, type:'invite', isCreatingAdvocateTeam:false, advocateTeamUpdate:advocateTeam})
    }

    onAddressDeleteHandler = async(address)=>{
        try {
            this.setState({addressLoader:true})
            this.setState({loading:true,error:false,message:''})
            let url = A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+A12Y_CONST.API_ENDPOINT.ADDRESSES+'/'+address.id
            let res = await secureClient.delete(url)
            this.setState({loading:false,addressLoader:false})
            if (!res.data.error && res.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.getAllAddress();
            }else {
                this.setState({error:true,message:'',errorMessage:res.data.sr?.a12yErr?.message})
            }
            await this.setState({addressLoader:false})
        }catch (e) {
            this.props.sendErrorReportToServer(A12Y_CONST.ERROR_MESSAGE.DELETE_ADDRESS,e);
            this.setState({error:true,message:'',errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG})
        }

    }
    getAdvocateTeamInviteListe = async()=>{
        try {
            this.setState({advocateTeamLoader:true,error:false,message:''})
            let url = A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.TEAMS;
            let res = await secureClient.post(url)
            if (!res.data.error && res.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let teamList = res.data.sr.invitations
                this.setState({advocateTeamList:teamList})
            }else {
                this.setState({error:true,message:'',errorMessage:res.data.sr?.a12yErr?.message})
            }
            await this.setState({advocateTeamLoader:false})

        }catch (e) {
            this.props.sendErrorReportToServer(A12Y_CONST.ERROR_MESSAGE.GET_ADVOCATE_TEAM_LIST,e);
            this.setState({error:true,message:'',errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG})
        }
    }
    handleDeleteInviteUser = async (inviteUserId) => {
        try {
            let data ={"invitation_id":inviteUserId}
            this.setState({advocateTeamLoader:true})
            this.setState({loading:true,error:false,message:''})
            let res = await secureClient.post(A12Y_CONST.API_ENDPOINT.REVOKE_INVITATION, data);
            //this.setState({loading:false,advocateTeamLoader:false})
            if (!res.data.error && res.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let teamList = res.data.sr.invitations
                this.setState({advocateTeamList:teamList})
               await this.getAdvocateTeamInviteListe();
            }else {
                this.setState({error:true,message:'',errorMessage:res.data.sr?.a12yErr?.message})
                await this.setState({advocateTeamLoader:false,loading:false})
            }
            await this.setState({advocateTeamLoader:false,loading:false})
        }catch (e) {
            this.props.sendErrorReportToServer(A12Y_CONST.ERROR_MESSAGE.DELETE_INVITE_USER,e);
            this.setState({error:true,message:'',errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG})
        }
    }
    onCreateAddressHandler =()=>{
        this.setState({isEditMode:true,type:'address',isCreating:true,address:{}})
    }
    onInviteUserHandler  = async () => {
    await this.setState({isEditMode:true,type:'invite',isCreatingAdvocateTeam:true, advocateTeamUpdate:{}})
    }
    billingSectionSelect  = async () => {
    await this.setState({type:'billing',isEditMode:true})
    this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT_BILLING)

    }

    onAddressCancel = () =>{
        this.setState({isEditMode:false})
    }
    reDirectToProfileViewScreen = () =>{
        this.setState({isEditMode:false})
    }
    onDonSuccessButton = () =>{
        this.setState({isEditMode:false})
        this.getAdvocateTeamInviteListe();
    }

    reloadHandler = async() =>{
        await this.getAllAddress();
        this.setState({isEditMode:false})
    }

    toastHandler = (message) =>{
        this.setState({message:message})
        setTimeout(()=>{

        },500)
        this.onCancelHandler();
    }
    overlay = (status) => {
        this.props.overlay(status)
    }

    goToDocumnet = async () =>{
        await this.props.isCommingFromDependentViewLegal(true)
    }

    goToDocumnetInvoice = async () =>{
        await this.props.commingFromDependentInvoiceView(true)
    }

    updateBilling = async(data)=>{
        // let userInfo =await this.props.userState.userInfo
        this.setState({error:false})
        let obj = {
            "payment_method" : data.payMethod,
            "email" : this.props.userState.userInfo.email,
            "user_id" : this.props.userState.userInfo.id,
            'dependent_id':this.props.dependent.id,
            "price_id":this.props?.subscriptionProduct[0]?.plans[0]?.id,
            // "stripe_customer_id":this.props?.dependent?.subscirption?.subscription_details?.external_system_customer_id
            "stripe_customer_id":this.props?.dependent?.subscirption?.subscription_details?.external_system_customer_id

        }
        await this.setState({loading:true})
        if(this.state.isPromoCodeApplied && Object.keys(this.state.promoCode).length > 0){
            obj.promotion_code = this.state.promoCode.id
        }
        if(this.props?.dependent?.subscirption?.payment_method !== null && this.props?.dependent?.subscirption?.payment_method !== undefined){
            obj.existing_payment_method_id = this.props?.dependent?.subscirption?.payment_method.default_payment_method_id
        }
        await this.props.updatePaymentMethod(obj);
        // if(data.editMode) {
        //     // obj.stripe_customer_id = this.props.paymentMethod.external_customer_id
        //     if(this.props?.dependent?.subscirption?.payment_method !== null && this.props?.dependent?.subscirption?.payment_method !== undefined)obj.existing_payment_method_id = this.props?.dependent?.subscirption?.payment_method.default_payment_method_id
        //     await this.props.updatePaymentMethod(obj);
        // }else{
        //     await this.props.createUserSubscription(obj);
        // }
        await this.setState({loading:false,isPromoCodeApplied:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
        }else {
            let dependent = this.props.dependent
            dependent.subscirption.trial_period = false;
            dependent.subscirption.is_trial_expired = false;
            dependent.subscirption.subscription_details = this.props.userSubscription;
            dependent.subscirption.payment_method = this.props.paymentMethod;
            dependent.subscirption.plan_name = this.props.planName;
           await this.props.updateDependentProps(dependent)
            this.setState({successMessage:this.props.successMessage,resetFields:true,error:false,showPopup:true})
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);

        let userSubscription = this.props?.dependent?.subscirption?.subscription_details
        if((userSubscription !== undefined && userSubscription !== null && userSubscription?.subscription_status !== A12Y_CONST.TEXT.EXPIRED)){
            await this.getInvoiceData()
        }
    }

    handleCancelSubscription = async() =>{

        this.setState({error:false})
        let obj = {
            "stripe_customer_id" : this.props?.dependent?.subscirption?.payment_method?.external_customer_id,
            "subscription_id" : this.props?.dependent?.subscirption?.subscription_details?.subscription_id,
            "user_id" : this.props?.userState.userInfo.id
        }
        await this.setState({billingLoading:true})
        await this.props.cancelUserSubscription(obj);
        this.setState({billingLoading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
        }else {
            let dependent = this.props.dependent
            dependent.subscirption.subscription_details = this.props?.userSubscription;
            await this.props.updateDependentProps(dependent)
            this.setState({successMessage:this.props.successMessage,resetFields:true,error:false})
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }

    onContinueHandel =()=>{
        this.setState({showPopup:false})
        this.setState({isEditMode:false})
        this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)

    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }
    onBackHandler = async ()=>{

        if(window.location.hash === A12Y_CONST.LABEL.HASH_BILLING){
            await this.setState({isEditMode:false})
            this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)
        }else{
            this.setState({isPromoCodeApplied:false})
            if(this.props?.dependent?.subscirption?.is_trial_expired || this.props?.dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED || this.props?.dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT){
                this.goToDashBoardScreen()
            }else{
                await this.setState({isEditMode:false});
            }
            await this.setState({promocodeError:false})
        }
    }

    getInvoiceData = async () =>{
        // if(this.props.userSubscription !== undefined && this.props.paymentMethod !== undefined){
        if(this.props?.dependent?.subscirption?.subscription_details !== undefined && this.props?.dependent?.subscirption?.payment_method !== undefined){
            let response  = await secureClient.put(A12Y_CONST.API_ENDPOINT.GET_INVOICES,{
                "stripe_customer_id" : this.props?.dependent?.subscirption?.payment_method?.external_customer_id,
                "subscription_id" : this.props?.dependent?.subscirption?.subscription_details.subscription_id
            })
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr.invoices.data.length > 0){
                    this.setState({invoiceData:response.data.sr.invoices.data[0]})
                }
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            }
        }
    }
    goToDashBoardScreen = () => {
        this.props.history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW);
    }
    handelApplyPromoCode = async (promoCode)=>{
        await this.setState({loaderFullScreen:true,promocodeMessage:'', promocodeError:false})
        try{
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.VALIDATE_PROMOCODE,{code:promoCode, product_id:this.props.subscriptionProduct[0].id});
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response?.data?.sr?.promotion_code?.data[0]?.active){
                    let obj = response?.data?.sr?.promotion_code?.data[0];
                    if(obj.coupon.amount_off !== null && Object.keys(this.props.subscriptionProduct[0].plans).length > 0){
                        let discountPrice = obj.coupon.amount_off/100
                        this.setState({discountAmt:this.props.subscriptionProduct[0].plans[0].plan_price - discountPrice, promocodeInAmount:true, promocodeMessage:A12Y_CONST.TEXT.OFFER_HAS_BEEN_APPLIED, couponAmount: discountPrice})
                    }else {
                        let discountPrice =  ((obj.coupon.percent_off/ 100) * this.props.subscriptionProduct[0].plans[0].plan_price).toFixed(2)
                        this.setState({discountAmt:this.props.subscriptionProduct[0].plans[0].plan_price - discountPrice, promocodeInAmount:false, promocodeMessage:A12Y_CONST.TEXT.OFFER_HAS_BEEN_APPLIED, couponAmount: obj.coupon.percent_off})
                    }
                    this.setState({isPromoCodeApplied:true,promoCode:obj,})
                }else {
                    this.setState({isPromoCodeApplied:false,promoCode:{}})
                }
                await this.setState({loaderFullScreen:false})
            }else {
                await  this.setState({isPromoCodeApplied:false,promoCode:{},promocodeError:true, promocodeMessage:response.data.sr.a12yErr.message, loaderFullScreen:false})
            }
        }catch (e) {
            console.error('error',e)
            await this.setState({loaderFullScreen:false})
        }
    }
    render() {
        return (
            ((this.state.loadingFullScreen) || (this.state.stripePromiseState === null && this.state.isEditMode && this.state.type === 'billing')?
                <LoaderFullScreen/>
                :
            (this.state.isEditMode && this.state.type === 'general'?
                    <DependentProfileEditScreen
                        onCancel={this.onCancelHandler}
                        showToast={this.toastHandler}
                        overlay = {this.overlay}
                    />
                    :
                (this.state.isEditMode && this.state.type === 'address' ?
                        <DependentAddressEditScreen
                            onCancel={this.onAddressCancel}
                            isCreating={this.state.isCreating}
                            address={this.state.address}
                            reloadData={this.reloadHandler}
                        />
                        :
                        (this.state.isEditMode && this.state.type === 'invite' ?
                            <InviteUserScreen
                                advocateTeamUpdate={this.state.advocateTeamUpdate}
                                onCancel={this.reDirectToProfileViewScreen}
                                onDonSuccessButton={this.onDonSuccessButton}
                                isCreatingAdvocateTeam={this.state.isCreatingAdvocateTeam}
                            />
                        :
                        (this.state.stripePromiseState !== null && this.state.isEditMode && this.state.type === 'billing' ?
                                        <div className='tabContainer myAcHeight fullWidth'>
                                            {/*<PhotoUpload/>*/}
                                            <div className="titleRow margignTop768_13 margignBottom768_10">
                                                <h2 className='titleWithSidebar df'>
                                                   <span className="cursorPointer backArrow" onClick={this.onBackHandler}>
                                                     <svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#112e53" />
                                                     </svg>
                                                   </span>
                                                    <span>{A12Y_CONST.TEXT.BILLING}</span>
                                                </h2>
                                            </div>
                                            {/*<Elements stripe={this.state.stripePromiseState} options={{appearance:{theme:"night", variables:{colorPrimary: '#0570de',*/}
                                            {/*            colorBackground: '#ffffff',*/}
                                            {/*            colorText: '#30313d',*/}
                                            {/*            colorDanger: '#df1b41',*/}
                                            {/*            fontSizeBase:'22px',*/}
                                            {/*            fontFamily: 'Ideal Sans, system-ui, sans-serif',*/}
                                            {/*            spacingUnit: '2px',*/}
                                            {/*            borderRadius: '4px',}}}}>*/}
                                            {/*    <BillingComponent*/}
                                            {/*        onSubmit={this.updateBilling}*/}
                                            {/*        dependent={this.props.dependent}*/}
                                            {/*        onCancel={this.handleCancelSubscription}*/}
                                            {/*        userSubscription={this.props.userSubscription}*/}
                                            {/*        userPlan={this.props.userPlan}*/}
                                            {/*        subscriptionProduct={this.props.subscriptionProduct}*/}
                                            {/*        paymentMethod={this.props.paymentMethod}*/}
                                            {/*        loading={this.state.loading}*/}
                                            {/*        error={this.state.error}*/}
                                            {/*        errorMessage={this.state.errorMessage}*/}
                                            {/*        message={this.state.successMessage}*/}
                                            {/*        onContinue={this.onContinueHandel}*/}
                                            {/*        showPopup={this.state.showPopup}*/}
                                            {/*        invoiceData={this.state.invoiceData}*/}
                                            {/*        isDataChanged={this.dataChangeHandler}*/}
                                            {/*        onBack={this.onBackHandler}*/}
                                            {/*        goToDashBoardScreen={this.goToDashBoardScreen}*/}
                                            {/*        onFlow={false}*/}
                                            {/*    />*/}
                                            {/*</Elements>*/}
                                            <Elements stripe={this.state.stripePromiseState} options={{appearance:{theme:"night", variables:{colorPrimary: '#0570de',
                                                        colorBackground: '#ffffff',
                                                        colorText: '#30313d',
                                                        colorDanger: '#df1b41',
                                                        fontSizeBase:'22px',
                                                        fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                                        spacingUnit: '2px',
                                                        borderRadius: '4px',}}}}>
                                                <BillingComponent
                                                    onSubmit={this.updateBilling}
                                                    dependent={this.props.dependent}
                                                    onCancel={this.handleCancelSubscription}
                                                    // userSubscription={this.props.userSubscription}
                                                    userSubscription={this.props?.dependent?.subscirption?.subscription_details}
                                                    subscription={this.props.subscription}
                                                    userPlan={this.props?.subscriptionProduct}
                                                    subscriptionProduct={this.props?.subscriptionProduct}
                                                    paymentMethod={this.props?.paymentMethod}
                                                    loading={this.state.loading}
                                                    error={this.state.error}
                                                    onFlow={false}
                                                    onBack={this.onBackHandler}
                                                    goToDashBoardScreen={this.goToDashBoardScreen}
                                                    errorMessage={this.state.errorMessage}
                                                    message={this.state.successMessage}
                                                    onContinue={this.onContinueHandel}
                                                    showPopup={this.state.showPopup}
                                                    invoiceData={this.state.invoiceData}
                                                    isDataChanged={this.dataChangeHandler}
                                                    addDependentTabActive={this.addDependentTabActive}
                                                    applyPromoCode={this.handelApplyPromoCode}
                                                    isPromoCodeApplied={this.state.isPromoCodeApplied}
                                                    discountAmt={this.state.discountAmt}
                                                    promocodeMessage={this.state.promocodeMessage}
                                                    promocodeError={this.state.promocodeError}
                                                    promocodeInAmount={this.state.promocodeInAmount}
                                                    couponAmount={this.state.couponAmount}
                                                    loaderFullScreen={this.state.loaderFullScreen}
                                                    resetPromocodeError={(status)=>{this.setState({promocodeError:false})}}
                                                />
                                            </Elements>


                                            {/*<img className='billingImage' src={billingImg} />*/}
                                        </div>
                                        :
                        (!this.state.isEditMode ?
                                <DependentProfileViewComponent
                                    dependent={this.props.dependent}
                                    onEdit={this.editHandler}
                                    addressList={this.props.addressList}
                                    onAddressEdit={this.onAddressEditHandler}
                                    onAddressDelete={this.onAddressDeleteHandler}
                                    onCreateAddress={this.onCreateAddressHandler}
                                    onInviteUser={this.onInviteUserHandler}
                                    sectionType={this.state.type}
                                    message={this.state.message}
                                    error={this.state.error}
                                    errorMessage={this.state.errorMessage}
                                    advocateTeamList={this.state.advocateTeamList}
                                    advocateTeamResend={this.onAdvocateTeamResendHandler}
                                    loaderFullScreen={this.state.loaderFullScreen}
                                    handleDeleteInviteUser = {this.handleDeleteInviteUser}
                                    goToDocumnet={this.goToDocumnet}
                                    goToDocumnetInvoice={this.goToDocumnetInvoice}
                                    addressLoader={this.state.addressLoader}
                                    advocateTeamLoader={this.state.advocateTeamLoader}
                                    editBilling={this.billingSectionSelect}
                                    billingLoading={this.state.billingLoading}
                                    stripePromise = {loadStripe(this?.props?.stripeConfig?.stripe_publishable_key)}
                                    userSubscription={this.props.userSubscription}
                                    subscription={this.props.subscription}
                                    invoiceData={this.state.invoiceData}
                                    userPlan={this.props.userPlan}
                                    // onEdit={this.onEditInfo}
                                    paymentMethod={this.props.paymentMethod}
                                    onCancel={this.handleCancelSubscription}
                                />
                                :
                                null
                        )
                )

             )
            )
           )
         )
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
    addressList : state.dependentReducer.addressList,
    dependent : state.dependentReducer.dependent,
    userSubscription : state.userDetailReducer.userSubscription,
    subscription : state.userDetailReducer.subscription,
    planName : state.userDetailReducer.planName,
    paymentMethod : state.userDetailReducer.paymentMethod,
    userPlan : state.userDetailReducer.userPlan,
    subscriptionProduct : state.userDetailReducer.subscriptionProduct,
    stripeConfig : state.userDetailReducer.stripeConfig,
    isDataChanged : state.userDetailReducer.isDataChanged,
    userInfo : state.userDetailReducer.userState.userInfo,
    isBillingScreen : state.userDetailReducer.isBillingScreen,
    isError : state.userDetailReducer.isError,
});

const mapDispatchToProps = dispatch => ({
    login : (username,password) => dispatch(userDetailActions.login(username,password)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    getAllDependent : (advocate_id) => dispatch(dependentActions.getAllDependent(advocate_id)),
    isCommingFromDependentViewLegal : (status) => dispatch(dependentActions.isCommingFromDependentViewLegal(status)),
    commingFromDependentInvoiceView : (status) => dispatch(dependentActions.commingFromDependentInvoiceView(status)),
    getAddress : (advocate_id,dependent_id) => dispatch(dependentActions.getAddress(advocate_id,dependent_id)),
    createAddress : (advocate_id,dependent_id,data) => dispatch(dependentActions.createAddress(advocate_id,dependent_id)),
    createUserSubscription : (data) => dispatch(userDetailActions.createUserSubscription(data)),
    updatePaymentMethod : (data) => dispatch(userDetailActions.updatePaymentMethod(data)),
    cancelUserSubscription : (data) => dispatch(userDetailActions.cancelUserSubscription(data)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    goToBillingScreen : (status) => dispatch(userDetailActions.goToBillingScreen(status)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DependentProfileVIew));


