import A12Y_CONST from '../common/a12yConst'

export async function titleRoutePath(key){
    let titleRoutesName = new Map();
    titleRoutesName.set(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT,'My Account')
    titleRoutesName.set(A12Y_CONST.ROUTE.DASHBOARD,'Dashboard')
    titleRoutesName.set(A12Y_CONST.ROUTE.LOGOUT,'Logout')
    titleRoutesName.set(A12Y_CONST.ROUTE.DEPENDENT,'Dependent')
    titleRoutesName.set(A12Y_CONST.ROUTE.DEPENDENT_VIEW,'Dependent')
    titleRoutesName.set(A12Y_CONST.ROUTE.EXPENSES,'Expense')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_EXPENSE,'Expense')
    titleRoutesName.set(A12Y_CONST.ROUTE.EXPENSE_VIEW,'Expense')
    titleRoutesName.set(A12Y_CONST.ROUTE.INCOMES,'Income')
    titleRoutesName.set(A12Y_CONST.ROUTE.INCOME_VIEW,'Income')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_INCOME,'Income')
    titleRoutesName.set(A12Y_CONST.ROUTE.DOCUMENTS,'Documents')
    titleRoutesName.set(A12Y_CONST.ROUTE.ACTIVITIES,'Activity')
    titleRoutesName.set(A12Y_CONST.ROUTE.ACTIVITY_VIEW,'Activity')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_ACTIVITY,'Activity')
    titleRoutesName.set(A12Y_CONST.ROUTE.HOME,'Login')
    titleRoutesName.set(A12Y_CONST.ROUTE.LOGIN,'Login')
    titleRoutesName.set(A12Y_CONST.ROUTE.SIGNUP,'Register')
    titleRoutesName.set(A12Y_CONST.ROUTE.CODE,'Code')
    titleRoutesName.set(A12Y_CONST.ROUTE.SIGN_UP_EMAIL_VARIFICATION,'Verify Account')
    titleRoutesName.set(A12Y_CONST.ROUTE.FORGOT_PASSWORD_EMAIL,'Forgot Password')
    titleRoutesName.set(A12Y_CONST.ROUTE.FORGOT_PASSWORD_VARIFICATION_CODE,'Code Verify')
    titleRoutesName.set(A12Y_CONST.ROUTE.FORGOT_CREATE_PASSWORD,'Create Password')
    titleRoutesName.set(A12Y_CONST.ROUTE.FORGOT_PASSWORD_UPDATED,'Forgot Password Update')
    titleRoutesName.set(A12Y_CONST.ROUTE.EMAIL_LOGIN,'Verified Account Login')
    titleRoutesName.set(A12Y_CONST.ROUTE.PASSWORD_UPDATED,'Update Password')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_ACTIVITY,'Activity')
    titleRoutesName.set(A12Y_CONST.ROUTE.INVENTORY_VIEW,'Inventory')
    titleRoutesName.set(A12Y_CONST.ROUTE.INVENTORYVIEW,'Inventory')
    titleRoutesName.set(A12Y_CONST.ROUTE.INVENTORY,'Inventory')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_ASSET,'Inventory')
    titleRoutesName.set(A12Y_CONST.ROUTE.EDIT_ASSET,'Inventory')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_INSTITUTION,'Add Institution')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_CONTACT,'Add Contact')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_BENEFICIARY,'Add Beneficiary')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_CO_OWNER,'Add CoOwner')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_LOAN,'Loan Against Policy')
    titleRoutesName.set(A12Y_CONST.ROUTE.MEDICAL,'Medical')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_MEDICATION,'Add Medicine')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_DOCTOR,'Add Provider')
    titleRoutesName.set(A12Y_CONST.ROUTE.RELEASE_NOTES,'Release Notes')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_DEBT,'Add Debt')
    titleRoutesName.set(A12Y_CONST.ROUTE.DEBT_VIEW,'Debt')
    titleRoutesName.set(A12Y_CONST.ROUTE.EDIT_DEBT,'Edit Debt')
    titleRoutesName.set(A12Y_CONST.ROUTE.EDIT_CONTACT,'Edit Contact')
    titleRoutesName.set(A12Y_CONST.ROUTE.EDIT_BENEFICIARY,'Edit Beneficiary')
    titleRoutesName.set(A12Y_CONST.ROUTE.EDIT_CO_OWNER,'Edit Co-Owner')
    titleRoutesName.set(A12Y_CONST.ROUTE.MEDICATION_VIEW,'Medication')
    titleRoutesName.set(A12Y_CONST.ROUTE.DOCTOR_VIEW,'Provider')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW,'New Account')
    titleRoutesName.set(A12Y_CONST.ROUTE.EDIT_DOCTOR,'Edit Provider')
    titleRoutesName.set(A12Y_CONST.ROUTE.DASHBOARD_VIEW,'Dashboard')
    titleRoutesName.set(A12Y_CONST.ROUTE.SIGNUP_EMAIL,'EMAIL Register')
    titleRoutesName.set(A12Y_CONST.ROUTE.ADD_ADDRESS,'Inventory')
    titleRoutesName.set(A12Y_CONST.ROUTE.DEPENDENT_BILLING,'My Account')
    titleRoutesName.set(A12Y_CONST.ROUTE.ABONELAW,'Abonelaw Register')
    return titleRoutesName.get(key);
}