import React, { useState,useEffect } from 'react'
import { useHistory } from "react-router-dom";
import A12Y_CONST from "../common/a12yConst";
import PopUpIncomeExpComponent from './PopUpIncomeExpComponent'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import LoaderFullScreen from "./LoaderFullScreenComponent";
import NotesComponent from "./NotesComponent";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {ddmmyyyyFormat} from "../util/date_format";
import moment from "moment";

function ActivityViewComponent(props) {
    const history = useHistory();
    const [error, setErrors] = useState(false);
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState('');

    const [isCommentTabVisible, setIsCommentTabVisible] = useState(true);

    useEffect(()=>{
        setErrors(props.error)
        setLoading(props.loading);
        setLoaderFullScreenVisible(props.loading)
    },[props.error, props.loading])

    function goBack() {
        history.push("/activities");
    }

    function deleteActivity() {
        setIsPopUp(true)
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.onDelete()
    }

    function editActivity() {
        props.onEdit();
    }


    function goToExpenseScreen(expense){
        props.goToExpense(expense)
    }

    useEffect(() => {
        if(props?.activityObj?.web_url){
            let link = (props?.activityObj?.web_url.indexOf('://') === -1) ? 'http://' + props.activityObj?.web_url : props.activityObj?.web_url;
            setWebsiteUrl(link)
        }else{
            setWebsiteUrl('')
        }
    },[props?.activityObj, props?.activityObj?.web_url])



    // Note Tab code
    const tabCheckBox = (status) => {
        setIsCommentTabVisible(status)
    }
    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addActivitiesWrap">
            {loaderFullScreenVisible ?
                <LoaderFullScreen />
                :
                null
            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h2 className="titleWithSidebar titleMarginBottomViewScreen marginBottom30 viewActivityTitleError">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage} />
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message} />

                            :
                            null
                    )

                }

                <span id={A12Y_CONST.BUTTON_ID.ACTIVITY_VIEW_BACK} className="cursorPointer" onClick={goBack}><svg width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span className="marginLeft10">{A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL}</span></span>
                {props.dependent?.access && props.dependent.access.can_update === true ?
                    <div className="actionButton hideDesktopShowMobile">
                        <button className="btn-primary mobileEditViewButton saveButton viewEditButton" onClick={editActivity}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z"
                                    fill="#2D6CB2"/>
                            </svg>
                            <span className="paddingLeft1">{A12Y_CONST.LABEL.DEPENDENT_EDIT}</span>
                        </button>
                    </div>
                    :
                    null
                }
            </h2>
            <div className="dependentBorderBox dependentDefauldMinHeight activityViewMain pad_0_5">
                <div className="df aic marginBottom20">
                    <h4 className="dependentSubTitle"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'>{A12Y_CONST.LABEL.APOSTROPHE_S} {A12Y_CONST.TEXT.ACTIVITY}</span></h4>

                    <div className="mla desktopCol">
                        {props.dependent?.access && props.dependent.access.can_update === true ?
                        <>
                            <button id={A12Y_CONST.BUTTON_ID.EDIT_ACTIVITY} className="btn-primary viewEditButton saveButton marginRight20" onClick={editActivity}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2" />
                                </svg>
                                <span className="paddingLeft1">{A12Y_CONST.TEXT.EDIT}</span>
                            </button>
                            <button  id={A12Y_CONST.BUTTON_ID.DELETE_ACTIVITY} className="cancelButton1 viewDeleteButton" onClick={deleteActivity}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D96941" />
                                </svg>
                                <span className="paddingLeft1">{A12Y_CONST.TEXT.DELETE}</span>
                            </button>
                        </>
                            :
                            null
                        }
                    </div>
                    {/*{props.dependent?.access && props.dependent.access.can_update === true ?*/}
                    {/*    <div className="mla actionButton mobileCol">*/}
                    {/*            <span onClick={editActivity}>*/}
                    {/*        <svg width="15" height="16" viewBox="0 0 15 16" fill="none"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                    {/*          <path*/}
                    {/*              d="M10.4943 0.390682C10.8022 0.0820122 11.3027 0.0820122 11.6106 0.390682L14.7685 3.55247C15.0772 3.86114 15.0772 4.36166 14.7685 4.67033L4.50547 14.9462C4.35784 15.0944 4.15652 15.1777 3.94732 15.1777H0.789463C0.353443 15.1777 0 14.8238 0 14.3872V11.2254C0 11.0158 0.0832095 10.8147 0.231234 10.6665L8.12595 2.76218L10.4943 0.390682ZM8.6841 4.4388L1.57893 11.5528V13.5968H3.62048L10.7256 6.48274L8.6841 4.4388ZM11.8419 5.36489L13.094 4.1114L11.0525 2.06746L9.8004 3.32095L11.8419 5.36489Z"*/}
                    {/*              fill="#2D6CB2" fillOpacity="0.99"/>*/}
                    {/*        </svg>*/}
                    {/*    </span>*/}
                    {/*        <span onClick={deleteActivity}>*/}
                    {/*        <svg width="16" height="17" viewBox="0 0 16 17" fill="none"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path*/}
                    {/*                d="M4 1.76115C4 0.876382 4.71634 0.15918 5.6 0.15918H10.4C11.2837 0.15918 12 0.876382 12 1.76115V3.36312H13.5918C13.5967 3.36304 13.6017 3.36304 13.6066 3.36312H15.2C15.6418 3.36312 16 3.72172 16 4.16411C16 4.60649 15.6418 4.96509 15.2 4.96509H14.3449L13.651 14.6911C13.5912 15.5293 12.8946 16.1789 12.0551 16.1789H3.9449C3.10547 16.1789 2.40877 15.5293 2.34896 14.6911L1.6551 4.96509H0.8C0.358176 4.96509 0 4.60649 0 4.16411C0 3.72172 0.358176 3.36312 0.8 3.36312H2.39339C2.39836 3.36304 2.40331 3.36304 2.40826 3.36312H4V1.76115ZM5.6 3.36312H10.4V1.76115H5.6V3.36312ZM3.25918 4.96509L3.9449 14.5769H12.0551L12.7408 4.96509H3.25918ZM6.4 6.56707C6.84184 6.56707 7.2 6.92567 7.2 7.36805V12.174C7.2 12.6163 6.84184 12.975 6.4 12.975C5.95818 12.975 5.6 12.6163 5.6 12.174V7.36805C5.6 6.92567 5.95818 6.56707 6.4 6.56707ZM9.6 6.56707C10.0418 6.56707 10.4 6.92567 10.4 7.36805V12.174C10.4 12.6163 10.0418 12.975 9.6 12.975C9.15816 12.975 8.8 12.6163 8.8 12.174V7.36805C8.8 6.92567 9.15816 6.56707 9.6 6.56707Z"*/}
                    {/*                fill="#D83933"/>*/}
                    {/*        </svg>*/}
                    {/*    </span>*/}
                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}

                </div>
                <div className="activityDetails expenseDetails invBox">
                    <div className="df">
                        <label>{A12Y_CONST.LABEL.ACTIVITY_TYPE}</label>
                         <div>{props.activityObj.activity_type}</div>
                    </div>
                    <div className="df">
                        <label className='textTransferUppercase'>{A12Y_CONST.LABEL.DESCRIPTION}</label>
                        <div>{props.activityObj.descriptions}</div>
                    </div>
                   <div className="fullWidth">
                    <div className="df width50">
                        <label>{A12Y_CONST.LABEL.DATE}</label>
                         <div>{props.activityObj.date_of_activity}</div>
                    </div>
                    <div className="df width50 durationMarginTop20">
                        <label>{A12Y_CONST.LABEL.DURATION}</label>
                         <div>{props.activityObj.activity_duration} {A12Y_CONST.LABEL.HOURS}</div>
                    </div>
                   </div>
                    {/*<div className="df clearBoth">
                        <label>{A12Y_CONST.LABEL.EXPENSE}</label>
                        <div>{props.activityObj.expenses && props.activityObj.expenses.length > 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.activityObj.expenses.map(item => item.amount).reduce((prev, next) => prev + next)) : '$'+0 } </div>
                    </div>*/}
                    <div className="df clearBoth">
                        <label>{A12Y_CONST.LABEL.TAG}</label>
                         <div>
                            <span className={`tag tag-${props.activityObj.tag}`}><span></span></span>
                        </div>
                    </div>
                    {websiteUrl ?
                        <div className="df">
                            <a id={A12Y_CONST.TEXT.LINK_URL} rel="noreferrer" className='linkToWebsite df alignCenterVH' target="_blank" href={websiteUrl}>
                                <svg className='marginRight10' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <path d="M9.16667 18.3333C4.10392 18.3333 0 14.2294 0 9.16667C0 4.10392 4.10392 0 9.16667 0C14.2294 0 18.3333 4.10392 18.3333 9.16667C18.3333 14.2294 14.2294 18.3333 9.16667 18.3333ZM7.0675 16.1947C6.16313 14.2765 5.63922 12.201 5.52475 10.0833H1.89017C2.06854 11.494 2.65276 12.8225 3.57185 13.9074C4.49095 14.9923 5.70535 15.7869 7.0675 16.1947ZM7.36083 10.0833C7.49925 12.3191 8.13817 14.4192 9.16667 16.2727C10.223 14.3702 10.84 12.2554 10.9725 10.0833H7.36083ZM16.4432 10.0833H12.8086C12.6941 12.201 12.1702 14.2765 11.2658 16.1947C12.628 15.7869 13.8424 14.9923 14.7615 13.9074C15.6806 12.8225 16.2648 11.494 16.4432 10.0833ZM1.89017 8.25H5.52475C5.63922 6.1323 6.16313 4.05688 7.0675 2.13858C5.70535 2.54639 4.49095 3.34102 3.57185 4.42594C2.65276 5.51085 2.06854 6.83934 1.89017 8.25ZM7.36175 8.25H10.9716C10.8394 6.07805 10.2226 3.96324 9.16667 2.06067C8.11037 3.96316 7.49334 6.07798 7.36083 8.25H7.36175ZM11.2658 2.13858C12.1702 4.05688 12.6941 6.1323 12.8086 8.25H16.4432C16.2648 6.83934 15.6806 5.51085 14.7615 4.42594C13.8424 3.34102 12.628 2.54639 11.2658 2.13858Z" fill="#2F6DB3"/>
                                </svg>
                                {A12Y_CONST.TEXT.LINK_TO_WEBSITE}</a>
                        </div>
                        :
                        null
                    }
                    <div className="df commentSetionViewScreen">
                        <label>{A12Y_CONST.TEXT.OTHER_SURVEY}
                        <span className='showLabel'>{A12Y_CONST.TEXT.SHOW}</span>
                        </label>
                       {/* <div className='breakWords noteText ayScrollBar'>{
                            props?.activityObj?.note?.length > 0 && props?.activityObj?.note?.split('\n').map((n, i)=> {
                            return <div key={i}>{n}</div>
                        })}
                        </div>*/}
                        <div className='fullWidth'>
                            <div className='fullWidth marginBottom20 marginTop35 commentMarginResponsive'>
                             <div id={A12Y_CONST.LABEL.EXPENSES_LABEL+A12Y_CONST.LABEL.ACTIVITY_LABEL+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(true)}} className={`commentExpenseTab ${isCommentTabVisible?'activecommentExpenseTab':''}`}>{A12Y_CONST.LABEL.EXPENSES_LABEL}</div>
                             <div id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.LABEL.ACTIVITY_LABEL+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(false)}}  className={`commentExpenseTab  ${isCommentTabVisible ? '' : 'activecommentExpenseTab'}`}>{A12Y_CONST.LABEL.NOTES}</div>
                            </div>
                            <div className="fullWidth tabContentBox">
                                {isCommentTabVisible?
                                <div className='fullWidth'>
                                    <div className={`expList df fcol fullWidth p0 ${props.activityObj.expenses && props.activityObj.expenses.length > 0 ?'bgWhiteBorderRadius': null}`}>
                                        {props.activityObj.expenses && props.activityObj.expenses.length > 0 ?
                                            props.activityObj.expenses.map((expense,key)=>(
                                                <div id={A12Y_CONST.LABEL.EXPENSES_LABEL+expense.id} className="exp df cursorPointer" key={key} onClick={()=>{goToExpenseScreen(expense)}}>
                                                    <span className="fl expIco">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.0092 0.0582892C12.4268 0.0582892 12.7192 0.0499622 13.0116 0.0582892C13.5712 0.0666163 14.0056 0.499624 13.9972 1.04921C13.9972 1.56549 13.5462 2.00682 13.0032 1.9985C11.9173 1.99017 10.8397 2.00682 9.77053 2.23998C5.87795 3.07269 2.76222 6.3369 2.11068 10.2673C1.2503 15.4884 4.43286 20.3347 9.57006 21.6421C15.1583 23.0577 20.8886 19.3105 21.8158 13.6397C21.9996 12.5322 22.0079 11.4331 21.8158 10.3256C21.6822 9.54283 22.0915 8.97659 22.7764 8.96826C23.2776 8.95993 23.7036 9.30967 23.7955 9.80097C24.9649 16.2295 20.897 22.3082 14.4984 23.7155C8.05813 25.1311 1.5176 20.876 0.239565 14.4308C-1.06353 7.8441 3.07129 1.64043 9.67865 0.274793C10.4889 0.108252 11.3075 0.033308 12.0092 0.0582892Z" fill="#0466C8" />
                                                            <path d="M12.0092 0.0582892C12.4268 0.0582892 12.7192 0.0499622 13.0116 0.0582892C13.5712 0.0666163 14.0056 0.499624 13.9972 1.04921C13.9972 1.56549 13.5462 2.00682 13.0032 1.9985C11.9173 1.99017 10.8397 2.00682 9.77053 2.23998C5.87795 3.07269 2.76222 6.3369 2.11068 10.2673C1.2503 15.4884 4.43286 20.3347 9.57006 21.6421C15.1583 23.0577 20.8886 19.3105 21.8158 13.6397C21.9996 12.5322 22.0079 11.4331 21.8158 10.3256C21.6822 9.54283 22.0915 8.97659 22.7764 8.96826C23.2776 8.95993 23.7036 9.30967 23.7955 9.80097C24.9649 16.2295 20.897 22.3082 14.4984 23.7155C8.05813 25.1311 1.5176 20.876 0.239565 14.4308C-1.06353 7.8441 3.07129 1.64043 9.67865 0.274793C10.4889 0.108252 11.3075 0.033308 12.0092 0.0582892Z" fill="black" fillOpacity="0.2" />
                                                            <path d="M12.995 6.42849C12.995 7.05302 12.995 7.05302 13.588 7.21956C14.841 7.58595 15.7097 8.33539 15.9687 9.65939C16.0606 10.1091 15.952 10.5088 15.551 10.7835C15.2253 11.0084 14.8744 11.025 14.5236 10.8335C14.2145 10.667 14.0224 10.4088 14.0057 10.0674C13.9806 9.63441 13.7467 9.35962 13.3792 9.21806C12.4604 8.85167 11.5332 8.85999 10.6143 9.21806C10.2802 9.35129 10.0045 9.58445 10.0045 9.96749C10.0045 10.3505 10.2969 10.567 10.6227 10.7169C11.0654 10.9168 11.5415 10.9584 12.026 10.9584C12.7277 10.9584 13.4126 11.0583 14.0642 11.3331C15.0081 11.7328 15.668 12.4073 15.9186 13.4149C16.2109 14.5724 15.5844 15.7964 14.415 16.4043C14.0391 16.6042 13.6382 16.7457 13.2121 16.8123C13.0451 16.8373 12.9782 16.8956 12.9866 17.0705C13.0033 17.3619 12.995 17.6534 12.9866 17.9448C12.9699 18.5027 12.5355 18.9358 11.9926 18.9358C11.4496 18.9358 11.0069 18.5111 10.9902 17.9532C10.9818 17.6617 10.9818 17.3703 10.9902 17.0788C10.9985 16.9123 10.9401 16.8457 10.773 16.8207C9.91264 16.6958 9.21097 16.2794 8.64295 15.6299C8.25871 15.1886 8.04988 14.664 7.99141 14.0811C7.94129 13.4982 8.31718 13.0152 8.86849 12.9569C9.43651 12.8986 9.92099 13.2983 9.97111 13.8895C10.0045 14.2393 10.1799 14.4891 10.4807 14.6223C11.4663 15.072 12.4687 15.0637 13.4627 14.639C13.4794 14.6307 13.4961 14.6223 13.5212 14.6057C14.0809 14.2476 14.1059 13.7646 13.5713 13.3649C13.137 13.0402 12.6191 12.9569 12.0928 12.9486C11.3494 12.9403 10.6227 12.8653 9.92934 12.5739C8.98543 12.1742 8.31718 11.508 8.05823 10.5004C7.75752 9.34296 8.38401 8.12721 9.5618 7.51933C9.90428 7.34447 10.2635 7.2029 10.6477 7.14462C10.8983 7.10298 11.0236 7.02804 10.9902 6.73659C10.9568 6.47845 10.9818 6.22031 10.9818 5.96217C10.9985 5.39593 11.4246 4.97125 11.9759 4.97125C12.5188 4.96292 12.9615 5.39593 12.9866 5.95384C13.0033 6.12038 12.995 6.2786 12.995 6.42849Z" fill="#0466C8" />
                                                            <path d="M12.995 6.42849C12.995 7.05302 12.995 7.05302 13.588 7.21956C14.841 7.58595 15.7097 8.33539 15.9687 9.65939C16.0606 10.1091 15.952 10.5088 15.551 10.7835C15.2253 11.0084 14.8744 11.025 14.5236 10.8335C14.2145 10.667 14.0224 10.4088 14.0057 10.0674C13.9806 9.63441 13.7467 9.35962 13.3792 9.21806C12.4604 8.85167 11.5332 8.85999 10.6143 9.21806C10.2802 9.35129 10.0045 9.58445 10.0045 9.96749C10.0045 10.3505 10.2969 10.567 10.6227 10.7169C11.0654 10.9168 11.5415 10.9584 12.026 10.9584C12.7277 10.9584 13.4126 11.0583 14.0642 11.3331C15.0081 11.7328 15.668 12.4073 15.9186 13.4149C16.2109 14.5724 15.5844 15.7964 14.415 16.4043C14.0391 16.6042 13.6382 16.7457 13.2121 16.8123C13.0451 16.8373 12.9782 16.8956 12.9866 17.0705C13.0033 17.3619 12.995 17.6534 12.9866 17.9448C12.9699 18.5027 12.5355 18.9358 11.9926 18.9358C11.4496 18.9358 11.0069 18.5111 10.9902 17.9532C10.9818 17.6617 10.9818 17.3703 10.9902 17.0788C10.9985 16.9123 10.9401 16.8457 10.773 16.8207C9.91264 16.6958 9.21097 16.2794 8.64295 15.6299C8.25871 15.1886 8.04988 14.664 7.99141 14.0811C7.94129 13.4982 8.31718 13.0152 8.86849 12.9569C9.43651 12.8986 9.92099 13.2983 9.97111 13.8895C10.0045 14.2393 10.1799 14.4891 10.4807 14.6223C11.4663 15.072 12.4687 15.0637 13.4627 14.639C13.4794 14.6307 13.4961 14.6223 13.5212 14.6057C14.0809 14.2476 14.1059 13.7646 13.5713 13.3649C13.137 13.0402 12.6191 12.9569 12.0928 12.9486C11.3494 12.9403 10.6227 12.8653 9.92934 12.5739C8.98543 12.1742 8.31718 11.508 8.05823 10.5004C7.75752 9.34296 8.38401 8.12721 9.5618 7.51933C9.90428 7.34447 10.2635 7.2029 10.6477 7.14462C10.8983 7.10298 11.0236 7.02804 10.9902 6.73659C10.9568 6.47845 10.9818 6.22031 10.9818 5.96217C10.9985 5.39593 11.4246 4.97125 11.9759 4.97125C12.5188 4.96292 12.9615 5.39593 12.9866 5.95384C13.0033 6.12038 12.995 6.2786 12.995 6.42849Z" fill="black" fillOpacity="0.2" />
                                                            <path d="M19.519 1.9985C19.0428 1.9985 18.5667 1.9985 18.0989 1.9985C17.4474 1.9985 16.988 1.58214 16.9963 0.990922C17.0047 0.408027 17.4557 0 18.0906 0C19.3603 0 20.6299 0 21.8996 0C22.5762 0 22.9939 0.416354 22.9939 1.08252C22.9939 2.35656 22.9939 3.63893 22.9939 4.91297C22.9939 5.5375 22.5595 5.98717 21.9748 5.97884C21.4068 5.97051 20.9975 5.52085 20.9891 4.91297C20.9891 4.43 20.9891 3.94703 20.9891 3.39745C20.7135 3.67224 20.4796 3.9054 20.2457 4.13856C19.4187 4.96294 18.6001 5.78732 17.7732 6.60337C17.3054 7.06969 16.6956 7.09467 16.2779 6.67831C15.8603 6.26196 15.8853 5.65408 16.3531 5.18777C17.3388 4.20517 18.3245 3.22258 19.3185 2.23998C19.3853 2.17337 19.4855 2.1234 19.5691 2.06511C19.5607 2.04846 19.544 2.02348 19.519 1.9985Z" fill="#0466C8" />
                                                            <path d="M19.519 1.9985C19.0428 1.9985 18.5667 1.9985 18.0989 1.9985C17.4474 1.9985 16.988 1.58214 16.9963 0.990922C17.0047 0.408027 17.4557 0 18.0906 0C19.3603 0 20.6299 0 21.8996 0C22.5762 0 22.9939 0.416354 22.9939 1.08252C22.9939 2.35656 22.9939 3.63893 22.9939 4.91297C22.9939 5.5375 22.5595 5.98717 21.9748 5.97884C21.4068 5.97051 20.9975 5.52085 20.9891 4.91297C20.9891 4.43 20.9891 3.94703 20.9891 3.39745C20.7135 3.67224 20.4796 3.9054 20.2457 4.13856C19.4187 4.96294 18.6001 5.78732 17.7732 6.60337C17.3054 7.06969 16.6956 7.09467 16.2779 6.67831C15.8603 6.26196 15.8853 5.65408 16.3531 5.18777C17.3388 4.20517 18.3245 3.22258 19.3185 2.23998C19.3853 2.17337 19.4855 2.1234 19.5691 2.06511C19.5607 2.04846 19.544 2.02348 19.519 1.9985Z" fill="black" fillOpacity="0.2" />
                                                        </svg>
                                                    </span>
                                                    <div className='expMid'>
                                                        <h5>{expense.expense_type}</h5>
                                                        <div className='expInfo df aic'>
                                                            {/* <span>{item?.upload_date ? dateFormatting(item.upload_date) : null} 12/11/2021</span> */}
                                                            <span>{expense.date_of_expense}</span>
                                                            {expense.paid_to !== '' && (<span className="dot"></span>)}
                                                            <span className='txtEllipsis vam'>{expense.paid_to}</span>
                                                            <span className="dot"></span>
                                                            {/*<span>${expense.amount}</span>*/}
                                                            <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(expense.amount)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div className='noData inventoryNote df aic jcc fcol'>
                                                <strong>{A12Y_CONST.LABEL.NO_EXPENSE_ATTACHED}</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className='fullWidth'>
                                <NotesComponent
                                noteType={A12Y_CONST.LABEL.NOTE_ACTIVITY_TYPE}
                                selectedItem={props?.activityObj}
                                dependent={props.dependent}
                                />
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                  {/*  <div className="df">
                        <label className="df">{A12Y_CONST.LABEL.EXPENSE}</label>
                    </div>*/}

                    {props.dependent?.access && props.dependent.access.can_update === true ?
                        <div className="actionButton hideDesktopShowMobile marginTop20  mobileDeleteViewButton">
                            < button className = "cancelButton1 viewDeleteButton" onClick={deleteActivity}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D96941" />
                                </svg>
                                <span className="paddingLeft1">{A12Y_CONST.TEXT.DELETE} {A12Y_CONST.LABEL.ACTIVITY_LABEL}</span>
                            </button>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
    console.log('props.activityObj', props.activityObj)
}

export default ActivityViewComponent
