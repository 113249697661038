import React,{Component} from 'react'
import {Button, TextField, Grid, Paper, Typography,} from "@material-ui/core";
import {client} from "../config/axiosClient";
import { connect } from 'react-redux';

import A12Y_CONST from '../common/a12yConst'

class CodeScreen extends Component {
    constructor(props){
        super(props)
        this.state={
            email:'',
            username:'',
            code:''
        }
        window.document.title = A12Y_CONST.TEXT.TITLE_VERIFY_CODE
        window.gtag("event", "screen_view", {
            screen_name: "Verify code"
        });
    }

    componentDidMount = ()=>{
        const {email,username} = this.props.location.state;
        console.debug("data.....",this.props.location.state,email)
        this.setState({email, username})
    }

    handleSubmit = async(event)=>{
        event.preventDefault();
        let response = await client.post(A12Y_CONST.API_ENDPOINT.SIGNUP_VERIFY_CODE, {
            "username" : this.state.username,
            "code" : this.state.code
        });
        if (response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.props.history.push(A12Y_CONST.ROUTE.DASHBOARD)
        } else {
        }
    }

    render(){
        return(
            <div>
                <Grid container spacing={0} justifyContent="center" direction="row">
                    <Grid item>
                        <Grid container direction="column" justifyContent="center" spacing={2} className="registration-form">
                            <Paper variant="elevation" elevation={2} className="login-background">
                                <Grid item>
                                    <Typography component="h1" variant="h5">
                                        {A12Y_CONST.LABEL.CODE}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <form onSubmit={this.handleSubmit}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <TextField
                                                    type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                                    placeholder="Code"
                                                    fullWidth
                                                    name="code"
                                                    onWheel={e => e.target.blur()}
                                                    variant="outlined"
                                                    value={this.state.code}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            code: event.target.value,
                                                        })
                                                    }
                                                    required
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    className="button-block"
                                                >
                                                    {A12Y_CONST.BUTTON_TEXT.SUBMIT}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userExist: state.userDetailReducer.userExist,
    AccessToken : state.userDetailReducer.AccessToken,
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    userProfileData : state.userDetailReducer.userProfileData
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps,mapDispatchToProps) (CodeScreen);
