import React, { Component } from 'react'
import ActivityViewComponent from '../components/ActivityViewComponent'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import  * as userDetailActions from '../redux/actions/userDetailActions'
import A12Y_CONST from "../common/a12yConst";
import {secureClient} from "../config/axiosClient";

export class ActivityViewScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error:false,
            message:'',
            errorMessage:'',
            loading: false,
        }
    }

    componentDidMount = async () => {
        await this.props.dataChanged(false)
    }

    onEditHandler = async()=>{
        // this.props.history.push(A12Y_CONST.ROUTE.ADD_ACTIVITY);
        let activity = this.props.currentActivity
        if(activity.expenses && activity.expenses.length > 0){
            activity.expense_ids = [];
            activity.expenses.forEach((item,key)=>{
                activity.expense_ids.push(item.id)
            })
        }else {
            activity.expense_ids = [];
        }
        await this.props.currentActivityData(activity)
        localStorage.setItem("activity", JSON.stringify(this.props.currentActivity));
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_ACTIVITY,
            state: {fromActivityView:true }
        });
    }

    onDelete = async()=>{
        this.setState({loading: true})
        try {
            if(this.props.currentActivity && Object.keys(this.props.currentActivity).length > 0){
                this.setState({loading:true,errorMessage:'',error:false})
                let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ACTIVITIES+"/"+this.props.currentActivity.id);
                this.setState({loading:false})
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.props.history.push(A12Y_CONST.ROUTE.ACTIVITIES);
                    await this.props.currentActivityData({})
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message, loading: false})
                }
            }
            let temp = this;
            setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
        }catch (e) {
            this.setState({loading: false, error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DELETE_ACTIVITY,e)
        }
    }

    onCancel = async()=>{
        await this.props.currentActivityData({})
        this.props.history.push(A12Y_CONST.ROUTE.ACTIVITIES);
    }

    goToExpenseHandler = async (expense) =>{
        await this.props.expenseViewingUsingActivityView(true);
        await this.props.currentExpenseData(expense)
        this.props.history.push({
            pathname : A12Y_CONST.ROUTE.EXPENSE_VIEW
        })
    }


    render() {
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
            <div className='rightPanelWidhtSidebar'>
                <div className="rightPanelWidhtSidebarContainer fullWidth">
                    <ActivityViewComponent
                        loading={this.state.loading}
                        activityObj={this.props.currentActivity}
                        message={this.state.message}
                        error={this.state.error}
                        errorMessage={this.state.errorMessage}
                        dependent={this.props.dependent}
                        onEdit={this.onEditHandler}
                        onDelete={this.onDelete}
                        onCancel={this.onCancel}
                        goToExpense={this.goToExpenseHandler}
                    />
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    currentActivity : state.dependentReducer.currentActivity,
});
const mapDispatchToProps = dispatch => ({
    currentActivityData : (activity) => dispatch(dependentActions.currentActivityData(activity)),
    currentExpenseData : (expense) => dispatch(dependentActions.currentExpenseData(expense)),
    expenseViewingUsingActivityView : (status) => dispatch(dependentActions.expenseViewingUsingActivityView(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});

export default connect(mapStateToProps,mapDispatchToProps) (ActivityViewScreen);
