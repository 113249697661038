import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import Loading from '../components/LoadingComponent';
import UploadFiles from '../components/ImageUploadComponent';
import LoaderFullScreen from './LoaderFullScreenComponent'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from 'uuid';
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import Switch from "@material-ui/core/Switch";
import MedicationPhotoUploadComponent from "./MedicationPhotoUploadComponent";
import PopUpMedicationComponent from "./PopUpMedicationComponent";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {hideOnBlur, readOnlyMobile} from '../util/inputKeyboardHide';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function AddMedicationComponent(props) {
    const [windowDimensions, setWindowDimensions] = useState({});
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prescribeDoctorType, setprescribeDoctorType] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [note, setNote] = useState('');
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [startDateErrorMeesage, setStartDateErrorMeesage] = useState('');
    const [endDateError, setEndDateError] = useState(false);
    const [endDateErrorMeesage, setEndDateErrorMeesage] = useState('');
    const [prescribeDoctorTypeOptionsList, setprescribeDoctorTypeOptionsList] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [fileInfo, setFileInfo] = useState([]);
    //const [isButtonDisbale, setIsButtonDisbale] = useState(false);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [prescribeDoctorTypeError, setprescribeDoctorTypeError] = useState(false);
    const [editMode, setEditMode] = useState(null);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [medicineStatusPopup, setMedicineStatusPopup] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const history = useHistory();
    const [medicationId, setMedicationId] = useState(null)
    const { register, handleSubmit, setValue, control, setError, formState: { errors, isSubmitting } } = useForm({ mode: "all" });

    const [status, setStatus] = useState(true);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMeesage, setNameErrorMeesage] = useState('');

    const [instructions, setInstructions] = useState('');
    const [instructionsError, setInstructionsError] = useState(false);
    const [instructionsErrorMeesage, setInstructionsErrorMeesage] = useState('');
    const [medicationPicObj, setMedicationPicObj] = useState({})
    const [isProfilePicRemoved, setIsProfilePicRemoved] = useState(false)
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const [linkToWebsite, setLinkToWebsite] = useState('');

    useEffect( () => {
        setWindowDimensions(getWindowDimensions())
        let arr =[startDateRef.current, endDateRef.current]
        readOnlyMobile(arr)
    },[])

    const handleNameChange = (e) => {
        setName(e.target.value.trimStart())
        setValue('name', e.target.value.trimStart())
    }
    const validateName = (e) => {
        if(name === '' && name.length === 0) {
            setNameError(true)
            setNameErrorMeesage(A12Y_CONST.ERROR_MESSAGE.NAME_REQUIRED);
            return true;
        }else{
            setNameError(false)
            setNameErrorMeesage('');
            return false;
        }
    }

    const handleInstructionsChange = (e) => {
        setInstructions(e.target.value.trimStart())
        setValue('instructions', e.target.value.trimStart())
    }
    const validateInstructions = (e) => {
        if(instructions === '' && instructions.length === 0) {
            setInstructionsError(true)
            setInstructionsErrorMeesage(A12Y_CONST.ERROR_MESSAGE.INSTRUCTIONS_REQUIRED);
            return true;
        }else{
            setInstructionsError(false)
            setInstructionsErrorMeesage('');
            return false;
        }
    }

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === 'POP') {
                history.goBack();
            }
        });
    });

    function goBack() {
        // props.onBack()
        history.goBack();
    }

    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.error, props.loading, props.loaderFullScreen])

    useEffect( ()=>{
        const initiliazeData = async()=>{
            if(props.currentMedication === null || Object.keys(props.currentMedication).length === 0){
                const uuid = await uuidv4();
                setMedicationId(uuid)
                setEditMode(false)
            }else {
                if(props.currentMedication !== null && Object.keys(props.currentMedication).length > 0 ){
                    if(props.currentMedication.hasOwnProperty('medication_pic')){
                        setMedicationPicObj(props.currentMedication.medication_pic)
                    }
                }
            }
        }

        initiliazeData();

    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
       const assignFormDefaultData = async ()=>{
           let medication = props.currentMedication;
           if(medication && Object.keys(medication).length > 0){
               setName(medication.medicine_name)
               setInstructions(medication.uses_instruction)
               setLinkToWebsite(medication?.web_url)
               setStatus(medication.status === 1? true : false)
               if(medication.prescribing_doctor_id && props.doctorList.length > 0 && !medication.hasOwnProperty('doctor')){
                   let selectedDoctor = props.doctorList.find(item =>item.id === medication.prescribing_doctor_id)
                   if(selectedDoctor !== undefined && selectedDoctor !== null){
                       setprescribeDoctorType(selectedDoctor)
                   }
               }else {
                   if(medication.hasOwnProperty('doctor')){
                       let doctorId = medication.doctor.id
                       if(Object.keys(medication.doctor).length === 0 && medication.prescribing_doctor_id !== undefined){
                           doctorId = medication.prescribing_doctor_id
                       }
                       if(doctorId !== null && doctorId !== undefined){
                           let selectedDoctor = props.doctorList.find(item =>item.id === doctorId)
                           if(selectedDoctor !== undefined && selectedDoctor !== null){
                               setprescribeDoctorType(selectedDoctor)
                           }
                       }
                   }
               }
               setStartDate( medication.start_date !== null ? new Date(medication.start_date) : null)
               if(medication.end_date !== null){
                   setEndDate(new Date(medication.end_date))
               }
               setNote(medication.note)
               if(medication.hasOwnProperty('isEditMode') && medication.isEditMode){
                   setMedicationId(medication.id)
                   setEditMode(true)
               }else {
                   setEditMode(false)
                   setMedicationId(medication.id)
               }
           }
       }
       assignFormDefaultData();

    },[props.currentMedication,medicationId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
        const checkIsFormDataChanged = async ()=>{
            setIsBtnDisabled(true)
            setIsDataModified(false)
            props.isDataChanged(false)
            let medication = props.medication_data;
            console.log('endDate', endDate)
            if(editMode && Object.keys(medication).length > 0){
                if((name !== '' && instructions !== '' && startDate !== null) && (name !== medication.medicine_name || instructions !== medication.uses_instruction || note !== medication.note || linkToWebsite !== medication.web_url || await ddmmyyyyFormat(startDate) !== medication.start_date || (endDate !== null && await ddmmyyyyFormat(endDate) !== medication.end_date) || (medication.end_date !== null && endDate === null) || (Object.keys(prescribeDoctorType).length > 0 && prescribeDoctorType.id !== medication.prescribing_doctor_id) || deletedFileInfo.length > 0 || fileInfo.length > 0 || isProfilePicRemoved || Object.keys(medicationPicObj).length > 0)){
                    if(name !== '' && instructions !== '' && startDate !== null){
                        setIsBtnDisabled(false)
                    }
                    setIsBtnDisabled(false)
                    setIsDataModified(true)
                    props.isDataChanged(true)
                }
            }else {
                let today =await ddmmyyyyFormat(new Date())
                if(editMode!== null && !editMode){
                    if(name !== '' || linkToWebsite !== '' || instructions !== '' || note !== '' || await ddmmyyyyFormat(startDate) !== today || Object.keys(prescribeDoctorType).length > 0 || deletedFileInfo.length > 0 || fileInfo.length > 0 || isProfilePicRemoved || Object.keys(medicationPicObj).length > 0){
                        if(name !== '' && instructions !== '' && startDate !== null){
                            setIsBtnDisabled(false)
                        }
                        setIsDataModified(true)
                        props.isDataChanged(true)
                    }
                }
            }
        }
        checkIsFormDataChanged();
    },[note,startDate,prescribeDoctorType,fileInfo,deletedFileInfo,editMode,name,instructions,isProfilePicRemoved,medicationPicObj,endDate, linkToWebsite]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        const dateValidationCheck = ()=>{
            if(endDate !== null && startDate !== null && startDate.getTime() < endDate.getTime()){
                isEndDateValid()
            }if(startDate !== null){
                isDateValid();
            }
        }
        dateValidationCheck();
    },[startDate,endDate]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            props.onCancel()
        }
    }

    const handleResetAllFields = async () => {
        setIsPopUpVisible(false);
        props.isDataChanged(false)
        props.onCancel()
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handlePrescribingDoctorChange = async (e) => {
        if(e.id === 0){
            let obj = {}
            if(editMode){
                obj = Object.assign({}, props.currentMedication);
                obj.medicine_name = name
                obj.prescribing_doctor_id = prescribeDoctorType.id
                obj.medicine_pic_url = isProfilePicRemoved ? null : props.currentMedication !== null && Object.keys(props.currentMedication).length > 0 && props.currentMedication.medicine_pic_url !=="" ? props.currentMedication.medicine_pic_url : null
                obj.uses_instruction = instructions
                obj.start_date = startDate !== null ? await ddmmyyyyFormat(new Date(startDate)) : null
                obj.end_date = endDate !== null ? await ddmmyyyyFormat(new Date(endDate)) : null
                obj.note = note
                obj.web_url = linkToWebsite
            }else {
                obj = {
                    "id" : medicationId,
                    "medicine_name" : name,
                    "prescribing_doctor_id" : prescribeDoctorType.id,
                    "medicine_pic_url" :props.currentMedication !== null && Object.keys(props.currentMedication).length > 0 && props.currentMedication.medicine_pic_url !=="" ? props.currentMedication.medicine_pic_url : null,
                    "uses_instruction" : instructions,
                    "start_date" : startDate !== null ? await ddmmyyyyFormat(new Date(startDate)) : null,
                    "end_date" : endDate !== null ? await ddmmyyyyFormat(new Date(endDate)) : null,
                    "note" : note,
                    "web_url" : linkToWebsite,
                }
            }
            if(Object.keys(medicationPicObj).length > 0){
                obj.medication_pic = medicationPicObj
            }
            props.goToCreateDoctorScreen(obj)
        }
        await setprescribeDoctorType(e);
    }


     function handleNoteChange(e) {
        if(e.target.value === " "){
            e.preventDefault();
        }else {
            setNote(e.target.value);
        }
    }

    useEffect(  () => {
        const assignPrescribeDoctorTypeList = async ()=>{
            var prescribeDoctorTypeList = await props.doctorList.map(item => ({ label: item.doctor_name, value: item.doctor_name, id:item.id , data:item}));
            prescribeDoctorTypeList.push({
                label:"Provider", value:'provider', id:0
            })
            setprescribeDoctorTypeOptionsList(prescribeDoctorTypeList)
        }
        assignPrescribeDoctorTypeList()
    },[props.doctorList])


    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
        if (props.loading) {
            setIsBtnDisabled(true)
        } if (props.error) {
            setIsBtnDisabled(false)
        }
    }, [props.error, props.loading])

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const isDateValid = () => {
        if(startDate !== null){
            let isValidObj = validateDate(startDate, 20, 2)
            setStartDateError(isValidObj.isErr)
            setStartDateErrorMeesage(isValidObj.errMsg)
            if(endDate !== null){
                return isDateMatching();
            }
        }else{
            setStartDateError(true)
            setStartDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    
    const isEndDateValid = () => {
        if (endDate != null) {
            setEndDateError(false)
            setEndDateErrorMeesage('')
            if(startDate != null){
                return isDateMatching();
            }
        }else if(endDate==null){
            setEndDateError(false)
            setEndDateErrorMeesage('')
        }
    }

    const isDateMatching = () => {
        let sDt = new Date(startDate);
        let eDt = new Date(endDate);

        if (startDate !== null && endDate !== null) {
            if (eDt.toDateString() === sDt.toDateString()) {
                setEndDateError(false)
                setEndDateErrorMeesage('')
                return true;
            }
            else if (eDt < sDt) {
                setEndDateError(true)
                setEndDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.END_DATE_ERROR_MEDICAL)
                return false;
            }else if(sDt < eDt){
                setEndDateError(false)
                setEndDateErrorMeesage('')
                return true;
            }
        }
    };

    const onSubmit = async(incomeData)=>{
        let isNameValidate = await validateName();
        let isInstructionsValidate = await validateInstructions();

        let obj = {
            "id" : medicationId,
            "medicine_name" : name,
            "web_url" : linkToWebsite,
            "prescribing_doctor_id" : prescribeDoctorType.id,
            "medicine_pic_url" : isProfilePicRemoved ? null : editMode && props.currentMedication !== null && Object.keys(props.currentMedication).length > 0 ? props.currentMedication.medicine_pic_url : null,
            "uses_instruction" : instructions,
            "start_date" : await ddmmyyyyFormat(new Date(startDate)),
            "end_date" : endDate === null ? null : await ddmmyyyyFormat(new Date(endDate)),
            "note" : note
        }
        let isDatesValid = validateDate(startDate, 20, 2)

        if(!isNameValidate && !isInstructionsValidate && !isDatesValid.isErr && !startDateError && !endDateError){
            props.isDataChanged(false)
            if(editMode){
                setIsSubmit(true)
                props.onUpdate(obj, fileInfo, fileBase64Info,deletedFileInfo,editMode,medicationPicObj)
            }else {
                setIsSubmit(true)
                props.onSubmit(obj, fileInfo, fileBase64Info,deletedFileInfo,editMode,medicationPicObj)
            }
        }
        //setSaveClicked(true)
    }

    function validateprescribeDoctorType(type) {
        if(type === 'blur'){
            if(prescribeDoctorType && Object.keys(prescribeDoctorType).length === 0){
                setprescribeDoctorTypeError(true)
            }
        }else {
            setprescribeDoctorTypeError(false)
        }
    }

    const filesDetail_FromChild = (e,deletedFiles) => {
        let fileList = [];
        let base64List = [];
        for (var i = 0; i < e.length; i++) {
            if(!e[i].file_id){
                let name = e[i].displayName
                let size = e[i].size
                let file = e[i];
                let fileName = e[i].name;
                let reader = new FileReader();
                const uuid = uuidv4();
                let s3key = `${props.dependent.id}/medication/${medicationId}/${uuid}`
                reader.readAsDataURL(file);
                reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                    // console.log('RESULT =====',reader.result )
                );
                // extension = path.substring(fileName.lastIndexOf('.')+1);
                fileList.push({
                    's3_key': s3key,
                    file_id:uuid,
                    medication_id:medicationId,
                    'contentType': e[i].type,
                    'fileMetadata': {
                        "about_dependent": "some more info"
                    },
                    'name' : name,
                    'size' : size,
                    'fileName': fileName,
                });
            }
        }
        setFileInfo(fileList);
        setFileBase64Info(base64List)
        setDeletedFileInfo(deletedFiles)
    }

    const handlestatusChange = (e) => {
        let status = e.target.checked
        if(!status){
            setMedicineStatusPopup(true)
        }

    }
    const overlay = (status) => {
        props.overlay(status)
    }
    const handleRemoveImg = () => {
        if(props.currentMedication?.medicine_pic_url){
            setIsProfilePicRemoved(true)
        }
        setMedicationPicObj({})
        // props.handleRemoveImg();
    }
    const checkIsPopupOpen = (status)=>{
        //setIsButtonDisbale(status)
    }
    const handleNoButton = () => {
        setStatus(true);
        setValue('status', true)
        setMedicineStatusPopup(false)
    }

    const handleYesButton = () => {
        props.inActiveMedication()
        setStatus(false);
        setValue('status', false)
        setMedicineStatusPopup(false)
    }

    const uploadingMedicationPic = (data) => {
        setMedicationPicObj(data)
    }

    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addIncomesWrap">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            {loaderFullScreenVisible ?
                <LoaderFullScreen />
                :
                null
            }
            <h2 className="titleWithSidebar addIncomeTitle marginBottom30">
                <span
                      // className="cursorPointer"
                      onClick={goBack}>
                {/*<svg  className="marginRight10" width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />*/}
                {/*</svg>*/}
                    <span>{windowDimensions.width < 768 ? A12Y_CONST.LABEL.ADD :''} {A12Y_CONST.LABEL.MEDICAL}</span></span>
            </h2>
            <div className="dependentBorderBox dependentDefauldMinHeight pad_0_5">
                <h3 className='positionRelative fullWidth marginNone600 medH3'><span className='vam'>{editMode ? 'Update' : 'Add'} {A12Y_CONST.LABEL.MEDICATION} {windowDimensions.width < 768 ?A12Y_CONST.LABEL.OF+' '+ props.dependent.nick_name:''}</span>
                    {editMode ?
                        <div className="medicationStatus displayNone_600 positionAbsoute">
                            <label className="lblExpType">{A12Y_CONST.LABEL.STATUS}</label>
                            <div className="marginTop5 df aic">
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({field}) =>
                                        <Switch
                                            id={A12Y_CONST.BUTTON_ID.MEDICATION_STATUS}
                                            checked={status}
                                            onChange={handlestatusChange}
                                            color="primary"
                                        />
                                    }
                                />
                                {!status && <span className='yesNo paddingleft16'>{A12Y_CONST.LABEL.INACTIVE}</span>}
                                {status && <span className='yesNo paddingleft16'>{A12Y_CONST.LABEL.ACTIVE}</span>}
                            </div>
                        </div>
                        :
                        null
                    }

                </h3>
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }

                <form autoComplete="off" className='marginTop60 fullWidth medicalForm' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                 <div className="fullWidth paddingLeft220 positionRelative medicalImageUploadCol">
                    <div className='positionAbsoute medicationImg'>
                        <div className='generalInfoProfileImg dependentPro'>
                            <MedicationPhotoUploadComponent overlay={overlay} medicationPicObj={medicationPicObj} removeImageCol={handleRemoveImg} uploadingMedicationPic={uploadingMedicationPic} medication={props.currentMedication}  dependent={props.dependent} loading={props.loading} isPopupOpen={checkIsPopupOpen}/>
                        </div>
                    </div>
                     {editMode ?
                         <div className='fullWidth medicalStatus_600'>
                             <div className="medicationStatus">
                                 <label className="lblExpType">{A12Y_CONST.LABEL.STATUS}</label>
                                 <div className="marginTop5 df aic">
                                     <Controller
                                         name="status"
                                         control={control}
                                         render={({field}) =>
                                             <Switch
                                                 checked={status}
                                                 onChange={handlestatusChange}
                                                 color="primary"
                                             />
                                         }
                                     />
                                     {!status &&
                                     <span className='yesNo paddingleft16'>{A12Y_CONST.LABEL.INACTIVE}</span>}
                                     {status && <span className='yesNo paddingleft16'>{A12Y_CONST.LABEL.ACTIVE}</span>}
                                 </div>
                             </div>
                         </div>
                     :
                     null
                     }
                    <div className="formRow fullWidth marginBottom30 marginBottom20_600">
                        <label>Name<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <input
                                {...register("name")}
                                placeholder="Name of Medication"
                                type='text'
                                id="nsme"
                                onChange={handleNameChange}
                                tabIndex={1}
                                maxLength='250'
                                value={name}
                                onBlur={validateName}
                                onFocus={()=>setNameErrorMeesage(false)}
                                className={`fullWidth txtName inputColum24size ${nameError && 'errorInputBorderRed'}`}
                            />
                            <div className='errorRelativeRow'>{nameError? <p className='errorMessage'>{nameErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginBottom30 marginBottom20_600">
                        <label>instructions<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <input
                                {...register("instructions")}
                                placeholder="Describe the frequency, and any other specifications."
                                type='text'
                                id="instructions"
                                onChange={handleInstructionsChange}
                                value={instructions}
                                maxLength='250'
                                tabIndex={2}
                                onBlur={validateInstructions}
                                onFocus={()=>setInstructionsErrorMeesage(false)}
                                className={`fullWidth txtName inputColum24size ${instructionsError && 'errorInputBorderRed'}`}
                            />
                            <div className='errorRelativeRow'>{instructionsError? <p className='errorMessage'>{instructionsErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                 </div>
                    <div className="formRow fullWidth marginNone600 formRowGap payRow">
                        <div className="width50 paddingRight16 marginBottom20_600 positionRelative">
                            <label>{A12Y_CONST.LABEL.DATE_STARTED}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth">
                                <Controller
                                    name="startDate"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            id={A12Y_CONST.BUTTON_ID.START_DATE}
                                            ref={startDateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'startDate')}
                                            onBlur={isDateValid}
                                            onCalendarClose={isDateValid}
                                            tabIndex={3}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, startDateRef)}
                                            onFocus={(e)=> {setStartDateError(false)}}
                                            className={!startDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {startDateError? <p className='errorMessage'>{startDateErrorMeesage}</p>: null}
                                </div>
                            </div>
                        </div>
                        <div className="width50 marginBottom20_600 positionRelative">
                            <label className='minWidht190 textAlignRight'>{A12Y_CONST.LABEL.END_DATE}</label>
                            <div className="fullWidth">
                                <Controller
                                    name="endDate"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            id={A12Y_CONST.BUTTON_ID.END_DATE}
                                            ref={endDateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={endDate}
                                            onChange={date => setEndDate(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'endDate')}
                                            onBlur={isEndDateValid}
                                            onCalendarClose={isEndDateValid}
                                            showMonthDropdown
                                            showYearDropdown
                                            tabIndex={4}
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, endDateRef)}
                                            onFocus={(e)=> {setEndDateError(false)}}
                                            className={!endDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {endDateError? <p className='errorMessage'>{endDateErrorMeesage}</p>: null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth addressLabelTop10 formRowGap">
                        <label className="prescribeDoctorLable maxWidth138">{A12Y_CONST.LABEL.PRESCRIBING_DOCTOR}</label>
                        <div className={`fullWidth doctorPrescribingCol ${prescribeDoctorTypeError && 'selectError'} ${ prescribeDoctorType && Object.keys(prescribeDoctorType).length > 0 ? 'hasValue' : 'noValue'}`}>
                            <Controller
                                name="prescribeDoctorType"
                                value={prescribeDoctorType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix='doctorPrescribing'
                                        value={prescribeDoctorTypeOptionsList.find(c => c.id === prescribeDoctorType.id)}
                                        options={prescribeDoctorTypeOptionsList}
                                        tabIndex={5}
                                        onChange={handlePrescribingDoctorChange}
                                        onFocus={()=>{validateprescribeDoctorType('focus')}}
                                       // onBlur={()=>{validateprescribeDoctorType('blur')}}
                                    />
                                )}
                                control={control}
                                className="fullwidth inputColum24size"
                                placeholder="Select"
                            />
                            <div className='errorRelativeRow'> {prescribeDoctorTypeError && <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_PRESCRIBING_DOCTOR_TYPE}</p>}</div>
                        </div>
                    </div>

                    <div className="formRow fullWidth marginNone600 formRowGap payRow">
                        <div className="width50 paddingRight16 marginBottom20_600 positionRelative">
                            <label className='labelCol emailStep4'>{A12Y_CONST.LABEL.LINK_URL}</label>
                            <div className='fullWidth doctorRowMarginB_600'>
                                <input
                                    {...register('linkToWebsite',{
                                        pattern:{
                                            //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                            value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                            message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                        }
                                    })}
                                    placeholder='www.example.com'
                                    className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                    type='text'
                                    id='displayName'
                                    autoComplete="none"
                                    onChange={(e) => handleLinkToWebsite(e.target.value)}
                                    value={linkToWebsite}
                                    onFocus={()=>{
                                        setError("linkToWebsite", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />
                                <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                            </div>

                        </div>
                    </div>
                    {editMode?
                        null
                        :
                        <div className="formRow fullWidth formRowGap medicalMarBottom20 df fcol">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className="fullWidth">
                            <textarea
                                {...register("note")}
                                rows="3"
                                onChange={handleNoteChange}
                                value={note}
                                tabIndex={6}
                                maxLength='4000'
                                className="fullWidth inputColum24size txtNote"
                                placeholder="Capture details about this medication."
                            ></textarea>
                        </div>
                    </div>
                    }
                    <div className="formRow fullWidth formRowGap df fcol">
                        <label className="lblExpense medicaAttachemnt600">{A12Y_CONST.LABEL.ATTACHMENT}</label>
                        <div className="lblUpload">
                            <UploadFiles
                                screenFrom={A12Y_CONST.LABEL.MEDICATION}
                                //saveClick={saveClicked}
                                fileDetailSendToParent={filesDetail_FromChild}
                                fileUploadingData={props.fileUploadingData}
                                isSubmit={isSubmit}
                                tabIndex={7}
                                editMode={editMode}
                                obj={props.currentMedication}
                                accepts={""}
                            />
                            <div className='errorRelativeRow'> {errors.incomeAttachment && <p className='errorMessage'>{A12Y_CONST.TEXT.SELECT_FILE_UPLOAD_MESSAGE}</p>}</div>
                        </div>
                    </div>

                    <div className="fullWidth textAlignRight marginTop30 buttonRow marginBottom30 displayFlexCol" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.ADD_MEDICAL_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.ADD_MEDICAL_SAVE} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />

            <PopUpMedicationComponent
                popUpStatus={medicineStatusPopup}
                handleNoButton={handleNoButton}
                popUpType={A12Y_CONST.LABEL.MEDICATIONS}
                handleYesButton={handleYesButton}
            />
        </div>
    )
}

export default AddMedicationComponent