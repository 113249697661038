import React, {Component} from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import InventoryViewComponent from '../components/InventoryViewComponent'
import * as dependentActions from "../redux/actions/dependentActions";
import {secureClient} from "../config/axiosClient";
import {decryptAccountNo, setupAccessControl} from "../util/AssetCommonApi";
import * as userDetailActions from "../redux/actions/userDetailActions";




class InventoryViewScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : true,
            lifeInsurancePolicyLoader:false,
            institutesLoader:false,
            loanLoader:false,
            beneficiaryLoader:false,
            contactsLoader:false,
            coOwnerLoader:false,
            encryptAccount:'',
            encryptLoader:false,
            addressLoader:false,
            assetId:null,
            showAssetDetailView:false,
            showEditOrAddIcon:true
        }
    }

    componentDidMount = async()=>{
        await this.props.dataChanged(false)
        if(this.props.currentAsset.hasOwnProperty('transfer')){
            let id = this.props.currentAsset.transfer.asset_id
            this.setState({assetId:id,showAssetDetailView:true,showEditOrAddIcon:false},async ()=>{
                await this.getAssetDetails();
            })
        }
        if(!this.props.currentAsset.isDataPresent && !this.props.currentAsset.hasOwnProperty('transfer')){
            let id = this.props.currentAsset.id
            this.setState({assetId:id,showEditOrAddIcon:true},async ()=>{
                await this.getAssetDetails();
            })
        }
    }

    getAssetDetails = async ()=>{
        if(Object.keys(this.props.currentAsset).length > 0 && this.state.assetId !== null){
           this.setState({loading:true,error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.state.assetId);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let asset = {};
                if(this.props.currentAsset.hasOwnProperty('transfer')){
                    let transfer = this.props.currentAsset.transfer
                    asset = response.data.sr.asset;
                    asset.transfer = transfer
                }else {
                    asset = response.data.sr.asset
                }
                asset.isDataPresent = true
                await this.props.currentAssetData(asset)
                 this.setState({loading:false,})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false})
            }
        }
    }

    getInstitutesDetails = async ()=>{
        if(Object.keys(this.props.currentAsset).length > 0 ){
            this.setState({institutesLoader:true,error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.INSTITUTES);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr?.institute){
                    let currentAsset = this.props.currentAsset;
                    currentAsset.institute = response.data.sr.institute
                    await this.props.currentAssetData(currentAsset)
                }
                this.setState({institutesLoader:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,institutesLoader:false})
            }
        }
    }

    getAddressDetails = async ()=>{
        if(Object.keys(this.props.currentAsset).length > 0 ){
            this.setState({addressLoader:true,error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+A12Y_CONST.API_ENDPOINT.ADDRESSES);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr?.asset){
                    let currentAsset = this.props.currentAsset;
                    currentAsset.address = response.data.sr.asset
                    await this.props.currentAssetData(currentAsset)
                }
                this.setState({addressLoader:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,addressLoader:false})
            }
        }
    }

    getLoansDetails = async ()=>{
        if(Object.keys(this.props.currentAsset).length > 0 ){
            this.setState({loanLoader:true,error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.LOANS);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr?.loan){
                     let currentAsset = this.props.currentAsset;
                   currentAsset.loan = response.data.sr.loan
                   await this.props.currentAssetData(currentAsset)
                }
                this.setState({loanLoader:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,loanLoader:false})
            }
        }
    }

    getBeneficiaryDetails = async ()=>{
        if(Object.keys(this.props.currentAsset).length > 0){
            this.setState({beneficiaryLoader:true,error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.BENEFICIARIES);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr?.beneficiary_list){
                    let currentAsset = this.props.currentAsset;
                    currentAsset.beneficiary = response.data.sr.beneficiary_list
                    await this.props.currentAssetData(currentAsset)
                }
                this.setState({beneficiaryLoader:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,beneficiaryLoader:false})
            }
        }
    }

    getContactInformationList = async () =>{
        if(Object.keys(this.props.currentAsset).length > 0){
            this.setState({contactsLoader:true,error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.CONTACTS);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr?.contacts){
                    let currentAsset = this.props.currentAsset;
                    currentAsset.contacts = response.data.sr.contacts
                    await this.props.currentAssetData(currentAsset)
                }
                this.setState({contactsLoader:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,contactsLoader:false})
            }
        }
    }

    getCoOwnersList = async () =>{
        if(Object.keys(this.props.currentAsset).length > 0){
            this.setState({coOwnerLoader:true,error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.OWNERS);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response.data.sr?.asset_co_owners){
                    let currentAsset = this.props.currentAsset;
                    currentAsset.coowners = response.data.sr.asset_co_owners
                    await this.props.currentAssetData(currentAsset)
                }
                this.setState({coOwnerLoader:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,coOwnerLoader:false})
            }
        }
    }

    onContactDelete = async (contact)=>{
        this.setState({contactsLoader:true})
        let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.CONTACTS+"/"+contact.id);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let contactList = Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.hasOwnProperty('contacts') ? this.props.currentAsset.contacts : [];
            if(contactList.length > 0){
                contactList = await contactList.filter(item=>item.id !== contact.id);
                let currentAsset = this.props.currentAsset;
                if(contactList.length > 0){
                    currentAsset.contacts = contactList
                }else {
                  delete currentAsset.contacts
                }

                await this.props.currentAssetData(currentAsset)
            }
            this.setState({contactsLoader:false,successMessage:response.data.sr.message,error:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,contactsLoader:false})
        }
    }
    onBeneficiaryDelete = async (beneficiary) => {
        this.setState({ beneficiaryLoader:true})
        let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.BENEFICIARIES+"/"+beneficiary.id);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let beneficiaryList = Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.beneficiary.length > 0 ? this.props.currentAsset.beneficiary : [];
            if(beneficiaryList.length > 0 ){
                beneficiaryList = await beneficiaryList.filter(item=>item.id !== beneficiary.id);
                let currentAsset = this.props.currentAsset;
                if(beneficiaryList.length > 0){
                    currentAsset.beneficiary = beneficiaryList
                }else {
                    delete currentAsset.beneficiary
                }
                await this.props.currentAssetData(currentAsset)
            }
            this.setState({beneficiaryLoader:false,successMessage:response.data.sr.message,error:false})
        }else{
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,beneficiaryLoader:false})
        }
    }
    coOwnerDelete = async (coOwner) => {
        this.setState({ coOwnerLoader:true})
        let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.OWNERS+"/"+coOwner.id);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let coOwnerList = Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.coowners.length > 0 ? this.props.currentAsset.coowners : [];
            if(coOwnerList.length > 0 ){
                coOwnerList = await coOwnerList.filter(item=>item.id !== coOwner.id);
                let currentAsset = this.props.currentAsset;
                if(coOwnerList.length > 0){
                    currentAsset.coowners = coOwnerList
                }else {
                    delete currentAsset.coowners
                }
                await this.props.currentAssetData(currentAsset)
            }
            this.setState({coOwnerLoader:false,successMessage:response.data.sr.message,error:false})
        }else{
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,coOwnerLoader:false})
        }
    }

    goToAddInstitutionScreen = ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.ADD_INSTITUTION)
    }

    goToAddAddressScreen = ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.ADD_ADDRESS)
    }

    decryptAccountNumber = async (id) => {
        this.setState({ encryptLoader:true})
        let response = await decryptAccountNo(this.props.dependent.id,id)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({encryptAccount:response.data.sr.dec_txt, encryptLoader:false})
        }else{
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false, encryptLoader:false})
        }
    }

    accessToAsset = async (status) => {
        this.setState({ loading: true,error:false,errorMessage:'' })
        let obj = {advocate_control:status,action:A12Y_CONST.TEXT.UPDATE_ADVOCATE_ACCESS };
        let response = await setupAccessControl(this.props.dependent.id, this.props.currentAsset.id, obj)
        if(!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK){
            let currentAsset = this.props.currentAsset;
            currentAsset.advocate_control = response.data.sr.asset.advocate_control
            await this.props.currentAssetData(currentAsset)
            this.setState({ loading: false })
        }else {
            this.setState({ error: true, errorMessage: response.data.sr?.a12yErr?.message })
            this.setState({ loading: false })
        }
    }


    handelGetDecryptAccountNumber = async (id) =>{
        this.setState({ loading:true})
        let response = await decryptAccountNo(this.props.dependent.id,id)
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let currentAsset = this.props.currentAsset;
            if(currentAsset.hasOwnProperty('bank')){
                currentAsset.bank.account_number = response.data.sr.dec_txt
            }if(currentAsset.hasOwnProperty('burial')){
                currentAsset.burial.account_number = response.data.sr.dec_txt
            }
            await this.props.currentAssetData(currentAsset)
            this.setState({encryptAccount:response.data.sr.dec_txt, loading:false})
            // console.log('this.encr', this.state.encryptAccount)
        }else{
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false, encryptLoader:false})
        }
    }

    updateAssetEditScreenKeyHandler = async (key)=>{
        await this.props.assetKeyUpdate(key);
    }



    render() {
        const {contactsLoader,coOwnerLoader,loanLoader,institutesLoader,loading, error, errorMessage, beneficiaryLoader,successMessage,addressLoader,showAssetDetailView,showEditOrAddIcon} = this.state
        return (
            <div className='myProfileAccount animationall mainRightSidebare'>
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <InventoryViewComponent
                            dependent={this.props.dependent}
                            currentAsset={this.props.currentAsset}
                            loading={loading}
                            gettingProviderDetails={this.getInstitutesDetails}
                            gettingLoansDetails={this.getLoansDetails}
                            institutesLoader={institutesLoader}
                            successMessage={successMessage}
                            loanLoader={loanLoader}
                            coOwnerLoader={coOwnerLoader}
                            contactsLoader={contactsLoader}
                            beneficiaryLoader={beneficiaryLoader}
                            reloadAssetData={this.getAssetDetails}
                            getContactDeatils={this.getContactInformationList}
                            getBeneficiaryData={this.getBeneficiaryDetails}
                            getCoOwnerDetails={this.getCoOwnersList}
                            onContactDelete={this.onContactDelete}
                            onBeneficiaryDelete={this.onBeneficiaryDelete}
                            goToAddInstitutionScreen={this.goToAddInstitutionScreen}
                            coOwnerDelete={this.coOwnerDelete}
                            decryptAccountNo={this.decryptAccountNumber}
                            encryptAccount={this.state.encryptAccount}
                            encryptLoader={this.state.encryptLoader}
                            goToAddAddressScreen={this.goToAddAddressScreen}
                            accessToAsset={this.accessToAsset}
                            error={error}
                            errorMessage={errorMessage}
                            getAddress={this.getAddressDetails}
                            addressLoader={addressLoader}
                            getDecryptAccountNumber={this.handelGetDecryptAccountNumber}
                            showAssetDetailView={showAssetDetailView}
                            showEditOrAddIcon={showEditOrAddIcon}
                            updateAssetEditScreenKey={this.updateAssetEditScreenKeyHandler}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    currentAsset : state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
    assetKeyUpdate : (key) => dispatch(dependentActions.assetKeyUpdate(key)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});


export default connect(mapStateToProps, mapDispatchToProps) (InventoryViewScreen);

