import React, { Component } from 'react';
import AddBeneficiaryComponent from '../components/AddBeneficiaryComponent';
import {secureClient} from "../config/axiosClient";
import A12Y_CONST from "../common/a12yConst";
import {connect} from "react-redux";
import * as userDetailActions from "../redux/actions/userDetailActions";


export class AddBeneficiaryScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            loaderFullScreenVisible : false,
            editMode:false,
            beneficiaryDetails:{}
           }
    }

    componentDidMount = async ()=> {
        this.setState({editMode:false,beneficiaryDetails:{}})
        if(this.props.location && this.props.location?.state && this.props.location.state?.beneficiary){
            this.setState({editMode:true,beneficiaryDetails:this.props.location.state.beneficiary})
        }
    }


    addBeneficiary = async(data)=>{
        try {
            this.setState({loading:true,errorMessage:'',error:false})
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+this.props.currentAsset.id+'/'+A12Y_CONST.API_ENDPOINT.BENEFICIARIES, data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.props.dataChanged(false)
                this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.ADD_BENEFICIARY,e)
        }

    }
    updateBeneficiary = async (data) => {
        try {
            this.setState({loading:true,errorMessage:'',error:false})
            let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.ASSETS+'/'+this.props.currentAsset.id+'/'+A12Y_CONST.API_ENDPOINT.BENEFICIARIES+'/'+this.props.location.state?.beneficiary.id, data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.props.dataChanged(false)
                this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.UPDATE_BENEFICIARY,e)
        }
    }


    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }
    render() {
        const {loading,error,errorMessage,loaderFullScreen,editMode,beneficiaryDetails} = this.state
        return (
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddBeneficiaryComponent
                            loading={loading}
                            error={error}
                            errorMessage={errorMessage}
                            loaderFullScreen={loaderFullScreen}
                            onSubmit={this.addBeneficiary}
                            onUpdate={this.updateBeneficiary}
                            editMode={editMode}
                            beneficiaryDetails={beneficiaryDetails}
                            isDataChanged={this.dataChangeHandler}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    assetTypeList: state.dependentReducer.assetTypeList,
    asset : state.dependentReducer.asset,
    asset_present: state.dependentReducer.asset_present,
    currentAsset:state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
});

export default connect(mapStateToProps, mapDispatchToProps) (AddBeneficiaryScreen);
