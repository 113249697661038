import React, { useState,useEffect } from 'react'
import { useHistory } from "react-router-dom";
import A12Y_CONST from "../common/a12yConst";
import ErrorMessageComponent from "./ErrorMessageComponent";
import Switch from "@material-ui/core/Switch";
import { useForm, Controller } from 'react-hook-form';
import SuccessMessageComponent from "./SuccessMessageComponent";
import CoOwnerDetailComponent from "./CoOwnerDetailComponent";
import BeneficiaryDetailComponent from "./BeneficiaryDetailComponent";
import ContactInformationDetailComponent from "./ContactInformationDetailComponent";
import LifeInsuranceDetailComponent from "./LifeInsuranceDetailComponent";
import BankAccountDetailComponent from "./BankAccountDetailComponent";
import RealEstateDetailComponent from "./RealEstateDetailComponent";
import AutomobileDetailComponent from "./AutomobileDetailComponent";
import BusinessInrestDetailComponent from "./BusinessIntrestDetailComponent";
import PersonalPropertyDetailComponent from "./PersonalPropertyDetailComponent";
import SafeDepositBoxDetailComponent from "./SafeDepositBoxDetailComponent";
import InvestmentAccountDetailComponent from "./InvestmentAccountDetailComponent";
import RetirementAccountDetailComponent from "./RetirementAccountDetailComponent";
import BurialAccountDetailComponent from "./BurialAccountDetailComponent";
import OtherDetailComponent from "./OtherDetailComponent";
import AssetTransferDetailComponent from './AssetTransferDetailComponent';


function InventoryViewComponent(props) {
    /* eslint-disable no-unused-vars */
    const [error, setErrors] = useState(false);
    /* eslint-disable no-unused-vars */
    const [isProvider, setIsProvider] = useState(false);
    const [isLoan, setIsLoan] = useState(false);
    //const [isContact, setIsContact] = useState(false);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [isCoOwner, setIsCoOwner] = useState(false);
    const [showMainLoader, setShowMainLoader] = useState(true);
    const [canUpdate, setCanUpdate] = useState(false);
    const [controlAsset, setControlAsset] = useState(false);
    const {control,setValue} = useForm({ mode: "onBlur" });
    const history = useHistory();
    // const showCoOwnersFor = ['real_estate']
    const showCoOwnersFor = ['bank_account','real_estate','automobile','business_interest','personal_property','safe_deposit_box','personal_property','investment_account','retirement_account','other', 'burial_account'] // remove oce real estate API integration
    const showBeneficaryFor = ['life_insurance','real_estate','bank_account','automobile','business_interest','personal_property','safe_deposit_box','personal_property','investment_account','retirement_account','other', 'burial_account']
    const showContactFor = ['life_insurance','real_estate','bank_account','automobile','business_interest','personal_property','safe_deposit_box','personal_property','investment_account','retirement_account','other', 'burial_account']

    useEffect(() => {
        setControlAsset(props.currentAsset.advocate_control)
        setCanUpdate(props?.dependent?.access?.can_update)
    },[controlAsset, props.currentAsset, props.currentAsset.advocate_control]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        let element = document.getElementById('Policy');
        if(element && element.classList.contains('heightAuto')){
            setShowMainLoader(false)
        }
        props.reloadAssetData()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const handlecontrolAssetChange = (e) => {
        setControlAsset(e.target.checked);
        setValue('controlAsset', e.target.checked)
        props.accessToAsset(e.target.checked)
    }

    const collapse = (elment) => {
        let elm = document.getElementById(elment);
        if(elm !== null){
            elm.parentElement.classList.toggle('opened')
            elm.classList.toggle('heightAuto')
            
        }
    }

    useEffect(() => {
        setIsLoan(true)
        setIsProvider(true)
        setIsBeneficiary(true)
        setIsCoOwner(true)
        //setIsContact(true)
        // collapse('Policy')
        setCanUpdate(props?.dependent?.access?.can_update)
    }, [isLoan, isProvider, isBeneficiary, isCoOwner, props.dependent?.access])



    const goToEditScreen = async (key)=>{
        if(key === A12Y_CONST.TEXT.ASSET_BANK_KEY){
            if(props.currentAsset.bank.account_number !== "" && props.currentAsset.bank.account_number !== null){
               await props.getDecryptAccountNumber(props.currentAsset.id)
               // await this.decryptAccountNumber(this.props.currentAsset?.bank?.account_number)
            }
        }if(key === A12Y_CONST.TEXT.ASSET_BURIAL_ACCOUNT_KEY){
            if(props.currentAsset.burial.account_number !== "" && props.currentAsset.burial.account_number !== null){
               await props.getDecryptAccountNumber(props.currentAsset.id)
               // await this.decryptAccountNumber(this.props.currentAsset?.bank?.account_number)
            }
        }
        await props.updateAssetEditScreenKey(key);
        history.push({
            pathname: A12Y_CONST.ROUTE.EDIT_ASSET,
            search: '?type='+key,  // query string
        });
    }

    function goBack() {
        history.push({
            pathname:A12Y_CONST.ROUTE.INVENTORY,
            state:props.showAssetDetailView ? {tab:A12Y_CONST.TEXT.TRANSFER} : null
        });
    }

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addActivitiesWrap invView">
            <h2 className="titleWithSidebar titleMarginBottomViewScreen marginBottom30 positionRelative viewActivityTitleError">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage} />
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message} />

                            :
                            null
                    )

                }
                <span onClick={goBack} className="cursorPointer">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.42489 16.8708C10.0735 16.1977 10.0784 15.0987 9.43597 14.4197L4.02513 8.68634L9.48758 3.00217C10.1362 2.32899 10.1411 1.23 9.49866 0.550982C8.85785 -0.129777 7.81113 -0.134509 7.16419 0.540429L0.528966 7.44492C-0.119619 8.1181 -0.124587 9.21709 0.517886 9.8961L7.09041 16.8603C7.73123 17.541 8.77795 17.5458 9.42489 16.8708Z" fill="#565C65"/>
                    </svg>
                    <span className="marginLeft10">{A12Y_CONST.LABEL.INVENTORY}</span>
                </span>
                {canUpdate ? 'transfer' in props.currentAsset ? '' :
                    <div className="contorlAccessViewScreen positionAbsoute">
                        <label className="lblExpType">{A12Y_CONST.TEXT.CONTROL_OF_ASSET}</label>
                        <div className="marginTop5 df aic">
                            <Controller
                                name="controlAsset"
                                control={control}
                                render={({field}) =>
                                    <Switch
                                        id={A12Y_CONST.BUTTON_ID.CONTROL_OF_ASSET}
                                        checked={controlAsset}
                                        onChange={handlecontrolAssetChange}
                                        color="primary"
                                    />
                                }
                            />
                            {controlAsset && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}
                            {!controlAsset && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}
                        </div>
                    </div>
                    :
                    null
                }
            </h2>
            <div className="dependentBorderBox paddingNone">
                {props.loading && showMainLoader ?
                    <div className='loaderPol df aic jcc fcol height100 width100 positionAbsouteOnly'>
                        <div className="positionRelative">
                            <div className="dependentLoader">
                                <div className='fullWidth justifyCenter marginBottom10'>
                                    <div className="stage">
                                        <div className="dot-pulse"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='marginTop20 fullWidth'>{A12Y_CONST.TEXT.LOADING_INVENTORY}</div>
                        </div>
                    </div>
                    :
                    null
                }

                {props.showAssetDetailView ?
                    <AssetTransferDetailComponent
                        title={A12Y_CONST.LABEL.SALE_OF_PROPERTY}
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        goToEditScreen={goToEditScreen}
                    />
                    :
                    null
                }

                {/*LifeInsurance*/}
                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_LIFE_INSURANCE_KEY ?
                    <LifeInsuranceDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        gettingLoansDetails={props.gettingLoansDetails}
                        institutesLoader={props.institutesLoader}
                        loanLoader={props.loanLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }
                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_BANK_KEY ?
                    <BankAccountDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        institutesLoader={props.institutesLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        decryptAccountNo={props.decryptAccountNo}
                        encryptAccount={props.encryptAccount}
                        encryptLoader={props.encryptLoader}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_REAL_ESTATE ?
                    <RealEstateDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        goToEditScreen={goToEditScreen}
                        goToAddAddressScreen={props.goToAddAddressScreen}
                        getAddress={props.getAddress}
                        addressLoader={props.addressLoader}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_AUTOMOBILE_KEY ?
                    <AutomobileDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        goToEditScreen={goToEditScreen}
                        institutesLoader={props.institutesLoader}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_BUSINESS_INTEREST_KEY ?
                    <BusinessInrestDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        institutesLoader={props.institutesLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_PERSONAL_PROPERTY_KEY ?
                    <PersonalPropertyDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        goToEditScreen={goToEditScreen}
                        goToAddAddressScreen={props.goToAddAddressScreen}
                        getAddress={props.getAddress}
                        addressLoader={props.addressLoader}
                        institutesLoader={props.institutesLoader}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_SAFE_DEPOSIT_BOX_KEY ?
                    <SafeDepositBoxDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        institutesLoader={props.institutesLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_INVESTMENT_ACCOUNT_KEY ?
                    <InvestmentAccountDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        institutesLoader={props.institutesLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }
                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_RETOREMENT_ACCOUNT_KEY ?
                    <RetirementAccountDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        institutesLoader={props.institutesLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }
                {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_OTHER_KEY ?
                    <OtherDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        institutesLoader={props.institutesLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }
                  {props.currentAsset.asset_type_key === A12Y_CONST.TEXT.ASSET_BURIAL_ACCOUNT_KEY ?
                    <BurialAccountDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        gettingProviderDetails={props.gettingProviderDetails}
                        institutesLoader={props.institutesLoader}
                        goToEditScreen={goToEditScreen}
                        goToAddInstitutionScreen={props.goToAddInstitutionScreen}
                        decryptAccountNo={props.decryptAccountNo}
                        encryptAccount={props.encryptAccount}
                        encryptLoader={props.encryptLoader}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

                {/*ContactInformation*/}
                {showContactFor.indexOf(props.currentAsset.asset_type_key) !== -1 ?
                    <ContactInformationDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.contactsLoader}
                        getContactDeatils={props.getContactDeatils}
                        contactsLoader={props.contactsLoader}
                        onDelete={props.onContactDelete}
                        message={props.successMessage}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

               {/*BeneficaryDetail*/}
                {showBeneficaryFor.indexOf(props.currentAsset.asset_type_key) !== -1 ?
                    <BeneficiaryDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.loading}
                        getBeneficiaryData={props.getBeneficiaryData}
                        beneficiaryLoader={props.beneficiaryLoader}
                        onDelete={props.onBeneficiaryDelete}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }

               {/*CoOwnerDetail*/}
                {showCoOwnersFor.indexOf(props.currentAsset.asset_type_key) !== -1 ?
                    <CoOwnerDetailComponent
                        dependent={props.dependent}
                        currentAsset={props.currentAsset}
                        loading={props.coOwnerLoader}
                        getCoOwnerDetails={props.getCoOwnerDetails}
                        coOwnerLoader={props.coOwnerLoader}
                        coOwnerDelete={props.coOwnerDelete}
                        showEditOrAddIcon={props.showEditOrAddIcon}
                    />
                    :
                    null
                }
            </div>

        </div>
    )
}

export default InventoryViewComponent
