import React, {Component} from 'react'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import HelpComponent from '../components/HelpComponent'


class Help extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : false,
        }
    }

    componentDidMount = async()=>{


    }



    render() {
        return (
            <div className=" myProfileAccount animationall mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <HelpComponent/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
});

const mapDispatchToProps = dispatch => ({
    getAllDependent : (advocate_id) => dispatch(dependentActions.getAllDependent(advocate_id)),
});


export default connect(mapStateToProps, mapDispatchToProps) (Help);

