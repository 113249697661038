// import React from "react";


import AssetAccessControlComponent from "../components/AssetAccessControlComponent";

const A12Y_CONST = {
    API_ENDPOINT: {
        'LOGIN': 'login',
        'SIGNUP': 'signup',
        'SIGNUP_VERIFY_CODE': 'signup/verify_code',
        'SIGNUP_RESEND_VERIFY_CODE': 'signup/resend_verify_code',
        'REPORT_ERROR': 'logMe',
        'FORGOT_PASSWORD': '/password/forgot_password',
        'CONFIRM_PASSWORD': '/password/confirm_password',
        'UPDATE_PROFILE': '/users/profile',
        'UPDATE_PASSWORD': '/users/update_password',
        'ADVOCATE_BASE_URL': '/advocate',
        'ADVOCATE_DEPENDENT': '/dependent',
        'ADVOCATE_DEPENDENTS': '/dependents',
        'GET_ADVOCATE_DEPENDENTS': '/all_dependents',
        'ADDRESSES': '/addresses',
        'ADDRESS': '/address',
        'EXPENSES_ENDPOINT': '/expenses',
        'USER_INVITE': '/users/invite',
        'USER_RE_INVITE': 'users/re_invite',
        'REVOKE_INVITATION': 'users/revoke_invitation',
        'INVITATION_DETAILS': '/invitation_details',
        'ADVOCATE': '/advocate',
        'TEAMS': 'teams',
        'EXPENSE_EXPENSE_TYPES': '/expense/expense_types',
        'DEBT_DEBT_TYPES': '/debt/debt_types',
        'EXPENSES': 'expenses',
        'DOCS_MULTI_S3_SIGN_URL_PUT': 'docs/multi_s3_sign_url_put',
        'EXPENSE_BY_DATE_RANGE': 'expense_by_date_range',
        'INCOME_BY_DATE_RANGE': 'income_by_date_range',
        'ASSET_BY_DATE_RANGE': 'asset_by_date_range',
        'DEBT_BY_DATE_RANGE': 'debt_by_date_range',
        'INCOME': 'income/',
        'INCOME_TYPES': 'income_types',
        'INCOMES': 'incomes',
        'METADATA': 'metadata',
        'DOCS_S3_SIGN_URL_GET': 'docs/s3_sign_url_get',
        'DOCS_S3_SIGN_URL_PUT': '/docs/s3_sign_url_put',
        'PROFILE_PC': '/profile_pic',
        'DELETE_PROFILE_PIC': 'delete_profile_pic',
        'ACTIVITY': 'activity',
        'ACTIVITY_BY_DATE_RANGE': 'activity_by_date_range',
        'ACTIVITY_TYPES': 'activity_types',
        'ACTIVITIES': 'activities',
        'DEVELOPMENT': 'development',
        'DOCUMENTS': 'documents',
        'USERS_PREFERENCES': 'users/preferences',
        'INVENTORY': 'Inventory',
        'ASSETS':'assets',
        'ASSET_TYPES':'asset_types',
        'ASSET_LIST':'asset_list',
        'INSTITUTES':'institutes',
        'ADVOCATE_ACCESS':'advocate_access',
        'LOANS':'loans',
        'CONTACTS':'contacts',
        'ATTACHMENTS':'attachments',
        'CREATE_SUBSCRIPTION_WITH_PAYMENT' : '/subscription/subscribe_with_payment',
        'UPDATE_PAYMENT_METHOD' : '/subscription/update_payment_method',
        'CANCEL_SUBSCRIPTION' : '/subscription/cancel_subscription',
        'OWNERS':'owners',
        'BENEFICIARIES':'beneficiaries',
        'GET_INVOICES' : '/subscription/get_invoices',
        'DECRYPT':'decrypt_bank_account',
        'TRANSFER':'transfers',
        'TRANSFERED_ASSETS':'transferred_assets',
        'DOCTORS':'doctors',
        'MEDICINES':'medicines',
        'SPECIALITY':'speciality',
        'DEACTIVATE':'deactivate',
        'DEBTS':'debts',
        'MOVE':'move',
        'ROLE_TYPE':'users/role_type',
        'ALL_DEPENDENTS':'all_dependents',
        'VALIDATE_PROMOCODE':'/subscription/validate_promo_code',
        'APPLY_PROMOCODE':'/subscription/apply_promo_code',
        'LOGIN_URL':'login_url',
        'TRACK_USER':'track_user',
        'KLAVIYO_STATUS':'/users/klaviyo_status',
        'NOTES':'notes'
    },
    API_RESPONSE_STATUS: {
        'OK': 'ok',
        'FAILED': 'failed',
        'CAP_OK': 'OK'
    },
    'ROUTE': {
        'HOME': '/',
        'LOGIN': '/login',
        'SIGNUP': '/register',
        'ABONELAW': '/abonelaw',
        'SPONSOREXAMPLE': '/sponsorexample',
        'TRUSTWORTHY' : '/trustworthy',
        'CODE': '/code',
        'SIGN_UP_EMAIL_VARIFICATION': '/signUpEmailVerification',
        'DASHBOARD': '/secure',
        'FORGOT_PASSWORD_EMAIL': '/ForgotPasswordEmail',
        'FORGOT_PASSWORD_VARIFICATION_CODE': '/forgotPasswordVerificationCode',
        'FORGOT_CREATE_PASSWORD': '/forgotCreatePassword',
        'FORGOT_PASSWORD_UPDATED': '/ForgotPasswordUpdated',
        'ADVOCATE_PROFILE_MY_ACCOUNT': '/AdvocateProfileMyAccount',
        'EMAIL_LOGIN': '/accountVerified',
        'PASSWORD_UPDATED': '/passwordUpdated',
        'LOGOUT': '/logout',
        'DEPENDENT': '/dependent',
        'DEPENDENT_BILLING': '/AdvocateProfileMyAccount#billing',
        'DEPENDENT_VIEW': '/DependentProfileVIew',
        'EXPENSES': '/expenses',
        'ADD_EXPENSE': '/addExpense',
        'EXPENSE_VIEW': '/expenseView',
        'INCOMES': '/income',
        'ADD_INCOME': '/addIncome',
        'INCOME_VIEW': '/incomeView',
        'DOCUMENTS': '/documents',
        "HELP": '/help',
        'ACTIVITIES': '/activities',
        'ADD_ACTIVITY': '/addActivity',
        'INVENTORYVIEW': '/InventoryView',
        'ACTIVITY_VIEW': '/activityView',
        'INVENTORY': '/inventory',
        'INVENTORY_VIEW': '/inventoryView',
        'ADD_ASSET': '/addAsset',
        'EDIT_ASSET': '/editAsset',
        'ADD_CO_OWNER': '/addCoOwner',
        'ADD_CONTACT': '/addContact',
        'EDIT_CONTACT': '/editContact',
        'ADD_BENEFICIARY': '/AddBeneficiary',
        'ADD_INSTITUTION': '/addInstitution',
        'EDIT_BENEFICIARY': '/EditBeneficiary',
        'EDIT_CO_OWNER': '/EditCo-Owner',
        'ADD_LOAN': '/AddLoan',
        'ADD_ADDRESS': '/AddAddress',
        'ADD_DEBT': '/AddDebt',
        'EDIT_DEBT': '/EditDebt',
        'DEBT_VIEW': '/debtView',
        'DASHBOARD_VIEW': '/dashboard',
        'MEDICAL': '/medical',
        'ADD_DOCTOR': '/AddProvider',
        'EDIT_DOCTOR': '/EditProvider',
        'DOCTOR_VIEW': '/providerView',
        'ADD_MEDICATION': '/addMedication',
        'MEDICATION_VIEW': '/medicationView',
        'MY_ACCOUNT': '/myAccount',
        'ADVOCATE_MYACCOUNT': '/AdvocateProfileMyAccount/myAccount',
        'ADVOCATE_MYACCOUNT_NEW': '/myAccountNew',
        'RELEASE_NOTES': '/releaseNotes',
        'SIGNUP_EMAIL': '/registerWithEmail',

    },
    DISPATCH_ACTION: {
        'UNAUTHORIZED': 'UNAUTHORIZED',
        'LOGIN_START': 'LOGIN_START',
        'LOGIN_SUCCESS': 'LOGIN_SUCCESS',
        'LOGIN_ERROR': 'LOGIN_ERROR',
        'LOGOUT': 'LOGOUT',
        'NOT_CONFIRM_USER': 'NOT_CONFIRM_USER',
        'SIGNUP_START': 'SIGNUP_START',
        'SIGNUP_SUCCESS': 'SIGNUP_SUCCESS',
        'SIGNUP_ERROR': 'SIGNUP_ERROR',
        'UPDATE_USER_PROFILE_ERROR': 'UPDATE_USER_PROFILE_ERROR',
        'UPDATE_USER_PROFILE_START': 'UPDATE_USER_PROFILE_START',
        'UPDATE_USER_PROFILE_SUCCESS': 'UPDATE_USER_PROFILE_SUCCESS',
        'UPDATE_USER_PASSWORD_START': 'UPDATE_USER_PASSWORD_START',
        'UPDATE_USER_PASSWORD_ERROR': 'UPDATE_USER_PASSWORD_ERROR',
        'UPDATE_USER_PASSWORD_SUCCESS': 'UPDATE_USER_PASSWORD_SUCCESS',
        'GET_DEPENDENT_START': 'GET_DEPENDENT_START',
        'GET_DEPENDENT_SUCCESS': 'GET_DEPENDENT_SUCCESS',
        'GET_DEPENDENT_ERROR': 'GET_DEPENDENT_ERROR',
        'CREATE_DEPENDENT_START': 'CREATE_DEPENDENT_START',
        'CREATE_DEPENDENT_SUCCESS': 'CREATE_DEPENDENT_SUCCESS',
        'CREATE_DEPENDENT_ERROR': 'CREATE_DEPENDENT_ERROR',
        'UPDATE_DEPENDENT_START': 'UPDATE_DEPENDENT_START',
        'UPDATE_DEPENDENT_SUCCESS': 'UPDATE_DEPENDENT_SUCCESS',
        'UPDATE_DEPENDENT_ERROR': 'UPDATE_DEPENDENT_ERROR',
        'CREATE_ADDRESSES_START': 'CREATE_ADDRESSES_START',
        'CREATE_ADDRESSES_SUCCESS': 'CREATE_ADDRESSES_SUCCESS',
        'CREATE_ADDRESSES_ERROR': 'CREATE_ADDRESSES_ERROR',
        'UPDATE_ADDRESSES_START': 'UPDATE_ADDRESSES_START',
        'UPDATE_ADDRESSES_SUCCESS': 'UPDATE_ADDRESSES_SUCCESS',
        'UPDATE_ADDRESSES_ERROR': 'UPDATE_ADDRESSES_ERROR',
        'GET_ADDRESSES_START': 'GET_ADDRESSES_START',
        'GET_ADDRESSES_SUCCESS': 'GET_ADDRESSES_SUCCESS',
        'GET_ADDRESSES_ERROR': 'GET_ADDRESSES_ERROR',
        'DELETE_ADDRESSES_START': 'DELETE_ADDRESSES_START',
        'DELETE_ADDRESSES_SUCCESS': 'DELETE_ADDRESSES_SUCCESS',
        'DELETE_ADDRESSES_ERROR': 'DELETE_ADDRESSES_ERROR',
        'UPDATE_PROFILE_PIC': 'UPDATE_PROFILE_PIC',
        'CLEAN_DEPENDENT': 'CLEAN_DEPENDENT',
        'IS_FIELD_UPDATED': 'IS_FIELD_UPDATED',
        'CURRENT_EXPENSE_DATA': 'CURRENT_EXPENSE_DATA',
        'CURRENT_INCOME_DATA': 'CURRENT_INCOME_DATA',
        'CURRENT_ACTIVITY_DATA': 'CURRENT_ACTIVITY_DATA',
        'CURRENT_ASSET_DATA': 'CURRENT_ASSET_DATA',
        'CURRENT_DEBT_DATA': 'CURRENT_DEBT_DATA',
        'CURRENT_DOCTOR_DATA': 'CURRENT_DOCTOR_DATA',
        'CURRENT_MEDICATION_DATA': 'CURRENT_MEDICATION_DATA',
        'MEDICATION_DATA': 'MEDICATION_DATA',
        'CREATE_EXPENSE_USING_ACTIVITY_LOG': 'CREATE_EXPENSE_USING_ACTIVITY_LOG',
        'CREATE_EXPENSE_USING_ACTIVITY_VIEW_LOG': 'CREATE_EXPENSE_USING_ACTIVITY_VIEW_LOG',
        'VIEW_EXPENSE_USING_ACTIVITY_VIEW': 'VIEW_EXPENSE_USING_ACTIVITY_VIEW',
        'DOCUMENT_SCREEN_CHECKED': 'DOCUMENT_SCREEN_CHECKED',
        'ACTIVITY_TYPE_LIST_START': 'ACTIVITY_TYPE_LIST_START',
        'ACTIVITY_TYPE_LIST_SUCCESS': 'ACTIVITY_TYPE_LIST_SUCCESS',
        'ACTIVITY_TYPE_LIST_ERROR': 'ACTIVITY_TYPE_LIST_ERROR',
        'EXPENSE_TYPE_LIST_START': 'EXPENSE_TYPE_LIST_START',
        'EXPENSE_TYPE_LIST_SUCCESS': 'EXPENSE_TYPE_LIST_SUCCESS',
        'EXPENSE_TYPE_LIST_ERROR': 'EXPENSE_TYPE_LIST_ERROR',
        'DEBT_TYPE_LIST_START': 'DEBT_TYPE_LIST_START',
        'DEBT_TYPE_LIST_SUCCESS': 'DEBT_TYPE_LIST_SUCCESS',
        'DEBT_TYPE_LIST_ERROR': 'DEBT_TYPE_LIST_ERROR',
        'INCOME_TYPE_LIST_START': 'INCOME_TYPE_LIST_START',
        'INCOME_TYPE_LIST_SUCCESS': 'INCOME_TYPE_LIST_SUCCESS',
        'INCOME_TYPE_LIST_ERROR': 'INCOME_TYPE_LIST_ERROR',
        'UPDATE_USERS_PREFERENCES': 'UPDATE_USERS_PREFERENCES',
        'DEPENDENT_DOCS': 'DEPENDENT_DOCS',
        'MY_ACCOUNT_CLICKED': 'MY_ACCOUNT_CLICKED',
        'LEGAL_DOC_ID': 'LEGAL_DOC_ID',
        'IS_COMMING_FROM_DEPENDENT_VIEW': 'IS_COMMING_FROM_DEPENDENT_VIEW',
        'IS_COMMING_FROM_DEPENDENT_INVOICE_VIEW': 'IS_COMMING_FROM_DEPENDENT_INVOICE_VIEW',
        'IS_COMMING_FROM_ACTIVITY_LIST': 'IS_COMMING_FROM_ACTIVITY_LIST',
        'UPDATE_META_DATA': 'UPDATE_META_DATA',
        'UPDATE_MEDICINE_PRESENT_META_DATA': 'UPDATE_MEDICINE_PRESENT_META_DATA',
        'UPDATE_DOCTOR_PRESENT_META_DATA': 'UPDATE_DOCTOR_PRESENT_META_DATA',
        'USER_SESSION_OUT_USER_ID': 'USER_SESSION_OUT_USER_ID',
        'USER_SESSION_OUT_SCREEN': 'USER_SESSION_OUT_SCREEN',
        'ASSET_TYPE_LIST_START': 'ASSET_TYPE_LIST_START',
        'ASSET_TYPE_LIST_SUCCESS': 'ASSET_TYPE_LIST_SUCCESS',
        'ASSET_TYPE_LIST_ERROR': 'ASSET_TYPE_LIST_ERROR',
        'ASSET_LIST_START': 'ASSET_LIST_START',
        'ASSET_LIST_SUCCESS': 'ASSET_LIST_SUCCESS',
        'ASSET_LIST_ERROR': 'ASSET_LIST_ERROR',
        'UPDATE_SESSION_TIMEOUT': 'UPDATE_SESSION_TIMEOUT',
        'SELECTED_TYPE_ASSET': 'SELECTED_TYPE_ASSET',
        'CREATE_ASSET_START': 'CREATE_ASSET_START',
        'CREATE_ASSET_SUCCESS': 'CREATE_ASSET_SUCCESS',
        'CREATE_ASSET_ERROR': 'CREATE_ASSET_ERROR',
        'ASSET_OBJ': 'ASSET_OBJ',
        'TOKEN_REFRESHED': 'TOKEN_REFRESHED',
        'CREATE_USER_SUBSCRIPTION_START': 'CREATE_USER_SUBSCRIPTION_START',
        'CREATE_USER_SUBSCRIPTION_SUCCESS': 'CREATE_USER_SUBSCRIPTION_SUCCESS',
        'CREATE_USER_SUBSCRIPTION_ERROR': 'CREATE_USER_SUBSCRIPTION_ERROR',
        'USER_SUBSCRIPTION_SUCCESS': 'USER_SUBSCRIPTION_SUCCESS',
        'UPDATE_PAYMENT_METHOD_START': 'UPDATE_PAYMENT_METHOD_START',
        'UPDATE_PAYMENT_METHOD_SUCCESS': 'UPDATE_PAYMENT_METHOD_SUCCESS',
        'UPDATE_PAYMENT_METHOD_ERROR': 'UPDATE_PAYMENT_METHOD_ERROR',
        'CANCEL_SUBSCRIPTION_START': 'CANCEL_SUBSCRIPTION_START',
        'CANCEL_SUBSCRIPTION_SUCCESS': 'CANCEL_SUBSCRIPTION_SUCCESS',
        'CANCEL_SUBSCRIPTION_ERROR': 'CANCEL_SUBSCRIPTION_ERROR',
        'ADD_CO_OWNER_START': 'ADD_CO_OWNER_START',
        'ADD_CO_OWNER_SUCCESS': 'ADD_CO_OWNER_SUCCESS',
        'ADD_CO_OWNER_ERROR': 'ADD_CO_OWNER_ERROR',
        'DOCTOR_SPECIALIST_LIST_START': 'DOCTOR_SPECIALIST_LIST_START',
        'DOCTOR_SPECIALIST_SUCCESS': 'DOCTOR_SPECIALIST_SUCCESS',
        'DOCTOR_SPECIALIST_TYPE_LIST_ERROR': 'DOCTOR_SPECIALIST_TYPE_LIST_ERROR',
        'GET_ALL_DOCTORS_START': 'GET_ALL_DOCTORS_START',
        'GET_ALL_DOCTORS_SUCCESS': 'GET_ALL_DOCTORS_SUCCESS',
        'GET_ALL_DOCTORS_ERROR': 'GET_ALL_DOCTORS_ERROR',
        'UPDATE_DOCTORS_LIST': 'UPDATE_DOCTORS_LIST',
        'DEPENDENT_PROFILE': 'DEPENDENT_PROFILE',
        'ASSET_EDIT_SCREEN_KEY': 'ASSET_EDIT_SCREEN_KEY',
        'USER_ROLE_TYPE':'USER_ROLE_TYPE',
        'SIGNUP_TEXT':'SIGNUP_TEXT',
        'SWITCH_DEPENDENT': 'SWITCH_DEPENDENT',
        'TAB_NAME': 'TAB_NAME',
        'UPDATE_DEPENDENT_LIST': 'UPDATE_DEPENDENT_LIST',
        'INVITATION_DEPENDENT': 'INVITATION_DEPENDENT',
        'IS_TRIAL_REMINDER':'IS_TRIAL_REMINDER',
        'IS_WELCOME_POPUP':'IS_WELCOME_POPUP',
        'BILLING_SCREEN':'BILLING_SCREEN',
        'INVITE_CODE':'INVITE_CODE',
        'USER_SOURCE_LOGIN':'USER_SOURCE_LOGIN',
        'SET_LOGOUT_POPUP_STATUS':'SET_LOGOUT_POPUP_STATUS',
        'SELECTED_FILLTER_TYPE_LIST':'SELECTED_FILLTER_TYPE_LIST',
        'SELECTED_FILLTER_TYPE_LIST_INCOME':'SELECTED_FILLTER_TYPE_LIST_INCOME',
        'SELECTED_FILLTER_TYPE_LIST_EXPENSE':'SELECTED_FILLTER_TYPE_LIST_EXPENSE',
        'SELECTED_FILTERED':'SELECTED_FILTERED',
        'USER_SUBSCRIPTION_DETAIL_UPDATE':'USER_SUBSCRIPTION_DETAIL_UPDATE',
        'IS_SURVEY_ROLE_POPUP':'IS_SURVEY_ROLE_POPUP',
        'IS_SURVEY_POPUP':'IS_SURVEY_POPUP',
        'PATH_BEFORE_LOGIN':'PATH_BEFORE_LOGIN',
        'VIEW_MEDICAL_VIEW':'VIEW_MEDICAL_VIEW',
        'PREVEIOUS_MEDICATION_DATA':'PREVEIOUS_MEDICATION_DATA'
    },
    TEXT: {
        'TITLE_HOME': 'Advocord – Home',
        'DEPENDENT_VIEW_ID': 'dependentViewId',
        'PRIVACY_POLICY_URL': 'http://www.advocord.com/privacy/',
        'TERMS_OF_SERVICE_URL': 'http://www.advocord.com/termsofservice/',
        'ADVOCORT_URL':'http://advocord.com',
        'ADVOCORT_FAQ_URL':'http://advocord.com/faq',
        'ADVOCORT_PRICING_URL':'https://advocord.com/pricing',
        'INCOME_NOTE_PLACEHOLDER':'Capture a note about this income.',
        'EXPENSE_NOTE_PLACEHOLDER':'Capture the details of this expense.',
        'ACCOUNTABILITY': 'Advocord - ',
        'ADVOCORD': 'Advocord Platform | ',
        'TITLE_SIGNUP': 'Advocord – Sign Up',
        'TITLE_VERIFY_CODE': 'Advocord – Verify Code',
        'TITLE_LOGIN': 'Advocord – Log In',
        'TITLE_DASHBOARD': 'Advocord – Dashboard',
        'ARE_YOU_SURE_YOU':'Are you sure you want to cancel your subscription?',
        'TITLE_MY_ACCOUNT': "My Account",
        'TITLE_MY_ACCOUNT_EDIT': "Edit My Account ",
        'ADDRESS': 'Address',
        'CHROME': 'chrome',
        'SAFARI': 'safari',
        'FIREFOX': 'firefox',
        'PASSWORD_ASTRIC': '************',
        "ARE_YOU_SURE": 'Are you sure?',
        'INVITE_VIEW_ONLY': 'Invite a view-only user',
        'INVITE_USER': 'Invite a user',
        'SUCCESSFULLY_INVITE': 'Successfully Invited',
        'CANCEL_INVITE': 'Cancel this invitation.',
        'DELETE_INVITE': 'Delete this invitation.',
        'RESEND_INVITE': 'Resend the invitation to this user.',
        'DELETE_VIEWER_ACCESS': 'Delete Viewer Access',
        'CURRENT_ADDRESS': 'Current Address',
        'DELETE_THIS_ADDRESS': 'Delete this Address?',
        'YOU_HAVE_BEEN': 'You’ve been invited by,',
        'TO_CREATE_AN_ACCOUNT': 'to create an account and access',
        'ACCOUNTABILITY_INFORMATION': 'Advocord information.',
        'REMOVED_ACCESS': 'Access removed.',
        'TRY_AGAIN': 'Try again.',
        'ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT': 'Are you sure you want to delete?',
        'DOCUMENTS': 'Documents',
        'DELETE_THIS_FILE': 'Delete this File?',
        'DELETE_THIS_FOLDER': 'Delete this Folder?',
        'DOCUMENT_TYPE': 'Document type',
        'DOCUMENT_UNABLE_TO_REMOVE': 'We can\'t remove this document.',
        'CANNOT_DELETE_FOLDER': 'We can\'t delete this folder.',
        'YOU_HAVE_TO_DELETE_ALL': 'Delete all the items in this folder before deleting the folder.',
        'REMOVE_EXPENSE': 'Remove Expense',
        'YOU_ARE_NOT_DELETING_THE_EXPENSE': 'This action will only remove this expense from this activity. You can still access the expense in your Expenses tab.',
        'ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ATTACHMENT': 'Are you sure you want to delete this attachment?',
        'RENAME': 'Rename',
        'Move_TO': 'Move To',
        'DELETE': 'Delete',
        'LEGAL_NOTE': 'Legal Note',
        "IT_LOOKS_LIKE_YOU_PHASE1": 'It looks like you don\'t currently have access to this asset. We suggest reaching out to the institution to let them know you are',
        "IT_LOOKS_LIKE_YOU_PHASE2": 'legal guardian. They may require legal documents from you to grant you access.',
        "INSTITUTION_LIFE_INSURANCE_TITLE": "Life Insurance Policy Provider",
        "LIFE_INSURANCE_PROVIDE": "Life Insurance Provider",
        "ASSET_LIFE_INSURANCE_KEY": "life_insurance",
        "ASSET_BUSINESS_INTEREST_KEY": "business_interest",
        "ASSET_REAL_ESTATE": "real_estate",
        "ASSET_BANK_KEY": "bank_account",
        "ASSET_PERSONAL_PROPERTY_KEY": "personal_property",
        'ASSET_SAFE_DEPOSIT_BOX_KEY': 'safe_deposit_box',
        'ASSET_INVESTMENT_ACCOUNT_KEY': 'investment_account',
        'ASSET_AUTOMOBILE_KEY': 'automobile',
        'ASSET_INSTITUTION_KEY': 'institution',
        'ASSET_ADDRESS_KEY': 'address',
        'LOAN_AGAINST_KEY': 'loan_against_key',
        "CURRENCY_FORMAT": "en-US",
        'WHO_IS': 'Who is',
        'WHAT_IS': 'What is',
        'WHERE_IS': 'Where is',
        'EXPIRED': 'EXPIRED',
        'ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE': 'Cancel Subscription?',
        'REMOVE_SUBSCRIPTION_TEXT': 'We\'re sorry to see you go! Your subscription will expire on #expirydate#. After that date, you won\'t be able to access any data. Are you sure you still want to cancel?',
        'REMOVE_SUBSCRIPTION': 'Cancel Subscription',
        'UPDATE_ADVOCATE_ACCESS': 'update_advocate_access',
        'UPDATE_DISABILITY_CAR_INSURANCE': 'update_disability_car_insurance',
        'ASSET_RETOREMENT_ACCOUNT_KEY': 'retirement_account',
        'ASSET_OTHER_KEY': 'other',
        'ASSET_TRANSFER_KEY': 'asset_transfer',
        'ASSET_BURIAL_ACCOUNT_KEY': 'burial_account',
        'BENEFICIARY_INFORMATION': 'Beneficiary Information',
        'BANK_INFORMATION': 'Bank Information',
        'to': 'to',
        'FILTER': 'Filter',
        'APPLY':'Apply',
        'MEDICATION': 'medication',
        'TOTAL': 'Total',
        'INVOICE': 'Invoice',
        'DOWNLOAD': 'Download',
        'MOVE_TO':'Move to',
        'COLON': ':',
        'PLUS_SYMBOL': '+',
        'DOLLOR_SYMBOL': '$',
        'percent_SYMBOL': '%',
        'HASH_SYMBOL': '#',
        'PRINT': 'Print',
        'ACTIVITY': 'Activity',
        'EDIT': 'Edit',
        'Asset_of': 'Asset of',
        'NO_PROVIDER': 'No Provider',
        'LOADING_ACTIVITY': 'Loading Activity...',
        'LOADING_CO_OWNERS': 'Loading Co-owners...',
        'ADD_LIFE_INSURANCE_POLICY_PROVIDER': 'Add Life Insurance Policy Provider',
        'LOADING_LIFE_INSURANCE_PROVIDER': 'Loading Life Insurance Policy Provider...',
        'NO_CO_OWNERS': 'No Co-Owners',
        'LOADING_BUSINESS': 'Loading Business...',
        'LOADING_CONTACT_INFORMATION': 'Loading Contact Information...',
        'LOADING_BUSINESS_DETAIL': 'Loading Busienss Detail...',
        'ADD_BUSINESS_DETAIL': 'Add Business Detail',
        'LOADING_AUTOMOBILE': 'Loading Automobile...',
        'LOADING_BANK_ACCOUNT': 'Loading Bank Account...',
        'LOADING_BENEFICIARY': 'Loading Beneficiary...',
        'LOADING_BURIAL_ACCOUNT': 'Loading Burial Account...',
        'LOADING_BURIAL_ACCOUNT_PROVIDER': 'Loading Burial Account Provider...',
        'NO_BURIAL_ACCOUNT_PROVIDER': 'No Burial Account Provider',
        'ADD_BURIAL_ACCOUNT_PROVIDER': 'Add a burial account provider.',
        'NO_DATA_FOUND': 'No Data Found',
        'NO_DATA_FOUND_MESSAGE': 'We can\'t find any data within this date range.',
        'CONTROL_OF_ASSET': 'CONTROL OF ASSET?',
        'SELECT_FILE_UPLOAD_MESSAGE': 'Select a file or files to upload.',
        'IMPORTANT_NOTE': 'Important Note',
        'NO_DETAIL': 'No Detail',
        'NO_CONTACT': 'No Contact',
        'LOADING_ADDRESS': 'Loading Address...',
        'LOADING_DOCTORS': 'Loading Doctors...',
        'LOADING_PROVIDER': 'Loading Providers...',
        'LOADING_POLICY': 'Loading Policy...',
        'LOADING_EXPENSES': 'Loading Expenses...',
        'LOADING_INCOMES': 'Loading Income...',
        'LOADING_ASSETS': 'Loading Assets...',
        'LOADING_DEBTS': 'Loading Debts...',
        'LOADING_TRANSFER': 'Loading Transfer...',
        'LOADING_INVENTORY': 'Loading Inventory...',
        'LOADING_MEDICAL': 'Loading Medications...',
        'LOADING_INVEST_ACCOUNT': 'Loading Investment Account...',
        'LOADING_OTHER_ACCOUNT': 'Loading Other Account...',
        'LOADING_LOCATION': 'Loading Location...',
        'NO_ADDRESS': 'No Address',
        'LOADING': 'Loading...',
        'LOADINGNote': 'Loading',
        'LOADING_SAFE_DEPOSITE_BOX': 'Loading Safe Deposit Box...',
        'LOADING_INSTITUTION': 'Loading Institution...',
        'LOADING_PERSONAL_PROPERTY': 'Loading Personal Property...',
        'TRACK': 'Track',
        'HELLO': 'Hello',
        'DEBTS': 'DEBTS',
        'DOCTOR': 'DOCTOR',
        'DEBT': 'debt',
        'TRANSFER': 'TRANSFER',
        'ASSET': 'ASSET',
        'INACTIVE': 'Inactive',
        'ACTIVE': 'Active',
        'ACTIVE_UPPER': 'ACTIVE',
        'UPLOAD_PHOTO': 'Upload Photo',
        'YOU_HAVE_REGISTERED_WITH_EMAIL': 'Congratulations! You\'ve successfully registered with your email address.',
        'LOADING_REAL_ESTATE': 'Loading Real Estate...',
        'STAY_ON_TOP_OF_INCOME': 'Stay on top of Income',
        'COMPARE_EXPENSE_FROM_TO_YEAR': 'Compare expenses from year to year.',
        'REPORT_TOTAL_ANNUAL_EXPENSE': 'Report on the total volume of annual expenses.',
        'STAY_ON_TOP_OF_EXPENSES': 'Stay on top of Expenses',
        'TRANSFER_ASSETS': 'Transfer assets.',
        'DOWNLOADING_FILE': 'Downloading File...',
        'ARE_YOU_SURE_TEXT': 'Are you sure?',
        'NO_INVITED_USER': 'No Invited User',
        'QUICKLY_RECORD_CLASSIFY_TYPES_OF_EXPENSES': 'Quickly record and classify types of expenses.',
        'DOCUMENT_STORAGE': 'Document Storage',
        'ALLOW_TRANSPARENCY_ADVOCATE_TEAM': 'Allow for transparency with the advocate team.',
        'UPLOAD_DOWNLOAD_MULTIPLE_FILE_FORMATS': 'Upload and download multiple file formats.',
        'USE_PREDEFINED': 'Use predefined or make your own.',
        'ANOTHER_MARKETING_BULLET': 'Another Marketing Bullet if required.',
        'COMPARE_INCOME_STREAM': 'Compare income streams from year to year.',
        'REPORT_TOTAL_ANNUAL_INCOME': 'Report on total value of annual income',
        'QUICKLY_RECORD_INCOME': 'Quickly record and classify types of income',
        'LOADING_LOAN_AGAINST_POLICY': 'Loading Loan Against Policy...',
        'INVITE_USER_MESSAGE': 'This invitation will allow view-only access to the user you\'re inviting. Make sure this email address belongs to someone you trust.',
        'DEPENDENT_TAKE_LOAN_FROM_POLICY_MESSAGE': 'Did the dependent take any loans from this policy?',
        'LOADING_INVITE_USER': 'Loading Invited User...',
        'STAY_ORGANIZED_ADDING_DOCUMENTS': 'Stay organized by adding documents to folders.',
        'TRACK_CURRENT_ADDRESS_MESSAGE': 'Track current and past addresses.',
        'ADD_CONTACT_INFORMATION': 'Add contact information.',
        'ADD_CONTACT_INSTITUTION_MESSAGE': 'Add Contacts at the Institution or others involved in this asset.',
        'ENTER_NEW_PASSWORD': 'Enter New Password',
        'LOADING_RETIREMENT_ACCOUNT': 'Loading Retirement Account...',
        'STAY_ON_TOP_ACTIVITY': 'Stay on top of Activity Log',
        'TRACK_YOUR_TIME': 'Track your time spent as an advocat.',
        'REMEMBER_EVENTS': 'Remember what events took place.',
        'ADD_MULTIPLE_EXPENSE_TO_ACTIVITY': 'Note haircuts, grocery shopping and other errands.',
        'DROP_FILE_MESSAGE': 'Drag and drop your file, or click here to upload.',
        'DEPENDENT_PROFILE_DETAIL_TEST_MESSAGE': 'This is where you can log and track basic information like your dependent\'s address, phone number, etc., so that it\'s readily available when you need it. You can also store any court documents related to your dependent here.',
        'I_ACCEPT_SUBSCRIPTION_T': 'I accept subscription T',
        'SIGN_OUT_MESSAGE': 'Are you sure you want to sign out?',
        'BILLING': 'Billing',
        'PERSONAL': 'Personal',
        'SIGN_IN_SECURITY': 'Sign In & Security',
        'YOU_WILL_BE_CHARGED_MESSAGE': 'You will be charged automatically on the renewal date, unless unsubscribed.',
        'YOUR_NEXT_RENEWAL':'Your next renewal',
        'PLEASE_ENTER_CARD_DETAIL_MESSAGE': 'Please enter your card details below.',
        'ADD_BENEFICIARY_CONTACT_INFORMATION_MESSAGE': 'Add Beneficiaries and their contact information.',
        'SINCE_YOU_DONT_CURRENTLY_HAVE': 'Since you don’t currently have access to this asset, we suggest that you locate and secure the asset, as well as any keys and/or electronic access codes related to the asset. You will need to present your legal documents to the person or entity currently in possession of the asset.',
        'TRACK_ALL_ACTIVITIES':'Track all activities in one spot.',
        'ACTIVITIES_CAN_INCLUDE':'Keeping track of the time you spend with your dependent is important for a variety of reasons. In many cases, you can bill for your time, and some states require time reports — with designated minimums.',
        'DEPENDING_ON_YOUR_SITUATION':"Logging each and every activity with #dependentName# can feel overwhelming, but Advocord makes it easy to stay organized with tags, notes and integrated expenses.",
        'HERE_ARE_SOME_WAYS':'Here are some examples of activities you can log:',
        'TRACT_YOUR_TIME':"Note when you take #dependentName# to lunch.",
        'REVIEW_AND_MANAGE':"Note time spent reviewing bills or expenses with #dependentName#.",
        'ADD_MULTIPLE_EXPENSES':'Add multiple expenses to an activity.',
        'STAY_ON_TOP_OF':'Stay on top of',
        'EXPENSES_DOT':'expenses.',
        'INCOME_DOT':'income.',
        'JUST_AS_WITH_INCOME':'Just as with income, logging expenses when they occur makes it significantly easier to review past spending, discover trends and simplify year-end reporting.',
        'USE_THIS_SECTION_TO':'Use this section to record and manage recurring or one-time expenses. You can also compare expenses year-over-year and share information with viewers you invite to the platform.',
        'ADD_YOUR_FIRST_EXPENSE':'Add your first expense to explore:',
        'QUICKLY_RECORD_AND_CLASSIFY':'Quickly record and classify types of expenses.',
        'REPORT_ON_TOTAL_VALUE':'Report on total value of annual expenses.',
        'COMPARE_EXPENSES_FROM_YEAR':'Compare expenses from year to year.',
        'INCOME_STREAMS':'Income Streams',
        'LOGGING_AND_TRAKING_YOUR':'Logging and tracking your dependent’s income throughout the year offers a clearer picture of their earnings over time and makes year-end reporting a breeze.',
        'IN_THIS_SECTION':'In this section, you can record and manage a variety of income sources, whether daily, monthly, annually or one-time-only. You can also see trends over time, share information with viewers you invite or generate year-end compliance reports.',
        'ADD_AN_INCOME_TO_EXPLORE':'Add an income source to explore:',
        'INVENTORY_DESCRIPTIONLIST_TITLE':'Advocord helps you more easily see the big picture:',
        'QUICKLY_RECORD_AND_CLASSIFY_TYPES_OF_INCOMES':'Quickly record and classify types of income.',
        'REPORT_ON_TOTAL_VALUE_OF_ANNUAL_INCOME':'Report on total value of annual income.',
        'COMPARE_INCOME_FROM_YEAR':'Compare income streams from year to year.',
        'KEEP_TRACK_OF_IMPORTANT_DETAILS':'Keep track of important details.',
        'INVENTORY_MAIN_TITLE':"Build #dependentName#'s financial foundation.",
        'IT_CAN_BE_NEARLY':'It can be nearly impossible to manually keep track of all of the details and documents related to your dependent’s financial and medical needs.',
        'IN_THIS_SECTION_YOU_CAN_LOG':'In this section, you can log, manage and access assets, liabilities, transfers, care teams, prescriptions and other important information.',
        'INVENTORY_DESCRIPTION1': "Keeping a current and complete inventory is the foundation for any sound financial decision. Log and maintain real estate, deeds, car titles, bank accounts, investment accounts, personal property and more to ensure you have the full picture of #dependentName#'s financial situation.",
        'INVENTORY_DESCRIPTION2': 'Advocord helps you more easily see the big picture:',
        'INVENTORY_DESCRIPTIONLIST_1': "Digitally record and maintain all of #dependentName#'s assets.",
        'INVENTORY_DESCRIPTIONLIST_2': 'Access a complete inventory anytime, anywhere.',
        'INVENTORY_DESCRIPTIONLIST_3': 'Rest easy knowing everything is in one place.',
        'MEDICAL_DETAILS_AT':'Medical details at your fingertips.',
        'DOCUMENTS_DETAILS_AT':`Access all documents in \n one place.`,
        'ADD_PRESCRIPTIONS_MEDICATIONS':'In an emergency, no one wants to be fumbling around for the right information. Add prescriptions, medications and provider information so that you have all the details where you need them, when you need them.',
        'DOCUMENT_DESCRIPTIONLIST_1':'Upload, store and access all of your dependent’s important legal, financial, medical and training documents in this central hub.',
        'DOCUMENT_DESCRIPTIONLIST_2':'Our convenient all-in-one plateform means you can retrieve files from anywhere, at any time.',
        'ADD_A_MEDICATION':'Add a medication or a provider to begin.',
        'ARE_YOU_SURE_WANT':'Are you sure you want to log out?',
        'ANY_INFORMATION_OR_ACTIVITY':'Any information or activity you have logged will be saved for the next time you sign in.\n',
        'YOUR_DEPENDENT':'Your Dependent',
        'ADD_YOUR_DEPENDENT_INFORMATION':"Add your dependent’s information.",
        'THIS_IS_WHERE_YOU_CAN_LOG':'This is where you can log and track basic information like your dependent\'s address, phone number, etc., so that it\'s readily available when you need it.',
        'YOU_CAN_ALSO_STORE_MY':'You can also store any court documents related to your dependent here.',
        'GUARDIAN' : 1,
        'AGENT' : 2,
        'REPRESENTATIVE' : 3,
        'OTHER' : 4,
        'REFERRAL_CODE':'REFERRAL CODE:',
        'SPECIAL_CODE':'SPECIAL CODE:',
        'OFFER_HAS_BEEN_APPLIED':'Offer has been applied. You save',
        'INVENTORY_OVERVIEW':'Inventory Overview',
        'Activity_OVERVIEW':'Activity Overview',
        'MEDICAL_OVERVIEW':'Medical Overview',
        'EXPENSE_OVERVIEW':'Expense Overview',
        'Income_OVERVIEW':'Income Overview',
        'DOCUMENT_OVERVIEW':'Document Overview',
        'PLEASE_CONTACT':'Plesae Contact',
        'TO_ACTIVE_ACCOUNT':'to active account.',
        'LINK_TO_WEBSITE':'Link to Website',
        'INVOICES':'invoices',
        'ADVOCORD_ADMIN':'Advocord Admin',
        'HOW_DID_YOU_HEAR':'How did you hear about us?',
        'REFERRAL_COURTS':'Referral Courts/Clerks Office',
        'REFERRAL_FROM_PROFESSIONAL':'Referral from Professional/Legal Services',
        'ANOTHER_ADVOCORD':'Another Advocord User',
        'GOOGLE':'Google',
        'FACEBOOK':'Facebook',
        'INSTAGRAM':'Instagram',
        'ORRSTOWN_BANK':'Orrstown Bank',
        'BANK_FINANCIAL_INSTITUTE':'Bank/Financial Institution',
        'TCARE':'TCARE',
        'TWITTER':'Twitter',
        'LINKEDIN':'LinkedIn',
        'OTHER_SURVEY':'Other',
        'SKIP':'SKIP',
        'YOUR_PROMO_CODE_OF':'Your promo code of',
        'OFF_HAS_BEEN_APPLIED':'off has been applied',
        'WANT_MORE_INFORMATION':'Want more information?',
        'TO_VISIT_THE_ADVOCORD':'to visit the Advocord Learning Center.',
        'CLICK_HERE':'Click here',
        'WHICH_OF_THESE_ROLES_ARE':'Which of these roles are you?',
        'GUARDIAN_CONSERVATOR':'Guardian/Conservator',
        'AGENT_UNDER_POWER_OF_ATTORNEY':'Agent under Power of Attorney',
        'REPRESENTATIVE_PAYEE':'Representative Payee',
        'OTHER_LABEL':'Other',
        'FAILED_PAYMENT':'FAILED PAYMENT',
        'SHOW':'Show:',
        'TAB':'Tab',
        'BANK_ACCOUNT':'BankAccount',
        'BURIAL_ACCOUNT':'BurialAccount',
        'BUSINESS_INTEREST':'BusinessInterest',
        'INVENST_ACCOUNT':'InvestmentAccount',
        'LIFE_INSURANCE':'LifeInsurance',
        'PERSONAL_PROPERTY':'PersonalProperty',
        'REAL_ESTATE':'RealEstate',
        'RETIREMENT_ACCOUNT':'RetirementAccount',
        'SAFE_DEPOSIT_BOX':'SafeDepositeBox',
        'SAFETY_DEPOSIT':'SafetyDeposite',
        'ASSET_TRANSFER':'AssetTransfer',
        'LINK_URL':'linkUrl',
        'ADD_CONTACT_ASSET':'AddContactAsset',
        'EDIT_CONTACT_ASSET':'EditContactAsset',
        'DELETE_CONTACT_ASSET':'DeleteContactAsset',
        'ADD_BENEFICIARY_ASSET':'AddBeneficiaryAsset',
        'EDIT_BENEFICIARY_ASSET':'EditBeneficiaryAsset',
        'DELETE_BENEFICIARY_ASSET':'DeleteBeneficiaryAsset',
        'ADD_CO_OWNER_ASSET':'AddCoOwnerAsset',
        'EDIT_CO_OWNER_ASSET':'EditCoOwnerAsset',
        'DELETE_CO_OWNER_ASSET':'DeleteCoOwnerAsset',
        'SHOW_ACCOUNT_NO':'showAccountNO',
        'ADD_BANK_INFO_ASSET':'AddBankInfoAsset',
        'EDIT_BANK_INFO_ASSET':'EditBankInfoAsset',
        'ADD_BURIAL_ACCOUNT_PROVIDER_ASSET':'AddBurialAccountProviderAsset',
        'EDIT_BURIAL_ACCOUNT_PROVIDER_ASSET':'EditBurialAccountProviderAsset',
        'BUSINESS_DETAILS':'BusinessDetails',
        'ADD':'Add',
        'INSURANCE_PROVIDER':'InsuranceProvider',
        'LOAN':'Loan',
        'LEARN_MORE':'LearnMore',
        'VIEW_SECTION':'viewSection',
        'VIEW':'View'
    },
    LABEL: {
        'LOGIN': 'Login',
        'ASSET_TYPE': 'ASSET TYPE',
        'STAR': '*',
        'REGISTRATION': 'Registration',
        'CODE': 'Code',
        'C': 'C',
        'HOW_MUCH_OF_THE_ASSET_DO_THEY_OWN': 'How much of the asset do they own?',
        'REPORT_SALE_OF_PROPERTY': 'Report Sale of Property',
        'WELCOME_TO': 'Welcome to',
        'ACCOUNTABILITY': 'Accountability',
        'FAX': 'FAX',
        'WHERE_IS': 'Where is',
        'TO': 'to',
        'TO_COLON': 'TO:',
        'TOTAL': 'Total',
        'PHOTO_UPLOADED': 'Photo uploaded',
        'OWED_TO': 'OWED TO',
        'REMOVE_PHOTO': 'Remove Photo',
        'PASSWORD': 'PASSWORD',
        'NEW_PASSWORD': 'NEW PASSWORD',
        'CURRENT_PASSWORD': 'CURRENT PASSWORD',
        'STATUS': 'STATUS',
        'TRASNFER': 'TRANSFER',
        'TRASNFER_DATE': 'TRANSFER DATE',
        'ASSET_NAME': 'ASSET NAME',
        'ACQUIRED': 'ACQUIRED',
        'PRINCIPAL': 'Principal',
        'SUCCESSFULLY_PURCHASED': 'Successfully Purchased',
        'WELCOME_TO_ADVOCORD':'Welcome to Advocord',
        'YOUR_30_DAY_TRIAL':'Your 30 day trial has expired.',
        'YOUR_PLAN_EXPIRED':'Your Monthly Plan expired.',
        'YOUR_SUBSCRIPTION_HAS_BEEN':'Your subscription has been activated.',
        'MAKE': 'MAKE',
        'MODEL': 'MODEL',
        'VIN_NUMBER': 'VIN NUMBER',
        'UNTIL': 'UNTIL',
        'INVOICE': 'INVOICE',
        'INTEREST': 'Interest',
        'LOAN_AGAINST_POLICY': 'Loan Against Policy',
        'INSURANCE_TYPE': 'INSURANCE TYPE',
        'NO': 'No',
        'TERM': 'Term',
        'WHOLE': 'Whole',
        'ATTACH': 'Attach',
        'INTEREST_ONLY': 'Interest Only',
        'YES': 'Yes',
        'DEPENDENT_PROFILE': 'Dependent Profile',
        'CONTACT_INFORMATION': ' Contact Information',
        'PLAN_NAME': 'PLAN NAME',
        'PLAN_CHARGES': 'PLAN CHARGES',
        'PLAN_DETAILS': 'PLAN DETAILS',
        'ROUTING': 'ROUTING',
        'SUBSCRIPTION_PLAN': 'Subscription Plan',
        'NO_BENEFICIARY': 'No Beneficiaries',
        'SUBSCRIPTION_STATUS': 'Subscription Status',
        'SUBSCRIPTION_START_DATE': 'Subscription Start Date',
        'SUBSCRIPTION_EXPIRATION_DATE': 'Subscription Expiration Date',
        'SUBSCRIPTION_RENEWAL_DATE': 'Subscription Renewal Date',
        'EXPIRATION_DATE': 'Expiration Date',
        'RENEWAL_DATE': 'Renewal Date',
        'SUBSCRIPTION_CANCEL_MESSAGE': 'You can cancel this subscription by clicking here',
        'ONLINE_BANKING_SETUP_MESSAGE': 'IS IT SET UP FOR ONLINE BANKING?',
        'DATE_OPENED': 'DATE OPENED',
        'ACCOUNT_DETAILS': 'Account Details',
        'BANKS_INFORMATION': 'Bank’s Information',
        'LOADING_BANK_INFORMATION': 'Loading Bank Information...',
        'ADD_BANK_INFORMATION': 'Add Bank Information',
        'NO_BANK_INFORMATION': 'No Bank Information',
        'INSURANCE': 'INSURANCE',
        'COVERAGE_PERIOD': 'COVERAGE PERIOD',
        'PROVIDER': 'PROVIDER',
        'CAR_INSURED': 'CAR INSURED',
        'Attach_Files': 'Attach Files',
        'SIGNUP': 'Sign Up',
        'SIGNIN': 'Sign In',
        'Email': 'Email',
        'INCOME_TYPE': 'INCOME TYPE',
        'PAID_FROM': 'PAID FROM',
        'SOURCE_OF_FUNDS': 'Source of funds',
        'INCOME_AMOUNT': 'INCOME AMOUNT',
        'EXPENSE_TYPE': 'EXPENSE TYPE',
        'DEBT_TYPE': 'DEBT TYPE',
        'DEBTS_LIABILITIES': 'Debts/Liabilities',
        'AMOUNT': 'AMOUNT',
        'PAID_TO': 'PAID TO',
        'DATE_OF_COURT': 'DATE OF COURT',
        'DATE_OF_COURT_ORDER': 'DATE OF COURT ORDER',
        'Income_of': 'Income of',
        'PHONE1':'Phone 1',
        'Income_for': 'Income for',
        'DEPENDENTS_VALUE': 'DEPENDENTS VALUE:',
        'Expense_of': 'Expense of',
        'Expense_for': 'Expense for',
        'DEBT_OF': 'Debt of',
        'AND':'and',
        'CO_OWNER_TYPE': 'CO-OWNER TYPE',
        'Information': 'Information',
        'KEEPMELOGGEDIN': 'Keep me logged in',
        'SIGNIN_FORGOT_PASSWORD': 'Forgot Password?',
        'SINGIN_DONT_HAVE_AN': 'Don’t have an account?',
        'SINGIN_JOIN_NOW': 'Join Now',
        'SINGUP_TERM_OF_USE': 'Terms of Use',
        'TERMS_OF_SERVICE': 'Terms of Service',
        'SINGUP_PRIVACY_POLICY': 'Privacy Policy',
        'SINGUP_ALREADY_HAVE': 'Already have an account?',
        'SINGUP_SINGIN': 'Sign In',
        'INCOME_STREAMS':'Income Streams',
        "EMAIL_VERIFICATION_DONT_RECEIVE": 'Didn\'t receive the code?',
        "EMAIL_VERIFICATION_RESEND_CODE": 'Resend Code',
        "FORGOT_PASSWORD_VERIFICATION_CODE_RESEND_CODE": 'Resend Code',
        'GAURDIAN_PROFILE_PERSONAL_INFO_NAME': 'NAME',
        'GAURDIAN_PROFILE_PERSONAL_INFO_PHONE': 'PHONE',
        'GAURDIAN_PROFILE_PERSONAL_INFO_ADDRESS': 'Address',
        'DEPENDENT_DISPLAY_NAME': 'Display Name',
        'DEPENDENT_GENDER': 'Gender',
        'DEPENDENT_DOB': 'DATE OF BIRTH',
        'DEPENDENT_CASE_FILE_NUMBER': 'CASE FILE NUMBER',
        'DEPENDENT_CASE_DATE_OF_ADVOCATE_APPOINTMENT': 'DATE OF ADVOCATE APPOINTMENT',
        'DATE_OF_APPOINTMENT': 'DATE OF APPOINTMENT',
        'DEPENDENT_EMAIL': 'EMAIL',
        'PRECENT_OWNED': 'PERCENT OWNED',
        'TRASFER_DATE': 'TRANSFER DATE',
        'VALUE_SOLD': 'VALUE SOLD',
        'VALUE_OF_CO_OWNER_INTEREST':'Value of Co-Owner Interest',
        'DEPENDENT_PHONE': 'PHONE',
        'DEPENDENT_STEP_LABEL': 'onboarding: Step',
        'DEPENDENT_LABEL': 'Dependent',
        'DEPENDENTS_LABEL': 'Dependents',
        'DEPENDENT_TOTAL_SCREEN': 'of 4',
        'DEPENDENT_PROFILE_BOTTOM_TEXT_START': 'Finish ',
        'DEPENDENT_PROFILE_BOTTOM_TEXT_END': 'onboarding ',
        'DEPENDENT_PROFILE_EDIT_TITLE': 'Dependent ',
        'DEPENDENT_YOU_WILL_NOTE_ABLE': 'You will not able to recover it',
        'DOT': '.',
        'DEPENDENT_D_O_B': 'D.O.B',
        'DEPENDENT_MOBILE': 'Mobile',
        'MOBILE': 'MOBILE',
        'CAPITAL_OTHER': 'OTHER',
        'OFFICE': 'OFFICE',
        'HOME': 'HOME',
        'OF':'of',
        'DEPENDENT_ALTERNATE': 'Alternate',
        'Dependent_DATE_APPOINTED': 'Date Appointed',
        'DEPENDENT_CASE_NUMBER': 'Case Number',
        'DEPENDENT_DOCUMENT': 'Document',
        'DEPENDENT_PERSONAL_INFO': 'Personal Information',
        'DEPENDENT_CONTACT_INFO': 'Contact Information',
        'DEPENDENT_ADVOCATE_INFO': 'Advocate Information',
        'DEPENDENT': 'Dependent',
        'ADVOCATE': 'Advocate',
        'LEGAL_AUTHORITY': 'Legal Authority',
        'DEPENDENT_ADDRESS': 'Address',
        'DEPENDENT_EDIT': 'Edit',
        'DEPENDENT_ADVOCATE_TEAM': 'Advocate Team',
        'DEPENDENT_INVITE': 'Invite',
        'DEPENDENT_GENERAL_INFO': 'General Information',
        'DEPENDENT_INFORMATION': 'Dependent Information',
        'RESIDENCE': 'RESIDENCE',
        'REASON_FOR_MOVE': 'REASON FOR MOVE',
        'GUAURDIAN_APPOINTMENT_LABEL': 'Guardian Appointment',
        'GUAURDIAN_INFORMATION': 'Guardian Information',
        'DEPENDENT_LIVED_HERE_SINCE': 'DATE THE DEPENDENT MOVED INTO THIS ADDRESS',
        'YOU_ARE_ABOUT_TO': `You are about to leave the Wizard without saving your changes.`,
        'ANY_UNSAVED_INFORMATION': 'Any unsaved information will be forgotten. You can access the profile later to update.',
        'ANY_EDITS_YOU': 'Any edits you have made will not be saved.',
        'DELETE_WILL_PERMANENETLY': 'Deleting will permanently remove this from the system.',
        'EXPENSES_LABEL': 'Expenses',
        'DEBT_LABEL': 'Debt',
        'ADD_ASSOCIATED_EXPENSE': 'Add Associated Expense',
        'EXPENSES': 'EXPENSES',
        'EXPENSE_LABEL': 'Expense',
        'MY_ACCOUNT': 'My Account',
        'ADD_EXPENSE_LABEL': 'Add Expense',
        'INCOMES_LABEL': 'Income',
        'INCOME_LABEL': 'Income',
        'INCOME_SOURCES': 'Income Sources',
        'ADD_INCOME_LABEL': 'Add Income',
        'ACTIVITY_LOG_LABEL': 'Activity Log',
        'ACTIVITY_LABEL': 'Activity',
        'ADD_ACTIVITY_LABEL': 'Add Activity',
        'ADD_AN_ASSET_FOR':'Add an Asset for',
        'RED': 4,
        'YELLOW': 3,
        'GREEN': 2,
        'BLUE': 1,
        'NEW_FOLDER': 'New Folder',
        'BACK_TO_ACTIVITY': 'Back to Activity',
        'INVENTORY': 'Inventory',
        'POLICY_NUMBER': 'POLICY NUMBER',
        'ANNUAL_PREMIUM': 'ANNUAL PREMIUM',
        'TYPE': 'Type',
        'CAPITAL_TYPE': 'TYPE',
        'TERM_DATE': 'TERM DATE',
        'ACCOUNT_TYPE': 'ACCOUNT TYPE',
        'TOTAL_VALUE': 'TOTAL VALUE',
        'DATE_ACQUIRED': 'DATE ACQUIRED',
        'DEATH_BENEFIT': 'DEATH BENEFIT',
        'ROUTING_': 'ROUTING #',
        'ACCOUNT_': 'ACCOUNT #',
        'IS_IT_SET_UP_FOR_ONLINE_BANKING': 'IS IT SET UP FOR  ONLINE BANKING?',
        'PURCHASE_DATE': 'PURCHASE DATE',
        'CASH_VALUE': 'CASH VALUE',
        'AS_OF_DATE': 'AS OF DATE',
        'INSURANCE_PROVIDER': 'Insurance Provider',
        'POLICY': 'Policy',
        'INSTITUTION': 'INSTITUTION',
        'COMPANY_NAME': 'COMPANY NAME',
        'ADDRESS': 'ADDRESS',
        'LOAN_AMOUNT': 'LOAN AMOUNT',
        'DATE_OF_LOAN': 'DATE OF LOAN',
        'CURRENT_BALANCE': 'CURRENT BALANCE',
        'RATE': 'RATE',
        'PAYMENT_TERMS': 'PAYMENT TERMS',
        'FROM': 'From',
        'FROM_COLON': 'FROM:',
        'FREQUENCY': 'FREQUENCY',
        'LOAN_PAYMENT': 'LOAN PAYMENT',
        'CO_OWNER': 'Co-Owner',
        'CO_OWNERS': 'Co-Owners',
        'BENEFICIARY': 'Beneficiary',
        'LIFE_INSURANCE_POLICY': 'Life Insurance Policy',
        'LIFE_INSURANCE': 'Life Insurance',
        'INVENTORY_LABEL': 'Inventory',
        'ADD_ASSET_LABEL': 'Add Asset',
        'ADD_CO_OWNER_LABEL': 'Add Co-owner',
        'NAME': 'NAME',
        'NOTE': 'NOTE',
        'INCOME_ATTACHMENT': 'INCOME ATTACHMENT',
        'EXPENSE_ATTACHMENT': 'EXPENSE ATTACHMENT',
        'DEBT_ATTACHMENT': 'DEBT ATTACHMENT',
        'HAVE_ACCESS_TO_THIS_ASSET': 'Do you currently have access to this asset?',
        'HAVE_LOAN_ACCESS_TO_THIS_ASSET': 'Did the dependent take any loans from this policy?',
        'ATTACHMENT': 'ATTACHMENT',
        'ATTACHMENT_SMALL': 'Attachment',
        'ATTACHMENTS_SMALL': 'Attachments',
        'HIDE_NOTE_FROM_VIEWER': 'HIDE NOTE FROM VIEWER',
        'BUSINESS_INTEREST': 'Business Interest',
        'REAL_ESTATE': 'Real Estate',
        'BANK_ACCOUNT': 'Bank Account',
        'PERSONAL_PROPERTY': 'Personal Property',
        'PROPERTY_LOCATED': 'Property located',
        'SAFE_DEPOSIT_BOX': 'Safe Deposit Box',
        'INVESTMENT_ACCOUNT': 'Investment Account',
        'AUTOMOBILE': 'Automobile',
        'DOES_THE_INCAPACITATED': 'Does the Incapacitated Person have an automobile insurance policy?',
        'DETAILS': 'Details',
        'RETIREMENT_ACCOUNT': 'Retirement Account',
        'BURIAL_ACCOUNT': 'Burial Account',
        'PERCENT_OWNED': 'PERCENT OWNED',
        'ASSET_VALUE': 'ASSET VALUE',
        'ASSET': 'Asset',
        'DEBT': 'Debt',
        'BUSINESS_DETAILS': 'Business Details',
        'BURIAL_PROVIDER': 'Burial Provider',
        'BURIAL_ACCOUNT_PROVIDER': 'Burial Account Provider',
        'ACCOUNT': 'ACCOUNT',
        'LOCATION': 'Location',
        'GIFT': 'Gift',
        'SALE': 'Sale',
        'PARTIAL_SALE': 'Partial Sale',
        'SALE_OF_PROPERTY': 'Sale of Property',
        'PERCENTAGE': 'PERCENTAGE',
        'DATE_OF_SALE': 'DATE OF SALE',
        'NET_PROCEEDS': 'NET PROCEED',
        'COURT_ORDER_USED':'COURT ORDER USED',
        'COURT_ORDER':'COURT ORDER',
        'COURT_ORDERS':'COURT ORDERS',
        'OTHER':'Other',
        'MEDICAL':'Medical',
        'DOCUMENTS':'Documents',
        'ADD_MEDICAL_INFORMATION':'Add Medical Information',
        'ADD_DOCTOR':'Add Provider',
        'UPDATE_DOCTOR':'Update Provider',
        'DOCTOR_OF':'Provider of',
        'TAG':'TAG',
        'DATE':'DATE',
        'YEAR':'YEAR',
        'HOURS':'Hours',
        'EXPENSE':'EXPENSE',
        'PERCENT_SOLD':'PERCENT SOLD',
        'DURATION':'DURATION',
        'CAPITAL_LOCATION':'LOCATION',
        'APOSTROPHE_S':"'s",
        'ACTIVITY_TYPE':"ACTIVITY TYPE",
        'LINK_TO_WEBSITE':'LINK TO WEBSITE',
        'LINK_URL':'Link to website',
        'DESCRIPTION':'Description',
        'ACTIVITY_ON_BEHALF_OF_DEPENDENT':'Activity on behalf of Dependent',
        'ACTIVITY_ON_BEHALF':'Activity on behalf of',
        'ON_BEHALF_OF':'On behalf of',
        'WITH_DEPENDENT':'With dependent',
        'DOCTOR':'Provider',
        'SPECIALTY':'Specialty',
        'PRACTICE_OR_HOSPITAL_GROUP':'Practice or hospital group',
        'AT':'at',
        'HOSPITAL_AFFILIATION':'HOSPITAL AFFILIATION',
        'PHONE':'PHONE',
        'EMAIL':'EMAIL',
        'WEBSITE':'WEBSITE',
        'MEDICATION':'Medication',
        'ADD_MEDICATION':'Add Medication',
        'DATE_STARTED':'Date Started',
        'PROMO_CODE_LABEL':'Special Code (optional)',
        'END_DATE':'End Date',
        'PRESCRIBING_DOCTOR' :'Prescribing Provider',
        'INACTIVE':'Inactive',
        'ACTIVE':'Active',
        'FILES':'files',
        'COPY_OF_PRESCRIPTION':'Copy of Prescription',
        'DRAG_YOUR_FILE_OR_CLICK_HERE':'Drop your file or click here to  upload',
        'UPLOAD':'Upload',
        'MEDICATIONS':'Medications',
        'MEDICATIONS_OF':'Medications of',
        'INSTRUCTIONS':'INSTRUCTIONS',
        'ONCE_SET_INACTIVE':'Once set inactive, you can’t make it active again.',
        'START_DATE':'START DATE',
        'HOME_SMALL':'Home',
        'OTHER_SMALL':'Other',
        'OFFICE_SMALL':'Office',
        'MOBILE_SMALL':'Mobile',
        'UNIT':'UNIT',
        'STATE':'STATE',
        'CITY':'CITY',
        'COUNTY':'COUNTY',
        'ZIP':'ZIP',
        'MEDICATIONS_DOCTOR_INFORMATION':'Medications & Doctor Information',
        'MANAGE_AND_TRACK_MEDICAL_INFORMATION':'Manage and track medical information.',
        'TRACK_WHEN_TO_TAKE_MEDICATIONS':'Track when to tke medications.',
        'INACTIVATE_AND_TAKE_NOTES_ON_PAST_MEDICATIONS':'Inactivate and take notes on past medications.',
        'EASILY_CONTACT_MEDICAL_PROVIDERS':'Easily contact medical providers.',
        'VIEW':'View',
        'LEGAL_DOCUMENTS':'legal documents',
        'RENAME':'Rename',
        'MOVE_TO':'Move to',
        'FIRST_NAME':'First Name',
        'YOUR_FIRST_NAME':'Your First Name',
        'LAST_NAME':'Last Name',
        'YOUR_LAST_NAME':'Your Last Name',
        'TIILE_RELATING_TO_WHY_THIS':'Title relating to why this step is important',
        'LOGOUT':'Log Out',
        'LOG_OUT':'Log out',
        'EXPENSES_SMALL':'Expenses',
        'RECORD_MANAGE':'Record. Manage. Share. All in one place.',
        'ADVOCORD_IS_AS_LIFE_MANAGEMENT':'Advocord is a life-management platform for guardians and other advocates to log and track their dependents’ legal, financial and medical details and compile year-end compliance reports.',
        'HELP_US_VERIFY':'Help us verify your account.',
        'SECURITY_IS_A_CORNERSTONE':'Security is a cornerstone of our platform, and it starts with protecting your login.',
        'LET_GET_SOME':'Let’s get some information to start.',
        'WE_NEED_YOUR_FULL':'We need your full name and address to prepopulate forms and reports, and to save you time later.',
        'YOU_HAVE_BEEN_INVITED':'You’ve been invited as a Viewer, which means you’ll be able to view all of the details on this account at any time.',
        'TO_GET_STARTED':'To get started, we\'ll need some basic contact information so other members of the dependent\'s care team can reach you.',
        'ADDRESS_1':'Address 1',
        'ADDRESS_2':'Address 2',
        'TELL_US_ABOUT_YOUR':'Tell us about your dependent.',
        'DROP_A_PHOTO_HERE_OR':'Drop a photo here or tap to upload',
        'REMOVE':'remove',
        'WHICH_OF_THESE_ROLES_ARE_YOU':'Which of these roles are you?',
        'WHICH_ROLE_BEST':'Which role best describes your legal obligation to the dependent?',
        'GUARDIAN_CONSERVATOR':'Court-appointed guardian or conservator',
        'AGENT_UNDER_POWER_OF_ATTORNEY':'Acting power of attorney',
        'REPRESENTATIVE_PAYEE':'Representative payee',
        'NO_LEGAL_OBLIGATION':'No legal obligation',
        'NONE_OF_THE_ABOVE':'None of the above',
        'ADD_YOUR_GUARDIAN_CONSERVATOR_INFORMATION':"Add your Guardian/Conservator Information",
        'ADD_THE_LEGAL_DETAILS':'Add the legal details of your relationship.',
        'ADD_YOUR_POWER_OF_ATTORNEY_INFORMATION':'Add your Power of Attorney Information',
        'ADD_YOUR_REPRESENTATIVE_PAYEE_INFORMATION':'Add your Representative Payee Information',
        'POWER_OF_ATTORNEY_DOCUMENTS':'Power of Attorney Documents',
        'FILE_NUMBER':'FILE NUMBER',
        'RELATED_DOCUMENTS':'Related Documents',
        'VIVAMUS_SAGITTIS_LACUS':'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec sed odio dui. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor.',
        'WE_JUST_NEED_A_LITTLE_MORE_INFORMATION':'We just need a little more information.',
        'PROVIDING_SOME_OF_YOUR':'Providing some of your dependent’s details upfront helps us to pre-populate your account and ensures we have all the basic information we need to deliver a seamless experience.',
        'YOU_CAN_ALWAYS_CHANGE_OR_EDIT':'You can always change or edit this later.',
        'TELL_US_ABOUT_YOUR_DEPENDENT':'Tell us about your dependent. ',
        'SUBSCRIBE_FOR_LESS_THAN_A':'Subscribe for less than a dollar a day.',
        'TRY_US_FOR_30_DAYS':'Try us for 30 days.',
        'ONE_FREE_BILLED_ANNUALLY':'One fee, billed annually, gives you access to all of Advocord’s seamless and user-friendly features. The fee also covers view-only access for two additional users.',
        'LETS_MAKE_IT_OFFOCIAL':'Let’s make it official.',
        'MEMBERSHIP':'Membership',
        'CVC':'CVC',
        'ADD_DEPENDENT':'Add Dependent',
        'FINISH_YOUR_PROFILE':'Finish Your Profile',
        'ACTIVATE_ACCOUNT':'Activate Account',
        'CLLICK_ON_AVATOR_TO_ADD':'Click on avatar to add a photo of Dependent.',
        'PHOTO_HAS_UPDATED':'Photo has Updated!',
        'UPLOAD_A_PHOTO_OF':'Upload a photo of your dependent',
        'DEPENDENT_PROFILE_PHOTO':'Dependent Profile Photo',
        'CAPTURE_THE_DETAILS_OF':'Capture the details of this income source.',
        'IS_THE_WARD_MOVING':'IS THE DEPENDENT MOVING FROM THIS LOCATION?',
        'EXTRA_CARE_NEEDED':'Extra care needed, moving in with relative, etc.',
        'REASON_FOR_MOVING':'Reason for Moving',
        'Banks_INFORMSATION':"Bank's Information",
        'ADD':'Add',
        'FILTER_BY_DATE':'Filter by Date',
        'FILTER':'Filter',
        'FILTERS':'Filters',
        'CLEAR':'Clear',
        'APPLY':'Apply',
        'VALUE':'VALUE',
        'SORT_BY':'Sort by:',
        'DELETE_EXPENSE':'Delete Expense',
        'DELETE_INCOME':'Delete Income',
        'DELETE_DOCTOR':'Delete Doctor',
        'DELETE_PROVIDER':'Delete Provider',
        'LOG_IN': 'Log in',
        'QUICK_LINKS':'Quick Links',
        'Dashboard':'Dashboard',
        'DASHBOARD_UPPERCASE':'Dashboard',
        'ACTIVITY_LOG':"ACTIVITY LOG",
        'ACTIVITY_DES_DASHBOARD':'Track time spent with and on behalf of your dependent.',
        'EXPENSES_DES_DASHBOARD':'Keep a log of receipts and expenses.',
        'INCOME_DES_DASHBOARD':'Keep track of your dependent\'s income sources.',
        'DOCUMENTS_DES_DASHBOARD':'Upload, manage and track all important documents.',
        'MEDICAL_DES_DASHBOARD':'Manage medical providers and prescription information.',
        'INVENTORY_DES_DASHBOARD':'Build your dependent’s statement of assets and liabilities.',
        'EXPENSE_small':'Expense',
        'DOCUMENT':'Document',
        'DOES_THIS_REPEAT_MONTHLY':'DOES THIS REPEAT MONTHLY',
        'END_REPEAT_BY':'END REPEAT BY',
        'REPEAT':'REPEAT',
        'ATTACH_TO_ALL_FUTURE_EXPENSES':'ATTACH TO ALL FUTURE EXPENSES',
        'ATTACH_TO_ALL_FUTURE_INCOME':'ATTACH TO ALL FUTURE INCOME',
        'HELLO':'Hello',
        'YOU_HAVE_30_DAYS':'You can use your free account for 30 days and take advantage of all Advocord has to offer.  After the 30 days, you can keep all of the information you entered. We will provide a reminder of when you will need to enter your payment details to continue your subscription.',
        'ALL_YOUR_ACCOUNTS_DETAILS':'All the information you entered has been saved.  In order to continue using Advocord, please enter your billing information and start your subscription.',
        'REMINDER':'Reminder!',
        'DAYS_LEFT':'days left in free account.',
        'SUBSCRIBE':'Subscribe',
        'FREE_ACCOUNT_CREATION':'Free Account Creation',
        'ACTICE':'Active',
        'PROMO_CODE':'PROMO CODE',
        'LEARN_MORE':'Learn More...',
        'TRIAL_HAS_EXPIRED':'trial has expired',
        'PURCHASED_PLAN_EXPIRED':'purchased plan expired',
        'TRIALING':'trialing',
        'YOU_HAVE_BEEN_GIVEN_VIEW':'You have been given view only access to this dependent.  As a view only account you can view but not add information to this dependents account.  The Advocate pays for the account. ',
        'YOU_HAVE_BEEN_LOGGED_OUT':'You have been logged out.',
        'TO_KEEP_YOUR_SENSATIVE':'To keep your sensative information safe, we automatically sign you out after 3 hours.',
        'GOOGLE':'google',
        'ITS_FREE_ACCOUNT':'It’s Free Account Time',
        'WE_BUILT_ADVOCORD_FOR_ADVOCATES':'We built Advocord for Advocates — guardians, conservators, POAs and others — who have responsibility for the wellbeing and finances of an adult dependent. Our platform helps you manage:',
        'GET_STARTED_WITH_YOUR':'Get started with your free account. Purchase a monthly plan at any time.',
        'LEGAL_COMPLIANCE':'Legal compliance',
        'FINANCIAL_AFFAIRS':'Financial affairs',
        'MEDICAL_INFORMATION':'Medical information',
        'TELL_US_LITTLE_ABOUT_YOU':'Tell us a little about you.',
        'MY':'My',
        'LET_US_CREATE_A':'Let us create a',
        'FREE':'FREE',
        'ACCOUNT_FOR_YOU_TO_EXPLORE':'Account for you to explore Advocord. No Credit Card required.',
        'TO_KEEP_YOUR_SENSITIVE':'To keep your sensitive information safe, we automatically sign you out after 30 minutes of being idle.',
        'HASH_BILLING':'#billing',
        'YOUR_PLAN_IS':'Your plan is',
        'PAID_MONTHLY':'paid monthly',
        'PRESCRIVING_DOCTOR':'Prescribing Doctor',
        'NO_TAG_WAS_ADDED':'No tag was added to the Activity.',
        'ALL':'All',
        'RANGE':'Range:',
        'view_invoice_receipts':'VIEW INVOICE & RECEIPTS',
        'PAGE':'Page',
        'RENEWAL_PRICE':'RENEWAL PRICE',
        'CODE_APPLIED':'Code Applied:',
        'HAVE_A_PROMO_CODE':'Have a Promo Code?',
        'PLEASE_NOTE_THIS_PROMO':'Please note, this promo code will be added to next months payment.',
        'A,_MONTH':'a month.',
        'YOU_CAN_ONLY_HAVE_ONE_PROMO':'You can only have one promo code applied at a time.',
        'SUCCESSFULLY_UPDATE': 'Successfully Updated',
        'YOUR_CREDIT_CARD_INFORMATION':'Your Credit Card information has sucessfully been updated.  You won’t be charged until your next renewal payment is due.',
        'CANCELLED':'CANCELLED',
        'EDIT_YOUR_CREDIT_CARD':'Edit your Credit Card information.',
        'ADD_NOTE':'Add Note',
        'NOTES':'Notes',
        'WRITE_YOUR_NOTE':'Write your note here...',
        'TYPE_SOMETHING_NOTE':'Type Something here...',
        'NOTE_INCOME_TYPE' : 1,
        'NOTE_EXPENSE_TYPE' : 2,
        'NOTE_ACTIVITY_TYPE' : 3,
        'NOTE_AUTOMOBILE_TYPE' : 4,
        'NOTE_BANK_ACCOUNT_TYPE' : 5,
        'NOTE_BURIAL_TYPE' : 6,
        'NOTE_BUSINESS_INTEREST_TYPE' : 7,
        'NOTE_INVESTMENT_ACCOUNT_TYPE' : 8,
        'NOTE_LIFE_INSURANCE_TYPE' : 9,
        'NOTE_PERSONAL_PROPERTY_TYPE' : 10,
        'NOTE_REAL_ESTATE_TYPE' : 11,
        'NOTE_RETIREMENT_ACCOUNT_TYPE' : 12,
        'NOTE_SAFE_DEPOSIT_BOX_TYPE' : 13,
        'NOTE_OTHER_TYPE' : 14,
        'NOTE_ASSET_TRANSFER_TYPE' : 15,
        'NOTE_DEBTS_TYPE' : 16,
        'NOTE_DOCTOR_TYPE' : 17,
        'NOTE_MEDICINE_TYPE' : 18,
        'ACTIVITY_NOTES_API':'/notes?note_type=3&ref_id=',
        'SEE_OLDER_NOTES':'See older notes...',
        'COMMENTS':'Comments',
        'TYPE_DESCRIPTION':'Type description...',
        'NO_EXPENSE_ATTACHED':'No Expense Attached',
        'PRICE_LISTED_IS_FOR':'Limited Time Introductory Price',
        'ESSENTIALS_PLAN':'Essentials Plan',
        'EDIT':'Edit',
        'SPONSORED_BY':'Sponsored by',
        'ONCE_YOU_CREATE_YOUR_FREE':'Once you create your',
        'FREE':'Free',
        'ACCOUNT_ENTER_THE':'account, enter the promo code provided by the sponsor.'
    },
    ERROR_MESSAGE: {
        'SIGNIN_USERNAME_REQUIRED': 'Your username is required.',
        'SIGNIN_USERNAME_INVALID': 'Please enter a valid email address.',
        'SIGNIN_PASSWORD_REQUIRED': 'Your password is required.',
        'SIGNIN_PASSWORD_INVALID': 'Your password is invalid.',
        'SIGNUP_FIRST_NAME_REQUIRED': 'First name is required.',
        'FIRST_NAME_REQUIRED': 'First name is required.',
        'LAST_NAME_REQUIRED': 'Last name is required.',
        'SIGNUP_FIRST_NAME_INVALID': 'First name must be 1 or more characters.',
        'SIGNUP_LAST_NAME_REQUIRED': 'Last name is required.',
        'SIGNUP_LAST_NAME_INVALID': 'Last name must be 1 or more characters.',
        'SIGNUP_USERNAME_REQUIRED': 'Your username is required.',
        'SIGNUP_USERNAME_INVALID': 'Please enter a valid email address.',
        'SIGNUP_PASSWORD_REQUIRED': 'Your password is required.',
        'OLD_PASSWORD_REQUIRED': 'Your current password is required.',
        'SIGNUP_PASSWORD_INVALID': 'Your password is invalid.',
        'SIGNUP_TERM_CONDITION_UNCHECK': 'Please read and accept the Terms of Use and Privacy Policy.',
        'UNEXPECTED_ERROR_MSG': 'Something went wrong.',
        'EMAIL_VERIFICATION_REQUIRED': 'Your verification code is required.',
        'EMAIL_VERIFICATION_INVALID': 'Your code should include 6 characters.',
        'EMAIL_REQUIRED': 'Your email address is required.',
        'REASON_FOR_MOVE': 'Your reason for moving is required.',
        'ADDRESS_REQUIRED': 'Your address is required.',
        'DEPENDENT_LIVED_HERE_SINCE_IS_REQUIRED': 'Please fill out the "Dependent Lived Here Since" field.',
        'RESIDENCE_IS_REQUIRED': 'Residence is required.',
        'COUNTY_IS_REQUIRED': 'County is required.',
        'ASSET_TYPE_IS_REQUIRED': 'Asset type is required.',
        'FOLDERNAME': 'The folder name is required.',
        'NAME_REQUIRED': 'Name is required.',
        'VIN_REQUIRED': 'Vin number is required.',
        'VIN_MIN_MAX_ERROR': 'Min 10 char and max 17 char long.',
        'PERCENT_OWNED': "Please fill out the \"Percent Owned\" field.",
        'LOAN_PAYMENT_REQUIRED': 'The loan payment is required.',
        // 'DATE_OUT_RANGE': 'That date is outside the range you\'ve selected.',
        'DATE_OUT_RANGE': 'That date is outside the range you’ve selected.',
        'INSTRUCTIONS_REQUIRED': 'Instruction is required.',
        'DESCRIPTION_REQUIRED': 'Description is required.',
        'END_DATE_ERROR_MEDICAL': 'End date must be greater than start date.',
        'END_REPEAT_DATE_ERROR_MEDICAL': 'End repeat date must be greater than date.',
        'FUTURE_DATE_ERROR': 'The date can’t be in the future.',
        'DATE_OUT_RANGE_OPEN': 'Date opened must be today or prior date.',
        'DATE_OUT_RANGE_ACQUIRE': 'Date acquired must be today or prior date.',
        'UNTIL_DATE_ERROR': 'Until date must be greater than Coverage Period date.',
        'DATE_IS_REQUIRED': 'The date is required.',
        'SELECT_ACTIVITY_TYPE': 'Please select an activity type.',
        'ENTER_VALID_DURATION': 'Please enter a valid duration for this activity.',
        'ENTER_DURATION': 'Enter duration.',
        'PLEASE_SELECT_TYPE': 'Please select type.',
        'ENTER_NET_PROCEEDS': 'Please enter the net proceeds.',
        'PHONE_NUMBER_IS_INVALID': 'This phone number is invalid.',
        'PHONE_NUMBER_IS_REQUIRED': 'Phone number is Required',
        'ENTER_PERCENT_OWNED': 'Please enter the percent owned.',
        'ENTER_VALID_FAX': 'Please enter a valid fax number.',
        'ENTER_VALID_EMAIL': 'Please enter a valid email address.',
        'LOAN_AMOUNT_IS_REQUIRED': 'The loan amount is required.',
        'CURRENT_BALANCE_IS_REQUIRED': 'Current balance is required.',
        'CURRENT_BALANCE_IS_GREATER_THAN_LOAN_AMOUNT': 'Current Balance is greater than Loan Amount.',
        'ENTER_EMAIL': 'Please enter your email.',
        'ENTER_VALID_AMOUNT': 'Please enter a valid amount.',
        'ENTER_VALUE_SOLD': 'Please enter the value sold.',
        'SELECT_INCOME_TYPE': 'Please select an income type.',
        'SELECT_COOWNER_TYPE': 'Select co-owner type.',
        'SELECT_PRESCRIBING_DOCTOR_TYPE': 'Select prescribing doctor.',
        'CO_OWNER_IS_REQUIRED': 'A co-owner is required.',
        'ENTER_PERCENTAGE_SOLD': 'Please enter the percentage sold.',
        'SELECT_EXPENSE_TYPE': 'Please select an expense type.',
        'SELECT_ASSET_TYPE': 'Please select an asset type.',
        'SELECT_TRANSFER_TYPE': 'Please select a transfer type.',
        'YEAR_IS_REQUIRED': 'The year is required.',
        'YEAR_GREATER': 'The year should not be greater then current year.',
        'POLICY_IS_REQUIRED': 'Policy is required.',
        'LOAN_PAYMENT_IS_REQUIRED': 'The loan payment is required.',
        'MAKE_IS_REQUIRED': 'Make is required.',
        'MODEL_IS_REQUIRED': 'Model is required.',
        'RATE_IS_REQUIRED': 'Rate is required.',
        'RATE_MUST_BE_GREATER_THAN_ZERO': 'Please enter a rate greater than 0.',
        'ENTER_ACCOUNT_TYPE': 'Please enter the account type.',
        'PLEASE_SELECT_PHONE_TYPE': 'Please select the type of phone.',
        'POLICY_NAME_IS_REQUIRED': 'A policy name is required.',
        'POLICY_NUMBER_IS_REQUIRED': 'A policy number is required.',
        'ENTER_AS_OF_DATE': 'Please enter the as-of date.',
        'ENTER_VALID_CASH_VALUE': 'Please enter a valid cash value.',
        'ENTER_VALID_DEATH_BENEFIT': 'Please enter a valid death benefit.',
        'ENTER_INSTITUTION_NAME': 'Please enter the institution name.',
        'ENTER_VALID_ANNUAL_PREMIUM': 'Please enter a valid annual premium.',
        'INVALID_ACCOUNT_NUMBER': 'Please enter a valid account number.',
        'INSURANCE_PROVIDER_REQUIRED': 'The insurance provider is required.',
        'ENTER_VALID_ASSET_VALUE': 'Please enter a valid asset value.',
        'SELECT_RETIREMENT_ACCOUNT_TYPE': 'Please select the retirement account type.',
        'ENTER_REASON_FOR_NOT_OBTAINING_ORDER': 'Please provide a reason for not obtaining a court order.',
        'PERCENT_OWNED_MUST_BE_GREATER_THAN_0': 'The "Percent Owned" field must be greater than 0.',
        'PERCENT_OWNED_MUST_BE_LESS_THAN_0': 'The "Percent Owned" field must be less than 100.',
        'PERCENT_SOLD_MUST_BE_GREATER_THAN_0': 'The "Percent Sold" field must be greater than 0.',
        'ENTER_SPECIALTY_TYPE': 'Enter Specialty type.',
        'WEBSITE_ERROR_MESSAGE': 'Please enter valid Website address.',
        'NETWORK_ERROR_TRY_AGAIN':'System error. Please try again',
        'ZIP_IS_REQUIRED': 'Zip is required.',
    },
    ERROR_API_CODE: {
        'REMOVED_ACCESS': 'E0255',
        'TRY_AGAIN': 'E0250',
        'ALREADYSIGNEDUP': 'E0260',
        'ALREADY_USER_VERIFIED': 'E0080'
    },
    GOOGLE_ANALYTIC_EVENT: {
        SIGNUP_SUCCESS: 'signup_success',
        SIGNUP_FAILED: 'signup_failed',
        SIGNUP_SS_ERROR: 'signup_ss_error',
        SIGNUP_CODE_VERIFIED: 'signup_code_verified',
        SIGNUP_CODE_VERIFICATION_FAILED: 'signup_code_verification_failed',
        SIGNUP_CODE_ALREADY_VERIFIED: 'signup_code_already_verified'
    },
    SUCCESS_MESSAGE: {
        'SIGNUP_YOUR_PASSWORD': 'Your password is secure and you\'re all set!'
    },
    MESSAGE: {
        'ALL_RIGHT_RESERVED': '©2021 All Rights Reserved. AccountabilityTech Inc.',
        'Advocord': '©Advocord.',
        'EMAIL_VERIFICATION_TITLE': 'Verification Code',
        'EMAIL_VERIFICATION_MESSAGE': 'To confirm your account, we have sent a verification code to the email provided. Please enter the code below:',
        'SINGUP_BY_CLICING': 'By clicking Sign Up, you agree to ',
        'FORGOT_PASSWORD_VERIFICATION_CODE_TITLE': 'Check your mail',
        'FORGOT_PASSWORD_VERIFICATION_CODE_MESSAGE': 'We have sent a verification code to the email provided. Please enter the code below:',
        'FORGOT_PASSWORD_EMAIL_TITLE': 'Forgot Password?',
        'EMAIL_LOGIN_TITLE': 'Account Verified',
        'PASSWORD_UPDATED_LOGIN_TITLE': 'Password Updated',
        'PASSWORD_UPDATED_LOGIN_MESSAGE': 'Please Sign In using your new credentials.',
        'EMAIL_LOGIN_MESSAGE': 'You may now Sign In.',
        'FORGOT_PASSWORD_EMAIL_MESSAGE': 'Enter your email address.',
        'FORGOT_CREATE_PASSWORD_TITLE': 'Code Verified',
        'FORGOT_CREATE_PASSWORD_MESSAGE': 'Please enter a new password',
        'FORGOT_PASSWORD_UPDATE_TITLE': 'Password Updated',
        'FORGOT_PASSWORD_UPDATE_MESSAGE': 'Your password has been updated!',
        'FORGOT_PASSWORD_POPUP_TITLE': `Are you sure you want \n to cancel?`,
        'FORGOT_PASSWORD_POPUP_MESSAGE': 'Any update you have made will not be saved.',
        'PROFILE_PIC_TEXT': 'Click on avatar to add a photo of ',
        'MESSAGE_HAS_BEEN_SEND': 'Message has been sent.',
        'MESSAGE_HAS_BEEN_SEND_TO': 'Message has been sent to',
        'YOU_WILL_NOT_ABLE': 'You will not able to recover it',
        'ARE_YOU_SURE_YOU_WANT_TO_DELETE': 'Are you sure you want to delete the access to',
        'ARE_YOU_SURE_YOU_WANT_TO_RESEND': 'Are you sure you want to Resend the Invite to',
        'YOU_NO_LONGER_HAVE': 'You no longer have view access to',
        'ACCOUNT_IN_ACCOUNTABILITY': 'account in Accountability',
        'PLEASE_CONTACT_THE_ADVOCATE': 'Please contact the advocate that invited you. This invite is now invalid.',
        'YOU_HAVE_ALREADY_SIGNEDUP': 'You have already signed up using this link. Please sign in.',
        'YOUR_ACCOUNT_IS_PAID':'Your account is paid for by the person who invited you. Please contact them to find out more.',
        'VIEW':'View',
    },
    BUTTON_TEXT: {
        'SIGNIN_SINGIN': 'Log In',
        'SIGNIN_SIGNING': 'Logging In...',
        'SIGNUP_SIGNUP': 'Sign Up',
        'SIGNUP_SIGNINGUP': 'Signing Up...',
        'EMAIL_VERIFICATION_VERIFY_PROCEED': 'Verify & Proceed',
        'FORGOT_PASSWORD_EMAIL_SEND': 'Send',
        'FORGOT_PASSWORD_EMAIL_SENDING': 'Sending...',
        'FORGOT_PASSWORD_VERIFICATION_CODE_DONE': 'Update Password',
        'FORGOT_CREATE_PASSWORD_UPDATE': 'Update Password',
        'FORGOT_CREATE_PASSWORD_UPDATING_PASSWORD': 'Updating Password...',
        'FORGOT_PASSWORD_UPDATE_LOGIN': 'Log In',
        'GAURDIAN_PROFILE_PERSONAL_INFO_SAVE': 'Save',
        'APPLY': 'APPLY',
        'GAURDIAN_PROFILE_PERSONAL_INFO_SAVEING': 'Saving',
        'GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL': 'Cancel',
        'DEPENDENT_COUNTINUE': 'Continue',
        'DEPENDENT_COUNTINUING': 'Continuing...',
        'DEPENDENT_BACK': 'Back',
        'DEPENDENT_FINISH': 'Finish',
        'SAVE': 'Save',
        'ADD':'Add',
        'CANCEL': 'Cancel',
        'APPLY_SMALL':'Apply',
        'CLOSE': 'Close',
        'CANCELLED': 'CANCELLED',
        'Ok': 'OK',
        'DELETE': 'Delete',
        'NO': "No",
        'OK': "OK",
        'YES': 'Yes',
        'KEEP_PLAN': 'Keep Plan',
        'CANCEL_PLAN': 'Cancel Plan',
        'SEND_INVITE': 'Send Invite',
        'DONE': 'Done',
        'RESEND_INVITE': 'Resend Invite',
        'UPDATE': 'Update',
        'CREATE': 'Create',
        'Next': 'Next',
        'CONTINUE': 'Continue',
        'CONTINUE_CAPS': 'CONTINUE',
        'TRASNFER': 'Transfer',
        'UNSUBSCRIBE': 'Unsubscribe',
        'PURCHASE': 'Purchase',
        'SUBMIT': 'Submit',
        'ADD_FIIRST_MEDICAL_LOG': 'Add First Medical Log',
        'LOGOUT': 'Logout',
        'TRANSFER': 'Transfer',
        'BACK':'Back',
        'FINISH':'Finish',
        'SIGN_IN_SECURITY':'Sign In & Security',
        'SEND':'Send',
        'ADD_AN_ACTIVITY':'ADD AN ACTIVITY',
        'ADD_AN_EXPENSE':'ADD AN Expense',
        'ADD_AN_INCOME':'ADD AN INCOME',
        'MANAGE_INVENTORIES':'Build Inventory',
        'ADD_MEDOCAL_INFORMATION':'Add Medical Information',
        'ADD_FIRST_MEDICATION':'Add Medical Information',
        'ADD_FIRST_DOCTOR':'Add Provider Information',
        'LOG_OUT':'LOG OUT',
        'CREATE_DEPENDENT_PROFILE':'Create Dependent Profile',
        'ACTIVATE':'Activate',
        'GET_STARTED':'GET STARTED',
        'BACK_TO_INVENTORY':'Back to Inventory',
        'BACK_TO_ACTIVITY':'Back to Activity',
        'BACK_TO_DOCUMENTS':'Back to Documents',
        'BACK_TO_EXPENSE':'Back to Expense',
        'BACK_TO_INCOME':'Back to Income',
        'BACK_TO_MEDICAL':'Back to Medical',
        'JOIN_ADVOCORD':'Join Advocord',
        'SUBSCRIBE':'Subscribe',
        'LOGIN_WITH_GOOGLE':'login with google',
        'JOIN_VIA_GOOGLE':'join Via Google',
        'CREATE_FREE_ACCOUNT_VIA_GOOGLE':'Create FREE ACCOUNT via google',
        'CREATE_FREE_ACCOUNT':'Create FREE ACCOUNT',
        'CREATING_FREE_ACCOUNT':'Creating FREE ACCOUNT',
        'OKAY':'Okay',


    },
    TAB_TEXT: {
        'GAURDIAN_PROFILE_PERSONAL_INFO': 'Personal Information',
        'GAURDIAN_PROFILE_PASSWORD': 'Password',
        'GAURDIAN_PROFILE_BILLING': 'Billing',
        'DEPENDENT_GENEERAL_INFO': 'General Information',
        'DEPENDENT_ADDRESS': 'Address',
        'DEPENDENT_ADVOCATE_TEAM': 'Advocate Team',
        'MEDICATIONS': 'Medications',
        'DOCTOR': 'Provider',
        'BILLING': 'Billing',


    },
    ERROR_CODE : {
        'SIGNUP' : 'web-signup',
        'LOGIN' : 'web-login',
        'UPDATE_PROFILE' : 'update-profile',
        'UPDATE_PASSWORD' : 'update-password',
        'GET_ALL_DEPENDENT' : 'get-all-dependent',
        'CREATE_DEPENDENT' : 'create_dependent',
        'UPDATE_DEPENDENT' : 'update_dependent',
        'CREATE_ADDRESS' : 'create-address',
        'UPDATE_ADDRESS' : 'update-address',
        'GET_ADDRESS' : 'get-address',
        'GET_ACTIVITY_TYPE' : 'get-activity-type',
        'GET_EXPENSE_TYPE' : 'get-expense-type',
        'GET_INCOME_TYPE' : 'get-income-type',
        'GET_DEBT_TYPE' : 'get-debt-type',
        'SET_USER_PREFRENCE' : 'set-user-prefrence',
        'GET_ASSET_TYPE' : 'get-asset-type',
        'GET_ASSET_LIST' : 'get-asset-list',
        'CREATE_ASSET' : 'create-asset',
        'ADD_CO_OWNER' : 'add_co_owner',
        'ADD_ADDRESS' : 'add-address',
        'ADD_CONTACT' : 'add-contact',
        'ADD_INSTITUTION' : 'add-institution',
        'UPDATE_CONTACT' : 'update-contact',
        'UPDATE_DEBT' : 'update-debt',
        'UPDATE_EXPENSE' : 'update-expense',
        'CREATE_EXPENSE' : 'create-expense',
        'CREATE_DEBT' : 'create-debt',
        'CREATE_DOCTOR' : 'create-doctor',
        'CREATE_INCOME' : 'create-income',
        'UPDATE_DOCTOR' : 'update-doctor',
        'UPDATE_INCOME' : 'update-income',
        'SAVING_DEBT' : 'saving-debt-file',
        'ADD_BENEFICIARY' : 'add-beneficiary',
        'UPDATE_BENEFICIARY' : 'update-beneficiary',
        'INVITE_USER' : 'invite-user',
        'DELETE_PROFILE_PIC' : 'delete-profile-pic',
        'DELETE_ADDRESS' : 'delete-address',
        'GET_ADVOCATE_TEAM_LIST' : 'get-advocate-team-list',
        'DELETE_INVITE_USER' : 'delete-invite-user',
        'DELETE_ACTIVITY' : 'delete-activity',
        'SEARCH_ACTIVITY_LIST' : 'search-activity-list',
        'ALL_MEDICAL_LIST' : 'all-medical-list',
        'UPDATE_ACTIVITY' : 'update-activity',
        'CREATE_ACTIVITY' : 'create-activity',
        'DELETE_DOCTOR' : 'delete-doctor',
        'DELETE_MEDICATION' : 'delete-medication',
        'DEPENDENT_UPLOAD_DOCS' : 'dependent-upload-docs',
        'DEPENDENT_DELETE_DOCS' : 'dependent-delete-docs',
        'GET_DOCOTRS_SPECIALIST_TYPE' : 'get-doctor-specialist-type',
        'TIILE_RELATING_TO_WHY_THIS':'Title relating to why this step is important'
    },
    MIMES_TYPES : {
        'JPEG' : 'jpeg',
        'JPG' : 'jpg',
        'PNG' : 'png',
        'PDF' : 'pdf',
        'SVG' : 'svg',
    },

    BUTTON_ID: {
        'FREE_TRIAL': 'Free_Trial',
        'SUbSCRIBE':'Subscribe',
        'Trial_Expired':'Trial_Expired',
        'SUBSCRIBECLOSEID':'subscribeCloseId',
        'SUBSCRIBETEXTID':'subscribeTextId',
        'GOOGLELOGINBUTTON':'GoogleLogInButton',
        'EMAILLOGINBUTTON':'EmailLogInButton',
        'JOINNOWBUTTON':'JoinNowButton',
        'EMAILSIGNUPBUTTON':'EmailSignUpButton',
        'EMAILSIGNUPBUTTONABONELAW':'EmailSignUpButtonAbonelaw',
        'ALREADY_HAS_ACCOUNT':'AlreadyHasAccount',
        'GOOGLE_SIGNUP_BUTTON':'GoogleSignUpButton',
        'GOOGLE_SIGNUP_BUTTON_ABONELAW':'GoogleSignUpButtonAbonelaw',
        'GOOGLE_SIGNUP_CREATE_FREE_BUTTON':'GoogleSignUpCreateFreeButton',
        'UNVERIFIED_LEAD':'UnVerified_Lead',
        'FORGOT_BUTTON':'Forgot_Button',
        'VERIFIED_LEAD':'Verified_Lead',
        'PASSWORD_UPDATED':'Password_Updated',
        'LOGIN_BUTTON':'Login_Button',
        'CREATE_FREE_ACCOUNT':'Create_Free_Account',
        'GET_STARTED_ID':'getStartedId',
        'ROLE_POPUP_NEXT_ID':'rolePupNextId',
        'ROLE_POPUP_CROSS_BUTTON_ID':'rolePopUpCrossButton',
        'SURVEY_POPUP_CROSS_BUTTON_ID':'surveyPopUpCrossButton',
        'ROLE_POPUP_SKIP_BUTTON_ID':'rolePopUpSkitButton',
        'SURVEY_POPUP_SKIP_BUTTON_ID':'surveyPopUpSkipButton',
        'FORGOT_PASSWORD_SIGNIN':'forgotPasswordSignIn',
        'FORGOT_PASSWORD_ACCOUNTVERIFIED':'forgotPassword_AccountVerified',
        'FORGOT_PASSWORD_PASSWORDUPDATE':'forgotPassword_PasswordUPdate',
        'CANCEL_BUTTON_PASSWORDUPDATE':'cancelButton_PasswordUPdate',
        'SEND_BUTTON_FORGOT_PASSWORD':'sendButton_ForgotPassword',
        'CANCEL_BUTTON_FORGOT_PASSWORD':'cancelButton_ForgotPassword',
        'RESET_VERIFICATION_LINK_PASSWORD_VERIFICATION':'resetVerificationLink_passwordVerification',
        'RESET_VERIFICATION_LINK_EMAIL_VERIFICATION':'resetVerificationLink_emailVerification',
        'CANCEL_BUTTON_PASSWORD_VERIFICATION':'cancelButton_passwordVerification',
        'YES_BUTTON':'yesButton',
        'NO_BUTTON':'noButton',
        'TITLE_POPUP':'titlePopUp',
        'DESCRIPTION_POPUP':'descriptionPopUp',
        'ADD_ACTIVITY':'addActivity',
        'SAVE_ACTIVITY':'saveActivity',
        'DOWNLOAD_ACTIVITY':'downloadActivity',
        'CANCEL_ACTIVITY':'cancelActivity',
        'EDIT_ACTIVITY':'editActivity',
        'DELETE_ACTIVITY':'deleteActivity',
        'ACTIVITY_VIEW_BACK':'activityViewBack',
        'ACTIVITY_ADD_BACK':'activityAddBack',
        'EDIT_PROVIDER':'editProvider',
        'DELETE_PROVIDER':'deleteProvider',
        'PROVIDER_VIEW_BACK':'providerViewBack',
        'EDIT_MEDICATION':'editMedication',
        'MEDICATION_VIEW_BACK':'medicationViewBack',
        'EDIT_MEDICATION_MOBILE':'editMedicationMobile',
        'INCOME_VIEW_BACK':'incomeViewBack',
        'INCOME_ADD_BACK':'incomeAddBack',
        'EXPENSE_VIEW_BACK':'incomeViewBack',
        'EXPENSE_ADD_BACK':'incomeAddBack',
        'ADD_INCOME':'addIncome',
        'SAVE_INCOME':'saveIncome',
        'DOWNLOAD_INCOME':'downloadIncome',
        'DOWNLOAD_MEDICAL':'downloadMedical',
        'DOWNLOAD_PROVIDER':'downloadProvider',
        'CANCEL_INCOME':'cancelIncome',
        'EDIT_INCOME':'editIncome',
        'DELETE_INCOME':'deleteIncome',
        'ADD_EXPENSE':'addExpense',
        'SAVE_EXPENSE':'saveExpense',
        'DOWNLOAD_EXPENSE':'downloadExpense',
        'CANCEL_EXPENSE':'cancelExpense',
        'EDIT_EXPENSE':'editExpense',
        'DELETE_EXPENSE':'deleteExpense',
        'DEPENDENT_PROFILE_STEP1_BANK':'dependentProfileStepBack',
        'DEPENDENT_PROFILE_STEP3BACK':'dependentProfileStep3Back',
        'DEPENDENT_PROFILE_STEP2BACK':'dependentProfileStep2Back',
        'DEPENDENT_PROFILE_STEP4BACK':'dependentProfileStep4Back',
        'DEPENDENT_PROFILE_STEP3CONTINUE':'dependentProfileStep3Continue',
        'DEPENDENT_PROFILE_STEP2CONTINUE':'dependentProfileStep2Continue',
        'DEPENDENT_PROFILE_STEP4FINISHED':'dependentProfileStep4Finished',
        'ADD_DEBT_LIABILITY_CANCEL':'addDebtLiabilityCancel',
        'ADD_DEBT_LIABILITY_SAVE':'addDebtLiabilitySave',
        'PERSONAL_PROPERTY_CANCEL':'personalPropertyCancle',
        'PERSONAL_PROPERTY':'personalProperty',
        'IMAGE_UPLOAD':'imageUpload',
        'ADD_BENEFICIARY_CANCEL':'addBeneficiaryCancel',
        'ADD_BENEFICIARY_SAVE':'addBeneficiarySave',
        'ASSET_TRANSFER':'assetTransfer',
        'MEDICALP_IMAGE_UPLOAD':'medicalImageUpload',
        'MY_ACCOUNT_CANCEL':'myAccountCancel',
        'MY_ACCOUNT_CHANGE_PASSWORD':'myAccountCancelChangePassword',
        'REAL_ESTATE':'realEstate',
        'ASSET_ACCESS_CONTROL_YES':'AssetAccessControlYes',
        'ASSET_ACCESS_CONTROL_NO':'AssetAccessControlNo',
        'DOCUMENT_POPUP_CANCEL':'documentPopUpCancel',
        'DOCUMENT_POPUP_RENAME':'documentPopUpRename',
        'DOCUMENT_POPUP_TITLE':'documentPopUpRenameTitle',
        'DOCUMENT_POPUP_CREATE':'documentPopUpCreate',
        'REAL_STEP_2_CANCEL':'real_step2_cancel',
        'REAL_STEP_2_NEXT':'real_step2_next',
        'ADD_MEDICAL_CANCEL':'addMedicalCancel',
        'ADD_MEDICAL_SAVE':'addMedicalSave',
        'ADDRESS_CANCEL':'addressCancel',
        'ADDRESS':'address',
        'RETIREMENT_CANCEL':'retirementCancel',
        'RETIREMENT':'retirement',
        'SAFE_DEPOSIT_BOX_CANCEL':'safetyDepositeCancel',
        'SAFE_DEPOSIT_BOX':'address',
        'SIGNUP_LOADING_IMAGE_CANCEL':'signUpLoadingImageCancel',
        'CO_OWNER_CANCEL':'coOwnerCancel',
        'CO_OWNER':'coOwner',
        'INSTITUTION_ADDRESS_CANCEL':'institutionAddressCancel',
        'INSTITUTION_ADDRESS':'institutionAddress',
        'BURIAL_ACCOUNT_CANCEL':'burialAccountCancel',
        'BURIAL_ACCOUNT':'burialAccount',
        'INSURANCE_QUE_YES':'insuranceQueYes',
        'INSURANCE_QUE_NO':'insuranceQueNo',
        'BUSINESS_INTEREST_CANCEL':'businessInterestCancel',
        'BUSINESS_INTEREST':'businessInterest',
        'INVITE_USER_CANCEL':'inviteUSerCancel',
        'INVITE_USER_SEND':'inviteUSerSend',
        'INVITE_USER_RESEND':'inviteUSerReSend',
        'INSURANCE_STEP3_CANCEL':'insuranceStep3Cancel',
        'INSURANCE_STEP3':'insuranceStep3',
        'AUTOMOBILE_STEP2_YES':'automobileStep2Yes',
        'AUTOMOBILE_STEP2_NO':'automobileStep2No',
        'ADD_ASSET_CANCEL':'addAssetCancel',
        'ADD_ASSET_NEXT':'addAssetNext',
        'PHOTO_UPLOAD_EDIT_CANCEL':'photoUploadEditCancel',
        'PHOTO_UPLOAD_CANCEL':'photoUploadCancel',
        'INVESTMENT_ACCOUNT_CANCEL':'investmentAccountCancel',
        'INVESTMENT_ACCOUNT':'investmentAccount',
        'AUTOMOBILE_STEP1_CANCEL':'automobileStep1Cancel',
        'AUTOMOBILE_STEP1':'automobileStep1',
        'OTHER_CANCEL':'otherCancel',
        'OTHER':'other',
        'PROMOCODE2_APPLY_BUTTON':'promocode2ApplyButton',
        'ADD_CONTACT_CANCEL':'addAssetCancel',
        'ADD_CONTACT_SAVE':'addAssetSave',
        'BILLING_BACK_BUTTON':'billingBackButton',
        'BILLING_FINISH_BUTTON':'billingFinishButton',
        'BANK_ACCOUNT_CANCEL':'bankAccountCancel',
        'BANK_ACCOUNT_NEXT':'bankAccountNext',
        'BANK_ACCOUNT_SAVE':'bankAccountSave',
        'INSURANCE_STEP1_CANCEL':'insuranceStep1Cancel',
        'INSURANCE_STEP1':'insuranceStep1',
        'DEPENDENT_PROFILE_EDIT_CANCEL':'dependentProfileEditCancel',
        'DEPENDENT_PROFILE_EDIT_SAVE':'dependentProfileEditSave',
        'ADD_CO_OWNER_CANCEL':'addCoOwnerCancel',
        'ADD_CO_OWNER_SAVE':'addCoOwnerSave',
        'ADD_DOCTOR_CANCEL':'addDoctorCancel',
        'ADD_DOCTOR_SAVE':'addDoctorSave',
        'MY_ACCOUNT_PERSONAL_INFO_CANCEL':'myAccountPersonalInfoCancel',
        'MY_ACCOUNT_PERSONAL_INFO_SAVE':'myAccountPersonalInfoSave',
        'DEPENDENT_ADDRESS_EDIT_CANCEL':'dependentAddressEditCancel',
        'DEPENDENT_ADDRESS_EDIT_SAVE':'dependentAddressEditSave',
        'DELETE_POPUP_YES':'deletePopUpYes',
        'DELETE_POPUP_NO':'deletePopUpNo',
        'DELETE_POPUP_TITLE':'deletePopUpTitle',
        'DELETE_POPUP_DESCRIPTION':'deletePopUpDescription',
        'DOCUMENT_ADD':'documentAdd',
        'DOCUMENT_UPLOAD':'documentUpload',
        'DOCUMENT_DOWNLOAD':'documentDownload',
        'DOCUMENT_DELETE':'documentDelete',
        'DOCUMENT_BACK':'documentBack',
        'DOCUMENT_FOOTER_MY_DEPENDENT':'documentFooterMyDependent',
        'DOCUMENT_CANCEL':'documentCancel',
        'DOCUMENT_MOVE':'documentMove',
        'DOCUMENT_PRIVATE_FILE':'documentPrivateFile',
        'LEARN_MORE':'learnMore',
        'WELCOMESCREEN_CLICK_HERE':'welcomeScreenClickHere',
        'MY_ACCOUNT_ID':'MyAccount',
        'HELP':'Help',
        'LOGOUT':'LogOut',
        'WELCOME_SCREEN_BACK_BUTTON':'welcomeScreenBackButton',
        'CROSS':'Cross',
        'PASSWORD_SHOW_HIDE':'passwordShowHide',
        'NEW_PASSWORD_SHOW_HIDE':'newPasswordShowHide',
        'GENERAL_INFO':'GeneralInfo',
        'LEGAL_DOCUMENT':'legalDocument',
        'IMAGE_UPLOAD_BUTTON':'imageUploadButton',
        'IMAGE_REMOVE_BUTTON':'imageRemoveButton',
        'IMAGE_ADD_BUTTON':'imageAddButton',
        'DEPENDENT_MOVE_DATE':'DependentMoveDate',
        'DEPENDENT_MOVEING_LOCATION':'dependentMovingLocation',
        'DESKTOP':'desktop',
        'MOBILE':'mobile',
        'DELETE_BUTTON':'DeleteButton',
        'CANCEL_BUTTON':'CancelButton',
        'BUTTON':'Button',
        'ADVOCATE_TEAM':'AdvocateTeam',
        'RESEND':'Resend',
        'EMAIL':'email',
        'PHONE_NUMBER':'phoneNumber',
        'REPEAT_MONTHLY_TOGGLE':'repeatMonthlyToggle',
        'ATTACHED_ALL_FUTURE_EXPENSE':'AttachAllFutureExpense',
        'ATTACHED_ALL_FUTURE_INCOME':'AttachAllFutureINCOME',
        'DOCUMENT_RENAME':'documentRename',
        'DOCUMENT_MOVE_TO':'documentMoveTo',
        'CAR_INSURED':'carInsured',
        'DATE_ACQUIRED':'dateAcquired',
        'DATE_OPENED':'dateOpened',
        'AS_OF_DATE':'asOfDate',
        'TERM_DATE':'asOfDate',
        'PURCHASE_DATE':'asOfDate',
        'CONTROL_OF_ASSET':'controlOfAsset',
        'DELETE_DOCUMENT':'DeletedDocument',
        'SETUP_ONLINE_BANKING':'setupOnlineBanking',
        'TRANSFER':'transfer',
        'COURT_ORDER_USED':'CourtOrderUsed',
        'SALE':'sale',
        'TRANSFER_TYPE':'TransferType',
        'DATE':'date',
        'DATE_OF_BIRTH':'dateOfBirth',
        'MEDICATION_STATUS':'medicationStatus',
        'START_DATE':'startDate',
        'END_DATE':'endDate',
        'SIGN_IN_SECURITY':'signInSecurity',
        'FAQ_TEXT':'faqText',
        'JOIN_ADVOCORD':'joinAdvocord',
        'VIEW_INVOICE_BUTTON':'viewInvoiceButton',
        'HAVE_PROMO_CODE':'havePromoCode',
        'NAV_ICON':'navIcon',
        'EDIT_BILLING_ICON':'editBillingIcon',
        'ACTIVE_CARD_IMAGE':'activeCardImage'
    },
    REFRESH_TIMEOUT:1800, // in seconds
    LOGOUT_TIME : 1800000 //15 min in miliseconds

}

export default A12Y_CONST;
