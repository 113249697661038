import React, { Component } from 'react'
import IncomeViewComponent from '../components/IncomeViewComponent'
import  * as dependentActions from '../redux/actions/dependentActions'
import { connect } from 'react-redux';
import A12Y_CONST from "../common/a12yConst";
import {secureClient} from "../config/axiosClient";
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import {downloadFile} from "../util/imageDownload";
import * as userDetailActions from "../redux/actions/userDetailActions";

export class IncomeViewScreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            isDownloadActive: false,
            error:false,
            message:'',
            errorMessage:'',
        }
    }

    componentDidMount = async () => {
        await this.props.dataChanged(false)
    }

    onEditHandler = async()=>{
        // this.props.history.push(A12Y_CONST.ROUTE.ADD_INCOME);
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_INCOME,
            state: {fromIncomeView:true }
        });
    }


    onDelete = async()=>{
        if(this.props.currentIncome && Object.keys(this.props.currentIncome).length > 0){
            this.setState({loading:true,errorMessage:'',error:false})
            let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.INCOME+this.props.currentIncome.id);
            this.setState({loading:false})
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.props.history.push(A12Y_CONST.ROUTE.INCOMES);
                await this.props.currentIncomeData({})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            }
        }
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    onCancel = async()=>{
        this.props.history.push(A12Y_CONST.ROUTE.INCOMES);
        await this.props.currentIncomeData({})
    }

    onDownloadHandler = async(item, newName)=>{
        this.setState({
            isDownloadActive: true
        })
        await downloadFile(item, newName).then(() => {
            this.setState({
                isDownloadActive: false
            })
        })
    }

    render() {
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
            <div className='rightPanelWidhtSidebar'>
                <div className="rightPanelWidhtSidebarContainer fullWidth">
                    {this.state.loading ?
                        <LoaderFullScreen/>
                        :
                        <IncomeViewComponent
                            incomeObj={this.props.currentIncome}
                            dependent={this.props.dependent}
                            message={this.state.message}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            onEdit={this.onEditHandler}
                            onDelete={this.onDelete}
                            onCancel={this.onCancel}
                            onDownload={this.onDownloadHandler}
                        />
                    }
                    {
                        this.state.isDownloadActive && <LoaderFullScreen/>
                    }
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    dependent : state.dependentReducer.dependent,
    currentIncome : state.dependentReducer.currentIncome,
});
const mapDispatchToProps = dispatch => ({
    currentIncomeData : (income) => dispatch(dependentActions.currentIncomeData(income)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
});

export default connect(mapStateToProps,mapDispatchToProps) (IncomeViewScreen);
