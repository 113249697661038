import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Loading from '../components/LoadingComponent';
import UploadFiles from '../components/ImageUploadComponent';
import LoaderFullScreen from './LoaderFullScreenComponent'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import EditChangesRemovePopup from '../components/EditChangesRemovePopup'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from 'uuid';
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';
import {currencyFormatting} from "../util/CurrencyFormater";

function AddDebtFormComponent(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [debtType, setDebtType] = useState({});
    const [dateDebt, setDateDebt] = useState(new Date());
    const [amount, setAmount] = useState('');
    const [owedTo, setOwedTo] = useState('');
    const [note, setNote] = useState('');
    const [debtTypeOptionsList, setDebtTypeOptionsList] = useState([]);
    const [debtTypeError, setDebtTypeError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [dateErrorMeesage, setDateErrorMeesage] = useState('');
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [amountErrorMeesage, setAmountErrorMeesage] = useState('');
   // const [owedToError, setOwedToError] = useState(false);
    const [editMode, setEditMode] = useState(null);
    /*const [owedToErrorMeesage, setOwedToErrorMeesage] = useState('');*/
    const [isSubmit, setIsSubmit] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [debtId, setDebtId] = useState(null)
    const { register, handleSubmit, getValues, control,setError, formState: { errors, isSubmitting } } = useForm({ mode: "all" });
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const dateRef = useRef(null);
    const [linkToWebsite, setLinkToWebsite] = useState('');
    // const [isAmountEmpty, setIsAmountEmpty] = useState(true)


    function goBack() {
       // history.push("/debts");
        props.onCancel();
    }

    useEffect(()=>{
        const assignDefaultValue = async ()=>{
            let debt = props.debtObj;
            if(debt && Object.keys(debt).length > 0){
                setDebtId(debt.id);
                if(props.debtTypeList.length > 0){
                    let list = await props.debtTypeList.filter(item =>item.id === debt.debt_type_id)
                    if(list && list.length > 0){
                        setDebtType(list[0])
                        // setValue("debtType",list[0])
                    }
                }
                let amount = debt?.debt_value || debt?.debt_value === 0  ? await currencyFormatting(debt.debt_value) : ''
                setAmount(amount)
                setOwedTo(debt.owed_to)
                setNote(debt.note)
                setDateDebt(new Date(debt.as_of_date))
                setLinkToWebsite(debt?.web_url)
                await setEditMode(true)
                setIsBtnDisabled(true);
            }else {
                const uuid = await uuidv4();
                setDebtId(uuid);
                setEditMode(false)
            }
        }

        assignDefaultValue()

    },[props.debtTypeList,props.debtObj])

    useEffect( ()=>{
        const checkFormDataisChanged = async  ()=>{
            let debt = props.debtObj;
            setIsBtnDisabled(true)
            props.isDataChanged(false)
            setIsDataModified(false)
            if(editMode && Object.keys(debt).length > 0){
                setIsBtnDisabled(true)
                if(await currencyFormatting(debt.debt_value) !== amount || debt.owed_to !== owedTo || debt.web_url !== linkToWebsite || debt.note !== note || await ddmmyyyyFormat(debt.as_of_date) !==  await ddmmyyyyFormat(dateDebt) || (Object.keys(debtType).length > 0 && debt.debt_type_id !== debtType.id) || deletedFileInfo.length > 0 || fileInfo.length > 0){
                    if(Object.keys(debtType).length > 0 && amount !== "" && dateDebt !== null){
                        setIsBtnDisabled(false)
                    }
                    setIsDataModified(true)
                    props.isDataChanged(true)
                }
            }else {
                if(editMode !== null && !editMode){
                    let today = await ddmmyyyyFormat(new Date())
                    if(amount !== "" ||  owedTo !== "" ||  note !== "" || linkToWebsite !== "" || today !==  await ddmmyyyyFormat(dateDebt) || (Object.keys(debtType).length > 0) || deletedFileInfo.length > 0 || fileInfo.length > 0){
                        if(Object.keys(debtType).length > 0 && amount !== "" && dateDebt !== null){
                            setIsBtnDisabled(false)
                        }
                        setIsDataModified(true)
                        props.isDataChanged(true)
                    }
                }
            }
        }

        checkFormDataisChanged()

    },[amount,owedTo,note,dateDebt,debtType,fileInfo,deletedFileInfo,editMode, linkToWebsite]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            props.onCancel()
            //history.push(A12Y_CONST.ROUTE.INVENTORY)
            // if(props.isSessionTimeout){
            // }else {
            //     history.goBack()
            // }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        props.onCancel()
    }

    const handleDebtTypeChange = async (e) => {
       // const evt = e.target.value;
      await setDebtType(e)
        setDebtTypeError(false)
    }

    function validateDebtType(type) {
        if(type === 'blur'){
            if(debtType && Object.keys(debtType).length === 0){
                setDebtTypeError(true)
            }
        }else {
            setDebtTypeError(false)
        }

    }

    const handleAmountChange = async e => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setAmount(amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setAmount(temp)
            }
        }
    }

    const handleOwedToChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setOwedTo(e.target.value);
        }
    }

    const handleNoteChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setNote(e.target.value);
        }
    }
    
    useEffect(  () => {
        const assignDebtList = async ()=>{
            var debtTypeList = await props.debtTypeList.map(item => ({ label: item.title, value: item.title, id:item.id }));
            setDebtTypeOptionsList(debtTypeList)
        }

        assignDebtList()

    },[props.debtTypeList])
    
    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.error, props.loading, props.loaderFullScreen])
    
    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const isDateValid = () => {
        if(dateDebt != null){
            let isValidObj = validateDate(dateDebt, 20, 2)
            
            setDateError(isValidObj.isErr)
            setDateErrorMeesage(isValidObj.errMsg)
        }else{
            setDateError(true)
            setDateErrorMeesage('Date is required.')
        }
    }
    
    const validateAmount = () => {
        if(amount === '' && amount.length === 0) {
            setAmountError(true)
            setAmountErrorMeesage('Enter valid amount.');
            return true;
        }else{
            setAmountError(false)
            setAmountErrorMeesage('');
            return false;
        }
    }
    const validateOwedTo = () => {
        if(owedTo === '' && owedTo.length === 0) {
           // setOwedToError(true)
           // setOwedToErrorMeesage('Whom to pay');
            return true;
        }else{
            //setOwedToError(false)
            //setOwedToErrorMeesage('');
            return false;
        }
    }

    const onSubmit = async(debtData)=>{
        let isValidate = true;
        if(debtType && Object.keys(debtType).length === 0){
           await setDebtTypeError(true)
            isValidate = false;
        }
        if(amount === '' && amount.length === 0) {
            setAmountError(true)
            setAmountErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_AMOUNT);
            isValidate = false;
        }
        
        if(dateError){
            return
        }

        // if(dateDebt.length > 0 && dateDebt.length < 14 || dateDebt === '') {
        //     setDateError(true)
        //     setDateErrorMeesage('Invalid Date');
        //     isValidate = false;
        // }
        // if(dateDebt && dateDebt.split("/").length === 3){
        //     let dateDebtSplitList = dateDebt.split("/")
        //     let date = dateDebtSplitList[0].trim()+"/"+dateDebtSplitList[1].trim()+"/"+dateDebtSplitList[2].trim();
        //     debtData.dateDebt = date
        // }else {
        //     debtData.dateDebt = null
        // }
        let obj = {
            'id' : debtId,
            "debt_type_id" : debtType && Object.keys(debtType).length > 0 ? debtType.id: '',
            "debt_value" : Object.keys(amount).length > 0 && amount.includes(',') ? amount.replaceAll(',','') : amount ,
            "owed_to" : owedTo,
            "note" : note,
            "as_of_date": await ddmmyyyyFormat(new Date(dateDebt)),
            "web_url" : linkToWebsite,
        }

        let isDatesValid = validateDate(dateDebt, 20, 2)

        if(isValidate && !isDatesValid.isErr){
            if(editMode){
                setIsSubmit(true)
                props.onUpdate(obj, fileInfo, fileBase64Info,deletedFileInfo,editMode)
            }else {
                setIsSubmit(true)
                props.onSubmit(obj, fileInfo, fileBase64Info,deletedFileInfo,editMode)
            }
        }

    }
    const filesDetail_FromChild = (e,deletedFiles) => {
        let fileList = [];
        let base64List = [];
        for (var i = 0; i < e.length; i++) {
            if(!e[i].file_id){
                let name = e[i].displayName
                let size = e[i].size
                let file = e[i];
                let fileName = e[i].name;
                let reader = new FileReader();
                const uuid = uuidv4();
                //let s3key = props.dependent.id+'/'+'debt/'+debtId+'/'+uuid
                let s3key = `${props.dependent.id}/debt/${debtId}/${uuid}`
                reader.readAsDataURL(file);
                reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                );
                fileList.push({
                    s3_key: s3key,
                    file_id:uuid,
                    debt_id:debtId,
                    'contentType': e[i].type,
                    'fileMetadata': {
                        "about_dependent": "some more info"
                    },
                    'name' : name,
                    'size' : size,
                    'fileName': fileName
                });
            }
        }
        setFileInfo(fileList);
        setFileBase64Info(base64List)
        setDeletedFileInfo(deletedFiles)
    }
    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }
    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addExpensesWrap">
            { loaderFullScreenVisible ?
                <LoaderFullScreen/>
                :
                null
            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h2 className="titleWithSidebar noExpenseTitle marginBottom30">
                <span className="cursorPointer" onClick={goBack}><svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span>{A12Y_CONST.LABEL.DEBTS_LIABILITIES}</span></span>
            </h2>
            <div className="dependentBorderBox expenseBorderBox600 dependentDefauldMinHeight">
                <h3 className='marginBottom10'><span className='vam'>{editMode ? 'Update' : 'Add'}</span> <span className='vam'>{A12Y_CONST.LABEL.DEBT_OF}</span> <span className='txtEllipsis vam'>{props.dependent.nick_name}</span></h3>
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
                <form autoComplete="off" className='marginTop30 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow fullWidth formRowGap dependentResidencCol">
                        <label className="lblExpType">{A12Y_CONST.LABEL.DEBT_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className={(debtTypeError && 'selectError') + ' ' + (debtType && Object.keys(debtType).length > 0 ? 'hasValue ' : 'noValue ') + ' fullWidth dependentResidencCol'}>
                            <Controller
                                name="debtType"
                                value={debtType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={debtTypeOptionsList}
                                        value={debtTypeOptionsList.find(c => c.id === debtType.id)}
                                        onChange={handleDebtTypeChange}
                                        onFocus={()=>{validateDebtType('focus')}}
                                        onBlur={()=>{validateDebtType('blur')}}
                                        classNamePrefix={'dependentResidence'}
                                    />
                                )}
                                control={control}
                                className="fullwidth inputColum24size"
                                placeholder="Select"
                            />
                            <div className='errorRelativeRow'> {debtTypeError && <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_EXPENSE_TYPE}</p>}</div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGap payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.AMOUNT}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={getValues('amount') !== '' || amount.length < 1? "currency" : "currency currencyColorChnage" }>$</span>
                                <input 
                                    {...register("amount")}
                                    placeholder="0.00" 
                                    type='text' min='0' inputMode='decimal' //pattern='[0-9]+(\.[0-9]{1,2})?'
                                    id="amount"
                                    //maxLength="5"
                                    onWheel={e => e.target.blur()}
                                    onChange={handleAmountChange}
                                    value={amount}
                                    onBlur={validateAmount}
                                    onFocus={()=>setAmountErrorMeesage(false)}
                                    className={`fullWidth txtAmount inputColum24size ${amountError && 'errorInputBorderRed'}`} 
                                />
                                <div className='errorRelativeRow'>{amountError? <p className='errorMessage'>{amountErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 positionRelative paidTo">
                            <label className='minWidht190 textAlignRight'>{A12Y_CONST.LABEL.OWED_TO}</label>
                            <div className="fullWidth">
                                <input 
                                    {...register("owedTo")} 
                                    value={owedTo}
                                    id="owedTo"
                                    maxLength='255'
                                    onBlur={validateOwedTo}
                                    onChange={handleOwedToChange}
                                   // onFocus={()=>setOwedToErrorMeesage(false)}
                                    className="fullWidth inputColum24size"
                                    placeholder="Walmart"
                                />
                                {/*<div className='errorRelativeRow'>{owedToError? <p className='errorMessage'>{owedToErrorMeesage}</p>: null}</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="formRow width50 formRowGap">
                        <label>{A12Y_CONST.LABEL.DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="paddingRight16">
                            <Controller
                                name="dateDebt"
                                control={control}
                                inputmode='none'
                                render={({ field }) => 
                                    <DatePicker
                                        {...field}
                                        id={A12Y_CONST.BUTTON_ID.DATE}
                                        ref={dateRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={dateDebt}
                                        onChange={date => setDateDebt(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'dateDebt')}
                                        onBlur={isDateValid}
                                        onCalendarClose={isDateValid}
                                        showMonthDropdown
                                        onKeyDown={(event) => hideOnBlur(event, dateRef)}
                                        showYearDropdown
                                        dropdownMode="select"
                                        onFocus={(e)=> {setDateError(false); readOnlyOnMobile(e.target)}}
                                        className={!dateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>
                                {dateError? <p className='errorMessage'>{dateErrorMeesage}</p>: null}
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGapSM payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.LINK_URL}</label>
                            <div className='fullWidth doctorRowMarginB_600'>
                                <input
                                    {...register('linkToWebsite',{
                                        pattern:{
                                            //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                            value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                            message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                        }
                                    })}
                                    placeholder='www.example.com'
                                    className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                    type='text'
                                    id='displayName'
                                    autoComplete="none"
                                    onChange={(e) => handleLinkToWebsite(e.target.value)}
                                    value={linkToWebsite}
                                    onFocus={()=>{
                                        setError("linkToWebsite", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />
                                <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                            </div>
                        </div>
                    </div>
                    {editMode ?
                    null
                    :
                    <div className="formRow fullWidth formRowGap df fcol">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className="fullWidth">
                            <textarea 
                                {...register("note")} 
                                rows="3" 
                                onChange={handleNoteChange}
                                maxLength='4000'
                                value={note}
                                className="fullWidth inputColum24size txtNote" 
                                placeholder="Capture a note about this transaction."
                            ></textarea>
                        </div>
                        {/*<div className="txtCapture">Capture a note about this transaction.</div>*/}
                    </div>
                    }
                    <div className="formRow fullWidth formRowGap uploadWrap">
                        <label className="lblExpense">{A12Y_CONST.LABEL.DEBT_ATTACHMENT}</label>
                        <div className="lblUpload">
                            <UploadFiles
                                fileDetailSendToParent={filesDetail_FromChild}
                                fileUploadingData={props.fileUploadingData}
                                isSubmit={isSubmit}
                                editMode={editMode}
                                accepts={""}
                                obj={props.debtObj}
                            />
                            <div className='errorRelativeRow'> {errors.debtAttachment && <p className='errorMessage'>{A12Y_CONST.TEXT.SELECT_FILE_UPLOAD_MESSAGE}</p>}</div>
                        </div>
                    </div>

                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.ADD_DEBT_LIABILITY_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.ADD_DEBT_LIABILITY_SAVE} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={editMode ? A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE : A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default AddDebtFormComponent