import React, {useEffect, useState, useRef} from 'react';
import { useForm, Controller } from 'react-hook-form';
import Loading from './LoadingComponent'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import A12Y_CONST from '../common/a12yConst'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import PopUpComponent from '../components/PopUpComponent'
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {readOnlyMobile} from '../util/inputKeyboardHide';
import Switch from "@material-ui/core/Switch";

function DependentAddressEditComponent (props) {
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [countryState, setCountryState]  = useState({});
    const [county, setCounty]  = useState({});
    const [selectResident, setSelectResident]  = useState({});
    const [showAddressDropDown, setShowAddressDropDown] = useState(false);
    const [dependentLivedHereSince, setDependentLivedHereSince] = useState(null);
    const [dependentLivedError, setDependentLivedError] = useState(false);
    const [dependentLivedErrorMeesage, setDependentLivedErrorMeesage] = useState('');
    const [residenceErrorMeesage, setResidenceErrorMeesage] = useState('');
    const [residenceError, setResidenceError] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isDataModified, setDataModified] = useState(false);
    const [line2, setLine2] = useState('');
    const [line1, setLine1] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [zipErrorMeesage, setZipErrorMeesage] = useState('');
    const [zipError, setZipError] = useState(false);
    const [reasonForMove, setReasonForMove] = useState('');
    const [placeServiceList, setPlaceServiceList] = useState({});
    const dependentLivedHereSinceRef = useRef(null);
    const [dropDownIndex, setDropDownIndex] = useState(null);
    const [countyErrorMeesage, setCountyErrorMeesage] = useState('');
    const [countyError, setCountyError] = useState(false);
   // const [showLayer, setShowLayer] = useState(false);
    
    const [movingLocation, setmovingLocation] = useState(false);

    const dependentResidenceTypeList = [
        {
            "name":'Dependent’s Residence',
            "label":'Dependent’s Residence',
            "key":1
        },
        {
            "name":'Advocate’s Residence',
            "label":'Advocate’s Residence',
            "key":2
        },
        {
            "name":'Facility',
            "label":'Facility',
            "key":3
        },
        {
            "name":'Other',
            "label":'Other',
            "key":4
        }
    ];
    const countryStateList = [
            {
                "name": "Alabama",
                "label": "AL"
            },
            {
                "name": "Alaska",
                "label": "AK"
            },
            {
                "name": "American Samoa",
                "label": "AS"
            },
            {
                "name": "Arizona",
                "label": "AZ"
            },
            {
                "name": "Arkansas",
                "label": "AR"
            },
            {
                "name": "California",
                "label": "CA"
            },
            {
                "name": "Colorado",
                "label": "CO"
            },
            {
                "name": "Connecticut",
                "label": "CT"
            },
            {
                "name": "Delaware",
                "label": "DE"
            },
            {
                "name": "District Of Columbia",
                "label": "DC"
            },
            {
                "name": "Federated States Of Micronesia",
                "label": "FM"
            },
            {
                "name": "Florida",
                "label": "FL"
            },
            {
                "name": "Georgia",
                "label": "GA"
            },
            {
                "name": "Guam",
                "label": "GU"
            },
            {
                "name": "Hawaii",
                "label": "HI"
            },
            {
                "name": "Idaho",
                "label": "ID"
            },
            {
                "name": "Illinois",
                "label": "IL"
            },
            {
                "name": "Indiana",
                "label": "IN"
            },
            {
                "name": "Iowa",
                "label": "IA"
            },
            {
                "name": "Kansas",
                "label": "KS"
            },
            {
                "name": "Kentucky",
                "label": "KY"
            },
            {
                "name": "Louisiana",
                "label": "LA"
            },
            {
                "name": "Maine",
                "label": "ME"
            },
            {
                "name": "Marshall Islands",
                "label": "MH"
            },
            {
                "name": "Maryland",
                "label": "MD"
            },
            {
                "name": "Massachusetts",
                "label": "MA"
            },
            {
                "name": "Michigan",
                "label": "MI"
            },
            {
                "name": "Minnesota",
                "label": "MN"
            },
            {
                "name": "Mississippi",
                "label": "MS"
            },
            {
                "name": "Missouri",
                "label": "MO"
            },
            {
                "name": "Montana",
                "label": "MT"
            },
            {
                "name": "Nebraska",
                "label": "NE"
            },
            {
                "name": "Nevada",
                "label": "NV"
            },
            {
                "name": "New Hampshire",
                "label": "NH"
            },
            {
                "name": "New Jersey",
                "label": "NJ"
            },
            {
                "name": "New Mexico",
                "label": "NM"
            },
            {
                "name": "New York",
                "label": "NY"
            },
            {
                "name": "North Carolina",
                "label": "NC"
            },
            {
                "name": "North Dakota",
                "label": "ND"
            },
            {
                "name": "Northern Mariana Islands",
                "label": "MP"
            },
            {
                "name": "Ohio",
                "label": "OH"
            },
            {
                "name": "Oklahoma",
                "label": "OK"
            },
            {
                "name": "Oregon",
                "label": "OR"
            },
            {
                "name": "Palau",
                "label": "PW"
            },
            {
                "name": "Pennsylvania",
                "label": "PA"
            },
            {
                "name": "Puerto Rico",
                "label": "PR"
            },
            {
                "name": "Rhode Island",
                "label": "RI"
            },
            {
                "name": "South Carolina",
                "label": "SC"
            },
            {
                "name": "South Dakota",
                "label": "SD"
            },
            {
                "name": "Tennessee",
                "label": "TN"
            },
            {
                "name": "Texas",
                "label": "TX"
            },
            {
                "name": "Utah",
                "label": "UT"
            },
            {
                "name": "Vermont",
                "label": "VT"
            },
            {
                "name": "Virgin Islands",
                "label": "VI"
            },
            {
                "name": "Virginia",
                "label": "VA"
            },
            {
                "name": "Washington",
                "label": "WA"
            },
            {
                "name": "West Virginia",
                "label": "WV"
            },
            {
                "name": "Wisconsin",
                "label": "WI"
            },
            {
                "name": "Wyoming",
                "label": "WY"
            }
        ];
    const countyList1 = {"AL" : [
            {name:"AL",label:"Autauga County"},
            {name:"AL",label:"Baldwin County"},
            {name:"AL",label:"Barbour County"},
            {name:"AL",label:"Bibb County"},
            {name:"AL",label:"Blount County"},
            {name:"AL",label:"Bullock County"},
            {name:"AL",label:"Butler County"},
            {name:"AL",label:"Calhoun County"},
            {name:"AL",label:"Chambers County"},
            {name:"AL",label:"Cherokee County"},
            {name:"AL",label:"Chilton County"},
            {name:"AL",label:"Choctaw County"},
            {name:"AL",label:"Clarke County"},
            {name:"AL",label:"Clay County"},
            {name:"AL",label:"Cleburne County"},
            {name:"AL",label:"Coffee County"},
            {name:"AL",label:"Colbert County"},
            {name:"AL",label:"Conecuh County"},
            {name:"AL",label:"Coosa County"},
            {name:"AL",label:"Covington County"},
            {name:"AL",label:"Crenshaw County"},
            {name:"AL",label:"Cullman County"},
            {name:"AL",label:"Dale County"},
            {name:"AL",label:"Dallas County"},
            {name:"AL",label:"DeKalb County"},
            {name:"AL",label:"Elmore County"},
            {name:"AL",label:"Escambia County"},
            {name:"AL",label:"Etowah County"},
            {name:"AL",label:"Fayette County"},
            {name:"AL",label:"Franklin County"},
            {name:"AL",label:"Geneva County"},
            {name:"AL",label:"Greene County"},
            {name:"AL",label:"Hale County"},
            {name:"AL",label:"Henry County"},
            {name:"AL",label:"Houston County"},
            {name:"AL",label:"Jackson County"},
            {name:"AL",label:"Jefferson County"},
            {name:"AL",label:"Lamar County"},
            {name:"AL",label:"Lauderdale County"},
            {name:"AL",label:"Lawrence County"},
            {name:"AL",label:"Lee County"},
            {name:"AL",label:"Limestone County"},
            {name:"AL",label:"Lowndes County"},
            {name:"AL",label:"Macon County"},
            {name:"AL",label:"Madison County"},
            {name:"AL",label:"Marengo County"},
            {name:"AL",label:"Marion County"},
            {name:"AL",label:"Marshall County"},
            {name:"AL",label:"Mobile County"},
            {name:"AL",label:"Monroe County"},
            {name:"AL",label:"Montgomery County"},
            {name:"AL",label:"Morgan County"},
            {name:"AL",label:"Perry County"},
            {name:"AL",label:"Pickens County"},
            {name:"AL",label:"Pike County"},
            {name:"AL",label:"Randolph County"},
            {name:"AL",label:"Russell County"},
            {name:"AL",label:"St. Clair County"},
            {name:"AL",label:"Shelby County"},
            {name:"AL",label:"Sumter County"},
            {name:"AL",label:"Talladega County"},
            {name:"AL",label:"Tallapoosa County"},
            {name:"AL",label:"Tuscaloosa County"},
            {name:"AL",label:"Walker County"},
            {name:"AL",label:"Washington County"},
            {name:"AL",label:"Wilcox County"},
            {name:"AL",label:"Winston County"},], "AK" : [
            {name:"AK",label:"Aleutians East Borough"},
            {name:"AK",label:"Aleutians West Census Area"},
            {name:"AK",label:"Anchorage Municipality"},
            {name:"AK",label:"Bethel Census Area"},
            {name:"AK",label:"Bristol Bay Borough"},
            {name:"AK",label:"Denali Borough"},
            {name:"AK",label:"Dillingham Census Area"},
            {name:"AK",label:"Fairbanks North Star Borough"},
            {name:"AK",label:"Haines Borough"},
            {name:"AK",label:"Hoonah-Angoon Census Area"},
            {name:"AK",label:"Juneau City and Borough"},
            {name:"AK",label:"Kenai Peninsula Borough"},
            {name:"AK",label:"Ketchikan Gateway Borough"},
            {name:"AK",label:"Kodiak Island Borough"},
            {name:"AK",label:"Lake and Peninsula Borough"},
            {name:"AK",label:"Matanuska-Susitna Borough"},
            {name:"AK",label:"Nome Census Area"},
            {name:"AK",label:"North Slope Borough"},
            {name:"AK",label:"Northwest Arctic Borough"},
            {name:"AK",label:"Petersburg Census Area"},
            {name:"AK",label:"Prince of Wales-Hyder Census Area"},
            {name:"AK",label:"Sitka City and Borough"},
            {name:"AK",label:"Skagway Municipality"},
            {name:"AK",label:"Southeast Fairbanks Census Area"},
            {name:"AK",label:"Valdez-Cordova Census Area"},
            {name:"AK",label:"Wade Hampton Census Area"},
            {name:"AK",label:"Wrangell City and Borough"},
            {name:"AK",label:"Yakutat City and Borough"},
            {name:"AK",label:"Yukon-Koyukuk Census Area"},], "AZ" : [
            {name:"AZ",label:"Apache County"},
            {name:"AZ",label:"Cochise County"},
            {name:"AZ",label:"Coconino County"},
            {name:"AZ",label:"Gila County"},
            {name:"AZ",label:"Graham County"},
            {name:"AZ",label:"Greenlee County"},
            {name:"AZ",label:"La Paz County"},
            {name:"AZ",label:"Maricopa County"},
            {name:"AZ",label:"Mohave County"},
            {name:"AZ",label:"Navajo County"},
            {name:"AZ",label:"Pima County"},
            {name:"AZ",label:"Pinal County"},
            {name:"AZ",label:"Santa Cruz County"},
            {name:"AZ",label:"Yavapai County"},
            {name:"AZ",label:"Yuma County"},], "AR" : [
            {name:"AR",label:"Arkansas County"},
            {name:"AR",label:"Ashley County"},
            {name:"AR",label:"Baxter County"},
            {name:"AR",label:"Benton County"},
            {name:"AR",label:"Boone County"},
            {name:"AR",label:"Bradley County"},
            {name:"AR",label:"Calhoun County"},
            {name:"AR",label:"Carroll County"},
            {name:"AR",label:"Chicot County"},
            {name:"AR",label:"Clark County"},
            {name:"AR",label:"Clay County"},
            {name:"AR",label:"Cleburne County"},
            {name:"AR",label:"Cleveland County"},
            {name:"AR",label:"Columbia County"},
            {name:"AR",label:"Conway County"},
            {name:"AR",label:"Craighead County"},
            {name:"AR",label:"Crawford County"},
            {name:"AR",label:"Crittenden County"},
            {name:"AR",label:"Cross County"},
            {name:"AR",label:"Dallas County"},
            {name:"AR",label:"Desha County"},
            {name:"AR",label:"Drew County"},
            {name:"AR",label:"Faulkner County"},
            {name:"AR",label:"Franklin County"},
            {name:"AR",label:"Fulton County"},
            {name:"AR",label:"Garland County"},
            {name:"AR",label:"Grant County"},
            {name:"AR",label:"Greene County"},
            {name:"AR",label:"Hempstead County"},
            {name:"AR",label:"Hot Spring County"},
            {name:"AR",label:"Howard County"},
            {name:"AR",label:"Independence County"},
            {name:"AR",label:"Izard County"},
            {name:"AR",label:"Jackson County"},
            {name:"AR",label:"Jefferson County"},
            {name:"AR",label:"Johnson County"},
            {name:"AR",label:"Lafayette County"},
            {name:"AR",label:"Lawrence County"},
            {name:"AR",label:"Lee County"},
            {name:"AR",label:"Lincoln County"},
            {name:"AR",label:"Little River County"},
            {name:"AR",label:"Logan County"},
            {name:"AR",label:"Lonoke County"},
            {name:"AR",label:"Madison County"},
            {name:"AR",label:"Marion County"},
            {name:"AR",label:"Miller County"},
            {name:"AR",label:"Mississippi County"},
            {name:"AR",label:"Monroe County"},
            {name:"AR",label:"Montgomery County"},
            {name:"AR",label:"Nevada County"},
            {name:"AR",label:"Newton County"},
            {name:"AR",label:"Ouachita County"},
            {name:"AR",label:"Perry County"},
            {name:"AR",label:"Phillips County"},
            {name:"AR",label:"Pike County"},
            {name:"AR",label:"Poinsett County"},
            {name:"AR",label:"Polk County"},
            {name:"AR",label:"Pope County"},
            {name:"AR",label:"Prairie County"},
            {name:"AR",label:"Pulaski County"},
            {name:"AR",label:"Randolph County"},
            {name:"AR",label:"St. Francis County"},
            {name:"AR",label:"Saline County"},
            {name:"AR",label:"Scott County"},
            {name:"AR",label:"Searcy County"},
            {name:"AR",label:"Sebastian County"},
            {name:"AR",label:"Sevier County"},
            {name:"AR",label:"Sharp County"},
            {name:"AR",label:"Stone County"},
            {name:"AR",label:"Union County"},
            {name:"AR",label:"Van Buren County"},
            {name:"AR",label:"Washington County"},
            {name:"AR",label:"White County"},
            {name:"AR",label:"Woodruff County"},
            {name:"AR",label:"Yell County"},], "CA" : [
            {name:"CA",label:"Alameda County"},
            {name:"CA",label:"Alpine County"},
            {name:"CA",label:"Amador County"},
            {name:"CA",label:"Butte County"},
            {name:"CA",label:"Calaveras County"},
            {name:"CA",label:"Colusa County"},
            {name:"CA",label:"Contra Costa County"},
            {name:"CA",label:"Del Norte County"},
            {name:"CA",label:"El Dorado County"},
            {name:"CA",label:"Fresno County"},
            {name:"CA",label:"Glenn County"},
            {name:"CA",label:"Humboldt County"},
            {name:"CA",label:"Imperial County"},
            {name:"CA",label:"Inyo County"},
            {name:"CA",label:"Kern County"},
            {name:"CA",label:"Kings County"},
            {name:"CA",label:"Lake County"},
            {name:"CA",label:"Lassen County"},
            {name:"CA",label:"Los Angeles County"},
            {name:"CA",label:"Madera County"},
            {name:"CA",label:"Marin County"},
            {name:"CA",label:"Mariposa County"},
            {name:"CA",label:"Mendocino County"},
            {name:"CA",label:"Merced County"},
            {name:"CA",label:"Modoc County"},
            {name:"CA",label:"Mono County"},
            {name:"CA",label:"Monterey County"},
            {name:"CA",label:"Napa County"},
            {name:"CA",label:"Nevada County"},
            {name:"CA",label:"Orange County"},
            {name:"CA",label:"Placer County"},
            {name:"CA",label:"Plumas County"},
            {name:"CA",label:"Riverside County"},
            {name:"CA",label:"Sacramento County"},
            {name:"CA",label:"San Benito County"},
            {name:"CA",label:"San Bernardino County"},
            {name:"CA",label:"San Diego County"},
            {name:"CA",label:"San Francisco County"},
            {name:"CA",label:"San Joaquin County"},
            {name:"CA",label:"San Luis Obispo County"},
            {name:"CA",label:"San Mateo County"},
            {name:"CA",label:"Santa Barbara County"},
            {name:"CA",label:"Santa Clara County"},
            {name:"CA",label:"Santa Cruz County"},
            {name:"CA",label:"Shasta County"},
            {name:"CA",label:"Sierra County"},
            {name:"CA",label:"Siskiyou County"},
            {name:"CA",label:"Solano County"},
            {name:"CA",label:"Sonoma County"},
            {name:"CA",label:"Stanislaus County"},
            {name:"CA",label:"Sutter County"},
            {name:"CA",label:"Tehama County"},
            {name:"CA",label:"Trinity County"},
            {name:"CA",label:"Tulare County"},
            {name:"CA",label:"Tuolumne County"},
            {name:"CA",label:"Ventura County"},
            {name:"CA",label:"Yolo County"},
            {name:"CA",label:"Yuba County"},], "CO" :[
            {name:"CO",label:"Adams County"},
            {name:"CO",label:"Alamosa County"},
            {name:"CO",label:"Arapahoe County"},
            {name:"CO",label:"Archuleta County"},
            {name:"CO",label:"Baca County"},
            {name:"CO",label:"Bent County"},
            {name:"CO",label:"Boulder County"},
            {name:"CO",label:"Broomfield County"},
            {name:"CO",label:"Chaffee County"},
            {name:"CO",label:"Cheyenne County"},
            {name:"CO",label:"Clear Creek County"},
            {name:"CO",label:"Conejos County"},
            {name:"CO",label:"Costilla County"},
            {name:"CO",label:"Crowley County"},
            {name:"CO",label:"Custer County"},
            {name:"CO",label:"Delta County"},
            {name:"CO",label:"Denver County"},
            {name:"CO",label:"Dolores County"},
            {name:"CO",label:"Douglas County"},
            {name:"CO",label:"Eagle County"},
            {name:"CO",label:"Elbert County"},
            {name:"CO",label:"El Paso County"},
            {name:"CO",label:"Fremont County"},
            {name:"CO",label:"Garfield County"},
            {name:"CO",label:"Gilpin County"},
            {name:"CO",label:"Grand County"},
            {name:"CO",label:"Gunnison County"},
            {name:"CO",label:"Hinsdale County"},
            {name:"CO",label:"Huerfano County"},
            {name:"CO",label:"Jackson County"},
            {name:"CO",label:"Jefferson County"},
            {name:"CO",label:"Kiowa County"},
            {name:"CO",label:"Kit Carson County"},
            {name:"CO",label:"Lake County"},
            {name:"CO",label:"La Plata County"},
            {name:"CO",label:"Larimer County"},
            {name:"CO",label:"Las Animas County"},
            {name:"CO",label:"Lincoln County"},
            {name:"CO",label:"Logan County"},
            {name:"CO",label:"Mesa County"},
            {name:"CO",label:"Mineral County"},
            {name:"CO",label:"Moffat County"},
            {name:"CO",label:"Montezuma County"},
            {name:"CO",label:"Montrose County"},
            {name:"CO",label:"Morgan County"},
            {name:"CO",label:"Otero County"},
            {name:"CO",label:"Ouray County"},
            {name:"CO",label:"Park County"},
            {name:"CO",label:"Phillips County"},
            {name:"CO",label:"Pitkin County"},
            {name:"CO",label:"Prowers County"},
            {name:"CO",label:"Pueblo County"},
            {name:"CO",label:"Rio Blanco County"},
            {name:"CO",label:"Rio Grande County"},
            {name:"CO",label:"Routt County"},
            {name:"CO",label:"Saguache County"},
            {name:"CO",label:"San Juan County"},
            {name:"CO",label:"San Miguel County"},
            {name:"CO",label:"Sedgwick County"},
            {name:"CO",label:"Summit County"},
            {name:"CO",label:"Teller County"},
            {name:"CO",label:"Washington County"},
            {name:"CO",label:"Weld County"},
            {name:"CO",label:"Yuma County"},], "CT" : [
            {name:"CT",label:"Fairfield County"},
            {name:"CT",label:"Hartford County"},
            {name:"CT",label:"Litchfield County"},
            {name:"CT",label:"Middlesex County"},
            {name:"CT",label:"New Haven County"},
            {name:"CT",label:"New London County"},
            {name:"CT",label:"Tolland County"},
            {name:"CT",label:"Windham County"},], "DE" :[
            {name:"DE",label:"Kent County"},
            {name:"DE",label:"New Castle County"},
            {name:"DE",label:"Sussex County"}], "DC" : [
            {name:"DC",label:"District of Columbia"}], "FL" : [
            {name:"FL",label:"Alachua County"},
            {name:"FL",label:"Baker County"},
            {name:"FL",label:"Bay County"},
            {name:"FL",label:"Bradford County"},
            {name:"FL",label:"Brevard County"},
            {name:"FL",label:"Broward County"},
            {name:"FL",label:"Calhoun County"},
            {name:"FL",label:"Charlotte County"},
            {name:"FL",label:"Citrus County"},
            {name:"FL",label:"Clay County"},
            {name:"FL",label:"Collier County"},
            {name:"FL",label:"Columbia County"},
            {name:"FL",label:"DeSoto County"},
            {name:"FL",label:"Dixie County"},
            {name:"FL",label:"Duval County"},
            {name:"FL",label:"Escambia County"},
            {name:"FL",label:"Flagler County"},
            {name:"FL",label:"Franklin County"},
            {name:"FL",label:"Gadsden County"},
            {name:"FL",label:"Gilchrist County"},
            {name:"FL",label:"Glades County"},
            {name:"FL",label:"Gulf County"},
            {name:"FL",label:"Hamilton County"},
            {name:"FL",label:"Hardee County"},
            {name:"FL",label:"Hendry County"},
            {name:"FL",label:"Hernando County"},
            {name:"FL",label:"Highlands County"},
            {name:"FL",label:"Hillsborough County"},
            {name:"FL",label:"Holmes County"},
            {name:"FL",label:"Indian River County"},
            {name:"FL",label:"Jackson County"},
            {name:"FL",label:"Jefferson County"},
            {name:"FL",label:"Lafayette County"},
            {name:"FL",label:"Lake County"},
            {name:"FL",label:"Lee County"},
            {name:"FL",label:"Leon County"},
            {name:"FL",label:"Levy County"},
            {name:"FL",label:"Liberty County"},
            {name:"FL",label:"Madison County"},
            {name:"FL",label:"Manatee County"},
            {name:"FL",label:"Marion County"},
            {name:"FL",label:"Martin County"},
            {name:"FL",label:"Miami-Dade County"},
            {name:"FL",label:"Monroe County"},
            {name:"FL",label:"Nassau County"},
            {name:"FL",label:"Okaloosa County"},
            {name:"FL",label:"Okeechobee County"},
            {name:"FL",label:"Orange County"},
            {name:"FL",label:"Osceola County"},
            {name:"FL",label:"Palm Beach County"},
            {name:"FL",label:"Pasco County"},
            {name:"FL",label:"Pinellas County"},
            {name:"FL",label:"Polk County"},
            {name:"FL",label:"Putnam County"},
            {name:"FL",label:"St. Johns County"},
            {name:"FL",label:"St. Lucie County"},
            {name:"FL",label:"Santa Rosa County"},
            {name:"FL",label:"Sarasota County"},
            {name:"FL",label:"Seminole County"},
            {name:"FL",label:"Sumter County"},
            {name:"FL",label:"Suwannee County"},
            {name:"FL",label:"Taylor County"},
            {name:"FL",label:"Union County"},
            {name:"FL",label:"Volusia County"},
            {name:"FL",label:"Wakulla County"},
            {name:"FL",label:"Walton County"},
            {name:"FL",label:"Washington County"}], "GA" : [
            {name:"GA",label:"Appling County"},
            {name:"GA",label:"Atkinson County"},
            {name:"GA",label:"Bacon County"},
            {name:"GA",label:"Baker County"},
            {name:"GA",label:"Baldwin County"},
            {name:"GA",label:"Banks County"},
            {name:"GA",label:"Barrow County"},
            {name:"GA",label:"Bartow County"},
            {name:"GA",label:"Ben Hill County"},
            {name:"GA",label:"Berrien County"},
            {name:"GA",label:"Bibb County"},
            {name:"GA",label:"Bleckley County"},
            {name:"GA",label:"Brantley County"},
            {name:"GA",label:"Brooks County"},
            {name:"GA",label:"Bryan County"},
            {name:"GA",label:"Bulloch County"},
            {name:"GA",label:"Burke County"},
            {name:"GA",label:"Butts County"},
            {name:"GA",label:"Calhoun County"},
            {name:"GA",label:"Camden County"},
            {name:"GA",label:"Candler County"},
            {name:"GA",label:"Carroll County"},
            {name:"GA",label:"Catoosa County"},
            {name:"GA",label:"Charlton County"},
            {name:"GA",label:"Chatham County"},
            {name:"GA",label:"Chattahoochee County"},
            {name:"GA",label:"Chattooga County"},
            {name:"GA",label:"Cherokee County"},
            {name:"GA",label:"Clarke County"},
            {name:"GA",label:"Clay County"},
            {name:"GA",label:"Clayton County"},
            {name:"GA",label:"Clinch County"},
            {name:"GA",label:"Cobb County"},
            {name:"GA",label:"Coffee County"},
            {name:"GA",label:"Colquitt County"},
            {name:"GA",label:"Columbia County"},
            {name:"GA",label:"Cook County"},
            {name:"GA",label:"Coweta County"},
            {name:"GA",label:"Crawford County"},
            {name:"GA",label:"Crisp County"},
            {name:"GA",label:"Dade County"},
            {name:"GA",label:"Dawson County"},
            {name:"GA",label:"Decatur County"},
            {name:"GA",label:"DeKalb County"},
            {name:"GA",label:"Dodge County"},
            {name:"GA",label:"Dooly County"},
            {name:"GA",label:"Dougherty County"},
            {name:"GA",label:"Douglas County"},
            {name:"GA",label:"Early County"},
            {name:"GA",label:"Echols County"},
            {name:"GA",label:"Effingham County"},
            {name:"GA",label:"Elbert County"},
            {name:"GA",label:"Emanuel County"},
            {name:"GA",label:"Evans County"},
            {name:"GA",label:"Fannin County"},
            {name:"GA",label:"Fayette County"},
            {name:"GA",label:"Floyd County"},
            {name:"GA",label:"Forsyth County"},
            {name:"GA",label:"Franklin County"},
            {name:"GA",label:"Fulton County"},
            {name:"GA",label:"Gilmer County"},
            {name:"GA",label:"Glascock County"},
            {name:"GA",label:"Glynn County"},
            {name:"GA",label:"Gordon County"},
            {name:"GA",label:"Grady County"},
            {name:"GA",label:"Greene County"},
            {name:"GA",label:"Gwinnett County"},
            {name:"GA",label:"Habersham County"},
            {name:"GA",label:"Hall County"},
            {name:"GA",label:"Hancock County"},
            {name:"GA",label:"Haralson County"},
            {name:"GA",label:"Harris County"},
            {name:"GA",label:"Hart County"},
            {name:"GA",label:"Heard County"},
            {name:"GA",label:"Henry County"},
            {name:"GA",label:"Houston County"},
            {name:"GA",label:"Irwin County"},
            {name:"GA",label:"Jackson County"},
            {name:"GA",label:"Jasper County"},
            {name:"GA",label:"Jeff Davis County"},
            {name:"GA",label:"Jefferson County"},
            {name:"GA",label:"Jenkins County"},
            {name:"GA",label:"Johnson County"},
            {name:"GA",label:"Jones County"},
            {name:"GA",label:"Lamar County"},
            {name:"GA",label:"Lanier County"},
            {name:"GA",label:"Laurens County"},
            {name:"GA",label:"Lee County"},
            {name:"GA",label:"Liberty County"},
            {name:"GA",label:"Lincoln County"},
            {name:"GA",label:"Long County"},
            {name:"GA",label:"Lowndes County"},
            {name:"GA",label:"Lumpkin County"},
            {name:"GA",label:"McDuffie County"},
            {name:"GA",label:"McIntosh County"},
            {name:"GA",label:"Macon County"},
            {name:"GA",label:"Madison County"},
            {name:"GA",label:"Marion County"},
            {name:"GA",label:"Meriwether County"},
            {name:"GA",label:"Miller County"},
            {name:"GA",label:"Mitchell County"},
            {name:"GA",label:"Monroe County"},
            {name:"GA",label:"Montgomery County"},
            {name:"GA",label:"Morgan County"},
            {name:"GA",label:"Murray County"},
            {name:"GA",label:"Muscogee County"},
            {name:"GA",label:"Newton County"},
            {name:"GA",label:"Oconee County"},
            {name:"GA",label:"Oglethorpe County"},
            {name:"GA",label:"Paulding County"},
            {name:"GA",label:"Peach County"},
            {name:"GA",label:"Pickens County"},
            {name:"GA",label:"Pierce County"},
            {name:"GA",label:"Pike County"},
            {name:"GA",label:"Polk County"},
            {name:"GA",label:"Pulaski County"},
            {name:"GA",label:"Putnam County"},
            {name:"GA",label:"Quitman County"},
            {name:"GA",label:"Rabun County"},
            {name:"GA",label:"Randolph County"},
            {name:"GA",label:"Richmond County"},
            {name:"GA",label:"Rockdale County"},
            {name:"GA",label:"Schley County"},
            {name:"GA",label:"Screven County"},
            {name:"GA",label:"Seminole County"},
            {name:"GA",label:"Spalding County"},
            {name:"GA",label:"Stephens County"},
            {name:"GA",label:"Stewart County"},
            {name:"GA",label:"Sumter County"},
            {name:"GA",label:"Talbot County"},
            {name:"GA",label:"Taliaferro County"},
            {name:"GA",label:"Tattnall County"},
            {name:"GA",label:"Taylor County"},
            {name:"GA",label:"Telfair County"},
            {name:"GA",label:"Terrell County"},
            {name:"GA",label:"Thomas County"},
            {name:"GA",label:"Tift County"},
            {name:"GA",label:"Toombs County"},
            {name:"GA",label:"Towns County"},
            {name:"GA",label:"Treutlen County"},
            {name:"GA",label:"Troup County"},
            {name:"GA",label:"Turner County"},
            {name:"GA",label:"Twiggs County"},
            {name:"GA",label:"Union County"},
            {name:"GA",label:"Upson County"},
            {name:"GA",label:"Walker County"},
            {name:"GA",label:"Walton County"},
            {name:"GA",label:"Ware County"},
            {name:"GA",label:"Warren County"},
            {name:"GA",label:"Washington County"},
            {name:"GA",label:"Wayne County"},
            {name:"GA",label:"Webster County"},
            {name:"GA",label:"Wheeler County"},
            {name:"GA",label:"White County"},
            {name:"GA",label:"Whitfield County"},
            {name:"GA",label:"Wilcox County"},
            {name:"GA",label:"Wilkes County"},
            {name:"GA",label:"Wilkinson County"},
            {name:"GA",label:"Worth County"}], "HI" : [
            {name:"HI",label:"Hawaii County"},
            {name:"HI",label:"Honolulu County"},
            {name:"HI",label:"Kalawao County"},
            {name:"HI",label:"Kauai County"},
            {name:"HI",label:"Maui County"}], "ID" : [
            {name:"ID",label:"Ada County"},
            {name:"ID",label:"Adams County"},
            {name:"ID",label:"Bannock County"},
            {name:"ID",label:"Bear Lake County"},
            {name:"ID",label:"Benewah County"},
            {name:"ID",label:"Bingham County"},
            {name:"ID",label:"Blaine County"},
            {name:"ID",label:"Boise County"},
            {name:"ID",label:"Bonner County"},
            {name:"ID",label:"Bonneville County"},
            {name:"ID",label:"Boundary County"},
            {name:"ID",label:"Butte County"},
            {name:"ID",label:"Camas County"},
            {name:"ID",label:"Canyon County"},
            {name:"ID",label:"Caribou County"},
            {name:"ID",label:"Cassia County"},
            {name:"ID",label:"Clark County"},
            {name:"ID",label:"Clearwater County"},
            {name:"ID",label:"Custer County"},
            {name:"ID",label:"Elmore County"},
            {name:"ID",label:"Franklin County"},
            {name:"ID",label:"Fremont County"},
            {name:"ID",label:"Gem County"},
            {name:"ID",label:"Gooding County"},
            {name:"ID",label:"Idaho County"},
            {name:"ID",label:"Jefferson County"},
            {name:"ID",label:"Jerome County"},
            {name:"ID",label:"Kootenai County"},
            {name:"ID",label:"Latah County"},
            {name:"ID",label:"Lemhi County"},
            {name:"ID",label:"Lewis County"},
            {name:"ID",label:"Lincoln County"},
            {name:"ID",label:"Madison County"},
            {name:"ID",label:"Minidoka County"},
            {name:"ID",label:"Nez Perce County"},
            {name:"ID",label:"Oneida County"},
            {name:"ID",label:"Owyhee County"},
            {name:"ID",label:"Payette County"},
            {name:"ID",label:"Power County"},
            {name:"ID",label:"Shoshone County"},
            {name:"ID",label:"Teton County"},
            {name:"ID",label:"Twin Falls County"},
            {name:"ID",label:"Valley County"},
            {name:"ID",label:"Washington County"}], "IL" :[
            {name:"IL",label:"Adams County"},
            {name:"IL",label:"Alexander County"},
            {name:"IL",label:"Bond County"},
            {name:"IL",label:"Boone County"},
            {name:"IL",label:"Brown County"},
            {name:"IL",label:"Bureau County"},
            {name:"IL",label:"Calhoun County"},
            {name:"IL",label:"Carroll County"},
            {name:"IL",label:"Cass County"},
            {name:"IL",label:"Champaign County"},
            {name:"IL",label:"Christian County"},
            {name:"IL",label:"Clark County"},
            {name:"IL",label:"Clay County"},
            {name:"IL",label:"Clinton County"},
            {name:"IL",label:"Coles County"},
            {name:"IL",label:"Cook County"},
            {name:"IL",label:"Crawford County"},
            {name:"IL",label:"Cumberland County"},
            {name:"IL",label:"DeKalb County"},
            {name:"IL",label:"De Witt County"},
            {name:"IL",label:"Douglas County"},
            {name:"IL",label:"DuPage County"},
            {name:"IL",label:"Edgar County"},
            {name:"IL",label:"Edwards County"},
            {name:"IL",label:"Effingham County"},
            {name:"IL",label:"Fayette County"},
            {name:"IL",label:"Ford County"},
            {name:"IL",label:"Franklin County"},
            {name:"IL",label:"Fulton County"},
            {name:"IL",label:"Gallatin County"},
            {name:"IL",label:"Greene County"},
            {name:"IL",label:"Grundy County"},
            {name:"IL",label:"Hamilton County"},
            {name:"IL",label:"Hancock County"},
            {name:"IL",label:"Hardin County"},
            {name:"IL",label:"Henderson County"},
            {name:"IL",label:"Henry County"},
            {name:"IL",label:"Iroquois County"},
            {name:"IL",label:"Jackson County"},
            {name:"IL",label:"Jasper County"},
            {name:"IL",label:"Jefferson County"},
            {name:"IL",label:"Jersey County"},
            {name:"IL",label:"Jo Daviess County"},
            {name:"IL",label:"Johnson County"},
            {name:"IL",label:"Kane County"},
            {name:"IL",label:"Kankakee County"},
            {name:"IL",label:"Kendall County"},
            {name:"IL",label:"Knox County"},
            {name:"IL",label:"Lake County"},
            {name:"IL",label:"LaSalle County"},
            {name:"IL",label:"Lawrence County"},
            {name:"IL",label:"Lee County"},
            {name:"IL",label:"Livingston County"},
            {name:"IL",label:"Logan County"},
            {name:"IL",label:"McDonough County"},
            {name:"IL",label:"McHenry County"},
            {name:"IL",label:"McLean County"},
            {name:"IL",label:"Macon County"},
            {name:"IL",label:"Macoupin County"},
            {name:"IL",label:"Madison County"},
            {name:"IL",label:"Marion County"},
            {name:"IL",label:"Marshall County"},
            {name:"IL",label:"Mason County"},
            {name:"IL",label:"Massac County"},
            {name:"IL",label:"Menard County"},
            {name:"IL",label:"Mercer County"},
            {name:"IL",label:"Monroe County"},
            {name:"IL",label:"Montgomery County"},
            {name:"IL",label:"Morgan County"},
            {name:"IL",label:"Moultrie County"},
            {name:"IL",label:"Ogle County"},
            {name:"IL",label:"Peoria County"},
            {name:"IL",label:"Perry County"},
            {name:"IL",label:"Piatt County"},
            {name:"IL",label:"Pike County"},
            {name:"IL",label:"Pope County"},
            {name:"IL",label:"Pulaski County"},
            {name:"IL",label:"Putnam County"},
            {name:"IL",label:"Randolph County"},
            {name:"IL",label:"Richland County"},
            {name:"IL",label:"Rock Island County"},
            {name:"IL",label:"St. Clair County"},
            {name:"IL",label:"Saline County"},
            {name:"IL",label:"Sangamon County"},
            {name:"IL",label:"Schuyler County"},
            {name:"IL",label:"Scott County"},
            {name:"IL",label:"Shelby County"},
            {name:"IL",label:"Stark County"},
            {name:"IL",label:"Stephenson County"},
            {name:"IL",label:"Tazewell County"},
            {name:"IL",label:"Union County"},
            {name:"IL",label:"Vermilion County"},
            {name:"IL",label:"Wabash County"},
            {name:"IL",label:"Warren County"},
            {name:"IL",label:"Washington County"},
            {name:"IL",label:"Wayne County"},
            {name:"IL",label:"White County"},
            {name:"IL",label:"Whiteside County"},
            {name:"IL",label:"Will County"},
            {name:"IL",label:"Williamson County"},
            {name:"IL",label:"Winnebago County"},
            {name:"IL",label:"Woodford County"}], "IN" :[
            {name:"IN",label:"Adams County"},
            {name:"IN",label:"Allen County"},
            {name:"IN",label:"Bartholomew County"},
            {name:"IN",label:"Benton County"},
            {name:"IN",label:"Blackford County"},
            {name:"IN",label:"Boone County"},
            {name:"IN",label:"Brown County"},
            {name:"IN",label:"Carroll County"},
            {name:"IN",label:"Cass County"},
            {name:"IN",label:"Clark County"},
            {name:"IN",label:"Clay County"},
            {name:"IN",label:"Clinton County"},
            {name:"IN",label:"Crawford County"},
            {name:"IN",label:"Daviess County"},
            {name:"IN",label:"Dearborn County"},
            {name:"IN",label:"Decatur County"},
            {name:"IN",label:"DeKalb County"},
            {name:"IN",label:"Delaware County"},
            {name:"IN",label:"Dubois County"},
            {name:"IN",label:"Elkhart County"},
            {name:"IN",label:"Fayette County"},
            {name:"IN",label:"Floyd County"},
            {name:"IN",label:"Fountain County"},
            {name:"IN",label:"Franklin County"},
            {name:"IN",label:"Fulton County"},
            {name:"IN",label:"Gibson County"},
            {name:"IN",label:"Grant County"},
            {name:"IN",label:"Greene County"},
            {name:"IN",label:"Hamilton County"},
            {name:"IN",label:"Hancock County"},
            {name:"IN",label:"Harrison County"},
            {name:"IN",label:"Hendricks County"},
            {name:"IN",label:"Henry County"},
            {name:"IN",label:"Howard County"},
            {name:"IN",label:"Huntington County"},
            {name:"IN",label:"Jackson County"},
            {name:"IN",label:"Jasper County"},
            {name:"IN",label:"Jay County"},
            {name:"IN",label:"Jefferson County"},
            {name:"IN",label:"Jennings County"},
            {name:"IN",label:"Johnson County"},
            {name:"IN",label:"Knox County"},
            {name:"IN",label:"Kosciusko County"},
            {name:"IN",label:"LaGrange County"},
            {name:"IN",label:"Lake County"},
            {name:"IN",label:"LaPorte County"},
            {name:"IN",label:"Lawrence County"},
            {name:"IN",label:"Madison County"},
            {name:"IN",label:"Marion County"},
            {name:"IN",label:"Marshall County"},
            {name:"IN",label:"Martin County"},
            {name:"IN",label:"Miami County"},
            {name:"IN",label:"Monroe County"},
            {name:"IN",label:"Montgomery County"},
            {name:"IN",label:"Morgan County"},
            {name:"IN",label:"Newton County"},
            {name:"IN",label:"Noble County"},
            {name:"IN",label:"Ohio County"},
            {name:"IN",label:"Orange County"},
            {name:"IN",label:"Owen County"},
            {name:"IN",label:"Parke County"},
            {name:"IN",label:"Perry County"},
            {name:"IN",label:"Pike County"},
            {name:"IN",label:"Porter County"},
            {name:"IN",label:"Posey County"},
            {name:"IN",label:"Pulaski County"},
            {name:"IN",label:"Putnam County"},
            {name:"IN",label:"Randolph County"},
            {name:"IN",label:"Ripley County"},
            {name:"IN",label:"Rush County"},
            {name:"IN",label:"St. Joseph County"},
            {name:"IN",label:"Scott County"},
            {name:"IN",label:"Shelby County"},
            {name:"IN",label:"Spencer County"},
            {name:"IN",label:"Starke County"},
            {name:"IN",label:"Steuben County"},
            {name:"IN",label:"Sullivan County"},
            {name:"IN",label:"Switzerland County"},
            {name:"IN",label:"Tippecanoe County"},
            {name:"IN",label:"Tipton County"},
            {name:"IN",label:"Union County"},
            {name:"IN",label:"Vanderburgh County"},
            {name:"IN",label:"Vermillion County"},
            {name:"IN",label:"Vigo County"},
            {name:"IN",label:"Wabash County"},
            {name:"IN",label:"Warren County"},
            {name:"IN",label:"Warrick County"},
            {name:"IN",label:"Washington County"},
            {name:"IN",label:"Wayne County"},
            {name:"IN",label:"Wells County"},
            {name:"IN",label:"White County"},
            {name:"IN",label:"Whitley County"}], "IA" : [
            {name:"IA",label:"Adair County"},
            {name:"IA",label:"Adams County"},
            {name:"IA",label:"Allamakee County"},
            {name:"IA",label:"Appanoose County"},
            {name:"IA",label:"Audubon County"},
            {name:"IA",label:"Benton County"},
            {name:"IA",label:"Black Hawk County"},
            {name:"IA",label:"Boone County"},
            {name:"IA",label:"Bremer County"},
            {name:"IA",label:"Buchanan County"},
            {name:"IA",label:"Buena Vista County"},
            {name:"IA",label:"Butler County"},
            {name:"IA",label:"Calhoun County"},
            {name:"IA",label:"Carroll County"},
            {name:"IA",label:"Cass County"},
            {name:"IA",label:"Cedar County"},
            {name:"IA",label:"Cerro Gordo County"},
            {name:"IA",label:"Cherokee County"},
            {name:"IA",label:"Chickasaw County"},
            {name:"IA",label:"Clarke County"},
            {name:"IA",label:"Clay County"},
            {name:"IA",label:"Clayton County"},
            {name:"IA",label:"Clinton County"},
            {name:"IA",label:"Crawford County"},
            {name:"IA",label:"Dallas County"},
            {name:"IA",label:"Davis County"},
            {name:"IA",label:"Decatur County"},
            {name:"IA",label:"Delaware County"},
            {name:"IA",label:"Des Moines County"},
            {name:"IA",label:"Dickinson County"},
            {name:"IA",label:"Dubuque County"},
            {name:"IA",label:"Emmet County"},
            {name:"IA",label:"Fayette County"},
            {name:"IA",label:"Floyd County"},
            {name:"IA",label:"Franklin County"},
            {name:"IA",label:"Fremont County"},
            {name:"IA",label:"Greene County"},
            {name:"IA",label:"Grundy County"},
            {name:"IA",label:"Guthrie County"},
            {name:"IA",label:"Hamilton County"},
            {name:"IA",label:"Hancock County"},
            {name:"IA",label:"Hardin County"},
            {name:"IA",label:"Harrison County"},
            {name:"IA",label:"Henry County"},
            {name:"IA",label:"Howard County"},
            {name:"IA",label:"Humboldt County"},
            {name:"IA",label:"Ida County"},
            {name:"IA",label:"Iowa County"},
            {name:"IA",label:"Jackson County"},
            {name:"IA",label:"Jasper County"},
            {name:"IA",label:"Jefferson County"},
            {name:"IA",label:"Johnson County"},
            {name:"IA",label:"Jones County"},
            {name:"IA",label:"Keokuk County"},
            {name:"IA",label:"Kossuth County"},
            {name:"IA",label:"Lee County"},
            {name:"IA",label:"Linn County"},
            {name:"IA",label:"Louisa County"},
            {name:"IA",label:"Lucas County"},
            {name:"IA",label:"Lyon County"},
            {name:"IA",label:"Madison County"},
            {name:"IA",label:"Mahaska County"},
            {name:"IA",label:"Marion County"},
            {name:"IA",label:"Marshall County"},
            {name:"IA",label:"Mills County"},
            {name:"IA",label:"Mitchell County"},
            {name:"IA",label:"Monona County"},
            {name:"IA",label:"Monroe County"},
            {name:"IA",label:"Montgomery County"},
            {name:"IA",label:"Muscatine County"},
            {name:"IA",label:"O'Brien County"},
            {name:"IA",label:"Osceola County"},
            {name:"IA",label:"Page County"},
            {name:"IA",label:"Palo Alto County"},
            {name:"IA",label:"Plymouth County"},
            {name:"IA",label:"Pocahontas County"},
            {name:"IA",label:"Polk County"},
            {name:"IA",label:"Pottawattamie County"},
            {name:"IA",label:"Poweshiek County"},
            {name:"IA",label:"Ringgold County"},
            {name:"IA",label:"Sac County"},
            {name:"IA",label:"Scott County"},
            {name:"IA",label:"Shelby County"},
            {name:"IA",label:"Sioux County"},
            {name:"IA",label:"Story County"},
            {name:"IA",label:"Tama County"},
            {name:"IA",label:"Taylor County"},
            {name:"IA",label:"Union County"},
            {name:"IA",label:"Van Buren County"},
            {name:"IA",label:"Wapello County"},
            {name:"IA",label:"Warren County"},
            {name:"IA",label:"Washington County"},
            {name:"IA",label:"Wayne County"},
            {name:"IA",label:"Webster County"},
            {name:"IA",label:"Winnebago County"},
            {name:"IA",label:"Winneshiek County"},
            {name:"IA",label:"Woodbury County"},
            {name:"IA",label:"Worth County"},
            {name:"IA",label:"Wright County"}], "KS" :[
            {name:"KS",label:"Allen County"},
            {name:"KS",label:"Anderson County"},
            {name:"KS",label:"Atchison County"},
            {name:"KS",label:"Barber County"},
            {name:"KS",label:"Barton County"},
            {name:"KS",label:"Bourbon County"},
            {name:"KS",label:"Brown County"},
            {name:"KS",label:"Butler County"},
            {name:"KS",label:"Chase County"},
            {name:"KS",label:"Chautauqua County"},
            {name:"KS",label:"Cherokee County"},
            {name:"KS",label:"Cheyenne County"},
            {name:"KS",label:"Clark County"},
            {name:"KS",label:"Clay County"},
            {name:"KS",label:"Cloud County"},
            {name:"KS",label:"Coffey County"},
            {name:"KS",label:"Comanche County"},
            {name:"KS",label:"Cowley County"},
            {name:"KS",label:"Crawford County"},
            {name:"KS",label:"Decatur County"},
            {name:"KS",label:"Dickinson County"},
            {name:"KS",label:"Doniphan County"},
            {name:"KS",label:"Douglas County"},
            {name:"KS",label:"Edwards County"},
            {name:"KS",label:"Elk County"},
            {name:"KS",label:"Ellis County"},
            {name:"KS",label:"Ellsworth County"},
            {name:"KS",label:"Finney County"},
            {name:"KS",label:"Ford County"},
            {name:"KS",label:"Franklin County"},
            {name:"KS",label:"Geary County"},
            {name:"KS",label:"Gove County"},
            {name:"KS",label:"Graham County"},
            {name:"KS",label:"Grant County"},
            {name:"KS",label:"Gray County"},
            {name:"KS",label:"Greeley County"},
            {name:"KS",label:"Greenwood County"},
            {name:"KS",label:"Hamilton County"},
            {name:"KS",label:"Harper County"},
            {name:"KS",label:"Harvey County"},
            {name:"KS",label:"Haskell County"},
            {name:"KS",label:"Hodgeman County"},
            {name:"KS",label:"Jackson County"},
            {name:"KS",label:"Jefferson County"},
            {name:"KS",label:"Jewell County"},
            {name:"KS",label:"Johnson County"},
            {name:"KS",label:"Kearny County"},
            {name:"KS",label:"Kingman County"},
            {name:"KS",label:"Kiowa County"},
            {name:"KS",label:"Labette County"},
            {name:"KS",label:"Lane County"},
            {name:"KS",label:"Leavenworth County"},
            {name:"KS",label:"Lincoln County"},
            {name:"KS",label:"Linn County"},
            {name:"KS",label:"Logan County"},
            {name:"KS",label:"Lyon County"},
            {name:"KS",label:"McPherson County"},
            {name:"KS",label:"Marion County"},
            {name:"KS",label:"Marshall County"},
            {name:"KS",label:"Meade County"},
            {name:"KS",label:"Miami County"},
            {name:"KS",label:"Mitchell County"},
            {name:"KS",label:"Montgomery County"},
            {name:"KS",label:"Morris County"},
            {name:"KS",label:"Morton County"},
            {name:"KS",label:"Nemaha County"},
            {name:"KS",label:"Neosho County"},
            {name:"KS",label:"Ness County"},
            {name:"KS",label:"Norton County"},
            {name:"KS",label:"Osage County"},
            {name:"KS",label:"Osborne County"},
            {name:"KS",label:"Ottawa County"},
            {name:"KS",label:"Pawnee County"},
            {name:"KS",label:"Phillips County"},
            {name:"KS",label:"Pottawatomie County"},
            {name:"KS",label:"Pratt County"},
            {name:"KS",label:"Rawlins County"},
            {name:"KS",label:"Reno County"},
            {name:"KS",label:"Republic County"},
            {name:"KS",label:"Rice County"},
            {name:"KS",label:"Riley County"},
            {name:"KS",label:"Rooks County"},
            {name:"KS",label:"Rush County"},
            {name:"KS",label:"Russell County"},
            {name:"KS",label:"Saline County"},
            {name:"KS",label:"Scott County"},
            {name:"KS",label:"Sedgwick County"},
            {name:"KS",label:"Seward County"},
            {name:"KS",label:"Shawnee County"},
            {name:"KS",label:"Sheridan County"},
            {name:"KS",label:"Sherman County"},
            {name:"KS",label:"Smith County"},
            {name:"KS",label:"Stafford County"},
            {name:"KS",label:"Stanton County"},
            {name:"KS",label:"Stevens County"},
            {name:"KS",label:"Sumner County"},
            {name:"KS",label:"Thomas County"},
            {name:"KS",label:"Trego County"},
            {name:"KS",label:"Wabaunsee County"},
            {name:"KS",label:"Wallace County"},
            {name:"KS",label:"Washington County"},
            {name:"KS",label:"Wichita County"},
            {name:"KS",label:"Wilson County"},
            {name:"KS",label:"Woodson County"},
            {name:"KS",label:"Wyandotte County"}], "KY" :[
            {name:"KY",label:"Adair County"},
            {name:"KY",label:"Allen County"},
            {name:"KY",label:"Anderson County"},
            {name:"KY",label:"Ballard County"},
            {name:"KY",label:"Barren County"},
            {name:"KY",label:"Bath County"},
            {name:"KY",label:"Bell County"},
            {name:"KY",label:"Boone County"},
            {name:"KY",label:"Bourbon County"},
            {name:"KY",label:"Boyd County"},
            {name:"KY",label:"Boyle County"},
            {name:"KY",label:"Bracken County"},
            {name:"KY",label:"Breathitt County"},
            {name:"KY",label:"Breckinridge County"},
            {name:"KY",label:"Bullitt County"},
            {name:"KY",label:"Butler County"},
            {name:"KY",label:"Caldwell County"},
            {name:"KY",label:"Calloway County"},
            {name:"KY",label:"Campbell County"},
            {name:"KY",label:"Carlisle County"},
            {name:"KY",label:"Carroll County"},
            {name:"KY",label:"Carter County"},
            {name:"KY",label:"Casey County"},
            {name:"KY",label:"Christian County"},
            {name:"KY",label:"Clark County"},
            {name:"KY",label:"Clay County"},
            {name:"KY",label:"Clinton County"},
            {name:"KY",label:"Crittenden County"},
            {name:"KY",label:"Cumberland County"},
            {name:"KY",label:"Daviess County"},
            {name:"KY",label:"Edmonson County"},
            {name:"KY",label:"Elliott County"},
            {name:"KY",label:"Estill County"},
            {name:"KY",label:"Fayette County"},
            {name:"KY",label:"Fleming County"},
            {name:"KY",label:"Floyd County"},
            {name:"KY",label:"Franklin County"},
            {name:"KY",label:"Fulton County"},
            {name:"KY",label:"Gallatin County"},
            {name:"KY",label:"Garrard County"},
            {name:"KY",label:"Grant County"},
            {name:"KY",label:"Graves County"},
            {name:"KY",label:"Grayson County"},
            {name:"KY",label:"Green County"},
            {name:"KY",label:"Greenup County"},
            {name:"KY",label:"Hancock County"},
            {name:"KY",label:"Hardin County"},
            {name:"KY",label:"Harlan County"},
            {name:"KY",label:"Harrison County"},
            {name:"KY",label:"Hart County"},
            {name:"KY",label:"Henderson County"},
            {name:"KY",label:"Henry County"},
            {name:"KY",label:"Hickman County"},
            {name:"KY",label:"Hopkins County"},
            {name:"KY",label:"Jackson County"},
            {name:"KY",label:"Jefferson County"},
            {name:"KY",label:"Jessamine County"},
            {name:"KY",label:"Johnson County"},
            {name:"KY",label:"Kenton County"},
            {name:"KY",label:"Knott County"},
            {name:"KY",label:"Knox County"},
            {name:"KY",label:"Larue County"},
            {name:"KY",label:"Laurel County"},
            {name:"KY",label:"Lawrence County"},
            {name:"KY",label:"Lee County"},
            {name:"KY",label:"Leslie County"},
            {name:"KY",label:"Letcher County"},
            {name:"KY",label:"Lewis County"},
            {name:"KY",label:"Lincoln County"},
            {name:"KY",label:"Livingston County"},
            {name:"KY",label:"Logan County"},
            {name:"KY",label:"Lyon County"},
            {name:"KY",label:"McCracken County"},
            {name:"KY",label:"McCreary County"},
            {name:"KY",label:"McLean County"},
            {name:"KY",label:"Madison County"},
            {name:"KY",label:"Magoffin County"},
            {name:"KY",label:"Marion County"},
            {name:"KY",label:"Marshall County"},
            {name:"KY",label:"Martin County"},
            {name:"KY",label:"Mason County"},
            {name:"KY",label:"Meade County"},
            {name:"KY",label:"Menifee County"},
            {name:"KY",label:"Mercer County"},
            {name:"KY",label:"Metcalfe County"},
            {name:"KY",label:"Monroe County"},
            {name:"KY",label:"Montgomery County"},
            {name:"KY",label:"Morgan County"},
            {name:"KY",label:"Muhlenberg County"},
            {name:"KY",label:"Nelson County"},
            {name:"KY",label:"Nicholas County"},
            {name:"KY",label:"Ohio County"},
            {name:"KY",label:"Oldham County"},
            {name:"KY",label:"Owen County"},
            {name:"KY",label:"Owsley County"},
            {name:"KY",label:"Pendleton County"},
            {name:"KY",label:"Perry County"},
            {name:"KY",label:"Pike County"},
            {name:"KY",label:"Powell County"},
            {name:"KY",label:"Pulaski County"},
            {name:"KY",label:"Robertson County"},
            {name:"KY",label:"Rockcastle County"},
            {name:"KY",label:"Rowan County"},
            {name:"KY",label:"Russell County"},
            {name:"KY",label:"Scott County"},
            {name:"KY",label:"Shelby County"},
            {name:"KY",label:"Simpson County"},
            {name:"KY",label:"Spencer County"},
            {name:"KY",label:"Taylor County"},
            {name:"KY",label:"Todd County"},
            {name:"KY",label:"Trigg County"},
            {name:"KY",label:"Trimble County"},
            {name:"KY",label:"Union County"},
            {name:"KY",label:"Warren County"},
            {name:"KY",label:"Washington County"},
            {name:"KY",label:"Wayne County"},
            {name:"KY",label:"Webster County"},
            {name:"KY",label:"Whitley County"},
            {name:"KY",label:"Wolfe County"},
            {name:"KY",label:"Woodford County"}], "LA" :[
            {name:"LA",label:"Acadia Parish"},
            {name:"LA",label:"Allen Parish"},
            {name:"LA",label:"Ascension Parish"},
            {name:"LA",label:"Assumption Parish"},
            {name:"LA",label:"Avoyelles Parish"},
            {name:"LA",label:"Beauregard Parish"},
            {name:"LA",label:"Bienville Parish"},
            {name:"LA",label:"Bossier Parish"},
            {name:"LA",label:"Caddo Parish"},
            {name:"LA",label:"Calcasieu Parish"},
            {name:"LA",label:"Caldwell Parish"},
            {name:"LA",label:"Cameron Parish"},
            {name:"LA",label:"Catahoula Parish"},
            {name:"LA",label:"Claiborne Parish"},
            {name:"LA",label:"Concordia Parish"},
            {name:"LA",label:"De Soto Parish"},
            {name:"LA",label:"East Baton Rouge Parish"},
            {name:"LA",label:"East Carroll Parish"},
            {name:"LA",label:"East Feliciana Parish"},
            {name:"LA",label:"Evangeline Parish"},
            {name:"LA",label:"Franklin Parish"},
            {name:"LA",label:"Grant Parish"},
            {name:"LA",label:"Iberia Parish"},
            {name:"LA",label:"Iberville Parish"},
            {name:"LA",label:"Jackson Parish"},
            {name:"LA",label:"Jefferson Parish"},
            {name:"LA",label:"Jefferson Davis Parish"},
            {name:"LA",label:"Lafayette Parish"},
            {name:"LA",label:"Lafourche Parish"},
            {name:"LA",label:"La Salle Parish"},
            {name:"LA",label:"Lincoln Parish"},
            {name:"LA",label:"Livingston Parish"},
            {name:"LA",label:"Madison Parish"},
            {name:"LA",label:"Morehouse Parish"},
            {name:"LA",label:"Natchitoches Parish"},
            {name:"LA",label:"Orleans Parish"},
            {name:"LA",label:"Ouachita Parish"},
            {name:"LA",label:"Plaquemines Parish"},
            {name:"LA",label:"Pointe Coupee Parish"},
            {name:"LA",label:"Rapides Parish"},
            {name:"LA",label:"Red River Parish"},
            {name:"LA",label:"Richland Parish"},
            {name:"LA",label:"Sabine Parish"},
            {name:"LA",label:"St. Bernard Parish"},
            {name:"LA",label:"St. Charles Parish"},
            {name:"LA",label:"St. Helena Parish"},
            {name:"LA",label:"St. James Parish"},
            {name:"LA",label:"St. John the Baptist Parish"},
            {name:"LA",label:"St. Landry Parish"},
            {name:"LA",label:"St. Martin Parish"},
            {name:"LA",label:"St. Mary Parish"},
            {name:"LA",label:"St. Tammany Parish"},
            {name:"LA",label:"Tangipahoa Parish"},
            {name:"LA",label:"Tensas Parish"},
            {name:"LA",label:"Terrebonne Parish"},
            {name:"LA",label:"Union Parish"},
            {name:"LA",label:"Vermilion Parish"},
            {name:"LA",label:"Vernon Parish"},
            {name:"LA",label:"Washington Parish"},
            {name:"LA",label:"Webster Parish"},
            {name:"LA",label:"West Baton Rouge Parish"},
            {name:"LA",label:"West Carroll Parish"},
            {name:"LA",label:"West Feliciana Parish"},
            {name:"LA",label:"Winn Parish"}], "ME" :[
            {name:"ME",label:"Androscoggin County"},
            {name:"ME",label:"Aroostook County"},
            {name:"ME",label:"Cumberland County"},
            {name:"ME",label:"Franklin County"},
            {name:"ME",label:"Hancock County"},
            {name:"ME",label:"Kennebec County"},
            {name:"ME",label:"Knox County"},
            {name:"ME",label:"Lincoln County"},
            {name:"ME",label:"Oxford County"},
            {name:"ME",label:"Penobscot County"},
            {name:"ME",label:"Piscataquis County"},
            {name:"ME",label:"Sagadahoc County"},
            {name:"ME",label:"Somerset County"},
            {name:"ME",label:"Waldo County"},
            {name:"ME",label:"Washington County"},
            {name:"ME",label:"York County"}], "MD":[
            {name:"MD",label:"Allegany County"},
            {name:"MD",label:"Anne Arundel County"},
            {name:"MD",label:"Baltimore County"},
            {name:"MD",label:"Calvert County"},
            {name:"MD",label:"Caroline County"},
            {name:"MD",label:"Carroll County"},
            {name:"MD",label:"Cecil County"},
            {name:"MD",label:"Charles County"},
            {name:"MD",label:"Dorchester County"},
            {name:"MD",label:"Frederick County"},
            {name:"MD",label:"Garrett County"},
            {name:"MD",label:"Harford County"},
            {name:"MD",label:"Howard County"},
            {name:"MD",label:"Kent County"},
            {name:"MD",label:"Montgomery County"},
            {name:"MD",label:"Prince George's County"},
            {name:"MD",label:"Queen Anne's County"},
            {name:"MD",label:"St. Mary's County"},
            {name:"MD",label:"Somerset County"},
            {name:"MD",label:"Talbot County"},
            {name:"MD",label:"Washington County"},
            {name:"MD",label:"Wicomico County"},
            {name:"MD",label:"Worcester County"},
            {name:"MD",label:"Baltimore city"}], "MA" : [
            {name:"MA",label:"Barnstable County"},
            {name:"MA",label:"Berkshire County"},
            {name:"MA",label:"Bristol County"},
            {name:"MA",label:"Dukes County"},
            {name:"MA",label:"Essex County"},
            {name:"MA",label:"Franklin County"},
            {name:"MA",label:"Hampden County"},
            {name:"MA",label:"Hampshire County"},
            {name:"MA",label:"Middlesex County"},
            {name:"MA",label:"Nantucket County"},
            {name:"MA",label:"Norfolk County"},
            {name:"MA",label:"Plymouth County"},
            {name:"MA",label:"Suffolk County"},
            {name:"MA",label:"Worcester County"}], "MI": [
            {name:"MI",label:"Alcona County"},
            {name:"MI",label:"Alger County"},
            {name:"MI",label:"Allegan County"},
            {name:"MI",label:"Alpena County"},
            {name:"MI",label:"Antrim County"},
            {name:"MI",label:"Arenac County"},
            {name:"MI",label:"Baraga County"},
            {name:"MI",label:"Barry County"},
            {name:"MI",label:"Bay County"},
            {name:"MI",label:"Benzie County"},
            {name:"MI",label:"Berrien County"},
            {name:"MI",label:"Branch County"},
            {name:"MI",label:"Calhoun County"},
            {name:"MI",label:"Cass County"},
            {name:"MI",label:"Charlevoix County"},
            {name:"MI",label:"Cheboygan County"},
            {name:"MI",label:"Chippewa County"},
            {name:"MI",label:"Clare County"},
            {name:"MI",label:"Clinton County"},
            {name:"MI",label:"Crawford County"},
            {name:"MI",label:"Delta County"},
            {name:"MI",label:"Dickinson County"},
            {name:"MI",label:"Eaton County"},
            {name:"MI",label:"Emmet County"},
            {name:"MI",label:"Genesee County"},
            {name:"MI",label:"Gladwin County"},
            {name:"MI",label:"Gogebic County"},
            {name:"MI",label:"Grand Traverse County"},
            {name:"MI",label:"Gratiot County"},
            {name:"MI",label:"Hillsdale County"},
            {name:"MI",label:"Houghton County"},
            {name:"MI",label:"Huron County"},
            {name:"MI",label:"Ingham County"},
            {name:"MI",label:"Ionia County"},
            {name:"MI",label:"Iosco County"},
            {name:"MI",label:"Iron County"},
            {name:"MI",label:"Isabella County"},
            {name:"MI",label:"Jackson County"},
            {name:"MI",label:"Kalamazoo County"},
            {name:"MI",label:"Kalkaska County"},
            {name:"MI",label:"Kent County"},
            {name:"MI",label:"Keweenaw County"},
            {name:"MI",label:"Lake County"},
            {name:"MI",label:"Lapeer County"},
            {name:"MI",label:"Leelanau County"},
            {name:"MI",label:"Lenawee County"},
            {name:"MI",label:"Livingston County"},
            {name:"MI",label:"Luce County"},
            {name:"MI",label:"Mackinac County"},
            {name:"MI",label:"Macomb County"},
            {name:"MI",label:"Manistee County"},
            {name:"MI",label:"Marquette County"},
            {name:"MI",label:"Mason County"},
            {name:"MI",label:"Mecosta County"},
            {name:"MI",label:"Menominee County"},
            {name:"MI",label:"Midland County"},
            {name:"MI",label:"Missaukee County"},
            {name:"MI",label:"Monroe County"},
            {name:"MI",label:"Montcalm County"},
            {name:"MI",label:"Montmorency County"},
            {name:"MI",label:"Muskegon County"},
            {name:"MI",label:"Newaygo County"},
            {name:"MI",label:"Oakland County"},
            {name:"MI",label:"Oceana County"},
            {name:"MI",label:"Ogemaw County"},
            {name:"MI",label:"Ontonagon County"},
            {name:"MI",label:"Osceola County"},
            {name:"MI",label:"Oscoda County"},
            {name:"MI",label:"Otsego County"},
            {name:"MI",label:"Ottawa County"},
            {name:"MI",label:"Presque Isle County"},
            {name:"MI",label:"Roscommon County"},
            {name:"MI",label:"Saginaw County"},
            {name:"MI",label:"St. Clair County"},
            {name:"MI",label:"St. Joseph County"},
            {name:"MI",label:"Sanilac County"},
            {name:"MI",label:"Schoolcraft County"},
            {name:"MI",label:"Shiawassee County"},
            {name:"MI",label:"Tuscola County"},
            {name:"MI",label:"Van Buren County"},
            {name:"MI",label:"Washtenaw County"},
            {name:"MI",label:"Wayne County"},
            {name:"MI",label:"Wexford County"}], "MN" : [
            {name:"MN",label:"Aitkin County"},
            {name:"MN",label:"Anoka County"},
            {name:"MN",label:"Becker County"},
            {name:"MN",label:"Beltrami County"},
            {name:"MN",label:"Benton County"},
            {name:"MN",label:"Big Stone County"},
            {name:"MN",label:"Blue Earth County"},
            {name:"MN",label:"Brown County"},
            {name:"MN",label:"Carlton County"},
            {name:"MN",label:"Carver County"},
            {name:"MN",label:"Cass County"},
            {name:"MN",label:"Chippewa County"},
            {name:"MN",label:"Chisago County"},
            {name:"MN",label:"Clay County"},
            {name:"MN",label:"Clearwater County"},
            {name:"MN",label:"Cook County"},
            {name:"MN",label:"Cottonwood County"},
            {name:"MN",label:"Crow Wing County"},
            {name:"MN",label:"Dakota County"},
            {name:"MN",label:"Dodge County"},
            {name:"MN",label:"Douglas County"},
            {name:"MN",label:"Faribault County"},
            {name:"MN",label:"Fillmore County"},
            {name:"MN",label:"Freeborn County"},
            {name:"MN",label:"Goodhue County"},
            {name:"MN",label:"Grant County"},
            {name:"MN",label:"Hennepin County"},
            {name:"MN",label:"Houston County"},
            {name:"MN",label:"Hubbard County"},
            {name:"MN",label:"Isanti County"},
            {name:"MN",label:"Itasca County"},
            {name:"MN",label:"Jackson County"},
            {name:"MN",label:"Kanabec County"},
            {name:"MN",label:"Kandiyohi County"},
            {name:"MN",label:"Kittson County"},
            {name:"MN",label:"Koochiching County"},
            {name:"MN",label:"Lac qui Parle County"},
            {name:"MN",label:"Lake County"},
            {name:"MN",label:"Lake of the Woods County"},
            {name:"MN",label:"Le Sueur County"},
            {name:"MN",label:"Lincoln County"},
            {name:"MN",label:"Lyon County"},
            {name:"MN",label:"McLeod County"},
            {name:"MN",label:"Mahnomen County"},
            {name:"MN",label:"Marshall County"},
            {name:"MN",label:"Martin County"},
            {name:"MN",label:"Meeker County"},
            {name:"MN",label:"Mille Lacs County"},
            {name:"MN",label:"Morrison County"},
            {name:"MN",label:"Mower County"},
            {name:"MN",label:"Murray County"},
            {name:"MN",label:"Nicollet County"},
            {name:"MN",label:"Nobles County"},
            {name:"MN",label:"Norman County"},
            {name:"MN",label:"Olmsted County"},
            {name:"MN",label:"Otter Tail County"},
            {name:"MN",label:"Pennington County"},
            {name:"MN",label:"Pine County"},
            {name:"MN",label:"Pipestone County"},
            {name:"MN",label:"Polk County"},
            {name:"MN",label:"Pope County"},
            {name:"MN",label:"Ramsey County"},
            {name:"MN",label:"Red Lake County"},
            {name:"MN",label:"Redwood County"},
            {name:"MN",label:"Renville County"},
            {name:"MN",label:"Rice County"},
            {name:"MN",label:"Rock County"},
            {name:"MN",label:"Roseau County"},
            {name:"MN",label:"St. Louis County"},
            {name:"MN",label:"Scott County"},
            {name:"MN",label:"Sherburne County"},
            {name:"MN",label:"Sibley County"},
            {name:"MN",label:"Stearns County"},
            {name:"MN",label:"Steele County"},
            {name:"MN",label:"Stevens County"},
            {name:"MN",label:"Swift County"},
            {name:"MN",label:"Todd County"},
            {name:"MN",label:"Traverse County"},
            {name:"MN",label:"Wabasha County"},
            {name:"MN",label:"Wadena County"},
            {name:"MN",label:"Waseca County"},
            {name:"MN",label:"Washington County"},
            {name:"MN",label:"Watonwan County"},
            {name:"MN",label:"Wilkin County"},
            {name:"MN",label:"Winona County"},
            {name:"MN",label:"Wright County"},
            {name:"MN",label:"Yellow Medicine County"}], "MS" :[
            {name:"MS",label:"Adams County"},
            {name:"MS",label:"Alcorn County"},
            {name:"MS",label:"Amite County"},
            {name:"MS",label:"Attala County"},
            {name:"MS",label:"Benton County"},
            {name:"MS",label:"Bolivar County"},
            {name:"MS",label:"Calhoun County"},
            {name:"MS",label:"Carroll County"},
            {name:"MS",label:"Chickasaw County"},
            {name:"MS",label:"Choctaw County"},
            {name:"MS",label:"Claiborne County"},
            {name:"MS",label:"Clarke County"},
            {name:"MS",label:"Clay County"},
            {name:"MS",label:"Coahoma County"},
            {name:"MS",label:"Copiah County"},
            {name:"MS",label:"Covington County"},
            {name:"MS",label:"DeSoto County"},
            {name:"MS",label:"Forrest County"},
            {name:"MS",label:"Franklin County"},
            {name:"MS",label:"George County"},
            {name:"MS",label:"Greene County"},
            {name:"MS",label:"Grenada County"},
            {name:"MS",label:"Hancock County"},
            {name:"MS",label:"Harrison County"},
            {name:"MS",label:"Hinds County"},
            {name:"MS",label:"Holmes County"},
            {name:"MS",label:"Humphreys County"},
            {name:"MS",label:"Issaquena County"},
            {name:"MS",label:"Itawamba County"},
            {name:"MS",label:"Jackson County"},
            {name:"MS",label:"Jasper County"},
            {name:"MS",label:"Jefferson County"},
            {name:"MS",label:"Jefferson Davis County"},
            {name:"MS",label:"Jones County"},
            {name:"MS",label:"Kemper County"},
            {name:"MS",label:"Lafayette County"},
            {name:"MS",label:"Lamar County"},
            {name:"MS",label:"Lauderdale County"},
            {name:"MS",label:"Lawrence County"},
            {name:"MS",label:"Leake County"},
            {name:"MS",label:"Lee County"},
            {name:"MS",label:"Leflore County"},
            {name:"MS",label:"Lincoln County"},
            {name:"MS",label:"Lowndes County"},
            {name:"MS",label:"Madison County"},
            {name:"MS",label:"Marion County"},
            {name:"MS",label:"Marshall County"},
            {name:"MS",label:"Monroe County"},
            {name:"MS",label:"Montgomery County"},
            {name:"MS",label:"Neshoba County"},
            {name:"MS",label:"Newton County"},
            {name:"MS",label:"Noxubee County"},
            {name:"MS",label:"Oktibbeha County"},
            {name:"MS",label:"Panola County"},
            {name:"MS",label:"Pearl River County"},
            {name:"MS",label:"Perry County"},
            {name:"MS",label:"Pike County"},
            {name:"MS",label:"Pontotoc County"},
            {name:"MS",label:"Prentiss County"},
            {name:"MS",label:"Quitman County"},
            {name:"MS",label:"Rankin County"},
            {name:"MS",label:"Scott County"},
            {name:"MS",label:"Sharkey County"},
            {name:"MS",label:"Simpson County"},
            {name:"MS",label:"Smith County"},
            {name:"MS",label:"Stone County"},
            {name:"MS",label:"Sunflower County"},
            {name:"MS",label:"Tallahatchie County"},
            {name:"MS",label:"Tate County"},
            {name:"MS",label:"Tippah County"},
            {name:"MS",label:"Tishomingo County"},
            {name:"MS",label:"Tunica County"},
            {name:"MS",label:"Union County"},
            {name:"MS",label:"Walthall County"},
            {name:"MS",label:"Warren County"},
            {name:"MS",label:"Washington County"},
            {name:"MS",label:"Wayne County"},
            {name:"MS",label:"Webster County"},
            {name:"MS",label:"Wilkinson County"},
            {name:"MS",label:"Winston County"},
            {name:"MS",label:"Yalobusha County"},
            {name:"MS",label:"Yazoo County"}], "MO" : [
            {name:"MO",label:"Adair County"},
            {name:"MO",label:"Andrew County"},
            {name:"MO",label:"Atchison County"},
            {name:"MO",label:"Audrain County"},
            {name:"MO",label:"Barry County"},
            {name:"MO",label:"Barton County"},
            {name:"MO",label:"Bates County"},
            {name:"MO",label:"Benton County"},
            {name:"MO",label:"Bollinger County"},
            {name:"MO",label:"Boone County"},
            {name:"MO",label:"Buchanan County"},
            {name:"MO",label:"Butler County"},
            {name:"MO",label:"Caldwell County"},
            {name:"MO",label:"Callaway County"},
            {name:"MO",label:"Camden County"},
            {name:"MO",label:"Cape Girardeau County"},
            {name:"MO",label:"Carroll County"},
            {name:"MO",label:"Carter County"},
            {name:"MO",label:"Cass County"},
            {name:"MO",label:"Cedar County"},
            {name:"MO",label:"Chariton County"},
            {name:"MO",label:"Christian County"},
            {name:"MO",label:"Clark County"},
            {name:"MO",label:"Clay County"},
            {name:"MO",label:"Clinton County"},
            {name:"MO",label:"Cole County"},
            {name:"MO",label:"Cooper County"},
            {name:"MO",label:"Crawford County"},
            {name:"MO",label:"Dade County"},
            {name:"MO",label:"Dallas County"},
            {name:"MO",label:"Daviess County"},
            {name:"MO",label:"DeKalb County"},
            {name:"MO",label:"Dent County"},
            {name:"MO",label:"Douglas County"},
            {name:"MO",label:"Dunklin County"},
            {name:"MO",label:"Franklin County"},
            {name:"MO",label:"Gasconade County"},
            {name:"MO",label:"Gentry County"},
            {name:"MO",label:"Greene County"},
            {name:"MO",label:"Grundy County"},
            {name:"MO",label:"Harrison County"},
            {name:"MO",label:"Henry County"},
            {name:"MO",label:"Hickory County"},
            {name:"MO",label:"Holt County"},
            {name:"MO",label:"Howard County"},
            {name:"MO",label:"Howell County"},
            {name:"MO",label:"Iron County"},
            {name:"MO",label:"Jackson County"},
            {name:"MO",label:"Jasper County"},
            {name:"MO",label:"Jefferson County"},
            {name:"MO",label:"Johnson County"},
            {name:"MO",label:"Knox County"},
            {name:"MO",label:"Laclede County"},
            {name:"MO",label:"Lafayette County"},
            {name:"MO",label:"Lawrence County"},
            {name:"MO",label:"Lewis County"},
            {name:"MO",label:"Lincoln County"},
            {name:"MO",label:"Linn County"},
            {name:"MO",label:"Livingston County"},
            {name:"MO",label:"McDonald County"},
            {name:"MO",label:"Macon County"},
            {name:"MO",label:"Madison County"},
            {name:"MO",label:"Maries County"},
            {name:"MO",label:"Marion County"},
            {name:"MO",label:"Mercer County"},
            {name:"MO",label:"Miller County"},
            {name:"MO",label:"Mississippi County"},
            {name:"MO",label:"Moniteau County"},
            {name:"MO",label:"Monroe County"},
            {name:"MO",label:"Montgomery County"},
            {name:"MO",label:"Morgan County"},
            {name:"MO",label:"New Madrid County"},
            {name:"MO",label:"Newton County"},
            {name:"MO",label:"Nodaway County"},
            {name:"MO",label:"Oregon County"},
            {name:"MO",label:"Osage County"},
            {name:"MO",label:"Ozark County"},
            {name:"MO",label:"Pemiscot County"},
            {name:"MO",label:"Perry County"},
            {name:"MO",label:"Pettis County"},
            {name:"MO",label:"Phelps County"},
            {name:"MO",label:"Pike County"},
            {name:"MO",label:"Platte County"},
            {name:"MO",label:"Polk County"},
            {name:"MO",label:"Pulaski County"},
            {name:"MO",label:"Putnam County"},
            {name:"MO",label:"Ralls County"},
            {name:"MO",label:"Randolph County"},
            {name:"MO",label:"Ray County"},
            {name:"MO",label:"Reynolds County"},
            {name:"MO",label:"Ripley County"},
            {name:"MO",label:"St. Charles County"},
            {name:"MO",label:"St. Clair County"},
            {name:"MO",label:"Ste. Genevieve County"},
            {name:"MO",label:"St. Francois County"},
            {name:"MO",label:"St. Louis County"},
            {name:"MO",label:"Saline County"},
            {name:"MO",label:"Schuyler County"},
            {name:"MO",label:"Scotland County"},
            {name:"MO",label:"Scott County"},
            {name:"MO",label:"Shannon County"},
            {name:"MO",label:"Shelby County"},
            {name:"MO",label:"Stoddard County"},
            {name:"MO",label:"Stone County"},
            {name:"MO",label:"Sullivan County"},
            {name:"MO",label:"Taney County"},
            {name:"MO",label:"Texas County"},
            {name:"MO",label:"Vernon County"},
            {name:"MO",label:"Warren County"},
            {name:"MO",label:"Washington County"},
            {name:"MO",label:"Wayne County"},
            {name:"MO",label:"Webster County"},
            {name:"MO",label:"Worth County"},
            {name:"MO",label:"Wright County"},
            {name:"MO",label:"St. Louis city"}], "MT" : [
            {name:"MT",label:"Beaverhead County"},
            {name:"MT",label:"Big Horn County"},
            {name:"MT",label:"Blaine County"},
            {name:"MT",label:"Broadwater County"},
            {name:"MT",label:"Carbon County"},
            {name:"MT",label:"Carter County"},
            {name:"MT",label:"Cascade County"},
            {name:"MT",label:"Chouteau County"},
            {name:"MT",label:"Custer County"},
            {name:"MT",label:"Daniels County"},
            {name:"MT",label:"Dawson County"},
            {name:"MT",label:"Deer Lodge County"},
            {name:"MT",label:"Fallon County"},
            {name:"MT",label:"Fergus County"},
            {name:"MT",label:"Flathead County"},
            {name:"MT",label:"Gallatin County"},
            {name:"MT",label:"Garfield County"},
            {name:"MT",label:"Glacier County"},
            {name:"MT",label:"Golden Valley County"},
            {name:"MT",label:"Granite County"},
            {name:"MT",label:"Hill County"},
            {name:"MT",label:"Jefferson County"},
            {name:"MT",label:"Judith Basin County"},
            {name:"MT",label:"Lake County"},
            {name:"MT",label:"Lewis and Clark County"},
            {name:"MT",label:"Liberty County"},
            {name:"MT",label:"Lincoln County"},
            {name:"MT",label:"McCone County"},
            {name:"MT",label:"Madison County"},
            {name:"MT",label:"Meagher County"},
            {name:"MT",label:"Mineral County"},
            {name:"MT",label:"Missoula County"},
            {name:"MT",label:"Musselshell County"},
            {name:"MT",label:"Park County"},
            {name:"MT",label:"Petroleum County"},
            {name:"MT",label:"Phillips County"},
            {name:"MT",label:"Pondera County"},
            {name:"MT",label:"Powder River County"},
            {name:"MT",label:"Powell County"},
            {name:"MT",label:"Prairie County"},
            {name:"MT",label:"Ravalli County"},
            {name:"MT",label:"Richland County"},
            {name:"MT",label:"Roosevelt County"},
            {name:"MT",label:"Rosebud County"},
            {name:"MT",label:"Sanders County"},
            {name:"MT",label:"Sheridan County"},
            {name:"MT",label:"Silver Bow County"},
            {name:"MT",label:"Stillwater County"},
            {name:"MT",label:"Sweet Grass County"},
            {name:"MT",label:"Teton County"},
            {name:"MT",label:"Toole County"},
            {name:"MT",label:"Treasure County"},
            {name:"MT",label:"Valley County"},
            {name:"MT",label:"Wheatland County"},
            {name:"MT",label:"Wibaux County"},
            {name:"MT",label:"Yellowstone County"}], "NE" :[
            {name:"NE",label:"Adams County"},
            {name:"NE",label:"Antelope County"},
            {name:"NE",label:"Arthur County"},
            {name:"NE",label:"Banner County"},
            {name:"NE",label:"Blaine County"},
            {name:"NE",label:"Boone County"},
            {name:"NE",label:"Box Butte County"},
            {name:"NE",label:"Boyd County"},
            {name:"NE",label:"Brown County"},
            {name:"NE",label:"Buffalo County"},
            {name:"NE",label:"Burt County"},
            {name:"NE",label:"Butler County"},
            {name:"NE",label:"Cass County"},
            {name:"NE",label:"Cedar County"},
            {name:"NE",label:"Chase County"},
            {name:"NE",label:"Cherry County"},
            {name:"NE",label:"Cheyenne County"},
            {name:"NE",label:"Clay County"},
            {name:"NE",label:"Colfax County"},
            {name:"NE",label:"Cuming County"},
            {name:"NE",label:"Custer County"},
            {name:"NE",label:"Dakota County"},
            {name:"NE",label:"Dawes County"},
            {name:"NE",label:"Dawson County"},
            {name:"NE",label:"Deuel County"},
            {name:"NE",label:"Dixon County"},
            {name:"NE",label:"Dodge County"},
            {name:"NE",label:"Douglas County"},
            {name:"NE",label:"Dundy County"},
            {name:"NE",label:"Fillmore County"},
            {name:"NE",label:"Franklin County"},
            {name:"NE",label:"Frontier County"},
            {name:"NE",label:"Furnas County"},
            {name:"NE",label:"Gage County"},
            {name:"NE",label:"Garden County"},
            {name:"NE",label:"Garfield County"},
            {name:"NE",label:"Gosper County"},
            {name:"NE",label:"Grant County"},
            {name:"NE",label:"Greeley County"},
            {name:"NE",label:"Hall County"},
            {name:"NE",label:"Hamilton County"},
            {name:"NE",label:"Harlan County"},
            {name:"NE",label:"Hayes County"},
            {name:"NE",label:"Hitchcock County"},
            {name:"NE",label:"Holt County"},
            {name:"NE",label:"Hooker County"},
            {name:"NE",label:"Howard County"},
            {name:"NE",label:"Jefferson County"},
            {name:"NE",label:"Johnson County"},
            {name:"NE",label:"Kearney County"},
            {name:"NE",label:"Keith County"},
            {name:"NE",label:"Keya Paha County"},
            {name:"NE",label:"Kimball County"},
            {name:"NE",label:"Knox County"},
            {name:"NE",label:"Lancaster County"},
            {name:"NE",label:"Lincoln County"},
            {name:"NE",label:"Logan County"},
            {name:"NE",label:"Loup County"},
            {name:"NE",label:"McPherson County"},
            {name:"NE",label:"Madison County"},
            {name:"NE",label:"Merrick County"},
            {name:"NE",label:"Morrill County"},
            {name:"NE",label:"Nance County"},
            {name:"NE",label:"Nemaha County"},
            {name:"NE",label:"Nuckolls County"},
            {name:"NE",label:"Otoe County"},
            {name:"NE",label:"Pawnee County"},
            {name:"NE",label:"Perkins County"},
            {name:"NE",label:"Phelps County"},
            {name:"NE",label:"Pierce County"},
            {name:"NE",label:"Platte County"},
            {name:"NE",label:"Polk County"},
            {name:"NE",label:"Red Willow County"},
            {name:"NE",label:"Richardson County"},
            {name:"NE",label:"Rock County"},
            {name:"NE",label:"Saline County"},
            {name:"NE",label:"Sarpy County"},
            {name:"NE",label:"Saunders County"},
            {name:"NE",label:"Scotts Bluff County"},
            {name:"NE",label:"Seward County"},
            {name:"NE",label:"Sheridan County"},
            {name:"NE",label:"Sherman County"},
            {name:"NE",label:"Sioux County"},
            {name:"NE",label:"Stanton County"},
            {name:"NE",label:"Thayer County"},
            {name:"NE",label:"Thomas County"},
            {name:"NE",label:"Thurston County"},
            {name:"NE",label:"Valley County"},
            {name:"NE",label:"Washington County"},
            {name:"NE",label:"Wayne County"},
            {name:"NE",label:"Webster County"},
            {name:"NE",label:"Wheeler County"},
            {name:"NE",label:"York County"}], "NV" :[
            {name:"NV",label:"Churchill County"},
            {name:"NV",label:"Clark County"},
            {name:"NV",label:"Douglas County"},
            {name:"NV",label:"Elko County"},
            {name:"NV",label:"Esmeralda County"},
            {name:"NV",label:"Eureka County"},
            {name:"NV",label:"Humboldt County"},
            {name:"NV",label:"Lander County"},
            {name:"NV",label:"Lincoln County"},
            {name:"NV",label:"Lyon County"},
            {name:"NV",label:"Mineral County"},
            {name:"NV",label:"Nye County"},
            {name:"NV",label:"Pershing County"},
            {name:"NV",label:"Storey County"},
            {name:"NV",label:"Washoe County"},
            {name:"NV",label:"White Pine County"},
            {name:"NV",label:"Carson City"}], "NH" :[
            {name:"NH",label:"Belknap County"},
            {name:"NH",label:"Carroll County"},
            {name:"NH",label:"Cheshire County"},
            {name:"NH",label:"Coos County"},
            {name:"NH",label:"Grafton County"},
            {name:"NH",label:"Hillsborough County"},
            {name:"NH",label:"Merrimack County"},
            {name:"NH",label:"Rockingham County"},
            {name:"NH",label:"Strafford County"},
            {name:"NH",label:"Sullivan County"}], "NJ" :[
            {name:"NJ",label:"Atlantic County"},
            {name:"NJ",label:"Bergen County"},
            {name:"NJ",label:"Burlington County"},
            {name:"NJ",label:"Camden County"},
            {name:"NJ",label:"Cape May County"},
            {name:"NJ",label:"Cumberland County"},
            {name:"NJ",label:"Essex County"},
            {name:"NJ",label:"Gloucester County"},
            {name:"NJ",label:"Hudson County"},
            {name:"NJ",label:"Hunterdon County"},
            {name:"NJ",label:"Mercer County"},
            {name:"NJ",label:"Middlesex County"},
            {name:"NJ",label:"Monmouth County"},
            {name:"NJ",label:"Morris County"},
            {name:"NJ",label:"Ocean County"},
            {name:"NJ",label:"Passaic County"},
            {name:"NJ",label:"Salem County"},
            {name:"NJ",label:"Somerset County"},
            {name:"NJ",label:"Sussex County"},
            {name:"NJ",label:"Union County"},
            {name:"NJ",label:"Warren County"}], "NM" :[
            {name:"NM",label:"Bernalillo County"},
            {name:"NM",label:"Catron County"},
            {name:"NM",label:"Chaves County"},
            {name:"NM",label:"Cibola County"},
            {name:"NM",label:"Colfax County"},
            {name:"NM",label:"Curry County"},
            {name:"NM",label:"De Baca County"},
            {name:"NM",label:"Dona Ana County"},
            {name:"NM",label:"Eddy County"},
            {name:"NM",label:"Grant County"},
            {name:"NM",label:"Guadalupe County"},
            {name:"NM",label:"Harding County"},
            {name:"NM",label:"Hidalgo County"},
            {name:"NM",label:"Lea County"},
            {name:"NM",label:"Lincoln County"},
            {name:"NM",label:"Los Alamos County"},
            {name:"NM",label:"Luna County"},
            {name:"NM",label:"McKinley County"},
            {name:"NM",label:"Mora County"},
            {name:"NM",label:"Otero County"},
            {name:"NM",label:"Quay County"},
            {name:"NM",label:"Rio Arriba County"},
            {name:"NM",label:"Roosevelt County"},
            {name:"NM",label:"Sandoval County"},
            {name:"NM",label:"San Juan County"},
            {name:"NM",label:"San Miguel County"},
            {name:"NM",label:"Santa Fe County"},
            {name:"NM",label:"Sierra County"},
            {name:"NM",label:"Socorro County"},
            {name:"NM",label:"Taos County"},
            {name:"NM",label:"Torrance County"},
            {name:"NM",label:"Union County"},
            {name:"NM",label:"Valencia County"}], "NY" :[
            {name:"NY",label:"Albany County"},
            {name:"NY",label:"Allegany County"},
            {name:"NY",label:"Bronx County"},
            {name:"NY",label:"Broome County"},
            {name:"NY",label:"Cattaraugus County"},
            {name:"NY",label:"Cayuga County"},
            {name:"NY",label:"Chautauqua County"},
            {name:"NY",label:"Chemung County"},
            {name:"NY",label:"Chenango County"},
            {name:"NY",label:"Clinton County"},
            {name:"NY",label:"Columbia County"},
            {name:"NY",label:"Cortland County"},
            {name:"NY",label:"Delaware County"},
            {name:"NY",label:"Dutchess County"},
            {name:"NY",label:"Erie County"},
            {name:"NY",label:"Essex County"},
            {name:"NY",label:"Franklin County"},
            {name:"NY",label:"Fulton County"},
            {name:"NY",label:"Genesee County"},
            {name:"NY",label:"Greene County"},
            {name:"NY",label:"Hamilton County"},
            {name:"NY",label:"Herkimer County"},
            {name:"NY",label:"Jefferson County"},
            {name:"NY",label:"Kings County"},
            {name:"NY",label:"Lewis County"},
            {name:"NY",label:"Livingston County"},
            {name:"NY",label:"Madison County"},
            {name:"NY",label:"Monroe County"},
            {name:"NY",label:"Montgomery County"},
            {name:"NY",label:"Nassau County"},
            {name:"NY",label:"New York County"},
            {name:"NY",label:"Niagara County"},
            {name:"NY",label:"Oneida County"},
            {name:"NY",label:"Onondaga County"},
            {name:"NY",label:"Ontario County"},
            {name:"NY",label:"Orange County"},
            {name:"NY",label:"Orleans County"},
            {name:"NY",label:"Oswego County"},
            {name:"NY",label:"Otsego County"},
            {name:"NY",label:"Putnam County"},
            {name:"NY",label:"Queens County"},
            {name:"NY",label:"Rensselaer County"},
            {name:"NY",label:"Richmond County"},
            {name:"NY",label:"Rockland County"},
            {name:"NY",label:"St. Lawrence County"},
            {name:"NY",label:"Saratoga County"},
            {name:"NY",label:"Schenectady County"},
            {name:"NY",label:"Schoharie County"},
            {name:"NY",label:"Schuyler County"},
            {name:"NY",label:"Seneca County"},
            {name:"NY",label:"Steuben County"},
            {name:"NY",label:"Suffolk County"},
            {name:"NY",label:"Sullivan County"},
            {name:"NY",label:"Tioga County"},
            {name:"NY",label:"Tompkins County"},
            {name:"NY",label:"Ulster County"},
            {name:"NY",label:"Warren County"},
            {name:"NY",label:"Washington County"},
            {name:"NY",label:"Wayne County"},
            {name:"NY",label:"Westchester County"},
            {name:"NY",label:"Wyoming County"},
            {name:"NY",label:"Yates County"}], "NC" :[
            {name:"NC",label:"Alamance County"},
            {name:"NC",label:"Alexander County"},
            {name:"NC",label:"Alleghany County"},
            {name:"NC",label:"Anson County"},
            {name:"NC",label:"Ashe County"},
            {name:"NC",label:"Avery County"},
            {name:"NC",label:"Beaufort County"},
            {name:"NC",label:"Bertie County"},
            {name:"NC",label:"Bladen County"},
            {name:"NC",label:"Brunswick County"},
            {name:"NC",label:"Buncombe County"},
            {name:"NC",label:"Burke County"},
            {name:"NC",label:"Cabarrus County"},
            {name:"NC",label:"Caldwell County"},
            {name:"NC",label:"Camden County"},
            {name:"NC",label:"Carteret County"},
            {name:"NC",label:"Caswell County"},
            {name:"NC",label:"Catawba County"},
            {name:"NC",label:"Chatham County"},
            {name:"NC",label:"Cherokee County"},
            {name:"NC",label:"Chowan County"},
            {name:"NC",label:"Clay County"},
            {name:"NC",label:"Cleveland County"},
            {name:"NC",label:"Columbus County"},
            {name:"NC",label:"Craven County"},
            {name:"NC",label:"Cumberland County"},
            {name:"NC",label:"Currituck County"},
            {name:"NC",label:"Dare County"},
            {name:"NC",label:"Davidson County"},
            {name:"NC",label:"Davie County"},
            {name:"NC",label:"Duplin County"},
            {name:"NC",label:"Durham County"},
            {name:"NC",label:"Edgecombe County"},
            {name:"NC",label:"Forsyth County"},
            {name:"NC",label:"Franklin County"},
            {name:"NC",label:"Gaston County"},
            {name:"NC",label:"Gates County"},
            {name:"NC",label:"Graham County"},
            {name:"NC",label:"Granville County"},
            {name:"NC",label:"Greene County"},
            {name:"NC",label:"Guilford County"},
            {name:"NC",label:"Halifax County"},
            {name:"NC",label:"Harnett County"},
            {name:"NC",label:"Haywood County"},
            {name:"NC",label:"Henderson County"},
            {name:"NC",label:"Hertford County"},
            {name:"NC",label:"Hoke County"},
            {name:"NC",label:"Hyde County"},
            {name:"NC",label:"Iredell County"},
            {name:"NC",label:"Jackson County"},
            {name:"NC",label:"Johnston County"},
            {name:"NC",label:"Jones County"},
            {name:"NC",label:"Lee County"},
            {name:"NC",label:"Lenoir County"},
            {name:"NC",label:"Lincoln County"},
            {name:"NC",label:"McDowell County"},
            {name:"NC",label:"Macon County"},
            {name:"NC",label:"Madison County"},
            {name:"NC",label:"Martin County"},
            {name:"NC",label:"Mecklenburg County"},
            {name:"NC",label:"Mitchell County"},
            {name:"NC",label:"Montgomery County"},
            {name:"NC",label:"Moore County"},
            {name:"NC",label:"Nash County"},
            {name:"NC",label:"New Hanover County"},
            {name:"NC",label:"Northampton County"},
            {name:"NC",label:"Onslow County"},
            {name:"NC",label:"Orange County"},
            {name:"NC",label:"Pamlico County"},
            {name:"NC",label:"Pasquotank County"},
            {name:"NC",label:"Pender County"},
            {name:"NC",label:"Perquimans County"},
            {name:"NC",label:"Person County"},
            {name:"NC",label:"Pitt County"},
            {name:"NC",label:"Polk County"},
            {name:"NC",label:"Randolph County"},
            {name:"NC",label:"Richmond County"},
            {name:"NC",label:"Robeson County"},
            {name:"NC",label:"Rockingham County"},
            {name:"NC",label:"Rowan County"},
            {name:"NC",label:"Rutherford County"},
            {name:"NC",label:"Sampson County"},
            {name:"NC",label:"Scotland County"},
            {name:"NC",label:"Stanly County"},
            {name:"NC",label:"Stokes County"},
            {name:"NC",label:"Surry County"},
            {name:"NC",label:"Swain County"},
            {name:"NC",label:"Transylvania County"},
            {name:"NC",label:"Tyrrell County"},
            {name:"NC",label:"Union County"},
            {name:"NC",label:"Vance County"},
            {name:"NC",label:"Wake County"},
            {name:"NC",label:"Warren County"},
            {name:"NC",label:"Washington County"},
            {name:"NC",label:"Watauga County"},
            {name:"NC",label:"Wayne County"},
            {name:"NC",label:"Wilkes County"},
            {name:"NC",label:"Wilson County"},
            {name:"NC",label:"Yadkin County"},
            {name:"NC",label:"Yancey County"}], "ND" :[
            {name:"ND",label:"Adams County"},
            {name:"ND",label:"Barnes County"},
            {name:"ND",label:"Benson County"},
            {name:"ND",label:"Billings County"},
            {name:"ND",label:"Bottineau County"},
            {name:"ND",label:"Bowman County"},
            {name:"ND",label:"Burke County"},
            {name:"ND",label:"Burleigh County"},
            {name:"ND",label:"Cass County"},
            {name:"ND",label:"Cavalier County"},
            {name:"ND",label:"Dickey County"},
            {name:"ND",label:"Divide County"},
            {name:"ND",label:"Dunn County"},
            {name:"ND",label:"Eddy County"},
            {name:"ND",label:"Emmons County"},
            {name:"ND",label:"Foster County"},
            {name:"ND",label:"Golden Valley County"},
            {name:"ND",label:"Grand Forks County"},
            {name:"ND",label:"Grant County"},
            {name:"ND",label:"Griggs County"},
            {name:"ND",label:"Hettinger County"},
            {name:"ND",label:"Kidder County"},
            {name:"ND",label:"LaMoure County"},
            {name:"ND",label:"Logan County"},
            {name:"ND",label:"McHenry County"},
            {name:"ND",label:"McIntosh County"},
            {name:"ND",label:"McKenzie County"},
            {name:"ND",label:"McLean County"},
            {name:"ND",label:"Mercer County"},
            {name:"ND",label:"Morton County"},
            {name:"ND",label:"Mountrail County"},
            {name:"ND",label:"Nelson County"},
            {name:"ND",label:"Oliver County"},
            {name:"ND",label:"Pembina County"},
            {name:"ND",label:"Pierce County"},
            {name:"ND",label:"Ramsey County"},
            {name:"ND",label:"Ransom County"},
            {name:"ND",label:"Renville County"},
            {name:"ND",label:"Richland County"},
            {name:"ND",label:"Rolette County"},
            {name:"ND",label:"Sargent County"},
            {name:"ND",label:"Sheridan County"},
            {name:"ND",label:"Sioux County"},
            {name:"ND",label:"Slope County"},
            {name:"ND",label:"Stark County"},
            {name:"ND",label:"Steele County"},
            {name:"ND",label:"Stutsman County"},
            {name:"ND",label:"Towner County"},
            {name:"ND",label:"Traill County"},
            {name:"ND",label:"Walsh County"},
            {name:"ND",label:"Ward County"},
            {name:"ND",label:"Wells County"},
            {name:"ND",label:"Williams County"}], "OH" :[
            {name:"OH",label:"Adams County"},
            {name:"OH",label:"Allen County"},
            {name:"OH",label:"Ashland County"},
            {name:"OH",label:"Ashtabula County"},
            {name:"OH",label:"Athens County"},
            {name:"OH",label:"Auglaize County"},
            {name:"OH",label:"Belmont County"},
            {name:"OH",label:"Brown County"},
            {name:"OH",label:"Butler County"},
            {name:"OH",label:"Carroll County"},
            {name:"OH",label:"Champaign County"},
            {name:"OH",label:"Clark County"},
            {name:"OH",label:"Clermont County"},
            {name:"OH",label:"Clinton County"},
            {name:"OH",label:"Columbiana County"},
            {name:"OH",label:"Coshocton County"},
            {name:"OH",label:"Crawford County"},
            {name:"OH",label:"Cuyahoga County"},
            {name:"OH",label:"Darke County"},
            {name:"OH",label:"Defiance County"},
            {name:"OH",label:"Delaware County"},
            {name:"OH",label:"Erie County"},
            {name:"OH",label:"Fairfield County"},
            {name:"OH",label:"Fayette County"},
            {name:"OH",label:"Franklin County"},
            {name:"OH",label:"Fulton County"},
            {name:"OH",label:"Gallia County"},
            {name:"OH",label:"Geauga County"},
            {name:"OH",label:"Greene County"},
            {name:"OH",label:"Guernsey County"},
            {name:"OH",label:"Hamilton County"},
            {name:"OH",label:"Hancock County"},
            {name:"OH",label:"Hardin County"},
            {name:"OH",label:"Harrison County"},
            {name:"OH",label:"Henry County"},
            {name:"OH",label:"Highland County"},
            {name:"OH",label:"Hocking County"},
            {name:"OH",label:"Holmes County"},
            {name:"OH",label:"Huron County"},
            {name:"OH",label:"Jackson County"},
            {name:"OH",label:"Jefferson County"},
            {name:"OH",label:"Knox County"},
            {name:"OH",label:"Lake County"},
            {name:"OH",label:"Lawrence County"},
            {name:"OH",label:"Licking County"},
            {name:"OH",label:"Logan County"},
            {name:"OH",label:"Lorain County"},
            {name:"OH",label:"Lucas County"},
            {name:"OH",label:"Madison County"},
            {name:"OH",label:"Mahoning County"},
            {name:"OH",label:"Marion County"},
            {name:"OH",label:"Medina County"},
            {name:"OH",label:"Meigs County"},
            {name:"OH",label:"Mercer County"},
            {name:"OH",label:"Miami County"},
            {name:"OH",label:"Monroe County"},
            {name:"OH",label:"Montgomery County"},
            {name:"OH",label:"Morgan County"},
            {name:"OH",label:"Morrow County"},
            {name:"OH",label:"Muskingum County"},
            {name:"OH",label:"Noble County"},
            {name:"OH",label:"Ottawa County"},
            {name:"OH",label:"Paulding County"},
            {name:"OH",label:"Perry County"},
            {name:"OH",label:"Pickaway County"},
            {name:"OH",label:"Pike County"},
            {name:"OH",label:"Portage County"},
            {name:"OH",label:"Preble County"},
            {name:"OH",label:"Putnam County"},
            {name:"OH",label:"Richland County"},
            {name:"OH",label:"Ross County"},
            {name:"OH",label:"Sandusky County"},
            {name:"OH",label:"Scioto County"},
            {name:"OH",label:"Seneca County"},
            {name:"OH",label:"Shelby County"},
            {name:"OH",label:"Stark County"},
            {name:"OH",label:"Summit County"},
            {name:"OH",label:"Trumbull County"},
            {name:"OH",label:"Tuscarawas County"},
            {name:"OH",label:"Union County"},
            {name:"OH",label:"Van Wert County"},
            {name:"OH",label:"Vinton County"},
            {name:"OH",label:"Warren County"},
            {name:"OH",label:"Washington County"},
            {name:"OH",label:"Wayne County"},
            {name:"OH",label:"Williams County"},
            {name:"OH",label:"Wood County"},
            {name:"OH",label:"Wyandot County"}], "OK" :[
            {name:"OK",label:"Adair County"},
            {name:"OK",label:"Alfalfa County"},
            {name:"OK",label:"Atoka County"},
            {name:"OK",label:"Beaver County"},
            {name:"OK",label:"Beckham County"},
            {name:"OK",label:"Blaine County"},
            {name:"OK",label:"Bryan County"},
            {name:"OK",label:"Caddo County"},
            {name:"OK",label:"Canadian County"},
            {name:"OK",label:"Carter County"},
            {name:"OK",label:"Cherokee County"},
            {name:"OK",label:"Choctaw County"},
            {name:"OK",label:"Cimarron County"},
            {name:"OK",label:"Cleveland County"},
            {name:"OK",label:"Coal County"},
            {name:"OK",label:"Comanche County"},
            {name:"OK",label:"Cotton County"},
            {name:"OK",label:"Craig County"},
            {name:"OK",label:"Creek County"},
            {name:"OK",label:"Custer County"},
            {name:"OK",label:"Delaware County"},
            {name:"OK",label:"Dewey County"},
            {name:"OK",label:"Ellis County"},
            {name:"OK",label:"Garfield County"},
            {name:"OK",label:"Garvin County"},
            {name:"OK",label:"Grady County"},
            {name:"OK",label:"Grant County"},
            {name:"OK",label:"Greer County"},
            {name:"OK",label:"Harmon County"},
            {name:"OK",label:"Harper County"},
            {name:"OK",label:"Haskell County"},
            {name:"OK",label:"Hughes County"},
            {name:"OK",label:"Jackson County"},
            {name:"OK",label:"Jefferson County"},
            {name:"OK",label:"Johnston County"},
            {name:"OK",label:"Kay County"},
            {name:"OK",label:"Kingfisher County"},
            {name:"OK",label:"Kiowa County"},
            {name:"OK",label:"Latimer County"},
            {name:"OK",label:"Le Flore County"},
            {name:"OK",label:"Lincoln County"},
            {name:"OK",label:"Logan County"},
            {name:"OK",label:"Love County"},
            {name:"OK",label:"McClain County"},
            {name:"OK",label:"McCurtain County"},
            {name:"OK",label:"McIntosh County"},
            {name:"OK",label:"Major County"},
            {name:"OK",label:"Marshall County"},
            {name:"OK",label:"Mayes County"},
            {name:"OK",label:"Murray County"},
            {name:"OK",label:"Muskogee County"},
            {name:"OK",label:"Noble County"},
            {name:"OK",label:"Nowata County"},
            {name:"OK",label:"Okfuskee County"},
            {name:"OK",label:"Oklahoma County"},
            {name:"OK",label:"Okmulgee County"},
            {name:"OK",label:"Osage County"},
            {name:"OK",label:"Ottawa County"},
            {name:"OK",label:"Pawnee County"},
            {name:"OK",label:"Payne County"},
            {name:"OK",label:"Pittsburg County"},
            {name:"OK",label:"Pontotoc County"},
            {name:"OK",label:"Pottawatomie County"},
            {name:"OK",label:"Pushmataha County"},
            {name:"OK",label:"Roger Mills County"},
            {name:"OK",label:"Rogers County"},
            {name:"OK",label:"Seminole County"},
            {name:"OK",label:"Sequoyah County"},
            {name:"OK",label:"Stephens County"},
            {name:"OK",label:"Texas County"},
            {name:"OK",label:"Tillman County"},
            {name:"OK",label:"Tulsa County"},
            {name:"OK",label:"Wagoner County"},
            {name:"OK",label:"Washington County"},
            {name:"OK",label:"Washita County"},
            {name:"OK",label:"Woods County"},
            {name:"OK",label:"Woodward County"}], "OR" :[
            {name:"OR",label:"Baker County"},
            {name:"OR",label:"Benton County"},
            {name:"OR",label:"Clackamas County"},
            {name:"OR",label:"Clatsop County"},
            {name:"OR",label:"Columbia County"},
            {name:"OR",label:"Coos County"},
            {name:"OR",label:"Crook County"},
            {name:"OR",label:"Curry County"},
            {name:"OR",label:"Deschutes County"},
            {name:"OR",label:"Douglas County"},
            {name:"OR",label:"Gilliam County"},
            {name:"OR",label:"Grant County"},
            {name:"OR",label:"Harney County"},
            {name:"OR",label:"Hood River County"},
            {name:"OR",label:"Jackson County"},
            {name:"OR",label:"Jefferson County"},
            {name:"OR",label:"Josephine County"},
            {name:"OR",label:"Klamath County"},
            {name:"OR",label:"Lake County"},
            {name:"OR",label:"Lane County"},
            {name:"OR",label:"Lincoln County"},
            {name:"OR",label:"Linn County"},
            {name:"OR",label:"Malheur County"},
            {name:"OR",label:"Marion County"},
            {name:"OR",label:"Morrow County"},
            {name:"OR",label:"Multnomah County"},
            {name:"OR",label:"Polk County"},
            {name:"OR",label:"Sherman County"},
            {name:"OR",label:"Tillamook County"},
            {name:"OR",label:"Umatilla County"},
            {name:"OR",label:"Union County"},
            {name:"OR",label:"Wallowa County"},
            {name:"OR",label:"Wasco County"},
            {name:"OR",label:"Washington County"},
            {name:"OR",label:"Wheeler County"},
            {name:"OR",label:"Yamhill County"}], "PA" :[
            {name:"PA",label:"Adams County"},
            {name:"PA",label:"Allegheny County"},
            {name:"PA",label:"Armstrong County"},
            {name:"PA",label:"Beaver County"},
            {name:"PA",label:"Bedford County"},
            {name:"PA",label:"Berks County"},
            {name:"PA",label:"Blair County"},
            {name:"PA",label:"Bradford County"},
            {name:"PA",label:"Bucks County"},
            {name:"PA",label:"Butler County"},
            {name:"PA",label:"Cambria County"},
            {name:"PA",label:"Cameron County"},
            {name:"PA",label:"Carbon County"},
            {name:"PA",label:"Centre County"},
            {name:"PA",label:"Chester County"},
            {name:"PA",label:"Clarion County"},
            {name:"PA",label:"Clearfield County"},
            {name:"PA",label:"Clinton County"},
            {name:"PA",label:"Columbia County"},
            {name:"PA",label:"Crawford County"},
            {name:"PA",label:"Cumberland County"},
            {name:"PA",label:"Dauphin County"},
            {name:"PA",label:"Delaware County"},
            {name:"PA",label:"Elk County"},
            {name:"PA",label:"Erie County"},
            {name:"PA",label:"Fayette County"},
            {name:"PA",label:"Forest County"},
            {name:"PA",label:"Franklin County"},
            {name:"PA",label:"Fulton County"},
            {name:"PA",label:"Greene County"},
            {name:"PA",label:"Huntingdon County"},
            {name:"PA",label:"Indiana County"},
            {name:"PA",label:"Jefferson County"},
            {name:"PA",label:"Juniata County"},
            {name:"PA",label:"Lackawanna County"},
            {name:"PA",label:"Lancaster County"},
            {name:"PA",label:"Lawrence County"},
            {name:"PA",label:"Lebanon County"},
            {name:"PA",label:"Lehigh County"},
            {name:"PA",label:"Luzerne County"},
            {name:"PA",label:"Lycoming County"},
            {name:"PA",label:"McKean County"},
            {name:"PA",label:"Mercer County"},
            {name:"PA",label:"Mifflin County"},
            {name:"PA",label:"Monroe County"},
            {name:"PA",label:"Montgomery County"},
            {name:"PA",label:"Montour County"},
            {name:"PA",label:"Northampton County"},
            {name:"PA",label:"Northumberland County"},
            {name:"PA",label:"Perry County"},
            {name:"PA",label:"Philadelphia County"},
            {name:"PA",label:"Pike County"},
            {name:"PA",label:"Potter County"},
            {name:"PA",label:"Schuylkill County"},
            {name:"PA",label:"Snyder County"},
            {name:"PA",label:"Somerset County"},
            {name:"PA",label:"Sullivan County"},
            {name:"PA",label:"Susquehanna County"},
            {name:"PA",label:"Tioga County"},
            {name:"PA",label:"Union County"},
            {name:"PA",label:"Venango County"},
            {name:"PA",label:"Warren County"},
            {name:"PA",label:"Washington County"},
            {name:"PA",label:"Wayne County"},
            {name:"PA",label:"Westmoreland County"},
            {name:"PA",label:"Wyoming County"},
            {name:"PA",label:"York County"}], "RI" :[
            {name:"RI",label:"Bristol County"},
            {name:"RI",label:"Kent County"},
            {name:"RI",label:"Newport County"},
            {name:"RI",label:"Providence County"},
            {name:"RI",label:"Washington County"}], "SC" :[
            {name:"SC",label:"Abbeville County"},
            {name:"SC",label:"Aiken County"},
            {name:"SC",label:"Allendale County"},
            {name:"SC",label:"Anderson County"},
            {name:"SC",label:"Bamberg County"},
            {name:"SC",label:"Barnwell County"},
            {name:"SC",label:"Beaufort County"},
            {name:"SC",label:"Berkeley County"},
            {name:"SC",label:"Calhoun County"},
            {name:"SC",label:"Charleston County"},
            {name:"SC",label:"Cherokee County"},
            {name:"SC",label:"Chester County"},
            {name:"SC",label:"Chesterfield County"},
            {name:"SC",label:"Clarendon County"},
            {name:"SC",label:"Colleton County"},
            {name:"SC",label:"Darlington County"},
            {name:"SC",label:"Dillon County"},
            {name:"SC",label:"Dorchester County"},
            {name:"SC",label:"Edgefield County"},
            {name:"SC",label:"Fairfield County"},
            {name:"SC",label:"Florence County"},
            {name:"SC",label:"Georgetown County"},
            {name:"SC",label:"Greenville County"},
            {name:"SC",label:"Greenwood County"},
            {name:"SC",label:"Hampton County"},
            {name:"SC",label:"Horry County"},
            {name:"SC",label:"Jasper County"},
            {name:"SC",label:"Kershaw County"},
            {name:"SC",label:"Lancaster County"},
            {name:"SC",label:"Laurens County"},
            {name:"SC",label:"Lee County"},
            {name:"SC",label:"Lexington County"},
            {name:"SC",label:"McCormick County"},
            {name:"SC",label:"Marion County"},
            {name:"SC",label:"Marlboro County"},
            {name:"SC",label:"Newberry County"},
            {name:"SC",label:"Oconee County"},
            {name:"SC",label:"Orangeburg County"},
            {name:"SC",label:"Pickens County"},
            {name:"SC",label:"Richland County"},
            {name:"SC",label:"Saluda County"},
            {name:"SC",label:"Spartanburg County"},
            {name:"SC",label:"Sumter County"},
            {name:"SC",label:"Union County"},
            {name:"SC",label:"Williamsburg County"},
            {name:"SC",label:"York County"}], "SD" :[
            {name:"SD",label:"Aurora County"},
            {name:"SD",label:"Beadle County"},
            {name:"SD",label:"Bennett County"},
            {name:"SD",label:"Bon Homme County"},
            {name:"SD",label:"Brookings County"},
            {name:"SD",label:"Brown County"},
            {name:"SD",label:"Brule County"},
            {name:"SD",label:"Buffalo County"},
            {name:"SD",label:"Butte County"},
            {name:"SD",label:"Campbell County"},
            {name:"SD",label:"Charles Mix County"},
            {name:"SD",label:"Clark County"},
            {name:"SD",label:"Clay County"},
            {name:"SD",label:"Codington County"},
            {name:"SD",label:"Corson County"},
            {name:"SD",label:"Custer County"},
            {name:"SD",label:"Davison County"},
            {name:"SD",label:"Day County"},
            {name:"SD",label:"Deuel County"},
            {name:"SD",label:"Dewey County"},
            {name:"SD",label:"Douglas County"},
            {name:"SD",label:"Edmunds County"},
            {name:"SD",label:"Fall River County"},
            {name:"SD",label:"Faulk County"},
            {name:"SD",label:"Grant County"},
            {name:"SD",label:"Gregory County"},
            {name:"SD",label:"Haakon County"},
            {name:"SD",label:"Hamlin County"},
            {name:"SD",label:"Hand County"},
            {name:"SD",label:"Hanson County"},
            {name:"SD",label:"Harding County"},
            {name:"SD",label:"Hughes County"},
            {name:"SD",label:"Hutchinson County"},
            {name:"SD",label:"Hyde County"},
            {name:"SD",label:"Jackson County"},
            {name:"SD",label:"Jerauld County"},
            {name:"SD",label:"Jones County"},
            {name:"SD",label:"Kingsbury County"},
            {name:"SD",label:"Lake County"},
            {name:"SD",label:"Lawrence County"},
            {name:"SD",label:"Lincoln County"},
            {name:"SD",label:"Lyman County"},
            {name:"SD",label:"McCook County"},
            {name:"SD",label:"McPherson County"},
            {name:"SD",label:"Marshall County"},
            {name:"SD",label:"Meade County"},
            {name:"SD",label:"Mellette County"},
            {name:"SD",label:"Miner County"},
            {name:"SD",label:"Minnehaha County"},
            {name:"SD",label:"Moody County"},
            {name:"SD",label:"Pennington County"},
            {name:"SD",label:"Perkins County"},
            {name:"SD",label:"Potter County"},
            {name:"SD",label:"Roberts County"},
            {name:"SD",label:"Sanborn County"},
            {name:"SD",label:"Shannon County"},
            {name:"SD",label:"Spink County"},
            {name:"SD",label:"Stanley County"},
            {name:"SD",label:"Sully County"},
            {name:"SD",label:"Todd County"},
            {name:"SD",label:"Tripp County"},
            {name:"SD",label:"Turner County"},
            {name:"SD",label:"Union County"},
            {name:"SD",label:"Walworth County"},
            {name:"SD",label:"Yankton County"},
            {name:"SD",label:"Ziebach County"}], "TN" :[
            {name:"TN",label:"Anderson County"},
            {name:"TN",label:"Bedford County"},
            {name:"TN",label:"Benton County"},
            {name:"TN",label:"Bledsoe County"},
            {name:"TN",label:"Blount County"},
            {name:"TN",label:"Bradley County"},
            {name:"TN",label:"Campbell County"},
            {name:"TN",label:"Cannon County"},
            {name:"TN",label:"Carroll County"},
            {name:"TN",label:"Carter County"},
            {name:"TN",label:"Cheatham County"},
            {name:"TN",label:"Chester County"},
            {name:"TN",label:"Claiborne County"},
            {name:"TN",label:"Clay County"},
            {name:"TN",label:"Cocke County"},
            {name:"TN",label:"Coffee County"},
            {name:"TN",label:"Crockett County"},
            {name:"TN",label:"Cumberland County"},
            {name:"TN",label:"Davidson County"},
            {name:"TN",label:"Decatur County"},
            {name:"TN",label:"DeKalb County"},
            {name:"TN",label:"Dickson County"},
            {name:"TN",label:"Dyer County"},
            {name:"TN",label:"Fayette County"},
            {name:"TN",label:"Fentress County"},
            {name:"TN",label:"Franklin County"},
            {name:"TN",label:"Gibson County"},
            {name:"TN",label:"Giles County"},
            {name:"TN",label:"Grainger County"},
            {name:"TN",label:"Greene County"},
            {name:"TN",label:"Grundy County"},
            {name:"TN",label:"Hamblen County"},
            {name:"TN",label:"Hamilton County"},
            {name:"TN",label:"Hancock County"},
            {name:"TN",label:"Hardeman County"},
            {name:"TN",label:"Hardin County"},
            {name:"TN",label:"Hawkins County"},
            {name:"TN",label:"Haywood County"},
            {name:"TN",label:"Henderson County"},
            {name:"TN",label:"Henry County"},
            {name:"TN",label:"Hickman County"},
            {name:"TN",label:"Houston County"},
            {name:"TN",label:"Humphreys County"},
            {name:"TN",label:"Jackson County"},
            {name:"TN",label:"Jefferson County"},
            {name:"TN",label:"Johnson County"},
            {name:"TN",label:"Knox County"},
            {name:"TN",label:"Lake County"},
            {name:"TN",label:"Lauderdale County"},
            {name:"TN",label:"Lawrence County"},
            {name:"TN",label:"Lewis County"},
            {name:"TN",label:"Lincoln County"},
            {name:"TN",label:"Loudon County"},
            {name:"TN",label:"McMinn County"},
            {name:"TN",label:"McNairy County"},
            {name:"TN",label:"Macon County"},
            {name:"TN",label:"Madison County"},
            {name:"TN",label:"Marion County"},
            {name:"TN",label:"Marshall County"},
            {name:"TN",label:"Maury County"},
            {name:"TN",label:"Meigs County"},
            {name:"TN",label:"Monroe County"},
            {name:"TN",label:"Montgomery County"},
            {name:"TN",label:"Moore County"},
            {name:"TN",label:"Morgan County"},
            {name:"TN",label:"Obion County"},
            {name:"TN",label:"Overton County"},
            {name:"TN",label:"Perry County"},
            {name:"TN",label:"Pickett County"},
            {name:"TN",label:"Polk County"},
            {name:"TN",label:"Putnam County"},
            {name:"TN",label:"Rhea County"},
            {name:"TN",label:"Roane County"},
            {name:"TN",label:"Robertson County"},
            {name:"TN",label:"Rutherford County"},
            {name:"TN",label:"Scott County"},
            {name:"TN",label:"Sequatchie County"},
            {name:"TN",label:"Sevier County"},
            {name:"TN",label:"Shelby County"},
            {name:"TN",label:"Smith County"},
            {name:"TN",label:"Stewart County"},
            {name:"TN",label:"Sullivan County"},
            {name:"TN",label:"Sumner County"},
            {name:"TN",label:"Tipton County"},
            {name:"TN",label:"Trousdale County"},
            {name:"TN",label:"Unicoi County"},
            {name:"TN",label:"Union County"},
            {name:"TN",label:"Van Buren County"},
            {name:"TN",label:"Warren County"},
            {name:"TN",label:"Washington County"},
            {name:"TN",label:"Wayne County"},
            {name:"TN",label:"Weakley County"},
            {name:"TN",label:"White County"},
            {name:"TN",label:"Williamson County"},
            {name:"TN",label:"Wilson County"}], "TX" :[
            {name:"TX",label:"Anderson County"},
            {name:"TX",label:"Andrews County"},
            {name:"TX",label:"Angelina County"},
            {name:"TX",label:"Aransas County"},
            {name:"TX",label:"Archer County"},
            {name:"TX",label:"Armstrong County"},
            {name:"TX",label:"Atascosa County"},
            {name:"TX",label:"Austin County"},
            {name:"TX",label:"Bailey County"},
            {name:"TX",label:"Bandera County"},
            {name:"TX",label:"Bastrop County"},
            {name:"TX",label:"Baylor County"},
            {name:"TX",label:"Bee County"},
            {name:"TX",label:"Bell County"},
            {name:"TX",label:"Bexar County"},
            {name:"TX",label:"Blanco County"},
            {name:"TX",label:"Borden County"},
            {name:"TX",label:"Bosque County"},
            {name:"TX",label:"Bowie County"},
            {name:"TX",label:"Brazoria County"},
            {name:"TX",label:"Brazos County"},
            {name:"TX",label:"Brewster County"},
            {name:"TX",label:"Briscoe County"},
            {name:"TX",label:"Brooks County"},
            {name:"TX",label:"Brown County"},
            {name:"TX",label:"Burleson County"},
            {name:"TX",label:"Burnet County"},
            {name:"TX",label:"Caldwell County"},
            {name:"TX",label:"Calhoun County"},
            {name:"TX",label:"Callahan County"},
            {name:"TX",label:"Cameron County"},
            {name:"TX",label:"Camp County"},
            {name:"TX",label:"Carson County"},
            {name:"TX",label:"Cass County"},
            {name:"TX",label:"Castro County"},
            {name:"TX",label:"Chambers County"},
            {name:"TX",label:"Cherokee County"},
            {name:"TX",label:"Childress County"},
            {name:"TX",label:"Clay County"},
            {name:"TX",label:"Cochran County"},
            {name:"TX",label:"Coke County"},
            {name:"TX",label:"Coleman County"},
            {name:"TX",label:"Collin County"},
            {name:"TX",label:"Collingsworth County"},
            {name:"TX",label:"Colorado County"},
            {name:"TX",label:"Comal County"},
            {name:"TX",label:"Comanche County"},
            {name:"TX",label:"Concho County"},
            {name:"TX",label:"Cooke County"},
            {name:"TX",label:"Coryell County"},
            {name:"TX",label:"Cottle County"},
            {name:"TX",label:"Crane County"},
            {name:"TX",label:"Crockett County"},
            {name:"TX",label:"Crosby County"},
            {name:"TX",label:"Culberson County"},
            {name:"TX",label:"Dallam County"},
            {name:"TX",label:"Dallas County"},
            {name:"TX",label:"Dawson County"},
            {name:"TX",label:"Deaf Smith County"},
            {name:"TX",label:"Delta County"},
            {name:"TX",label:"Denton County"},
            {name:"TX",label:"DeWitt County"},
            {name:"TX",label:"Dickens County"},
            {name:"TX",label:"Dimmit County"},
            {name:"TX",label:"Donley County"},
            {name:"TX",label:"Duval County"},
            {name:"TX",label:"Eastland County"},
            {name:"TX",label:"Ector County"},
            {name:"TX",label:"Edwards County"},
            {name:"TX",label:"Ellis County"},
            {name:"TX",label:"El Paso County"},
            {name:"TX",label:"Erath County"},
            {name:"TX",label:"Falls County"},
            {name:"TX",label:"Fannin County"},
            {name:"TX",label:"Fayette County"},
            {name:"TX",label:"Fisher County"},
            {name:"TX",label:"Floyd County"},
            {name:"TX",label:"Foard County"},
            {name:"TX",label:"Fort Bend County"},
            {name:"TX",label:"Franklin County"},
            {name:"TX",label:"Freestone County"},
            {name:"TX",label:"Frio County"},
            {name:"TX",label:"Gaines County"},
            {name:"TX",label:"Galveston County"},
            {name:"TX",label:"Garza County"},
            {name:"TX",label:"Gillespie County"},
            {name:"TX",label:"Glasscock County"},
            {name:"TX",label:"Goliad County"},
            {name:"TX",label:"Gonzales County"},
            {name:"TX",label:"Gray County"},
            {name:"TX",label:"Grayson County"},
            {name:"TX",label:"Gregg County"},
            {name:"TX",label:"Grimes County"},
            {name:"TX",label:"Guadalupe County"},
            {name:"TX",label:"Hale County"},
            {name:"TX",label:"Hall County"},
            {name:"TX",label:"Hamilton County"},
            {name:"TX",label:"Hansford County"},
            {name:"TX",label:"Hardeman County"},
            {name:"TX",label:"Hardin County"},
            {name:"TX",label:"Harris County"},
            {name:"TX",label:"Harrison County"},
            {name:"TX",label:"Hartley County"},
            {name:"TX",label:"Haskell County"},
            {name:"TX",label:"Hays County"},
            {name:"TX",label:"Hemphill County"},
            {name:"TX",label:"Henderson County"},
            {name:"TX",label:"Hidalgo County"},
            {name:"TX",label:"Hill County"},
            {name:"TX",label:"Hockley County"},
            {name:"TX",label:"Hood County"},
            {name:"TX",label:"Hopkins County"},
            {name:"TX",label:"Houston County"},
            {name:"TX",label:"Howard County"},
            {name:"TX",label:"Hudspeth County"},
            {name:"TX",label:"Hunt County"},
            {name:"TX",label:"Hutchinson County"},
            {name:"TX",label:"Irion County"},
            {name:"TX",label:"Jack County"},
            {name:"TX",label:"Jackson County"},
            {name:"TX",label:"Jasper County"},
            {name:"TX",label:"Jeff Davis County"},
            {name:"TX",label:"Jefferson County"},
            {name:"TX",label:"Jim Hogg County"},
            {name:"TX",label:"Jim Wells County"},
            {name:"TX",label:"Johnson County"},
            {name:"TX",label:"Jones County"},
            {name:"TX",label:"Karnes County"},
            {name:"TX",label:"Kaufman County"},
            {name:"TX",label:"Kendall County"},
            {name:"TX",label:"Kenedy County"},
            {name:"TX",label:"Kent County"},
            {name:"TX",label:"Kerr County"},
            {name:"TX",label:"Kimble County"},
            {name:"TX",label:"King County"},
            {name:"TX",label:"Kinney County"},
            {name:"TX",label:"Kleberg County"},
            {name:"TX",label:"Knox County"},
            {name:"TX",label:"Lamar County"},
            {name:"TX",label:"Lamb County"},
            {name:"TX",label:"Lampasas County"},
            {name:"TX",label:"La Salle County"},
            {name:"TX",label:"Lavaca County"},
            {name:"TX",label:"Lee County"},
            {name:"TX",label:"Leon County"},
            {name:"TX",label:"Liberty County"},
            {name:"TX",label:"Limestone County"},
            {name:"TX",label:"Lipscomb County"},
            {name:"TX",label:"Live Oak County"},
            {name:"TX",label:"Llano County"},
            {name:"TX",label:"Loving County"},
            {name:"TX",label:"Lubbock County"},
            {name:"TX",label:"Lynn County"},
            {name:"TX",label:"McCulloch County"},
            {name:"TX",label:"McLennan County"},
            {name:"TX",label:"McMullen County"},
            {name:"TX",label:"Madison County"},
            {name:"TX",label:"Marion County"},
            {name:"TX",label:"Martin County"},
            {name:"TX",label:"Mason County"},
            {name:"TX",label:"Matagorda County"},
            {name:"TX",label:"Maverick County"},
            {name:"TX",label:"Medina County"},
            {name:"TX",label:"Menard County"},
            {name:"TX",label:"Midland County"},
            {name:"TX",label:"Milam County"},
            {name:"TX",label:"Mills County"},
            {name:"TX",label:"Mitchell County"},
            {name:"TX",label:"Montague County"},
            {name:"TX",label:"Montgomery County"},
            {name:"TX",label:"Moore County"},
            {name:"TX",label:"Morris County"},
            {name:"TX",label:"Motley County"},
            {name:"TX",label:"Nacogdoches County"},
            {name:"TX",label:"Navarro County"},
            {name:"TX",label:"Newton County"},
            {name:"TX",label:"Nolan County"},
            {name:"TX",label:"Nueces County"},
            {name:"TX",label:"Ochiltree County"},
            {name:"TX",label:"Oldham County"},
            {name:"TX",label:"Orange County"},
            {name:"TX",label:"Palo Pinto County"},
            {name:"TX",label:"Panola County"},
            {name:"TX",label:"Parker County"},
            {name:"TX",label:"Parmer County"},
            {name:"TX",label:"Pecos County"},
            {name:"TX",label:"Polk County"},
            {name:"TX",label:"Potter County"},
            {name:"TX",label:"Presidio County"},
            {name:"TX",label:"Rains County"},
            {name:"TX",label:"Randall County"},
            {name:"TX",label:"Reagan County"},
            {name:"TX",label:"Real County"},
            {name:"TX",label:"Red River County"},
            {name:"TX",label:"Reeves County"},
            {name:"TX",label:"Refugio County"},
            {name:"TX",label:"Roberts County"},
            {name:"TX",label:"Robertson County"},
            {name:"TX",label:"Rockwall County"},
            {name:"TX",label:"Runnels County"},
            {name:"TX",label:"Rusk County"},
            {name:"TX",label:"Sabine County"},
            {name:"TX",label:"San Augustine County"},
            {name:"TX",label:"San Jacinto County"},
            {name:"TX",label:"San Patricio County"},
            {name:"TX",label:"San Saba County"},
            {name:"TX",label:"Schleicher County"},
            {name:"TX",label:"Scurry County"},
            {name:"TX",label:"Shackelford County"},
            {name:"TX",label:"Shelby County"},
            {name:"TX",label:"Sherman County"},
            {name:"TX",label:"Smith County"},
            {name:"TX",label:"Somervell County"},
            {name:"TX",label:"Starr County"},
            {name:"TX",label:"Stephens County"},
            {name:"TX",label:"Sterling County"},
            {name:"TX",label:"Stonewall County"},
            {name:"TX",label:"Sutton County"},
            {name:"TX",label:"Swisher County"},
            {name:"TX",label:"Tarrant County"},
            {name:"TX",label:"Taylor County"},
            {name:"TX",label:"Terrell County"},
            {name:"TX",label:"Terry County"},
            {name:"TX",label:"Throckmorton County"},
            {name:"TX",label:"Titus County"},
            {name:"TX",label:"Tom Green County"},
            {name:"TX",label:"Travis County"},
            {name:"TX",label:"Trinity County"},
            {name:"TX",label:"Tyler County"},
            {name:"TX",label:"Upshur County"},
            {name:"TX",label:"Upton County"},
            {name:"TX",label:"Uvalde County"},
            {name:"TX",label:"Val Verde County"},
            {name:"TX",label:"Van Zandt County"},
            {name:"TX",label:"Victoria County"},
            {name:"TX",label:"Walker County"},
            {name:"TX",label:"Waller County"},
            {name:"TX",label:"Ward County"},
            {name:"TX",label:"Washington County"},
            {name:"TX",label:"Webb County"},
            {name:"TX",label:"Wharton County"},
            {name:"TX",label:"Wheeler County"},
            {name:"TX",label:"Wichita County"},
            {name:"TX",label:"Wilbarger County"},
            {name:"TX",label:"Willacy County"},
            {name:"TX",label:"Williamson County"},
            {name:"TX",label:"Wilson County"},
            {name:"TX",label:"Winkler County"},
            {name:"TX",label:"Wise County"},
            {name:"TX",label:"Wood County"},
            {name:"TX",label:"Yoakum County"},
            {name:"TX",label:"Young County"},
            {name:"TX",label:"Zapata County"},
            {name:"TX",label:"Zavala County"}], "UT" :[
            {name:"UT",label:"Beaver County"},
            {name:"UT",label:"Box Elder County"},
            {name:"UT",label:"Cache County"},
            {name:"UT",label:"Carbon County"},
            {name:"UT",label:"Daggett County"},
            {name:"UT",label:"Davis County"},
            {name:"UT",label:"Duchesne County"},
            {name:"UT",label:"Emery County"},
            {name:"UT",label:"Garfield County"},
            {name:"UT",label:"Grand County"},
            {name:"UT",label:"Iron County"},
            {name:"UT",label:"Juab County"},
            {name:"UT",label:"Kane County"},
            {name:"UT",label:"Millard County"},
            {name:"UT",label:"Morgan County"},
            {name:"UT",label:"Piute County"},
            {name:"UT",label:"Rich County"},
            {name:"UT",label:"Salt Lake County"},
            {name:"UT",label:"San Juan County"},
            {name:"UT",label:"Sanpete County"},
            {name:"UT",label:"Sevier County"},
            {name:"UT",label:"Summit County"},
            {name:"UT",label:"Tooele County"},
            {name:"UT",label:"Uintah County"},
            {name:"UT",label:"Utah County"},
            {name:"UT",label:"Wasatch County"},
            {name:"UT",label:"Washington County"},
            {name:"UT",label:"Wayne County"},
            {name:"UT",label:"Weber County"}], "VT" :[
            {name:"VT",label:"Addison County"},
            {name:"VT",label:"Bennington County"},
            {name:"VT",label:"Caledonia County"},
            {name:"VT",label:"Chittenden County"},
            {name:"VT",label:"Essex County"},
            {name:"VT",label:"Franklin County"},
            {name:"VT",label:"Grand Isle County"},
            {name:"VT",label:"Lamoille County"},
            {name:"VT",label:"Orange County"},
            {name:"VT",label:"Orleans County"},
            {name:"VT",label:"Rutland County"},
            {name:"VT",label:"Washington County"},
            {name:"VT",label:"Windham County"},
            {name:"VT",label:"Windsor County"}], "VA" :[
            {name:"VA",label:"Accomack County"},
            {name:"VA",label:"Albemarle County"},
            {name:"VA",label:"Alleghany County"},
            {name:"VA",label:"Amelia County"},
            {name:"VA",label:"Amherst County"},
            {name:"VA",label:"Appomattox County"},
            {name:"VA",label:"Arlington County"},
            {name:"VA",label:"Augusta County"},
            {name:"VA",label:"Bath County"},
            {name:"VA",label:"Bedford County"},
            {name:"VA",label:"Bland County"},
            {name:"VA",label:"Botetourt County"},
            {name:"VA",label:"Brunswick County"},
            {name:"VA",label:"Buchanan County"},
            {name:"VA",label:"Buckingham County"},
            {name:"VA",label:"Campbell County"},
            {name:"VA",label:"Caroline County"},
            {name:"VA",label:"Carroll County"},
            {name:"VA",label:"Charles City County"},
            {name:"VA",label:"Charlotte County"},
            {name:"VA",label:"Chesterfield County"},
            {name:"VA",label:"Clarke County"},
            {name:"VA",label:"Craig County"},
            {name:"VA",label:"Culpeper County"},
            {name:"VA",label:"Cumberland County"},
            {name:"VA",label:"Dickenson County"},
            {name:"VA",label:"Dinwiddie County"},
            {name:"VA",label:"Essex County"},
            {name:"VA",label:"Fairfax County"},
            {name:"VA",label:"Fauquier County"},
            {name:"VA",label:"Floyd County"},
            {name:"VA",label:"Fluvanna County"},
            {name:"VA",label:"Franklin County"},
            {name:"VA",label:"Frederick County"},
            {name:"VA",label:"Giles County"},
            {name:"VA",label:"Gloucester County"},
            {name:"VA",label:"Goochland County"},
            {name:"VA",label:"Grayson County"},
            {name:"VA",label:"Greene County"},
            {name:"VA",label:"Greensville County"},
            {name:"VA",label:"Halifax County"},
            {name:"VA",label:"Hanover County"},
            {name:"VA",label:"Henrico County"},
            {name:"VA",label:"Henry County"},
            {name:"VA",label:"Highland County"},
            {name:"VA",label:"Isle of Wight County"},
            {name:"VA",label:"James City County"},
            {name:"VA",label:"King and Queen County"},
            {name:"VA",label:"King George County"},
            {name:"VA",label:"King William County"},
            {name:"VA",label:"Lancaster County"},
            {name:"VA",label:"Lee County"},
            {name:"VA",label:"Loudoun County"},
            {name:"VA",label:"Louisa County"},
            {name:"VA",label:"Lunenburg County"},
            {name:"VA",label:"Madison County"},
            {name:"VA",label:"Mathews County"},
            {name:"VA",label:"Mecklenburg County"},
            {name:"VA",label:"Middlesex County"},
            {name:"VA",label:"Montgomery County"},
            {name:"VA",label:"Nelson County"},
            {name:"VA",label:"New Kent County"},
            {name:"VA",label:"Northampton County"},
            {name:"VA",label:"Northumberland County"},
            {name:"VA",label:"Nottoway County"},
            {name:"VA",label:"Orange County"},
            {name:"VA",label:"Page County"},
            {name:"VA",label:"Patrick County"},
            {name:"VA",label:"Pittsylvania County"},
            {name:"VA",label:"Powhatan County"},
            {name:"VA",label:"Prince Edward County"},
            {name:"VA",label:"Prince George County"},
            {name:"VA",label:"Prince William County"},
            {name:"VA",label:"Pulaski County"},
            {name:"VA",label:"Rappahannock County"},
            {name:"VA",label:"Richmond County"},
            {name:"VA",label:"Roanoke County"},
            {name:"VA",label:"Rockbridge County"},
            {name:"VA",label:"Rockingham County"},
            {name:"VA",label:"Russell County"},
            {name:"VA",label:"Scott County"},
            {name:"VA",label:"Shenandoah County"},
            {name:"VA",label:"Smyth County"},
            {name:"VA",label:"Southampton County"},
            {name:"VA",label:"Spotsylvania County"},
            {name:"VA",label:"Stafford County"},
            {name:"VA",label:"Surry County"},
            {name:"VA",label:"Sussex County"},
            {name:"VA",label:"Tazewell County"},
            {name:"VA",label:"Warren County"},
            {name:"VA",label:"Washington County"},
            {name:"VA",label:"Westmoreland County"},
            {name:"VA",label:"Wise County"},
            {name:"VA",label:"Wythe County"},
            {name:"VA",label:"York County"},
            {name:"VA",label:"Alexandria city"},
            {name:"VA",label:"Bedford city"},
            {name:"VA",label:"Bristol city"},
            {name:"VA",label:"Buena Vista city"},
            {name:"VA",label:"Charlottesville city"},
            {name:"VA",label:"Chesapeake city"},
            {name:"VA",label:"Colonial Heights city"},
            {name:"VA",label:"Covington city"},
            {name:"VA",label:"Danville city"},
            {name:"VA",label:"Emporia city"},
            {name:"VA",label:"Fairfax city"},
            {name:"VA",label:"Falls Church city"},
            {name:"VA",label:"Franklin city"},
            {name:"VA",label:"Fredericksburg city"},
            {name:"VA",label:"Galax city"},
            {name:"VA",label:"Hampton city"},
            {name:"VA",label:"Harrisonburg city"},
            {name:"VA",label:"Hopewell city"},
            {name:"VA",label:"Lexington city"},
            {name:"VA",label:"Lynchburg city"},
            {name:"VA",label:"Manassas city"},
            {name:"VA",label:"Manassas Park city"},
            {name:"VA",label:"Martinsville city"},
            {name:"VA",label:"Newport News city"},
            {name:"VA",label:"Norfolk city"},
            {name:"VA",label:"Norton city"},
            {name:"VA",label:"Petersburg city"},
            {name:"VA",label:"Poquoson city"},
            {name:"VA",label:"Portsmouth city"},
            {name:"VA",label:"Radford city"},
            {name:"VA",label:"Richmond city"},
            {name:"VA",label:"Roanoke city"},
            {name:"VA",label:"Salem city"},
            {name:"VA",label:"Staunton city"},
            {name:"VA",label:"Suffolk city"},
            {name:"VA",label:"Virginia Beach city"},
            {name:"VA",label:"Waynesboro city"},
            {name:"VA",label:"Williamsburg city"},
            {name:"VA",label:"Winchester city"}], "WA" :[
            {name:"WA",label:"Adams County"},
            {name:"WA",label:"Asotin County"},
            {name:"WA",label:"Benton County"},
            {name:"WA",label:"Chelan County"},
            {name:"WA",label:"Clallam County"},
            {name:"WA",label:"Clark County"},
            {name:"WA",label:"Columbia County"},
            {name:"WA",label:"Cowlitz County"},
            {name:"WA",label:"Douglas County"},
            {name:"WA",label:"Ferry County"},
            {name:"WA",label:"Franklin County"},
            {name:"WA",label:"Garfield County"},
            {name:"WA",label:"Grant County"},
            {name:"WA",label:"Grays Harbor County"},
            {name:"WA",label:"Island County"},
            {name:"WA",label:"Jefferson County"},
            {name:"WA",label:"King County"},
            {name:"WA",label:"Kitsap County"},
            {name:"WA",label:"Kittitas County"},
            {name:"WA",label:"Klickitat County"},
            {name:"WA",label:"Lewis County"},
            {name:"WA",label:"Lincoln County"},
            {name:"WA",label:"Mason County"},
            {name:"WA",label:"Okanogan County"},
            {name:"WA",label:"Pacific County"},
            {name:"WA",label:"Pend Oreille County"},
            {name:"WA",label:"Pierce County"},
            {name:"WA",label:"San Juan County"},
            {name:"WA",label:"Skagit County"},
            {name:"WA",label:"Skamania County"},
            {name:"WA",label:"Snohomish County"},
            {name:"WA",label:"Spokane County"},
            {name:"WA",label:"Stevens County"},
            {name:"WA",label:"Thurston County"},
            {name:"WA",label:"Wahkiakum County"},
            {name:"WA",label:"Walla Walla County"},
            {name:"WA",label:"Whatcom County"},
            {name:"WA",label:"Whitman County"},
            {name:"WA",label:"Yakima County"}], "WV" :[
            {name:"WV",label:"Barbour County"},
            {name:"WV",label:"Berkeley County"},
            {name:"WV",label:"Boone County"},
            {name:"WV",label:"Braxton County"},
            {name:"WV",label:"Brooke County"},
            {name:"WV",label:"Cabell County"},
            {name:"WV",label:"Calhoun County"},
            {name:"WV",label:"Clay County"},
            {name:"WV",label:"Doddridge County"},
            {name:"WV",label:"Fayette County"},
            {name:"WV",label:"Gilmer County"},
            {name:"WV",label:"Grant County"},
            {name:"WV",label:"Greenbrier County"},
            {name:"WV",label:"Hampshire County"},
            {name:"WV",label:"Hancock County"},
            {name:"WV",label:"Hardy County"},
            {name:"WV",label:"Harrison County"},
            {name:"WV",label:"Jackson County"},
            {name:"WV",label:"Jefferson County"},
            {name:"WV",label:"Kanawha County"},
            {name:"WV",label:"Lewis County"},
            {name:"WV",label:"Lincoln County"},
            {name:"WV",label:"Logan County"},
            {name:"WV",label:"McDowell County"},
            {name:"WV",label:"Marion County"},
            {name:"WV",label:"Marshall County"},
            {name:"WV",label:"Mason County"},
            {name:"WV",label:"Mercer County"},
            {name:"WV",label:"Mineral County"},
            {name:"WV",label:"Mingo County"},
            {name:"WV",label:"Monongalia County"},
            {name:"WV",label:"Monroe County"},
            {name:"WV",label:"Morgan County"},
            {name:"WV",label:"Nicholas County"},
            {name:"WV",label:"Ohio County"},
            {name:"WV",label:"Pendleton County"},
            {name:"WV",label:"Pleasants County"},
            {name:"WV",label:"Pocahontas County"},
            {name:"WV",label:"Preston County"},
            {name:"WV",label:"Putnam County"},
            {name:"WV",label:"Raleigh County"},
            {name:"WV",label:"Randolph County"},
            {name:"WV",label:"Ritchie County"},
            {name:"WV",label:"Roane County"},
            {name:"WV",label:"Summers County"},
            {name:"WV",label:"Taylor County"},
            {name:"WV",label:"Tucker County"},
            {name:"WV",label:"Tyler County"},
            {name:"WV",label:"Upshur County"},
            {name:"WV",label:"Wayne County"},
            {name:"WV",label:"Webster County"},
            {name:"WV",label:"Wetzel County"},
            {name:"WV",label:"Wirt County"},
            {name:"WV",label:"Wood County"},
            {name:"WV",label:"Wyoming County"}], "WI" :[
            {name:"WI",label:"Adams County"},
            {name:"WI",label:"Ashland County"},
            {name:"WI",label:"Barron County"},
            {name:"WI",label:"Bayfield County"},
            {name:"WI",label:"Brown County"},
            {name:"WI",label:"Buffalo County"},
            {name:"WI",label:"Burnett County"},
            {name:"WI",label:"Calumet County"},
            {name:"WI",label:"Chippewa County"},
            {name:"WI",label:"Clark County"},
            {name:"WI",label:"Columbia County"},
            {name:"WI",label:"Crawford County"},
            {name:"WI",label:"Dane County"},
            {name:"WI",label:"Dodge County"},
            {name:"WI",label:"Door County"},
            {name:"WI",label:"Douglas County"},
            {name:"WI",label:"Dunn County"},
            {name:"WI",label:"Eau Claire County"},
            {name:"WI",label:"Florence County"},
            {name:"WI",label:"Fond du Lac County"},
            {name:"WI",label:"Forest County"},
            {name:"WI",label:"Grant County"},
            {name:"WI",label:"Green County"},
            {name:"WI",label:"Green Lake County"},
            {name:"WI",label:"Iowa County"},
            {name:"WI",label:"Iron County"},
            {name:"WI",label:"Jackson County"},
            {name:"WI",label:"Jefferson County"},
            {name:"WI",label:"Juneau County"},
            {name:"WI",label:"Kenosha County"},
            {name:"WI",label:"Kewaunee County"},
            {name:"WI",label:"La Crosse County"},
            {name:"WI",label:"Lafayette County"},
            {name:"WI",label:"Langlade County"},
            {name:"WI",label:"Lincoln County"},
            {name:"WI",label:"Manitowoc County"},
            {name:"WI",label:"Marathon County"},
            {name:"WI",label:"Marinette County"},
            {name:"WI",label:"Marquette County"},
            {name:"WI",label:"Menominee County"},
            {name:"WI",label:"Milwaukee County"},
            {name:"WI",label:"Monroe County"},
            {name:"WI",label:"Oconto County"},
            {name:"WI",label:"Oneida County"},
            {name:"WI",label:"Outagamie County"},
            {name:"WI",label:"Ozaukee County"},
            {name:"WI",label:"Pepin County"},
            {name:"WI",label:"Pierce County"},
            {name:"WI",label:"Polk County"},
            {name:"WI",label:"Portage County"},
            {name:"WI",label:"Price County"},
            {name:"WI",label:"Racine County"},
            {name:"WI",label:"Richland County"},
            {name:"WI",label:"Rock County"},
            {name:"WI",label:"Rusk County"},
            {name:"WI",label:"St. Croix County"},
            {name:"WI",label:"Sauk County"},
            {name:"WI",label:"Sawyer County"},
            {name:"WI",label:"Shawano County"},
            {name:"WI",label:"Sheboygan County"},
            {name:"WI",label:"Taylor County"},
            {name:"WI",label:"Trempealeau County"},
            {name:"WI",label:"Vernon County"},
            {name:"WI",label:"Vilas County"},
            {name:"WI",label:"Walworth County"},
            {name:"WI",label:"Washburn County"},
            {name:"WI",label:"Washington County"},
            {name:"WI",label:"Waukesha County"},
            {name:"WI",label:"Waupaca County"},
            {name:"WI",label:"Waushara County"},
            {name:"WI",label:"Winnebago County"},
            {name:"WI",label:"Wood County"}], "WY" :[
            {name:"WY",label:"Albany County"},
            {name:"WY",label:"Big Horn County"},
            {name:"WY",label:"Campbell County"},
            {name:"WY",label:"Carbon County"},
            {name:"WY",label:"Converse County"},
            {name:"WY",label:"Crook County"},
            {name:"WY",label:"Fremont County"},
            {name:"WY",label:"Goshen County"},
            {name:"WY",label:"Hot Springs County"},
            {name:"WY",label:"Johnson County"},
            {name:"WY",label:"Laramie County"},
            {name:"WY",label:"Lincoln County"},
            {name:"WY",label:"Natrona County"},
            {name:"WY",label:"Niobrara County"},
            {name:"WY",label:"Park County"},
            {name:"WY",label:"Platte County"},
            {name:"WY",label:"Sheridan County"},
            {name:"WY",label:"Sublette County"},
            {name:"WY",label:"Sweetwater County"},
            {name:"WY",label:"Teton County"},
            {name:"WY",label:"Uinta County"},
            {name:"WY",label:"Washakie County"},
            {name:"WY",label:"Weston County"}]
    }
    const countyList = [
        {name:"AL",label:"Autauga County"},
        {name:"AL",label:"Baldwin County"},
        {name:"AL",label:"Barbour County"},
        {name:"AL",label:"Bibb County"},
        {name:"AL",label:"Blount County"},
        {name:"AL",label:"Bullock County"},
        {name:"AL",label:"Butler County"},
        {name:"AL",label:"Calhoun County"},
        {name:"AL",label:"Chambers County"},
        {name:"AL",label:"Cherokee County"},
        {name:"AL",label:"Chilton County"},
        {name:"AL",label:"Choctaw County"},
        {name:"AL",label:"Clarke County"},
        {name:"AL",label:"Clay County"},
        {name:"AL",label:"Cleburne County"},
        {name:"AL",label:"Coffee County"},
        {name:"AL",label:"Colbert County"},
        {name:"AL",label:"Conecuh County"},
        {name:"AL",label:"Coosa County"},
        {name:"AL",label:"Covington County"},
        {name:"AL",label:"Crenshaw County"},
        {name:"AL",label:"Cullman County"},
        {name:"AL",label:"Dale County"},
        {name:"AL",label:"Dallas County"},
        {name:"AL",label:"DeKalb County"},
        {name:"AL",label:"Elmore County"},
        {name:"AL",label:"Escambia County"},
        {name:"AL",label:"Etowah County"},
        {name:"AL",label:"Fayette County"},
        {name:"AL",label:"Franklin County"},
        {name:"AL",label:"Geneva County"},
        {name:"AL",label:"Greene County"},
        {name:"AL",label:"Hale County"},
        {name:"AL",label:"Henry County"},
        {name:"AL",label:"Houston County"},
        {name:"AL",label:"Jackson County"},
        {name:"AL",label:"Jefferson County"},
        {name:"AL",label:"Lamar County"},
        {name:"AL",label:"Lauderdale County"},
        {name:"AL",label:"Lawrence County"},
        {name:"AL",label:"Lee County"},
        {name:"AL",label:"Limestone County"},
        {name:"AL",label:"Lowndes County"},
        {name:"AL",label:"Macon County"},
        {name:"AL",label:"Madison County"},
        {name:"AL",label:"Marengo County"},
        {name:"AL",label:"Marion County"},
        {name:"AL",label:"Marshall County"},
        {name:"AL",label:"Mobile County"},
        {name:"AL",label:"Monroe County"},
        {name:"AL",label:"Montgomery County"},
        {name:"AL",label:"Morgan County"},
        {name:"AL",label:"Perry County"},
        {name:"AL",label:"Pickens County"},
        {name:"AL",label:"Pike County"},
        {name:"AL",label:"Randolph County"},
        {name:"AL",label:"Russell County"},
        {name:"AL",label:"St. Clair County"},
        {name:"AL",label:"Shelby County"},
        {name:"AL",label:"Sumter County"},
        {name:"AL",label:"Talladega County"},
        {name:"AL",label:"Tallapoosa County"},
        {name:"AL",label:"Tuscaloosa County"},
        {name:"AL",label:"Walker County"},
        {name:"AL",label:"Washington County"},
        {name:"AL",label:"Wilcox County"},
        {name:"AL",label:"Winston County"},
        {name:"AK",label:"Aleutians East Borough"},
        {name:"AK",label:"Aleutians West Census Area"},
        {name:"AK",label:"Anchorage Municipality"},
        {name:"AK",label:"Bethel Census Area"},
        {name:"AK",label:"Bristol Bay Borough"},
        {name:"AK",label:"Denali Borough"},
        {name:"AK",label:"Dillingham Census Area"},
        {name:"AK",label:"Fairbanks North Star Borough"},
        {name:"AK",label:"Haines Borough"},
        {name:"AK",label:"Hoonah-Angoon Census Area"},
        {name:"AK",label:"Juneau City and Borough"},
        {name:"AK",label:"Kenai Peninsula Borough"},
        {name:"AK",label:"Ketchikan Gateway Borough"},
        {name:"AK",label:"Kodiak Island Borough"},
        {name:"AK",label:"Lake and Peninsula Borough"},
        {name:"AK",label:"Matanuska-Susitna Borough"},
        {name:"AK",label:"Nome Census Area"},
        {name:"AK",label:"North Slope Borough"},
        {name:"AK",label:"Northwest Arctic Borough"},
        {name:"AK",label:"Petersburg Census Area"},
        {name:"AK",label:"Prince of Wales-Hyder Census Area"},
        {name:"AK",label:"Sitka City and Borough"},
        {name:"AK",label:"Skagway Municipality"},
        {name:"AK",label:"Southeast Fairbanks Census Area"},
        {name:"AK",label:"Valdez-Cordova Census Area"},
        {name:"AK",label:"Wade Hampton Census Area"},
        {name:"AK",label:"Wrangell City and Borough"},
        {name:"AK",label:"Yakutat City and Borough"},
        {name:"AK",label:"Yukon-Koyukuk Census Area"},
        {name:"AZ",label:"Apache County"},
        {name:"AZ",label:"Cochise County"},
        {name:"AZ",label:"Coconino County"},
        {name:"AZ",label:"Gila County"},
        {name:"AZ",label:"Graham County"},
        {name:"AZ",label:"Greenlee County"},
        {name:"AZ",label:"La Paz County"},
        {name:"AZ",label:"Maricopa County"},
        {name:"AZ",label:"Mohave County"},
        {name:"AZ",label:"Navajo County"},
        {name:"AZ",label:"Pima County"},
        {name:"AZ",label:"Pinal County"},
        {name:"AZ",label:"Santa Cruz County"},
        {name:"AZ",label:"Yavapai County"},
        {name:"AZ",label:"Yuma County"},
        {name:"AR",label:"Arkansas County"},
        {name:"AR",label:"Ashley County"},
        {name:"AR",label:"Baxter County"},
        {name:"AR",label:"Benton County"},
        {name:"AR",label:"Boone County"},
        {name:"AR",label:"Bradley County"},
        {name:"AR",label:"Calhoun County"},
        {name:"AR",label:"Carroll County"},
        {name:"AR",label:"Chicot County"},
        {name:"AR",label:"Clark County"},
        {name:"AR",label:"Clay County"},
        {name:"AR",label:"Cleburne County"},
        {name:"AR",label:"Cleveland County"},
        {name:"AR",label:"Columbia County"},
        {name:"AR",label:"Conway County"},
        {name:"AR",label:"Craighead County"},
        {name:"AR",label:"Crawford County"},
        {name:"AR",label:"Crittenden County"},
        {name:"AR",label:"Cross County"},
        {name:"AR",label:"Dallas County"},
        {name:"AR",label:"Desha County"},
        {name:"AR",label:"Drew County"},
        {name:"AR",label:"Faulkner County"},
        {name:"AR",label:"Franklin County"},
        {name:"AR",label:"Fulton County"},
        {name:"AR",label:"Garland County"},
        {name:"AR",label:"Grant County"},
        {name:"AR",label:"Greene County"},
        {name:"AR",label:"Hempstead County"},
        {name:"AR",label:"Hot Spring County"},
        {name:"AR",label:"Howard County"},
        {name:"AR",label:"Independence County"},
        {name:"AR",label:"Izard County"},
        {name:"AR",label:"Jackson County"},
        {name:"AR",label:"Jefferson County"},
        {name:"AR",label:"Johnson County"},
        {name:"AR",label:"Lafayette County"},
        {name:"AR",label:"Lawrence County"},
        {name:"AR",label:"Lee County"},
        {name:"AR",label:"Lincoln County"},
        {name:"AR",label:"Little River County"},
        {name:"AR",label:"Logan County"},
        {name:"AR",label:"Lonoke County"},
        {name:"AR",label:"Madison County"},
        {name:"AR",label:"Marion County"},
        {name:"AR",label:"Miller County"},
        {name:"AR",label:"Mississippi County"},
        {name:"AR",label:"Monroe County"},
        {name:"AR",label:"Montgomery County"},
        {name:"AR",label:"Nevada County"},
        {name:"AR",label:"Newton County"},
        {name:"AR",label:"Ouachita County"},
        {name:"AR",label:"Perry County"},
        {name:"AR",label:"Phillips County"},
        {name:"AR",label:"Pike County"},
        {name:"AR",label:"Poinsett County"},
        {name:"AR",label:"Polk County"},
        {name:"AR",label:"Pope County"},
        {name:"AR",label:"Prairie County"},
        {name:"AR",label:"Pulaski County"},
        {name:"AR",label:"Randolph County"},
        {name:"AR",label:"St. Francis County"},
        {name:"AR",label:"Saline County"},
        {name:"AR",label:"Scott County"},
        {name:"AR",label:"Searcy County"},
        {name:"AR",label:"Sebastian County"},
        {name:"AR",label:"Sevier County"},
        {name:"AR",label:"Sharp County"},
        {name:"AR",label:"Stone County"},
        {name:"AR",label:"Union County"},
        {name:"AR",label:"Van Buren County"},
        {name:"AR",label:"Washington County"},
        {name:"AR",label:"White County"},
        {name:"AR",label:"Woodruff County"},
        {name:"AR",label:"Yell County"},
        {name:"CA",label:"Alameda County"},
        {name:"CA",label:"Alpine County"},
        {name:"CA",label:"Amador County"},
        {name:"CA",label:"Butte County"},
        {name:"CA",label:"Calaveras County"},
        {name:"CA",label:"Colusa County"},
        {name:"CA",label:"Contra Costa County"},
        {name:"CA",label:"Del Norte County"},
        {name:"CA",label:"El Dorado County"},
        {name:"CA",label:"Fresno County"},
        {name:"CA",label:"Glenn County"},
        {name:"CA",label:"Humboldt County"},
        {name:"CA",label:"Imperial County"},
        {name:"CA",label:"Inyo County"},
        {name:"CA",label:"Kern County"},
        {name:"CA",label:"Kings County"},
        {name:"CA",label:"Lake County"},
        {name:"CA",label:"Lassen County"},
        {name:"CA",label:"Los Angeles County"},
        {name:"CA",label:"Madera County"},
        {name:"CA",label:"Marin County"},
        {name:"CA",label:"Mariposa County"},
        {name:"CA",label:"Mendocino County"},
        {name:"CA",label:"Merced County"},
        {name:"CA",label:"Modoc County"},
        {name:"CA",label:"Mono County"},
        {name:"CA",label:"Monterey County"},
        {name:"CA",label:"Napa County"},
        {name:"CA",label:"Nevada County"},
        {name:"CA",label:"Orange County"},
        {name:"CA",label:"Placer County"},
        {name:"CA",label:"Plumas County"},
        {name:"CA",label:"Riverside County"},
        {name:"CA",label:"Sacramento County"},
        {name:"CA",label:"San Benito County"},
        {name:"CA",label:"San Bernardino County"},
        {name:"CA",label:"San Diego County"},
        {name:"CA",label:"San Francisco County"},
        {name:"CA",label:"San Joaquin County"},
        {name:"CA",label:"San Luis Obispo County"},
        {name:"CA",label:"San Mateo County"},
        {name:"CA",label:"Santa Barbara County"},
        {name:"CA",label:"Santa Clara County"},
        {name:"CA",label:"Santa Cruz County"},
        {name:"CA",label:"Shasta County"},
        {name:"CA",label:"Sierra County"},
        {name:"CA",label:"Siskiyou County"},
        {name:"CA",label:"Solano County"},
        {name:"CA",label:"Sonoma County"},
        {name:"CA",label:"Stanislaus County"},
        {name:"CA",label:"Sutter County"},
        {name:"CA",label:"Tehama County"},
        {name:"CA",label:"Trinity County"},
        {name:"CA",label:"Tulare County"},
        {name:"CA",label:"Tuolumne County"},
        {name:"CA",label:"Ventura County"},
        {name:"CA",label:"Yolo County"},
        {name:"CA",label:"Yuba County"},
        {name:"CO",label:"Adams County"},
        {name:"CO",label:"Alamosa County"},
        {name:"CO",label:"Arapahoe County"},
        {name:"CO",label:"Archuleta County"},
        {name:"CO",label:"Baca County"},
        {name:"CO",label:"Bent County"},
        {name:"CO",label:"Boulder County"},
        {name:"CO",label:"Broomfield County"},
        {name:"CO",label:"Chaffee County"},
        {name:"CO",label:"Cheyenne County"},
        {name:"CO",label:"Clear Creek County"},
        {name:"CO",label:"Conejos County"},
        {name:"CO",label:"Costilla County"},
        {name:"CO",label:"Crowley County"},
        {name:"CO",label:"Custer County"},
        {name:"CO",label:"Delta County"},
        {name:"CO",label:"Denver County"},
        {name:"CO",label:"Dolores County"},
        {name:"CO",label:"Douglas County"},
        {name:"CO",label:"Eagle County"},
        {name:"CO",label:"Elbert County"},
        {name:"CO",label:"El Paso County"},
        {name:"CO",label:"Fremont County"},
        {name:"CO",label:"Garfield County"},
        {name:"CO",label:"Gilpin County"},
        {name:"CO",label:"Grand County"},
        {name:"CO",label:"Gunnison County"},
        {name:"CO",label:"Hinsdale County"},
        {name:"CO",label:"Huerfano County"},
        {name:"CO",label:"Jackson County"},
        {name:"CO",label:"Jefferson County"},
        {name:"CO",label:"Kiowa County"},
        {name:"CO",label:"Kit Carson County"},
        {name:"CO",label:"Lake County"},
        {name:"CO",label:"La Plata County"},
        {name:"CO",label:"Larimer County"},
        {name:"CO",label:"Las Animas County"},
        {name:"CO",label:"Lincoln County"},
        {name:"CO",label:"Logan County"},
        {name:"CO",label:"Mesa County"},
        {name:"CO",label:"Mineral County"},
        {name:"CO",label:"Moffat County"},
        {name:"CO",label:"Montezuma County"},
        {name:"CO",label:"Montrose County"},
        {name:"CO",label:"Morgan County"},
        {name:"CO",label:"Otero County"},
        {name:"CO",label:"Ouray County"},
        {name:"CO",label:"Park County"},
        {name:"CO",label:"Phillips County"},
        {name:"CO",label:"Pitkin County"},
        {name:"CO",label:"Prowers County"},
        {name:"CO",label:"Pueblo County"},
        {name:"CO",label:"Rio Blanco County"},
        {name:"CO",label:"Rio Grande County"},
        {name:"CO",label:"Routt County"},
        {name:"CO",label:"Saguache County"},
        {name:"CO",label:"San Juan County"},
        {name:"CO",label:"San Miguel County"},
        {name:"CO",label:"Sedgwick County"},
        {name:"CO",label:"Summit County"},
        {name:"CO",label:"Teller County"},
        {name:"CO",label:"Washington County"},
        {name:"CO",label:"Weld County"},
        {name:"CO",label:"Yuma County"},
        {name:"CT",label:"Fairfield County"},
        {name:"CT",label:"Hartford County"},
        {name:"CT",label:"Litchfield County"},
        {name:"CT",label:"Middlesex County"},
        {name:"CT",label:"New Haven County"},
        {name:"CT",label:"New London County"},
        {name:"CT",label:"Tolland County"},
        {name:"CT",label:"Windham County"},
        {name:"DE",label:"Kent County"},
        {name:"DE",label:"New Castle County"},
        {name:"DE",label:"Sussex County"},
        {name:"DC",label:"District of Columbia"},
        {name:"FL",label:"Alachua County"},
        {name:"FL",label:"Baker County"},
        {name:"FL",label:"Bay County"},
        {name:"FL",label:"Bradford County"},
        {name:"FL",label:"Brevard County"},
        {name:"FL",label:"Broward County"},
        {name:"FL",label:"Calhoun County"},
        {name:"FL",label:"Charlotte County"},
        {name:"FL",label:"Citrus County"},
        {name:"FL",label:"Clay County"},
        {name:"FL",label:"Collier County"},
        {name:"FL",label:"Columbia County"},
        {name:"FL",label:"DeSoto County"},
        {name:"FL",label:"Dixie County"},
        {name:"FL",label:"Duval County"},
        {name:"FL",label:"Escambia County"},
        {name:"FL",label:"Flagler County"},
        {name:"FL",label:"Franklin County"},
        {name:"FL",label:"Gadsden County"},
        {name:"FL",label:"Gilchrist County"},
        {name:"FL",label:"Glades County"},
        {name:"FL",label:"Gulf County"},
        {name:"FL",label:"Hamilton County"},
        {name:"FL",label:"Hardee County"},
        {name:"FL",label:"Hendry County"},
        {name:"FL",label:"Hernando County"},
        {name:"FL",label:"Highlands County"},
        {name:"FL",label:"Hillsborough County"},
        {name:"FL",label:"Holmes County"},
        {name:"FL",label:"Indian River County"},
        {name:"FL",label:"Jackson County"},
        {name:"FL",label:"Jefferson County"},
        {name:"FL",label:"Lafayette County"},
        {name:"FL",label:"Lake County"},
        {name:"FL",label:"Lee County"},
        {name:"FL",label:"Leon County"},
        {name:"FL",label:"Levy County"},
        {name:"FL",label:"Liberty County"},
        {name:"FL",label:"Madison County"},
        {name:"FL",label:"Manatee County"},
        {name:"FL",label:"Marion County"},
        {name:"FL",label:"Martin County"},
        {name:"FL",label:"Miami-Dade County"},
        {name:"FL",label:"Monroe County"},
        {name:"FL",label:"Nassau County"},
        {name:"FL",label:"Okaloosa County"},
        {name:"FL",label:"Okeechobee County"},
        {name:"FL",label:"Orange County"},
        {name:"FL",label:"Osceola County"},
        {name:"FL",label:"Palm Beach County"},
        {name:"FL",label:"Pasco County"},
        {name:"FL",label:"Pinellas County"},
        {name:"FL",label:"Polk County"},
        {name:"FL",label:"Putnam County"},
        {name:"FL",label:"St. Johns County"},
        {name:"FL",label:"St. Lucie County"},
        {name:"FL",label:"Santa Rosa County"},
        {name:"FL",label:"Sarasota County"},
        {name:"FL",label:"Seminole County"},
        {name:"FL",label:"Sumter County"},
        {name:"FL",label:"Suwannee County"},
        {name:"FL",label:"Taylor County"},
        {name:"FL",label:"Union County"},
        {name:"FL",label:"Volusia County"},
        {name:"FL",label:"Wakulla County"},
        {name:"FL",label:"Walton County"},
        {name:"FL",label:"Washington County"},
        {name:"GA",label:"Appling County"},
        {name:"GA",label:"Atkinson County"},
        {name:"GA",label:"Bacon County"},
        {name:"GA",label:"Baker County"},
        {name:"GA",label:"Baldwin County"},
        {name:"GA",label:"Banks County"},
        {name:"GA",label:"Barrow County"},
        {name:"GA",label:"Bartow County"},
        {name:"GA",label:"Ben Hill County"},
        {name:"GA",label:"Berrien County"},
        {name:"GA",label:"Bibb County"},
        {name:"GA",label:"Bleckley County"},
        {name:"GA",label:"Brantley County"},
        {name:"GA",label:"Brooks County"},
        {name:"GA",label:"Bryan County"},
        {name:"GA",label:"Bulloch County"},
        {name:"GA",label:"Burke County"},
        {name:"GA",label:"Butts County"},
        {name:"GA",label:"Calhoun County"},
        {name:"GA",label:"Camden County"},
        {name:"GA",label:"Candler County"},
        {name:"GA",label:"Carroll County"},
        {name:"GA",label:"Catoosa County"},
        {name:"GA",label:"Charlton County"},
        {name:"GA",label:"Chatham County"},
        {name:"GA",label:"Chattahoochee County"},
        {name:"GA",label:"Chattooga County"},
        {name:"GA",label:"Cherokee County"},
        {name:"GA",label:"Clarke County"},
        {name:"GA",label:"Clay County"},
        {name:"GA",label:"Clayton County"},
        {name:"GA",label:"Clinch County"},
        {name:"GA",label:"Cobb County"},
        {name:"GA",label:"Coffee County"},
        {name:"GA",label:"Colquitt County"},
        {name:"GA",label:"Columbia County"},
        {name:"GA",label:"Cook County"},
        {name:"GA",label:"Coweta County"},
        {name:"GA",label:"Crawford County"},
        {name:"GA",label:"Crisp County"},
        {name:"GA",label:"Dade County"},
        {name:"GA",label:"Dawson County"},
        {name:"GA",label:"Decatur County"},
        {name:"GA",label:"DeKalb County"},
        {name:"GA",label:"Dodge County"},
        {name:"GA",label:"Dooly County"},
        {name:"GA",label:"Dougherty County"},
        {name:"GA",label:"Douglas County"},
        {name:"GA",label:"Early County"},
        {name:"GA",label:"Echols County"},
        {name:"GA",label:"Effingham County"},
        {name:"GA",label:"Elbert County"},
        {name:"GA",label:"Emanuel County"},
        {name:"GA",label:"Evans County"},
        {name:"GA",label:"Fannin County"},
        {name:"GA",label:"Fayette County"},
        {name:"GA",label:"Floyd County"},
        {name:"GA",label:"Forsyth County"},
        {name:"GA",label:"Franklin County"},
        {name:"GA",label:"Fulton County"},
        {name:"GA",label:"Gilmer County"},
        {name:"GA",label:"Glascock County"},
        {name:"GA",label:"Glynn County"},
        {name:"GA",label:"Gordon County"},
        {name:"GA",label:"Grady County"},
        {name:"GA",label:"Greene County"},
        {name:"GA",label:"Gwinnett County"},
        {name:"GA",label:"Habersham County"},
        {name:"GA",label:"Hall County"},
        {name:"GA",label:"Hancock County"},
        {name:"GA",label:"Haralson County"},
        {name:"GA",label:"Harris County"},
        {name:"GA",label:"Hart County"},
        {name:"GA",label:"Heard County"},
        {name:"GA",label:"Henry County"},
        {name:"GA",label:"Houston County"},
        {name:"GA",label:"Irwin County"},
        {name:"GA",label:"Jackson County"},
        {name:"GA",label:"Jasper County"},
        {name:"GA",label:"Jeff Davis County"},
        {name:"GA",label:"Jefferson County"},
        {name:"GA",label:"Jenkins County"},
        {name:"GA",label:"Johnson County"},
        {name:"GA",label:"Jones County"},
        {name:"GA",label:"Lamar County"},
        {name:"GA",label:"Lanier County"},
        {name:"GA",label:"Laurens County"},
        {name:"GA",label:"Lee County"},
        {name:"GA",label:"Liberty County"},
        {name:"GA",label:"Lincoln County"},
        {name:"GA",label:"Long County"},
        {name:"GA",label:"Lowndes County"},
        {name:"GA",label:"Lumpkin County"},
        {name:"GA",label:"McDuffie County"},
        {name:"GA",label:"McIntosh County"},
        {name:"GA",label:"Macon County"},
        {name:"GA",label:"Madison County"},
        {name:"GA",label:"Marion County"},
        {name:"GA",label:"Meriwether County"},
        {name:"GA",label:"Miller County"},
        {name:"GA",label:"Mitchell County"},
        {name:"GA",label:"Monroe County"},
        {name:"GA",label:"Montgomery County"},
        {name:"GA",label:"Morgan County"},
        {name:"GA",label:"Murray County"},
        {name:"GA",label:"Muscogee County"},
        {name:"GA",label:"Newton County"},
        {name:"GA",label:"Oconee County"},
        {name:"GA",label:"Oglethorpe County"},
        {name:"GA",label:"Paulding County"},
        {name:"GA",label:"Peach County"},
        {name:"GA",label:"Pickens County"},
        {name:"GA",label:"Pierce County"},
        {name:"GA",label:"Pike County"},
        {name:"GA",label:"Polk County"},
        {name:"GA",label:"Pulaski County"},
        {name:"GA",label:"Putnam County"},
        {name:"GA",label:"Quitman County"},
        {name:"GA",label:"Rabun County"},
        {name:"GA",label:"Randolph County"},
        {name:"GA",label:"Richmond County"},
        {name:"GA",label:"Rockdale County"},
        {name:"GA",label:"Schley County"},
        {name:"GA",label:"Screven County"},
        {name:"GA",label:"Seminole County"},
        {name:"GA",label:"Spalding County"},
        {name:"GA",label:"Stephens County"},
        {name:"GA",label:"Stewart County"},
        {name:"GA",label:"Sumter County"},
        {name:"GA",label:"Talbot County"},
        {name:"GA",label:"Taliaferro County"},
        {name:"GA",label:"Tattnall County"},
        {name:"GA",label:"Taylor County"},
        {name:"GA",label:"Telfair County"},
        {name:"GA",label:"Terrell County"},
        {name:"GA",label:"Thomas County"},
        {name:"GA",label:"Tift County"},
        {name:"GA",label:"Toombs County"},
        {name:"GA",label:"Towns County"},
        {name:"GA",label:"Treutlen County"},
        {name:"GA",label:"Troup County"},
        {name:"GA",label:"Turner County"},
        {name:"GA",label:"Twiggs County"},
        {name:"GA",label:"Union County"},
        {name:"GA",label:"Upson County"},
        {name:"GA",label:"Walker County"},
        {name:"GA",label:"Walton County"},
        {name:"GA",label:"Ware County"},
        {name:"GA",label:"Warren County"},
        {name:"GA",label:"Washington County"},
        {name:"GA",label:"Wayne County"},
        {name:"GA",label:"Webster County"},
        {name:"GA",label:"Wheeler County"},
        {name:"GA",label:"White County"},
        {name:"GA",label:"Whitfield County"},
        {name:"GA",label:"Wilcox County"},
        {name:"GA",label:"Wilkes County"},
        {name:"GA",label:"Wilkinson County"},
        {name:"GA",label:"Worth County"},
        {name:"HI",label:"Hawaii County"},
        {name:"HI",label:"Honolulu County"},
        {name:"HI",label:"Kalawao County"},
        {name:"HI",label:"Kauai County"},
        {name:"HI",label:"Maui County"},
        {name:"ID",label:"Ada County"},
        {name:"ID",label:"Adams County"},
        {name:"ID",label:"Bannock County"},
        {name:"ID",label:"Bear Lake County"},
        {name:"ID",label:"Benewah County"},
        {name:"ID",label:"Bingham County"},
        {name:"ID",label:"Blaine County"},
        {name:"ID",label:"Boise County"},
        {name:"ID",label:"Bonner County"},
        {name:"ID",label:"Bonneville County"},
        {name:"ID",label:"Boundary County"},
        {name:"ID",label:"Butte County"},
        {name:"ID",label:"Camas County"},
        {name:"ID",label:"Canyon County"},
        {name:"ID",label:"Caribou County"},
        {name:"ID",label:"Cassia County"},
        {name:"ID",label:"Clark County"},
        {name:"ID",label:"Clearwater County"},
        {name:"ID",label:"Custer County"},
        {name:"ID",label:"Elmore County"},
        {name:"ID",label:"Franklin County"},
        {name:"ID",label:"Fremont County"},
        {name:"ID",label:"Gem County"},
        {name:"ID",label:"Gooding County"},
        {name:"ID",label:"Idaho County"},
        {name:"ID",label:"Jefferson County"},
        {name:"ID",label:"Jerome County"},
        {name:"ID",label:"Kootenai County"},
        {name:"ID",label:"Latah County"},
        {name:"ID",label:"Lemhi County"},
        {name:"ID",label:"Lewis County"},
        {name:"ID",label:"Lincoln County"},
        {name:"ID",label:"Madison County"},
        {name:"ID",label:"Minidoka County"},
        {name:"ID",label:"Nez Perce County"},
        {name:"ID",label:"Oneida County"},
        {name:"ID",label:"Owyhee County"},
        {name:"ID",label:"Payette County"},
        {name:"ID",label:"Power County"},
        {name:"ID",label:"Shoshone County"},
        {name:"ID",label:"Teton County"},
        {name:"ID",label:"Twin Falls County"},
        {name:"ID",label:"Valley County"},
        {name:"ID",label:"Washington County"},
        {name:"IL",label:"Adams County"},
        {name:"IL",label:"Alexander County"},
        {name:"IL",label:"Bond County"},
        {name:"IL",label:"Boone County"},
        {name:"IL",label:"Brown County"},
        {name:"IL",label:"Bureau County"},
        {name:"IL",label:"Calhoun County"},
        {name:"IL",label:"Carroll County"},
        {name:"IL",label:"Cass County"},
        {name:"IL",label:"Champaign County"},
        {name:"IL",label:"Christian County"},
        {name:"IL",label:"Clark County"},
        {name:"IL",label:"Clay County"},
        {name:"IL",label:"Clinton County"},
        {name:"IL",label:"Coles County"},
        {name:"IL",label:"Cook County"},
        {name:"IL",label:"Crawford County"},
        {name:"IL",label:"Cumberland County"},
        {name:"IL",label:"DeKalb County"},
        {name:"IL",label:"De Witt County"},
        {name:"IL",label:"Douglas County"},
        {name:"IL",label:"DuPage County"},
        {name:"IL",label:"Edgar County"},
        {name:"IL",label:"Edwards County"},
        {name:"IL",label:"Effingham County"},
        {name:"IL",label:"Fayette County"},
        {name:"IL",label:"Ford County"},
        {name:"IL",label:"Franklin County"},
        {name:"IL",label:"Fulton County"},
        {name:"IL",label:"Gallatin County"},
        {name:"IL",label:"Greene County"},
        {name:"IL",label:"Grundy County"},
        {name:"IL",label:"Hamilton County"},
        {name:"IL",label:"Hancock County"},
        {name:"IL",label:"Hardin County"},
        {name:"IL",label:"Henderson County"},
        {name:"IL",label:"Henry County"},
        {name:"IL",label:"Iroquois County"},
        {name:"IL",label:"Jackson County"},
        {name:"IL",label:"Jasper County"},
        {name:"IL",label:"Jefferson County"},
        {name:"IL",label:"Jersey County"},
        {name:"IL",label:"Jo Daviess County"},
        {name:"IL",label:"Johnson County"},
        {name:"IL",label:"Kane County"},
        {name:"IL",label:"Kankakee County"},
        {name:"IL",label:"Kendall County"},
        {name:"IL",label:"Knox County"},
        {name:"IL",label:"Lake County"},
        {name:"IL",label:"LaSalle County"},
        {name:"IL",label:"Lawrence County"},
        {name:"IL",label:"Lee County"},
        {name:"IL",label:"Livingston County"},
        {name:"IL",label:"Logan County"},
        {name:"IL",label:"McDonough County"},
        {name:"IL",label:"McHenry County"},
        {name:"IL",label:"McLean County"},
        {name:"IL",label:"Macon County"},
        {name:"IL",label:"Macoupin County"},
        {name:"IL",label:"Madison County"},
        {name:"IL",label:"Marion County"},
        {name:"IL",label:"Marshall County"},
        {name:"IL",label:"Mason County"},
        {name:"IL",label:"Massac County"},
        {name:"IL",label:"Menard County"},
        {name:"IL",label:"Mercer County"},
        {name:"IL",label:"Monroe County"},
        {name:"IL",label:"Montgomery County"},
        {name:"IL",label:"Morgan County"},
        {name:"IL",label:"Moultrie County"},
        {name:"IL",label:"Ogle County"},
        {name:"IL",label:"Peoria County"},
        {name:"IL",label:"Perry County"},
        {name:"IL",label:"Piatt County"},
        {name:"IL",label:"Pike County"},
        {name:"IL",label:"Pope County"},
        {name:"IL",label:"Pulaski County"},
        {name:"IL",label:"Putnam County"},
        {name:"IL",label:"Randolph County"},
        {name:"IL",label:"Richland County"},
        {name:"IL",label:"Rock Island County"},
        {name:"IL",label:"St. Clair County"},
        {name:"IL",label:"Saline County"},
        {name:"IL",label:"Sangamon County"},
        {name:"IL",label:"Schuyler County"},
        {name:"IL",label:"Scott County"},
        {name:"IL",label:"Shelby County"},
        {name:"IL",label:"Stark County"},
        {name:"IL",label:"Stephenson County"},
        {name:"IL",label:"Tazewell County"},
        {name:"IL",label:"Union County"},
        {name:"IL",label:"Vermilion County"},
        {name:"IL",label:"Wabash County"},
        {name:"IL",label:"Warren County"},
        {name:"IL",label:"Washington County"},
        {name:"IL",label:"Wayne County"},
        {name:"IL",label:"White County"},
        {name:"IL",label:"Whiteside County"},
        {name:"IL",label:"Will County"},
        {name:"IL",label:"Williamson County"},
        {name:"IL",label:"Winnebago County"},
        {name:"IL",label:"Woodford County"},
        {name:"IN",label:"Adams County"},
        {name:"IN",label:"Allen County"},
        {name:"IN",label:"Bartholomew County"},
        {name:"IN",label:"Benton County"},
        {name:"IN",label:"Blackford County"},
        {name:"IN",label:"Boone County"},
        {name:"IN",label:"Brown County"},
        {name:"IN",label:"Carroll County"},
        {name:"IN",label:"Cass County"},
        {name:"IN",label:"Clark County"},
        {name:"IN",label:"Clay County"},
        {name:"IN",label:"Clinton County"},
        {name:"IN",label:"Crawford County"},
        {name:"IN",label:"Daviess County"},
        {name:"IN",label:"Dearborn County"},
        {name:"IN",label:"Decatur County"},
        {name:"IN",label:"DeKalb County"},
        {name:"IN",label:"Delaware County"},
        {name:"IN",label:"Dubois County"},
        {name:"IN",label:"Elkhart County"},
        {name:"IN",label:"Fayette County"},
        {name:"IN",label:"Floyd County"},
        {name:"IN",label:"Fountain County"},
        {name:"IN",label:"Franklin County"},
        {name:"IN",label:"Fulton County"},
        {name:"IN",label:"Gibson County"},
        {name:"IN",label:"Grant County"},
        {name:"IN",label:"Greene County"},
        {name:"IN",label:"Hamilton County"},
        {name:"IN",label:"Hancock County"},
        {name:"IN",label:"Harrison County"},
        {name:"IN",label:"Hendricks County"},
        {name:"IN",label:"Henry County"},
        {name:"IN",label:"Howard County"},
        {name:"IN",label:"Huntington County"},
        {name:"IN",label:"Jackson County"},
        {name:"IN",label:"Jasper County"},
        {name:"IN",label:"Jay County"},
        {name:"IN",label:"Jefferson County"},
        {name:"IN",label:"Jennings County"},
        {name:"IN",label:"Johnson County"},
        {name:"IN",label:"Knox County"},
        {name:"IN",label:"Kosciusko County"},
        {name:"IN",label:"LaGrange County"},
        {name:"IN",label:"Lake County"},
        {name:"IN",label:"LaPorte County"},
        {name:"IN",label:"Lawrence County"},
        {name:"IN",label:"Madison County"},
        {name:"IN",label:"Marion County"},
        {name:"IN",label:"Marshall County"},
        {name:"IN",label:"Martin County"},
        {name:"IN",label:"Miami County"},
        {name:"IN",label:"Monroe County"},
        {name:"IN",label:"Montgomery County"},
        {name:"IN",label:"Morgan County"},
        {name:"IN",label:"Newton County"},
        {name:"IN",label:"Noble County"},
        {name:"IN",label:"Ohio County"},
        {name:"IN",label:"Orange County"},
        {name:"IN",label:"Owen County"},
        {name:"IN",label:"Parke County"},
        {name:"IN",label:"Perry County"},
        {name:"IN",label:"Pike County"},
        {name:"IN",label:"Porter County"},
        {name:"IN",label:"Posey County"},
        {name:"IN",label:"Pulaski County"},
        {name:"IN",label:"Putnam County"},
        {name:"IN",label:"Randolph County"},
        {name:"IN",label:"Ripley County"},
        {name:"IN",label:"Rush County"},
        {name:"IN",label:"St. Joseph County"},
        {name:"IN",label:"Scott County"},
        {name:"IN",label:"Shelby County"},
        {name:"IN",label:"Spencer County"},
        {name:"IN",label:"Starke County"},
        {name:"IN",label:"Steuben County"},
        {name:"IN",label:"Sullivan County"},
        {name:"IN",label:"Switzerland County"},
        {name:"IN",label:"Tippecanoe County"},
        {name:"IN",label:"Tipton County"},
        {name:"IN",label:"Union County"},
        {name:"IN",label:"Vanderburgh County"},
        {name:"IN",label:"Vermillion County"},
        {name:"IN",label:"Vigo County"},
        {name:"IN",label:"Wabash County"},
        {name:"IN",label:"Warren County"},
        {name:"IN",label:"Warrick County"},
        {name:"IN",label:"Washington County"},
        {name:"IN",label:"Wayne County"},
        {name:"IN",label:"Wells County"},
        {name:"IN",label:"White County"},
        {name:"IN",label:"Whitley County"},
        {name:"IA",label:"Adair County"},
        {name:"IA",label:"Adams County"},
        {name:"IA",label:"Allamakee County"},
        {name:"IA",label:"Appanoose County"},
        {name:"IA",label:"Audubon County"},
        {name:"IA",label:"Benton County"},
        {name:"IA",label:"Black Hawk County"},
        {name:"IA",label:"Boone County"},
        {name:"IA",label:"Bremer County"},
        {name:"IA",label:"Buchanan County"},
        {name:"IA",label:"Buena Vista County"},
        {name:"IA",label:"Butler County"},
        {name:"IA",label:"Calhoun County"},
        {name:"IA",label:"Carroll County"},
        {name:"IA",label:"Cass County"},
        {name:"IA",label:"Cedar County"},
        {name:"IA",label:"Cerro Gordo County"},
        {name:"IA",label:"Cherokee County"},
        {name:"IA",label:"Chickasaw County"},
        {name:"IA",label:"Clarke County"},
        {name:"IA",label:"Clay County"},
        {name:"IA",label:"Clayton County"},
        {name:"IA",label:"Clinton County"},
        {name:"IA",label:"Crawford County"},
        {name:"IA",label:"Dallas County"},
        {name:"IA",label:"Davis County"},
        {name:"IA",label:"Decatur County"},
        {name:"IA",label:"Delaware County"},
        {name:"IA",label:"Des Moines County"},
        {name:"IA",label:"Dickinson County"},
        {name:"IA",label:"Dubuque County"},
        {name:"IA",label:"Emmet County"},
        {name:"IA",label:"Fayette County"},
        {name:"IA",label:"Floyd County"},
        {name:"IA",label:"Franklin County"},
        {name:"IA",label:"Fremont County"},
        {name:"IA",label:"Greene County"},
        {name:"IA",label:"Grundy County"},
        {name:"IA",label:"Guthrie County"},
        {name:"IA",label:"Hamilton County"},
        {name:"IA",label:"Hancock County"},
        {name:"IA",label:"Hardin County"},
        {name:"IA",label:"Harrison County"},
        {name:"IA",label:"Henry County"},
        {name:"IA",label:"Howard County"},
        {name:"IA",label:"Humboldt County"},
        {name:"IA",label:"Ida County"},
        {name:"IA",label:"Iowa County"},
        {name:"IA",label:"Jackson County"},
        {name:"IA",label:"Jasper County"},
        {name:"IA",label:"Jefferson County"},
        {name:"IA",label:"Johnson County"},
        {name:"IA",label:"Jones County"},
        {name:"IA",label:"Keokuk County"},
        {name:"IA",label:"Kossuth County"},
        {name:"IA",label:"Lee County"},
        {name:"IA",label:"Linn County"},
        {name:"IA",label:"Louisa County"},
        {name:"IA",label:"Lucas County"},
        {name:"IA",label:"Lyon County"},
        {name:"IA",label:"Madison County"},
        {name:"IA",label:"Mahaska County"},
        {name:"IA",label:"Marion County"},
        {name:"IA",label:"Marshall County"},
        {name:"IA",label:"Mills County"},
        {name:"IA",label:"Mitchell County"},
        {name:"IA",label:"Monona County"},
        {name:"IA",label:"Monroe County"},
        {name:"IA",label:"Montgomery County"},
        {name:"IA",label:"Muscatine County"},
        {name:"IA",label:"O'Brien County"},
        {name:"IA",label:"Osceola County"},
        {name:"IA",label:"Page County"},
        {name:"IA",label:"Palo Alto County"},
        {name:"IA",label:"Plymouth County"},
        {name:"IA",label:"Pocahontas County"},
        {name:"IA",label:"Polk County"},
        {name:"IA",label:"Pottawattamie County"},
        {name:"IA",label:"Poweshiek County"},
        {name:"IA",label:"Ringgold County"},
        {name:"IA",label:"Sac County"},
        {name:"IA",label:"Scott County"},
        {name:"IA",label:"Shelby County"},
        {name:"IA",label:"Sioux County"},
        {name:"IA",label:"Story County"},
        {name:"IA",label:"Tama County"},
        {name:"IA",label:"Taylor County"},
        {name:"IA",label:"Union County"},
        {name:"IA",label:"Van Buren County"},
        {name:"IA",label:"Wapello County"},
        {name:"IA",label:"Warren County"},
        {name:"IA",label:"Washington County"},
        {name:"IA",label:"Wayne County"},
        {name:"IA",label:"Webster County"},
        {name:"IA",label:"Winnebago County"},
        {name:"IA",label:"Winneshiek County"},
        {name:"IA",label:"Woodbury County"},
        {name:"IA",label:"Worth County"},
        {name:"IA",label:"Wright County"},
        {name:"KS",label:"Allen County"},
        {name:"KS",label:"Anderson County"},
        {name:"KS",label:"Atchison County"},
        {name:"KS",label:"Barber County"},
        {name:"KS",label:"Barton County"},
        {name:"KS",label:"Bourbon County"},
        {name:"KS",label:"Brown County"},
        {name:"KS",label:"Butler County"},
        {name:"KS",label:"Chase County"},
        {name:"KS",label:"Chautauqua County"},
        {name:"KS",label:"Cherokee County"},
        {name:"KS",label:"Cheyenne County"},
        {name:"KS",label:"Clark County"},
        {name:"KS",label:"Clay County"},
        {name:"KS",label:"Cloud County"},
        {name:"KS",label:"Coffey County"},
        {name:"KS",label:"Comanche County"},
        {name:"KS",label:"Cowley County"},
        {name:"KS",label:"Crawford County"},
        {name:"KS",label:"Decatur County"},
        {name:"KS",label:"Dickinson County"},
        {name:"KS",label:"Doniphan County"},
        {name:"KS",label:"Douglas County"},
        {name:"KS",label:"Edwards County"},
        {name:"KS",label:"Elk County"},
        {name:"KS",label:"Ellis County"},
        {name:"KS",label:"Ellsworth County"},
        {name:"KS",label:"Finney County"},
        {name:"KS",label:"Ford County"},
        {name:"KS",label:"Franklin County"},
        {name:"KS",label:"Geary County"},
        {name:"KS",label:"Gove County"},
        {name:"KS",label:"Graham County"},
        {name:"KS",label:"Grant County"},
        {name:"KS",label:"Gray County"},
        {name:"KS",label:"Greeley County"},
        {name:"KS",label:"Greenwood County"},
        {name:"KS",label:"Hamilton County"},
        {name:"KS",label:"Harper County"},
        {name:"KS",label:"Harvey County"},
        {name:"KS",label:"Haskell County"},
        {name:"KS",label:"Hodgeman County"},
        {name:"KS",label:"Jackson County"},
        {name:"KS",label:"Jefferson County"},
        {name:"KS",label:"Jewell County"},
        {name:"KS",label:"Johnson County"},
        {name:"KS",label:"Kearny County"},
        {name:"KS",label:"Kingman County"},
        {name:"KS",label:"Kiowa County"},
        {name:"KS",label:"Labette County"},
        {name:"KS",label:"Lane County"},
        {name:"KS",label:"Leavenworth County"},
        {name:"KS",label:"Lincoln County"},
        {name:"KS",label:"Linn County"},
        {name:"KS",label:"Logan County"},
        {name:"KS",label:"Lyon County"},
        {name:"KS",label:"McPherson County"},
        {name:"KS",label:"Marion County"},
        {name:"KS",label:"Marshall County"},
        {name:"KS",label:"Meade County"},
        {name:"KS",label:"Miami County"},
        {name:"KS",label:"Mitchell County"},
        {name:"KS",label:"Montgomery County"},
        {name:"KS",label:"Morris County"},
        {name:"KS",label:"Morton County"},
        {name:"KS",label:"Nemaha County"},
        {name:"KS",label:"Neosho County"},
        {name:"KS",label:"Ness County"},
        {name:"KS",label:"Norton County"},
        {name:"KS",label:"Osage County"},
        {name:"KS",label:"Osborne County"},
        {name:"KS",label:"Ottawa County"},
        {name:"KS",label:"Pawnee County"},
        {name:"KS",label:"Phillips County"},
        {name:"KS",label:"Pottawatomie County"},
        {name:"KS",label:"Pratt County"},
        {name:"KS",label:"Rawlins County"},
        {name:"KS",label:"Reno County"},
        {name:"KS",label:"Republic County"},
        {name:"KS",label:"Rice County"},
        {name:"KS",label:"Riley County"},
        {name:"KS",label:"Rooks County"},
        {name:"KS",label:"Rush County"},
        {name:"KS",label:"Russell County"},
        {name:"KS",label:"Saline County"},
        {name:"KS",label:"Scott County"},
        {name:"KS",label:"Sedgwick County"},
        {name:"KS",label:"Seward County"},
        {name:"KS",label:"Shawnee County"},
        {name:"KS",label:"Sheridan County"},
        {name:"KS",label:"Sherman County"},
        {name:"KS",label:"Smith County"},
        {name:"KS",label:"Stafford County"},
        {name:"KS",label:"Stanton County"},
        {name:"KS",label:"Stevens County"},
        {name:"KS",label:"Sumner County"},
        {name:"KS",label:"Thomas County"},
        {name:"KS",label:"Trego County"},
        {name:"KS",label:"Wabaunsee County"},
        {name:"KS",label:"Wallace County"},
        {name:"KS",label:"Washington County"},
        {name:"KS",label:"Wichita County"},
        {name:"KS",label:"Wilson County"},
        {name:"KS",label:"Woodson County"},
        {name:"KS",label:"Wyandotte County"},
        {name:"KY",label:"Adair County"},
        {name:"KY",label:"Allen County"},
        {name:"KY",label:"Anderson County"},
        {name:"KY",label:"Ballard County"},
        {name:"KY",label:"Barren County"},
        {name:"KY",label:"Bath County"},
        {name:"KY",label:"Bell County"},
        {name:"KY",label:"Boone County"},
        {name:"KY",label:"Bourbon County"},
        {name:"KY",label:"Boyd County"},
        {name:"KY",label:"Boyle County"},
        {name:"KY",label:"Bracken County"},
        {name:"KY",label:"Breathitt County"},
        {name:"KY",label:"Breckinridge County"},
        {name:"KY",label:"Bullitt County"},
        {name:"KY",label:"Butler County"},
        {name:"KY",label:"Caldwell County"},
        {name:"KY",label:"Calloway County"},
        {name:"KY",label:"Campbell County"},
        {name:"KY",label:"Carlisle County"},
        {name:"KY",label:"Carroll County"},
        {name:"KY",label:"Carter County"},
        {name:"KY",label:"Casey County"},
        {name:"KY",label:"Christian County"},
        {name:"KY",label:"Clark County"},
        {name:"KY",label:"Clay County"},
        {name:"KY",label:"Clinton County"},
        {name:"KY",label:"Crittenden County"},
        {name:"KY",label:"Cumberland County"},
        {name:"KY",label:"Daviess County"},
        {name:"KY",label:"Edmonson County"},
        {name:"KY",label:"Elliott County"},
        {name:"KY",label:"Estill County"},
        {name:"KY",label:"Fayette County"},
        {name:"KY",label:"Fleming County"},
        {name:"KY",label:"Floyd County"},
        {name:"KY",label:"Franklin County"},
        {name:"KY",label:"Fulton County"},
        {name:"KY",label:"Gallatin County"},
        {name:"KY",label:"Garrard County"},
        {name:"KY",label:"Grant County"},
        {name:"KY",label:"Graves County"},
        {name:"KY",label:"Grayson County"},
        {name:"KY",label:"Green County"},
        {name:"KY",label:"Greenup County"},
        {name:"KY",label:"Hancock County"},
        {name:"KY",label:"Hardin County"},
        {name:"KY",label:"Harlan County"},
        {name:"KY",label:"Harrison County"},
        {name:"KY",label:"Hart County"},
        {name:"KY",label:"Henderson County"},
        {name:"KY",label:"Henry County"},
        {name:"KY",label:"Hickman County"},
        {name:"KY",label:"Hopkins County"},
        {name:"KY",label:"Jackson County"},
        {name:"KY",label:"Jefferson County"},
        {name:"KY",label:"Jessamine County"},
        {name:"KY",label:"Johnson County"},
        {name:"KY",label:"Kenton County"},
        {name:"KY",label:"Knott County"},
        {name:"KY",label:"Knox County"},
        {name:"KY",label:"Larue County"},
        {name:"KY",label:"Laurel County"},
        {name:"KY",label:"Lawrence County"},
        {name:"KY",label:"Lee County"},
        {name:"KY",label:"Leslie County"},
        {name:"KY",label:"Letcher County"},
        {name:"KY",label:"Lewis County"},
        {name:"KY",label:"Lincoln County"},
        {name:"KY",label:"Livingston County"},
        {name:"KY",label:"Logan County"},
        {name:"KY",label:"Lyon County"},
        {name:"KY",label:"McCracken County"},
        {name:"KY",label:"McCreary County"},
        {name:"KY",label:"McLean County"},
        {name:"KY",label:"Madison County"},
        {name:"KY",label:"Magoffin County"},
        {name:"KY",label:"Marion County"},
        {name:"KY",label:"Marshall County"},
        {name:"KY",label:"Martin County"},
        {name:"KY",label:"Mason County"},
        {name:"KY",label:"Meade County"},
        {name:"KY",label:"Menifee County"},
        {name:"KY",label:"Mercer County"},
        {name:"KY",label:"Metcalfe County"},
        {name:"KY",label:"Monroe County"},
        {name:"KY",label:"Montgomery County"},
        {name:"KY",label:"Morgan County"},
        {name:"KY",label:"Muhlenberg County"},
        {name:"KY",label:"Nelson County"},
        {name:"KY",label:"Nicholas County"},
        {name:"KY",label:"Ohio County"},
        {name:"KY",label:"Oldham County"},
        {name:"KY",label:"Owen County"},
        {name:"KY",label:"Owsley County"},
        {name:"KY",label:"Pendleton County"},
        {name:"KY",label:"Perry County"},
        {name:"KY",label:"Pike County"},
        {name:"KY",label:"Powell County"},
        {name:"KY",label:"Pulaski County"},
        {name:"KY",label:"Robertson County"},
        {name:"KY",label:"Rockcastle County"},
        {name:"KY",label:"Rowan County"},
        {name:"KY",label:"Russell County"},
        {name:"KY",label:"Scott County"},
        {name:"KY",label:"Shelby County"},
        {name:"KY",label:"Simpson County"},
        {name:"KY",label:"Spencer County"},
        {name:"KY",label:"Taylor County"},
        {name:"KY",label:"Todd County"},
        {name:"KY",label:"Trigg County"},
        {name:"KY",label:"Trimble County"},
        {name:"KY",label:"Union County"},
        {name:"KY",label:"Warren County"},
        {name:"KY",label:"Washington County"},
        {name:"KY",label:"Wayne County"},
        {name:"KY",label:"Webster County"},
        {name:"KY",label:"Whitley County"},
        {name:"KY",label:"Wolfe County"},
        {name:"KY",label:"Woodford County"},
        {name:"LA",label:"Acadia Parish"},
        {name:"LA",label:"Allen Parish"},
        {name:"LA",label:"Ascension Parish"},
        {name:"LA",label:"Assumption Parish"},
        {name:"LA",label:"Avoyelles Parish"},
        {name:"LA",label:"Beauregard Parish"},
        {name:"LA",label:"Bienville Parish"},
        {name:"LA",label:"Bossier Parish"},
        {name:"LA",label:"Caddo Parish"},
        {name:"LA",label:"Calcasieu Parish"},
        {name:"LA",label:"Caldwell Parish"},
        {name:"LA",label:"Cameron Parish"},
        {name:"LA",label:"Catahoula Parish"},
        {name:"LA",label:"Claiborne Parish"},
        {name:"LA",label:"Concordia Parish"},
        {name:"LA",label:"De Soto Parish"},
        {name:"LA",label:"East Baton Rouge Parish"},
        {name:"LA",label:"East Carroll Parish"},
        {name:"LA",label:"East Feliciana Parish"},
        {name:"LA",label:"Evangeline Parish"},
        {name:"LA",label:"Franklin Parish"},
        {name:"LA",label:"Grant Parish"},
        {name:"LA",label:"Iberia Parish"},
        {name:"LA",label:"Iberville Parish"},
        {name:"LA",label:"Jackson Parish"},
        {name:"LA",label:"Jefferson Parish"},
        {name:"LA",label:"Jefferson Davis Parish"},
        {name:"LA",label:"Lafayette Parish"},
        {name:"LA",label:"Lafourche Parish"},
        {name:"LA",label:"La Salle Parish"},
        {name:"LA",label:"Lincoln Parish"},
        {name:"LA",label:"Livingston Parish"},
        {name:"LA",label:"Madison Parish"},
        {name:"LA",label:"Morehouse Parish"},
        {name:"LA",label:"Natchitoches Parish"},
        {name:"LA",label:"Orleans Parish"},
        {name:"LA",label:"Ouachita Parish"},
        {name:"LA",label:"Plaquemines Parish"},
        {name:"LA",label:"Pointe Coupee Parish"},
        {name:"LA",label:"Rapides Parish"},
        {name:"LA",label:"Red River Parish"},
        {name:"LA",label:"Richland Parish"},
        {name:"LA",label:"Sabine Parish"},
        {name:"LA",label:"St. Bernard Parish"},
        {name:"LA",label:"St. Charles Parish"},
        {name:"LA",label:"St. Helena Parish"},
        {name:"LA",label:"St. James Parish"},
        {name:"LA",label:"St. John the Baptist Parish"},
        {name:"LA",label:"St. Landry Parish"},
        {name:"LA",label:"St. Martin Parish"},
        {name:"LA",label:"St. Mary Parish"},
        {name:"LA",label:"St. Tammany Parish"},
        {name:"LA",label:"Tangipahoa Parish"},
        {name:"LA",label:"Tensas Parish"},
        {name:"LA",label:"Terrebonne Parish"},
        {name:"LA",label:"Union Parish"},
        {name:"LA",label:"Vermilion Parish"},
        {name:"LA",label:"Vernon Parish"},
        {name:"LA",label:"Washington Parish"},
        {name:"LA",label:"Webster Parish"},
        {name:"LA",label:"West Baton Rouge Parish"},
        {name:"LA",label:"West Carroll Parish"},
        {name:"LA",label:"West Feliciana Parish"},
        {name:"LA",label:"Winn Parish"},
        {name:"ME",label:"Androscoggin County"},
        {name:"ME",label:"Aroostook County"},
        {name:"ME",label:"Cumberland County"},
        {name:"ME",label:"Franklin County"},
        {name:"ME",label:"Hancock County"},
        {name:"ME",label:"Kennebec County"},
        {name:"ME",label:"Knox County"},
        {name:"ME",label:"Lincoln County"},
        {name:"ME",label:"Oxford County"},
        {name:"ME",label:"Penobscot County"},
        {name:"ME",label:"Piscataquis County"},
        {name:"ME",label:"Sagadahoc County"},
        {name:"ME",label:"Somerset County"},
        {name:"ME",label:"Waldo County"},
        {name:"ME",label:"Washington County"},
        {name:"ME",label:"York County"},
        {name:"MD",label:"Allegany County"},
        {name:"MD",label:"Anne Arundel County"},
        {name:"MD",label:"Baltimore County"},
        {name:"MD",label:"Calvert County"},
        {name:"MD",label:"Caroline County"},
        {name:"MD",label:"Carroll County"},
        {name:"MD",label:"Cecil County"},
        {name:"MD",label:"Charles County"},
        {name:"MD",label:"Dorchester County"},
        {name:"MD",label:"Frederick County"},
        {name:"MD",label:"Garrett County"},
        {name:"MD",label:"Harford County"},
        {name:"MD",label:"Howard County"},
        {name:"MD",label:"Kent County"},
        {name:"MD",label:"Montgomery County"},
        {name:"MD",label:"Prince George's County"},
        {name:"MD",label:"Queen Anne's County"},
        {name:"MD",label:"St. Mary's County"},
        {name:"MD",label:"Somerset County"},
        {name:"MD",label:"Talbot County"},
        {name:"MD",label:"Washington County"},
        {name:"MD",label:"Wicomico County"},
        {name:"MD",label:"Worcester County"},
        {name:"MD",label:"Baltimore city"},
        {name:"MA",label:"Barnstable County"},
        {name:"MA",label:"Berkshire County"},
        {name:"MA",label:"Bristol County"},
        {name:"MA",label:"Dukes County"},
        {name:"MA",label:"Essex County"},
        {name:"MA",label:"Franklin County"},
        {name:"MA",label:"Hampden County"},
        {name:"MA",label:"Hampshire County"},
        {name:"MA",label:"Middlesex County"},
        {name:"MA",label:"Nantucket County"},
        {name:"MA",label:"Norfolk County"},
        {name:"MA",label:"Plymouth County"},
        {name:"MA",label:"Suffolk County"},
        {name:"MA",label:"Worcester County"},
        {name:"MI",label:"Alcona County"},
        {name:"MI",label:"Alger County"},
        {name:"MI",label:"Allegan County"},
        {name:"MI",label:"Alpena County"},
        {name:"MI",label:"Antrim County"},
        {name:"MI",label:"Arenac County"},
        {name:"MI",label:"Baraga County"},
        {name:"MI",label:"Barry County"},
        {name:"MI",label:"Bay County"},
        {name:"MI",label:"Benzie County"},
        {name:"MI",label:"Berrien County"},
        {name:"MI",label:"Branch County"},
        {name:"MI",label:"Calhoun County"},
        {name:"MI",label:"Cass County"},
        {name:"MI",label:"Charlevoix County"},
        {name:"MI",label:"Cheboygan County"},
        {name:"MI",label:"Chippewa County"},
        {name:"MI",label:"Clare County"},
        {name:"MI",label:"Clinton County"},
        {name:"MI",label:"Crawford County"},
        {name:"MI",label:"Delta County"},
        {name:"MI",label:"Dickinson County"},
        {name:"MI",label:"Eaton County"},
        {name:"MI",label:"Emmet County"},
        {name:"MI",label:"Genesee County"},
        {name:"MI",label:"Gladwin County"},
        {name:"MI",label:"Gogebic County"},
        {name:"MI",label:"Grand Traverse County"},
        {name:"MI",label:"Gratiot County"},
        {name:"MI",label:"Hillsdale County"},
        {name:"MI",label:"Houghton County"},
        {name:"MI",label:"Huron County"},
        {name:"MI",label:"Ingham County"},
        {name:"MI",label:"Ionia County"},
        {name:"MI",label:"Iosco County"},
        {name:"MI",label:"Iron County"},
        {name:"MI",label:"Isabella County"},
        {name:"MI",label:"Jackson County"},
        {name:"MI",label:"Kalamazoo County"},
        {name:"MI",label:"Kalkaska County"},
        {name:"MI",label:"Kent County"},
        {name:"MI",label:"Keweenaw County"},
        {name:"MI",label:"Lake County"},
        {name:"MI",label:"Lapeer County"},
        {name:"MI",label:"Leelanau County"},
        {name:"MI",label:"Lenawee County"},
        {name:"MI",label:"Livingston County"},
        {name:"MI",label:"Luce County"},
        {name:"MI",label:"Mackinac County"},
        {name:"MI",label:"Macomb County"},
        {name:"MI",label:"Manistee County"},
        {name:"MI",label:"Marquette County"},
        {name:"MI",label:"Mason County"},
        {name:"MI",label:"Mecosta County"},
        {name:"MI",label:"Menominee County"},
        {name:"MI",label:"Midland County"},
        {name:"MI",label:"Missaukee County"},
        {name:"MI",label:"Monroe County"},
        {name:"MI",label:"Montcalm County"},
        {name:"MI",label:"Montmorency County"},
        {name:"MI",label:"Muskegon County"},
        {name:"MI",label:"Newaygo County"},
        {name:"MI",label:"Oakland County"},
        {name:"MI",label:"Oceana County"},
        {name:"MI",label:"Ogemaw County"},
        {name:"MI",label:"Ontonagon County"},
        {name:"MI",label:"Osceola County"},
        {name:"MI",label:"Oscoda County"},
        {name:"MI",label:"Otsego County"},
        {name:"MI",label:"Ottawa County"},
        {name:"MI",label:"Presque Isle County"},
        {name:"MI",label:"Roscommon County"},
        {name:"MI",label:"Saginaw County"},
        {name:"MI",label:"St. Clair County"},
        {name:"MI",label:"St. Joseph County"},
        {name:"MI",label:"Sanilac County"},
        {name:"MI",label:"Schoolcraft County"},
        {name:"MI",label:"Shiawassee County"},
        {name:"MI",label:"Tuscola County"},
        {name:"MI",label:"Van Buren County"},
        {name:"MI",label:"Washtenaw County"},
        {name:"MI",label:"Wayne County"},
        {name:"MI",label:"Wexford County"},
        {name:"MN",label:"Aitkin County"},
        {name:"MN",label:"Anoka County"},
        {name:"MN",label:"Becker County"},
        {name:"MN",label:"Beltrami County"},
        {name:"MN",label:"Benton County"},
        {name:"MN",label:"Big Stone County"},
        {name:"MN",label:"Blue Earth County"},
        {name:"MN",label:"Brown County"},
        {name:"MN",label:"Carlton County"},
        {name:"MN",label:"Carver County"},
        {name:"MN",label:"Cass County"},
        {name:"MN",label:"Chippewa County"},
        {name:"MN",label:"Chisago County"},
        {name:"MN",label:"Clay County"},
        {name:"MN",label:"Clearwater County"},
        {name:"MN",label:"Cook County"},
        {name:"MN",label:"Cottonwood County"},
        {name:"MN",label:"Crow Wing County"},
        {name:"MN",label:"Dakota County"},
        {name:"MN",label:"Dodge County"},
        {name:"MN",label:"Douglas County"},
        {name:"MN",label:"Faribault County"},
        {name:"MN",label:"Fillmore County"},
        {name:"MN",label:"Freeborn County"},
        {name:"MN",label:"Goodhue County"},
        {name:"MN",label:"Grant County"},
        {name:"MN",label:"Hennepin County"},
        {name:"MN",label:"Houston County"},
        {name:"MN",label:"Hubbard County"},
        {name:"MN",label:"Isanti County"},
        {name:"MN",label:"Itasca County"},
        {name:"MN",label:"Jackson County"},
        {name:"MN",label:"Kanabec County"},
        {name:"MN",label:"Kandiyohi County"},
        {name:"MN",label:"Kittson County"},
        {name:"MN",label:"Koochiching County"},
        {name:"MN",label:"Lac qui Parle County"},
        {name:"MN",label:"Lake County"},
        {name:"MN",label:"Lake of the Woods County"},
        {name:"MN",label:"Le Sueur County"},
        {name:"MN",label:"Lincoln County"},
        {name:"MN",label:"Lyon County"},
        {name:"MN",label:"McLeod County"},
        {name:"MN",label:"Mahnomen County"},
        {name:"MN",label:"Marshall County"},
        {name:"MN",label:"Martin County"},
        {name:"MN",label:"Meeker County"},
        {name:"MN",label:"Mille Lacs County"},
        {name:"MN",label:"Morrison County"},
        {name:"MN",label:"Mower County"},
        {name:"MN",label:"Murray County"},
        {name:"MN",label:"Nicollet County"},
        {name:"MN",label:"Nobles County"},
        {name:"MN",label:"Norman County"},
        {name:"MN",label:"Olmsted County"},
        {name:"MN",label:"Otter Tail County"},
        {name:"MN",label:"Pennington County"},
        {name:"MN",label:"Pine County"},
        {name:"MN",label:"Pipestone County"},
        {name:"MN",label:"Polk County"},
        {name:"MN",label:"Pope County"},
        {name:"MN",label:"Ramsey County"},
        {name:"MN",label:"Red Lake County"},
        {name:"MN",label:"Redwood County"},
        {name:"MN",label:"Renville County"},
        {name:"MN",label:"Rice County"},
        {name:"MN",label:"Rock County"},
        {name:"MN",label:"Roseau County"},
        {name:"MN",label:"St. Louis County"},
        {name:"MN",label:"Scott County"},
        {name:"MN",label:"Sherburne County"},
        {name:"MN",label:"Sibley County"},
        {name:"MN",label:"Stearns County"},
        {name:"MN",label:"Steele County"},
        {name:"MN",label:"Stevens County"},
        {name:"MN",label:"Swift County"},
        {name:"MN",label:"Todd County"},
        {name:"MN",label:"Traverse County"},
        {name:"MN",label:"Wabasha County"},
        {name:"MN",label:"Wadena County"},
        {name:"MN",label:"Waseca County"},
        {name:"MN",label:"Washington County"},
        {name:"MN",label:"Watonwan County"},
        {name:"MN",label:"Wilkin County"},
        {name:"MN",label:"Winona County"},
        {name:"MN",label:"Wright County"},
        {name:"MN",label:"Yellow Medicine County"},
        {name:"MS",label:"Adams County"},
        {name:"MS",label:"Alcorn County"},
        {name:"MS",label:"Amite County"},
        {name:"MS",label:"Attala County"},
        {name:"MS",label:"Benton County"},
        {name:"MS",label:"Bolivar County"},
        {name:"MS",label:"Calhoun County"},
        {name:"MS",label:"Carroll County"},
        {name:"MS",label:"Chickasaw County"},
        {name:"MS",label:"Choctaw County"},
        {name:"MS",label:"Claiborne County"},
        {name:"MS",label:"Clarke County"},
        {name:"MS",label:"Clay County"},
        {name:"MS",label:"Coahoma County"},
        {name:"MS",label:"Copiah County"},
        {name:"MS",label:"Covington County"},
        {name:"MS",label:"DeSoto County"},
        {name:"MS",label:"Forrest County"},
        {name:"MS",label:"Franklin County"},
        {name:"MS",label:"George County"},
        {name:"MS",label:"Greene County"},
        {name:"MS",label:"Grenada County"},
        {name:"MS",label:"Hancock County"},
        {name:"MS",label:"Harrison County"},
        {name:"MS",label:"Hinds County"},
        {name:"MS",label:"Holmes County"},
        {name:"MS",label:"Humphreys County"},
        {name:"MS",label:"Issaquena County"},
        {name:"MS",label:"Itawamba County"},
        {name:"MS",label:"Jackson County"},
        {name:"MS",label:"Jasper County"},
        {name:"MS",label:"Jefferson County"},
        {name:"MS",label:"Jefferson Davis County"},
        {name:"MS",label:"Jones County"},
        {name:"MS",label:"Kemper County"},
        {name:"MS",label:"Lafayette County"},
        {name:"MS",label:"Lamar County"},
        {name:"MS",label:"Lauderdale County"},
        {name:"MS",label:"Lawrence County"},
        {name:"MS",label:"Leake County"},
        {name:"MS",label:"Lee County"},
        {name:"MS",label:"Leflore County"},
        {name:"MS",label:"Lincoln County"},
        {name:"MS",label:"Lowndes County"},
        {name:"MS",label:"Madison County"},
        {name:"MS",label:"Marion County"},
        {name:"MS",label:"Marshall County"},
        {name:"MS",label:"Monroe County"},
        {name:"MS",label:"Montgomery County"},
        {name:"MS",label:"Neshoba County"},
        {name:"MS",label:"Newton County"},
        {name:"MS",label:"Noxubee County"},
        {name:"MS",label:"Oktibbeha County"},
        {name:"MS",label:"Panola County"},
        {name:"MS",label:"Pearl River County"},
        {name:"MS",label:"Perry County"},
        {name:"MS",label:"Pike County"},
        {name:"MS",label:"Pontotoc County"},
        {name:"MS",label:"Prentiss County"},
        {name:"MS",label:"Quitman County"},
        {name:"MS",label:"Rankin County"},
        {name:"MS",label:"Scott County"},
        {name:"MS",label:"Sharkey County"},
        {name:"MS",label:"Simpson County"},
        {name:"MS",label:"Smith County"},
        {name:"MS",label:"Stone County"},
        {name:"MS",label:"Sunflower County"},
        {name:"MS",label:"Tallahatchie County"},
        {name:"MS",label:"Tate County"},
        {name:"MS",label:"Tippah County"},
        {name:"MS",label:"Tishomingo County"},
        {name:"MS",label:"Tunica County"},
        {name:"MS",label:"Union County"},
        {name:"MS",label:"Walthall County"},
        {name:"MS",label:"Warren County"},
        {name:"MS",label:"Washington County"},
        {name:"MS",label:"Wayne County"},
        {name:"MS",label:"Webster County"},
        {name:"MS",label:"Wilkinson County"},
        {name:"MS",label:"Winston County"},
        {name:"MS",label:"Yalobusha County"},
        {name:"MS",label:"Yazoo County"},
        {name:"MO",label:"Adair County"},
        {name:"MO",label:"Andrew County"},
        {name:"MO",label:"Atchison County"},
        {name:"MO",label:"Audrain County"},
        {name:"MO",label:"Barry County"},
        {name:"MO",label:"Barton County"},
        {name:"MO",label:"Bates County"},
        {name:"MO",label:"Benton County"},
        {name:"MO",label:"Bollinger County"},
        {name:"MO",label:"Boone County"},
        {name:"MO",label:"Buchanan County"},
        {name:"MO",label:"Butler County"},
        {name:"MO",label:"Caldwell County"},
        {name:"MO",label:"Callaway County"},
        {name:"MO",label:"Camden County"},
        {name:"MO",label:"Cape Girardeau County"},
        {name:"MO",label:"Carroll County"},
        {name:"MO",label:"Carter County"},
        {name:"MO",label:"Cass County"},
        {name:"MO",label:"Cedar County"},
        {name:"MO",label:"Chariton County"},
        {name:"MO",label:"Christian County"},
        {name:"MO",label:"Clark County"},
        {name:"MO",label:"Clay County"},
        {name:"MO",label:"Clinton County"},
        {name:"MO",label:"Cole County"},
        {name:"MO",label:"Cooper County"},
        {name:"MO",label:"Crawford County"},
        {name:"MO",label:"Dade County"},
        {name:"MO",label:"Dallas County"},
        {name:"MO",label:"Daviess County"},
        {name:"MO",label:"DeKalb County"},
        {name:"MO",label:"Dent County"},
        {name:"MO",label:"Douglas County"},
        {name:"MO",label:"Dunklin County"},
        {name:"MO",label:"Franklin County"},
        {name:"MO",label:"Gasconade County"},
        {name:"MO",label:"Gentry County"},
        {name:"MO",label:"Greene County"},
        {name:"MO",label:"Grundy County"},
        {name:"MO",label:"Harrison County"},
        {name:"MO",label:"Henry County"},
        {name:"MO",label:"Hickory County"},
        {name:"MO",label:"Holt County"},
        {name:"MO",label:"Howard County"},
        {name:"MO",label:"Howell County"},
        {name:"MO",label:"Iron County"},
        {name:"MO",label:"Jackson County"},
        {name:"MO",label:"Jasper County"},
        {name:"MO",label:"Jefferson County"},
        {name:"MO",label:"Johnson County"},
        {name:"MO",label:"Knox County"},
        {name:"MO",label:"Laclede County"},
        {name:"MO",label:"Lafayette County"},
        {name:"MO",label:"Lawrence County"},
        {name:"MO",label:"Lewis County"},
        {name:"MO",label:"Lincoln County"},
        {name:"MO",label:"Linn County"},
        {name:"MO",label:"Livingston County"},
        {name:"MO",label:"McDonald County"},
        {name:"MO",label:"Macon County"},
        {name:"MO",label:"Madison County"},
        {name:"MO",label:"Maries County"},
        {name:"MO",label:"Marion County"},
        {name:"MO",label:"Mercer County"},
        {name:"MO",label:"Miller County"},
        {name:"MO",label:"Mississippi County"},
        {name:"MO",label:"Moniteau County"},
        {name:"MO",label:"Monroe County"},
        {name:"MO",label:"Montgomery County"},
        {name:"MO",label:"Morgan County"},
        {name:"MO",label:"New Madrid County"},
        {name:"MO",label:"Newton County"},
        {name:"MO",label:"Nodaway County"},
        {name:"MO",label:"Oregon County"},
        {name:"MO",label:"Osage County"},
        {name:"MO",label:"Ozark County"},
        {name:"MO",label:"Pemiscot County"},
        {name:"MO",label:"Perry County"},
        {name:"MO",label:"Pettis County"},
        {name:"MO",label:"Phelps County"},
        {name:"MO",label:"Pike County"},
        {name:"MO",label:"Platte County"},
        {name:"MO",label:"Polk County"},
        {name:"MO",label:"Pulaski County"},
        {name:"MO",label:"Putnam County"},
        {name:"MO",label:"Ralls County"},
        {name:"MO",label:"Randolph County"},
        {name:"MO",label:"Ray County"},
        {name:"MO",label:"Reynolds County"},
        {name:"MO",label:"Ripley County"},
        {name:"MO",label:"St. Charles County"},
        {name:"MO",label:"St. Clair County"},
        {name:"MO",label:"Ste. Genevieve County"},
        {name:"MO",label:"St. Francois County"},
        {name:"MO",label:"St. Louis County"},
        {name:"MO",label:"Saline County"},
        {name:"MO",label:"Schuyler County"},
        {name:"MO",label:"Scotland County"},
        {name:"MO",label:"Scott County"},
        {name:"MO",label:"Shannon County"},
        {name:"MO",label:"Shelby County"},
        {name:"MO",label:"Stoddard County"},
        {name:"MO",label:"Stone County"},
        {name:"MO",label:"Sullivan County"},
        {name:"MO",label:"Taney County"},
        {name:"MO",label:"Texas County"},
        {name:"MO",label:"Vernon County"},
        {name:"MO",label:"Warren County"},
        {name:"MO",label:"Washington County"},
        {name:"MO",label:"Wayne County"},
        {name:"MO",label:"Webster County"},
        {name:"MO",label:"Worth County"},
        {name:"MO",label:"Wright County"},
        {name:"MO",label:"St. Louis city"},
        {name:"MT",label:"Beaverhead County"},
        {name:"MT",label:"Big Horn County"},
        {name:"MT",label:"Blaine County"},
        {name:"MT",label:"Broadwater County"},
        {name:"MT",label:"Carbon County"},
        {name:"MT",label:"Carter County"},
        {name:"MT",label:"Cascade County"},
        {name:"MT",label:"Chouteau County"},
        {name:"MT",label:"Custer County"},
        {name:"MT",label:"Daniels County"},
        {name:"MT",label:"Dawson County"},
        {name:"MT",label:"Deer Lodge County"},
        {name:"MT",label:"Fallon County"},
        {name:"MT",label:"Fergus County"},
        {name:"MT",label:"Flathead County"},
        {name:"MT",label:"Gallatin County"},
        {name:"MT",label:"Garfield County"},
        {name:"MT",label:"Glacier County"},
        {name:"MT",label:"Golden Valley County"},
        {name:"MT",label:"Granite County"},
        {name:"MT",label:"Hill County"},
        {name:"MT",label:"Jefferson County"},
        {name:"MT",label:"Judith Basin County"},
        {name:"MT",label:"Lake County"},
        {name:"MT",label:"Lewis and Clark County"},
        {name:"MT",label:"Liberty County"},
        {name:"MT",label:"Lincoln County"},
        {name:"MT",label:"McCone County"},
        {name:"MT",label:"Madison County"},
        {name:"MT",label:"Meagher County"},
        {name:"MT",label:"Mineral County"},
        {name:"MT",label:"Missoula County"},
        {name:"MT",label:"Musselshell County"},
        {name:"MT",label:"Park County"},
        {name:"MT",label:"Petroleum County"},
        {name:"MT",label:"Phillips County"},
        {name:"MT",label:"Pondera County"},
        {name:"MT",label:"Powder River County"},
        {name:"MT",label:"Powell County"},
        {name:"MT",label:"Prairie County"},
        {name:"MT",label:"Ravalli County"},
        {name:"MT",label:"Richland County"},
        {name:"MT",label:"Roosevelt County"},
        {name:"MT",label:"Rosebud County"},
        {name:"MT",label:"Sanders County"},
        {name:"MT",label:"Sheridan County"},
        {name:"MT",label:"Silver Bow County"},
        {name:"MT",label:"Stillwater County"},
        {name:"MT",label:"Sweet Grass County"},
        {name:"MT",label:"Teton County"},
        {name:"MT",label:"Toole County"},
        {name:"MT",label:"Treasure County"},
        {name:"MT",label:"Valley County"},
        {name:"MT",label:"Wheatland County"},
        {name:"MT",label:"Wibaux County"},
        {name:"MT",label:"Yellowstone County"},
        {name:"NE",label:"Adams County"},
        {name:"NE",label:"Antelope County"},
        {name:"NE",label:"Arthur County"},
        {name:"NE",label:"Banner County"},
        {name:"NE",label:"Blaine County"},
        {name:"NE",label:"Boone County"},
        {name:"NE",label:"Box Butte County"},
        {name:"NE",label:"Boyd County"},
        {name:"NE",label:"Brown County"},
        {name:"NE",label:"Buffalo County"},
        {name:"NE",label:"Burt County"},
        {name:"NE",label:"Butler County"},
        {name:"NE",label:"Cass County"},
        {name:"NE",label:"Cedar County"},
        {name:"NE",label:"Chase County"},
        {name:"NE",label:"Cherry County"},
        {name:"NE",label:"Cheyenne County"},
        {name:"NE",label:"Clay County"},
        {name:"NE",label:"Colfax County"},
        {name:"NE",label:"Cuming County"},
        {name:"NE",label:"Custer County"},
        {name:"NE",label:"Dakota County"},
        {name:"NE",label:"Dawes County"},
        {name:"NE",label:"Dawson County"},
        {name:"NE",label:"Deuel County"},
        {name:"NE",label:"Dixon County"},
        {name:"NE",label:"Dodge County"},
        {name:"NE",label:"Douglas County"},
        {name:"NE",label:"Dundy County"},
        {name:"NE",label:"Fillmore County"},
        {name:"NE",label:"Franklin County"},
        {name:"NE",label:"Frontier County"},
        {name:"NE",label:"Furnas County"},
        {name:"NE",label:"Gage County"},
        {name:"NE",label:"Garden County"},
        {name:"NE",label:"Garfield County"},
        {name:"NE",label:"Gosper County"},
        {name:"NE",label:"Grant County"},
        {name:"NE",label:"Greeley County"},
        {name:"NE",label:"Hall County"},
        {name:"NE",label:"Hamilton County"},
        {name:"NE",label:"Harlan County"},
        {name:"NE",label:"Hayes County"},
        {name:"NE",label:"Hitchcock County"},
        {name:"NE",label:"Holt County"},
        {name:"NE",label:"Hooker County"},
        {name:"NE",label:"Howard County"},
        {name:"NE",label:"Jefferson County"},
        {name:"NE",label:"Johnson County"},
        {name:"NE",label:"Kearney County"},
        {name:"NE",label:"Keith County"},
        {name:"NE",label:"Keya Paha County"},
        {name:"NE",label:"Kimball County"},
        {name:"NE",label:"Knox County"},
        {name:"NE",label:"Lancaster County"},
        {name:"NE",label:"Lincoln County"},
        {name:"NE",label:"Logan County"},
        {name:"NE",label:"Loup County"},
        {name:"NE",label:"McPherson County"},
        {name:"NE",label:"Madison County"},
        {name:"NE",label:"Merrick County"},
        {name:"NE",label:"Morrill County"},
        {name:"NE",label:"Nance County"},
        {name:"NE",label:"Nemaha County"},
        {name:"NE",label:"Nuckolls County"},
        {name:"NE",label:"Otoe County"},
        {name:"NE",label:"Pawnee County"},
        {name:"NE",label:"Perkins County"},
        {name:"NE",label:"Phelps County"},
        {name:"NE",label:"Pierce County"},
        {name:"NE",label:"Platte County"},
        {name:"NE",label:"Polk County"},
        {name:"NE",label:"Red Willow County"},
        {name:"NE",label:"Richardson County"},
        {name:"NE",label:"Rock County"},
        {name:"NE",label:"Saline County"},
        {name:"NE",label:"Sarpy County"},
        {name:"NE",label:"Saunders County"},
        {name:"NE",label:"Scotts Bluff County"},
        {name:"NE",label:"Seward County"},
        {name:"NE",label:"Sheridan County"},
        {name:"NE",label:"Sherman County"},
        {name:"NE",label:"Sioux County"},
        {name:"NE",label:"Stanton County"},
        {name:"NE",label:"Thayer County"},
        {name:"NE",label:"Thomas County"},
        {name:"NE",label:"Thurston County"},
        {name:"NE",label:"Valley County"},
        {name:"NE",label:"Washington County"},
        {name:"NE",label:"Wayne County"},
        {name:"NE",label:"Webster County"},
        {name:"NE",label:"Wheeler County"},
        {name:"NE",label:"York County"},
        {name:"NV",label:"Churchill County"},
        {name:"NV",label:"Clark County"},
        {name:"NV",label:"Douglas County"},
        {name:"NV",label:"Elko County"},
        {name:"NV",label:"Esmeralda County"},
        {name:"NV",label:"Eureka County"},
        {name:"NV",label:"Humboldt County"},
        {name:"NV",label:"Lander County"},
        {name:"NV",label:"Lincoln County"},
        {name:"NV",label:"Lyon County"},
        {name:"NV",label:"Mineral County"},
        {name:"NV",label:"Nye County"},
        {name:"NV",label:"Pershing County"},
        {name:"NV",label:"Storey County"},
        {name:"NV",label:"Washoe County"},
        {name:"NV",label:"White Pine County"},
        {name:"NV",label:"Carson City"},
        {name:"NH",label:"Belknap County"},
        {name:"NH",label:"Carroll County"},
        {name:"NH",label:"Cheshire County"},
        {name:"NH",label:"Coos County"},
        {name:"NH",label:"Grafton County"},
        {name:"NH",label:"Hillsborough County"},
        {name:"NH",label:"Merrimack County"},
        {name:"NH",label:"Rockingham County"},
        {name:"NH",label:"Strafford County"},
        {name:"NH",label:"Sullivan County"},
        {name:"NJ",label:"Atlantic County"},
        {name:"NJ",label:"Bergen County"},
        {name:"NJ",label:"Burlington County"},
        {name:"NJ",label:"Camden County"},
        {name:"NJ",label:"Cape May County"},
        {name:"NJ",label:"Cumberland County"},
        {name:"NJ",label:"Essex County"},
        {name:"NJ",label:"Gloucester County"},
        {name:"NJ",label:"Hudson County"},
        {name:"NJ",label:"Hunterdon County"},
        {name:"NJ",label:"Mercer County"},
        {name:"NJ",label:"Middlesex County"},
        {name:"NJ",label:"Monmouth County"},
        {name:"NJ",label:"Morris County"},
        {name:"NJ",label:"Ocean County"},
        {name:"NJ",label:"Passaic County"},
        {name:"NJ",label:"Salem County"},
        {name:"NJ",label:"Somerset County"},
        {name:"NJ",label:"Sussex County"},
        {name:"NJ",label:"Union County"},
        {name:"NJ",label:"Warren County"},
        {name:"NM",label:"Bernalillo County"},
        {name:"NM",label:"Catron County"},
        {name:"NM",label:"Chaves County"},
        {name:"NM",label:"Cibola County"},
        {name:"NM",label:"Colfax County"},
        {name:"NM",label:"Curry County"},
        {name:"NM",label:"De Baca County"},
        {name:"NM",label:"Dona Ana County"},
        {name:"NM",label:"Eddy County"},
        {name:"NM",label:"Grant County"},
        {name:"NM",label:"Guadalupe County"},
        {name:"NM",label:"Harding County"},
        {name:"NM",label:"Hidalgo County"},
        {name:"NM",label:"Lea County"},
        {name:"NM",label:"Lincoln County"},
        {name:"NM",label:"Los Alamos County"},
        {name:"NM",label:"Luna County"},
        {name:"NM",label:"McKinley County"},
        {name:"NM",label:"Mora County"},
        {name:"NM",label:"Otero County"},
        {name:"NM",label:"Quay County"},
        {name:"NM",label:"Rio Arriba County"},
        {name:"NM",label:"Roosevelt County"},
        {name:"NM",label:"Sandoval County"},
        {name:"NM",label:"San Juan County"},
        {name:"NM",label:"San Miguel County"},
        {name:"NM",label:"Santa Fe County"},
        {name:"NM",label:"Sierra County"},
        {name:"NM",label:"Socorro County"},
        {name:"NM",label:"Taos County"},
        {name:"NM",label:"Torrance County"},
        {name:"NM",label:"Union County"},
        {name:"NM",label:"Valencia County"},
        {name:"NY",label:"Albany County"},
        {name:"NY",label:"Allegany County"},
        {name:"NY",label:"Bronx County"},
        {name:"NY",label:"Broome County"},
        {name:"NY",label:"Cattaraugus County"},
        {name:"NY",label:"Cayuga County"},
        {name:"NY",label:"Chautauqua County"},
        {name:"NY",label:"Chemung County"},
        {name:"NY",label:"Chenango County"},
        {name:"NY",label:"Clinton County"},
        {name:"NY",label:"Columbia County"},
        {name:"NY",label:"Cortland County"},
        {name:"NY",label:"Delaware County"},
        {name:"NY",label:"Dutchess County"},
        {name:"NY",label:"Erie County"},
        {name:"NY",label:"Essex County"},
        {name:"NY",label:"Franklin County"},
        {name:"NY",label:"Fulton County"},
        {name:"NY",label:"Genesee County"},
        {name:"NY",label:"Greene County"},
        {name:"NY",label:"Hamilton County"},
        {name:"NY",label:"Herkimer County"},
        {name:"NY",label:"Jefferson County"},
        {name:"NY",label:"Kings County"},
        {name:"NY",label:"Lewis County"},
        {name:"NY",label:"Livingston County"},
        {name:"NY",label:"Madison County"},
        {name:"NY",label:"Monroe County"},
        {name:"NY",label:"Montgomery County"},
        {name:"NY",label:"Nassau County"},
        {name:"NY",label:"New York County"},
        {name:"NY",label:"Niagara County"},
        {name:"NY",label:"Oneida County"},
        {name:"NY",label:"Onondaga County"},
        {name:"NY",label:"Ontario County"},
        {name:"NY",label:"Orange County"},
        {name:"NY",label:"Orleans County"},
        {name:"NY",label:"Oswego County"},
        {name:"NY",label:"Otsego County"},
        {name:"NY",label:"Putnam County"},
        {name:"NY",label:"Queens County"},
        {name:"NY",label:"Rensselaer County"},
        {name:"NY",label:"Richmond County"},
        {name:"NY",label:"Rockland County"},
        {name:"NY",label:"St. Lawrence County"},
        {name:"NY",label:"Saratoga County"},
        {name:"NY",label:"Schenectady County"},
        {name:"NY",label:"Schoharie County"},
        {name:"NY",label:"Schuyler County"},
        {name:"NY",label:"Seneca County"},
        {name:"NY",label:"Steuben County"},
        {name:"NY",label:"Suffolk County"},
        {name:"NY",label:"Sullivan County"},
        {name:"NY",label:"Tioga County"},
        {name:"NY",label:"Tompkins County"},
        {name:"NY",label:"Ulster County"},
        {name:"NY",label:"Warren County"},
        {name:"NY",label:"Washington County"},
        {name:"NY",label:"Wayne County"},
        {name:"NY",label:"Westchester County"},
        {name:"NY",label:"Wyoming County"},
        {name:"NY",label:"Yates County"},
        {name:"NC",label:"Alamance County"},
        {name:"NC",label:"Alexander County"},
        {name:"NC",label:"Alleghany County"},
        {name:"NC",label:"Anson County"},
        {name:"NC",label:"Ashe County"},
        {name:"NC",label:"Avery County"},
        {name:"NC",label:"Beaufort County"},
        {name:"NC",label:"Bertie County"},
        {name:"NC",label:"Bladen County"},
        {name:"NC",label:"Brunswick County"},
        {name:"NC",label:"Buncombe County"},
        {name:"NC",label:"Burke County"},
        {name:"NC",label:"Cabarrus County"},
        {name:"NC",label:"Caldwell County"},
        {name:"NC",label:"Camden County"},
        {name:"NC",label:"Carteret County"},
        {name:"NC",label:"Caswell County"},
        {name:"NC",label:"Catawba County"},
        {name:"NC",label:"Chatham County"},
        {name:"NC",label:"Cherokee County"},
        {name:"NC",label:"Chowan County"},
        {name:"NC",label:"Clay County"},
        {name:"NC",label:"Cleveland County"},
        {name:"NC",label:"Columbus County"},
        {name:"NC",label:"Craven County"},
        {name:"NC",label:"Cumberland County"},
        {name:"NC",label:"Currituck County"},
        {name:"NC",label:"Dare County"},
        {name:"NC",label:"Davidson County"},
        {name:"NC",label:"Davie County"},
        {name:"NC",label:"Duplin County"},
        {name:"NC",label:"Durham County"},
        {name:"NC",label:"Edgecombe County"},
        {name:"NC",label:"Forsyth County"},
        {name:"NC",label:"Franklin County"},
        {name:"NC",label:"Gaston County"},
        {name:"NC",label:"Gates County"},
        {name:"NC",label:"Graham County"},
        {name:"NC",label:"Granville County"},
        {name:"NC",label:"Greene County"},
        {name:"NC",label:"Guilford County"},
        {name:"NC",label:"Halifax County"},
        {name:"NC",label:"Harnett County"},
        {name:"NC",label:"Haywood County"},
        {name:"NC",label:"Henderson County"},
        {name:"NC",label:"Hertford County"},
        {name:"NC",label:"Hoke County"},
        {name:"NC",label:"Hyde County"},
        {name:"NC",label:"Iredell County"},
        {name:"NC",label:"Jackson County"},
        {name:"NC",label:"Johnston County"},
        {name:"NC",label:"Jones County"},
        {name:"NC",label:"Lee County"},
        {name:"NC",label:"Lenoir County"},
        {name:"NC",label:"Lincoln County"},
        {name:"NC",label:"McDowell County"},
        {name:"NC",label:"Macon County"},
        {name:"NC",label:"Madison County"},
        {name:"NC",label:"Martin County"},
        {name:"NC",label:"Mecklenburg County"},
        {name:"NC",label:"Mitchell County"},
        {name:"NC",label:"Montgomery County"},
        {name:"NC",label:"Moore County"},
        {name:"NC",label:"Nash County"},
        {name:"NC",label:"New Hanover County"},
        {name:"NC",label:"Northampton County"},
        {name:"NC",label:"Onslow County"},
        {name:"NC",label:"Orange County"},
        {name:"NC",label:"Pamlico County"},
        {name:"NC",label:"Pasquotank County"},
        {name:"NC",label:"Pender County"},
        {name:"NC",label:"Perquimans County"},
        {name:"NC",label:"Person County"},
        {name:"NC",label:"Pitt County"},
        {name:"NC",label:"Polk County"},
        {name:"NC",label:"Randolph County"},
        {name:"NC",label:"Richmond County"},
        {name:"NC",label:"Robeson County"},
        {name:"NC",label:"Rockingham County"},
        {name:"NC",label:"Rowan County"},
        {name:"NC",label:"Rutherford County"},
        {name:"NC",label:"Sampson County"},
        {name:"NC",label:"Scotland County"},
        {name:"NC",label:"Stanly County"},
        {name:"NC",label:"Stokes County"},
        {name:"NC",label:"Surry County"},
        {name:"NC",label:"Swain County"},
        {name:"NC",label:"Transylvania County"},
        {name:"NC",label:"Tyrrell County"},
        {name:"NC",label:"Union County"},
        {name:"NC",label:"Vance County"},
        {name:"NC",label:"Wake County"},
        {name:"NC",label:"Warren County"},
        {name:"NC",label:"Washington County"},
        {name:"NC",label:"Watauga County"},
        {name:"NC",label:"Wayne County"},
        {name:"NC",label:"Wilkes County"},
        {name:"NC",label:"Wilson County"},
        {name:"NC",label:"Yadkin County"},
        {name:"NC",label:"Yancey County"},
        {name:"ND",label:"Adams County"},
        {name:"ND",label:"Barnes County"},
        {name:"ND",label:"Benson County"},
        {name:"ND",label:"Billings County"},
        {name:"ND",label:"Bottineau County"},
        {name:"ND",label:"Bowman County"},
        {name:"ND",label:"Burke County"},
        {name:"ND",label:"Burleigh County"},
        {name:"ND",label:"Cass County"},
        {name:"ND",label:"Cavalier County"},
        {name:"ND",label:"Dickey County"},
        {name:"ND",label:"Divide County"},
        {name:"ND",label:"Dunn County"},
        {name:"ND",label:"Eddy County"},
        {name:"ND",label:"Emmons County"},
        {name:"ND",label:"Foster County"},
        {name:"ND",label:"Golden Valley County"},
        {name:"ND",label:"Grand Forks County"},
        {name:"ND",label:"Grant County"},
        {name:"ND",label:"Griggs County"},
        {name:"ND",label:"Hettinger County"},
        {name:"ND",label:"Kidder County"},
        {name:"ND",label:"LaMoure County"},
        {name:"ND",label:"Logan County"},
        {name:"ND",label:"McHenry County"},
        {name:"ND",label:"McIntosh County"},
        {name:"ND",label:"McKenzie County"},
        {name:"ND",label:"McLean County"},
        {name:"ND",label:"Mercer County"},
        {name:"ND",label:"Morton County"},
        {name:"ND",label:"Mountrail County"},
        {name:"ND",label:"Nelson County"},
        {name:"ND",label:"Oliver County"},
        {name:"ND",label:"Pembina County"},
        {name:"ND",label:"Pierce County"},
        {name:"ND",label:"Ramsey County"},
        {name:"ND",label:"Ransom County"},
        {name:"ND",label:"Renville County"},
        {name:"ND",label:"Richland County"},
        {name:"ND",label:"Rolette County"},
        {name:"ND",label:"Sargent County"},
        {name:"ND",label:"Sheridan County"},
        {name:"ND",label:"Sioux County"},
        {name:"ND",label:"Slope County"},
        {name:"ND",label:"Stark County"},
        {name:"ND",label:"Steele County"},
        {name:"ND",label:"Stutsman County"},
        {name:"ND",label:"Towner County"},
        {name:"ND",label:"Traill County"},
        {name:"ND",label:"Walsh County"},
        {name:"ND",label:"Ward County"},
        {name:"ND",label:"Wells County"},
        {name:"ND",label:"Williams County"},
        {name:"OH",label:"Adams County"},
        {name:"OH",label:"Allen County"},
        {name:"OH",label:"Ashland County"},
        {name:"OH",label:"Ashtabula County"},
        {name:"OH",label:"Athens County"},
        {name:"OH",label:"Auglaize County"},
        {name:"OH",label:"Belmont County"},
        {name:"OH",label:"Brown County"},
        {name:"OH",label:"Butler County"},
        {name:"OH",label:"Carroll County"},
        {name:"OH",label:"Champaign County"},
        {name:"OH",label:"Clark County"},
        {name:"OH",label:"Clermont County"},
        {name:"OH",label:"Clinton County"},
        {name:"OH",label:"Columbiana County"},
        {name:"OH",label:"Coshocton County"},
        {name:"OH",label:"Crawford County"},
        {name:"OH",label:"Cuyahoga County"},
        {name:"OH",label:"Darke County"},
        {name:"OH",label:"Defiance County"},
        {name:"OH",label:"Delaware County"},
        {name:"OH",label:"Erie County"},
        {name:"OH",label:"Fairfield County"},
        {name:"OH",label:"Fayette County"},
        {name:"OH",label:"Franklin County"},
        {name:"OH",label:"Fulton County"},
        {name:"OH",label:"Gallia County"},
        {name:"OH",label:"Geauga County"},
        {name:"OH",label:"Greene County"},
        {name:"OH",label:"Guernsey County"},
        {name:"OH",label:"Hamilton County"},
        {name:"OH",label:"Hancock County"},
        {name:"OH",label:"Hardin County"},
        {name:"OH",label:"Harrison County"},
        {name:"OH",label:"Henry County"},
        {name:"OH",label:"Highland County"},
        {name:"OH",label:"Hocking County"},
        {name:"OH",label:"Holmes County"},
        {name:"OH",label:"Huron County"},
        {name:"OH",label:"Jackson County"},
        {name:"OH",label:"Jefferson County"},
        {name:"OH",label:"Knox County"},
        {name:"OH",label:"Lake County"},
        {name:"OH",label:"Lawrence County"},
        {name:"OH",label:"Licking County"},
        {name:"OH",label:"Logan County"},
        {name:"OH",label:"Lorain County"},
        {name:"OH",label:"Lucas County"},
        {name:"OH",label:"Madison County"},
        {name:"OH",label:"Mahoning County"},
        {name:"OH",label:"Marion County"},
        {name:"OH",label:"Medina County"},
        {name:"OH",label:"Meigs County"},
        {name:"OH",label:"Mercer County"},
        {name:"OH",label:"Miami County"},
        {name:"OH",label:"Monroe County"},
        {name:"OH",label:"Montgomery County"},
        {name:"OH",label:"Morgan County"},
        {name:"OH",label:"Morrow County"},
        {name:"OH",label:"Muskingum County"},
        {name:"OH",label:"Noble County"},
        {name:"OH",label:"Ottawa County"},
        {name:"OH",label:"Paulding County"},
        {name:"OH",label:"Perry County"},
        {name:"OH",label:"Pickaway County"},
        {name:"OH",label:"Pike County"},
        {name:"OH",label:"Portage County"},
        {name:"OH",label:"Preble County"},
        {name:"OH",label:"Putnam County"},
        {name:"OH",label:"Richland County"},
        {name:"OH",label:"Ross County"},
        {name:"OH",label:"Sandusky County"},
        {name:"OH",label:"Scioto County"},
        {name:"OH",label:"Seneca County"},
        {name:"OH",label:"Shelby County"},
        {name:"OH",label:"Stark County"},
        {name:"OH",label:"Summit County"},
        {name:"OH",label:"Trumbull County"},
        {name:"OH",label:"Tuscarawas County"},
        {name:"OH",label:"Union County"},
        {name:"OH",label:"Van Wert County"},
        {name:"OH",label:"Vinton County"},
        {name:"OH",label:"Warren County"},
        {name:"OH",label:"Washington County"},
        {name:"OH",label:"Wayne County"},
        {name:"OH",label:"Williams County"},
        {name:"OH",label:"Wood County"},
        {name:"OH",label:"Wyandot County"},
        {name:"OK",label:"Adair County"},
        {name:"OK",label:"Alfalfa County"},
        {name:"OK",label:"Atoka County"},
        {name:"OK",label:"Beaver County"},
        {name:"OK",label:"Beckham County"},
        {name:"OK",label:"Blaine County"},
        {name:"OK",label:"Bryan County"},
        {name:"OK",label:"Caddo County"},
        {name:"OK",label:"Canadian County"},
        {name:"OK",label:"Carter County"},
        {name:"OK",label:"Cherokee County"},
        {name:"OK",label:"Choctaw County"},
        {name:"OK",label:"Cimarron County"},
        {name:"OK",label:"Cleveland County"},
        {name:"OK",label:"Coal County"},
        {name:"OK",label:"Comanche County"},
        {name:"OK",label:"Cotton County"},
        {name:"OK",label:"Craig County"},
        {name:"OK",label:"Creek County"},
        {name:"OK",label:"Custer County"},
        {name:"OK",label:"Delaware County"},
        {name:"OK",label:"Dewey County"},
        {name:"OK",label:"Ellis County"},
        {name:"OK",label:"Garfield County"},
        {name:"OK",label:"Garvin County"},
        {name:"OK",label:"Grady County"},
        {name:"OK",label:"Grant County"},
        {name:"OK",label:"Greer County"},
        {name:"OK",label:"Harmon County"},
        {name:"OK",label:"Harper County"},
        {name:"OK",label:"Haskell County"},
        {name:"OK",label:"Hughes County"},
        {name:"OK",label:"Jackson County"},
        {name:"OK",label:"Jefferson County"},
        {name:"OK",label:"Johnston County"},
        {name:"OK",label:"Kay County"},
        {name:"OK",label:"Kingfisher County"},
        {name:"OK",label:"Kiowa County"},
        {name:"OK",label:"Latimer County"},
        {name:"OK",label:"Le Flore County"},
        {name:"OK",label:"Lincoln County"},
        {name:"OK",label:"Logan County"},
        {name:"OK",label:"Love County"},
        {name:"OK",label:"McClain County"},
        {name:"OK",label:"McCurtain County"},
        {name:"OK",label:"McIntosh County"},
        {name:"OK",label:"Major County"},
        {name:"OK",label:"Marshall County"},
        {name:"OK",label:"Mayes County"},
        {name:"OK",label:"Murray County"},
        {name:"OK",label:"Muskogee County"},
        {name:"OK",label:"Noble County"},
        {name:"OK",label:"Nowata County"},
        {name:"OK",label:"Okfuskee County"},
        {name:"OK",label:"Oklahoma County"},
        {name:"OK",label:"Okmulgee County"},
        {name:"OK",label:"Osage County"},
        {name:"OK",label:"Ottawa County"},
        {name:"OK",label:"Pawnee County"},
        {name:"OK",label:"Payne County"},
        {name:"OK",label:"Pittsburg County"},
        {name:"OK",label:"Pontotoc County"},
        {name:"OK",label:"Pottawatomie County"},
        {name:"OK",label:"Pushmataha County"},
        {name:"OK",label:"Roger Mills County"},
        {name:"OK",label:"Rogers County"},
        {name:"OK",label:"Seminole County"},
        {name:"OK",label:"Sequoyah County"},
        {name:"OK",label:"Stephens County"},
        {name:"OK",label:"Texas County"},
        {name:"OK",label:"Tillman County"},
        {name:"OK",label:"Tulsa County"},
        {name:"OK",label:"Wagoner County"},
        {name:"OK",label:"Washington County"},
        {name:"OK",label:"Washita County"},
        {name:"OK",label:"Woods County"},
        {name:"OK",label:"Woodward County"},
        {name:"OR",label:"Baker County"},
        {name:"OR",label:"Benton County"},
        {name:"OR",label:"Clackamas County"},
        {name:"OR",label:"Clatsop County"},
        {name:"OR",label:"Columbia County"},
        {name:"OR",label:"Coos County"},
        {name:"OR",label:"Crook County"},
        {name:"OR",label:"Curry County"},
        {name:"OR",label:"Deschutes County"},
        {name:"OR",label:"Douglas County"},
        {name:"OR",label:"Gilliam County"},
        {name:"OR",label:"Grant County"},
        {name:"OR",label:"Harney County"},
        {name:"OR",label:"Hood River County"},
        {name:"OR",label:"Jackson County"},
        {name:"OR",label:"Jefferson County"},
        {name:"OR",label:"Josephine County"},
        {name:"OR",label:"Klamath County"},
        {name:"OR",label:"Lake County"},
        {name:"OR",label:"Lane County"},
        {name:"OR",label:"Lincoln County"},
        {name:"OR",label:"Linn County"},
        {name:"OR",label:"Malheur County"},
        {name:"OR",label:"Marion County"},
        {name:"OR",label:"Morrow County"},
        {name:"OR",label:"Multnomah County"},
        {name:"OR",label:"Polk County"},
        {name:"OR",label:"Sherman County"},
        {name:"OR",label:"Tillamook County"},
        {name:"OR",label:"Umatilla County"},
        {name:"OR",label:"Union County"},
        {name:"OR",label:"Wallowa County"},
        {name:"OR",label:"Wasco County"},
        {name:"OR",label:"Washington County"},
        {name:"OR",label:"Wheeler County"},
        {name:"OR",label:"Yamhill County"},
        {name:"PA",label:"Adams County"},
        {name:"PA",label:"Allegheny County"},
        {name:"PA",label:"Armstrong County"},
        {name:"PA",label:"Beaver County"},
        {name:"PA",label:"Bedford County"},
        {name:"PA",label:"Berks County"},
        {name:"PA",label:"Blair County"},
        {name:"PA",label:"Bradford County"},
        {name:"PA",label:"Bucks County"},
        {name:"PA",label:"Butler County"},
        {name:"PA",label:"Cambria County"},
        {name:"PA",label:"Cameron County"},
        {name:"PA",label:"Carbon County"},
        {name:"PA",label:"Centre County"},
        {name:"PA",label:"Chester County"},
        {name:"PA",label:"Clarion County"},
        {name:"PA",label:"Clearfield County"},
        {name:"PA",label:"Clinton County"},
        {name:"PA",label:"Columbia County"},
        {name:"PA",label:"Crawford County"},
        {name:"PA",label:"Cumberland County"},
        {name:"PA",label:"Dauphin County"},
        {name:"PA",label:"Delaware County"},
        {name:"PA",label:"Elk County"},
        {name:"PA",label:"Erie County"},
        {name:"PA",label:"Fayette County"},
        {name:"PA",label:"Forest County"},
        {name:"PA",label:"Franklin County"},
        {name:"PA",label:"Fulton County"},
        {name:"PA",label:"Greene County"},
        {name:"PA",label:"Huntingdon County"},
        {name:"PA",label:"Indiana County"},
        {name:"PA",label:"Jefferson County"},
        {name:"PA",label:"Juniata County"},
        {name:"PA",label:"Lackawanna County"},
        {name:"PA",label:"Lancaster County"},
        {name:"PA",label:"Lawrence County"},
        {name:"PA",label:"Lebanon County"},
        {name:"PA",label:"Lehigh County"},
        {name:"PA",label:"Luzerne County"},
        {name:"PA",label:"Lycoming County"},
        {name:"PA",label:"McKean County"},
        {name:"PA",label:"Mercer County"},
        {name:"PA",label:"Mifflin County"},
        {name:"PA",label:"Monroe County"},
        {name:"PA",label:"Montgomery County"},
        {name:"PA",label:"Montour County"},
        {name:"PA",label:"Northampton County"},
        {name:"PA",label:"Northumberland County"},
        {name:"PA",label:"Perry County"},
        {name:"PA",label:"Philadelphia County"},
        {name:"PA",label:"Pike County"},
        {name:"PA",label:"Potter County"},
        {name:"PA",label:"Schuylkill County"},
        {name:"PA",label:"Snyder County"},
        {name:"PA",label:"Somerset County"},
        {name:"PA",label:"Sullivan County"},
        {name:"PA",label:"Susquehanna County"},
        {name:"PA",label:"Tioga County"},
        {name:"PA",label:"Union County"},
        {name:"PA",label:"Venango County"},
        {name:"PA",label:"Warren County"},
        {name:"PA",label:"Washington County"},
        {name:"PA",label:"Wayne County"},
        {name:"PA",label:"Westmoreland County"},
        {name:"PA",label:"Wyoming County"},
        {name:"PA",label:"York County"},
        {name:"RI",label:"Bristol County"},
        {name:"RI",label:"Kent County"},
        {name:"RI",label:"Newport County"},
        {name:"RI",label:"Providence County"},
        {name:"RI",label:"Washington County"},
        {name:"SC",label:"Abbeville County"},
        {name:"SC",label:"Aiken County"},
        {name:"SC",label:"Allendale County"},
        {name:"SC",label:"Anderson County"},
        {name:"SC",label:"Bamberg County"},
        {name:"SC",label:"Barnwell County"},
        {name:"SC",label:"Beaufort County"},
        {name:"SC",label:"Berkeley County"},
        {name:"SC",label:"Calhoun County"},
        {name:"SC",label:"Charleston County"},
        {name:"SC",label:"Cherokee County"},
        {name:"SC",label:"Chester County"},
        {name:"SC",label:"Chesterfield County"},
        {name:"SC",label:"Clarendon County"},
        {name:"SC",label:"Colleton County"},
        {name:"SC",label:"Darlington County"},
        {name:"SC",label:"Dillon County"},
        {name:"SC",label:"Dorchester County"},
        {name:"SC",label:"Edgefield County"},
        {name:"SC",label:"Fairfield County"},
        {name:"SC",label:"Florence County"},
        {name:"SC",label:"Georgetown County"},
        {name:"SC",label:"Greenville County"},
        {name:"SC",label:"Greenwood County"},
        {name:"SC",label:"Hampton County"},
        {name:"SC",label:"Horry County"},
        {name:"SC",label:"Jasper County"},
        {name:"SC",label:"Kershaw County"},
        {name:"SC",label:"Lancaster County"},
        {name:"SC",label:"Laurens County"},
        {name:"SC",label:"Lee County"},
        {name:"SC",label:"Lexington County"},
        {name:"SC",label:"McCormick County"},
        {name:"SC",label:"Marion County"},
        {name:"SC",label:"Marlboro County"},
        {name:"SC",label:"Newberry County"},
        {name:"SC",label:"Oconee County"},
        {name:"SC",label:"Orangeburg County"},
        {name:"SC",label:"Pickens County"},
        {name:"SC",label:"Richland County"},
        {name:"SC",label:"Saluda County"},
        {name:"SC",label:"Spartanburg County"},
        {name:"SC",label:"Sumter County"},
        {name:"SC",label:"Union County"},
        {name:"SC",label:"Williamsburg County"},
        {name:"SC",label:"York County"},
        {name:"SD",label:"Aurora County"},
        {name:"SD",label:"Beadle County"},
        {name:"SD",label:"Bennett County"},
        {name:"SD",label:"Bon Homme County"},
        {name:"SD",label:"Brookings County"},
        {name:"SD",label:"Brown County"},
        {name:"SD",label:"Brule County"},
        {name:"SD",label:"Buffalo County"},
        {name:"SD",label:"Butte County"},
        {name:"SD",label:"Campbell County"},
        {name:"SD",label:"Charles Mix County"},
        {name:"SD",label:"Clark County"},
        {name:"SD",label:"Clay County"},
        {name:"SD",label:"Codington County"},
        {name:"SD",label:"Corson County"},
        {name:"SD",label:"Custer County"},
        {name:"SD",label:"Davison County"},
        {name:"SD",label:"Day County"},
        {name:"SD",label:"Deuel County"},
        {name:"SD",label:"Dewey County"},
        {name:"SD",label:"Douglas County"},
        {name:"SD",label:"Edmunds County"},
        {name:"SD",label:"Fall River County"},
        {name:"SD",label:"Faulk County"},
        {name:"SD",label:"Grant County"},
        {name:"SD",label:"Gregory County"},
        {name:"SD",label:"Haakon County"},
        {name:"SD",label:"Hamlin County"},
        {name:"SD",label:"Hand County"},
        {name:"SD",label:"Hanson County"},
        {name:"SD",label:"Harding County"},
        {name:"SD",label:"Hughes County"},
        {name:"SD",label:"Hutchinson County"},
        {name:"SD",label:"Hyde County"},
        {name:"SD",label:"Jackson County"},
        {name:"SD",label:"Jerauld County"},
        {name:"SD",label:"Jones County"},
        {name:"SD",label:"Kingsbury County"},
        {name:"SD",label:"Lake County"},
        {name:"SD",label:"Lawrence County"},
        {name:"SD",label:"Lincoln County"},
        {name:"SD",label:"Lyman County"},
        {name:"SD",label:"McCook County"},
        {name:"SD",label:"McPherson County"},
        {name:"SD",label:"Marshall County"},
        {name:"SD",label:"Meade County"},
        {name:"SD",label:"Mellette County"},
        {name:"SD",label:"Miner County"},
        {name:"SD",label:"Minnehaha County"},
        {name:"SD",label:"Moody County"},
        {name:"SD",label:"Pennington County"},
        {name:"SD",label:"Perkins County"},
        {name:"SD",label:"Potter County"},
        {name:"SD",label:"Roberts County"},
        {name:"SD",label:"Sanborn County"},
        {name:"SD",label:"Shannon County"},
        {name:"SD",label:"Spink County"},
        {name:"SD",label:"Stanley County"},
        {name:"SD",label:"Sully County"},
        {name:"SD",label:"Todd County"},
        {name:"SD",label:"Tripp County"},
        {name:"SD",label:"Turner County"},
        {name:"SD",label:"Union County"},
        {name:"SD",label:"Walworth County"},
        {name:"SD",label:"Yankton County"},
        {name:"SD",label:"Ziebach County"},
        {name:"TN",label:"Anderson County"},
        {name:"TN",label:"Bedford County"},
        {name:"TN",label:"Benton County"},
        {name:"TN",label:"Bledsoe County"},
        {name:"TN",label:"Blount County"},
        {name:"TN",label:"Bradley County"},
        {name:"TN",label:"Campbell County"},
        {name:"TN",label:"Cannon County"},
        {name:"TN",label:"Carroll County"},
        {name:"TN",label:"Carter County"},
        {name:"TN",label:"Cheatham County"},
        {name:"TN",label:"Chester County"},
        {name:"TN",label:"Claiborne County"},
        {name:"TN",label:"Clay County"},
        {name:"TN",label:"Cocke County"},
        {name:"TN",label:"Coffee County"},
        {name:"TN",label:"Crockett County"},
        {name:"TN",label:"Cumberland County"},
        {name:"TN",label:"Davidson County"},
        {name:"TN",label:"Decatur County"},
        {name:"TN",label:"DeKalb County"},
        {name:"TN",label:"Dickson County"},
        {name:"TN",label:"Dyer County"},
        {name:"TN",label:"Fayette County"},
        {name:"TN",label:"Fentress County"},
        {name:"TN",label:"Franklin County"},
        {name:"TN",label:"Gibson County"},
        {name:"TN",label:"Giles County"},
        {name:"TN",label:"Grainger County"},
        {name:"TN",label:"Greene County"},
        {name:"TN",label:"Grundy County"},
        {name:"TN",label:"Hamblen County"},
        {name:"TN",label:"Hamilton County"},
        {name:"TN",label:"Hancock County"},
        {name:"TN",label:"Hardeman County"},
        {name:"TN",label:"Hardin County"},
        {name:"TN",label:"Hawkins County"},
        {name:"TN",label:"Haywood County"},
        {name:"TN",label:"Henderson County"},
        {name:"TN",label:"Henry County"},
        {name:"TN",label:"Hickman County"},
        {name:"TN",label:"Houston County"},
        {name:"TN",label:"Humphreys County"},
        {name:"TN",label:"Jackson County"},
        {name:"TN",label:"Jefferson County"},
        {name:"TN",label:"Johnson County"},
        {name:"TN",label:"Knox County"},
        {name:"TN",label:"Lake County"},
        {name:"TN",label:"Lauderdale County"},
        {name:"TN",label:"Lawrence County"},
        {name:"TN",label:"Lewis County"},
        {name:"TN",label:"Lincoln County"},
        {name:"TN",label:"Loudon County"},
        {name:"TN",label:"McMinn County"},
        {name:"TN",label:"McNairy County"},
        {name:"TN",label:"Macon County"},
        {name:"TN",label:"Madison County"},
        {name:"TN",label:"Marion County"},
        {name:"TN",label:"Marshall County"},
        {name:"TN",label:"Maury County"},
        {name:"TN",label:"Meigs County"},
        {name:"TN",label:"Monroe County"},
        {name:"TN",label:"Montgomery County"},
        {name:"TN",label:"Moore County"},
        {name:"TN",label:"Morgan County"},
        {name:"TN",label:"Obion County"},
        {name:"TN",label:"Overton County"},
        {name:"TN",label:"Perry County"},
        {name:"TN",label:"Pickett County"},
        {name:"TN",label:"Polk County"},
        {name:"TN",label:"Putnam County"},
        {name:"TN",label:"Rhea County"},
        {name:"TN",label:"Roane County"},
        {name:"TN",label:"Robertson County"},
        {name:"TN",label:"Rutherford County"},
        {name:"TN",label:"Scott County"},
        {name:"TN",label:"Sequatchie County"},
        {name:"TN",label:"Sevier County"},
        {name:"TN",label:"Shelby County"},
        {name:"TN",label:"Smith County"},
        {name:"TN",label:"Stewart County"},
        {name:"TN",label:"Sullivan County"},
        {name:"TN",label:"Sumner County"},
        {name:"TN",label:"Tipton County"},
        {name:"TN",label:"Trousdale County"},
        {name:"TN",label:"Unicoi County"},
        {name:"TN",label:"Union County"},
        {name:"TN",label:"Van Buren County"},
        {name:"TN",label:"Warren County"},
        {name:"TN",label:"Washington County"},
        {name:"TN",label:"Wayne County"},
        {name:"TN",label:"Weakley County"},
        {name:"TN",label:"White County"},
        {name:"TN",label:"Williamson County"},
        {name:"TN",label:"Wilson County"},
        {name:"TX",label:"Anderson County"},
        {name:"TX",label:"Andrews County"},
        {name:"TX",label:"Angelina County"},
        {name:"TX",label:"Aransas County"},
        {name:"TX",label:"Archer County"},
        {name:"TX",label:"Armstrong County"},
        {name:"TX",label:"Atascosa County"},
        {name:"TX",label:"Austin County"},
        {name:"TX",label:"Bailey County"},
        {name:"TX",label:"Bandera County"},
        {name:"TX",label:"Bastrop County"},
        {name:"TX",label:"Baylor County"},
        {name:"TX",label:"Bee County"},
        {name:"TX",label:"Bell County"},
        {name:"TX",label:"Bexar County"},
        {name:"TX",label:"Blanco County"},
        {name:"TX",label:"Borden County"},
        {name:"TX",label:"Bosque County"},
        {name:"TX",label:"Bowie County"},
        {name:"TX",label:"Brazoria County"},
        {name:"TX",label:"Brazos County"},
        {name:"TX",label:"Brewster County"},
        {name:"TX",label:"Briscoe County"},
        {name:"TX",label:"Brooks County"},
        {name:"TX",label:"Brown County"},
        {name:"TX",label:"Burleson County"},
        {name:"TX",label:"Burnet County"},
        {name:"TX",label:"Caldwell County"},
        {name:"TX",label:"Calhoun County"},
        {name:"TX",label:"Callahan County"},
        {name:"TX",label:"Cameron County"},
        {name:"TX",label:"Camp County"},
        {name:"TX",label:"Carson County"},
        {name:"TX",label:"Cass County"},
        {name:"TX",label:"Castro County"},
        {name:"TX",label:"Chambers County"},
        {name:"TX",label:"Cherokee County"},
        {name:"TX",label:"Childress County"},
        {name:"TX",label:"Clay County"},
        {name:"TX",label:"Cochran County"},
        {name:"TX",label:"Coke County"},
        {name:"TX",label:"Coleman County"},
        {name:"TX",label:"Collin County"},
        {name:"TX",label:"Collingsworth County"},
        {name:"TX",label:"Colorado County"},
        {name:"TX",label:"Comal County"},
        {name:"TX",label:"Comanche County"},
        {name:"TX",label:"Concho County"},
        {name:"TX",label:"Cooke County"},
        {name:"TX",label:"Coryell County"},
        {name:"TX",label:"Cottle County"},
        {name:"TX",label:"Crane County"},
        {name:"TX",label:"Crockett County"},
        {name:"TX",label:"Crosby County"},
        {name:"TX",label:"Culberson County"},
        {name:"TX",label:"Dallam County"},
        {name:"TX",label:"Dallas County"},
        {name:"TX",label:"Dawson County"},
        {name:"TX",label:"Deaf Smith County"},
        {name:"TX",label:"Delta County"},
        {name:"TX",label:"Denton County"},
        {name:"TX",label:"DeWitt County"},
        {name:"TX",label:"Dickens County"},
        {name:"TX",label:"Dimmit County"},
        {name:"TX",label:"Donley County"},
        {name:"TX",label:"Duval County"},
        {name:"TX",label:"Eastland County"},
        {name:"TX",label:"Ector County"},
        {name:"TX",label:"Edwards County"},
        {name:"TX",label:"Ellis County"},
        {name:"TX",label:"El Paso County"},
        {name:"TX",label:"Erath County"},
        {name:"TX",label:"Falls County"},
        {name:"TX",label:"Fannin County"},
        {name:"TX",label:"Fayette County"},
        {name:"TX",label:"Fisher County"},
        {name:"TX",label:"Floyd County"},
        {name:"TX",label:"Foard County"},
        {name:"TX",label:"Fort Bend County"},
        {name:"TX",label:"Franklin County"},
        {name:"TX",label:"Freestone County"},
        {name:"TX",label:"Frio County"},
        {name:"TX",label:"Gaines County"},
        {name:"TX",label:"Galveston County"},
        {name:"TX",label:"Garza County"},
        {name:"TX",label:"Gillespie County"},
        {name:"TX",label:"Glasscock County"},
        {name:"TX",label:"Goliad County"},
        {name:"TX",label:"Gonzales County"},
        {name:"TX",label:"Gray County"},
        {name:"TX",label:"Grayson County"},
        {name:"TX",label:"Gregg County"},
        {name:"TX",label:"Grimes County"},
        {name:"TX",label:"Guadalupe County"},
        {name:"TX",label:"Hale County"},
        {name:"TX",label:"Hall County"},
        {name:"TX",label:"Hamilton County"},
        {name:"TX",label:"Hansford County"},
        {name:"TX",label:"Hardeman County"},
        {name:"TX",label:"Hardin County"},
        {name:"TX",label:"Harris County"},
        {name:"TX",label:"Harrison County"},
        {name:"TX",label:"Hartley County"},
        {name:"TX",label:"Haskell County"},
        {name:"TX",label:"Hays County"},
        {name:"TX",label:"Hemphill County"},
        {name:"TX",label:"Henderson County"},
        {name:"TX",label:"Hidalgo County"},
        {name:"TX",label:"Hill County"},
        {name:"TX",label:"Hockley County"},
        {name:"TX",label:"Hood County"},
        {name:"TX",label:"Hopkins County"},
        {name:"TX",label:"Houston County"},
        {name:"TX",label:"Howard County"},
        {name:"TX",label:"Hudspeth County"},
        {name:"TX",label:"Hunt County"},
        {name:"TX",label:"Hutchinson County"},
        {name:"TX",label:"Irion County"},
        {name:"TX",label:"Jack County"},
        {name:"TX",label:"Jackson County"},
        {name:"TX",label:"Jasper County"},
        {name:"TX",label:"Jeff Davis County"},
        {name:"TX",label:"Jefferson County"},
        {name:"TX",label:"Jim Hogg County"},
        {name:"TX",label:"Jim Wells County"},
        {name:"TX",label:"Johnson County"},
        {name:"TX",label:"Jones County"},
        {name:"TX",label:"Karnes County"},
        {name:"TX",label:"Kaufman County"},
        {name:"TX",label:"Kendall County"},
        {name:"TX",label:"Kenedy County"},
        {name:"TX",label:"Kent County"},
        {name:"TX",label:"Kerr County"},
        {name:"TX",label:"Kimble County"},
        {name:"TX",label:"King County"},
        {name:"TX",label:"Kinney County"},
        {name:"TX",label:"Kleberg County"},
        {name:"TX",label:"Knox County"},
        {name:"TX",label:"Lamar County"},
        {name:"TX",label:"Lamb County"},
        {name:"TX",label:"Lampasas County"},
        {name:"TX",label:"La Salle County"},
        {name:"TX",label:"Lavaca County"},
        {name:"TX",label:"Lee County"},
        {name:"TX",label:"Leon County"},
        {name:"TX",label:"Liberty County"},
        {name:"TX",label:"Limestone County"},
        {name:"TX",label:"Lipscomb County"},
        {name:"TX",label:"Live Oak County"},
        {name:"TX",label:"Llano County"},
        {name:"TX",label:"Loving County"},
        {name:"TX",label:"Lubbock County"},
        {name:"TX",label:"Lynn County"},
        {name:"TX",label:"McCulloch County"},
        {name:"TX",label:"McLennan County"},
        {name:"TX",label:"McMullen County"},
        {name:"TX",label:"Madison County"},
        {name:"TX",label:"Marion County"},
        {name:"TX",label:"Martin County"},
        {name:"TX",label:"Mason County"},
        {name:"TX",label:"Matagorda County"},
        {name:"TX",label:"Maverick County"},
        {name:"TX",label:"Medina County"},
        {name:"TX",label:"Menard County"},
        {name:"TX",label:"Midland County"},
        {name:"TX",label:"Milam County"},
        {name:"TX",label:"Mills County"},
        {name:"TX",label:"Mitchell County"},
        {name:"TX",label:"Montague County"},
        {name:"TX",label:"Montgomery County"},
        {name:"TX",label:"Moore County"},
        {name:"TX",label:"Morris County"},
        {name:"TX",label:"Motley County"},
        {name:"TX",label:"Nacogdoches County"},
        {name:"TX",label:"Navarro County"},
        {name:"TX",label:"Newton County"},
        {name:"TX",label:"Nolan County"},
        {name:"TX",label:"Nueces County"},
        {name:"TX",label:"Ochiltree County"},
        {name:"TX",label:"Oldham County"},
        {name:"TX",label:"Orange County"},
        {name:"TX",label:"Palo Pinto County"},
        {name:"TX",label:"Panola County"},
        {name:"TX",label:"Parker County"},
        {name:"TX",label:"Parmer County"},
        {name:"TX",label:"Pecos County"},
        {name:"TX",label:"Polk County"},
        {name:"TX",label:"Potter County"},
        {name:"TX",label:"Presidio County"},
        {name:"TX",label:"Rains County"},
        {name:"TX",label:"Randall County"},
        {name:"TX",label:"Reagan County"},
        {name:"TX",label:"Real County"},
        {name:"TX",label:"Red River County"},
        {name:"TX",label:"Reeves County"},
        {name:"TX",label:"Refugio County"},
        {name:"TX",label:"Roberts County"},
        {name:"TX",label:"Robertson County"},
        {name:"TX",label:"Rockwall County"},
        {name:"TX",label:"Runnels County"},
        {name:"TX",label:"Rusk County"},
        {name:"TX",label:"Sabine County"},
        {name:"TX",label:"San Augustine County"},
        {name:"TX",label:"San Jacinto County"},
        {name:"TX",label:"San Patricio County"},
        {name:"TX",label:"San Saba County"},
        {name:"TX",label:"Schleicher County"},
        {name:"TX",label:"Scurry County"},
        {name:"TX",label:"Shackelford County"},
        {name:"TX",label:"Shelby County"},
        {name:"TX",label:"Sherman County"},
        {name:"TX",label:"Smith County"},
        {name:"TX",label:"Somervell County"},
        {name:"TX",label:"Starr County"},
        {name:"TX",label:"Stephens County"},
        {name:"TX",label:"Sterling County"},
        {name:"TX",label:"Stonewall County"},
        {name:"TX",label:"Sutton County"},
        {name:"TX",label:"Swisher County"},
        {name:"TX",label:"Tarrant County"},
        {name:"TX",label:"Taylor County"},
        {name:"TX",label:"Terrell County"},
        {name:"TX",label:"Terry County"},
        {name:"TX",label:"Throckmorton County"},
        {name:"TX",label:"Titus County"},
        {name:"TX",label:"Tom Green County"},
        {name:"TX",label:"Travis County"},
        {name:"TX",label:"Trinity County"},
        {name:"TX",label:"Tyler County"},
        {name:"TX",label:"Upshur County"},
        {name:"TX",label:"Upton County"},
        {name:"TX",label:"Uvalde County"},
        {name:"TX",label:"Val Verde County"},
        {name:"TX",label:"Van Zandt County"},
        {name:"TX",label:"Victoria County"},
        {name:"TX",label:"Walker County"},
        {name:"TX",label:"Waller County"},
        {name:"TX",label:"Ward County"},
        {name:"TX",label:"Washington County"},
        {name:"TX",label:"Webb County"},
        {name:"TX",label:"Wharton County"},
        {name:"TX",label:"Wheeler County"},
        {name:"TX",label:"Wichita County"},
        {name:"TX",label:"Wilbarger County"},
        {name:"TX",label:"Willacy County"},
        {name:"TX",label:"Williamson County"},
        {name:"TX",label:"Wilson County"},
        {name:"TX",label:"Winkler County"},
        {name:"TX",label:"Wise County"},
        {name:"TX",label:"Wood County"},
        {name:"TX",label:"Yoakum County"},
        {name:"TX",label:"Young County"},
        {name:"TX",label:"Zapata County"},
        {name:"TX",label:"Zavala County"},
        {name:"UT",label:"Beaver County"},
        {name:"UT",label:"Box Elder County"},
        {name:"UT",label:"Cache County"},
        {name:"UT",label:"Carbon County"},
        {name:"UT",label:"Daggett County"},
        {name:"UT",label:"Davis County"},
        {name:"UT",label:"Duchesne County"},
        {name:"UT",label:"Emery County"},
        {name:"UT",label:"Garfield County"},
        {name:"UT",label:"Grand County"},
        {name:"UT",label:"Iron County"},
        {name:"UT",label:"Juab County"},
        {name:"UT",label:"Kane County"},
        {name:"UT",label:"Millard County"},
        {name:"UT",label:"Morgan County"},
        {name:"UT",label:"Piute County"},
        {name:"UT",label:"Rich County"},
        {name:"UT",label:"Salt Lake County"},
        {name:"UT",label:"San Juan County"},
        {name:"UT",label:"Sanpete County"},
        {name:"UT",label:"Sevier County"},
        {name:"UT",label:"Summit County"},
        {name:"UT",label:"Tooele County"},
        {name:"UT",label:"Uintah County"},
        {name:"UT",label:"Utah County"},
        {name:"UT",label:"Wasatch County"},
        {name:"UT",label:"Washington County"},
        {name:"UT",label:"Wayne County"},
        {name:"UT",label:"Weber County"},
        {name:"VT",label:"Addison County"},
        {name:"VT",label:"Bennington County"},
        {name:"VT",label:"Caledonia County"},
        {name:"VT",label:"Chittenden County"},
        {name:"VT",label:"Essex County"},
        {name:"VT",label:"Franklin County"},
        {name:"VT",label:"Grand Isle County"},
        {name:"VT",label:"Lamoille County"},
        {name:"VT",label:"Orange County"},
        {name:"VT",label:"Orleans County"},
        {name:"VT",label:"Rutland County"},
        {name:"VT",label:"Washington County"},
        {name:"VT",label:"Windham County"},
        {name:"VT",label:"Windsor County"},
        {name:"VA",label:"Accomack County"},
        {name:"VA",label:"Albemarle County"},
        {name:"VA",label:"Alleghany County"},
        {name:"VA",label:"Amelia County"},
        {name:"VA",label:"Amherst County"},
        {name:"VA",label:"Appomattox County"},
        {name:"VA",label:"Arlington County"},
        {name:"VA",label:"Augusta County"},
        {name:"VA",label:"Bath County"},
        {name:"VA",label:"Bedford County"},
        {name:"VA",label:"Bland County"},
        {name:"VA",label:"Botetourt County"},
        {name:"VA",label:"Brunswick County"},
        {name:"VA",label:"Buchanan County"},
        {name:"VA",label:"Buckingham County"},
        {name:"VA",label:"Campbell County"},
        {name:"VA",label:"Caroline County"},
        {name:"VA",label:"Carroll County"},
        {name:"VA",label:"Charles City County"},
        {name:"VA",label:"Charlotte County"},
        {name:"VA",label:"Chesterfield County"},
        {name:"VA",label:"Clarke County"},
        {name:"VA",label:"Craig County"},
        {name:"VA",label:"Culpeper County"},
        {name:"VA",label:"Cumberland County"},
        {name:"VA",label:"Dickenson County"},
        {name:"VA",label:"Dinwiddie County"},
        {name:"VA",label:"Essex County"},
        {name:"VA",label:"Fairfax County"},
        {name:"VA",label:"Fauquier County"},
        {name:"VA",label:"Floyd County"},
        {name:"VA",label:"Fluvanna County"},
        {name:"VA",label:"Franklin County"},
        {name:"VA",label:"Frederick County"},
        {name:"VA",label:"Giles County"},
        {name:"VA",label:"Gloucester County"},
        {name:"VA",label:"Goochland County"},
        {name:"VA",label:"Grayson County"},
        {name:"VA",label:"Greene County"},
        {name:"VA",label:"Greensville County"},
        {name:"VA",label:"Halifax County"},
        {name:"VA",label:"Hanover County"},
        {name:"VA",label:"Henrico County"},
        {name:"VA",label:"Henry County"},
        {name:"VA",label:"Highland County"},
        {name:"VA",label:"Isle of Wight County"},
        {name:"VA",label:"James City County"},
        {name:"VA",label:"King and Queen County"},
        {name:"VA",label:"King George County"},
        {name:"VA",label:"King William County"},
        {name:"VA",label:"Lancaster County"},
        {name:"VA",label:"Lee County"},
        {name:"VA",label:"Loudoun County"},
        {name:"VA",label:"Louisa County"},
        {name:"VA",label:"Lunenburg County"},
        {name:"VA",label:"Madison County"},
        {name:"VA",label:"Mathews County"},
        {name:"VA",label:"Mecklenburg County"},
        {name:"VA",label:"Middlesex County"},
        {name:"VA",label:"Montgomery County"},
        {name:"VA",label:"Nelson County"},
        {name:"VA",label:"New Kent County"},
        {name:"VA",label:"Northampton County"},
        {name:"VA",label:"Northumberland County"},
        {name:"VA",label:"Nottoway County"},
        {name:"VA",label:"Orange County"},
        {name:"VA",label:"Page County"},
        {name:"VA",label:"Patrick County"},
        {name:"VA",label:"Pittsylvania County"},
        {name:"VA",label:"Powhatan County"},
        {name:"VA",label:"Prince Edward County"},
        {name:"VA",label:"Prince George County"},
        {name:"VA",label:"Prince William County"},
        {name:"VA",label:"Pulaski County"},
        {name:"VA",label:"Rappahannock County"},
        {name:"VA",label:"Richmond County"},
        {name:"VA",label:"Roanoke County"},
        {name:"VA",label:"Rockbridge County"},
        {name:"VA",label:"Rockingham County"},
        {name:"VA",label:"Russell County"},
        {name:"VA",label:"Scott County"},
        {name:"VA",label:"Shenandoah County"},
        {name:"VA",label:"Smyth County"},
        {name:"VA",label:"Southampton County"},
        {name:"VA",label:"Spotsylvania County"},
        {name:"VA",label:"Stafford County"},
        {name:"VA",label:"Surry County"},
        {name:"VA",label:"Sussex County"},
        {name:"VA",label:"Tazewell County"},
        {name:"VA",label:"Warren County"},
        {name:"VA",label:"Washington County"},
        {name:"VA",label:"Westmoreland County"},
        {name:"VA",label:"Wise County"},
        {name:"VA",label:"Wythe County"},
        {name:"VA",label:"York County"},
        {name:"VA",label:"Alexandria city"},
        {name:"VA",label:"Bedford city"},
        {name:"VA",label:"Bristol city"},
        {name:"VA",label:"Buena Vista city"},
        {name:"VA",label:"Charlottesville city"},
        {name:"VA",label:"Chesapeake city"},
        {name:"VA",label:"Colonial Heights city"},
        {name:"VA",label:"Covington city"},
        {name:"VA",label:"Danville city"},
        {name:"VA",label:"Emporia city"},
        {name:"VA",label:"Fairfax city"},
        {name:"VA",label:"Falls Church city"},
        {name:"VA",label:"Franklin city"},
        {name:"VA",label:"Fredericksburg city"},
        {name:"VA",label:"Galax city"},
        {name:"VA",label:"Hampton city"},
        {name:"VA",label:"Harrisonburg city"},
        {name:"VA",label:"Hopewell city"},
        {name:"VA",label:"Lexington city"},
        {name:"VA",label:"Lynchburg city"},
        {name:"VA",label:"Manassas city"},
        {name:"VA",label:"Manassas Park city"},
        {name:"VA",label:"Martinsville city"},
        {name:"VA",label:"Newport News city"},
        {name:"VA",label:"Norfolk city"},
        {name:"VA",label:"Norton city"},
        {name:"VA",label:"Petersburg city"},
        {name:"VA",label:"Poquoson city"},
        {name:"VA",label:"Portsmouth city"},
        {name:"VA",label:"Radford city"},
        {name:"VA",label:"Richmond city"},
        {name:"VA",label:"Roanoke city"},
        {name:"VA",label:"Salem city"},
        {name:"VA",label:"Staunton city"},
        {name:"VA",label:"Suffolk city"},
        {name:"VA",label:"Virginia Beach city"},
        {name:"VA",label:"Waynesboro city"},
        {name:"VA",label:"Williamsburg city"},
        {name:"VA",label:"Winchester city"},
        {name:"WA",label:"Adams County"},
        {name:"WA",label:"Asotin County"},
        {name:"WA",label:"Benton County"},
        {name:"WA",label:"Chelan County"},
        {name:"WA",label:"Clallam County"},
        {name:"WA",label:"Clark County"},
        {name:"WA",label:"Columbia County"},
        {name:"WA",label:"Cowlitz County"},
        {name:"WA",label:"Douglas County"},
        {name:"WA",label:"Ferry County"},
        {name:"WA",label:"Franklin County"},
        {name:"WA",label:"Garfield County"},
        {name:"WA",label:"Grant County"},
        {name:"WA",label:"Grays Harbor County"},
        {name:"WA",label:"Island County"},
        {name:"WA",label:"Jefferson County"},
        {name:"WA",label:"King County"},
        {name:"WA",label:"Kitsap County"},
        {name:"WA",label:"Kittitas County"},
        {name:"WA",label:"Klickitat County"},
        {name:"WA",label:"Lewis County"},
        {name:"WA",label:"Lincoln County"},
        {name:"WA",label:"Mason County"},
        {name:"WA",label:"Okanogan County"},
        {name:"WA",label:"Pacific County"},
        {name:"WA",label:"Pend Oreille County"},
        {name:"WA",label:"Pierce County"},
        {name:"WA",label:"San Juan County"},
        {name:"WA",label:"Skagit County"},
        {name:"WA",label:"Skamania County"},
        {name:"WA",label:"Snohomish County"},
        {name:"WA",label:"Spokane County"},
        {name:"WA",label:"Stevens County"},
        {name:"WA",label:"Thurston County"},
        {name:"WA",label:"Wahkiakum County"},
        {name:"WA",label:"Walla Walla County"},
        {name:"WA",label:"Whatcom County"},
        {name:"WA",label:"Whitman County"},
        {name:"WA",label:"Yakima County"},
        {name:"WV",label:"Barbour County"},
        {name:"WV",label:"Berkeley County"},
        {name:"WV",label:"Boone County"},
        {name:"WV",label:"Braxton County"},
        {name:"WV",label:"Brooke County"},
        {name:"WV",label:"Cabell County"},
        {name:"WV",label:"Calhoun County"},
        {name:"WV",label:"Clay County"},
        {name:"WV",label:"Doddridge County"},
        {name:"WV",label:"Fayette County"},
        {name:"WV",label:"Gilmer County"},
        {name:"WV",label:"Grant County"},
        {name:"WV",label:"Greenbrier County"},
        {name:"WV",label:"Hampshire County"},
        {name:"WV",label:"Hancock County"},
        {name:"WV",label:"Hardy County"},
        {name:"WV",label:"Harrison County"},
        {name:"WV",label:"Jackson County"},
        {name:"WV",label:"Jefferson County"},
        {name:"WV",label:"Kanawha County"},
        {name:"WV",label:"Lewis County"},
        {name:"WV",label:"Lincoln County"},
        {name:"WV",label:"Logan County"},
        {name:"WV",label:"McDowell County"},
        {name:"WV",label:"Marion County"},
        {name:"WV",label:"Marshall County"},
        {name:"WV",label:"Mason County"},
        {name:"WV",label:"Mercer County"},
        {name:"WV",label:"Mineral County"},
        {name:"WV",label:"Mingo County"},
        {name:"WV",label:"Monongalia County"},
        {name:"WV",label:"Monroe County"},
        {name:"WV",label:"Morgan County"},
        {name:"WV",label:"Nicholas County"},
        {name:"WV",label:"Ohio County"},
        {name:"WV",label:"Pendleton County"},
        {name:"WV",label:"Pleasants County"},
        {name:"WV",label:"Pocahontas County"},
        {name:"WV",label:"Preston County"},
        {name:"WV",label:"Putnam County"},
        {name:"WV",label:"Raleigh County"},
        {name:"WV",label:"Randolph County"},
        {name:"WV",label:"Ritchie County"},
        {name:"WV",label:"Roane County"},
        {name:"WV",label:"Summers County"},
        {name:"WV",label:"Taylor County"},
        {name:"WV",label:"Tucker County"},
        {name:"WV",label:"Tyler County"},
        {name:"WV",label:"Upshur County"},
        {name:"WV",label:"Wayne County"},
        {name:"WV",label:"Webster County"},
        {name:"WV",label:"Wetzel County"},
        {name:"WV",label:"Wirt County"},
        {name:"WV",label:"Wood County"},
        {name:"WV",label:"Wyoming County"},
        {name:"WI",label:"Adams County"},
        {name:"WI",label:"Ashland County"},
        {name:"WI",label:"Barron County"},
        {name:"WI",label:"Bayfield County"},
        {name:"WI",label:"Brown County"},
        {name:"WI",label:"Buffalo County"},
        {name:"WI",label:"Burnett County"},
        {name:"WI",label:"Calumet County"},
        {name:"WI",label:"Chippewa County"},
        {name:"WI",label:"Clark County"},
        {name:"WI",label:"Columbia County"},
        {name:"WI",label:"Crawford County"},
        {name:"WI",label:"Dane County"},
        {name:"WI",label:"Dodge County"},
        {name:"WI",label:"Door County"},
        {name:"WI",label:"Douglas County"},
        {name:"WI",label:"Dunn County"},
        {name:"WI",label:"Eau Claire County"},
        {name:"WI",label:"Florence County"},
        {name:"WI",label:"Fond du Lac County"},
        {name:"WI",label:"Forest County"},
        {name:"WI",label:"Grant County"},
        {name:"WI",label:"Green County"},
        {name:"WI",label:"Green Lake County"},
        {name:"WI",label:"Iowa County"},
        {name:"WI",label:"Iron County"},
        {name:"WI",label:"Jackson County"},
        {name:"WI",label:"Jefferson County"},
        {name:"WI",label:"Juneau County"},
        {name:"WI",label:"Kenosha County"},
        {name:"WI",label:"Kewaunee County"},
        {name:"WI",label:"La Crosse County"},
        {name:"WI",label:"Lafayette County"},
        {name:"WI",label:"Langlade County"},
        {name:"WI",label:"Lincoln County"},
        {name:"WI",label:"Manitowoc County"},
        {name:"WI",label:"Marathon County"},
        {name:"WI",label:"Marinette County"},
        {name:"WI",label:"Marquette County"},
        {name:"WI",label:"Menominee County"},
        {name:"WI",label:"Milwaukee County"},
        {name:"WI",label:"Monroe County"},
        {name:"WI",label:"Oconto County"},
        {name:"WI",label:"Oneida County"},
        {name:"WI",label:"Outagamie County"},
        {name:"WI",label:"Ozaukee County"},
        {name:"WI",label:"Pepin County"},
        {name:"WI",label:"Pierce County"},
        {name:"WI",label:"Polk County"},
        {name:"WI",label:"Portage County"},
        {name:"WI",label:"Price County"},
        {name:"WI",label:"Racine County"},
        {name:"WI",label:"Richland County"},
        {name:"WI",label:"Rock County"},
        {name:"WI",label:"Rusk County"},
        {name:"WI",label:"St. Croix County"},
        {name:"WI",label:"Sauk County"},
        {name:"WI",label:"Sawyer County"},
        {name:"WI",label:"Shawano County"},
        {name:"WI",label:"Sheboygan County"},
        {name:"WI",label:"Taylor County"},
        {name:"WI",label:"Trempealeau County"},
        {name:"WI",label:"Vernon County"},
        {name:"WI",label:"Vilas County"},
        {name:"WI",label:"Walworth County"},
        {name:"WI",label:"Washburn County"},
        {name:"WI",label:"Washington County"},
        {name:"WI",label:"Waukesha County"},
        {name:"WI",label:"Waupaca County"},
        {name:"WI",label:"Waushara County"},
        {name:"WI",label:"Winnebago County"},
        {name:"WI",label:"Wood County"},
        {name:"WY",label:"Albany County"},
        {name:"WY",label:"Big Horn County"},
        {name:"WY",label:"Campbell County"},
        {name:"WY",label:"Carbon County"},
        {name:"WY",label:"Converse County"},
        {name:"WY",label:"Crook County"},
        {name:"WY",label:"Fremont County"},
        {name:"WY",label:"Goshen County"},
        {name:"WY",label:"Hot Springs County"},
        {name:"WY",label:"Johnson County"},
        {name:"WY",label:"Laramie County"},
        {name:"WY",label:"Lincoln County"},
        {name:"WY",label:"Natrona County"},
        {name:"WY",label:"Niobrara County"},
        {name:"WY",label:"Park County"},
        {name:"WY",label:"Platte County"},
        {name:"WY",label:"Sheridan County"},
        {name:"WY",label:"Sublette County"},
        {name:"WY",label:"Sweetwater County"},
        {name:"WY",label:"Teton County"},
        {name:"WY",label:"Uinta County"},
        {name:"WY",label:"Washakie County"},
        {name:"WY",label:"Weston County"}
    ]

    const {register,handleSubmit, control, setError, setFocus ,formState:{ errors, isSubmitting},setValue,getValues} = useForm({
        mode:"all"
    });

    /*const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: CONFIG.GOOGLE_PLACES_KEY,
        options: {
            componentRestrictions: { country: "us" },
        },
    });*/

    useEffect(()=>{
        setShowAddressDropDown(false)
    },[props.isClickedOutside])

    useEffect( ()=>{
        if(props.address && Object.keys(props.address).length > 0){
            setEditData()
        }else {
            setDependentLivedHereSince(new Date())
        }
    },[props.address]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(  ()=>{
        if(isSubmitting){
             formFieldValidate()
        }
    },[isSubmitting]) // eslint-disable-line react-hooks/exhaustive-deps

    const formFieldValidate = async () => {
        let livedHere = false;
        let resident = false;
        // if(dependentLivedHereSince.length < 1 ){
        //     await  setDependentLivedError(true)
        //     setDependentLivedErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DEPENDENT_LIVED_HERE_SINCE_IS_REQUIRED);
        //     livedHere = true;
        // }else{
        //     await  setDependentLivedError(false);
        //     livedHere = false;
        // }
        if(Object.keys(selectResident).length < 1){
            await  setResidenceError(true)
            await  setResidenceErrorMeesage(A12Y_CONST.ERROR_MESSAGE.RESIDENCE_IS_REQUIRED)
            resident = true
        }else{
            await  setResidenceError(false)
            resident = false;
        }

        if(Object.keys(county).length < 1){
            await  setCountyError(true)
            await  setCountyErrorMeesage(A12Y_CONST.ERROR_MESSAGE.RESIDENCE_IS_REQUIRED)
            resident = true
        }else{
            await  setCountyError(false)
            resident = false;
        }

        if(livedHere || resident){
            return true;
        }else{
            return false;
        }
    }

    const getPlaceFromService = async (obj)=>{
        //if (!isBrowser) return;
        const request = {
            input: obj.input,
            componentRestrictions: {country : 'us'},
            fields: ["name", "geometry", "formatted_address", "place_id"],
        };
        if(window.google){

            let service = new window.google.maps.places.AutocompleteService(document.createElement("div"));
            service.getPlacePredictions(request,(results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                    setPlaceServiceList(results)
                }
            });
        }
    }

    const setEditData = async() =>{
        setValue('add1',props.address.line1)
        setValue('add2',props.address.line2)
        setLine2(props.address.line2)
        setValue('city',props.address.city)
        setCity(props.address.city)
        setValue('zip',props.address.zip)
        setZip(props.address.zip)
        setValue('reason_for_move',props.address.reason_for_move)
        setReasonForMove(props.address.reason_for_move)
        if(props.address.type){
            dependentResidenceTypeList.forEach((item,index)=>{
                if(props.address.type === item.key){
                    setSelectResident(item)
                }
            })
        }
        if(props.address.county){
            let data = countyList.filter(item => item.label === props.address.county)
            if(data.length > 0){
                setCounty(data[0]);
            }
        }if(props.address.state){
            let data = countryStateList.filter(item => item.label === props.address.state)
            if(data.length > 0){
                setCountryState(data[0]);
            }
        }
        if(props.address.start_date){
            // let date = await ddmmyyyyFormat(new Date(props.address.start_date))
            // setValue("since",date)
            setDependentLivedHereSince(new Date(props.address.start_date))
        }
        await setEditMode(true);

        if(props.address.reason_for_move){
            setmovingLocation(true);
        }
    }

    /*useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length){
            placePredictions.map((place,key) => {placesService?.getDetails(
                {
                    placeId: place.place_id,
                },
                (placeDetails) =>{
                    setPlacePredictionList(oldArray => [...oldArray, placeDetails]);
                }
            );});
        }
    }, [placePredictions]);*/

    useEffect(()=>{
        setErrors(props.error)
        setLoading(props.loading);
        //setShowLayer(props.showLayer);
        if(props.loading){
            setIsBtnDisabled(true)
        }
    },[props.error, props.loading, props.setShowLayer]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(()=>{
        setShowAddressDropDown(false)
    },[props.isClickedOutside]) // eslint-disable-line react-hooks/exhaustive-deps

    const selectAdd = (add) =>{
        if(add){
            setShowAddressDropDown(false)
            const request = {
                placeId: add.place_id,
                fields: ["name", "formatted_address", "place_id", "geometry", "address_components"],
            };
            const service = new window.google.maps.places.PlacesService(document.createElement("div"));

            service.getDetails(request, (place, status) => {
                setLine1('');
                setValue("add1", '')
                place.address_components.forEach((item) => {
                    if (item.types.length > 0) {
                        item.types.forEach((type) => {
                            if (type === "street_number") {
                                setValue("add1", item.short_name)
                                setLine1(item.short_name)
                            }
                            if (type === "route") {
                                let add = getValues("add1")
                                if (add.trim() === "") {
                                    setValue("add1", item.short_name)
                                    setLine1(item.short_name)
                                } else {
                                    setValue("add1", add + " " + item.short_name)
                                    setLine1(add + " " + item.short_name)
                                }
                            }
                            if (type === "postal_code") {
                                setValue("zip", item.short_name)
                                setZip(item.short_name)
                            }
                            if (type === "locality") {
                                setValue("city", item.short_name)
                                setCity(item.short_name)
                            }
                            /*if(type === "neighborhood"){
                                setValue("address2",item.short_name)
                            }
                            if(type === "sublocality_level_1"){
                                let add = getValues("address2")
                                if(add.trim() === ""){
                                    setValue("address2",item.short_name)
                                }else {
                                    setValue("address2",add+" "+item.short_name)
                                }

                            }*/
                            if (type === "administrative_area_level_1") {
                                let stateObj = countryStateList.filter(data => data.label === item.short_name)
                                setCountryState(stateObj[0])
                                //setValue("address2",item.short_name)
                            }
                            if (type === "administrative_area_level_2") {
                                let countyObj = countyList.filter(data => data.label === item.short_name)
                                if (countyObj && countyObj.length > 0) {
                                    setCounty(countyObj[0])
                                }

                                //setValue("address2",item.short_name)
                            }
                        })
                    }
                })
            })

            let address1 = getValues("add1")
            if(address1.trim() === ""){
                setValue("add1",address1.formatted_address)
                setLine1(address1.formatted_address)
            }

        }
        /*let addObj = {
            "address_line1" : getValues('address1'),
            "address_line2" : getValues('address2'),
            "city" : getValues('city'),
            "state" : countryState ? countryState.label : '',
            "zip" : getValues('zipcode'),
            "country_code" : "USA"

        }
        setSelectedAdd(addObj)*/
        setDropDownIndex(null)
    }
    const onFocusEventHandler = () => {
        if(showAddressDropDown){
            setShowAddressDropDown(false)
        }
    }

    const onFocusEventHadlerZip = () => {
        setZipError(false)
        onFocusEventHandler()
    }
    const stateOptions = countryStateList && countryStateList.map(state =>{
        return {label: state.label, value: state.label}
    })
    const countyOptions = countyList1[countryState.label] && countyList1[countryState.label].map(state =>{
        return {label: state.label, value: state.label}
    })
    const dependentResidenceType = dependentResidenceTypeList && dependentResidenceTypeList.map(state =>{
        return {label: state.label, value: state.label , key: state.key}
    })
    const handleChangeCountryState = e => {
        setCountryState(e);
    }
    const handleChangeCounty = async (e) => {
        setCounty(e);

        if(!e){
            await  setCountyError(true)
            await  setCountyErrorMeesage(A12Y_CONST.ERROR_MESSAGE.COUNTY_IS_REQUIRED)
        }else{
            await  setCountyError(false);
            // if(e.key === 2 && props.userInfo && Object.keys(props.userInfo).length > 0 && props.userInfo.address && Object.keys(props.userInfo.address).length > 0){
            //     await setAdvocatedAddress(props.userInfo.address);
            // }else {
            //     await setAdvocatedAddress(null);
            // }
        }
    }

    const validateSelectCounty = () => {
        if(Object.keys(county).length === 0){
            setCountyError(true)
            setCountyErrorMeesage(A12Y_CONST.ERROR_MESSAGE.COUNTY_IS_REQUIRED)
            return  true
        }else {
            setCountyError(false)
            setCountyErrorMeesage('')
            return false
        }
    }

    const handleSelectResident = async (e) => {
        setSelectResident(e);
        if(!e){
            await  setResidenceError(true)
            await  setResidenceErrorMeesage(A12Y_CONST.ERROR_MESSAGE.RESIDENCE_IS_REQUIRED)
        }else{
            await  setResidenceError(false);
            if(e.key === 2 && props.userInfo && Object.keys(props.userInfo).length > 0 && props.userInfo.address && Object.keys(props.userInfo.address).length > 0){
               await setAdvocatedAddress(props.userInfo.address);
            }else {
                await setAdvocatedAddress(null);
            }
        }
    }

    const validateSelectResident = () => {
        if(Object.keys(selectResident).length === 0){
            setResidenceError(true)
            setResidenceErrorMeesage(A12Y_CONST.ERROR_MESSAGE.RESIDENCE_IS_REQUIRED)
            return  true
        }else {
            setResidenceError(false)
            setResidenceErrorMeesage('')
            return false
        }
    }

    const setAdvocatedAddress = async (add) =>{
        if(add){
            setValue('add1',add.address_line1)
            setValue('add2',add.address_line2)
            setLine2(add.address_line2)
            setValue('city',add.city)
            setCity(add.city)
            setValue('zip',add.zip)
            setZip(add.zip)
            if(add.country_code){
                let data = countyList.filter(item => item.label === add.country_code)
                if(data.length > 0){
                    setCounty(data[0]);
                }
            }if(add.state){
                let data = countryStateList.filter(item => item.label === add.state)
                if(data.length > 0){
                    setCountryState(data[0]);
                }
            }
        }else {
            setValue('add1','')
            setValue('add2','')
            setLine2('')
            setValue('city','')
            setCity('')
            setValue('zip', '')
            setZip('')
            setCounty({});
            setCountryState({});
        }
    }

   /* const checkValue = (str, max) => {
        if (str.charAt(0) !== '0' || str === '00') {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str = num > parseInt(max.toString().charAt(0))
            && num.toString().length === 1 ? '0' + num : num.toString();
        };
        return str;
    };*/

    const onSubmit = async (user) => {
        let validForm = await formFieldValidate();
        if(!validForm){
            let data = user
            // if (dependentLivedHereSince && dependentLivedHereSince.split("/").length === 3) {
            //     let dobSplitList = dependentLivedHereSince.split("/")
            //     let date = dobSplitList[0].trim() + "/" + dobSplitList[1].trim() + "/" + dobSplitList[2].trim();
            //     data.start_date = dependentLivedHereSince
            // }
            data.start_date = await ddmmyyyyFormat(new Date(dependentLivedHereSince))
            data.reason_for_move = reasonForMove
            delete data["since"];
            data.line1 = data.add1;
            data.line2 = data.add2;
            data.country_code = "1";
            data.zip = zip;
            data.country = "US";
            if(selectResident){
                data.type = selectResident.key;
            }if(county){
                data.county = county.label
            }if(countryState){
                data.state = countryState.label
            }

            if(dependentLivedError){
                return
            }
            props.onSubmit(data)
        }
    }
    // const onCancel = async()=>{
    //     props.onCancel()
    // }

    const onCancel = ()=>{
        if(isDataModified){
            setIsPopUp(true)
        }else {
            props.onCancel()
        }

    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        props.isDataChanged(false)
        setIsPopUp(false)
        props.onCancel()
    }

    const handelLine2 = async (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            await setLine2(value)
        }
    }

    const handelCity = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setCity(value)
        }
    }

    const handelZip = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setZip(value)
        }
    }

    const handelReasonForMove = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setReasonForMove(value)
        }
    }


    useEffect( ()=>{
        const checkFormDataIsChanged = async ()=>{
            setIsBtnDisabled(true)
            let address = props.address
            if(editMode && Object.keys(address).length > 0){
                props.isDataChanged(false)
                setDataModified(false)
                let livedHereFrom = await ddmmyyyyFormat(new Date(dependentLivedHereSince))
                let line1 = await getValues('add1')
                if(( dependentLivedHereSince !== null && address.start_date !== livedHereFrom) || (Object.keys(county).length > 0 && address.county !== county.label) || (Object.keys(selectResident).length > 0 && address.type !== selectResident.key) || address.zip !== zip || address.line1 !== line1 || address.line2 !== line2 || address.city !== city || (Object.keys(countryState).length >0 && address.state !== countryState.label) || address.zip !== zip || (Object.keys(county).length > 0 && address.county !== county.label) || address.reason_for_move !== reasonForMove){
                    if(dependentLivedHereSince !== null && Object.keys(selectResident).length > 0 && Object.keys(county).length > 0 && line1 !== undefined && line1 !== "" && line1?.length > 2 && zip !== "" && zip !== undefined){
                        // setIsBtnDisabled(false);
                        if(movingLocation){
                            if(reasonForMove === ""){
                                setIsBtnDisabled(true);
                            }else{
                                setIsBtnDisabled(false);
                            }
                        }else{
                            setIsBtnDisabled(false);
                        }
                    }
                    props.isDataChanged(true)
                    setDataModified(true)
                }
            }else {
                if(Object.keys(address).length < 1){
                    props.isDataChanged(false)
                    setDataModified(false)
                    let livedHereFrom = await ddmmyyyyFormat(new Date(dependentLivedHereSince))
                    let today = await ddmmyyyyFormat(new Date())
                    let line1 = await getValues('add1')
                    if((dependentLivedHereSince !== null && livedHereFrom !== today) || (Object.keys(selectResident).length > 0) || (Object.keys(county).length > 0)  || zip !== "" ||  line1 !== "" || line2 !=="" || city !=="" || (Object.keys(countryState).length >0) ||  zip !=="" || (Object.keys(county).length > 0) || (movingLocation && reasonForMove !== "")){
                        if(dependentLivedHereSince !== null && Object.keys(selectResident).length > 0 && Object.keys(county).length > 0 && line1 !== undefined && line1 !== "" && zip !== undefined && zip !== "" && line1?.length > 2 ){
                            if(movingLocation){
                                if(reasonForMove === ""){
                                    setIsBtnDisabled(true);
                                }else{
                                    setIsBtnDisabled(false);
                                }
                            }else{
                                setIsBtnDisabled(false);
                            }

                        }
                        props.isDataChanged(true)
                        setDataModified(true)
                    }
                }
            }
        }
         checkFormDataIsChanged()
    },[dependentLivedHereSince,selectResident,line2,city,zip,countryState,county,getValues('add1'),reasonForMove, line1, movingLocation]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleDateChange = () => {
        if(dependentLivedHereSince != null){
            let isValidObj = validateDate(dependentLivedHereSince, 100, 0)
            setDependentLivedError(isValidObj.isErr)
            setDependentLivedErrorMeesage(isValidObj.errMsg)

        }else{
            setDependentLivedError(true)
            setDependentLivedErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }

    }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter' && dropDownIndex !== null){
            e.preventDefault();
        }
    };

    const onMouseMoveEvent = async ()=>{
        if(dropDownIndex !== null){
            setDropDownIndex(null)
        }
    }

    const handelLine1 = async (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
           await setLine1(value)
        }
    }

    const handlemovingLocation = async (e) => {
       await setmovingLocation(e.target.checked);
       await setValue('movingLocation', e.target.checked)
        if(!e.target.checked){
            setReasonForMove('')
        }
        // if(movingLocation){
        //     setFocus("reason_for_move");
        // }else{
        //     setFocus("reason_for_move");
        // }
    }

    useEffect(() => {
        if(movingLocation && reasonForMove === ''){
            setFocus("reason_for_move");
        }
    }, [movingLocation, reasonForMove]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        let arr =[dependentLivedHereSinceRef.current]
        readOnlyMobile(arr)
    },[])


    const validateZip = (e) => {
        if(zip === '' && zip.length === 0) {
            setZipError(true)
            setZipErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ZIP_IS_REQUIRED);
            return true;
        }else{
            setZipError(false)
            setZipErrorMeesage('');
            return false;
        }
    }

    useEffect(() => {
      if(zip !== ''){
          setZipError(false);
      }
    }, [zip]);

    return (
        <div className="dependentBorderBox">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className="fullWidth DependentStep1FormCol positionRelative paddingBottom100">
            <div className="dependentSubTitle marginBottom5">
                {A12Y_CONST.TEXT.ADDRESS}
            </div>

            {error ?
                <div className='maxWidth600 marginLeftRightAuto'>
                    <ErrorMessageComponent errorMessage={props.errorMessage}/>
                </div>
                :
                (props.message ?
                        <SuccessMessageComponent successMessage={props.message}/>

                        :
                        null
                )

            }
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)} className='marginTop12 paddingTop20' autoComplete="off">
                <div className='dependentStepEditFormRow fullWidth dependentRowSpace positionRelative livedWrap'>
                    <label className='labelCol maxWidth250'>{A12Y_CONST.LABEL.DEPENDENT_LIVED_HERE_SINCE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className='fullWidth maxWidth338' id={A12Y_CONST.BUTTON_ID.DEPENDENT_MOVE_DATE}>
                        <Controller
                            name="since"
                            control={control}
                            inputmode='none'
                            render={({ field }) => 
                                <DatePicker
                                    {...field}
                                    ref={dependentLivedHereSinceRef}
                                    dateFormat="MM / dd / yyyy"
                                    selected={dependentLivedHereSince}
                                    onChange={date => setDependentLivedHereSince(date)}
                                    onChangeRaw={(event) => handleChangeRaw(event, 'since')}
                                    onBlur={handleDateChange}
                                    onCalendarClose={handleDateChange}
                                    // onFocus={e => readOnlyOnMobile(e.target)}
                                    placeholderText="MM / DD / YYYY"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onFocus={()=>{
                                        setDependentLivedError(false)
                                    }}
                                    className={!dependentLivedError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                />
                            }
                        />
                        <div className='errorRelativeRow'> 
                            {dependentLivedError && <p className='errorMessage'>{dependentLivedErrorMeesage}</p>}
                        </div>
                    </div>
                </div>
                <div className='dependentStepEditFormRow fullWidth marginTop15 marginBottom30 positionRelative'>
                    <label className='labelCol residence'>{A12Y_CONST.LABEL.RESIDENCE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className={`fullWidth dependentResidencCol ${residenceError && !selectResident.label && 'selectError'} ${ selectResident && Object.keys(selectResident).length > 0 ? 'hasValue' : 'noValue'}`}>
                        <Select
                            classNamePrefix={'dependentResidence'}
                            placeholder="Select"
                            value={selectResident.label ? selectResident : ""}
                            options={dependentResidenceType}
                            onChange={handleSelectResident}
                            onBlur={validateSelectResident}
                            onFocus={()=> {setResidenceError(false);setResidenceErrorMeesage('');}}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0096C708',
                                },
                            })}
                        />
                    </div>
                    <div className='errorRelativeRow'> {residenceError && !selectResident.label ? <p className='errorMessage'>{residenceErrorMeesage}</p>  : null}</div>
                    </div>
                <div className='dependentStepEditFormRow fullWidth positionRelative marginTop10 addressMargin'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.GAURDIAN_PROFILE_PERSONAL_INFO_ADDRESS}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className='fullWidth marginBottom30 address1'>
                        <input
                            {...register('add1', { required: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED })}
                            placeholder='Street Address'
                            className={errors.line1 ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                            type='text'
                            maxLength='255'
                            onChange={handelLine1}
                            onKeyUp={(evt) => {
                                //setPlacePredictionList([])
                                if(evt.target.value === " "){
                                    setValue('add1',evt.target.value.trim())
                                    setLine1(evt.target.value.trim());
                                }
                                if(evt.target.value.length > 2 ){
                                    if(evt.key === "ArrowDown" || evt.key === "ArrowUp" || evt.key === 'Enter'){
                                        if(dropDownIndex === null){
                                            setDropDownIndex(0)
                                        }else {
                                            setDropDownIndex(evt.key === "ArrowUp" && dropDownIndex !== 0 ? dropDownIndex -1 : evt.key === "ArrowDown" && placeServiceList.length-1 > dropDownIndex ? dropDownIndex +1 : dropDownIndex )
                                            if(evt.key === 'Enter' && dropDownIndex !== null && placeServiceList.length > 0){
                                                selectAdd(placeServiceList[dropDownIndex])
                                            }
                                        }

                                    }else {
                                        setLine1(evt.target.value);
                                        setShowAddressDropDown(true)
                                        //getPlacePredictions({ input: evt.target.value });
                                        getPlaceFromService({ input: evt.target.value });
                                    }
                                }
                            }}
                            // loading={isPlacePredictionsLoading}
                            id='add1'
                            autoComplete="new-no-line1"
                            //onBlur={()=>setShowAddressDropDown(false)}
                            onFocus={()=>{
                                setError("add1", {
                                    type: "manual",
                                    message: "",
                                });
                            }}
                        />
                        {/*<div onClick={()=>{selectAdd(null)}} >Hello</div>)*/}
                        {showAddressDropDown && placeServiceList.length > 0?
                            <div className='addressLin1DropDown'>
                                <div className="addressLin1DropDownInner">
                                    {placeServiceList && placeServiceList.map((item,key) =>
                                        (item && item.description ?
                                                <div onMouseMove={onMouseMoveEvent} className={dropDownIndex === key ? 'addressListDropDownHighlight' : dropDownIndex === null ? 'addressListDropDown addressHover' : 'addressListDropDown' } onClick={()=>{selectAdd(item)}} key={key}>{item.description}</div>
                                                :
                                                null
                                        )
                                    )}
                                </div>
                            </div>
                            :
                            null
                        }
                        {/*{placePredictions.map((item) => <div>{item.description}</div>)}*/}
                        <div className='errorRelativeRow'> {errors.line1 && <p className='errorMessage'>{errors.line1.message}</p>}</div>
                    </div>
                </div>
                <div className='dependentStepEditFormRow fullWidth marginTop10 positionRelative '>
                    <label className='labelCol'>{A12Y_CONST.LABEL.UNIT}</label>
                    <div className='fullWidth marginBottom30  address2'>
                        <input
                            {...register('add2',)}
                            placeholder='Apartment, Suite, Unit, Building'
                            className='inputColum24size '
                            maxLength='255'
                            type='text'
                            id='add2'
                            onFocus={onFocusEventHandler}
                            autoComplete="new-no-line2"
                            onChange={handelLine2}
                            value={line2}
                        />
                    </div>
                </div>
                <div className='dependentStepEditFormRow fullWidth addressLabelTop10 positionRelative '>
                    <label className='labelCol labelColCity'>{A12Y_CONST.LABEL.CITY}</label>
                    <div className='fullWidth marginBottom10 cityCountryState'>
                        <div className='width37 paddingRight35 marginBottom30 marginTop10 city'>
                            <input
                                {...register('city',)}
                                placeholder='City'
                                maxLength='100'
                                className='inputColum24size'
                                type='text'
                                id='city'
                                onFocus={onFocusEventHandler}
                                autoComplete="new-no-city"
                                onChange={handelCity}
                                value={city}
                            />
                        </div>
                        <div className='positionRelative width63 floatRight addressLabelTop10 addressRow'>
                        <label className='labelCol'>{A12Y_CONST.LABEL.STATE}</label>
                        <div className={(countryState && Object.keys(countryState).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol paddingLeft35 marginTop18 countryState'}>
                            <Select
                                // selectdisplayprops={countryState}
                                // defaultValue={countryStateList[0]}
                                // classesName='phoneTypeLabel'
                                classNamePrefix={'dependentResidence'}
                                placeholder="Select"
                                maxLength='100'
                                value={countryState.label ? countryState : ""}
                                options={stateOptions}
                                onChange={handleChangeCountryState}
                                // getOptionLabel={e => (
                                //     <div className='phoneListRow'>
                                //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                //     </div>
                                // )}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: 'red',
                                        primary: '#0096C708',
                                    },
                                })}
                            />
                        </div>
                        </div>
                    </div>
                </div>
                <div className='dependentStepEditFormRow fullWidth positionRelative marginBottom30 '>
                    <label className='labelCol'>{A12Y_CONST.LABEL.ZIP}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className='fullWidth'>
                        <div className='width37 paddingRight35 zip'>
                            <input
                                {...register('zip',)}
                                placeholder='00000'
                                className={`fullWidth txtName inputColum24size ${zipError && 'errorInputBorderRed'}`}
                                maxLength='100'
                                type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                onWheel={e => e.target.blur()}
                                id='zip'
                                onFocus={onFocusEventHadlerZip}
                                autoComplete="new-pincode"
                                onChange={handelZip}
                                value={zip}
                                onBlur={validateZip}

                            />
                            <div className='errorRelativeRow'>{zipError? <p className='errorMessage'>{zipErrorMeesage}</p>: null}</div>
                        </div>
                        <div className='positionRelative width63 floatRight addressLabelTop10 addressRow'>
                            <label className='labelCol'>{A12Y_CONST.LABEL.COUNTY}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            {/*<div className={(county && Object.keys(county).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol paddingLeft35 marginTop18 country'}>*/}
                            <div className={`fullWidth dependentResidencCol paddingLeft35 marginTop18 country ${countyError && !county.label && 'selectError'} ${ county && Object.keys(county).length > 0  ? 'hasValue' : 'noValue'}`}>
                            <Select
                                // selectdisplayprops={countryState}
                                // defaultValue={countryStateList[0]}
                                classNamePrefix={'dependentResidence'}
                                placeholder="Select"
                                value={county.label ? county : ""}
                                options={countyOptions}
                                onBlur={validateSelectCounty}
                                onFocus={()=> {setCountyError(false);setCountyErrorMeesage('');}}
                                onChange={handleChangeCounty}
                                // getOptionLabel={e => (
                                //     <div className='phoneListRow'>
                                //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                //     </div>
                                // )}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: 'red',
                                        primary: '#0096C708',
                                    },
                                })}
                            />
                            <div className='errorRelativeRow'> {countyError && !county.label ? <p className='errorMessage'>{countyErrorMeesage}</p>  : null}</div>
                        </div>
                        </div>
                    </div>
                </div>
               <div className="fullWidth df aic marginTop20">
                       <label className="wardMovingLabel">{A12Y_CONST.LABEL.IS_THE_WARD_MOVING}</label>
                       <div className="marginTop5 df aic" >
                           <Controller
                               name="movingLocation"
                               control={control}
                               render={({field}) =>
                                   <Switch
                                       id={A12Y_CONST.BUTTON_ID.DEPENDENT_MOVEING_LOCATION}
                                       checked={movingLocation}
                                       onChange={handlemovingLocation}
                                       color="primary"
                                   />
                               }
                           />
                           {movingLocation && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}
                           {!movingLocation && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}
                       </div>
               </div>
                {movingLocation ?
                    <div className='dependentStepEditFormRow fullWidth marginTop40 positionRelative'>
                        <label className='labelCol'>{A12Y_CONST.LABEL.REASON_FOR_MOVING}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className='fullWidth'>
                            {/*<input*/}
                            {/*    {...register('reason_for_move', { required: A12Y_CONST.ERROR_MESSAGE.REASON_FOR_MOVE })}*/}
                            {/*    placeholder='If long-term residence, type N/A.'*/}
                            {/*    className={errors.reason_for_move ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}*/}
                            {/*    type='text'*/}
                            {/*    maxLength='255'*/}
                            {/*    id='displayName'*/}
                            {/*    onChange={handelReasonForMove}*/}
                            {/*    value={reasonForMove}*/}
                            {/*    onFocus={()=>{*/}
                            {/*        setError("reason_for_move", {*/}
                            {/*            type: "manual",*/}
                            {/*            message: "",*/}
                            {/*        });*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <textarea
                                {...register('reason_for_move', { required: A12Y_CONST.ERROR_MESSAGE.REASON_FOR_MOVE })}
                                rows="3"
                                maxLength='4000'
                                onChange={handelReasonForMove}
                                value={reasonForMove}
                                onFocus={()=>{
                                    setError("reason_for_move", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                                className= {`fullWidth inputColum24size txtNote ${errors.reason_for_move && 'errorInputAllBorderRed'}`}
                                placeholder='If long-term residence, type N/A.'
                            ></textarea>
                            <div className='errorRelativeRow'> {errors.reason_for_move && <p className='errorMessage'>{errors.reason_for_move.message}</p>}</div>
                        </div>
                    </div>
                :
                null
                }

               <div className="fullWidth textAlignRight marginTop30 displayFlexCol bottom_25_600 positionAbsouteBottom" >
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.DEPENDENT_ADDRESS_EDIT_CANCEL} className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            {loading ?
                                <Loading />
                                :
                                null
                            }
                            <input id={A12Y_CONST.BUTTON_ID.DEPENDENT_ADDRESS_EDIT_SAVE} className={loading || isBtnDisabled ? "saveButtonDisable" :'saveButton'} disabled={loading || isBtnDisabled}  type="submit" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE}/>
                        </div>
                    </div>
                </div>
            </form>
            </div>
            <PopUpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
}

export default  DependentAddressEditComponent;