import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux';

import A12Y_CONST from '../common/a12yConst'

class ProtectedRoute extends Component {

    componentDidMount(){
    }

    render() {
        const { component: Component, layout:Layout, ...props } = this.props

        return (
            <Route
                {...props}
                render={props => (
                    this.props.isLoggedIn ?
                       <Layout> <Component {...props} />
                       </Layout>
                           :
                        <Redirect to={A12Y_CONST.ROUTE.LOGIN} />
                )}
            />
        )
    }
}

const mapStateToProps = state => ({
    userExist: state.userDetailReducer.userExist,
    AccessToken : state.userDetailReducer.AccessToken,
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    userProfileData : state.userDetailReducer.userProfileData
});


export default connect(mapStateToProps,null) (ProtectedRoute);
