import  React, {Component} from 'react'
import logo from '../assets/images/logoAdvocord.png'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';

class HeaderColumn extends Component {
    constructor(props) {
        super(props)
        this.state={

        }
    }

    goToSignInScreen=()=>{
        this.props?.history?.push( this.props.isLoggedIn ? A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT : A12Y_CONST.ROUTE.LOGIN);
       // console.log('goToSignInScreen',this.props.isLoggedIn)
    }

    render() {
        return (
           <div className='topLogoRow'>
               <div className={`logoCol  ${this.props?.signUpflow && this.props.signUpflow ? 'logoLeft': null}`} onClick={this.goToSignInScreen}>
                   <a rel="noreferrer" target='_blank' href={A12Y_CONST.TEXT.ADVOCORT_URL} ><img id='MarketingSite' src={logo} alt='logo'/></a>
               </div>
           </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
});


export default connect(mapStateToProps,null)(HeaderColumn);