import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import Loading from './LoadingComponent';
import Select from 'react-select';
import {isValidPhoneNumber}from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorMessageComponent from './ErrorMessageComponent';
import SuccessMessageComponent from './SuccessMessageComponent';
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {currencyFormatting} from "../util/CurrencyFormater";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';

function AddCoOwnerComponent(props) {
	const { register, handleSubmit, control,setValue, formState: {  isSubmitting } } = useForm({ mode: "onBlur" });
    const history = useHistory();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [error, setError] = useState(false);
	const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMeesage, setNameErrorMeesage] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMeesage, setEmailErrorMeesage] = useState('');

    const [precentOwned, setPrecentOwned] = useState('');
    const [precentOwnedError, setPrecentOwnedError] = useState(false);
    const [precentOwnedErrorMeesage, setPrecentOwnedErrorMeesage] = useState('');

    const [dateTransfer, setDateTransfer] = useState(new Date());
    const [dateTransferError, setDateTransferError] = useState(false);
    const [dateTransferErrorMessage, setDateTransferErrorMessage] = useState('');

    const [coOwnerType, setCoOwnerType] = useState('');
    const [coOwnerTypeError, setCoOwnerTypeError] = useState(false);
    const [coOwnerTypeOptionsList, setCoOwnerTypeOptionsList] = useState([]);

    const [valueSold, setValueSold] = useState('');
    const [valueSoldError, setValueSoldError] = useState(false);
    const [valueSoldErrorMeesage, setValueSoldErrorMeesage] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumber_2, setPhoneNumber_2] = useState('');
    const [phoneNumber_1ErrorMessage, setPhoneNumber_1ErrorMessage] = useState('');
    const [phoneNumber_2ErrorMessage, setPhoneNumber_2ErrorMessage] = useState('');
    const [phoneNumberError, setphoneNumberError] = useState(false);
    const [phoneNumber2Error, setphoneNumber2Error] = useState(false);
    const [phoneType1LabelActive, setPhoneType1LabelActive] = useState(false);
    const [phoneType2LabelActive, setPhoneType2LabelActive] = useState(false);
    const [isPhone2DropdownSelect, setIsPhone2DropdownSelect] = useState(false);   
    const [selectedPhoneType1, setSelectedPhoneType1] = useState({});
    const [selectedPhoneType2, setSelectedPhoneType2] = useState({});
    const [dependentVal, setDependentVal] = useState('')
    const [isEditMode, setEditMode] = useState(false);
    const dateTransferRef = useRef(null);

    useState(async () => {
        let dependentValue = props.currentAsset.asset_value || props.currentAsset.asset_value === 0 ? await currencyFormatting(props.currentAsset.asset_value) : ''
        setDependentVal(dependentValue)
    },[props.currentAsset.asset_value, dependentVal])


    const phoneTypeList = [
        {
            value: 'select',
            label: 'Select',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#0096C7"></path>
            </svg>
        },
        {
            value: 'mobile',
            label: 'Mobile',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#2D6CB2"></path>
            </svg>
        },
        {
            value: 'office',
            label: 'Office',
            icon:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'home',
            label: 'Home',
            icon: <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'other',
            label: 'Other',
            icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#71767A" style={{strokeWidth:2}} ></path>
            </svg>,
            iconActive: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#0297C8" style={{strokeWidth:2}}></path>
            </svg>
        }
    ];

    const phoneTypeOptions = phoneTypeList && phoneTypeList.map(phoneTypeList =>{
        return {label:phoneTypeList.label, value: phoneTypeList.label}
    })

    useEffect(()=>{
        setLoading(props.loading);
        setError(props.error)
    },[props.loading,props.error])

    const validateName = (e) =>{
        if(name === '' && name.length === 0) {
            setNameError(true)
            setNameErrorMeesage(A12Y_CONST.ERROR_MESSAGE.NAME_REQUIRED);
            return true;
        }else{
            setNameError(false)
            setNameErrorMeesage('');
            return false;
        }
    }
    const handleNameChange = (e) =>{
        setValue('name',e.target.value)
        setName(e.target.value)
    }

    const validateEmail = (e) =>{
        if(email === '' && email.length === 0) {
            setEmailError(true)
            setEmailErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_EMAIL);
            return true;
        }else if(!/\S+@\S+\.\S+/.test(email)){
            setEmailError(true)
            setEmailErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_EMAIL);
            return true;
        }
        else{
            setEmailError(false)
            setEmailErrorMeesage('');
            return false;
        }
    }
    const handleEmailChange = (e) =>{
        setValue('email',e.target.value)
        setEmail(e.target.value)
    }

    const handleChangePhoneType1 = async e => {
        if(e.label === 'Select'){
            setPhoneNumber("1")
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
        }else {
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone();
        }
        await  setSelectedPhoneType1(e);
    }
    const handleChangePhoneType2 = e => {
        if(e.label === 'Select'){
            setPhoneNumber_2("1")
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone2();
        }
        setSelectedPhoneType2(e);
    }
    const handlePhoneField = e => {
        if(e.length > 1){
            setPhoneType1LabelActive(true)
        }else{
            setPhoneType1LabelActive(false);
        }
        setPhoneNumber(e)
    }
    const onBlurEventHandlerPhone = () => {
        if(phoneNumber.length > 1){
            const isValid =  validatePhoneNumber(phoneNumber)
            if(!isValid){
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
            }else {
                setphoneNumberError(false)
                setPhoneNumber_1ErrorMessage('')
            }
        }
    }

    useEffect(()=>{
        // debugger
        let dependentInfo = props.userInfo.first_name + ' ' + props.userInfo.last_name;
		setCoOwnerTypeOptionsList([
			{id:0, value:'0', label:`Individual - ${dependentInfo}`},
			{id:1, value:'1', label:'Individual - Other'},
			{id:2, value:'2', label:'Trust'},
			{id:3, value:'3', label:'Business'}
		])

        setError(props.error)
        setLoading(props.loading);
	},[]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleCoOwnerTypeChange = (e) => {
		setCoOwnerType(e)
        
        if(e.id === 0){
            const adv = props.userInfo;
            setName(adv.first_name +' '+ adv.last_name)
            setNameError(false)
            setNameErrorMeesage('')
            setEmail(adv.email)
            setEmailError(false)
            setEmailErrorMeesage('')
            adv.phones.forEach((phone, i) => {
                if(phone.type !== "" && phone.number !== ""){
                    if(i=== 0){
                        setSelectedPhoneType1({label: phone.type, value: phone.type})
                        setPhoneNumber(phone.number)
                        setPhoneType1LabelActive(true)
                        setphoneNumberError(false)
                        setPhoneNumber_1ErrorMessage('')
                        setIsPhone2DropdownSelect(false)
                    }else{
                        setSelectedPhoneType2({label: phone.type, value: phone.type})
                        setPhoneNumber_2(phone.number)
                        setPhoneType2LabelActive(true)
                        setphoneNumber2Error(false)
                        setPhoneNumber_2ErrorMessage('')
                        setIsPhone2DropdownSelect(false)
                    }
                }
            });
        }

	}

    const validatePhoneNumber = (e) => {
        const validNumber = '+'+e.toString()
        const phoneNumber = isValidPhoneNumber(validNumber)
        return phoneNumber
    }

    const handlePhoneField_2 = e => {
        if(e.length > 1){
            setPhoneType2LabelActive(true)
        }else{
            setPhoneType2LabelActive(false);
        }
        setPhoneNumber_2(e)
    }
    const onBlurEventHandlerPhone2 = () => {
        const isValid =  validatePhoneNumber(phoneNumber_2)
        if(phoneNumber_2.length > 1 && !isValid){
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }
    }

    const validatePrecentOwned = (e) =>{
        console.log('parseFloat(precentOwned)', parseFloat(precentOwned))
        if(precentOwned === '' && precentOwned.length === 0) {
            setPrecentOwnedError(true)
            setPrecentOwnedErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_PERCENT_OWNED);
            return true;
        }else if(parseFloat(precentOwned) === 0){
            setPrecentOwnedError(true)
            setPrecentOwnedErrorMeesage(A12Y_CONST.ERROR_MESSAGE.PERCENT_OWNED_MUST_BE_GREATER_THAN_0);
            return true;
        }else if(parseFloat(precentOwned) === 100){
            setPrecentOwnedError(true)
            setPrecentOwnedErrorMeesage(A12Y_CONST.ERROR_MESSAGE.PERCENT_OWNED_MUST_BE_LESS_THAN_0);
            return true;
        }
        else{
            setPrecentOwnedError(false)
            setPrecentOwnedErrorMeesage('');
            return false;
        }
    }

    const validatePrecent = (amount) =>{
        let amt = amount.trim();
        let reg = /^(\d{0,2}(\.\d{0,2})?|100(\.00?)?)$/;
        
        return reg.test(amt)
    }

    const handlePrecentOwnedChange = (e) =>{
        let amt = e.target.value

        if(validatePrecent(amt)){
            setValue('precentOwned', amt)
            setPrecentOwned(amt)
        }
    }

    const validateValueSold = (e) =>{
        if(valueSold === '' && valueSold.length === 0) {
            setValueSoldError(true)
            setValueSoldErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALUE_SOLD);
            return true;
        }else{
            setValueSoldError(false)
            setValueSoldErrorMeesage('');
            return false;
        }
    }

    // const handleValueSoldChange = (e) =>{
    //     setValue('name',e.target.value)
    //     setValueSold(e.target.value)
    // }

    const handleValueSoldChange = async (e) =>{
        let amt = e.target.value.trim();
        if(amt === ''){
            await setValueSold(amt)
            setValue('valueSold',amt)
        }else{
            let temp = await currencyFormatting(amt);
            if(temp !== undefined){
                await setValueSold(temp);
                setValue('valueSold',amt)
            }
        }
    }

    const onSubmit = async (formData)=>{
        let isNameValidate = await validateName();
        let isEmailValidate = await validateEmail();
        let isPrecentOwnedValidate = await validatePrecentOwned();
        let isValueSoldValidate = await  validateValueSold()
        let coOwnerTypeValidate = false;
        let IsPhone1DropdownSelected = false;
        let IsPhone2DropdownSelected = false;
        let phoneError = false
        if(Object.keys(coOwnerType).length > 0){
            setCoOwnerTypeError(false)
            coOwnerTypeValidate = false;
        }else{
            setCoOwnerTypeError(true)
            coOwnerTypeValidate = true;
        }

        let phoneList = [{type:selectedPhoneType1.label && selectedPhoneType1.label !== 'Select' ?selectedPhoneType1.label.toLowerCase():'',number:(phoneNumber === '1' || phoneNumber === '') ? '' : phoneNumber},{type:selectedPhoneType2.label && selectedPhoneType2.label !== 'Select' ? selectedPhoneType2.label.toLowerCase():'',number:(phoneNumber_2 === '1' || phoneNumber_2 === '') ? '' : phoneNumber_2}]
        await onBlurEventHandlerPhone();
        if(phoneNumber.length > 1 && phoneNumber !== "1" &&  Object.keys(selectedPhoneType1).length === 0 ){
            phoneError = true
            setphoneNumberError(true)
            setIsPhone2DropdownSelect(true)
            IsPhone1DropdownSelected = true
            setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label !== 'Select' && (phoneNumber === "" || phoneNumber === "1")){
                phoneError = true
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone1DropdownSelected = false
        }

        await onBlurEventHandlerPhone2();
        if(phoneNumber_2.length > 1 && phoneNumber_2 !== "1" &&  Object.keys(selectedPhoneType2).length === 0 ){
            phoneError = true
            setIsPhone2DropdownSelect(true)
            IsPhone2DropdownSelected = true
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label !== 'Select' && (phoneNumber_2 === "" || phoneNumber_2 === "1")){
                phoneError = true
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone2DropdownSelected = false
        }
        if(phoneNumberError || phoneNumber2Error || IsPhone2DropdownSelected ||IsPhone1DropdownSelected ||  phoneError){
            return
        }
        if(dateTransferError || precentOwnedError){
            return;
        }
        
        // setLoading(true)
        // debugger

        let formObj = {
            owner_type : coOwnerType.id,
            owner_name : name,
            email : email,
            percentage_ownership : precentOwned,
            phones : JSON.stringify(phoneList),
            date_of_ownership : await ddmmyyyyFormat(dateTransfer),
            sold_value :  valueSold && valueSold.includes(',') ? valueSold.replaceAll(',','') : valueSold,
        }
        if(!isNameValidate && !isEmailValidate && !coOwnerTypeValidate && !isPrecentOwnedValidate && !isValueSoldValidate){
            if(props.editMode){
            await props.onUpdate(formObj)
            }else{
            await props.onSubmit(formObj)
            }
        }

        // if(props.isComplete){
        //     setLoading(props.loading)
        //     history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
        // }
    }
    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
        }
    }

    const goBack = async()=>{
        history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
    }

    useEffect( ()=>{
        const assignDefaultValue = async ()=>{
            if(props.editMode && Object.keys(props.coOwnerDetails).length > 0){
                let coOwer = props.coOwnerDetails
                let valueSold = coOwer.sold_value || coOwer.sold_value === 0 ? await currencyFormatting(coOwer.sold_value) : ''
                setName(coOwer.owner_name)
                setEmail(coOwer.email)
                setPrecentOwned(coOwer.percentage_ownership)
                setValueSold(valueSold);
                setDateTransfer(coOwer.sold_value)
                setDateTransfer(new Date(coOwer.date_of_ownership))
                if(coOwer.owner_type !== null){
                    let co_ownerType = await coOwnerTypeOptionsList.find(item=>item.id === coOwer.owner_type)
                    await setCoOwnerType(co_ownerType)
                }
                if(coOwer?.phones && coOwer.phones.length > 0){
                    coOwer.phones.forEach((phone,index)=>{
                        if(index === 0){
                            if(phone.type === 'mobile'){
                                setSelectedPhoneType1({value:'Mobile' , label:'Mobile'})
                            }else if(phone.type === 'office'){
                                setSelectedPhoneType1({value:'Office' , label:'Office'})
                            }else if(phone.type === 'home'){
                                setSelectedPhoneType1({value:'Home' , label:'Home'})
                            }else if(phone.type === 'other'){
                                setSelectedPhoneType1({value:'Other' , label:'Other'})
                            }
                            if(phone.number.length > 0){
                                setPhoneNumber(phone.number)
                                setPhoneType1LabelActive(true)
                            }else {
                                setPhoneNumber('')
                            }
                        }else {
                            if(phone.type === 'mobile'){
                                setSelectedPhoneType2({value:'Mobile' , label:'Mobile'})
                            }else if(phone.type === 'office'){
                                setSelectedPhoneType2({value:'Office' , label:'Office'})
                            }else if(phone.type === 'home'){
                                setSelectedPhoneType2({value:'Home' , label:'Home'})
                            }else if(phone.type === 'other'){
                                setSelectedPhoneType2({value:'Other' , label:'Other'})
                            }
                            if(phone.number.length > 0){
                                setPhoneNumber_2(phone.number)
                                setPhoneType2LabelActive(true)
                            }else {
                                setPhoneNumber_2('')
                            }
                        }
                    })
                }

            }
            setEditMode(true)
        }

        assignDefaultValue();

    },[props.editMode,props.coOwnerDetails]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(  () => {
        const checkFormDataIsChanged = async ()=>{
            let coOwer = props.coOwnerDetails;
            let soldValue = await currencyFormatting(coOwer.sold_value)
            setIsDataModified(false)
            setIsBtnDisabled(true)
            props.isDataChanged(false);
            if(isEditMode && Object.keys(props.coOwnerDetails).length > 0) {
                setIsBtnDisabled(true)
                if((Object.keys(coOwnerType).length > 0 && await coOwnerType.id !== coOwer.owner_type) || name !== coOwer.owner_name || email !== coOwer.email || parseInt(precentOwned) !== coOwer.percentage_ownership || valueSold !== soldValue || (coOwer.phones.length > 0 && ((coOwer.phones[0].number !== "" && phoneNumber === "1") || (coOwer.phones[1].number !== "" && phoneNumber_2 === "1")))  || await ddmmyyyyFormat(new Date(dateTransfer)) !== coOwer.date_of_ownership || (Object.keys(selectedPhoneType1).length > 0 && coOwer.phones[0].type !== selectedPhoneType1.value.toLowerCase()) || (phoneNumber !== '' && phoneNumber !=="1" && phoneNumber !==  coOwer.phones[0].number) || (Object.keys(selectedPhoneType2).length > 0 && coOwer.phones[1].type !== selectedPhoneType2.value.toLowerCase()) || (phoneNumber_2 !== '' && phoneNumber_2 !=="1" && phoneNumber_2 !==  coOwer.phones[1].number) ){
                    if(Object.keys(coOwnerType).length > 0 && name !== '' && email !== '' && precentOwned !== '' && valueSold !== '' && dateTransfer !== null){
                        if(await emailValidate(email)){
                            setIsBtnDisabled(false)
                        }
                    }
                    setIsDataModified(true)
                    props.isDataChanged(true);
                }
            }
            else {
                if(!props.editMode){
                    let today = await ddmmyyyyFormat(new Date())
                    if(Object.keys(coOwnerType).length > 0 || name !== '' || email !== '' || precentOwned !== '' || valueSold !== '' || await ddmmyyyyFormat(new Date(dateTransfer)) !== today || Object.keys(selectedPhoneType1).length > 0 || (phoneNumber !== '' && phoneNumber !=="1") || Object.keys(selectedPhoneType2).length > 0 || (phoneNumber_2 !== '' && phoneNumber_2 !=="1")){
                        if(Object.keys(coOwnerType).length > 0 && name !== '' && email !== '' && precentOwned !== '' && valueSold !== '' && dateTransfer !== null){
                            if(await emailValidate(email)){
                                setIsBtnDisabled(false)
                            }
                        }
                        setIsDataModified(true)
                        props.isDataChanged(true);
                    }
                }
            }
        }

        checkFormDataIsChanged()

    }, [name, email, precentOwned, dateTransfer, valueSold, phoneNumber, coOwnerType, selectedPhoneType1, selectedPhoneType2, phoneNumber_2]) // eslint-disable-line react-hooks/exhaustive-deps

    const emailValidate = async (email)=>{
        //eslint-disable-next-line
        let regex = /^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return regex.test(email)
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const isDateValid = () => {
        if(dateTransfer != null){
            let isValidObj = validateDate(dateTransfer, 20, 2)
            
            setDateTransferError(isValidObj.isErr)
            setDateTransferErrorMessage(isValidObj.errMsg)
        }
        else{
            setDateTransferError(true)
            setDateTransferErrorMessage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
    }

    function validateOwnerType(type) {
        if(type === 'blur'){
            if(coOwnerType === '' && Object.keys(coOwnerType).length === 0){
                setCoOwnerTypeError(true)

            }
        }else {
            setCoOwnerTypeError(false)
        }

    }

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addActivitiesWrap">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h2 className="titleWithSidebar noExpenseTitle marginBottom30">
                <span className="cursorPointer" onClick={goBack}><svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span>{A12Y_CONST.LABEL.ADD_CO_OWNER_LABEL}</span></span>
            </h2>
            <div className="dependentBorderBox dependentDefauldMinHeight paddingBottom100 activityViewMain positionRelative">
            <div className="df fcol">
                <h4 className="dependentSubTitle"><span className='txtEllipsis vam'>{A12Y_CONST.LABEL.CO_OWNER}</span><span className='vam'>{A12Y_CONST.LABEL.APOSTROPHE_S} {A12Y_CONST.LABEL.Information}</span></h4>
               <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.successMessage}/>

                            :
                            null
                    )

                }
                {loading ?
                    <div className="transparentLayer"></div>
                    :
                    null
                }
               </div>
                <form autoComplete="off" className='fullWidth dependentEditProfile coOwn marginTop20' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow fullWidth formRowGap">
                        <label className="lblExpType">{A12Y_CONST.LABEL.CO_OWNER_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className={(coOwnerTypeError && 'selectError') + ' ' + (coOwnerType && Object.keys(coOwnerType).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol'}>
                            <Controller
                                name="coOwnerType"
                                value={coOwnerType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={coOwnerTypeOptionsList}
                                        value={coOwnerTypeOptionsList.find(c => c.id === coOwnerType.id)}
                                        onChange={handleCoOwnerTypeChange}
                                        onFocus={()=>{validateOwnerType('focus')}}
                                        onBlur={()=>{validateOwnerType('blur')}}
                                        classNamePrefix={'dependentResidence'}
                                    />
                                )}
                                control={control}
                                className="fullwidth inputColum24size"
                                placeholder="Select Type"
                            />
                            <div className='errorRelativeRow'>{coOwnerTypeError? <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_COOWNER_TYPE}</p>: null}</div>
                        </div>
                    </div>
                    
                        <div className="formRow fullWidth marginTop20">
                            <label className="lblExpType">{A12Y_CONST.LABEL.NAME}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth marginBottom30 marginTop5">
                                <input 
                                    {...register("name")} 
                                    value={name}
                                    id="name"
                                    maxLength='255'
                                    onBlur={validateName}
                                    onChange={handleNameChange}
                                    onFocus={()=>setNameErrorMeesage(false)}
                                    className={`fullWidth inputColum24size ${nameError && 'errorInputBorderRed'}`}
                                    placeholder="Name"
                                />
                                <div className='errorRelativeRow'>{nameError? <p className='errorMessage'>{nameErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="formRow fullWidth">
                            <label className="lblExpType">{A12Y_CONST.LABEL.DEPENDENT_EMAIL}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth marginBottom30 marginTop5">
                                <input 
                                    {...register("email")} 
                                    value={email}
                                    id="email"
                                    maxLength='255'
                                    onBlur={validateEmail}
                                    onChange={handleEmailChange}
                                    onFocus={()=>setEmailErrorMeesage(false)}
                                    className={`fullWidth inputColum24size ${emailError && 'errorInputBorderRed'}`}
                                    placeholder="name@example.com"
                                />
                                <div className='errorRelativeRow'>{emailError? <p className='errorMessage'>{emailErrorMeesage}</p>: null}</div>
                            </div>
                        </div>

                        <div className='dependentStepFormRow fullWidth positionRelative'>
                            <label className='labelCol phoneEditProfile'>{A12Y_CONST.LABEL.DEPENDENT_PHONE}</label>
                            <div className='fullWidth marginBottom30 marginTop5'>
                                <div className='width50 paddingRight16 selectBoxParend myProfilePhoneCol1_600'>
                                    <div className={(selectedPhoneType1 && Object.keys(selectedPhoneType1).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsouteTop4 positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                        <Select
                                            className='phoneTypeLabel'
                                            classNamePrefix={phoneType1LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                            placeholder="Select"
                                            value={selectedPhoneType1.label ? selectedPhoneType1 : ""}
                                            options={phoneTypeOptions}
                                            onChange={handleChangePhoneType1}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: 'red',
                                                    primary: '#0096C708',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <PhoneInput
                                            country='us'
                                            disabled={Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label === 'Select' ? true : false}
                                            countryCodeEditable={false}
                                            // containerClass={phoneType1LabelActive ? 'phoneNumber_Active': null}
                                            containerClass={phoneType1LabelActive ? phoneNumberError? 'errorInputBorderRedInput phoneNumber_Active': phoneNumber === "1" ? 'phone_number_placeholder_color null' :'phoneNumber_Active' : phoneNumberError? 'errorInputBorderRedInput': null}
                                            value={phoneNumber}
                                            onBlur={onBlurEventHandlerPhone}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                            }}
                                            onChange={phone => {handlePhoneField(phone)}}
                                            onFocus={() => setphoneNumberError(false)}
                                        />
                                    </div>
                                    <div className='errorRelativeRow'> {phoneNumberError ? <p className='errorMessage'>{phoneNumber_1ErrorMessage}</p> : null }</div>
                                </div>
                                <div className='width48 marginTop768_10 floatRight paddingleft16 selectBoxParend myProfilePhoneCol2_600'>
                                    <div className={(selectedPhoneType2 && Object.keys(selectedPhoneType2).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsouteTop4 positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                        <Select
                                            className='phoneTypeLabel'
                                            classNamePrefix={phoneType2LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                            placeholder="Select"
                                            value={selectedPhoneType2.label ? selectedPhoneType2 : ""}
                                            options={phoneTypeOptions}
                                            onChange={handleChangePhoneType2}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: 'red',
                                                    primary: '#0096C708',
                                                },
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <PhoneInput
                                            country='us'
                                            countryCodeEditable={false}
                                            disabled={Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label === 'Select' ? true : false}
                                            // containerClass={phoneType2LabelActive ? 'phoneNumber_Active': null}
                                            containerClass={phoneType2LabelActive ? phoneNumber2Error? 'errorInputBorderRedInput phoneNumber_Active': phoneNumber_2 === "1" ? 'phone_number_placeholder_color null' : 'phoneNumber_Active' : phoneNumber2Error? 'errorInputBorderRedInput': null}
                                            placeholder='Select'
                                            value={phoneNumber_2}
                                            onBlur={onBlurEventHandlerPhone2}
                                            inputProps={{
                                                name: 'phone1',
                                                required: false,
                                                autoFocus: false
                                            }}
                                            onChange={phone => {handlePhoneField_2(phone)}}
                                            onFocus={() => {
                                                setphoneNumber2Error(false)
                                                setIsPhone2DropdownSelect(false)
                                            }}
                                        />
                                    </div>
                                    <div className='errorRelativeRow'> {phoneNumber2Error || isPhone2DropdownSelect? <p className='errorMessage'>{phoneNumber_2ErrorMessage}</p> : null }</div>
                                </div>

                            </div>
                        </div>
                        <div className="formRow fullWidth assetOwn marginTop10">
                            <div className='fullWidth paddingBottom10'><label>{A12Y_CONST.LABEL.HOW_MUCH_OF_THE_ASSET_DO_THEY_OWN}</label></div>
                        </div>

                        <div className="formRow fullWidth marginTop50">
                            <label>{A12Y_CONST.LABEL.PRECENT_OWNED}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div className="fullWidth">
                                    <input 
                                        {...register("precentOwned")} 
                                        value={precentOwned}
                                        id="precentOwned"
                                        type='text' min='0'
                                        onWheel={e => e.target.blur()}
                                        maxLength='50'
                                        onBlur={validatePrecentOwned}
                                        onChange={handlePrecentOwnedChange}
                                        onFocus={()=>setPrecentOwnedErrorMeesage(false)}
                                        className={`fullWidth inputColum24size percentOwned ${precentOwnedError && 'errorInputBorderRed'}`}
                                        placeholder="00.00" 
                                    />
                                    <label className={`lblPercent ${precentOwned > 0 && 'activeVal'} `}>%</label>
                                    <div className='errorRelativeRow'>{precentOwnedError? <p className='errorMessage'>{precentOwnedErrorMeesage}</p>: null}</div>
                                </div>
                        </div>
                        <div className="formRow fullWidth marginTop35 payRow">
                            <div className="width50 paddingRight16 positionRelative">
                                <label className='coOwnerLabel'>{A12Y_CONST.LABEL.VALUE_OF_CO_OWNER_INTEREST}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div className="fullWidth amountWrap">
                                    <span className={valueSold === '' ? "currency" : "currency currencyColorChnage" }>{A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                                    <input 
                                        {...register("valueSold")} 
                                        value={valueSold}
                                        id="valueSold"
                                        type='text' 
                                        min='0'
                                        onWheel={e => e.target.blur()}
                                        maxLength='50'
                                        onBlur={validateValueSold}
                                        onChange={handleValueSoldChange}
                                        onFocus={()=>setValueSoldErrorMeesage(false)}
                                        className={`fullWidth txtAmount inputColum24size ${valueSoldError && 'errorInputBorderRed'}`}
                                        placeholder="0.00" 
                                    />
                                    <div className='errorRelativeRow'>{valueSoldError? <p className='errorMessage'>{valueSoldErrorMeesage}</p>: null}</div>
                                </div>
                            </div>
                            <div className="width50 positionRelative">
                                <label>{A12Y_CONST.LABEL.DATE_ACQUIRED}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div className="fullWidth">
                                    <Controller
                                        name="dateTransfer"
                                        control={control}
                                        inputmode='none'
                                        render={({ field }) =>
                                            <DatePicker
                                                {...field}
                                                dateFormat="MM / dd / yyyy"
                                                ref={dateTransferRef}
                                                selected={dateTransfer}
                                                onChange={(date) => setDateTransfer(date)}
                                                onChangeRaw={(event) => handleChangeRaw(event, "dateTransfer")}
                                                onBlur={isDateValid}
                                                onCalendarClose={isDateValid}
                                                onKeyDown={(event) => hideOnBlur(event, dateTransferRef)}
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onFocus={(e)=> {setDateTransferError(false); readOnlyOnMobile(e.target)}}
                                                className={!dateTransferError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                                placeholderText="MM / DD / YYYY"
                                            />
                                        }
                                    />
                                    <div className='errorRelativeRow'>
                                        {dateTransferError? <p className='errorMessage'>{dateTransferErrorMessage}</p>: null}
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/*<div className="formRow fullWidth marginTop35 payRow">*/}
                        {/*    <div className="width50 paddingRight16 positionRelative">*/}
                        {/*        <label>{A12Y_CONST.LABEL.DEPENDENTS_VALUE}</label>*/}
                        {/*        <div className="fullWidth marginTop5 paddingLeft1 assetOwn">*/}
                        {/*            <label className='static'>{A12Y_CONST.TEXT.DOLLOR_SYMBOL}{dependentVal}</label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*------------------ Button Actions ---------------------*/}
                        <div className="fullWidth textAlignRight marginTop30 marginBottom20 displayFlexCol right35 positionAbsouteBottom">
                            <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.ADD_CO_OWNER_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                            <div className='widthAuto marginLeft20'>
                                <div className='loaderSpinner'>
                                    { loading ? <Loading /> : null }
                                    <input id={A12Y_CONST.BUTTON_ID.ADD_CO_OWNER_SAVE} className={ loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} type="submit" value={A12Y_CONST.BUTTON_TEXT.SAVE} />
                                </div>
                            </div>
                        </div>
                </form>
            </div>
		</div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
	);
}

export default AddCoOwnerComponent;
