import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import Loading from '../components/LoadingComponent';
import UploadFiles from '../components/ImageUploadComponent';
import LoaderFullScreen from './LoaderFullScreenComponent'
import EditChangesRemovePopup from '../components/EditChangesRemovePopup'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from 'uuid';
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {currencyFormatting} from "../util/CurrencyFormater";
import {hideOnBlur, readOnlyMobile} from "../util/inputKeyboardHide";
import Switch from "@material-ui/core/Switch";

function AddIncome(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [incomeType, setIncomeType] = useState({});
    const [dateExp, setDateExp] = useState(null);
    const [dateEndRepeatBy, setDateEndRepeatBy] = useState(new Date());
    const [amount, setAmount] = useState('');
    const [paidFrom, setPaidFrom] = useState('');
    const [note, setNote] = useState('');
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [dateErrorMeesage, setDateErrorMeesage] = useState('');
    const [dateEndRepeatByError, setDateEndRepeatByError] = useState(false);
    const [dateEndRepeatByErrorMeesage, setDateEndRepeatByErrorMeesage] = useState('');
    const [incomeTypeOptionsList, setIncomeTypeOptionsList] = useState([]);
    const [amountError, setAmountError] = useState(false);
    const [amountErrorMeesage, setAmountErrorMeesage] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [incomeTypeError, setIncomeTypeError] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const history = useHistory();
    const [incomeId, setIncomeId] = useState(null)
    const { register, handleSubmit, getValues, setValue, control,setError, formState: { errors, isSubmitting } } = useForm({ mode: "all" });
    const dateRef = useRef(null);
    const dateEndRepeatByRef = useRef(null);
    // const [isAmountEmpty, setIsAmountEmpty] = useState(true)

    const [repeatMonthly, setrepeatMonthly] = useState(false);
    const [attachAllFile, setAttachAllFile] = useState(false);
    const [linkToWebsite, setLinkToWebsite] = useState('');

    function goBack() {
       // props.onBack()
        history.goBack();
    }

    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.error, props.loading, props.loaderFullScreen])

    useEffect(()=>{
       const assignDefaultValue = async ()=>{
           let income = props.incomeObj;
           if(income && Object.keys(income).length > 0){
               setIsBtnDisabled(true)
               setIncomeId(income.id)
               // setValue("amount",expense.amount)
               if(props.incomeTypeList.length > 0){
                   let list = await props.incomeTypeList.filter(item =>item.id === income.income_type_id)
                   if(list && list.length > 0){
                       setIncomeType(list[0])
                       // setValue("expenseType",list[0])
                   }
               }
               let amount = income?.amount || income?.amount === 0 ? await currencyFormatting(income.amount) : ''
               setAmount(amount)
               setPaidFrom(income.paid_from)
               setLinkToWebsite(income?.web_url)
               setNote(income.note)
               setDateExp( income.date_of_income !== null ? new Date(income.date_of_income) : null)
               await setEditMode(true)

               // if(income.date_of_income){
               //     let date = await moment(income.date_of_income).format("MM / DD / YYYY")
               //     setDateExp(date)
               // }

           }else {
               const uuid = await uuidv4();
               setIncomeId(uuid)
               setDateExp(new Date())
               setDateEndRepeatBy(new Date(props.dependent.reporting_period.end_date))
           }
       }

       assignDefaultValue()

    },[props.incomeTypeList,props.incomeObj]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkIsFormDataChanged = async ()=>{
           let income = props.incomeObj;
           let expDate = dateExp !== null ? await ddmmyyyyFormat(dateExp) : null;
           let today = await ddmmyyyyFormat(new Date());
           setIsBtnDisabled(true)
           //  if(editMode){
           props.isDataChanged(false)
           if(deletedFileInfo.length > 0 || fileInfo.length > 0){
               setIsBtnDisabled(false)
               setIsDataModified(true)
               props.isDataChanged(true)
           }else {
               setIsBtnDisabled(true)
               setIsDataModified(false)
               props.isDataChanged(false)
           }
           if(editMode){
               if( await currencyFormatting(income.amount) !== amount || income.paid_from !== paidFrom || income.web_url !== linkToWebsite ||  income.note !== note || income.date_of_income !==  expDate || (Object.keys(incomeType).length > 0 && income.income_type_id !== incomeType.id)){
                   if(Object.keys(incomeType).length > 0 && amount !== "" && dateExp !== null){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }else {
               if( amount !== "" ||  paidFrom !== "" || note !=="" || linkToWebsite !== '' || today !==  expDate || (Object.keys(incomeType).length > 0)){
                   if(Object.keys(incomeType).length > 0 && amount !== "" && dateExp !== null){
                       if(repeatMonthly){
                           if(dateEndRepeatBy === null || dateEndRepeatByError){
                               setIsBtnDisabled(true);
                           }else{
                               setIsBtnDisabled(false);
                           }
                       }else{
                           setIsBtnDisabled(false);
                       }

                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }
       }

        checkIsFormDataChanged();

       // }
    },[amount,paidFrom, note,dateExp,incomeType,fileInfo,deletedFileInfo,editMode,dateEndRepeatBy,dateEndRepeatByError, linkToWebsite]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            if(props.isSessionTimeout || !props.fromScreen){
                history.push(A12Y_CONST.ROUTE.INCOMES)
            }else {
                history.goBack()
            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        setIsPopUpVisible(false);
        props.isDataChanged(false)
        if(props.isSessionTimeout){
            history.push(A12Y_CONST.ROUTE.INCOMES)
        }else {
            history.goBack()
        }
    }

    const handleIncomeTypeChange = async (e) => {
        await setIncomeType(e);
    }

    async function handleAmountChange (e) {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setAmount(amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setAmount(temp)
            }
        }
    }

    const handlePaidFromChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setPaidFrom(e.target.value);
        }
    }

    function handleNoteChange(e) {
        if(e.target.value === " "){
            e.preventDefault();
        }else {
            setNote(e.target.value);
        }
    }

    useEffect(  () => {
        const assignIncomeTypeList = async ()=>{
            var incomeTypeList = await props.incomeTypeList.map(item => ({ label: item.title, value: item.title, id:item.id }));
            setIncomeTypeOptionsList(incomeTypeList)
        }

        assignIncomeTypeList()
    },[props.incomeTypeList])


    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
        if (props.loading) {
            setIsBtnDisabled(true)
        } if (props.error) {
            setIsBtnDisabled(false)
        }
    }, [props.error, props.loading])
    
    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const isDateValid = () => {
        if(dateExp != null){
            let isValidObj = validateDate(dateExp, 20, 2)
            setDateError(isValidObj.isErr)
            setDateErrorMeesage(isValidObj.errMsg)
        }else{
            setDateError(true)
            setDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
        if(dateEndRepeatBy != null){
            return isDateMatching();
        }
    }

    const isRepeatMonthlyDateValid = () => {
        if(repeatMonthly){
            if(dateEndRepeatBy != null){
                return isDateMatching();
            }else{
                setDateEndRepeatByError(true)
                setDateEndRepeatByErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
            }
        }
    }

    useEffect(() => {
        if(fileInfo.length > 0 && repeatMonthly){
            setAttachAllFile(true);
        }else{
            setAttachAllFile(false);
        }
    },[fileInfo, repeatMonthly])

    const isDateMatching = () => {
        let sDt = new Date(dateExp);
        let eDt = new Date(dateEndRepeatBy);

        if (dateExp !== null && dateEndRepeatBy !== null) {
            if (eDt.toDateString() === sDt.toDateString()) {
                setDateEndRepeatByError(false)
                setDateEndRepeatByErrorMeesage('')
                return true;
            }
            else if (eDt < sDt) {
                setDateEndRepeatByError(true)
                setDateEndRepeatByErrorMeesage(A12Y_CONST.ERROR_MESSAGE.END_REPEAT_DATE_ERROR_MEDICAL)
                return false;
            }else if(sDt < eDt){
                setDateEndRepeatByError(false)
                setDateEndRepeatByErrorMeesage('')
                return true;
            }
        }
    };

    // const checkValue = (str, max) => {
    //     if (str.charAt(0) !== '0' || str == '00') {
    //         var num = parseInt(str);
    //         if (isNaN(num) || num <= 0 || num > max) num = 1;
    //         str = num > parseInt(max.toString().charAt(0))
    //         && num.toString().length == 1 ? '0' + num : num.toString();
    //     };
    //     return str;
    // };

    // const handleDateChange = async (e) => {
    //     setIsValueChanged(true)
    //     var input = e.target.value
    //     if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    //     var values = input.split('/').map(function(v) {
    //         return v.replace(/\D/g, '')
    //     });
    //     if (values[0]) values[0] = checkValue(values[0], 12);
    //     if (values[1]) values[1] = checkValue(values[1], 31);
    //     var output = values.map(function(v, i) {
    //         return v.length == 2 && i < 2 ? v + ' / ' : v;
    //     });
    //     let dateOfIncome = output.join('').substr(0, 14);
    //     await setDateExp(dateOfIncome);
    // }

    // const validateDate = () => {
    //     if(dateExp.length > 0 && dateExp.length < 14) {
    //         setDateError(true)
    //         setDateErrorMeesage('Invalid date. Enter as MM/DD/YYYY');
    //         return true;
    //     }else{
    //         setDateError(false)
    //         setDateErrorMeesage('');
    //         return false;
    //     }
    // }

    const validateAmount = (e) => {
        let amt = amount.replaceAll(',', '')
        
        if(amt === '' && amt.length === 0) {
            setAmountError(true)
            setAmountErrorMeesage('Enter valid amount.');
            return true;
        } else if(amt > 99000000000) {
            setAmountError(true)
            setAmountErrorMeesage('Amount should be less than 99Bn.');
            return true;
        }
        else{
            setAmountError(false)
            setAmountErrorMeesage('');
            return false;
        }
    }
        
    const onSubmit = async(incomeData)=>{
        let isValidate = true;
        if(incomeType && Object.keys(incomeType).length === 0){
            await setIncomeTypeError(true)
            isValidate = false;
        }
        if(amount === '' && amount.length === 0) {
            setAmountError(true)
            setAmountErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_AMOUNT);
            isValidate = false;
        }
        
        if(dateError){
            return
        }
        // if(dateExp.length > 0 && dateExp.length < 14 || dateExp === '') {
        //     setDateError(true)
        //     setDateErrorMeesage('Invalid Date');
        //     isValidate = false;
        // }
        // if(dateExp && dateExp.split("/").length === 3){
        //     let dateExpSplitList = dateExp.split("/")
        //     let date = dateExpSplitList[0].trim()+"/"+dateExpSplitList[1].trim()+"/"+dateExpSplitList[2].trim();
        //     incomeData.dateExp = date
        // }else {
        //     incomeData.dateExp = null
        // }
        let obj = {
            "id" : incomeId,
            "income_type_id" : incomeType && Object.keys(incomeType).length > 0 ? incomeType.id: '',
            "amount" : Object.keys(amount).length > 0 && amount.includes(',') ? amount.replaceAll(',','') : amount ,
            "paid_from" : paidFrom,
            "note" : note,
            "web_url" : linkToWebsite,
            "date_of_income" : await ddmmyyyyFormat(new Date(dateExp)),
            "document_ref" : {},
            "repeat_monthly" : repeatMonthly,
            "end_repeat_by": repeatMonthly ? await ddmmyyyyFormat(new Date(dateEndRepeatBy)): '',
            "include_attachment":attachAllFile
        }
        let isDatesValid = validateDate(dateExp, 20, 2)
        let isAmountValid = validateAmount();
        if(isValidate && !isDatesValid.isErr && !isAmountValid){
            if(editMode){
                setIsSubmit(true)
                props.onUpdate(obj, fileInfo, fileBase64Info,deletedFileInfo)
            }else {
                setIsSubmit(true)
                props.onSubmit(obj, fileInfo, fileBase64Info)
            }
        }
        //setSaveClicked(true)
    }

    function validateIncomeType(type) {
        if(type === 'blur'){
            if(incomeType && Object.keys(incomeType).length === 0){
                setIncomeTypeError(true)
            }
        }else {
            setIncomeTypeError(false)
        }
    }

    const filesDetail_FromChild = (e,deletedFiles) => {
        let fileList = [];
        let base64List = [];
        for (var i = 0; i < e.length; i++) {
            if(!e[i].file_id){
                let name = e[i].displayName
                let size = e[i].size
                let file = e[i];
                let fileName = e[i].name;
                let reader = new FileReader();
                const uuid = uuidv4();
                //let s3key = props.dependent.id+'/'+'income/'+incomeId+'/'+uuid
                let s3key = `${props.dependent.id}/income/${incomeId}/${uuid}`
                reader.readAsDataURL(file);
                reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                );
                // extension = path.substring(fileName.lastIndexOf('.')+1);
                fileList.push({
                    's3_key': s3key,
                    file_id:uuid,
                    income_id:incomeId,
                    'contentType': e[i].type,
                    'fileMetadata': {
                        "about_dependent": "some more info"
                    },
                    'name' : name,
                    'size' : size,
                    'fileName': fileName,
                });
            }
        }
        setFileInfo(fileList);
        if(fileList.length > 0){
            setAttachAllFile(true);
        }else{
            setAttachAllFile(false);
        }
        setFileBase64Info(base64List)
        setDeletedFileInfo(deletedFiles)
    }
    useEffect( () => {
        let arr =[dateRef.current]
      //  let arr1 =[dateEndRepeatByRef.current]
        readOnlyMobile(arr)
        // readOnlyMobile(arr1)
    },[])

    const handleRepeatMonthly = async (e) => {
        await setrepeatMonthly(e.target.checked);
        await setValue('repeatMonthly', e.target.checked)

        var currentDate = new Date();
        var theLast = new Date(currentDate.getFullYear(), 11, 31);
        var date  = await ddmmyyyyFormat(new Date(theLast))

        if(e.target.checked){
            await setDateEndRepeatBy(new Date(date))
        }else{
            await setDateEndRepeatBy('')
        }
      /*  if(!e.target.checked){
            /!*setDateEndRepeatBy(new Date(props.dependent.reporting_period.end_date))*!/

        }*/
        if(dateEndRepeatBy != null){
            return isDateMatching();
        }
    }
   const handleAttachAllFile = async (e) => {
        await setAttachAllFile(e.target.checked);
        await setValue('attachAllFile', e.target.checked)
    }


    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addIncomesWrap">
            { loaderFullScreenVisible ?
                <LoaderFullScreen/>
                :
                null
            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h2 className="titleWithSidebar addIncomeTitle marginBottom30">
                <span id={A12Y_CONST.BUTTON_ID.INCOME_ADD_BACK} className="cursorPointer" onClick={onCancel}><svg  className="marginRight10" width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span>{A12Y_CONST.LABEL.INCOME_SOURCES}</span></span>
            </h2>
            <div className="dependentBorderBox dependentDefauldMinHeight pad_0_5">
                <h3 className='addIncomeTitle'><span className='vam marginBottom10'>{editMode ? 'Update' : 'Add'} {A12Y_CONST.LABEL.Income_for}</span> <span className='txtEllipsis vam marginBottom10'>{props.dependent.nick_name}</span></h3>
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }

                <form autoComplete="off" className='marginTop40 fullWidth addIncomeTopMargin_600' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow fullWidth formRowGap">
                        <label className="lblExpType">{A12Y_CONST.LABEL.INCOME_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className={`fullWidth dependentResidencCol incomeTypeCol ${incomeTypeError && !incomeType.id ? 'selectError': null} ${ incomeType && Object.keys(incomeType).length > 0 ? 'hasValue' : 'noValue'}`}>
                            <Controller
                                name="incomeType"
                                value={incomeType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix='dependentResidence'
                                        value={incomeTypeOptionsList.find(c => c.id === incomeType.id)}
                                        options={incomeTypeOptionsList}
                                        onChange={handleIncomeTypeChange}
                                        onFocus={()=>{validateIncomeType('focus')}}
                                        onBlur={()=>{validateIncomeType('blur')}}
                                    />
                                )}
                                control={control}
                                className="fullwidth inputColum24size"
                                placeholder="Select"
                            />
                            <div className='errorRelativeRow'> {incomeTypeError && !incomeType.id? <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_INCOME_TYPE}</p> : null}</div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGap payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.INCOME_AMOUNT}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={getValues('amount') !== '' || amount.length < 1? "currency" : "currency currencyColorChnage" }> {A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                                <input 
                                    {...register("amount")} 
                                    placeholder="0.00" 
                                    type='text' min='0' inputMode='decimal' //pattern='[0-9]+(\.[0-9]{1,2})?'
                                    id="amount"
                                    onWheel={e => e.target.blur()}
                                    //maxLength="5"
                                    onChange={handleAmountChange}
                                    maxLength={14}
                                    value={amount}
                                    onBlur={e => validateAmount(e)}
                                    onFocus={()=>setAmountErrorMeesage(false)}
                                    className={`fullWidth txtAmount inputColum24size ${amountError && 'errorInputBorderRed'}`} 
                                />
                                <div className='errorRelativeRow'>{amountError? <p className='errorMessage'>{amountErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 positionRelative paidFrom">
                            <label className='paddingLeft35 textTransferUppercase'>{A12Y_CONST.LABEL.SOURCE_OF_FUNDS}</label>
                            <div className="fullWidth">
                                <input 
                                    {...register("paidFrom")} 
                                    value={paidFrom}
                                    id="paidFrom"
                                    maxLength='255'
                                    onChange={handlePaidFromChange}
                                    className="fullWidth inputColum24size"
                                    placeholder="Individual, Business or Organization"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="fullWidth payRow">
                        <div className="formRow width50 paddingRight16 formRowGap">
                            <label>{A12Y_CONST.LABEL.DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth">
                                <Controller
                                    name="dateExp"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) => 
                                        <DatePicker
                                            {...field}
                                            ref={dateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={dateExp}
                                            onChange={date => setDateExp(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'dateExp')}
                                            onBlur={isDateValid}
                                            onCalendarClose={isDateValid}
                                            showMonthDropdown
                                            showYearDropdown
                                            onKeyDown={(event) => hideOnBlur(event, dateRef)}
                                            dropdownMode="select"
                                            onFocus={(e)=> {setDateError(false)}}
                                            className={!dateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {dateError? <p className='errorMessage'>{dateErrorMeesage}</p>: null}
                                </div>
                            </div>
                        </div>
                        {props.incomeObj && Object.keys(props.incomeObj).length === 0 ?
                        <div className="formRow width50 paddingleft16 repeatMonthlyColIncome formRowGap">
                            <div className="fullWidth df aic">
                                <label className="wardMovingLabel repeatMonthlyLabelIncome">{A12Y_CONST.LABEL.DOES_THIS_REPEAT_MONTHLY}</label>
                                <div className="marginTop5 df aic">
                                    <Controller
                                        name="repeatMonthly"
                                        control={control}
                                        render={({field}) =>
                                            <Switch
                                                id={A12Y_CONST.BUTTON_ID.REPEAT_MONTHLY_TOGGLE}
                                                checked={repeatMonthly}
                                                onChange={handleRepeatMonthly}
                                                color="primary"
                                            />
                                        }
                                    />
                                    {repeatMonthly && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}
                                    {!repeatMonthly && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}
                                </div>
                            </div>
                        </div>
                        :
                        null
                        }
                        {repeatMonthly && (props.incomeObj && Object.keys(props.incomeObj).length === 0 )?
                            <div className="fullWidth payRow">
                                <div className="formRow width50 paddingRight16 formRowGap">
                                    <label>{A12Y_CONST.LABEL.END_REPEAT_BY}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                    <div>
                                        <Controller
                                            name="dateEndRepeatBy"
                                            control={control}
                                            inputmode='none'
                                            render={({ field }) =>
                                                <DatePicker
                                                    {...field}
                                                    ref={dateEndRepeatByRef}
                                                    dateFormat="MM / dd / yyyy"
                                                    selected={dateEndRepeatBy}
                                                    onChange={date => setDateEndRepeatBy(date)}
                                                    onChangeRaw={(event) => handleChangeRaw(event, 'dateEndRepeatBy')}
                                                    onBlur={isRepeatMonthlyDateValid}
                                                    onCalendarClose={isRepeatMonthlyDateValid}
                                                    showMonthDropdown
                                                    onKeyDown={(event) => hideOnBlur(event, dateEndRepeatByRef)}
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onFocus={(e)=> {setDateEndRepeatByError(false)}}
                                                    className={!dateEndRepeatByError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                                    placeholderText="MM / DD / YYYY"
                                                />
                                            }
                                        />
                                        <div className='errorRelativeRow'>
                                            {dateEndRepeatByError? <p className='errorMessage'>{dateEndRepeatByErrorMeesage}</p>: null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="fullWidth payRow">
                        <div className="formRow width50 paddingRight16 formRowGap">
                            <div>
                                <label>{A12Y_CONST.LABEL.LINK_URL}</label>
                                <div className='fullWidth doctorRowMarginB_600'>
                                    <input
                                        {...register('linkToWebsite',{
                                            pattern:{
                                                //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                                value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                                message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                            }
                                        })}
                                        placeholder='www.example.com'
                                        className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                        type='text'
                                        id='displayName'
                                        autoComplete="none"
                                        onChange={(e) => handleLinkToWebsite(e.target.value)}
                                        value={linkToWebsite}
                                        onFocus={()=>{
                                            setError("linkToWebsite", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.incomeObj && Object.keys(props.incomeObj)?.length > 0 ?
                    null
                    :
                    <div className="formRow fullWidth formRowGap df fcol">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className="fullWidth">
                            <textarea 
                                {...register("note")} 
                                rows="3" 
                                onChange={handleNoteChange}
                                value={note}
                                maxLength='4000'
                                className="fullWidth inputColum24size txtNote" 
                                placeholder={A12Y_CONST.LABEL.CAPTURE_THE_DETAILS_OF}
                            ></textarea>
                        </div>
                    </div>
                    }
                    <div className="formRow fullWidth formRowGap uploadWrap">
                        <label className="lblExpense">{A12Y_CONST.LABEL.INCOME_ATTACHMENT}</label>
                        <div className="lblUpload">
                            <UploadFiles
                                //saveClick={saveClicked}
                                fileDetailSendToParent={filesDetail_FromChild}
                                fileUploadingData={props.fileUploadingData}
                                isSubmit={isSubmit}
                                editMode={editMode}
                                obj={props.incomeObj}
                                accepts={""}
                            />
                            <div className='errorRelativeRow'> {errors.incomeAttachment && <p className='errorMessage'>{A12Y_CONST.TEXT.SELECT_FILE_UPLOAD_MESSAGE}</p>}</div>
                        </div>
                    </div>
                    {repeatMonthly && props.incomeObj && Object.keys(props.incomeObj).length === 0 ?
                        <div className="formRow width50 paddingleft16 attachAllFileCol formRowGap">
                            <div className="fullWidth df aic">
                                <label className="wardMovingLabel repeatMonthlyLabelIncome">{A12Y_CONST.LABEL.ATTACH_TO_ALL_FUTURE_INCOME}</label>
                                <div className="marginTop5 df aic">
                                    <Controller
                                        name="attachAllFile"
                                        control={control}
                                        render={({field}) =>
                                            <Switch
                                                id={A12Y_CONST.BUTTON_ID.ATTACHED_ALL_FUTURE_INCOME}
                                                checked={attachAllFile}
                                                onChange={handleAttachAllFile}
                                                color="primary"
                                            />
                                        }
                                    />
                                    {attachAllFile && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}
                                    {!attachAllFile && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.CANCEL_INCOME} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.SAVE_INCOME} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default AddIncome