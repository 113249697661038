import React, {useEffect, useState, useRef} from 'react';
import A12Y_CONST from "../common/a12yConst";
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Switch from "@material-ui/core/Switch";
import UploadFiles from "./ImageUploadComponent";
import Loading from "./LoadingComponent";
import {v4 as uuidv4} from "uuid";
import {currencyFormatting} from "../util/CurrencyFormater";
import {dateFromKeyboard, ddmmyyyyFormat, validateDate} from "../util/date_format";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {useHistory} from "react-router-dom";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';

function AssetTransferFormComponent(props) {

    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [error, setError] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [transferType, setTransferType] = useState({});
    const [transferTypeError, setTransferTypeError] = useState(false);
   // const [transferTypeErrorMsg, setTransferTypeErrorMsg] = useState('');
    const [transferTypeOptions, setTransferTypeOptions] = useState([]);

    const [dateOfSale, setDateOfSale] = useState(new Date());
    const [dateOfSaleError, setDateOfSaleError] = useState(false);
    const [dateOfSaleErrorMsg, setDateOfSaleErrorMsg] = useState('');

    const [netProceeds, setNetProceeds] = useState('');
    const [netProceedsError, setNetProceedsError] = useState(false);
    const [netProceedsErrorMsg, setNetProceedsErrorMsg] = useState('');

    const [precentageSold, setPrecentageSold] = useState('');
    const [precentageSoldError, setPrecentageSoldError] = useState(false);
    const [precentageSoldErrorMsg, setPrecentageSoldErrorMsg] = useState('');

    const [courtOrder, setCourtOrder] = useState(false);
    const [isPartial, setIsPartial] = useState(false);

    const [dateOfCourtOrder, setDateOfCourtOrder] = useState(new Date());
    const [dateOfCourtOrderError, setDateOfCourtOrderError] = useState(false);
    const [dateOfCourtOrderErrorMsg, setDateOfCourtOrderErrorMsg] = useState('');

    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);

    const [note, setNote] = useState('');

    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState(false);
    const [editMode, setIsEditMode] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [assetTransferId, setAssetTransferId] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const dateOfSaleRef = useRef(null);
    const dateOfCourtOrderRef = useRef(null);

    const { register, handleSubmit, getValues, control,setValue, formState: { errors } } = useForm({ mode: "onBlur" });


    useEffect( ()=>{
       const initiliazeData = async ()=>{
           if(!props.editMode){
               const uuid = await uuidv4();
               setAssetTransferId(uuid)
           }
           setTransferTypeOptions([
               {label: 'Sale', value: 'Sale', key:2},
               {label: 'Gift', value: 'Gift', key:3},
               {label: 'Partial Sale', value: 'Partial Sale', key:4}])
       }
       initiliazeData();
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
        setError(props.error)
        setLoading(props.loading);
    },[props.popUpStatus, props.error, props.loading])


    const handleTransferTypeChange = (e) => {
        setTransferType(e)
        setIsPartial(e.value === 'Partial Sale')
        setTransferTypeError(false)
    }

    const validateTransferType = () => {
        if(Object.keys(transferType).length === 0){
            setTransferTypeError(true)
           // setTransferTypeErrorMsg(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
            return  true
        }else {
            setTransferTypeError(false)
            //setTransferTypeErrorMsg('')
            return false
        }
    }
    const validateCourtOrder = () => {
        if(Object.keys(reason).length === 0){
            setReasonError(true)
            return  true
        }else {
            setReasonError(false)
            return false
        }

    }
    const validateNetProceeds = () => {
        if(netProceeds === ''){
            setNetProceedsError(true)
            setNetProceedsErrorMsg(A12Y_CONST.ERROR_MESSAGE.ENTER_NET_PROCEEDS)
            return  true
        }else {
            setNetProceedsError(false)
            setNetProceedsErrorMsg('')
            return false
        }
    }

    const validateRateAmount = (amount) =>{
        let amt = amount.trim();
        let reg = new RegExp('^[0-9 .]*$');
        if(reg.test(amt)){
            if(amt.toString().includes('.')){
                let index = amt.indexOf('.');
                let result = amt.substring(index+1, amt.length);
                if(result.length <= 2){
                    return amt
                }
            }else {
                return amt
            }
        }
    }

    const handleNetProceedsChange = async (e) => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setNetProceeds(amt)
            setValue('assetValue',amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setNetProceeds(temp)
                setValue('assetValue', temp)
            }
        }
    }

    const validatePrecent = (amount) =>{
        let amt = amount.trim();
        let reg = /^(\d{0,2}(\.\d{0,2})?|100(\.00?)?)$/;
        
        return reg.test(amt)
    }

    const handlePrecentageSoldChange = async (e) => {
        let value = e.target.value.trim()
        let netProceed = await validateRateAmount(value)
        if(netProceed !== undefined && netProceed !== null){
            if(validatePrecent(value)){
                setPrecentageSold(netProceed)
            }
        }
    }

    const validatePrecentageSold = () => {
        if(Object.keys(transferType).length > 0 && transferType.value === 'Partial Sale' ){
            if(precentageSold === ''){
                setPrecentageSoldError(true)
                setPrecentageSoldErrorMsg(A12Y_CONST.ERROR_MESSAGE.ENTER_PERCENTAGE_SOLD)
                return true
            }else if(parseFloat(precentageSold) === 0){
                setPrecentageSoldError(true)
                setPrecentageSoldErrorMsg(A12Y_CONST.ERROR_MESSAGE.PERCENT_SOLD_MUST_BE_GREATER_THAN_0)
                return true
            }
            else {
                setPrecentageSoldError(false)
                setPrecentageSoldErrorMsg('')
                return false
            }
        }
    }
    const handleCourtOrderChange = (event) => {
        setReason('')
        setReasonError(false)
        setCourtOrder(event.target.checked);
        setReason('');
    }

    const filesDetail_FromChild = (e,deletedFiles) => {
        if(assetTransferId !== null){
            let fileList = [];
            let base64List = [];
            for (var i = 0; i < e.length; i++) {
                if(!e[i].file_id){
                    let name = e[i].displayName
                    let size = e[i].size
                    let file = e[i];
                    let fileName = e[i].name;
                    let reader = new FileReader();
                    const uuid = uuidv4();
                    let s3key = `${props.dependent.id}/asset_transfer/${assetTransferId}/${uuid}`
                    reader.readAsDataURL(file);
                    reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                    );
                    fileList.push({
                        s3_key: s3key,
                        file_id:uuid,
                        asset_id:assetTransferId,
                        'contentType': e[i].type,
                        'fileMetadata': {
                            "about_dependent": "some more info"
                        },
                        'name' : name,
                        'size' : size,
                        'fileName': fileName
                    });
                }
            }
            setFileInfo(fileList);
            setFileBase64Info(base64List)
            setDeletedFileInfo(deletedFiles)
        }
    }

    const handleNoteChange = (e) => {
        // setNote(e.target.value)
            if(e.target.value === " "){
                e.preventDefault();
            }else {
                setNote(e.target.value);
            }
    }

    const handleReasonChange = (e) => {
        if(e.target.value.length === 1 && e.target.value === " "){
            return;
        }else{
            setReason(e.target.value);
        }
    }

    const onSubmit = async (formData)=>{
        let asset  = props.currentAsset;
        await validateTransferType();
       let isNetProceedsValid = await validateNetProceeds();
       let isPrecentageSoldValid = false
        if(transferType?.key && transferType.key === 4){
            if(precentageSold === null){
                isPrecentageSoldValid = true
                setPrecentageSoldError(true)
                setPrecentageSoldErrorMsg(A12Y_CONST.ERROR_MESSAGE.ENTER_PERCENTAGE_SOLD)
            }else {
                isPrecentageSoldValid = await validatePrecentageSold();
            }
        }
        let isCourtOrderValid = true
        if(courtOrder){
            await handleDateOfCourtOrderChange()
        }else {
            isCourtOrderValid = reason.length > 0 ? true : false
            if(!isCourtOrderValid){
                setReasonError(true)
            }
        }
        if(assetTransferId !== null && !dateOfSaleError && !transferTypeError && !isNetProceedsValid && !dateOfCourtOrderError && !isPrecentageSoldValid && Object.keys(asset).length > 0 && isCourtOrderValid && !precentageSoldError){
            let obj = {
                "id" : assetTransferId,
                "asset_type_id" : asset.asset_type_id,
                "asset_name" : asset.asset_name,
                "court_order_used" : courtOrder,
                "court_order" : reason,
                "net_proceeds" : netProceeds && netProceeds.includes(',') ? netProceeds.replaceAll(',','') : netProceeds,
                "transfer_type" : transferType.key,
                "transfer_date" : await ddmmyyyyFormat(new Date(dateOfSale)),
                "court_order_date" : await ddmmyyyyFormat(new Date(dateOfCourtOrder)),
                "note" :  note
            }
            if(precentageSold){
                obj.percentage_sold = precentageSold
            }
            setIsSubmit(true)
            props.isDataChanged(false)
            if(props.editMode){
                props.onUpdate(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }else {
                props.onSubmit(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }
        }
    }

    useEffect( ()=>{
        const assignFormDefaultData = async ()=>{
            if(props.editMode && Object.keys(props.currentAsset).length > 0 && props.currentAsset.hasOwnProperty('transfer')){
                let transfer = props.currentAsset.transfer;
                let netProceeds = transfer.net_proceeds || transfer.net_proceeds === 0 ? await currencyFormatting(transfer.net_proceeds) : ''

                setAssetTransferId(transfer.id)
                let selecetdTransferKey = await transferTypeOptions.find(item => item.key === transfer.transfer_type)
                if(selecetdTransferKey !== undefined && Object.keys(selecetdTransferKey).length > 0 ){
                    setTransferType(selecetdTransferKey)
                    if(transferType.label === 'Partial Sale'){
                        setIsPartial(true)
                    }
                }
                setPrecentageSold(transfer.percentage_sold)
                setDateOfSale(new Date(transfer.transfer_date))
                setNetProceeds(netProceeds)
                setReason(transfer.court_order)
                setCourtOrder(transfer.court_order_used)
                setDateOfCourtOrder(new Date(transfer.court_order_date))
                setNote(transfer.note)

                setIsEditMode(true);
            }
        }

        assignFormDefaultData();

    },[props.editMode,transferTypeOptions]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkFormDataIsChanged = async ()=>{
           setIsBtnDisabled(true)
           setIsDataModified(false)
           props.isDataChanged(false)
           if(editMode && Object.keys(props.currentAsset).length > 0 && props.currentAsset.hasOwnProperty('transfer')){
               let transfer = props.currentAsset.transfer;
               setIsBtnDisabled(true)
               let court_Message = transfer.court_order === null ? "" : transfer.court_order
               let reason_Message = reason === null ? "" : reason
               if(transferType.key !== transfer.transfer_type || (transferType.key === 4 && precentageSold !== null && precentageSold.toString() !== transfer.percentage_sold.toString()) || await ddmmyyyyFormat(new Date(dateOfSale)) !== transfer.transfer_date || netProceeds !== await currencyFormatting(transfer.net_proceeds) || (reason_Message !== court_Message) || note !== transfer.note || courtOrder !== transfer.court_order_used || (courtOrder && await ddmmyyyyFormat(dateOfCourtOrder) !== await ddmmyyyyFormat(new Date(transfer.court_order_date))) || fileInfo.length > 0 || deletedFileInfo.length > 0){
                   if(Object.keys(transferType).length > 0 && dateOfSale !== null && netProceeds !== '' && ((courtOrder && dateOfCourtOrder !== null) || (!courtOrder && reason !== ""))){
                       if(transferType.key === 4 && precentageSold !== ''){
                           setIsBtnDisabled(false)
                       }else {
                           if(transferType.key !== 4){
                               setIsBtnDisabled(false)
                           }
                       }
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }else {
               if(!props.editMode){
                   let today = await ddmmyyyyFormat(new Date())
                   if(Object.keys(transferType).length > 0 || (Object.keys(transferType).length > 0  && transferType.key === 4 && precentageSold !== '') || await ddmmyyyyFormat(new Date(dateOfSale)) !== today || netProceeds !== '' || (reason !== '') || note !== '' || courtOrder !== false || (courtOrder && await ddmmyyyyFormat(dateOfCourtOrder) !== today) || fileInfo.length > 0 || deletedFileInfo.length > 0){
                       if(Object.keys(transferType).length > 0 && dateOfSale !== null && netProceeds !== '' && ((courtOrder && dateOfCourtOrder !== null) || (!courtOrder && reason !== ""))){
                           if(transferType.key === 4 && precentageSold !== ''){
                               setIsBtnDisabled(false)
                           }else {
                               if(transferType.key !== 4){
                                   setIsBtnDisabled(false)
                               }
                           }
                       }

                       setIsDataModified(true)
                       props.isDataChanged(true)
                   }
               }
           }
       }
       checkFormDataIsChanged();
    },[editMode,transferType,dateOfSale,netProceeds,reason,note,courtOrder,dateOfCourtOrder,precentageSold,fileInfo,deletedFileInfo]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleDateOfSaleChange = () => {
        if(dateOfSale != null){
            let isValidObj = validateDate(dateOfSale, 100, 0)
            setDateOfSaleError(isValidObj.isErr)
            setDateOfSaleErrorMsg(isValidObj.errMsg)
        }
        else{
            setDateOfSaleError(true)
            setDateOfSaleErrorMsg(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }

    const handleDateOfCourtOrderChange = () => {
        if(dateOfCourtOrder != null){
            let isValidObj = validateDate(dateOfCourtOrder, 100, 0)
            setDateOfCourtOrderError(isValidObj.isErr)
            setDateOfCourtOrderErrorMsg(isValidObj.errMsg)
        }else{
            setDateOfCourtOrderError(true)
            setDateOfCourtOrderErrorMsg(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }


    const hidePopUp = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            if(props.editMode){
                history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
            }else {
                props.hidePopUp()
            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        if(props.editMode){
            history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW);
        }else {
            props.hidePopUp()
        }
    }


    return(
        <div className='height100'>
            {/*{loaderFullScreenVisible ?
                <LoaderFullScreen />
                :
                null
            }*/}
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            {/*<h4 className="dependentSubTitle marginBottom10">Loan Against Policy</h4>*/}
            <div className='height100'>
                <div className="areYouText df marginBottom15 ">
                    <span className='maxWidth392 displayInline dependentNameTitle'> {A12Y_CONST.LABEL.REPORT_SALE_OF_PROPERTY} </span>
                </div>
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )
                }
                <form autoComplete="off" className='fullWidth marginTop50' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className={`fullWidth ${editMode?'':'formInner'}`}>
                        <div className="formRow fullWidth formRowGap">
                            <label className="lblExpType">{A12Y_CONST.LABEL.CAPITAL_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className={`fullWidth dependentResidencCol ${transferTypeError && 'selectError'} ${ transferType && Object.keys(transferType).length > 0 ? 'hasValue' : 'noValue'}`}>
                                <Controller
                                    name="transferType"
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={transferTypeOptions.find(c => c.key === transferType.key)}
                                            options={transferTypeOptions}
                                            className="inputColum24size borderNone"
                                            onChange={handleTransferTypeChange}
                                            onBlur={validateTransferType}
                                            classNamePrefix={'dependentResidence'}
                                        />
                                    )}
                                    control={control}
                                    className="fullwidth inputColum24size"
                                    placeholder="Select"
                                />

                                <div className='errorRelativeRow'> {transferTypeError && <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_TRANSFER_TYPE}</p>}</div>
                            </div>
                        </div>

                        <div className="formRow fullWidth formRowGap">
                            <label>{A12Y_CONST.LABEL.DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="datePicker">
                                <Controller
                                    name="dateOfSale"
                                    control={control}
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            dateFormat="MM / dd / yyyy"
                                            selected={dateOfSale}
                                            ref={dateOfSaleRef}
                                            onChange={date => setDateOfSale(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'dateOfSale')}
                                            onBlur={handleDateOfSaleChange}
                                            onCalendarClose={handleDateOfSaleChange}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, dateOfSaleRef)}
                                            onFocus={(e)=> {setDateOfSaleError(false); readOnlyOnMobile(e.target)}}
                                            className={dateOfSaleError ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {dateOfSaleError? <p className='errorMessage'>{dateOfSaleErrorMsg}</p>: null}
                                </div>
                            </div>
                        </div>

                        <div className="formRow fullWidth formRowGap df fcol">
                            <label>{A12Y_CONST.LABEL.NET_PROCEEDS}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={getValues('netProceeds') !== '' || netProceeds.length < 1? "currency" : "currency currencyColorChnage" }> {A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                                <input
                                    {...register("netProceeds")}
                                    // value={netProceeds}
                                    id="netProceeds"
                                    maxLength='255'
                                    placeholder='0.00'
                                    value={netProceeds}
                                    onBlur={validateNetProceeds}
                                    onChange={handleNetProceedsChange}
                                    onFocus={()=>setNetProceedsError(false)}
                                    className={`fullWidth txtAmount inputColum24size ${netProceedsError && 'errorInputBorderRed'}`}
                                />
                                <div className='errorRelativeRow'>{netProceedsError? <p className='errorMessage'>{netProceedsErrorMsg}</p>: null}</div>
                            </div>
                        </div>

                        {
                            isPartial || (Object.keys(transferType).length > 0 && transferType.key === 4) ? (
                                <div className="formRow fullWidth formRowGap df fcol">
                                    <label>{A12Y_CONST.LABEL.PERCENT_SOLD}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                    <div className="fullWidth">
                                        <input
                                            {...register("precentageSold")}
                                            value={precentageSold}
                                            id="precentageSold"
                                            maxLength='255'
                                            // placeholder='Type here...'
                                            onBlur={validatePrecentageSold}
                                            onChange={handlePrecentageSoldChange}
                                            onFocus={()=>setPrecentageSoldError(false)}
                                            className={`fullWidth inputColum24size percentOwned ${precentageSoldError && 'errorInputBorderRed'}`}
                                            placeholder="00.00"
                                        />
                                        <label className={`lblPercent ${precentageSold > 0 && 'activeVal'} `}>%</label>
                                        <div className='errorRelativeRow'>{precentageSoldError? <p className='errorMessage'>{precentageSoldErrorMsg}</p>: null}</div>
                                    </div>
                                </div>
                            )
                                :
                                null
                        }

                        <div className="formRow fullWidth formRowGap df fcol">
                            <label>{A12Y_CONST.LABEL.COURT_ORDER_USED}</label>
                            <div className="fullWidth marginTop6">
                                <Controller
                                    name="courtOrder"
                                    control={control}
                                    render={({ field }) =>
                                        <Switch
                                            id={A12Y_CONST.BUTTON_ID.COURT_ORDER_USED}
                                            checked={courtOrder}
                                            onChange={handleCourtOrderChange}
                                            color="primary"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        {
                            courtOrder ?
                                <div className="formRow fullWidth formRowGap dtCoRow">
                                    <label className='lblDtCO dateCourtOrderTop'>{A12Y_CONST.LABEL.DATE_OF_COURT_ORDER}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                    <div className="datePicker">
                                        <Controller
                                            name="dateOfCourtOrder"
                                            control={control}
                                            render={({ field }) =>
                                                <DatePicker
                                                    {...field}
                                                    dateFormat="MM / dd / yyyy"
                                                    ref={dateOfCourtOrderRef}
                                                    selected={dateOfCourtOrder}
                                                    onChange={date => setDateOfCourtOrder(date)}
                                                    onChangeRaw={(event) => handleChangeRaw(event, 'dateOfCourtOrder')}
                                                    onBlur={handleDateOfCourtOrderChange}
                                                    onCalendarClose={handleDateOfCourtOrderChange}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onKeyDown={(event) => hideOnBlur(event, dateOfCourtOrderRef)}
                                                    onFocus={(e)=> {setDateOfCourtOrderError(false); readOnlyOnMobile(e.target)}}
                                                    className={dateOfCourtOrderError ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                                    placeholderText="MM / DD / YYYY"
                                                />
                                            }
                                        />
                                        <div className='errorRelativeRow'>
                                            {dateOfCourtOrderError? <p className='errorMessage'>{dateOfCourtOrderErrorMsg}</p>: null}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="formRow fullWidth formRowGap df fcol">
                                    <label>{A12Y_CONST.LABEL.COURT_ORDER}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                    <div className="fullWidth">
                                                            <textarea
                                                                {...register("reason")}
                                                                rows="3"
                                                                onChange={handleReasonChange}
                                                                maxLength='4000'
                                                                placeholder='Reason for not obtaining court order...'
                                                                value={reason}
                                                                onBlur={validateCourtOrder}
                                                                className={`fullWidth inputColum24size txtNote ${reasonError && 'errorInputAllBorderRed'}`}
                                                                onFocus={()=>{setReasonError(false)}}
                                                            ></textarea>
                                        <div className='errorRelativeRow'> {reasonError && <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.ENTER_REASON_FOR_NOT_OBTAINING_ORDER}</p>}</div>
                                    </div>
                                </div>
                        }`
                        {editMode ?
                            null
                            :
                        <div className="formRow fullWidth formRowGap df fcol">
                            <label>{A12Y_CONST.LABEL.NOTE}</label>
                            <div className="fullWidth">
                                                        <textarea
                                                            {...register("note")}
                                                            rows="3"
                                                            onChange={handleNoteChange}
                                                            placeholder='Capture details about this transfer'
                                                            maxLength='4000'
                                                            value={note}
                                                            className="fullWidth inputColum24size txtNote"
                                                        ></textarea>
                            </div>
                        </div>
                        }
                        <div className="formRow fullWidth formRowGap df fcol">
                            <label>{A12Y_CONST.LABEL.ATTACHMENT}</label>
                            <div className="fullWidth">
                                <UploadFiles
                                    fileDetailSendToParent={filesDetail_FromChild}
                                    fileUploadingData={props.fileUploadingData}
                                    isSubmit={isSubmit}
                                    obj={props.currentAsset.transfer}
                                    editMode={editMode}
                                    accepts={""}
                                />
                                <div className='errorRelativeRow'> {errors.expenseAttachment && <p className='errorMessage'>{A12Y_CONST.TEXT.SELECT_FILE_UPLOAD_MESSAGE}</p>}</div>
                            </div>
                        </div>
                    </div>
                    <div className="fullWidth padding20 textAlignRight displayFlexCol">
                        <div id={A12Y_CONST.BUTTON_ID.CANCEL_BUTTON} className=" grayButtonColorBorder" onClick={hidePopUp}>{A12Y_CONST.BUTTON_TEXT.CANCEL}</div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.ASSET_TRANSFER+props.BtnTxt} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={props.BtnTxt} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )

}

export default AssetTransferFormComponent