import React,{Component} from 'react'
import {secureClient} from "../config/axiosClient";
import A12Y_CONST from '../common/a12yConst'
import MyAccountPersonalInformationComponent from "../components/MyAccountPersonalInformationComponent";
import * as userDetailActions from "../redux/actions/userDetailActions";
import {connect} from "react-redux";
import DependentProfileAll from "../components/DependentProfileAll";
import {loadStripe} from '@stripe/stripe-js';
import BillingComponent from "../components/BillingComponent";
import {Elements} from '@stripe/react-stripe-js';

class NewMyAccountDependentScreen extends Component {
    constructor(props){
        super(props)

        this.state = {
            loading: false,
            error:false,
            message:'',
            errorMessage:'',
            loaderFullScreenVisible : false,
            isClickedOutside:false,
            isAssetPopUp:false,
            successMessage:'',
            isProfile:true,
            isDependent:false,
            isDependentActive:false,
            isAccount:false,
            isActiveAccount:false,
            resetFields:false,
            stripePromiseState:null,
            showPopup:false,
            invoiceData:{},
            isPopupVisible:false,
            tabSelecting:'',
            screen: null,
            isPromoCodeApplied:false,
            promoCode:{},
            discountAmt:'',
            promocodeMessage:'',
            promocodeError:false,
            promocodeInAmount:false,
            couponAmount:0,
            loaderFullScreen:false,

        }
    }
    componentDidMount = async ()=> {
        const stripePromise = loadStripe(this?.props?.stripeConfig?.stripe_publishable_key);
        this.setState({stripePromiseState:stripePromise})
        let userInfo =await this.props.userState.userInfo
        let userSubscription = this.props.userSubscription
        if(userInfo.phones && userInfo.phones.length === 0){
            let obj = {title:A12Y_CONST.LABEL.LET_GET_SOME,description:A12Y_CONST.LABEL.WE_NEED_YOUR_FULL,otherDes:''}
            await this.props.signUpTextChange(obj);
            this.setState({isProfile :true, isDependent:false, isDependentActive:false, screen:null})
        }else {
            if(userInfo.phones && userInfo.phones.length > 0 && ((Object.keys(this.props.dependent).length === 0) || (Object.keys(this.props.dependent).length > 0 && this.props.dependent.access.can_update)) && ((userSubscription === undefined) || (userSubscription !== undefined && userSubscription !== null && (userSubscription.subscription_status === A12Y_CONST.TEXT.EXPIRED || userSubscription.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT)))){
                let obj = {title:A12Y_CONST.LABEL.WE_JUST_NEED_A_LITTLE_MORE_INFORMATION,description:A12Y_CONST.LABEL.PROVIDING_SOME_OF_YOUR,otherDes:A12Y_CONST.LABEL.TELL_US_ABOUT_YOUR_DEPENDENT}
                await this.props.signUpTextChange(obj);
                if(Object.keys(this.props.dependent).length === 0){
                    this.setState({isProfile : false ,isAccount:false, isActiveAccount:false, screen:1, isDependent:true, isDependentActive:true})
                }else{
                    let userRoleType = this.props.userRoleType
                    if(Object.keys(this.props.dependent).length > 0 && userRoleType === null && userSubscription === undefined){
                        this.setState({isProfile : false ,isAccount:false, isActiveAccount:false, screen:2, isDependent:true, isDependentActive:true})
                    }else {
                        if(userRoleType !== '' && userRoleType === 4){
                            let obj = {title:A12Y_CONST.LABEL.TRY_US_FOR_30_DAYS,description:A12Y_CONST.LABEL.ONE_FREE_BILLED_ANNUALLY,otherDes:A12Y_CONST.LABEL.LETS_MAKE_IT_OFFOCIAL}
                            await this.props.signUpTextChange(obj);
                            this.setState({isDependent :false, isAccount:true, isActiveAccount:true, screen:null, isProfile : false , isDependentActive:true})
                        }else {
                            this.setState({isProfile : false ,isAccount:false, isActiveAccount:false, screen:3, isDependent:true, isDependentActive:true})
                        }
                    }
                }
                //await this.tabcheck(false,false,true)
            }
        }
    }

    handelSubmit = async(data)=>{
        this.setState({error:false})
        data.userId = this.props.userState.userInfo.id
        this.setState({loading:true})
        let isPhonePresent = this.props.userState.userInfo?.phones?.length > 0 ? true : false;
        await this.props.updateUserProfile(data,this.props.userState.userInfo.email);
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg})
        }else {
            await this.setState({isProfile:false, isDependent:true, isDependentActive:true})
            let obj = {
                title:A12Y_CONST.LABEL.WE_JUST_NEED_A_LITTLE_MORE_INFORMATION,
                description:A12Y_CONST.LABEL.PROVIDING_SOME_OF_YOUR,
                otherDes:A12Y_CONST.LABEL.TELL_US_ABOUT_YOUR_DEPENDENT}
            await this.props.signUpTextChange(obj);
            let userInfo =await this.props.userState.userInfo
            let userSubscription = this.props.userSubscription
            //this.setState({successMessage:this.props.successMessage})
            if(userInfo.phones && userInfo.phones.length > 0 && ((Object.keys(this.props.dependent).length === 0) || (Object.keys(this.props.dependent).length > 0 && this.props.dependent.access.can_update)) && ((userSubscription === undefined) || (userSubscription !== undefined && userSubscription !== null && (userSubscription.subscription_status === A12Y_CONST.TEXT.EXPIRED || userSubscription.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT)))){
                // await this.tabcheck(false,false,true)
            }else {
                if(userInfo.phones && userInfo.phones.length > 0 && Object.keys(this.props.dependent).length === 0){
                    this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)
                }else {
                    if(!isPhonePresent && (Object.keys(this.props.dependent).length > 0 && !this.props.dependent.access.can_update)){
                        this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)
                    }
                }
            }
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    onFinishHandler = ()=>{
        // if(this.props.dependent && Object.keys(this.props.dependent) && Object.keys(this.props.dependent).length>0){
        //     this.setState({isDependentProfileCreated:true})
        // }
    }
    overlay = (status) => {
        // this.setState({overylayResponsive : status})
    }

    updateBilling = async(data)=>{
        this.setState({error:false})
        let obj = {
            "payment_method" : data.payMethod,
            "email" : this.props.userState.userInfo.email,
            "user_id" : this.props.userState.userInfo.id,
            "price_id":this.props?.subscriptionProduct[0]?.plans[0]?.id

        }
        this.setState({loading:true})
        if(this.state.isPromoCodeApplied && Object.keys(this.state.promoCode).length > 0){
            obj.promotion_code = this.state.promoCode.id
        }
        if(data.editMode) {
            obj.stripe_customer_id = this.props.paymentMethod.external_customer_id
            obj.existing_payment_method_id = this.props.paymentMethod.default_payment_method_id
            await this.props.updatePaymentMethod(obj);
        }else{
            await this.props.createUserSubscription(obj);
        }
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
        }else {
            this.setState({successMessage:this.props.successMessage,resetFields:true,error:false,showPopup:true})
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }
    handleCancelSubscription = async() =>{
        this.setState({error:false})
        let obj = {
            "stripe_customer_id" : this.props.paymentMethod.external_customer_id,
            "subscription_id" : this.props.userSubscription.subscription_id,
            "user_id" : this.props.userState.userInfo.id
        }
        this.setState({loading:true})
        await this.props.cancelUserSubscription(obj);
        this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,resetFields:false})
        }else {
            this.setState({successMessage:this.props.successMessage,resetFields:true,error:false})
        }
        let temp = this;
        setTimeout(function(){ temp.setState({successMessage:null}) }, 3000);
    }

    onContinueHandel =()=>{
        this.setState({showPopup:false})
        // this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)
        this.props.history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW)
    }
    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }
    tabSelect = async () => {
        this.setState({isDependent :false, isAccount:true, isActiveAccount:true, screen:null})
        let obj = {title:A12Y_CONST.LABEL.TRY_US_FOR_30_DAYS,description:A12Y_CONST.LABEL.ONE_FREE_BILLED_ANNUALLY,otherDes:A12Y_CONST.LABEL.LETS_MAKE_IT_OFFOCIAL}
        await this.props.signUpTextChange(obj);
    }
    yourProfileTabActive = async () => {
        this.setState({isProfile :true, isDependent:false, isDependentActive:false, screen:null})
        let obj = {title:A12Y_CONST.LABEL.LET_GET_SOME,description:A12Y_CONST.LABEL.WE_NEED_YOUR_FULL,otherDes:''}
        await this.props.signUpTextChange(obj);
    }
    addDependentTabActive = async () => {
        let userRoleType = this.props.userRoleType
        this.setState({isAccount:false, isActiveAccount:false, screen:userRoleType !== 4 ? 3 : 2, isDependent:true, isDependentActive:true})
        let obj = {title:A12Y_CONST.LABEL.WE_JUST_NEED_A_LITTLE_MORE_INFORMATION,description:A12Y_CONST.LABEL.PROVIDING_SOME_OF_YOUR,otherDes:A12Y_CONST.LABEL.TELL_US_ABOUT_YOUR_DEPENDENT}
        await this.props.signUpTextChange(obj);
    }

    handelApplyPromoCode = async (promoCode)=>{
        await this.setState({loaderFullScreen:true,promocodeMessage:''})
        try{
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.VALIDATE_PROMOCODE,{code:promoCode});
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(response?.data?.sr?.promotion_code?.data[0]?.active){
                    let obj = response?.data?.sr?.promotion_code?.data[0];
                    if(obj.coupon.amount_off !== null && Object.keys(this.props.userPlan).length > 0){
                        let discountPrice = obj.coupon.amount_off/100
                        this.setState({discountAmt:this.props.userPlan.plan_price - discountPrice, promocodeInAmount:true, promocodeMessage:A12Y_CONST.TEXT.OFFER_HAS_BEEN_APPLIED, couponAmount: discountPrice})
                    }else {
                        let discountPrice =  ((obj.coupon.percent_off/ 100) * this.props.userPlan.plan_price).toFixed(2)
                        this.setState({discountAmt:this.props.userPlan.plan_price - discountPrice, promocodeInAmount:false, promocodeMessage:A12Y_CONST.TEXT.OFFER_HAS_BEEN_APPLIED, couponAmount: obj.coupon.percent_off})
                    }
                    this.setState({isPromoCodeApplied:true,promoCode:obj,})
                }else {
                    this.setState({isPromoCodeApplied:false,promoCode:{}})
                }
                await this.setState({loaderFullScreen:false})
            }else {
                  await  this.setState({isPromoCodeApplied:false,promoCode:{},promocodeError:true, promocodeMessage:response.data.sr.a12yErr.message, loaderFullScreen:false})
            }
        }catch (e) {
            console.error('error',e)
            await this.setState({loaderFullScreen:false})
        }
    }

    render(){
        return(
            <div className='fullWidth' onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
                <div className="newSignUpTabBox fullWidth">
                    <div className={`tabRowMyAccount fullWidth ${Object.keys(this.props?.dependent).length > 0 && !this.props?.dependent?.access.can_update ?'readOnlyMyAccountTitle': null}`}>
                        <ul>
                            <li className='active'>
                                <div className='tabTitle'>
                                    <span className='count'>1</span>
                                    <span className='tabText'>{A12Y_CONST.LABEL.FINISH_YOUR_PROFILE}</span>
                                </div>
                            </li>
                            {Object.keys(this.props?.dependent).length > 0 && !this.props?.dependent?.access.can_update  ?
                            null
                            :
                            <>
                            <li className={`${this.state.isDependentActive?'active':''}`}>
                                <div className='tabTitle'>
                                    <span className='count'>2</span>
                                    <span className='tabText'>{A12Y_CONST.LABEL.ADD_DEPENDENT}</span>
                                </div>
                            </li>
                            <li className={`${this.state.isActiveAccount?'active':''}`}>
                                <div className='tabTitle'>
                                    <span className='count'>3</span>
                                    <span className='tabText'>{A12Y_CONST.LABEL.ACTIVATE_ACCOUNT}</span>
                                </div>
                            </li>
                            </>

                            }
                        </ul>
                    </div>
                    <div className="tabContainerMyAccount fullWidth">
                        {this.state.isProfile &&
                        <MyAccountPersonalInformationComponent
                            onSubmit={this.handelSubmit}
                            userInfo={this.props.userState.userInfo}
                            error={this.state.error}
                            dependent={this.props.dependent}
                            errorMessage={this.state.errorMessage}
                            message={this.state.successMessage}
                            loading={this.state.loading}
                            isClickedOutside={this.state.isClickedOutside}
                            isDataChanged={this.dataChangeHandler}
                            onFlow={true}
                            userSubscription={this.props.userSubscription}
                        />
                        }
                        {this.state.isDependent &&
                        <DependentProfileAll
                            onCompleteDependent={this.handelDependentComplete}
                            onFinish={this.onFinishHandler}
                            overlay = {this.overlay}
                            tabSelect ={this.tabSelect}
                            screen={this.state.screen}
                            yourProfileTabActive={this.yourProfileTabActive}
                        />
                        }
                        {
                            this.state.isAccount &&
                            <div className='fullWidth'>
                                {/*<PhotoUpload/>*/}
                                <Elements stripe={this.state.stripePromiseState} options={{appearance:{theme:"night", variables:{colorPrimary: '#0570de',
                                            colorBackground: '#ffffff',
                                            colorText: '#30313d',
                                            colorDanger: '#df1b41',
                                            fontSizeBase:'22px',
                                            fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                            spacingUnit: '2px',
                                            borderRadius: '4px',}}}}>
                                    <BillingComponent
                                        onSubmit={this.updateBilling}
                                        onCancel={this.handleCancelSubscription}
                                        userSubscription={this.props.userSubscription}
                                        userPlan={this.props.userPlan}
                                        subscriptionProduct={this.props.subscriptionProduct}
                                        paymentMethod={this.props.paymentMethod}
                                        loading={this.state.loading}
                                        error={this.state.error}
                                        onFlow={true}
                                        errorMessage={this.state.errorMessage}
                                        message={this.state.successMessage}
                                        onContinue={this.onContinueHandel}
                                        showPopup={this.state.showPopup}
                                        invoiceData={this.state.invoiceData}
                                        isDataChanged={this.dataChangeHandler}
                                        addDependentTabActive={this.addDependentTabActive}
                                        applyPromoCode={this.handelApplyPromoCode}
                                        isPromoCodeApplied={this.state.isPromoCodeApplied}
                                        discountAmt={this.state.discountAmt}
                                        promocodeMessage={this.state.promocodeMessage}
                                        promocodeError={this.state.promocodeError}
                                        promocodeInAmount={this.state.promocodeInAmount}
                                        couponAmount={this.state.couponAmount}
                                        loaderFullScreen={this.state.loaderFullScreen}
                                        resetPromocodeError={(status)=>{this.setState({promocodeError:false})}}
                                    />
                                </Elements>

                                {/*<img className='billingImage' src={billingImg} />*/}
                            </div>
                        }
                    </div>
                </div>
            </div>

        )
    }
}


const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
    isError : state.userDetailReducer.isError,
    successMessage : state.userDetailReducer.successMessage,
    isDataChanged : state.userDetailReducer.isDataChanged,
    dependent : state.dependentReducer.dependent,
    userSubscription : state.userDetailReducer.userSubscription,
    paymentMethod : state.userDetailReducer.paymentMethod,
    userPlan : state.userDetailReducer.userPlan,
    subscriptionProduct : state.userDetailReducer.subscriptionProduct,
    stripeConfig : state.userDetailReducer.stripeConfig,
    signUpText : state.userDetailReducer.signUpText,
    userRoleType: state.userDetailReducer.userRoleType,

});

const mapDispatchToProps = dispatch => ({
    updateUserProfile : (data,email) => dispatch(userDetailActions.updateUserProfile(data,email)),
    updatePassword : (data) => dispatch(userDetailActions.updatePassword(data)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    createUserSubscription : (data) => dispatch(userDetailActions.createUserSubscription(data)),
    updatePaymentMethod : (data) => dispatch(userDetailActions.updatePaymentMethod(data)),
    cancelUserSubscription : (data) => dispatch(userDetailActions.cancelUserSubscription(data)),
    signUpTextChange : (data) => dispatch(userDetailActions.signUpTextChange(data)),
});


export default connect(mapStateToProps, mapDispatchToProps) (NewMyAccountDependentScreen);