import React from 'react'
import exclamationImg from '../assets/images/exclamationErrorIcon.svg';
function ErrorMessageComponent (props) {
    return (
        <div className="positionRelative fullWidth">
            <div className="errorMessageWithBackground positionAbsoute">
                <div className="exclamationErrorIcon"><img alt={''} src={exclamationImg}/></div>
                <div className='errorTextContent'>{props.errorMessage}</div>
            </div>
        </div>

    )
}

export default  ErrorMessageComponent;