import React, { useState, useEffect}from 'react';
import A12Y_CONST from '../common/a12yConst'

function  PopUpInviteUserComponent (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    useEffect(()=>{
        if(props.title){
            if (props.popUpType === A12Y_CONST.TEXT.SUCCESSFULLY_INVITE){
                  setTitle(A12Y_CONST.TEXT.SUCCESSFULLY_INVITE)
            }
            if (props.popUpType === A12Y_CONST.TEXT.CANCEL_INVITE){
                  setTitle(A12Y_CONST.TEXT.CANCEL_INVITE)
            }
            if (props.popUpType === A12Y_CONST.TEXT.DELETE_INVITE){
                setTitle(A12Y_CONST.TEXT.DELETE_VIEWER_ACCESS)
            }
            if (props.popUpType === A12Y_CONST.TEXT.RESEND_INVITE){
                setTitle(A12Y_CONST.TEXT.RESEND_INVITE+"?")
            }
        }
        if(props.message){
            if (props.popUpType === A12Y_CONST.TEXT.SUCCESSFULLY_INVITE){
                setMessage(A12Y_CONST.MESSAGE.MESSAGE_HAS_BEEN_SEND_TO)
            }
            if (props.popUpType === A12Y_CONST.TEXT.CANCEL_INVITE){
                setMessage(A12Y_CONST.MESSAGE.YOU_WILL_NOT_ABLE)
            }
            if (props.popUpType === A12Y_CONST.TEXT.DELETE_INVITE){
                setMessage(A12Y_CONST.MESSAGE.ARE_YOU_SURE_YOU_WANT_TO_DELETE)
            }
            if (props.popUpType === A12Y_CONST.TEXT.RESEND_INVITE){
                setMessage(A12Y_CONST.MESSAGE.ARE_YOU_SURE_YOU_WANT_TO_RESEND)
            }
        }
    },[props.title,props.message, props.popUpType])


    const handleDeleteButton = () => {
        props.handleDeleteButton(props.popUpType)
    }
    const hidePopUp = () => {
        props.hidePopUp(props.popUpType)
    }
    const hidePopUp_Redirect = () => {
        props.hidePopUp_Redirect()
    }
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className="cancelIcon fullWidth marginTopBottom10">
                                        {props.popUpType === A12Y_CONST.TEXT.SUCCESSFULLY_INVITE  ?
                                            <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.5371 41.6609L34.7444 53.9774L56.7566 29.8799" stroke="url(#paint0_linear_2940_29902)" strokeWidth="4" strokeLinecap="round"/>
                                                <path d="M21.5371 41.6609L34.7444 53.9774L56.7566 29.8799" stroke="url(#paint1_linear_2940_29902)" strokeOpacity="0.2" strokeWidth="4" strokeLinecap="round"/>
                                                <circle cx="41" cy="41" r="37" stroke="url(#paint2_linear_2940_29902)" strokeWidth="2"/>
                                                <circle cx="41" cy="41" r="39.5" stroke="url(#paint3_linear_2940_29902)" strokeOpacity="0.18" strokeWidth="3"/>
                                                <defs>
                                                    <linearGradient id="paint0_linear_2940_29902" x1="21.5371" y1="29.8799" x2="45.7264" y2="62.2261" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_2940_29902" x1="26.5903" y1="33.1659" x2="43.604" y2="59.7492" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_2940_29902" x1="3" y1="3" x2="82.2198" y2="75.4809" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_2940_29902" x1="3" y1="3" x2="82.2198" y2="75.4809" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#34A0A4"/>
                                                        <stop offset="1" stopColor="#85C894"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            :
                                            null
                                        }
                                        {props.popUpType === A12Y_CONST.TEXT.CANCEL_INVITE  ?
                                            <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M34 6.8C18.9788 6.8 6.8 18.9788 6.8 34C6.8 49.0212 18.9788 61.2 34 61.2C49.0212 61.2 61.2 49.0212 61.2 34C61.2 18.9788 49.0212 6.8 34 6.8ZM0 34C0 15.2218 15.2218 0 34 0C52.7782 0 68 15.2218 68 34C68 52.7782 52.7782 68 34 68C15.2218 68 0 52.7782 0 34ZM19.6962 19.6962C21.0222 18.3668 23.1778 18.3668 24.5038 19.6962L34 29.1924L43.4962 19.6962C44.8222 18.3668 46.9778 18.3668 48.3038 19.6962C49.6332 21.0222 49.6332 23.1778 48.3038 24.5038L38.8076 34L48.3038 43.4962C49.6332 44.8222 49.6332 46.9778 48.3038 48.3038C46.9778 49.6332 44.8222 49.6332 43.4962 48.3038L34 38.8076L24.5038 48.3038C23.1778 49.6332 21.0222 49.6332 19.6962 48.3038C18.3668 46.9778 18.3668 44.8222 19.6962 43.4962L29.1924 34L19.6962 24.5038C18.3668 23.1778 18.3668 21.0222 19.6962 19.6962Z" fill="#D83933"/>
                                            </svg>
                                            :
                                            null
                                        }
                                        {props.popUpType === A12Y_CONST.TEXT.DELETE_INVITE  ?
                                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 12.8V3.2C16 2.336 16.3361 1.53613 16.9377 0.928125C17.5361 0.352125 18.352 0 19.2 0H44.8C45.648 0 46.4639 0.352125 47.0623 0.928125C47.6639 1.53613 48 2.336 48 3.2V12.8H64V19.2H57.6V60.8C57.6 61.664 57.2639 62.4639 56.6623 63.0719C56.0639 63.6479 55.248 64 54.4 64H9.6C8.752 64 7.9361 63.6479 7.3377 63.0719C6.7361 62.4639 6.4 61.664 6.4 60.8V19.2H0V12.8H16ZM36.5248 38.4L42.1824 32.7359L37.6576 28.2242L32 33.8883L26.3424 28.2242L21.8176 32.7359L27.4752 38.4L21.8176 44.0641L26.3424 48.5758L32 42.9117L37.6576 48.5758L42.1824 44.0641L36.5248 38.4ZM22.4 6.4V12.8H41.6V6.4H22.4Z" fill="#D83933"/>
                                            </svg>
                                            :
                                            null
                                        }
                                        {props.popUpType === A12Y_CONST.TEXT.RESEND_INVITE?
                                            <svg width="90" height="89" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M88.9592 0.123047C89.3813 0.216836 89.522 0.638885 89.8033 0.873357C89.8033 16.7237 89.8033 32.574 89.8033 48.4242C88.7951 49.6669 88.4434 49.7138 86.7083 48.987C78.9238 45.6575 71.1628 42.3045 63.3784 38.975C63.1439 38.8812 62.886 38.8109 62.6281 38.7406C66.5437 33.0195 70.4594 27.2983 74.3751 21.5772C78.619 15.3872 82.8395 9.22055 87.0366 3.00705C87.6931 2.04572 88.4903 1.20162 88.9592 0.123047Z" fill="#2D6CB2"/>
                                                <path d="M88.9593 0.123437C88.4669 1.20201 87.6697 2.04611 87.0366 3.00744C82.8396 9.22094 78.6191 15.3876 74.3986 21.5542C70.4829 27.2753 66.5672 32.9964 62.6515 38.7175C59.9551 42.6566 57.2353 46.6192 54.5388 50.5583C53.4134 52.1996 52.241 52.1293 51.3735 50.3708C48.9818 45.5641 46.5902 40.7809 44.1752 35.9742C44.9489 35.5521 45.5586 34.8956 46.2385 34.3563C48.8881 32.2226 51.5141 30.0655 54.1402 27.9083C56.7429 25.7981 59.3455 23.7348 61.9481 21.6245C64.9494 19.186 67.9272 16.724 70.9284 14.2621C73.2966 12.3394 75.6647 10.4167 78.0564 8.51753C80.448 6.57141 82.863 4.64874 85.2312 2.70263C86.3098 1.81163 87.3649 0.897194 88.42 0.00620091C88.6076 0.00620091 88.8186 -0.0406935 88.9593 0.123437Z" fill="#1E8E93"/>
                                                <path d="M88.42 0.0291588C87.3649 0.920152 86.3097 1.83459 85.2312 2.72558C82.8396 4.6717 80.4479 6.59437 78.0563 8.54049C75.6882 10.4632 73.2965 12.3624 70.9284 14.285C67.9271 16.7236 64.9493 19.1855 61.9481 21.6475C59.3455 23.7577 56.7428 25.8445 54.1402 27.9313C51.4907 30.065 48.888 32.2456 46.2385 34.3793C45.5585 34.9186 44.9489 35.5751 44.1751 35.9971C39.0637 35.9971 33.9522 35.9971 28.8407 35.9971C28.2076 35.9971 27.5745 35.9971 27.387 35.1999C27.2228 34.4496 27.6918 34.0979 28.2545 33.7697C40.1657 27.0403 52.1003 20.2875 64.0115 13.5347C71.7021 9.17356 79.4163 4.83583 87.1304 0.474655C87.529 0.263631 87.9041 -0.06463 88.42 0.0291588Z" fill="#2D6CB2"/>
                                                <path d="M16.1323 73.1378C16.8826 70.5586 17.6798 68.0029 18.6881 65.5175C19.6963 63.0086 22.3224 61.6956 24.9719 62.1411C27.4573 62.5631 29.3565 64.603 29.6613 67.2291C29.9662 69.8552 28.7469 71.7075 26.6132 73.0675C23.823 74.826 21.0328 76.5846 18.2426 78.3665C16.8123 79.2575 16.4371 80.4064 16.953 82.1884C17.586 84.4159 19.4384 85.9634 21.7596 85.9634C25.6753 85.94 28.9814 84.4628 31.5371 81.4616C32.9908 79.7499 34.1397 77.8038 35.4059 75.9515C39.6264 69.8083 43.8234 63.6652 48.0205 57.522C48.677 56.5607 49.4039 56.3262 50.1307 56.7951C50.9514 57.3344 51.0452 58.1316 50.3418 59.1633C46.6605 64.5561 42.9559 69.9724 39.2512 75.3653C37.4927 77.9445 35.8748 80.6409 33.8818 83.056C30.5289 87.1358 26.1443 89.0585 20.8686 88.8005C17.6564 88.6364 14.6082 85.7524 13.9986 82.4698C13.9283 82.0946 13.8579 81.7429 13.7641 81.2505C12.5918 81.9774 11.4898 82.6808 10.3878 83.3842C7.85546 84.9786 5.22937 86.6434 2.62674 88.3081C2.53295 88.3785 2.43916 88.4254 2.32192 88.4957C1.47782 88.9647 0.68062 88.824 0.235123 88.1206C-0.210373 87.4172 -0.0227959 86.62 0.797855 86.1041C2.57984 84.9318 4.38527 83.8063 6.19071 82.6574C9.05126 80.8285 11.9118 79.0231 14.7724 77.2176C14.8193 77.1473 14.8662 77.1004 14.9365 77.0301H14.9131C16.273 76.4439 17.3985 75.5294 18.6412 74.7557C18.6881 74.7088 18.735 74.6384 18.8053 74.5915C21.15 73.2785 23.354 71.7544 25.6284 70.3007C26.027 70.0428 26.3318 69.6911 26.5663 69.269C27.3635 67.8153 26.7773 65.963 25.2767 65.2596C23.7058 64.5093 21.9003 65.1658 21.2907 66.7836C20.7749 68.1436 20.3294 69.527 19.8604 70.9103C19.8135 70.9807 19.7666 71.0276 19.7197 71.0979H19.7432C18.3832 71.5434 17.375 72.5282 16.1323 73.1378Z" fill="#2D6CB2"/>
                                                <path d="M16.1323 73.1376C17.3751 72.528 18.3833 71.5197 19.6963 71.0508C19.6025 71.4025 19.5322 71.7776 19.4384 72.1294C19.2274 72.9266 18.7584 73.6534 18.7819 74.521C18.6646 74.521 18.6412 74.5913 18.6178 74.6851C17.3751 75.4354 16.2261 76.3733 14.8896 76.9595C15.171 75.6699 15.64 74.4037 16.1323 73.1376Z" fill="#1E8E93"/>
                                                <path d="M14.9365 76.9834C14.8896 77.0537 14.8427 77.1006 14.7723 77.171C14.8192 77.1241 14.8661 77.0537 14.9365 76.9834Z" fill="#98D1A3"/>
                                                <path d="M19.8135 70.8867C19.7666 70.9571 19.7197 71.004 19.6729 71.0743C19.7197 71.004 19.7666 70.9336 19.8135 70.8867Z" fill="#98D1A3"/>
                                                <path d="M18.6411 74.7088C18.6646 74.615 18.6646 74.5212 18.8052 74.5447C18.7349 74.615 18.688 74.6619 18.6411 74.7088Z" fill="white"/>
                                            </svg>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="areYouText areYouDmFont marginBottom10">
                                        {title}
                                    </div>
                                    <div className="popUpInviteUserMessage marginTop10">
                                        {message} {props.popUpType === A12Y_CONST.TEXT.DELETE_INVITE || props.popUpType === A12Y_CONST.TEXT.RESEND_INVITE ?<span className='wordBreak'>{props.inviteUserEmailId? props.inviteUserEmailId: null}</span>:null}
                                        {props.popUpType === A12Y_CONST.TEXT.SUCCESSFULLY_INVITE ? <span className="displayBlock wordBreak">{props.userFirstName && props.userFirstName} {A12Y_CONST.LABEL.AT}  {props.userEmail}</span>: null}
                                    </div>
                                    {props.popUpType === A12Y_CONST.TEXT.SUCCESSFULLY_INVITE?
                                        <div className='fullWidth marginTop20 marginBottom20 texAlingCener'>
                                                <input id='signInButton' className={'button maxWidth270'} onClick={hidePopUp_Redirect} type="button" value={A12Y_CONST.BUTTON_TEXT.DONE}/>
                                        </div>
                                        :
                                        <div className="fullWidth marginTop40 marginBottom20 texAlingCener">
                                            <div id={props.buttonDeleteTxt+A12Y_CONST.BUTTON_ID.BUTTON} className="popUpYesButton marginLeftRight2percent" onClick={handleDeleteButton} >{props.buttonDeleteTxt}</div>
                                            <div id={props.buttonDeleteTxt+A12Y_CONST.BUTTON_ID.BUTTON} className=" popUpNoButton" onClick={hidePopUp}>{props.buttonCancelTxt}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpInviteUserComponent;