import React ,{useEffect, useState, useRef}from 'react'
import A12Y_CONST from '../common/a12yConst'
import { useForm, Controller } from 'react-hook-form';
import Loading from './LoadingComponent'
import Select from 'react-select';
import {isValidPhoneNumber}from 'libphonenumber-js';
import PhotoUploadComponent from './PhotoUploadEditComponent'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import moment from 'moment';
import PopUpComponent from '../components/PopUpComponent'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {hideOnBlur, readOnlyMobile} from "../util/inputKeyboardHide";

function DependentProfileEditComponent(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [isButtonDisbale, setIsButtonDisbale] = useState(false);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const {register,control, handleSubmit, setError, setFocus,formState:{ errors, isSubmitting},setValue, getValues} = useForm({mode:"all"});
    const [gender, setGender] = useState({ value: 'Choose Gender', label: 'Choose Gender', isdisabled: true});
    const [nickName, setNickName] = useState('');
    const [appointmentDateErrorMeesage, setAppointmentDateErrorMeesage] = useState('');
    const [appointmentDateError, setAppointmentDateError] = useState(false);
    const [selectedPhoneType1, setSelectedPhoneType1] = useState({});
    const [selectedPhoneType2, setSelectedPhoneType2] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumber_2, setPhoneNumber_2] = useState('');
    const [phoneNumber_1ErrorMessage, setPhoneNumber_1ErrorMessage] = useState('');
    const [phoneNumber_2ErrorMessage, setPhoneNumber_2ErrorMessage] = useState('');
    const [phoneNumberError, setphoneNumberError] = useState(false);
    const [phoneNumber2Error, setphoneNumber2Error] = useState(false);
    const [phoneType1LabelActive, setPhoneType1LabelActive] = useState(false);
    const [phoneType2LabelActive, setPhoneType2LabelActive] = useState(false);
    const [isPhone2DropdownSelect, setIsPhone2DropdownSelect] = useState(false);
    const [email, setEmail] = useState('');
    const [caseFileNum, setCaseFileNum] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isPopUp, setIsPopUp] = useState(false);
    const [isEditMode, setisEditMode] = useState(false);
    const [isDataModified, setDataModified] = useState(false);
    const [dependentProfileObj, setDependentProfileObj] = useState({});

    const [appointDate, setAppointDate] = useState(null);
    const [shouldImageRemove, setshouldImageRemove] = useState(false);

    const [dobDate, setDobDate] = useState(null);
    const [dobDateError, setDobDateError] = useState(false);
    const [dobDateErrorMessage, setDobDateErrorMeesage] = useState('');
    
    const appointDateRef = useRef(null);
    const dobDateRef = useRef(null);
    
    const genderOptions = [
        { value: 'Choose Gender', label: 'Choose Gender', isdisabled: true},
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' },
        { value: 'X', label: 'X' }
    ]

    const onValueChange = async (event) => {
      await setGender(event.target.value)
    }
    useEffect( ()=>{
       const assignDefaultFormData = async ()=>{
           if(props.dependent && Object.keys(props.dependent).length > 0){
               await setDefaultValue();
           }
       }
       assignDefaultFormData()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!appointDate && appointDate !== null){
            setFocus("appointment_date");
        }
    }, [setFocus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
        if(props.loading){
            setIsBtnDisabled(true)
        }if(props.error){
            setIsBtnDisabled(false)
        }
    },[props.loading,props.error])

    const setDefaultValue = async()=>{
        setValue("first_name",props.dependent.first_name)
        setFirstName(props.dependent.first_name)
        setLastName(props.dependent.last_name)
        setValue("last_name",props.dependent.last_name)
        setValue("nick_name",props.dependent.nick_name)
        setNickName(props.dependent.nick_name)
        setValue("case_file_num",props.dependent.case_file_num)
        setCaseFileNum(props.dependent.case_file_num)
        if(props.dependent.email){
            setValue("email",props.dependent.email.email)
            setEmail(props.dependent.email.email)
        }
        if(props.dependent.gender){
            setGender(props.dependent.gender)
            // setGender({ value: props.dependent.gender, label: props.dependent.gender })
        }
        if(props.dependent.dob){
            let date = new Date(props.dependent.dob)
            setDobDate(date)
        }if(props.dependent.appointment_date){
            let date = new Date(props.dependent.appointment_date)
            setAppointDate(date)
        }

        if(props.dependent.phone && props.dependent.phone.length > 0) {
            props.dependent.phone.forEach((phone,index)=>{
                if(index === 0){
                    if(phone.type === 'mobile'){
                        setSelectedPhoneType1({value:'Mobile' , label:'Mobile'})
                    }else if(phone.type === 'office'){
                        setSelectedPhoneType1({value:'Office' , label:'Office'})
                    }else if(phone.type === 'home'){
                        setSelectedPhoneType1({value:'Home' , label:'Home'})
                    }else if(phone.type === 'other'){
                        setSelectedPhoneType1({value:'Other' , label:'Other'})
                    }
                    if(phone.number.length > 0){
                        setPhoneNumber(phone.number)
                        setPhoneType1LabelActive(true)
                    }else {
                        setPhoneNumber('')
                    }
                }else {
                    if(phone.type === 'mobile'){
                        setSelectedPhoneType2({value:'Mobile' , label:'Mobile'})
                    }else if(phone.type === 'office'){
                        setSelectedPhoneType2({value:'Office' , label:'Office'})
                    }else if(phone.type === 'home'){
                        setSelectedPhoneType2({value:'Home' , label:'Home'})
                    }else if(phone.type === 'other'){
                        setSelectedPhoneType2({value:'Other' , label:'Other'})
                    }
                    if(phone.number.length > 0){
                        setPhoneNumber_2(phone.number)
                        setPhoneType2LabelActive(true)
                    }else {
                        setPhoneNumber_2('')
                    }
                }
            })
        }
       await setisEditMode(true)
    }

    useEffect( ()=>{
        const checkIsFormDataChanged = async ()=>{
            if(isEditMode){
                setIsBtnDisabled(true)
                props.isDataChanged(false)
                let dependent = props.dependent;
                setDataModified(false)
                let appointmentDate = await ddmmyyyyFormat(appointDate)
                let doB = await ddmmyyyyFormat(new Date(dobDate))
                if(dependent && Object.keys(dependent).length > 0){
                    console.log('shouldImageRemove', shouldImageRemove)
                    console.log('Object.keys(dependentProfileObj).length > 0', Object.keys(dependentProfileObj).length > 0)
                    console.log('dependent', dependent)
                    if((dependent.appointment_date !== null && dependent.appointment_date !== appointmentDate) || (!shouldImageRemove && Object.keys(dependentProfileObj).length > 0) || (shouldImageRemove && Object.keys(dependentProfileObj).length === 0 && dependent.profile_pic_url !== null) || Object.keys(dependentProfileObj).length > 0 || (dependent.appointment_date === null && appointDate !== null) || (dependent.dob === null && dobDate !== null) || (dependent.dob!== null && dependent.dob !== doB)){
                        setDataModified(true)
                        setIsBtnDisabled(false)
                        props.isDataChanged(true)
                    }
                    if(getValues("first_name") !== dependent.first_name  || getValues("last_name") !== dependent.last_name || dependent.nick_name !== nickName || /*shouldImageRemove || Object.keys(dependentProfileObj).length > 0 ||*/ (dependent.case_file_num !== null && dependent.case_file_num !== caseFileNum) || (dependent.case_file_num === null && caseFileNum !== null && caseFileNum !=="") || (dependent.email !== null && dependent.email.email !==  email) || (dependent.email === null && email !== '') || (dependent.gender !== null && dependent.gender !== gender) || (dependent.gender === null && gender.value !== 'Choose Gender') || (dependent.phone === null && phoneNumber!=='' && phoneNumber.length >1) || (dependent.phone === null && phoneNumber_2!=='' && phoneNumber_2.length >1) || ((dependent.phone === null && (Object.keys(selectedPhoneType1).length > 0 || Object.keys(selectedPhoneType2).length > 0)) || (dependent.phone && dependent.phone.length > 0 && ((Object.keys(selectedPhoneType1).length > 0 && dependent.phone[0].type !== selectedPhoneType1.value.toLowerCase()) || (phoneNumber !== "1" && dependent.phone[0].number !== phoneNumber) || (dependent.phone[0].number !== '' && phoneNumber === "1") || ((Object.keys(selectedPhoneType2).length > 0 && dependent.phone[1].type !== selectedPhoneType2.value.toLowerCase()) || (phoneNumber_2 !== "1" && dependent.phone[1].number !== phoneNumber_2) || (dependent.phone[1].number !== '' && phoneNumber_2 === "1")))))){
                        setDataModified(true)
                        setIsBtnDisabled(false)
                        props.isDataChanged(true)
                    }
                   if(getValues("first_name")  ==="" || getValues("last_name")  === ""){
                        setIsBtnDisabled(true)
                    }
                }
            }
        }
        checkIsFormDataChanged()
    },[nickName,firstName,lastName,caseFileNum,appointDate,email,gender,dobDate,isEditMode,selectedPhoneType1,selectedPhoneType2,phoneNumber,phoneNumber_2,dependentProfileObj,shouldImageRemove]) // eslint-disable-line react-hooks/exhaustive-deps

    const phoneTypeList = [
        {
            value: 'select',
            label: 'Select',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#0096C7"></path>
            </svg>
        },{
            value: 'mobile',
            label: 'Mobile',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#0096C7"></path>
            </svg>
        },
        {
            value: 'office',
            label: 'Office',
            icon:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'home',
            label: 'Home',
            icon: <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'other',
            label: 'Other',
            icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#71767A" style={{strokeWidth:2}} ></path>
            </svg>,
            iconActive: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#0297C8" style={{strokeWidth:2}}></path>
            </svg>
        }
    ];

    const phoneTypeOptions = phoneTypeList && phoneTypeList.map(phoneTypeList =>{
        return {label:phoneTypeList.label, value: phoneTypeList.label}
    })

    const handleChangePhoneType1 = async e => {
        if(e.label === 'Select'){
            setPhoneNumber("1")
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
        }else {
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone();
        }
        await  setSelectedPhoneType1(e);
    }
    const handleChangePhoneType2 = e => {
        if(e.label === 'Select'){
            setPhoneNumber_2("1")
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone2();
        }
        setSelectedPhoneType2(e);
    }
    const handlePhoneField = e => {
        if(e.length > 1){
            setPhoneType1LabelActive(true)
        }else{
            setPhoneType1LabelActive(false);
        }
        setPhoneNumber(e)
    }
    const onBlurEventHandlerPhone = () => {
        if(phoneNumber.length > 1){
            const isValid =  validatePhoneNumber(phoneNumber)
            if(!isValid){
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
            }else {
                setphoneNumberError(false)
                setPhoneNumber_1ErrorMessage('')
            }
        }
    }

    const validatePhoneNumber = (e) => {
        const validNumber = '+'+e.toString()
        const phoneNumber = isValidPhoneNumber(validNumber)
        return phoneNumber
    }

    const handlePhoneField_2 = e => {
        if(e.length > 1){
            setPhoneType2LabelActive(true)
        }else{
            setPhoneType2LabelActive(false);
        }
        setPhoneNumber_2(e)
    }
    const onBlurEventHandlerPhone2 = () => {
        const isValid =  validatePhoneNumber(phoneNumber_2)
        if(phoneNumber_2.length > 1 && !isValid){
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }
    }

    /*const checkValue = (str, max) => {
        if (str.charAt(0) !== '0' || str == '00') {
            var num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            str = num > parseInt(max.toString().charAt(0))
            && num.toString().length == 1 ? '0' + num : num.toString();
        };
        return str;
    };*/
   /* const handleDob = async (e) => {
        var input = e.target.value
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split('/').map(function(v) {
            return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = checkValue(values[0], 12);
        if (values[1]) values[1] = checkValue(values[1], 31);
        var output = values.map(function(v, i) {
            return v.length == 2 && i < 2 ? v + ' / ' : v;
        });
        let dateOfBirth = output.join('').substr(0, 14);
        await setDob(dateOfBirth);
    }*/

    /*const handleAppointementDate = async (e) => {
        var input = e.target.value
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split('/').map(function(v) {
            return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = checkValue(values[0], 12);
        if (values[1]) values[1] = checkValue(values[1], 31);
        var output = values.map(function(v, i) {
            return v.length == 2 && i < 2 ? v + ' / ' : v;
        });
        let dateOfBirth = output.join('').substr(0, 14);
        await setAppointmentDate(dateOfBirth);
    }*/

    /*const validateDOB = (type) => {
        if(type === 'dob'){
            if(dob.length > 0 && dob.length < 14) {
                setDobError(true)
                setDobErrorMeesage('Invalid date. Enter as MM/DD/YYYY');
                return true;
            }else{
                setDobError(false)
                setDobErrorMeesage('');
                return false;
            }
        }else {
            if(appointmentDate.length > 0 && appointmentDate.length < 14) {
                setAppointmentDateError(true)
                setAppointmentDateErrorMeesage('Invalid date. Enter as MM/DD/YYYY');
                return true;
            }else{
                setAppointmentDateError(false)
                setAppointmentDateErrorMeesage('');
                return false;
            }
        }


    }*/

    const handleEmail = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setEmail(value);
        }
    }
    const handleNickName = (value) => {
        if(value.trim().length === 0){
            setValue("nick_name",'')
            setNickName('');
        }else {
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setNickName(value)
                setValue('nick_name',value)
            }
        }
    }

    const onSubmit = async (user)=>{
        let IsPhone1DropdownSelected = false;
        let IsPhone2DropdownSelected = false;
        let phoneError = false
        if(!user.nick_name){
            user.nick_name = user.first_name
        }
        if(gender.value !== "Choose Gender"){
            user.gender = gender;
        }else {
            user.gender = null;
        }
        user.advocate_id = props.dependent.advocate_id
        if(dobDate){
            user.dob = await ddmmyyyyFormat(dobDate)
        }else {
            user.dob = null
        }
        if(appointDate){
            user.appointment_date = await ddmmyyyyFormat(appointDate)
        }else {
            user.appointment_date = null
        }
        let phoneList = [{type:selectedPhoneType1.label && selectedPhoneType1.label !== 'Select' ? selectedPhoneType1.label.toLowerCase():'',number:(phoneNumber === '1' || phoneNumber === '') ? '' : phoneNumber},{type:selectedPhoneType2.label && selectedPhoneType2.label !== 'Select'?selectedPhoneType2.label.toLowerCase():'',number:(phoneNumber_2 === '1' || phoneNumber_2 === '') ? '' : phoneNumber_2}]
        /*if(Object.keys(selectedPhoneType1).length > 0 && phoneNumber){
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
            onBlurEventHandlerPhone()
        }else if(Object.keys(selectedPhoneType1).length === 0 && phoneNumber){
            onBlurEventHandlerPhone()
            if(!phoneNumberError){
                isPhoneError = true
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                //return;
            }
        }*/

        /*if(Object.keys(selectedPhoneType2).length > 0 && phoneNumber_2){
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            onBlurEventHandlerPhone2()
        }else if(Object.keys(selectedPhoneType2).length === 0 && phoneNumber_2){
            onBlurEventHandlerPhone2()
            if(!phoneNumber2Error){
                isPhoneError = true
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                //return;
            }
        }*/
        await onBlurEventHandlerPhone();
        if(phoneNumber.length > 1 && phoneNumber !== "1" &&  Object.keys(selectedPhoneType1).length === 0 ){
            phoneError = true
            setphoneNumberError(true)
            setIsPhone2DropdownSelect(true)
            IsPhone1DropdownSelected = true
            setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label !== 'Select' && (phoneNumber === "" || phoneNumber === "1")){
                phoneError = true
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
               // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone1DropdownSelected = false
        }

        await onBlurEventHandlerPhone2();
        if(phoneNumber_2.length > 1 && phoneNumber_2 !== "1" &&  Object.keys(selectedPhoneType2).length === 0 ){
            phoneError = true
            setIsPhone2DropdownSelect(true)
            IsPhone2DropdownSelected = true
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label !== 'Select' && (phoneNumber_2 === "" || phoneNumber_2 === "1")){
                phoneError = true
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
               // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone2DropdownSelected = false
        }
        if(phoneNumberError || phoneNumber2Error || IsPhone2DropdownSelected ||IsPhone1DropdownSelected ||  phoneError){
            return
        }

        if(!phoneNumberError && !phoneNumber2Error && !appointmentDateError && !dobDateError){
            user.phone = JSON.stringify(phoneList)
            user.email = JSON.stringify({email:user.email})
            props.onSubmit(user,dependentProfileObj,shouldImageRemove)
        }
    }
    const onCancel = ()=>{
        if(isDataModified){
            setIsPopUp(true)
        }else {
            props.onCancel();
        }

    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = async () => {
        props.isDataChanged(false)
        setIsPopUp(false)
        props.onCancel()
    }
    const checkIsPopupOpen = (status)=>{
        setIsButtonDisbale(status)
    }


    const handleRemoveImg = (status) => {
        setshouldImageRemove(status)
        setDependentProfileObj({});
       // props.handleRemoveImg();
    }

    const handeBlankSpace = (e) => {
        if(e.target.value.length < 1) {
            if (e.key === " ") {
                e.preventDefault();
            }
        }
    }

    const handelCaseNumber = (e) =>{
        if (e.target.value === " ") {
            e.preventDefault();
        }else {
            setCaseFileNum(e.target.value.trim())
        }
    }

    const overlay = (status) => {
        props.overlay(status)
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleDobDateChange = () => {
        if(dobDate != null){
            let isValidObj = validateDate(dobDate, 100, 0);
            setDobDateError(isValidObj.isErr)
            setDobDateErrorMeesage(isValidObj.errMsg)
        }
        
        else{
            setDobDateError(false)
        }
    }
    
    const handleAppointDateDateChange = () => {
        if(appointDate != null){
            let isValidObj = validateDate(appointDate, 100, 100)
            setAppointmentDateError(isValidObj.isErr)
            setAppointmentDateErrorMeesage(isValidObj.errMsg)
        }else{
            setAppointmentDateError(false)
            setAppointmentDateErrorMeesage('')
        }
    }
    useEffect( () => {
        let arr =[appointDateRef.current,dobDateRef.current]
        readOnlyMobile(arr)
    },[])

    const dependentProfileUploadHandler =  (croppedImageUrl1,advocate_id,dependentId,cropedUrl,dependent)=>{
        setDependentProfileObj({
            'croppedImageUrl1':croppedImageUrl1,
            'advocate_id':advocate_id,
            'dependentId':dependentId,
            'cropedUrl':cropedUrl,
            'dependent':dependent,
        })
        setshouldImageRemove(false)
    }

    const onFirstNameBlur = ()=>{
        if(firstName.length === 0){
            setError("first_name", {
                type: "manual",
                message: A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_REQUIRED,
            });
        }
        let displayName = getValues("nick_name")
        let first_Name = getValues("first_name")
        if(!displayName && first_Name && firstName){
            setValue("nick_name",firstName)
        }

    }
    const handleFirstName = (e) =>{
        let value = e.target.value
        if(value.trim().length === 0){
            setValue("first_name",'')
            setFirstName('');
        }else {
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setFirstName(value)
                setValue('first_name',value)
            }
        }
    }

    const handleLastName = (e) =>{
        let value = e.target.value
        if(value.trim().length === 0){
            setLastName('')
            setValue("last_name",'')
        }else {
            //eslint-disable-next-line
            if(/^[a-z\d\_\s]+$/i.test(value)){
                setLastName(value)
                setValue("last_name",value)
            }
        }
    }
    return(
        <div className="dependentBorderBox dependentProile_Edit_resposnive">
            <div className="fullWidth DependentStep1FormCol paddingBottom100 positionRelative">
            <div className="dependentSubTitle marginBottom5">
                {A12Y_CONST.LABEL.DEPENDENT_INFORMATION}
            </div>
            {error ?
                <div className='maxWidth600 marginLeftRightAuto'>
                    <ErrorMessageComponent errorMessage={props.errorMessage}/>
                </div>
                :
                (props.message ?
                        <SuccessMessageComponent successMessage={props.message}/>

                        :
                        null
                )

            }
            <form onSubmit={handleSubmit(onSubmit)} className='paddingTop20 marginTop40 dependentEditProfile' autoComplete="off">
                <div className="generalInfoTitle fullWidth positionRelative marginBottom30">
                    <div className="generalInfoProfileImg dependentPro">
                        <PhotoUploadComponent dependentProfileUpload={dependentProfileUploadHandler} overlay={overlay} removeImageCol={handleRemoveImg}  displayName={props.displayName} dependent={props.dependent} loading={props.loading} isPopupOpen={checkIsPopupOpen}/>
                    </div>
                    <div className="generalInfoUserName">
                        {A12Y_CONST.LABEL.DEPENDENT_PROFILE}
                    </div>
                 </div>
                <div className='dependentStepFormRow fullWidth marginBottom40 positionRelative'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.GAURDIAN_PROFILE_PERSONAL_INFO_NAME}<span className='astrciBlue'>*</span></label>
                    <div className='fullWidth'>
                        <div className='width50 paddingRight16 myProfile_personal_FirstName600'>
                            <input
                                {...register('first_name', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_REQUIRED, pattern:{
                                        value:/^.{1,}$/,
                                        //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_FIRST_NAME_INVALID
                                    }})}
                                placeholder='First Name'
                                className={getValues('first_name')?.length < 1 ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                type='text'
                                id='userFirstName'
                                onBlur={onFirstNameBlur}
                                autoComplete="none"
                                maxLength="100"
                                onChange={handleFirstName}
                                value={firstName}
                                // onKeyDown={e => handeBlankSpace(e)}
                                onFocus={()=>{
                                    setError("first_name", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div className='errorRelativeRow'> {errors.first_name && <p className='errorMessage'>{errors.first_name.message}</p>}</div>
                        </div>
                        <div className='width50 paddingleft16 responsive6000LeftRight'>
                            <input
                                {...register('last_name', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_REQUIRED , pattern:{
                                        value:/^.{1,}$/,
                                        //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_LAST_NAME_INVALID
                                    }})}
                                placeholder='Last Name'
                                className={errors.last_name ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                type='text'
                                id='userLastName'
                                maxLength="100"
                                autoComplete="none"
                                onChange={handleLastName}
                                value={lastName}
                                // onKeyDown={e => handeBlankSpace(e)}
                                onFocus={()=>{
                                    setError("last_name", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div className='errorRelativeRow'>{errors.last_name && <p className='errorMessage'>{errors.last_name.message}</p>}</div>
                        </div>
                    </div>
                </div>

                <div className='dependentStepFormRow fullWidth marginBottom30 positionRelative'>
                    <label className='labelCol positionAbsoute'>{A12Y_CONST.LABEL.DEPENDENT_DOB}</label>
                    <div className='fullWidth'>
                        <div className='width50 maxWidth388'>
                            <div className='fullWidth'>
                                <Controller
                                    name="dob"
                                    control={control}
                                    // rules={{ required: true }}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            ref={dobDateRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={dobDate}
                                            onChange={date => setDobDate(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'dob')}
                                            onBlur={handleDobDateChange}
                                            onCalendarClose={handleDobDateChange}
                                            peekNextMonth
                                            showMonthDropdown
                                            tabIndex={1}
                                            showYearDropdown
                                            onKeyDown={(event) => hideOnBlur(event, dobDateRef)}
                                            onFocus={(e)=> {setDobDateError(false)}}
                                            dropdownMode="select"
                                            className={dobDateError ? 'inputColum24size dtPicker errorInputBorderRed': 'inputColum24size dtPicker'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                {/* <input
                                    {...register('dob')}
                                    placeholder='MM/DD/YYYY'
                                    className={dobError ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    type='text'
                                    id='dob'
                                    autoComplete="none"
                                    onChange={handleDob}
                                    value={dob}
                                    onBlur={()=>{validateDOB('dob')}}
                                    onFocus={()=>setDobError(false)}
                                    //  onKeyDown={e => handeBlankSpace(e)}
                                />*/}
                                <div className='errorRelativeRow'>
                                    {dobDateError? <p className='errorMessage'>{dobDateErrorMessage}</p>: null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dependentStepFormRow fullWidth marginBottom30 positionRelative'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.DEPENDENT_GENDER}</label>
                    <div className='fullWidth'>
                        {/* <div className='width37'> */}
                            <div className='dependentGenderCol maxWidth338'>
                                {/*<Select*/}
                                {/*    placeholder="Select"*/}
                                {/*    options={genderOptions}*/}
                                {/*    onChange={onValueChange}*/}
                                {/*    isOptionDisabled={(option) => option.isdisabled}*/}
                                {/*    classNamePrefix={'dependentGender'}*/}
                                {/*    value={gender}*/}
                                {/*/>*/}
                                {
                                    genderOptions.map((item, i) => (
                                      item.value !== 'Choose Gender'?
                                        <label key={ i }>
                                            <input id={item.value+A12Y_CONST.LABEL.DEPENDENT_GENDER} type="radio"
                                                   checked={ gender === item.value }
                                                   onChange={onValueChange}
                                                   tabIndex={2}
                                                   value={item.value } />
                                            <span> { item.label }</span>
                                        </label>
                                          :
                                          null

                                    ))
                                }
                            </div>
                        {/* </div> */}
                    </div>
                </div>
                <div className='dependentStepFormRow fullWidth marginBottom40 positionRelative'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.DEPENDENT_DISPLAY_NAME}</label>
                    <div className='fullWidth'>
                        <input
                            {...register('nick_name')}
                            className={`inputColum24size ${nickName.length === 0 && 'noText'}`}
                            placeholder='&nbsp;'
                            type='text'
                            id='displayName'
                            maxLength="100"
                            tabIndex={3}
                            onChange={(e) => handleNickName(e.target.value)}
                            value={nickName}
                            onKeyDown={e => handeBlankSpace(e)}
                            autoComplete="none"
                        />
                        <div className='errorRelativeRow'> {errors.nick_name && <p className='errorMessage'>{errors.nick_name.message}</p>}</div>
                    </div>
                </div>

                <div className="dependentSubTitle marginBottom30">
                    {A12Y_CONST.LABEL.CONTACT_INFORMATION}
                </div>

                <div className='dependentStepFormRow fullWidth marginBottom40 positionRelative'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.DEPENDENT_EMAIL}</label>
                    <div className='fullWidth'>
                        <input
                            {...register('email',{
                                pattern:{
                                    //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                    //eslint-disable-next-line
                                    value:/^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_USERNAME_INVALID
                                }
                            })}
                            placeholder='name@example.com'
                            className={errors.email ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                            type='text'
                            id='displayName'
                            autoComplete="none"
                            onChange={(e) => handleEmail(e.target.value)}
                            value={email}
                            tabIndex={4}
                            onFocus={()=>{
                                setError("email", {
                                    type: "manual",
                                    message: "",
                                });
                            }}
                        />
                        <div className='errorRelativeRow'> {errors.email && <p className='errorMessage'>{errors.email.message}</p>}</div>
                    </div>
                </div>
                <div className='dependentStepFormRow fullWidth marginBottom40 positionRelative'>
                    <label className='labelCol phoneEditProfile'>{A12Y_CONST.LABEL.DEPENDENT_PHONE}</label>
                    <div className='fullWidth'>
                        <div className='width50 paddingRight16 selectBoxParend myProfilePhoneCol1_600'>
                            <div className={(selectedPhoneType1 && Object.keys(selectedPhoneType1).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsouteTop4 positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                <Select
                                    className='phoneTypeLabel'
                                    classNamePrefix={phoneType1LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                    placeholder="Select"
                                    value={selectedPhoneType1.label ? selectedPhoneType1 : ""}
                                    options={phoneTypeOptions}
                                    onChange={handleChangePhoneType1}
                                    tabIndex={5}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: 'red',
                                            primary: '#0096C708',
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <PhoneInput
                                    country='us'
                                    countryCodeEditable={false}
                                    disabled={Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label === 'Select' ? true : false}
                                    // containerClass={phoneType1LabelActive ? 'phoneNumber_Active': null}
                                    containerClass={phoneType1LabelActive ? phoneNumberError ?  'errorInputBorderRedInput phoneNumber_Active': phoneNumber === "1" ? 'phone_number_placeholder_color null' : 'phoneNumber_Active'  : phoneNumberError? 'errorInputBorderRedInput': null}
                                    value={phoneNumber}
                                    onBlur={onBlurEventHandlerPhone}
                                    tabIndex={6}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    onChange={phone => {handlePhoneField(phone)}}
                                    onFocus={() => setphoneNumberError(false)}
                                />
                            </div>
                            <div className='errorRelativeRow'> {phoneNumberError ? <p className='errorMessage'>{phoneNumber_1ErrorMessage}</p> : null }</div>
                        </div>
                        <div className='width48 marginTop768_10 floatRight paddingleft16 selectBoxParend myProfilePhoneCol2_600'>
                            <div className={(selectedPhoneType2 && Object.keys(selectedPhoneType2).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsouteTop4 positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                <Select
                                    className='phoneTypeLabel'
                                    classNamePrefix={phoneType2LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                    placeholder="Select"
                                    value={selectedPhoneType2.label ? selectedPhoneType2 : ""}
                                    options={phoneTypeOptions}
                                    onChange={handleChangePhoneType2}
                                    tabIndex={7}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: 'red',
                                            primary: '#0096C708',
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <PhoneInput
                                    country='us'
                                    countryCodeEditable={false}
                                    disabled={Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label === 'Select' ? true : false}
                                    // containerClass={phoneType2LabelActive ? 'phoneNumber_Active': null}
                                    containerClass={phoneType2LabelActive ? phoneNumber2Error? 'errorInputBorderRedInput phoneNumber_Active': phoneNumber_2 === "1" ? 'phone_number_placeholder_color null' :'phoneNumber_Active' : phoneNumber2Error? 'errorInputBorderRedInput': null}
                                    placeholder='Select'
                                    value={phoneNumber_2}
                                    onBlur={onBlurEventHandlerPhone2}
                                    tabIndex={8}
                                    inputProps={{
                                        name: 'phone1',
                                        required: false,
                                        autoFocus: false
                                    }}
                                    onChange={phone => {handlePhoneField_2(phone)}}
                                    onFocus={() => {
                                        setphoneNumber2Error(false)
                                        setIsPhone2DropdownSelect(false)
                                    }}
                                />
                            </div>
                            <div className='errorRelativeRow'> {phoneNumber2Error || isPhone2DropdownSelect? <p className='errorMessage'>{phoneNumber_2ErrorMessage}</p> : null }</div>
                        </div>

                    </div>
                </div>
                <div className="dependentSubTitle marginBottom30">
                    {A12Y_CONST.LABEL.GUAURDIAN_INFORMATION}
                </div>

                <div className='dependentStepFormRow fullWidth marginBottom40 marginTop20 guardianAppointmentRow600 positionRelative'>
                    <label className='labelCol label_guardianApp'>{A12Y_CONST.LABEL.GUAURDIAN_APPOINTMENT_LABEL}</label>
                    <div className='fullWidth maxWidth338 dtPickerWrap'>
                        <Controller
                            name="appointment_date"
                            control={control}
                            inputmode='none'
                           // rules={{ required: true }}
                            render={({ field }) => 
                                <DatePicker
                                    {...field}
                                    ref={appointDateRef}
                                    dateFormat="MM / dd / yyyy"
                                    selected={appointDate}
                                    onChange={date => setAppointDate(date)}
                                    onChangeRaw={(event) => handleChangeRaw(event, 'appointment_date')}
                                    tabIndex={9}
                                    onBlur={handleAppointDateDateChange}
                                    onCalendarClose={handleAppointDateDateChange}
                                    showMonthDropdown
                                    showYearDropdown
                                    onFocus={(e)=> {setAppointmentDateError(false);}}
                                    onKeyDown={(event) => hideOnBlur(event, appointDateRef)}
                                    dropdownMode="select"
                                    placeholderText="MM / DD / YYYY"
                                    className={appointmentDateError ? 'inputColum24size dtPicker errorInputBorderRed': 'inputColum24size dtPicker'}
                                />
                            }
                        />
                        <div className='errorRelativeRow'> 
                            {appointmentDateError && <p className='errorMessage'>{appointmentDateErrorMeesage}</p>}
                        </div>
                    </div>
                </div>
                <div className='dependentStepFormRow fullWidth marginBottom40 positionRelative'>
                    <label className='labelCol'>{A12Y_CONST.LABEL.DEPENDENT_CASE_NUMBER}</label>
                    <div className='fullWidth'>
                        <input
                            {...register('case_file_num')}
                            placeholder={moment().format('YYYYMMDD')+'-01'}
                            className={'inputColum24size'}
                            type='text'
                            id='displayName'
                            maxLength='255'
                            tabIndex={10}
                            autoComplete="none"
                            onChange={handelCaseNumber}
                            value={caseFileNum}
                        />
                        {/*<div className='errorRelativeRow'> {errors.nick_name && <p className='errorMessage'>{errors.nick_name.message}</p>}</div>*/}
                    </div>
                </div>
                <div className="fullWidth textAlignRight  displayFlexCol bottom_20_600 positionAbsouteBottom">
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_EDIT_CANCEL} className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            {loading ?
                                <Loading />
                                :
                                null
                            }
                            <input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_EDIT_SAVE} className={loading || isButtonDisbale || isBtnDisabled  ? "saveButtonDisable" :'saveButton'} disabled={loading || isButtonDisbale || isBtnDisabled }  type="submit" value={A12Y_CONST.BUTTON_TEXT.SAVE}/>
                        </div>
                    </div>
                </div>
            </form>
            <PopUpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
            </div>
        </div>
    )
}

export default DependentProfileEditComponent;