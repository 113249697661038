import React,{Component} from 'react'
// import {persistor} from "../redux/store";
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions';

import A12Y_CONST from "../common/a12yConst";
import * as dependentActions from "../redux/actions/dependentActions";
import WelcomeComponent from "../components/WelcomeComponent";
// import {client} from "../config/axiosClient";

import {LogOut} from "../util/LogOut";

class LogoutScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            logOutUrl:''
        };

        window.document.title = A12Y_CONST.TEXT.TITLE_DASHBOARD
        window.gtag('event', 'purchase', {
            'method': 'purchase-event'
        });
    }

    logoutUser = async() =>{
       /* localStorage.clear();
        await this.props.cleanDependent();
        await this.props.logoutUser()
        await this.props.userSessionBackupScreenDetails(null)
        await this.props.userSessionBackupUserIdDetails(null)
        persistor.purge();
        if(this.props?.userInfo?.is_google_user){
            const response = await client.get(A12Y_CONST.API_ENDPOINT.LOGIN_URL);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.setState({logOutUrl:response.data.sr.google_logout_url})
                window.location.href=this.state.logOutUrl
            }
        }else{
            this.props.history.push(A12Y_CONST.ROUTE.HOME);
        }*/

        let logoutResponse = LogOut()
        await this.props.userSessionBackupScreenDetails(null)
        await this.props.userSessionBackupUserIdDetails(null)
        if(!logoutResponse){
            this.props.history.push(A12Y_CONST.ROUTE.HOME);
        }

    }
    gotToScreenAdvocateProfileMyAccount = () => {
        this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT);
    }
     render(){
        return(
            // <div className="animationall mainRightSidebare">
            //     <div className='rightPanelWidhtSidebar'>
            //         <div className="rightPanelWidhtSidebarContainer alignCenterVH fullWidth">
            //              <div className='logoutMainCol fullWidth'>
            //                  <div className='popContainer popMaxWidth'>
            //                     <div className='popMaster'>
            //                         <div className="popUpMainBg boder1px fullWidth">
            //                             <div className="cancelIcon fullWidth marginTopBottom20">
            //                                 <svg xmlns="http://www.w3.org/2000/svg" width="40" height="36" viewBox="0 0 20 18" fill="none">
            //                                     <path d="M9.09091 18C4.07 18 0 13.9707 0 9C0 4.0293 4.07 2.53646e-06 9.09091 2.53646e-06C10.5024 -0.00104728 11.8947 0.323798 13.1572 0.94873C14.4197 1.57366 15.5176 2.48146 16.3636 3.6H13.9C12.8502 2.68359 11.5556 2.08652 10.1716 1.88046C8.7875 1.6744 7.37279 1.86809 6.09717 2.43829C4.82155 3.0085 3.73923 3.93099 2.98006 5.09507C2.2209 6.25915 1.81715 7.61538 1.81727 9.001C1.81739 10.3866 2.22136 11.7428 2.98072 12.9067C3.74008 14.0707 4.82256 14.993 6.09827 15.563C7.37399 16.133 8.78874 16.3264 10.1728 16.1202C11.5568 15.9139 12.8513 15.3166 13.9009 14.4H16.3645C15.5184 15.5187 14.4204 16.4265 13.1577 17.0515C11.8951 17.6764 10.5026 18.0012 9.09091 18ZM15.4545 12.6V9.9H8.18182V8.1H15.4545V5.4L20 9L15.4545 12.6Z" fill="#7BA4CD"/>
            //                                 </svg>
            //                             </div>
            //                             <div className="areYouText logOutText">
            //                                 {A12Y_CONST.TEXT.SIGN_OUT_MESSAGE}
            //                             </div>
            //                             <div className="fullWidth marginTop45 marginBottom20 alignCenterVH">
            //                                 <div className="popUpNoButton" onClick={this.gotToScreenAdvocateProfileMyAccount}>No</div>
            //                                 <div className="popUpYesButton marginLeftRight2percent" onClick={()=>{this.logoutUser()}} >{A12Y_CONST.BUTTON_TEXT.LOGOUT}</div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
            <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare welcomeScreen">
                <div className='rightPanelWidhtSidebar bgWhite fullWidth marginTop12'>
                    <div className="rightPanelWidhtSidebarContainer welcomeBg fullWidth marginNone">
                        <WelcomeComponent
                            screenName={A12Y_CONST.LABEL.LOG_OUT}
                            title={A12Y_CONST.TEXT.ARE_YOU_SURE_WANT}
                            description1={A12Y_CONST.TEXT.ANY_INFORMATION_OR_ACTIVITY}
                            dependent={this.props.dependent}
                            onCreate={this.onCreateHandler}
                            welcomeScreenType={A12Y_CONST.LABEL.LOGOUT}
                            logoutUser={this.logoutUser}
                            gotToScreenAdvocateProfileMyAccount={this.gotToScreenAdvocateProfileMyAccount}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    userInfo : state.userDetailReducer.userState.userInfo,
});

const mapDispatchToProps = dispatch => ({
    cleanDependent : () => dispatch(dependentActions.cleanDependent()),
    userSessionBackupScreenDetails : (screen) => dispatch(dependentActions.userSessionBackupScreenDetails(screen)),
    userSessionBackupUserIdDetails : (userId) => dispatch(dependentActions.userSessionBackupUserIdDetails(userId)),
    logoutUser : () => dispatch(userDetailActions.logoutUser()),
    login : (username,password) => dispatch(userDetailActions.login(username,password)),
});

export default connect(mapStateToProps,mapDispatchToProps) (LogoutScreen);