import React, { Component } from 'react'
import AddIncome from '../components/AddIncomeFormComponent'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import {secureClient} from "../config/axiosClient";
import axios from "axios";
import * as userDetailActions from "../redux/actions/userDetailActions";

class AddIncomeScreen extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : true,
            incomeTypeList: [],
            loaderFullScreen:false,
            fileUploadingData:{},
            fromScreen:false,
            fromViewScreen:false,
        }
    }

    componentDidMount() {
        let state = { ...this.props.location.state };
        if(state?.fromIncomeList || state?.fromIncomeView){
            this.setState({fromScreen:true});
        }
        if(state?.fromIncomeView){
            this.setState({fromViewScreen:true});
        }
    }

    handleOnSubmit = async (data, fileInfo, fileBase64Info) => {
        try{
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                await this.createIncome(data);
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    let attached_file = [];
                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();
                        let uploadDate = new Date();
                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                            "Content-Type" : fileInfo[i].contentType
                        },onUploadProgress:async data => { // eslint-disable-line
                            //Set the progress value to show the progress bar
                            // setProgress(Math.round((100 * data.loaded) / data.total))
                            let temp={data:data,index:i}
                            await this.setState({fileUploadingData:temp})
                        }})
                        // console.log('uploadResBody ',uploadResBody)
                        // if(uploadResBody.statusText === 'OK'){
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                file_id:fileInfo[i].file_id,
                                id:fileInfo[i].income_id,
                                dependent_id:this.props.dependent.id,
                                type:'income',
                                "name" : fileInfo[i].name,
                                "size" : fileInfo[i].size,
                                "upload_date" : uploadDate,
                                "contentType" : fileInfo[i].contentType
                            })
                            data.document_ref = {"attached_files":attached_file}
                        }
                        i++;
                    }
                    await this.createIncome(data);
                    // console.log('document_ref ===',attached_file,data)
                }else {
                }
            }

        }catch (e){
            console.error('error ......',e)
        }
    }

    onUpdateHandler = async(data,fileInfo, fileBase64Info,deletedFileInfo) =>{
        this.setState({error:false,loading:true})
        let attached_file = [];
        try{
            if(this.props.currentIncome && Object.keys(this.props.currentIncome).length > 0 && Object.keys(this.props.currentIncome.document_ref).length > 0 && this.props.currentIncome.document_ref.attached_files.length > 0){
                let attached_files_list = this.props.currentIncome.document_ref.attached_files
                let arr =[];
                if(deletedFileInfo.length > 0){
                    for await (const item of deletedFileInfo) {
                        attached_files_list = attached_files_list.filter(item_1 => item_1.file_id !== item)
                        arr = attached_files_list
                    }
                    attached_file = arr;
                    data.document_ref = {"attached_files":attached_file}
                    data.s3_delete_keys = deletedFileInfo
                }else {
                    attached_file = attached_files_list
                    data.document_ref = {"attached_files":attached_file}
                }
            }
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                await this.updateIncome(data);
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    //  console.log('if response', response.data.sr.signed_urls , typeof response.data.sr.signed_urls)
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    let progressIndex = data.document_ref && Object.keys(data.document_ref).length > 0 ? data.document_ref.attached_files.length > 0  ? data.document_ref.attached_files.length : 0 : 0;
                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();
                        let uploadDate = new Date();
                        // console.log('signed_urls ===',uploadUrl)
                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                            "Content-Type" : fileInfo[i].contentType
                        },onUploadProgress:async data => { // eslint-disable-line
                            //Set the progress value to show the progress bar
                            // setProgress(Math.round((100 * data.loaded) / data.total))
                            let temp={data:data,index:progressIndex}
                            await this.setState({fileUploadingData:temp})
                        }})
                        // console.log('uploadResBody ',uploadResBody)
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                file_id:fileInfo[i].file_id,
                                id:fileInfo[i].income_id,
                                dependent_id:this.props.dependent.id,
                                type:'income',
                                "name" : fileInfo[i].name,
                                "size" : fileInfo[i].size,
                                "upload_date" : uploadDate,
                                "contentType" : fileInfo[i].contentType
                            })
                            data.document_ref = {"attached_files":attached_file}
                        }
                        i++;
                        progressIndex++
                    }
                    await this.updateIncome(data);
                    // console.log('document_ref ===',attached_file,data)
                }else {
                }
            }

        }catch (e){
            console.error('error ......',e)
        }
    }

    updateIncome = async(data) =>{
        try {
            if(this.props.currentIncome && Object.keys(this.props.currentIncome).length !== 0){
                let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.INCOME+this.props.currentIncome.id,data);
                this.setState({loading:false})
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    await this.props.currentIncomeData(response.data.sr.income)
                    if(this.state.fromViewScreen){
                        this.props.history.push(A12Y_CONST.ROUTE.INCOME_VIEW);
                    }else{
                        this.props.history.push(A12Y_CONST.ROUTE.INCOMES);
                    }

                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                    this.setState({loading:false})
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.UPDATE_INCOME,e)
        }

    }

    createIncome = async(data) =>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.INCOMES, data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                if(!this.props.income_present){
                    let dependent = this.props.dependent
                    dependent.metadata.income_present = true
                    this.props.updateDependentProps(dependent)
                    this.props.updateMetaData(!this.props.income_present,this.props.expense_present,this.props.activity_present,this.props.asset_present)
                }
                this.props.history.push(A12Y_CONST.ROUTE.INCOMES);
                this.setState({loading:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.CREATE_INCOME,e)
        }

    }

    onBackHandler = ()=>{
        this.props.history.push(A12Y_CONST.ROUTE.INCOMES);
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    render(props) {
        const {fileUploadingData} = this.state;
        return (
            <div className="myProfileAccount animationall mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddIncome
                            loading={this.state.loading}
                            message={this.state.message}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            incomeTypeList={this.props.incomeTypeList}
                            onSubmit={this.handleOnSubmit}
                            loaderFullScreen={this.state.loaderFullScreen}
                            dependent={this.props.dependent}
                            incomeObj={this.props.currentIncome}
                            onUpdate={this.onUpdateHandler}
                            fileUploadingData={fileUploadingData}
                            onBack={this.onBackHandler}
                            isDataChanged={this.dataChangeHandler}
                            isSessionTimeout={this.props.isSessionTimeout}
                            fromScreen={this.state.fromScreen}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    currentIncome : state.dependentReducer.currentIncome,
    incomeTypeList : state.dependentReducer.incomeTypeList,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    asset_present: state.dependentReducer.asset_present,
    isSessionTimeout: state.userDetailReducer.isSessionTimeout,
});

const mapDispatchToProps = dispatch => ({
    currentIncomeData : (income) => dispatch(dependentActions.currentIncomeData(income)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});

export default connect(mapStateToProps, mapDispatchToProps) (AddIncomeScreen);
