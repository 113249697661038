import React, { useState,useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useHistory } from "react-router-dom";
import PopUpIncomeExpComponent from "./PopUpIncomeExpComponent";
import parsePhoneNumber from "libphonenumber-js";

function BeneficiaryDetailComponent(props) {

    const history = useHistory();
    const [beneficiaryList, setBeneficiaryList] = useState([]);
    const [isPopUp, setIsPopUp] = useState(false);
    const [beneficiaryDelete, setBeneficiaryDelete] = useState({})
    const [canUpdate, setCanUpdate] = useState(false);
    const [showEditOrAddIcon, setShowEditOrAddIcon] = useState(false);
    const [selectBeneficiary, setSelectBeneficiary] = useState({});

    useEffect(()=>{
        setShowEditOrAddIcon(props.showEditOrAddIcon)
    },[props.showEditOrAddIcon])


    const collapse = (elment) => {
        setBeneficiaryList([])
        let elm = document.getElementById(elment);
        if(elm !== null){
            elm.parentElement.classList.toggle('opened')
            elm.classList.toggle('heightAuto')
            
        }
       /* if(!props.currentAsset.beneficiary){*/
            props.getBeneficiaryData();
       /* }*/
    }

    useEffect(() => {
        if(Object.keys(props.currentAsset).length > 0 && props.currentAsset.beneficiary && props.currentAsset.beneficiary.length > 0) {
            setBeneficiaryList(props.currentAsset.beneficiary)
        }else{
            if(!props.currentAsset.beneficiary){
                setBeneficiaryList([])
            }
        }
        setCanUpdate(props?.dependent?.access?.can_update)

    },[props.currentAsset, props.currentAsset.beneficiary, props.dependent.access])

    const deleteBeneficary = (item) => {
        setBeneficiaryDelete(item)
        setIsPopUp(true)
    }

    const addBeneficiary = async () => {
        history.push(A12Y_CONST.ROUTE.ADD_BENEFICIARY)
    }

    const BeneficiaryUpdate = async (item) => {
        history.push({
            pathname: A12Y_CONST.ROUTE.EDIT_BENEFICIARY,
            state: {beneficiary : item,
            },
        });
    }
    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.onDelete(beneficiaryDelete)
    }
    const openBeneficiaryInfo = (beneficiairy) => {
        if(beneficiairy.id === selectBeneficiary.id){
            setSelectBeneficiary({})
        }else{
            setSelectBeneficiary(beneficiairy)
        }

    }
    const phoneNumberFormate = (number) => {
        let numberFormating ;
        const mobileNum = parsePhoneNumber('+'+number);
        const mobileNumberFormating = '+1 ' + mobileNum.formatNational()
        // setMobileNumberFormat(mobileNumberFormating)
        numberFormating = mobileNumberFormating
        return numberFormating;
    }
    return(
        <div className="inventoryBox fullWidth borderBottom">
            <div onClick={() => collapse('Beneficiary')} className="inventoryScreenTitle fullWidth positionRelative  inventory_Col_padding">
                {A12Y_CONST.LABEL.BENEFICIARY}
                {
                    !props.beneficiaryLoader && canUpdate && showEditOrAddIcon &&
                    <span id={A12Y_CONST.TEXT.ADD_BENEFICIARY_ASSET} onClick={addBeneficiary} className='cursorPointer dn'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0C8.552 0 9 0.4477 9 1V7H15C15.552 7 16 7.4477 16 8C16 8.5523 15.552 9 15 9H9V15C9 15.5523 8.552 16 8 16C7.448 16 7 15.5523 7 15V9H1C0.448 9 0 8.5523 0 8C0 7.4477 0.448 7 1 7H7V1C7 0.4477 7.448 0 8 0Z" fill="#2F6DB3"/>
                        </svg>
                    </span>
                }
                
                <div className="btnAddEditWrap df fcol jcc aic">
                    <span className='collape marginBottom20'>
                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.703768 0.684193C1.63973 -0.228064 3.16111 -0.228064 4.09707 0.684193L12 8.36722L19.9029 0.684193C20.8389 -0.228064 22.3603 -0.228064 23.2962 0.684193C24.2346 1.59412 24.2346 3.07333 23.2962 3.98326L13.6966 13.3158C12.7607 14.2281 11.2393 14.2281 10.3033 13.3158L0.703768 3.98326C-0.23459 3.07333 -0.23459 1.59412 0.703768 0.684193Z" fill="#565C65"/>
                        </svg>
                    </span>
                </div>
            </div>
            <div id='Beneficiary' className='fullWidth texAlingCener Beneficiary height0'>
                {
                    beneficiaryList.length === 0 ?
                        (
                        props.beneficiaryLoader ?
                        <div  className='positionRelative fullWidth zIndex2 df aic jcc fcol height50  fullWidth bgColor '>
                            <div className="positionRelative">
                                <div className="dependentLoader">
                                    <div className='fullWidth justifyCenter marginBottom10'>
                                        <div className="stage">
                                            <div className="dot-pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='marginTop20 fullWidth'>{A12Y_CONST.TEXT.LOADING_BENEFICIARY}</div>
                            </div>
                        </div>
                        :
                        <div className='fullWidth noDataInventory'>
                            <svg width="54" height="63" viewBox="0 0 54 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.1601 48.7239C30.1601 50.8298 30.7618 52.9358 31.9652 54.7408H0V48.047C0 36.9908 8.95025 28.0405 20.0064 28.0405H21.285C28.355 28.0405 34.9737 31.8011 38.5838 37.8933C33.6198 39.1719 30.1601 43.6847 30.1601 48.7239Z" fill="#2D6CB2"/>
                                <path d="M20.6827 27.0764C28.1597 27.0764 34.2209 21.0151 34.2209 13.5382C34.2209 6.06125 28.1597 0 20.6827 0C13.2058 0 7.14453 6.06125 7.14453 13.5382C7.14453 21.0151 13.2058 27.0764 20.6827 27.0764Z" fill="#2D6CB2"/>
                                <g clipPath="url(#clip0_8885_61958)">
                                <path d="M40.8745 57.9908C47.8888 55.4575 51.5213 47.7176 48.988 40.7033C46.4547 33.689 38.7148 30.0564 31.7005 32.5898C24.6862 35.1231 21.0537 42.863 23.587 49.8773C26.1203 56.8916 33.8602 60.5241 40.8745 57.9908Z" fill="#FBC37D"/>
                                <path d="M46.5467 50.1101C49.2062 44.4436 46.7685 37.694 41.1019 35.0345C35.4354 32.3751 28.6858 34.8128 26.0263 40.4793C23.3669 46.1459 25.8046 52.8954 31.4711 55.5549C37.1377 58.2144 43.8872 55.7767 46.5467 50.1101Z" fill="#EFAA54"/>
                                <path d="M35.4633 53.3267L35.4661 51.437C34.6073 51.3962 33.773 51.2379 33.0811 51.0114C32.502 50.8194 32.1763 50.2184 32.3233 49.6304L32.3389 49.558C32.5069 48.9123 33.1913 48.5398 33.8207 48.7554C34.4676 48.973 35.1999 49.1264 35.9782 49.1294C37.1144 49.1285 37.8943 48.6964 37.8893 47.896C37.8903 47.1351 37.2521 46.6637 35.7825 46.1582C33.6513 45.4403 32.197 44.4446 32.2012 42.5127C32.2023 40.7573 33.4389 39.3914 35.5692 38.973L35.572 37.0834L37.5214 37.0896L37.5203 38.845C38.2421 38.8768 38.8389 38.9788 39.3369 39.1082C39.9476 39.272 40.3062 39.8946 40.1497 40.5027C39.9838 41.1309 39.3343 41.5075 38.7082 41.3242C38.2398 41.1842 37.6652 41.0741 36.954 41.0719C35.6587 41.0717 35.2398 41.6242 35.2412 42.1836C35.2452 42.8423 35.9393 43.2602 37.6335 43.9018C40.0015 44.7431 40.956 45.8357 40.9534 47.6334C40.9502 49.4062 39.6925 50.9218 37.4024 51.3143L37.4007 53.3457L35.4633 53.3267Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_8885_61958">
                                <rect width="27.0148" height="27.0148" fill="white" transform="translate(19 37.1768) rotate(-19.858)"/>
                                </clipPath>
                                </defs>
                            </svg>


                        <div className="noAddressText fullWidth marginTop5">{A12Y_CONST.LABEL.NO_BENEFICIARY}</div>
                        { canUpdate && showEditOrAddIcon && <div className="noAddressDescription marginTop5 fullWidth">{A12Y_CONST.TEXT.ADD_BENEFICIARY_CONTACT_INFORMATION_MESSAGE}</div>}
                    </div>
                        )
                        :
                        <>
                            {window.outerWidth > 768 ?
                             <div className='personList padding20'>
                                <div className='person df fcol positionRelative'>
                                    {beneficiaryList && beneficiaryList.length > 0 && beneficiaryList.map(item => {
                                        return (
                                            <div className='personInfo df aic fullWidth paddingBottom15 margoinNone' key={item.id}>
                                            <span className='positionAbsouteOnly paddingleft16'>
                                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.043 0C24.875 0 32.043 7.168 32.043 16C32.043 24.832 24.875 32 16.043 32C7.21097 32 0.0429688 24.832 0.0429688 16C0.0429688 7.168 7.21097 0 16.043 0ZM6.47979 21.4719C8.77765 24.8999 12.2026 27.1029 16.043 27.1969V17.6023C12.4901 17.6651 9.08136 19.0344 6.47979 21.4719ZM16.043 14.4C17.3166 14.4 18.5374 13.8882 19.4366 12.9922C20.3374 12.0962 20.843 10.88 20.843 9.6C20.843 8.32 20.3374 7.10381 19.4366 6.20781C18.5374 5.31181 17.3166 4.8 16.043 4.8C14.7694 4.8 13.5486 5.31181 12.6494 6.20781C11.7486 7.10381 11.243 8.32 11.243 9.6C11.243 10.88 11.7486 12.0962 12.6494 12.9922C13.5486 13.8882 14.7694 14.4 16.043 14.4ZM23.9314 18.0274C26.227 19.1048 27.232 21.8021 26.1761 24.0519C25.1202 26.3017 22.4033 27.2521 20.1077 26.1748C17.8121 25.0974 16.8072 22.4002 17.8631 20.1503C18.919 17.9005 21.6359 16.9501 23.9314 18.0274Z" fill="#89B1D9"/>
                                                <path d="M21.7721 24.8135L21.773 24.1558C21.4742 24.1416 21.1838 24.0865 20.943 24.0077C20.7414 23.9409 20.6281 23.7317 20.6792 23.5271L20.6847 23.5018C20.7431 23.2771 20.9813 23.1475 21.2004 23.2225C21.4255 23.2983 21.6804 23.3516 21.9512 23.3527C22.3467 23.3524 22.6181 23.202 22.6164 22.9234C22.6167 22.6586 22.3946 22.4945 21.8832 22.3186C21.1414 22.0688 20.6353 21.7222 20.6367 21.0498C20.6371 20.4389 21.0675 19.9635 21.8089 19.8179L21.8099 19.1603L22.4884 19.1624L22.488 19.7734C22.7392 19.7844 22.9469 19.8199 23.1202 19.865C23.3327 19.922 23.4575 20.1387 23.4031 20.3503C23.3454 20.5689 23.1193 20.7 22.9014 20.6362C22.7384 20.5875 22.5384 20.5492 22.2909 20.5484C21.84 20.5484 21.6943 20.7406 21.6948 20.9353C21.6961 21.1646 21.9377 21.31 22.5274 21.5333C23.3515 21.8261 23.6837 22.2064 23.6828 22.832C23.6817 23.449 23.244 23.9765 22.4469 24.1131L22.4463 24.8201L21.7721 24.8135Z" fill="#89B1D9"/>
                                                </svg>
                                            </span>
                                                <div className='pName texAlingLeft text24'>{item.beneficiary_name}</div>
                                                <a className='pEmail texAlingLeft text24' href={`mailto:${item.email}`}>{item.email}</a>
                                                <div className='pAction mla df aic'>
                                                    { item.phones[0].number.length > 5 ?
                                                    <a href={"tel:" + item.phones[0].number}>
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.67963 1.58353L3.55605 0.707107C3.94658 0.316583 4.57974 0.316582 4.97026 0.707106L7.76658 3.50342C8.1571 3.89394 8.1571 4.52711 7.76658 4.91763L5.84261 6.8416C5.528 7.15621 5.45 7.63685 5.64898 8.03481C6.79925 10.3353 8.66465 12.2007 10.9652 13.351C11.3632 13.55 11.8438 13.472 12.1584 13.1574L14.0824 11.2334C14.4729 10.8429 15.1061 10.8429 15.4966 11.2334L18.2929 14.0297C18.6834 14.4203 18.6834 15.0534 18.2929 15.4439L17.4165 16.3204C15.3051 18.4317 11.9625 18.6693 9.57382 16.8777L7.94436 15.6556C6.20083 14.348 4.65201 12.7992 3.34436 11.0556L2.12226 9.42618C0.330722 7.03745 0.568273 3.69488 2.67963 1.58353Z" fill="#2D6CB2"/>
                                                        </svg>
                                                    </a>
                                                        :
                                                    null
                                                    }

                                                    { canUpdate && showEditOrAddIcon &&
                                                        <span id={A12Y_CONST.TEXT.EDIT_BENEFICIARY_ASSET+item.id} onClick={() => {BeneficiaryUpdate(item)}} className='actDel cursorPointer marginLeft20'>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.9924 0.30829C14.403 -0.102763 15.0703 -0.102763 15.4809 0.30829L19.6913 4.51882C20.1029 4.92987 20.1029 5.5964 19.6913 6.00745L6.00729 19.6917C5.81045 19.8891 5.54203 20 5.26309 20H1.05262C0.471257 20 0 19.5287 0 18.9474V14.7368C0 14.4577 0.110946 14.1899 0.308312 13.9925L10.8346 3.4664L13.9924 0.30829ZM11.5788 5.69914L2.10524 15.1728V17.8947H4.8273L14.3009 8.42104L11.5788 5.69914ZM15.7893 6.9324L17.4587 5.26314L14.7366 2.54124L13.0672 4.21051L15.7893 6.9324Z" fill="#2D6CB2"/>
                                                            </svg>
                                                        </span>
                                                    }
                                                    { canUpdate && showEditOrAddIcon&& <span id={A12Y_CONST.TEXT.DELETE_BENEFICIARY_ASSET+item.id} onClick={()=>{deleteBeneficary(item)}} className='actDel cursorPointer marginLeft20'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 2C5 0.8954 5.89543 0 7 0H13C14.1046 0 15 0.8954 15 2V4H16.9897C16.9959 3.9999 17.0021 3.9999 17.0083 4H19C19.5523 4 20 4.4477 20 5C20 5.5523 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.189 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.189 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.5523 0 5C0 4.4477 0.44772 4 1 4H2.99174C2.99795 3.9999 3.00414 3.9999 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#D96941"/>
                                                    </svg>
                                                    </span>}
                                                </div>
                                            </div>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                                 :
                                <div className='personList padding20'>

                                            {
                                            beneficiaryList && beneficiaryList.length > 0 && beneficiaryList.map(item => {
                                                return (
                                                    <div onClick={() => {openBeneficiaryInfo(item)}}  className="beneficiaryMain">
                                                      <div className='person df fcol positionRelative'>
                                                          <div className='personInfo df aic fullWidth paddingBottom15 margoinNone' key={item.id}>
                                                                <span className='positionAbsouteOnly paddingleft16'>
                                                                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.043 0C24.875 0 32.043 7.168 32.043 16C32.043 24.832 24.875 32 16.043 32C7.21097 32 0.0429688 24.832 0.0429688 16C0.0429688 7.168 7.21097 0 16.043 0ZM6.47979 21.4719C8.77765 24.8999 12.2026 27.1029 16.043 27.1969V17.6023C12.4901 17.6651 9.08136 19.0344 6.47979 21.4719ZM16.043 14.4C17.3166 14.4 18.5374 13.8882 19.4366 12.9922C20.3374 12.0962 20.843 10.88 20.843 9.6C20.843 8.32 20.3374 7.10381 19.4366 6.20781C18.5374 5.31181 17.3166 4.8 16.043 4.8C14.7694 4.8 13.5486 5.31181 12.6494 6.20781C11.7486 7.10381 11.243 8.32 11.243 9.6C11.243 10.88 11.7486 12.0962 12.6494 12.9922C13.5486 13.8882 14.7694 14.4 16.043 14.4ZM23.9314 18.0274C26.227 19.1048 27.232 21.8021 26.1761 24.0519C25.1202 26.3017 22.4033 27.2521 20.1077 26.1748C17.8121 25.0974 16.8072 22.4002 17.8631 20.1503C18.919 17.9005 21.6359 16.9501 23.9314 18.0274Z" fill="#89B1D9"/>
                                                                    <path d="M21.7721 24.8135L21.773 24.1558C21.4742 24.1416 21.1838 24.0865 20.943 24.0077C20.7414 23.9409 20.6281 23.7317 20.6792 23.5271L20.6847 23.5018C20.7431 23.2771 20.9813 23.1475 21.2004 23.2225C21.4255 23.2983 21.6804 23.3516 21.9512 23.3527C22.3467 23.3524 22.6181 23.202 22.6164 22.9234C22.6167 22.6586 22.3946 22.4945 21.8832 22.3186C21.1414 22.0688 20.6353 21.7222 20.6367 21.0498C20.6371 20.4389 21.0675 19.9635 21.8089 19.8179L21.8099 19.1603L22.4884 19.1624L22.488 19.7734C22.7392 19.7844 22.9469 19.8199 23.1202 19.865C23.3327 19.922 23.4575 20.1387 23.4031 20.3503C23.3454 20.5689 23.1193 20.7 22.9014 20.6362C22.7384 20.5875 22.5384 20.5492 22.2909 20.5484C21.84 20.5484 21.6943 20.7406 21.6948 20.9353C21.6961 21.1646 21.9377 21.31 22.5274 21.5333C23.3515 21.8261 23.6837 22.2064 23.6828 22.832C23.6817 23.449 23.244 23.9765 22.4469 24.1131L22.4463 24.8201L21.7721 24.8135Z" fill="#89B1D9"/>
                                                                    </svg>
                                                                </span>
                                                              <div className='pName texAlingLeft text24'>{item.beneficiary_name}</div>
                                                          </div>
                                                    </div>
                                                        {item.id === selectBeneficiary.id ?
                                                            <div className=' df fcol positionRelative peronOtherInfo'>
                                                                {item.email ?
                                                                <div className='hideDesktopShowMobile mobileEmail'>
                                                                    <a className='pEmail texAlingLeft text24' href={`mailto:${item.email}`}>
                                                                       <span className='contactIcon'>
                                                                         <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M0.6 0H11.4C11.5591 0 11.7117 0.0585316 11.8243 0.162718C11.9368 0.266905 12 0.408213 12 0.555556V9.44444C12 9.59179 11.9368 9.7331 11.8243 9.83728C11.7117 9.94147 11.5591 10 11.4 10H0.6C0.44087 10 0.288258 9.94147 0.175736 9.83728C0.0632141 9.7331 0 9.59179 0 9.44444V0.555556C0 0.408213 0.0632141 0.266905 0.175736 0.162718C0.288258 0.0585316 0.44087 0 0.6 0ZM6.036 4.82389L2.1888 1.79889L1.4118 2.64556L6.0438 6.28722L10.5924 2.64278L9.8076 1.80222L6.0366 4.82389H6.036Z" fill="#71767A"/>
                                                                            </svg>
                                                                        </span>
                                                                        {item.email}
                                                                    </a>
                                                                </div>
                                                                    :
                                                                    null
                                                                }
                                                                { item.phones[0].number.length > 5 ?
                                                                    <div className="phoneNumberMobile">
                                                                        <a href={"tel:" + item.phones[0].number}>
                                                                              <span className='contactIcon'>
                                                                                 <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M10.7597 8.07553L12.4061 9.72192C12.7341 10.0499 12.7341 10.5817 12.4061 10.9097C10.6329 12.6829 7.82564 12.8824 5.81946 11.3778L5.70251 11.29C4.18922 10.1551 2.84493 8.81078 1.70996 7.29749L1.62224 7.18054C0.117611 5.17436 0.317118 2.36709 2.09034 0.593866C2.41833 0.265883 2.95009 0.265883 3.27808 0.593867L4.92447 2.24026C5.315 2.63079 5.315 3.26395 4.92447 3.65447L3.78983 4.78912C3.5696 5.00935 3.515 5.34579 3.65429 5.62436C4.45948 7.23474 5.76526 8.54052 7.37564 9.34571C7.65421 9.485 7.99065 9.4304 8.21088 9.21017L9.34552 8.07553C9.73605 7.685 10.3692 7.685 10.7597 8.07553Z" fill="#71767A"/>
                                                                                  </svg>
                                                                               </span>
                                                                            {phoneNumberFormate(item.phones[0].number)}
                                                                        </a>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }

                                                            </div>
                                                            :
                                                            null
                                                        }
                                                   </div>
                                                )
                                            }
                                            )

                                            }

                                    </div>
                            }
                        </>
                }
            </div>
            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
}

export default BeneficiaryDetailComponent