import React, { useState, useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import InsuranceScreen from '../screens/InsuranceScreen';
import BankAccountScreen from '../screens/BankAccountScreen';
import RealEstateScreen from '../screens/RealEstateScreen';
import BusinessInterestScreen from '../screens/BusinessInterestScreen';
import PersonalPropertyScreen from "../screens/PersonalPropertyScreen";
import SafeDepositBoxScreen from "../screens/SafeDepositBoxScreen";
import InvestmentAccountScreen from "../screens/InvestmentAccountScreen";
import AutomobileScreen from "../screens/AutomobileScreen";
import RetirementAccountScreen from "../screens/RetirementAccountScreen";
import OtherScreen from "../screens/OtherScreen";
import BurialAccountScreen from "../screens/BurialAccountScreen";

function AddAssetsFormComponent(props) {
    const [assetType, setAssetType] = useState('');
    const [assetTypeError, setAssetTypeError] = useState(false);
    const [assetTypeOptionsList, setAssetTypeOptionsList] = useState([]);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [isAssetTypeVisible, setIsAssetTypeVisible] = useState(true);
    const [isInsuranceVisible, setIsInsuranceVisible] = useState(false);
    const [isBankAccountVisible, setIsBankAccountVisible] = useState(false);
    const [isRealEstateVisible, setIsRealEstateVisible] = useState(false);
    const [isBusiness_Interest, setIsBusiness_Interest] = useState(false);
    const [isPersonal_Property, setIsPersonalProperty] = useState(false);
    const [isSafeDepositBox, setIsSafeDepositBox] = useState(false);
    const [isInventmentAccount, setIsInventmentAccount] = useState(false);
    const [isBurialAccount, setIsBurialAccount] = useState(false);
    const [isAutomobile, setIsAutomobile] = useState(false);
    const [isRetirementAccount, setIsRetirementAccount] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const {  handleSubmit,  control, formState: {  isSubmitting } } = useForm({ mode: "onBlur" });
    const history = useHistory();


    useEffect(  () => {
       const setAssetTypeList = async ()=>{
           let assetTypeList = await props.assetTypeList.map(item => ({ label: item.title, value: item.title, id:item.id, asset_type_key:item.asset_type_key, status:item.status }));
           setAssetTypeOptionsList(assetTypeList)
       }
        setAssetTypeList();
    },[props.assetTypeList])

    const handleAssetTypeChange = async (e) => {
       await setAssetType(e)
        setIsBtnDisabled(e.value === '')
        props.selectedAssetType(e)
    }

    function validateAssetType(type) {
        if(type === 'blur'){
            if(assetType === ''){
                setAssetTypeError(true)
                return true
            }
        }else {
            setAssetTypeError(false)
            return false
        }
    }

    const onSubmit = async (e) =>{
        await  setIsAssetTypeVisible(false)
        setIsInsuranceVisible(false)
        setIsBankAccountVisible(false)
        setIsBusiness_Interest(false)
        setIsInventmentAccount(false)
        setIsPersonalProperty(false)
        if(Object.keys(assetType).length > 0){
           if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_LIFE_INSURANCE_KEY){
                setIsInsuranceVisible(true)
            }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_BANK_KEY){
                setIsBankAccountVisible(true)
            }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_REAL_ESTATE){
                setIsRealEstateVisible(true)
            }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_BUSINESS_INTEREST_KEY){
                setIsBusiness_Interest(true)
            }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_PERSONAL_PROPERTY_KEY){
                setIsPersonalProperty(true)
           }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_SAFE_DEPOSIT_BOX_KEY){
                setIsSafeDepositBox(true)
           }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_INVESTMENT_ACCOUNT_KEY){
                setIsInventmentAccount(true)
           }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_BURIAL_ACCOUNT_KEY){
               setIsBurialAccount(true)}
           else  if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_AUTOMOBILE_KEY){
               setIsAutomobile(true)
           }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_RETOREMENT_ACCOUNT_KEY){
               setIsRetirementAccount(true)
           }else if(assetType.asset_type_key === A12Y_CONST.TEXT.ASSET_OTHER_KEY){
               setIsOther(true)
           }
        }
    }

    const onCancel = async()=>{
            // history.goBack()
        history.push(A12Y_CONST.ROUTE.INVENTORY)
    }

    function goBack() {
        history.goBack();
    }

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addAssetResponsive addActivitiesWrap">
            <h2 className="titleWithSidebar noExpenseTitle marginBottom30">
                <span className="cursorPointer" onClick={goBack}><svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span>{A12Y_CONST.LABEL.ADD_ASSET_LABEL}</span></span>
            </h2>
            <div className="dependentBorderBox dependentDefauldMinHeight paddingBottom70 activityViewMain positionRelative">
                {
                    isAssetTypeVisible &&
                    <div className="df fcol marginBottom20 height100">
                        <h4 className="dependentSubTitle"><span className='vam'>{A12Y_CONST.LABEL.ADD_AN_ASSET_FOR} </span> <span className='txtEllipsis vam'>{props.dependent.nick_name}</span></h4>
                        <form autoComplete="off" className='marginTop30 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                            <div className={'formRow fullWidth formRowGap'}>
                                <label className="lblExpType">{A12Y_CONST.LABEL.ASSET_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div className={`fullWidth dependentResidencCol ${assetTypeError && 'selectError'} ${ assetType && Object.keys(assetType).length > 0 ? 'hasValue' : 'noValue'}`}>
                                    <Controller
                                        name="assetType"
                                        value={assetType}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={assetTypeOptionsList}
                                                value={assetTypeOptionsList.find(c => c.id === assetType.id)}
                                                onChange={handleAssetTypeChange}
                                                onFocus={() => validateAssetType('focus')}
                                                onBlur={() => validateAssetType('blur')}
                                                classNamePrefix={'dependentResidence'}
                                            />
                                        )}
                                        control={control}
                                        className="fullwidth inputColum24size"
                                        placeholder="Select"
                                    />
                                    <div className='errorRelativeRow'> {assetTypeError && <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_ASSET_TYPE}</p>}</div>
                                </div>
                            </div>

                            {/*------------------ Button Actions ---------------------*/}
                            <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom" >
                                <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.ADD_ASSET_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                                <div className='widthAuto marginLeft20'>
                                    <div className='loaderSpinner'>
                                        <input id={A12Y_CONST.BUTTON_ID.ADD_ASSET_NEXT} className={ isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={isBtnDisabled} type="submit" value={A12Y_CONST.BUTTON_TEXT.Next} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                }

                {/*--------------- Asset Form ---------------*/}
                { isInsuranceVisible && <InsuranceScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isBankAccountVisible && <BankAccountScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isRealEstateVisible && <RealEstateScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isBusiness_Interest && <BusinessInterestScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isPersonal_Property && <PersonalPropertyScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isSafeDepositBox && <SafeDepositBoxScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isInventmentAccount && <InvestmentAccountScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isAutomobile && <AutomobileScreen
                    history={history}
                /> }
                { isRetirementAccount && <RetirementAccountScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isBurialAccount && <BurialAccountScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }
                { isOther && <OtherScreen
                    history={history}
                    isClickedOutside={props.isClickedOutside}
                /> }

            </div>

        </div>
    )
}

export default AddAssetsFormComponent

