import A12Y_CONST from '../../common/a12yConst'
let initialState = {
    isLoggedIn: false,
    errMsg:'',
    code:'',
    isUpdated:false,
    isError:false,
    successMessage:'',
    isDataChanged:false,
    isSessionTimeout:false,
    isTrialReminder:false,
    IsWelcomePopUp:true,
    IsSurveyRolePopUp:false,
    IsSurvey_PopUp:false,
    isBillingScreen:false,
    isLogOutPopUP:false,
    userState : {
        access_token: null,
        refresh_token: null,
        isLoading: false,
        userInfo: {},
        error: null,
        isNotConfirmUser: null,
        id_token: null,
    },
    userSubscription:{},
    userSubscriptionAllDetails:{},
    loginAndSource:{},
    inviteCode:'',
    stripeConfig:{},
    paymentMethod:{},
    price_details:{},
    subscription:{},
    subscriptionProduct:[],
    userPlan:null,
    userRoleType:'',
    planName:'',
    signUpText:{
        title:'',
        description:'',
        otherDes:''
    },
    filterSelectedTypeList:[],
    filterSelectedTypeListIncome:[],
    filterData:{
        "activity":{startDate:"",endDate:"",type:[],tag:[], isModify:false},
        "expense": {startDate:"",endDate:"",type:[], isModify:false},
        "income": {startDate:"",endDate:"",type:[], isModify:false},
    }
}

function userDetailReducer(state = initialState, action) {
    switch (action.type) {
        case A12Y_CONST.DISPATCH_ACTION.UNAUTHORIZED:
            return { ...state, isLoggedIn: false, isSessionTimeout:true }

        case A12Y_CONST.DISPATCH_ACTION.LOGIN_START:
            return { ...state, isLoggedIn: false}

        case A12Y_CONST.DISPATCH_ACTION.LOGIN_SUCCESS:
            return { ...state, userState: action.userState , isLoggedIn : true,}

        case A12Y_CONST.DISPATCH_ACTION.LOGIN_ERROR:
            return { ...state, isLoggedIn: false , errMsg:action.errMsg, code:action.code}

        case A12Y_CONST.DISPATCH_ACTION.LOGOUT:
            return { ...state, userState: {
                    access_token: null,
                    refresh_token: null,
                    isLoading: false,
                    userInfo: {},
                    error: null,
                    isNotConfirmUser: null,
                    id_token: null,
                }, isLoggedIn: false,errMsg:'',
                code:'',
                isUpdated:false,
                isError:false,
                successMessage:'',
                isDataChanged:false,
                isSessionTimeout:false,
                isTrialReminder:false,
                IsWelcomePopUp:true,
                IsSurveyRolePopUp:false,
                IsSurvey_PopUp:false,
                isBillingScreen:false,
                isLogOutPopUP:false,
                userSubscription:{},
                userSubscriptionAllDetails:{},
                loginAndSource:{},
                inviteCode:'',
                stripeConfig:{},
                subscriptionProduct:[],
                paymentMethod:{},
                price_details:{},
                userPlan:null,
                userRoleType:'',
                planName:'',
                filterSelectedTypeList:[],
                filterSelectedTypeListIncome:[],
                filterData:{
                    "activity":{startDate:"",endDate:"",type:[],tag:[], isModify:false},
                    "expense": {startDate:"",endDate:"",type:[], isModify:false},
                    "income": {startDate:"",endDate:"",type:[], isModify:false},
                },
                signUpText:{
                    title:'',
                    description:'',
                    otherDes:''
                }
            }

        case A12Y_CONST.DISPATCH_ACTION.NOT_CONFIRM_USER:
            return { ...state, CustomerInformation: action.payload, isNotConfirmUser: true }

        case A12Y_CONST.DISPATCH_ACTION.SIGNUP_START:
             return { ...state, isLoggedIn: false, isError:false, errMsg:'', successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.SIGNUP_SUCCESS:
            return { ...state, userState: action.userState , isLoggedIn : false, isError:false, errMsg:'', successMessage:''}

        case A12Y_CONST.DISPATCH_ACTION.SIGNUP_ERROR:
            return { ...state, isLoggedIn: false , errMsg:action.errMsg, code:action.code, isError:true}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PROFILE_START:
            return { ...state, isUpdated:false , isError:false}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PROFILE_SUCCESS:
            return { ...state, userState: {...state.userState , ...state.userState.userInfo = action.userInfo} , isUpdated:true ,isError:false, successMessage:action.message }

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PROFILE_ERROR:
            return { ...state, errMsg:action.errMsg, code:action.code , isUpdated:false , isError:true}


        case A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PASSWORD_START:
            return { ...state, isUpdated:false , isError:false}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PASSWORD_SUCCESS:
            return { ...state, isUpdated:true ,isError:false, successMessage:action.message }

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PASSWORD_ERROR:
            return { ...state, errMsg:action.errMsg, code:action.code , isUpdated:false , isError:true}

        case A12Y_CONST.DISPATCH_ACTION.IS_FIELD_UPDATED:
            return { ...state, isDataChanged: action.payload.status}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_SESSION_TIMEOUT:
            return { ...state, isSessionTimeout: action.payload.isSessionTimeout}

        case A12Y_CONST.DISPATCH_ACTION.TOKEN_REFRESHED:
            let temp  = state.userState
            temp.access_token = action.refreshedToken.accessToken;
            temp.refresh_token = action.refreshedToken.refreshToken;
            temp.id_token = action.refreshedToken.idToken;
            return { ...state, userState: temp }

        case A12Y_CONST.DISPATCH_ACTION.CREATE_USER_SUBSCRIPTION_START:
            return { ...state, userSubscription: {}}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_USER_SUBSCRIPTION_SUCCESS:
            return { ...state, userSubscription: action.userSubscription, paymentMethod:action.paymentMethod, isError: false, successMessage:action.message, userPlan:action.userPlan}

        case A12Y_CONST.DISPATCH_ACTION.CREATE_USER_SUBSCRIPTION_ERROR:
            return { ...state, userSubscription: {}, paymentMethod: {}, errMsg:action.errMsg, code:action.code, isError: true}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_PAYMENT_METHOD_START:
            return { ...state}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_PAYMENT_METHOD_SUCCESS:
            return { ...state, price_details : action.price_details ,userSubscription: action.userSubscription, subscription: action.subscription,  paymentMethod:action.paymentMethod, isError: false, successMessage:action.message, planName:action.planName}

        case A12Y_CONST.DISPATCH_ACTION.UPDATE_PAYMENT_METHOD_ERROR:
            return { ...state, errMsg:action.errMsg, code:action.code, isError: true}

        case A12Y_CONST.DISPATCH_ACTION.CANCEL_SUBSCRIPTION_START:
            return { ...state}

        case A12Y_CONST.DISPATCH_ACTION.CANCEL_SUBSCRIPTION_SUCCESS:
            return { ...state, userSubscription: action.userSubscription, paymentMethod:action.paymentMethod, isError: false, successMessage:action.message}

        case A12Y_CONST.DISPATCH_ACTION.CANCEL_SUBSCRIPTION_ERROR:
            return { ...state, errMsg:action.errMsg, code:action.code, isError: true}

        case A12Y_CONST.DISPATCH_ACTION.USER_SUBSCRIPTION_SUCCESS:
            return { ...state, userSubscriptionAllDetails: action.userSubscriptionAllDetails, userSubscription: action.userSubscription, stripeConfig:action.stripeConfig, subscriptionProduct:action.subscriptionProduct, paymentMethod:action.paymentMethod, userPlan:action.userPlan}

        case A12Y_CONST.DISPATCH_ACTION.USER_ROLE_TYPE:
            return { ...state, userRoleType:action.payload.type}

        case A12Y_CONST.DISPATCH_ACTION.SIGNUP_TEXT:
            return { ...state, signUpText:action.payload.type}

        case A12Y_CONST.DISPATCH_ACTION.IS_TRIAL_REMINDER:
            return { ...state, isTrialReminder: action.payload.status}

        case A12Y_CONST.DISPATCH_ACTION.IS_WELCOME_POPUP:
            return { ...state, IsWelcomePopUp: action.payload.status}

        case A12Y_CONST.DISPATCH_ACTION.IS_SURVEY_ROLE_POPUP:
            console.log('status survey-role', action.payload.status)
            return { ...state, IsSurveyRolePopUp: action.payload.status}

        case A12Y_CONST.DISPATCH_ACTION.IS_SURVEY_POPUP:
            console.log('survey---', action.payload.status)
            return { ...state, IsSurvey_PopUp: action.payload.status}

        case A12Y_CONST.DISPATCH_ACTION.BILLING_SCREEN:
            return { ...state, isBillingScreen: action.payload.status}

        case A12Y_CONST.DISPATCH_ACTION.INVITE_CODE:
            return { ...state, inviteCode:action.payload.type}

        case A12Y_CONST.DISPATCH_ACTION.USER_SOURCE_LOGIN:
            return { ...state, loginAndSource:action.payload.type}

        case A12Y_CONST.DISPATCH_ACTION.SET_LOGOUT_POPUP_STATUS:
            return { ...state, isLogOutPopUP: action.payload.status}

        case A12Y_CONST.DISPATCH_ACTION.SELECTED_FILLTER_TYPE_LIST:
            return { ...state, filterSelectedTypeList: action.payload.type}

        case A12Y_CONST.DISPATCH_ACTION.SELECTED_FILLTER_TYPE_LIST_INCOME:
            return { ...state, filterSelectedTypeListIncome: action.payload.type}

        case A12Y_CONST.DISPATCH_ACTION.SELECTED_FILTERED:
            return { ...state, filterData: action.payload.data}

        case A12Y_CONST.DISPATCH_ACTION.USER_SUBSCRIPTION_DETAIL_UPDATE:
            return { ...state, userSubscriptionAllDetails: action.payload.data}


        default:
            return state
    }
}

export default userDetailReducer;