import React,{Component} from 'react'
import {Button, Typography, Grid} from "@material-ui/core";
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions';

import A12Y_CONST from "../common/a12yConst";

class SecureScreen extends Component {
    constructor(props){
        super(props);
        this.state={
        };

        window.document.title = A12Y_CONST.TEXT.TITLE_DASHBOARD
    }

    logoutUser(){
        this.props.logoutUser()
    }

    render(){
        return(
            <div id="main">
                <Typography variant="h4" align="center" style={{marginTop: "60px",color: "#2196F3"}}>
                    {A12Y_CONST.TEXT.HELLO} {this.props.userState.userInfo.name}
                </Typography>
                <Typography variant="h6" align="center" style={{marginTop: "20px",textAlign: 'center'}}>
                    {A12Y_CONST.TEXT.YOU_HAVE_REGISTERED_WITH_EMAIL}{A12Y_CONST.TEXT.COLON} {this.props.userState.userInfo.email}
                </Typography>
                <div style={{width: "50%", margin: "0 auto", marginTop: "40px"}}>
                    <Grid container  spacing={2} align="center" >
                        <Grid item xs={4} >

                        </Grid>
                        <Grid item xs={2} >
                            <Button onClick={()=>{this.logoutUser()}} variant="contained" color="primary" style={{backgroundColor: "#2196F3"}}>
                                {A12Y_CONST.BUTTON_TEXT.Logout}
                            </Button>
                        </Grid>

                        {/* <Grid item xs={2}>
                            <Button variant="contained" color="primary" style={{backgroundColor: "#2196F3"}}>
                                Signup
                            </Button>
                        </Grid>*/}
                        <Grid item xs={4} >

                        </Grid>
                    </Grid>
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
});

const mapDispatchToProps = dispatch => ({
    logoutUser : () => dispatch(userDetailActions.logoutUser()),
    login : (username,password) => dispatch(userDetailActions.login(username,password)),
});

export default connect(mapStateToProps,mapDispatchToProps) (SecureScreen);
