import React, { useState, useEffect}from 'react';
import A12Y_CONST from "../common/a12yConst";


function  EditChangesRemovePopup (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const hidePopUp = async () => {
      await  setIsPopUpVisible(false)
        props.hidePopUp()
    }

    useEffect(()=>{
        if(props.title){
            setTitle(props.title)
        }
        if(props.message){
            setMessage(props.message)
        }
    },[props.title,props.message])

    const resetAllFields = async () => {
       await setIsPopUpVisible(false)
        props.resetAllFields()
    }
    return (
        <div>
        {
        isPopUpVisible ?
            <div className='popTop'>
            <div className='popContainer popMaxWidth'>
                <div className='popMaster'>
                    <div className="popUpMainBg fullWidth">
                        <div className="cancelIcon fullWidth marginTopBottom20">
                            <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34 6.8C18.9788 6.8 6.8 18.9788 6.8 34C6.8 49.0212 18.9788 61.2 34 61.2C49.0212 61.2 61.2 49.0212 61.2 34C61.2 18.9788 49.0212 6.8 34 6.8ZM0 34C0 15.2218 15.2218 0 34 0C52.7782 0 68 15.2218 68 34C68 52.7782 52.7782 68 34 68C15.2218 68 0 52.7782 0 34ZM19.6962 19.6962C21.0222 18.3668 23.1778 18.3668 24.5038 19.6962L34 29.1924L43.4962 19.6962C44.8222 18.3668 46.9778 18.3668 48.3038 19.6962C49.6332 21.0222 49.6332 23.1778 48.3038 24.5038L38.8076 34L48.3038 43.4962C49.6332 44.8222 49.6332 46.9778 48.3038 48.3038C46.9778 49.6332 44.8222 49.6332 43.4962 48.3038L34 38.8076L24.5038 48.3038C23.1778 49.6332 21.0222 49.6332 19.6962 48.3038C18.3668 46.9778 18.3668 44.8222 19.6962 43.4962L29.1924 34L19.6962 24.5038C18.3668 23.1778 18.3668 21.0222 19.6962 19.6962Z" fill="#D83933"></path>
                            </svg>
                        </div>
                        <div className="areYouText areYouDmFont" id={A12Y_CONST.BUTTON_ID.TITLE_POPUP}>
                            {title ?
                                title
                                :
                                <div>{A12Y_CONST.TEXT.ARE_YOU_SURE_TEXT}</div>
                            }
                        </div>
                        <div className='useInfo marginTop10' id={A12Y_CONST.BUTTON_ID.DESCRIPTION_POPUP}>
                            {message ?
                                message
                                :
                                <div>{A12Y_CONST.LABEL.ANY_EDITS_YOU}</div>
                            }
                        </div>
                        <div className="fullWidth marginTop45 marginBottom20 texAlingCener">
                            <div id={A12Y_CONST.BUTTON_ID.YES_BUTTON} className="popUpYesButton marginLeftRight2percent" onClick={resetAllFields}>{A12Y_CONST.LABEL.YES}</div>
                            <div id={A12Y_CONST.BUTTON_ID.NO_BUTTON} className="popUpNoButton" onClick={hidePopUp}>{A12Y_CONST.LABEL.NO}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            :
            null
        }
        </div>
    )
}

export default EditChangesRemovePopup;