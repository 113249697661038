import React, {useEffect} from 'react';
import Drawer from './Drawer'
import  * as userDetailActions from '../redux/actions/userDetailActions'
import  * as dependentActions from '../redux/actions/dependentActions'
import { connect } from 'react-redux';
import A12Y_CONST from "../common/a12yConst";
import { useHistory } from "react-router-dom";
import {titleRoutePath} from "../util/titleRouteNamePath";

const LayoutWithLogin =  (props) => {
    const history = useHistory();
    const [sidebarVisible, setSidebarVisible] = React.useState(false);
    const [isDependentListVisible, setIsDependentListVisible] = React.useState(null);

    const toggleSidebar = async(status)=> {
        setSidebarVisible(status)
    }

    useEffect( ()=>{
        const initiliazePageData = async ()=>{
            let path = window.location.pathname;
            let title = await titleRoutePath(path)
            document.title =A12Y_CONST.TEXT.ADVOCORD+title;
            if(path !== '/logout'){
                await props.userSessionBackupScreenDetails(path)
            }
            if(props.dependent?.metadata?.activity_present && !props.activity_present){
              await  props.updateMetaData(props.income_present,props.expense_present,!props.activity_present,props.asset_present)
            }else if(props.dependent?.metadata?.expense_present && !props.expense_present){
              await  props.updateMetaData(props.income_present,!props.expense_present,props.activity_present,props.asset_present)
            }else if(props.dependent?.metadata?.income_present && !props.income_present){
               await props.updateMetaData(!props.income_present,props.expense_present,props.activity_present,props.asset_present)
            }
            if(props.isDocumentChecked){
                await props.checkDocumentScreen(false)
            }
        }
        initiliazePageData()
    },[window.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps


    const udpateIsDataChange = async (status) => {
        await props.dataChanged(status)
    }
    const myAccountClicked = async ()=>{
        await props.myAccountClicked(true)
    }
    const handelSwitchDependent = async (dependent)=>{
        if(Object.keys(dependent).length > 0){
            if(dependent.id !== props.dependent.id){
                await props.switchDependent(dependent)
                await props.updateMetaData(dependent.metadata.income_present,dependent.metadata.expense_present,dependent.metadata.activity_present,dependent.metadata.asset_present)
                await props.updateMedicineMetaData(dependent.metadata.medicine_present)
                await props.updateDoctorMetaData(dependent.metadata.doctor_present)
                if(dependent?.subscirption?.is_trial_expired || dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED || dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT){
                    history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW)
                }else{
                    history.push(A12Y_CONST.ROUTE.DEPENDENT)
                }
            }else {
                if(dependent.subscirption.is_trial_expired  || dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED || dependent?.subscirption?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT){
                    history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW)
                }else{
                    history.push(A12Y_CONST.ROUTE.DEPENDENT)
                }
            }
        }
    }

    const handlerDependentListViewStatus = async (status)=>{
       await setIsDependentListVisible(status)
    }


    return (
        <div onClick={()=>handlerDependentListViewStatus(false) } className={sidebarVisible?'sideBarLeftCol fullWidth sideBarVisible':'sideBarLeftCol fullWidth'}>
           <Drawer
               isDependentListVisible={isDependentListVisible}
               toggleSidebar={toggleSidebar}
               isDataChanged={props.isDataChanged}
               udpateIsDataChange = {udpateIsDataChange}
               dependent={props.dependent}
               userSubscriptionAllDetails={props.userSubscriptionAllDetails}
               userInfo={props.userInfo}
               userSubscription={props.userSubscription}
               myAccountClicked={myAccountClicked}
               dependentList={props.dependentList}
               switchDependent={handelSwitchDependent}
               dependentListViewStatus={handlerDependentListViewStatus}
               welcomePopUpStatus={props.IsWelcomePopUp}
               IsSurveyRolePopUp={props.IsSurveyRolePopUp}
               IsSurvey_PopUp={props.IsSurvey_PopUp}
           />
           {props.children}
        </div>
    )
}


const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    isDataChanged : state.userDetailReducer.isDataChanged,
    dependent : state.dependentReducer.dependent,
    userSubscriptionAllDetails : state.userDetailReducer.userSubscriptionAllDetails,
    dependentList : state.dependentReducer.dependentList,
    screen : state.dependentReducer.screen,
    userId : state.dependentReducer.userId,
    userInfo : state.userDetailReducer.userState.userInfo,
    userSubscription : state.userDetailReducer.userSubscription,
    asset_present: state.dependentReducer.asset_present,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    medicine_present : state.dependentReducer.medicine_present,
    doctor_present : state.dependentReducer.doctor_present,
    isDocumentChecked : state.dependentReducer.isDocumentChecked,
    IsWelcomePopUp : state.userDetailReducer.IsWelcomePopUp,
    IsSurveyRolePopUp : state.userDetailReducer.IsSurveyRolePopUp,
    IsSurvey_PopUp : state.userDetailReducer.IsSurvey_PopUp,
});

const mapDispatchToProps = dispatch => ({
    signUp : (userData) => dispatch(userDetailActions.signUp(userData)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    userSessionBackupScreenDetails : (screen) => dispatch(dependentActions.userSessionBackupScreenDetails(screen)),
    myAccountClicked : (status) => dispatch(dependentActions.myAccountClicked(status)),
    switchDependent : (dependent) => dispatch(dependentActions.switchDependent(dependent)),
    updateMedicineMetaData: (medicine_present) => dispatch(dependentActions.updateMedicineMetaData(medicine_present)),
    updateDoctorMetaData: (doctor_present) => dispatch(dependentActions.updateDoctorMetaData(doctor_present)),
    checkDocumentScreen : (documentStatus) => dispatch(dependentActions.checkDocumentScreen(documentStatus)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
});

export default connect(mapStateToProps,mapDispatchToProps) (LayoutWithLogin);

