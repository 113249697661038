import React, { Component } from 'react'
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import {secureClient} from "../config/axiosClient";
import AddDebt from '../components/AddDebtLiabilityFormComponent';
import * as userDetailActions from "../redux/actions/userDetailActions";
import axios from "axios";


class AddDebtScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            loaderFullScreenVisible : false,
            loaderFullScreen:false,
            fileUploadingData:{},
            debtTypeList:[],
            fromDebtViewScreen:false
        }
    }

    componentDidMount = async() => {
        let state = { ...this.props.location.state };
        if(state?.fromDebtView){
            await this.setState({fromDebtViewScreen:true})
        }
        this.setState({debtTypeList:this.props.debtTypeList})
    }

    fileUploading = async (data,fileInfo,fileBase64Info,deletedFileInfo,editMode)=>{
        this.setState({error:false,loading:true})
        let attached_file = [];
        let reqBody = {
            ref_type : "debt",
            ref_id : data.id,
            attachments : []
        }
        try{
            if(editMode && this.props.currentDebt && Object.keys(this.props.currentDebt).length > 0  && this.props.currentDebt.attachments.length > 0){
                let attached_files_list = this.props.currentDebt.attachments
                let arr =[];
                if(deletedFileInfo.length > 0){
                    for await (const item of deletedFileInfo) {
                        attached_files_list = attached_files_list.filter(item_1 => item_1.file !== item)
                        arr = attached_files_list
                    }
                    attached_file = arr;
                    reqBody.attachments = attached_files_list
                }else {
                    attached_file = attached_files_list
                    reqBody.attachments = attached_files_list
                }

            }
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({loading:true})
                if(deletedFileInfo.length > 0) {
                    await this.savingDebitFile(reqBody)
                }
                if(editMode){
                    await this.updateDebt(data)
                }else {
                    await this.createDebt(data)
                }
            }else{
                this.setState({error:false})
                this.setState({loading:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    //  console.log('if response', response.data.sr.signed_urls , typeof response.data.sr.signed_urls)
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    //let progressIndex = data.document_ref && Object.keys(data.document_ref).length > 0 ? data.document_ref.attached_files.length > 0  ? data.document_ref.attached_files.length : 0 : 0;
                    let deletedFile = deletedFileInfo.length > 0 ? deletedFileInfo.length : 0
                    let progressIndex = Object.keys(this.props.currentDebt).length > 0 && this.props.currentDebt.hasOwnProperty('attachments') ? this.props.currentDebt.attachments.length - deletedFile : 0
                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();

                        // console.log('signed_urls ===',uploadUrl)
                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                                "Content-Type" : fileInfo[i].contentType
                            },onUploadProgress:async data => { // eslint-disable-line
                                //Set the progress value to show the progress bar
                                // setProgress(Math.round((100 * data.loaded) / data.total))
                                let temp={data:data,index:progressIndex}
                                await this.setState({fileUploadingData:temp})
                                // console.log('loading Event....',data.loaded,this.state.fileUploadingData,progressIndex)
                            }})
                        // console.log('uploadResBody ',uploadResBody)
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                /* ref_type : "asset",
                                 ref_id :fileInfo[i].asset_id,*/
                                file : fileInfo[i].file_id,
                                file_name : fileInfo[i].name,
                                file_extension : fileInfo[i].contentType,
                                file_size : fileInfo[i].size
                            })
                            // data.document_ref = {"attached_files":attached_file}
                            reqBody.attachments = attached_file
                        }
                        i++;
                        progressIndex++
                    }
                    await this.savingDebitFile(reqBody)
                    if(editMode){
                        await this.updateDebt(data)
                    }else {
                        await this.createDebt(data)
                    }
                }else {
                    this.setState({error:true,loading:false,transferPopupLoader:false})
                }
            }

        }catch (e){
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false,transferPopupLoader:false})
            console.error('error ......',e)
        }

    }

    updateDebt = async(data)=>{
        try {
            let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.DEBTS+"/"+this.props.currentDebt.id,data);
            this.setState({loading:false})
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                await this.props.currentDebtData(response.data.sr.debt)
                await this.navigationHandler()
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.UPDATE_DEBT,e)
        }
    }

    createDebt = async(data) =>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+'/'+this.props.dependent.id+'/'+A12Y_CONST.API_ENDPOINT.DEBTS, data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                /*if(!this.props.debt_present){
                    this.props.updateMetaData(this.props.debt_present,!this.props.debt_present,this.props.activity_present,this.props.asset_present)
                }*/
                let dependent = this.props.dependent
                dependent.metadata.debt_present = true
                await this.props.updateDependentProps(dependent)
                await this.navigationHandler()
                this.setState({loading:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
                this.setState({loading:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.CREATE_DEBT,e)
        }

    }

    savingDebitFile = async (data)=>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ATTACHMENTS,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                // this.setState({loading:false})
                //this.redirectToInventoryView()
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,transferPopupLoader:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false,transferPopupLoader:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.SAVING_DEBT,e)
        }
    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

    navigationHandler = async ()=>{
        if(this.state.fromDebtViewScreen){
            this.props.history.push(A12Y_CONST.ROUTE.DEBT_VIEW);
        }else{
        let path = null;
        if(this.props.currentDebt !== null && Object.keys(this.props.currentDebt).length > 0 && this.props.currentDebt.hasOwnProperty('isComingFromView')){
            let debt = this.props.currentDebt;
            delete debt.isComingFromView;
            await this.props.currentDebtData(debt);
            path = A12Y_CONST.ROUTE.DEBT_VIEW
        }else {
            path = A12Y_CONST.ROUTE.INVENTORY
        }
        if(path !== null){
            this.props.history.push({
                pathname:path,
                state:{tab:A12Y_CONST.TEXT.DEBTS}
            })
        }
        }

    }

    onCancelHandler = async ()=>{
       await this.navigationHandler()
    }

    render() {
        const {fileUploadingData} = this.state;

        return (
            <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <AddDebt
                            debtTypeList={this.state.debtTypeList}
                            loading={this.state.loading}
                            message={this.state.message}
                            error={this.state.error}
                            errorMessage={this.state.errorMessage}
                            onSubmit={this.fileUploading}
                            loaderFullScreen={this.state.loaderFullScreen}
                            dependent={this.props.dependent}
                            debtObj={this.props.currentDebt}
                            onUpdate={this.fileUploading}
                            fileUploadingData={fileUploadingData}
                            isDataChanged={this.dataChangeHandler}
                            isSessionTimeout={this.props.isSessionTimeout}
                            onCancel={this.onCancelHandler}

                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    asset: state.dependentReducer.asset,
    debtTypeList: state.dependentReducer.debtTypeList,
    currentDebt: state.dependentReducer.currentDebt,
});

const mapDispatchToProps = dispatch => ({
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    currentDebtData : (debt) => dispatch(dependentActions.currentDebtData(debt)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    updateDependentProps : (dependent) => dispatch(dependentActions.updateDependentProps(dependent)),
});


export default connect(mapStateToProps, mapDispatchToProps) (AddDebtScreen)