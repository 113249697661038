import React ,{useEffect, useState}from 'react'
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import 'react-phone-input-2/lib/style.css'
import A12Y_CONST from '../common/a12yConst'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import {isValidPhoneNumber}from 'libphonenumber-js';
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import PopUpComponent from '../components/PopUpComponent'

function  DependentProfileStep4 (props) {
    const [loading, setLoading] = useState(false);
    const [error, setErrors] = useState(false);
    const [selectedPhoneType1, setSelectedPhoneType1] = useState({});
    const [selectedPhoneType2, setSelectedPhoneType2] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumber_2, setPhoneNumber_2] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber_1ErrorMessage, setPhoneNumber_1ErrorMessage] = useState('');
    const [phoneNumber_2ErrorMessage, setPhoneNumber_2ErrorMessage] = useState('');
    const [phoneNumberError, setphoneNumberError] = useState(false);
    const [phoneNumber2Error, setphoneNumber2Error] = useState(false);
    const [phoneType1LabelActive, setPhoneType1LabelActive] = useState(false);
    const [phoneType2LabelActive, setPhoneType2LabelActive] = useState(false);
    const [isPhone2DropdownSelect, setIsPhone2DropdownSelect] = useState(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [anyChanges, setAnychanges] = useState(false);
    const [isCancelButton, setIsCancelButton] = useState(false)
    const [popUpMessage, setPopUpMessage] = useState('');
    const [popUpButtonText1, setPopUpButtonText1] = useState('');
    const [popUpButtonText2, setPopUpButtonText2] = useState('');
    const [popUpType, setpopUpType] = useState('');
    const {register,handleSubmit,setError, setFocus ,formState:{ errors, isSubmitting},setValue} = useForm({
        mode:"all"
    });

    const onSubmit = async (user) => {
        let IsPhone1DropdownSelected = false;
        let IsPhone2DropdownSelected = false;
        let phoneError = false
        let phoneList = [{type:selectedPhoneType1.label?selectedPhoneType1.label.toLowerCase():'',number:(phoneNumber === '1' || phoneNumber === '') ? '' : phoneNumber},{type:selectedPhoneType2.label?selectedPhoneType2.label.toLowerCase():'',number:(phoneNumber_2 === '1' || phoneNumber_2 === '') ? '' : phoneNumber_2}]
           /* if(Object.keys(selectedPhoneType1).length > 0 && phoneNumber){
                setphoneNumberError(false)
                setPhoneNumber_1ErrorMessage('')
                onBlurEventHandlerPhone()
            }else if(Object.keys(selectedPhoneType1).length === 0 && phoneNumber){
                onBlurEventHandlerPhone()
                if(!phoneNumberError){
                    setphoneNumberError(true)
                    setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                    return;
                }
            }

        if(Object.keys(selectedPhoneType2).length > 0 && phoneNumber_2){
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            onBlurEventHandlerPhone2()
            }else if(Object.keys(selectedPhoneType2).length === 0 && phoneNumber_2){
            onBlurEventHandlerPhone2()
                if(!phoneNumber2Error){
                    setphoneNumber2Error(true)
                    setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                    return;
                }
            }*/
        await onBlurEventHandlerPhone();
        if(phoneNumber.length > 1 &&  Object.keys(selectedPhoneType1).length === 0 ){
            phoneError = true
            setphoneNumberError(true)
            setIsPhone2DropdownSelect(true)
            IsPhone1DropdownSelected = true
            setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType1).length > 0 && (phoneNumber === "" || phoneNumber === "1")){
                phoneError = true
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone1DropdownSelected = false
        }

        await onBlurEventHandlerPhone2();
        if(phoneNumber_2.length > 1 &&  Object.keys(selectedPhoneType2).length === 0 ){
            phoneError = true
            setIsPhone2DropdownSelect(true)
            IsPhone2DropdownSelected = true
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType2).length > 0 && (phoneNumber_2 === "" || phoneNumber_2 === "1")){
                phoneError = true
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone2DropdownSelected = false
        }
        if(phoneNumberError || phoneNumber2Error || IsPhone2DropdownSelected ||IsPhone1DropdownSelected ||  phoneError){
            return
        }

        if(!phoneNumberError && !phoneNumber2Error){
            user.phone = JSON.stringify(phoneList)
            user.email = JSON.stringify({email:user.email})
            props.onSubmit(4,user)
            props.isDataChanged(false)
        }

    }
    const onCancel = async()=>{
        props.isDataChanged(false)
        if(anyChanges){
            setIsCancelButton(true)
            setPopUpMessage(A12Y_CONST.LABEL.ANY_EDITS_YOU)
            setPopUpButtonText1(A12Y_CONST.BUTTON_TEXT.YES)
            setPopUpButtonText2(A12Y_CONST.BUTTON_TEXT.NO)
            setpopUpType('Cancel')
            setIsPopUp(true)

            setIsPopUp(true)
        }else {
            setIsCancelButton(false)
            props.onCancel(3)
        }
    }

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
    },[props.loading,props.error])

    useEffect(()=>{
        if(props.dependent){
            assignDefaultValue()
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const assignDefaultValue = ()=>{
        if(props.dependent.email){
            setValue("email",props.dependent.email.email)
            setEmail(props.dependent.email.email)
        }
        if(props.dependent.phone && props.dependent.phone.length > 0) {
            props.dependent.phone.forEach((phone,index)=>{
                if(index === 0){
                    if(phone.type === 'mobile'){
                        setSelectedPhoneType1({value:'Mobile' , label:'Mobile'})
                    }else if(phone.type === 'office'){
                        setSelectedPhoneType1({value:'Office' , label:'Office'})
                    }else if(phone.type === 'home'){
                        setSelectedPhoneType1({value:'Home' , label:'Home'})
                    }else if(phone.type === 'other'){
                        setSelectedPhoneType1({value:'Other' , label:'Other'})
                    }
                    if(phone.number.length > 0){
                        setPhoneNumber(phone.number)
                        setPhoneType1LabelActive(true)
                    }else {
                        setPhoneNumber('')
                    }
                }else {
                    if(phone.type === 'mobile'){
                        setSelectedPhoneType2({value:'Mobile' , label:'Mobile'})
                    }else if(phone.type === 'office'){
                        setSelectedPhoneType2({value:'Office' , label:'Office'})
                    }else if(phone.type === 'home'){
                        setSelectedPhoneType2({value:'Home' , label:'Home'})
                    }else if(phone.type === 'other'){
                        setSelectedPhoneType2({value:'Other' , label:'Other'})
                    }
                    if(phone.number.length > 0){
                        setPhoneNumber_2(phone.number)
                        setPhoneType2LabelActive(true)
                    }else {
                        setPhoneNumber_2('')
                    }
                }
            })
        }
    }

    const phoneTypeList = [
        {
            value: 'mobile',
            label: 'Mobile',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#2D6CB2"></path>
            </svg>
        },
        {
            value: 'office',
            label: 'Office',
            icon:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'home',
            label: 'Home',
            icon: <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'other',
            label: 'Other',
            icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#71767A" style={{strokeWidth:2}} ></path>
            </svg>,
            iconActive: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#0297C8" style={{strokeWidth:2}}></path>
            </svg>
        }
    ];

    const handleChangePhoneType1 = async e => {
        await  setSelectedPhoneType1(e);
    }
    const handleChangePhoneType2 = e => {
        setSelectedPhoneType2(e);
    }
    const handlePhoneField = e => {
        if(e.length > 1){
            setPhoneType1LabelActive(true)
        }else{
            setPhoneType1LabelActive(false);
        }
        setPhoneNumber(e)
    }
    const onBlurEventHandlerPhone = () => {
        if(phoneNumber.length > 1){
            const isValid =  validatePhoneNumber(phoneNumber)
            if(!isValid){
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
            }else {
                setphoneNumberError(false)
                setPhoneNumber_1ErrorMessage('')
            }
        }
    }

    const handlePhoneField_2 = e => {
        if(e.length > 1){
            setPhoneType2LabelActive(true)
        }else{
            setPhoneType2LabelActive(false);
        }
        setPhoneNumber_2(e)
    }
    const onBlurEventHandlerPhone2 = () => {
        const isValid =  validatePhoneNumber(phoneNumber_2)
        if(phoneNumber_2.length > 1 && !isValid){
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }
    }

    const validatePhoneNumber = (e) => {
        const validNumber = '+'+e.toString()
        const phoneNumber = isValidPhoneNumber(validNumber)
        return phoneNumber
    }
    const phoneTypeOptions = phoneTypeList && phoneTypeList.map(phoneTypeList =>{
        return {label:phoneTypeList.label, value: phoneTypeList.label}
    })

    const handleEmail = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setEmail(value);
        }
    }

    const onFinish = () =>{
        // setIsPopUp(true)
        if(anyChanges){
            setPopUpMessage(A12Y_CONST.LABEL.ANY_UNSAVED_INFORMATION)
            setPopUpButtonText1(A12Y_CONST.BUTTON_TEXT.DELETE)
            setPopUpButtonText2(A12Y_CONST.BUTTON_TEXT.CANCEL)
            setpopUpType('delete')
            setIsPopUp(true)
        }else{
            handleDeleteButton()
        }
    }
    useEffect(() => {
        setFocus("email");
    }, [setFocus])

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        if(isCancelButton){
            props.onCancel(3)
        }else{
        setIsPopUp(false)
        props.onFinish()
        }
    }

    useEffect(  () => {
        const checkIsFormDataChanged = async ()=>{
            await setAnychanges(false)
            props.isDataChanged(false)
            if(email.length > 0 || (phoneNumber !=="" && phoneNumber !=="1") || (phoneNumber_2 !=="" && phoneNumber_2 !=="1")|| Object.keys(selectedPhoneType1).length > 0 || Object.keys(selectedPhoneType2).length > 0){
                await setAnychanges(true)
                props.isDataChanged(true)
            }
        }

        checkIsFormDataChanged()
    },[email, phoneNumber, phoneNumber_2, selectedPhoneType1, selectedPhoneType2]) // eslint-disable-line react-hooks/exhaustive-deps

        return (
        <div className="dependentBorderBox step4Main">
            <div className="dependentSubTitle marginBottom10">
                {props.displayName}’s {A12Y_CONST.LABEL.DEPENDENT_STEP_LABEL} {props.step} {A12Y_CONST.LABEL.DEPENDENT_TOTAL_SCREEN}
            </div>
            <div className="fullWidth dependentStep3FormCol">
                {loading ?
                    <div className="transparentLayer"></div>
                    :
                    null
                }
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
            <form onSubmit={handleSubmit(onSubmit)} className='paddingTop30' autoComplete="off">
            <div className="fullWidth dependentStep3FormCol">
                <div className='dependentStepFormRow fullWidth dependentRowSpace positionRelative'>
                    <label className='labelCol emailStep4'>{A12Y_CONST.LABEL.DEPENDENT_EMAIL}</label>
                    <div className='fullWidth'>
                        <input
                            {...register('email',{
                                pattern:{
                                    //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                    //eslint-disable-next-line
                                    value:/^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_USERNAME_INVALID
                                }
                            })}
                            placeholder='name@example.com'
                            className={errors.email ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                            type='text'
                            tabIndex={1}
                            id='displayName'
                            autoComplete="none"
                            onChange={(e) => handleEmail(e.target.value)}
                            value={email}
                            onFocus={()=>{
                                setError("email", {
                                    type: "manual",
                                    message: "",
                                });
                            }}
                        />
                        <div className='errorRelativeRow'> {errors.email && <p className='errorMessage'>{errors.email.message}</p>}</div>
                    </div>
                </div>
                <div className='dependentStepFormRow fullWidth dependentRowSpace positionRelative'>
                    <label className='labelCol phoneStep4'>{A12Y_CONST.LABEL.DEPENDENT_PHONE}</label>
                    <div className='fullWidth'>
                        <div className='width50 paddingRight16 selectBoxParend myProfilePhoneCol1_600'>
                            <div className={(selectedPhoneType1 && Object.keys(selectedPhoneType1).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsoutePhoneLabel positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                <Select
                                    classesName='phoneTypeLabel'
                                    classNamePrefix={phoneType1LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                    placeholder="Select"
                                    value={selectedPhoneType1.label ? selectedPhoneType1 : ""}
                                    options={phoneTypeOptions}
                                    tabIndex={2}
                                    onChange={handleChangePhoneType1}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: 'red',
                                            primary: '#0096C708',
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <PhoneInput
                                    country='us'
                                    countryCodeEditable={false}
                                    containerClass={phoneType1LabelActive ? phoneNumberError? 'errorInputBorderRedInput phoneNumber_Active':'phoneNumber_Active' : phoneNumberError? 'errorInputBorderRedInput': null}
                                    value={phoneNumber}
                                    tabIndex={3}
                                    onBlur={onBlurEventHandlerPhone}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    onChange={phone => {handlePhoneField(phone)}}
                                    onFocus={()=>{setphoneNumberError(false)}}

                                />
                            </div>
                            <div className='errorRelativeRow'> {phoneNumberError ? <p className='errorMessage'>{phoneNumber_1ErrorMessage}</p> : null }</div>
                        </div>
                        <div className='width48 marginTop768_10 floatRight paddingleft16 selectBoxParend myProfilePhoneCol2_600'>
                            <div className={(selectedPhoneType2 && Object.keys(selectedPhoneType2).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsoutePhoneLabel positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                <Select
                                    classesName='phoneTypeLabel'
                                    classNamePrefix={phoneType2LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                    placeholder="Select"
                                    value={selectedPhoneType2.label ? selectedPhoneType2 : ""}
                                    options={phoneTypeOptions}
                                    tabIndex={4}
                                    onChange={handleChangePhoneType2}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            text: 'red',
                                            primary: '#0096C708',
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <PhoneInput
                                    country='us'
                                    countryCodeEditable={false}
                                    // containerClass={phoneType2LabelActive ? 'phoneNumber_Active': null}
                                    containerClass={phoneType2LabelActive ? phoneNumber2Error? 'errorInputBorderRedInput phoneNumber_Active':'phoneNumber_Active' : phoneNumber2Error? 'errorInputBorderRedInput': null}
                                    placeholder='Select'
                                    value={phoneNumber_2}
                                    onBlur={onBlurEventHandlerPhone2}
                                    tabIndex={5}
                                    inputProps={{
                                        name: 'phone1',
                                        required: false,
                                        autoFocus: false
                                    }}
                                    onChange={phone => {handlePhoneField_2(phone)}}
                                    onFocus={()=>{setphoneNumber2Error(false)}}
                                />
                            </div>
                            <div className='errorRelativeRow'> {phoneNumber2Error || isPhone2DropdownSelect? <p className='errorMessage'>{phoneNumber_2ErrorMessage}</p> : null }</div>
                        </div>

                    </div>
                </div>
                <div className="fullWidth textAlignRight dependentButtonRowBottom displayFlexCol" >
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_STEP4BACK} className={!isSubmitting ? "cancelButton1 buttonlaerUp" :'cancelButton1 buttonlaerUp'} onClick={onCancel}  type="button" value={A12Y_CONST.BUTTON_TEXT.DEPENDENT_BACK}/></div>
                    <div className='widthAuto marginButton buttonRightMargin'>
                        <div className='loaderSpinner'>
                            {loading ?
                                <Loading />
                                :
                                null
                            }
                            <input id={A12Y_CONST.BUTTON_ID.DEPENDENT_PROFILE_STEP4FINISHED} className={loading ? "saveButtonDisable buttonlaerUp" :'saveButton buttonlaerUp'} disabled={loading}  type="submit" value={A12Y_CONST.BUTTON_TEXT.DEPENDENT_FINISH}/>
                        </div>
                    </div>
                    <div className='positionAbsoute fullWidth texAlingCener dependentOnboardLaterText'><span onClick={onFinish}>{A12Y_CONST.LABEL.DEPENDENT_PROFILE_BOTTOM_TEXT_START} {props.displayName}’s {A12Y_CONST.LABEL.DEPENDENT_PROFILE_BOTTOM_TEXT_END} later.</span></div>
                </div>

            </div>
            </form>
            </div>
            <PopUpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={popUpMessage}
                buttonDeleteTxt={popUpButtonText1}
                buttonCancelTxt={popUpButtonText2}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
                popUpType={popUpType}
            />
        </div>
    )
}

export default  DependentProfileStep4;