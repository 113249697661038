import {secureClient} from "../config/axiosClient";
import A12Y_CONST from '../common/a12yConst'

export async function postNotes(dependentId, data) {
    let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId+"/"+A12Y_CONST.API_ENDPOINT.NOTES,data);
    return response;
}

export async function getNotes(dependentId, data, type) {
    let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId+'/notes?note_type='+type+'&ref_id='+data);
    return response;
}

export async function updateNote(dependentId, noteID, data) {
    let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId+"/"+A12Y_CONST.API_ENDPOINT.NOTES+'/'+noteID,data);
    return response;
}
export async function deleteNote(dependentId, noteID) {
    let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+dependentId+"/"+A12Y_CONST.API_ENDPOINT.NOTES+'/'+noteID);
    return response;
}



