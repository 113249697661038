/* eslint-disable */
import React, { useState, useEffect}from 'react';
import A12Y_CONST from '../common/a12yConst'
import Loading from "./LoadingComponent";
import {useForm} from "react-hook-form";

function  PopUpDocumentComponent (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
    const [updatedFolder, setUpdatedFolder] = useState({})
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [folderName, setFolderName] = useState('');
    const [error, setErrors] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const {register, handleSubmit, setFocus,setError, reset, formState:{ errors, isDirty, isValid, isSubmitting},setValue} = useForm({mode:"all"});

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    useEffect(() => {
        if(isPopUpVisible && props.popUpType === A12Y_CONST.LABEL.NEW_FOLDER) {
            setFocus("folderName");
        }
    }, [setFocus, isPopUpVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(props.popUpType){
            if (props.popUpType === A12Y_CONST.LABEL.NEW_FOLDER){
                setTitle(A12Y_CONST.LABEL.NEW_FOLDER)
                setMessage(props.PopUpMessage)
                setFolderName('')
            }
            if (props.popUpType === A12Y_CONST.TEXT.RENAME){
                if(props.selectedItem.is_folder){
                    setTitle('Rename Folder')
                }else{
                    setTitle('Rename File')
                }

                setFolderName(props.selectedItem.display_name)
            }
        }

    },[props.title,props.message, props.popUpType, props.selectedItem]) // eslint-disable-line react-hooks/exhaustive-deps


    const hidePopUp = async () => {

        props.hidePopUp()
        await setFolderName('')
       await setValue('folderName','')
        reset({
            folderName: ""
        })
    }
    const onSubmit = async () => {
        if(!error.folderName){
            if (props.popUpType === A12Y_CONST.TEXT.RENAME){
                let obj = {
                    id : props.selectedItem.id,
                    display_name : folderName
                }
                await setUpdatedFolder(obj)
                props.itemRename(obj)
                await setFolderName('')
            }else{
                props.createNewFolder(folderName)
                await setFolderName('')
            }

            setValue('folderName','')
        }

    }

    const handlefolderName = (value) => {
        if(value.length >= 1){
            setIsBtnDisabled(true)
        }else{
            setIsBtnDisabled(false)
        }
        if(value.length === 1 && value === " "){
            return
        }else{
            setValue("folderName",value)
            setFolderName(value);
        }
    }

    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop documentPopUp'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                { props.popUpType === A12Y_CONST.LABEL.NEW_FOLDER || props.popUpType === A12Y_CONST.TEXT.RENAME ?
                                    <div className="popUpMainBg fullWidth">
                                        <div className="cancelIcon fullWidth marginBottom10 marginTop30">
                                            {props.popUpType === A12Y_CONST.LABEL.NEW_FOLDER ?
                                                <svg width="58" height="52" viewBox="0 0 58 52" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M30.1743 6.16667H54.5013C55.2528 6.16667 55.9734 6.46518 56.5048 6.99653C57.0361 7.52788 57.3346 8.24855 57.3346 9V48.6667C57.3346 49.4181 57.0361 50.1388 56.5048 50.6701C55.9734 51.2015 55.2528 51.5 54.5013 51.5H3.5013C2.74986 51.5 2.02919 51.2015 1.49783 50.6701C0.96648 50.1388 0.667969 49.4181 0.667969 48.6667V3.33333C0.667969 2.58189 0.96648 1.86122 1.49783 1.32986C2.02919 0.798511 2.74986 0.5 3.5013 0.5H24.5076L30.1743 6.16667ZM6.33464 6.16667V45.8333H51.668V11.8333H27.8283L22.1616 6.16667H6.33464ZM26.168 26V17.5H31.8346V26H40.3346V31.6667H31.8346V40.1667H26.168V31.6667H17.668V26H26.168Z"
                                                        fill="#112E53"/>
                                                    <path
                                                        d="M26.168 17.5V26H17.668V31.6667H26.168V40.1667H31.8346V31.6667H40.3346V26H31.8346V17.5H26.168Z"
                                                        fill="#F2AA50"/>
                                                </svg>
                                                :
                                                null
                                            }
                                        </div>
                                        <div id={A12Y_CONST.BUTTON_ID.DOCUMENT_POPUP_TITLE} className={`areYouText marginBottom30 ${props.popUpType === A12Y_CONST.TEXT.RENAME? 'reNameTitle' : null}`}>
                                            {title}
                                        </div>
                                        <form autoComplete="off" className='emailVerficationMaxWidth documentFolder' onSubmit={handleSubmit(onSubmit)}>

                                            <div className={'positionRelative marginBottom30 maxWidth465 marginAuto'}>
                                                <input
                                                    {...register('folderName', { required: A12Y_CONST.ERROR_MESSAGE.FOLDERNAME , pattern:{
                                                            value:/^.{1,}$/,
                                                            //value:/^[^\s]+(\s+[^\s]+)*.{3,}$/,
                                                            message:A12Y_CONST.ERROR_MESSAGE.FOLDERNAME
                                                        }})}
                                                    placeholder='Untitled Folder'
                                                    className={errors.folderName ? 'inputColum errorInputBorderRed': 'inputColum'}
                                                    type='text'
                                                    maxLength='255'
                                                    id='folderName'
                                                    onChange={(e) => handlefolderName(e.target.value)}
                                                    value={folderName}
                                                    onFocus={()=>{
                                                        setError("folderName", {
                                                            type: "manual",
                                                            message: "",
                                                        });
                                                    }}
                                                />
                                                <div className='errorRelativeRow'>{errors.folderName && <p className='errorMessage'>{errors.folderName.message}</p>}</div>
                                            </div>
                                            <div className="fullWidth textAlignRight marginTop30 documentPopUpButtonRow displayFlexCol" >
                                                <div className='widthAuto cancelButtonCol'><input id={A12Y_CONST.BUTTON_ID.DOCUMENT_POPUP_CANCEL} className={!isSubmitting ? "cancelButton1" :'cancelButton1'} onClick={hidePopUp} type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL}/></div>
                                                <div className='widthAuto createButtonCol marginLeft20'>
                                                    <div className='loaderSpinner'>
                                                        {loading ?
                                                            <Loading />
                                                            :
                                                            null
                                                        }
                                                        <input id={props.popUpType === A12Y_CONST.TEXT.RENAME ?A12Y_CONST.BUTTON_ID.DOCUMENT_POPUP_RENAME:A12Y_CONST
                                                            .BUTTON_ID.DOCUMENT_POPUP_CREATE} className={loading || folderName === '' || folderName === undefined || folderName === props?.selectedItem?.display_name ? "saveButtonDisable" :'popUpNoButton'} disabled={loading|| folderName === '' || folderName === props?.selectedItem?.display_name }  type="submit" value={props.popUpType === A12Y_CONST.TEXT.RENAME ?  A12Y_CONST.TEXT.RENAME : A12Y_CONST.BUTTON_TEXT.CREATE}/>
                                                    </div>
                                                </div>
                                            </div>



                                        </form>
                                    </div>
                                    :
                                    null
                                }
                                {
                                    props.popUpType === A12Y_CONST.LABEL.DOCUMENT_UNABLE_TO_REMOVE ?
                                        <>
                                            <div className="popUpMainBg fullWidth">
                                                <div className="cancelIcon fullWidth marginBottom30 marginTop30">
                                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16 12.8V3.2C16 2.336 16.3361 1.53613 16.9377 0.928125C17.5361 0.352125 18.352 0 19.2 0H44.8C45.648 0 46.4639 0.352125 47.0623 0.928125C47.6639 1.53613 48 2.336 48 3.2V12.8H64V19.2H57.6V60.8C57.6 61.664 57.2639 62.4639 56.6623 63.0719C56.0639 63.6479 55.248 64 54.4 64H9.6C8.752 64 7.9361 63.6479 7.3377 63.0719C6.7361 62.4639 6.4 61.664 6.4 60.8V19.2H0V12.8H16ZM36.5248 38.4L42.1824 32.7359L37.6576 28.2242L32 33.8883L26.3424 28.2242L21.8176 32.7359L27.4752 38.4L21.8176 44.0641L26.3424 48.5758L32 42.9117L37.6576 48.5758L42.1824 44.0641L36.5248 38.4ZM22.4 6.4V12.8H41.6V6.4H22.4Z" fill="#D83933"/>
                                                    </svg>
                                                </div>
                                                <div className="areYouText paddingBottom10">
                                                    {A12Y_CONST.TEXT.CANNOT_DELETE_FOLDER}
                                                </div>
                                                <div className="popUpInviteUserMessage">
                                                    {A12Y_CONST.TEXT.YOU_HAVE_TO_DELETE_ALL}
                                                </div>
                                                <div className='fullWidth marginTop20 marginBottom40 texAlingCener'>
                                                    <input id='signInButton' className={'button maxWidth138 borderRadius4 height44'} onClick={hidePopUp} type="button" value={A12Y_CONST.BUTTON_TEXT.OK}/>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpDocumentComponent;