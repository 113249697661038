import React, { useState,useEffect } from 'react'
import { useHistory } from "react-router-dom";
import A12Y_CONST from "../common/a12yConst";
import PopUpIncomeExpComponent from "./PopUpIncomeExpComponent";
import parsePhoneNumber from "libphonenumber-js";

function ContactInformationDetailComponent(props) {

    const [contactList, setContactList] = useState([]);
    const [isPopUp, setIsPopUp] = useState(false);
    const [contactData, setContactData] = useState({});
    const history = useHistory();
    const [canUpdate, setCanUpdate] = useState(false);
    const [showEditOrAddIcon, setShowEditOrAddIcon] = useState(false);
    const [selectContact, setSelectContact] = useState({});

    useEffect(()=>{
        setShowEditOrAddIcon(props.showEditOrAddIcon)
    },[props.showEditOrAddIcon])


    const collapse = (elment) => {
        setContactList([])
        let elm = document.getElementById(elment);
        if(elm !== null){
            elm.parentElement.classList.toggle('opened')
            elm.classList.toggle('heightAuto')
            
        }
        /*if(!props.currentAsset.contact){*/
            props.getContactDeatils()
        /*}*/
    }

    useEffect(()=>{
        if(Object.keys(props.currentAsset).length > 0 && props.currentAsset.contacts && props.currentAsset.contacts.length > 0){
            setContactList(props.currentAsset.contacts)
        }else {
            if(!props.currentAsset.hasOwnProperty('contacts')){
                setContactList([])
            }
        }
        setCanUpdate(props?.dependent?.access?.can_update)

    },[props.currentAsset,props.currentAsset.contacts, props.dependent.access])

    const deleteContact = (contact) => {
        setContactData(contact)
        setIsPopUp(true)
    }

    const addContact = async () => {
        history.push(A12Y_CONST.ROUTE.ADD_CONTACT)
    }

    const goToEditContactScreen = async (item)=>{
        history.push({
            pathname:A12Y_CONST.ROUTE.EDIT_CONTACT,
            state:{contact:item}
        })
    }

    const hidePopUp = () => {
        setIsPopUp(false)
    }
    const handleDeleteButton = () => {
        setIsPopUp(false)
        props.onDelete(contactData)
    }

    const openContactInfo = (contact) => {
        if(contact.id === selectContact.id){
            setSelectContact({})
        }else{
            setSelectContact(contact)
        }

    }
    const phoneNumberFormate = (number) => {
        let numberFormating ;
        const mobileNum = parsePhoneNumber('+'+number);
        const mobileNumberFormating = '+1 ' + mobileNum.formatNational()
        // setMobileNumberFormat(mobileNumberFormating)
             numberFormating = mobileNumberFormating
            return numberFormating;
    }
    return(
        <div className="inventoryBox fullWidth borderBottom">
           {/* {props.error ?
                <div className='maxWidth600 marginLeftRightAuto'>
                    <ErrorMessageComponent errorMessage={props.errorMessage} />
                </div>
                :
                (props.message ?
                        <SuccessMessageComponent successMessage={props.message} />

                        :
                        null
                )

            }*/}
            <div onClick={() => collapse('Contact')} className="inventoryScreenTitle fullWidth positionRelative  inventory_Col_padding">
                <span className='df aic'>
                {A12Y_CONST.LABEL.CONTACT_INFORMATION}
                { 
                    !props.contactsLoader && canUpdate && showEditOrAddIcon &&
                    <span id={A12Y_CONST.TEXT.ADD_CONTACT_ASSET} onClick={addContact} className='cursorPointer marginLeft20'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0C8.552 0 9 0.4477 9 1V7H15C15.552 7 16 7.4477 16 8C16 8.5523 15.552 9 15 9H9V15C9 15.5523 8.552 16 8 16C7.448 16 7 15.5523 7 15V9H1C0.448 9 0 8.5523 0 8C0 7.4477 0.448 7 1 7H7V1C7 0.4477 7.448 0 8 0Z" fill="#2F6DB3"/>
                        </svg>
                    </span>
                }
                </span>

                <div className="btnAddEditWrap df fcol jcc aic">
                    <span className='collape marginBottom20'>
                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.703768 0.684193C1.63973 -0.228064 3.16111 -0.228064 4.09707 0.684193L12 8.36722L19.9029 0.684193C20.8389 -0.228064 22.3603 -0.228064 23.2962 0.684193C24.2346 1.59412 24.2346 3.07333 23.2962 3.98326L13.6966 13.3158C12.7607 14.2281 11.2393 14.2281 10.3033 13.3158L0.703768 3.98326C-0.23459 3.07333 -0.23459 1.59412 0.703768 0.684193Z" fill="#565C65"/>
                        </svg>
                    </span>
                </div>
            </div>
            <div id='Contact' className='fullWidth contactBox texAlingCener height0'>
                {
                    contactList.length === 0 ?
                        (
                        props.contactsLoader ?
                        <div  className='positionRelative zIndex2 df aic jcc fcol height50  fullWidth bgColor'>
                                <div className="positionRelative">
                                    <div className="dependentLoader">
                                        <div className='fullWidth justifyCenter marginBottom10'>
                                            <div className="stage">
                                                <div className="dot-pulse"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='marginTop20 fullWidth'>{A12Y_CONST.TEXT.LOADING_CONTACT_INFORMATION}</div>
                                </div>
                        </div>
                        :
                        <div className='noDataInventory fullWidth'>
                            <svg width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M45.3333 54H8C5.87733 54 3.84266 53.1468 2.34399 51.6267C0.842661 50.1093 0 48.0492 0 45.9V8.1C0 5.9508 0.842661 3.89069 2.34399 2.37329C3.84266 0.853194 5.87733 0 8 0H45.3333C46.04 0 46.7199 0.283498 47.2186 0.791098C47.7199 1.296 48 1.9845 48 2.7V51.3C48 52.0155 47.7199 52.704 47.2186 53.2089C46.7199 53.7165 46.04 54 45.3333 54ZM42.6667 48.6V43.2H8C7.29333 43.2 6.61333 43.4835 6.11467 43.9911C5.61333 44.496 5.33333 45.1845 5.33333 45.9C5.33333 46.6155 5.61333 47.304 6.11467 47.8089C6.61333 48.3165 7.29333 48.6 8 48.6H42.6667ZM24 21.6C25.4133 21.6 26.7707 21.0303 27.7707 20.0178C28.7707 19.0053 29.3333 17.631 29.3333 16.2C29.3333 14.769 28.7707 13.3947 27.7707 12.3822C26.7707 11.3697 25.4133 10.8 24 10.8C22.5867 10.8 21.2293 11.3697 20.2293 12.3822C19.2293 13.3947 18.6667 14.769 18.6667 16.2C18.6667 17.631 19.2293 19.0053 20.2293 20.0178C21.2293 21.0303 22.5867 21.6 24 21.6ZM16 32.4H32C32 30.2508 31.1573 28.1907 29.656 26.6733C28.1573 25.1532 26.1227 24.3 24 24.3C21.8773 24.3 19.8427 25.1532 18.344 26.6733C16.8427 28.1907 16 30.2508 16 32.4Z" fill="#2D6CB2"/>
                            </svg>
                            <div className="noAddressText fullWidth marginTop5">{A12Y_CONST.TEXT.NO_CONTACT}</div>
                            { canUpdate && showEditOrAddIcon && <div className="noAddressDescription marginTop5 fullWidth">{A12Y_CONST.TEXT.ADD_CONTACT_INFORMATION}</div>}
                        </div>
                        )
                        :

                        (contactList && contactList.length > 0 && 
                            <>
                            {window.outerWidth > 768 ?
                             <div className='personList padding20 positionRelative'>
                            {    
                                contactList.map((contact,key)=>(
                                <div key={key} className='person df fcol positionRelative'>
                                <div className='personInfo df fullWidth'>
                                    <span className='positionAbsouteOnly paddingleft16'>
                                        <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.6096 31.4045L16 35L12.3904 31.4045C5.29603 29.7818 0 23.45 0 15.9091C0 7.12727 7.168 0 16 0C24.832 0 32 7.12727 32 15.9091C32 23.45 26.704 29.7818 19.6096 31.4045ZM6.43682 21.3499C8.78562 24.834 12.312 27.0455 16.256 27.0455C20.1984 27.0455 23.7264 24.834 26.0736 21.3499C23.4112 18.8681 19.9008 17.5 16.256 17.5C12.6112 17.5 9.10082 18.8681 6.43682 21.3499ZM16 14.3182C17.2736 14.3182 18.4944 13.8093 19.3936 12.9184C20.2944 12.0275 20.8 10.8182 20.8 9.54545C20.8 8.27273 20.2944 7.06345 19.3936 6.17254C18.4944 5.28163 17.2736 4.77273 16 4.77273C14.7264 4.77273 13.5056 5.28163 12.6064 6.17254C11.7056 7.06345 11.2 8.27273 11.2 9.54545C11.2 10.8182 11.7056 12.0275 12.6064 12.9184C13.5056 13.8093 14.7264 14.3182 16 14.3182Z" fill="#89B1D9"/>
                                        </svg>
                                    </span>
                                    <div className='pName texAlingLeft text24'>{contact.contact_name}</div>
                                    <a className='pEmail texAlingLeft text24' href={`mailto:${contact.email}`}>{contact.email}</a>
                                    <div className='pAction mla df aic'>
                                        {contact.phones !== null && (contact.phones[0].number !== '' || contact.phones[1].number !== '') ?
                                            <a href={"tel:" + contact.phones[0].number}>
                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.67963 1.58353L3.55605 0.707107C3.94658 0.316583 4.57974 0.316582 4.97026 0.707106L7.76658 3.50342C8.1571 3.89394 8.1571 4.52711 7.76658 4.91763L5.84261 6.8416C5.528 7.15621 5.45 7.63685 5.64898 8.03481C6.79925 10.3353 8.66465 12.2007 10.9652 13.351C11.3632 13.55 11.8438 13.472 12.1584 13.1574L14.0824 11.2334C14.4729 10.8429 15.1061 10.8429 15.4966 11.2334L18.2929 14.0297C18.6834 14.4203 18.6834 15.0534 18.2929 15.4439L17.4165 16.3204C15.3051 18.4317 11.9625 18.6693 9.57382 16.8777L7.94436 15.6556C6.20083 14.348 4.65201 12.7992 3.34436 11.0556L2.12226 9.42618C0.330722 7.03745 0.568273 3.69488 2.67963 1.58353Z" fill="#2D6CB2"/>
                                                </svg>
                                            </a>
                                            :
                                            null
                                        }
                                    { canUpdate && showEditOrAddIcon &&
                                            <span id={A12Y_CONST.TEXT.EDIT_CONTACT_ASSET+key} onClick={()=>{goToEditContactScreen(contact)}} className='actDel cursorPointer marginLeft20'>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.9924 0.30829C14.403 -0.102763 15.0703 -0.102763 15.4809 0.30829L19.6913 4.51882C20.1029 4.92987 20.1029 5.5964 19.6913 6.00745L6.00729 19.6917C5.81045 19.8891 5.54203 20 5.26309 20H1.05262C0.471257 20 0 19.5287 0 18.9474V14.7368C0 14.4577 0.110946 14.1899 0.308312 13.9925L10.8346 3.4664L13.9924 0.30829ZM11.5788 5.69914L2.10524 15.1728V17.8947H4.8273L14.3009 8.42104L11.5788 5.69914ZM15.7893 6.9324L17.4587 5.26314L14.7366 2.54124L13.0672 4.21051L15.7893 6.9324Z" fill="#2D6CB2"/>
                                                </svg>
                                            </span>
                                    } 
                                        {canUpdate && showEditOrAddIcon &&
                                        <span id={A12Y_CONST.TEXT.DELETE_CONTACT_ASSET+key} onClick={()=>{deleteContact(contact)}} className='actDel cursorPointer marginLeft20'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 2C5 0.8954 5.89543 0 7 0H13C14.1046 0 15 0.8954 15 2V4H16.9897C16.9959 3.9999 17.0021 3.9999 17.0083 4H19C19.5523 4 20 4.4477 20 5C20 5.5523 19.5523 6 19 6H17.9311L17.0638 18.1425C16.989 19.189 16.1182 20 15.0689 20H4.93112C3.88184 20 3.01096 19.189 2.9362 18.1425L2.06888 6H1C0.44772 6 0 5.5523 0 5C0 4.4477 0.44772 4 1 4H2.99174C2.99795 3.9999 3.00414 3.9999 3.01032 4H5V2ZM7 4H13V2H7V4ZM4.07398 6L4.93112 18H15.0689L15.926 6H4.07398ZM8 8C8.5523 8 9 8.4477 9 9V15C9 15.5523 8.5523 16 8 16C7.44772 16 7 15.5523 7 15V9C7 8.4477 7.44772 8 8 8ZM12 8C12.5523 8 13 8.4477 13 9V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V9C11 8.4477 11.4477 8 12 8Z" fill="#D96941"/>
                                            </svg>
                                        </span>}
                                    </div>
                                </div>
                                {contact.address && Object.keys(contact.address).length > 0 ?
                                    <div className='pAddress texAlingLeft marginTop5'>
                                        {(contact.address.line1 ? contact.address.line1 +', ' : '') +''+ (contact.address.line2 ? contact.address.line2 +', ' : '') +''+(contact.address.city ? contact.address.city+', ' : '') +''+(contact.address.state  ? contact.address.state+', ' : '')+''+(contact.address.zip ? contact.address.zip : '')}
                                    </div>
                                    :
                                    null
                                }
                            </div>        
                            ))}
                            </div>
                            :
                             <div className='personList padding20 positionRelative'>
                                        {
                                            contactList.map((contact,key)=>(
                                               <div className='personMain'  onClick={() => {openContactInfo(contact)}} key={key} >
                                                <div className='person df fcol positionRelative'>
                                                    <div className='personInfo df fullWidth'>
                                                        <span className='positionAbsouteOnly paddingleft16'>
                                                            <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19.6096 31.4045L16 35L12.3904 31.4045C5.29603 29.7818 0 23.45 0 15.9091C0 7.12727 7.168 0 16 0C24.832 0 32 7.12727 32 15.9091C32 23.45 26.704 29.7818 19.6096 31.4045ZM6.43682 21.3499C8.78562 24.834 12.312 27.0455 16.256 27.0455C20.1984 27.0455 23.7264 24.834 26.0736 21.3499C23.4112 18.8681 19.9008 17.5 16.256 17.5C12.6112 17.5 9.10082 18.8681 6.43682 21.3499ZM16 14.3182C17.2736 14.3182 18.4944 13.8093 19.3936 12.9184C20.2944 12.0275 20.8 10.8182 20.8 9.54545C20.8 8.27273 20.2944 7.06345 19.3936 6.17254C18.4944 5.28163 17.2736 4.77273 16 4.77273C14.7264 4.77273 13.5056 5.28163 12.6064 6.17254C11.7056 7.06345 11.2 8.27273 11.2 9.54545C11.2 10.8182 11.7056 12.0275 12.6064 12.9184C13.5056 13.8093 14.7264 14.3182 16 14.3182Z" fill="#89B1D9"/>
                                                            </svg>
                                                        </span>
                                                        <div className='pName texAlingLeft text24'>
                                                            <span>{contact.contact_name}11</span>
                                                            {/*<div className='institutionNameCol fullWidth hideDesktopShowMobile'>{props?.currentAsset?.institute?.institution_name && props?.currentAsset?.institute?.institution_name}</div>*/}
                                                        </div>
                                                    </div>
                                                </div>

                                                     {contact.id === selectContact.id ?
                                                         <div className=' df fcol positionRelative peronOtherInfo'>
                                                             {contact.email ?
                                                             <div className='hideDesktopShowMobile mobileEmail'>
                                                                 <a className='pEmail texAlingLeft text24' href={`mailto:${contact.email}`}>
                                                                     <span className='contactIcon'>
                                                                         <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M0.6 0H11.4C11.5591 0 11.7117 0.0585316 11.8243 0.162718C11.9368 0.266905 12 0.408213 12 0.555556V9.44444C12 9.59179 11.9368 9.7331 11.8243 9.83728C11.7117 9.94147 11.5591 10 11.4 10H0.6C0.44087 10 0.288258 9.94147 0.175736 9.83728C0.0632141 9.7331 0 9.59179 0 9.44444V0.555556C0 0.408213 0.0632141 0.266905 0.175736 0.162718C0.288258 0.0585316 0.44087 0 0.6 0ZM6.036 4.82389L2.1888 1.79889L1.4118 2.64556L6.0438 6.28722L10.5924 2.64278L9.8076 1.80222L6.0366 4.82389H6.036Z" fill="#71767A"/>
                                                                            </svg>
                                                                     </span>
                                                                     {contact.email}
                                                                 </a>
                                                             </div>
                                                             :
                                                             null
                                                             }
                                                             {contact.phones !== null && (contact.phones[0].number !== '' || contact.phones[1].number !== '') ?
                                                             <div className="phoneNumberMobile">
                                                                 <a href={"tel:" + contact.phones[0].number}>
                                                                 <span className='contactIcon'>
                                                                     <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M10.7597 8.07553L12.4061 9.72192C12.7341 10.0499 12.7341 10.5817 12.4061 10.9097C10.6329 12.6829 7.82564 12.8824 5.81946 11.3778L5.70251 11.29C4.18922 10.1551 2.84493 8.81078 1.70996 7.29749L1.62224 7.18054C0.117611 5.17436 0.317118 2.36709 2.09034 0.593866C2.41833 0.265883 2.95009 0.265883 3.27808 0.593867L4.92447 2.24026C5.315 2.63079 5.315 3.26395 4.92447 3.65447L3.78983 4.78912C3.5696 5.00935 3.515 5.34579 3.65429 5.62436C4.45948 7.23474 5.76526 8.54052 7.37564 9.34571C7.65421 9.485 7.99065 9.4304 8.21088 9.21017L9.34552 8.07553C9.73605 7.685 10.3692 7.685 10.7597 8.07553Z" fill="#71767A"/>
                                                                      </svg>
                                                                 </span>
                                                                 {contact.phones[0].number !== ''? phoneNumberFormate(contact.phones[0].number): phoneNumberFormate(contact.phones[1].number)}
                                                                 </a>
                                                             </div>
                                                             :
                                                             null}
                                                             {contact.address && Object.keys(contact.address).length > 0 ?
                                                                 <div className='pAddress texAlingLeft marginTop5'>
                                                                     <span className='contactIcon'><svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.60838 13.9909C7.98164 13.2706 12.4158 10.6045 12.4158 6.2079C12.4158 2.77937 9.63643 0 6.2079 0C2.77937 0 0 2.77937 0 6.2079C0 10.6045 4.43416 13.2706 5.80743 13.9909C6.05969 14.1232 6.35611 14.1232 6.60838 13.9909ZM6.20823 8.86876C7.6776 8.86876 8.86876 7.6776 8.86876 6.20823C8.86876 4.73886 7.6776 3.5477 6.20823 3.5477C4.73886 3.5477 3.54771 4.73886 3.54771 6.20823C3.54771 7.6776 4.73886 8.86876 6.20823 8.86876Z" fill="#71767A"/>
                                                                        </svg>
                                                                     </span>
                                                                     {(contact.address.line1 ? contact.address.line1 +', ' : '') +''+ (contact.address.line2 ? contact.address.line2 +', ' : '') +''+(contact.address.city ? contact.address.city+', ' : '') +''+(contact.address.state  ? contact.address.state+', ' : '')+''+(contact.address.zip ? contact.address.zip : '')}
                                                                 </div>
                                                                 :
                                                                 null
                                                             }
                                                         </div>
                                                         :
                                                         null
                                                     }

                                               </div>
                                            ))}
                                    </div>

                            }
                            </>
                        )
                }
            </div>
            <PopUpIncomeExpComponent
                popUpStatus={isPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_TEXT}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />
        </div>
    )
}

export default ContactInformationDetailComponent