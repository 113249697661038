import React, { Component } from 'react'
import { connect } from 'react-redux';
import {secureClient} from "../config/axiosClient";
import  * as userDetailActions from '../redux/actions/userDetailActions'
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from '../common/a12yConst'
import moment from 'moment';
import InventoryListComponent from '../components/InventoryListComponent';
import axios from "axios";
import WelcomeComponent from "../components/WelcomeComponent";

class InventoryScreen extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            currentScreen :0,
            loading:false,
            loadingAsset:false,
            loadingTransfer:false,
            loadingDebt:false,
            error:false,
            transferError:false,
            message:'',
            errorMessage:'',
            isAssetAvailable: false,
            inventoryList : [],
            loaderFullScreenVisible : true,
            assetList: [],
            transferList: [],
            debtList: [],
            showTransferPopup:false,
            transferPopupLoader:false,
            fileUploadingData:{},
            tabStatus:null,
            selectedTab:null,
            buttonTitle:(this.props.dependent?.metadata?.asset_present || this.props?.dependent?.metadata?.transfer_present || this.props.dependent?.metadata?.debt_present) && this.props.asset_present === false ? A12Y_CONST.BUTTON_TEXT.BACK_TO_INVENTORY : A12Y_CONST.BUTTON_TEXT.MANAGE_INVENTORIES
        }
    }

    componentDidMount = async() => {
        let state = { ...this.props.location.state };
        if(state?.ladingPageVisible){
            // await this.goToInventoryOverviewHandler()
            this.props.updateMetaData(this.props.income_present,this.props.expense_present,this.props.activity_present,false)
            if(this.props?.dependent?.metadata?.asset_present){
                await this.setState({buttonTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_INVENTORY})
                await this.onSearchHandler();
            }else{
                await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.MANAGE_INVENTORIES})
            }

        }
        if(state?.ladingPageVisible){
            state.ladingPageVisible = false;
        }
        this.setState({loading:true,loadingAsset:true})
        if (this.props.location.state && this.props.location.state.tab) {
            let state = { ...this.props.location.state };
            let tab = null;
            if(state.tab === A12Y_CONST.TEXT.DEBTS){
                tab = A12Y_CONST.TEXT.DEBTS
            }if(state.tab === A12Y_CONST.TEXT.TRANSFER){
                tab = A12Y_CONST.TEXT.TRANSFER
            }
            this.setState({tabStatus:tab})
            delete state.tab;
            this.props.history.replace({...this.props.location, state})
        }
       // await this.props.getAssetTypeList()
        await this.props.currentAssetData({})
        await this.props.updateSessionTimeout(false)
        await this.props.dataChanged(false)
        if(this.props.assetTypeList.length === 0 || this.props.assetTypeList[0] === undefined){
            await this.getAssetTyp()
        }
        if(this.props.debtTypeList === undefined || this.props.debtTypeList.length === 0 ){
            await this.props.getDebtTypeList();
        }

        if(this.props.asset_present){
            // Get clarified about initial Filter for Asset data
            await this.onSearchHandler()
        }else {
            this.setState({loaderFullScreenVisible:false,loading:false,loadingAsset:false})
        }
    }

    getAssetTyp = async () => {
        await this.props.getAssetTypeList();
       // this.setState({loading:false})
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,loaderFullScreen: false})
        }else {
            this.setState({message:this.props.successMessage,loaderFullScreen: false})
        }
    }

    onSearchHandler = async(date1,date2)=>{
        this.setState({loading:true,loadingAsset:true,errorMessage:'',error:false})
        let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
             this.setState({assetList:response.data.sr.assets,loaderFullScreenVisible:false,loading:false,loadingAsset:false})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false,loadingAsset:false})
            await this.hideMessage();
        }
    }

    getTransferList = async()=>{
        if(this.state.selectedTab !== true || this.state.selectedTab === null){
            this.setState({loading:true,loadingTransfer:true,errorMessage:'',error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.TRANSFERED_ASSETS);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({transferList:response.data.sr.transfers,loaderFullScreenVisible:false,loading:false,loadingTransfer:false,selectedTab:true})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false,loadingTransfer:false})
            }
            await this.hideMessage();
        }
    }

    getDebtList = async()=>{
        if(this.state.selectedTab !== false || this.state.selectedTab === null){
            this.setState({loading:true, loadingDebt:true, errorMessage:'',error:false})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.DEBTS);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({debtList:response.data.sr.debts,loaderFullScreenVisible:false,loading:false,loadingDebt:false,selectedTab:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false,loadingDebt:false})
            }
            await this.hideMessage();
        }
    }



    onDeleteHandler = async(asset,eventType)=>{
        this.setState({errorMessage:'',error:false})
        if(eventType === A12Y_CONST.TEXT.ASSET){
            this.setState({loadingAsset:true})
        }if(eventType === A12Y_CONST.TEXT.TRANSFER){
            this.setState({loadingTransfer:true})
        }if(eventType === A12Y_CONST.TEXT.DEBTS){
            this.setState({loadingDebt:true})
        }
        let baseUrl = A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/";
        let url = eventType === A12Y_CONST.TEXT.ASSET ? baseUrl + A12Y_CONST.API_ENDPOINT.ASSETS+"/"+asset.id : eventType === A12Y_CONST.TEXT.TRANSFER ? baseUrl + A12Y_CONST.API_ENDPOINT.ASSETS+"/"+asset.asset_id+"/"+A12Y_CONST.API_ENDPOINT.TRANSFER+"/"+asset.id : baseUrl + A12Y_CONST.API_ENDPOINT.DEBTS+"/"+asset.id
        let response = await secureClient.delete(url);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
           let list = eventType === A12Y_CONST.TEXT.ASSET ? this.state.assetList : eventType === A12Y_CONST.TEXT.TRANSFER ? this.state.transferList : this.state.debtList;
            let dataList = await list.filter(item => item.id !== asset.id)
            if(eventType === A12Y_CONST.TEXT.ASSET){
                this.setState({assetList:dataList, loadingAsset:false })
            }if(eventType === A12Y_CONST.TEXT.TRANSFER){
                this.setState({transferList:dataList,loadingTransfer:false})
            }if(eventType === A12Y_CONST.TEXT.DEBTS){
                this.setState({debtList:dataList,loadingDebt:false})
            }
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }

    hideMessage = async ()=>{
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }

    dateFormat = async(date)=>{
      return  await moment(date).format("MM/DD/YYYY")
    }

    onEditHandler = async(asset) =>{
        await this.props.currentAssetData(asset)
        this.props.history.push(A12Y_CONST.ROUTE.ADD_ASSET);
    }

    onCreateHandler = async() =>{
        await this.props.currentAssetData({})
        this.props.history.push(A12Y_CONST.ROUTE.ADD_ASSET);
    }

    onViewHandler = async(asset,type) =>{
        let state = {type: type};
        let obj = {}
        if(type === A12Y_CONST.TEXT.ASSET){
            obj = asset;
        }if(type === A12Y_CONST.TEXT.TRANSFER){
            obj.transfer = asset;
        }
        await this.props.currentAssetData(obj)
        this.props.history.push({
            pathname:A12Y_CONST.ROUTE.INVENTORY_VIEW,
            state:state
        });

    }

    saveTransfer = async (obj)=>{
        this.setState({transferPopupLoader:true,transferError:false})
        let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.TRANSFER,obj);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
           // let dataList = await this.state.assetList.filter(item => item.id !== asset.id)
            this.setState({transferPopupLoader:false,showTransferPopup:false,  loading:true},()=>{
                this.setState({loading:false})
            })
            await this.onSearchHandler();
        }else {
            this.setState({transferError:true,errorMessage:response.data.sr?.a12yErr?.message,transferPopupLoader:false, loading:false})
        }
    }

    onTransferClickHandler = async (asset) =>{
        await this.props.currentAssetData(asset);
        this.setState({showTransferPopup:true})
    }

    saveTransferHandling = async (data,fileInfo,fileBase64Info,deletedFileInfo)=>{
        await this.fileUploading(data,fileInfo,fileBase64Info,deletedFileInfo)
    }

    fileUploading = async (data,fileInfo,fileBase64Info,deletedFileInfo)=>{
        this.setState({error:false,loading:false,transferPopupLoader:true})
        let attached_file = [];
        let reqBody = {
            ref_type : "transfer",
            ref_id : data.id,
            attachments : []
        }
        try{
           /* if(this.props.currentAsset && Object.keys(this.props.currentAsset).length > 0  && this.props.currentAsset.hasOwnProperty('transfer') && this.props.currentAsset.attachments.length > 0){
                let attached_files_list = this.props.currentAsset.transfer.attachments
                let arr =[];
                if(deletedFileInfo.length > 0){
                    for await (const item of deletedFileInfo) {
                        attached_files_list = attached_files_list.filter(item_1 => item_1.file !== item)
                        arr = attached_files_list
                    }
                    attached_file = arr;
                    reqBody.attachments = attached_files_list
                }else {
                    attached_file = attached_files_list
                    reqBody.attachments = attached_files_list
                }

            }*/
            if(fileInfo.length < 1){
                this.setState({error:false})
                this.setState({transferPopupLoader:true})
                if(deletedFileInfo.length > 0) {
                    await this.savingAssetFile(reqBody)
                }
                await this.saveTransfer(data)
            }else{
                this.setState({error:false})
                this.setState({transferPopupLoader:true})
                let obj = {}
                obj = {'files' : fileInfo}
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.DOCS_MULTI_S3_SIGN_URL_PUT, obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let signed_urls = Object.keys(response.data.sr.signed_urls)
                    let i = 0;
                    //let progressIndex = data.document_ref && Object.keys(data.document_ref).length > 0 ? data.document_ref.attached_files.length > 0  ? data.document_ref.attached_files.length : 0 : 0;
                    //let progressIndex = Object.keys(this.props.currentAsset).length > 0 && Object.keys(this.props.currentAsset.transfer).length > 0 && this.props.currentAsset.transfer.hasOwnProperty('attachments') ? this.props.currentAsset.transfer.attachments.length : 0
                    let progressIndex = 0
                    for await (const item of signed_urls) {
                        let uploadUrl = response.data.sr.signed_urls[item];
                        let filterData = await fileBase64Info.filter(a =>a.s3_key === fileInfo[i].s3_key) // eslint-disable-line
                        let fileResp = await fetch(filterData[0].base64);
                        let Blobdata = await fileResp.blob();

                        let uploadResBody = await axios.put(uploadUrl,Blobdata , {headers:{
                                "Content-Type" : fileInfo[i].contentType
                            },onUploadProgress:async data => { // eslint-disable-line
                                //Set the progress value to show the progress bar
                                // setProgress(Math.round((100 * data.loaded) / data.total))
                                let temp={data:data,index:progressIndex}
                                await this.setState({fileUploadingData:temp})
                            }})
                        if(uploadResBody.status === 200){
                            attached_file.push({
                                /* ref_type : "asset",
                                 ref_id :fileInfo[i].asset_id,*/
                                file : fileInfo[i].file_id,
                                file_name : fileInfo[i].name,
                                file_extension : fileInfo[i].contentType,
                                file_size : fileInfo[i].size
                            })
                            // data.document_ref = {"attached_files":attached_file}
                            reqBody.attachments = attached_file
                        }
                        i++;
                        progressIndex++
                    }
                    await this.savingAssetFile(reqBody)
                    await this.saveTransfer(data)
                }else {
                    this.setState({transferError:true,loading:false,transferPopupLoader:false})
                }
            }

        }catch (e){
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false,transferPopupLoader:false})
            console.error('error ......',e)
        }

    }

    savingAssetFile = async (data)=>{
        try {
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ATTACHMENTS,data);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({loading:false})
                //this.redirectToInventoryView()
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,transferPopupLoader:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false,transferPopupLoader:false})
        }
    }

    dataChangeHandler = async (status)=>{
       await this.props.dataChanged(status)
    }

    onAddDebtHandler = async () => {
        await this.props.currentDebtData({})
        await this.props.history.push(A12Y_CONST.ROUTE.ADD_DEBT);
    }
    onEditDebtHandler = async (item) => {
        await this.props.currentDebtData(item)
        await this.props.history.push(A12Y_CONST.ROUTE.EDIT_DEBT);
    }

    onViewDebtDetailsHandler = async (item)=>{
        await this.props.currentDebtData(item)
        await this.props.history.push(A12Y_CONST.ROUTE.DEBT_VIEW);
    }

    goToInventoryOverviewHandler = async (currentTab)=>{
        await this.props.currentTabName(currentTab)
        this.setState({buttonTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_INVENTORY})
        this.props.updateMetaData(this.props.income_present,this.props.expense_present,this.props.activity_present,!this.props.asset_present)
    }

    landingPageBtnEvent = async ()=>{
        if(this.props.dependent?.metadata?.asset_present || this.props?.dependent?.metadata?.transfer_present || this.props.dependent?.metadata?.debt_present){
            this.props.updateMetaData(this.props.income_present,this.props.expense_present,this.props.activity_present,!this.props.asset_present)
            let tab = null;
            if(this.props.tabName ===  A12Y_CONST.TEXT.ASSET){
                this.setState({tabStatus:tab})
                await this.onSearchHandler()
            }else {
                if(this.props.tabName === A12Y_CONST.TEXT.DEBTS){
                    tab = A12Y_CONST.TEXT.DEBTS
                }if(this.props.tabName === A12Y_CONST.TEXT.TRANSFER){
                    tab = A12Y_CONST.TEXT.TRANSFER
                }
                this.setState({tabStatus:tab})
            }
        }else {
            await this.props.currentAssetData({})
            this.props.history.push(A12Y_CONST.ROUTE.ADD_ASSET);
        }
    }

    componentWillUnmount= async ()=> {
        if(!this.props.asset_present && (this.props.dependent?.metadata?.asset_present || this.props?.dependent?.metadata?.transfer_present || this.props.dependent?.metadata?.debt_present)){
            this.props.updateMetaData(this.props.income_present,this.props.expense_present,this.props.activity_present,!this.props.asset_present)
            let tab = null;
            if(this.props.tabName ===  A12Y_CONST.TEXT.ASSET){
                this.setState({tabStatus:tab})
                await this.onSearchHandler()
            }else {
                if(this.props.tabName === A12Y_CONST.TEXT.DEBTS){
                    tab = A12Y_CONST.TEXT.DEBTS
                }if(this.props.tabName === A12Y_CONST.TEXT.TRANSFER){
                    tab = A12Y_CONST.TEXT.TRANSFER
                }
                this.setState({tabStatus:tab})
            }
        }
    }
    hideErrorOnCancle = async () =>{
        this.setState({showTransferPopup:false})
        this.setState({transferError:false,errorMessage:''})
    }

    render() {

        return (
            <div className="fullWidth">
                        {
                                this.props.asset_present ?
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor assetListScreenMain mainRightSidebare">
                                        <div className='rightPanelWidhtSidebar'>
                                                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                                                    <InventoryListComponent
                                                        inventoryList={this.state.inventoryList}
                                                        assetList={this.state.assetList}
                                                        assetTypeList={this.props.assetTypeList}
                                                        debtTypeList={this.props.debtTypeList}
                                                        transferList={this.state.transferList}
                                                        debtList={this.state.debtList}
                                                        message={this.state.message}
                                                        error={this.state.error}
                                                        userInfo={this.props.userState.userInfo}
                                                        errorMessage={this.state.errorMessage}
                                                        onSearch={this.onSearchHandler}
                                                        onDelete={this.onDeleteHandler}
                                                        onEdit={this.onEditHandler}
                                                        onCreate={this.onCreateHandler}
                                                        onAddDebt={this.onAddDebtHandler}
                                                        loading={this.state.loading}
                                                        dependent={this.props.dependent}
                                                        onView={this.onViewHandler}
                                                        saveTransfer={this.saveTransferHandling}
                                                        onTransferClick={this.onTransferClickHandler}
                                                        currentAsset={this.props.currentAsset}
                                                        showTransferPopup={this.state.showTransferPopup}
                                                        onHideTransferPopUp={this.hideErrorOnCancle}
                                                        transferPopupLoader={this.state.transferPopupLoader}
                                                        getTransferData={this.getTransferList}
                                                        fileUploadingData ={this.state.fileUploadingData}
                                                        isDataChanged={this.dataChangeHandler}
                                                        getDebtList={this.getDebtList}
                                                        onEditDebt={this.onEditDebtHandler}
                                                        onViewDebtDetails={this.onViewDebtDetailsHandler}
                                                        tabStatus={this.state.tabStatus}
                                                        getAssetList={()=>this.setState({selectedTab:null})}
                                                        goToInventoryOverview={this.goToInventoryOverviewHandler}
                                                        transferError={this.state.transferError}
                                                        loadingAsset={this.state.loadingAsset}
                                                        loadingTransfer={this.state.loadingTransfer}
                                                        loadingDebt={this.state.loadingDebt}
                                                    />
                                                    </div>
                                            </div>
                                        </div>
                                        :
                                        // <NoAssets
                                        //     dependent={this.props.dependent}
                                        //     onCreate={this.onCreateHandler}
                                        // />
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare welcomeScreen">
                                        <div className='rightPanelWidhtSidebar bgWhite fullWidth marginTop12'>
                                            <div className="rightPanelWidhtSidebarContainer welcomeBg fullWidth marginNone">
                                                <WelcomeComponent
                                                    screenName={A12Y_CONST.LABEL.INVENTORY}
                                                    title={A12Y_CONST.TEXT.INVENTORY_MAIN_TITLE.replace('#dependentName#', this.props.dependent.nick_name)}
                                                    description1={A12Y_CONST.TEXT.INVENTORY_DESCRIPTION1.replace('#dependentName#', this.props.dependent.nick_name)}
                                                    // description2={A12Y_CONST.TEXT.IN_THIS_SECTION_YOU_CAN_LOG}
                                                    descriptionTitle={A12Y_CONST.TEXT.INVENTORY_DESCRIPTIONLIST_TITLE}
                                                    descriptionList_1={A12Y_CONST.TEXT.INVENTORY_DESCRIPTIONLIST_1.replace('#dependentName#', this.props.dependent.nick_name)}
                                                    descriptionList_2={A12Y_CONST.TEXT.INVENTORY_DESCRIPTIONLIST_2}
                                                    descriptionList_3={A12Y_CONST.TEXT.INVENTORY_DESCRIPTIONLIST_3}
                                                    dependent={this.props.dependent}
                                                    onCreate={this.landingPageBtnEvent}
                                                    welcomeScreenType={A12Y_CONST.LABEL.INVENTORY}
                                                    buttonTitle={this.state.buttonTitle}
                                                />
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>

        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
    dependent : state.dependentReducer.dependent,
    assetTypeList: state.dependentReducer.assetTypeList,
    asset_present: state.dependentReducer.asset_present,
    currentAsset: state.dependentReducer.currentAsset,
    currentDebt: state.dependentReducer.currentDebt,
    debtTypeList: state.dependentReducer.debtTypeList,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    tabName: state.dependentReducer.tabName,
    dependentList: state.dependentReducer.dependentList,

});

const mapDispatchToProps = dispatch => ({
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
    setUserPreferences : (startDate,endDate,dependent,dependentList) => dispatch(dependentActions.setUserPreferences(startDate,endDate,dependent,dependentList)),
    getAssetTypeList: () => dispatch(dependentActions.getAssetTypeList()),
    getDebtTypeList: () => dispatch(dependentActions.getDebtTypeList()),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    updateSessionTimeout : (status) => dispatch(userDetailActions.updateSessionTimeout(status)),
    currentDebtData : (debt) => dispatch(dependentActions.currentDebtData(debt)),
    currentTabName : (tabName) => dispatch(dependentActions.currentTabName(tabName)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
});

export default connect(mapStateToProps, mapDispatchToProps) (InventoryScreen)
