import React, { useState,useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import InventoryFileViewer from "./InventoryFileViewer";
import NotesComponent from "./NotesComponent";

function BurialAccountDetailComponent(props) {

    const [isProvider, setIsProvider] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [isAccoutShow, setIsAccoutShow] = useState(false);
    const [showEditOrAddIcon, setShowEditOrAddIcon] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState('');

    const [isCommentTabVisible, setIsCommentTabVisible] = useState(true);

    useEffect(()=>{
        setShowEditOrAddIcon(props.showEditOrAddIcon)
    },[props.showEditOrAddIcon])

    const collapse = (elment) => {
        let elm = document.getElementById(elment);
        if(elm !== null){
            elm.parentElement.classList.toggle('opened')
            elm.classList.toggle('heightAuto')
            
        }
        if(elment === 'Location' ){
            props.gettingProviderDetails();
        }
    }

    useEffect(()=>{
        if(props.currentAsset?.institute){
            setIsProvider(true)
        }
        setCanUpdate(props?.dependent?.access?.can_update)
    },[props.currentAsset.institute, props.dependent.access])


    const editBurialAccount = () => {
        props.goToEditScreen(A12Y_CONST.TEXT.ASSET_BURIAL_ACCOUNT_KEY)
    }

    const editInstituition = () => {
        props.goToEditScreen(A12Y_CONST.TEXT.ASSET_INSTITUTION_KEY)
    }

    const addInstitution = ()=>{
        props.goToAddInstitutionScreen()
    }
    const toggleAccountInfo = (id) => {
        setIsAccoutShow(!isAccoutShow)
        props.decryptAccountNo(id);
    }

    useEffect(() => {
        if(props.currentAsset?.web_url){
            let link = (    props.currentAsset?.web_url.indexOf('://') === -1) ? 'http://' + props.currentAsset?.web_url : props.currentAsset?.web_url;
            setWebsiteUrl(link)
        }else{
            setWebsiteUrl('')
        }

    },[props.currentAsset?.web_url, websiteUrl])

    const tabCheckBox = (status) => {
        setIsCommentTabVisible(status)
    }

    return(
        <div>
            <div className="inventoryBox fullWidth borderBottom opened">
                <div onClick={() => collapse('Policy')} className="inventoryScreenTitle fullWidth positionRelative  inventory_Col_padding">
                    <div className='assetColTitle'>{props.dependent.nick_name}{A12Y_CONST.LABEL.APOSTROPHE_S} {A12Y_CONST.LABEL.BURIAL_ACCOUNT}</div>
                    {props.loading ?
                        null
                        :
                        (
                            canUpdate && showEditOrAddIcon?
                                <span id={A12Y_CONST.TEXT.BURIAL_ACCOUNT+A12Y_CONST.TEXT.EDIT} className='cursorPointer' onClick={editBurialAccount}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66767 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2"/>
                                </svg>
                        </span>
                                :
                                null
                        )
                    }
                    <div className="btnAddEditWrap df fcol jcc aic">
                        <span className='collape marginBottom20'>
                            <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.703768 0.684193C1.63973 -0.228064 3.16111 -0.228064 4.09707 0.684193L12 8.36722L19.9029 0.684193C20.8389 -0.228064 22.3603 -0.228064 23.2962 0.684193C24.2346 1.59412 24.2346 3.07333 23.2962 3.98326L13.6966 13.3158C12.7607 14.2281 11.2393 14.2281 10.3033 13.3158L0.703768 3.98326C-0.23459 3.07333 -0.23459 1.59412 0.703768 0.684193Z" fill="#565C65"/>
                        </svg>
                        </span>
                    </div>
                </div>

                <div id='Policy' className={`inventoryDetialBox marginTop20 heightAuto positionRelative ${props.loading ? 'polLoading' : 'height0'}`}>
                    {props.loading ?
                        <div className='loaderPol df aic jcc fcol height100 width100 positionAbsouteOnly'>
                            <div className="positionRelative">
                                <div className="dependentLoader">
                                    <div className='fullWidth justifyCenter marginBottom10'>
                                        <div className="stage">
                                            <div className="dot-pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='marginTop20 fullWidth'>{A12Y_CONST.TEXT.LOADING_BURIAL_ACCOUNT}</div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="inventoryRow fullWidth"><span className="inventory_label">{A12Y_CONST.LABEL.NAME}</span><span className="inventory_value">{props.currentAsset.asset_name}</span></div>
                            <div>
                                <div className="inventoryRow width50 marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.ASSET_VALUE}</span><span className="inventory_value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.currentAsset.asset_value)}</span></div>
                                <div className="inventoryRow width50 marginTop15"><span className="inventory_label inventory_label20Padding">{A12Y_CONST.LABEL.AS_OF_DATE}</span><span className="inventory_value">{props.currentAsset.as_of_date}</span></div>
                            </div>
                            <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.DATE_OPENED}</span><span className="inventory_value">{props.currentAsset.acquisition_date}</span></div>
                            <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.ACCOUNT_}</span>
                                <span className="inventory_value accountWidth190 floatLeft overflowHidden">{!isAccoutShow && props.currentAsset.burial.account_number !== "" ? <div className='lineHeight'>**********</div> : props.encryptLoader ? <div className="dot-pulse marginTop10 marginLeft20 floatLeft"></div>:props.encryptAccount}</span>
                                { props.currentAsset?.burial?.account_number !== "" && props.currentAsset?.burial?.account_number !== null ?
                                    <span id={A12Y_CONST.TEXT.SHOW_ACCOUNT_NO+A12Y_CONST.TEXT.BURIAL_ACCOUNT} className='marginTop5 floatLeft cursorPointer' onClick={() =>{ toggleAccountInfo(props.currentAsset.id)}}>
                                        {
                                            isAccoutShow ?
                                                <svg className='cursorPointer accountShowhide600 marginLeft20' xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                                    <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#414141"/>
                                                    <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#414141"/>
                                                </svg>
                                                :
                                                <svg className='cursorPointer, accountShowhide600 marginLeft20' width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#414141"></path>
                                                    <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#414141"></path>
                                                </svg>
                                        }
                                     </span>
                                    :
                                    null
                                }
                            </div>
                            <div className="fullWidth inventoryRow">
                                 {websiteUrl ?
                        <div className="df marginTop15 linkToWebsiteMargin">
                            <a  id={A12Y_CONST.TEXT.LINK_URL+A12Y_CONST.TEXT.BURIAL_ACCOUNT}  rel="noreferrer" className='linkToWebsite df alignCenterVH' target="_blank" href={websiteUrl}>
                                <svg className='marginRight10' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <path d="M9.16667 18.3333C4.10392 18.3333 0 14.2294 0 9.16667C0 4.10392 4.10392 0 9.16667 0C14.2294 0 18.3333 4.10392 18.3333 9.16667C18.3333 14.2294 14.2294 18.3333 9.16667 18.3333ZM7.0675 16.1947C6.16313 14.2765 5.63922 12.201 5.52475 10.0833H1.89017C2.06854 11.494 2.65276 12.8225 3.57185 13.9074C4.49095 14.9923 5.70535 15.7869 7.0675 16.1947ZM7.36083 10.0833C7.49925 12.3191 8.13817 14.4192 9.16667 16.2727C10.223 14.3702 10.84 12.2554 10.9725 10.0833H7.36083ZM16.4432 10.0833H12.8086C12.6941 12.201 12.1702 14.2765 11.2658 16.1947C12.628 15.7869 13.8424 14.9923 14.7615 13.9074C15.6806 12.8225 16.2648 11.494 16.4432 10.0833ZM1.89017 8.25H5.52475C5.63922 6.1323 6.16313 4.05688 7.0675 2.13858C5.70535 2.54639 4.49095 3.34102 3.57185 4.42594C2.65276 5.51085 2.06854 6.83934 1.89017 8.25ZM7.36175 8.25H10.9716C10.8394 6.07805 10.2226 3.96324 9.16667 2.06067C8.11037 3.96316 7.49334 6.07798 7.36083 8.25H7.36175ZM11.2658 2.13858C12.1702 4.05688 12.6941 6.1323 12.8086 8.25H16.4432C16.2648 6.83934 15.6806 5.51085 14.7615 4.42594C13.8424 3.34102 12.628 2.54639 11.2658 2.13858Z" fill="#2F6DB3"/>
                                </svg>
                                {A12Y_CONST.TEXT.LINK_TO_WEBSITE}</a>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="inventoryRow fullWidth marginTop15 commentSetionViewScreen">
                                {/*<span className="inventory_label">{A12Y_CONST.LABEL.NOTE}</span>
                                <span className="inventory_value noteText ayScrollBar">
                                 {
                                     props?.currentAsset?.note?.length > 0 && props?.currentAsset?.note?.split('\n').map((n, i)=> {
                                         return <div key={i}>{n}</div>
                                     })}
                                </span>*/}

                                <label className="inventory_label">{A12Y_CONST.TEXT.OTHER_SURVEY}
                                    <span className='showLabel'>{A12Y_CONST.TEXT.SHOW}</span>
                                </label>
                                <div className='fullWidth'>
                                    <div className='fullWidth marginBottom20 marginTop35 commentMarginResponsive'>
                                        <div id={A12Y_CONST.LABEL.ATTACHMENTS_SMALL+A12Y_CONST.TEXT.BURIAL_ACCOUNT+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(true)}} className={`commentExpenseTab ${isCommentTabVisible?'activecommentExpenseTab':''}`}>{A12Y_CONST.LABEL.ATTACHMENTS_SMALL}</div>
                                        <div id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.TEXT.BURIAL_ACCOUNT+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(false)}}  className={`commentExpenseTab  ${isCommentTabVisible ? '' : 'activecommentExpenseTab'}`}>{A12Y_CONST.LABEL.NOTES}</div>
                                    </div>
                                    <div className="fullWidth tabContentBox">
                                        {isCommentTabVisible?
                                            <div className='fullWidth'>
                                                {props.currentAsset.attachments && props.currentAsset.attachments.length > 0 ?
                                                    <InventoryFileViewer
                                                        currentAsset={props.currentAsset}
                                                        dependent={props.dependent}
                                                    />
                                                    :
                                                    <div className='noData inventoryNote df aic jcc fcol'>
                                                        <strong>{A12Y_CONST.TEXT.NO_DATA_FOUND}</strong>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div className='fullWidth'>
                                                <NotesComponent
                                                    noteType={A12Y_CONST.LABEL.NOTE_BURIAL_TYPE}
                                                    selectedItem={props.currentAsset}
                                                    dependent={props.dependent}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                          {/*  <div className="inventoryRow fullWidth marginTop15 marginBottom20"><span className="inventory_label">{A12Y_CONST.LABEL.ATTACHMENT}</span>
                                {props.currentAsset.attachments && props.currentAsset.attachments.length > 0 ?
                                    <InventoryFileViewer
                                        currentAsset={props.currentAsset}
                                        dependent={props.dependent}
                                    />
                                    :
                                    null
                                }
                            </div>*/}
                        </div>
                    }
                </div>

            </div>
            <div className="inventoryBox fullWidth borderBottom">
                <div onClick={() => collapse('Location')} className="inventoryScreenTitle fullWidth positionRelative  inventory_Col_padding">
                    {A12Y_CONST.LABEL.BURIAL_ACCOUNT_PROVIDER}
                    {
                        !props.institutesLoader && canUpdate && showEditOrAddIcon && (  isProvider ?
                                <span id={A12Y_CONST.TEXT.EDIT_BURIAL_ACCOUNT_PROVIDER_ASSET} className='cursorPointer dn' onClick={editInstituition}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2"/>
                                </svg>
                            </span>
                                :
                                <span id={A12Y_CONST.TEXT.ADD_BURIAL_ACCOUNT_PROVIDER_ASSET} className='cursorPointer dn' onClick={addInstitution}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 0C8.552 0 9 0.4477 9 1V7H15C15.552 7 16 7.4477 16 8C16 8.5523 15.552 9 15 9H9V15C9 15.5523 8.552 16 8 16C7.448 16 7 15.5523 7 15V9H1C0.448 9 0 8.5523 0 8C0 7.4477 0.448 7 1 7H7V1C7 0.4477 7.448 0 8 0Z" fill="#2F6DB3"/>
                                </svg>

                            </span>
                        )
                    }
                    <div className="btnAddEditWrap df fcol jcc aic">
                        <span className='collape marginBottom20'>
                            <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.703768 0.684193C1.63973 -0.228064 3.16111 -0.228064 4.09707 0.684193L12 8.36722L19.9029 0.684193C20.8389 -0.228064 22.3603 -0.228064 23.2962 0.684193C24.2346 1.59412 24.2346 3.07333 23.2962 3.98326L13.6966 13.3158C12.7607 14.2281 11.2393 14.2281 10.3033 13.3158L0.703768 3.98326C-0.23459 3.07333 -0.23459 1.59412 0.703768 0.684193Z" fill="#565C65"/>
                        </svg>
                        </span>
                    </div>
                </div>
                {/*<div id='Location' className="inventoryDetialBox marginTop20 height0">*/}
                {/*    <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.INSTITUTION}</span><span className="inventory_value">{props.currentAsset?.institute ? props.currentAsset.institute.institution_name : null}</span></div>*/}
                {/*    <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.ADDRESS}</span><span className="inventory_value">{props.currentAsset?.institute && Object.keys(props.currentAsset.institute.address).length > 0 ? (props.currentAsset.institute.address.line1 && props.currentAsset.institute.address.line1 ? props.currentAsset.institute.address.line1 +', ' : '') +''+ (props.currentAsset.institute.address.line2 && props.currentAsset.institute.address.line2 ? props.currentAsset.institute.address.line2 +', ' : '') +''+(props.currentAsset.institute.address.city && props.currentAsset.institute.address.city ? props.currentAsset.institute.address.city+', ' : '') +''+(props.currentAsset.institute.address.state && props.currentAsset.institute.address.state ? props.currentAsset.institute.address.state+', ' : '')+''+(props.currentAsset.institute.address.zip): ''}</span></div>*/}
                {/*</div>*/}

                <div id='Location' className={`inventoryDetialBox positionRelative height0`}>
                    {props.institutesLoader ?
                        <div  className='positionRelative df aic loaderMarginTop jcc fcol height50 zIndex2 fullWidth bgColor cb'>
                            <div className="positionRelative">
                                <div className="dependentLoader">
                                    <div className='fullWidth justifyCenter marginBottom10'>
                                        <div className="stage">
                                            <div className="dot-pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='marginTop20 fullWidth'>{A12Y_CONST.TEXT.LOADING_BURIAL_ACCOUNT_PROVIDER}</div>
                            </div>
                        </div>
                        :
                        <>
                            {props.currentAsset?.institute ?
                                <div className="inventoryDetialBox">
                                    <div className="inventoryRow fullWidth"><span className="inventory_label">{A12Y_CONST.LABEL.INSTITUTION}</span><span className="inventory_value">{props.currentAsset?.institute ? props.currentAsset.institute.institution_name : null}</span></div>
                                    <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.ADDRESS}</span><span className="inventory_value">{props.currentAsset?.institute && Object.keys(props.currentAsset.institute.address).length > 0 ? (props.currentAsset.institute.address.line1 && props.currentAsset.institute.address.line1 ? props.currentAsset.institute.address.line1 +', ' : '') +''+ (props.currentAsset.institute.address.line2 && props.currentAsset.institute.address.line2 ? props.currentAsset.institute.address.line2 +', ' : '') +''+(props.currentAsset.institute.address.city && props.currentAsset.institute.address.city ? props.currentAsset.institute.address.city+', ' : '') +''+(props.currentAsset.institute.address.state && props.currentAsset.institute.address.state ? props.currentAsset.institute.address.state+', ' : '')+''+(props.currentAsset.institute.address.zip): ''}</span></div>
                                </div>
                                :
                                <div className='marginTop20 fullWidth addressInfo   texAlingCener'>
                                    <svg width="46" height="59" viewBox="0 0 46 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.7778 0C16.7367 0 10.9431 2.39979 6.67146 6.67146C2.39979 10.9431 0 16.7367 0 22.7778C0 41.2169 22.7778 58.5714 22.7778 58.5714C22.7778 58.5714 45.5556 41.2169 45.5556 22.7778C45.5556 16.7367 43.1558 10.9431 38.8841 6.67146C34.6124 2.39979 28.8188 0 22.7778 0ZM34.3077 35.7937C31.341 38.4308 27.5599 39.9712 23.5949 40.1581C19.6299 40.345 15.7207 39.167 12.5191 36.8206C9.31748 34.4742 7.01698 31.1011 6.0012 27.2639C4.98543 23.4267 5.31575 19.3573 6.93711 15.7341C8.55847 12.111 11.3729 9.15315 14.911 7.35382C18.4491 5.55449 22.4971 5.02242 26.3801 5.84633C30.263 6.67024 33.7461 8.80034 36.2487 11.8814C38.7513 14.9625 40.122 18.8084 40.1323 22.7778C40.1387 25.2361 39.6227 27.6677 38.6186 29.9116C37.6144 32.1555 36.145 34.1604 34.3077 35.7937Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                        <path d="M32.3337 34.5673L32.453 34.4806L32.3337 34.5673ZM13.1028 34.4697L13.2221 34.5565L13.1028 34.4697Z" fill="black"/>
                                        <path d="M31.4898 34.3273C32.0794 34.2044 32.4305 33.5879 32.1439 33.0582C31.451 31.7774 30.3394 30.652 28.9092 29.7985C27.1041 28.7212 24.8923 28.1372 22.617 28.1372C20.3417 28.1372 18.1299 28.7212 16.3248 29.7985C14.8946 30.652 13.783 31.7774 13.09 33.0582C12.8034 33.5879 13.1546 34.2044 13.7442 34.3273L14.4561 34.4757C19.8389 35.5974 25.3951 35.5975 30.7779 34.4757L31.4898 34.3273Z" fill="#2D6CB2"/>
                                        <circle cx="22.6171" cy="20.0978" r="5.7423" fill="#2D6CB2"/>
                                    </svg>
                                    <div className="noAddressText fullWidth">{A12Y_CONST.TEXT.NO_ADDRESS}</div>
                                    {'transfer' in props.currentAsset || (props.dependent?.access && props.dependent.access.can_update === false) ? '' : <div className="noAddressDescription fullWidth marginTop5">{A12Y_CONST.TEXT.TRACK_CURRENT_ADDRESS_MESSAGE}</div>}
                                </div>
                            }
                        </>

                    }
                </div>
            </div>
        </div>
    )
}

export default BurialAccountDetailComponent