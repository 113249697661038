import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Loading from '../components/LoadingComponent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {currencyFormatting} from "../util/CurrencyFormater";
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import UploadFiles from "./ImageUploadComponent";
import {v4 as uuidv4} from "uuid";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';

function InvestmentAccountComponentStep1(props) {
    const { register, handleSubmit, setFocus, control,setValue,setError, formState: { errors, isSubmitting } } = useForm({ mode: "all" });
    const history = useHistory();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [error, setErrors] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMeesage, setNameErrorMeesage] = useState('');
    const [dateAcquired, setDateAcquired] = useState(null);
    const [dateAcquiredError, setDateAcquiredError] = useState(false);
    const [dateAcquiredErrorMeesage, setDateAcquiredErrorMeesage] = useState('');

    const [assetValue, setAssetValue] = useState('');
    const [assetValueError, setAssetValueError] = useState(false);
    const [assetValueErrorMeesage, setAssetValueErrorMeesage] = useState('');

    const [asOfDate, setAsOfDate] = useState(new Date());
    const [asOfDateError, setAsOfDateError] = useState(false);
    const [asOfDateErrorMeesage, setAsOfDateErrorMeesage] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [note, setNote] = useState('');
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const dateAcquiredRef = useRef(null);
    const asOfDateRef = useRef(null);
    const [linkToWebsite, setLinkToWebsite] = useState('');

    const onSubmit = async (formData)=>{
        let isNameValid = await validateName();
        let isAssetValueValid = await validateAssetValue();
        let isDatesValid = !asOfDateError && !dateAcquiredError
        formData.dateAcquired = dateAcquired
        formData.asOfDate = asOfDate
        let obj =  {
            "asset_type_id" : props.editMode ? props.currentAsset.asset_type_id : props.asset.asset_type_id,
            "asset_name" : name,
            "acquisition_date" : dateAcquired != null ? await ddmmyyyyFormat(new Date(dateAcquired)) : null,
            "asset_value" : assetValue && assetValue.includes(',') ? assetValue.replaceAll(',','') : assetValue ,
            "as_of_date" : await ddmmyyyyFormat(new Date(asOfDate)),
            "status" : "1",
            "note" : note,
            "web_url" : linkToWebsite,
        }


        if(isDatesValid && !isNameValid && !isAssetValueValid){
            setIsSubmit(true)
            setLoading(true);
            props.isDataChanged(false)
            if(props.editMode){
                obj.id = props.currentAsset.id;
                props.onUpdate(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }else {
                // props.onSubmit(obj)
                obj.id = assetId;
                props.onSubmit(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }
        }
    }

    useEffect(() => {
        setFocus("name");
    }, [setFocus])


    useEffect(  () => {
        const createUuid = async ()=>{
            if(props.currentAsset === undefined || (props.currentAsset && Object.keys(props.currentAsset).length === 0)){
                const uuid = await uuidv4();
                await setAssetId(uuid);
            }
        }
        createUuid()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const assignFormData = async ()=>{
           setIsEditMode(false)
           if(props.editMode && Object.keys(props.currentAsset).length > 0){
               let asset = props.currentAsset;
               setAssetId(asset.id)
               let assetVal = asset.asset_value || asset?.asset_value === 0 ? await currencyFormatting(asset.asset_value) : ''
               setName(asset.asset_name)
               setAssetValue(assetVal)
               setAsOfDate(new Date(asset.as_of_date))
               setDateAcquired(asset.acquisition_date != null ? new Date(asset.acquisition_date) : null)
               setNote(asset.note)
               setLinkToWebsite(asset?.web_url)
               setIsEditMode(true)
           }
       }
        assignFormData()
    },[props.editMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        const checkFormDataIsChanged = async ()=>{
            setIsBtnDisabled(true)
            setIsDataModified(false)
            props.isDataChanged(false)
            if(isEditMode && Object.keys(props.currentAsset).length > 0){
                /*setIsBtnDisabled(true)*/
                let asset = props.currentAsset;
                let asOfDate1 = await ddmmyyyyFormat(new Date(asOfDate))
                let dateAcquired1 = dateAcquired != null ? await ddmmyyyyFormat(new Date(dateAcquired)) : null
                if(name !== asset.asset_name || assetValue !== await currencyFormatting(asset.asset_value) || asOfDate1 !== asset.as_of_date || linkToWebsite !== asset.web_url || note !== asset.note || dateAcquired1 !== asset.acquisition_date || fileInfo.length > 0 || deletedFileInfo.length > 0){
                    if(name !== "" && assetValue !== "" && asOfDate !== null){
                        setIsBtnDisabled(false)
                    }
                    setIsDataModified(true)
                    props.isDataChanged(true)
                }
            }else {
                if(!props.editMode){
                    let today =  await ddmmyyyyFormat(new Date())
                    if(name !== '' || assetValue !== '' || linkToWebsite !== '' || await ddmmyyyyFormat(new Date(asOfDate)) !==  today || note !== '' || dateAcquired !== null || fileInfo.length > 0 || deletedFileInfo.length > 0){
                        if(name !== "" && assetValue !== "" && asOfDate !== null){
                            setIsBtnDisabled(false)
                        }
                        setIsDataModified(true)
                        props.isDataChanged(true)
                    }
                }
            }
        }
        checkFormDataIsChanged()

    }, [isEditMode,name, assetValue,  asOfDate, note, linkToWebsite, dateAcquired,fileInfo, deletedFileInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            if(props.editMode){
                props.onCancel()
            }else {
                history.push(A12Y_CONST.ROUTE.INVENTORY);
            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        if(props.editMode){
            props.onCancel()
        }else {
            history.push(A12Y_CONST.ROUTE.INVENTORY);
        }
    }

    const handleNameChange = (e) => {
        setName(e.target.value.trimStart())
        setValue('name', e.target.value.trimStart())
    }
    const validateName = (e) => {
        if(name === '' && name.length === 0) {
            setNameError(true)
            setNameErrorMeesage(A12Y_CONST.ERROR_MESSAGE.NAME_REQUIRED);
            return true;
        }else{
            setNameError(false)
            setNameErrorMeesage('');
            return false;
        }
    }

    const handleAssetValueChange = async (e) => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setAssetValue(amt)
            setValue('assetValue',amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setAssetValue(temp)
                setValue('assetValue', temp)
            }
        }
    }

    const validateAssetValue = () => {
        if(assetValue === '' && assetValue.length === 0) {
            setAssetValueError(true)
            setAssetValueErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_ASSET_VALUE);
            return true;
        }else{
            setAssetValueError(false)
            setAssetValueErrorMeesage('');
            return false;
        }
    }


    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
    },[props.loading,props.error,])


    const handleNoteChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setNote(e.target.value);
            setValue('note',e.target.value)
        }
    }


    /*const handleNoteHideChange = (e) => {
        setHideNote(e.target.checked);
        setValue('noteHide', e.target.checked)
    }*/

    const filesDetail_FromChild = (e,deletedFiles) => {
        if(assetId){
            let fileList = [];
            let base64List = [];
            for (var i = 0; i < e.length; i++) {
                if(!e[i].file_id){
                    let name = e[i].displayName
                    let size = e[i].size
                    let file = e[i];
                    let fileName = e[i].name;
                    let reader = new FileReader();
                    const uuid = uuidv4();
                    let s3key = `${props.dependent.id}/asset/${assetId}/${uuid}`
                    reader.readAsDataURL(file);
                    reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                    );
                    fileList.push({
                        s3_key: s3key,
                        file_id:uuid,
                        asset_id:assetId,
                        'contentType': e[i].type,
                        'fileMetadata': {
                            "about_dependent": "some more info"
                        },
                        'name' : name,
                        'size' : size,
                        'fileName': fileName
                    });
                }
            }
            setFileInfo(fileList);
            setFileBase64Info(base64List)
            setDeletedFileInfo(deletedFiles)
        }
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleAsOfDateChange = () => {
        if(asOfDate != null){
            let isValidObj = validateDate(asOfDate, 100, 0)
            setAsOfDateError(isValidObj.isErr)
            setAsOfDateErrorMeesage(isValidObj.errMsg)
        }
        else{
            setAsOfDateError(true)
            setAsOfDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    const handleDateAcquiredChange = () => {
        if(dateAcquired != null){
            let isValidObj = validateDate(dateAcquired, 100, 0)
            setDateAcquiredError(isValidObj.isErr)
            setDateAcquiredErrorMeesage(isValidObj.errMsg)
        }
    }
    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }
    return (
        <div className="df fcol marginBottom20 height100">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h4 className="dependentSubTitle marginBottom10"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'> {props.apostrophe ? `’s` : ''} {props.title}</span></h4>
            <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
            </div>
            <form autoComplete="off" className='marginTop30 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                <div className="formRow fullWidth formRowGapSM">
                    <label className="lblExpType">{A12Y_CONST.LABEL.NAME}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className="fullWidth">
                        <input
                            {...register("name")}
                            value={name}
                            id="name"
                            maxLength='255'
                            onBlur={validateName}
                            onChange={handleNameChange}
                            onFocus={()=>setNameErrorMeesage(false)}
                            className={`fullWidth inputColum24size ${nameError && 'errorInputBorderRed'}`}
                            placeholder="Name of Asset"
                        />
                        <div className='errorRelativeRow'>{nameError? <p className='errorMessage'>{nameErrorMeesage}</p>: null}</div>
                    </div>
                </div>
                <div className="formRow fullWidth formRowGapSM payRow">
                    <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.ASSET_VALUE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <span className={ assetValue.trim().length === 0? "currency" : "currency currencyColorChnage" }>{A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                            <input
                                {...register("assetValue")}
                                value={assetValue}
                                id="assetValue"
                                type='text' min='0' inputMode='decimal'
                                onWheel={e => e.target.blur()}
                                maxLength='50'
                                onBlur={validateAssetValue}
                                onChange={handleAssetValueChange}
                                onFocus={()=>setAssetValueErrorMeesage(false)}
                                className={`fullWidth txtAmount inputColum24size ${assetValueError && 'errorInputBorderRed'}`}
                                placeholder="0,000.00"
                            />
                            <div className='errorRelativeRow'>{assetValueError? <p className='errorMessage'>{assetValueErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="width50 paddingleft16 positionRelative">
                        <label>{A12Y_CONST.LABEL.AS_OF_DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth amountWrap">
                            <Controller
                                name="asOfDate"
                                control={control}
                                inputmode='none'
                                render={({ field }) =>
                                    <DatePicker
                                        {...field}
                                        id={A12Y_CONST.BUTTON_ID.AS_OF_DATE}
                                        ref={asOfDateRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={asOfDate}
                                        onChange={date => setAsOfDate(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'asOfDate')}
                                        onBlur={handleAsOfDateChange}
                                        onCalendarClose={handleAsOfDateChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(event) => hideOnBlur(event, asOfDateRef)}
                                        onFocus={(e)=> {setAsOfDateError(false); readOnlyOnMobile(e.target)}}
                                        className={!asOfDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>{asOfDateError? <p className='errorMessage'>{asOfDateErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                </div>

                <div className="formRow fullWidth formRowGapSM payRow">
                    <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.DATE_OPENED}</label>
                        <div className="fullWidth amountWrap">
                            <Controller
                                name="dateAcquired"
                                control={control}
                                inputmode='none'
                                render={({ field }) =>
                                    <DatePicker
                                        {...field}
                                        id={A12Y_CONST.BUTTON_ID.DATE_OPENED}
                                        ref={dateAcquiredRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={dateAcquired}
                                        onChange={date => setDateAcquired(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'dateAcquired')}
                                        onBlur={handleDateAcquiredChange}
                                        onCalendarClose={handleDateAcquiredChange}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        maxLength={14}
                                        dropdownMode="select"
                                        onKeyDown={(event) => hideOnBlur(event, dateAcquiredRef)}
                                        onFocus={(e)=> {setDateAcquiredError(false); readOnlyOnMobile(e.target)}}
                                        className={!dateAcquiredError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>
                                {dateAcquiredError? <p className='errorMessage'>{dateAcquiredErrorMeesage}</p>: null} 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="formRow fullWidth formRowGapSM payRow">
                    <div className="width50 paddingRight16 positionRelative">
                        <label>{A12Y_CONST.LABEL.LINK_URL}</label>
                        <div className='fullWidth doctorRowMarginB_600'>
                            <input
                                {...register('linkToWebsite',{
                                    pattern:{
                                        //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                        value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                        message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                    }
                                })}
                                placeholder='www.example.com'
                                className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                type='text'
                                id='displayName'
                                autoComplete="none"
                                onChange={(e) => handleLinkToWebsite(e.target.value)}
                                value={linkToWebsite}
                                onFocus={()=>{
                                    setError("linkToWebsite", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                        </div>
                    </div>
                </div>
                {!props.editMode ?
                <div className="formRow fullWidth formRowGapSM df fcol">
                    <label>{A12Y_CONST.LABEL.NOTE}</label>
                    <div className="fullWidth">
                            <textarea
                                {...register("note")}
                                rows="3"
                                onChange={handleNoteChange}
                                maxLength='4000'
                                value={note}
                                className="fullWidth inputColum24size txtNote"
                                placeholder="Capture details about this investment account."
                            ></textarea>

                        {/*<div className="fullWidth hideNote positionRelative">*/}
                        {/*    <label className="lblExpType">{A12Y_CONST.LABEL.HIDE_NOTE_FROM_VIEWER}</label>*/}
                        {/*    <div className="fullWidth marginTop5 df aic">*/}
                        {/*        <Controller*/}
                        {/*            name="noteHide"*/}
                        {/*            control={control}*/}
                        {/*            render={({ field }) =>*/}
                        {/*                <Switch*/}
                        {/*                    checked={hideNote}*/}
                        {/*                    onChange={handleNoteHideChange}*/}
                        {/*                    color="primary"*/}
                        {/*                />*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*        {hideNote && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}*/}
                        {/*        {!hideNote &&  <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="txtCapture">Capture a note about this transaction.</div>*/}
                </div>
                :
                null
                }

                <div className="formRow fullWidth marginBottom30 df fcol">
                    <label>{A12Y_CONST.LABEL.ATTACHMENT}</label>
                    <div className="fullWidth">
                        <div className="formRow fullWidth formRowGap uploadWrap">
                            <div className="lblUpload">
                                <UploadFiles
                                    fileDetailSendToParent={filesDetail_FromChild}
                                    fileUploadingData={props.fileUploadingData}
                                    isSubmit={isSubmit}
                                    editMode={props.editMode}
                                    obj={props.currentAsset}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*------------------ Button Actions ---------------------*/}
                <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom">
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.INVESTMENT_ACCOUNT_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            { loading ? <Loading /> : null }
                            <input id={A12Y_CONST.BUTTON_ID.INVESTMENT_ACCOUNT+props.BtnTxt} className={isBtnDisabled || loading ? "saveButtonDisable" : 'saveButton'} disabled={isBtnDisabled || loading} type="submit" value={props.BtnTxt} />
                        </div>
                    </div>
                </div>
            </form>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default InvestmentAccountComponentStep1;
