import {client,secureClient} from "../../config/axiosClient";
import A12Y_CONST from '../../common/a12yConst'
import CONFIG from "../../config/apiConfig";

export const login = (username, password, authcode) => async (dispatch, getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.LOGIN_START })
        let reqBody = Object.keys(authcode).length > 0  ?  authcode.invitation_uuid?{"auth_code" : authcode.auth_code, "invitation_uuid":authcode.invitation_uuid}:{ "auth_code" : authcode.auth_code} : {"username": username, "password": password}
        const loginResponse = await client.post(A12Y_CONST.API_ENDPOINT.LOGIN, reqBody);
        if (!loginResponse.data.error && loginResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'login', {
                'method': 'Google'
            });
            const loginResInfo = loginResponse.data;
            let filterInfo = loginResInfo.sr.dependents[0]?.reporting_period
            let userInfoObj = loginResInfo.sr?.userInfo
//            let userSubscription = loginResInfo.sr?.userSubscription
            let userSubscription = loginResInfo?.sr?.user_subscirption?.subscription_details
            let userSubscriptionAllDetails = loginResInfo?.sr.user_subscirption
            let paymentMethod = loginResInfo?.sr?.user_subscirption?.paymentMethod
            let stripeConfig = loginResInfo.sr?.stripeConfig
            let userPlan = loginResInfo.sr?.userPlan
            let subscriptionProduct = loginResInfo.sr?.subscription_product
            window.dataLayer.push({
                'user_id': loginResInfo?.sr?.user_subscirption?.subscription_details?.external_system_customer_id
            });
            // let filterObj = {}
            let filterObj={
                "activity":{startDate:'',endDate:"",type:[],tag:[], isModify:false},
                "expense": {startDate:"",endDate:"",type:[], isModify:false},
                "income": {startDate:"",endDate:"",type:[]}, isModify:false,
            }


            filterObj['activity'].type = filterInfo?.activity_type_ids ? filterInfo.activity_type_ids:[];
            filterObj['activity'].tag = filterInfo?.activity_tags ? filterInfo.activity_tags : [];
            filterObj['activity'].startDate = filterObj.start_date ? filterObj.start_date:"";
            filterObj['activity'].endDate = filterObj.end_date ? filterObj.end_date:"";
            filterObj['expense'].type = filterInfo?.expense_type_ids ? filterInfo.expense_type_ids : [];
            filterObj['expense'].startDate = filterObj.start_date ? filterObj.start_date:"";
            filterObj['expense'].endDate = filterObj.end_date ? filterObj.end_date:"";
            filterObj['income'].type = filterInfo?.income_type_ids ? filterInfo.income_type_ids : []
            filterObj['income'].startDate = filterObj.start_date ? filterObj.start_date:"";
            filterObj['income'].endDate = filterObj.end_date ? filterObj.end_date:"";

            userInfoObj.email = loginResInfo.sr.cogResp.email
            userInfoObj.is_google_user = loginResInfo?.sr?.cogResp?.is_google_user
            let dispatchData = {
                access_token : loginResInfo.sr.cogResp.access_token,
                refresh_token : loginResInfo.sr.cogResp.refresh_token,
                id_token : loginResInfo.sr.cogResp.id_token,
                userInfo :userInfoObj
            }
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.LEGAL_DOC_ID,
                doc_id: loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0].legal_doc_id : ''
            });

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.USER_SESSION_OUT_USER_ID,
                userId: userInfoObj.id
            });

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.USER_ROLE_TYPE,
                payload: {type:userInfoObj.user_role_type}
            })

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_META_DATA,
                income_present : loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0].metadata.income_present : false,
                expense_present : loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0].metadata.expense_present : false,
                activity_present : loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0].metadata.activity_present : false,
                asset_present : loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0].metadata.asset_present : false,
            });

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_MEDICINE_PRESENT_META_DATA,
                medicine_present : loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0].metadata.medicine_present : false,
            })

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_DOCTOR_PRESENT_META_DATA,
                doctor_present : loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0].metadata.doctor_present : false,
            })

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.GET_DEPENDENT_SUCCESS,
                dependents : loginResInfo.sr.dependents,
                dependent : loginResInfo.sr.dependents.length > 0 ? loginResInfo.sr.dependents[0] : {},
                message : '',
            });

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.USER_SUBSCRIPTION_SUCCESS,
                userSubscription : userSubscription,
                userSubscriptionAllDetails : userSubscriptionAllDetails,
                stripeConfig : stripeConfig,
                paymentMethod: paymentMethod,
                userPlan:userPlan,
                subscriptionProduct:subscriptionProduct
            });
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.LOGIN_SUCCESS,
                userState : dispatchData
            });

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.USER_SOURCE_LOGIN,
                payload: {type:{user_source:loginResponse.data?.sr?.user_source,login_success:true}}
            })

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.SELECTED_FILTERED,
                payload: {data:filterObj}
            })

        } else {
            const loginResInfo = loginResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.LOGIN_ERROR,
                errMsg:loginResInfo.sr.a12yErr.message,
                code:loginResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'exception', {
                description: "Login failed.",
                fatal: false
            });

            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.USER_SOURCE_LOGIN,
                payload: {type:{user_source:loginResponse.data.sr.user_source,login_success:false}}
            })
        }
    } catch (error) {
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.LOGIN,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.LOGIN_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "Login Error.",
            fatal: true
        });

    }
}

export const signUp = (data) => async (dispatch,getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.SIGNUP_START })

        const signUpResponse = await client.post(A12Y_CONST.API_ENDPOINT.SIGNUP, data );
        if (!signUpResponse.data.error && signUpResponse.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', A12Y_CONST.GOOGLE_ANALYTIC_EVENT.SIGNUP_SUCCESS, {
                description: "Signup success.",
            });
            const signupResInfo = signUpResponse.data;
            let dispatchData = {
                userInfo :{username:signupResInfo.sr.cognitoResponse.username},
                usercognitoResponse: {cognitoResponse:signupResInfo.sr.cognitoResponse}
            }
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.SIGNUP_SUCCESS,
                userState : dispatchData
            });
        } else {
            const signupResInfo = signUpResponse.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.SIGNUP_ERROR,
                errMsg:signupResInfo.sr.a12yErr.message,
                code:signupResInfo.sr.a12yErr.code,
            });
            window.gtag('event', A12Y_CONST.GOOGLE_ANALYTIC_EVENT.SIGNUP_FAILED, {
                description: signupResInfo.sr.a12yErr.message,
                email : data.email,
                fatal: false
            });
        }
    }catch (error){
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.SIGNUP,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.SIGNUP_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', A12Y_CONST.GOOGLE_ANALYTIC_EVENT.SIGNUP_SS_ERROR, {
            description: error.message,
            fatal: true
        });
    }
}

export const updateUserProfile = (data,email) => async (dispatch,getState) => {
    try {
        dispatch({ type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PROFILE_START })

        const response = await secureClient.put(A12Y_CONST.API_ENDPOINT.UPDATE_PROFILE, data );
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'advocate_profile_updated', {

            });
            const responseResInfo = response.data;
            responseResInfo.sr.userInfo.email = email
            let dispatchData = {
                userInfo : responseResInfo.sr.userInfo,

            }
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PROFILE_SUCCESS,
                userInfo : dispatchData,
                message: responseResInfo.sr.message
            });
        }else {
            const responseResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PROFILE_ERROR,
                errMsg:responseResInfo.sr.a12yErr.message,
                code:responseResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'advocate_profile_update_failed', {
                description: responseResInfo.sr.a12yErr.message,
                fatal: false
            });
        }

    }catch (error){
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.UPDATE_PROFILE,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PROFILE_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "Unable to update profile.",
            fatal: true
        });
    }
}

export const updatePassword = (data) => async (dispatch,getState) =>{
    try {
        dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PASSWORD_START})

        const response = await secureClient.post(A12Y_CONST.API_ENDPOINT.UPDATE_PASSWORD, data);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            window.gtag('event', 'password_updated', {

            });
            const responseResInfo = response.data;
            let dispatchData = {
                userInfo : responseResInfo.sr.userInfo,

            }
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PASSWORD_SUCCESS,
                userInfo : dispatchData,
                message: responseResInfo.sr.message
            });
        } else {
            const responseResInfo = response.data;
            dispatch({
                type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PASSWORD_ERROR,
                errMsg: responseResInfo.sr.a12yErr.message,
                code: responseResInfo.sr.a12yErr.code,
            });
            window.gtag('event', 'password_update_failed', {
                description: responseResInfo.sr.a12yErr.message
            });
        }
    }catch (error){
         await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
            type : A12Y_CONST.ERROR_CODE.UPDATE_PASSWORD,
            log : error
        });
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.UPDATE_USER_PASSWORD_ERROR,
            errMsg:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,
            code:'',
        });
        window.gtag('event', 'exception', {
            description: "Unable to update password",
            fatal: true
        });
    }
}

export const createUserSubscription = (data) => async(dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CREATE_USER_SUBSCRIPTION_START})
    const response = await secureClient.post(A12Y_CONST.API_ENDPOINT.CREATE_SUBSCRIPTION_WITH_PAYMENT, data);
    if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
        window.gtag('event', 'subscription_created', {

        });
        window.gtag('event', 'conversion', { 'send_to': CONFIG.GOOGLE_TRACKING_ID2, 'transaction_id': ''});
        const responseResInfo = response.data;
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.CREATE_USER_SUBSCRIPTION_SUCCESS,
            userSubscription : responseResInfo.sr.userSubscription,
            paymentMethod : responseResInfo.sr.paymentMethod,
            userPlan : responseResInfo.sr.userPlan,
            message: responseResInfo.sr.message
        });
    } else {
        const responseResInfo = response.data;
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.CREATE_USER_SUBSCRIPTION_ERROR,
            errMsg: responseResInfo.sr.a12yErr.message,
            code: responseResInfo.sr.a12yErr.code,
        });
        window.gtag('event', 'subscription_creation_failed', {
            description: responseResInfo.sr.a12yErr.message
        });
    }
}

export const updatePaymentMethod = (data) => async(dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_PAYMENT_METHOD_START})
    const response = await secureClient.post(A12Y_CONST.API_ENDPOINT.UPDATE_PAYMENT_METHOD, data);
    if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
        window.gtag('event', 'subscription_created', {

        });
        const responseResInfo = response.data;
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.UPDATE_PAYMENT_METHOD_SUCCESS,
          // userSubscription : responseResInfo.sr.userSubscription,
            userSubscription : responseResInfo.sr.user_subscirption.subscription_details,
            subscription : responseResInfo.sr.subscription,
            paymentMethod : responseResInfo.sr.paymentMethod,
            planName:responseResInfo.sr.plan_name,
            message: responseResInfo.sr.message,
            price_details: responseResInfo.sr.user_subscirption.price_details,
        });
    } else {
        const responseResInfo = response.data;
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.UPDATE_PAYMENT_METHOD_ERROR,
            errMsg: responseResInfo.sr.a12yErr.message,
            code: responseResInfo.sr.a12yErr.code,
        });
        window.gtag('event', 'subscription_creation_failed', {
            description: responseResInfo.sr.a12yErr.message
        });
    }
}


export const cancelUserSubscription = (data) => async(dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CANCEL_SUBSCRIPTION_START})
    const response = await secureClient.put(A12Y_CONST.API_ENDPOINT.CANCEL_SUBSCRIPTION, data);
    if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
        window.gtag('event', 'subscription_cancelled', {

        });
        const responseResInfo = response.data;
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.CANCEL_SUBSCRIPTION_SUCCESS,
            userSubscription : responseResInfo.sr.userSubscription,
            paymentMethod : responseResInfo.sr.paymentMethod,
            message: responseResInfo.sr.message
        });
    } else {
        const responseResInfo = response.data;
        dispatch({
            type: A12Y_CONST.DISPATCH_ACTION.CANCEL_SUBSCRIPTION_ERROR,
            errMsg: responseResInfo.sr.a12yErr.message,
            code: responseResInfo.sr.a12yErr.code,
        });
        window.gtag('event', 'subscription_cancel_failed', {
            description: responseResInfo.sr.a12yErr.message
        });
    }
}

export const logoutUser = () => (dispatch, getState) => {
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.LOGOUT, payload: {}})
}

export const dataChanged = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_FIELD_UPDATED, payload: {status:status}})
}

export const updateSessionTimeout = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.UPDATE_SESSION_TIMEOUT, payload: {isSessionTimeout:status}})
}

export const sendErrorReportToServer = (type,error) => async(dispatch, getState) =>{
    await client.post(A12Y_CONST.API_ENDPOINT.REPORT_ERROR, {
        type : type,
        log : error
    });
}

export const selectUserRoleType = (type) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.USER_ROLE_TYPE, payload: {type:type}})
}

export const signUpTextChange = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.SIGNUP_TEXT, payload: {type:data}})
}

export const trialReminder = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_TRIAL_REMINDER, payload: {status:status}})
}

export const welcomePopUp = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_WELCOME_POPUP, payload: {status:status}})
}

export const surveyRolePopUp = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_SURVEY_ROLE_POPUP, payload: {status:status}})
}

export const surveyPopUp = (status) => async(dispatch, getState) =>{
    console.log('status', status)
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.IS_SURVEY_POPUP, payload: {status:status}})
}

export const goToBillingScreen = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.BILLING_SCREEN, payload: {status:status}})
}

export const setInviteCode = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.INVITE_CODE, payload: {type:data}})
}

export const setLogoutPopStatus = (status) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.SET_LOGOUT_POPUP_STATUS, payload: {status:status}})
}

export const selectedFilterTypeList = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.SELECTED_FILLTER_TYPE_LIST, payload: {type:data}})
}

export const selectedFilterTypeListIncome = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.SELECTED_FILLTER_TYPE_LIST_INCOME, payload: {type:data}})
}

export const setSeletedFilter = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.SELECTED_FILTERED, payload: {data:data}})
}

export const userSubscriptionDetailUpdate = (data) => async(dispatch, getState) =>{
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.USER_SUBSCRIPTION_DETAIL_UPDATE, payload: {data:data}})
}


// export const setLoginSource = (data) => async(dispatch, getState) =>{
//     dispatch({type: A12Y_CONST.DISPATCH_ACTION.USER_SOURCE_LOGIN, payload: {type:data}})
// }
