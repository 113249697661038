import React, { useState,useEffect, useRef } from 'react'
import jpg from "../assets/images/jpg.png";
import pdf from "../assets/images/pdf.png";

import {downloadFile} from "../util/imageDownload";
import jpg_icon from "../assets/images/jpg_icon.svg";
import LoaderFullScreen from "./LoaderFullScreenComponent";

function InventoryFileViewer(props) {

    const [docList, setDocList] = useState([]);
    const [loader, setLoader] = useState(false)
    //const [height, setHeight] = useState(0)
    const [fileViewerType, setFileViewerType] = useState('asset')
    const ref = useRef(null)

   /* useEffect(() => {
        setHeight(ref.current.clientHeight)
    })*/

    useEffect(()=>{
        if(props.type === undefined){
            setFileViewerType('asset')
        }else {
            setFileViewerType(props.type)
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(props.currentAsset.attachments && props.currentAsset.attachments.length > 0){
            setDocList(props.currentAsset.attachments)
        }
    },[props.currentAsset])

    function bytesForHuman(bytes) {
        let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
        let i = 0
        for (i; bytes > 1024; i++) {
            bytes /= 1024;
        }
        return parseFloat(bytes).toFixed(1) + ' ' + units[i]
    }

    const downloadAssetFile = async (obj)=>{
        await setLoader(true)
        let s3_key = props.dependent.id+'/'+fileViewerType+'/'+props.currentAsset.id+'/'+obj.file
        let downloadFileObj = {
            s3_key : s3_key,
            name:obj.file_name,
            contentType:obj.file_extension
        }
        await downloadFile(downloadFileObj,obj.file_name)
       await setLoader(false)
    }
    return(
        <div className='positionRelative'>
            {loader ?
                <LoaderFullScreen />
                :
                null
            }
            <div className='fullWidth' ref={ref}>
            {docList.length > 0 && docList.map((doc,key)=>(
                <div className="inventory_value" key={key} onClick={()=>{downloadAssetFile(doc)}}>
                    <div className='fileList df fcol fullWidth p0 marginTop10'>
                        <div className="file df">
                            <div className="fileType cursorPointer">
                                {/*<img width="44" height="50" src={doc.file_extension.toLowerCase() === "jpeg" ? jpg :pdf} alt='logo' />*/}
                                {doc.file_extension.toLowerCase() === 'pdf' ? <img width="44" height="50" src={pdf} alt='logo' />: doc.file_extension.toLowerCase() === 'jpg' || doc.file_extension.toLowerCase() === 'jpeg'? <img width="44" height="50" src={jpg_icon} alt='logo' /> : <img width="44" height="50" src={jpg} alt='logo' />}
                            </div>
                            <div className="fileInfo cursorPointer">
                                <h5>{doc.file_name}</h5>
                                <div className="df">
                                    <span>{bytesForHuman(doc.file_size)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}

export default InventoryFileViewer;