import React, {Component} from 'react'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import DashboardComponent from "../components/DashboardComponent";
import A12Y_CONST from "../common/a12yConst";
import * as userDetailActions from "../redux/actions/userDetailActions";
import {secureClient} from "../config/axiosClient";


class DashboardScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : true,
            isDependentProfileCreated: false
        }
    }

    componentDidMount = async ()=> {

        if(this.props.userState.userInfo.last_login_time !== null && this.props.IsWelcomePopUp){
            await this.props.welcomePopUp(false);
        }
        if(this.props.assetTypeList.length === 0){
            await this.props.getAssetTypeList();
            await this.props.getAllDoctorList(this.props.dependent.id)
            await this.props.getSpecialtyList(this.props.dependent.id)
            if(this.props.isError){
                this.setState({error:true,errorMessage:this.props.errMsg,loaderFullScreen: false})
            }else {
                this.setState({message:this.props.successMessage,loaderFullScreen: false})
            }
        }
        await this.props.currentExpenseData({})
        await this.props.currentActivityData({})
        localStorage.setItem("activity", JSON.stringify({}));
        await this.props.currentIncomeData({})
        await this.props.currentMedicationData(null)
        await this.props.medicationData({});
    }

    goToListScreenHandler = (item)=>{
        this.props.history.push(item.viewURL);
    }
    goToAddScreenHandler = (item)=>{
        if(item.addURL === A12Y_CONST.ROUTE.ADD_ACTIVITY){
            this.props.history.push({
                pathname: item.addURL,
                state: {fromDashboardScreen:true }
            });
        }else{
        this.props.history.push(item.addURL);
        }
    }
    goToLandingScreenHandler  = async (item)=>{
        this.props.history.push({
            pathname: item.viewURL,
            state: {ladingPageVisible:true }
        });
    }

    trialReminderBoxHide = async (status) => {
        await this.props.trialReminder(status)
   }

    welcomePopUpClose = async (status) => {
        await this.props.welcomePopUp(status)
   }

   surveyPopUpHandle = async (status) => {
        await this.props.surveyRolePopUp(status)
   }

    billingScreenHandle = async () => {
    this.props.goToBillingScreen(true)
    this.props.history.push({
                        pathname: A12Y_CONST.ROUTE.DEPENDENT_BILLING,
                       state: {fromBillingScreen:true }
                       });
    }

    handleSurvey = async (data) => {
        let surveyData = {}
        if(data?.survey?.title) surveyData.Subscriber_Funnel_Source = data?.survey?.title
        if(data?.role?.title) surveyData["Any-other-field"] = data?.role?.title
        await secureClient.post(A12Y_CONST.API_ENDPOINT.KLAVIYO_STATUS, surveyData );
        await this.props.surveyPopUp(false)
        await this.props.surveyRolePopUp(false)
        let surveyTitle = data?.referralCourt ? data?.survey?.title + ' ' + data?.referralCourt : data?.survey?.title
        window.dataLayer.push({
            'survey': surveyTitle,
            'advocate_type':data?.role?.title
        });
    }

    surveySectionVisible = async (status) => {
        await this.props.surveyPopUp(status)
    }

    render() {
        return (
            <div className=" myProfileAccount animationall mainRightSidebare">
                <div className='rightPanelWidhtSidebar'>
                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                        <DashboardComponent
                        dependent={this.props.dependent}
                        userSubscriptionAllDetails={this.props.userSubscriptionAllDetails}
                        isTrialReminder={this.props.isTrialReminder}
                        goToScreen = {this.goToListScreenHandler}
                        goToAddScreen = {this.goToAddScreenHandler}
                        goToLandingScreen = {this.goToLandingScreenHandler}
                        userInfo={this.props.userState.userInfo}
                        trialReminderBoxHide={this.trialReminderBoxHide}
                        welcomePopUpStatus={this.props.IsWelcomePopUp}
                        IsSurveyRolePopUp={this.props.IsSurveyRolePopUp}
                        IsSurvey_PopUp={this.props.IsSurvey_PopUp}
                        surveySectionVisible={this.surveySectionVisible}
                        welcomePopUpClose={this.welcomePopUpClose}
                        goToBillingScreen={this.billingScreenHandle}
                        handleSurvey={this.handleSurvey}
                        surveyPopUpHandle={this.surveyPopUpHandle}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    userSubscriptionAllDetails : state.userDetailReducer.userSubscriptionAllDetails,
    assetTypeList: state.dependentReducer.assetTypeList,
    isTrialReminder : state.userDetailReducer.isTrialReminder,
    IsWelcomePopUp : state.userDetailReducer.IsWelcomePopUp,
    IsSurveyRolePopUp : state.userDetailReducer.IsSurveyRolePopUp,
    IsSurvey_PopUp : state.userDetailReducer.IsSurvey_PopUp,
    isBillingScreen : state.userDetailReducer.isBillingScreen,
    asset_present: state.dependentReducer.asset_present,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    medicine_present : state.dependentReducer.medicine_present,
    doctor_present : state.dependentReducer.doctor_present,
});

const mapDispatchToProps = dispatch => ({
    getAllDependent : (advocate_id) => dispatch(dependentActions.getAllDependent(advocate_id)),
    getAssetTypeList: () => dispatch(dependentActions.getAssetTypeList()),
    currentExpenseData : (expense) => dispatch(dependentActions.currentExpenseData(expense)),
    currentActivityData : (activity) => dispatch(dependentActions.currentActivityData(activity)),
    currentIncomeData : (income) => dispatch(dependentActions.currentIncomeData(income)),
    currentMedicationData: (medication) => dispatch(dependentActions.currentMedicationData(medication)),
    medicationData: (medication) => dispatch(dependentActions.medicationData(medication)),
    getSpecialtyList: (dependentId) => dispatch(dependentActions.getSpecialtyList(dependentId)),
    getAllDoctorList: (dependentId) => dispatch(dependentActions.getAllDoctorList(dependentId)),
    trialReminder : (status) => dispatch(userDetailActions.trialReminder(status)),
    welcomePopUp : (status) => dispatch(userDetailActions.welcomePopUp(status)),
    surveyRolePopUp : (status) => dispatch(userDetailActions.surveyRolePopUp(status)),
    surveyPopUp : (status) => dispatch(userDetailActions.surveyPopUp(status)),
    goToBillingScreen : (status) => dispatch(userDetailActions.goToBillingScreen(status)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
});


export default connect(mapStateToProps, mapDispatchToProps) (DashboardScreen);

