import React, {Component} from 'react'
import { connect } from 'react-redux';
import  * as dependentActions from '../redux/actions/dependentActions'
import DependentProfileAll from '../components/DependentProfileAll'
import DependentProfileVIew from '../screens/DependentProfileVIew'
import LoaderFullScreen from "../components/LoaderFullScreenComponent";

class DependentProfile extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            loaderFullScreenVisible : true,
            isDependentProfileCreated: false,
            overylayResponsive:false
        }
    }

    componentDidMount = async()=>{
        //await this.props.getAllDependent(this.props.userState.userInfo.id);
        if(this.props.dependent.id) {
            this.setState({isDependentProfileCreated:true, loaderFullScreenVisible : false});
        } else {
            this.setState({isDependentProfileCreated:false, loaderFullScreenVisible : false});
        }
    }

    handelDependentComplete = async()=>{
       // await this.props.getAllDependent(this.props.userState.userInfo.id)
    }

    onFinishHandler = ()=>{
        if(this.props.dependent && Object.keys(this.props.dependent) && Object.keys(this.props.dependent).length>0){
            this.setState({isDependentProfileCreated:true})
        }
    }
    overlay = (status) => {
        this.setState({overylayResponsive : status})
    }
    render() {
        return (
            <div>
                        { this.state.loaderFullScreenVisible ?
                            <div className={this.state.overylayResponsive ? "myProfileAccount animationall mainRightSidebare mainOverlay" : "myProfileAccount animationall mainRightSidebare"}>
                                <div className='rightPanelWidhtSidebar'>
                                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                                        <LoaderFullScreen/>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                            {
                            this.state.isDependentProfileCreated ?
                            <div className={this.state.overylayResponsive ? "myProfileAccount animationall mainRightSidebare mainOverlay" : "myProfileAccount animationall mainRightSidebare"}>
                                    <div className='rightPanelWidhtSidebar'>
                                        <div className="rightPanelWidhtSidebarContainer fullWidth">
                                        <DependentProfileVIew
                                        overlay = {this.overlay}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <DependentProfileAll
                                onCompleteDependent={this.handelDependentComplete}
                                onFinish={this.onFinishHandler}
                                overlay = {this.overlay}
                            />
                            }
                            </div>
                        }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userState : state.userDetailReducer.userState,
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
});

const mapDispatchToProps = dispatch => ({
    getAllDependent : (advocate_id) => dispatch(dependentActions.getAllDependent(advocate_id)),
});


export default connect(mapStateToProps, mapDispatchToProps) (DependentProfile);

