import React, {useEffect, useState} from 'react'
import A12Y_CONST from "../common/a12yConst";
import CONFIG from '../config/apiConfig'
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function WelcomeComponent(props) {
    /* eslint-disable no-unused-vars */
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [learningCenterURL, setLearningCenterURL] = useState('');
    /* eslint-disable no-unused-vars */
    const [isShowMore, setIsShowMore] = useState(false)

    useEffect(() => {
        if(props.welcomeScreenType === A12Y_CONST.LABEL.MEDICAL){
            setLearningCenterURL(CONFIG.MEDICAL)
        }
        if(props.welcomeScreenType === A12Y_CONST.LABEL.INVENTORY){
            setLearningCenterURL(CONFIG.INVENTORY)
        }
        if(props.welcomeScreenType === A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL){
            setLearningCenterURL(CONFIG.ACTIVITY)
        }
        if(props.welcomeScreenType === A12Y_CONST.LABEL.EXPENSES_LABEL){
            setLearningCenterURL(CONFIG.EXPENSES)
        }
        if(props.welcomeScreenType === A12Y_CONST.LABEL.INCOME_STREAMS){
            setLearningCenterURL(CONFIG.INCOME)
        }
        if(props.welcomeScreenType === A12Y_CONST.LABEL.DOCUMENTS){
            setLearningCenterURL(CONFIG.DOCUMENT)
        }
    },[props.welcomeScreenType])

    function clickHandler() {
        props.onCreate()
    }

    function clickHandlerNoButton() {
        props.gotToScreenAdvocateProfileMyAccount()
    }
    function logoutUser() {
        props.logoutUser()
    }

    const onSubmit=()=>{
        props.onSubmit(1,null);
    }
    const showMoreText = () => {
        setIsShowMore(!isShowMore)
    }

    return (
        <div className='fullWidth positionRelative zIndex2'>
            <div className="titleRow fullWidth margignTop768_13 margignBottom768_10 noActivityMain">
                <h2 className="titleWithSidebar noActivityMainTitle marginBottom30">{props.screenName}</h2>
            </div>
            <div className={`welcomeTitle marginTop40 marginBottom50 fullWidth maxWidth766`}>{props.title}</div>

            <div className="fullWidth">
                <div className={`welcomedescriptionLeftBox fullWidth marginRight50 maxWidth388 ${props.welcomeScreenType === A12Y_CONST.LABEL.INVENTORY || props.welcomeScreenType === A12Y_CONST.LABEL.MEDICAL || props.welcomeScreenType === A12Y_CONST.TEXT.YOUR_DEPENDENT ?'maxWidth600':null}`}>
                    <div className="welcomeDescriptionText welcomeDescriptionText1 marginBottom20 fullWidth">{props.description1}</div>
                    {/*{props.welcomeScreenType !== A12Y_CONST.LABEL.LOGOUT ? <div className="welcomeDescriptionText fullWidth">{props.description2}</div> : null}*/}
                    {windowDimensions.width < 768 && props.welcomeScreenType !== A12Y_CONST.LABEL.LOGOUT ?  isShowMore ? null : <div className='fullWidth'><span onClick={showMoreText} className='knowMore'>Know more</span></div>: null }
                    {props.welcomeScreenType !== A12Y_CONST.LABEL.LOGOUT ? <div className={`welcomeDescriptionText welcomeDescriptionText2 fullWidth  ${isShowMore?'displayBlock':''}`}>{props.description2}</div>:null}
                    {/* {isShowMore ? props.welcomeScreenType !== A12Y_CONST.LABEL.LOGOUT ? <div className="welcomeDescriptionText welcomeDescriptionText2 fullWidth">{props.description2}</div>:null : null} */}
                    {windowDimensions.width < 768 && props.welcomeScreenType !== A12Y_CONST.LABEL.LOGOUT ? isShowMore ?  <div className='fullWidth marginTop10 marginBottom10'><span onClick={showMoreText}  className='knowMore'>Show less</span></div> : null : null }
                </div>
                {props.welcomeScreenType === A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL || props.welcomeScreenType === A12Y_CONST.LABEL.EXPENSES_SMALL || props.welcomeScreenType === A12Y_CONST.TEXT.INCOME_STREAMS || props.welcomeScreenType === A12Y_CONST.LABEL.INVENTORY?
                <div className="descriptionrightBox fullWidth maxWidth750">
                    <h3 className='marginBottom20'>{props.descriptionTitle && props.descriptionTitle}</h3>
                    <ul>
                        <li>
                            <span className="welcomeRightTick">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_6959_64028)">
                                        <path d="M7.2386 10.4187L2.70417 5.81899C2.54949 5.66102 2.29774 5.66411 2.14609 5.81899L0.113939 7.90669C-0.0407467 8.06466 -0.0407467 8.32175 0.113939 8.47972C2.40087 10.7997 4.68476 13.1198 6.97169 15.4367C7.12637 15.5946 7.37812 15.5946 7.5328 15.4367L19.8865 2.76177C20.0381 2.6038 20.0381 2.34671 19.8834 2.18874L17.8391 0.116526C17.6845 -0.041446 17.4327 -0.041446 17.278 0.116526L7.2386 10.4187Z" fill="url(#paint0_linear_6959_64028)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_6959_64028" x1="9.99887" y1="-0.00195312" x2="9.99887" y2="15.5551" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FFA412"/>
                                            <stop offset="1" stopColor="#D96941"/>
                                        </linearGradient>
                                        <clipPath id="clip0_6959_64028">
                                            <rect width="20" height="15.5556" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span className="welcomeListText">
                                {props.descriptionList_1 && props.descriptionList_1}
                            </span>
                        </li>
                        <li>
                            <span className="welcomeRightTick">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_6959_64028)">
                                        <path d="M7.2386 10.4187L2.70417 5.81899C2.54949 5.66102 2.29774 5.66411 2.14609 5.81899L0.113939 7.90669C-0.0407467 8.06466 -0.0407467 8.32175 0.113939 8.47972C2.40087 10.7997 4.68476 13.1198 6.97169 15.4367C7.12637 15.5946 7.37812 15.5946 7.5328 15.4367L19.8865 2.76177C20.0381 2.6038 20.0381 2.34671 19.8834 2.18874L17.8391 0.116526C17.6845 -0.041446 17.4327 -0.041446 17.278 0.116526L7.2386 10.4187Z" fill="url(#paint0_linear_6959_64028)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_6959_64028" x1="9.99887" y1="-0.00195312" x2="9.99887" y2="15.5551" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FFA412"/>
                                            <stop offset="1" stopColor="#D96941"/>
                                        </linearGradient>
                                        <clipPath id="clip0_6959_64028">
                                            <rect width="20" height="15.5556" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span className="welcomeListText">
                                {props.descriptionList_2 && props.descriptionList_2}
                            </span>
                        </li>
                        <li>
                            <span className="welcomeRightTick">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_6959_64028)">
                                        <path d="M7.2386 10.4187L2.70417 5.81899C2.54949 5.66102 2.29774 5.66411 2.14609 5.81899L0.113939 7.90669C-0.0407467 8.06466 -0.0407467 8.32175 0.113939 8.47972C2.40087 10.7997 4.68476 13.1198 6.97169 15.4367C7.12637 15.5946 7.37812 15.5946 7.5328 15.4367L19.8865 2.76177C20.0381 2.6038 20.0381 2.34671 19.8834 2.18874L17.8391 0.116526C17.6845 -0.041446 17.4327 -0.041446 17.278 0.116526L7.2386 10.4187Z" fill="url(#paint0_linear_6959_64028)"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_6959_64028" x1="9.99887" y1="-0.00195312" x2="9.99887" y2="15.5551" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FFA412"/>
                                            <stop offset="1" stopColor="#D96941"/>
                                        </linearGradient>
                                        <clipPath id="clip0_6959_64028">
                                            <rect width="20" height="15.5556" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span className="welcomeListText">
                                {props.descriptionList_3 && props.descriptionList_3}
                            </span>
                        </li>
                    </ul>
                </div>
                :
                null
                }

            </div>

            {props.welcomeScreenType === A12Y_CONST.LABEL.LOGOUT ?
                <div className="welcomeButtonRow fullWidth marginTop45">
                    <input id={A12Y_CONST.BUTTON_TEXT.NO+A12Y_CONST.BUTTON_ID.BUTTON} className={`welcomeScreenButton maxWidth200 marginRight20`} type="button" onClick={clickHandlerNoButton} value={A12Y_CONST.BUTTON_TEXT.NO} />
                    <input id={A12Y_CONST.BUTTON_ID.LOGOUT+A12Y_CONST.BUTTON_ID.BUTTON} className={`welcomeScreenButton maxWidth200`} type="button" onClick={logoutUser} value={A12Y_CONST.BUTTON_TEXT.LOG_OUT} />
                </div>
                :
                props.welcomeScreenType === A12Y_CONST.TEXT.YOUR_DEPENDENT ?
                    <div className="welcomeButtonRow fullWidth marginTop45">
                        <input className={`welcomeScreenButton maxWidth315`} type="button" onClick={onSubmit} value={props.buttonTitle} />
                    </div>
                :
                <div className="welcomeButtonRow fullWidth marginTop45">
                    {(props.dependent?.access && props.dependent.access.can_update === true) || (props.buttonTitle === A12Y_CONST.BUTTON_TEXT.BACK_TO_ACTIVITY || props.buttonTitle === A12Y_CONST.BUTTON_TEXT.BACK_TO_INCOME || props.buttonTitle === A12Y_CONST.BUTTON_TEXT.BACK_TO_EXPENSE || props.buttonTitle === A12Y_CONST.BUTTON_TEXT.BACK_TO_INVENTORY || props.buttonTitle === A12Y_CONST.BUTTON_TEXT.BACK_TO_MEDICAL || props.buttonTitle === A12Y_CONST.BUTTON_TEXT.BACK_TO_DOCUMENTS) ?
                        <>

                        {props.welcomeScreenType === A12Y_CONST.LABEL.INVENTORY && window.outerWidth < 768 ?
                            (props.buttonTitle === A12Y_CONST.BUTTON_TEXT.BACK_TO_INVENTORY
                                ?
                                    <input id={A12Y_CONST.BUTTON_ID.WELCOME_SCREEN_BACK_BUTTON} className={`welcomeScreenButton marginRight20 marginBottom20 ${props.welcomeScreenType === A12Y_CONST.LABEL.MEDICAL?'maxWidth300':null}`} type="button" onClick={clickHandler} value={props.buttonTitle} />
                                    :
                                    null
                            )
                            :
                            <input  id={A12Y_CONST.BUTTON_ID.WELCOME_SCREEN_BACK_BUTTON} className={`welcomeScreenButton marginRight20 marginBottom20 ${props.welcomeScreenType === A12Y_CONST.LABEL.MEDICAL?'maxWidth300':null}`} type="button" onClick={clickHandler} value={props.buttonTitle} />
                        }
                        {
                            props.title === A12Y_CONST.TEXT.MEDICAL_DETAILS_AT && props.isOverview !== undefined && !props.isOverView && props.buttonTitle !== A12Y_CONST.BUTTON_TEXT.BACK_TO_MEDICAL ?
                                <input  id={A12Y_CONST.BUTTON_ID.WELCOME_SCREEN_BACK_BUTTON} className={`welcomeScreenButton ${props.welcomeScreenType === A12Y_CONST.LABEL.MEDICAL?'maxWidth300':null}`} type="button" onClick={props.onCreateDoctorScreen} value={A12Y_CONST.BUTTON_TEXT.ADD_FIRST_DOCTOR} />
                                :
                            null
                        }
                        </>
                        :
                        null
                    }
                </div>
            }
            {props.welcomeScreenType !== A12Y_CONST.LABEL.LOGOUT ?
            <div className='advocordLearningCenter fullWidth marginTop20'>
                <div>{A12Y_CONST.TEXT.WANT_MORE_INFORMATION}</div>
                <div><a id={A12Y_CONST.BUTTON_ID.WELCOMESCREEN_CLICK_HERE} rel="noreferrer" target='_blank' href={learningCenterURL}>{A12Y_CONST.TEXT.CLICK_HERE}</a> {A12Y_CONST.TEXT.TO_VISIT_THE_ADVOCORD}</div>
            </div>
                :
            null
            }

        </div>
    )
}

export default WelcomeComponent
