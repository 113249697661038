import React, { useState, useEffect}from 'react';
import A12Y_CONST from "../common/a12yConst";

function  PopUpComponent (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const hidePopUp = () => {
        props.hidePopUp()
    }

    useEffect(()=>{
        if(props.title){
            setTitle(props.title)
        }
        if(props.message){
            setMessage(props.message)
        }
    },[props.title,props.message])

    const handleDeleteButton = () => {
        props.handleDeleteButton()
    }
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className="cancelIcon fullWidth marginTopBottom20">

                                        {props.popUpType && props.popUpType === 'Cancel' ?
                                            <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M34 6.8C18.9788 6.8 6.8 18.9788 6.8 34C6.8 49.0212 18.9788 61.2 34 61.2C49.0212 61.2 61.2 49.0212 61.2 34C61.2 18.9788 49.0212 6.8 34 6.8ZM0 34C0 15.2218 15.2218 0 34 0C52.7782 0 68 15.2218 68 34C68 52.7782 52.7782 68 34 68C15.2218 68 0 52.7782 0 34ZM19.6962 19.6962C21.0222 18.3668 23.1778 18.3668 24.5038 19.6962L34 29.1924L43.4962 19.6962C44.8222 18.3668 46.9778 18.3668 48.3038 19.6962C49.6332 21.0222 49.6332 23.1778 48.3038 24.5038L38.8076 34L48.3038 43.4962C49.6332 44.8222 49.6332 46.9778 48.3038 48.3038C46.9778 49.6332 44.8222 49.6332 43.4962 48.3038L34 38.8076L24.5038 48.3038C23.1778 49.6332 21.0222 49.6332 19.6962 48.3038C18.3668 46.9778 18.3668 44.8222 19.6962 43.4962L29.1924 34L19.6962 24.5038C18.3668 23.1778 18.3668 21.0222 19.6962 19.6962Z" fill="#D83933"/>
                                            </svg>
                                          :
                                            props.popUpType === A12Y_CONST.TEXT.DOCUMENT_TYPE || props.popUpType === A12Y_CONST.TEXT.REMOVE_EXPENSE?
                                                <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M34 6.8C18.9788 6.8 6.8 18.9788 6.8 34C6.8 49.0212 18.9788 61.2 34 61.2C49.0212 61.2 61.2 49.0212 61.2 34C61.2 18.9788 49.0212 6.8 34 6.8ZM0 34C0 15.2218 15.2218 0 34 0C52.7782 0 68 15.2218 68 34C68 52.7782 52.7782 68 34 68C15.2218 68 0 52.7782 0 34ZM19.6962 19.6962C21.0222 18.3668 23.1778 18.3668 24.5038 19.6962L34 29.1924L43.4962 19.6962C44.8222 18.3668 46.9778 18.3668 48.3038 19.6962C49.6332 21.0222 49.6332 23.1778 48.3038 24.5038L38.8076 34L48.3038 43.4962C49.6332 44.8222 49.6332 46.9778 48.3038 48.3038C46.9778 49.6332 44.8222 49.6332 43.4962 48.3038L34 38.8076L24.5038 48.3038C23.1778 49.6332 21.0222 49.6332 19.6962 48.3038C18.3668 46.9778 18.3668 44.8222 19.6962 43.4962L29.1924 34L19.6962 24.5038C18.3668 23.1778 18.3668 21.0222 19.6962 19.6962Z" fill="#D83933"/>
                                                </svg>
                                                :
                                            props.popUpType && props.popUpType === A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION ?
                                                <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M65 71.5H13C12.138 71.5 11.3114 71.1576 10.7019 70.5481C10.0924 69.9386 9.75 69.112 9.75 68.25V9.75C9.75 8.88805 10.0924 8.0614 10.7019 7.4519C11.3114 6.84241 12.138 6.5 13 6.5H65C65.862 6.5 66.6886 6.84241 67.2981 7.4519C67.9076 8.0614 68.25 8.88805 68.25 9.75V68.25C68.25 69.112 67.9076 69.9386 67.2981 70.5481C66.6886 71.1576 65.862 71.5 65 71.5ZM61.75 65V13H16.25V65H61.75ZM26 22.75H52V29.25H26V22.75ZM26 35.75H52V42.25H26V35.75ZM26 48.75H42.25V55.25H26V48.75Z" fill="#112E53"/>
                                                    <circle cx="62.3992" cy="63.7" r="11.7" fill="white" stroke="#D96941" strokeWidth="3"/>
                                                    <path d="M70.1992 71.5L54.5992 55.9" stroke="#D96941" strokeWidth="3"/>
                                                </svg>
                                                :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
                                                <path d="M29 5.8C16.1869 5.8 5.8 16.1878 5.8 29C5.8 41.8122 16.1869 52.2 29 52.2C41.8131 52.2 52.2 41.8122 52.2 29C52.2 16.1878 41.8131 5.8 29 5.8ZM0 29C0 12.9833 12.9839 0 29 0C45.0161 0 58 12.9833 58 29C58 45.0167 45.0161 58 29 58C12.9839 58 0 45.0167 0 29Z" fill="#D83933"/>
                                                <path d="M29.0001 34.7996C27.3984 34.7996 26.1001 33.5004 26.1001 31.8996V14.4996C26.1001 12.8988 27.3984 11.5996 29.0001 11.5996C30.6018 11.5996 31.9001 12.8988 31.9001 14.4996V31.8996C31.9001 33.5004 30.6018 34.7996 29.0001 34.7996Z" fill="#D83933"/>
                                                <path d="M24.6499 42.0512C24.6499 39.65 26.5975 37.7012 28.9999 37.7012C31.4023 37.7012 33.3499 39.65 33.3499 42.0512C33.3499 44.4524 31.4023 46.4012 28.9999 46.4012C26.5975 46.4012 24.6499 44.4524 24.6499 42.0512Z" fill="#D83933"/>
                                            </svg>

                                        }



                                    </div>
                                    <div className={`areYouText areYouDmFont ${props.popUpType === A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION?'fontWeight400':null}`}>
                                        {title}
                                    </div>
                                    {props.popUpType === A12Y_CONST.TEXT.REMOVE_EXPENSE ?
                                    <div className={'useInfo marginTop10'}>
                                        {message}
                                    </div>
                                    :
                                    <div className={props.maxWidth ==='maxWidth' || props.popUpType === A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION ? 'popUpMessageWidth useInfo removeExpDescription marginTop10' : 'useInfo marginTop10'}>
                                        {message}
                                    </div>
                                    }
                                    <div className="fullWidth marginTop45 marginBottom20 texAlingCener">
                                        <div id={props.buttonDeleteTxt+A12Y_CONST.BUTTON_ID.BUTTON} className="popUpYesButton marginLeftRight2percent" onClick={handleDeleteButton} >{props.buttonDeleteTxt}</div>
                                        <div id={props.buttonCancelTxt+A12Y_CONST.BUTTON_ID.BUTTON} className=" popUpNoButton" onClick={hidePopUp}>{props.buttonCancelTxt}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpComponent;