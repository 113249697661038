import React, { useState, useEffect}from 'react';
import A12Y_CONST from "../common/a12yConst";

function  PopUpAssetComponent (props) {
    const [isPopUpVisible, setIsPopUpVisible] = useState(props.popUpStatus);
    /* eslint-disable no-unused-vars */
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    /* eslint-disable no-unused-vars */

    useEffect(()=> {
        setIsPopUpVisible(props.popUpStatus);
    },[props.popUpStatus])

    const hidePopUp = () => {
        props.hidePopUp()
    }

    useEffect(()=>{
        if(props.title){
            setTitle(props.title)
        }
        if(props.message){
            setMessage(props.message)
        }
    },[props.title,props.message])

    const assetPopUpHide = () => {
        props.assetPopUpHide()
    }
    return (
        <div>
            {
                isPopUpVisible ?
                    <div className='popTop'>
                        <div className='popContainer popMaxWidth'>
                            <div className='popMaster'>
                                <div className="popUpMainBg fullWidth">
                                    <div className="popUpAssetIcon texAlingCener fullWidth marginTop50 marginBottom20">
                                        {props.currentAsset?.asset_type_key === 'real_estate' || props.currentAsset?.asset_type_key === 'automobile' || props.currentAsset?.asset_type_key === 'personal_property' || props.currentAsset?.asset_type_key === 'other'?
                                            <svg width="103" height="114" viewBox="0 0 103 114" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_6523_54392)">
                                                    <path
                                                        d="M93.25 0.429688H8.73997C7.78263 0.431 6.83493 0.620864 5.95096 0.988434C5.067 1.356 4.2641 1.89409 3.58809 2.57196C2.91208 3.24983 2.3762 4.05418 2.01106 4.93915C1.64591 5.82411 1.45865 6.77234 1.45997 7.72968V104.2C1.45865 105.157 1.64591 106.105 2.01106 106.99C2.3762 107.875 2.91208 108.68 3.58809 109.357C4.2641 110.035 5.067 110.573 5.95096 110.941C6.83493 111.309 7.78263 111.498 8.73997 111.5H93.25C94.2073 111.498 95.155 111.309 96.039 110.941C96.9229 110.573 97.7258 110.035 98.4019 109.357C99.0779 108.68 99.6137 107.875 99.9789 106.99C100.344 106.105 100.531 105.157 100.53 104.2V7.72968C100.531 6.77234 100.344 5.82411 99.9789 4.93915C99.6137 4.05418 99.0779 3.24983 98.4019 2.57196C97.7258 1.89409 96.9229 1.356 96.039 0.988434C95.155 0.620864 94.2073 0.431 93.25 0.429688Z"
                                                        fill="white"/>
                                                    <path
                                                        d="M93.2503 114H8.77028C7.62175 113.999 6.48468 113.772 5.4242 113.331C4.36372 112.89 3.40069 112.244 2.59027 111.43C0.951868 109.783 0.031602 107.554 0.0302734 105.23V8.76022C0.031602 6.43663 0.951868 4.20788 2.59027 2.56024C3.40102 1.74684 4.36411 1.10119 5.42451 0.660217C6.48492 0.219241 7.62184 -0.00842324 8.77028 -0.00976562H93.2503C94.3987 -0.00842324 95.5356 0.219241 96.596 0.660217C97.6564 1.10119 98.6195 1.74684 99.4303 2.56024C101.069 4.20788 101.989 6.43663 101.99 8.76022V105.23C101.989 107.554 101.069 109.783 99.4303 111.43C98.619 112.242 97.6556 112.886 96.5952 113.326C95.5348 113.765 94.3981 113.991 93.2503 113.99V114ZM8.77028 2.93024C8.00483 2.93033 7.24692 3.08142 6.53994 3.37485C5.83297 3.66828 5.19082 4.09827 4.65028 4.64023C3.5579 5.7375 2.94329 7.22192 2.94027 8.77023V105.24C2.94329 106.789 3.5579 108.273 4.65028 109.37C5.19082 109.912 5.83297 110.342 6.53994 110.636C7.24692 110.929 8.00483 111.08 8.77028 111.08H93.2503C94.0159 111.081 94.7741 110.931 95.4812 110.637C96.1884 110.344 96.8304 109.913 97.3703 109.37C98.4626 108.273 99.0773 106.789 99.0803 105.24V8.77023C99.0773 7.22192 98.4626 5.7375 97.3703 4.64023C96.8304 4.09738 96.1884 3.66681 95.4812 3.37332C94.7741 3.07983 94.0159 2.92924 93.2503 2.93024H8.77028Z"
                                                        fill="#2D6CB2"/>
                                                    <g opacity="0.99">
                                                        <path opacity="0.99"
                                                              d="M85.0004 71.13C85.7687 70.364 86.3771 69.4531 86.7904 68.45C87.2081 67.4454 87.4222 66.3679 87.4204 65.28C87.422 64.1953 87.2078 63.1211 86.7904 62.12C86.3817 61.1145 85.7727 60.2027 85.0004 59.44C84.2386 58.6688 83.331 58.0569 82.3304 57.64C81.3299 57.2194 80.2557 57.0018 79.1704 57C78.0851 57.0018 77.0109 57.2194 76.0104 57.64C75.0085 58.0545 74.1004 58.6667 73.3404 59.44C72.5702 60.2026 71.9585 61.11 71.5404 62.11C71.1195 63.1139 70.902 64.1914 70.9004 65.28C70.9012 66.3687 71.1187 67.4463 71.5404 68.45C71.9608 69.4517 72.5722 70.3619 73.3404 71.13C74.1051 71.8972 75.0137 72.5059 76.0142 72.9211C77.0146 73.3364 78.0872 73.5501 79.1704 73.55C81.3577 73.5481 83.4547 72.6776 85.0004 71.13Z"
                                                              fill="white"/>
                                                    </g>
                                                    <g opacity="0.99">
                                                        <path opacity="0.99"
                                                              d="M78.29 95.5691C76.8109 95.5749 75.3454 95.2863 73.979 94.7201C72.6125 94.1539 71.3724 93.3214 70.3309 92.2711C69.2756 91.2304 68.4385 89.9896 67.8685 88.6214C67.2955 87.2492 67.0003 85.7771 67 84.2901C67.0003 82.8066 67.2955 81.3381 67.8685 79.9697C68.4356 78.5968 69.273 77.3519 70.3309 76.309C71.3682 75.2573 72.6054 74.4237 73.9697 73.8575C76.7364 72.7142 79.8436 72.7142 82.6103 73.8575C83.9778 74.4288 85.2184 75.2658 86.2601 76.32C87.3053 77.3646 88.1345 78.6048 88.7006 79.9697C89.2692 81.339 89.5607 82.8074 89.5581 84.2901C89.5615 85.7728 89.2701 87.2414 88.7006 88.6104C88.1375 89.9829 87.3038 91.2279 86.2491 92.2711C85.2069 93.3204 83.9666 94.1523 82.6003 94.7184C81.2341 95.2845 79.7689 95.5737 78.29 95.5691ZM78.29 75.1767C77.0969 75.1787 75.9161 75.4179 74.8162 75.8803C73.7189 76.3387 72.7218 77.0071 71.881 77.8481C71.0354 78.6915 70.3631 79.6924 69.9022 80.7942C69.4395 81.8979 69.2004 83.0824 69.1986 84.2791C69.1995 85.4759 69.4387 86.6605 69.9022 87.7639C70.3577 88.8687 71.0306 89.8706 71.881 90.7101C72.7202 91.5555 73.7188 92.2259 74.8189 92.6825C75.919 93.1392 77.0989 93.373 78.29 93.3705C79.4812 93.373 80.661 93.1392 81.7611 92.6825C82.8613 92.2259 83.8599 91.5555 84.6991 90.7101C85.5425 89.8672 86.2112 88.8659 86.6668 87.7639C87.126 86.6596 87.3614 85.4751 87.3594 84.2791C87.3612 83.0867 87.1257 81.9058 86.6668 80.8052C86.2112 79.7032 85.5425 78.702 84.6991 77.8591C83.8616 77.0113 82.8639 76.3386 81.7639 75.8803C80.664 75.4179 79.4831 75.1787 78.29 75.1767Z"
                                                              fill="#2D6CB2"/>
                                                        <path
                                                            d="M78.501 86C77.9487 86 77.501 85.552 77.501 85V79C77.501 78.448 77.9487 78 78.501 78C79.0533 78 79.501 78.448 79.501 79V85C79.501 85.552 79.0533 86 78.501 86Z"
                                                            fill="#0598C8"/>
                                                        <path
                                                            d="M77 88.5C77 87.672 77.6716 87 78.5 87C79.3284 87 80 87.672 80 88.5C80 89.328 79.3284 90 78.5 90C77.6716 90 77 89.328 77 88.5Z"
                                                            fill="#0598C8"/>
                                                    </g>
                                                    <path
                                                        d="M54.2496 40.8505H15.2496C15.0672 40.8465 14.8927 40.7753 14.7596 40.6505C14.6313 40.5196 14.5595 40.3437 14.5596 40.1605C14.5592 40.0712 14.5768 39.9827 14.6111 39.9003C14.6455 39.8179 14.6959 39.7431 14.7596 39.6805C14.8904 39.5523 15.0664 39.4804 15.2496 39.4805H54.2496C54.3389 39.4801 54.4273 39.4977 54.5098 39.532C54.5922 39.5664 54.6669 39.6168 54.7296 39.6805C54.7932 39.7431 54.8437 39.8179 54.878 39.9003C54.9124 39.9827 54.9299 40.0712 54.9296 40.1605C54.9296 40.3437 54.8578 40.5196 54.7296 40.6505C54.6001 40.7746 54.4289 40.846 54.2496 40.8505Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M54.2496 41.3496H15.2496C14.9347 41.3467 14.6334 41.2211 14.4096 40.9996C14.1862 40.777 14.0603 40.4749 14.0596 40.1596C14.0619 39.8475 14.1877 39.5491 14.4096 39.3296C14.5184 39.2172 14.649 39.1281 14.7935 39.068C14.9379 39.0078 15.0931 38.9777 15.2496 38.9796H54.2496C54.4044 38.9785 54.5579 39.009 54.7006 39.0691C54.8433 39.1293 54.9723 39.2179 55.0796 39.3296C55.1912 39.4369 55.2798 39.5658 55.34 39.7085C55.4002 39.8512 55.4307 40.0047 55.4296 40.1596C55.4314 40.3161 55.4014 40.4712 55.3412 40.6157C55.281 40.7601 55.192 40.8908 55.0796 40.9996C54.8613 41.2172 54.5677 41.3426 54.2596 41.3496H54.2496ZM15.2496 39.9796C15.2015 39.9792 15.155 39.9971 15.1196 40.0296C15.1012 40.046 15.0864 40.066 15.0761 40.0884C15.0658 40.1107 15.0601 40.135 15.0596 40.1596C15.0588 40.1844 15.0638 40.2091 15.0742 40.2317C15.0846 40.2542 15.1002 40.2741 15.1196 40.2896C15.1351 40.309 15.1549 40.3245 15.1775 40.335C15.2 40.3454 15.2247 40.3504 15.2496 40.3496H54.2496C54.2742 40.349 54.2984 40.3434 54.3208 40.3331C54.3432 40.3227 54.3632 40.3079 54.3796 40.2896C54.4121 40.2541 54.43 40.2077 54.4296 40.1596C54.4282 40.1118 54.4105 40.066 54.3796 40.0296C54.3432 39.9986 54.2973 39.981 54.2496 39.9796H15.2496Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.4596 48.9003H15.2496C15.0664 48.9003 14.8905 48.8285 14.7596 48.7003C14.6952 48.6361 14.6443 48.5597 14.61 48.4755C14.5756 48.3914 14.5585 48.3012 14.5596 48.2103C14.5593 48.121 14.5768 48.0325 14.6112 47.9501C14.6455 47.8676 14.696 47.7929 14.7596 47.7303C14.8905 47.602 15.0664 47.5303 15.2496 47.5303H47.4596C47.5489 47.5299 47.6374 47.5474 47.7198 47.5818C47.8022 47.6161 47.877 47.6666 47.9396 47.7303C48.0033 47.7929 48.0537 47.8676 48.0881 47.9501C48.1224 48.0325 48.1399 48.121 48.1396 48.2103C48.1407 48.3012 48.1236 48.3914 48.0892 48.4755C48.0549 48.5597 48.004 48.6361 47.9396 48.7003C47.877 48.7639 47.8022 48.8144 47.7198 48.8487C47.6374 48.8831 47.5489 48.9006 47.4596 48.9003Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.4596 49.4296H15.2496C15.0931 49.4315 14.9379 49.4015 14.7935 49.3413C14.649 49.2811 14.5184 49.1921 14.4096 49.0796C14.1862 48.8571 14.0603 48.555 14.0596 48.2396C14.0619 47.9276 14.1877 47.6292 14.4096 47.4097C14.5184 47.2972 14.649 47.2082 14.7935 47.148C14.9379 47.0879 15.0931 47.0578 15.2496 47.0597H47.4596C47.7615 47.0734 48.0457 47.2065 48.2496 47.4296C48.4714 47.6491 48.5972 47.9476 48.5996 48.2596C48.5988 48.5749 48.4729 48.8771 48.2496 49.0997C48.0391 49.3081 47.7558 49.4265 47.4596 49.4296ZM15.2496 48.0297C15.2076 48.0144 15.1616 48.0144 15.1196 48.0297C15.0834 48.0635 15.0619 48.1102 15.0596 48.1597C15.0588 48.1845 15.0638 48.2092 15.0742 48.2317C15.0846 48.2543 15.1002 48.2741 15.1196 48.2896C15.1233 48.3238 15.138 48.356 15.1614 48.3812C15.1848 48.4064 15.2157 48.4234 15.2496 48.4296H47.4596C47.5091 48.4274 47.5557 48.4058 47.5896 48.3697C47.6049 48.3277 47.6049 48.2816 47.5896 48.2396C47.6033 48.2008 47.6033 48.1585 47.5896 48.1197C47.5557 48.0835 47.5091 48.0619 47.4596 48.0597L15.2496 48.0297Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M64.3096 56.9401H15.2496C15.0664 56.9401 14.8905 56.8683 14.7596 56.7401C14.696 56.6774 14.6455 56.6027 14.6111 56.5203C14.5768 56.4378 14.5593 56.3494 14.5596 56.2601C14.5587 56.1707 14.576 56.0821 14.6103 55.9995C14.6447 55.917 14.6955 55.8424 14.7596 55.7801C14.8905 55.6519 15.0664 55.5801 15.2496 55.5801H64.2496C64.3389 55.5797 64.4274 55.5973 64.5098 55.6316C64.5922 55.666 64.667 55.7165 64.7296 55.7801C64.7937 55.8424 64.8445 55.917 64.8789 55.9995C64.9132 56.0821 64.9305 56.1707 64.9296 56.2601C64.9299 56.3494 64.9124 56.4378 64.8781 56.5203C64.8437 56.6027 64.7932 56.6774 64.7296 56.7401C64.667 56.8037 64.5922 56.8542 64.5098 56.8886C64.4274 56.9229 64.3389 56.9404 64.2496 56.9401H64.3096Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M64.3096 57.4303H15.2496C14.9359 57.4311 14.6344 57.3091 14.4096 57.0903C14.188 56.8665 14.0625 56.5652 14.0596 56.2503C14.0625 55.9354 14.188 55.6341 14.4096 55.4103C14.6344 55.1916 14.9359 55.0695 15.2496 55.0703H64.2496C64.4035 55.0696 64.556 55.0993 64.6984 55.1576C64.8409 55.2159 64.9704 55.3018 65.0796 55.4103C65.1915 55.5195 65.2802 55.6502 65.3404 55.7946C65.4005 55.9389 65.4309 56.0939 65.4296 56.2503C65.4304 56.564 65.3083 56.8655 65.0896 57.0903C64.8661 57.3049 64.5694 57.4265 64.2596 57.4303H64.3096ZM15.3096 56.0703C15.2615 56.0699 15.215 56.0878 15.1796 56.1203C15.1602 56.1358 15.1446 56.1557 15.1342 56.1782C15.1238 56.2008 15.1188 56.2255 15.1196 56.2503C15.1201 56.2749 15.1258 56.2992 15.1361 56.3215C15.1464 56.3439 15.1612 56.3639 15.1796 56.3803C15.1988 56.4021 15.2227 56.4192 15.2496 56.4303H64.2496C64.2973 56.4289 64.3432 56.4113 64.3796 56.3803C64.3933 56.3381 64.3933 56.2926 64.3796 56.2503C64.3949 56.2083 64.3949 56.1623 64.3796 56.1203C64.3432 56.0893 64.2973 56.0717 64.2496 56.0703H15.3096Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M53.2496 64.9901H15.2496C15.0664 64.9902 14.8905 64.9184 14.7596 64.7901C14.6952 64.726 14.6443 64.6496 14.61 64.5654C14.5756 64.4812 14.5585 64.391 14.5596 64.3001C14.5593 64.2108 14.5768 64.1224 14.6112 64.04C14.6455 63.9575 14.696 63.8828 14.7596 63.8202C14.8905 63.6919 15.0664 63.6201 15.2496 63.6202H53.2496C53.339 63.6192 53.4276 63.6365 53.5101 63.6709C53.5927 63.7053 53.6673 63.7561 53.7296 63.8202C53.7933 63.8828 53.8437 63.9575 53.8781 64.04C53.9124 64.1224 53.93 64.2108 53.9296 64.3001C53.9307 64.391 53.9136 64.4812 53.8792 64.5654C53.8449 64.6496 53.794 64.726 53.7296 64.7901C53.6673 64.8542 53.5927 64.905 53.5101 64.9394C53.4276 64.9738 53.339 64.9911 53.2496 64.9901Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M53.2496 65.4902H15.2496C14.9343 65.4894 14.6321 65.3635 14.4096 65.1402C14.1862 64.9176 14.0603 64.6155 14.0596 64.3002C14.0619 63.9881 14.1877 63.6897 14.4096 63.4702C14.5184 63.3578 14.649 63.2688 14.7935 63.2086C14.9379 63.1484 15.0931 63.1183 15.2496 63.1202H53.2496C53.5644 63.1231 53.8658 63.2487 54.0896 63.4702C54.198 63.5794 54.2839 63.7089 54.3423 63.8513C54.4006 63.9937 54.4303 64.1463 54.4296 64.3002C54.431 64.4557 54.4018 64.61 54.3434 64.7541C54.2851 64.8983 54.1988 65.0295 54.0896 65.1402C53.8671 65.3576 53.5705 65.4827 53.2596 65.4902H53.2496ZM15.2496 64.1202C15.2015 64.1198 15.155 64.1377 15.1196 64.1702C15.0834 64.204 15.0619 64.2507 15.0596 64.3002C15.0588 64.325 15.0638 64.3497 15.0742 64.3723C15.0846 64.3948 15.1002 64.4147 15.1196 64.4302C15.1351 64.4496 15.1549 64.4651 15.1775 64.4755C15.2 64.486 15.2247 64.491 15.2496 64.4902H53.2496C53.2744 64.491 53.2991 64.486 53.3217 64.4755C53.3442 64.4651 53.364 64.4496 53.3796 64.4302C53.3978 64.4142 53.4119 64.394 53.4206 64.3714C53.4293 64.3487 53.4324 64.3243 53.4296 64.3002C53.4303 64.2763 53.4262 64.2525 53.4177 64.2302C53.4091 64.2078 53.3961 64.1874 53.3796 64.1702C53.3441 64.1377 53.2977 64.1198 53.2496 64.1202H15.2496Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.4596 73.0497H15.2496C15.0672 73.0457 14.8927 72.9745 14.7596 72.8497C14.6313 72.7188 14.5595 72.5429 14.5596 72.3597C14.5592 72.2704 14.5768 72.1819 14.6111 72.0995C14.6455 72.0171 14.6959 71.9423 14.7596 71.8797C14.8904 71.7515 15.0664 71.6797 15.2496 71.6797H47.4596C47.5489 71.6794 47.6373 71.6969 47.7198 71.7312C47.8022 71.7656 47.8769 71.8161 47.9396 71.8797C48.0032 71.9423 48.0537 72.0171 48.088 72.0995C48.1224 72.1819 48.1399 72.2704 48.1396 72.3597C48.1396 72.5429 48.0678 72.7188 47.9396 72.8497C47.8101 72.9738 47.6389 73.0451 47.4596 73.0497Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.4596 73.5497H15.2496C14.9347 73.5468 14.6334 73.4212 14.4096 73.1997C14.1862 72.9771 14.0603 72.675 14.0596 72.3597C14.0619 72.0477 14.1877 71.7492 14.4096 71.5297C14.5188 71.4178 14.6495 71.3291 14.7938 71.2689C14.9382 71.2088 15.0932 71.1784 15.2496 71.1797H47.4596C47.6135 71.179 47.766 71.2087 47.9084 71.267C48.0509 71.3254 48.1804 71.4112 48.2896 71.5197C48.4006 71.6296 48.4887 71.7604 48.5488 71.9046C48.6089 72.0488 48.6397 72.2035 48.6396 72.3597C48.6414 72.5162 48.6114 72.6714 48.5512 72.8158C48.491 72.9602 48.402 73.0909 48.2896 73.1997C48.0701 73.4215 47.7716 73.5474 47.4596 73.5497ZM15.2496 72.1797C15.2015 72.1793 15.155 72.1972 15.1196 72.2297C15.1012 72.2461 15.0864 72.2661 15.0761 72.2885C15.0658 72.3109 15.0601 72.3351 15.0596 72.3597C15.0588 72.3846 15.0638 72.4092 15.0742 72.4318C15.0846 72.4544 15.1002 72.4742 15.1196 72.4897C15.1351 72.5091 15.1549 72.5247 15.1775 72.5351C15.2 72.5455 15.2247 72.5505 15.2496 72.5497H47.4596C47.5091 72.5474 47.5557 72.5259 47.5896 72.4897C47.6049 72.4477 47.6049 72.4017 47.5896 72.3597C47.6033 72.3175 47.6033 72.272 47.5896 72.2297C47.5521 72.2006 47.5069 72.1832 47.4596 72.1797H15.2496Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M55.2496 81.0897H15.2496C15.0664 81.0897 14.8904 81.0179 14.7596 80.8897C14.6959 80.8271 14.6455 80.7524 14.6111 80.6699C14.5768 80.5875 14.5592 80.499 14.5596 80.4097C14.5595 80.2265 14.6313 80.0506 14.7596 79.9197C14.8927 79.7949 15.0672 79.7237 15.2496 79.7197H55.2496C55.4293 79.7223 55.6012 79.7939 55.7296 79.9197C55.8606 80.0496 55.9359 80.2253 55.9396 80.4097C55.9381 80.4996 55.9187 80.5884 55.8827 80.6707C55.8466 80.7531 55.7946 80.8276 55.7296 80.8897C55.6673 80.9538 55.5926 81.0046 55.5101 81.039C55.4276 81.0734 55.339 81.0906 55.2496 81.0897Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M55.2496 81.5897H15.2496C15.0931 81.5916 14.9379 81.5615 14.7935 81.5013C14.649 81.4412 14.5184 81.3521 14.4096 81.2397C14.1877 81.0202 14.0619 80.7218 14.0596 80.4097C14.0625 80.0949 14.188 79.7935 14.4096 79.5697C14.6321 79.3464 14.9343 79.2205 15.2496 79.2197H55.2496C55.5644 79.2226 55.8658 79.3482 56.0896 79.5697C56.3129 79.7923 56.4388 80.0944 56.4396 80.4097C56.4366 80.7246 56.3111 81.0259 56.0896 81.2497C55.8661 81.4643 55.5694 81.5859 55.2596 81.5897H55.2496ZM15.2496 80.2197C15.2247 80.2189 15.2 80.224 15.1775 80.2344C15.1549 80.2448 15.1351 80.2603 15.1196 80.2797C15.1002 80.2952 15.0846 80.3151 15.0742 80.3376C15.0638 80.3602 15.0588 80.3849 15.0596 80.4097C15.0601 80.4343 15.0658 80.4586 15.0761 80.4809C15.0864 80.5033 15.1012 80.5233 15.1196 80.5397C15.155 80.5722 15.2015 80.5901 15.2496 80.5897H55.2496C55.2977 80.5901 55.3441 80.5722 55.3796 80.5397C55.3979 80.5233 55.4127 80.5033 55.4231 80.4809C55.4334 80.4586 55.439 80.4343 55.4396 80.4097C55.4404 80.3849 55.4353 80.3602 55.4249 80.3376C55.4145 80.3151 55.399 80.2952 55.3796 80.2797C55.364 80.2603 55.3442 80.2448 55.3217 80.2344C55.2991 80.224 55.2744 80.2189 55.2496 80.2197H15.2496Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.4596 89.1395H15.2496C15.0664 89.1396 14.8905 89.0678 14.7596 88.9395C14.696 88.8769 14.6455 88.8022 14.6111 88.7197C14.5768 88.6373 14.5593 88.5488 14.5596 88.4595C14.5587 88.3702 14.576 88.2815 14.6103 88.199C14.6447 88.1165 14.6955 88.0418 14.7596 87.9795C14.8895 87.8485 15.0652 87.7732 15.2496 87.7695H47.4596C47.5495 87.7711 47.6382 87.7904 47.7206 87.8264C47.803 87.8625 47.8775 87.9145 47.9396 87.9795C48.0037 88.0418 48.0545 88.1165 48.0889 88.199C48.1232 88.2815 48.1405 88.3702 48.1396 88.4595C48.1399 88.5488 48.1224 88.6373 48.0881 88.7197C48.0537 88.8022 48.0032 88.8769 47.9396 88.9395C47.877 89.0032 47.8022 89.0537 47.7198 89.088C47.6374 89.1224 47.5489 89.1399 47.4596 89.1395Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.4596 89.6395H15.2496C15.0941 89.641 14.9398 89.6117 14.7956 89.5534C14.6515 89.495 14.5203 89.4088 14.4096 89.2995C14.1889 89.0751 14.0635 88.7742 14.0596 88.4595C14.0603 88.1442 14.1862 87.8421 14.4096 87.6195C14.6334 87.398 14.9347 87.2725 15.2496 87.2695H47.4596C47.7716 87.2719 48.0701 87.3977 48.2896 87.6195C48.402 87.7284 48.491 87.859 48.5512 88.0034C48.6114 88.1479 48.6414 88.3031 48.6396 88.4595C48.6397 88.6157 48.6089 88.7704 48.5488 88.9146C48.4887 89.0588 48.4006 89.1897 48.2896 89.2995C48.1804 89.408 48.0509 89.4939 47.9084 89.5522C47.766 89.6106 47.6135 89.6402 47.4596 89.6395ZM15.2496 88.2695C15.2247 88.2687 15.2 88.2738 15.1775 88.2842C15.1549 88.2946 15.1351 88.3101 15.1196 88.3295C15.1002 88.345 15.0846 88.3649 15.0742 88.3875C15.0638 88.41 15.0588 88.4347 15.0596 88.4595C15.0601 88.4842 15.0658 88.5084 15.0761 88.5308C15.0864 88.5531 15.1012 88.5731 15.1196 88.5895C15.1616 88.6048 15.2076 88.6048 15.2496 88.5895H47.4596C47.5021 88.6015 47.5471 88.6015 47.5896 88.5895C47.6033 88.5473 47.6033 88.5018 47.5896 88.4595C47.6049 88.4175 47.6049 88.3715 47.5896 88.3295C47.5557 88.2933 47.5091 88.2718 47.4596 88.2695H15.2496Z"
                                                        fill="#2D6CB2"/>
                                                    <g opacity="0.76">
                                                        <path opacity="0.76"
                                                              d="M85.6701 13.8496H16.6301C15.1058 13.8496 13.8701 15.034 13.8701 16.495V27.3543C13.8701 28.8152 15.1058 29.9996 16.6301 29.9996H85.6701C87.1944 29.9996 88.4301 28.8152 88.4301 27.3543V16.495C88.4301 15.034 87.1944 13.8496 85.6701 13.8496Z"
                                                              fill="#2D6CB2"/>
                                                    </g>
                                                    <path
                                                        d="M43.4199 96.43H15.0799C14.9498 96.4287 14.8249 96.3787 14.7299 96.2899C14.6408 96.1991 14.5906 96.0772 14.5899 95.95C14.5891 95.8195 14.6393 95.6939 14.7299 95.6C14.8249 95.5112 14.9498 95.4612 15.0799 95.46H43.4099C43.5371 95.4607 43.659 95.5109 43.7499 95.6C43.8404 95.6939 43.8906 95.8195 43.8899 95.95C43.8891 96.0772 43.8389 96.1991 43.7499 96.2899C43.659 96.379 43.5371 96.4292 43.4099 96.43H43.4199Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M43.4198 96.93H15.0798C14.8175 96.9289 14.5661 96.8247 14.3798 96.64C14.1975 96.4562 14.0935 96.2088 14.0898 95.95C14.0909 95.6876 14.1951 95.4362 14.3798 95.25C14.5661 95.0652 14.8175 94.9611 15.0798 94.96H43.4098C43.6687 94.9637 43.9161 95.0676 44.0998 95.25C44.2846 95.4362 44.3887 95.6876 44.3898 95.95C44.3861 96.2088 44.2822 96.4562 44.0998 96.64C43.9185 96.8199 43.6752 96.9237 43.4198 96.93ZM43.4198 95.93H15.0898H43.4198Z"
                                                        fill="#2D6CB2"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_6523_54392">
                                                        <rect width="102.03" height="114" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            :
                                            <svg width="103" height="114" viewBox="0 0 103 114" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_5913_53555)">
                                                    <path
                                                        d="M93.25 0.429993H8.74C7.78266 0.431305 6.83496 0.621169 5.951 0.988739C5.06703 1.35631 4.26413 1.8944 3.58812 2.57227C2.91211 3.25013 2.37623 4.05449 2.01109 4.93945C1.64595 5.82442 1.45868 6.77264 1.46 7.72998V104.2C1.45868 105.157 1.64595 106.106 2.01109 106.991C2.37623 107.875 2.91211 108.68 3.58812 109.358C4.26413 110.036 5.06703 110.574 5.951 110.941C6.83496 111.309 7.78266 111.499 8.74 111.5H93.25C94.2073 111.499 95.155 111.309 96.039 110.941C96.923 110.574 97.7259 110.036 98.4019 109.358C99.0779 108.68 99.6138 107.875 99.9789 106.991C100.344 106.106 100.531 105.157 100.53 104.2V7.72998C100.531 6.77264 100.344 5.82442 99.9789 4.93945C99.6138 4.05449 99.0779 3.25013 98.4019 2.57227C97.7259 1.8944 96.923 1.35631 96.039 0.988739C95.155 0.621169 94.2073 0.431305 93.25 0.429993Z"
                                                        fill="white"/>
                                                    <path
                                                        d="M93.25 114H8.77C7.62148 113.999 6.4844 113.772 5.42392 113.331C4.36345 112.89 3.40041 112.244 2.59 111.43C0.951593 109.782 0.0313274 107.554 0.0299988 105.23V8.75998C0.0313274 6.43638 0.951593 4.20764 2.59 2.56C3.40074 1.7466 4.36384 1.10095 5.42424 0.659973C6.48464 0.218996 7.62156 -0.00866738 8.77 -0.0100098H93.25C94.3984 -0.00866738 95.5354 0.218996 96.5958 0.659973C97.6562 1.10095 98.6193 1.7466 99.43 2.56C101.068 4.20764 101.989 6.43638 101.99 8.75998V105.23C101.989 107.554 101.068 109.782 99.43 111.43C98.6188 112.242 97.6554 112.886 96.5949 113.325C95.5345 113.765 94.3978 113.991 93.25 113.99V114ZM8.77 2.92999C8.00455 2.93009 7.24664 3.08118 6.53967 3.3746C5.8327 3.66803 5.19055 4.09803 4.65 4.63998C3.55763 5.73725 2.94301 7.22168 2.94 8.76999V105.24C2.94301 106.788 3.55763 108.273 4.65 109.37C5.19055 109.912 5.8327 110.342 6.53967 110.635C7.24664 110.929 8.00455 111.08 8.77 111.08H93.25C94.0156 111.081 94.7738 110.93 95.481 110.637C96.1881 110.343 96.8301 109.913 97.37 109.37C98.4624 108.273 99.077 106.788 99.08 105.24V8.76999C99.077 7.22168 98.4624 5.73725 97.37 4.63998C96.8301 4.09714 96.1881 3.66657 95.481 3.37308C94.7738 3.07959 94.0156 2.92899 93.25 2.92999H8.77Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M83.74 86.81L79.41 81.81L79.23 81.66H79.02C78.9537 81.65 78.8863 81.65 78.82 81.66C78.7473 81.6924 78.685 81.7443 78.64 81.81L74.31 86.81C74.2425 86.8984 74.1475 86.9617 74.04 86.99C73.9925 87.0098 73.9415 87.02 73.89 87.02C73.8385 87.02 73.7875 87.0098 73.74 86.99C73.6359 86.9493 73.5481 86.8755 73.49 86.78C73.4268 86.6777 73.3922 86.5603 73.39 86.44V65.96H84.59V86.49C84.5913 86.6031 84.5601 86.7142 84.5 86.81C84.4431 86.8941 84.3633 86.96 84.27 87C84.1723 87.0301 84.0678 87.0301 83.97 87C83.8698 86.9728 83.7795 86.9173 83.71 86.84L83.74 86.81Z"
                                                        fill="#038AB6"/>
                                                    <g opacity="0.99">
                                                        <path opacity="0.99"
                                                              d="M85 71.13C85.7683 70.364 86.3767 69.4531 86.79 68.45C87.2077 67.4454 87.4219 66.3679 87.42 65.28C87.4216 64.1953 87.2075 63.1211 86.79 62.12C86.3813 61.1145 85.7723 60.2027 85 59.44C84.2382 58.6688 83.3306 58.0569 82.33 57.64C81.3295 57.2194 80.2554 57.0018 79.17 57C78.0847 57.0018 77.0105 57.2194 76.01 57.64C75.0081 58.0545 74.1001 58.6667 73.34 59.44C72.5699 60.2026 71.9581 61.11 71.54 62.11C71.1191 63.1139 70.9016 64.1914 70.9 65.28C70.9008 66.3687 71.1184 67.4463 71.54 68.45C71.9604 69.4517 72.5718 70.3619 73.34 71.13C74.1047 71.8972 75.0133 72.5059 76.0138 72.9211C77.0142 73.3364 78.0868 73.5501 79.17 73.55C81.3573 73.5481 83.4543 72.6776 85 71.13Z"
                                                              fill="white"/>
                                                    </g>
                                                    <g opacity="0.99">
                                                        <path opacity="0.99"
                                                              d="M79.17 75.55C77.8245 75.5553 76.4915 75.2928 75.2485 74.7777C74.0055 74.2627 72.8774 73.5054 71.93 72.55C70.9701 71.6033 70.2085 70.4746 69.69 69.23C69.1688 67.9818 68.9003 66.6426 68.9 65.29C68.9003 63.9406 69.1688 62.6047 69.69 61.36C70.2059 60.1111 70.9677 58.9787 71.93 58.03C72.8735 57.0733 73.999 56.3151 75.24 55.8C77.7568 54.7599 80.5832 54.7599 83.1 55.8C84.344 56.3197 85.4724 57.0811 86.42 58.04C87.3708 58.9902 88.1251 60.1184 88.64 61.36C89.1573 62.6056 89.4224 63.9413 89.42 65.29C89.4232 66.6388 89.1581 67.9747 88.64 69.22C88.1278 70.4685 87.3694 71.601 86.41 72.55C85.462 73.5045 84.3338 74.2612 83.0909 74.7762C81.8481 75.2912 80.5153 75.5542 79.17 75.55ZM79.17 57C78.0847 57.0019 77.0105 57.2194 76.01 57.64C75.0119 58.057 74.1049 58.6651 73.34 59.43C72.5708 60.1973 71.9593 61.1077 71.54 62.11C71.1191 63.1139 70.9016 64.1914 70.9 65.28C70.9008 66.3687 71.1184 67.4463 71.54 68.45C71.9544 69.4549 72.5665 70.3663 73.34 71.13C74.1034 71.899 75.0118 72.5088 76.0125 72.9242C77.0133 73.3396 78.0865 73.5523 79.17 73.55C80.2536 73.5523 81.3268 73.3396 82.3275 72.9242C83.3283 72.5088 84.2367 71.899 85 71.13C85.7673 70.3632 86.3756 69.4524 86.79 68.45C87.2077 67.4454 87.4219 66.368 87.42 65.28C87.4216 64.1953 87.2075 63.1212 86.79 62.12C86.3756 61.1176 85.7673 60.2068 85 59.44C84.2382 58.6688 83.3306 58.0569 82.33 57.64C81.3295 57.2194 80.2554 57.0019 79.17 57Z"
                                                              fill="#2D6CB2"/>
                                                    </g>
                                                    <g opacity="0.99">
                                                        <path opacity="0.99"
                                                              d="M78.0199 69.68L73.6799 65.4861C73.4998 65.2975 73.3976 65.0604 73.3899 64.8136C73.3913 64.6894 73.4184 64.5665 73.4699 64.4509C73.5215 64.3367 73.596 64.2318 73.6899 64.1412C73.7807 64.0534 73.8892 63.9814 74.0099 63.9288C74.13 63.8847 74.2603 63.8665 74.3899 63.8758C74.5171 63.8697 74.644 63.891 74.7599 63.9377C74.8781 63.99 74.9862 64.0587 75.0799 64.1412L78.0199 66.9725L82.8999 62.2743C83.0884 62.1061 83.3424 62.008 83.6099 62C83.8745 62.0126 84.1227 62.1176 84.2999 62.292C84.3932 62.3813 84.4647 62.4868 84.5099 62.6017C84.5613 62.7142 84.5884 62.8342 84.5899 62.9556C84.5895 63.0828 84.5624 63.2088 84.5099 63.3272C84.458 63.4386 84.3835 63.5405 84.2899 63.628L78.0199 69.68Z"
                                                              fill="#2D6CB2"/>
                                                    </g>
                                                    <path
                                                        d="M54.25 40.85H15.25C15.0676 40.846 14.8931 40.7748 14.76 40.65C14.6318 40.5191 14.56 40.3432 14.56 40.16C14.5597 40.0707 14.5772 39.9822 14.6115 39.8998C14.6459 39.8174 14.6964 39.7426 14.76 39.68C14.8909 39.5518 15.0668 39.48 15.25 39.48H54.25C54.3393 39.4797 54.4278 39.4972 54.5102 39.5315C54.5926 39.5659 54.6674 39.6164 54.73 39.68C54.7936 39.7426 54.8441 39.8174 54.8785 39.8998C54.9128 39.9822 54.9303 40.0707 54.93 40.16C54.93 40.3432 54.8582 40.5191 54.73 40.65C54.6005 40.7741 54.4293 40.8455 54.25 40.85Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M54.25 41.35H15.25C14.9351 41.3471 14.6338 41.2215 14.41 41C14.1866 40.7774 14.0608 40.4753 14.06 40.16C14.0623 39.848 14.1882 39.5495 14.41 39.33C14.5188 39.2176 14.6495 39.1286 14.7939 39.0684C14.9383 39.0082 15.0935 38.9781 15.25 38.98H54.25C54.4049 38.9789 54.5583 39.0094 54.701 39.0696C54.8437 39.1297 54.9727 39.2183 55.08 39.33C55.1917 39.4373 55.2803 39.5663 55.3405 39.709C55.4006 39.8517 55.4311 40.0052 55.43 40.16C55.4319 40.3165 55.4018 40.4717 55.3416 40.6161C55.2814 40.7605 55.1924 40.8912 55.08 41C54.8618 41.2177 54.5681 41.343 54.26 41.35H54.25ZM15.25 39.98C15.2019 39.9796 15.1554 39.9975 15.12 40.03C15.1016 40.0464 15.0868 40.0664 15.0765 40.0888C15.0662 40.1112 15.0606 40.1354 15.06 40.16C15.0592 40.1849 15.0642 40.2095 15.0746 40.2321C15.0851 40.2547 15.1006 40.2745 15.12 40.29C15.1355 40.3094 15.1553 40.325 15.1779 40.3354C15.2005 40.3458 15.2252 40.3508 15.25 40.35H54.25C54.2746 40.3495 54.2989 40.3438 54.3212 40.3335C54.3436 40.3232 54.3636 40.3084 54.38 40.29C54.4125 40.2546 54.4304 40.2081 54.43 40.16C54.4286 40.1123 54.411 40.0664 54.38 40.03C54.3436 39.9991 54.2978 39.9814 54.25 39.98H15.25Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.46 48.9H15.25C15.0668 48.9001 14.8909 48.8282 14.76 48.7C14.6956 48.6359 14.6447 48.5594 14.6104 48.4753C14.576 48.3911 14.5589 48.3009 14.56 48.21C14.5597 48.1207 14.5772 48.0323 14.6115 47.9498C14.6459 47.8674 14.6963 47.7927 14.76 47.73C14.8909 47.6018 15.0668 47.53 15.25 47.53H47.46C47.5493 47.5297 47.6377 47.5472 47.7202 47.5815C47.8026 47.6159 47.8773 47.6664 47.94 47.73C48.0036 47.7927 48.0541 47.8674 48.0884 47.9498C48.1228 48.0323 48.1403 48.1207 48.14 48.21C48.1411 48.3009 48.124 48.3911 48.0896 48.4753C48.0553 48.5594 48.0044 48.6359 47.94 48.7C47.8773 48.7637 47.8026 48.8141 47.7202 48.8485C47.6377 48.8828 47.5493 48.9004 47.46 48.9Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.46 49.43H15.25C15.0935 49.4319 14.9383 49.4018 14.7939 49.3416C14.6495 49.2815 14.5188 49.1924 14.41 49.08C14.1866 48.8574 14.0608 48.5553 14.06 48.24C14.0623 47.9279 14.1882 47.6295 14.41 47.41C14.5188 47.2976 14.6495 47.2086 14.7939 47.1484C14.9383 47.0882 15.0935 47.0581 15.25 47.06H47.46C47.762 47.0737 48.0461 47.2068 48.25 47.43C48.4718 47.6495 48.5977 47.9479 48.6 48.26C48.5992 48.5753 48.4733 48.8775 48.25 49.1C48.0395 49.3085 47.7562 49.4268 47.46 49.43ZM15.25 48.03C15.208 48.0147 15.162 48.0147 15.12 48.03C15.0838 48.0639 15.0623 48.1105 15.06 48.16C15.0592 48.1849 15.0642 48.2095 15.0746 48.2321C15.0851 48.2547 15.1006 48.2745 15.12 48.29C15.1237 48.3242 15.1384 48.3563 15.1618 48.3816C15.1852 48.4068 15.2162 48.4238 15.25 48.43H47.46C47.5095 48.4277 47.5561 48.4062 47.59 48.37C47.6053 48.328 47.6053 48.282 47.59 48.24C47.6037 48.2012 47.6037 48.1588 47.59 48.12C47.5561 48.0838 47.5095 48.0623 47.46 48.06L15.25 48.03Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M64.31 56.94H15.25C15.0668 56.9401 14.8908 56.8682 14.76 56.74C14.6963 56.6774 14.6458 56.6026 14.6115 56.5202C14.5772 56.4378 14.5596 56.3493 14.56 56.26C14.5591 56.1706 14.5763 56.082 14.6107 55.9995C14.6451 55.917 14.6959 55.8423 14.76 55.78C14.8908 55.6518 15.0668 55.58 15.25 55.58H64.25C64.3393 55.5797 64.4277 55.5972 64.5102 55.6316C64.5926 55.6659 64.6673 55.7164 64.73 55.78C64.7941 55.8423 64.8449 55.917 64.8792 55.9995C64.9136 56.082 64.9309 56.1706 64.93 56.26C64.9303 56.3493 64.9128 56.4378 64.8784 56.5202C64.8441 56.6026 64.7936 56.6774 64.73 56.74C64.6673 56.8037 64.5926 56.8541 64.5102 56.8885C64.4277 56.9228 64.3393 56.9404 64.25 56.94H64.31Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M64.31 57.43H15.25C14.9363 57.4308 14.6348 57.3088 14.41 57.09C14.1885 56.8662 14.0629 56.5649 14.06 56.25C14.0629 55.9351 14.1885 55.6338 14.41 55.41C14.6348 55.1913 14.9363 55.0692 15.25 55.07H64.25C64.4039 55.0693 64.5564 55.099 64.6989 55.1573C64.8413 55.2156 64.9708 55.3015 65.08 55.41C65.1919 55.5192 65.2807 55.6499 65.3408 55.7943C65.401 55.9386 65.4313 56.0936 65.43 56.25C65.4308 56.5637 65.3088 56.8652 65.09 57.09C64.8665 57.3046 64.5698 57.4262 64.26 57.43H64.31ZM15.31 56.07C15.2619 56.0696 15.2154 56.0875 15.18 56.12C15.1606 56.1355 15.1451 56.1554 15.1346 56.1779C15.1242 56.2005 15.1192 56.2252 15.12 56.25C15.1206 56.2746 15.1262 56.2989 15.1365 56.3212C15.1468 56.3436 15.1616 56.3636 15.18 56.38C15.1992 56.4018 15.2231 56.4189 15.25 56.43H64.25C64.2978 56.4286 64.3436 56.411 64.38 56.38C64.3937 56.3378 64.3937 56.2923 64.38 56.25C64.3953 56.208 64.3953 56.162 64.38 56.12C64.3436 56.089 64.2978 56.0714 64.25 56.07H15.31Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M53.25 64.99H15.25C15.0668 64.9901 14.8909 64.9182 14.76 64.79C14.6956 64.7259 14.6447 64.6494 14.6104 64.5653C14.576 64.4811 14.5589 64.3909 14.56 64.3C14.5597 64.2107 14.5772 64.1223 14.6115 64.0398C14.6459 63.9574 14.6963 63.8827 14.76 63.82C14.8909 63.6918 15.0668 63.62 15.25 63.62H53.25C53.3394 63.6191 53.428 63.6364 53.5105 63.6707C53.593 63.7051 53.6677 63.7559 53.73 63.82C53.7936 63.8827 53.8441 63.9574 53.8784 64.0398C53.9128 64.1223 53.9303 64.2107 53.93 64.3C53.9311 64.3909 53.914 64.4811 53.8796 64.5653C53.8453 64.6494 53.7944 64.7259 53.73 64.79C53.6677 64.8541 53.593 64.9049 53.5105 64.9393C53.428 64.9737 53.3394 64.9909 53.25 64.99Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M53.25 65.49H15.25C14.9347 65.4892 14.6326 65.3634 14.41 65.14C14.1866 64.9174 14.0608 64.6153 14.06 64.3C14.0623 63.9879 14.1882 63.6895 14.41 63.47C14.5188 63.3576 14.6495 63.2686 14.7939 63.2084C14.9383 63.1482 15.0935 63.1181 15.25 63.12H53.25C53.5649 63.1229 53.8662 63.2485 54.09 63.47C54.1985 63.5792 54.2844 63.7087 54.3427 63.8511C54.401 63.9936 54.4307 64.1461 54.43 64.3C54.4315 64.4555 54.4022 64.6098 54.3438 64.754C54.2855 64.8981 54.1992 65.0293 54.09 65.14C53.8675 65.3574 53.571 65.4825 53.26 65.49H53.25ZM15.25 64.12C15.2019 64.1196 15.1554 64.1375 15.12 64.17C15.0838 64.2039 15.0623 64.2505 15.06 64.3C15.0592 64.3248 15.0642 64.3495 15.0746 64.3721C15.0851 64.3947 15.1006 64.4145 15.12 64.43C15.1355 64.4494 15.1553 64.4649 15.1779 64.4754C15.2005 64.4858 15.2252 64.4908 15.25 64.49H53.25C53.2748 64.4908 53.2995 64.4858 53.3221 64.4754C53.3446 64.4649 53.3645 64.4494 53.38 64.43C53.3982 64.414 53.4123 64.3938 53.421 64.3712C53.4297 64.3485 53.4328 64.3241 53.43 64.3C53.4307 64.2761 53.4267 64.2523 53.4181 64.23C53.4095 64.2077 53.3965 64.1873 53.38 64.17C53.3446 64.1375 53.2981 64.1196 53.25 64.12H15.25Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.46 73.05H15.25C15.0676 73.046 14.8931 72.9748 14.76 72.85C14.6318 72.7191 14.56 72.5432 14.56 72.36C14.5597 72.2707 14.5772 72.1822 14.6115 72.0998C14.6459 72.0174 14.6964 71.9427 14.76 71.88C14.8909 71.7518 15.0668 71.68 15.25 71.68H47.46C47.5493 71.6797 47.6378 71.6972 47.7202 71.7315C47.8026 71.7659 47.8774 71.8164 47.94 71.88C48.0036 71.9427 48.0541 72.0174 48.0885 72.0998C48.1228 72.1822 48.1403 72.2707 48.14 72.36C48.14 72.5432 48.0682 72.7191 47.94 72.85C47.8105 72.9741 47.6393 73.0454 47.46 73.05Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.46 73.55H15.25C14.9351 73.5471 14.6338 73.4215 14.41 73.2C14.1866 72.9774 14.0608 72.6753 14.06 72.36C14.0623 72.048 14.1882 71.7495 14.41 71.53C14.5192 71.4181 14.6499 71.3294 14.7942 71.2692C14.9386 71.2091 15.0936 71.1787 15.25 71.18H47.46C47.6139 71.1793 47.7664 71.209 47.9089 71.2673C48.0513 71.3257 48.1808 71.4115 48.29 71.52C48.401 71.6299 48.4891 71.7608 48.5492 71.9049C48.6093 72.0491 48.6402 72.2038 48.64 72.36C48.6419 72.5165 48.6118 72.6717 48.5516 72.8161C48.4914 72.9605 48.4024 73.0912 48.29 73.2C48.0705 73.4219 47.7721 73.5477 47.46 73.55ZM15.25 72.18C15.2019 72.1796 15.1554 72.1975 15.12 72.23C15.1016 72.2464 15.0868 72.2664 15.0765 72.2888C15.0662 72.3112 15.0606 72.3354 15.06 72.36C15.0592 72.3849 15.0642 72.4095 15.0746 72.4321C15.0851 72.4547 15.1006 72.4745 15.12 72.49C15.1355 72.5094 15.1553 72.525 15.1779 72.5354C15.2005 72.5458 15.2252 72.5508 15.25 72.55H47.46C47.5095 72.5477 47.5561 72.5262 47.59 72.49C47.6053 72.448 47.6053 72.402 47.59 72.36C47.6037 72.3178 47.6037 72.2723 47.59 72.23C47.5526 72.2009 47.5073 72.1835 47.46 72.18H15.25Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M55.25 81.09H15.25C15.0668 81.0901 14.8909 81.0182 14.76 80.89C14.6964 80.8274 14.6459 80.7527 14.6115 80.6702C14.5772 80.5878 14.5597 80.4993 14.56 80.41C14.56 80.2268 14.6318 80.0509 14.76 79.92C14.8931 79.7952 15.0676 79.724 15.25 79.72H55.25C55.4297 79.7226 55.6016 79.7942 55.73 79.92C55.861 80.0499 55.9363 80.2256 55.94 80.41C55.9385 80.5 55.9191 80.5887 55.8831 80.6711C55.847 80.7534 55.795 80.8279 55.73 80.89C55.6677 80.9541 55.593 81.0049 55.5105 81.0393C55.428 81.0737 55.3394 81.0909 55.25 81.09Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M55.25 81.59H15.25C15.0935 81.5919 14.9383 81.5618 14.7939 81.5016C14.6495 81.4415 14.5188 81.3524 14.41 81.24C14.1882 81.0205 14.0623 80.7221 14.06 80.41C14.0629 80.0952 14.1885 79.7938 14.41 79.57C14.6326 79.3467 14.9347 79.2208 15.25 79.22H55.25C55.5649 79.223 55.8662 79.3485 56.09 79.57C56.3133 79.7926 56.4392 80.0947 56.44 80.41C56.4371 80.7249 56.3115 81.0262 56.09 81.25C55.8665 81.4646 55.5698 81.5862 55.26 81.59H55.25ZM15.25 80.22C15.2252 80.2192 15.2005 80.2243 15.1779 80.2347C15.1553 80.2451 15.1355 80.2606 15.12 80.28C15.1006 80.2955 15.0851 80.3154 15.0746 80.338C15.0642 80.3605 15.0592 80.3852 15.06 80.41C15.0606 80.4347 15.0662 80.4589 15.0765 80.4812C15.0868 80.5036 15.1016 80.5236 15.12 80.54C15.1554 80.5725 15.2019 80.5904 15.25 80.59H55.25C55.2981 80.5904 55.3446 80.5725 55.38 80.54C55.3984 80.5236 55.4132 80.5036 55.4235 80.4812C55.4338 80.4589 55.4394 80.4347 55.44 80.41C55.4408 80.3852 55.4358 80.3605 55.4254 80.338C55.4149 80.3154 55.3994 80.2955 55.38 80.28C55.3645 80.2606 55.3446 80.2451 55.3221 80.2347C55.2995 80.2243 55.2748 80.2192 55.25 80.22H15.25Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.46 89.14H15.25C15.0668 89.14 14.8908 89.0683 14.76 88.94C14.6963 88.8774 14.6458 88.8026 14.6115 88.7202C14.5772 88.6378 14.5596 88.5493 14.56 88.46C14.5591 88.3706 14.5763 88.282 14.6107 88.1995C14.6451 88.117 14.6959 88.0423 14.76 87.98C14.8898 87.849 15.0656 87.7737 15.25 87.77H47.46C47.5499 87.7715 47.6386 87.7909 47.721 87.8269C47.8034 87.863 47.8778 87.915 47.94 87.98C48.0041 88.0423 48.0549 88.117 48.0892 88.1995C48.1236 88.282 48.1409 88.3706 48.14 88.46C48.1403 88.5493 48.1228 88.6378 48.0884 88.7202C48.0541 88.8026 48.0036 88.8774 47.94 88.94C47.8773 89.0037 47.8026 89.0542 47.7202 89.0885C47.6377 89.1228 47.5493 89.1403 47.46 89.14Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M47.46 89.64H15.25C15.0945 89.6415 14.9402 89.6122 14.7961 89.5539C14.6519 89.4955 14.5207 89.4092 14.41 89.3C14.1894 89.0756 14.064 88.7747 14.06 88.46C14.0608 88.1447 14.1866 87.8426 14.41 87.62C14.6338 87.3985 14.9351 87.2729 15.25 87.27H47.46C47.7721 87.2723 48.0705 87.3982 48.29 87.62C48.4024 87.7289 48.4914 87.8595 48.5516 88.0039C48.6118 88.1484 48.6419 88.3036 48.64 88.46C48.6402 88.6162 48.6093 88.7709 48.5492 88.9151C48.4891 89.0593 48.401 89.1901 48.29 89.3C48.1808 89.4085 48.0513 89.4944 47.9089 89.5527C47.7664 89.611 47.6139 89.6407 47.46 89.64ZM15.25 88.27C15.2252 88.2692 15.2005 88.2743 15.1779 88.2847C15.1553 88.2951 15.1355 88.3106 15.12 88.33C15.1006 88.3455 15.0851 88.3654 15.0746 88.3879C15.0642 88.4105 15.0592 88.4352 15.06 88.46C15.0606 88.4846 15.0662 88.5089 15.0765 88.5312C15.0868 88.5536 15.1016 88.5736 15.12 88.59C15.162 88.6053 15.208 88.6053 15.25 88.59H47.46C47.5025 88.602 47.5475 88.602 47.59 88.59C47.6037 88.5478 47.6037 88.5023 47.59 88.46C47.6053 88.418 47.6053 88.372 47.59 88.33C47.5561 88.2938 47.5095 88.2723 47.46 88.27H15.25Z"
                                                        fill="#2D6CB2"/>
                                                    <g opacity="0.76">
                                                        <path opacity="0.76"
                                                              d="M85.67 13.85H16.63C15.1057 13.85 13.87 15.0857 13.87 16.61V27.94C13.87 29.4643 15.1057 30.7 16.63 30.7H85.67C87.1943 30.7 88.43 29.4643 88.43 27.94V16.61C88.43 15.0857 87.1943 13.85 85.67 13.85Z"
                                                              fill="#2D6CB2"/>
                                                    </g>
                                                    <path
                                                        d="M43.42 96.43H15.08C14.9499 96.4288 14.825 96.3788 14.73 96.29C14.6409 96.1991 14.5907 96.0772 14.59 95.95C14.5892 95.8195 14.6394 95.694 14.73 95.6C14.825 95.5112 14.9499 95.4613 15.08 95.46H43.41C43.5372 95.4608 43.6591 95.511 43.75 95.6C43.8405 95.694 43.8908 95.8195 43.89 95.95C43.8892 96.0772 43.839 96.1991 43.75 96.29C43.6591 96.379 43.5372 96.4293 43.41 96.43H43.42Z"
                                                        fill="#2D6CB2"/>
                                                    <path
                                                        d="M43.42 96.93H15.08C14.8177 96.9289 14.5663 96.8248 14.38 96.64C14.1977 96.4562 14.0937 96.2089 14.09 95.95C14.0911 95.6877 14.1953 95.4363 14.38 95.25C14.5663 95.0653 14.8177 94.9611 15.08 94.96H43.41C43.6689 94.9637 43.9162 95.0677 44.1 95.25C44.2848 95.4363 44.3889 95.6877 44.39 95.95C44.3863 96.2089 44.2824 96.4562 44.1 96.64C43.9187 96.82 43.6754 96.9238 43.42 96.93ZM43.42 95.93H15.09H43.42Z"
                                                        fill="#2D6CB2"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_5913_53555">
                                                        <rect width="102.03" height="114" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        }
                                    </div>
                                <div className="assetPopUpTitle texAlingCener">
                                    {props.currentAsset?.asset_type_key === 'real_estate' ||  props.currentAsset?.asset_type_key === 'automobile'  ||  props.currentAsset?.asset_type_key === 'personal_property'  ||  props.currentAsset?.asset_type_key === 'other'?
                                    A12Y_CONST.TEXT.IMPORTANT_NOTE
                                    :
                                    A12Y_CONST.TEXT.LEGAL_NOTE
                                    }
                                </div>
                                <div className="assetPopUpDescription">
                                    {props.currentAsset?.asset_type_key === 'real_estate' ||  props.currentAsset?.asset_type_key === 'automobile'  ||  props.currentAsset?.asset_type_key === 'personal_property'  ||  props.currentAsset?.asset_type_key === 'other'?
                                       A12Y_CONST.TEXT.SINCE_YOU_DONT_CURRENTLY_HAVE
                                    :
                                        A12Y_CONST.TEXT.IT_LOOKS_LIKE_YOU_PHASE1 + ' ' +props.dependent.nick_name+"'s " + A12Y_CONST.TEXT.IT_LOOKS_LIKE_YOU_PHASE2
                                    }
                                </div>
                                <div className="popUpAssetButtonRow textAlignRight">
                                    <div id={A12Y_CONST.BUTTON_TEXT.CONTINUE+A12Y_CONST.BUTTON_ID.BUTTON} className="countinueButton" onClick={assetPopUpHide}>
                                        {A12Y_CONST.BUTTON_TEXT.CONTINUE_CAPS}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default PopUpAssetComponent;