import React, {useEffect, useState} from 'react';
import A12Y_CONST from '../common/a12yConst'
import LoaderFullScreenComponent from './LoaderFullScreenComponent';
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import PopUpComponent from "./PopUpComponent";
import { Tooltip } from 'antd';
import 'antd/dist/antd.css';
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";

function MyAccountViewComponent(props) {
     const history = useHistory();
     const [error, setErrors] = useState(false);
     const [loading, setLoading] = useState(false);
//     const [isPopUp, setIsPopUp] = useState(false);
     const [popUpType, setPopUpType] = useState('');
     const [subscriptionMsg, setSubscriptionMsg] = useState('');
     const [mobileNumberFormat, setMobileNumberFormat] = useState('');
     const [alternateNumberFormat, setAlternateNumberFormat] = useState('');
     const [isPopUp2, setIsPopUp2] = useState(false);
    

    useEffect(()=>{
        setErrors(props.error)
        setLoading(props.loading);
    },[props.error, props.loading])

    const personalInfoEdit = (Edit) => {
        if(Edit === A12Y_CONST.TEXT.PERSONAL){
            props.onEdit(true, false, false)
        }
        if(Edit === A12Y_CONST.TEXT.SIGN_IN_SECURITY){
            props.onEdit(false, true, false)
        }
        if(Edit === A12Y_CONST.TEXT.BILLING){
            props.onEdit(false, false, true)
        }
    }
    const billingScreenEdit = (status) => {
    console.log('props.userSubscription?.subscription_status')
        props.billingScreenEdit(false, false, true, status)
    }
    const billingOnlyPromocde = () => {
        props.onlyBillingPromocodeEdit(false, false, true)
    }


    // const handleUnSubscribe = async () => {
    //     setIsPopUp(false);
    //     await props.onCancel()
    // }
    //
    /*const hidePopup = () => {
        setIsPopUp(false);
    }*/
    //
    // const cancelSubscription = async () => {
    //     setPopUpType(A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION)
    //     setIsPopUp(true);
	// 	await setSubscriptionMsg(A12Y_CONST.TEXT.ARE_YOU_SURE_YOU)
	// }

    const cancelSubscription = async () => {
        setPopUpType(A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION)
        setIsPopUp2(true);
        await setSubscriptionMsg(A12Y_CONST.TEXT.ARE_YOU_SURE_YOU)
    }

    const handleUnSubscribe = async () => {
        setIsPopUp2(false);
        await props.onCancel()
    }

    const hidePopup2 = () => {
        setIsPopUp2(false);
    }
    useEffect (  () => {
        // console.log('props.dependent.phones[0]', props.userInfo.phones[0])
        // console.log('props.userInfo.phones[0].number', props.userInfo.phones[0].number)
        if(props?.userInfo?.address) delete props.userInfo.address['country_code']
        if(props.userInfo.phones !== null && props.userInfo?.phones?.length > 0 && props.userInfo.phones[0].type !== "" && props.userInfo.phones[0].number.length > 1 ){
            const mobileNum = parsePhoneNumber('+'+props.userInfo.phones[0].number);
            const mobileNumberFormating = '+1 ' + mobileNum.formatNational()
            setMobileNumberFormat(mobileNumberFormating)
        }
        if(props.userInfo.phones !== null && props.userInfo?.phones?.length > 0 && props.userInfo.phones[1].type !== "" && props.userInfo.phones[1].number.length > 1 ){
            const alternateNum = parsePhoneNumber('+'+props.userInfo.phones[1].number);
            const altNumberFormating = '+1 ' + alternateNum.formatNational()
            setAlternateNumberFormat(altNumberFormating)
            // console.log('alter numbe=========', alternateNumberFormat)
        }


    },[props.userInfo])


    const goToDocumentInvoice = () => {
        props.goToDocumnetInvoice()
        history.push(A12Y_CONST.ROUTE.DOCUMENTS);
    }
//    const editBilling = (billingEdit) => {
//        props.editBilling(billingEdit)
//    }

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 ViewExpenseMain myAccountViewMain addExpensesWrap">
            { loading ?
              <LoaderFullScreenComponent/>
                :
             null
            }
                <h2 className='titleWithSidebar'>
                    <span>{A12Y_CONST.TEXT.TITLE_MY_ACCOUNT}</span>
                </h2>
            <div className="generalInfoMain dependentProfileMainViewBox fullWidth">
                <h3 className='df aic'>{A12Y_CONST.TEXT.PERSONAL}
                    <span id={A12Y_CONST.TEXT.EDIT+A12Y_CONST.TEXT.PERSONAL} onClick={() => personalInfoEdit(A12Y_CONST.TEXT.PERSONAL)} className='marginLeft20 cursorPointer'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2" fillOpacity="0.99"/>
                    </svg>
                    </span>
                </h3>
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )
                }
                <div className='invBox df fcol'>
                    <div className="generaInfoRow fullWidth">
                        <span className="geneInfo_label">{A12Y_CONST.LABEL.NAME}</span>
                        <span className="geneInfo_value">{props.userInfo.first_name + ' ' + props.userInfo.last_name}</span>
                    </div>
                    {props.userInfo.phones !== null && props.userInfo?.phones?.length > 0 && props.userInfo.phones.map((phone,key)=>(
                        phone.type !== "" && phone.number !== ''?
                            <div key={key} className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{phone.type !== null && phone.type === 'office'? A12Y_CONST.LABEL.OFFICE : phone.type === 'home' ? A12Y_CONST.LABEL.HOME : phone.type === 'mobile' ? A12Y_CONST.LABEL.MOBILE : A12Y_CONST.LABEL.OTHER }</span>
                                <span className="geneInfo_value">{key === 0 ? mobileNumberFormat : alternateNumberFormat}</span>
                                {/*<span className="geneInfo_value">{props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[0].type !="" && props.dependent.phone[0].number.length > 1?  mobileNumberFormat : '-'}</span>*/}
                            </div>
                            : null
                    ))}
                    {/*<div className="generaInfoRow fullWidth marginTop15">
                        <span className="geneInfo_label">{A12Y_CONST.LABEL.MOBILE}</span>
                        <span className="geneInfo_value">{props.userInfo?.phones !== null && props.userInfo?.phones[0].number}</span>
                    </div>
                    <div className="generaInfoRow fullWidth marginTop15">
                        <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_ALTERNATE}</span>
                        <span className="geneInfo_value">{props.userInfo?.phones !== null && props.userInfo.phones[1].number}</span>
                    </div>*/}
                    <div className="generaInfoRow fullWidth marginTop15">
                        <span className="geneInfo_label">{A12Y_CONST.LABEL.ADDRESS}</span>
                        <span className="geneInfo_value">{props.userInfo.address && Object.values(props.userInfo.address).filter(ad => ad !== '').join(', ')}</span>
                    </div>
                </div>
            </div>

            <div className="generalInfoMain dependentProfileMainViewBox fullWidth margin30">
                <h3 className='df aic'>{A12Y_CONST.TEXT.SIGN_IN_SECURITY}
                    {!props.userInfo.is_google_user ?
                        <span id={A12Y_CONST.TEXT.EDIT+A12Y_CONST.BUTTON_ID.SIGN_IN_SECURITY} onClick={() => personalInfoEdit(A12Y_CONST.TEXT.SIGN_IN_SECURITY)}
                           className='marginLeft20 cursorPointer'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                            d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z"
                            fill="#2D6CB2" fillOpacity="0.99"/>
                        </svg>
                    </span>
                        :
                        null
                    }
                </h3>
                <div className='invBox df fcol'>
                    <div className="generaInfoRow fullWidth">
                        <span className="geneInfo_label">{A12Y_CONST.LABEL.EMAIL}</span>
                        <span className="geneInfo_value">{props.userInfo.email}</span>
                    </div>
                    <div className="generaInfoRow fullWidth marginTop15">
                        <span className="geneInfo_label">{A12Y_CONST.LABEL.PASSWORD}</span>
                        <span className="geneInfo_value">{A12Y_CONST.TEXT.PASSWORD_ASTRIC}</span>
                    </div>
                </div>
            </div>

            <div className="generalInfoMain marginTop10 fullWidth margin30">
              {/*  <h3 className='df aic'>{A12Y_CONST.TEXT.BILLING}
                    {
                        ((Object.keys(props.dependent).length  === 0 && props.userSubscription?.subscription_status !== "CANCELLED") || ((Object.keys(props.dependent).length  > 0 && props?.dependent?.access?.can_update) && props.userSubscription?.subscription_status !== "CANCELLED") ) &&
                        <>
                        {isPopUp ?
                        null
                        :
                         // eslint-disable-next-line
                        <Tooltip color={'#112e53'} placement="right" style={{borderRadius:'4px'}} title={<><span className='cursorPointer' style={{fontSize: '14px', color:'white', lineHeight:'21px', padding: '5px', fontWeight: 400}}>You can cancel this subscription by clicking here  <a onClick={cancelSubscription} className={'cursorPointer'} style={{color:'#2D6CB2', display:'inline-block'}}>Unsubscribe</a>   </span></>}>
                            <span className='df aic marginLeft20'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 18C5.5817 18 2 14.418 2 10C2 5.582 5.5817 2 10 2C14.4183 2 18 5.582 18 10C18 14.418 14.4183 18 10 18ZM0 10C0 15.523 4.4772 20 10 20C15.5228 20 20 15.523 20 10C20 4.477 15.5228 0 10 0C4.4772 0 0 4.477 0 10Z" fill="#71767A"/>
                                    <path d="M10 8C9.4477 8 9 8.448 9 9V15C9 15.552 9.4477 16 10 16C10.5523 16 11 15.552 11 15V9C11 8.448 10.5523 8 10 8Z" fill="#71767A"/>
                                    <path d="M8.5 5.5C8.5 6.328 9.1716 7 10 7C10.8284 7 11.5 6.328 11.5 5.5C11.5 4.672 10.8284 4 10 4C9.1716 4 8.5 4.672 8.5 5.5Z" fill="#71767A"/>
                                </svg>
                            </span>
                        </Tooltip>
                        }
                        </>
                    }
                    <span onClick={() => personalInfoEdit(A12Y_CONST.TEXT.BILLING)} className='marginLeft20 df cursorPointer'>
                    {Object.keys(props.dependent).length  === 0 || (Object.keys(props.dependent).length  > 0 && props?.dependent?.access?.can_update) ?
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2" fillOpacity="0.99"/>
                            </svg>
                        :
                        null
                    }
                    </span>
                </h3>
*/}
               {/* {Object.keys(props.dependent).length  === 0 || (Object.keys(props.dependent).length  > 0 && props?.dependent?.access?.can_update)?
                    <div className='billWrap invBox df fcol'>
                        <div className="billLeft">
                            <div className='generaInfoRow fullWidth'>
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.SUBSCRIPTION_PLAN}</span>
                                <span className="geneInfo_value">{props?.userPlan?.plan_name}</span>
                            </div>
                            <div className='generaInfoRow fullWidth marginTop15'>
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.SUBSCRIPTION_STATUS}</span>
                                <span className="geneInfo_value">{props.userSubscription?.subscription_status}</span>
                            </div>
                            <div className='generaInfoRow fullWidth marginTop15'>
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.START_DATE}</span>
                                <span
                                    className="geneInfo_value">{new Date(props.userSubscription?.start_date).toDateString()}</span>
                            </div>
                            {
                                props.userSubscription?.subscription_status === "CANCELLED" ?
                                    <div className='generaInfoRow fullWidth marginTop15'>
                                        <span className="geneInfo_label">{A12Y_CONST.LABEL.EXPIRATION_DATE}</span>
                                        <span
                                            className="geneInfo_value">{new Date(props.userSubscription?.renewal_date).toDateString()}</span>
                                    </div> :
                                    <div className='generaInfoRow fullWidth marginTop15'>
                                        <span className="geneInfo_label">{A12Y_CONST.LABEL.RENEWAL_DATE}</span>
                                        <span
                                            className="geneInfo_value">{new Date(props.userSubscription?.renewal_date).toDateString()}</span>
                                    </div>
                            }
                            <div className='generaInfoRow fullWidth marginTop15'>
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.INVOICE}</span>
                                <span className="geneInfo_value downloadMargin">
                                {
                                    Object.keys(props.invoiceData).length > 0 && props.invoiceData.hasOwnProperty('invoice_pdf') ?
                                        <a className='invoiceText' target="_blank" href={props.invoiceData.invoice_pdf}>  eslint-disable-line react/jsx-no-target-blank
                                            <span className='text20 vam'>{A12Y_CONST.TEXT.DOWNLOAD} </span>
                                            <svg className='downloadImge vam' width="16" height="20" viewBox="0 0 16 20"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0C8.552 0 9 0.4477 9 1V11.5858L11.293 9.2929C11.683 8.9024 12.317 8.9024 12.707 9.2929C13.098 9.6834 13.098 10.3166 12.707 10.7071L8.70697 14.7071C8.31697 15.0976 7.68303 15.0976 7.29303 14.7071L3.29303 10.7071C2.90203 10.3166 2.90203 9.6834 3.29303 9.2929C3.68303 8.9024 4.31697 8.9024 4.70697 9.2929L7 11.5858V1C7 0.4477 7.448 0 8 0ZM1 15C1.552 15 2 15.4477 2 16V18H14V16C14 15.4477 14.448 15 15 15C15.552 15 16 15.4477 16 16V18C16 19.105 15.105 20 14 20H2C0.895 20 0 19.105 0 18V16C0 15.4477 0.448 15 1 15Z"
                                                    fill="#2D6CB2"/>
                                            </svg>
                                        </a>
                                        : null
                                }
                            </span>
                            </div>
                        </div>
                        <div className="billRight marginTop20">
                            <Cards
                                name={""}
                                preview={true}
                                issuer={props.paymentMethod?.card_type}
                                number={["dinersclub", "amex"].indexOf(props.paymentMethod?.card_type) !== -1 ? "***********" + props.paymentMethod?.card_last4 : "************" + props.paymentMethod?.card_last4}
                                placeholders={{name: "***"}}
                                cvc={"***"}
                                expiry={(props.paymentMethod?.card_expiration_month < 10 ? "0" + props.paymentMethod?.card_expiration_month.toString() : "" + props.paymentMethod?.card_expiration_month) + props.paymentMethod?.card_expiration_year}
                            />
                        </div>
                    </div>
                    :
                    <div className='geneInfo_value marginTop20'>
                        {A12Y_CONST.MESSAGE.YOUR_ACCOUNT_IS_PAID}
                    </div>
                }*/}
                <div className="dependentTabContainerCol" id="Billing">
                    <div className="dependentAddress dependentProfileMainViewBox fullWidth">
                        <div className="dependentAddressTitle dependentAddressTitle textStyle marginBottom5 positionRelative">
                            <h3 className='df aic'>{A12Y_CONST.TEXT.BILLING}
                                {
                                    // (props.dependent?.subscirption?.subscription_details?.subscription_status !== A12Y_CONST.TEXT.EXPIRED && !props?.userSubscription?.trial_period && ((Object.keys(props.dependent).length  === 0 && props.userSubscription?.subscription_details?.subscription_status !== "CANCELLED") || ((Object.keys(props.dependent).length  > 0 && props?.dependent?.access?.can_update) && props.userSubscription?.subscription_details?.subscription_status !== "CANCELLED"))) &&
                                    (!props.userSubscription?.trial_period && props.userSubscription?.subscription_details?.subscription_status === A12Y_CONST.TEXT.ACTIVE_UPPER && props?.dependent?.access?.can_update) &&

                                    <>
                                        {isPopUp2 ?
                                            null
                                            :
                                            // eslint-disable-next-line
                                            <Tooltip color={'#112e53'} placement="right" style={{borderRadius:'4px',width: '160px', maxWidth: '160px'}} title={<><span className='cursorPointer' overlayStyle={{width:'100px'}} overlayInnerStyle={{width: '150px'}}  style={{fontSize: '14px', color:'white', lineHeight:'21px', padding: '5px', fontWeight: 400}}>You can cancel this subscription by clicking here <a onClick={cancelSubscription} className={'cursorPointer'} style={{color:'#2D6CB2', display:'inline-block'}}>Unsubscribe</a></span></>}>
                                                     <span className='df aic marginLeft20'>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 18C5.5817 18 2 14.418 2 10C2 5.582 5.5817 2 10 2C14.4183 2 18 5.582 18 10C18 14.418 14.4183 18 10 18ZM0 10C0 15.523 4.4772 20 10 20C15.5228 20 20 15.523 20 10C20 4.477 15.5228 0 10 0C4.4772 0 0 4.477 0 10Z" fill="#71767A"/>
                                                            <path d="M10 8C9.4477 8 9 8.448 9 9V15C9 15.552 9.4477 16 10 16C10.5523 16 11 15.552 11 15V9C11 8.448 10.5523 8 10 8Z" fill="#71767A"/>
                                                            <path d="M8.5 5.5C8.5 6.328 9.1716 7 10 7C10.8284 7 11.5 6.328 11.5 5.5C11.5 4.672 10.8284 4 10 4C9.1716 4 8.5 4.672 8.5 5.5Z" fill="#71767A"/>
                                                        </svg>
                                                     </span>
                                            </Tooltip>
                                        }
                                    </>
                                }
                                  <span id={A12Y_CONST.TEXT.BILLING+A12Y_CONST.TEXT.EDIT} onClick={() => billingScreenEdit(props.userSubscription?.subscription_details?.subscription_status)} className='marginLeft20 df cursorPointer'>
                                  {!props.userSubscription?.trial_period && !props?.userSubscription?.is_trial_expired && (Object.keys(props.dependent).length  === 0 || (Object.keys(props.dependent).length  > 0 && props?.dependent?.access?.can_update)) ?
                                     <div className={`editBillingCol positionRelative ${props.userSubscription?.subscription_details?.subscription_status === A12Y_CONST.TEXT.ACTIVE_UPPER || props.userSubscription?.subscription_details?.subscription_status === A12Y_CONST.LABEL.CANCELLED || props.userSubscription?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT?'promocodeDisable':''}`}>
                                      <svg id={A12Y_CONST.BUTTON_ID.EDIT_BILLING_ICON} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                      </svg>
                                      <span className='tagTooltip_promo'>{A12Y_CONST.LABEL.EDIT_YOUR_CREDIT_CARD}</span>
                                      </div>
                                      :
                                      null
                                  }
                                </span>
                                {props.dependent.can_update || (props.userSubscription?.subscription_details?.subscription_status === A12Y_CONST.TEXT.ACTIVE_UPPER && !props.userSubscription?.trial_period) ?
                                <span id={A12Y_CONST.BUTTON_ID.HAVE_PROMO_CODE} onClick={() => billingOnlyPromocde()} className={`havePoromoCode positionRelative  /*${props?.userSubscription?.price_details?.coupon_name === null || props?.userSubscription?.price_details?.coupon_name === undefined?'':'promocodeDisable'}*/ `}>
                                    {A12Y_CONST.LABEL.HAVE_A_PROMO_CODE}
                                    <span className='tagTooltip_promo'>{A12Y_CONST.LABEL.YOU_CAN_ONLY_HAVE_ONE_PROMO}</span>
                                </span>
                                :
                                null
                                }
                            </h3>
                        </div>

                        {props?.dependent?.access?.can_update?
                            <>
                                {props.userSubscription?.subscription_details !== null && props.userSubscription?.subscription_details !== undefined && !props.userSubscription?.trial_period && !props?.userSubscription?.is_trial_expired?
                                    <div className='billWrap invBox fullWidth fcol'>
                                        <div className="billLeft">
                                            <div className='generaInfoRow fullWidth'>
                                                <span className="geneInfo_label">{A12Y_CONST.LABEL.SUBSCRIPTION_PLAN}</span>
                                                <span className="geneInfo_value">${props.userSubscription?.price_details?.plan_amount} {props.userSubscription?.price_details?.plan_interval_display}</span>
                                            </div>
                                            <div className='generaInfoRow fullWidth marginTop15'>
                                                <span className="geneInfo_label">{A12Y_CONST.LABEL.SUBSCRIPTION_STATUS}</span>
                                                <span
                                                    className="geneInfo_value">{props.userSubscription?.subscription_details?.subscription_status}</span>
                                            </div>
                                            <div className='generaInfoRow fullWidth marginTop15'>
                                                <span className="geneInfo_label">{A12Y_CONST.LABEL.START_DATE}</span>
                                                <span
                                                    className="geneInfo_value">{props.userSubscription?.subscription_details?.start_date ? moment(new Date(props.userSubscription?.subscription_details?.start_date)).format("MM/DD/YYYY") : null}
                                        </span>
                                            </div>
                                            {
                                                props.userSubscription?.subscription_details?.subscription_status === "CANCELLED" ?
                                                    <div className='generaInfoRow fullWidth marginTop15'>
                                                <span
                                                    className="geneInfo_label">{A12Y_CONST.LABEL.EXPIRATION_DATE}</span>
                                                        <span
                                                            className="geneInfo_value">{props.userSubscription?.subscription_details?.renewal_date ? moment(new Date(props.userSubscription?.subscription_details?.renewal_date)).format("MM/DD/YYYY"): null}</span>
                                                    </div> :
                                                    <div className='generaInfoRow fullWidth marginTop15'>
                                                        <span className="geneInfo_label">{A12Y_CONST.LABEL.RENEWAL_DATE}</span>
                                                        <span
                                                            className="geneInfo_value">{props.userSubscription?.subscription_details?.renewal_date ? moment(new Date(props.userSubscription?.subscription_details?.renewal_date)).format("MM/DD/YYYY"): null}</span>
                                                    </div>
                                            }
                                            {/*<div className='generaInfoRow fullWidth marginTop15'>*/}
                                            {/*    <span className="geneInfo_label">{A12Y_CONST.LABEL.INVOICE}</span>*/}
                                            {/*    <span className="geneInfo_value downloadMargin">*/}
                                            {/*        {*/}
                                            {/*            Object.keys(props.invoiceData).length > 0 && props.invoiceData.hasOwnProperty('invoice_pdf') ?*/}
                                            {/*                <a className='invoiceText' rel="noreferrer" target="_blank"*/}
                                            {/*                   href={props.invoiceData.invoice_pdf}>  /!*eslint-disable-line react/jsx-no-target-blank*!/*/}
                                            {/*                    <span className='text20 vam'>{A12Y_CONST.TEXT.DOWNLOAD} </span>*/}
                                            {/*                    <svg className='downloadImge vam' width="16" height="20" viewBox="0 0 16 20"*/}
                                            {/*                         fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                            {/*                        <path*/}
                                            {/*                            d="M8 0C8.552 0 9 0.4477 9 1V11.5858L11.293 9.2929C11.683 8.9024 12.317 8.9024 12.707 9.2929C13.098 9.6834 13.098 10.3166 12.707 10.7071L8.70697 14.7071C8.31697 15.0976 7.68303 15.0976 7.29303 14.7071L3.29303 10.7071C2.90203 10.3166 2.90203 9.6834 3.29303 9.2929C3.68303 8.9024 4.31697 8.9024 4.70697 9.2929L7 11.5858V1C7 0.4477 7.448 0 8 0ZM1 15C1.552 15 2 15.4477 2 16V18H14V16C14 15.4477 14.448 15 15 15C15.552 15 16 15.4477 16 16V18C16 19.105 15.105 20 14 20H2C0.895 20 0 19.105 0 18V16C0 15.4477 0.448 15 1 15Z"*/}
                                            {/*                            fill="#2D6CB2"/>*/}
                                            {/*                    </svg>*/}
                                            {/*                </a>*/}
                                            {/*                : null*/}
                                            {/*        }*/}
                                            {/*</span>*/}
                                            {/*</div>*/}
                                            <div className='generaInfoRow fullWidth marginTop15'>
                                                <span className="geneInfo_label">{A12Y_CONST.LABEL.RENEWAL_PRICE}</span>
                                                <span
                                                    className="geneInfo_value">${props.userSubscription?.price_details?.renewal_amount} {props.userSubscription?.price_details?.promo_code? A12Y_CONST.LABEL.CODE_APPLIED + ' ' + props.userSubscription?.price_details?.promo_code:null}
                                        </span>
                                            </div>
                                            <div onClick={goToDocumentInvoice}  className='fullWidth marginTop15'>
                                                <span id={A12Y_CONST.BUTTON_ID.VIEW_INVOICE_BUTTON} className='view_invoice_text'>{A12Y_CONST.LABEL.view_invoice_receipts}</span>
                                            </div>
                                        </div>
                                        <div className="billRight marginTop20" id={A12Y_CONST.BUTTON_ID.ACTIVE_CARD_IMAGE}>
                                            {props.userSubscription?.payment_method !== null && props.userSubscription?.payment_method !== undefined && props.userSubscription?.payment_method.card_last4 !== null ?
                                            <Cards
                                                name={""}
                                                preview={true}
                                                issuer={props?.userSubscription?.paymentMethod?.card_type}
                                                number={["dinersclub", "amex"].indexOf(props?.userSubscription?.payment_method?.card_type) !== -1 ? "***********" + props.userSubscription?.payment_method?.card_last4 : "************" + props.userSubscription?.payment_method?.card_last4}
                                                placeholders={{name: "***"}}
                                                cvc={"***"}
                                                expiry={(props?.userSubscription?.payment_method?.card_expiration_month < 10 ? "0" + props.userSubscription?.payment_method?.card_expiration_month.toString() : "" + props.userSubscription?.payment_method?.card_expiration_month) + props.userSubscription?.payment_method?.card_expiration_year}
                                            />
                                            :
                                            null
                                        }
                                        </div>
                                    </div>
                                    :
                                    <div className='dependentNoAddressData invBox fullWidth'>
                                        <div className="generaInfoRow fullWidth marginTop15">
                                            <span className="geneInfo_label">{A12Y_CONST.LABEL.SUBSCRIPTION_PLAN}</span>
                                            <span className="geneInfo_value">{A12Y_CONST.LABEL.FREE_ACCOUNT_CREATION}</span>
                                        </div>
                                        <div className="generaInfoRow fullWidth marginTop15">
                                            <span className="geneInfo_label">{A12Y_CONST.LABEL.SUBSCRIPTION_STATUS}</span>
                                            <span className="geneInfo_value">{props.userSubscription?.subscription_details?.subscription_status}</span>
                                        </div>
                                        <div className="generaInfoRow fullWidth marginTop15">
                                            <span className="geneInfo_label">{A12Y_CONST.LABEL.EXPIRATION_DATE}</span>
                                            {/*<span className="geneInfo_value">08/12/2022</span>*/}
                                            <span className="geneInfo_value">{props?.userSubscription?.subscription_details?.end_date ? moment(new Date(props?.userSubscription?.subscription_details?.end_date)).format("MM/DD/YYYY") : null}</span>
                                        </div>

                                        <div className="fullWidth marginTop15">
                                            <button id={A12Y_CONST.BUTTON_ID.JOIN_ADVOCORD} onClick={() => personalInfoEdit(A12Y_CONST.TEXT.BILLING)}  className="btn-primary saveButton joinAdvocord plusIcon textcapitalize"> <span>{A12Y_CONST.BUTTON_TEXT.JOIN_ADVOCORD}</span></button>
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <div className='geneInfo_value marginTop20'>
                                {A12Y_CONST.MESSAGE.YOUR_ACCOUNT_IS_PAID}
                            </div>
                        }
                    </div>
                </div>

            </div>

            <PopUpComponent
                popUpStatus={isPopUp2}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE}
                message={subscriptionMsg}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.CANCEL_PLAN}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.KEEP_PLAN}
                hidePopUp={hidePopup2}
                handleDeleteButton={handleUnSubscribe}
                popUpType={popUpType}
            />
        </div>
    )
}

export default MyAccountViewComponent