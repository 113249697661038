import {persistor} from "../redux/store";
import {store} from "../redux/store";
import A12Y_CONST from "../common/a12yConst";
import {client} from "../config/axiosClient";
const {dispatch} = store;

export async function LogOut () {
    let googleLogin = store.getState().userDetailReducer.userState?.userInfo?.is_google_user

    localStorage.clear();
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.CLEAN_DEPENDENT, payload: {}})
    dispatch({type: A12Y_CONST.DISPATCH_ACTION.LOGOUT, payload: {}})
    // dispatch({type: A12Y_CONST.DISPATCH_ACTION.USER_SESSION_OUT_SCREEN, screen:null})
    // dispatch({type: A12Y_CONST.DISPATCH_ACTION.USER_SESSION_OUT_USER_ID, userId:null})
    persistor.purge();

    if(googleLogin){
        const response = await client.get(A12Y_CONST.API_ENDPOINT.LOGIN_URL);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
             // window.location.href=response.data.sr.google_logout_url
            return true ;
        }else {
            return false;
        }
    }

}

