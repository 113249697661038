import React, { useEffect, useState } from 'react'
import A12Y_CONST from "../common/a12yConst";
import InventoryFileViewer from "./InventoryFileViewer";
import NotesComponent from "./NotesComponent";


function AssetTransferDetailComponent(props) {
    const [canUpdate, setCanUpdate] = useState(false);
    const [transferData, setTransferData] = useState({});

    const [isCommentTabVisible, setIsCommentTabVisible] = useState(true);

    useEffect(() => {
        setCanUpdate(props?.dependent?.access?.can_update)
        if(Object.keys(props.currentAsset).length > 0 && Object.keys(props.currentAsset.transfer).length > 0){
            setTransferData(props.currentAsset.transfer)
        }

    }, [props.dependent.access,props.currentAsset.transfer,props.currentAsset])
    

    const editTransfer = () => {
        props.goToEditScreen(A12Y_CONST.TEXT.ASSET_TRANSFER_KEY)
    }

    const collapse = (elment) => {
        let elm = document.getElementById(elment);
        if(elm !== null){
            elm.parentElement.classList.toggle('opened')
            elm.classList.toggle('heightAuto')

        }
        if(elment === 'Location' ){
            props.gettingProviderDetails();
        }
    }

    const tabCheckBox = (status) => {
        setIsCommentTabVisible(status)
    }

    return (
    <div className="inventoryBox fullWidth borderBottom opened assetTransferView">
        <div onClick={() => collapse('Transfer')} className="inventoryScreenTitle fullWidth positionRelative cb inventory_Col_padding">
            {props.title}
            {
                props.loading ?
                null
                :
                (
                    canUpdate ? 
                    <em id={A12Y_CONST.TEXT.ASSET_TRANSFER+A12Y_CONST.TEXT.EDIT} className='cursorPointer marginLeft20' onClick={editTransfer}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2"/>
                                </svg>
                    </em>
                    :
                    null
                )
            }

            <div className="btnAddEditWrap df fcol jcc aic">
                <span className='collape marginBottom20'>
                    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.703768 0.684193C1.63973 -0.228064 3.16111 -0.228064 4.09707 0.684193L12 8.36722L19.9029 0.684193C20.8389 -0.228064 22.3603 -0.228064 23.2962 0.684193C24.2346 1.59412 24.2346 3.07333 23.2962 3.98326L13.6966 13.3158C12.7607 14.2281 11.2393 14.2281 10.3033 13.3158L0.703768 3.98326C-0.23459 3.07333 -0.23459 1.59412 0.703768 0.684193Z" fill="#71767A"/>
                    </svg>
                </span>
                

            </div>
        </div>

        <div id='Transfer' className={`inventoryDetialBox marginTop20 heightAuto positionRelative ${props.loading ? 'polLoading' : 'height0'}`}>
                    {props.loading ?
                        // <div className='loaderPol df jcc fcol height100 fullWidth positionAbsouteOnly'>
                        //     <div className="positionRelative">
                        //         <div className="dependentLoader">
                        //             <div className='fullWidth justifyCenter marginBottom10'>
                        //                 <div className="stage">
                        //                     <div className="dot-pulse"></div>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //         <div className='marginTop20 fullWidth'>Loading Sale of property...</div>
                        //     </div>
                        // </div>
                        null
                        :
                        <div>
                            <div className="inventoryRow fullWidth"><span className="inventory_label">{A12Y_CONST.LABEL.TYPE}</span><span className="inventory_value">{Object.keys(transferData).length > 0 ? transferData.transfer_type === 2 ? 'Sale' : transferData.transfer_type === 3 ? 'Gift' : 'Partial Sale' : ''}</span></div>
                            {Object.keys(transferData).length > 0 && transferData.transfer_type === 4 ?
                                <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.PERCENTAGE}</span><span className="inventory_value">{Object.keys(transferData).length > 0 && transferData.percentage_sold !== null ? transferData.percentage_sold+'%' : '-' }</span></div>
                                :
                                null
                            }
                            <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.DATE_OF_SALE}</span><span className="inventory_value">{Object.keys(transferData).length > 0 ? transferData.transfer_date: ''}</span></div>
                            <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.NET_PROCEEDS}</span><span className="inventory_value">{Object.keys(transferData).length > 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transferData.net_proceeds): ''}</span></div>
                            <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.COURT_ORDER_USED}</span><span className="inventory_value">{Object.keys(transferData).length > 0 && transferData.court_order_used ? 'Yes' : 'No'}</span></div>
                            {Object.keys(transferData).length > 0 && transferData.court_order_used ?
                                <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.DATE_OF_COURT}</span><span className="inventory_value">{Object.keys(transferData).length > 0 ? transferData.court_order_date: ''}</span></div>
                                :
                                <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.COURT_ORDER}</span><span className="inventory_value noteText ayScrollBar">{
                                transferData?.court_order?.length > 0 ? transferData?.court_order?.split('\n').map((n, i)=> {
                                        return <div key={i}>{n}</div>
                                    }) :
                                    '-'
                            }</span></div>}
                            <div className="inventoryRow fullWidth marginTop15 commentSetionViewScreen">
                                {/*<span className="inventory_label">{A12Y_CONST.LABEL.NOTE}</span><span className="inventory_value noteText ayScrollBar">
                                {
                                    transferData?.note?.length > 0 && transferData?.note?.split('\n').map((n, i)=> {
                                        return <div key={i}>{n}</div>
                                })
                                }
                            </span>*/}
                                <label className="inventory_label">{A12Y_CONST.TEXT.OTHER_SURVEY}
                                    <span className='showLabel'>{A12Y_CONST.TEXT.SHOW}</span>
                                </label>
                                <div className='fullWidth'>
                                    <div className='fullWidth marginBottom20 marginTop35 commentMarginResponsive'>
                                        <div id={A12Y_CONST.LABEL.ATTACHMENTS_SMALL+A12Y_CONST.TEXT.ASSET_TRANSFER+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(true)}} className={`commentExpenseTab ${isCommentTabVisible?'activecommentExpenseTab':''}`}>{A12Y_CONST.LABEL.ATTACHMENTS_SMALL}</div>
                                        <div id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.TEXT.ASSET_TRANSFER+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(false)}}  className={`commentExpenseTab  ${isCommentTabVisible ? '' : 'activecommentExpenseTab'}`}>{A12Y_CONST.LABEL.NOTES}</div>
                                    </div>
                                    <div className="fullWidth tabContentBox">
                                        {isCommentTabVisible?
                                            <>
                                            {transferData.attachments && transferData.attachments.length > 0 ?
                                                    <InventoryFileViewer
                                                        currentAsset={transferData}
                                                        dependent={props.dependent}
                                                        type={A12Y_CONST.TEXT.ASSET_TRANSFER_KEY}
                                                    />
                                                    :
                                                    <div className='noData inventoryNote df aic jcc fcol'>
                                                        <strong>{A12Y_CONST.TEXT.NO_DATA_FOUND}</strong>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div className='fullWidth'>
                                                <NotesComponent
                                                    noteType={A12Y_CONST.LABEL.NOTE_ASSET_TRANSFER_TYPE}
                                                    selectedItem={transferData}
                                                    dependent={props.dependent}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                          {/*  <div className="inventoryRow fullWidth marginTop15 marginBottom20"><span className="inventory_label">{A12Y_CONST.LABEL.ATTACHMENT}</span>
                                {transferData.attachments && transferData.attachments.length > 0 ?
                                    <InventoryFileViewer
                                        currentAsset={transferData}
                                        dependent={props.dependent}
                                        type={A12Y_CONST.TEXT.ASSET_TRANSFER_KEY}
                                    />
                                    :
                                    null
                                }
                        </div>*/}
                        </div>
                    }
                </div>

    </div>
    
    
  )
}

export default AssetTransferDetailComponent