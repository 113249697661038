import React, { useRef, useEffect, useState } from "react";
import A12Y_CONST from "../common/a12yConst";
import moment from 'moment';
import PopUpComponent from '../components/PopUpComponent'
import LoaderFullScreen from './LoaderFullScreenComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import PopUpInviteUserComponent from '../components/PopUpInviteUserComponent'
import parsePhoneNumber from 'libphonenumber-js'
import ErrorMessageComponent from "./ErrorMessageComponent";
import { useHistory } from "react-router-dom";
import { ddmmyyyyFormat } from "../util/date_format";
//import {Tooltip} from "antd";
//import Cards from "react-credit-cards";
import LoaderFullScreenComponent from "./LoaderFullScreenComponent";

//import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function DependentProfileViewComponent(props) {
    const history = useHistory();
    const [visibleSection, setVisibleSection] = useState();
    const [isPopUp, setIsPopUp] = useState(false);
    const [isPopUp2, setIsPopUp2] = useState(false);
    const [isAdvocateTeamPopUp, setAdvocateTeamIsPopUp] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [error, setErrors] = useState(false);
    const [addressLIst, setAddressLIst] = useState([])
    const [popUpType, setPopUpType] = useState('');
    const [loading, setLoading] = useState(false);
    const headerRef = useRef(null);
    const sliderRef = useRef(null);
    const general_InformationRef = useRef(null);
    const addressRef = useRef(null);
    const advocateTeamRef = useRef(null);
    const billingRef = useRef(null);
    const [dependentDOB, setDependentDOB] = useState('');
    const [dependentAppointmentDate, setDependentAppointmentDate] = useState('');
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [advocateTeamList, setAdvocateTeamList]  = useState([]);
    const [popUpStatus, setPopUpStatus] = useState('');
    const [mobileNumberFormat, setMobileNumberFormat] = useState('');
    const [alternateNumberFormat, setAlternateNumberFormat] = useState('');
    const [inviteUserEmailId, setInviteUserEmailId]= useState('');
    const [inviteUserId, setInviteUserId] = useState('');
    const [isAdvocateTeam, setIsAdvocateTEam] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [bottomDivHeight, setBottomDivHeight] = useState(500);
    /* eslint-disable no-unused-vars */
    const [offSetTop, setOffSetTop] = useState (0);
    /* eslint-disable no-unused-vars */
    const [profileUrl, setProfileUrl] = useState('')
    const [isTab1Clicked, setIsTab1Clicked] = useState(false);
    const [isTab2Clicked, setIsTab2Clicked] = useState(false);
    const [isTab3Clicked, setIsTab3Clicked] = useState(false);
    const [isTab4Clicked, setIsTab4Clicked] = useState(false);
    const [imageLoaded, setImageLoaded]=React.useState(false);
    const [subscriptionMsg, setSubscriptionMsg] = useState('');
    const [isTabClick, setIsTabClick] = useState(false);


    const sectionRefs = [
        { section: "General_Information", ref: general_InformationRef },
        { section: "Address", ref: addressRef },
        { section: "Advocate_Team", ref: advocateTeamRef },
        { section: "Billing", ref: billingRef },
    ];

    useEffect(()=>{
        setLoading(props.billingLoading);
    },[props.billingLoading])

    useEffect(()=>{
        setWindowDimensions(getWindowDimensions())
    },[])

    const getDimensions = ele => {
        if(ele){
            const { height } = ele.getBoundingClientRect();
            const offsetTop = ele.offsetTop;
            const offsetBottom = offsetTop + height;

            return {
                height,
                offsetTop,
                offsetBottom,
            };
        }

    };


    useEffect(  () => {
        const dimension = async ()=>{
            if(windowDimensions.width > 768){
                setOffSetTop(170)
            }else{
                setOffSetTop(90)
            }

            if(!props.billingLoading){
                let advocateCol = advocateTeamRef.current.offsetHeight;
                let tabContainerHeight = windowDimensions.width > 1500 ? window.innerHeight - advocateCol - 175 : window.innerHeight - advocateCol - 275
                await setBottomDivHeight(tabContainerHeight > 0 ? tabContainerHeight : 0)
            }
        }
        dimension()

    },[visibleSection, windowDimensions, props.advocateTeamLoader, props.billingLoading])

    useEffect(  () => {
       const setProfileImg = async ()=>{
           await setProfileUrl(props.dependent.profile_pic_url)
       }
       setProfileImg()
    }, [profileUrl, props.dependent])


    useEffect( () => {
       const setAdvocateList = async ()=>{
           setLoaderFullScreenVisible(true)
           if(props.advocateTeamList && props.advocateTeamList.length > 0){
               await  setAdvocateTeamList(props.advocateTeamList)
               setIsAdvocateTEam(true);
           }else{
               setIsAdvocateTEam(false);
           }
           setLoaderFullScreenVisible(false)
       }
       setAdvocateList()
    },[props.advocateTeamList])


    const scrollTo = (ele, id) => {
        //var offset = 170;
        //var offset = 60;
        // var offset = offSetTop;
        let offsetTopElm = windowDimensions.width > 768 ? document.getElementById('TitleNameAndTabHeader').clientHeight : document.getElementById('TitleNameAndTabHeader').clientHeight - 35;
        var el = document.getElementById(id);
        window.scroll({ top: (el.offsetTop - offsetTopElm), left: 0, behavior: 'smooth' });
        setIsTabClick(true)
    };

   /* useEffect( async () => {
        // await setImageLoaded(true)
        await setProfileUrl(props.dependent.profile_pic_url)
        // return
        // await setProfileLoader(false)
    }, [profileUrl, props.dependent])*/

    useEffect(() => {
        setErrors(props.error)
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.error, props.loaderFullScreen])

    useEffect( ()  => {
    const setDefaultFormData = async ()=>{
        if(props.dependent.dob) {
            setDependentDOB(props.dependent.dob)
        }
        if(props.dependent.appointment_date) {
            setDependentAppointmentDate(props.dependent.appointment_date)
        }
    }
    setDefaultFormData();

    }, [props.dependent, dependentDOB])

    useEffect(() => {
        const handleScroll = async () => {
            if(headerRef.current){
                const { height: headerHeight } = getDimensions(headerRef.current);
                const scrollPosition = window.scrollY + headerHeight + 105;
                const selected = sectionRefs.find(({ section, ref }) => {
                    const ele = ref.current;
                    if (ele) {
                        const {offsetBottom, offsetTop } = getDimensions(ele);
                        return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                    }
                    return null
                });
                if (selected && selected.section !== visibleSection) {
                   await setVisibleSection(selected.section);
                    // console.log('General_Information---',selected.section)
                    if(selected.section === 'General_Information'){
                        if(!isTabClick){
                           await sliderRef.current.slickGoTo(0);
                        }

                    }else if (selected.section === 'Address'){
                        if(!isTabClick) {
                            // sliderRef.current.slickGoTo(2);
                            await  sliderRef.current.slickGoTo(1);
                        }
                    }else if (selected.section === 'Advocate_Team'){
                        if(!isTabClick){
                        // sliderRef.current.slickGoTo(2);
                            await sliderRef.current.slickGoTo(2);
                        }
                    }else if (selected.section === 'Billing'){
                        if(!isTabClick){
                        // sliderRef.current.slickGoTo(2);
                            await sliderRef.current.slickGoTo(3);
                        }
                    }
                    setIsTabClick(false)
                } else if (!selected && visibleSection) {
                    setVisibleSection(undefined);
                }
            }
        };

        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            setIsTab1Clicked(false)
            setIsTab2Clicked(false)
            setIsTab3Clicked(false)
            setIsTab4Clicked(false)
        };
    }, [visibleSection]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect (  () => {
        setTimeout(function(){
            if(visibleSection === 'General_Information'){
                sliderRef?.current?.slickGoTo(0);
            }
            if(visibleSection === 'Billing'){
                sliderRef?.current?.slickGoTo(3);
            }
        }, 700);


    },[visibleSection])

    useEffect (  () => {
        if(props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[0].type !== "" && props.dependent.phone[0].number.length > 1 ){
            const mobileNum = parsePhoneNumber('+'+props.dependent.phone[0].number);
            const mobileNumberFormating = '+1 ' + mobileNum.formatNational()
            setMobileNumberFormat(mobileNumberFormating)
        }
        if(props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[1].type !== "" && props.dependent.phone[1].number.length > 1 ){
            const alternateNum = parsePhoneNumber('+'+props.dependent.phone[1].number);
            const altNumberFormating = '+1 ' + alternateNum.formatNational()
            setAlternateNumberFormat(altNumberFormating)
        }


    },[props.dependent])

    const formatPhoneNumber = (phoneNumberString) =>  {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    const updateCurrentAddress = async (event) => {
       /* let ind = 0;
        let allAddress = addressLIst
        let updatedAddress = {}
        setSelectCurrentAddress(event.target.value)
        let updatedCurrentAddress = allAddress.map((item, index)=>{
            if(item.value === event.target.value){
                item.currentAddress = true;
                ind = index
                updatedAddress = item
            }else{
                item.currentAddress = false;
            }
        })
        let removeCurrentadder = allAddress.splice(ind, 1)
        allAddress.unshift(updatedAddress)
        setAddressLIst(allAddress)*/

    }

    useEffect(  ()=>{
        const scrolling = async ()=>{
            if(props.addressList && props.addressList.length > 0){
                let list = props.addressList
                list.forEach((item,index)=>{
                    if(index === 0){
                        item.currentAddress = true;
                    }else {
                        item.currentAddress = false;
                    }
                })
                await setAddressLIst(props.addressList)
            }
            setAddressLIst(props.addressList)

            if(props.sectionType === 'address'){
                scrollTo(addressRef.current, 'Address');
            }else if(props.sectionType === 'invite'){
                scrollTo(addressRef.current, 'Advocate_Team');
            }else if(props.sectionType === 'billing'){
                scrollTo(addressRef.current, 'Billing');
            }else{
                scrollTo(addressRef.current, 'General_Information');
            }
        }
        scrolling();

    },[props.addressList]) // eslint-disable-line react-hooks/exhaustive-deps



    const onEditHandler = ()=>{
        props.onEdit()
    }

    const addressEditHandler = (address)=>{
        props.onAddressEdit(address)
    }

    const advocateTeamResendHandler = (advocateTeam) => {
        props.advocateTeamResend(advocateTeam)
    }

    const addressDeleteHandler = (address)=>{
        setSelectedAddress(address)
        setIsPopUp(true)
    }

    const hidePopUp = () => {
        setSelectedAddress({})
        setIsPopUp(false)
    }
    const hidePopUpInviteTeam = (porpsType) => {
        setAdvocateTeamIsPopUp(false)
    }
    const handleDeleteButton = async () => {
        setLoaderFullScreenVisible(true)
        setIsPopUp(false)
        await props.onAddressDelete(selectedAddress)
        setLoaderFullScreenVisible(false)
    }
    const handleDeleteButtonInviteTeam = async (status) => {
        if(status === A12Y_CONST.TEXT.RESEND_INVITE){
            setAdvocateTeamIsPopUp(false)
        }
        if(status === A12Y_CONST.TEXT.DELETE_INVITE){
            setLoaderFullScreenVisible(true)
            props.handleDeleteInviteUser(inviteUserId)
            setAdvocateTeamIsPopUp(false)
            setLoaderFullScreenVisible(false)
        }

    }

    const createAddres = ()=>{
       props.onCreateAddress()
    }
    const inviteUser = ()=>{
       props.onInviteUser()

    }
    // const resendInvite = () => {
    //     setPopUpStatus(A12Y_CONST.TEXT.RESEND_INVITE)
    //     setAdvocateTeamIsPopUp(true)
    // }
    const invitationDelete = (item) => {
        setInviteUserEmailId(item.invitee_email)
        setInviteUserId(item.id)
        setPopUpStatus(A12Y_CONST.TEXT.DELETE_INVITE)
        setAdvocateTeamIsPopUp(true)
    }

    const goToDocument = ()=>{
       props.goToDocumnet()
       history.push(A12Y_CONST.ROUTE.DOCUMENTS);
    }

    const goToDocumentInvoice = () => {
        props.goToDocumnetInvoice()
        history.push(A12Y_CONST.ROUTE.DOCUMENTS);
    }

    useEffect( () => {
        const setAdvocateList = async ()=>{
            setLoaderFullScreenVisible(true)
            if(props.advocateTeamList && props.advocateTeamList.length > 0){
                await  setAdvocateTeamList(props.advocateTeamList)
                setIsAdvocateTEam(true);
            }else{
                setIsAdvocateTEam(false);
            }
            setLoaderFullScreenVisible(false)
        }
        setAdvocateList()
    },[props.advocateTeamList,])

    const editBilling = (billingEdit) => {
        props.editBilling(billingEdit)
    }

    const cancelSubscription = async () => {
        setPopUpType(A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION)
        setIsPopUp2(true);
        await setSubscriptionMsg(A12Y_CONST.TEXT.ARE_YOU_SURE_YOU)
    }

    const handleUnSubscribe = async () => {
        setIsPopUp2(false);
        await props.onCancel()
    }

    const hidePopup2 = () => {
        setIsPopUp2(false);
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows:true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    swipe: false
                }
            },
        ]
    };

    // console.log('this.props.dependent', props.dependent.subscirption.trial_period)
    //    console.log('this.props.dependent', props.dependent)
    //    console.log('props.dependent?.subscirption?.trial_period', !props.dependent?.subscirption?.trial_period)
       // console.log('props.dependent?.subscirption?.subscription_details', props.dependent?.subscirption?.subscription_details )
       // console.log('props?.dependent?.subscirption?.trial_period', props?.dependent?.subscirption?.trial_period)
    /*console.log('dependent',props.dependent)*/
    return (

        <div className="titleRow myProfileViewAccount">
            { loaderFullScreenVisible ?
              <LoaderFullScreen/>
                :
             null
            }
            { loading ?
                <LoaderFullScreenComponent/>
                :
                null
            }
            <div className='titleNameAndTabHeader' id='TitleNameAndTabHeader'>
                <h2 className="titleWithSidebar marginBottom30 headerFixed">{A12Y_CONST.LABEL.DEPENDENT_LABEL}</h2>
                <div className="dependentTabRow" ref={headerRef}>
                     <div
                         className={`width33 dependentTab texAlingCener ${visibleSection === "General_Information" ? "activeDependentTab" : ""} ${isTab1Clicked && ' clickDisable'}`}
                         onClick={(e) => {
                             setIsTab1Clicked(true)
                             scrollTo(general_InformationRef.current, 'General_Information');
                         }}
                     >
                      <span>{A12Y_CONST.TAB_TEXT.DEPENDENT_GENEERAL_INFO}</span>
                     </div>
                     <div
                         className={`width33 dependentTab texAlingCener ${visibleSection === "Address" ? "activeDependentTab" : ""} ${isTab2Clicked && ' clickDisable'}`}
                         onClick={() => {
                             setIsTab2Clicked(true)
                             scrollTo(addressRef.current, 'Address');
                         }}
                     >
                         <span>{A12Y_CONST.TAB_TEXT.DEPENDENT_ADDRESS}</span>
                     </div>
                     <div
                         className={`width33 dependentTab texAlingCener ${visibleSection === "Advocate_Team" ? "activeDependentTab" : ""} ${isTab3Clicked && ' clickDisable'}`}
                         onClick={() => {
                             setIsTab3Clicked(true)
                             scrollTo(advocateTeamRef.current, 'Advocate_Team');
                         }}
                     >
                         <span>{A12Y_CONST.TAB_TEXT.DEPENDENT_ADVOCATE_TEAM}</span>
                     </div>
                </div>
            </div>
            <div className='dependentTabContainer fullWidth '>
                <div className="dependentTabContainerCol paddingTop20" id="General_Information" ref={general_InformationRef}>
                    {error ?
                        <div className='maxWidth600 marginLeftRightAuto'>
                            <ErrorMessageComponent errorMessage={props.errorMessage}/>
                        </div>
                        :
                        (props.message ?
                                <SuccessMessageComponent successMessage={props.message}/>

                                :
                                null
                        )

                    }
                    <div className="generalInfoMain dependentProfileMainViewBox fullWidth paddingTop30">
                       <div className='fullWidth df marginBottom15 textStyle'>
                           {A12Y_CONST.LABEL.DEPENDENT_GENERAL_INFO}
                           {props.dependent?.access && props.dependent.access.can_update === true ?
                           <span id={A12Y_CONST.BUTTON_ID.GENERAL_INFO+A12Y_CONST.TEXT.EDIT} className='cursorPointer marginLeft10 marginTop5'><svg onClick={onEditHandler} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                               <path d="M15.194 4.24663C15.5224 3.91779 16.0563 3.91779 16.3847 4.24663L19.7531 7.61506C20.0823 7.9439 20.0823 8.47712 19.7531 8.80596L8.80583 19.7533C8.64836 19.9112 8.43363 20 8.21047 20H4.84209C4.37701 20 4 19.623 4 19.1579V15.7895C4 15.5661 4.08876 15.3519 4.24665 15.194L12.6677 6.77312L15.194 4.24663ZM13.263 8.55931L5.68419 16.1383V18.3158H7.86184L15.4407 10.7368L13.263 8.55931ZM16.6314 9.54592L17.967 8.21051L15.7893 6.03299L14.4538 7.3684L16.6314 9.54592Z" fill="#2D6CB2"/>
                           </svg>
                           </span>
                               :
                               null
                           }
                       </div>
                       <div className='fullWidth invBox df fcol'>
                            <div className="generalInfoTitle fullWidth positionRelative">
                                {!props.dependent.profile_pic_url ?
                                    <div className="generalInfoProfileDefaultImg">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144" fill="none">
                                            <circle cx="72" cy="72" r="72" fill="#E2EDF8"/>
                                            <circle cx="71.9999" cy="72.0008" r="63.4286" fill="#A1C4E7"/>
                                            <path d="M100.694 100.305C101.284 100.182 101.633 99.569 101.405 99.0115C99.4978 94.3457 95.7962 90.2359 90.7842 87.2447C85.3952 84.0285 78.7923 82.2852 71.9996 82.2852C65.2069 82.2852 58.604 84.0285 53.2149 87.2447C48.2029 90.2359 44.5014 94.3457 42.5937 99.0115C42.3657 99.5689 42.7154 100.182 43.305 100.305L63.8387 104.584C69.2214 105.706 74.7777 105.706 80.1604 104.584L100.694 100.305Z" fill="#F1F8FF"/>
                                            <ellipse cx="71.9998" cy="58.2854" rx="17.1429" ry="17.1429" fill="#F1F8FF"/>
                                        </svg>
                                    </div>
                                    :
                                    <div className="generalInfoProfileImg">
                                        {!imageLoaded && (
                                            <div className="picLoader">
                                                <div className='fullWidth justifyCenter marginBottom10' style={{height:15}}>
                                                    <div className="stage">
                                                        <div className="dot-pulse"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <img
                                            alt={''}
                                            onLoad={()=>{setImageLoaded(true)}}
                                            src={profileUrl}/>
                                    </div>
                                }
                                <div className="generalInfoUserName">
                                    {props.dependent.first_name} {props.dependent.last_name}
                                </div>
                                {/*{props.dependent?.access && props.dependent.access.can_update === true ?*/}
                                {/*<div className="generalInfo_buttonEditCol" onClick={onEditHandler}>*/}
                                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">*/}
                                {/*        <path d="M15.194 4.24663C15.5224 3.91779 16.0563 3.91779 16.3847 4.24663L19.7531 7.61506C20.0823 7.9439 20.0823 8.47712 19.7531 8.80596L8.80583 19.7533C8.64836 19.9112 8.43363 20 8.21047 20H4.84209C4.37701 20 4 19.623 4 19.1579V15.7895C4 15.5661 4.08876 15.3519 4.24665 15.194L12.6677 6.77312L15.194 4.24663ZM13.263 8.55931L5.68419 16.1383V18.3158H7.86184L15.4407 10.7368L13.263 8.55931ZM16.6314 9.54592L17.967 8.21051L15.7893 6.03299L14.4538 7.3684L16.6314 9.54592Z" fill="white"/>*/}
                                {/*    </svg>*/}
                                {/*    <span>*/}
                                {/*             {A12Y_CONST.LABEL.DEPENDENT_EDIT}*/}
                                {/*        </span>*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    null*/}
                                {/*}*/}
                            </div>
                            <div className="generalInfoSubTitle fullWidth marginTop35">
                                {A12Y_CONST.LABEL.DEPENDENT_PERSONAL_INFO}
                            </div>
                            <div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_D_O_B}</span>
                                <span className="geneInfo_value">{props.dependent.dob? dependentDOB : '-'}</span>
                            </div>
                            <div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_GENDER}</span>
                                <span className="geneInfo_value">{props.dependent.gender ? props.dependent.gender : '-'}</span>
                            </div>
                            <div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_DISPLAY_NAME}</span>
                                <span className="geneInfo_value">{props.dependent.nick_name ? props.dependent.nick_name : '-'}</span>
                            </div>
                            <div className="generalInfoSubTitle fullWidth marginTop35">
                                {A12Y_CONST.LABEL.DEPENDENT_CONTACT_INFO}
                            </div>
                            <div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_EMAIL}</span>
                                <span className="geneInfo_value">{props.dependent.email?.email ? props.dependent.email.email : '-'}</span>
                            </div>
                            {props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone.map((phone,key)=>(
                                phone.type !== "" && phone.number !== ''?
                                <div key={key} className="generaInfoRow fullWidth marginTop15">
                                    <span className="geneInfo_label">{phone.type !== null && phone.type === 'office'? A12Y_CONST.LABEL.OFFICE : phone.type === 'home' ? A12Y_CONST.LABEL.HOME : phone.type === 'mobile' ? A12Y_CONST.LABEL.MOBILE : A12Y_CONST.LABEL.OTHER }</span>
                                    <span className="geneInfo_value">{key === 0 ? mobileNumberFormat : alternateNumberFormat}</span>
                                    {/*<span className="geneInfo_value">{props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[0].type !="" && props.dependent.phone[0].number.length > 1?  mobileNumberFormat : '-'}</span>*/}
                                </div>
                                    : null
                            ))}
                            {/*<div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_MOBILE}</span>
                                <span className="geneInfo_value">{props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[0].type !="" ? props.dependent.phone[0].number : '-'}</span>
                                <span className="geneInfo_value">{props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[0].type !="" && props.dependent.phone[0].number.length > 1?  mobileNumberFormat : '-'}</span>
                            </div>
                            <div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_ALTERNATE}</span>
                                <span className="geneInfo_value">{props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[1].type !="" ? props.dependent.phone[1].number : '-'}</span>
                                <span className="geneInfo_value">{props.dependent.phone !== null && props.dependent?.phone?.length > 0 && props.dependent.phone[1].type !="" && props.dependent.phone[1].number.length > 1 ? alternateNumberFormat : '-'}</span>
                            </div>*/}

                            <div className="generalInfoSubTitle fullWidth marginTop35">
                                {A12Y_CONST.LABEL.LEGAL_AUTHORITY}
                            </div>
                            <div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.Dependent_DATE_APPOINTED}</span>
                                <span className="geneInfo_value">{props.dependent.appointment_date ? dependentAppointmentDate : '-'}</span>
                            </div>
                            <div className="generaInfoRow fullWidth marginTop15">
                                <span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_CASE_NUMBER}</span>
                                <span className="geneInfo_value">{props.dependent.case_file_num ? props.dependent.case_file_num : '-'}</span>
                            </div>
                            <div id={A12Y_CONST.BUTTON_ID.LEGAL_DOCUMENT} className="fullWidth marginTop30 df" onClick={goToDocument}>
                                {/*<span className="geneInfo_label">{A12Y_CONST.LABEL.DEPENDENT_DOCUMENT}</span>*/}
                                <span className='cursorPointer marginRight20'>
                                    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.36585 24C1.0039 24 0.65565 23.86 0.400236 23.6093C0.143455 23.36 0 23.02 0 22.6667V1.33333C0 0.98 0.143455 0.64004 0.400236 0.390706C0.65565 0.14004 1.0039 0 1.36585 0H11.4923L14.224 2.66667H24.5854C24.9473 2.66667 25.2956 2.80671 25.551 3.05737C25.8078 3.30671 25.9512 3.64667 25.9512 4V8H2.73171V21.328L5.46341 10.6667H28L24.8449 22.9906C24.7711 23.2786 24.6004 23.5347 24.36 23.7174C24.1196 23.9 23.8245 24 23.52 24H1.36585Z" fill="#2D6CB2"/>
                                    </svg>
                                </span><span className="geneInfo_value"><span>{A12Y_CONST.LABEL.VIEW} {props.dependent.nick_name}'s {A12Y_CONST.LABEL.LEGAL_DOCUMENTS}</span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dependentTabContainerCol" id="Address" ref={addressRef}>
                    <div className="dependentAddress dependentProfileMainViewBox fullWidth">
                        <div className="dependentAddressTitle dependentAddressTitle marginBottom5 textStyle positionRelative">
                            {A12Y_CONST.LABEL.DEPENDENT_ADDRESS}
                            {props.dependent?.access && props.dependent.access.can_update === true ?
                                <span className='marginLeft10 marginTop10 cursorPointer'>
                                    <svg id={A12Y_CONST.TEXT.ADD+A12Y_CONST.TEXT.ADDRESS} onClick={createAddres} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 4C12.552 4 13 4.4477 13 5V11H19C19.552 11 20 11.4477 20 12C20 12.5523 19.552 13 19 13H13V19C13 19.5523 12.552 20 12 20C11.448 20 11 19.5523 11 19V13H5C4.448 13 4 12.5523 4 12C4 11.4477 4.448 11 5 11H11V5C11 4.4477 11.448 4 12 4Z" fill="#0466C8"/>
                                </svg>
                                </span>
                                :
                                null
                            }
                        </div>
                        {addressLIst.length < 1 ?
                            <div className='dependentNoAddressData fullWidth texAlingCener'>

                                {props.addressLoader ?
                                    <div className="positionRelative">
                                        <div className="dependentLoader zIndex19">
                                            <span className='dependentLoaderCenterCol'>
                                                <div className='fullWidth justifyCenter marginBottom10' style={{height:15}}>
                                                    <div className="stage">
                                                    <div className="dot-pulse"></div>
                                                  </div>
                                                </div>
                                                <span>{A12Y_CONST.TEXT.LOADING_ADDRESS}</span>
                                            </span>
                                        </div>
                                    </div>
                                        :
                                        <div className="invBox df fcol aic jcc">
                                            <svg width="46" height="59" viewBox="0 0 46 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.7778 0C16.7367 0 10.9431 2.39979 6.67146 6.67146C2.39979 10.9431 0 16.7367 0 22.7778C0 41.2169 22.7778 58.5714 22.7778 58.5714C22.7778 58.5714 45.5556 41.2169 45.5556 22.7778C45.5556 16.7367 43.1558 10.9431 38.8841 6.67146C34.6124 2.39979 28.8188 0 22.7778 0ZM34.3077 35.7937C31.341 38.4308 27.5599 39.9712 23.5949 40.1581C19.6299 40.345 15.7207 39.167 12.5191 36.8206C9.31748 34.4742 7.01698 31.1011 6.0012 27.2639C4.98543 23.4267 5.31575 19.3573 6.93711 15.7341C8.55847 12.111 11.3729 9.15315 14.911 7.35382C18.4491 5.55449 22.4971 5.02242 26.3801 5.84633C30.263 6.67024 33.7461 8.80034 36.2487 11.8814C38.7513 14.9625 40.122 18.8084 40.1323 22.7778C40.1387 25.2361 39.6227 27.6677 38.6186 29.9116C37.6144 32.1555 36.145 34.1604 34.3077 35.7937Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                                <path d="M32.3335 34.5673L32.4528 34.4806L32.3335 34.5673ZM13.1025 34.4697L13.2219 34.5565L13.1025 34.4697Z" fill="black"/>
                                                <path d="M31.4895 34.3278C32.0792 34.2049 32.4303 33.5884 32.1437 33.0587C31.4507 31.7779 30.3391 30.6525 28.909 29.799C27.1038 28.7216 24.8921 28.1377 22.6168 28.1377C20.3414 28.1377 18.1297 28.7216 16.3245 29.799C14.8944 30.6525 13.7828 31.7779 13.0898 33.0587C12.8032 33.5884 13.1543 34.2049 13.744 34.3278L14.4559 34.4761C19.8386 35.5979 25.3949 35.5979 30.7776 34.4761L31.4895 34.3278Z" fill="#2D6CB2"/>
                                                <circle cx="22.6168" cy="20.0978" r="5.7423" fill="#2D6CB2"/>
                                            </svg>
                                            <div className="noAddressText fullWidth marginTop5">{A12Y_CONST.TEXT.NO_ADDRESS}</div>
                                            <div className="noAddressDescription marginTop5 fullWidth">{A12Y_CONST.TEXT.TRACK_CURRENT_ADDRESS_MESSAGE}</div>
                                        </div>
                                }
                            </div>
                            :
                            <div className="depAddress">
                                {
                                    addressLIst.map((item,index) => {
                                        return (
                                            item.currentAddress ?
                                                <div key={index} className='dependentAddressRow dependentCurrentAddressRow fullWidth'>
                                                    <div className="dependentColradioButton">
                                                        <label className='currentAddress_col positionRelative'>
                                                            <span className='addressTooltip'>{A12Y_CONST.TEXT.CURRENT_ADDRESS}</span>
                                                            <input type="radio"
                                                                   checked={item.currentAddress}
                                                                   onChange={updateCurrentAddress}
                                                                   value={item.value }
                                                                   name='address}'
                                                            />
                                                            <span className="dependentColAddress">{item.line1 && item.line1 +','} {item.line2 && item.line2+','} {item.city && item.city+','} {item.state && item.state} {item.zip} <span className='displayBlock'>{index > 0 ? 'from ' + ddmmyyyyFormat(new Date(item.start_date)) : null } {index > 0 ? 'until' : null}  {index > 0 ? ddmmyyyyFormat(new Date(addressLIst[index -1].start_date)) : null}</span></span>                                                        </label>
                                                    </div>
                                                    {props.dependent?.access && props.dependent.access.can_update === true ?
                                                        <div className="dependentColActionBUtton">
                                                       <span id={A12Y_CONST.TEXT.ADDRESS+A12Y_CONST.TEXT.EDIT+item.id+A12Y_CONST.BUTTON_ID.DESKTOP} className='desktopCol' onClick={()=>{addressEditHandler(item)}}>
                                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.293 4.29287C17.683 3.90237 18.317 3.90237 18.707 4.29287L22.707 8.29287C23.098 8.68337 23.098 9.31657 22.707 9.70707L9.707 22.7071C9.52 22.8946 9.265 23 9 23H5C4.4477 23 4 22.5523 4 22V18C4 17.7348 4.1054 17.4804 4.2929 17.2929L14.293 7.29307L17.293 4.29287ZM15 9.41417L6 18.4142V21H8.586L17.586 12L15 9.41417ZM19 10.5858L20.586 8.99997L18 6.41417L16.414 7.99997L19 10.5858Z" fill="#71767A"/>
                                                            </svg>
                                                        </span>
                                                            <span id={A12Y_CONST.TEXT.ADDRESS+A12Y_CONST.TEXT.EDIT+item.id+A12Y_CONST.BUTTON_ID.MOBILE} className='mobileCol' onClick={()=>{addressEditHandler(item)}}>
                                                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.4943 0.231217C10.8022 -0.0770725 11.3027 -0.0770725 11.6106 0.231217L14.7685 3.38912C15.0772 3.69741 15.0772 4.1973 14.7685 4.50559L4.50547 14.7688C4.35784 14.9168 4.15652 15 3.94732 15H0.789463C0.353443 15 0 14.6466 0 14.2105V11.0526C0 10.8433 0.0832095 10.6424 0.231234 10.4944L8.12595 2.5998L10.4943 0.231217ZM8.6841 4.27435L1.57893 11.3796V13.4211H3.62048L10.7256 6.31578L8.6841 4.27435ZM11.8419 5.1993L13.094 3.94735L11.0525 1.90593L9.8004 3.15788L11.8419 5.1993Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                                            </svg>
                                                        </span>
                                                            <span id={A12Y_CONST.TEXT.ADDRESS+A12Y_CONST.TEXT.DELETE+item.id+A12Y_CONST.BUTTON_ID.DESKTOP} className='desktopCol' onClick={()=>{addressDeleteHandler(item)}}>
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9 6C9 4.8954 9.89543 4 11 4H17C18.1046 4 19 4.8954 19 6V8H20.9897C20.9959 7.9999 21.0021 7.9999 21.0083 8H23C23.5523 8 24 8.4477 24 9C24 9.5523 23.5523 10 23 10H21.9311L21.0638 22.1425C20.989 23.189 20.1182 24 19.0689 24H8.93112C7.88184 24 7.01096 23.189 6.9362 22.1425L6.06888 10H5C4.44772 10 4 9.5523 4 9C4 8.4477 4.44772 8 5 8H6.99174C6.99795 7.9999 7.00414 7.9999 7.01032 8H9V6ZM11 8H17V6H11V8ZM8.07398 10L8.93112 22H19.0689L19.926 10H8.07398ZM12 12C12.5523 12 13 12.4477 13 13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13C11 12.4477 11.4477 12 12 12ZM16 12C16.5523 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V13C15 12.4477 15.4477 12 16 12Z" fill="#71767A"/>
                                                            </svg>
                                                        </span>
                                                            <span id={A12Y_CONST.TEXT.ADDRESS+A12Y_CONST.TEXT.DELETE+item.id+A12Y_CONST.BUTTON_ID.MOBILE} className='mobileCol' onClick={()=>{addressDeleteHandler(item)}}>
                                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D83933"/>
                                                        </svg>
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                <div key={index} className={item.length - 1 === index ?'dependentAddressRow fullWidth':'dependentAddressRow borderBottom fullWidth'}>
                                                    <div className="dependentColradioButton">
                                                        <label>
                                                            <input type="radio"
                                                                   checked={item.currentAddress}
                                                                   onChange={updateCurrentAddress}
                                                                   value={item.value }
                                                                   name='address'
                                                            />
                                                            <span className="dependentColAddress">{item.line1 && item.line1 +','} {item.line2 && item.line2+','} {item.city && item.city+','} {item.state && item.state} {item.zip} <span className='displayBlock'>{index > 0 ? 'from ' +moment(new Date(item.start_date)).format("MM/DD/YYYY") : null } {index > 0 ? 'until' : null} {index > 0 ? moment(new Date(addressLIst[index -1].start_date)).format("MM/DD/YYYY") : null}</span></span>
                                                        </label>
                                                    </div>
                                                    {props.dependent?.access && props.dependent.access.can_update === true ?
                                                        <div className="dependentColActionBUtton">
                                                       <span className='desktopCol' onClick={()=>{addressEditHandler(item)}}>
                                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.293 4.29287C17.683 3.90237 18.317 3.90237 18.707 4.29287L22.707 8.29287C23.098 8.68337 23.098 9.31657 22.707 9.70707L9.707 22.7071C9.52 22.8946 9.265 23 9 23H5C4.4477 23 4 22.5523 4 22V18C4 17.7348 4.1054 17.4804 4.2929 17.2929L14.293 7.29307L17.293 4.29287ZM15 9.41417L6 18.4142V21H8.586L17.586 12L15 9.41417ZM19 10.5858L20.586 8.99997L18 6.41417L16.414 7.99997L19 10.5858Z" fill="#71767A"/>
                                                            </svg>
                                                        </span>
                                                            <span className='mobileCol' onClick={()=>{addressEditHandler(item)}}>
                                                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.4943 0.231217C10.8022 -0.0770725 11.3027 -0.0770725 11.6106 0.231217L14.7685 3.38912C15.0772 3.69741 15.0772 4.1973 14.7685 4.50559L4.50547 14.7688C4.35784 14.9168 4.15652 15 3.94732 15H0.789463C0.353443 15 0 14.6466 0 14.2105V11.0526C0 10.8433 0.0832095 10.6424 0.231234 10.4944L8.12595 2.5998L10.4943 0.231217ZM8.6841 4.27435L1.57893 11.3796V13.4211H3.62048L10.7256 6.31578L8.6841 4.27435ZM11.8419 5.1993L13.094 3.94735L11.0525 1.90593L9.8004 3.15788L11.8419 5.1993Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                                            </svg>
                                                        </span>
                                                            <span className='desktopCol' onClick={()=>{addressDeleteHandler(item)}}>
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9 6C9 4.8954 9.89543 4 11 4H17C18.1046 4 19 4.8954 19 6V8H20.9897C20.9959 7.9999 21.0021 7.9999 21.0083 8H23C23.5523 8 24 8.4477 24 9C24 9.5523 23.5523 10 23 10H21.9311L21.0638 22.1425C20.989 23.189 20.1182 24 19.0689 24H8.93112C7.88184 24 7.01096 23.189 6.9362 22.1425L6.06888 10H5C4.44772 10 4 9.5523 4 9C4 8.4477 4.44772 8 5 8H6.99174C6.99795 7.9999 7.00414 7.9999 7.01032 8H9V6ZM11 8H17V6H11V8ZM8.07398 10L8.93112 22H19.0689L19.926 10H8.07398ZM12 12C12.5523 12 13 12.4477 13 13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13C11 12.4477 11.4477 12 12 12ZM16 12C16.5523 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V13C15 12.4477 15.4477 12 16 12Z" fill="#71767A"/>
                                                            </svg>
                                                        </span>
                                                            <span className='mobileCol' onClick={()=>{addressDeleteHandler(item)}}>
                                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D83933"/>
                                                        </svg>
                                                        </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                        )

                                    })
                                }
                            </div>
                        }
                    </div>
                </div>

               <div className="dependentTabContainerCol" id="Advocate_Team" ref={advocateTeamRef}>
                <div className="dependentAddress dependentProfileMainViewBox fullWidth">
                    <div className="dependentAddressTitle dependentAddressTitle textStyle marginBottom5 positionRelative">
                        {A12Y_CONST.LABEL.DEPENDENT_ADVOCATE_TEAM}
                        {props.dependent?.access && props.dependent.access.can_update === true ?
                            <span className='cursorPointer marginLeft10 marginTop10' onClick={inviteUser}>
                                  <svg id={A12Y_CONST.BUTTON_ID.ADVOCATE_TEAM+A12Y_CONST.TEXT.ADD} onClick={createAddres} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 4C12.552 4 13 4.4477 13 5V11H19C19.552 11 20 11.4477 20 12C20 12.5523 19.552 13 19 13H13V19C13 19.5523 12.552 20 12 20C11.448 20 11 19.5523 11 19V13H5C4.448 13 4 12.5523 4 12C4 11.4477 4.448 11 5 11H11V5C11 4.4477 11.448 4 12 4Z" fill="#0466C8"/>
                                </svg>
                            </span>

                            :
                            null
                        }
                    </div>
                    <div className='dependentNoAddressData fullWidth texAlingCener'>
                        {props.advocateTeamLoader ?
                            <div className="positionRelative">
                                <div className="dependentLoader">
                                    <span className='dependentLoaderCenterCol'>
                                        <div className='fullWidth justifyCenter marginBottom10' style={{height:15}}>
                                            <div className="stage">
                                            <div className="dot-pulse"></div>
                                          </div>
                                        </div>
                                        <span>{A12Y_CONST.TEXT.LOADING_INVITE_USER}</span>
                                    </span>
                                </div>
                            </div>
                            :
                            (!isAdvocateTeam ?
                                <div className="invBox df fcol jcc aic">
                                    <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.5038 48.7234C30.5038 50.8293 31.1055 52.9353 32.3089 54.7404H0.34375V48.0465C0.34375 36.9903 9.294 28.04 20.3502 28.04H21.6288C28.6987 28.04 35.3174 31.8006 38.9276 37.8928C33.9636 39.1714 30.5038 43.6842 30.5038 48.7234Z" fill="#2D6CB2" fillOpacity="0.99"/>
                                        <path d="M21.027 27.0764C28.5039 27.0764 34.5652 21.0151 34.5652 13.5382C34.5652 6.06125 28.5039 0 21.027 0C13.55 0 7.48877 6.06125 7.48877 13.5382C7.48877 21.0151 13.55 27.0764 21.027 27.0764Z" fill="#2D6CB2"/>
                                        <path d="M52 48.0404C52 54.6667 46.6486 60 40 60C33.3513 60 28 54.6667 28 48.0404C28 42.5455 31.7297 37.7778 37.0811 36.404C38.0541 36.1616 39.027 36 40.0811 36C46.5676 36.0808 52 41.4141 52 48.0404Z" fill="#F7F9FA"/>
                                        <path d="M33.0001 47.147C32.9959 46.8503 33.1295 46.6661 33.3799 46.5717C34.7824 46.0369 36.1848 45.502 37.5914 44.9672C40.1458 43.9964 42.6961 43.0301 45.2505 42.0549C45.4759 41.9695 45.6804 41.9695 45.8557 42.1582C46.031 42.347 46.0268 42.5672 45.9475 42.8099C44.5576 47.0481 43.1677 51.2908 41.7778 55.5289C41.6484 55.9245 41.3729 56.0907 41.0766 55.9514C40.9514 55.893 40.8637 55.7941 40.8053 55.6638C40.0999 54.0323 39.3945 52.4054 38.6808 50.7739C38.6349 50.675 38.6808 50.6346 38.735 50.5762C39.4529 49.8031 40.1709 49.0301 40.8888 48.2571C41.0223 48.1133 41.1016 47.9515 41.0432 47.7447C40.9806 47.52 40.8429 47.3807 40.6342 47.3267C40.4463 47.2773 40.3044 47.3672 40.175 47.502C39.4696 48.2661 38.7601 49.0211 38.0589 49.7897C37.967 49.8885 37.9003 49.8975 37.7834 49.8391C36.31 49.0975 34.8324 48.3605 33.3549 47.6189C33.142 47.5155 33.0126 47.3582 33.0001 47.147Z" fill="#2D6CB2"/>
                                    </svg>
                                    <div className="noAddressText fullWidth marginTop5">{A12Y_CONST.TEXT.NO_INVITED_USER}</div>
                                    <div className="noAddressDescription marginTop5 fullWidth">Invite other advocates to have view only access to {props.dependent.nick_name}'s account.</div>
                                </div>
                                :
                                <div className="depAddress">
                                    {advocateTeamList.map((item,index) => {
                                        return (
                                            <div key={index} className={item.length - 1 === index ?'dependentAdvocateTeamRow positionRelative fullWidth':'dependentAdvocateTeamRow positionRelative borderBottom fullWidth'}>
                                                <div className='dependentAdvocateTeamIcon'>
                                                    { item.advocate ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                            <circle cx="16" cy="16" r="16" fill="#112E53"/>
                                                            <path d="M16.8285 7H13.9233C15.3319 10.9616 18.3603 19.4131 19.2055 21.526C20.0506 23.6389 21.6705 24.1671 22.3748 24.1671H25.8082C25.456 23.2867 24.2048 20.2122 22.1107 14.395C19.7337 7.79233 18.9413 7.26411 16.8285 7Z" fill="white"/>
                                                            <path d="M12.8668 12.5469H16.0361C15.1558 14.7478 13.2366 19.625 12.6027 21.5266C11.9689 23.4282 10.4018 24.0796 9.69752 24.1677H6C6.88036 21.8787 8.85238 16.8254 9.69752 14.9239C10.5427 13.0223 12.1625 12.5469 12.8668 12.5469Z" fill="white"/>
                                                        </svg>
                                                        :
                                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20 4C28.832 4 36 11.168 36 20C36 28.832 28.832 36 20 36C11.168 36 4 28.832 4 20C4 11.168 11.168 4 20 4ZM10.4368 25.4719C12.7856 28.9759 16.312 31.2 20.256 31.2C24.1984 31.2 27.7264 28.9759 30.0736 25.4719C27.4112 22.9759 23.9008 21.6 20.256 21.6C16.6112 21.6 13.1008 22.9759 10.4368 25.4719ZM20 18.4C21.2736 18.4 22.4944 17.8882 23.3936 16.9922C24.2944 16.0962 24.8 14.88 24.8 13.6C24.8 12.32 24.2944 11.1038 23.3936 10.2078C22.4944 9.31181 21.2736 8.8 20 8.8C18.7264 8.8 17.5056 9.31181 16.6064 10.2078C15.7056 11.1038 15.2 12.32 15.2 13.6C15.2 14.88 15.7056 16.0962 16.6064 16.9922C17.5056 17.8882 18.7264 18.4 20 18.4Z" fill="#0466C8" fillOpacity="0.4"/>
                                                        </svg>
                                                    }

                                                </div>
                                                <div className='dependentAdvocateTeamUserName texAlingLeft'>{item.invitee_first_name} {item.invitee_last_name}</div>
                                                <div className='dependentAdvocateTeamUserEmailId texAlingLeft'><a id={A12Y_CONST.BUTTON_ID.EMAIL+item.id} href={`mailto:${item.invitee_email}`}>{item.invitee_email}</a></div>
                                                <div className={item.status === 0 ? 'dependentPendingStatus dependentAdvocateTeamUserStatus':'dependentActiveStatus dependentAdvocateTeamUserStatus'}>{item.status === 0 ? 'Pending' : 'Active' }</div>
                                                <div className="dependentAdvocateActionBUtton">
                                                    <div className="advocateTeamCall">
                                                        {item?.phones && item?.phones !== null && (item?.phones[0].number !== '' || item?.phones[1].number !== '') ?
                                                            <a id={A12Y_CONST.BUTTON_ID.PHONE_NUMBER+item.id} href={"tel:" + item.phones[0].number}>
                                                                     <span className='contactIcon'>
                                                                         <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                                            <path d="M2.67963 1.58353L3.55605 0.707107C3.94658 0.316583 4.57974 0.316582 4.97026 0.707106L7.76658 3.50342C8.1571 3.89394 8.1571 4.52711 7.76658 4.91763L5.84261 6.8416C5.528 7.15621 5.45 7.63685 5.64898 8.03481C6.79925 10.3353 8.66465 12.2007 10.9652 13.351C11.3632 13.55 11.8438 13.472 12.1584 13.1574L14.0824 11.2334C14.4729 10.8429 15.1061 10.8429 15.4966 11.2334L18.2929 14.0297C18.6834 14.4203 18.6834 15.0534 18.2929 15.4439L17.4165 16.3204C15.3051 18.4317 11.9625 18.6693 9.57382 16.8777L7.94436 15.6556C6.20083 14.348 4.65201 12.7992 3.34436 11.0556L2.12226 9.42618C0.330722 7.03745 0.568273 3.69488 2.67963 1.58353Z" fill="#2D6CB2"/>
                                                                        </svg>
                                                                     </span>
                                                            </a>
                                                            :
                                                            null}
                                                    </div>
                                                    {props.dependent?.access && props.dependent.access.can_update === true ?
                                                        <div>
                                                            {item.status === 0 ?
                                                                <div className='displayInlineBlock'>
                                                    <span id={A12Y_CONST.BUTTON_ID.RESEND+item.id+A12Y_CONST.BUTTON_ID.DESKTOP} className='desktopCol' onClick={() => {advocateTeamResendHandler(item)}}>
                                                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 14.6741C4.13057 14.3963 4.37082 14.2656 4.62151 14.1294C10.7426 10.8014 16.8585 7.46803 22.9744 4.13461C23.2355 3.99299 23.4862 3.92763 23.7421 4.11827C23.9928 4.3089 24.0294 4.57035 23.9824 4.88082C23.669 7.02684 23.3713 9.17286 23.0632 11.3243C22.7707 13.3832 22.473 15.4421 22.1805 17.501C22.0239 18.5958 21.8724 19.6906 21.7105 20.7799C21.6374 21.2756 21.2822 21.488 20.8226 21.3246C18.7387 20.5838 16.66 19.8431 14.5762 19.1023C13.7771 18.8191 12.978 18.5304 12.1476 18.2363C14.9626 14.7013 17.7673 11.1773 20.6085 7.6042C20.4048 7.70224 20.2951 7.83841 20.1698 7.94734C16.7071 11.0357 13.2443 14.124 9.78163 17.2177C9.6824 17.3049 9.60405 17.3321 9.47348 17.2885C7.81264 16.684 6.14657 16.0903 4.4805 15.502C4.24547 15.4203 4.10968 15.246 4 15.039C4 14.9192 4 14.7939 4 14.6741Z" fill="#2D6CB2"/>
                                                        <path d="M14.6856 20.5186C13.9962 21.499 13.3225 22.4522 12.6487 23.4108C12.56 23.5361 12.4764 23.6668 12.3772 23.7812C12.2048 23.9882 11.9384 24.0535 11.7034 23.9555C11.4632 23.852 11.3065 23.6614 11.3065 23.3836C11.3013 22.0491 11.3013 20.7092 11.3013 19.3748C11.3013 19.3639 11.3117 19.3475 11.3169 19.3203C12.4346 19.7125 13.5471 20.1101 14.6856 20.5186Z" fill="#2D6CB2"/>
                                                        </svg>
                                                    </span>
                                                            <span id={A12Y_CONST.BUTTON_ID.RESEND+item.id+A12Y_CONST.BUTTON_ID.MOBILE} className='mobileCol' onClick={() => {advocateTeamResendHandler(item)}}>
                                                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 8.53929C0.104456 8.31706 0.296655 8.21248 0.49721 8.10355C5.39411 5.44116 10.2868 2.77443 15.1795 0.107687C15.3885 -0.00560586 15.589 -0.0578949 15.7937 0.0946147C15.9943 0.247124 16.0235 0.45628 15.9859 0.704653C15.7352 2.42148 15.4971 4.1383 15.2506 5.85948C15.0166 7.50658 14.7784 9.15368 14.5444 10.8008C14.4191 11.6766 14.2979 12.5525 14.1684 13.424C14.1099 13.8205 13.8258 13.9904 13.4581 13.8597C11.791 13.2671 10.128 12.6745 8.46093 12.0819C7.82166 11.8553 7.18239 11.6243 6.51805 11.389C8.77012 8.56107 11.0138 5.74183 13.2868 2.88336C13.1238 2.96179 13.0361 3.07073 12.9358 3.15788C10.1657 5.62853 7.39548 8.09919 4.62531 10.5742C4.54592 10.6439 4.48325 10.6657 4.37879 10.6308C3.05011 10.1472 1.71726 9.67222 0.384398 9.20161C0.196377 9.13625 0.087743 8.99682 0 8.83123C0 8.73537 0 8.63515 0 8.53929Z" fill="#2D6CB2"/>
                                                        <path d="M8.54856 13.215C7.99704 13.9993 7.45804 14.7619 6.91905 15.5288C6.84802 15.629 6.78117 15.7336 6.70178 15.8251C6.5639 15.9906 6.35081 16.0429 6.16279 15.9645C5.97059 15.8817 5.84524 15.7292 5.84524 15.507C5.84106 14.4394 5.84106 13.3675 5.84106 12.2999C5.84106 12.2912 5.84942 12.2781 5.8536 12.2563C6.74774 12.5701 7.63771 12.8882 8.54856 13.215Z" fill="#2D6CB2"/>
                                                        </svg>
                                                    </span>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <span id={A12Y_CONST.TEXT.DELETE+item.id+A12Y_CONST.BUTTON_ID.DESKTOP} className='desktopCol' onClick={() => {invitationDelete(item)}}>
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 6C9 4.8954 9.89543 4 11 4H17C18.1046 4 19 4.8954 19 6V8H20.9897C20.9959 7.9999 21.0021 7.9999 21.0083 8H23C23.5523 8 24 8.4477 24 9C24 9.5523 23.5523 10 23 10H21.9311L21.0638 22.1425C20.989 23.189 20.1182 24 19.0689 24H8.93112C7.88184 24 7.01096 23.189 6.9362 22.1425L6.06888 10H5C4.44772 10 4 9.5523 4 9C4 8.4477 4.44772 8 5 8H6.99174C6.99795 7.9999 7.00414 7.9999 7.01032 8H9V6ZM11 8H17V6H11V8ZM8.07398 10L8.93112 22H19.0689L19.926 10H8.07398ZM12 12C12.5523 12 13 12.4477 13 13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13C11 12.4477 11.4477 12 12 12ZM16 12C16.5523 12 17 12.4477 17 13V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V13C15 12.4477 15.4477 12 16 12Z" fill="#71767A"/>
                                                        </svg>
                                                </span>
                                                            <span id={A12Y_CONST.TEXT.DELETE+item.id+A12Y_CONST.BUTTON_ID.MOBILE} className='mobileCol' onClick={() => {invitationDelete(item)}}>
                                                               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4 1.6C4 0.71632 4.71634 0 5.6 0H10.4C11.2837 0 12 0.71632 12 1.6V3.2H13.5918C13.5967 3.19992 13.6017 3.19992 13.6066 3.2H15.2C15.6418 3.2 16 3.55816 16 4C16 4.44184 15.6418 4.8 15.2 4.8H14.3449L13.651 14.514C13.5912 15.3512 12.8946 16 12.0551 16H3.9449C3.10547 16 2.40877 15.3512 2.34896 14.514L1.6551 4.8H0.8C0.358176 4.8 0 4.44184 0 4C0 3.55816 0.358176 3.2 0.8 3.2H2.39339C2.39836 3.19992 2.40331 3.19992 2.40826 3.2H4V1.6ZM5.6 3.2H10.4V1.6H5.6V3.2ZM3.25918 4.8L3.9449 14.4H12.0551L12.7408 4.8H3.25918ZM6.4 6.4C6.84184 6.4 7.2 6.75816 7.2 7.2V12C7.2 12.4418 6.84184 12.8 6.4 12.8C5.95818 12.8 5.6 12.4418 5.6 12V7.2C5.6 6.75816 5.95818 6.4 6.4 6.4ZM9.6 6.4C10.0418 6.4 10.4 6.75816 10.4 7.2V12C10.4 12.4418 10.0418 12.8 9.6 12.8C9.15816 12.8 8.8 12.4418 8.8 12V7.2C8.8 6.75816 9.15816 6.4 9.6 6.4Z" fill="#D83933"/>
                                                            </svg>
                                                </span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>)
                        }
                    </div>


                </div>

            </div>
                <div className="bottom-spacer" style={{height:bottomDivHeight}} />

                </div>
            <PopUpComponent
                popUpStatus={isPopUp}
                // title={A12Y_CONST.TEXT.TITLE_DELETE_THE_UPDATE}
                // message={A12Y_CONST.LABEL.DEPENDENT_YOU_WILL_NOTE_ABLE}
                title={A12Y_CONST.TEXT.DELETE_THIS_ADDRESS}
                message={A12Y_CONST.LABEL.DELETE_WILL_PERMANENETLY}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.DELETE}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.CANCEL}
                hidePopUp={hidePopUp}
                handleDeleteButton={handleDeleteButton}
            />


            <PopUpInviteUserComponent
                popUpStatus={isAdvocateTeamPopUp}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE}
                message={A12Y_CONST.LABEL.ANY_EDITS_YOU}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.YES}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.NO}
                hidePopUp={hidePopUpInviteTeam}
                popUpType={popUpStatus}
                handleDeleteButton={handleDeleteButtonInviteTeam}
                inviteUserEmailId = {inviteUserEmailId}

            />
            <PopUpComponent
                popUpStatus={isPopUp2}
                title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE}
                message={subscriptionMsg}
                buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.CANCEL_PLAN}
                buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.KEEP_PLAN}
                hidePopUp={hidePopup2}
                handleDeleteButton={handleUnSubscribe}
                popUpType={popUpType}
            />
        </div>




);
}

export default DependentProfileViewComponent;
