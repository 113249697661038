import React, {useEffect, useState} from "react";
import moment from 'moment';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';
// import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {useForm} from "react-hook-form";
import A12Y_CONST from "../common/a12yConst";
import ErrorMessageComponent from "./ErrorMessageComponent";
import Loading from "./LoadingComponent";
// import PopUpComponent from "./PopUpComponent";
import EditChangesRemovePopup from '../components/EditChangesRemovePopup'
import LoaderFullScreenComponent from "./LoaderFullScreenComponent";
//const stripePromise = loadStripe('pk_test_h3Oy3GfTmt67Q6JOSleepomK005ScnwAHy');


const BillingComponent = props =>{
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [isPromoCodeBtnDisabel, setIsPromoCodeBtnDisabel] = useState(true);
    const [promoCode, setPromoCode] = useState('');
    const [isNumberComplete, setIsNumberComplete] = useState(false);
    const [isExpiryComplete, setIsExpiryComplete] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isCVCComplete, setIsCVCComplete] = useState(false);
    //const [isTCChecked, setIsTCChecked] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    // const [userSubscription, setUserSubscription] = useState(null);
    // const [paymentMethod, setPaymentMethod] = useState(null);
    // const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [editMode, setEditMode] = useState(false);
    // const [startDate, setStartDate] = useState(null);
    // const [renewalDate, setRenewalDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setErrors] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
   // const [expiryDate, setExpiryDate] = useState(new Date());
   //  const [subscriptionMsg, setSubscriptionMsg] = useState('');
    /*const [isCardNumberFocus, setIsCardNumberFocus] = useState(false);
    const [isCardExpiryElementFocus, setIsCardExpiryElementFocus] = useState(false);
    const [isCardCvcElementFocus, setIsCardCvcElementFocus] = useState(false);*/
    const {register,handleSubmit, setError  ,formState:{ errors}} = useForm({
        mode:"all"
    });

    // const [isPopUp, setIsPopUp] = useState(false);
    // const [popUpType, setPopUpType] = useState('');
    const [cardErrorMsg, setCardErrorMsg] = useState('');
    const [expiryErrorMsg, setExpiryErrorMsg] = useState('');
    const [cvcErrorMsg, setCvcErrorMsg] = useState('');
    const [isDataModified, setIsDataModified] = useState(false);
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [isPromocodeErrMessVisible, setIsPromocodeErrMessVisible] = useState(true)


    useEffect(()=>{
        setErrors(props.error)
        setLoading(props.loading);
        setShowPopup(props.showPopup)
        setLoaderFullScreenVisible(props.loaderFullScreen)
    },[props.error, props.loading,props.showPopup, props.loaderFullScreen])

    useEffect(()=>{
         assignDefaultValue();
    },[props.userSubscription,props.paymentMethod]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(isExpiryComplete && isCVCComplete && isNumberComplete){
            if(promoCode.length > 0 && !props.isPromoCodeApplied){
                setIsBtnDisabled(true)
            }else{
              setIsBtnDisabled(false)
            }

        }else{
            setIsBtnDisabled(true)
        }
    },[isCVCComplete, isExpiryComplete, isNumberComplete, promoCode, props.isPromoCodeApplied])

    useEffect( ()=>{
        props.isDataChanged(false)
        setIsDataModified(false)
        if(cvcErrorMsg.length >0 || expiryErrorMsg.length > 0 || cardErrorMsg.length > 0 || isCVCComplete || isExpiryComplete || isNumberComplete){
            setIsDataModified(true)
            props.isDataChanged(true)
        }

    },[cvcErrorMsg , expiryErrorMsg , cardErrorMsg,isCVCComplete, isExpiryComplete, isNumberComplete,]) // eslint-disable-line react-hooks/exhaustive-deps


    const assignDefaultValue = async() =>{
        // setUserSubscription(props.dependent.subscirption.subscription_details)
        // setPaymentMethod(props.dependent.subscirption.paymentMethod)
        // if(props?.dependent?.subscirption.subscription_status === A12Y_CONST.LABEL.TRIALING){
        // if(false){
        //     setIsNewCustomer(true)
        // }else{
        //     // setStartDate(new Date(props.userSubscription.start_date).toDateString())
        //     // setRenewalDate(new Date(props.userSubscription.renewal_date).toDateString())
        //     if(props?.dependent?.subscirption?.subscription_details?.start_date )setStartDate(new Date(props?.dependent?.subscirption?.subscription_details?.start_date).toDateString());
        //     if(props?.dependent?.subscirption?.subscription_details?.renewal_date)setRenewalDate(new Date(props?.dependent?.subscirption?.subscription_details?.renewal_date).toDateString());
        //
        //     setIsNewCustomer(false)
        //    // setEditMode(false) oldOne
        //     setEditMode(true)
        // }
        // if(props?.dependent?.subscirption?.subscription_details?.start_date )setStartDate(new Date(props?.dependent?.subscirption?.subscription_details?.start_date).toDateString());
        // if(props?.dependent?.subscirption?.subscription_details?.renewal_date)setRenewalDate(new Date(props?.dependent?.subscirption?.subscription_details?.renewal_date).toDateString());

        // setIsNewCustomer(false)
        setEditMode(true)
    }

    const getCardDetails = async(e) =>{
        setIsNumberComplete(e.complete)
        await validateCard(e)
    }

    const getCardExpiry = async(e) =>{
        setIsExpiryComplete(e.complete)
        await validateExpiry(e)
    }

    const getCvc = async(e) =>{
        setIsCVCComplete(e.complete)
        await validateCvc(e)
    }
    const onSubmit = async (event) => {
        setIsBtnDisabled(true)
        setLoading(true)
        const card = elements.getElement(CardNumberElement);
        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card
            /*billing_details: {
                name,
                address: {
                    postal_code: postal,
                },
            },*/
        });

        if (payload.error) {
            //setErrorMessage(payload.error.message);
            //setPaymentMethod(null);
            setIsBtnDisabled(false)
        } else {
            let obj={payMethod : payload.paymentMethod, editMode:editMode}
            await props.onSubmit(obj)
            setIsBtnDisabled(false)
            //setPaymentMethod(payload.paymentMethod);
            //setErrorMessage(null);
        }
    }

	// const handleUnSubscribe = async () => {
    //     setIsPopUp(false);
    //     await props.onCancel()
    // }
    //
	// const hidePopup = () => {
    //     setIsPopUp(false);
    // }

	// const cancelSubscription = async () => {
    //     setPopUpType(A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION)
    //     setIsPopUp(true);
	// 	//let dt = await ddmmyyyyFormat(expiryDate)
	// 	// await setSubscriptionMsg(A12Y_CONST.TEXT.REMOVE_SUBSCRIPTION_TEXT.replace('#expiryDate#', renewalDate))
    //     await setSubscriptionMsg(A12Y_CONST.TEXT.ARE_YOU_SURE_YOU)
	// }

    const validateCard = (e) => {
        let msg = e.error !== undefined ? e.error.message : ''
        setCardErrorMsg(msg)
    }

    const validateExpiry = (e) => {
        let msg = e.error !== undefined ? e.error.message : ''
        setExpiryErrorMsg(msg)
    }
    const validateCvc = (e) => {
        let msg = e.error !== undefined ? e.error.message : ''
        setCvcErrorMsg(msg)
    }

   const onContinue = ()=>{
       props.isDataChanged(false)
        props.onContinue()
    }

    const cancelBilling = () => {
        if(cvcErrorMsg.length > 0 || expiryErrorMsg.length > 0 || cardErrorMsg.length > 0 || isDataModified){
            setIsPopUpVisible(true);
        }else{
            if(props.userSubscriptionAllDetails?.is_trial_expired || props.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED || props.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.FAILED_PAYMENT){
                props.goToDashBoardScreen()
            }else {
                props.onBack()
            }
            setEditMode(false)

        }     
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        setCvcErrorMsg('')
        setExpiryErrorMsg('')
        setCardErrorMsg('')

        //reset credit card form
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
       // setIsTCChecked(false)
        if(editMode){
            // setEditMode(false)
            props.onBack()
        }else {
            if(props.onFlow){
                await  props.addDependentTabActive()
            }else {
                props.onBack()
            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handlePromoCode = async (promoCode)=>{
        let code = promoCode.trim()
        await setPromoCode(code)
        if(code.length > 0){
            setIsPromoCodeBtnDisabel(false)
        }else {
            resetPromocodeError();
            setIsPromoCodeBtnDisabel(true)
        }
    }

    const applyPromoCode = ()=>{
        props.applyPromoCode(promoCode)
    }

    const resetPromocodeError = ()=>{
        if(props.promocodeError){
            setPromoCode('')
            setIsPromoCodeBtnDisabel(true)
            props.resetPromocodeError(true)
        }
    }

    const myAccountViewSection = () => {
        props.myAccountViewSection()
    }

    // console.log('props-dependent', props.dependent)
    // console.log('props-dependent', props?.dependent?.subscirption.subscription_status)
    // console.log('plan-details', props.subscriptionProduct[0].plans[0].plan_price)

    return (
        <div className='fullWidth height100 marginTop30 paddingBottom100 billingFormWrap'>
            { loaderFullScreenVisible ?
                <LoaderFullScreenComponent/>
                :
                null
            }
            {error ?
                <div className='maxWidth600 marginLeftRightAuto'>
                    <ErrorMessageComponent errorMessage={props.errorMessage}/>
                </div>
                :
                // (props.message ?
                //         <SuccessMessageComponent successMessage={props.message}/>
                //
                //         :
                //         null
                // )
                null

            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
                <div>
                {
                    props.onFlow ?
                        // <form onSubmit={handleSubmit(onSubmit)} className="df billingForm newFormUI fcol marginTop10 "
                        //       autoComplete="off">
                        //     <div className="fullWidth">
                        //         {/*<div className='formRow fullWidth'>*/}
                        //         {/*	<label>{A12Y_CONST.LABEL.PLAN_NAME}</label>*/}
                        //         {/*	<div className="fullWidth borderBotom paddingBottom5">*/}
                        //         {/*		<strong className="head24">{props?.userPlan?.plan_name}</strong>*/}
                        //         {/*	</div>*/}
                        //         {/*</div>*/}
                        //         {/*<div className='formRow fullWidth marginTop30'>*/}
                        //         {/*	<label>{A12Y_CONST.LABEL.PLAN_CHARGES}</label>*/}
                        //         {/*    <div className="fullWidth borderBotom paddingBottom5">*/}
                        //         {/*		<strong className="head24">${props?.userPlan?.plan_price} / {props?.userPlan?.billing_period}</strong>*/}
                        //         {/*	</div>*/}
                        //         {/*</div>*/}
                        //         <div className="fullWidth billingBgheader marginBottom30">
                        //             <div
                        //                 className="yearlyMemberShip marginTop20 fullWidth texAlingCener">{props?.userPlan?.billing_period} {A12Y_CONST.LABEL.MEMBERSHIP}</div>
                        //             <div
                        //                 className="fullwidth planAmount texAlingCener marginBottom30">${props.isPromoCodeApplied ? props.discountAmt : props?.userPlan?.plan_price}</div>
                        //         </div>
                        //
                        //     </div>
                        //     <div className={`fullWidth promoCodeMain ${props.isPromoCodeApplied ?'greenBorder':''}`}>
                        //     {props.isPromoCodeApplied?
                        //         <div className="fullWidth referralSuccess positionRelative">
                        //                 <div className="doll_Icon">
                        //                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                        //                          xmlns="http://www.w3.org/2000/svg">
                        //                         <path
                        //                             d="M12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24ZM7.8 14.4V16.8H10.8V19.2H13.2V16.8H14.4C15.1956 16.8 15.9588 16.4844 16.5216 15.9216C17.0844 15.3588 17.4 14.5956 17.4 13.8C17.4 13.0044 17.0844 12.2412 16.5216 11.6784C15.9588 11.1156 15.1956 10.8 14.4 10.8H9.6C9.4404 10.8 9.288 10.7364 9.1752 10.6248C9.0636 10.512 9 10.3596 9 10.2C9 10.0404 9.0636 9.88801 9.1752 9.7752C9.288 9.6636 9.4404 9.6 9.6 9.6H16.2V7.2H13.2V4.8H10.8V7.2H9.6C8.8044 7.2 8.04119 7.5156 7.47839 8.0784C6.91559 8.6412 6.6 9.4044 6.6 10.2C6.6 10.9956 6.91559 11.7588 7.47839 12.3216C8.04119 12.8844 8.8044 13.2 9.6 13.2H14.4C14.5596 13.2 14.712 13.2636 14.8248 13.3752C14.9364 13.488 15 13.6404 15 13.8C15 13.9596 14.9364 14.112 14.8248 14.2248C14.712 14.3364 14.5596 14.4 14.4 14.4H7.8Z"
                        //                             fill="#00A91C"/>
                        //                     </svg>
                        //                 </div>
                        //                 <div className='referralText'>
                        //                     <span>{A12Y_CONST.TEXT.SPECIAL_CODE} {promoCode}</span>
                        //                     <span>{props.promocodeMessage} {props.promocodeInAmount?A12Y_CONST.TEXT.DOLLOR_SYMBOL:''}{props.couponAmount}{props.promocodeInAmount? '':A12Y_CONST.TEXT.percent_SYMBOL}</span>
                        //                 </div>
                        //             </div>
                        //             :
                        //         <div className="fullWidth">
                        //                 <div className="fullWidth promoCodeFormRow">
                        //                     <div className="fullWidth">
                        //                     <label
                        //                         className='textcapitalize promoCoceLabel'>{A12Y_CONST.LABEL.PROMO_CODE_LABEL}</label>
                        //                     <input
                        //                         {...register('promocode')}
                        //                         className={errors.promocode && props.promocodeError ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                        //                         type='text'
                        //                         id='promocode'
                        //                         onChange={(e) => handlePromoCode(e.target.value)}
                        //                         value={promoCode}
                        //                         placeholder='&nbsp;'
                        //                         maxLength="100"
                        //                         autoComplete="off"
                        //                         // onFocus={onFocusEventHandler}
                        //                         onBlur={() => {setIsPromocodeErrMessVisible(true)}}
                        //                         onFocus={() => {
                        //                             setError("promocode", {
                        //                                 type: "manual",
                        //                                 message: "",
                        //                             });
                        //                          setIsPromocodeErrMessVisible(false)
                        //                         }}
                        //                     />
                        //                     <input onClick={applyPromoCode}
                        //                            className={isPromoCodeBtnDisabel ? "saveButtonDisable promoCodeButton" : 'promoCodeButton saveButton'}
                        //                            disabled={isPromoCodeBtnDisabel} type="button"
                        //                            value={A12Y_CONST.BUTTON_TEXT.APPLY}/>
                        //                     </div>
                        //                 </div>
                        //             {props.promocodeError ?
                        //                 isPromocodeErrMessVisible ? <div className='fullWidth positionRelative'><p className='positionAbsoute errorMessagePromocode'>{props.promocodeMessage}</p></div>:null : null}
                        //             </div>
                        //         }
                        //     </div>
                        //     <div className="fullWidth">
                        //         <h3 className='generalInfoSubTitle fullWidth'>
                        //             {A12Y_CONST.TEXT.PLEASE_ENTER_CARD_DETAIL_MESSAGE}
                        //         </h3>
                        //         <div className='formRow fullWidth marginTop20'>
                        //             <label>CARD NUMBER</label>
                        //             <div className="fullWidth">
                        //                 <div className={`fullWidth`}>
                        //                     <CardNumberElement
                        //                         options={{
                        //                             iconStyle: "solid",
                        //                             showIcon: true,
                        //                             style: {base: window.outerWidth > 768 ? {fontWeight: 400, fontSize: '24px'} : {fontWeight: 400, fontSize: '16px'}},
                        //                         }}
                        //                         onChange={(e) => {
                        //                             getCardDetails(e)
                        //                         }}
                        //                         onFocus={() => {
                        //                             resetPromocodeError()
                        //                            // setIsCardNumberFocus(true)
                        //                         }}
                        //                         onBlur={() => {
                        //                            // setIsCardNumberFocus(false)
                        //                         }}
                        //                     />
                        //                 </div>
                        //                 {
                        //                     cardErrorMsg.length > 0 ?
                        //                         <div className='errorRelativeRow'><p
                        //                             className='errorMessage'>{cardErrorMsg}</p></div>
                        //                         : ''
                        //                 }
                        //             </div>
                        //         </div>
                        //
                        //         <div className="formRow fullWidth marginTop20">
                        //             <div className="width50 paddingRight16 positionRelative">
                        //                 <label>{A12Y_CONST.LABEL.EXPIRATION_DATE}</label>
                        //                 <div className="fullWidth ">
                        //                     <div className={`fullWidth`}>
                        //                         <CardExpiryElement
                        //                             onFocus={() => {
                        //                                // setIsCardExpiryElementFocus(true)
                        //                                 resetPromocodeError()
                        //                             }}
                        //                             onBlur={() => {
                        //                                 //setIsCardExpiryElementFocus(false)
                        //                             }}
                        //                             options={{style: {base: window.outerWidth > 768 ? {fontWeight: 400, fontSize: '22px'} : {fontWeight: 400, fontSize: '16px'}}}}
                        //                             onChange={(e) => {
                        //                                 getCardExpiry(e)
                        //                             }}/>
                        //                     </div>
                        //                     {
                        //                         expiryErrorMsg.length > 0 ?
                        //                             <div className='errorRelativeRow'><p
                        //                                 className='errorMessage'>{expiryErrorMsg}</p></div>
                        //                             : ''
                        //                     }
                        //                 </div>
                        //             </div>
                        //             <div className="width50 paddingleft16 positionRelative cvcWrap">
                        //                 <label className='minWidht190 paddingRight16'>{A12Y_CONST.LABEL.CVC}</label>
                        //                 <div className="fullWidth">
                        //                     <div className={`fullWidt`}>
                        //                         <CardCvcElement
                        //                             onFocus={() => {
                        //                                 //setIsCardCvcElementFocus(true)
                        //                                 resetPromocodeError()
                        //                             }}
                        //                             onBlur={() => {
                        //                                 //setIsCardCvcElementFocus(false)
                        //                             }}
                        //                             options={{style: {base: window.outerWidth > 768 ? {fontWeight: 400, fontSize: '22px'} : {fontWeight: 400, fontSize: '16px'}}}}
                        //                             onChange={(e) => {
                        //                                 getCvc(e)
                        //                             }}/>
                        //                     </div>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //         {/*{editMode ? '' :*/}
                        //         {/*    <div className="fullWidth marginTop30 payRow">*/}
                        //         {/*        <label className="chkBox maxWidth310 df aic">*/}
                        //         {/*            <input type='checkbox' className="checkboxCustom" checked={isTCChecked}*/}
                        //         {/*                   onClick={() => setIsTCChecked(!isTCChecked)}/>*/}
                        //         {/*            <span*/}
                        //         {/*                className="acceptSubscription paddingleft16">{A12Y_CONST.TEXT.I_ACCEPT_SUBSCRIPTION_T} &amp; {A12Y_CONST.LABEL.C}</span>*/}
                        //         {/*        </label>*/}
                        //         {/*    </div>*/}
                        //         {/*}*/}
                        //     </div>
                        //
                        //     <div className="fullWidth textAlignRight signUpFlowButtonRow marginBottom30 displayFlexCol">
                        //         <div className='widthAuto'>
                        //             <input className={'backButton'} type="button"
                        //                    onClick={cancelBilling} value={A12Y_CONST.BUTTON_TEXT.BACK}/>
                        //         </div>
                        //         <div className='widthAuto marginLeft20'>
                        //             <div className='loaderSpinner'>
                        //                 {loading ?
                        //                     <Loading/>
                        //                     :
                        //                     null
                        //                 }
                        //                 <input id='ActivateSubscription'
                        //                        className={isBtnDisabled || props?.promocodeError ? "saveButtonDisable buttonHeight56" : 'saveButton buttonHeight56'}
                        //                        disabled={isBtnDisabled} type="submit"
                        //                     // value={editMode ? A12Y_CONST.BUTTON_TEXT.UPDATE : A12Y_CONST.BUTTON_TEXT.FINISH}
                        //                        value={editMode ? A12Y_CONST.BUTTON_TEXT.ACTIVATE : A12Y_CONST.BUTTON_TEXT.ACTIVATE}
                        //                 />
                        //             </div>
                        //         </div>
                        //     </div>
                        // </form>
                        <>
                        </>
                        :
                        <form onSubmit={handleSubmit(onSubmit)} className={`${!props.isPromocodeVisible?'df billingForm fcol marginTop30':'df billingForm fcol'}`} autoComplete="off">
                            <div className="fullWidth">
                                <h3 className='paddingLeft35 myAccountTitle'>{A12Y_CONST.TEXT.BILLING}</h3>
                            </div>
                            {!props.isPromocodeVisible ?
                                    <div className="billingBox billingPaddingBottomNone fullWidth">
                                        {/*<div className='formRow fullWidth'>*/}
                                        {/*    <label>{A12Y_CONST.LABEL.PLAN_NAME}</label>*/}
                                        {/*    <div className="fullWidth borderBotom paddingBottom5">*/}
                                        {/*        <strong className="head24">{props?.userPlan?.plan_name}</strong>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className='formRow fullWidth marginTop30'>*/}
                                        {/*    <label>{A12Y_CONST.LABEL.PLAN_CHARGES}</label>*/}
                                        {/*    <div className="fullWidth borderBotom paddingBottom5">*/}
                                        {/*        <strong className="head24">${props?.userPlan?.plan_price} / {props?.userPlan?.billing_period}</strong>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className='formRow fullWidth '>
                                            <label>{A12Y_CONST.LABEL.PLAN_DETAILS}</label>
                                            <div className='priceListedIs maxWidth300 texAlingCener paddingLeftNone'>{A12Y_CONST.LABEL.ESSENTIALS_PLAN}</div>
                                            <div className="fullWidth billingAmountNew">
                                                <strong
                                                    className="head24">{props?.subscriptionProduct && props?.subscriptionProduct[0]?.plans[0]?.billing_period} ${props.isPromoCodeApplied ? props.discountAmt : props?.subscriptionProduct && props?.subscriptionProduct[0]?.plans[0]?.plan_price}</strong>

                                            </div>
                                            <div className='fullWidth priceUsCurrencyText marginTop10 paddingLeft10'>
                                                Prices are mentioned in US currency and do not include applicable taxes
                                            </div>
                                            <div className='fullWidth marginTop25'>
                                                <span className="anyQuestion">Any questions? Check out our <span><a
                                                    id={A12Y_CONST.BUTTON_ID.FAQ_TEXT}
                                                    href={A12Y_CONST.TEXT.ADVOCORT_PRICING_URL} rel="noreferrer"
                                                    target='_blank'>FAQ’s here</a></span>.</span>
                                            </div>

                                        </div>
                                    </div>
                                :
                                    null
                            }
                            <div className="fullWidth billingBox">
                                {!props.isPromocodeVisible?
                                <>
                                    <h3 className='generalInfoSubTitle fullWidth'>
                                        {A12Y_CONST.TEXT.PLEASE_ENTER_CARD_DETAIL_MESSAGE}
                                    </h3>
                                    <div className='formRow fullWidth marginTop30'>
                                        <label>CARD NUMBER</label>
                                        <div className="fullWidth">
                                            <div className={`fullWidth`}>
                                                <CardNumberElement
                                                    options={{
                                                        iconStyle: "solid",
                                                        showIcon: true,
                                                        style: {base:window.outerWidth>768? {fontWeight: 400, fontSize: '24px'} : {fontWeight: 400, fontSize: '16px',}},
                                                    }}
                                                    onChange={(e) => {
                                                        getCardDetails(e)
                                                    }}
                                                   /* onFocus={() => {
                                                        setIsCardNumberFocus(true)
                                                    }}*/
                                                    /*onBlur={() => {
                                                        setIsCardNumberFocus(false)
                                                    }}*/
                                                />
                                            </div>
                                            {
                                                cardErrorMsg.length > 0 ?
                                                    <div className='errorRelativeRow'><p className='errorMessage'>{cardErrorMsg}</p></div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="formRow fullWidth marginTop30 payRow">
                                    <div className="width50 paddingRight16 positionRelative">
                                        <label>EXPIRES</label>
                                        <div className="fullWidth ">
                                            <div className={`fullWidth`}>
                                                <CardExpiryElement
                                                   /* onFocus={() => {
                                                        setIsCardExpiryElementFocus(true)
                                                    }}
                                                    onBlur={() => {
                                                        setIsCardExpiryElementFocus(false)
                                                    }}*/
                                                    options={{style: {base:window.outerWidth>768? {fontWeight: 400, fontSize: '22px'} : {fontWeight: 400, fontSize: '16px'}}}} onChange={(e) => {
                                                    getCardExpiry(e)
                                                }}/>
                                            </div>
                                            {
                                                expiryErrorMsg.length > 0 ?
                                                    <div className='errorRelativeRow'><p className='errorMessage'>{expiryErrorMsg}</p></div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="width50 paddingleft16 positionRelative paidTo">
                                        <label className='minWidht190 textAlignRight paddingRight16'>CVC</label>
                                        <div className="fullWidth">
                                            <div className={`fullWidt`}>
                                                <CardCvcElement
                                                    /*onFocus={() => {
                                                        setIsCardCvcElementFocus(true)
                                                    }}*/
                                                    /*onBlur={() => {
                                                        setIsCardCvcElementFocus(false)
                                                    }}*/
                                                    options={{style: {base:window.outerWidth>768?{fontWeight: 400, fontSize: '22px'}: {fontWeight: 400, fontSize: '16px'}}}} onChange={(e) => {
                                                    getCvc(e)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                                    :
                                null
                                }
                                {props.isPromocodeVisible ?
                                <>
                                <div className={`${!props.isPromocodeVisible?'formRow fullWidth marginTop50 promoCodeBoxBilling payRow':'formRow fullWidth promoCodeBoxBilling payRow'}`}>
                                        <div className={`${!props.isPromocodeVisible?'fullWidth width50 promoCode':'fullWidth width50 maxWidth600 promoCode'}`}>
                                            <label>{A12Y_CONST.LABEL.PROMO_CODE}</label>
                                            <div className="fullWidth marginBottom20">
                                                <div className="fullWidth promoCodeFormRow">
                                                    <div className='fullWidth'>
                                                        <input
                                                            {...register('promocode')}
                                                            className={errors.promocode && props.promocodeError ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                                            type='text'
                                                            id='promocode'
                                                            onChange={(e) => handlePromoCode(e.target.value)}
                                                            value={promoCode}
                                                            placeholder='&nbsp;'
                                                            maxLength="100"
                                                            autoComplete="off"
                                                            // onFocus={onFocusEventHandler}
                                                            onBlur={() => {
                                                                setIsPromocodeErrMessVisible(true)
                                                            }}
                                                            onFocus={() => {
                                                                setError("promocode", {
                                                                    type: "manual",
                                                                    message: "",
                                                                });
                                                                setIsPromocodeErrMessVisible(false)
                                                            }}
                                                        />
                                                        <input id={A12Y_CONST.BUTTON_ID.PROMOCODE_APPLY_BUTTON} onClick={applyPromoCode}
                                                               className={isPromoCodeBtnDisabel ? "saveButtonDisable promoCodeButton" : 'promoCodeButton saveButton'}
                                                               disabled={isPromoCodeBtnDisabel} type="button"
                                                               value={A12Y_CONST.BUTTON_TEXT.APPLY}/>
                                                    </div>
                                                </div>
                                                {props.promocodeError ?
                                                    isPromocodeErrMessVisible ?
                                                        <div className='fullWidth positionRelative'><p
                                                            className='positionAbsoute errorMessagePromocode'>{props.promocodeMessage}</p>
                                                        </div> : null : null}

                                            </div>

                                        </div>
                                    </div>

                                    <div className='promoInfo fullWidth'>{A12Y_CONST.LABEL.PLEASE_NOTE_THIS_PROMO}</div>


                                    {props.isPromoCodeApplied ?
                                        <div
                                            className={`fullWidth promoCodeMain onlyPromoScreen marginTop20 ${props.isPromoCodeApplied ? 'greenBorder' : ''}`}>
                                            <div className="fullWidth positionRelative">
                                                <div className='referralText'>
                                                    <span>{A12Y_CONST.TEXT.SPECIAL_CODE} {promoCode}</span>
                                                    <span>{props.promocodeMessage} {props.promocodeInAmount ? A12Y_CONST.TEXT.DOLLOR_SYMBOL : ''}{props.couponAmount}{props.promocodeInAmount ? '' : A12Y_CONST.TEXT.percent_SYMBOL} {A12Y_CONST.LABEL.A_MONTH}</span>
                                                </div>
                                            </div>


                                        </div>
                                        :
                                        null
                                    }
                                </>
                                    :
                                <>
                                {(props.userSubscriptionAllDetails?.is_trial_expired || props.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED || props.userSubscriptionAllDetails?.trial_period) ?
                                    <div className='formRow fullWidth marginTop50 promoCodeBoxBilling payRow'>
                                        <div className='fullWidth width50 promoCode'>
                                            <label>{A12Y_CONST.LABEL.PROMO_CODE}</label>
                                            <div className="fullWidth">
                                                <div className="fullWidth promoCodeFormRow marginBottom10">
                                                    <div className='fullWidth'>
                                                        <input
                                                            {...register('promocode')}
                                                            className={errors.promocode && props.promocodeError ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                                            type='text'
                                                            id='promocode'
                                                            onChange={(e) => handlePromoCode(e.target.value)}
                                                            value={promoCode}
                                                            placeholder='&nbsp;'
                                                            maxLength="100"
                                                            autoComplete="off"
                                                            // onFocus={onFocusEventHandler}
                                                            onBlur={() => {
                                                                setIsPromocodeErrMessVisible(true)
                                                            }}
                                                            onFocus={() => {
                                                                setError("promocode", {
                                                                    type: "manual",
                                                                    message: "",
                                                                });
                                                                setIsPromocodeErrMessVisible(false)
                                                            }}
                                                        />
                                                        <input id={A12Y_CONST.BUTTON_ID.PROMOCODE2_APPLY_BUTTON} onClick={applyPromoCode}
                                                               className={isPromoCodeBtnDisabel ? "saveButtonDisable promoCodeButton" : 'promoCodeButton saveButton'}
                                                               disabled={isPromoCodeBtnDisabel} type="button"
                                                               value={A12Y_CONST.BUTTON_TEXT.APPLY}/>
                                                    </div>
                                                </div>

                                            </div>
                                            {props.promocodeError ?
                                                isPromocodeErrMessVisible ?
                                                    <div className='fullWidth positionRelative'><p
                                                        className='positionAbsoute errorMessagePromocode'>{props.promocodeMessage}</p>
                                                    </div> : null : null}

                                            {props.isPromoCodeApplied ?
                                                <div
                                                    className={`fullWidth promoCodeMain ${props.isPromoCodeApplied ? 'greenBorder' : ''}`}>
                                                    <div className="fullWidth referralSuccess positionRelative">
                                                        <div className="doll_Icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24ZM7.8 14.4V16.8H10.8V19.2H13.2V16.8H14.4C15.1956 16.8 15.9588 16.4844 16.5216 15.9216C17.0844 15.3588 17.4 14.5956 17.4 13.8C17.4 13.0044 17.0844 12.2412 16.5216 11.6784C15.9588 11.1156 15.1956 10.8 14.4 10.8H9.6C9.4404 10.8 9.288 10.7364 9.1752 10.6248C9.0636 10.512 9 10.3596 9 10.2C9 10.0404 9.0636 9.88801 9.1752 9.7752C9.288 9.6636 9.4404 9.6 9.6 9.6H16.2V7.2H13.2V4.8H10.8V7.2H9.6C8.8044 7.2 8.04119 7.5156 7.47839 8.0784C6.91559 8.6412 6.6 9.4044 6.6 10.2C6.6 10.9956 6.91559 11.7588 7.47839 12.3216C8.04119 12.8844 8.8044 13.2 9.6 13.2H14.4C14.5596 13.2 14.712 13.2636 14.8248 13.3752C14.9364 13.488 15 13.6404 15 13.8C15 13.9596 14.9364 14.112 14.8248 14.2248C14.712 14.3364 14.5596 14.4 14.4 14.4H7.8Z"
                                                                    fill="#00A91C"/>
                                                            </svg>
                                                        </div>
                                                        <div className='referralText'>
                                                            <span>{A12Y_CONST.TEXT.SPECIAL_CODE} {promoCode}</span>
                                                            <span>{props.promocodeMessage} {props.promocodeInAmount ? A12Y_CONST.TEXT.DOLLOR_SYMBOL : ''}{props.couponAmount}{props.promocodeInAmount ? '' : A12Y_CONST.TEXT.percent_SYMBOL}</span>
                                                        </div>
                                                    </div>


                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                </>

                                }
                                {/*{editMode ? '' :*/}
                                {/*    <div className="fullWidth marginTop30 payRow">*/}
                                {/*        <label className="chkBox maxWidth310 df aic">*/}
                                {/*            <input type='checkbox' className="checkboxCustom" checked={isTCChecked}*/}
                                {/*                   onClick={() => setIsTCChecked(!isTCChecked)}/>*/}
                                {/*            <span*/}
                                {/*                className="acceptSubscription paddingleft16">{A12Y_CONST.TEXT.I_ACCEPT_SUBSCRIPTION_T} &amp; {A12Y_CONST.LABEL.C}</span>*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*}*/}
                            </div>

                            <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol billingBox paddingTop positionAbsouteOnly bottom bottom_50_600 right">
                                {props.isPromocodeVisible ?
                                    <div className='widthAuto marginButton buttonRightMargin'>
                                        <div className='loaderSpinner'>
                                           <input
                                                  onClick={myAccountViewSection}
                                                   className='saveButton maxWidth138'
                                                   value={A12Y_CONST.BUTTON_TEXT.DONE}/>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className='widthAuto'>
                                            <input id={A12Y_CONST.BUTTON_ID.BILLING_BACK_BUTTON} className={'cancelButton1'} type="button"
                                                   onClick={cancelBilling} value={A12Y_CONST.BUTTON_TEXT.BACK}/>
                                        </div>
                                        <div className='widthAuto marginButton buttonRightMargin'>
                                            <div className='loaderSpinner'>
                                                {loading ?
                                                    <Loading/>
                                                    :
                                                    null
                                                }
                                                <input id={editMode? A12Y_CONST.BUTTON_ID.BILLING_FINISH_BUTTON : A12Y_CONST.BUTTON_ID.BILLING_FINISH_BUTTON}
                                                       className={isBtnDisabled ? "saveButtonDisable" : 'saveButton'}
                                                       disabled={isBtnDisabled} type="submit"
                                                       value={editMode ? A12Y_CONST.BUTTON_TEXT.FINISH : A12Y_CONST.BUTTON_TEXT.FINISH}/>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </form>

                }
                </div>
            {showPopup && !props.onFlow?
             <div className='popTop'>
                    <div className='popContainer popMaxWidth'>
                        <div className='popMaster'>
                            <div className="popUpMainBg fullWidth">
                                <div className="cancelIcon fullWidth marginTopBottom20">
                                    <div className="successIcon">
                                        <svg width="92" height="96" viewBox="0 0 92 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25 48.5144L39.25 62.3666L63 35.2644" stroke="url(#paint0_linear_7911_52433)" strokeWidth="5" strokeLinecap="round"/>
                                            <path d="M25 48.5144L39.25 62.3666L63 35.2644" stroke="url(#paint1_linear_7911_52433)" strokeOpacity="0.2" strokeWidth="5" strokeLinecap="round"/>
                                            <path d="M84.5 47.7731C84.5 70.0948 67.1661 88.0112 46 88.0112C24.8339 88.0112 7.5 70.0948 7.5 47.7731C7.5 25.4513 24.8339 7.53491 46 7.53491C67.1661 7.53491 84.5 25.4513 84.5 47.7731Z" stroke="url(#paint2_linear_7911_52433)" strokeWidth="5"/>
                                            <path d="M25 48.5144L39.25 62.3666L63 35.2644" stroke="url(#paint3_linear_7911_52433)" strokeOpacity="0.18" strokeWidth="5" strokeLinecap="round"/>
                                            <path d="M25 48.5144L39.25 62.3666L63 35.2644" stroke="url(#paint4_linear_7911_52433)" strokeOpacity="0.2" strokeWidth="5" strokeLinecap="round"/>
                                            <path d="M46 93.0112C70.1212 93.0112 89.5 72.6586 89.5 47.7731C89.5 22.8876 70.1212 2.53491 46 2.53491C21.8788 2.53491 2.5 22.8876 2.5 47.7731C2.5 72.6586 21.8788 93.0112 46 93.0112Z" stroke="url(#paint5_linear_7911_52433)" strokeOpacity="0.18" strokeWidth="5"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_7911_52433" x1="25" y1="35.2644" x2="52.5046" y2="70.5481" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_7911_52433" x1="30.4522" y1="38.9602" x2="49.9089" y2="68.1243" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint2_linear_7911_52433" x1="5" y1="5.03491" x2="93.6945" y2="82.8841" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint3_linear_7911_52433" x1="25" y1="35.2644" x2="52.5046" y2="70.5481" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint4_linear_7911_52433" x1="30.4522" y1="38.9602" x2="49.9089" y2="68.1243" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint5_linear_7911_52433" x1="5" y1="5.03491" x2="93.6945" y2="82.8841" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                                <div className="areYouText planPurchaseTitle">
                                    {props.planStatus === A12Y_CONST.TEXT.ACTIVE_UPPER || props.planStatus === A12Y_CONST.LABEL.CANCELLED || props.planStatus === A12Y_CONST.TEXT.FAILED_PAYMENT  ? A12Y_CONST.LABEL.SUCCESSFULLY_UPDATE : A12Y_CONST.LABEL.SUCCESSFULLY_PURCHASED}
                                </div>
                                {props.planStatus === A12Y_CONST.TEXT.ACTIVE_UPPER || props.planStatus === A12Y_CONST.LABEL.CANCELLED || props.planStatus === A12Y_CONST.TEXT.FAILED_PAYMENT ?
                                    <div className={'maxWidth360 useInfo marginTop10'}>
                                        {A12Y_CONST.LABEL.YOUR_CREDIT_CARD_INFORMATION}
                                    </div>
                                    :
                                    <div className={props.maxWidth === 'maxWidth' ? 'popUpMessageWidth useInfo marginTop10' : 'useInfo marginTop10 maxWidth360'}>
                                        <div className="fullWidth texAlingLeft positionRelative paddingLeft35 marginTop5">
                                           <span className='popUpTick positionAbsoute'>
                                                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.627 4.27063C24.0859 4.66374 24.127 5.34098 23.7192 5.78327L11.8677 18.6404C11.6566 18.8692 11.3544 19 11.0366 19C10.7199 19 10.4177 18.8692 10.2065 18.6404L4.28082 12.2118C3.87303 11.7696 3.91414 11.0923 4.37304 10.6992C4.83083 10.3061 5.53418 10.346 5.94086 10.7881L11.0366 16.3159L22.0591 4.35956C22.4669 3.91738 23.1692 3.87752 23.627 4.27063Z" fill="#00A91C"/>
                                                </svg>
                                           </span>
                                            {A12Y_CONST.LABEL.YOUR_PLAN_IS} ${props?.subscriptionProduct[0]?.plans[0]?.plan_price} {A12Y_CONST.LABEL.PAID_MONTHLY}
                                        </div>
                                        {/*<div className="fullWidth texAlingLeft positionRelative paddingLeft35 marginTop5">*/}
                                        {/*   <span className='popUpTick positionAbsoute'>*/}
                                        {/*        <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*            <path d="M23.627 4.27063C24.0859 4.66374 24.127 5.34098 23.7192 5.78327L11.8677 18.6404C11.6566 18.8692 11.3544 19 11.0366 19C10.7199 19 10.4177 18.8692 10.2065 18.6404L4.28082 12.2118C3.87303 11.7696 3.91414 11.0923 4.37304 10.6992C4.83083 10.3061 5.53418 10.346 5.94086 10.7881L11.0366 16.3159L22.0591 4.35956C22.4669 3.91738 23.1692 3.87752 23.627 4.27063Z" fill="#00A91C"/>*/}
                                        {/*        </svg>*/}
                                        {/*   </span>*/}
                                        {/*    {props?.dependent?.subscirption?.plan_name? props?.dependent?.subscirption?.plan_name: '-'}*/}
                                        {/*</div>*/}
                                        <div className="fullWidth texAlingLeft positionRelative paddingLeft35 marginTop5">
                                            <span className='popUpTick positionAbsoute'>
                                                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.627 4.27063C24.0859 4.66374 24.127 5.34098 23.7192 5.78327L11.8677 18.6404C11.6566 18.8692 11.3544 19 11.0366 19C10.7199 19 10.4177 18.8692 10.2065 18.6404L4.28082 12.2118C3.87303 11.7696 3.91414 11.0923 4.37304 10.6992C4.83083 10.3061 5.53418 10.346 5.94086 10.7881L11.0366 16.3159L22.0591 4.35956C22.4669 3.91738 23.1692 3.87752 23.627 4.27063Z" fill="#00A91C"/>
                                                </svg>
                                           </span>
                                            {A12Y_CONST.TEXT.YOU_WILL_BE_CHARGED_MESSAGE}
                                        </div>
                                        {props.userSubscriptionAllDetails?.price_details?.current_amount_off ||  props.userSubscriptionAllDetails?.price_details?.current_percent_off ?
                                            <div className="fullWidth texAlingLeft positionRelative paddingLeft35 marginTop5">
                                            <span className='popUpTick positionAbsoute'>
                                                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.627 4.27063C24.0859 4.66374 24.127 5.34098 23.7192 5.78327L11.8677 18.6404C11.6566 18.8692 11.3544 19 11.0366 19C10.7199 19 10.4177 18.8692 10.2065 18.6404L4.28082 12.2118C3.87303 11.7696 3.91414 11.0923 4.37304 10.6992C4.83083 10.3061 5.53418 10.346 5.94086 10.7881L11.0366 16.3159L22.0591 4.35956C22.4669 3.91738 23.1692 3.87752 23.627 4.27063Z" fill="#00A91C"/>
                                                </svg>
                                           </span>
                                                {props.userSubscriptionAllDetails?.price_details?.current_amount_off !== null ? A12Y_CONST.TEXT.YOUR_PROMO_CODE_OF + ' $' + props.userSubscriptionAllDetails?.price_details?.current_amount_off/100+' ' + A12Y_CONST.TEXT.OFF_HAS_BEEN_APPLIED : props.userSubscriptionAllDetails?.price_details?.current_percent_off? A12Y_CONST.TEXT.YOUR_PROMO_CODE_OF + ' ' +props.userSubscriptionAllDetails?.price_details?.current_percent_off+'% ' + A12Y_CONST.TEXT.OFF_HAS_BEEN_APPLIED:''}
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="fullWidth texAlingLeft positionRelative paddingLeft35 marginTop5">
                                            <span className='popUpTick positionAbsoute'>
                                                <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.627 4.27063C24.0859 4.66374 24.127 5.34098 23.7192 5.78327L11.8677 18.6404C11.6566 18.8692 11.3544 19 11.0366 19C10.7199 19 10.4177 18.8692 10.2065 18.6404L4.28082 12.2118C3.87303 11.7696 3.91414 11.0923 4.37304 10.6992C4.83083 10.3061 5.53418 10.346 5.94086 10.7881L11.0366 16.3159L22.0591 4.35956C22.4669 3.91738 23.1692 3.87752 23.627 4.27063Z" fill="#00A91C"/>
                                                </svg>
                                           </span>
                                            {A12Y_CONST.TEXT.YOUR_NEXT_RENEWAL}  {props?.userSubscriptionAllDetails?.subscription_details?.renewal_date ? moment(props?.userSubscriptionAllDetails?.subscription_details?.renewal_date).format("MM-DD-YYYY"):null}
                                        </div>
                                    </div>
                                }
                                <div className="fullWidth marginTop45 marginBottom20 textAlignRight">
                                    {/*<div className=" grayButtonColorBorder" onClick={hidePopUp}>{props.buttonCancelTxt}</div>*/}
                                    <div id={A12Y_CONST.BUTTON_ID.SUbSCRIBE} className="popUpNoButton marginLeftRight2percent" onClick={onContinue} >{A12Y_CONST.BUTTON_TEXT.OKAY}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {showPopup && props.onFlow?
                <div className='popTop'>
                    <div className='popContainer popMaxWidth'>
                        <div className='popMaster'>
                            <div className="popUpMainBg texAlingCener fullWidth">
                                <div className="cancelIcon fullWidth marginTopBottom20">
                                    <div className="successIcon">
                                        <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25 46.7111L39.25 60L63 34" stroke="url(#paint0_linear_9983_64252)" strokeWidth="5" strokeLinecap="round"/>
                                            <path d="M25 46.7111L39.25 60L63 34" stroke="url(#paint1_linear_9983_64252)" stroke-opacity="0.2" strokeWidth="5" strokeLinecap="round"/>
                                            <circle cx="46" cy="46" r="38.5" stroke="url(#paint2_linear_9983_64252)" strokeWidth="5"/>
                                            <path d="M25 46.7111L39.25 60L63 34" stroke="url(#paint3_linear_9983_64252)" stroke-opacity="0.18" strokeWidth="5" strokeLinecap="round"/>
                                            <path d="M25 46.7111L39.25 60L63 34" stroke="url(#paint4_linear_9983_64252)" stroke-opacity="0.2" strokeWidth="5" strokeLinecap="round"/>
                                            <circle cx="46" cy="46" r="43.5" stroke="url(#paint5_linear_9983_64252)" stroke-opacity="0.18" strokeWidth="5"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_9983_64252" x1="25" y1="34" x2="51.099" y2="68.8999" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_9983_64252" x1="30.4522" y1="37.5455" x2="48.8091" y2="66.2274" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint2_linear_9983_64252" x1="5" y1="5" x2="90.474" y2="83.203" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint3_linear_9983_64252" x1="25" y1="34" x2="51.099" y2="68.8999" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint4_linear_9983_64252" x1="30.4522" y1="37.5455" x2="48.8091" y2="66.2274" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                                <linearGradient id="paint5_linear_9983_64252" x1="5" y1="5" x2="90.474" y2="83.203" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#34A0A4"/>
                                                    <stop offset="1" stopColor="#85C894"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                                <div className="sucessPopUpTitle">
                                    {A12Y_CONST.LABEL.WELCOME_TO_ADVOCORD}
                                </div>
                                <div className='yourSubscribe fullWidth marginTop20 marginBottom20'>{A12Y_CONST.LABEL.YOUR_SUBSCRIPTION_HAS_BEEN}</div>
                                <div className="fullWidth marginTop45 marginBottom20 textAlignRight">
                                    {/*<div className=" grayButtonColorBorder" onClick={hidePopUp}>{props.buttonCancelTxt}</div>*/}
                                    <div className="popUpNoButton marginLeftRight2percent" onClick={onContinue} >{A12Y_CONST.BUTTON_TEXT.GET_STARTED}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }

			{/*<PopUpComponent*/}
            {/*    popUpStatus={isPopUp}*/}
            {/*    title={A12Y_CONST.TEXT.ARE_YOU_SURE_YOU_WANT_TO_UNSUBSCRIBE}*/}
            {/*    message={subscriptionMsg}*/}
            {/*    buttonDeleteTxt={A12Y_CONST.BUTTON_TEXT.CANCEL_PLAN}*/}
            {/*    buttonCancelTxt={A12Y_CONST.BUTTON_TEXT.KEEP_PLAN}*/}
            {/*    hidePopUp={hidePopup}*/}
            {/*    handleDeleteButton={handleUnSubscribe}*/}
            {/*    popUpType={popUpType}*/}
            {/*/>*/}
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    );
};

export default BillingComponent;