import React, { Component } from 'react'
import ActivityList from '../components/ActivityListComponent'
import { connect } from 'react-redux';
import {secureClient} from "../config/axiosClient";
import  * as dependentActions from '../redux/actions/dependentActions'
import A12Y_CONST from '../common/a12yConst'
import moment from 'moment';
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import WelcomeComponent from '../components/WelcomeComponent'
import * as userDetailActions from "../redux/actions/userDetailActions";
import {ddmmyyyyFormat} from "../util/date_format";

class ActivityScreen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentScreen :0,
            loading:false,
            error:false,
            message:'',
            errorMessage:'',
            isActivitysAvailable: false,
            activityList : [],
            loaderFullScreenVisible : false,
            btnTitle: this.props.dependent?.metadata?.activity_present && this.props.activity_present === false ? A12Y_CONST.BUTTON_TEXT.BACK_TO_ACTIVITY : A12Y_CONST.BUTTON_TEXT.ADD_AN_ACTIVITY
           // loaderFullScreenVisible : false,
        }
    }

    componentDidMount = async() => {
        let state = { ...this.props.location.state };
        if(state?.ladingPageVisible){
            // await this.goToActivityLandingPageHandler()
            if(this.props?.dependent?.metadata?.activity_present){
                await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_ACTIVITY})
            }else{
                await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.ADD_AN_ACTIVITY})
            }
            await this.props.updateMetaData(this.props.income_present,this.props.expense_present,false,this.props.asset_present)
        }
        if(state?.ladingPageVisible){
            state.ladingPageVisible = false;
        }
       // await this.props.currentActivityData({})
       // await this.props.updateSessionTimeout(false)
        this.setState({loading:true})
        await this.props.dataChanged(false)
        if(this.props.activityTypeList.length === 0 || this.props.activityTypeList[0] === undefined){
           await this.getActivityTyp();
            console.log('activty---bottom', this.props.activityTypeList)
        }
        //await this.getMetaData();
        if(this.props.activity_present){
            let types =[]
            let tags = []
            if(this.props.filterData['activity'].type.length > 0){
                types = this.props.filterData['activity'].type;
            }
            if(this.props.filterData['activity'].tag.length > 0){
                tags = this.props.filterData['activity'].tag;
            }
            await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date,types,tags)
        }else {
            this.setState({loaderFullScreenVisible:false,loading:false})
        }
    }

    getActivityTyp = async () => {
      //  this.setState({ loaderFullScreen: true })
        await this.props.getActivityTypeList(this.props.dependent.id);
        if(this.props.isError){
            this.setState({error:true,errorMessage:this.props.errMsg,loaderFullScreen: false})
            console.log('getActivityTyp if---', this.props.activityTypeList)
        }else {
            this.setState({message:this.props.successMessage,loaderFullScreen: false})
            console.log('getActivityTyp else---', this.props.activityTypeList)
        }
    }

   /* getMetaData = async() =>{
        let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENT+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.METADATA);
        this.setState({loaderFullScreenVisible:false})
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            this.setState({isActivitysAvailable:response.data.sr.activity_present})
            if(response.data.sr.activity_present){
                await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date)
            }else {
                this.setState({loaderFullScreenVisible:false})
            }
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
        }
        await this.hideMessage();
    }*/

    onSearchHandler = async(date1,date2,typeList,tagList)=>{
        try {
            let startDate = await ddmmyyyyFormat(date1);
            let endDate = await ddmmyyyyFormat(date2);
            this.setState({loading:true,errorMessage:'',error:false})
            let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ACTIVITY_BY_DATE_RANGE,{"start_date": startDate,"end_date": endDate,"activity_type_ids":typeList,"tags":tagList,});
            // this.setState({loading:false})
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {

                for(let i = 0; i < response.data.sr.activity_list.length; i++) {
                    let one_activity = response.data.sr.activity_list[i];
                    one_activity.expense_ids = [];
                    if(one_activity.expenses && one_activity.expenses.length > 0) {
                        for(let j = 0; j < one_activity.expenses.length; j++) {
                            one_activity.expense_ids.push(one_activity.expenses[j].id);
                        }
                    }
                }
                this.setState({activityList:response.data.sr.activity_list})
                // if((Object.keys(this.props.dependent).length > 0 && this.props.dependent.reporting_period.start_date !== startDate) || this.props.dependent.reporting_period.end_date !== endDate){
                //
                // }

                await this.props.setUserPreferences(startDate,endDate,this.props.dependent,this.props.dependentList,typeList,tagList,this.props.dependent.reporting_period.expense_type_ids,this.props.dependent.reporting_period.income_type_ids)
                this.setState({loaderFullScreenVisible:false,loading:false});
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loaderFullScreenVisible:false,loading:false})
            }
            await this.hideMessage();
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loaderFullScreenVisible:false,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.SEARCH_ACTIVITY_LIST,e)
        }
    }

    onDeleteHandler = async(activity)=>{
        try{
            this.setState({loading:true,errorMessage:'',error:false})
            let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ACTIVITIES+"/"+activity.id);
            this.setState({loading:false})
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                let dataList = await this.state.activityList.filter(item => item.id !== activity.id)
                this.setState({activityList:dataList})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false})
            }
            await this.hideMessage();
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loaderFullScreenVisible:false,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DELETE_ACTIVITY,e)
        }

    }

    hideMessage = async ()=>{
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 1000);
    }

    dateFormat = async(date)=>{
      return  await moment(date).format("MM/DD/YYYY")
    }

    onEditHandler = async(activity) =>{
        if(activity.expenses && activity.expenses.length > 0){
            activity.expense_ids = [];
            activity.expenses.forEach((item,key)=>{
                activity.expense_ids.push(item.id)
            })
        }else {
            activity.expense_ids = [];
        }
        await this.props.currentActivityData(activity)
        localStorage.setItem("activity", JSON.stringify(activity));
        await this.props.isCommingFromActivityList(A12Y_CONST.ROUTE.ACTIVITIES);
        //this.props.history.push(A12Y_CONST.ROUTE.ADD_ACTIVITY);
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_ACTIVITY,
            state: {fromActivityList:true }
        });
    }

    onCreateHandler = async() =>{
        await this.props.currentActivityData({})
        await this.props.isCommingFromActivityList(A12Y_CONST.ROUTE.ACTIVITIES);
        // this.props.history.push(A12Y_CONST.ROUTE.ADD_ACTIVITY);
        this.props.history.push({
            pathname: A12Y_CONST.ROUTE.ADD_ACTIVITY,
            state: {fromActivityList:true }
        });
    }

    onViewHandler = async(activity) =>{
        localStorage.setItem("activity", JSON.stringify(activity));
        await this.props.currentActivityData(activity)
        await this.props.isCommingFromActivityList(A12Y_CONST.ROUTE.ACTIVITY_VIEW);
        this.props.history.push(A12Y_CONST.ROUTE.ACTIVITY_VIEW);
    }

    goToActivityLandingPageHandler = async ()=>{
       await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_ACTIVITY})
       await this.props.updateMetaData(this.props.income_present,this.props.expense_present,!this.props.activity_present,this.props.asset_present)
    }

    landingPageBtnEvent = async ()=>{
        if(this.props.dependent?.metadata?.activity_present){
            this.props.updateMetaData(this.props.income_present,this.props.expense_present,!this.props.activity_present,this.props.asset_present)
            // await this.onSearchHandler(this.props.dependent?.reporting_period?.start_date,this.props.dependent?.reporting_period?.end_date)
            let types =[]
            let tags = []
            if(this.props.filterData['activity'].type.length > 0){
                types = this.props.filterData['activity'].type;
            }
            if(this.props.filterData['activity'].tag.length > 0){
                tags = this.props.filterData['activity'].tag;
            }
            await this.onSearchHandler(this.props.dependent.reporting_period.start_date,this.props.dependent.reporting_period.end_date,types,tags)
        }else {
            await this.props.currentActivityData({})
            await this.props.isCommingFromActivityList(A12Y_CONST.ROUTE.ACTIVITIES);
            this.props.history.push(A12Y_CONST.ROUTE.ADD_ACTIVITY);
        }
    }

    // updateSelectedFilterTypeList = async (data) => {
    //     var filter = this.props.filterData
    //     filter['activity'].type = data
    //     filter['activity'].isModify = true
    //     await this.props.setSeletedFilter(filter)
    //    // await this.props.selectedFilterTypeList(data)
    //    // await this.props.setSeletedFilter(data)
    // }
    setfilter = async (date1, date2,typeList,tagList,modify) => {
        var filter = this.props.filterData
        filter['activity'].type = typeList
        filter['activity'].tag = tagList
        filter['activity'].isModify = modify
        await this.props.setSeletedFilter(filter)
        this.onSearchHandler(date1, date2,typeList,tagList)
    }

    render() {
        const {activityList} = this.state
        return (
            <div className="fullWidth">
                        {this.state.loaderFullScreenVisible ?
                            <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare">
                                <div className='rightPanelWidhtSidebar'>
                                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                                        <LoaderFullScreen/>
                                    </div>
                                </div>
                            </div>
                            :
                            // (isActivitysAvailable ?
                            (this.props.activity_present ?
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare">
                                        <div className='rightPanelWidhtSidebar'>
                                            <div className="rightPanelWidhtSidebarContainer fullWidth">
                                            <ActivityList
                                                activityList={activityList}
                                                message={this.state.message}
                                                error={this.state.error}
                                                userInfo={this.props.userState.userInfo}
                                                errorMessage={this.state.errorMessage}
                                                onSearch={this.onSearchHandler}
                                                onDelete={this.onDeleteHandler}
                                                activityTypeList={this.props.activityTypeList}
                                                onEdit={this.onEditHandler}
                                                onCreate={this.onCreateHandler}
                                                loading={this.state.loading}
                                                dependent={this.props.dependent}
                                                onView={this.onViewHandler}
                                                goToActivityLandingPage={this.goToActivityLandingPageHandler}
                                                updateSelectedFilterTypeList={this.updateSelectedFilterTypeList}
                                                setfilter={this.setfilter}
                                                filterSelectedTypeList={this.props.filterSelectedTypeList}
                                                filterData={this.props.filterData}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare welcomeScreen">
                                        <div className='rightPanelWidhtSidebar bgWhite fullWidth marginTop12'>
                                            <div className="rightPanelWidhtSidebarContainer welcomeBg fullWidth marginNone">
                                            <WelcomeComponent
                                                screenName={A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL}
                                                title={A12Y_CONST.TEXT.TRACK_ALL_ACTIVITIES}
                                                description1={A12Y_CONST.TEXT.ACTIVITIES_CAN_INCLUDE}
                                                description2={A12Y_CONST.TEXT.DEPENDING_ON_YOUR_SITUATION.replace('#dependentName#', this.props.dependent.nick_name)}
                                                descriptionTitle={A12Y_CONST.TEXT.HERE_ARE_SOME_WAYS}
                                                descriptionList_1={A12Y_CONST.TEXT.TRACT_YOUR_TIME.replace('#dependentName#', this.props.dependent.nick_name)}
                                                descriptionList_2={A12Y_CONST.TEXT.REVIEW_AND_MANAGE.replace('#dependentName#', this.props.dependent.nick_name)}
                                                descriptionList_3={A12Y_CONST.TEXT.ADD_MULTIPLE_EXPENSE_TO_ACTIVITY}
                                                dependent={this.props.dependent}
                                                onCreate={this.landingPageBtnEvent}
                                                welcomeScreenType={A12Y_CONST.LABEL.ACTIVITY_LOG_LABEL}
                                                buttonTitle={this.state.btnTitle}
                                            />
                                            </div>
                                        </div>
                                    </div>

                            )
                        }


            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    dependent : state.dependentReducer.dependent,
    activityTypeList: state.dependentReducer.activityTypeList,
    asset_present: state.dependentReducer.asset_present,
    activity_present: state.dependentReducer.activity_present,
    expense_present: state.dependentReducer.expense_present,
    income_present: state.dependentReducer.income_present,
    userState : state.userDetailReducer.userState,
    filterSelectedTypeList : state.userDetailReducer.filterSelectedTypeList,
    filterData : state.userDetailReducer.filterData,
    dependentList : state.dependentReducer.dependentList,
});

const mapDispatchToProps = dispatch => ({
    currentActivityData : (activity) => dispatch(dependentActions.currentActivityData(activity)),
    setUserPreferences : (startDate,endDate,dependent,dependentList,activeTypeList,tagList,expenseTypeList,incomeTypeList) => dispatch(dependentActions.setUserPreferences(startDate,endDate,dependent,dependentList,activeTypeList,tagList,expenseTypeList,incomeTypeList)),
    getActivityTypeList : (dependentId) => dispatch(dependentActions.getActivityTypeList(dependentId)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    isCommingFromActivityList : (screen) => dispatch(dependentActions.isCommingFromActivityList(screen)),
    updateSessionTimeout : (status) => dispatch(userDetailActions.updateSessionTimeout(status)),
    selectedFilterTypeList : (data) => dispatch(userDetailActions.selectedFilterTypeList(data)),
    setSeletedFilter : (data) => dispatch(userDetailActions.setSeletedFilter(data)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
});

export default  connect(mapStateToProps, mapDispatchToProps) (ActivityScreen)
