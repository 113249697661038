import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Loading from '../components/LoadingComponent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "@material-ui/core/Switch";
import {currencyFormatting} from "../util/CurrencyFormater";
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import UploadFiles from "./ImageUploadComponent";
import {v4 as uuidv4} from "uuid";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import {readOnlyOnMobile, hideOnBlur} from '../util/inputKeyboardHide';

function AutomobileComponentStep1(props) {
    const { register, handleSubmit, setFocus, control,setValue, setError , formState: { isSubmitting, errors } } = useForm({ mode: "all" });
    const history = useHistory();
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [error, setErrors] = useState(false);
    const [name, setName] = useState('');
    const [dateAcquired, setDateAcquired] = useState(null);
    const [dateAcquiredError, setDateAcquiredError] = useState(false);
    const [dateAcquiredErrorMeesage, setDateAcquiredErrorMeesage] = useState('');

    const [assetValue, setAssetValue] = useState('');
    const [assetValueError, setAssetValueError] = useState(false);
    const [assetValueErrorMeesage, setAssetValueErrorMeesage] = useState('');

    const [asOfDate, setAsOfDate] = useState(new Date());
    const [asOfDateError, setAsOfDateError] = useState(false);
    const [asOfDateErrorMeesage, setAsOfDateErrorMeesage] = useState('');
    const [isCarInsured, setIsCarInsured] = useState(false);

    const [note, setNote] = useState('');

    const [model, setModel] = useState('');
    const [modelError, setModelError] = useState(false);
    const [modelErrorMeesage, setModelErrorMeesage] = useState('');
    
    const [vin, setVin] = useState('');
    const [vinError, setVinError] = useState(false);
    const [vinErrorMeesage, setVinErrorMeesage] = useState('');

    const [make, setMake] = useState('');
    const [makeErrorMeesage, setMakeErrorMeesage] = useState('');
    const [makeError, setMakeError] = useState(false);

    const [insuraceProvider, setInsuraceProvider] = useState('');
    const [insuraceProviderErrorMeesage, setInsuraceProviderErrorMeesage] = useState('');
    const [insuraceProviderError, setInsuraceProviderError] = useState(false);
    const [policy, setPolicy] = useState('');
    const [policyErrorMeesage, setPolicyErrorMeesage] = useState('');
    const [policyError, setPolicyError] = useState(false);
    const [year, setYear] = useState('');
    const [yearErrorMeesage, setYearErrorMeesage] = useState('');
    const [yearError, setYearError] = useState(false);
    const [carrier, setCarrier] = useState('');
    const [coveragePeriod, setCoveragePeriod] = useState(new Date());
    const [coveragePeriodError, setCoveragePeriodError] = useState(false);
    const [coveragePeriodErrorMeesage, setCoveragePeriodErrorMeesage] = useState('');
    const [until, setUntil] = useState(new Date());
    const [untilError, setUntilError] = useState(false);
    const [untilErrorMessage, setUntilErrorMeesage] = useState('');
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const dateAcquiredRef = useRef(null);
    const asOfDateRef = useRef(null);
    const coveragePeriodRef = useRef(null);
    const untilRef = useRef(null);
    const [linkToWebsite, setLinkToWebsite] = useState('');

    const onSubmit = async (formData)=>{
        let isValidateMake = await validateMake()
        let isValidateModel = await validateModel()
        let isValidateVin = await validateVin()
        let isValidateAssetValue= await validateAssetValue()
        let isValidateYear= await validateYear()
        // let isDatesValid = (asOfDate != null && !asOfDateError) && (dateAcquired != null && !dateAcquiredError) && (coveragePeriodError != null && !coveragePeriodError) && (until != null && !untilError)
        let isDatesValid = !asOfDateError && !dateAcquiredError && !coveragePeriodError && !untilError
        let isValidateInsureateProvider = isCarInsured? await validateInsuranceProvider() : false
        let isValidatePolicy = isCarInsured? await validatePolicy() : false
        formData.dateAcquired = dateAcquired
        formData.asOfDate = asOfDate
        formData.until = until
        formData.coveragePeriod = coveragePeriod
        let nameAsset = make + ' ' + model + ' ' + year
        let obj =  {
            "asset_type_id" : props.editMode ? props.currentAsset.asset_type_id : props.asset.asset_type_id,
            "asset_name" : nameAsset,
            "make" : make,
            "model" : model,
            "model_year" : year,
            "vin_number" : vin,
            "policy_number" : policy,
            "insured" : isCarInsured,
            "insurance_provider": insuraceProvider,
            "cover_from_date" : await ddmmyyyyFormat(new Date(coveragePeriod)),
            "cover_end_date": await ddmmyyyyFormat(new Date(until)),
            "acquisition_date" : dateAcquired != null ? await ddmmyyyyFormat(new Date(dateAcquired)) : null,
            "asset_value" : assetValue && assetValue.includes(',') ? assetValue.replaceAll(',','') : assetValue ,
            "as_of_date" : await ddmmyyyyFormat(new Date(asOfDate)),
            "status" : "1",
            "note" : note,
            "web_url" : linkToWebsite,
        }


        if(!isValidateMake && !isValidateModel && !isValidateVin && !isValidateInsureateProvider && !isValidatePolicy && !isValidateAssetValue && !isValidateYear && isDatesValid){
            setIsSubmit(true)
            setLoading(true);
            props.isDataChanged(false)
            if(props.editMode){
                obj.id = props.currentAsset.id;
                props.onUpdate(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }else {
                // props.onSubmit(obj)
                obj.id = assetId;
                props.onSubmit(obj,fileInfo,fileBase64Info,deletedFileInfo)
            }
        }
    }

    useEffect(() => {
        setFocus("make");
    }, [setFocus])

    useEffect(  () => {
        const createUuid = async ()=>{
            if(props.currentAsset === undefined || (props.currentAsset && Object.keys(props.currentAsset).length === 0)){
                const uuid = await uuidv4();
                await setAssetId(uuid);
            }
        }
        createUuid()
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
        const assignFormDefaultValue = async ()=>{
            setIsEditMode(false)
            if(props.editMode && props.currentAsset.hasOwnProperty('automobile')){
                let asset = props.currentAsset;
                setAssetId(asset.id)
                let assetVal = asset.asset_value || asset?.asset_value === 0  ? await currencyFormatting(asset.asset_value) : ''
                setName(asset.asset_name)
                setAssetValue(assetVal)
                setDateAcquired(asset.acquisition_date != null ? new Date(asset.acquisition_date) : null)
                setAsOfDate(new Date(asset.as_of_date))
                setNote(asset.note)
                setMake(asset.automobile.make)
                setModel(asset.automobile.model)
                setYear(asset.automobile.model_year)
                setVin(asset.automobile.vin_number)
                setCarrier(asset.automobile.carrier)
                setIsCarInsured(asset.automobile.insured)
                setCoveragePeriod(new Date(asset.automobile.cover_from_date))
                setUntil(new Date(asset.automobile.cover_end_date))
                setInsuraceProvider(asset.automobile.insurance_provider)
                setPolicy(asset.automobile.policy_number)
                setIsEditMode(true)
                setLinkToWebsite(asset?.web_url)
            }
        }
        assignFormDefaultValue()

    },[props.editMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkIsFormDataChanged = async ()=>{
           setIsBtnDisabled(true)
           setIsDataModified(false)
           props.isDataChanged(false)
           if(isEditMode && props.currentAsset.hasOwnProperty('automobile')){
               let asset = props.currentAsset;
               setIsBtnDisabled(true)
               if(insuraceProvider !== asset.automobile.insurance_provider|| policy !== asset.automobile.policy_number || linkToWebsite !== asset.web_url || name !== asset.asset_name || assetValue !== await currencyFormatting(asset.asset_value) || (dateAcquired !== null && await ddmmyyyyFormat(dateAcquired) !== asset.acquisition_date) || await ddmmyyyyFormat(asOfDate) !== asset.as_of_date || note !== asset.note || make !== asset.automobile.make || vin !== asset.automobile.vin_number || model !== asset.automobile.model || year.toString() !== asset.automobile.model_year.toString() || carrier !== asset.automobile.carrier || isCarInsured !== asset.automobile.insured || await ddmmyyyyFormat(new Date(coveragePeriod)) !== asset.automobile.cover_from_date || await ddmmyyyyFormat(new Date(until)) !== asset.automobile.cover_end_date || fileInfo.length > 0 || deletedFileInfo.length > 0){
                   if(make !== "" && model !== "" && year !=="" && asOfDate !== null && assetValue !== ""){
                       if(isCarInsured && (insuraceProvider !== "" && policy !== "" && coveragePeriod !== null && until !== null)){
                           setIsBtnDisabled(false)
                       }else {
                           if(!isCarInsured){
                               setIsBtnDisabled(false)
                           }
                       }

                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }else {
               if(!props.editMode){
                   let today = await ddmmyyyyFormat(new Date());
                   console.log('year', year)
                   if(insuraceProvider !== '' || policy !== '' || name !== '' || assetValue !== '' || linkToWebsite !== '' || dateAcquired !== null || await ddmmyyyyFormat(asOfDate) !== today || note !== '' || make !== '' || model !== '' || year !== '' || carrier !== '' || isCarInsured !== false || await ddmmyyyyFormat(new Date(coveragePeriod)) !== today || await ddmmyyyyFormat(new Date(until)) !== today || fileInfo.length > 0 || deletedFileInfo.length > 0){
                       if(make !== "" && model !== "" && year !=="" && asOfDate !== null && assetValue !== ""){
                           if(isCarInsured && (insuraceProvider !== "" && policy !== "" && coveragePeriod !== null && until !== null)){
                               setIsBtnDisabled(false)
                           }else {
                               if(!isCarInsured){
                                   setIsBtnDisabled(false)
                               }
                           }

                       }
                       console.log('check')
                       setIsDataModified(true)
                       props.isDataChanged(true)
                   }
               }
           }
       }

       checkIsFormDataChanged()

    },[isEditMode, insuraceProvider, policy, name, assetValue, dateAcquired, asOfDate, note, make, model, year, vin, carrier, isCarInsured, coveragePeriod, until, insuraceProvider, fileInfo, deletedFileInfo, linkToWebsite ]) // eslint-disable-line react-hooks/exhaustive-deps


    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            if(props.editMode){
                props.onCancel()
            }else {
                history.push(A12Y_CONST.ROUTE.INVENTORY);
            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        if(props.editMode){
            props.onCancel()
        }else {
            history.push(A12Y_CONST.ROUTE.INVENTORY);
        }
    }

    const handleVinChange = (e) => {
        setVin(e.target.value.trimStart())
        setValue('vin', e.target.value.trimStart())
    }
    const validateVin = (e) => {
        if(vin === null || vin === '') {
            return false;
        }else{
            if(vin.length < 10 || vin.length > 17){
                setVinError(true)
                setVinErrorMeesage(A12Y_CONST.ERROR_MESSAGE.VIN_MIN_MAX_ERROR);
                return true;
            }else{
                setVinError(false)
                setVinErrorMeesage('');
                return false;
            }
        }
    }

    const validateYear = () => {
        let currentYear = new Date().getFullYear()
        if( year == null || (year === '' && year.length === 0) || (year.length < 4)) {
            setYearError(true)
            setYearErrorMeesage(A12Y_CONST.ERROR_MESSAGE.YEAR_IS_REQUIRED);
            return true;
        }if(currentYear < parseInt(year)){
            setYearError(true)
            setYearErrorMeesage(A12Y_CONST.ERROR_MESSAGE.YEAR_GREATER);
            return true;
        }
        else{
            setYearError(false)
            setYearErrorMeesage('');
            return false;
        }
    }

    const handleAssetValueChange = async (e) => {
        let amt = e.target.value.trim()
        if(amt === ''){
            await setAssetValue(amt)
            setValue('assetValue',amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setAssetValue(temp)
                setValue('assetValue', temp)
            }
        }
    }

    const validateAssetValue = () => {
        if(assetValue === '' && assetValue.length === 0) {
            setAssetValueError(true)
            setAssetValueErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_ASSET_VALUE);
            return true;
        }else{
            setAssetValueError(false)
            setAssetValueErrorMeesage('');
            return false;
        }
    }
    const validateInsuranceProvider = () => {
        if(insuraceProvider === '' && insuraceProvider.length === 0) {
            setInsuraceProviderError(true)
            setInsuraceProviderErrorMeesage(A12Y_CONST.ERROR_MESSAGE.INSURANCE_PROVIDER_REQUIRED);
            return true;
        }else{
            setInsuraceProviderError(false)
            setInsuraceProviderErrorMeesage('');
            return false;
        }
    }
    const validatePolicy = () => {
        if(policy === '' && policy.length === 0) {
            setPolicyError(true)
            setPolicyErrorMeesage(A12Y_CONST.ERROR_MESSAGE.POLICY_IS_REQUIRED);
            return true;
        }else{
            setPolicyErrorMeesage(false)
            setPolicyErrorMeesage('');
            return false;
        }
    }

    const handleMakeChange = (e) => {
        setMake(e.target.value.trimStart())
        setValue('make', e.target.value.trimStart())
    }

    const validateMake = (e) => {
        if(make === '' && make.length === 0) {
            setMakeError(true)
            setMakeErrorMeesage(A12Y_CONST.ERROR_MESSAGE.MAKE_IS_REQUIRED);
            return true;
        }else{
            setMakeError(false)
            setMakeErrorMeesage('');
            return false;
        }
    }
    const validateModel = (e) => {
        if(model === '' && model.length === 0) {
            setModelError(true)
            setModelErrorMeesage(A12Y_CONST.ERROR_MESSAGE.MODEL_IS_REQUIRED);
            return true;
        }else{
            setModelError(false)
            setModelErrorMeesage('');
            return false;
        }
    }
    const handleInsuraceProviderChange = (e) => {
        setInsuraceProvider(e.target.value.trimStart())
        setValue('insuranceProvider', e.target.value.trimStart())
    }
    const handlePolicyChange = (e) => {
        setPolicy(e.target.value.trimStart())
        setValue('policy', e.target.value.trimStart())
    }

    const handleYearChange = (e) => {
        let reg = new RegExp('^[0-9]*$');
        let value = e.target.value.trimStart();
        if(reg.test(value) && value.length < 5){
            setYear(value)
            setValue('Year',value)
        }else {
            e.preventDefault();
        }
    }


    const handlemodelChange = (e) => {
        setModel(e.target.value.trimStart())
        setValue('model', e.target.value.trimStart())
    }

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
    },[props.loading,props.error,])

    //
    // useEffect(() => {
    //     if(name !== '' !== '' && assetValue !== '' && asOfDate != ''){
    //         setIsBtnDisabled(false)
    //     }
    // }, [name, assetValue,  asOfDate])

    const handleNoteChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setNote(e.target.value);
            setValue('note',e.target.value)
        }
    }

    const handleCarInsuredChange = async (e) => {
        if(!e.target.checked){
        if(props.editMode && props.currentAsset.hasOwnProperty('automobile')){
            let asset = props.currentAsset;
            setCoveragePeriod(new Date(asset.automobile.cover_from_date))
            setUntil(new Date(asset.automobile.cover_end_date))
        }
        setInsuraceProvider('')
        setPolicy('')
        }
        await setIsCarInsured(e.target.checked);
        setValue('carInsured', e.target.checked)

    }

    const filesDetail_FromChild = (e,deletedFiles) => {
        if(assetId){
            let fileList = [];
            let base64List = [];
            for (var i = 0; i < e.length; i++) {
                if(!e[i].file_id){
                    let name = e[i].displayName
                    let size = e[i].size
                    let file = e[i];
                    let fileName = e[i].name;
                    let reader = new FileReader();
                    const uuid = uuidv4();
                    let s3key = `${props.dependent.id}/asset/${assetId}/${uuid}`
                    reader.readAsDataURL(file);
                    reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                    );
                    fileList.push({
                        s3_key: s3key,
                        file_id:uuid,
                        asset_id:assetId,
                        'contentType': e[i].type,
                        'fileMetadata': {
                            "about_dependent": "some more info"
                        },
                        'name' : name,
                        'size' : size,
                        'fileName': fileName
                    });
                }
            }
            setFileInfo(fileList);
            setFileBase64Info(base64List)
            setDeletedFileInfo(deletedFiles)
        }
    }

    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const handleAsOfDateChange = () => {
        if(asOfDate != null){
            let isValidObj = validateDate(asOfDate, 100, 0)
            setAsOfDateError(isValidObj.isErr)
            setAsOfDateErrorMeesage(isValidObj.errMsg)
        }
        else{
            setAsOfDateError(true)
            setAsOfDateErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    const handleDateAcquiredChange = () => {
        if(dateAcquired != null){
            let isValidObj = validateDate(dateAcquired, 100, 0)
            setDateAcquiredError(isValidObj.isErr)
            setDateAcquiredErrorMeesage(isValidObj.errMsg)
        }
    }
    
    const handleCoveragePeriodChange = () => {
        if(coveragePeriod != null){
            let isValidObj = validateDate(coveragePeriod, 100, 0)
            setCoveragePeriodError(isValidObj.isErr)
            setCoveragePeriodErrorMeesage(isValidObj.errMsg)

            if(until != null){
                return isDateMatching();
            }
        }else{
            setCoveragePeriodError(true)
            setCoveragePeriodErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }
    const handleUntilDateChange = () => {
        if (until != null) {
            setUntilError(false)
            setUntilErrorMeesage('')
            if(coveragePeriod != null){
                return isDateMatching();
            }
        } else {
            setUntilError(true)
            setUntilErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
        }
    }

    const isDateMatching = () => {
        let sDt = Date.parse(coveragePeriod);
        let eDt = Date.parse(until);
        if (eDt === sDt) {
            setUntilError(false)
            setUntilErrorMeesage('')
            return true;
        }
        else if (eDt < sDt) {
            setUntilError(true)
            setUntilErrorMeesage(A12Y_CONST.ERROR_MESSAGE.UNTIL_DATE_ERROR)
            return false;
        }else if(sDt < eDt){
            setUntilError(false)
            setUntilErrorMeesage('')
            return true;
        }
    }

    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }

    return (
        <div className="df fcol marginBottom20 height100">
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className='fullWidth'>
                <h4 className="dependentSubTitle marginBottom10"><span className='txtEllipsis vam'>{props.dependent.nick_name}</span><span className='vam'>{props.apostrophe ? A12Y_CONST.LABEL.APOSTROPHE_S : ''} {props.title}</span></h4>
            </div>
            <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
            </div>
            <form autoComplete="off" className='marginTop30 autoMobileForm fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                <div className='fullWidth'>
                    <div className="formRow fullWidth formRowGapSM payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.MAKE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth">
                                <input
                                    {...register("make")}
                                    value={make}
                                    id="make"
                                    onBlur={validateMake}
                                    onChange={handleMakeChange}
                                    onFocus={()=>setMakeErrorMeesage(false)}
                                    className={`fullWidth inputColum24size ${ makeError && 'errorInputBorderRed'}`}
                                    placeholder="&nbsp;"
                                    maxLength="100"
                                />
                                <div className='errorRelativeRow'>{makeError? <p className='errorMessage'>{makeErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 textAlignRight positionRelative">
                            <label>{A12Y_CONST.LABEL.MODEL}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth">
                                <input
                                    {...register("model")}
                                    value={model}
                                    id="model"
                                    onBlur={validateModel}
                                    onChange={handlemodelChange}
                                    onFocus={()=>setModelErrorMeesage(false)}
                                    className={`fullWidth inputColum24size ${modelError && 'errorInputBorderRed'}`}
                                    placeholder="&nbsp;"
                                    maxLength="100"
                                />
                                <div className='errorRelativeRow'>{modelError? <p className='errorMessage'>{modelErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGapSM payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.YEAR}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth">
                                <input
                                    {...register("year")}
                                    value={year}
                                    id="year"
                                    onBlur={validateYear}
                                    onChange={handleYearChange}
                                    onFocus={()=>setYearErrorMeesage(false)}
                                    className={`fullWidth inputColum24size ${yearError && 'errorInputBorderRed'}`}
                                    placeholder="YYYY"
                                />
                                <div className='errorRelativeRow'>{yearError? <p className='errorMessage'>{yearErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 textAlignRight positionRelative">
                            <label>{A12Y_CONST.LABEL.VIN_NUMBER}</label>
                            <div className="fullWidth">
                                <input
                                    {...register("vin")}
                                    value={vin}
                                    id="vin"
                                    onBlur={(e) => validateVin(e)}
                                    onChange={handleVinChange}
                                    maxLength={17}
                                    onFocus={()=>setVinError(false)}
                                    className={`fullWidth inputColum24size ${vinError && 'errorInputBorderRed'}`}
                                    placeholder="&nbsp;"
                                />
                                <div className='errorRelativeRow'>{vinError? <p className='errorMessage'>{vinErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                    </div>

                    <div className="formRow fullWidth formRowGapSM payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.ASSET_VALUE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={ assetValue.trim().length === 0? "currency" : "currency currencyColorChnage" }>{A12Y_CONST.TEXT.DOLLOR_SYMBOL}</span>
                                <input
                                    {...register("assetValue")}
                                    value={assetValue}
                                    id="assetValue"
                                    type='text' min='0' inputMode='decimal' //pattern='[0-9]+(\.[0-9]{1,2})?'
                                    onWheel={e => e.target.blur()}
                                    maxLength='50'
                                    onBlur={validateAssetValue}
                                    onChange={handleAssetValueChange}
                                    onFocus={()=>setAssetValueErrorMeesage(false)}
                                    className={`fullWidth txtAmount inputColum24size ${assetValueError && 'errorInputBorderRed'}`}
                                    placeholder="0,000.00"
                                />
                                <div className='errorRelativeRow'>{assetValueError? <p className='errorMessage'>{assetValueErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 positionRelative">
                            <label className='textAlignRight'>{A12Y_CONST.LABEL.AS_OF_DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <Controller
                                    name="asOfDate"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            id={A12Y_CONST.BUTTON_ID.AS_OF_DATE}
                                            dateFormat="MM / dd / yyyy"
                                            ref={asOfDateRef}
                                            selected={asOfDate}
                                            onChange={date => setAsOfDate(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'asOfDate')}
                                            onBlur={handleAsOfDateChange}
                                            onCalendarClose={handleAsOfDateChange}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, asOfDateRef)}
                                            onFocus={(e)=> {setAsOfDateError(false); readOnlyOnMobile(e.target)}}
                                            className={!asOfDateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {asOfDateError? <p className='errorMessage'>{ asOfDateErrorMeesage }</p>: null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGapSM payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.DATE_ACQUIRED}</label>
                            <div className="fullWidth amountWrap">
                                <Controller
                                    name="dateAcquired"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            id={A12Y_CONST.BUTTON_ID.DATE_ACQUIRED}
                                            dateFormat="MM / dd / yyyy"
                                            ref={dateAcquiredRef}
                                            selected={dateAcquired}
                                            onChange={date => setDateAcquired(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'dateAcquired')}
                                            onBlur={handleDateAcquiredChange}
                                            onCalendarClose={handleDateAcquiredChange}
                                            peekNextMonth
                                            showMonthDropdown
                                            onFocus={(e)=> {setDateAcquiredError(false); readOnlyOnMobile(e.target)}}
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(event) => hideOnBlur(event, dateAcquiredRef)}
                                            className={!dateAcquiredError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {dateAcquiredError? <p className='errorMessage'>{dateAcquiredErrorMeesage}</p>: null}   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="autoMobileDetail fullWidth">
                     <div className="formRow fullWidth formRowGapSM">
                        <label className="lblExpType">{A12Y_CONST.LABEL.CAR_INSURED}</label>
                        <div className="fullWidth">
                            <div className="fullWidth marginTop5 df aic">
                                <Controller
                                    name="carInsured"
                                    control={control}
                                    render={({ field }) =>
                                        <Switch
                                            checked={isCarInsured}
                                            onChange={handleCarInsuredChange}
                                            color="primary"
                                            id={A12Y_CONST.BUTTON_ID.CAR_INSURED}
                                        />
                                    }
                                />
                                {isCarInsured && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}
                                {!isCarInsured &&  <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}
                            </div>
                        </div>
                    </div>
                    { isCarInsured ?
                    <>
                     <div className="formRow fullWidth formRowGapSM payRow">
                            <div className="width50 paddingRight16 positionRelative">
                                <label className='lableTop0'>{A12Y_CONST.LABEL.INSURANCE} <br/> {A12Y_CONST.LABEL.PROVIDER}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div className="fullWidth">
                                    <input
                                        {...register("insuraceProvider")}
                                        value={insuraceProvider}
                                        id="insuraceProvider"
                                        onBlur={validateInsuranceProvider}
                                        onChange={handleInsuraceProviderChange}
                                        onFocus={()=>setInsuraceProviderErrorMeesage(false)}
                                        className={`fullWidth inputColum24size ${insuraceProviderError && 'errorInputBorderRed'}`}
                                        placeholder="Company Name"
                                    />
                                    <div className='errorRelativeRow'>{insuraceProviderError? <p className='errorMessage'>{insuraceProviderErrorMeesage}</p>: null}</div>
                                </div>
                            </div>
                            <div className="width50 paddingleft16 textAlignRight positionRelative">
                                <label className="lblExpType">{A12Y_CONST.LABEL.POLICY_NUMBER}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div className="fullWidth">
                                    <input
                                        {...register("policy")}
                                        value={policy}
                                        id="policy"
                                        onBlur={validatePolicy}
                                        onChange={handlePolicyChange}
                                        onFocus={()=>setPolicyError(false)}
                                        className={`fullWidth inputColum24size ${policyError && 'errorInputBorderRed'}`}
                                        placeholder="Add Policy"
                                    />
                                    <div className='errorRelativeRow'>{policyError? <p className='errorMessage'>{policyErrorMeesage}</p>: null}</div>
                                </div>
                            </div>
                        </div>
                     <div className="formRow fullWidth formRowGapSM payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label className="lblExpType">{A12Y_CONST.LABEL.COVERAGE_PERIOD}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth coveragePeriod">
                                <Controller
                                    name="coveragePeriod"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            dateFormat="MM / dd / yyyy"
                                            ref={coveragePeriodRef}
                                            selected={coveragePeriod}
                                            onChange={date => setCoveragePeriod(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'coveragePeriod')}
                                            onBlur={ ({ target: { value } }) => handleCoveragePeriodChange(value)}
                                            onCalendarClose={() => handleCoveragePeriodChange(coveragePeriod)}
                                            onKeyDown={(event) => hideOnBlur(event, coveragePeriodRef)}
                                            onFocus={(e)=> {setCoveragePeriodError(false); readOnlyOnMobile(e.target)}}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            className={!coveragePeriodError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {coveragePeriodError? <p className='errorMessage'>{coveragePeriodErrorMeesage}</p>: null}
                                </div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 textAlignRight positionRelative">
                            <label className="lblExpType">{A12Y_CONST.LABEL.UNTIL}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth coveragePeriod">
                                <Controller
                                    name="until"
                                    control={control}
                                    inputmode='none'
                                    render={({ field }) =>
                                        <DatePicker
                                            {...field}
                                            ref={untilRef}
                                            dateFormat="MM / dd / yyyy"
                                            selected={until}
                                            onChange={date => setUntil(date)}
                                            onChangeRaw={(event) => handleChangeRaw(event, 'until')}
                                            onBlur={ ({ target: { value } }) => handleUntilDateChange(value)}
                                            onCalendarClose={() => handleUntilDateChange(until)}
                                            peekNextMonth
                                            showMonthDropdown
                                            onKeyDown={(event) => hideOnBlur(event, untilRef)}
                                            onFocus={(e)=> {setUntilError(false); readOnlyOnMobile(e.target)}}
                                            showYearDropdown
                                            dropdownMode="select"
                                            className={!untilError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                            placeholderText="MM / DD / YYYY"
                                        />
                                    }
                                />
                                <div className='errorRelativeRow'>
                                    {untilError? <p className='errorMessage'>{untilErrorMessage}</p>: null}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                    :
                    null
                    }
                </div>
                <div className="formRow fullWidth formRowGapSM payRow">
                    <div className="width50 paddingRight16 positionRelative">
                    <label>{A12Y_CONST.LABEL.LINK_URL}</label>
                        <div className='fullWidth doctorRowMarginB_600'>
                            <input
                                {...register('linkToWebsite',{
                                    pattern:{
                                        //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                        value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                        message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                    }
                                })}
                                placeholder='www.example.com'
                                className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                type='text'
                                id='displayName'
                                autoComplete="none"
                                onChange={(e) => handleLinkToWebsite(e.target.value)}
                                value={linkToWebsite}
                                onFocus={()=>{
                                    setError("linkToWebsite", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                        </div>
                    </div>
                </div>
                {!props.editMode?
                <div className="formRow fullWidth formRowGapSM df fcol">
                    <label>{A12Y_CONST.LABEL.NOTE}</label>
                    <div className="fullWidth">
                            <textarea
                                {...register("note")}
                                rows="3"
                                onChange={handleNoteChange}
                                maxLength='4000'
                                value={note}
                                className="fullWidth inputColum24size txtNote"
                                placeholder="Capture a note about this automobile."
                            ></textarea>
                    </div>
                    {/*<div className="txtCapture">Capture a note about this transaction.</div>*/}
                </div>
                :
                null
                }
                <div className="formRow fullWidth marginBottom30 df fcol">
                    <label>{A12Y_CONST.LABEL.ATTACHMENT}</label>
                    <div className="fullWidth">
                        <div className="formRow fullWidth formRowGap uploadWrap">
                            <div className="lblUpload">
                                <UploadFiles
                                    fileDetailSendToParent={filesDetail_FromChild}
                                    fileUploadingData={props.fileUploadingData}
                                    isSubmit={isSubmit}
                                    editMode={props.editMode}
                                    obj={props.currentAsset}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*------------------ Button Actions ---------------------*/}
                <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom">
                    <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.AUTOMOBILE_STEP1_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                    <div className='widthAuto marginLeft20'>
                        <div className='loaderSpinner'>
                            { loading ? <Loading /> : null }
                            <input id={A12Y_CONST.BUTTON_ID.AUTOMOBILE_STEP1+props.BtnTxt} className={isBtnDisabled || loading ? "saveButtonDisable" : 'saveButton'} disabled={isBtnDisabled || loading} type="submit" value={props.BtnTxt} />
                        </div>
                    </div>
                </div>
            </form>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default AutomobileComponentStep1;
