import React, { useState,useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import InventoryFileViewer from "./InventoryFileViewer";
import NotesComponent from "./NotesComponent";

function AutomobileDetailComponent(props) {

    //const [isProvider, setIsProvider] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [showEditOrAddIcon, setShowEditOrAddIcon] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState('');

    const [isCommentTabVisible, setIsCommentTabVisible] = useState(true);

    useEffect(()=>{
        setShowEditOrAddIcon(props.showEditOrAddIcon)
    },[props.showEditOrAddIcon])


    const collapse = (elment) => {
        let elm = document.getElementById(elment);
        if(elm !== null){
            elm.parentElement.classList.toggle('opened')
            elm.classList.toggle('heightAuto')

            
        }
    }

    useEffect(()=>{
        if(props.currentAsset?.institute){
           // setIsProvider(true)
        }
        setCanUpdate(props?.dependent?.access?.can_update)

    },[props.currentAsset.institute, props.dependent.access])


    const editAutomobile = () => {
        props.goToEditScreen(A12Y_CONST.TEXT.ASSET_AUTOMOBILE_KEY)
    }

    useEffect(() => {
        if(props.currentAsset?.web_url){
            let link = (    props.currentAsset?.web_url.indexOf('://') === -1) ? 'http://' + props.currentAsset?.web_url : props.currentAsset?.web_url;
            setWebsiteUrl(link)
        }else{
            setWebsiteUrl('')
        }

    },[props.currentAsset?.web_url, websiteUrl])

    const tabCheckBox = (status) => {
        setIsCommentTabVisible(status)
    }
    return(
        <div>
            <div className="inventoryBox fullWidth borderBottom opened">
                <div onClick={() => collapse('Policy')} className="inventoryScreenTitle fullWidth positionRelative cursorPointer  inventory_Col_padding">
                    {/*{props.dependent.nick_name}'s {A12Y_CONST.LABEL.AUTOMOBILE}*/}
                   <div className='assetColTitle'> {props.dependent.nick_name}'s {props.currentAsset?.automobile? props.currentAsset?.automobile.model_year :null} {props.currentAsset?.automobile? props.currentAsset?.automobile.make :null} {props.currentAsset?.automobile? props.currentAsset?.automobile.model:null}</div>
                    {props.loading ?
                        null
                        :
                        (
                            canUpdate && showEditOrAddIcon ?

                                <span id={A12Y_CONST.LABEL.AUTOMOBILE+A12Y_CONST.LABEL.EDIT} className='cursorPointer' onClick={editAutomobile}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.194 0.246632C11.5224 -0.0822106 12.0563 -0.0822106 12.3847 0.246632L15.7531 3.61506C16.0823 3.9439 16.0823 4.47712 15.7531 4.80596L4.80583 15.7533C4.64836 15.9112 4.43363 16 4.21047 16H0.842094C0.377006 16 0 15.623 0 15.1579V11.7895C0 11.5661 0.0887568 11.3519 0.246649 11.194L8.66768 2.77312L11.194 0.246632ZM9.26304 4.55931L1.68419 12.1383V14.3158H3.86184L11.4407 6.73683L9.26304 4.55931ZM12.6314 5.54592L13.967 4.21051L11.7893 2.03299L10.4538 3.3684L12.6314 5.54592Z" fill="#2D6CB2"/>
                                </svg>
                            </span>
                            :
                            null
                        )
                    }
                    
                    <div className="btnAddEditWrap df fcol jcc aic">
                        <span className='collape marginBottom20'>
                            <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.703768 0.684193C1.63973 -0.228064 3.16111 -0.228064 4.09707 0.684193L12 8.36722L19.9029 0.684193C20.8389 -0.228064 22.3603 -0.228064 23.2962 0.684193C24.2346 1.59412 24.2346 3.07333 23.2962 3.98326L13.6966 13.3158C12.7607 14.2281 11.2393 14.2281 10.3033 13.3158L0.703768 3.98326C-0.23459 3.07333 -0.23459 1.59412 0.703768 0.684193Z" fill="#565C65"/>
                        </svg>
                        </span>
                    </div>
                </div>

                <div id='Policy' className={`inventoryDetialBox marginTop20 heightAuto positionRelative ${props.loading ? 'polLoading' : 'height0'}`}>
                    {props.loading ?
                        <div className='loaderPol df aic jcc fcol height100 width100 positionAbsouteOnly'>
                            <div className="positionRelative">
                                <div className="dependentLoader">
                                    <div className='fullWidth justifyCenter marginBottom10'>
                                        <div className="stage">
                                            <div className="dot-pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='marginTop20 fullWidth'>{A12Y_CONST.TEXT.LOADING_AUTOMOBILE}</div>
                            </div>
                        </div>
                        :
                        <div>
                            {/*<div>*/}
                            {/*    <div className="inventoryRow width50"><span className="inventory_label">{A12Y_CONST.LABEL.MAKE}</span><span className="inventory_value breakWords">{props.currentAsset?.automobile? props.currentAsset?.automobile.make : null }</span></div>*/}
                            {/*    <div className="inventoryRow width50"><span className="inventory_label">{A12Y_CONST.LABEL.MODEL}</span><span className="inventory_value">{props.currentAsset?.automobile? props.currentAsset?.automobile.model : null }</span></div>*/}
                            {/*</div>*/}
                            <div className='fullWidth'>
                                {/*<div className="inventoryRow width50 marginTop15"><span className="inventory_label">YEAR</span><span className="inventory_value">{props.currentAsset?.automobile? props.currentAsset?.automobile.model_year : null }</span></div>*/}
                                <div className="inventoryRow width50"><span className="inventory_label">{A12Y_CONST.LABEL.VIN_NUMBER}</span><span className="inventory_value">{props.currentAsset?.automobile.vin_number}</span></div>
                            </div>
                            <div>
                                <div className="inventoryRow width50 marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.ASSET_VALUE}</span><span className="inventory_value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.currentAsset.asset_value)}</span></div>
                                <div className="inventoryRow width50 marginTop15"><span className="inventory_label inventory_label20Padding">{A12Y_CONST.LABEL.AS_OF_DATE}</span><span className="inventory_value">{props.currentAsset.as_of_date}</span></div>
                            </div>
                            <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.DATE_ACQUIRED}</span><span className="inventory_value">{props.currentAsset.acquisition_date}</span></div>

                            <div className="inventoryRow fullWidth marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.CAR_INSURED}</span><span className="inventory_value">{props.currentAsset?.automobile? props.currentAsset?.automobile.insured ? 'YES' : 'NO' : 'NO'}</span></div>
                            {props.currentAsset?.automobile?.insured ?
                                <div className='fullWidth'>
                                    <div className='fullWidth'>
                                        <div className="inventoryRow width50 marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.INSURANCE_PROVIDER}</span><span className="inventory_value">{props.currentAsset?.automobile?.insurance_provider}</span></div>
                                        <div className="inventoryRow width50 marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.POLICY}</span><span className="inventory_value">{props.currentAsset?.automobile?.policy_number}</span></div>
                                    </div>
                                    <div className='fullWidth'>
                                        <div className="inventoryRow width50 marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.COVERAGE_PERIOD}</span><span className="inventory_value">{props.currentAsset?.automobile? props.currentAsset?.automobile.cover_from_date : null}</span></div>
                                        <div className="inventoryRow width50 marginTop15"><span className="inventory_label">{A12Y_CONST.LABEL.UNTIL}</span><span className="inventory_value">{props.currentAsset?.automobile? props.currentAsset?.automobile.cover_end_date : null}</span></div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            <div className="fullWidth inventoryRow">
                             {websiteUrl ?
                        <div className="df marginTop15 linkToWebsiteMargin">
                            <a id={A12Y_CONST.TEXT.LINK_URL+A12Y_CONST.LABEL.AUTOMOBILE} rel="noreferrer" className='linkToWebsite df alignCenterVH' target="_blank" href={websiteUrl}>
                                <svg className='marginRight10' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                    <path d="M9.16667 18.3333C4.10392 18.3333 0 14.2294 0 9.16667C0 4.10392 4.10392 0 9.16667 0C14.2294 0 18.3333 4.10392 18.3333 9.16667C18.3333 14.2294 14.2294 18.3333 9.16667 18.3333ZM7.0675 16.1947C6.16313 14.2765 5.63922 12.201 5.52475 10.0833H1.89017C2.06854 11.494 2.65276 12.8225 3.57185 13.9074C4.49095 14.9923 5.70535 15.7869 7.0675 16.1947ZM7.36083 10.0833C7.49925 12.3191 8.13817 14.4192 9.16667 16.2727C10.223 14.3702 10.84 12.2554 10.9725 10.0833H7.36083ZM16.4432 10.0833H12.8086C12.6941 12.201 12.1702 14.2765 11.2658 16.1947C12.628 15.7869 13.8424 14.9923 14.7615 13.9074C15.6806 12.8225 16.2648 11.494 16.4432 10.0833ZM1.89017 8.25H5.52475C5.63922 6.1323 6.16313 4.05688 7.0675 2.13858C5.70535 2.54639 4.49095 3.34102 3.57185 4.42594C2.65276 5.51085 2.06854 6.83934 1.89017 8.25ZM7.36175 8.25H10.9716C10.8394 6.07805 10.2226 3.96324 9.16667 2.06067C8.11037 3.96316 7.49334 6.07798 7.36083 8.25H7.36175ZM11.2658 2.13858C12.1702 4.05688 12.6941 6.1323 12.8086 8.25H16.4432C16.2648 6.83934 15.6806 5.51085 14.7615 4.42594C13.8424 3.34102 12.628 2.54639 11.2658 2.13858Z" fill="#2F6DB3"/>
                                </svg>
                                {A12Y_CONST.TEXT.LINK_TO_WEBSITE}</a>
                                </div>
                                :
                                null
                            }
                            </div>
                            <div className="inventoryRow fullWidth marginTop15 commentSetionViewScreen">
                            {/*<span className="inventory_label">{A12Y_CONST.LABEL.NOTE}</span>*/}
                            {/*<span className="inventory_value noteText ayScrollBar">
                                {
                                    props?.currentAsset?.note?.length > 0 && props?.currentAsset?.note?.split('\n').map((n, i)=> {
                                        return <div key={i}>{n}</div>
                                    })}
                            </span>*/}
                                <label className="inventory_label">{A12Y_CONST.TEXT.OTHER_SURVEY}
                                <span className='showLabel'>{A12Y_CONST.TEXT.SHOW}</span>
                                </label>
                                <div className='fullWidth'>
                                    <div className='fullWidth marginBottom20 marginTop35 commentMarginResponsive'>
                                        <div id={A12Y_CONST.LABEL.ATTACHMENTS_SMALL+A12Y_CONST.LABEL.AUTOMOBILE+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(true)}} className={`commentExpenseTab ${isCommentTabVisible?'activecommentExpenseTab':''}`}>{A12Y_CONST.LABEL.ATTACHMENTS_SMALL}</div>
                                        <div id={A12Y_CONST.LABEL.NOTES+A12Y_CONST.LABEL.AUTOMOBILE+A12Y_CONST.TEXT.TAB} onClick={() => {tabCheckBox(false)}}  className={`commentExpenseTab  ${isCommentTabVisible ? '' : 'activecommentExpenseTab'}`}>{A12Y_CONST.LABEL.NOTES}</div>
                                    </div>
                                    <div className="fullWidth tabContentBox">
                                        {isCommentTabVisible?
                                            <div className='fullWidth'>
                                                {props.currentAsset.attachments && props.currentAsset.attachments.length > 0 ?
                                                    <InventoryFileViewer
                                                        currentAsset={props.currentAsset}
                                                        dependent={props.dependent}
                                                    />
                                                    :
                                                    <div className='noData inventoryNote df aic jcc fcol'>
                                                        <strong>{A12Y_CONST.TEXT.NO_DATA_FOUND}</strong>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div className='fullWidth'>
                                                <NotesComponent
                                                    noteType={A12Y_CONST.LABEL.NOTE_AUTOMOBILE_TYPE}
                                                    selectedItem={props.currentAsset}
                                                    dependent={props.dependent}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            {/*<div className="inventoryRow fullWidth marginTop15 marginBottom20"><span className="inventory_label">{A12Y_CONST.LABEL.ATTACHMENT}</span>*/}

                            {/*</div>*/}
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default AutomobileDetailComponent