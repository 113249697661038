import React, { useState, useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Loading from '../components/LoadingComponent';
import stateList from '../common/stateList.json'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import EditChangesRemovePopup from "./EditChangesRemovePopup";
import PhoneInput from "react-phone-input-2";
import {isValidPhoneNumber} from "libphonenumber-js";


function AddDoctorComponent(props) {
    const [loading, setLoading] = useState(false);
    const [error, setErrors] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const { register, handleSubmit, getValues, control, setValue, setFocus, setError, formState: { errors, isSubmitting } } = useForm({ mode: "all" });
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorMeesage, setNameErrorMeesage] = useState('');

    const [pracOrHosp, setPracOrHosp] = useState('');
    //const [pracOrHospErrorMeesage, setPracOrHospErrorMeesage] = useState('');

    const [selectedPhoneType1, setSelectedPhoneType1] = useState({});
    const [selectedPhoneType2, setSelectedPhoneType2] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumber_2, setPhoneNumber_2] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber_1ErrorMessage, setPhoneNumber_1ErrorMessage] = useState('');
    const [phoneNumber_2ErrorMessage, setPhoneNumber_2ErrorMessage] = useState('');
    const [phoneNumberError, setphoneNumberError] = useState(false);
    const [phoneNumber2Error, setphoneNumber2Error] = useState(false);
    const [phoneType1LabelActive, setPhoneType1LabelActive] = useState(false);
    const [phoneType2LabelActive, setPhoneType2LabelActive] = useState(false);
    const [isPhone2DropdownSelect, setIsPhone2DropdownSelect] = useState(false);
    const [note, setNote] = useState('');

    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [countryState, setCountryState]  = useState({});
    const [showAddressDropDown, setShowAddressDropDown] = useState(false);
    const [line2, setLine2] = useState('');
    const [line1, setLine1] = useState('');
    const [placeServiceList, setPlaceServiceList] = useState({});
    const [isEditMode, setIsEditMode] = useState(false)
    const [isDataModified, setIsDataModified] = useState(false);

    const [linkToWebsite, setLinkToWebsite] = useState('');

    const [fax, setFax] = useState('');
    const [faxError, setFaxError] = useState(false);
    const [faxErrorMeesage, setFaxErrorMeesage] = useState('');
    const [dropDownIndex, setDropDownIndex] = useState(null);
    const [specialtyTypeOptionsList, setSpecialtyTypeOptionsList] = useState([]);


    const [specialtyType, setSpecialtyType] = useState([]);
    const [specialtyTypeError, setSpecialtyTypeError] = useState(false);
    const [specialtyTypeErrorMessage, setSpecialtyTypeErrorMessage] = useState('');


    const countryStateList = stateList;
    const stateOptions = countryStateList && countryStateList.map(state =>{
        return {label: state.label, value: state.label}
    })

    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
    }, [props.error, props.loading, props.loaderFullScreen])

    useEffect(  () => {
        const assignDoctorSpecialistList = async ()=>{
            let doctorSpecialistList = await props.doctorSpecialistTypeList.map(item => ({ label: item.title, value: item.title, id:item.id, place_order:item.place_order, status:item.status }));
            setSpecialtyTypeOptionsList(doctorSpecialistList)
        }

        assignDoctorSpecialistList()

    },[props.doctorSpecialistTypeList])

    const onSubmit = async (formData)=>{
        let IsPhone1DropdownSelected = false;
        let IsPhone2DropdownSelected = false;
        let phoneError = false
        let isSpecialtyTypeValidate = await validateSpecialtyType();
        let isNameValidate = await validateName();
        let isFaxValidate = await  validateFax()
        let isNumberPresent = false
        let phoneList = [{type:selectedPhoneType1.label && selectedPhoneType1.label !== 'Select'  ?selectedPhoneType1?.label?.toLowerCase():'',number:(phoneNumber === '1' || phoneNumber === '') ? '' : phoneNumber},{type:selectedPhoneType2.label && selectedPhoneType2.label !== 'Select' ?selectedPhoneType2?.label?.toLowerCase():'',number:(phoneNumber_2 === '1' || phoneNumber_2 === '') ? '' : phoneNumber_2}]
        /*if(Object.keys(selectedPhoneType1).length > 0 && phoneNumber){
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
            onBlurEventHandlerPhone()
        }else if(Object.keys(selectedPhoneType1).length === 0 && phoneNumber){
            onBlurEventHandlerPhone()
            if(!phoneNumberError){
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                return;
            }
        }
        if(Object.keys(selectedPhoneType2).length > 0 && phoneNumber_2){
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            onBlurEventHandlerPhone()
        }else if(Object.keys(selectedPhoneType2).length === 0 && phoneNumber_2){
            onBlurEventHandlerPhone()
            if(!phoneNumber2Error){
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_TYPE)
                return;
            }
        }*/
        await onBlurEventHandlerPhone();
        if(phoneNumber.length > 1 && phoneNumber !== "1" &&  Object.keys(selectedPhoneType1).length === 0 ){
            phoneError = true
            setphoneNumberError(true)
            setIsPhone2DropdownSelect(true)
            IsPhone1DropdownSelected = true
            setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label !== 'Select'&& (phoneNumber === "" || phoneNumber === "1")){
                phoneError = true
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone1DropdownSelected = false
        }

        await onBlurEventHandlerPhone2();
        if(phoneNumber_2.length > 1 && phoneNumber_2 !== "1" &&  Object.keys(selectedPhoneType2).length === 0 ){
            phoneError = true
            setIsPhone2DropdownSelect(true)
            IsPhone2DropdownSelected = true
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PLEASE_SELECT_PHONE_TYPE)
            //return;
        }else{
            if(Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label !== 'Select' && (phoneNumber_2 === "" || phoneNumber_2 === "1")){
                phoneError = true
                setphoneNumber2Error(true)
                setPhoneNumber_2ErrorMessage(A12Y_CONST.ERROR_MESSAGE.PHONE_NUMBER_IS_INVALID)
                // return
            }
            setIsPhone2DropdownSelect(false)
            IsPhone2DropdownSelected = false
        }
        if(phoneNumberError || phoneNumber2Error || IsPhone2DropdownSelected ||IsPhone1DropdownSelected ||  phoneError){
            return
        }
        if(!phoneNumberError && !phoneNumber2Error){
            phoneList.forEach((item,key)=>{
                if(item.number){
                    isNumberPresent = true
                }
            })
        }
        let obj = {
            "doctor_name" : name,
            "speciality_id" : specialtyType.id,
            "note" : note,
            "phones" : isNumberPresent ? JSON.stringify(phoneList) : null,
            "email" : email,
            "web_url" : linkToWebsite,
            "hospital_name":pracOrHosp,
            "fax" :(fax === "1" || fax === "" ) ? "" : fax,
            "address":{
                "line1" : line1,
                "line2" : line2,
                "city" : city,
                "state" : Object.keys(countryState).length > 0 ? countryState.label : '',
                "zip" : zip,
            }
        }

        if(!isSpecialtyTypeValidate && !isNameValidate && !phoneNumberError && !phoneNumber2Error && !isFaxValidate){
            props.isDataChanged(false)
            if(isEditMode){
                obj.id = props.doctor.id
                props.onUpdate(obj)
            }else {
               props.onSubmit(obj)
            }
        }

    }

    useEffect(()=>{
        if(props.doctor !== null && props.doctor && Object.keys(props.doctor).length > 0){
            let doctor = props.doctor
            setName(doctor.doctor_name)
            setNote(doctor.note)
            setEmail(doctor.email)
            setLinkToWebsite(doctor.web_url)
            setPracOrHosp(doctor.hospital_name)
            setFax(doctor.fax)
            if(doctor.speciality_id && props.doctorSpecialistTypeList.length > 0){
                let selectedSpeciality = props.doctorSpecialistTypeList.find(item => item.id === doctor.speciality_id)
                setSpecialtyType(selectedSpeciality)
            }
            if(doctor.phones !== null && doctor.phones.length > 0){
                doctor.phones.forEach((phone,index)=>{
                    if(index === 0){
                        if(phone.type === 'mobile'){
                            setSelectedPhoneType1({value:'Mobile' , label:'Mobile'})
                        }else if(phone.type === 'office'){
                            setSelectedPhoneType1({value:'Office' , label:'Office'})
                        }else if(phone.type === 'home'){
                            setSelectedPhoneType1({value:'Home' , label:'Home'})
                        }else if(phone.type === 'other'){
                            setSelectedPhoneType1({value:'Other' , label:'Other'})
                        }
                        if(phone.number.length > 0){
                            setPhoneNumber(phone.number)
                            setPhoneType1LabelActive(true)
                        }else {
                            setPhoneNumber('')
                        }
                    }else {
                        if(phone.type === 'mobile'){
                            setSelectedPhoneType2({value:'Mobile' , label:'Mobile'})
                        }else if(phone.type === 'office'){
                            setSelectedPhoneType2({value:'Office' , label:'Office'})
                        }else if(phone.type === 'home'){
                            setSelectedPhoneType2({value:'Home' , label:'Home'})
                        }else if(phone.type === 'other'){
                            setSelectedPhoneType2({value:'Other' , label:'Other'})
                        }
                        if(phone.number.length > 0){
                            setPhoneNumber_2(phone.number)
                            setPhoneType2LabelActive(true)
                        }else {
                            setPhoneNumber_2('')
                        }
                    }
                })
            }
            if(doctor.hasOwnProperty('address')){
                setValue('line1',doctor.address.line1)
                setLine1(doctor.address.line1)
                setLine2(doctor.address.line2)
                setCity(doctor.address.city)
                setZip(doctor.address.zip)
                if(doctor.address.state){
                    let selectedState = countryStateList.find(state => state.label === doctor.address.state)
                    setCountryState(selectedState)
                }
            }
            setIsEditMode(true)
        }
    },[props.doctor]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkFormDataIsChanged = async ()=>{
           setIsBtnDisabled(true)
           setIsDataModified(false)
           let doctor = props.doctor
           props.isDataChanged(false)
           if(isEditMode && Object.keys(doctor).length > 0){
               setIsBtnDisabled(true)
               // let line1 = await getValues('line1')
               if(name !== doctor.doctor_name || note !== doctor.note || (Object.keys(specialtyType).length > 0 && specialtyType.id !== doctor.speciality_id) || email !== doctor.email || linkToWebsite !== doctor.web_url || pracOrHosp !== doctor.hospital_name || (fax !=="1" && fax !== doctor.fax) || (doctor.fax !== "" && fax !== doctor.fax) || (doctor.phones === null &&(((phoneNumber !== "" && phoneNumber !== '1' ) || (phoneNumber_2 !== "" && phoneNumber_2 !== '1') ) || (Object.keys(selectedPhoneType1).length > 0 || Object.keys(selectedPhoneType2).length > 0)) ) ||(doctor.phones !== null && doctor.phones.length > 0 && ((Object.keys(selectedPhoneType1).length > 0 && doctor.phones[0].type !== selectedPhoneType1.value.toLowerCase()) || (doctor.phones[0].number !== phoneNumber && phoneNumber !== "1") || ((Object.keys(selectedPhoneType2).length > 0 && (doctor.phones[1].type !== selectedPhoneType2?.value?.toLowerCase())) || (doctor.phones[1].number !== phoneNumber_2 && phoneNumber_2 !== "1")))) || (doctor.address && Object.keys(doctor.address).length > 0 && (doctor.address.line1 !== line1 || doctor.address.line2 !== line2 || doctor.address.city !== city || doctor.address.zip !== zip || (countryState !== null && Object.keys(countryState).length > 0 && doctor.address.state !== countryState.label) ))){
                   if(name !== "" && Object.keys(specialtyType).length > 0 ){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }else {
               if(!isEditMode){
                   if(name !== '' || note !== '' || email !== '' || Object.keys(specialtyType).length > 0 || linkToWebsite !== '' || pracOrHosp !== '' || fax !== '' || Object.keys(selectedPhoneType1).length > 0 || Object.keys(selectedPhoneType2).length > 0 || phoneNumber !== '' || phoneNumber_2 !== '' || getValues('line1') !== '' || line1 !== '' || line2 !== '' || city !== '' || zip !== '' || Object.keys(countryState).length > 0){
                       if(name !== "" && Object.keys(specialtyType).length > 0 ){
                           setIsBtnDisabled(false)
                       }
                       setIsDataModified(true)
                       props.isDataChanged(true)
                   }
               }
           }
       }
        checkFormDataIsChanged()

    },[line1,getValues('line1'),line1, line2, city, zip, countryState, isEditMode, name, note, phoneNumber_2, phoneNumber, linkToWebsite, selectedPhoneType1, selectedPhoneType2, pracOrHosp, fax, email,specialtyType]) // eslint-disable-line react-hooks/exhaustive-deps


    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            props.onCancel()
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        props.onCancel()
    }

    const getPlaceFromService = async (obj)=>{
        //if (!isBrowser) return;
        const request = {
            input: obj.input,
            componentRestrictions: {country : 'us'},
            fields: ["name", "geometry", "formatted_address", "place_id"],
        };
        if(window.google){

            let service = new window.google.maps.places.AutocompleteService(document.createElement("div"));
            service.getPlacePredictions(request,(results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                    setPlaceServiceList(results)
                }
            });
        }
    }


    const handelZip = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setZip(value)
        }
    }

    const handelCity = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setCity(value)
        }
    }

    const handleChangeCountryState = e => {
        setCountryState(e);
    }

    const handelLine2 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine2(value)
        }
    }

    /*const handelLine1 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine1(value)
            setValue('line1',value)
        }
    }*/

    // const handleFaxChange = (e) => {
    //     setFax(e.target.value.trimStart())
    //     setValue('fax', e.target.value.trimStart())
    // }

    const handleFaxChange = (value) => {
        setFax(value.trimStart())
        setValue('fax', value.trimStart())
    }
    const validateFax = (e) => {
        if(fax !== '' && fax !=="1" && fax.length >= 0) {
            if(validatePhoneNumber(fax)){
                setFaxError(false)
                setFaxErrorMeesage('');
                return false;
            }else{
                setFaxError(true)
                setFaxErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_VALID_FAX);
                return true;
            }
        }
    }

    const selectAdd = (add) =>{
        if(add){
            setShowAddressDropDown(false)
            const request = {
                placeId: add.place_id,
                fields: ["name", "formatted_address", "place_id", "geometry", "address_components"],
            };
            const service = new window.google.maps.places.PlacesService(document.createElement("div"));

            service.getDetails(request, (place, status) => {
                setLine1('');
                setValue("line1", '')
                place.address_components.forEach((item) => {
                    if (item.types.length > 0) {
                        item.types.forEach((type) => {
                            if (type === "street_number") {
                                setValue("line1", item.short_name)
                                setLine1( item.short_name)
                            }
                            if (type === "route") {
                                let add = getValues("line1")
                                if (add.trim() === "") {
                                    setValue("line1", item.short_name)
                                    setLine1(item.short_name)
                                } else {
                                    setValue("line1", add + " " + item.short_name)
                                    setLine1( add + " " + item.short_name)
                                }
                            }
                            if (type === "postal_code") {
                                setValue("zip", item.short_name)
                                setZip(item.short_name)
                            }
                            if (type === "locality") {
                                setValue("city", item.short_name)
                                setCity(item.short_name)
                            }
                            /*if(type === "neighborhood"){
                                setValue("address2",item.short_name)
                            }
                            if(type === "sublocality_level_1"){
                                let add = getValues("address2")
                                if(add.trim() === ""){
                                    setValue("address2",item.short_name)
                                }else {
                                    setValue("address2",add+" "+item.short_name)
                                }

                            }*/
                            if (type === "administrative_area_level_1") {
                                let stateObj = countryStateList.filter(data => data.label === item.short_name)
                                setCountryState(stateObj[0])
                                //setValue("address2",item.short_name)
                            }
                        })
                    }
                })
            });

            let address1 = getValues("line1")
            if(address1.trim() === ""){
                setValue("line1",address1.formatted_address)
                setLine1(address1.formatted_address)
            }

        }
        setDropDownIndex(null)
    }

    useEffect(()=>{
        //console.log('isClickedOutside')
        setShowAddressDropDown(false)
    },[props.isClickedOutside])

    const onFocusEventHandler = () => {
        if(showAddressDropDown){
            setShowAddressDropDown(false)
        }
    }

    useEffect(() => {
        setFocus("name");
    }, [setFocus])

    useEffect(()=>{
        setLoading(props.loading);
        setErrors(props.error)
    },[props.loading,props.error])

    const phoneTypeList = [
        {
            value: 'select',
            label: 'Select',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#0096C7"></path>
            </svg>
        },
        {
            value: 'mobile',
            label: 'Mobile',
            icon: <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V18H12V2H2ZM1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM7 15C7.26522 15 7.51957 15.1054 7.70711 15.2929C7.89464 15.4804 8 15.7348 8 16C8 16.2652 7.89464 16.5196 7.70711 16.7071C7.51957 16.8946 7.26522 17 7 17C6.73478 17 6.48043 16.8946 6.29289 16.7071C6.10536 16.5196 6 16.2652 6 16C6 15.7348 6.10536 15.4804 6.29289 15.2929C6.48043 15.1054 6.73478 15 7 15Z" fill="#2D6CB2"></path>
            </svg>
        },
        {
            value: 'office',
            label: 'Office',
            icon:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'home',
            label: 'Home',
            icon: <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#71767A"></path>
            </svg>,
            iconActive:<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 19.6484H4C3.73478 19.6484 3.48043 19.543 3.29289 19.3555C3.10536 19.1679 3 18.9136 3 18.6484V9.64836H0L10.327 0.260357C10.5111 0.0928353 10.7511 0 11 0C11.2489 0 11.4889 0.0928353 11.673 0.260357L22 9.64836H19V18.6484C19 18.9136 18.8946 19.1679 18.7071 19.3555C18.5196 19.543 18.2652 19.6484 18 19.6484ZM5 17.6484H17V7.80536L11 2.35136L5 7.80536V17.6484ZM11 13.6484C10.337 13.6484 9.70107 13.385 9.23223 12.9161C8.76339 12.4473 8.5 11.8114 8.5 11.1484C8.5 10.4853 8.76339 9.84943 9.23223 9.38059C9.70107 8.91175 10.337 8.64836 11 8.64836C11.663 8.64836 12.2989 8.91175 12.7678 9.38059C13.2366 9.84943 13.5 10.4853 13.5 11.1484C13.5 11.8114 13.2366 12.4473 12.7678 12.9161C12.2989 13.385 11.663 13.6484 11 13.6484Z" fill="#0297C8"></path>
            </svg>
        },
        {
            value: 'other',
            label: 'Other',
            icon: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#71767A" style={{strokeWidth:2}} ></path>
            </svg>,
            iconActive: <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4655 12.3L18.2557 14.9433C18.6601 15.3264 18.6601 15.9704 18.2557 16.3535C16.2347 18.2682 13.1418 18.486 10.8724 16.8735L9.00803 15.5489C7.23388 14.2883 5.64763 12.7823 4.29674 11.0759L3.07878 9.53743C1.33598 7.33599 1.56252 4.16816 3.60084 2.23712C3.94932 1.90698 4.49513 1.90698 4.84361 2.23712L7.67816 4.92248C8.0945 5.31691 8.0945 5.97996 7.67816 6.37439L6.6293 7.36804C6.45432 7.53382 6.41112 7.79583 6.52363 8.009C7.77053 10.3715 9.74929 12.2665 12.1635 13.4101L12.3201 13.4843C12.529 13.5832 12.7772 13.5437 12.945 13.3847L14.09 12.3C14.4757 11.9346 15.0798 11.9346 15.4655 12.3Z" stroke="#0297C8" style={{strokeWidth:2}}></path>
            </svg>
        }
    ];

    const handleChangePhoneType1 = async e => {
        if(e.label === 'Select'){
            setPhoneNumber("1")
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
        }else {
            setphoneNumberError(false)
            setPhoneNumber_1ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone();
        }
        await  setSelectedPhoneType1(e);
    }
    const handleChangePhoneType2 = e => {
        if(e.label === 'Select'){
            setPhoneNumber_2("1")
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
            setIsPhone2DropdownSelect(false)
            onBlurEventHandlerPhone2();
        }
        setSelectedPhoneType2(e);
    }
    const handlePhoneField = e => {
        if(e.length > 1){
            setPhoneType1LabelActive(true)
        }else{
            setPhoneType1LabelActive(false);
        }
        setPhoneNumber(e)
    }
    const onBlurEventHandlerPhone = () => {
        if(phoneNumber.length > 1){
            const isValid =  validatePhoneNumber(phoneNumber)
            if(!isValid){
                setphoneNumberError(true)
                setPhoneNumber_1ErrorMessage('Phone number is Invalid')
            }else {
                setphoneNumberError(false)
                setPhoneNumber_1ErrorMessage('')
            }
        }
    }

    const handlePhoneField_2 = e => {
        if(e.length > 1){
            setPhoneType2LabelActive(true)
        }else{
            setPhoneType2LabelActive(false);
        }
        setPhoneNumber_2(e)
    }
    const onBlurEventHandlerPhone2 = () => {
        const isValid =  validatePhoneNumber(phoneNumber_2)
        if(phoneNumber_2.length > 1 && !isValid){
            setphoneNumber2Error(true)
            setPhoneNumber_2ErrorMessage('Phone number is Invalid')
        }else {
            setphoneNumber2Error(false)
            setPhoneNumber_2ErrorMessage('')
        }
    }

    const validatePhoneNumber = (e) => {
        const validNumber = '+'+e.toString()
        //    console.log('validNumber',validNumber, typeof validNumber)
        const phoneNumber = isValidPhoneNumber(validNumber)
        //    console.log('phoneNumber')
        return phoneNumber
    }
    const phoneTypeOptions = phoneTypeList && phoneTypeList.map(phoneTypeList =>{
        return {label:phoneTypeList.label, value: phoneTypeList.label}
    })

    const handleEmail = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setEmail(value);
        }
    }

    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }

    const handleNameChange = (e) => {
        setName(e.target.value.trimStart())
        setValue('name', e.target.value.trimStart())
    }
    const validateName = (e) => {
        if(name === '' && name.length === 0) {
            setNameError(true)
            setNameErrorMeesage('Name is required.');
            return true;
        }else{
            setNameError(false)
            setNameErrorMeesage('');
            return false;
        }
    }
    const handleNoteChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setNote(e.target.value);
            setValue('note',e.target.value)
        }
    }
    const handlpracOrHospChange = (e) => {
        setPracOrHosp(e.target.value.trimStart())
        setValue('pracOrHosp', e.target.value.trimStart())
    }
    const handlespecialtyTypeChange = async (e) => {
        await setSpecialtyType(e)
        setIsBtnDisabled(e.value === '')
    }
    const validateSpecialtyType = (e) => {
        if(specialtyType.length === 0) {
            setSpecialtyTypeError(true)
            setSpecialtyTypeErrorMessage(A12Y_CONST.ERROR_MESSAGE.ENTER_SPECIALTY_TYPE);
            return true;
        }else{
            setSpecialtyTypeError(false)
            setSpecialtyTypeErrorMessage('');
            return false;
        }
    }

    const onMouseMoveEvent = async ()=>{
        if(dropDownIndex !== null){
            setDropDownIndex(null)
        }
    }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter' && dropDownIndex !== null){
            e.preventDefault();
        }
    };

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addActivitiesWrap">
            <h2 className="titleWithSidebar noExpenseTitle marginBottom30">
                <span>{isEditMode ? A12Y_CONST.LABEL.UPDATE_DOCTOR : A12Y_CONST.LABEL.ADD_DOCTOR}</span>
            </h2>
            <div className="dependentBorderBox dependentDefauldMinHeight paddingBottom70 activityViewMain positionRelative pad_0_5">
        <div className='height100'>
          {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className="df fcol marginBottom20 height100">
                <h4 className="dependentSubTitle marginBottom10 fontSize20"><span className='vam'>{A12Y_CONST.LABEL.DOCTOR_OF} </span> <span className='txtEllipsis vam'>{props.dependent.first_name} {props.dependent.last_name}</span></h4>
                <div className="fullWidth">
                    {error ?
                        <div className='maxWidth600 marginLeftRightAuto'>
                            <ErrorMessageComponent errorMessage={props.errorMessage}/>
                        </div>
                        :
                        (props.message ?
                                <SuccessMessageComponent successMessage={props.message}/>

                                :
                                null
                        )

                    }
                </div>
                <form autoComplete="off" onKeyDown={(e) => checkKeyDown(e)} className='marginTop60 fullWidth doctorAddForm600' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow marginBottom20_600 fullWidth marginBottom30">
                        <label>Name<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <input
                                {...register("name")}
                                placeholder="Name"
                                type='text'
                                id="nsme"
                                onChange={handleNameChange}
                                maxLength='100'
                                value={name}
                                onBlur={validateName}
                                onFocus={()=>setNameErrorMeesage(false)}
                                className={`fullWidth txtName inputColum24size ${nameError && 'errorInputBorderRed'}`}
                            />
                            <div className='errorRelativeRow'>{nameError? <p className='errorMessage'>{nameErrorMeesage}</p>: null}</div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginNone600 marginBottom30 payRow">
                        <div className="width50 marginBottom20_600 paddingRight16 positionRelative">
                            <label className="lblExpType">SPECIALTY<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className={`fullWidth dependentResidencCol ${specialtyTypeError  && !specialtyType.id ? 'selectError': null} ${ specialtyType && Object.keys(specialtyType).length > 0 ? 'hasValue' : 'noValue'}`}>
                                <Controller
                                    name="specialty"
                                    value={specialtyType}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={specialtyTypeOptionsList}
                                            value={specialtyTypeOptionsList.find(c => c.id === specialtyType.id)}
                                            onChange={handlespecialtyTypeChange}
                                            onBlur={validateSpecialtyType}
                                            onFocus={() => {setSpecialtyTypeError(false);setSpecialtyTypeErrorMessage('')}}
                                            classNamePrefix={'dependentResidence'}
                                        />
                                    )}
                                    control={control}
                                    placeholder="Select"
                                />
                                <div className='errorRelativeRow'>{specialtyTypeError && !specialtyType.id ? <p className='errorMessage'>{specialtyTypeErrorMessage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 marginBottom20_600 paddingleft16 textAlignRight positionRelative">
                            <label className="practiceOrHosLabel">Practice or HOspital Group</label>
                            <div className="fullWidth">
                                <input
                                    {...register("pracOrHosp")}
                                    value={pracOrHosp}
                                    id="make"
                                    maxLength='255'
                                    onChange={handlpracOrHospChange}
                                    // onFocus={()=>setpracOrHospErrorMeesage(false)}
                                    className="fullWidth inputColum24size"
                                    placeholder="&nbsp;"
                                />
                                {/*<div className='errorRelativeRow'>{makeError? <p className='errorMessage'>{makeErrorMeesage}</p>: null}</div>*/}
                            </div>
                        </div>
                    </div>
                    {isEditMode ?
                        null
                        :
                        <div className="formRow fullWidth marginNone600 marginTop20 marginBottom30 df fcol">
                            <label>NOTE</label>
                            <div className="fullWidth">
                            <textarea
                                {...register("note")}
                                rows="3"
                                onChange={handleNoteChange}
                                maxLength='4000'
                                value={note}
                                className="fullWidth inputColum24size txtNote"
                                placeholder="Capture details about this doctor."
                            ></textarea>

                            </div>
                        </div>
                    }
                    <div className='fullWidth doctorTitleMargin_600 marginBottom20'>
                        <h4 className="dependentSubTitle fontSize20"><span className='vam'>{A12Y_CONST.LABEL.DEPENDENT_CONTACT_INFO}</span></h4>
                    </div>
                    <div className='dependentStepFormRow fullWidth marginTop30 marginNone600 positionRelative'>
                        <label className='labelCol phoneStep4'>{A12Y_CONST.LABEL.DEPENDENT_PHONE}</label>
                        <div className='fullWidth'>
                            <div className='width50 doctorRowMarginB_600 paddingRight16 selectBoxParend myProfilePhoneCol1_600'>
                                <div className={(selectedPhoneType1 && Object.keys(selectedPhoneType1).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsoutePhoneLabel positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                    <Select
                                        className='phoneTypeLabel'
                                        classNamePrefix={phoneType1LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                        placeholder="Select"
                                        value={selectedPhoneType1.label ? selectedPhoneType1 : ""}
                                        options={phoneTypeOptions}
                                        onChange={handleChangePhoneType1}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />
                                </div>
                                <div>
                                    <PhoneInput
                                        country='us'
                                        countryCodeEditable={false}
                                        disabled={Object.keys(selectedPhoneType1).length > 0 && selectedPhoneType1.label === 'Select' ? true : false}
                                        containerClass={phoneType1LabelActive ? phoneNumberError? 'errorInputBorderRedInput phoneNumber_Active':phoneNumber === "1" ? 'phone_number_placeholder_color null' : 'phoneNumber_Active'  : phoneNumberError? 'errorInputBorderRedInput': null}
                                        value={phoneNumber}
                                        onBlur={onBlurEventHandlerPhone}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        onChange={phone => {handlePhoneField(phone)}}
                                        onFocus={()=>{setphoneNumberError(false)}}

                                    />
                                </div>
                                <div className='errorRelativeRow'> {phoneNumberError ? <p className='errorMessage'>{phoneNumber_1ErrorMessage}</p> : null }</div>
                            </div>
                            <div className='width48 doctorRowMarginB_600 floatRight paddingleft16 selectBoxParend myProfilePhoneCol2_600'>
                                <div className={(selectedPhoneType2 && Object.keys(selectedPhoneType2).length > 0 ? 'hasValue ' : 'noValue ') + 'fullWidth phoneTypeCol positionAbsoutePhoneLabel positionTop maxWidth138px phoneDropDownResponsiveCol'}>
                                    <Select
                                        className='phoneTypeLabel'
                                        classNamePrefix={phoneType2LabelActive? 'phoneNumberActive phoneType1' : 'phoneType1'}
                                        placeholder="Select"
                                        value={selectedPhoneType2.label ? selectedPhoneType2 : ""}
                                        options={phoneTypeOptions}
                                        onChange={handleChangePhoneType2}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />
                                </div>
                                <div>
                                    <PhoneInput
                                        country='us'
                                        countryCodeEditable={false}
                                        disabled={Object.keys(selectedPhoneType2).length > 0 && selectedPhoneType2.label === 'Select' ? true : false}
                                        // containerClass={phoneType2LabelActive ? 'phoneNumber_Active': null}
                                        containerClass={phoneType2LabelActive ? phoneNumber2Error? 'errorInputBorderRedInput phoneNumber_Active':phoneNumber_2 === "1" ? 'phone_number_placeholder_color null' : 'phoneNumber_Active'  : phoneNumber2Error? 'errorInputBorderRedInput': null}
                                        placeholder='Select'
                                        value={phoneNumber_2}
                                        onBlur={onBlurEventHandlerPhone2}
                                        inputProps={{
                                            name: 'phone1',
                                            required: false,
                                            autoFocus: false
                                        }}
                                        onChange={phone => {handlePhoneField_2(phone)}}
                                        onFocus={()=>{
                                            setphoneNumber2Error(false)
                                            setIsPhone2DropdownSelect(false)
                                        }}
                                    />
                                </div>
                                <div className='errorRelativeRow'> {phoneNumber2Error || isPhone2DropdownSelect? <p className='errorMessage'>{phoneNumber_2ErrorMessage}</p> : null }</div>
                            </div>

                        </div>
                    </div>
                    <div className='dependentStepFormRow fullWidth marginTop30 marginNone600  positionRelative'>
                        <label className='labelCol emailStep4'>{A12Y_CONST.LABEL.DEPENDENT_EMAIL}</label>
                        <div className='doctorRowMarginB_600 fullWidth'>
                            <input
                                {...register('email',{
                                    pattern:{
                                        //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                        //eslint-disable-next-line
                                        value:/^\w+([\+.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_USERNAME_INVALID
                                    }
                                })}
                                placeholder='name@example.com'
                                className={errors.email ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                type='text'
                                id='displayName'
                                autoComplete="none"
                                onChange={(e) => handleEmail(e.target.value)}
                                value={email}
                                onFocus={()=>{
                                    setError("email", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div className='errorRelativeRow'> {errors.email && <p className='errorMessage'>{errors.email.message}</p>}</div>
                        </div>
                    </div>
                    <div className='dependentStepFormRow fullWidth marginTop30 marginNone600 doctorRowMarginB_600 positionRelative'>
                        <label className='labelCol emailStep4'>{A12Y_CONST.LABEL.LINK_TO_WEBSITE}</label>
                        <div className='fullWidth doctorRowMarginB_600'>
                            <input
                                {...register('linkToWebsite',{
                                    pattern:{
                                        //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                        value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                        message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                    }
                                })}
                                placeholder='www.example.com'
                                className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                type='text'
                                id='displayName'
                                autoComplete="none"
                                onChange={(e) => handleLinkToWebsite(e.target.value)}
                                value={linkToWebsite}
                                onFocus={()=>{
                                    setError("linkToWebsite", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                        </div>
                    </div>
                    <div className="formRow marginTop30 marginNone600 fullWidth">
                        <label>{A12Y_CONST.LABEL.ADDRESS}</label>
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth marginBottom30 marginNone600 doctorRowMarginB_600 address1'>
                                    <input
                                        {...register('line1')}
                                        placeholder='Street Address'
                                        className={errors.line1 ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                        type='text'
                                        //value={line1}
                                        maxLength='255'

                                        onKeyUp={(evt) => {
                                            //setPlacePredictionList([])
                                            if(evt.target.value === " "){
                                                setValue('line1',evt.target.value.trim())
                                                setLine1(evt.target.value.trim())
                                            }
                                            if(evt.target.value.length > 2 ){
                                                if(evt.key === "ArrowDown" || evt.key === "ArrowUp" || evt.key === 'Enter'){
                                                    if(dropDownIndex === null){
                                                        setDropDownIndex(0)
                                                    }else {
                                                        setDropDownIndex(evt.key === "ArrowUp" && dropDownIndex !== 0 ? dropDownIndex -1 : evt.key === "ArrowDown" && placeServiceList.length-1 > dropDownIndex ? dropDownIndex +1 : dropDownIndex )
                                                        if(evt.key === 'Enter' && dropDownIndex !== null && placeServiceList.length > 0){
                                                            selectAdd(placeServiceList[dropDownIndex])
                                                        }
                                                    }

                                                }else {
                                                    setShowAddressDropDown(true)
                                                    setLine1(evt.target.value.trim())
                                                    getPlaceFromService({ input: evt.target.value });
                                                }

                                            }
                                        }}
                                        // loading={isPlacePredictionsLoading}
                                        id='line1'
                                        autoComplete="new-no-line1"
                                        //onBlur={()=>setShowAddressDropDown(false)}
                                        onFocus={()=>{
                                            setError("line1", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />

                                    {/*<div onClick={()=>{selectAdd(null)}} >Hello</div>)*/}
                                    {showAddressDropDown && placeServiceList.length > 0?
                                        <div className='addressLin1DropDown'>
                                            <div className="addressLin1DropDownInner">
                                                {placeServiceList && placeServiceList.map((item,key) =>
                                                    (item && item.description ?
                                                            <div onMouseMove={onMouseMoveEvent} className={dropDownIndex === key ? 'addressListDropDownHighlight' : dropDownIndex === null ? 'addressListDropDown addressHover' : 'addressListDropDown' } onClick={()=>{selectAdd(item)}} key={key}>{item.description}</div>
                                                            :
                                                            null
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {/*{placePredictions.map((item) => <div>{item.description}</div>)}*/}
                                    <div className='errorRelativeRow'> {errors.line1 && <p className='errorMessage'>{errors.line1.message}</p>}</div>
                                </div>
                            </div>
                    </div>
                    <div className="formRow unitMarginTop20 fullWidth">
                        <label>{A12Y_CONST.LABEL.UNIT}</label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth marginBottom30 doctorRowMarginB_600 address2'>
                                <input
                                    {...register('line2',)}
                                    placeholder='Apartment, Suite, Unit, Building'
                                    className='inputColum24size '
                                    maxLength='255'
                                    type='text'
                                    id='line2'
                                    onFocus={onFocusEventHandler}
                                    autoComplete="new-no-line2"
                                    onChange={handelLine2}
                                    value={line2}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth">
                        <label>{A12Y_CONST.LABEL.CITY}</label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth cityCountryState'>
                                <div className='width40 paddingRight35 marginBottom30 city marginNone600'>
                                    <input
                                        {...register('city',)}
                                        placeholder='City'
                                        maxLength='100'
                                        className='inputColum24size'
                                        type='text'
                                        id='city'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-no-city"
                                        onChange={handelCity}
                                        value={city}
                                    />
                                </div>
                                <div className='width60 dependentResidencCol floatLeft marginTop10 positionRelative paddingLeft35 addressRow countryState'>
                                    <label className='labelCol institutionStateLabel'>{A12Y_CONST.LABEL.STATE}</label>
                                <div className={(countryState && Object.keys(countryState).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol paddingLeft35 countryState'}>
                                    <Select
                                        // selectdisplayprops={countryState}
                                        // defaultValue={countryStateList[0]}
                                        // className='phoneTypeLabel'
                                        classNamePrefix={'dependentResidence'}
                                        placeholder="Select"
                                        maxLength='100'
                                        value={countryState.label ? countryState : ""}
                                        options={stateOptions}
                                        onChange={handleChangeCountryState}
                                        // getOptionLabel={e => (
                                        //     <div className='phoneListRow'>
                                        //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                        //     </div>
                                        // )}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'red',
                                                primary: '#0096C708',
                                            },
                                        })}
                                    />
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth unitMarginTop20">
                        <label>{A12Y_CONST.LABEL.ZIP}</label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth'>
                                <div className='width40 paddingRight35 zip'>
                                    <input
                                        {...register('zip',)}
                                        placeholder='00000'
                                        className='inputColum24size'
                                        maxLength='100'
                                        type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                        onWheel={e => e.target.blur()}
                                        id='zip'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-pincode"
                                        onChange={handelZip}
                                        value={zip}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="formRow fullWidth doctorTitleMargin_600 marginTop30">
                        <label className="lblExpType7">FAX</label>
                        <div className='width40 marginBottom30 paddingRight35'>
                            <PhoneInput
                                country='us'
                                countryCodeEditable={false}
                                containerClass={`${faxError? 'errorInputBorderRedInput phoneNumber_Active': 'form-phoneNumber_Active'} ${fax.toString().length > 1 ? ' hasValue phoneNumber_Active' : null}`}
                                {...register("fax")}
                                placeholder="+(000) 000-000"
                                value={fax}
                                tabIndex={12}
                                onBlur={validateFax}
                                inputProps={{
                                    name: 'fax',
                                    required: false,
                                    autoFocus: false
                                }}
                                onChange={fx => handleFaxChange(fx)}
                                onFocus={() => setFaxError(false)}
                            />
                            <div className='errorRelativeRow'>{faxError? <p className='errorMessage'>{faxErrorMeesage}</p>: null}</div>
                        </div>
                    </div>

                    {/*------------------ Button Actions ---------------------*/}
                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom buttonRow600" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.ADD_DOCTOR_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.ADD_DOCTOR_SAVE} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={A12Y_CONST.BUTTON_TEXT.SAVE} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
            </div>
        </div>
        </div>
    )
}

export default AddDoctorComponent
