import React,{Component} from 'react'
import { connect } from 'react-redux';
import  * as userDetailActions from '../redux/actions/userDetailActions'
import LoginForm from '../components/LoginFormComponent'
import A12Y_CONST from '../common/a12yConst'
import  * as dependentActions from '../redux/actions/dependentActions'
import {client} from "../config/axiosClient";
import LoaderFullScreen from "../components/LoaderFullScreenComponent";
import {routePathRedirect} from '../util/RedirectPathAfterLogin'

class SiginScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            errorMessage:false,
            SignUpSuccess:false,
            alreadySigndUpMessage:'',
            urlStore:'',
            loadingFullScreen:false,
            urlAuthCode:false,
            logOutUrl:''

        }
        window.document.title = A12Y_CONST.TEXT.TITLE_LOGIN
    }

    componentDidMount = async () => {
        await  this.urlGet();
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let googleCode = params.get('code');
        if(googleCode){
            this.setState({loadingFullScreen:true})
            let data = this.props.inviteCode === '' ?{'auth_code':googleCode}:{'auth_code':googleCode,invitation_uuid:this.props.inviteCode};
            await  this.setState({urlAuthCode:true})
            //window.location.href='https://deva12y.auth.us-east-1.amazoncognito.com/logout?client_id=i417ngdnfqhe05p64gu8aafo1&logout_uri=https://dev.accountabilitytech.com/login';
            this.handleSubmit(data)
            await this.props.setInviteCode('')
        }
    }
    urlGet = async () => {
        const response = await client.get(A12Y_CONST.API_ENDPOINT.LOGIN_URL);
        if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
            let url = ''
            if(this.props?.loginAndSource?.user_source === A12Y_CONST.LABEL.GOOGLE && !this.props?.loginAndSource?.login_success){
                url = response.data.sr.google_re_login_url;
            }else{
                url = response.data.sr.google_login_url;
            }
            await this.setState({urlStore:url, logOutUrl:response.data.sr.google_logout_url})
        }else {
            this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message})
            this.setState({loading:false})
        }
    }

    componentWillMount(){
        const { state } = this.props.location
        if(state?.SignUpSuccess){
            this.setState({SignUpSuccess:true})
        }else {
            this.setState({SignUpSuccess:false})
        }
        if(state?.message_already_SignedUp){
            this.setState({errorMessage:true})
            this.setState({alreadySigndUpMessage:state.message_already_SignedUp})
        }
    }

    handleSubmit = async(loginData)=> {
        this.setState({errorMessage:false})
        // let loginSourcedetails ={}
        let usrName = loginData.username ? loginData.username :'';
        let password = loginData.password ? loginData.password :'';
        let auth_code = loginData.auth_code ? this.props.inviteCode === '' ?{'auth_code':loginData.auth_code}:{'auth_code':loginData.auth_code,invitation_uuid:loginData.invitation_uuid} :{};
        if(usrName && password){
            this.setState({loading:true})
        }
        // this.props?.history?.push(this.props.screen);
        await this.props.login(usrName,password, auth_code)
        await this.urlGet()
        this.setState({loading:false})
        if(this.props.isLoggedIn){
            await this.props.trialReminder(true);
            if(Object.keys(this.props.dependent).length > 0){
                this.props.getActivityTypeList(this.props.dependent.id);
            }
            this.props.getExpenseTypeList();
            this.props.getIncomeTypeList();
            let userInfo = this.props.userState.userInfo
            if(this.props.userId !== null && userInfo.id === this.props.userId){
                if(this.props.IsExpenseCreateFromActivityLog && this.props.screen === A12Y_CONST.ROUTE.ADD_EXPENSE){
                    this.props.history.push(A12Y_CONST.ROUTE.ADD_ACTIVITY);
                }else{
                    let partRedirect = await routePathRedirect(this.props.screen)
                   if(this.props.userSubscriptionAllDetails?.subscription_details?.subscription_status === A12Y_CONST.TEXT.EXPIRED || this.props?.userSubscriptionAllDetails?.is_trial_expired || this.props.userState.userInfo.last_login_time === null){
                       this.props?.history?.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW);
                           await this.props.hadlePathBeforeLogin('')
                   }else{
                       if(this.props.pathWithoutLogin.length > 0){
                           let beforeLoginRedirect = await routePathRedirect(this.props.pathWithoutLogin)
                           this.props?.history?.push(beforeLoginRedirect);
                           await this.props.hadlePathBeforeLogin('')
                       }else{
                           this.props?.history?.push(partRedirect);
                   }
                    }
                }
            }else {
               /* await this.props.userSessionBackupUserIdDetails(userInfo.id)
                let userSubscription = this.props.userSubscription
                if(userSubscription?.subscription_status === A12Y_CONST.TEXT.EXPIRED){
                    this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_PROFILE_MY_ACCOUNT)
                }else if(userInfo.phones && userInfo.phones.length > 0 && ((Object.keys(this.props.dependent).length === 0) || (Object.keys(this.props.dependent).length > 0 && this.props.dependent.access.can_update)) && ((userSubscription === undefined) || (userSubscription !== undefined && userSubscription !== null && userSubscription.subscription_status === A12Y_CONST.TEXT.EXPIRED))){
                        this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW);
                }
                else if(userInfo.phones && userInfo.phones.length > 0 && Object.keys(this.props.dependent).length === 0){
                    this.props.history.push(A12Y_CONST.ROUTE.DEPENDENT)
                }else if(Object.keys(userInfo).length > 0 && userInfo.phones && userInfo.phones.length > 0 && this.props.dependent && Object.keys(this.props.dependent).length > 0){
                    this.props.history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW);
                }else {
                    this.props.history.push(A12Y_CONST.ROUTE.ADVOCATE_MYACCOUNT_NEW);
                }*/
                this.props.history.push(A12Y_CONST.ROUTE.DASHBOARD_VIEW);
            }
        }else {
            this.setState({errorMessage:true,loadingFullScreen:false})
            // if(auth_code){
            //     window.location.href=this.state.logOutUrl
            // }
        }
    }


    render() {
        return (
            <div className="fullWidth">
                {this.state.loadingFullScreen?
                    <LoaderFullScreen/>
                    :
                    null
                    }
                <LoginForm
                    loder={this.state.loading}
                    onSubmit={this.handleSubmit}
                    errorMessage={this.state.errorMessage}
                    message={this.state.alreadySigndUpMessage ? this.state.alreadySigndUpMessage : this.props.errMsg}
                    SignUpSuccess={this.state.SignUpSuccess}
                    url={this.state.urlStore}
                    urlAuthCode={this.state.urlAuthCode}
                    signInUrlCheck={() => {this.urlGet()}}
                >
                </LoginForm>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.userDetailReducer.isLoggedIn,
    errMsg : state.userDetailReducer.errMsg,
    userState : state.userDetailReducer.userState,
    dependent : state.dependentReducer.dependent,
    currentIncome : state.dependentReducer.currentIncome,
    currentExpense : state.dependentReducer.currentExpense,
    currentActivityLog : state.dependentReducer.currentActivityLog,
    screen : state.dependentReducer.screen,
    userId : state.dependentReducer.userId,
    IsExpenseCreateFromActivityLog : state.dependentReducer.IsExpenseCreateFromActivityLog,
    userSubscription : state.userDetailReducer.userSubscription,
    inviteCode : state.userDetailReducer.inviteCode,
    loginAndSource : state.userDetailReducer.loginAndSource,
    pathWithoutLogin : state.dependentReducer.pathWithoutLogin,
    userSubscriptionAllDetails : state.userDetailReducer.userSubscriptionAllDetails,

});

const mapDispatchToProps = dispatch => ({
    login : (username,password,authcode) => dispatch(userDetailActions.login(username,password,authcode)),
    getAllDependent : (advocate_id) => dispatch(dependentActions.getAllDependent(advocate_id)),
    userSessionBackupUserIdDetails : (userId) => dispatch(dependentActions.userSessionBackupUserIdDetails(userId)),
    getActivityTypeList : (dependentId) => dispatch(dependentActions.getActivityTypeList(dependentId)),
    getExpenseTypeList: () => dispatch(dependentActions.getExpenseTypeList()),
    getIncomeTypeList : () => dispatch(dependentActions.getIncomeTypeList()),
    trialReminder : (status) => dispatch(userDetailActions.trialReminder(status)),
    setInviteCode : (code) => dispatch(userDetailActions.setInviteCode(code)),
    hadlePathBeforeLogin : (path) => dispatch(dependentActions.hadlePathBeforeLogin(path)),

});

export default connect(mapStateToProps,mapDispatchToProps) (SiginScreen);
