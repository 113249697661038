import React from 'react'
function SuccessMessageComponent (props) {
    return (
        <div className="positionRelative fullWidth">
            <div className="successMessageWithBackground positionAbsoute">
                <div className="successIcon"><svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6875 2.1C6.0486 2.1 2.2875 5.8611 2.2875 10.5C2.2875 15.1389 6.0486 18.9 10.6875 18.9C15.3264 18.9 19.0875 15.1389 19.0875 10.5C19.0875 5.8611 15.3264 2.1 10.6875 2.1ZM0.1875 10.5C0.1875 4.70085 4.88835 0 10.6875 0C16.4866 0 21.1875 4.70085 21.1875 10.5C21.1875 16.2991 16.4866 21 10.6875 21C4.88835 21 0.1875 16.2991 0.1875 10.5ZM15.5847 7.09064C16.0184 7.47599 16.0572 8.1396 15.6718 8.5722L10.0722 14.8722C9.8727 15.0969 9.58711 15.225 9.28786 15.225C8.98756 15.225 8.70196 15.0969 8.50246 14.8722L5.70316 11.7222C5.31781 11.2896 5.35665 10.626 5.7903 10.2406C6.2229 9.85529 6.88649 9.89415 7.27184 10.3278L9.28786 12.5947L14.1032 7.1778C14.4885 6.74415 15.1521 6.70529 15.5847 7.09064Z" fill="#4D8055"></path></svg></div>
                <div className="successTextContent">{props.successMessage}</div>
            </div>
        </div>

    )
}

export default  SuccessMessageComponent;