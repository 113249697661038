import React, { Component } from 'react';
import A12Y_CONST from '../common/a12yConst'
import { connect } from 'react-redux';
import {secureClient, } from "../config/axiosClient";
import * as dependentActions from "../redux/actions/dependentActions";
import * as userDetailActions from "../redux/actions/userDetailActions";
import MedicalListComponent from '../components/MedicalListComponent'
import WelcomeComponent from "../components/WelcomeComponent";

class MedicalScreen extends Component {
    constructor(props){
        super(props)

        this.state = {
            loading: false,
            loadingMedical: false,
            loadingDoctor: false,
            error:false,
            message:'',
            errorMessage:'',
            doctorList:[],
            medicationList:[],
            tabStatus:null,
            tabType:null,
            isOverView:false,
            btnTitle:A12Y_CONST.BUTTON_TEXT.ADD_FIRST_MEDICATION

        }
    }

    componentDidMount = async () => {
        this.setState({loadingMedical:true})
        let state = { ...this.props.location.state };
        if(state?.ladingPageVisible){
            // await this.goToMedicalOverviewScreenHandler()

            // await this.props.currentTabName(currentTab)
            // this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_MEDICAL})
            // this.setState({isOverView:true})
            // this.props.updateMedicineMetaData(false)
            // this.props.updateDoctorMetaData(false)

            if(this.props?.dependent?.metadata?.medicine_present){
                await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_MEDICAL})
            }else{
                await this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.ADD_MEDOCAL_INFORMATION})
            }
            this.props.updateMedicineMetaData(false)
            this.props.updateDoctorMetaData(false)
        }
        if(state?.ladingPageVisible){
            state.ladingPageVisible = false;
        }
        if((this.props.dependent?.metadata?.medicine_present || this.props.dependent?.metadata?.doctor_present) && (!this.props.medicine_present || !this.props.doctor_present)){
            this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_MEDICAL,isOverView:true})
        }
        this.setState({tabStatus:true})
        await this.props.medicationData({});
        let tab = null;
        if (this.props.location.state && this.props.location.state.tab) {
            let state = { ...this.props.location.state };
            let tab = null;
            if(state.tab === A12Y_CONST.TEXT.DOCTOR){
                tab = A12Y_CONST.TEXT.DOCTOR
            }
            this.setState({tabType:tab})
            delete state.tab;
            this.props.history.replace({...this.props.location, state})
        }
        await this.props.getSpecialtyList(this.props.dependent.id)
        if(tab === null){
            await this.getAllMedicationList();
        }
        await this.getAllDoctorList();
    }

    hideMessage = async ()=>{
        let temp = this;
        setTimeout(function(){ temp.setState({errorMessage:null,message:'',error:false}) }, 3000);
    }
    
    onTabChangeHandler = async (tab)=>{
        if(tab){
            if(this.state.tabStatus !== tab){
               await this.setState({tabStatus:true, loadingMedical:true})
                await this.getAllMedicationList();
            }
        }else {
            if(this.state.tabStatus !== tab){
              await  this.setState({loadingDoctor:true,tabStatus:false})
                await this.getAllDoctorList();
            }
        }
    }

    getAllDoctorList = async () =>{
        await this.props.getAllDoctorList(this.props.dependent.id)
            if (!this.props.isError) {
                this.setState({doctorList:this.props.doctorList,loadingDoctor:false})
            }else {
                this.setState({error:true,errorMessage:this.props.errMsg,loadingDoctor:false})
            }
    }

    getAllMedicationList = async () =>{
        try{
           // this.setState({loading:true})
            let response = await secureClient.get(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.MEDICINES);
            if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                this.setState({medicationList:response.data.sr.medicines,loadingMedical:false})
            }else {
                this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loadingMedical:false})
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loadingMedical:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.ALL_MEDICAL_LIST,e)
        }
    }

    deletingDoctor = async (item) =>{
        try {
            if(Object.keys(item).length > 0){
                this.setState({loading:true, loadingDoctor:true  , error:false,errorMessage:''})
                let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.DOCTORS+"/"+item.id);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let filteredList = this.state.doctorList.filter(doctor => doctor.id !== item.id)
                    this.setState({doctorList:filteredList,loading:false,loadingDoctor:false})
                    await this.props.updateDoctorsList(filteredList)
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,loadingDoctor:false})
                }
                let temp = this;
                setTimeout(function(){ temp.setState({successMessage:null,error:false,errorMessage:''}) }, 3000);
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false,loadingDoctor:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DELETE_DOCTOR,e)
        }
    }

    deletingMedication = async (item) =>{
        try {
            if(Object.keys(item).length > 0){
                this.setState({loading:true})
                let response = await secureClient.delete(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.DOCTORS+"/"+item.id);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    let filteredList = this.state.medicationList.filter(medication => medication.id !== item.id)
                    this.setState({medicationList:filteredList,loading:false})
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false})
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.DELETE_MEDICATION,e)
        }
    }

    addDoctorHandler = async() =>{
        await this.props.currentMedicationData(null);
        await this.props.currentDoctorData(null)
        this.props.history.push(A12Y_CONST.ROUTE.ADD_DOCTOR);
    }

    addMedicationHandler = async() =>{
        await this.props.currentMedicationData(null);
        this.props.history.push(A12Y_CONST.ROUTE.ADD_MEDICATION);
    }
    onViewHandler = async(doctor)=>{
        await this.props.currentDoctorData(doctor)
        this.props.history.push(A12Y_CONST.ROUTE.DOCTOR_VIEW);
    }
    onViewMedicationHandler = async (medication) => {
        await this.props.currentMedicationData(medication)
        this.props.history.push(A12Y_CONST.ROUTE.MEDICATION_VIEW);
    }

    onEditDoctorHandler = async (doctor) =>{
        await this.props.currentDoctorData(doctor)
        this.props.history.push(A12Y_CONST.ROUTE.EDIT_DOCTOR);
    }

    onEditMedicationHandler = async (medication) =>{
        let previousMedication = Object.assign({}, this.props.currentMedication);
        await this.props.handlePreviousMedication(previousMedication)

        medication.isEditMode = true;
        await this.props.currentMedicationData(medication)
        await this.props.medicationData(medication);
        this.props.history.push(A12Y_CONST.ROUTE.ADD_MEDICATION);
    }

    goToMedicalOverviewScreenHandler = async (currentTab)=>{
        await this.props.currentTabName(currentTab)
        this.setState({btnTitle:A12Y_CONST.BUTTON_TEXT.BACK_TO_MEDICAL})
        this.setState({isOverView:true})
        this.props.updateMedicineMetaData(false)
        this.props.updateDoctorMetaData(false)
    }

    landingPageBtnEvent = async ()=>{
        if(this.props.dependent?.metadata?.medicine_present || this.props.dependent?.metadata?.doctor_present){
            this.setState({isOverView:false})
            let tab= null;
            this.props.updateMedicineMetaData(!this.props.medicine_present)
            this.props.updateDoctorMetaData(!this.props.doctor_present)
            if(this.props.tabName === '2'){
                tab = A12Y_CONST.TEXT.DOCTOR
                this.setState({tabType:tab})
            }else {
                this.setState({tabType:tab})
                await this.getAllMedicationList()
            }
        }else {
            await this.props.currentMedicationData(null);
            this.props.history.push(A12Y_CONST.ROUTE.ADD_MEDICATION);
        }
    }

    componentWillUnmount= async ()=> {
        if((!this.props.medicine_present || !this.props.doctor_present) && (this.props.dependent?.metadata?.medicine_present || this.props.dependent?.metadata?.doctor_present)){
            this.setState({isOverView:false})
            let tab= null;
            this.props.updateMedicineMetaData(!this.props.medicine_present)
            this.props.updateDoctorMetaData(!this.props.doctor_present)
            if(this.props.tabName === '2'){
                tab = A12Y_CONST.TEXT.DOCTOR
                this.setState({tabType:tab})
            }else {
                this.setState({tabType:tab})
                await this.getAllMedicationList()
            }
        }
    }

    render() {
        const {doctorList,loadingMedical,loadingDoctor,loading,medicationList,tabType,error,message,errorMessage} = this.state;
        return (
                <div className="fullWidth">
                        {this.props.medicine_present || this.props.doctor_present?
                            <div className="myProfileAccount animationall lightBackgroundBlueColor assetListScreenMain mainRightSidebare">
                                <div className='rightPanelWidhtSidebar'>
                                    <div className="rightPanelWidhtSidebarContainer fullWidth">
                                     <MedicalListComponent
                                     dependent={this.props.dependent}
                                     addDoctorScreen={this.addDoctorHandler}
                                     addMedication={this.addMedicationHandler}
                                     onView={this.onViewHandler}
                                     onViewMedication={this.onViewMedicationHandler}
                                     onTabChange={this.onTabChangeHandler}
                                     doctorList={doctorList}
                                     userInfo={this.props.userState.userInfo}
                                     medicationList={medicationList}
                                     loadingDoctor={loadingDoctor}
                                     loading={loading}
                                     loadingMedical={loadingMedical}
                                     onDeleteDoctor={this.deletingDoctor}
                                     onEditDoctor={this.onEditDoctorHandler}
                                     onEditMedication={this.onEditMedicationHandler}
                                     tabType={tabType}
                                     error={error}
                                     message={message}
                                     errorMessage={errorMessage}
                                     goToMedicalOverviewScreen={this.goToMedicalOverviewScreenHandler}
                                     />
                                    </div>
                                </div>
                            </div>
                            :
                                // <NoMedical
                                //     dependent={this.props.dependent}
                                //     onCreate={this.addMedicationHandler}
                                // />
                                <div className="myProfileAccount animationall lightBackgroundBlueColor activityListScreenMain mainRightSidebare welcomeScreen">
                                    <div className='rightPanelWidhtSidebar bgWhite fullWidth marginTop12'>
                                        <div className="rightPanelWidhtSidebarContainer welcomeBg fullWidth marginNone">
                                            <WelcomeComponent
                                                screenName={A12Y_CONST.LABEL.MEDICAL}
                                                title={A12Y_CONST.TEXT.MEDICAL_DETAILS_AT}
                                                description1={A12Y_CONST.TEXT.ADD_PRESCRIPTIONS_MEDICATIONS}
                                                description2={A12Y_CONST.TEXT.ADD_A_MEDICATION}
                                                dependent={this.props.dependent}
                                                onCreate={this.landingPageBtnEvent}
                                                onCreateDoctorScreen={this.addDoctorHandler}
                                                welcomeScreenType={A12Y_CONST.LABEL.MEDICAL}
                                                isOverview={this.state.isOverView}
                                                buttonTitle={this.state.btnTitle}
                                            />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
        )
    }
}


const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependentList : state.dependentReducer.dependentList,
    dependent : state.dependentReducer.dependent,
    doctorSpecialistTypeList : state.dependentReducer.doctorSpecialistTypeList,
    currentDoctor : state.dependentReducer.currentDoctor,
    currentMedication : state.dependentReducer.currentMedication,
    doctorList : state.dependentReducer.doctorList,
    medicine_present : state.dependentReducer.medicine_present,
    doctor_present : state.dependentReducer.doctor_present,
    medication_data : state.dependentReducer.medication_data,
    userState : state.userDetailReducer.userState,
    tabName: state.dependentReducer.tabName,
    previousCurrentMedication : state.dependentReducer.previousCurrentMedication,

});

const mapDispatchToProps = dispatch => ({
    updateMetaData : (income_present,expense_present,activity_present,asset_present) => dispatch(dependentActions.updateMetaData(income_present,expense_present,activity_present,asset_present)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
    getSpecialtyList: (dependentId) => dispatch(dependentActions.getSpecialtyList(dependentId)),
    currentDoctorData: (doctor) => dispatch(dependentActions.currentDoctorData(doctor)),
    currentMedicationData: (medication) => dispatch(dependentActions.currentMedicationData(medication)),
    medicationData: (medication) => dispatch(dependentActions.medicationData(medication)),
    getAllDoctorList: (dependentId) => dispatch(dependentActions.getAllDoctorList(dependentId)),
    updateDoctorsList: (doctors) => dispatch(dependentActions.updateDoctorsList(doctors)),
    updateMedicineMetaData: (medicine_present) => dispatch(dependentActions.updateMedicineMetaData(medicine_present)),
    updateDoctorMetaData: (doctor_present) => dispatch(dependentActions.updateDoctorMetaData(doctor_present)),
    currentTabName : (tabName) => dispatch(dependentActions.currentTabName(tabName)),
    handlePreviousMedication: (medication) => dispatch(dependentActions.handlePreviousMedication(medication)),
});

export default connect(mapStateToProps, mapDispatchToProps) (MedicalScreen);
