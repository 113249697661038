import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {CONFIG} from "./apiConfig";
import {store} from "../redux/store";
const {dispatch} = store;


export const client = axios.create({
    baseURL:CONFIG.baseUrl,
    headers:{
        Accept: 'application/json'
    }
});

export const secureClient = axios.create({
    baseURL:CONFIG.baseUrl,
    headers:{
        Accept: 'application/json'
    }
});


secureClient.interceptors.request.use((config) => {
        let tokens = store.getState().userDetailReducer.userState;
        if(tokens && tokens.access_token) {
            config.headers['Authorization'] = tokens.access_token;
            config.headers['id-token'] = tokens.id_token;
            return config;
        } else {
            dispatch({type: 'UNAUTHORIZED'})
        }
    }, (error) => {
        return Promise.reject(error);
    }
);

const refreshAuthLogic = async (failedRequest) => {
    let tokens = store.getState().userDetailReducer.userState;
    let refresh_token = null
    if(tokens && tokens.refresh_token) {
        refresh_token = tokens.refresh_token
    }
    let requestObj = {
        refresh_token: refresh_token
    }

    try {
        let refreshResponse = await axios.post(CONFIG.baseUrl+'refresh_token', requestObj, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        let refreshedToken = {
            "accessToken" : refreshResponse.data.sr.token.access_token,
            "refreshToken" :refreshResponse.data.sr.token.refresh_token,
            "idToken" :refreshResponse.data.sr.token.id_token,
        }
        dispatch({ type: 'TOKEN_REFRESHED', refreshedToken})
      //  console.log('refreshResponse.........',refreshResponse.data.sr.token.access_token)
       // console.log('failedRequest...',failedRequest)
       /* failedRequest.response.config.headers['Authorization'] = refreshResponse.data.sr.token.access_token;
        return Promise.resolve();*/
    }catch (e) {
        console.error("refresh toke failed :: ", e);
    }
};

createAuthRefreshInterceptor(secureClient, refreshAuthLogic);

const UNAUTHORIZED = 401;
secureClient.interceptors.response.use(
    response => response,
    error => {
        if(error?.response?.status){
            const {status} = error?.response;
            if (status === UNAUTHORIZED) {
                // Send user back to log in
                console.log('[axiosDevAuth] send user back to log in page. Refresh failed.')
                // going to have to test this on live website, wont be able to
                // open sign in window from cloud9 url
                //window.open('https://app.contactninjadev.us/')
                dispatch({type: 'UNAUTHORIZED'})
            }
        }
        return Promise.reject(error);
    }
);
