import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import Loading from './LoadingComponent'
import 'react-phone-input-2/lib/style.css'
import { Check } from '@material-ui/icons';
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import EditChangesRemovePopup from '../components/EditChangesRemovePopup'
import SuccessMessageComponent from '../components/SuccessMessageComponent'

import A12Y_CONST from '../common/a12yConst'

const MyAccountPasswordComponent = props => {

    const [error, setErrors] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [passwordConditionBox, setPasswordConditionBox] = useState(false);
    /* eslint-disable no-unused-vars */
    const [passwordSuccessMessage, setPasswordSuccessMessage] = useState(false);
    const [passwordCondition, setPasswordCondition] = useState([
        {
            type:'lowercaseCharacter',
            name: 'One lowercase character',
            status: false
        },
        {
            type:'specialCharacter',
            name: 'One special character',
            status: false
        },
        {
            type:'uppercaseCharacter',
            name: 'One uppercase character',
            status: false
        },
        {
            type:'minimumCharacters',
            name: '8 characters minimum',
            status: false
        },
        {
            type:'numberOnly',
            name: 'Should be one number',
            status: false
        }
    ])
    /* eslint-disable no-unused-vars */
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [passwordErrorHide, setaPsswordErrorHide] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);


    const {register,handleSubmit, setFocus, setError,formState:{ errors, isDirty, isValid, isSubmitting},reset,getValues} = useForm({mode:"all"});

    const onSubmit = async (data) => {
        setIsBtnDisabled(true)
        await  props.onSubmit(data)
        setIsBtnDisabled(false)
    };

    useEffect(()=>{
        setIsBtnDisabled(true)
        if(password !== '' && oldPassword !== ''){
            setIsBtnDisabled(false)
        }
    },[password,oldPassword])

    useEffect(() => {
        setFocus("oldPassword");
    }, [setFocus])

    const handleChangeOldPassword = (event) => {
        setOldPassword(event.target.value);
    }

    useEffect(()=>{
        setErrors(props.error)
        setLoading(props.loading);
        if(props.resetFields){
            passwordCondition.forEach((item, index) => {
                    item.status = false
            })
            reset({
                oldPassword:'',
                password:''
            })
            setPassword('');
            setOldPassword('')
        }

    },[props.error, props.loading, props.resetFields]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangePassword = (event) => {
        if(event.target.value === " "){
            return
        }else{
            let count = 0;
            if(event.target.value.length > 0){
                setPasswordConditionBox(true)
            }
            else{
                setPasswordConditionBox(false)
            }
            let lowercaseCharacter = /[a-z]/g;
            if(event.target.value.match(lowercaseCharacter)) {
                validatePassword('lowercaseCharacter', true)
                count++
            } else {
                validatePassword('lowercaseCharacter', false)
            }
            let uppercaseCharacter = /[A-Z]/g;
            if(event.target.value.match(uppercaseCharacter)) {
                validatePassword('uppercaseCharacter', true)
                count++
            } else {
                validatePassword('uppercaseCharacter', false)
            }
            let numberOnly = /[0-9]/g;
            if(event.target.value.match(numberOnly)) {
                validatePassword('numberOnly', true)
                count++
            } else {
                validatePassword('numberOnly', false)
            }
            //eslint-disable-next-line
            let specialCharacter = /[ `!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/? ~]/;
            if(event.target.value.match(specialCharacter)) {
                validatePassword('specialCharacter', true)
                count++
            } else {
                validatePassword('specialCharacter', false)
            }
            if(event.target.value.length >= 8) {
                validatePassword('minimumCharacters', true)
                count++
            } else {
                validatePassword('minimumCharacters', false)
            }
            if(count === passwordCondition.length) {
                setPasswordSuccessMessage(true);
                setaPsswordErrorHide(false)
            }else{
                setPasswordSuccessMessage(false);
                setaPsswordErrorHide(true)
            }
            setPassword(event.target.value);
        }
    };

    const validatePassword = (type, status) => {
        passwordCondition.forEach((item, index) => {
            if(item.type === type){
                item.status = status
            }
        })

    }
    const togglePasswordVisiblity = () => {
        document.getElementById('userPassword').focus()
        setPasswordShown(passwordShown ? false : true);
    };
    const toggleOldPasswordVisiblity = () => {
        document.getElementById('oldPassword').focus()
        setOldPasswordShown(oldPasswordShown ? false : true);
    };
    const handeBlankSpace = (e) => {
        if(e.key === " "){
            e.preventDefault();
        }
    }

    const onCancel =()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }else {
            props.onCancel()
        }
    }

    useEffect(()=>{
        setIsDataModified(false);
        props.isDataChanged(false)
        if(oldPassword !== "" || password !== ""){
            setIsDataModified(true);
            props.isDataChanged(true)
        }
    },[oldPassword,password]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        passwordCondition.forEach((item, index) => {
            item.status = false
        })
        reset({
            oldPassword:'',
            password:''
        })
        setPassword('');
        setOldPassword('')
        setIsPopUpVisible(false);
        props.isDataChanged(false)
        props.onCancel()
    }

    return (

        <div className='myAccountPersonalInfo myAccountPassordScreen paddingTop20'>
            {error ?
                <div className='maxWidth600 marginLeftRightAuto'>
                    {/*<div className="errorMessageWithBackground">*/}
                        {/*<div className="exclamationErrorIcon"><img src={exclamationImg}/></div>*/}
                        {/*<div className='errorTextContent'>{props.errorMessage}</div>*/}
                    {/*</div>*/}
                    <ErrorMessageComponent errorMessage={props.errorMessage}/>
                </div>
                :
                (props.message ?
                        <SuccessMessageComponent successMessage={props.message}/>
                        :
                        null
                )

            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <form onSubmit={handleSubmit(onSubmit)} className='fullWidth marginTop30 myProfile_security'>
                <div className="fullWidth">
                    <h3 className='paddingLeft35 marginTop20 myAccountTitle'>{A12Y_CONST.TEXT.SIGN_IN_SECURITY}</h3>
                </div>
                <div className='personalInfoNameRow passwordLabel margignBottom768_20 fullWidth myProfile_password_email600'>
                    <label>{A12Y_CONST.LABEL.DEPENDENT_EMAIL}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className='fullWidth firstLastNameSpace passwordTabUsername '>
                        {props.userInfo.email}
                    </div>
                </div>
                <div className='personalInfoNameRow passwordLabel fullWidth'>
                    <label className='passordLable2 floatLeft width192'>{A12Y_CONST.LABEL.PASSWORD}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className='fullWidth firstLastNameSpace'>
                           <div className="fullWidth positionRelative margignBottom768_48 myProfile_password_oldPassword600">
                                <input
                                    {...register('oldPassword',{ required:A12Y_CONST.ERROR_MESSAGE.OLD_PASSWORD_REQUIRED})}
                                    id='oldPassword'
                                    placeholder='Old Password'
                                    className={errors.oldPassword ? 'inputColum errorInputBorderRed PaddingPasswordIcon': 'inputColum PaddingPasswordIcon'}
                                    onChange={handleChangeOldPassword}
                                    type={oldPasswordShown ? "text" : "password"}
                                    value={oldPassword}
                                    onKeyDown={e => handeBlankSpace(e)}
                                    onFocus={()=>{
                                        setError("oldPassword", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />
                                <div id={A12Y_CONST.BUTTON_ID.PASSWORD_SHOW_HIDE} className="passwordTextShow" onClick={toggleOldPasswordVisiblity}>
                                    {
                                        oldPasswordShown ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                                <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#A9AEB1"/>
                                                <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#A9AEB1"/>
                                            </svg>
                                            :
                                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#A9AEB1"></path>
                                                <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#A9AEB1"></path>
                                            </svg>
                                    }

                                </div>
                               {errors.oldPassword? <div className='errorRelativeRow'> {errors.oldPassword && <p className='errorMessage'>{errors.oldPassword.message}</p>}</div> : null}
                            </div>
                    </div>
                </div>
                <div className='personalInfoNameRow passwordLabel fullWidth'>
                    <label className='passordLable floatLeft width192'>{A12Y_CONST.LABEL.NEW_PASSWORD}<span className='astrciBlue'>{A12Y_CONST.LABEL.STAR}</span></label>
                    <div className='fullWidth firstLastNameSpace'>
                        <div className={'fullWidth positionRelative myProfile_password_Newpassword600'}>
                            <input
                                {...register('password', { required: A12Y_CONST.ERROR_MESSAGE.SIGNUP_PASSWORD_REQUIRED,
                                    pattern:{
                                        value:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$/,
                                        message:A12Y_CONST.ERROR_MESSAGE.SIGNUP_PASSWORD_INVALID
                                    }
                                })}
                                id='userPassword'
                                placeholder='New Password'
                                className={errors.password ? 'inputColum errorInputBorderRed PaddingPasswordIcon': 'inputColum PaddingPasswordIcon'}
                                onChange={handleChangePassword}
                                type={passwordShown ? "text" : "password"}
                                value={password}
                                onKeyDown={e => handeBlankSpace(e)}
                                onFocus={()=>{
                                    setError("password", {
                                        type: "manual",
                                        message: "",
                                    });
                                }}
                            />
                            <div id={A12Y_CONST.BUTTON_ID.NEW_PASSWORD_SHOW_HIDE} className="passwordTextShow" onClick={togglePasswordVisiblity}>
                                {
                                    passwordShown ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                            <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#A9AEB1"/>
                                            <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#A9AEB1"/>
                                        </svg>
                                        :
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#A9AEB1"></path>
                                            <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#A9AEB1"></path>
                                        </svg>
                                }

                            </div>
                            {passwordErrorHide || !passwordConditionBox? <div className='errorRelativeRow'> {errors.password && <p className='errorMessage'>{errors.password.message}</p>}</div> : null}
                        </div>
                        {passwordConditionBox ?
                            <div className="passwordMatchBox fullWidth">
                                <ul>
                                    {
                                        passwordCondition.map((item, index) => {
                                            return(
                                                <li key={index} className='passowrdListOfCondition'>
                                                    <span className='rightIocn'><Check className={item.status ? 'checkIconActive':'checkIcon'}/></span>
                                                    <span className='passowrdListOfConditionText'>{item.name}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            null
                        }
                        {/*{passwordConditionBox && passwordSuccessMessage?
                                <div className="successMessageWithBackground marginTopBottom10">
                                    <div className="successIcon"><svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6875 2.1C6.0486 2.1 2.2875 5.8611 2.2875 10.5C2.2875 15.1389 6.0486 18.9 10.6875 18.9C15.3264 18.9 19.0875 15.1389 19.0875 10.5C19.0875 5.8611 15.3264 2.1 10.6875 2.1ZM0.1875 10.5C0.1875 4.70085 4.88835 0 10.6875 0C16.4866 0 21.1875 4.70085 21.1875 10.5C21.1875 16.2991 16.4866 21 10.6875 21C4.88835 21 0.1875 16.2991 0.1875 10.5ZM15.5847 7.09064C16.0184 7.47599 16.0572 8.1396 15.6718 8.5722L10.0722 14.8722C9.8727 15.0969 9.58711 15.225 9.28786 15.225C8.98756 15.225 8.70196 15.0969 8.50246 14.8722L5.70316 11.7222C5.31781 11.2896 5.35665 10.626 5.7903 10.2406C6.2229 9.85529 6.88649 9.89415 7.27184 10.3278L9.28786 12.5947L14.1032 7.1778C14.4885 6.74415 15.1521 6.70529 15.5847 7.09064Z" fill="#4D8055"></path>
                                    </svg></div>
                                    <div className='successTextContent'>{A12Y_CONST.SUCCESS_MESSAGE.SIGNUP_YOUR_PASSWORD}</div>
                                </div>
                                :
                                null

                            }*/}
                    </div>
                </div>
                <div className='passwordLabel positionAbsouteOnly bottom40 bottom_70_600 paddingRight140 fullWidth right'>
                    <div className='fullWidth marginTop30 passwordButtonRow textAlignRight displayFlexColJC-end'>
                         <div className='widthAuto marginRight30 changePasswordButton'><input id={A12Y_CONST.BUTTON_ID.MY_ACCOUNT_CANCEL} className={isSubmitting ? "cancelButton1" :'cancelButton1'} disabled={isSubmitting} onClick={onCancel}  type="button" value='Cancel'/></div>
                        <div className='widthAuto'>
                        <div className='loaderSpinner'>
                            {loading ?
                                <Loading />
                                :
                                null
                            }
                        <input id={A12Y_CONST.BUTTON_ID.MY_ACCOUNT_CHANGE_PASSWORD} className={isBtnDisabled ? 'saveButtonDisable' : "saveButton"} disabled={isBtnDisabled}  type="submit" value='Change Password'/></div>
                        </div>
                    </div>
                </div>

            </form>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>

    );
};

export default MyAccountPasswordComponent;
