import React, { useState, useEffect } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import {useLocation} from "react-router-dom";
import Loading from '../components/LoadingComponent';
import stateList from '../common/stateList.json'
import ErrorMessageComponent from "./ErrorMessageComponent";
import SuccessMessageComponent from "./SuccessMessageComponent";
import EditChangesRemovePopup from "./EditChangesRemovePopup";


function InstitutionAddressComponent(props) {
    const [loading, setLoading] = useState(false);
    const [error, setErrors] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const { register, handleSubmit, getValues, setFocus, setValue, setError, formState: { errors, isSubmitting } } = useForm({ mode: "onBlur" });
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [institutionName, setInstitutionName] = useState('');
    const [institutionNameError, setInstitutionNameError] = useState(false);
    const [institutionNameErrorMeesage, setInstitutionNameErrorMeesage] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [countryState, setCountryState]  = useState({});
    const [showAddressDropDown, setShowAddressDropDown] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [line2, setLine2] = useState('');
    const [line1, setLine1] = useState('');
    const [dropDownIndex, setDropDownIndex] = useState(null);
    const [placeServiceList, setPlaceServiceList] = useState({});
    const location = useLocation();

    /*const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: CONFIG.GOOGLE_PLACES_KEY,
        options: {
            componentRestrictions: { country: "us" },
        },
    });*/

    const countryStateList = stateList;
    const stateOptions = countryStateList && countryStateList.map(state =>{
        return {label: state.label, value: state.label}
    })

    useEffect(() => {
        setFocus("institutionName");
    }, [setFocus])

    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
    }, [props.error, props.loading, props.loaderFullScreen])

    useEffect(()=>{
        if(props.editMode){
            let asset = props.currentAsset;
            if(asset && Object.keys(asset).length > 0 && Object.keys(asset.institute).length > 0 && Object.keys(asset.institute.address).length > 0){
                let state = asset.institute.address.state
                let selectedState = countryStateList.find(item=>item.label === state)
                if(selectedState !== null && selectedState !== undefined){
                    setCountryState(selectedState)
                }
                setInstitutionName(asset.institute.institution_name)
                setValue('institutionName',asset.institute.institution_name)
                setValue('line1',asset.institute.address.line1)
                setLine1(asset.institute.address.line1)
                setLine2(asset.institute.address.line2)
                setCity(asset.institute.address.city)
                setZip(asset.institute.address.zip)
            }
            setIsEditMode(true)
        }
    },[props.editMode]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect( ()=>{
        setIsBtnDisabled(true)
        setIsDataModified(false)
        props.isDataChanged(false)
        if(isEditMode && Object.keys(props.currentAsset).length > 0 && Object.keys(props.currentAsset.institute).length > 0 && Object.keys(props.currentAsset.institute.address).length > 0){
            let asset = props.currentAsset;
           // setIsBtnDisabled(true)
            //let line1 = await getValues('line1')
            if(institutionName !== asset.institute.institution_name || line1 !== asset.institute.address.line1 || line2 !== asset.institute.address.line2 || city !== asset.institute.address.city || zip !== asset.institute.address.zip || (Object.keys(countryState).length > 0 && countryState.label !== asset.institute.address.state)){
                if(institutionName !== "" && line1 !== "" && line1?.length > 2 ){
                    setIsBtnDisabled(false);
                }
                setIsDataModified(true)
                props.isDataChanged(true)
            }
        }else {
            if(!props.editMode){
                if(getValues('line1') !== '' || institutionName !== '' || line2 !== '' || city !== '' || zip !== '' || Object.keys(countryState).length > 0){
                   if(institutionName !== "" && line1 !== "" && line1?.length > 2){
                       setIsBtnDisabled(false);
                   }
                    setIsDataModified(true)
                    props.isDataChanged(true)
                }
            }
        }
    },[institutionName, line1, getValues('line1'), line2, city, zip, countryState, isEditMode]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (formData)=>{
        let isInstitutionNameValid =  validateInstitutionName()
        if(line1 === '' || getValues('line1') === ''){
            setError("line1", {
                type: "manual",
                message: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED,
            });
            return
        }
        let obj = {
            address:{
                line1 : line1,
                line2 : line2,
                city : city,
                state : Object.keys(countryState).length > 0 ? countryState.label : '',
                zip : zip,
            },
            institution_name : institutionName,
        }
        if(!isInstitutionNameValid){
            props.isDataChanged(false)
            if(props.editMode){
                obj.id = props.currentAsset.institute.id
                props.onUpdate(obj)
            }else {
                props.onSubmit(obj)
            }
        }
    }
   /* const onCancel = async()=>{
        props.onCancel()
    }
*/
    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            props.onCancel()
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        props.onCancel()
    }

    const getPlaceFromService = async (obj)=>{
        //if (!isBrowser) return;
        const request = {
            input: obj.input,
            componentRestrictions: {country : 'us'},
            fields: ["name", "geometry", "formatted_address", "place_id"],
        };
        if(window.google){

            let service = new window.google.maps.places.AutocompleteService(document.createElement("div"));
            service.getPlacePredictions(request,(results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                    setPlaceServiceList(results)
                }
            });
        }
    }

    const validateInstitutionName = () =>{
        if(institutionName === '' && institutionName.length === 0) {
            setInstitutionNameError(true)
            setInstitutionNameErrorMeesage(A12Y_CONST.ERROR_MESSAGE.ENTER_INSTITUTION_NAME);
            return true;
        }else{
            setInstitutionNameError(false)
            setInstitutionNameErrorMeesage('');
            return false;
        }
    }
    const handleInstitutionName = (e) =>{
        setValue('institutionName',e.target.value.trimStart())
        setInstitutionName(e.target.value.trimStart())
    }

    const handelZip = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setZip(value)
        }
    }

    const handelCity = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setCity(value)
        }
    }

    const handleChangeCountryState = e => {
        setCountryState(e);
    }

    const handelLine2 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine2(value)
        }
    }

    const handelLine1 = (e)=>{
        let value = e.target.value
        if(value === " "){
            e.preventDefault()
        }else {
            setLine1(value)
            setValue("line1", value)
        }
    }

    const selectAdd = (add) =>{
        if(add){
            setShowAddressDropDown(false)
            const request = {
                placeId: add.place_id,
                fields: ["name", "formatted_address", "place_id", "geometry", "address_components"],
            };
            const service = new window.google.maps.places.PlacesService(document.createElement("div"));

            service.getDetails(request, (place, status) => {
                setLine1('');
                setValue("line1", '')
                place.address_components.forEach((item) => {
                    if (item.types.length > 0) {
                        item.types.forEach((type) => {
                            if (type === "street_number") {
                                setValue("line1", item.short_name)
                            }
                            if (type === "route") {
                                let add = getValues("line1")
                                if (add.trim() === "") {
                                    setValue("line1", item.short_name)
                                    setLine1(item.short_name)
                                } else {
                                    setValue("line1", add + " " + item.short_name)
                                    setLine1(add + " " + item.short_name)
                                }
                            }
                            if (type === "postal_code") {
                                setValue("zip", item.short_name)
                                setZip(item.short_name)
                            }
                            if (type === "locality") {
                                setValue("city", item.short_name)
                                setCity(item.short_name)
                            }
                            /*if(type === "neighborhood"){
                                setValue("address2",item.short_name)
                            }
                            if(type === "sublocality_level_1"){
                                let add = getValues("address2")
                                if(add.trim() === ""){
                                    setValue("address2",item.short_name)
                                }else {
                                    setValue("address2",add+" "+item.short_name)
                                }

                            }*/
                            if (type === "administrative_area_level_1") {
                                let stateObj = countryStateList.filter(data => data.label === item.short_name)
                                setCountryState(stateObj[0])
                                //setValue("address2",item.short_name)
                            }
                        })
                    }
                })
            });

            let address1 = getValues("line1")
            if(address1.trim() === ""){
                setValue("line1",address1.formatted_address)
                setLine1(address1.formatted_address)
            }

        }
        /*let addObj = {
            "address_line1" : getValues('address1'),
            "address_line2" : getValues('address2'),
            "city" : getValues('city'),
            "state" : countryState ? countryState.label : '',
            "zip" : getValues('zipcode'),
            "country_code" : "USA"

        }
        setSelectedAdd(addObj)*/
        setDropDownIndex(null)
    }

    useEffect(()=>{
        setShowAddressDropDown(false)
    },[props.isClickedOutside])

    const onFocusEventHandler = () => {
        if(showAddressDropDown){
            setShowAddressDropDown(false)
        }
    }

    const onMouseMoveEvent = async ()=>{
        if(dropDownIndex !== null){
            setDropDownIndex(null)
        }
    }

    const checkKeyDown = (e) => {
        if (e.code === 'Enter' && dropDownIndex !== null){
            e.preventDefault();
        }
    };


    /*useEffect(() => {
        // fetch place details for the first element in placePredictions array
        if (placePredictions.length){
            placePredictions.map((place,key) => {placesService?.getDetails(
                {
                    placeId: place.place_id,
                },
                (placeDetails) =>{
                    setPlacePredictionList(oldArray => [...oldArray, placeDetails]);
                }
            );});
        }
    }, [placePredictions]);*/
    return (
        <div className='height100 institutionInventoryScreen'>
           {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <div className="df fcol marginBottom20 height100">
                <h4 className="dependentSubTitle marginBottom10"><span className='vam'>{!props.fromViewScreen? props.titlePrefix : null} </span> <span className='txtEllipsis vam'>{(!props.fromViewScreen  && !props.editMode )|| props.title === A12Y_CONST.LABEL.BURIAL_PROVIDER ? props.dependent.nick_name :''}</span><span className='vam'>{(!props.fromViewScreen  && !props.editMode) || props.title === A12Y_CONST.LABEL.BURIAL_PROVIDER ?A12Y_CONST.LABEL.APOSTROPHE_S:''} {props.title}{props.editMode || location.pathname === A12Y_CONST.ROUTE.ADD_INSTITUTION || props.title === A12Y_CONST.LABEL.INVESTMENT_ACCOUNT || props.title === A12Y_CONST.LABEL.LOCATION || props.title === A12Y_CONST.LABEL.RETIREMENT_ACCOUNT  ?'':'?'}</span> </h4>
                <div className="fullWidth">
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
                </div>
                <form autoComplete="off" onKeyDown={(e) => checkKeyDown(e)} className='marginTop30 fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow fullWidth formRowGap formRowMarginBottom10">
                        <label className="lblExpType institutionNameLabel">{props.title === A12Y_CONST.LABEL.BUSINESS_INTEREST || props.title === A12Y_CONST.LABEL.BUSINESS_DETAILS ? A12Y_CONST.LABEL.COMPANY_NAME : A12Y_CONST.LABEL.INSTITUTION}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className="fullWidth">
                            <div className=' fullWidth positionRelative'>
                                <div className="fullWidth fullWidth marginBottom30 marginTop10">
                                    <input
                                        {...register("institutionName",{required:true})}
                                        value={institutionName}
                                        id="institutionName"
                                        maxLength='255'
                                        onBlur={validateInstitutionName}
                                        onChange={handleInstitutionName}
                                        onFocus={()=>setInstitutionNameError(false)}
                                        className={`fullWidth inputColum24size ${institutionNameError && 'errorInputBorderRed'}`}
                                        placeholder={props.title === A12Y_CONST.LABEL.BUSINESS_INTEREST || props.title === A12Y_CONST.LABEL.BUSINESS_DETAILS ? "Company Name" : "Institution Name"}
                                    />
                                    <div className='errorRelativeRow'>{institutionNameError? <p className='errorMessage'>{institutionNameErrorMeesage}</p>: null}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth">
                        <label className="lblExpType">{A12Y_CONST.LABEL.GAURDIAN_PROFILE_PERSONAL_INFO_ADDRESS}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className=' fullWidth positionRelative'>
                            <div className='fullWidth marginBottom30 address1'>
                                <input
                                    {...register('line1', { required: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED })}
                                    placeholder='Street Address'
                                    className={errors.line1 && line1?.length === 0 ? 'inputColum24size errorInputBorderRed': 'inputColum24size'}
                                    type='text'
                                    maxLength='255'
                                    onChange={handelLine1}
                                    onKeyUp={(evt) => {
                                        //setPlacePredictionList([])
                                        if(evt.target.value === " "){
                                            setValue('line1',evt.target.value.trim())
                                            setLine1(evt.target.value.trim())
                                        }
                                        if(evt.target.value.length > 2 ){
                                            if(evt.key === "ArrowDown" || evt.key === "ArrowUp" || evt.key === 'Enter'){
                                                if(dropDownIndex === null){
                                                    setDropDownIndex(0)
                                                }else {
                                                    setDropDownIndex(evt.key === "ArrowUp" && dropDownIndex !== 0 ? dropDownIndex -1 : evt.key === "ArrowDown" && placeServiceList.length-1 > dropDownIndex ? dropDownIndex +1 : dropDownIndex )
                                                    if(evt.key === 'Enter' && dropDownIndex !== null && placeServiceList.length > 0){
                                                        selectAdd(placeServiceList[dropDownIndex])
                                                    }
                                                }

                                            }else {
                                                setLine1(evt.target.value.trim())
                                                setShowAddressDropDown(true)
                                                getPlaceFromService({ input: evt.target.value });
                                            }
                                        }
                                    }}
                                    // loading={isPlacePredictionsLoading}
                                    id='line1'
                                    autoComplete="new-no-line1"
                                    onBlur={()=>{
                                        if(line1?.length === 0){
                                            setError("line1", {
                                                type: "manual",
                                                message: A12Y_CONST.ERROR_MESSAGE.ADDRESS_REQUIRED,
                                            });
                                        }
                                    }}
                                    onFocus={()=>{
                                        setError("line1", {
                                            type: "manual",
                                            message: "",
                                        });
                                    }}
                                />

                                {/*<div onClick={()=>{selectAdd(null)}} >Hello</div>)*/}
                                {showAddressDropDown && placeServiceList.length > 0?
                                    <div className='addressLin1DropDown'>
                                        <div className="addressLin1DropDownInner">
                                            {placeServiceList && placeServiceList.map((item,key) =>
                                                (item && item.description ?
                                                        <div onMouseMove={onMouseMoveEvent} className={dropDownIndex === key ? 'addressListDropDownHighlight' : dropDownIndex === null ? 'addressListDropDown addressHover' : 'addressListDropDown' } onClick={()=>{selectAdd(item)}} key={key}>{item.description}</div>
                                                        :
                                                        null
                                                )
                                            )}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {/*{placePredictions.map((item) => <div>{item.description}</div>)}*/}
                                <div className='errorRelativeRow'> {errors.line1 && <p className='errorMessage'>{errors.line1.message}</p>}</div>
                            </div>
                        </div>
                    </div>
                    <div className="formRow fullWidth marginTop10">
                        <label className="lblExpType">{A12Y_CONST.LABEL.UNIT}</label>
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth marginBottom30  address2'>
                                    <input
                                        {...register('line2',)}
                                        placeholder='Apartment, Suite, Unit, Building'
                                        className='inputColum24size '
                                        maxLength='255'
                                        type='text'
                                        id='line2'
                                        onFocus={onFocusEventHandler}
                                        autoComplete="new-no-line2"
                                        onChange={handelLine2}
                                        value={line2}
                                    />
                                </div>
                             </div>
                    </div>
                    <div className="formRow fullWidth marginTop18 marginBottom30">
                        <label className="lblExpType">{A12Y_CONST.LABEL.CITY}</label>
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth marginBottom10 cityCountryState'>
                                    <div className='width40 paddingRight35 floatLeft city'>
                                        <input
                                            {...register('city',)}
                                            placeholder='City'
                                            maxLength='100'
                                            className='inputColum24size'
                                            type='text'
                                            id='city'
                                            onFocus={onFocusEventHandler}
                                            autoComplete="new-no-city"
                                            onChange={handelCity}
                                            value={city}
                                        />
                                    </div>
                                    <div className='width60 dependentResidencCol floatLeft marginTop10 positionRelative paddingLeft35 addressRow countryState'>
                                        <label className='labelCol institutionStateLabel'>{A12Y_CONST.LABEL.STATE}</label>
                                        <div className={(countryState && Object.keys(countryState).length > 0 ? 'hasValue' : 'noValue') + ' fullWidth dependentResidencCol paddingLeft35 countryState'}>
                                        <Select
                                            // selectdisplayprops={countryState}
                                            // defaultValue={countryStateList[0]}
                                            // className='phoneTypeLabel'
                                            classNamePrefix={'dependentResidence'}
                                            placeholder="Select"
                                            maxLength='100'
                                            value={countryState?.label ? countryState : ""}
                                            options={stateOptions}
                                            onChange={handleChangeCountryState}
                                            // getOptionLabel={e => (
                                            //     <div className='phoneListRow'>
                                            //         <span className={countryState.abbreviation === e.abbreviation ? 'phoneListText selectColor' : 'phoneListText'}>{e.abbreviation}</span>
                                            //     </div>
                                            // )}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    text: 'red',
                                                    primary: '#0096C708',
                                                },
                                            })}
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="formRow fullWidth">
                        <label className="lblExpType">{A12Y_CONST.LABEL.ZIP}</label>
                            <div className=' fullWidth positionRelative'>
                                <div className='fullWidth'>
                                    <div className='width40 paddingRight35 zip'>
                                        <input
                                            {...register('zip',)}
                                            placeholder='00000'
                                            className='inputColum24size'
                                            maxLength='100'
                                            type='number' min='0' inputMode='numeric' pattern='[0-9]*'
                                            onWheel={e => e.target.blur()}
                                            id='zip'
                                            onFocus={onFocusEventHandler}
                                            autoComplete="new-pincode"
                                            onChange={handelZip}
                                            value={zip}
                                        />
                                    </div>
                                </div>
                            </div>
                    </div>
                    {/*------------------ Button Actions ---------------------*/}
                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol right35 positionAbsouteBottom" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.INSTITUTION_ADDRESS_CANCEL} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.INSTITUTION_ADDRESS+props.BtnTxt} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={props.BtnTxt} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default InstitutionAddressComponent
