import React, { Component } from 'react';
import AddContactComponent from '../components/AddContactFormComponent';
import {secureClient} from "../config/axiosClient";
import A12Y_CONST from "../common/a12yConst";
import * as dependentActions from "../redux/actions/dependentActions";
import {connect} from "react-redux";
import * as userDetailActions from "../redux/actions/userDetailActions";

export class AddContactScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            errorMessage:false,
            successMessage:'',
            error:false,
            message:'',
            editMode:false,
            contactDetails:{},
            isClickedOutside:false
        }
    }

    componentDidMount = async ()=> {
        this.setState({editMode:false,contactDetails:{}})
        if(this.props.location && this.props.location?.state && this.props.location.state?.contact){
            this.setState({editMode:true,contactDetails:this.props.location.state.contact})
        }
    }

    onSubmitHandler = async (obj)=>{
        try{
            if(Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.id){
                this.setState({loading:true})
                let response = await secureClient.post(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.CONTACTS,obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
                    await this.props.dataChanged(false)
                    this.setState({loading:false,message:'Successfully Contact added'})
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,institutesLoader:false})
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.ADD_CONTACT,e)
        }
    }

    onUpdateHandler = async (obj)=>{
        try {
            const {contactDetails} = this.state;
            if(Object.keys(this.props.currentAsset).length > 0 && this.props.currentAsset.id && Object.keys(contactDetails).length > 0){
                this.setState({loading:true})
                let response = await secureClient.put(A12Y_CONST.API_ENDPOINT.ADVOCATE_DEPENDENTS+"/"+this.props.dependent.id+"/"+A12Y_CONST.API_ENDPOINT.ASSETS+"/"+this.props.currentAsset.id+"/"+A12Y_CONST.API_ENDPOINT.CONTACTS+"/"+contactDetails.id,obj);
                if (!response.data.error && response.data.status === A12Y_CONST.API_RESPONSE_STATUS.OK) {
                    this.props.history.push(A12Y_CONST.ROUTE.INVENTORY_VIEW)
                    await this.props.dataChanged(false)
                    this.setState({loading:false,message:'Successfully Contact added'})
                }else {
                    this.setState({error:true,errorMessage:response.data.sr?.a12yErr?.message,loading:false,institutesLoader:false})
                }
            }
        }catch (e) {
            this.setState({error:true,errorMessage:A12Y_CONST.ERROR_MESSAGE.UNEXPECTED_ERROR_MSG,loading:false})
            await this.props.sendErrorReportToServer(A12Y_CONST.ERROR_CODE.UPDATE_CONTACT,e)
        }

    }

    dataChangeHandler = async(status) =>{
        await this.props.dataChanged(status)
    }

  render() {
        const {loading,error,errorMessage,message,editMode,contactDetails,isClickedOutside} = this.state
    return (
        <div className="myProfileAccount animationall addExpenseScreenMain lightBackgroundBlueColor mainRightSidebare" onClick={()=>this.setState({isClickedOutside:!this.state.isClickedOutside})}>
            <div className='rightPanelWidhtSidebar'>
                <div className="rightPanelWidhtSidebarContainer fullWidth">
                    <AddContactComponent
                        message={message}
                        loading={loading}
                        error={error}
                        errorMessage={errorMessage}
                        onSubmit={this.onSubmitHandler}
                        editMode={editMode}
                        contactDetails={contactDetails}
                        onUpdate={this.onUpdateHandler}
                        isDataChanged={this.dataChangeHandler}
                        isClickedOutside={isClickedOutside}
                    />
                </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
    errMsg : state.dependentReducer.errMsg,
    isError : state.dependentReducer.isError,
    successMessage : state.dependentReducer.successMessage,
    dependent : state.dependentReducer.dependent,
    currentAsset : state.dependentReducer.currentAsset,
});

const mapDispatchToProps = dispatch => ({
    currentAssetData : (asset) => dispatch(dependentActions.currentAssetData(asset)),
    dataChanged : (status) => dispatch(userDetailActions.dataChanged(status)),
    sendErrorReportToServer : (type,error) => dispatch(userDetailActions.sendErrorReportToServer(type,error)),
});


export default connect(mapStateToProps, mapDispatchToProps) (AddContactScreen);
