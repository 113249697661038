import React, { useState, useEffect, useRef } from 'react'
import A12Y_CONST from "../common/a12yConst";
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import Loading from '../components/LoadingComponent';
import UploadFiles from '../components/ImageUploadComponent';
import LoaderFullScreen from './LoaderFullScreenComponent'
import ErrorMessageComponent from '../components/ErrorMessageComponent'
import SuccessMessageComponent from '../components/SuccessMessageComponent'
import EditChangesRemovePopup from '../components/EditChangesRemovePopup'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from 'uuid';
import {validateDate, ddmmyyyyFormat, dateFromKeyboard} from "../util/date_format";
import {currencyFormatting} from "../util/CurrencyFormater";
import {hideOnBlur, readOnlyMobile} from "../util/inputKeyboardHide";
import Switch from "@material-ui/core/Switch";

function AddExpense(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [expenseType, setExpenseType] = useState({});
    const [dateExp, setDateExp] = useState(new Date());
    const [dateEndRepeatBy, setDateEndRepeatBy] = useState(new Date());
    const [amount, setAmount] = useState('');
    const [paidTo, setPaidTo] = useState('');
    const [note, setNote] = useState('');
    const [expenseTypeOptionsList, setExpenseTypeOptionsList] = useState([]);
    const [expenseTypeError, setExpenseTypeError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [dateErrorMeesage, setDateErrorMeesage] = useState('');
    const [dateEndRepeatByError, setDateEndRepeatByError] = useState(false);
    const [dateEndRepeatByErrorMeesage, setDateEndRepeatByErrorMeesage] = useState('');
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [amountErrorMeesage, setAmountErrorMeesage] = useState('');
   // const [paidToError, setPaidToError] = useState(false);
    const [editMode, setEditMode] = useState(null);
   // const [paidToErrorMeesage, setPaidToErrorMeesage] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [isDataModified, setIsDataModified] = useState(false);
    const [expenseId, setExpenseId] = useState(null)
    const history = useHistory();
    const { register, handleSubmit, getValues, setValue,  setError,   control, formState: { errors, isSubmitting } } = useForm({ mode: "all" });
    const [fileInfo, setFileInfo] = useState([]);
    const [fileBase64Info, setFileBase64Info] = useState([]);
    const [deletedFileInfo, setDeletedFileInfo] = useState([]);
    const dateRef = useRef(null);
    const dateEndRepeatByRef = useRef(null);
    // const [isAmountEmpty, setIsAmountEmpty] = useState(true)
    const [linkToWebsite, setLinkToWebsite] = useState('');
    const [repeatMonthly, setrepeatMonthly] = useState(false);
    const [attachAllFile, setAttachAllFile] = useState(false);

    function goBack() {
       // history.push("/expenses");
        history.goBack();
    }


    useEffect(()=>{
       const assignDefaultValue = async ()=>{
           let expense = props.expenseObj;
           if(expense && Object.keys(expense).length > 0){
               setExpenseId(expense.id);
               // setValue("amount",expense.amount)
               if(props.expenseTypeList.length > 0){
                   let list = await props.expenseTypeList.filter(item =>item.id === expense.expense_type_id)
                   if(list && list.length > 0){
                       setExpenseType(list[0])
                       // setValue("expenseType",list[0])
                   }
               }
               let amount = expense?.amount || expense?.amount === 0 ? await currencyFormatting(expense.amount) : ''
               setAmount(amount)
               setPaidTo(expense.paid_to)
               setLinkToWebsite(expense?.web_url)
               setNote(expense.note)
               setDateExp(new Date(expense.date_of_expense))
               // if(expense.date_of_expense){
               //  let date = await moment(expense.date_of_expense).format("MM/DD/YYYY")
               //  setDateExp(date)
               // }
               await setEditMode(true)
               setIsBtnDisabled(true);
           }else {
               const uuid = await uuidv4();
               setExpenseId(uuid);
               await setEditMode(false)
               setDateEndRepeatBy(new Date(props.dependent.reporting_period.end_date))
               if(props.fromActivity){
                   setNote(props.currentActivity.note !== '' ? props.currentActivity.note: '')
                   setDateExp(props.currentActivity.date_of_activity !== null && props.currentActivity.date_of_activity !== undefined ? new Date(props.currentActivity.date_of_activity) : new Date())
               }
           }
       }

       assignDefaultValue()
    },[props.expenseTypeList,props.expenseObj]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=>{
       const checkIsFormatDataChanged = async ()=>{
           let expense = props.expenseObj;
           props.isDataChanged(false)
           setIsDataModified(false)
           setIsBtnDisabled(true)
           let expDate = dateExp !== null ? await ddmmyyyyFormat(dateExp) : null
           let today = await ddmmyyyyFormat(new Date())
           if(editMode !== null && editMode){
               if(await currencyFormatting(expense.amount) !== amount || expense.paid_to !== paidTo || expense.web_url !== linkToWebsite ||  expense.note !== note || expense.date_of_expense !==  expDate || (Object.keys(expenseType).length > 0 && expense.expense_type_id !== expenseType.id) || deletedFileInfo.length > 0 || fileInfo.length > 0){
                   if(Object.keys(expenseType).length > 0 && amount !== "" && dateExp !== null){
                       setIsBtnDisabled(false)
                   }
                   setIsDataModified(true)
                   props.isDataChanged(true)
               }
           }else {
               if(editMode !== null && !editMode){
                   if(amount !== "" ||  paidTo !== "" ||  note !== "" || linkToWebsite !== '' || today !==  expDate || (Object.keys(expenseType).length > 0) || deletedFileInfo.length > 0 || fileInfo.length > 0){
                       if(Object.keys(expenseType).length > 0 && amount !== "" && dateExp !== null){
                           if(repeatMonthly){
                               if(dateEndRepeatBy === null || dateEndRepeatByError){
                                   setIsBtnDisabled(true);
                               }else{
                                   setIsBtnDisabled(false);
                               }
                           }else{
                               setIsBtnDisabled(false);
                           }
                       }
                       setIsDataModified(true)
                       props.isDataChanged(true)
                   }
               }
           }
       }
        checkIsFormatDataChanged()

    },[editMode,amount,paidTo,note,dateExp,expenseType,fileInfo,deletedFileInfo,dateEndRepeatBy,dateEndRepeatByError, linkToWebsite]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCancel = async()=>{
        if(isDataModified){
            setIsPopUpVisible(true);
        }
        else{
            setIsPopUpVisible(false);
            props.checkIsCommingFromActivity()
            if(props.isSessionTimeout || !props.fromActivity){
                // history.push(A12Y_CONST.ROUTE.EXPENSES)
                if(props.fromExpenseViewScreen){
                    history.push(A12Y_CONST.ROUTE.EXPENSE_VIEW);
                }else{
                    history.push(A12Y_CONST.ROUTE.EXPENSES)
                }
            }else {
                // history.goBack()
                if(props.IsExpenseCreateFromActivityLog){
                    props.backToActivity()
                }else{
                    history.goBack()
                }

            }
        }
    }

    const handleHidePopup = () => {
        setIsPopUpVisible(false);
    }

    const handleResetAllFields = async () => {
        props.isDataChanged(false)
        setIsPopUpVisible(false);
        props.checkIsCommingFromActivity()
        if(props.isSessionTimeout || !props.fromActivity){
            // history.push(A12Y_CONST.ROUTE.EXPENSES)
            if(props.fromExpenseViewScreen){
                history.push(A12Y_CONST.ROUTE.EXPENSE_VIEW);
            }else{
                history.push(A12Y_CONST.ROUTE.EXPENSES)
            }
        }else {
            if(props.IsExpenseCreateFromActivityLog){
                props.backToActivity()
            }else{
                history.goBack()
            }
        }
        // props.isDataChanged(false)
    }

    const handleExpenseTypeChange = async (e) => {
       // const evt = e.target.value;
      await setExpenseType(e)
        setExpenseTypeError(false)
    }

    function validateExpType(type) {
        if(type === 'blur'){
            if(expenseType && Object.keys(expenseType).length === 0){
                setExpenseTypeError(true)
            }
        }else {
            setExpenseTypeError(false)
        }

    }

    const handleAmountChange = async e => {
        // let amt = e.target.value.trim();
        // let reg = new RegExp('^[0-9 .]*$');
        // if(reg.test(amt)){
        //     if(amt.toString().includes('.')){
        //         let index = amt.indexOf('.');
        //         let result = amt.substring(index+1, amt.length);
        //         if(result.length <= 2){
        //             await setAmount(amt);
        //         }
        //     }else {
        //         await setAmount(amt);
        //     }
        // }

        let amt = e.target.value.trim()
        if(amt === ''){
            await setAmount(amt)
            e.preventDefault();
        }else {
            let temp = await currencyFormatting(amt);
            if (temp !== undefined) {
                await setAmount(temp)
            }
        }
    }

    const handlePaidToChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setPaidTo(e.target.value);
        }
    }

    const handleNoteChange = async e => {
        if(e.target.value === " " ){
            e.preventDefault();
        }else {
            await setNote(e.target.value);
        }
    }
    
    useEffect(  () => {
        const assignExpenseTypeList = async ()=>{
            var expenseTypeList = await props.expenseTypeList.map(item => ({ label: item.title, value: item.title, id:item.id }));
            setExpenseTypeOptionsList(expenseTypeList)
        }

        assignExpenseTypeList()
    },[props.expenseTypeList])
    
    useEffect(() => {
        setErrors(props.error)
        setLoading(props.loading);
        setLoaderFullScreenVisible(props.loaderFullScreen)
    }, [props.error, props.loading, props.loaderFullScreen])
    
    const handleChangeRaw = (e, elmName) => {
        let val = e.target.value
        if(typeof val !== 'object' && val !== undefined){
            document.getElementsByName(elmName)[0].value = dateFromKeyboard(val)
        }
    }

    const isDateValid = () => {
        if(dateExp != null){
            let isValidObj = validateDate(dateExp, 20, 2)
            
            setDateError(isValidObj.isErr)
            setDateErrorMeesage(isValidObj.errMsg)
        }else{
            setDateError(true)
            setDateErrorMeesage('Date is required.')
        }
        if(dateEndRepeatBy != null){
            return isDateMatching();
        }
    }

    const isRepeatMonthlyDateValid = () => {
        if(repeatMonthly){
            if(dateEndRepeatBy != null){
                return isDateMatching();
            }else{
                setDateEndRepeatByError(true)
                setDateEndRepeatByErrorMeesage(A12Y_CONST.ERROR_MESSAGE.DATE_IS_REQUIRED)
            }
        }
    }

    const isDateMatching = () => {
        let sDt = new Date(dateExp);
        let eDt = new Date(dateEndRepeatBy);

        if (dateExp !== null && dateEndRepeatBy !== null) {
            if (eDt.toDateString() === sDt.toDateString()) {
                setDateEndRepeatByError(false)
                setDateEndRepeatByErrorMeesage('')
                return true;
            }
            else if (eDt < sDt) {
                setDateEndRepeatByError(true)
                setDateEndRepeatByErrorMeesage(A12Y_CONST.ERROR_MESSAGE.END_REPEAT_DATE_ERROR_MEDICAL)
                return false;
            }else if(sDt < eDt){
                setDateEndRepeatByError(false)
                setDateEndRepeatByErrorMeesage('')
                return true;
            }
        }
    };

    const validateAmount = (e) => {
        let amt = amount.replaceAll(',', '')
        
        if(amt === '' && amt.length === 0) {
            setAmountError(true)
            setAmountErrorMeesage('Enter valid amount.');
            return true;
        } else if(amt > 99000000000) {
            setAmountError(true)
            setAmountErrorMeesage('Amount should be less than 99Bn.');
            return true;
        }
        else{
            setAmountError(false)
            setAmountErrorMeesage('');
            return false;
        }
    }

    const validatePaidTo = () => {
        if(paidTo === '' && paidTo.length === 0) {
           // setPaidToError(true)
           // setPaidToErrorMeesage('Whom to pay');
            return true;
        }else{
           //setPaidToError(false)
           // setPaidToErrorMeesage('');
            return false;
        }
    }

    const onSubmit = async(expenseData)=>{
        // user.advocate_id = props.dependent.advocate_id
        let isValidate = true;
        if(expenseType && Object.keys(expenseType).length === 0){
           await setExpenseTypeError(true)
            isValidate = false;
        }
        if(amount === '' && amount.length === 0) {
            setAmountError(true)
            setAmountErrorMeesage('Enter valid amount.');
            isValidate = false;
        }
        
        if(dateError){
            return
        }

        // if(dateExp.length > 0 && dateExp.length < 14 || dateExp === '') {
        //     setDateError(true)
        //     setDateErrorMeesage('Invalid Date');
        //     isValidate = false;
        // }
        // if(dateExp && dateExp.split("/").length === 3){
        //     let dateExpSplitList = dateExp.split("/")
        //     let date = dateExpSplitList[0].trim()+"/"+dateExpSplitList[1].trim()+"/"+dateExpSplitList[2].trim();
        //     expenseData.dateExp = date
        // }else {
        //     expenseData.dateExp = null
        // }
        let obj = {
            'id' : expenseId,
            "expense_type_id" : expenseType && Object.keys(expenseType).length > 0 ? expenseType.id: '',
            // "amount" : amount,
            "amount" : Object.keys(amount).length > 0 && amount.includes(',') ? amount.replaceAll(',','') : amount ,
            "paid_to" : paidTo,
            "web_url" : linkToWebsite,
            "title" : "",
            "note" : note,
            "document_ref" : {},
            "date_of_expense": await ddmmyyyyFormat(new Date(dateExp)),
            "repeat_monthly" : repeatMonthly,
            "end_repeat_by":repeatMonthly?await ddmmyyyyFormat(new Date(dateEndRepeatBy)):'',
            "include_attachment":attachAllFile
        }

        let isDatesValid = validateDate(dateExp, 20, 2)
        let isAmountValid = validateAmount();
        
        if(isValidate && !isDatesValid.isErr && !isAmountValid){
            if(editMode){
                setIsSubmit(true)
                props.onUpdate(obj, fileInfo, fileBase64Info,deletedFileInfo)
            }else {
                setIsSubmit(true)
                props.onSubmit(obj, fileInfo, fileBase64Info)
            }
        }

    }
    const filesDetail_FromChild = (e,deletedFiles) => {
        let fileList = [];
        let base64List = [];
        for (var i = 0; i < e.length; i++) {
            if(!e[i].file_id){
                let name = e[i].displayName
                let size = e[i].size
                let file = e[i];
                let fileName = e[i].name;
                let reader = new FileReader();
                const uuid = uuidv4();
                //let s3key = props.dependent.id+'/'+'expense/'+expenseId+'/'+uuid
                let s3key = `${props.dependent.id}/expense/${expenseId}/${uuid}`
                reader.readAsDataURL(file);
                reader.addEventListener('loadend', () =>
                        base64List.push({
                            base64:reader.result,
                            name: name,
                            size: size,
                            s3_key: s3key,
                            fileName: fileName
                        })
                );
                fileList.push({
                    s3_key: s3key,
                    file_id:uuid,
                    expense_id:expenseId,
                    'contentType': e[i].type,
                    'fileMetadata': {
                        "about_dependent": "some more info"
                    },
                    'name' : name,
                    'size' : size,
                    'fileName': fileName
                });
            }
        }
        setFileInfo(fileList);

        setFileBase64Info(base64List)
        setDeletedFileInfo(deletedFiles)
    }

    useEffect(() => {
        if(fileInfo.length > 0 && repeatMonthly){
            setAttachAllFile(true);
        }else{
            setAttachAllFile(false);
        }
    },[fileInfo, repeatMonthly])

    useEffect( () => {
        let arr =[dateRef.current]
       // let arr1 =[dateEndRepeatByRef.current]
        readOnlyMobile(arr)
        // readOnlyMobile(arr1)
    },[])


    const handleRepeatMonthly = async (e) => {
        await setrepeatMonthly(e.target.checked);
        await setValue('repeatMonthly', e.target.checked)
        // if(!e.target.checked){
        //     setDateEndRepeatBy(new Date(props.dependent.reporting_period.end_date))
        // }
        var currentDate = new Date();
        var theLast = new Date(currentDate.getFullYear(), 11, 31);
        var date  = await ddmmyyyyFormat(new Date(theLast))
        if(e.target.checked){
            await setDateEndRepeatBy(new Date(date))
        }else{
            await setDateEndRepeatBy('')
        }
        if(dateEndRepeatBy != null){
            return isDateMatching();
        }

    }
    const handleAttachAllFile = async (e) => {
        await setAttachAllFile(e.target.checked);
        await setValue('attachAllFile', e.target.checked)
    }


    const handleLinkToWebsite = (value) => {
        if(value.length === 1 && value === " "){
            return
        }else{
            setLinkToWebsite(value);
        }
    }

    return (
        <div className="titleRow margignTop768_13 margignBottom768_10 addExpensesWrap">
            { loaderFullScreenVisible ?
                <LoaderFullScreen/>
                :
                null
            }
            {loading ?
                <div className="transparentLayer"></div>
                :
                null
            }
            <h2 className="titleWithSidebar noExpenseTitle marginBottom30">
                <span id={A12Y_CONST.BUTTON_ID.EXPENSE_ADD_BACK} className="cursorPointer" onClick={onCancel}><svg className='marginRight10' width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.652 16.5323C10.3215 15.8818 10.3355 14.8104 9.68334 14.1426L4.19094 8.5042L9.82931 3.01176C10.4988 2.3613 10.5128 1.28991 9.86065 0.622118C9.21019 -0.047358 8.13868 -0.0614097 7.47089 0.590778L0.621993 7.26237C-0.0474648 7.91283 -0.0615151 8.98422 0.590655 9.65201L7.26225 16.5009C7.91271 17.1704 8.98421 17.1844 9.652 16.5323Z" fill="#565C65" />
                </svg>
                    <span>{props.fromActivity ? A12Y_CONST.LABEL.BACK_TO_ACTIVITY : A12Y_CONST.LABEL.EXPENSES_LABEL}</span></span>
            </h2>
            <div className="dependentBorderBox expenseBorderBox600 dependentDefauldMinHeight">
                <h3 className='marginBottom10'><span className='vam'>{editMode ? 'Update' : 'Add'}</span> <span className='vam'>{A12Y_CONST.LABEL.Expense_for}</span> <span className='txtEllipsis vam'>{props.dependent.nick_name}</span></h3>
                {error ?
                    <div className='maxWidth600 marginLeftRightAuto'>
                        <ErrorMessageComponent errorMessage={props.errorMessage}/>
                    </div>
                    :
                    (props.message ?
                            <SuccessMessageComponent successMessage={props.message}/>

                            :
                            null
                    )

                }
                <form autoComplete="off" className='marginTop30 expesneFormTop fullWidth' encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                    <div className="formRow fullWidth formRowGap">
                        <label className="lblExpType">{A12Y_CONST.LABEL.EXPENSE_TYPE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div className={`fullWidth expenseTypeCol dependentResidencCol ${expenseTypeError && 'selectError'} ${ expenseType && Object.keys(expenseType).length > 0 ? 'hasValue' : 'noValue'}`}>
                            <Controller
                                name="expenseType"
                                value={expenseType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={expenseTypeOptionsList}
                                        value={expenseTypeOptionsList.find(c => c.id === expenseType.id)}
                                        onChange={handleExpenseTypeChange}
                                        onFocus={()=>{validateExpType('focus')}}
                                        onBlur={()=>{validateExpType('blur')}}
                                        classNamePrefix={'dependentResidence'}
                                    />
                                )}
                                control={control}
                                className="fullwidth inputColum24size"
                                placeholder="Select"
                            />
                            <div className='errorRelativeRow'> {expenseTypeError && <p className='errorMessage'>{A12Y_CONST.ERROR_MESSAGE.SELECT_EXPENSE_TYPE}</p>}</div>
                        </div>
                    </div>
                    <div className="formRow fullWidth formRowGap payRow">
                        <div className="width50 paddingRight16 positionRelative">
                            <label>{A12Y_CONST.LABEL.AMOUNT}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                            <div className="fullWidth amountWrap">
                                <span className={getValues('amount') !== '' || amount.length < 1? "currency" : "currency currencyColorChnage" }>$</span>
                                <input 
                                    {...register("amount")}
                                    placeholder="0.00" 
                                    type='text' min='0' inputMode='decimal' //pattern='[0-9]+(\.[0-9]{1,2})?'
                                    id="amount"
                                    //maxLength="5"
                                    onWheel={e => e.target.blur()}
                                    onChange={handleAmountChange}
                                    value={amount}
                                    maxLength={14}
                                    onBlur={e => validateAmount(e)}
                                    onFocus={()=>setAmountErrorMeesage(false)}
                                    className={`fullWidth txtAmount inputColum24size ${amountError && 'errorInputBorderRed'}`}
                                />
                                <div className='errorRelativeRow'>{amountError? <p className='errorMessage'>{amountErrorMeesage}</p>: null}</div>
                            </div>
                        </div>
                        <div className="width50 paddingleft16 positionRelative paidTo">
                            <label className='minWidht190 textAlignRight'>{A12Y_CONST.LABEL.PAID_TO}</label>
                            <div className="fullWidth">
                                <input 
                                    {...register("paidTo")} 
                                    value={paidTo}
                                    id="paidTo"
                                    maxLength='255'
                                    onBlur={validatePaidTo}
                                    onChange={handlePaidToChange}
                                    //onFocus={()=>setPaidToErrorMeesage(false)}
                                    className="fullWidth inputColum24size"
                                    placeholder="Business Name"
                                />
                                {/*<div className='errorRelativeRow'>{paidToError? <p className='errorMessage'>{paidToErrorMeesage}</p>: null}</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="fullWidth payRow">
                    <div className="formRow width50 paddingRight16 formRowGap">
                        <label>{A12Y_CONST.LABEL.DATE}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                        <div>
                            <Controller
                                name="dateExp"
                                control={control}
                                inputmode='none'
                                render={({ field }) => 
                                    <DatePicker
                                        {...field}
                                        ref={dateRef}
                                        dateFormat="MM / dd / yyyy"
                                        selected={dateExp}
                                        onChange={date => setDateExp(date)}
                                        onChangeRaw={(event) => handleChangeRaw(event, 'dateExp')}
                                        onBlur={isDateValid}
                                        onCalendarClose={isDateValid}
                                        showMonthDropdown
                                        onKeyDown={(event) => hideOnBlur(event, dateRef)}
                                        showYearDropdown
                                        dropdownMode="select"
                                        onFocus={(e)=> {setDateError(false)}}
                                        className={!dateError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                        placeholderText="MM / DD / YYYY"
                                    />
                                }
                            />
                            <div className='errorRelativeRow'>
                                {dateError? <p className='errorMessage'>{dateErrorMeesage}</p>: null}
                            </div>
                        </div>
                    </div>
                        {props.expenseObj && Object.keys(props.expenseObj).length === 0 && !props.fromActivity?
                            <div className="formRow width50 paddingleft16 repeatMonthlyCol formRowGap">
                                <div className="fullWidth df aic">
                                    <label
                                        className="wardMovingLabel repeatMonthlyLabel">{A12Y_CONST.LABEL.DOES_THIS_REPEAT_MONTHLY}</label>
                                    <div className="marginTop5 df aic">
                                        <Controller
                                            name="repeatMonthly"
                                            control={control}
                                            render={({field}) =>
                                                <Switch
                                                    id={A12Y_CONST.BUTTON_ID.REPEAT_MONTHLY_TOGGLE}
                                                    checked={repeatMonthly}
                                                    onChange={handleRepeatMonthly}
                                                    color="primary"
                                                />
                                            }
                                        />
                                        {repeatMonthly &&
                                        <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}
                                        {!repeatMonthly &&
                                        <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {repeatMonthly ?
                            <div className="fullWidth payRow">
                                <div className="formRow width50 paddingRight16 formRowGap">
                                <label>{A12Y_CONST.LABEL.END_REPEAT_BY}<span className="astrciBlue">{A12Y_CONST.LABEL.STAR}</span></label>
                                <div>
                                    <Controller
                                        name="dateEndRepeatBy"
                                        control={control}
                                        inputmode='none'
                                        render={({ field }) =>
                                            <DatePicker
                                                {...field}
                                                ref={dateEndRepeatByRef}
                                                dateFormat="MM / dd / yyyy"
                                                selected={dateEndRepeatBy}
                                                onChange={date => setDateEndRepeatBy(date)}
                                                onChangeRaw={(event) => handleChangeRaw(event, 'dateEndRepeatBy')}
                                                onBlur={isDateValid}
                                                onCalendarClose={isRepeatMonthlyDateValid}
                                                showMonthDropdown
                                                onKeyDown={(event) => hideOnBlur(event, dateEndRepeatByRef)}
                                                showYearDropdown
                                                dropdownMode="select"
                                                onFocus={(e)=> {setDateEndRepeatByError(false)}}
                                                className={!dateEndRepeatByError ? 'inputColum24size ': 'inputColum24size errorInputBorderRed'}
                                                placeholderText="MM / DD / YYYY"
                                            />
                                        }
                                    />
                                    <div className='errorRelativeRow'>
                                        {dateEndRepeatByError? <p className='errorMessage'>{dateEndRepeatByErrorMeesage}</p>: null}
                                    </div>
                                </div>
                            </div>
                            </div>
                        :
                            null
                        }
                    </div>
                    <div className="fullWidth payRow">
                        <div className="formRow width50 paddingRight16 formRowGap">
                            <div>
                                <label>{A12Y_CONST.LABEL.LINK_URL}</label>
                                <div className='fullWidth doctorRowMarginB_600'>
                                    <input
                                        {...register('linkToWebsite',{
                                            pattern:{
                                                //value:/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9]{1,61}$/,
                                                value:/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
                                                message:A12Y_CONST.ERROR_MESSAGE.WEBSITE_ERROR_MESSAGE
                                            }
                                        })}
                                        placeholder='www.example.com'
                                        className={errors.linkToWebsite ? 'inputColum24size errorInputBorderRed' : 'inputColum24size'}
                                        type='text'
                                        id='displayName'
                                        autoComplete="none"
                                        onChange={(e) => handleLinkToWebsite(e.target.value)}
                                        value={linkToWebsite}
                                        onFocus={()=>{
                                            setError("linkToWebsite", {
                                                type: "manual",
                                                message: "",
                                            });
                                        }}
                                    />
                                    <div className='errorRelativeRow'>{errors.linkToWebsite? <p className='errorMessage'>{errors.linkToWebsite.message}</p>: null}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.expenseObj && Object.keys(props.expenseObj)?.length > 0 ?
                    null
                    :
                    <div className="formRow fullWidth formRowGap df fcol">
                        <label>{A12Y_CONST.LABEL.NOTE}</label>
                        <div className="fullWidth">
                            <textarea 
                                {...register("note")} 
                                rows="3" 
                                onChange={handleNoteChange}
                                maxLength='4000'
                                value={note}
                                className="fullWidth inputColum24size txtNote" 
                                placeholder={A12Y_CONST.TEXT.EXPENSE_NOTE_PLACEHOLDER}
                            ></textarea>
                        </div>
                        {/*<div className="txtCapture">Capture a note about this transaction.</div>*/}
                    </div>
                    }

                    <div className="formRow fullWidth formRowGap uploadWrap">
                        <label className="lblExpense">{A12Y_CONST.LABEL.EXPENSE_ATTACHMENT}</label>
                        <div className="lblUpload">
                            <UploadFiles
                                fileDetailSendToParent={filesDetail_FromChild}
                                fileUploadingData={props.fileUploadingData}
                                isSubmit={isSubmit}
                                editMode={editMode}
                                accepts={""}
                                obj={props.expenseObj}
                            />
                            <div className='errorRelativeRow'> {errors.expenseAttachment && <p className='errorMessage'>{A12Y_CONST.TEXT.SELECT_FILE_UPLOAD_MESSAGE}</p>}</div>
                        </div>
                    </div>

                    {repeatMonthly && props.expenseObj && Object.keys(props.expenseObj).length === 0 && !props.fromActivity?
                        <div className="formRow width50 paddingleft16 attachAllFileCol formRowGap">
                            <div className="fullWidth df aic">
                                <label className="wardMovingLabel repeatMonthlyLabelIncome">{A12Y_CONST.LABEL.ATTACH_TO_ALL_FUTURE_EXPENSES}</label>
                                <div className="marginTop5 df aic">
                                    <Controller
                                        name="attachAllFile"
                                        control={control}
                                        render={({field}) =>
                                            <Switch
                                                id={A12Y_CONST.BUTTON_ID.ATTACHED_ALL_FUTURE_EXPENSE}
                                                checked={attachAllFile}
                                                onChange={handleAttachAllFile}
                                                color="primary"
                                            />
                                        }
                                    />
                                    {attachAllFile && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.YES}</span>}
                                    {!attachAllFile && <span className='yesNo paddingleft16'>{A12Y_CONST.BUTTON_TEXT.NO}</span>}
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className="fullWidth textAlignRight marginTop30 marginBottom30 displayFlexCol" >
                        <div className='widthAuto'><input id={A12Y_CONST.BUTTON_ID.CANCEL_EXPENSE} className={!isSubmitting ? "cancelButton1" : 'cancelButton1'} onClick={onCancel} type="button" value={A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_CANCEL} /></div>
                        <div className='widthAuto marginLeft20'>
                            <div className='loaderSpinner'>
                                {loading ?
                                    <Loading />
                                    :
                                    null
                                }
                                <input id={A12Y_CONST.BUTTON_ID.SAVE_EXPENSE} className={loading || isBtnDisabled ? "saveButtonDisable" : 'saveButton'} disabled={loading || isBtnDisabled} type="submit" value={editMode ? A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE : A12Y_CONST.BUTTON_TEXT.GAURDIAN_PROFILE_PERSONAL_INFO_SAVE} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditChangesRemovePopup
                popUpStatus={isPopUpVisible}
                hidePopUp={handleHidePopup}
                resetAllFields={handleResetAllFields}
            />
        </div>
    )
}

export default AddExpense